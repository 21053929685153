import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from 'app/modules/auth/api';
import { AuthorizationService } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { IUpdateModel } from '../../interfaces';

import { IVerifiedCustomer } from './../../interfaces/verifyed-customer.interface';


/**
 * Service um die Kundenliste mit NDG zu holen
 */
@Injectable()
export class VerfiedCustomerService {

    /**
     * Standard Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     */
    public constructor(
      private authorizationService: AuthorizationService,
      private config: ConfigService,
      private httpClient: HttpClient,
    ) {}

    /**
     * Lädt die Liste aus 
     *
     * @param {string} id ID den Kunde
     * @returns {Observable<IVerifiedCustomer[] | undefined>} Liste die Kunden
     */
    public loadVerifiedCustomers(id: UUID): Observable<IVerifiedCustomer[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false, true).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<{verifiedCustomer: IVerifiedCustomer[]}>(`${this.config.getEnvironment().apiUrl}/CustomerProfile/VerifyCustomer?debitorId=${id}`, {}).pipe(map(result => result.verifiedCustomer)),
                of(undefined),
            )),
        )
    }

    /**
     * Aktualisieren DGA Kundendaten
     * 
     * @param {IUpdateModel} updateModel  DGA Customer
     * @returns { Observable<void> } Observable
     */
    public updateDgaCustomer(updateModel: IUpdateModel): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false, true).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/CustomerProfile/Update`, updateModel),
                of(undefined),
            )),
        )
    }

    /**
     * Erstellt einen DGA Benutzer
     * 
     * @param {string} fpCustomerId CustomerID
     * @param {string} financingMapId FinancingMapID
     * @param {string} countryName country Name
     * @param {string} countryForReporting countryForReporting 
     * @returns {Observable<string | undefined>} NDG
     */
    public createDgaCustomer(fpCustomerId: string, financingMapId: string, countryName: string, countryForReporting: string): Observable<string | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false, true).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<{ndg: string}>(`${this.config.getEnvironment().apiUrl}/CustomerProfile/Create`, {}, { 
                    params: {
                        financingId: financingMapId,
                        debitorId: fpCustomerId,
                        country: countryName,
                        countryForReporting: countryForReporting,
                    },
                }).pipe(
                    map(response => response.ndg)),
                of(undefined),
            )),
        )
    }
}
