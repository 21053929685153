import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const DOCUMENTS: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'documents',

    ndg: { type: WorkflowType.String },
}
