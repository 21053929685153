<div class="container" fxFill fxLayout="column" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
    <h1 class="color-primary">{{ 'navigation.administration.systemconfig' | translate }}</h1>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button mat-mini-fab color="primary" (click)="toggleLock()"
        *finprocessAuthorization="Role.RiskParameterizationAdministrator"
        matTooltip="{{ (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate }}">
        <mat-icon *ngIf="locked">lock</mat-icon>
        <mat-icon *ngIf="!locked">lock_open</mat-icon>
      </button>
      <button *finprocessAuthorization="Role.RiskParameterizationAdministrator" mat-mini-fab color="primary"
        (click)="save()" [disabled]="form.pristine || locked || hasAnyError(form)"
        matTooltip="{{ 'button.save' | translate }}">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
  <div class="content p-3">
    <div fxLayout="column" class="p-3">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.defaultReferenceInterestRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input currencyMask [options]="factorOptionsThree" formControlName="defaultReferenceInterestRate" matInput>
          <mat-error>{{ form.get('defaultReferenceInterestRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.additionComparativeCalculation' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input currencyMask [options]="factorOptionsThree" formControlName="additionComparativeCalculation" matInput>
          <mat-error>{{ form.get('additionComparativeCalculation')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.defaultAddition' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input currencyMask [options]="factorOptionsThree" formControlName="defaultAddition" matInput>
          <mat-error>{{ form.get('defaultAddition')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.bruttoNettoUrl' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input formControlName="bruttoNettoCalculatorUrl" matInput>
        </mat-form-field>
      </div>
    </div>
    <div class="divider p-1 my-2">{{'administration.features.systemconfig.calculator' | translate }}</div>
    <div fxLayout="column" class="p-3">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.maxDebitRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input currencyMask [options]="factorOptionsThree" formControlName="interestRate" matInput>
          <mat-error>{{ form.get('interestRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span fxFlex="20">{{'administration.features.systemconfig.maxAmount' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="20">
          <input currencyMask [options]="currencyOptions" formControlName="amountLimit" matInput>
          <mat-error>{{ form.get('amountLimit')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="divider p-1 my-2">{{ 'navigation.administration.user-agreement' | translate }}</div>
    <div class=" p-3">
      <mat-form-field appearance="fill" class="user-agreement-area">
        <textarea formControlName="userAgreement" matInput rows="30" cols="40">
          </textarea>
      </mat-form-field>
    </div>

    <div class="divider p-1 my-2">{{ 'administration.features.guidelines-configuration.guidelines-title' | translate }}</div>
    <div fxLayout="column" class="p-3" [formGroup]="form">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <span fxFlex="20">{{'administration.features.guidelines-configuration.numberOfDependentChildren' | translate}}</span>
          <mat-form-field appearance="fill" fxFlex="20">
              <input currencyMask [options]="currencyOptions" formControlName="baseChildrenAmount" matInput>
              <mat-error>{{ form.get('baseChildrenAmount')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
      </div>
  
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <span fxFlex="20">{{'administration.features.guidelines-configuration.numberOfChildrenReceivingSupportPayments' | translate}}</span>
          <mat-form-field appearance="fill" fxFlex="20">
              <input currencyMask [options]="currencyOptions" formControlName="baseChildrenObligationAmount"
                  matInput>
              <mat-error>{{ form.get('baseChildrenObligationAmount')?.errors | finProcessFormError}}</mat-error>
          </mat-form-field>
      </div>
  
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <span fxFlex="20">{{'administration.features.guidelines-configuration.numberOfChildrenProvidingSupportPayments' | translate }}</span>
          <mat-form-field appearance="fill" fxFlex="20">
              <input currencyMask [options]="currencyOptions" formControlName="baseChildrenRecipientAmount" matInput>
              <mat-error>{{ form.get('baseChildrenRecipientAmount')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
      </div>
  </div>

  </div>
</div>