/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IDropFolderViewModel } from 'app/modules/smartdoc/data';
import { inPlaceSort } from 'fast-sort';

import { AddedOrUpdatedDropArea, AddedOrUpdatedDropFolder, DeletedDropArea, DeletedDropFolder, SmartDocConfigurationFoldersLoaded } from './smartdoc-configuration.actions';

/**
 * Zustandsobjekt für SmardDoc configuration
 */
export interface ISmartDocConfigurationStateModel {
    /**
     * Aktuelle zustandsconfiguration
     */
     dropFolders: IDropFolderViewModel[]
}

/**
 * Zustand für aktuelle SmartDoc Konfiguration
 */
@State<ISmartDocConfigurationStateModel>({
    name: SmartDocConfigurationState.keyName,
    defaults: {
        dropFolders: [],
    },
})
@Injectable()
export class SmartDocConfigurationState {
    public static readonly keyName = 'smartDocConfiguration';

    /**
     *Selektor für Folder Configuration
     *
     * @param { ISmartDocConfigurationStateModel }state Zustandsobjekt
     * @returns {IDropFolderViewModel[]} Folder Configuration
     */
    @Selector()
    public static getDropFoldersConfiguration(state: ISmartDocConfigurationStateModel): IDropFolderViewModel[] {
        return state.dropFolders
    }

    /**
     * Zustandsänderung nach dem Laden SmardDoc configuration
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SmartDocConfigurationFoldersLoaded} action Aktion
     */
    @Action(SmartDocConfigurationFoldersLoaded)
    public smartDocConfigurationFoldersLoaded({patchState}: StateContext<ISmartDocConfigurationStateModel>, { payload }: SmartDocConfigurationFoldersLoaded): void {

        patchState({
            dropFolders: payload,
        });
    }

    /**
     * Zustandsänderung nach der Erstellung oder Bearbeitung eines Ordners
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AddedOrUpdatedDropFolder} action Aktion
     */
    @Action(AddedOrUpdatedDropFolder)
    public addedOrUpdatedDropFolder({patchState, getState}: StateContext<ISmartDocConfigurationStateModel>, { payload }: AddedOrUpdatedDropFolder): void {
        const currentState = getState();

        const existingFolder = currentState.dropFolders?.find(({id}) => id === payload.id);
        let allFolders: IDropFolderViewModel[] | undefined;

        if (!existingFolder) {
            allFolders = currentState.dropFolders.concat([payload]);
        }
        else {
            allFolders = currentState.dropFolders?.map(folder => {
                if (folder.id === payload.id) {
                    return {
                        ...folder,
                        name: payload.name,
                    }
                }
                return folder
            })
        }

        inPlaceSort(allFolders).asc(folder => folder.name);

        patchState({
            dropFolders: allFolders,
        });
    }

    /**
     * Zustandsänderung zum Löschen eines Ordners
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AddedOrUpdatedDropFolder} action Aktion
     */
    @Action(DeletedDropFolder)
    public deletedDropFolder({patchState, getState }: StateContext<ISmartDocConfigurationStateModel>, { payload }: DeletedDropFolder): void {
        const currentState = getState().dropFolders;

        const newFolders = currentState.filter(folder => folder.id !== payload).map(folder => ({...folder}));
        const folderToDelete = currentState.find(folder => folder.id === payload);

        if (folderToDelete !== undefined && folderToDelete.isDeletable) {
            if (folderToDelete.parentDropFolderId !== undefined) {
                const parentFolder = newFolders.find(folder => folder.id === folderToDelete.parentDropFolderId);

                //wenn folder Parent hat, set children zum neeun Parent
                if (parentFolder !== undefined) {
                    newFolders.filter(folders => folders.parentDropFolderId === folderToDelete.id).forEach(childFolder => {
                        childFolder.parentDropFolderId = parentFolder.id;
                    })
                }
            }
            else {
                newFolders.filter(folders => folders.parentDropFolderId === folderToDelete.id).forEach(childFolder => {
                    childFolder.parentDropFolderId = undefined;
                })
            }

            inPlaceSort(newFolders).asc(folder => folder.name);

            patchState ({
                dropFolders: newFolders,
            })
        }
    }

    /**
     * Zustandsänderung zum änderungen einen Area
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AddedOrUpdatedDropFolder} action Aktion
     */
    @Action(AddedOrUpdatedDropArea)
    public addedOrUpdatedDropArea({ getState, patchState }: StateContext<ISmartDocConfigurationStateModel>, { payload }: AddedOrUpdatedDropArea): void {

        const state = getState();
        let newFolders = state.dropFolders.slice();
        const existingArea = newFolders.find(folder => folder.id === payload.dropFolderId)?.dropAreas.find(area => area.id === payload.id);

        if (existingArea !== undefined) {
            if (existingArea.dropFolderId !== payload.dropFolderId) {
                if (newFolders.some(it => it.id === existingArea.dropFolderId) && newFolders.some(it => it.id === payload.dropFolderId)) {
                    const oldParentFolder = {...newFolders.find(it => it.id === existingArea.dropFolderId)} as IDropFolderViewModel;
                    const newParentFolder = {...newFolders.find(it => it.id === payload.dropFolderId)} as IDropFolderViewModel;
                    oldParentFolder.dropAreas = oldParentFolder.dropAreas.filter(it => it.id !== payload.id);
                    newParentFolder.dropAreas = [...newParentFolder.dropAreas, {...existingArea, ...payload}];
                    inPlaceSort(newParentFolder.dropAreas).asc(area => area.name);
                    newFolders = [...newFolders.filter(it => it.id !== oldParentFolder.id && it.id !== newParentFolder.id), oldParentFolder, newParentFolder];
                }
                else {
                    return;
                }
            }
            else if (newFolders.some(it => it.id === existingArea.dropFolderId)) {
                const parentFolder = {...newFolders.find(it => it.id === existingArea.dropFolderId)} as IDropFolderViewModel;
                parentFolder.dropAreas = [...parentFolder.dropAreas.filter(it => it.id !== existingArea.id), {...existingArea, ...payload}];
                inPlaceSort(parentFolder.dropAreas).asc(area => area.name);
                newFolders = [...newFolders.filter(it => it.id !== parentFolder.id), parentFolder];
            }
            else {
                return;
            }
        }
        else if (newFolders.some(it => it.id === payload.dropFolderId)) {
            const parentFolder = {...newFolders.find(it => it.id === payload.dropFolderId)} as IDropFolderViewModel;
            parentFolder.dropAreas = [...parentFolder.dropAreas, payload];
            inPlaceSort(parentFolder.dropAreas).asc(area => area.name);
            newFolders = [...newFolders.filter(it => it.id !== parentFolder.id), parentFolder];
        }
        else {
            return;
        }

        inPlaceSort(newFolders).asc(folder => folder.name);

        patchState({
            dropFolders: newFolders,
        });
    }
    /**
     * Zustandsänderung zum Löschen einen Area
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AddedOrUpdatedDropFolder} action Aktion
     */
     @Action(DeletedDropArea)
    public deletedDropArea({ getState, patchState }: StateContext<ISmartDocConfigurationStateModel>, { payload }: DeletedDropArea): void {
        const state = getState();
        let newFolders = state.dropFolders.slice();
        const existingArea = newFolders.find(folder => folder.dropAreas.some(area => area.id === payload))?.dropAreas.find(area => area.id === payload)

        if ( existingArea !== undefined && existingArea.isDeletable && newFolders.some(it => it.id === existingArea.dropFolderId)) {
            const parentFolder = {...newFolders.find(it => it.id === existingArea.dropFolderId)} as IDropFolderViewModel;
            parentFolder.dropAreas = parentFolder.dropAreas.filter(it => it.id !== payload);
            newFolders = [...newFolders.filter(it => it.id !== parentFolder.id), parentFolder];
            inPlaceSort(newFolders).asc(folder => folder.name);

            patchState({
                dropFolders: newFolders,
            });
        }
    }
}
