<div class="finprocess-hint p-2" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px" [ngClass]="type">
  <ng-container [ngSwitch]="type">
    <mat-icon *ngSwitchCase="'info'" color="primary">info</mat-icon>
    <mat-icon *ngSwitchCase="'help'" color="primary">help</mat-icon>
    <mat-icon *ngSwitchCase="'success'">check_circle</mat-icon>
    <mat-icon *ngSwitchCase="'warn'">warning</mat-icon>
    <mat-icon *ngSwitchCase="'error'">error</mat-icon>
    <mat-icon *ngSwitchDefault color="primary">info</mat-icon>
  </ng-container>
  <div fxFlex fxLayout="column" fxLayoutGap="6px">
      <b><ng-content select="[header]"></ng-content></b>
      <ng-content select="[content]"></ng-content>
  </div>
</div>