export enum Rating {
    /**
     * 1+
     */
    OnePlus = 0,

    /**
     * 1
     */
    One = 1,

    /**
     * 1-
     */
    OneMinus = 2,

    /**
     * 2+
     */
    TwoPlus = 3,

    /**
     * 2
     */
    Two = 4,

    /**
     * 2-
     */
    TwoMinus = 5,

    /**
     * 3+
     */
    ThreePlus = 7,

    /**
     * 3
     */
    Three = 8,

    /**
     * 3-
     */
    ThreeMinus = 9,

    /**
     * 4+
     */
    FourPlus = 11,

    /**
     * 4
     */
    Four = 12,

    /**
     * 4-
     */
    FourMinus = 13,

    /**
     * 5+
     */
    FivePlus = 14,

    /**
     * 5
     */
    Five = 15,

    /**
     * 5-
     */
    FiveMinus = 16,

    /**
     * 6+
     */
    SixPlus = 17,

    /**
     * 6
     */
    Six = 18,

    /**
     * 6-
     */
    SixMinus = 19,

    /**
     * 7+
     */
    SevenPlus = 20,

    /**
     * 7
     */
    Seven = 21,

    /**
     * 7-
     */
    SevenMinus = 22,

    /**
     * 8+
     */
    EightPlus = 23,

    /**
     * 8
     */
    Eight = 24,
}
