/**
 * Klasse für Aktion
 */
export class SmartDocMenuCollapsed {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocMenuCollapsed';
    /**
     * Constructor
     *
     * @param {boolean} payload Payload
     */
    public constructor(public payload: boolean) { }
}
