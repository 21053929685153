export enum LiabilityType {

    /**
     * Darlehen
     */
    Credit,

    /**
     * Ergänzungsdarlehen
     */
    ComfortCredit,

    /**
     * Überschreitung
     */
    Overdraft,

    /**
     * Einmalbarkredit
     */
    OneTimeCashLoan,

    /**
     * Garantie stand alone (Mietkautionsgarantie ohne Besicherung und Hauserrichtung mit Verpfändung Sparkonto)
     */
    GuaranteeStandAlone,

    /**
     * Garantie im Zusammenhang mit BW Finanzierung
     */
    GuaranteeConstruction,

    /**
     * Zwifi ohne Anschlussfinanzierung
     */
    ConstructionInterimFinancing,

    /**
     * Zwischenfinanzierung ohne Anschlussfinanzierung für Vorfinanzierung USt. einer Vorsorgewohnung bis 50.000,00€
     */
    ConstructionPrefinancingInvestmentFlatLow,

    /**
     * Zwischenfinanzierung ohne Anschlussfinanzierung für Vorfinanzierung USt. einer Vorsorgewohnung über 50.000,00€
     */
    ConstructionPrefinancingInvestmentFlatHigh,

    /**
     * Zwischenfinanzierung mit Anschlussfinanzierung Förderung
     */
    ConstructionFollowUpFinancing,

    /**
     * Zwischenfinanzierung mit Anschlussfinanzierung z.B. Bauspardarlehen
     */
    ConstructionFollowUpFinancingBuildingLoan,

    /**
     * Kreditkarte
     */
    CreditCard,

    /**
     * FW-Kredit
     */
    DevelopmentLoan,

    /**
     * Förderdarlehen
     */
    SubsidizedLoan,

    /**
     * Betrieblicher Kredit
     */
    CompanyCredit,

    /**
     * KFZ-Leasing
     */
    KfzLeasing,
}
