import { Component, computed, input } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente zur Eingabe eines Ja/Nein-Feldes
 */
@Component({
    selector: 'finprocess-boolean-input',
    templateUrl: './boolean-input.component.html',
    styleUrls: ['./boolean-input.component.scss'],
})
export class BooleanInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Optional label for the true value
     */
    public trueLabel = input<string>();

    /**
     * Optional label for the false value
     */
    public falseLabel = input<string>();

    /**
     * Translated label for the tooltip for the original value
     */
    public translatedOriginalValueKey = computed(() => {
        const originalValue = this.originalValue();

        if (originalValue === undefined || originalValue === null) {
            return 'financing.features.financing-processing.unset';
        } else if (originalValue === true) {
            return this.trueLabel() ?? 'financing.features.financing-processing.true';
        } else if (originalValue === false) {
            return this.falseLabel() ?? 'financing.features.financing-processing.false';
        }

        return originalValue.toString();

    });

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.Bool, financingService);
    }

    /**
     * Prüft, ob ein Radio zurückgesetzt werden kann und speichert dies ggf.
     *
     * @param {MouseEvent} event Klick-Event
     * @param {MatRadioButton} matRadio Radio-Button
     */
    public checkReset(event: MouseEvent, matRadio: MatRadioButton): void {
        if (!matRadio.disabled && matRadio.checked && this.originalValue() === undefined) {
            event.preventDefault();
            matRadio.checked = false;

            super.save(undefined);
        }
    }
}
