export enum TypeAddIncome {
    /**
     * Karenzgeld
     */
    KARE,

    /**
     * Nachhaltige Überstunden 100%
     */
    UEST,

    /**
     * Nachhaltige Provisionen 50%
     */
    PROV,

    /**
     * Alimente
     */
    ALIM,

    /**
     * Nachhaltige Zulagen
     */
    ZULA,

    /**
     * Sonst. nachweisbare nachhaltige Einkünfte
     */
    OTHE,
}
