import { Inject, Injectable, InjectionToken } from '@angular/core';

import * as enums from '../../../core';
import { EnumMissingTranslationHandler } from '../../classes';
import { IEnumMissingTranslationHandlerParams } from '../../interfaces';
import { ENUMS_USE_DEFAULT_LANG } from '../enum-translation/enum-translation.service';

/**
 * Benutzerdefinierte zu übersetzende Enums
 */
export const CUSTOM_ENUMS = new InjectionToken<Record<string, unknown>>('CUSTOM_ENUMS');

/**
 * Service zum Behandeln von fehlenden Übersetzungen
 */
@Injectable()
export class MissingEnumTranslationService implements EnumMissingTranslationHandler {
    /**
     * Konstruktor
     *
     * @param {boolean} useDefaultLang ENUMS_USE_DEFAULT_LANG-Injektor
     * @param {Record<string, any>} customEnums CUSTOM_ENUMS-Injektor
     */
    public constructor(@Inject(ENUMS_USE_DEFAULT_LANG) private useDefaultLang: boolean = true, @Inject(CUSTOM_ENUMS) private customEnums: Record<string, unknown> = {}) {

    }

    /**
     * Behandelt eine fehlende Übersetzung
     *
     * @param {IEnumMissingTranslationHandlerParams} params Parameter
     * @returns {string} Übsetzung oder Schlüssel, wenn keine Übersetzung gefunden
     */
    public handle(params: IEnumMissingTranslationHandlerParams): string {
        const newKey = this.getOtherKey(params.key);
        let result;
        if (newKey !== result) {
            if (!!(params.translateService.translations as Record<string,unknown> | undefined | null) && !!(params.translateService.translations[params.translateService.currentLang] as Record<string,unknown> | undefined | null)) {
                result = params.translateService.parser.interpolate(params.translateService.parser.getValue(params.translateService.translations[params.translateService.currentLang], newKey), params.interpolateParams);
            }
            if (typeof result === 'undefined' && (params.translateService.defaultLang as string | null) !== null && params.translateService.defaultLang !== params.translateService.currentLang && this.useDefaultLang) {
                result = params.translateService.parser.interpolate(params.translateService.parser.getValue(params.translateService.translations[params.translateService.defaultLang], newKey), params.interpolateParams);
            }
        }

        return typeof result !== 'undefined' ? result : params.key;
    }

    /**
     * Liefert den Übersetzungsschlüssel mit dem gegenteiligem Datentyp des Enum-Wertes zurück
     *
     * @param {string} key Übersetzungschlüssel zu dem keine Übersetzung gefunden wurde
     * @returns {string} Übersetzungsschlüssel (Enum-Name -> Number, Number -> Enum-Name)
     */
    private getOtherKey(key: string) {
        if (!key.includes('enumLabels')) {
            const parts = key.split('.');
            const enumKey = parts[parts.length - 1].toString();
            const enumType = parts[parts.length - 2];
            const mergedEnumObject = Object.assign({...enums}, this.customEnums);
            let enumOb : Record<string, unknown> = Object.assign({}, mergedEnumObject[enumType]);

            if (Object.prototype.hasOwnProperty.call(mergedEnumObject, `Baf${enumType}`) as boolean) {
                enumOb = Object.assign(enumOb, mergedEnumObject[`Baf${enumType}`]);
            }

            if (Object.prototype.hasOwnProperty.call(mergedEnumObject, `Homestory${enumType}`) as boolean) {
                enumOb = Object.assign(enumOb, mergedEnumObject[`Homestory${enumType}`]);
            }

            if (Object.prototype.hasOwnProperty.call(mergedEnumObject, `FinAdvisory${enumType}`) as boolean) {
                enumOb = Object.assign(enumOb, mergedEnumObject[`FinAdvisory${enumType}`]);
            }

            if (Object.prototype.hasOwnProperty.call(enumOb, enumKey) as boolean) {
                parts[parts.length - 1] = enumOb[enumKey] as string;
                return parts.join('.');
            }
            return key;
        }
        return key;
    }
}
