/**
 * @copyright NT.AG 2021
 */

/**
 * Klasse für Aktion
 */
export class SmartDocClosed {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'finprocess.smartDocClosed';
    /**
     * Constructor
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor () { }
}
