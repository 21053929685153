import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_PHONE_INFO_IN_V2: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerPhoneInfoInV2',

    callFrom: { type: WorkflowType.Number },

    callTo: { type: WorkflowType.Number },

    currentInternationalPhoneCode: { type: WorkflowType.String },

    currentNationalPhoneCode: { type: WorkflowType.String },

    currentPhoneNumber: { type: WorkflowType.String },

    flagA: { type: WorkflowType.String },

    forcedData: { type: WorkflowType.String },

    internationalPhoneCode: { type: WorkflowType.String },

    nationalPhoneCode: { type: WorkflowType.String },

    notes: { type: WorkflowType.String },

    pager: { type: WorkflowType.String },

    phoneCertificate: { type: WorkflowType.String },

    phoneDisplaySequence: { type: WorkflowType.Number },

    phoneNumber: { type: WorkflowType.String },

    phoneServiceProviderCode: { type: WorkflowType.String },

    phoneStatus: { type: WorkflowType.String },

    phoneType: { type: WorkflowType.String },

    utilizationDate: { type: WorkflowType.Number },

    utilizationFlag: { type: WorkflowType.String },

    utilizationMode: { type: WorkflowType.String },
}
