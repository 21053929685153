import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard, LockedGuard } from './../checks';
import { LockedComponent, LoginComponent, LogoutComponent, SamlComponent } from './components';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo:'login',
    },
    {
        path: 'login',
        component: LoginComponent,
        canDeactivate: [AuthenticationGuard],
    },
    {
        path: 'locked',
        component: LockedComponent,
        canActivate: [LockedGuard],
        canDeactivate: [LockedGuard],
    },
    {
        path: 'signin-saml',
        component: SamlComponent,
        canDeactivate: [AuthenticationGuard],
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
];

/**
 * Modul für Routen zur Login- und Sperrseite
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthLoginRoutingModule {

}
