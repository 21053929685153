import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, mergeMap, of } from 'rxjs';

import { HttpMethod } from '../enums';
import { IMidtermModel } from '../interfaces';

/**
 * Service zum Aufrufen der Testcontroller
 */
@Injectable()
export class MidtermService {

    /**
     * Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Aufruf einer generischen API Methode
     *
     * @param {any} request Request
     * @param {string} apiPath API Pfad
     * @param {HttpMethod} method HTTP Methode
     * @param {Record} optionalParams optional Params
     * @returns {Observable} Antwort vom Server
     */
    public callApi<T extends IMidtermModel | Record<string, string | number | boolean>, R>(request: T, apiPath: string, method: HttpMethod, optionalParams?: Record<string, string | number | boolean>): Observable<R | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.makeRequest<T, R>(request, apiPath, method, optionalParams),
                of(undefined),
            )),
        );
    }

    /**
     * Interner Aufruf je nach Http Methode
     *
     * @param {any} request Request
     * @param {string} apiPath API Pfad
     * @param {HttpMethod} method HTTP Methode
     * @param {Record} optionalParams optional params
     * @returns {Observable} Antwort vom Server
     */
    private makeRequest<T extends IMidtermModel | Record<string, string | number | boolean>, R>(request: T, apiPath: string, method: HttpMethod, optionalParams?: Record<string, string | number | boolean>): Observable<R> {
        switch (method) {
            case HttpMethod.Get:
                return this.httpClient.get<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, { params: request as Record<string, string | number | boolean> });
            case HttpMethod.Post:

                // Check if optionalParams are provided, if yes, put them into the request
                if (optionalParams) {
                    return this.httpClient.post<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, request, {params: optionalParams});
                }
                else {
                    return this.httpClient.post<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, request);
                }

            //  return this.httpClient.post<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, request);
            case HttpMethod.Put:
                return this.httpClient.put<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, request);
            case HttpMethod.Delete:
                return this.httpClient.delete<R>(`${this.config.getEnvironment().apiUrl}/${apiPath}`, { params: request as Record<string, string | number | boolean> });
            default:
                throw new Error('Unknown http method');
        }
    }
}
