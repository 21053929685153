import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IFinancing, IFinancingStateParentDefinition, IRFPData } from 'app/modules/financing/data';
import { IScoringGWB } from 'app/modules/financing/data/interfaces';
import { FinancingService, ScoringService } from 'app/modules/financing/data/services';
import { IMasterdataParentDefinition } from 'app/modules/masterdata/data';
import { FinancingPlanStatus } from 'app/modules/shared';
import { HelperService, UUID } from 'app/modules/shared/util';
import { BehaviorSubject, Observable, Subject, combineLatest, iif, map, mergeMap, of, take, takeUntil, tap } from 'rxjs';

interface IScroingForm {
    financingPlanId: FormControl<UUID | null>;
    productRating: FormControl<number | null>;
    zone: FormControl<string | null>;
}

/**
 * Komponente für das Scoring
 */
@Component({
    selector: 'finprocess-scoring',
    templateUrl: './scoring.component.html',
    styleUrls: ['./scoring.component.scss'],
})
export class ScoringComponent implements OnInit, OnDestroy {

    /**
     * Observer für Verfügbarkeit von DGA
     */
    @Select((it: IMasterdataParentDefinition) => it.systemConfiguration.externalSystemsAvailability.scoringIsAvailable)
    public isScoringAvailable$!: Observable<boolean>;

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    public isScoringAvailable = false;
    public financingPlanStatuses: FinancingPlanStatus[] = [];
    public financingPlanId: string | undefined;
    public scoring: IScoringGWB | undefined;
    public form: FormGroup | undefined;

    public isReadonly$: Observable<boolean>

    public riskFinancing: IRFPData | undefined;

    public riskFinancingID$ = new BehaviorSubject<string | undefined>(undefined);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingPlanStatus: FinancingPlanStatus[] = [];

    public test: FinancingPlanStatus | undefined

    public zones = [
        { value: 1, viewValue: '1: Top' },
        { value: 2, viewValue: '2: Grün' },
        { value: 3, viewValue: '3: Gelb' },
        { value: 4, viewValue: '4: Rot' },
        { value: 5, viewValue: '5: Schwarz' },
    ];

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Standard konstruktor
     *
     * private fb: FormBuilder,
     *
     * @param { ScoringService } scoringService scoringService Injektor
     * @param { ActivatedRoute }activatedRoute activatedRoute Injektor
     * @param  { FormBuilder }fb FormBuilder Injektor
     * @param { WaiterService } waiterService WaiterService-Injektor
     * @param { NotificationService } notification NotificationService-Injektor
     * @param { TranslateService } translate TranslateService-Injektor
     * @param {FinancingService} financingService FinancingService
     * @param {Store} store Store Injector
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private scoringService: ScoringService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        private financingService: FinancingService,
        private store: Store,
        private authorizationService: AuthorizationService,
    ) {
        this.form = this.fb.group<IScroingForm>({
            financingPlanId: this.fb.control(null),
            productRating: this.fb.control(null, Validators.required),
            zone: this.fb.control(null, Validators.required),
        });

        this.isReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.waiterService.show();

        this.financingPlanStatuses = (HelperService.getEnumArray(FinancingPlanStatus) as number[]);

        combineLatest([
            this.isScoringAvailable$,
            this.activatedRoute.paramMap,
        ]).pipe(
            take(1),
            tap(([available, params]) => {
                this.isScoringAvailable = available,
                this.financingPlanId = params.get('riskfinancingplanid') as UUID;
                this.riskFinancingID$.next(this.financingPlanId);
            }),
            mergeMap(([available, params]) => iif(
                () => available,
                this.scoringService.loadExistingScoring(params.get('riskfinancingplanid') as UUID).pipe(
                    tap(scoring => { this.scoring = scoring })),
                of(undefined),
            )),
        ).subscribe({
            next: result => {
                this.waiterService.hide();
                this.patchFormValues(result);
            },
            complete: () => { this.waiterService.hide() },
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'))
            },
        })

        combineLatest([
            this.isReadonly$,
            this.isScoringAvailable$,
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.riskFinancingPlans).pipe(map(it => it.find(plan => plan.id === this.riskFinancingID$.value))),
            this.riskFinancingID$,
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(([isReadonly, isScoringAvailable, riskFinancing ]) => {
            this.riskFinancing = riskFinancing;
            if (((isReadonly === false && (!isScoringAvailable || riskFinancing?.status === FinancingPlanStatus.ScoringError)) || (isReadonly === false && this.authorizationService.hasOneRoleOf(Role.ScoringAdministrator))))
            {
                this.form?.enable();
            }
            else {
                this.form?.disable();
            }
        });
    }


    /**
     * Manuele Scoring senden
     */
    public sendScoring(): void {
        this.waiterService.show();
        if (!!this.form && this.form.valid) {
            const data = this.form.getRawValue() as IScoringGWB;
            data.financingPlanId = this.financingPlanId;

            this.scoringService.saveManualScoring(data).pipe(
                take(1),
                mergeMap(() => this.financingService.getRiskFinancingPlanStatusEntries(this.financingPlanId as string)),
            ).subscribe({
                next: () => {
                    this.waiterService.hide();
                    this.notification.toast(this.translate.instant('financing.features.financing-processing.scoringGwb.scoringSended'));
                    this.form?.disable();
                },
                complete: () => { this.waiterService.hide() },
                error: (err: HttpErrorResponse) => {
                    if (err.status) {
                        this.waiterService.hide();
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                    }
                },
            })
        }
    }

    /**
     * Patch Werte
     *
     * @param {IScoringGWB} scoring IScoringGWB
     */
    private patchFormValues(scoring?: IScoringGWB): void {
        this.form?.controls.productRating?.patchValue(scoring?.productRating);
        this.form?.controls.zone?.patchValue(scoring?.zone);
    }

    /**
     * Neue Scoring abfragen
     */
    public getNewScoring(): void {
        this.waiterService.show();
        if (!!this.financingPlanId) {
            this.scoringService.calculateNewScoring(this.financingPlanId).pipe(
                take(1),
            ).subscribe({
                next: newScoring => {
                    this.waiterService.hide();
                    this.form?.disable();
                    this.patchFormValues(newScoring)
                },
                complete: () => {
                    this.waiterService.hide();
                    this.financingService.getRiskFinancingPlanStatusEntries(this.financingPlanId as string).pipe(take(1)).subscribe();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            })
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
