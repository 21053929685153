import { EnumSettings } from './../enum.settings';

/**
 * Kreditprodukt
 */
export enum CreditType {
    /**
     * Kredit
     */
    Inital = 0,
    /**
     * KomfortKredit
     */
    ComfortCredit = 1,

    /**
     * KomfortKredit Plus
     */
    ComfortCreditPlus = 2,
}

export namespace CreditType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {CreditType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: CreditType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'CreditType', CreditType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('CreditType', plocale);
}
