/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Logout } from 'auth';

import { EditModeChanged, FinancingLeaved, TabChanged } from './financing-tab.actions';


export interface IFinancingTabStateModel {
    currentTab?: number;
    editMode: boolean;
}

const defaultData: IFinancingTabStateModel = {
    editMode: false,
};

/**
 * Zustand für Tabs der Finanzierung
 */
@State<IFinancingTabStateModel>({
    name: FinancingTabState.keyName,
    defaults: defaultData,
})
@Injectable()
export class FinancingTabState {
    public static readonly keyName = 'financingTabs';

    /**
     * Zustandsänderung nach Wechsel des Tabs
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {TabChanged} action Aktion
     */
    @Action(TabChanged)
    public tabChanged({ patchState }: StateContext<IFinancingTabStateModel>, { payload }: TabChanged): void {
        patchState({
            currentTab: payload,
        });
    }

    /**
     * Zustandsänderung nach Änderung des Bearbeitungsmodus
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {EditModeChanged} action Aktion
     */
    @Action(EditModeChanged)
    public editModeChanged({ patchState }: StateContext<IFinancingTabStateModel>, { payload }: EditModeChanged): void {
        patchState({
            editMode: payload,
        });
    }

    /**
     * Zustandsänderung beim Verlassen der Finanzierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public financingLeaved({ setState }: StateContext<IFinancingTabStateModel>): void {
        setState(defaultData);
    }
}
