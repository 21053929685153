/**
 * [MEHRFACHAUSWAHL] Ausstattungsmerkmale Außenanlagen
 */
export enum OutsideFacilities {

    /**
     * Garten nicht einsehbar
     */
    GardenNotVisible = 32,

    /**
     * Künstliche Geländegestaltung / Stützmauern
     */
    RetainingWalls = 64,

    /**
     * Gartenbewässerung (voll automatisch)
     */
    GardenWatering = 128,
}
