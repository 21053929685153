/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IDocument } from '../../interfaces';

import { DocumentsLoaded } from './document.actions';

export interface IDocumentStateModel {
    documents: IDocument[];
}

const defaultData: IDocumentStateModel = {
    documents: [],
};

/**
 * Zustand der Finanzierung
 */
@State<IDocumentStateModel>({
    name: DocumentState.keyName,
    defaults: defaultData,
})
@Injectable()
export class DocumentState {

    public static readonly keyName = 'document';


    /**
     * Selektor für alle Dokumente
     *
     * @param {IDocumentStateModel} state aktueller State Kontext
     * @returns {IDocument[]} Liste aller Dokumente
     */
    @Selector()
    public static allDocuments(state: IDocumentStateModel): IDocument[] {
        return state.documents;
    }

    /**
     * Zustandsänderung beim Laden der Dokumente
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {DocumentsLoaded} action Aktion
     */
    @Action(DocumentsLoaded)
    public documentsLoaded({ patchState }: StateContext<IDocumentStateModel>, { payload }: DocumentsLoaded): void {
        patchState({
            documents: payload,
        });
    }
}
