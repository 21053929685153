<ng-template #labelValueTemplate let-fieldName="fieldName" let-value="value">
    <finprocess-label-value>
        <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
        <span value>{{ value !== null && value !== undefined && value !== '' ? value : '-' }}</span>
    </finprocess-label-value>
</ng-template>


<ng-template #integerInputTemplate let-validate="validate" let-label="label" let-entityClassType="entityClassType" let-entity="entity" let-fieldName="fieldName" let-editable="editable" let-value="value">
  @if((fieldReadonly$ | async) === false) {
      <finprocess-integer-input
          [overwrite]="false"
          [entityClassType]="entityClassType"
          [fieldName]="fieldName"
          [flexDirection]="inputFlexDirection"
          [hideHorizontalRule]="true"
          [readonly]="editable ? fieldReadonly$ : alwaysReadonly$"
          [entity]="entity"
          [transparent]="true"
          [validators]="validate ? ((entity?.sharesDenominator !== undefined && entity?.sharesDenominator !== null) ? [Validators.required, Validators.min(1)] : Validators.min(1)) : Validators.min(1)" 
          [allowNegativeValues]="false">
          <span label class="input-label">{{ label | translate }}</span>
      </finprocess-integer-input>
  } @else {
      <finprocess-label-value>
          <span label>{{ label | translate }}</span>
          <span value>{{ (value !== undefined && value !== null) ? value : '-' }}</span>
      </finprocess-label-value>
  }
</ng-template>

<ucba-heading [type]="headingType">
    <div ucba-heading-title>{{ 'asset.assetCommon.assetProvider' | translate }}</div>
    <div class="add-btn" ucba-heading-actions *ngIf="(fieldReadonly$ | async) !== true" (click)="addAssetProvider()">
        <span class="mr-1">{{ 'asset.assetProvider.addAssetProvider' | translate }}</span>
        <mat-icon style="font-size: 20px">add_circle</mat-icon>
    </div>
    <div ucba-heading-body-visible>
        @for (provider of assetProviders; track $index) {
        <div [class.tile-margin]="$index !== 0" [class.darker-tiles]="$index % 2 !== 0">
            <ucba-tile-layout>
                <!-- NDG -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'ndg', value: provider.ndg | formatNdg }"></ng-container>
                </ng-template>

                <!-- Nachname -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'lastName', value: provider.lastName }"></ng-container>
                </ng-template>

                <!-- Vorname -->
                <ng-template ucba-tile>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'firstName', value: provider.firstName }"></ng-container>
                    </div>
                </ng-template>

                <ng-template ucba-tile>
                  <div fxLayout="row">
                    <span class="input-padding-right" fxFlex="50">
                      <!-- Anteile Zähler -->
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: { validate: true, label: 'Anteile Zähler', fieldName: 'sharesCounter', editable: true, entityClassType: entityClassTypeAssetProvider, entity: provider, value: provider.sharesCounter }"></ng-container>
                    </span>
                    
                    <!-- Anteile Nenner -->
                    <span class="input-padding-left" fxFlex="50">
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: {  label: 'Anteile Nenner', fieldName: 'sharesDenominator', editable: true, entityClassType: entityClassTypeAssetProvider, entity: provider, value: provider.sharesDenominator }"></ng-container>
                      </span>
                    </div>
                </ng-template>

                <ng-template>
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <button
                        *ngIf="(fieldReadonly$ | async) !== true"
                        (click)="showProviderInfo(provider)"
                        [matTooltip]="'asset.assetProvider.assetProviderInfos' | translate"
                        mat-icon-button>
                        <mat-icon>person_search</mat-icon>
                    </button>
                    <button
                        *ngIf="(fieldReadonly$ | async) !== true"
                        class="delete-btn"
                        (click)="deleteAssetProvider(provider.id)"
                        [matTooltip]="'asset.assetProvider.delete' | translate"
                        mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
              </ng-template>

            </ucba-tile-layout>
        </div>
        } @empty {
        <ucba-tile-layout>
            <ng-template ucba-tile [span]="5">
                {{ 'asset.assetProvider.noAssetProviders' | translate }}
            </ng-template>
        </ucba-tile-layout>
        }
    </div>
</ucba-heading>
