/**
 * Art eines Joint Headings
 */
export enum JointHeadingType {
    /**
     * Manuell angelegtes Joint Heading für die Finanzierungsanfrage
     */
    New,

    /**
     * Von AGP abgerufenes Joint Heading
     */
    AGP,

    /**
     * Von KSV abgerufenes Joint Heading
     */
    KSV,

    /**
     * Bei der Einreichung automatisch erstellt zum Datenmapping
     */
    AutoCreated,
}

