import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FinancingMode, IFinancingStateParentDefinition, IStepperStep } from 'app/modules/financing/data';
import { FinancingPlanStatus } from 'app/modules/shared';
import { Subject, takeUntil } from 'rxjs';

/**
 * Riskfinancingplan Stepper
 */
@Component({
    selector: 'finprocess-risk-financing-plan',
    templateUrl: './risk-financing-plan.component.html',
    styleUrls: ['./risk-financing-plan.component.scss'],
})
export class RiskFinancingPlanComponent implements OnInit, OnDestroy {

    /**
     * Step für Risikofinanzierungsplan
     */
    public financingStep: IStepperStep = {
        route: 'financingComponent',
        label: 'Finanzierungsplan',
        queryParams: { mode: FinancingMode.RiskFinancingPlan },
    };

    /**
     * Step für Produktpakete
     */
    public productPackageStep: IStepperStep = {
        route: 'product-package',
        label: 'Produktpakete',
        queryParams: { mode: FinancingMode.RiskFinancingPlan },
    };

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Ladezustand der Komponente
     */
    public loading = false;

    /**
     * Standard Konstruktor
     * 
     * @param {Store} store Store-Injector
     */
    public constructor(private store: Store) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing?.status).pipe(takeUntil(this.onDestroy$)).subscribe(status => {
            if (!!status) {
                switch (status) {
                    case FinancingPlanStatus.ScoringError:
                        this.financingStep.status = 'warn';
                        this.productPackageStep.disabled = true;
                        break;
                    case FinancingPlanStatus.ScoringFailed:
                        this.financingStep.status = 'error';
                        this.productPackageStep.disabled = true;
                        break;
                    case FinancingPlanStatus.ScoringSuccesful:
                        this.financingStep.status = 'success';
                        this.productPackageStep.disabled = false;
                        break;
                    case FinancingPlanStatus.Editing:
                    default:
                        this.financingStep.status = 'pending';
                        this.productPackageStep.disabled = false;
                        break;
                }
            }
        });
    }


    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
