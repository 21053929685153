export enum ApprovalDecision {
    /**
     * Darstellbar
     */
    Approved,
    
    /**
     * Nicht Darstellbar
     */
    NotApproved,

    /**
     * Offene Fragen
     */
    OpenQuestions,
}
