import { IRiskParametrizationResponse, ISystemConfigurationValue } from './../../interfaces';

/**
 * Aktion beim erfolgreichen Laden der Risikoparametrisierung
 */
export class RiskParametrizationLoaded {
    public static readonly type = 'riskParametrization.loaded';

    /**
     * Konstruktor
     *
     * @param {IRiskParametrizationResponse} payload Systemkonfiguration
     */
    public constructor(public payload: IRiskParametrizationResponse) { }
}

/**
 * Aktion beim Ändern der Risikoparametrisierung
 */
export class RiskParametrizationChanged {
    public static readonly type = 'riskParametrization.changed';

    /**
     * Konstruktor
     *
     * @param {IRiskParametrizationResponse} payload Risiko Parametrisierungs State
     */
    public constructor(public payload: IRiskParametrizationResponse) { }
}

/**
 * Aktion beim Laden eines einzelnen Systemkonfigurationswertes
 */
export class SystemConfigurationLoaded {
    public static readonly type = 'riskParametrization.systemConfigLoaded';

    /**
     * Konstruktor
     *
     * @param {ISystemConfigurationValue[]} payload Risiko Parametrisierungs State
     */
    public constructor(public payload: ISystemConfigurationValue[]) { }
}
