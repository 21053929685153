export enum ApprovalAuthoritiesFile {
    /**
     * Datenimport für Nutzer zu Kompetenzrollen
     */
    UserApprovalLevel = 0,

    /*
    * Kompetenzmatrix
    */
    ApprovalMatrix = 1,
}
