export enum RepaymentDetailsType {

    /**
     * Lastschrift
     */
    DirectDebit = 0,

    /**
     * Zahlschein
     */
    PaymentSlip = 1,
}
