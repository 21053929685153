import { IEnumMissingTranslationHandlerParams } from '../interfaces';

/**
 * Abstrakte Klassen zum Implementieren eines Handler bei fehlenden Enum-Übersetzungen
 */
export abstract class EnumMissingTranslationHandler {
    /**
     * Eine Funktion die fehlende Übersetzungen behandelt
     */
    public abstract handle(params: IEnumMissingTranslationHandlerParams): unknown;
}
