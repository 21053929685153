import { EnumSettings } from './../enum.settings';

/**
 * Vergleichstyp
 */
export enum ComparisonType {
    /**
     * Größer als
     */
    GreaterThan = 0,

    /**
     * Größer oder gleich
     */
    GreaterThanEqual = 1,

    /**
     * Kleiner als
     */
    LessThan = 2,

    /**
     * Kleiner oder gleich
     */
    LessThanEqual = 3,
}

export namespace ComparisonType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {ComparisonType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: ComparisonType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'ComparisonType', ComparisonType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('ComparisonType', plocale);
}
