import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductPackageType } from '@ntag-ef/finprocess-enums';
import { IProductPackage, IProductPackageData } from 'app/modules/financing/data';
import { HelperService } from 'app/modules/shared';

/**
 * add new product package
 */
@Component({
    selector: 'finprocess-add-product-package-dialog',
    templateUrl: './add-product-package-dialog.component.html',
    styleUrls: ['./add-product-package-dialog.component.scss'],
})
export class AddProductPackageDialogComponent implements OnInit {

    public selectedValue?: string;

    public productPackageType: ProductPackageType[] = [];

    public longTermFinancing = false;
    public shortTermFinancing = false;
    public bridgingFinancing = false;
    public guaranteeAmount = false;

    /**
     * disable Guarantee if it already exists in ProductPackage Tab
     */
    public guaranteeExists = false;

    public isProductPackage!: boolean;


    /**
     * Kontruktor
     *
     * @param {MatDialogRef} dialogRef dialogref
     * @param {number} data data
     * @param {number} data.grossFinancingRequirement langfristiger finanzierungsbedarf
     * @param {number} data.bridgingFinancing zischenfinanzierung
     * @param {number} data.comfortCreditPlus kurzfristiger Finanzierungsbedarf
     * @param {number} data.guaranteeAmount Garantiebetrag  
     * @param {boolean} data.isProductPackage ist es um ein Productpaket  
     * @param {IProductPackageData} data.existingProductPackages existing product packages
     * @param {string} data.productPackageID product package id
     */
    public constructor(private dialogRef: MatDialogRef<AddProductPackageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { 
            grossFinancingRequirement: number, 
            comfortCreditPlus: number, 
            bridgingFinancing: number, 
            existingProductPackages?: IProductPackage[], 
            productPackageID?: string, 
            guaranteeAmount: number,
            isProductPackage: boolean,
        }) {

        this.isProductPackage = data.isProductPackage;
        
        // Check if a product with Guarantee credit type exists in selected productpackage
        if (!!data.existingProductPackages && !!data.productPackageID) {

            const productPackage = data.existingProductPackages.find(pp => pp.productPackageID === data.productPackageID);

            if (!!productPackage && Array.isArray(productPackage.assignProducts)) {
                this.guaranteeExists = productPackage.assignProducts.some(product => product.productCreditType === ProductPackageType.Guarantee);
            }
        }

        if (data.grossFinancingRequirement === 0 || data.grossFinancingRequirement === undefined) {
            this.longTermFinancing = true;
        }

        if (data.comfortCreditPlus === 0 || data.comfortCreditPlus === undefined) {
            this.shortTermFinancing = true;
        }

        if (data.bridgingFinancing === 0 || data.bridgingFinancing === undefined) {
            this.bridgingFinancing = true;
        }

        if (data.guaranteeAmount === 0 || data.guaranteeAmount === undefined) {
            this.guaranteeAmount = true;
        }
    }


    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.productPackageType = (HelperService.getEnumArray(ProductPackageType) as number[]);
    }

    /**
     * confirm selection
     */
    public confirmSelection() {
        if (this.selectedValue !== undefined) {
            this.dialogRef.close({ selection: this.selectedValue });
        }
    }

    /**
     * check if dropdown option needs to be disabled based on financingScope Values
     *
     * @param {ProductPackageType} type ProductPackageType
     * @returns {boolean} true or false
     */
    public isOptionDisabled(type: ProductPackageType): boolean {
        if (type === ProductPackageType.Credit && this.longTermFinancing) {
            return true;
        }
        if (type === ProductPackageType.ComfortCredit && this.shortTermFinancing) {
            return true;
        }
        if ((type === ProductPackageType.BWWithFollowUpFinancing || type === ProductPackageType.BWWithoutFollowUpFinancing) && this.bridgingFinancing) {
            return true;
        }
        if (type === ProductPackageType.Guarantee && this.guaranteeAmount) {
            return true;
        }
        if (type === ProductPackageType.Guarantee && this.guaranteeExists === true) {
            return true;
        }
        return false;
    }
}
