<div [ngClass]="class" class="w-100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <div fxLayout="column">
            <label [for]="id">{{label}}</label>
            <small *ngIf="!!hint"><i>{{hint}}</i></small>
        </div>
        <mat-icon class="ml-1" *ngIf="(numberObservable | async) !== (numberOriginalObservable | async)" [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: ((numberOriginalObservable | async) | number:format:locale) }">info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" [fxLayoutAlign]="fxValueLayoutAlign" fxLayoutAlign.lt-sm="start center">
        <span class="praefix mr-1" *ngIf="!!praefix">{{praefix}}</span><span class="value" [id]="id">{{(numberObservable | async) | number:format:locale}}</span><span class="suffix ml-1" *ngIf="!!suffix">{{suffix}}</span>
    </div>
    <hr class="custom-hr">
</div>
