import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SmartDocService } from '../../../../data';
import { ISmartDocStateModel } from '../../../../data/states/smartdoc/smartdoc.state';
import { ISmartDocStateParentDefinition } from '../../../../data/states/state.definiton';


/**
 * Komponente für das SmartDoc
 */
@Component({
    selector: 'finprocess-smartdoc',
    templateUrl: './smartdoc.component.html',
    styleUrls: ['./smartdoc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartdocComponent implements OnInit, OnDestroy {
    /**
     * aktueller Prozesszustand der SmartDoc-Bearbeitung
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc)
    public state$!: Observable<ISmartDocStateModel>;
    /**
     * menu is collapsed?
     */
    public isCollapsed = true;

    /**
     * Constructor
     *
     * @param {SmartDocService} smartdocService SmartDocService
     * @param {Router} router Router
     */
    public constructor (
        private smartdocService: SmartDocService,
        private router: Router,
    ) {

    }


    /**
     * Angular-Hook beim Entfernen der Komponente
     */
    public ngOnInit(): void {
        this;
        void this.router.navigate(['/smartdoc/smartdocSelection']);
        //throw new Error('Method not implemented.');
    }

    /**
     * Angular-Hook beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this;
        //throw new Error('Method not implemented.');
    }

    /**
     * Klappfunktionalität des linken Navigationsmenüs
     */
    public menuExpander(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    /**
     * Schließt Smartdoc und routet zur Finanzierung
     *
     */
    public closeSmartdoc(): void {
        this.smartdocService.close();
    }
}
