import { WorkflowType } from '../../enums';
import { CreditClassType, FormattingType, OptionalBoolenType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const EXTERNAL_REMAIN_BANK_LOAN: IMidtermModel = {

    midtermName: 'externalRemainBankLoan',

    /**
     * Kreditart
     * Lieferung entweder "nicht-revolvierend" oder "revolvierend"
     * fbk_kreditart_1 bis fbk_kreditart_10
     */
    creditType: { type: WorkflowType.Enum, enumObject: CreditClassType, enumLabel: 'CreditClassType' },

    /**
     * Aushaftung
     * fbk_aushaftung_1 bis fbk_aushaftung_10
     */
    liability: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * fiktive Rate wenn "nicht-revolvierend"
     * fbk_rate_fiktiv_1 bis fbk_rate_fiktiv_10
     */
    rateFictional: { type: WorkflowType.Number, formattingType: FormattingType.Currency},

    /**
     * Rate gem. KIM-V
     * fbk_rate_echt_1 bis fbk_rate_echt_10
     */
    rateReal: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Anzahl Kreditbeteiligte beim Fremdbankkredit
     * fbk_kreditnehmer_anzahl_1 bis fbk_kreditnehmer_anzahl_10
     */
    debitorsCount: { type: WorkflowType.Number },

    /**
     * Anzahl Kreditbeteiligte in diesem BA-Antrag
     * fbk_kreditnehmer_anzahl_antrag_1 bis fbk_kreditnehmer_anzahl_antrag_10
     */
    debitorsCountApplication: { type: WorkflowType.Number },

    /**
     * Finanziert Liegenschaft in diesem BA-Antrag?
     * fbk_finanziert_liegenschaft_1 bis fbk_finanziert_liegenschaft_10
     */
    financesRealEstate: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Finanziert eine Wohnimmobilie des Kreditnehmers
     * fbk_finanziert_woib
     */
    financesResidentialRealEstate: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },
}
