import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { CountryChanged, ICountry } from 'app/modules/masterdata/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * Service für Länderlisten
 */
 @Injectable()
export class CountryService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Update Land daten
     *
     * @param {Partial<ICountry>} country ICountry
     * @returns {Observable<ICountry | undefined>} Observable
     */
    public editCountry(country: ICountry): Observable <ICountry | undefined> {
        return this.authorizationService.checkPermissions$(Role.SelectListsAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/Country`, country, {
                }).pipe(
                    mergeMap(() => this.store.dispatch(new CountryChanged(country))),
                ),
                of(undefined),
            )),
        )
    }
}
