import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxLoggerLevel } from 'ngx-logger';
import { Observable, mergeMap, of } from 'rxjs';

import { ILogModel } from '../../interfaces/log-model.interface';
import { ConfigService } from '../config/config.service';

/**
 * Log Service
 */
@Injectable()
export class LogService {

    /**
     * Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     */
    public constructor (
      private httpClient: HttpClient, 
     private config: ConfigService,
    ) { }

    /**
     * Send log zum Server
     * 
     * @param {NgxLoggerLevel} logerLevel  NgxLoggerLevel
     * @param {Date} time date
     * @param {string} logMessage Messagge
     * @param {string} fileName File Name
     * @param {number} lineNumber Line Nr
     * @param {string[]} additional Extra
     * @returns {Observable} Abschlussobservable
     */
    public saveLog(logerLevel: NgxLoggerLevel, time?: Date, logMessage?: string, fileName?: string, lineNumber?: number, additional?: string[] ): Observable<void> {
        const logModel = { level: logerLevel, timestamp: time, message: logMessage, fileName: fileName, lineNumber: lineNumber, additional: additional } as ILogModel
        return this.httpClient.post(`${this.config.getEnvironment().apiUrl}/Log/`, logModel).pipe(
            mergeMap(() => of(void 0)),
        )
    }

}
