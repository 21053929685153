import { IToken } from '../../interfaces';

/**
 * Aktion bei erfolgreichem Login
 */
export class Login {
    public static readonly type = 'auth.login';

    /**
     * Konstruktor
     *
     * @param {{ token: IToken, refreshToken: IToken }} payload Payload
     * @param {IToken} payload.token Authentifizierungstoken
     * @param {boolean} payload.externalLogin Handelt es sich um einen externen Login?
     */
    public constructor(public payload: { token: IToken, externalLogin?: boolean}) { }
}

/**
 * Aktion bei erfolgreichem Logout
 */
export class Logout {
    public static readonly type = 'auth.logout';
}

/**
 * Aktion beim Ablauf des Authentifizierungstokens
 */
export class TokenExpired {
    public static readonly type = 'auth.tokenExpired';
}
