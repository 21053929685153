<h2 mat-dialog-title>
    @if (data.type === 'existing') {
        {{ 'financing.features.financing-processing.rental-income.titleExisting' | translate }}
    } @else if(data.type === 'future') {
        {{ 'financing.features.financing-processing.rental-income.titleFuture' | translate }}
    }
    @if (data.rentalIncome?.id !== undefined) {
    {{ 'financing.features.financing-processing.rental-income.titleUpdate' | translate }}
    } @else {
    {{ 'financing.features.financing-processing.rental-income.titleAdd' | translate }}
    }
</h2>
<mat-dialog-content>
    <div [formGroup]="rentalIncomeForm" fxLayout="column">
        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.objectName' | translate }}</mat-label>
            <input matInput formControlName="objectName" />
            @if (rentalIncomeForm.controls.objectName.errors) {
            <mat-error>{{ rentalIncomeForm.controls.objectName.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.grossRentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="grossRentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (rentalIncomeForm.controls.grossRentPerMonth.errors) {
            <mat-error>{{ rentalIncomeForm.controls.grossRentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.vatPerMonth' | translate }}</mat-label>
            <input matInput formControlName="vatPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (rentalIncomeForm.controls.vatPerMonth.errors) {
            <mat-error>{{ rentalIncomeForm.controls.vatPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.operatingCostsPerMonth' | translate }}</mat-label>
            <input matInput formControlName="operatingCostsPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (rentalIncomeForm.controls.operatingCostsPerMonth.errors) {
            <mat-error>{{ rentalIncomeForm.controls.operatingCostsPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.netRentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="netRentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (rentalIncomeForm.controls.netRentPerMonth.errors) {
            <mat-error>{{ rentalIncomeForm.controls.netRentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.netRent70PercentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="netRent70PercentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (rentalIncomeForm.controls.netRent70PercentPerMonth.errors) {
            <mat-error>{{ rentalIncomeForm.controls.netRent70PercentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{ 'button.cancel' | translate }}</button>
    <button
        mat-button
        [disabled]="rentalIncomeForm.invalid"
        (click)="save()">
        {{ (data.rentalIncome?.id !== undefined ? 'button.update' : 'button.save') | translate }}
    </button>
</mat-dialog-actions>
