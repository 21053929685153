<div left class="p-2">
    <div *ngFor="let lateSubmission of lateSubmissions; let i = index">
        <div fxLayout="row">
            <h4 class="titles">
                <span *ngIf="lateSubmission.event.isRead">{{'financing.features.financing-processing.documents.subsequent' | translate}} {{ lateSubmissions.length - i }}</span>
                <u *ngIf="!lateSubmission.event.isRead">{{'financing.features.financing-processing.documents.subsequent' | translate}} {{ lateSubmissions.length - i }}</u>
            </h4>
            <mat-icon class="ml-1" (click)="downloadSubsequents(lateSubmission)" *ngIf="(isEditor$ | async) ?? false">
                download
            </mat-icon>
        </div>
        <finprocess-document-list [documents]="lateSubmission.documents">
        </finprocess-document-list>
    </div>
    <div *ngIf="calculations.length > 0">
        <h4 class="titles"><b><u>{{'financing.features.financing-processing.documents.calcExample' | translate}}</u></b></h4>
        <finprocess-document-list [documents]="calculations">
        </finprocess-document-list>
    </div>
    <div *ngIf="incomeDocuments.length > 0">
        <h4 class="titles"><b><u>{{'financing.features.financing-processing.documents.incomeDocuments' | translate}}</u></b></h4>
        <finprocess-document-list [documents]="incomeDocuments">
        </finprocess-document-list>
    </div>
    <div *ngIf="submittedDocuments.length > 0">
        <h4 class="titles"><b><u>{{'financing.features.financing-processing.documents.submission' | translate}}</u></b></h4>
        <finprocess-document-list [documents]="submittedDocuments">
        </finprocess-document-list>
    </div>

    <h4 class="titles"><b><u>{{ 'smartdoc.smartdoc' | translate }}</u></b></h4>
    <div *ngIf="arrangedDocuments.length > 0 ">
        <finprocess-document-list [documents]="arrangedDocuments">
        </finprocess-document-list>
    </div>
    <div *ngIf="arrangedDocuments.length === 0 ">
        <p>{{ 'smartdoc.noDocuments' | translate }}</p>
    </div>
