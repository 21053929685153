import { Component, Input } from '@angular/core';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Eingabefeld - Ganzzahl ohne Dezimalzeichen
 */
@Component({
    selector: 'finprocess-integer-input',
    templateUrl: './integer-input.component.html',
    styleUrls: ['./integer-input.component.scss'],
})
export class IntegerInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Platzhalter
     */
    @Input()
    public placeholder!: string;

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     *
     * @returns {string} Anzeigeschlüssel
     */
    public get translatedOriginalValueKey(): string {
        if (this.originalValue === undefined || this.originalValue === null) {
            return 'financing.features.financing-processing.unset';
        }

        return this.originalValue.toString();
    }

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.Int, financingService);
    }
}
