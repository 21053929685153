<div class="p-2" fxFlex fxLayout="column">
    <div *ngIf="!loading" fxLayout="column">
        <div *ngIf="financingSumLiabilities.households.length === 0" class="empty-hint p-4">
            {{ 'financing.features.financing-processing.liabilities.liabilitySums' | translate}}
        </div>
        <div *ngFor="let household of financingSumLiabilities.households" class="p-2">
            <h2 class="color-primary">{{ 'financing.features.financing-processing.liabilities.liabilitySums.household' | translate}} {{ household.position + 1}}</h2>
            <div *ngFor="let debitor of household.debitors" class="ml-4 mt-5">
                <h3 class="debitor-title" fxLayoutAlign="start center" 
                    [class.color-primary]="debitor?.isHouseholdEconomicLeader">
                    <b>{{ debitor.name }}</b>
                    <mat-icon style="font-size: small;" color="primary" *ngIf="debitor?.isHouseholdEconomicLeader">star</mat-icon>
                </h3>
                <div *ngFor="let liability of debitor.liabilities" fxLayout="row" fxLayoutAlign="start center" class="liability-row">
                    
                    <finprocess-label-value fxFlex="20">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityType' | translate }} </span>
                        <span *ngIf="liability.type === undefined" value>{{'general.noValue' | translate}}</span>
                        <span *ngIf="liability.type !== undefined" value>{{ liability.type | finprocessEnumTranslation:'LiabilityType'}}</span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityDate' | translate }} </span>
                        <span value>{{liability.started | date: 'dd.MM.YYYY'}}</span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilitySource' | translate }} </span>
                        <span value>{{liability.liabilitySource | finprocessEnumTranslation:'JointHeadingType'}}</span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilitySum' | translate }} </span>
                        <span value>{{liability.sum | formatCurrency}}</span>
                    </finprocess-label-value>

                    <finprocess-label-value [fxFlex]="hasCreditLimit(liability) ? 8 : 15">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityDuration' | translate }} </span>
                        <span value>{{liability.durationInMonths}} {{ 'financing.features.financing-processing.liabilities.liabilityOverview.months' | translate }}</span>
                    </finprocess-label-value>

                    <finprocess-label-value *ngIf="hasCreditLimit(liability)" fxFlex="7">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.creditLimit' | translate }} </span>
                        <span value>{{liability.creditLimit | formatCurrency}} </span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10" [class.color-warning]="liability.monthlyRate === 0 || liability.monthlyRate === undefined">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityMonthlyRate' | translate }} </span>
                        <span value>{{liability.monthlyRate | formatCurrency}}</span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityFictionalRate' | translate }} </span>
                        <span value>{{liability.fictionalRate}}%</span>
                    </finprocess-label-value>

                    <finprocess-label-value fxFlex="10">
                        <span label>{{'financing.features.financing-processing.liabilities.liabilitySums.liabilityFictionalRateAmount' | translate }} </span>
                        <span value>{{liability.fictionalRateAmount | formatCurrency}}</span>
                    </finprocess-label-value>

                    <div fxFlex="5" fxLayout="row" fxLayoutAlign="end center"><mat-icon color="accent" *ngIf="liability.isJointHeadingsWithMultipleDebitors" matTooltip="mehrere Kreditnehmer">link</mat-icon></div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="sum-debitor">
                    <finprocess-label-value fxFlex="20">
                        <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sum' | translate }}:</b></span>
                        <span value><b>{{debitor.sum | formatCurrency}}</b></span>
                    </finprocess-label-value>
                    <finprocess-label-value fxFlex="20">
                        <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumFictionalRates' | translate }}</b></span>
                        <span value><b>{{debitor.sumFictionalRates | formatCurrency}}</b></span>
                    </finprocess-label-value>
                    <finprocess-label-value fxFlex="20">
                        <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumRates' | translate }}</b></span>
                        <span value><b>{{debitor.sumRates | formatCurrency}}</b></span>
                    </finprocess-label-value>
                    <div fxFlex="40"></div>
                </div>
            </div>
            <div class="sum-household">
                <finprocess-label-value fxFlex="20">
                    <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumHousehold' | translate }} {{ household.position + 1}}:</b></span>
                    <span value><b>{{household.sum | formatCurrency}}</b></span>
                </finprocess-label-value>
                <finprocess-label-value fxFlex="20">
                    <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumHouseholdFictionalRates' | translate }} {{ household.position + 1}}:</b></span>
                    <span value><b>{{household.sumFictionalRates | formatCurrency}}</b></span>
                </finprocess-label-value>
                <finprocess-label-value fxFlex="20">
                    <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumHouseholdRates' | translate }} {{ household.position + 1}}:</b></span>
                    <span value><b>{{household.sumRates | formatCurrency}}</b></span>
                </finprocess-label-value>
                <div fxFlex="60"></div>
            </div>

        </div>
        <div class="sum-financing">
            <finprocess-label-value fxFlex="20">
                <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotal' | translate }}</b></span>
                <span value><b>{{financingSumLiabilities.sum | formatCurrency}}</b></span>
            </finprocess-label-value>
            <finprocess-label-value fxFlex="20">
                <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotalFictionalRates' | translate }}</b></span>
                <span value><b>{{financingSumLiabilities.sumFictionalRates | formatCurrency}}</b></span>
            </finprocess-label-value>
            <finprocess-label-value fxFlex="20">
                <span label><b>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotalRates' | translate }}</b></span>
                <div value fxLayout="row" fxLayoutAlign="start center">
                    <b>{{financingSumLiabilities.sumRates | formatCurrency}}</b>
                    <ng-container *ngIf="checkRates()">
                        &nbsp;
                        <mat-icon color="warn" matTooltip="{{'financing.features.financing-processing.liabilities.liabilitySums.missingRatesHint' | translate}}">warning</mat-icon>
                    </ng-container>
                </div>
            </finprocess-label-value>
            <div fxFlex="60"></div>
        </div>
    </div>
    <div class="waiter" fxFlex *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <waiter-component [isLoading]="loading"></waiter-component>
    </div>
</div>