import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Store } from '@ngxs/store';
import { DashboardType, IDashboardStateParentDefinition } from 'app/modules/dashboard/data';
import { UUID } from 'app/modules/shared';
import { IUser, Role } from 'auth';
import { BehaviorSubject } from 'rxjs';

import { LoadBalancingService } from './../../../../data/services/load-balancing/load-balancing.service';

/**
 * Komponente für den Ausgleichen-Dialog
 */
@Component({
    selector: 'finprocess-transfer-contract-dialog',
    templateUrl: './transfer-contract-dialog.component.html',
    styleUrls: ['./transfer-contract-dialog.component.scss'],
})

export class TransferContractDialogComponent {
    public displayedColumns: string[] = ['selector', 'firstName', 'lastName'];
    public displayedUsers: IUser[] = [];
    public currentPage = 0;
    public paginatorLength = 0;

    public users: IUser[] = [];

    public selectedUser?: IUser;
    public isLoading = true;
    public isBalancing = false;

    private financingId!: UUID;

    /**
     * Subject für die Textsuche
     */
    public searchTerm = new BehaviorSubject<string>('');

    /**
     * DashboardType
     */
    public dashboardType?: DashboardType;

    /**
     * Userpurpose
     */
    public userPurpose?: Role;


    /**
     * Standard Konstruktor
     *
     * @param {LoadBalancingService} loadBalancingService Service zum Abrufen der Benutzer
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     * @param {UUID} financingId financing id
     * @param {Store} store Store
     */
    public constructor(public loadBalancingService: LoadBalancingService, public dialogRef: MatDialogRef<TransferContractDialogComponent>,
        @Inject(MAT_DIALOG_DATA) financingId: UUID, private store: Store) {

        this.financingId = financingId;
        this.dashboardType = this.store.selectSnapshot((it: IDashboardStateParentDefinition) => it.dashboard?.currentDashboard);

        if (this.dashboardType === DashboardType.Expert) {
            this.userPurpose = Role.Expert;
        }
        else {
            this.userPurpose = Role.Referent;
        }

        loadBalancingService.getEditors(financingId, this.userPurpose).subscribe(loadedUsers => {
            if (loadedUsers === undefined) {
                return;
            }

            this.users = loadedUsers;

            const startIndex = this.currentPage * 5;
            this.displayedUsers = this.users.slice(startIndex, startIndex + 5);

            this.isLoading = false;

            this.paginatorLength = this.users.length;
        });


    }

    /**
     * Antrag ausgleichen
     */
    public balanceContract(): void {
        if (this.selectedUser === undefined) {
            return;
        }

        this.isBalancing = true;

        if (!!this.userPurpose) {
            this.loadBalancingService.setEditor(this.financingId, this.selectedUser.id, this.userPurpose).subscribe(
                () => {
                    this.dialogRef.close(true);
                },
            );
        }
        else {
            //this.notificationService.
        }
    }

    /**
     * Ändert die angezeigten Benutzer wenn die Seite des Paginators geändert wird
     *
     * @param {PageEvent} pageEvent Das PageEvent des Paginators
     */
    public changePage(pageEvent: PageEvent): void {
        this.currentPage = pageEvent.pageIndex;
        const startIndex = this.currentPage * 5;

        if (this.searchTerm.value === '') {
            this.displayedUsers = this.users.slice(startIndex, startIndex + 5);
        }
    }

    /**
     * Suche aktualisieren
     *
     * @param {string} searchTerm Suchbegriff
     */
    public updateSearch(searchTerm: string): void {

        this.currentPage = 0;

        this.displayedUsers = this.users.filter(user => searchTerm.split(' ').map(term => term.toLowerCase()).every(term =>
            (user.firstName.toLowerCase().includes(term) ||
            user.lastName.toLowerCase().includes(term)),
        )).slice(this.currentPage, this.currentPage + 5);

        if (this.searchTerm.value !== '') {
            this.paginatorLength = this.displayedUsers.length
        }
        else {
            this.paginatorLength = this.users.length
        }
    }
}
