<div [ngClass]="class" class="w-100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id()">{{ label() }}</label>
        @if (overwrite() && currentValue() !== originalValue()) {
        <mat-icon
            class="ml-1"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | translate) }">
            info</mat-icon
        >
        }
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start center">
        <div fxFlex="100">
            <ul>
                @for (item of internalItems(); track $index) {
                <li>
                    <mat-checkbox
                        [(ngModel)]="item.isChecked"
                        (ngModelChange)="updateAllChecked(item)"
                        [color]="'primary'"
                        [disabled]="(readonly() | async) ?? false">
                        {{ item.displayName }}
                    </mat-checkbox>
                </li>
                }
            </ul>
        </div>
    </div>
</div>
