import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthenticationInterceptor } from './interceptors';

/**
 * Modul mit Authentifizierungs-Interceptor
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers:[
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
    ],
})
export class AuthInterceptorsModule {
}
