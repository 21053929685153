import { Component, Input } from '@angular/core';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Eingabefeld - Mehrzeiligier Text
 */
@Component({
    selector: 'finprocess-textarea-input',
    templateUrl: './textarea-input.component.html',
    styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Placeholder
     */
    @Input()
    public placeholder = '';

    /**
     * Max length of the input
     */
    @Input()
    public maxLength?: number;

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     *
     * @returns {string} Anzeigeschlüssel
     */
    public get translatedOriginalValueKey(): string {
        if (this.originalValue === undefined) {
            return 'financing.features.financing-processing.unset';
        }

        return this.originalValue?.toString() ?? '';
    }

    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.String, financingService);
    }    
}
