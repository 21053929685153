<div class="p-3">

    <mat-tab-group mat-stretch-tabs="false" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="updateIndex($event)">
        <mat-tab
            [label]="'financing.features.financing-processing.customer-center.sampleCalculationOverview' | translate">

            <div class="alert-warning" *ngIf="!!page1Note"> {{ page1Note }} </div>

            <div fxLayout="row" fxLayoutAlign="end" *ngIf="showSendButton && ((productPackageIds$ | async)?.length ?? 0) > 0">
                <button mat-raised-button color="primary" class="mt-3 mb-3 mr-2 send-files" [disabled]="(fieldReadonly$ | async)"
                    (click)="sendSampleCalculations()">
                    {{ 'financing.features.financing-processing.customer-center.sendSampleCalculationsBtn' | translate
                    }}
                </button>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" class="mt-4" fxLayoutAlign="row-wrap"
                *ngIf="riskFinancingPlans$ as riskFinancingPlans">
                <mat-card appearance="outlined" *ngFor="let rfp of (riskFinancingPlans | async); let i = index;" class="mb-4 bg-dark-2">
                    <mat-card-header>
                        <mat-card-title>
                            {{'financing.features.financing-processing.customer-center.riskFinancingPanTitle'|
                            translate}}:
                            {{ rfp.name }}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                        <mat-expansion-panel class="mb-3 bg-dark-2" fxlayout="column" style="box-shadow: none;"
                            hideToggle *ngFor="let package of rfp.assignProductPackages; let j = index"
                            [expanded]="expanded[i][j]">
                            <mat-expansion-panel-header fxlayout="column"
                                [ngClass]="{'expanded-header': expanded[i][j], 'collapsed-header': !expanded[i][j]}">
                                <mat-panel-title (click)="$event.stopPropagation();">
                                    {{
                                    !!package.name ? package.name :
                                    'financing.features.financing-processing.customer-center.productPackageDefaultName'
                                    | translate:{idx: j+1}
                                    }}
                                    <button style="float: left;" mat-icon-button
                                        (click)="expanded[i][j] = !expanded[i][j]; $event.stopPropagation()">
                                        <mat-icon>{{ expanded[i][j] ? 'expand_less' : 'expand_more' }}</mat-icon>
                                    </button>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <h4 class="color-primary mt-3">
                                <b>{{ 'financing.features.financing-processing.customer-center.sampleCalculationTitle' |
                                    translate }}</b>
                            </h4>
                            <ng-container *ngIf="package.assignedSampleCalculations as sampleCalculationsForPackage">
                                <mat-list role="list">
                                    <div *ngFor="let sampleCalculation of sampleCalculationsForPackage" class="mb-3">
                                        <mat-list-item role="listitem">

                                            <div class="mt-3">
                                                <mat-icon class="document-icon mr-2">description</mat-icon>
                                                <small>{{sampleCalculation.sampleCalculationName}}.pdf</small>

                                                <mat-icon class="mr-2 ml-2 icon-pointer open-icon"
                                                    (click)="openSampleCalculationDocument(sampleCalculation.sampleCalculationId, sampleCalculation.sampleCalculationName)">open_in_new</mat-icon>
                                            </div>

                                            <div class="ml-2" fxLayout="row">
                                                <finprocess-label-value class="mr-3" *ngIf="!!dateText">
                                                    <span label><small>{{'financing.features.financing-processing.customer-center.sendDate'
                                                            | translate }}</small></span>
                                                    <span value><small>{{dateText}}</small></span>
                                                </finprocess-label-value>

                                                <finprocess-label-value>
                                                    <span label><small>{{'financing.features.financing-processing.customer-center.state'
                                                            |translate }}</small></span>
                                                    <span *ngIf="!!statusText"
                                                        value><small>{{statusText}}</small></span>
                                                    <span *ngIf="!statusText" value><small>Offen</small></span>
                                                </finprocess-label-value>
                                            </div>
                                        </mat-list-item>
                                        <hr class="mb-3">
                                    </div>
                                </mat-list>

                                <div *ngIf="!!rfp.systemNotes && !!rfp.systemNotes[0]" fxLayout="row" fxLayoutAlign="start center" class="mt-5">
                                    <small class="system-notes">
                                        <mat-icon style="font-size: 22px; vertical-align: middle;"
                                            >info</mat-icon>
                                        <span>{{rfp.systemNotes[0].displayText}}</span>
                                    </small>
                                </div>

                            </ng-container>
                        </mat-expansion-panel>
                        
                        <!--if no other sample calc exists, show systemNotes in matcardcontent-->
                        <div *ngIf="!!rfp.systemNotes && !!rfp.systemNotes[0] && !rfp.assignProductPackages" fxLayout="row" fxLayoutAlign="start center" class="mt-5">
                            <small class="system-notes">
                                <mat-icon style="font-size: 22px; vertical-align: middle;"
                                    >info</mat-icon>
                                <span>{{rfp.systemNotes[0].displayText}}</span>
                            </small>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>

        <mat-tab
            [label]="'financing.features.financing-processing.customer-center.acceptedSampleCalculationTitle' | translate">

            <div class="py-2" *ngIf="!sampleCalculationAccepted">
                <finprocess-hint type="info">
                    <span content>{{ 'financing.features.financing-processing.customer-center.sampleCalculationNotAccepted' | translate }}</span>
                </finprocess-hint>
            </div>
            <div fxLayout="column" *ngIf="sampleCalculationAccepted" class="py-2">
                <finprocess-hint type="success">
                    <span header>{{'financing.features.financing-processing.customer-center.acceptedSampleCalculationTitle' | translate}}</span>
                    <div content>
                        <div *ngIf="!!sampleCalculationResponse">{{ sampleCalculationResponse }}</div>
                        <mat-list class="mb-4 mt-4 bg-dark-2" role="list" *ngIf="acceptedSampleCalculation">
                            <mat-list-item role="listitem">
                                <mat-icon class="document-icon mr-2">description</mat-icon>
                                <small>{{acceptedSampleCalculation.name}}.pdf</small>
                                <mat-icon class="mr-2 ml-4 icon-pointer open-icon"
                                    (click)="onAcceptedSampleCalculationClick(acceptedSampleCalculation)">open_in_new</mat-icon>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </finprocess-hint>
                <div *ngIf="(acceptedProductPackage$ | async) as acceptedProductPackage">
                    <finprocess-textbox-input 
                        [overwrite]="false" 
                        [entityClassType]="EntityClassType.ProductPackage"
                        [label]="'financing.features.financing-processing.customer-center.accountholder' | translate"
                        [entity]="acceptedProductPackage" fieldName="accountholder" [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>
                    <finprocess-textbox-input 
                        [overwrite]="false" 
                        [entityClassType]="EntityClassType.ProductPackage"
                        [label]="'financing.features.financing-processing.customer-center.iban' | translate"
                        [entity]="acceptedProductPackage" fieldName="accountIban" [readonly]="fieldReadonly$">
                    </finprocess-textbox-input>
                </div>
                <div *ngIf="!!productPackageEmptyMessage">{{productPackageEmptyMessage}}</div>
                <finprocess-sub-header label="Stellungnahme für Übergabe an Referenten"></finprocess-sub-header>
                <ckeditor [editor]="Editor" [(ngModel)]="refereeStatement" required [disabled]="disableSendToReferee || ((fieldReadonly$ | async) ?? true)" (change)="true" #refereeStatementControl="ngModel"></ckeditor>
                <div fxLayout="row" fxLayoutAlign="end">
                    <button mat-raised-button color="primary" class="mt-3 mb-3 mr-2 send-files" 
                        [disabled]="disableSendToReferee || (fieldReadonly$ | async) || refereeStatementControl.invalid"
                        [matTooltip]="'financing.features.financing-processing.customer-center.financingToRef' | translate "
                        [matTooltipDisabled]="!disableSendToReferee" (click)="sendToReferee()">
                        {{ 'financing.features.financing-processing.customer-center.CompleteBtn' | translate }}
                    </button>
                    <button *finprocessAuthorization="Role.Referent" mat-raised-button class="mt-3 mb-3 mr-2 send-files" (click)="assignSelfAsReferee()" [disabled]="disableSendToReferee || (fieldReadonly$ | async)">{{'financing.features.financing-processing.process.assignSelfAsReferee' | translate }}</button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>