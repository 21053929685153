import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, combineLatest, forkJoin, map, mergeMap, take, takeUntil, tap } from 'rxjs';

import { ISystemConfigurationValue } from '../../../../data/interfaces';
import { RiskParametrizationService } from '../../../../data/services';
import { IAdministrationStateParentDefinition } from '../../../../data/states';

/**
 * Komponente zur Verwaltung der Nutzervereinbarung
 */
@Component({
    selector: 'finprocess-system-config',
    templateUrl: './system-config.component.html',
    styleUrls: ['./system-config.component.scss'],
})
export class SystemConfigComponent implements OnDestroy, OnInit {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Formular
     */
    public form: FormGroup;

    /**
     * Ob die Komponente zur Bearbeitung gesperrt ist
     */
    public locked = true;


    private viewLeft$ = new Subject<void>();

    /**
     * Formatierung für Faktoren
     */
    public factorOptionsThree: NgxCurrencyConfig;

    /**
     * Formatierung für €
     */
    public currencyOptions: NgxCurrencyConfig;

    /**
     * Standard Konstruktor
     *
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {RiskParametrizationService} riskParametrizationService RiskParametrizationService-Injektor
     * @param {Store} store Store-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {string} locale Lokalisierung
     */
    public constructor(
        private fb: FormBuilder,
        private riskParametrizationService: RiskParametrizationService,
        private store: Store,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        @Inject(LOCALE_ID) locale: string,
    ) {
        this.form = this.fb.group({
            userAgreement: [null],
            amountLimit: [null],
            interestRate: [null],
            defaultReferenceInterestRate: [null],
            defaultAddition: [null],
            additionComparativeCalculation: [null],
            bruttoNettoCalculatorUrl: [null],
            baseChildrenAmount: [null],
            baseChildrenObligationAmount: [null],
            baseChildrenRecipientAmount: [null],
        });

        this.currencyOptions = HelperService.getInputMask(locale, {
            align: 'left',
            allowNegative: false,
            prefix: '€ ',
            precision: 0,
            thousands: '.',
            allowZero: true,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.factorOptionsThree = HelperService.getInputMask(locale, {
            align: 'left',
            suffix: '%',
            precision: 3,
            allowZero: true,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.disableForm();
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {

        combineLatest([
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AmountLimit))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.InterestRate))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.DefaultReferenceInterestRate))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.DefaultAddition))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AdditionComparativeCalculation))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BruttoNettoCalculatorUrl))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenAmount))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenObligationAmount))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenRecipientAmount))),
        ]).subscribe(([amountLimit, interestRate, defaultReferenceInterestRate, defaultAddition, additionComparativeCalculation, 
            bruttoNettoCalculatorUrl, baseChildrenAmount, baseChildrenObligationAmount, baseChildrenRecipientAmount]) => {
            this.form.get('amountLimit')?.patchValue(amountLimit);
            this.form.get('interestRate')?.patchValue(interestRate);
            this.form.get('defaultReferenceInterestRate')?.patchValue(defaultReferenceInterestRate);
            this.form.get('defaultAddition')?.patchValue(defaultAddition);
            this.form.get('additionComparativeCalculation')?.patchValue(additionComparativeCalculation);
            this.form.get('bruttoNettoCalculatorUrl')?.patchValue(bruttoNettoCalculatorUrl);
            this.form.get('baseChildrenAmount')?.patchValue(baseChildrenAmount);
            this.form.get('baseChildrenObligationAmount')?.patchValue(baseChildrenObligationAmount);
            this.form.get('baseChildrenRecipientAmount')?.patchValue(baseChildrenRecipientAmount);
        });

        this.riskParametrizationService.getSystemConfiguration(SystemConfigurationType.UserAgreementText).pipe(
            take(1),
        ).subscribe();

        this.store.select((it: IAdministrationStateParentDefinition) => it.riskParametrization.singleConfigurationValues).pipe(
            takeUntil(this.viewLeft$),
        ).subscribe(configurationValues => {
            const userAgreement = configurationValues.find(value => value.type === SystemConfigurationType.UserAgreementText);

            if (!!userAgreement) {
                this.form.get('userAgreement')?.patchValue(userAgreement.value);
            }
        });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
        this.viewLeft$.complete();
    }

    /**
     * Speichert die Nutzervereinbarung
     */
    public save(): void {
        const requests: Observable<unknown>[] = [];

        if (this.shoudUpdateControl('amountLimit')) {
            requests.push(this.getRequest('amountLimit', SystemConfigurationType.AmountLimit))
        }

        if (this.shoudUpdateControl('interestRate')) {
            requests.push(this.getRequest('interestRate', SystemConfigurationType.InterestRate))
        }

        if (this.shoudUpdateControl('defaultReferenceInterestRate')) {
            requests.push(this.getRequest('defaultReferenceInterestRate', SystemConfigurationType.DefaultReferenceInterestRate))
        }

        if (this.shoudUpdateControl('defaultAddition')) {
            requests.push(this.getRequest('defaultAddition', SystemConfigurationType.DefaultAddition))
        }

        if (this.shoudUpdateControl('additionComparativeCalculation')) {
            requests.push(this.getRequest('additionComparativeCalculation', SystemConfigurationType.AdditionComparativeCalculation))
        }

        if (this.shoudUpdateControl('userAgreement')) {
            requests.push(this.getRequest('userAgreement', SystemConfigurationType.UserAgreementText))
        }

        if (this.shoudUpdateControl('bruttoNettoCalculatorUrl')) {
            requests.push(this.getRequest('bruttoNettoCalculatorUrl', SystemConfigurationType.BruttoNettoCalculatorUrl))
        }

        if (this.shoudUpdateControl('baseChildrenAmount')) {
            requests.push(this.getRequest('baseChildrenAmount', SystemConfigurationType.BaseChildrenAmount))
        }

        if (this.shoudUpdateControl('baseChildrenObligationAmount')) {
            requests.push(this.getRequest('baseChildrenObligationAmount', SystemConfigurationType.BaseChildrenObligationAmount))
        }

        if (this.shoudUpdateControl('baseChildrenRecipientAmount')) {
            requests.push(this.getRequest('baseChildrenRecipientAmount', SystemConfigurationType.BaseChildrenRecipientAmount))
        }

        if (requests.length > 0) {
            this.waiterService.show().pipe(
                mergeMap(() => forkJoin(requests)),
                tap(() => this.waiterService.hide()),
            ).subscribe(
                {
                    next: () => {
                        this.locked = true;
                        this.disableForm();
                        this.form.markAsPristine();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notification.alert(
                            this.translate.instant('general.error'),
                            this.translate.instant('administration.features.userAgreement.error'),
                        );
                    },
                },
            );
        }
    }

    /**
     * Check muss control updated werden
     *
     * @param {string} controlName controlName
     * @returns {boolean  } boolean
     */
    private shoudUpdateControl(controlName: string): boolean {
        return this.form.get(controlName)?.dirty ?? false;
    }

    /**
     *  Erstellt ein Update Requests 
     * 
     * @param {string} controlName controlName 
     * @param {SystemConfigurationType} type SystemConfigurationType
     * @returns {Observable<unknown> } Request für Server
     */
    private getRequest(controlName: string, type: SystemConfigurationType): Observable<unknown> {
        const config: ISystemConfigurationValue = {
            type: type,
            mandantId: undefined,
            value: this.form.get(controlName)?.value?.toString(),
        };
        return this.riskParametrizationService.updateSystemConfiguration(config);
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.form.enable();
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.form.disable();
    }

    /**
     * check if Form has any error
     * 
     * @param {FormGroup} form FormGroup
     * @returns {boolean} true/false
     */
    public hasAnyError(form: FormGroup): boolean {
        return Object.keys(form.controls).some(controlName => {
            const control = form.get(controlName);
            return control && control.invalid && control.dirty;
        });
    }
}
