import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Component, Inject, LOCALE_ID, computed, input, model } from '@angular/core';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { HelperService } from 'shared/util';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente zur Eingabe eines Dezimalwertes
 */
@Component({
    selector: 'finprocess-decimal-input',
    templateUrl: './decimal-input.component.html',
    styleUrls: ['./decimal-input.component.scss'],
})
export class DecimalInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Options for configuration of the ngx currency directive
     */
    public options = model<NgxCurrencyConfig>();

    /**
     * Display format
     */
    public format = input<string>('1.2-2');

    /**
     * Minimum value
     */
    public minValue = input<number>();

    /**
     * Maximum value
     */
    public maxValue = input<number>();

    /**
     * Prefix
     */
    public praefix = input<string>();

    /**
     * Suffix
     */
    public suffix = input<string>();

    /**
     * Additional hint below the label
     */
    public hint = input<string>();

    /**
     * Alignment of the value within the input field
     */
    public fxValueAlignment = input<'end' | 'start'>('end');

    /**
     * Localisation
     */
    public locale: string;

    /**
     *  Gets the original value of the entity
     *  Any because typescript does not understand that T[K] is a number
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public originalValue = computed<any>(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        return entity[this.fieldName()];
    });

    /**
     * Options for configuration of the ngx currency directive
     * If no options are provided, the default options are calculated
     */
    public optionsComputed = computed<NgxCurrencyConfig>(() => {
        const options = this.options();

        if (!!options) {
            return options;
        }

        const minValue = this.minValue();
        const prefix = this.praefix();
        const suffix = this.suffix();

        return {
            align: this.fxValueAlignment() === 'end' ? 'right' : 'left',
            allowNegative: (minValue !== undefined && minValue < 0) || this.allowNegativeValues() === true,
            decimal: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal),
            precision: HelperService.getMaxDecimalPlacesByFormat(this.format()),
            prefix: prefix !== undefined ? `${prefix} ` : '',
            suffix: suffix !== undefined ? ` ${suffix}` : '',
            thousands: getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyGroup),
            allowZero: minValue === undefined || minValue <= 0,
            nullable: true,
            inputMode: NgxCurrencyInputMode.Natural,
            min: minValue,
            max: this.maxValue(),
        }
    });
    

    /**
     * Constructor
     *
     * @param {string} locale Locale
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(@Inject(LOCALE_ID) locale: string, financingService: FinancingService) {
        super(ValueStorageType.Decimal, financingService);
        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }
}
