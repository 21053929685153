import { UnionValueType } from './union-value-type';

/**
 * Klasse für Hilfsmethoden
 */
export class Helper {
    /**
     * Liefert den/die Übersetzungsschlüssel für eine Enum-Übersetzung
     *
     * @param {UnionValueType} param Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @returns {string | string[]} Übersetzungsschlüssel
     */
    public static getKeyByEnum(param: UnionValueType): string | string[] {
        if (!Array.isArray(param)) {
            if (typeof param !== 'object') {
                return param;
            }
            let result = '';
            result = Helper.setKeyFragementIfDefined(result, param.translatePraefix);
            result = Helper.setKeyFragementIfDefined(result, param.type);
            result = Helper.setKeyFragementIfDefined(result, param.value, false);

            return result;

        }
        else if (param.length > 0) {
            const result: string[] = [];
            param.forEach(val => {
                if (typeof val === 'object') {
                    result.push(this.getKeyByEnum(val) as string);
                }
                else {
                    result.push(val);
                }
            });
            return result;
        }
        else {
            return [] as string[];
        }
    }

    /**
     * Liefert einen zusammengesetzten Schlüssel, wenn Schlüsselfragment definiert ist
     *
     * @param {string} target Zielschlüssel
     * @param {string | number | null} key Zu setzendes Schlüsselfragment
     * @param {boolean} pointSuffix Suffix hinzufügen
     * @returns {string} Zusammengesetzter Schlüssel
     */
    private static setKeyFragementIfDefined(target: string, key?: string | number | null, pointSuffix = true): string {
        if (key !== undefined && key !== null && key !== '') {
            target += key;
            if (pointSuffix) {
                target += '.';
            }
        }
        return target;
    }
}
