import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CollateralType, EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { AssetService, AssetState, FinancingService, SourceCollateral } from 'app/modules/financing/data';
import { IAssetAccount } from 'app/modules/financing/data/interfaces/asset-account';
import { ISelectItem } from 'app/modules/shared';
import { Subject, takeUntil } from 'rxjs';

import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 * Asset account Component
 */
@Component({
    selector: 'finprocess-asset-account',
    templateUrl: './asset-account.component.html',
    styleUrls: ['./asset-account.component.scss'],
})
export class AssetAccountComponent extends AssetCommonComponent implements OnInit, OnDestroy {

    @Input()
    public assetAccount: IAssetAccount | undefined;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public collateralType = CollateralType;

    public assetExpand = false;

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Dropdown Boolean
     */
    public booleanItems?: ISelectItem<boolean | null>[] = [];

    /**
     * Validators für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /**
     * SourceCollateral für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral

    /**
     * Requirements failed Map
     */
    public validAccountMap: { [id: string]: boolean } = {};

    /**
     * Destroy-Subject
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     * @param {NotificationService} notificationService NotificationService
     * @param {TranslateService} translate TranslateService
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            enumTranslate: EnumTranslationPipe,
            assetService: AssetService,
            store: Store,
            financingService: FinancingService,
            private notificationService: NotificationService,
            private translate: TranslateService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )

        this.booleanItems = [true, false].map(value => ({
            value: value,
            displayName: value ? 'Ja' : 'Nein',
        }));

        this.booleanItems.push(({ displayName: 'Keine Auswahl', value: null }));

        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        //Validate Accounts
        this.store.select(AssetState.validateAssetInsurance).pipe(takeUntil(this.onDestroy$)).subscribe(invalidaccounts => {
            this.validAccountMap = invalidaccounts.reduce((acc, accounts) => {
                acc[accounts.id] = accounts.valid;

                if (!accounts.valid && accounts.isActive) {
                    this.setAssetActive(accounts.id, false).subscribe(() => {
                        this.notificationService.toast(this.translate.instant('asset.assetCommon.assetDeactivated'));
                    });
                }

                return acc;
            }, {} as { [id: string]: boolean });
        });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
