import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { InformationEventService } from './services';
import { InformationEventState } from './states';

export * from './enums';
export * from './interfaces';
export * from './services';
export * from './states';

/**
 * Modul für das Laden der Aktivitätendaten
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([InformationEventState]),
    ],
    providers: [
        InformationEventService,
    ],
})
export class InformationEventDataModule { }
