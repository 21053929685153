import { IUser } from 'auth';

import { DashboardSorting } from '../../types';

import { DashboardFilter, DashboardType, SalesPartnerCenterFilter } from './../../enums';


/**
 * Aktion beim Wechseln des Tabs im Dashboard
 */
export class TabChanged {
    public static readonly type = 'dashboard.tabChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {number | undefined} payload.tabId Ausgewählter Tab
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string}) { }
}

/**
 * Aktion beim Synchronisieren der Vetretungstabs
 */
export class TabsSynced {
    public static readonly type = 'dashboard.tabsSynced';

    /**
     * Konstruktor
     *
     * @param {IUser[]} payload Vertretungstabs
     */
    public constructor(public payload: IUser[]) { }
}


/**
 * Aktion beim Wechseln der Anzahl von Einträgen pro Seite
 */
export class PageSizeChanged {
    public static readonly type = 'dashboard.pageSizeChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {number | undefined} payload.pageSize Einträge pro Seite
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; pageSize?: number; }) { }
}

/**
 * Aktion beim Wechseln der Seite
 */
export class PageChanged {
    public static readonly type = 'dashboard.pageChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {number} payload.page Seite
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; page: number; }) { }
}

/**
 * Aktion beim Wechseln des Statusfilters
 */
export class StatusFilterChanged {
    public static readonly type = 'dashboard.statusFilterChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {DashboardFilter} payload.filter Filter
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; filter: DashboardFilter; }) { }
}

/**
 * Aktion beim Wechseln des Regionsfilters
 */
export class SalesPartnerCenterFilterChanged {
    public static readonly type = 'dashboard.salesPartnerCenterFilterChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {SalesPartnerCenterFilter} payload.filter Regionsfilter
     * @param {string[]} payload.salesPartnerCenterIds salesPartnerCenterIds
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; filter: SalesPartnerCenterFilter, salesPartnerCenterIds: string[] }) { }
}

/**
 * Aktion beim Ändern der Volltextsuche
 */
export class SearchChanged {
    public static readonly type = 'dashboard.searchChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {string} payload.search Volltextsuche
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; search: string; }) { }
}

/**
 * Aktion beim Wechseln der Sortierung
 */
export class SortingChanged {
    public static readonly type = 'dashboard.sortingChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.dashboardType Welches Dashboard
     * @param {string} payload.tabId Tab
     * @param {DashboardSorting} payload.sorting Sortierungen
     */
    public constructor(public payload: { dashboardType: DashboardType; tabId: string; sorting: DashboardSorting }) { }
}

/**
 * Aktion beim Wechseln des Typs des Dashboards
 */
export class DashboardTypeChanged {
    public static readonly type = 'dashboard.dashboardTypeChanged';

    /**
     * Konstruktor
     * 
     * @param {DashboardType} payload Typ des Dashboards
     */
    public constructor(public payload: DashboardType) {}
}

/**
 * Aktion beim Verlassen des Dashboards
 */
export class DashboardLeaved {
    public static readonly type = 'dashboard.dashboardLeaved';
}
