<div fxFlex="column" style="width: 100%;" class="m-1">
  <div fxLayout="row" fxLayoutAlign="end start" style="padding-top: 30px; padding-right: 20px;">
    <button [disabled]="(editingReadonly$ | async)" mat-raised-button class="mb-4" color="primary" [matMenuTriggerFor]="menu">{{'asset.assetContainer.newAsset' | translate}}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="addNewAsset(entityClassType.AssetRealEstate)">{{'asset.assetContainer.mortgage' | translate}}</button>
      <button mat-menu-item (click)="addNewAsset(entityClassType.AssetInsurance)">{{'asset.assetContainer.insurance' | translate}}</button>
      <button mat-menu-item (click)="addNewAsset(entityClassType.AssetAccount, true)">{{'asset.assetContainer.bonds' | translate}}</button>
      <button mat-menu-item (click)="addNewAsset(entityClassType.AssetAccount)">{{'asset.assetContainer.account' | translate}}</button>
    </mat-menu>
  </div>
  <div>
    <!-- bei großere Datenmenge defer u.a sorgt sich um bessere User Experience, damit ist waiter immer angezeigt, bis die Daten geladen/gerendert sind -->
    @defer {
      <ng-container *ngIf="(assetRealEstates$ | async) as assetRealEstates">
        <div *ngFor="let assetRealEstate of assetRealEstates; trackBy: panelTrackedBy" class="mb-4">
          <finprocess-asset-real-estate [assetRealEstate]="assetRealEstate">
          </finprocess-asset-real-estate>
        </div>
      </ng-container>
      <ng-container *ngIf="(assetInsurances$ | async) as assetInsurances">
        <div *ngFor="let insurance of assetInsurances; trackBy: panelTrackedBy" class="mb-4">
          <finprocess-asset-insurance [assetInsurance]="insurance">
          </finprocess-asset-insurance>
        </div>
      </ng-container>
      <ng-container *ngIf="(assetAccounts$ | async) as assetAccounts">
        <div *ngFor="let account of assetAccounts; trackBy: panelTrackedBy" class="mb-4">
          <finprocess-asset-account [assetAccount]="account">
          </finprocess-asset-account>
        </div>
      </ng-container>
    }
  </div>
  <div *ngIf="(assetsEmpty$ | async)" fxLayout="row" fxLayoutAlign="center center" class="no-assets mt-2">
    {{'asset.assetContainer.noAssets' | translate}}
  </div>
</div>