import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, Optional, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { IRentalIncome } from '../..';
import { RentalIncomeAdded, RentalIncomeDeleted, RentalIncomeUpdated, RentalIncomesLoaded } from '../../states/debitor/debitor.actions';

/**
 * Service for the existing and future rental income controllers
 */
@Injectable()
export class RentalIncomeService {

    /**
     * Service constructor
     * 
     * @param {Store} store Store
     * @param {HttpClient} httpClient HttpClient
     * @param {ConfigService} config ConfigService
     * @param {AuthorizationService} authorizationService AuthorizationService
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Loads a list of rental incomes for a debitor
     *
     * @param {UUID} debitorId ID of the debitor
     * @returns {Observable<IRentalIncome[] | undefined>} List of rental incomes
     */
    public getRentalIncomes(debitorId: UUID): Observable<IRentalIncome[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IRentalIncome[]>(`${this.config.getEnvironment().apiUrl}/rentalincome/getall`, { params: { id: debitorId }}).pipe(
                    mergeMap((rentalIncomes: IRentalIncome[]) => this.store.dispatch(new RentalIncomesLoaded({ debitorId, rentalIncomes })).pipe(
                        map(() => rentalIncomes),
                    )), 
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Adds a rental income
     *
     * @param {IRentalIncome} rentalIncome Rental income
     * @returns {Observable<IRentalIncome | undefined>} The added rental income
     */
    public addRentalIncome(rentalIncome: Optional<IRentalIncome, 'id'>): Observable<IRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IRentalIncome>(`${this.config.getEnvironment().apiUrl}/rentalincome/add`, rentalIncome).pipe(
                    mergeMap((newRentalIncome: IRentalIncome) => this.store.dispatch(new RentalIncomeAdded(newRentalIncome)).pipe(
                        map(() => newRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Updates a rental income
     *
     * @param {IRentalIncome} rentalIncome Rental income
     * @returns {Observable<IRentalIncome | undefined>} The updated rental income
     */
    public updateRentalIncome(rentalIncome: IRentalIncome): Observable<IRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.patch<IRentalIncome>(`${this.config.getEnvironment().apiUrl}/rentalincome/update`, rentalIncome).pipe(
                    mergeMap((updatedRentalIncome: IRentalIncome) => this.store.dispatch(new RentalIncomeUpdated(updatedRentalIncome)).pipe(
                        map(() => updatedRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Deletes a rental income
     *
     * @param {UUID} debitorId ID of the debitor
     * @param {UUID} rentalIncomeId ID of the rental income
     * @returns {Observable<void | undefined>} Empty server response
     */
    public deleteRentalIncome(debitorId: UUID, rentalIncomeId: UUID): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete<void>(`${this.config.getEnvironment().apiUrl}/rentalincome/delete`, { params: { id: rentalIncomeId }}).pipe(
                    mergeMap(() => this.store.dispatch(new RentalIncomeDeleted({ debitorId, rentalIncomeId }))),
                ),
                of(undefined),
            )),
        );
    }
}
