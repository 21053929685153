/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormControlOptions, ValidatorFn, Validators } from '@angular/forms';

import { IProviderWithMulti, IValidationOptions } from '../interfaces';

import { FinprocessFormGroup } from './finprocess-form-group';
import { FinprocessFormArray } from './finprocess-form.array';
import { parseValidationOptions } from './form-builder';

/**
 * Finprocess Form Control die eine normale FormControl
 * um eine Funktion zum Aktualisieren von Validatoren erweitert
 */
export class FinprocessFormControl<T> extends FormControl<T | null> {

    /**
     * Initialisiert eine FinprocessFormControl
     * 
     * @param {any} value Initialer Wert
     * @param {IValidationOptions} options Validierungsoptionen
     * @param {FormControlOptions} formOtp FormControlOptions zum anwenden an das FormControl (z.B. zum Steuern der updateOn Strategie)
     */
    public constructor(value?: T, options?: IValidationOptions<any, any, any, any>, formOtp?: FormControlOptions) {
        super(value ?? null, formOtp);

        parseValidationOptions(this, options);
    }

    /**
     * Funktion für die Validierung der Form Control
     */
    public validatorFunction?: (...args: any) => ValidatorFn | null;

    /**
     * Fixer Validator, der sich nie ändert
     */
    public fixedValidator?: ValidatorFn;

    /**
     * Zusätzliche Validatoren, die außerhalb der normalen Validierung gesetzt werden können
     */
    public additionalValidators: ValidatorFn[] = [];

    /**
     * Zugriffsproperties für Unterobjekte
     */
    public validatorProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion für die Sichtbarkeit der Form Control
     */
    public visibilityFunction?: (...args: unknown[]) => boolean;

    /**
     * Zugriffsproperties für Unterobjekte für die Sichtbarkeit
     */
    public visibilityProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion für einen alternative Translation Key
     */
    public translateKeyFunction?: (...args: unknown[]) => string | null;

    /**
     * Zugriffsproperties für Unterobjekte für einen alternative Translation Key
     */
    public translateKeyProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion zum Berechnen des Default Werts
     */
    public defaultFunction?: (...args: unknown[]) => T;

    /**
     * Zugriffsproperties für Unterobjekte für den Default Wert
     */
    public defaultProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Initialisiert Validatoren und Default Wert
     */
    public initValidation(): void {
        if (!!this.defaultFunction && (this.value === null || this.value === undefined)) {
            const defaultValue = this.getDefaultValue();
            if (defaultValue !== null) {
                this.patchValue(defaultValue, { emitEvent: false, onlySelf: true });
            }
        }

        if (!!this.validatorFunction || !!this.fixedValidator) {
            this.updateValidators();
        }
    }

    /**
     * Aktualisiert die Validatoren der FormControl
     */
    public updateValidators(): void {
        this.setValidators(this.getValidator());
        this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    /**
     * Aktualisiert die Sichtbarkeit der FormControl
     *
     * @returns {boolean} Sichtbarkeit
     */
    public updateVisibility(): boolean {
        if (!!this.visibilityFunction) {
            let parameters: unknown[] = [];

            if (this.visibilityProviders) {
                parameters = this.visibilityProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.visibilityFunction(...parameters);
        }

        return true;
    }

    /**
     * gibt einen alternative Translate Key zurück
     *
     * @returns {string} Sichtbarkeit
     */
    public getTranslateKey(): string | null {
        if (!!this.translateKeyFunction) {
            let parameters: unknown[] = [];

            if (this.translateKeyProviders) {
                parameters = this.translateKeyProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.translateKeyFunction(...parameters);
        }

        return null;
    }

    /**
     * Bestimmt den Validator anhand eines fixen Validators oder einer Funktion
     * 
     * @returns {ValidatorFn | null} Validator Funktion
     */
    private getValidator(): ValidatorFn | null {
        if (!!this.fixedValidator) {
            if (this.additionalValidators.length > 0) {
                return Validators.compose([this.fixedValidator, ...this.additionalValidators]);
            }

            return this.fixedValidator;
        }

        if (!!this.validatorFunction) {
            let parameters: unknown[] = [];

            if (this.validatorProviders) {
                parameters = this.validatorProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            if (this.additionalValidators.length > 0) {
                return Validators.compose([this.validatorFunction(...parameters), ...this.additionalValidators]);
            }

            return this.validatorFunction(...parameters);
        }

        return this.additionalValidators.length > 0 ? Validators.compose(this.additionalValidators) : null;
    }

    /**
     * Aktualisiert die Sichtbarkeit der FormControl
     *
     * @returns {boolean} Sichtbarkeit
     */
    private getDefaultValue(): T | null {
        if (!!this.defaultFunction) {
            let parameters: unknown[] = [];

            if (this.defaultProviders) {
                parameters = this.validatorProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.defaultFunction(...parameters);
        }

        return null;
    }
}
