import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IBranch } from 'app/modules/masterdata/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IAddBranchRequest, IChangeBranchStatusRequest, IEditBranchRequest } from '../../interfaces';
import { BranchAdded, BranchEdited, BranchStatusChanged, BranchesLoaded } from '../../states';

/**
 * Service für Filialadministration
 */
@Injectable()
export class BranchService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Lädt eine Liste der Filialen
     *
     * @returns {IBranch[] | undefined} Liste der Nutzer
     */
    public getList(): Observable<IBranch[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IBranch[]>(`${this.config.getEnvironment().apiUrl}/Branch`).pipe(
                    mergeMap((branches: IBranch[]) => this.store.dispatch(new BranchesLoaded(branches)).pipe(
                        map(() => branches),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Fügt eine neue Filiale hinzu
     *
     * @param {IAddBranchRequest} request Request
     * @returns {UUID} ID der neuen Filiale
     */
    public addBranch(request: IAddBranchRequest): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.BranchAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<UUID>(`${this.config.getEnvironment().apiUrl}/Branch/add`, request, {
                }).pipe(
                    mergeMap((id: UUID) => this.store.dispatch(new BranchAdded({ branch: request, id })).pipe(
                        map(() => id),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Bearbeitet eine Filiale
     *
     * @param {IEditBranchRequest} request Request
     * @returns {Observable} Observable
     */
    public editBranch(request: IEditBranchRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.BranchAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/Branch/edit`, request, {
                }).pipe(
                    mergeMap(() => this.store.dispatch(new BranchEdited(request))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Ändert den Status einer Filiale
     *
     * @param {IEditBranchRequest} request Request
     * @returns {Observable} Observable
     */
    public setStatus(request: IChangeBranchStatusRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.BranchAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/Branch/setstatus`, request, {
                }).pipe(
                    mergeMap(() => this.store.dispatch(new BranchStatusChanged({ disabled: !request.active, id: request.id}))),
                ),
                of(undefined),
            )),
        );
    }
}
