export enum BusinessType {
    /**
     * Geschäftskunde
     */
    BusinessCustomer = 0,

    /**
     * Corporates
     */
    Corporates = 1,

    /**
     * ErfolgsKunde Privat
     */
    SuccessfulCustomer = 2,

    /**
     * TopKunde Privat
     */
    TopCustomer = 3,
}
