import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { IApprovalUserLevel } from 'app/modules/administration/data/interfaces/approval-user-level.interface';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { HelperService } from 'app/modules/shared';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { ApprovalAuthoritiesFile, ControlTableType } from '../../../../data/enums';
import { IApprovalMatrix, IBaseControlTable, IBuildingPromoter, ICadastralCommunity, ICollateralFactor, ICollateralFactorsForAsset, IUploadApprovalControlTableRequest, IUploadControlTableRequest } from '../../../../data/interfaces';
import { ControlTableService } from '../../../../data/services';

interface IControlTable<T extends IBaseControlTable> {
    columns: Array<keyof T>;
    translateKey: string;
}

const BUILDING_PROMOTER_TABLE: IControlTable<IBuildingPromoter> = {
    columns: [
        'ndg',
        'name',
        'street',
        'city',
        'phoneNumber',
        'note',
        'modifiedBy',
        'disabledSince',
        'lastModified',
    ],
    translateKey: 'building-promoters',
};

const CADASTRAL_COMMUNITY_TABLE: IControlTable<ICadastralCommunity> = {
    columns: [
        'landRegisterNumber',
        'nameOfLandRegister',
        'districtCourt',
        'modifiedBy',
        'disabledSince',
        'lastModified',
    ],
    translateKey: 'cadastral-community',

}

const COLLATERAL_FACTOR_TABLE: IControlTable<ICollateralFactor> = {
    columns: [
        'collateralDescription',
        'collateralType',
        'cfDefault',
        'cfMin',
        'cfMax',
        'typeOfAssignment',
        'modifiedBy',
        'disabledSince',
        'lastModified',
    ],
    translateKey: 'collateral-factor',
}

const COLLATERAL_FACTORS_FOR_ASSET: IControlTable<ICollateralFactorsForAsset> = {
    columns: [
        'coltObjectTypeCode',
        'bankingObjectTypeCode',
        'descriptionGer',
        'completionGrade',
        'afDefault',
        'afMin',
        'afMax',
        'modifiedBy',
        'disabledSince',
        'lastModified',
    ],
    translateKey: 'collateral-factors-for-asset',
}

const APPROVAL_USER_LEVEL: IControlTable<IApprovalUserLevel> = {
    columns: [
        'bankId',
        'userName',
        'approvalLevelSales',
    ],
    translateKey: 'approval-user-level',
}

const APPROVAL_MATRIX: IControlTable<IApprovalMatrix> = {
    columns: [
        'approvalLevel',
        'lowerLimit',
        'upperLimit',
        'minLevel',
        'maxLevel',
        'levelAddition',
    ],
    translateKey: 'approval-matrix',
}

/**
 * Komponente zum Hochladen und Ansehen der Steuertabellen für COLT
 */
@Component({
    selector: 'finprocess-control-tables',
    templateUrl: './control-tables.component.html',
    styleUrls: ['./control-tables.component.scss'],
})
export class ControlTablesComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('uploadArea')
    public uploadArea?: ElementRef<HTMLDivElement>;

    /**
     * Auswahlliste der Steuertabellen
     */
    public controlTableTypes?: Observable<ControlTableType[]>;

    /**
     * Aktuell ausgewählte Steuertabelle
     */
    public selectedTableType?: ControlTableType;

    /**
     * Spalten der Tabelle
     */
    public tableColumns: string[] = [];

    /**
     * Datenquelle für die Tabelle
     */
    public tableData$ = new BehaviorSubject<IBaseControlTable[]>([]);

    /**
     * Gefilterte Tabellendaten für den Paginator
     */
    public filteredData$ = new BehaviorSubject<IBaseControlTable[]>([]);

    /**
     * Datenquelle für aktuell sichtbaren Einträge der Tabelle
     */
    public displayedData$!: Observable<IBaseControlTable[]>;

    /**
     * Aktuell angezeigte Seite
     */
    public currentPage$ = new BehaviorSubject<number>(0);

    /**
     * Anzahl der Einträge pro Seite
     */
    public pageSize = 12;

    /**
     * Suchtext
     */
    public search$ = new BehaviorSubject<string>('');

    /**
     * Klasse für den Dropdown Bereich für Dateien
     */
    public dragAreaClass = '';

    /**
     * Übersetzungsschlüssel für verschiedene Tabellen
     */
    public translateKey = '';

    /**
     * Hat der Nutzer die notwendigen Berechnungen die Seite zu bearbeiten
     */
    public readonly = false;

    /**
     * Hat der Nutzer die Sonderberechtigung die Nutzertabelle zu sehen und zu bearbeiten
     */
    public canEditUserTable = false;

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$: Subject<void> = new Subject();

    /**
     * Standard Konstruktor
     *
     * @param {ControlTableService} controlTableService ControlTableService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private controlTableService: ControlTableService,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private enumTranslate: EnumTranslationPipe,
        private translate: TranslateService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Angular-Lifecycle Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {

        this.controlTableTypes = combineLatest([
            this.authorizationService.hasRole$(Role.ControlTableDefaultAdministrator),
            this.authorizationService.hasRole$(Role.ControlTableUserCompetenceAdministrator),
        ]).pipe(
            takeUntil(this.onDestroy$),
            tap(([defaultAdmin, userCompetenceAdministrator]) => {
                this.readonly = !defaultAdmin;
                this.canEditUserTable = userCompetenceAdministrator;
            }),
            map(([, userCompetenceAdministrator]) => {
                if (userCompetenceAdministrator) {
                    return HelperService.getEnumArray(ControlTableType, true) as number[];
                }

                return HelperService.getEnumArray(ControlTableType, true, [ControlTableType.UserApprovalLevel]) as number[];
            }),
        );

        this.displayedData$ = combineLatest([
            this.tableData$,
            this.currentPage$,
            this.search$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([tableData, currentPage, search]) => {
                const filteredData = this.searchData(tableData, search);
                this.filteredData$.next(filteredData);

                const totalPages = Math.ceil(filteredData.length / this.pageSize);

                if (currentPage > totalPages) {
                    this.currentPage$.next(0);
                }

                const startIndex = currentPage * this.pageSize;
                const displayedRows = filteredData.slice(startIndex, startIndex + this.pageSize);

                if (displayedRows.length < this.pageSize) {
                    // Filialen mit leeren Einträgen auffuellen damit die Tabelle gut aussieht
                    displayedRows.push(...Array(this.pageSize - displayedRows.length).fill(undefined))
                }

                return displayedRows;
            }),
        );
    }

    /**
     * Beendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Angular Lifecycle-Hook nach dem Initialisieren der Komponente
     */
    public ngAfterViewInit(): void {
        if (!!this.uploadArea) {
            this.uploadArea.nativeElement.addEventListener('dragover', event => this.onDragOver(event));
            this.uploadArea.nativeElement.addEventListener('dragenter', event => this.onDragEnter(event));
            this.uploadArea.nativeElement.addEventListener('dragend', event => this.onDragEnd(event));
            this.uploadArea.nativeElement.addEventListener('dragleave', event => this.onDragLeave(event));
            this.uploadArea.nativeElement.addEventListener('drop', event => this.onDrop(event));
        }
    }

    /**
     * Wechselt den Typ der Tabelle
     *
     * @param {MatSelectChange} event Event beim Wechseln der Auswahl im Dropdown Menü
     */
    public changeControlTableType(event: MatSelectChange): void {
        switch (event.value) {
            case ControlTableType.CollateralFactors:
                this.tableColumns = COLLATERAL_FACTOR_TABLE.columns;
                this.translateKey = COLLATERAL_FACTOR_TABLE.translateKey;
                break;
            case ControlTableType.BuildingPromoters:
                this.tableColumns = BUILDING_PROMOTER_TABLE.columns;
                this.translateKey = BUILDING_PROMOTER_TABLE.translateKey;
                break;
            case ControlTableType.CadastralCommunities:
                this.tableColumns = CADASTRAL_COMMUNITY_TABLE.columns;
                this.translateKey = CADASTRAL_COMMUNITY_TABLE.translateKey;
                break;
            case ControlTableType.CollateralFactorsForAssets:
                this.tableColumns = COLLATERAL_FACTORS_FOR_ASSET.columns;
                this.translateKey = COLLATERAL_FACTORS_FOR_ASSET.translateKey;
                break;
            case ControlTableType.UserApprovalLevel:
                this.tableColumns = APPROVAL_USER_LEVEL.columns;
                this.translateKey = APPROVAL_USER_LEVEL.translateKey;
                break;
            case ControlTableType.ApprovalMatrix:
                this.tableColumns = APPROVAL_MATRIX.columns;
                this.translateKey = APPROVAL_MATRIX.translateKey
                break;
            default:
                //TODO: show warning
                break;
        }

        this.loadControlTable(event.value).pipe(
            take(1),
        ).subscribe(data => {
            if (!!data) {
                this.tableData$.next(data);
            }
        });
    }

    /**
     * load correct control table
     * 
     * @param {ControlTableType} eventValue event value from mat select
     * @returns {Observable} observable
     */
    private loadControlTable<T extends IBaseControlTable>(eventValue: ControlTableType): Observable<T[] | undefined> {
        if (eventValue === ControlTableType.UserApprovalLevel || eventValue === ControlTableType.ApprovalMatrix) {
            const selectedType: ApprovalAuthoritiesFile = eventValue === ControlTableType.UserApprovalLevel
                ? ApprovalAuthoritiesFile.UserApprovalLevel
                : ApprovalAuthoritiesFile.ApprovalMatrix;

            return this.controlTableService.loadControlTable(selectedType, true);
        } else {
            return this.controlTableService.loadControlTable(eventValue, false);
        }
    }

    /**
     * File Upload über den Button
     *
     * @param {EventTarget} target übergebene Datei
     */
    public uploadFile(target: EventTarget | null): void {
        const tmp = target as HTMLInputElement;

        if (tmp.files && tmp.files.length > 1) {
            this.notification.toast(this.translate.instant('administration.features.control-tables.maxOneFile'), { duration: 3000 });
        }

        const file = tmp.files?.item(0);

        if (!!file) {
            this.uploadControlTable(file);
        }
        //HTMLInputElement wert löschen, dass dieselbe Tabelle wieder ausgewählt werden kann
        tmp.value = ''
    }

    /**
     * Lädt eine Steuertablle hoch
     *
     * @param {File} file Steuertabelle als Excel Datei
     */
    public uploadControlTable(file: File): void {
        const selectedTableType = this.selectedTableType;

        if (selectedTableType === undefined) {
            return;
        }

        this.notification.confirmOkCancel(
            this.translate.instant('administration.features.control-tables.maxOneFile'),
            this.translate.instant('administration.features.control-tables.confirmDialogContent', {
                name: file.name,
                size: Math.round(file.size / 1024),
                type: this.enumTranslate.transform(selectedTableType, 'ControlTableType'),
            }))
            .pipe(mergeMap(result => {
                if (result !== 'submit') {
                    return of(void 0);
                }

                if (selectedTableType === ControlTableType.UserApprovalLevel || selectedTableType === ControlTableType.ApprovalMatrix) {
                    const fileType: ApprovalAuthoritiesFile = selectedTableType === ControlTableType.UserApprovalLevel
                        ? ApprovalAuthoritiesFile.UserApprovalLevel
                        : ApprovalAuthoritiesFile.ApprovalMatrix;

                    const request: IUploadApprovalControlTableRequest = {
                        fileType: fileType,
                        file: file,
                    };

                    return this.waiterService.show().pipe(
                        mergeMap(() => this.controlTableService.uploadControlTable(request)),
                        tap(data => {
                            if (!!data) {
                                this.tableData$.next(data);
                            }
                        }),
                    );
                } else {
                    const request: IUploadControlTableRequest = {
                        controlTableType: selectedTableType,
                        file: file,
                    };

                    return this.waiterService.show().pipe(
                        mergeMap(() => this.controlTableService.uploadControlTable(request)),
                        tap(data => {
                            if (!!data) {
                                this.tableData$.next(data);
                            }
                        }),
                    );
                }
            }))
            .subscribe({
                next: () => {
                    this.waiterService.hide();
                },
                error: (err: HttpErrorResponse) => {
                    let errorMessage = this.translate.instant('administration.features.control-tables.uploadError');
                    if (typeof err.error === 'string' && err.error.includes('ControlTableType is not matching with file format')) {
                        errorMessage = this.translate.instant('administration.features.control-tables.wrongTypeError');
                    }
                    this.waiterService.hide();
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        errorMessage,
                    );
                },
            });
    }

    /**
     * Wechselt die Seite der Tabelle
     *
     * @param {PageEvent} pageEvent Das PageEvent des Paginators
     */
    public changePage(pageEvent: PageEvent): void {
        this.currentPage$.next(pageEvent.pageIndex);
    }

    /**
     * Suche aktualisieren
     *
     * @param {string} search Suchbegriff
     */
    public updateSearch(search: string): void {
        this.search$.next(search);
    }

    /**
     * HostListener dragover
     *
     * @param {DragEvent} event DragEvent
     */
    public onDragOver(event: DragEvent): void {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    /**
     * HostListener dragenter
     *
     * @param {DragEvent} event DragEvent
     */
    public onDragEnter(event: DragEvent): void {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    /**
     * HostListener dragend
     *
     * @param {DragEvent} event DragEvent
     */
    public onDragEnd(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    /**
     * HostListener dragleave
     *
     * @param {DragEvent} event DragEvent
     */
    public onDragLeave(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    /**
     * HostListener drop
     *
     * @param {DragEvent} event DragEvent
     */
    public onDrop(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        if (this.selectedTableType !== undefined && event.dataTransfer?.files && event.dataTransfer?.files.length === 1) {
            this.uploadControlTable(event.dataTransfer?.files[0]);
        }
    }

    /**
     * Durchsucht die Tabelle nach einem oder mehreren Suchbegriffen
     *
     * @param {IBaseControlTable[]} data Tabellendaten
     * @param {string }searchTerm Suchbegriff
     * @returns {IBaseControlTable[]} Gefilterte Tabelle
     */
    private searchData<T extends IBaseControlTable>(data: T[], searchTerm: string): T[] {
        return data.filter(entry => {
            const values = this.tableColumns.map(column => {
                const value = entry[column as keyof IBaseControlTable] as string | number | undefined;

                if (typeof value === 'string' || value === undefined) {
                    return value?.toLowerCase();
                }

                return value.toString().toLowerCase();
            });

            return values.some(value => searchTerm.split(' ').map(searchPart => searchPart.toLowerCase()).every(searchPart => value?.includes(searchPart)));
        });
    }
}
