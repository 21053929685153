import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

/**
 * edit telecommunication costs
 */
@Component({
    selector: 'finprocess-edit-household-calc-telecom-dialog',
    templateUrl: './edit-household-calc-telecom-dialog.component.html',
    styleUrls: ['./edit-household-calc-telecom-dialog.component.scss'],
})
export class EditHouseholdCalcTelecomDialogComponent implements OnInit {

    public newBroadcastCost = 0;

    public newPhoneCost = 0;

    @Output() public editedTelecomData = new EventEmitter<{ newBroadcastCost: number; newPhoneCost: number }>();

    /**
     * Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     * Konstruktor
     *
     * @param {object} data data for telecommunication costs
     * @param {number} data.previousBroadCastCost previous broadcast costs
     * @param {number} data.previousPhoneCost previous phone costs
     * @param {MatDialogRef} dialogRef mat dialog ref
     * @param {string} locale locale 
     */
    public constructor(@Inject(MAT_DIALOG_DATA) public data: { previousBroadCastCost: number, previousPhoneCost: number },
        public dialogRef: MatDialogRef<EditHouseholdCalcTelecomDialogComponent>, @Inject(LOCALE_ID) locale: string) {

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.newBroadcastCost = this.data.previousBroadCastCost;
        this.newPhoneCost = this.data.previousPhoneCost;
    }

    /**
     * save telecommunication costs
     */
    public saveTelecommunicationCosts() {
        if (this.newBroadcastCost !== this.data.previousBroadCastCost || this.newPhoneCost !== this.data.previousPhoneCost) {
            this.dialogRef.close({ newBroadcastCost: this.newBroadcastCost, newPhoneCost: this.newPhoneCost });
        }
        else {
            this.dialogRef.close();
        }
    }
}
