import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { IDropAreaViewModel, IDropFolderViewModel } from 'app/modules/smartdoc/data';
import { AAGUID } from 'app/modules/smartdoc/data/types/aaguid';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { AddedOrUpdatedDropFolder, SmartDocConfigurationFoldersLoaded } from '../../states/smartdoc-configuration/smartdoc-configuration.actions';

import { AddedOrUpdatedDropArea, DeletedDropArea, DeletedDropFolder } from './../../states/smartdoc-configuration/smartdoc-configuration.actions';

/**
 * Service zum Administrieren der Smartdoc-Konfiguration
 */
@Injectable()
export class SmartDocConfigurationService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Lädt die SmartDoc-Ordnerstruktur
     *
     * @returns {Observable<IDropFolderViewModel[] | undefined> } Die Liste SmartDoc-Ordnerstruktur
     */
    public loadDropFolders(): Observable<IDropFolderViewModel[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.SmartDocAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IDropFolderViewModel[]>(`${this.config.getEnvironment().apiUrl}/SmartDocConfiguration/GetFolders`).pipe(
                    mergeMap((folders: IDropFolderViewModel[]) =>
                        this.store.dispatch(new SmartDocConfigurationFoldersLoaded(folders)).pipe(
                            map(() => folders),
                        ),
                    ),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * fügt einen Ordner hinzu oder aktualisiert ihn
     *
     * @param {IDropFolderViewModel} folder folder zum update/drop
     * @returns {Observable} Antwort vom Server
     */
    public addOrUpdateDropFolder(folder: Partial<IDropFolderViewModel>): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.SmartDocAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/smartdocconfiguration/dropfolder`, folder).pipe(
                    mergeMap((folderId: string) => {
                        if (folder.id === undefined) {
                            folder.id = folderId
                        }
                        return this.store.dispatch(new AddedOrUpdatedDropFolder(folder as IDropFolderViewModel))
                    }),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * löscht einen Ordner, wenn möglich
     *
     * @param {AAGUID} id folder id  zum löshen
     * @returns {Observable} Antwort vom Server
     */
    public deleteDropFolder(id: AAGUID): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.SmartDocAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete(`${this.config.getEnvironment().apiUrl}/smartdocconfiguration/dropfolder`, { params: new HttpParams().set('dropFolderId', id) }).pipe(
                    mergeMap(() => this.store.dispatch(new DeletedDropFolder(id))),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * fügt eine Dokumentenablage hinzu oder aktualisiert sie
     *
     * @param {IDropAreaViewModel} area area zum update/drop
     * @returns {Observable} Antwort vom Server
     */
    public addOrUpdateDropArea(area: Partial<IDropAreaViewModel>): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.SmartDocAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/smartdocconfiguration/droparea`, area).pipe(
                    mergeMap((areaId: string) => {
                        if (area.id === undefined) {
                            area.id = areaId
                        }
                        return this.store.dispatch(new AddedOrUpdatedDropArea(area as IDropAreaViewModel))
                    }),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * löscht eine Dokumentenablage, wenn möglich
     *
     * @param {AAGUID} id Dokumentenablage id  zum löshen
     * @returns {Observable} Antwort vom Server
     */
    public deleteDropArea(id: AAGUID): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.SmartDocAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete(`${this.config.getEnvironment().apiUrl}/smartdocconfiguration/droparea`, { params: new HttpParams().set('dropAreaId', id) }).pipe(
                    mergeMap(() => this.store.dispatch(new DeletedDropArea(id))),
                ),
                of(undefined),
            )),
        )
    }
}
