import { EnumSettings } from './../enum.settings';

/**
 * FinProcess-Fehlercode
 */
export enum FinProcessErrorCode {
    /**
     * Mandant nicht vorhanden
     * Dieser Fehler tritt ausschließlich bei einer fehlerhaften Konfiguration der Schnittstelleninstanz auf
     */
    MandantIsNull = 100,

    /**
     * Kreditanfrage nicht vorhanden oder gelöscht
     */
    FinancingMapNotFound = 101,

    /**
     * Nachricht nicht vorhanden oder gelöscht
     */
    MessageNotFound = 102,

    /**
     * Haushalt nicht vorhanden
     */
    HouseholdNotFound = 103,

    /**
     * Kreditnehmer nicht vorhanden
     */
    DebitorNotFound = 104,

    /**
     * Objekt nicht vorhanden
     */
    RealEstateNotFound = 105,

    /**
     * Rechenbeispiel nicht vorhanden
     */
    SampleCalculationNotFound = 106,

    /**
     * Esis nicht vorhanden
     */
    EsisNotFound = 107,

    /**
     * Nachricht existiert bereits
     */
    MessageExists = 202,

    /**
     * Status kann nicht gewechselt werden
     */
    StatusChangeNotAllowed = 203,

    /**
     * Mappe kann nicht wiedereingereicht werden
     */
    ImportNotAllowed = 204,

    /**
     * Dokument existiert schon
     */
    DocumentExists = 205,

    /**
     * Datei existiert schon
     */
    FileExists = 206,

    /**
     * Container existiert schon
     */
    FinancingMapContainerExists = 207,

    /**
     * Dokumente können im aktuellen Status nicht übertragen werden
     */
    DocumentTransmissionNotAllowed = 208,

    /**
     * Dokumentobjekt nicht valide
     */
    DocumentInvalid = 301,
}

export namespace FinProcessErrorCode {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {FinProcessErrorCode | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: FinProcessErrorCode | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'FinProcessErrorCode', FinProcessErrorCode, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('FinProcessErrorCode', plocale);
}
