/**
 * Dokumententyp
 */
export enum DocumentType {
    /**
     * Auszug Flächenwidmung
     */
    Map = 4,

    /**
     * Bilanzen (inkl. G+V) der letzten 3 Jahre (max. 21 Monate alt)
     */
    BalanceSheet = 28,

    /**
     * Einnahmen-/Ausgabenrechnung der letzten 3 Jahre (max. 21 Monate alt)
     */
    AccountingOnCashBasis = 29,

    /**
     * EK-Erklärung (letzte 3 Jahre)
     */
    IncomeTaxReturn = 30,

    /**
     * Aktueller Jahresstatus / Saldenliste (seit letzter ESt-Erklärung)
     */
    BalanceList = 31,

    /**
     * Aktuelle Lastschriftanzeige Finanzamt (max. 3 Monate alt)
     */
    DebitNoteTaxAuthority = 32,

    /**
     * Aktuelle Buchungsanzeige Österreichische Gesundheitskasse (max. 3 Monate alt)
     */
    BookingNoteHealthInsurance = 33,

    /**
     * Rückforderung Genossenschaft
     */
    ReclaimAssociation = 43,

    /**
     * Mietvertrag (bei Vermietung)
     */
    Lease = 47,

    /**
     * Energieausweis
     */
    EnergyCertificate = 48,

    /**
     * Standardinformation
     */
    StandardInformationVVISignature = 51,

    /**
     * Bestätigung Liegenschaftsangaben
     */
    ConfirmationRealEstateInformationSignature = 52,

    /**
     * Haushaltsrechnung
     */
    HouseholdBalanceSignature = 54,

    /**
     * Kreditlaufzeit bis in Pension
     */
    CreditDurationInRetirementSignature = 55,

    /**
     * Zusammensetzung Einkommen
     */
    CompositionOfIncomeSignature = 57,

    /**
     * Argumentation Alimente und andere Einkünfte
     */
    CompositionOfOtherIncomeSignature = 60,

    /**
     * Aufenthaltsbescheinigung
     */
    RegisterConfirmation = 63,

    /**
     * Risikoversicherung
     */
    OfferRequiredInsurance = 68,

    /**
     * Kontopreise
     */
    BankAccountPrices = 70,

    /**
     * Unterlagen bestehend bleibende Finanzierung
     */
    PersistingFinancingDocument = 74,

    /**
     * Dienstvertrag (bei RWR Karte Nachweis unbefristetes DV erforderlich)
     */
    EmploymentContract = 75,

    /**
     * Versicherungsanträge/-offerte/-polizzen
     */
    InsuranceApplications = 76,

    /**
     * Kinderbetreuungsgeldbescheid
     */
    ChildcareAllowanceNotice = 77,

    /**
     * Gebietskrankenkassenauszug (bei Saisonarbeitern notwendig (Bau, Tourismus))
     */
    RegionalHealthInsuranceStatement = 78,

    /**
     * Aktuelle Buchungsanzeige Sozialversicherung (max. 3 Monate alt)
     */
    BookingNoteSocialInsurance = 80,

    /**
     * Einkommensteuer-Bescheide der letzten 3 Jahre
     */
    IncomeTaxAssessment = 81,

    /**
     * Nutzervereinbarung
     */
    UserAgreement = 84,
    
    /**
     * Vertragsentwurf
     */
    ContractDraft = 93,

    /**
     * Individuelle Haushaltsrechnung
     */
    RiskHouseHoldCalculationIndividual = 100,

    /**
     * Kundenhaushaltsrechnung
     */
    RiskHouseHoldCalculationCustomer = 101,
}
