<div class="upload-tab">
    <div class="upload-tab-container">
        <ng-container *ngFor="let context of uploadContexts">
            <finprocess-sub-header *ngIf="uploadContexts.length > 1" [label]="context.title">

            </finprocess-sub-header>
            <ng-container *ngIf="!!context?.lists">
                <div class="file" *ngFor="let list of context.lists">
                    <ng-container *ngIf="list.list.length > 0">
                        <h4>
                            {{ list.type | finprocessEnumTranslation : 'DocumentType'}}:
                        </h4>
                        <mat-chip-listbox #chipList>
                            <div *ngFor="let calculation of list.list">
                                <mat-chip-option [removable]="!readonly" (removed)="removeFile(list.list, calculation)"
                                    (click)="openFile(calculation)">
                                    {{calculation.file.name}}
                                    <span class="remove-file" mat-button matChipRemove *ngIf="!readonly">
                                        <mat-icon>delete</mat-icon>
                                    </span>
                                </mat-chip-option>
                            </div>
                        </mat-chip-listbox>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!!uploadContexts && uploadContexts.length > 1 && !readonly">
            <finprocess-sub-header label="Hochladen"></finprocess-sub-header>
            <mat-chip-listbox #chipList>
                <div *ngFor="let context of uploadContexts; index as i">
                    <mat-chip-option (click)="openFileUpload(context)" [removable]="!readonly">{{ context.buttonLabel }}
                        <span class="remove-file" mat-button matChipRemove *ngIf="!readonly">
                            <mat-icon>upload</mat-icon>
                        </span>
                    </mat-chip-option>
                </div>
            </mat-chip-listbox>
        </ng-container>
        <ng-container *ngIf="!!uploadContexts && uploadContexts.length === 1">
            <div *ngFor="let context of uploadContexts" class="upload-button container">
                <div class="center">
                    <button mat-raised-button color="primary" class="upload-button-item"
                        (click)="openFileUpload(context)" [disabled]="readonly">
                        {{ context?.buttonLabel }}
                    </button>
                </div>
                <div class="break"></div> <!-- break -->
                <div class="center">
                    <span *ngIf="!!context?.buttonHint">{{ context.buttonHint }}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>