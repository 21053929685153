/**
 * Genauere Spezifizierung des Status einer Kreditanfrage in FinProcess
 */
export enum FinancingSubStatus {

    /**
     * Offen
     */
    Open = 10,
    
    /**
     * In Bearbeitung
     */
    Editing = 11,
    
    /**
     * Rechenbeispiel abgelehnt
     */
    SampleCalculationRejected = 12,
    
    /**
     * Nicht in Colt einreichbarer Fall
     * Muss manuell nach "altem" Weg mittels Kurzantrag-Export prozessiert werden
     */
    Manual = 70,
    
    /**
     * Über Colt prozessierbarer Fall
     */
    ViaColtApplication = 71,
    
    /**
     * Colt-Ready - Finanzierung ist fertig für Abruf mittels Colt
     */
    Ready = 72,
    
    /**
     * Colt-Retrieved - Finanzierung ist durch Colt abgerufen wurden
     */
    RetrievedByColtApplication = 73,
    
    /**
     * Finanzierung ist bereit um von einem Referenten angefordert zu werden
     */
    ReadyForReferee = 74,
    
    /**
     * Finanzierung wurde von einem Referenten angefordert und wird Bearbeitet
     */
    InProgress = 75,
    
    /**
     * Abgelehnt
     */
    Rejected = 90,
    
    /**
     * Automatisch abgelehnt
     */
    AutomaticallyRejected = 91,
    
    /**
     * Zuständigkeit Private Financing
     */
    RejectedByResponsibilityPF = 92,
    
    /**
     * Zuständigkeit Small Business
     */
    RejectedByResponsibilityGK = 93,
    
    /**
     * Rechenbeispiel angenommen
     */
    SampleCalculationAccepted = 110,
    
    /**
     * Esis abgelehnt
     */
    EsisRejected = 111,
    
    /**
     * Fall wurde von einen Referenten zurück an den Experten gegeben
     */
    RefereeRejected = 112,
    
    /**
     * Haushaltsrechnung wurde gesendet
     */
    HouseholdCalculationWaitingForAcception = 120,
    
    /**
     * Haushaltsrechnung wurde aktualisiert und muss von Einreichsystem abgerufen werden
     */
    HouseholdCalculationUpdateAvailable = 121,
    
}
