import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const GET_RISK_POSITION: IMidtermModel = {

    /**
     * Name für Übersetzungen
     */
    midtermName: 'getRiskPosition',

    /**
     * Kundennummer / NDG
     */
    economicLeaderNdg: { type: WorkflowType.String },

}
