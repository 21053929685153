export enum Collateral {
    /**
     * Keine Sicherheit
     */
    KeineSicherheit = 51,

    /**
     * Verpfändung BA-CA Sparbuch/Kontoguthaben
     */
    VerpfändungSparbuchKontoguthaben = 52,

    /**
     * Verpfändung BA-CA WP-Depot/WP-Plan
     */
    VerpfändungDepotWPPlan = 53,

    /**
     * Verpfändung fremdes (nicht BA-CA) Sparbuch/Kontoguthaben
     */
    VerpfändungFremdesSparbuchKontoguthaben = 54,

    /**
     * Verpfändung fremdes (nicht BA-CA) WP-Depot/WP-Plan
     */
    VerpfändungFremdesPDepotWPPlan = 55,

    /**
     * Personensicherheiten (Bürgen, Wechsel, Garantien)
     */
    Personensicherheiten = 56,

    /**
     * Kapitalbildende Personenversicherung
     */
    KapitalbildendePersonenversicherung = 57,

    /**
     * Risiko- u. Sachversicherung
     */
    RisikouSachversicherung = 58,

    /**
     * Inländische/Ausländische eingetragene Hypotheken
     */
    InländischeAusländischeEingetrageneHypotheken = 59,

    /**
     * Inländische/Ausländische nicht eingetragene Hypotheken
     */
    InländischeAusländischeNichtEingetrageneHypotheken = 60,

    /**
     * Offene Gehaltsverpfändung
     */
    OffeneGehaltsverpfändung = 61,

    /**
     * Sonstige Sachsicherheiten (Rückforderungsansprüche, . . .)
     */
    SonstigeSachsicherheitenRückforderungsansprüche = 62,
}
