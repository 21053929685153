import { EnumTranslationService } from '../providers/enum-translation/enum-translation.service';

/**
 * Parameter-Objekt zum Behandeln fehlender Übersetzungen
 */
export interface IEnumMissingTranslationHandlerParams {
    /**
     * Übersetzungsschlüssel, der in Übersetzungen fehlt
     */
    key: string;

    /**
     * Instanz des EnumTranslationService, der genutzt wurde
     */
    translateService: EnumTranslationService;

    /**
     * Parameter, die interpoliert werden sollten
     */
    interpolateParams?: Record<string, unknown>;
}
