<div [ngClass]="class" class="w-100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id">{{label}}</label>
        <mat-icon class="ml-1" *ngIf="overwrite && currentValue !== originalValue"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (translatedOriginalValueKey | translate) }">
            info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutAlign.lt-sm="start center">

        <div [ngClass]="class" class="w-100" fxLayout="row wrap" fxLayoutAlign="start start">


            <ng-container *ngIf="(realEstates$ | async) as realEstates">
                <div class="value-container secured-realestate" fxFlex="100" fxFlex.lt-sm="100" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start center"
                    *ngFor="let re of currentValue; let i = index"
                    [ngClass]="i+1 === currentValue?.length ? 'secured-realestate-input-none-bottom-padding' : ''">
                    <mat-form-field class="field-color" appearance="fill" fxFlex="100">
                        <input matInput [disabled]="true" [ngModelOptions]="{ updateOn: 'blur' }"
                            [ngModel]="getCurrentIRealEstateItem(re, realEstates)?.position === 0 ? ('financing.features.financing-processing.realEstate.financingRealEstate' | translate) : ('financing.features.financing-processing.realEstate.realEstate' | translate) + ' ' + getCurrentIRealEstateItem(re, realEstates)?.realEstate?.address?.zip"
                            (ngModelChange)="save($event)">
                    </mat-form-field>
                    <br>
                </div>
            </ng-container>

        </div>
    </div>
    <hr class="custom-hr">
</div>
