import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropFolderViewModel } from 'app/modules/smartdoc/data';

/**
 * Komponente für SmartDoc Folder
 */
@Component({
    selector: 'finprocess-smartdoc-folder',
    templateUrl: './smartdoc-folder.component.html',
    styleUrls: ['./smartdoc-folder.component.scss'],
})
export class SmartdocFolderComponent {

    @Input()
    public dropFolder!: IDropFolderViewModel;

    @Output()
    public editFolder = new EventEmitter<IDropFolderViewModel>();

    @Output()
    public deleteFolder = new EventEmitter<string>();

    @Output()
    public openFolder = new EventEmitter<IDropFolderViewModel>();

    /**
     * Event beim Folder offen
     */
    public onOpenFolder(): void {
        this.openFolder.emit(this.dropFolder);
    }

    /**
     * Event beim Folder editieren
     */
    public onEditFolder(): void {
        this.editFolder.emit(this.dropFolder);
    }

    /**
     * Event beim Folder löshen
     */
    public onDeleteFolder(): void {
        this.deleteFolder.emit(this.dropFolder.id);
    }


}
