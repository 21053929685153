/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Logout } from 'app/modules/auth/data';
import { IBranch } from 'app/modules/masterdata/data';

import { BranchAdded, BranchEdited, BranchStatusChanged, BranchesLoaded } from './branch.action';

/**
 * Zustandsobjekt für alle existierenden Nutzer
 */
export interface IBranchStateModel {
    /**
     * Alle aktuell existierenden Filialen
     */
    branches: IBranch[];
}

/**
 * Zustand für alle existierenden Filialen
 */
@State<IBranchStateModel>({
    name: BranchState.keyName,
    defaults: { branches: [] },
})
@Injectable()
export class BranchState {
    public static readonly keyName = 'branch';

    /**
     * Selektor für alle Filialen
     *
     * @param {BranchState} state Zustandsobjekt für alle existierenden Filialen
     * @returns {IBranch} Alle existierenden Filialen
     */
    @Selector()
    public static branches(state: IBranchStateModel): IBranch[] {
        return state.branches;
    }

    /**
     * Zustandsänderung nach dem Laden aller Filialen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BranchesLoaded} action Aktion
     */
    @Action(BranchesLoaded)
    public branchesLoaded({ patchState }: StateContext<IBranchStateModel>, { payload }: BranchesLoaded): void {
        patchState({
            branches: payload,
        });
    }

    /**
     * Zustandsänderung beim Hinzufügen einer Filiale
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BranchAdded} action Aktion
     */
    @Action(BranchAdded)
    public branchAdded({ getState, patchState }: StateContext<IBranchStateModel>, { payload }: BranchAdded): void {
        const branches = getState().branches;

        const newBranch: IBranch = {
            id: payload.id,
            disabled: !payload.branch.active,
            name: payload.branch.name,
            costCentre: payload.branch.costCentre,
            branchCode: payload.branch.branchCode,
            responsibility: payload.branch.responsibility,
        }

        // TODO: Missachtet Sortierung und fügt neue Filiale hinten an
        const newBranches = branches.concat([newBranch]);

        patchState({
            branches: newBranches,
        });
    }

    /**
     * Zustandsänderung beim Editieren einer Filiale
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BranchEdited} action Aktion
     */
    @Action(BranchEdited)
    public branchEdited({ getState, patchState }: StateContext<IBranchStateModel>, { payload }: BranchEdited): void {
        const branches = getState().branches.map(branch => {
            if (branch.id === payload.id) {
                return {
                    ...branch,
                    id: payload.id,
                    name: payload.name,
                    responsibility: payload.responsibility,
                    costCentre: payload.costCentre,
                    branchCode: payload.branchCode,
                }
            }

            return branch;
        });

        patchState({ branches });
    }

    /**
     * Zustandsänderung beim Ändern des Status einer Filiale
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BranchStatusChanged} action Aktion
     */
    @Action(BranchStatusChanged)
    public userConfirmed({ getState, patchState }: StateContext<IBranchStateModel>, { payload }: BranchStatusChanged): void {
        const branches = getState().branches.map(branch => ({
            ...branch,
            disabled: branch.id === payload.id ? payload.disabled : branch.disabled,
        }));

        patchState({ branches });
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IBranchStateModel>): void {
        setState({ branches: []});
    }
}
