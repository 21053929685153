<span div fxLayout="row" fxLayoutAlign="space-between start" class="m-4 set-size">
    <h1 class="titles"></h1>
    <button
        mat-raised-button
        color="primary"
        style="right: 35px"
        (click)="documentsSend()"
        [disabled]="existNotGeneratedDocument || !existNotSendedDocument || isStatusNotOpenOrWaiting || isBaf || ((fieldReadonly$ | async) ?? true)">
        <span
            *ngIf="!existingSendedDocument"
            [matTooltip]="'financing.features.financing-processing.householdBillsView.notPossible' | translate"
            [matTooltipDisabled]="!isBaf">
            {{ 'financing.features.financing-processing.householdBillsView.sendAllDocumentsTitle' | translate }}
        </span>
        <span *ngIf="existingSendedDocument">
            {{ 'financing.features.financing-processing.householdBillsView.sendAgainAllDocumentsTitle' | translate }}
        </span>
    </button>
</span>
<div fxLayout="column" fxLayoutAlign="start stretch" class="set-size" *ngFor="let household of householdOverviewDocuments; let i = index">
    <div fxLayout="row" fxFlex="start end" style="margin: 25px 25px 0px 45px">
        <h2 fxFlex="50" class="color-primary">Haushalt {{ household.householdPosition + 1 }}</h2>
        <span fxFlex="10"></span>
        <p fxFlex="10" class="font-w">{{ 'financing.features.financing-processing.householdBillsView.generated' | translate }}</p>
        <p fxFlex="10" class="font-w">{{ 'financing.features.financing-processing.householdBillsView.sended' | translate }}</p>
        <p fxFlex="10" class="font-w">{{ 'financing.features.financing-processing.householdBillsView.singed' | translate }}</p>
        <span fxFlex="10"></span>
    </div>

    <mat-accordion multi [togglePosition]="'before'">
        <mat-expansion-panel class="mb-3 mr-3 ml-3" *ngFor="let document of household.householdOverviewDocumentTypes; let i = index">
            <mat-expansion-panel-header>
                <div fxFlex="50" class="font-w">
                    {{ document.documentType | finprocessEnumTranslation : 'DocumentType' }}
                    {{ document?.displayName }}
                </div>
                <span fxFlex="10">
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="file.click()"
                        *ngIf="!!document?.debitorId"
                        [disabled]="document.householdOverviewDocuments.length > 1 || ((fieldReadonly$ | async) ?? true) || isStatusNotOpenOrWaiting">
                        <mat-icon matTooltip="Datei hochladen"> upload_file </mat-icon>
                    </button>
                    <input
                        type="file"
                        #file
                        [multiple]="false"
                        [disabled]="document.householdOverviewDocuments.length > 1 || isStatusNotOpenOrWaiting"
                        (change)="uploadFile($event.target, document.documentType, document.debitorId, document.householdOverviewDocuments[0]?.fileId)"
                        accept=".pdf"
                        style="display: none" />

                    <button
                        *ngIf="hasRoleExpert === true && isFinAdvisory === true && !!document?.debitorId"
                        mat-icon-button
                        color="primary"
                        (click)="generateSelfDisclosure(document?.debitorId)"
                        [disabled]="document.householdOverviewDocuments.length > 1 || ((fieldReadonly$ | async) ?? true) || isStatusNotOpenOrWaiting">
                        <mat-icon matTooltip="Selbstauskunft generieren"> person_pin </mat-icon>
                    </button>
                </span>
                <div fxFlex="10">{{ document?.generated | date : 'dd.MM.yyyy' }}</div>
                <div fxFlex="10" fxLayout="row" *ngIf="!!document.sended">
                    {{ document.sended | date : 'dd.MM.yyyy' }}
                    <mat-icon class="check">check</mat-icon>
                </div>
                <div fxFlex="10" fxLayout="row" fxLayoutAlign="center" *ngIf="!document.sended">
                    <mat-icon class="send" matTooltip="Dokument noch nicht versendet">schedule_send</mat-icon>
                </div>
                <div fxFlex="10">{{ document?.signed | date : 'dd.MM.yyyy' }}</div>
                <span fxFlex="10">
                    <button
                        *ngIf="!!document?.generated && !!document?.sended"
                        mat-icon-button
                        [disabled]="!!document?.signed || isStatusNotOpenOrWaiting || ((fieldReadonly$ | async) ?? true)"
                        color="primary"
                        (click)="signDocument($event, document.documentType, document.householdOverviewDocuments)">
                        <mat-icon matTooltip="Unterschrift gesetzt" matTooltipPosition="right">border_color</mat-icon>
                    </button>
                </span>
            </mat-expansion-panel-header>

            <div *ngIf="!!document.generated">
                <hr style="width: 100%; margin-top: 0px" />
                <div fxLayout="row" fxLayoutAlign="start stretch" style="padding-left: 42px" *ngIf="document.householdOverviewDocuments.length > 0">
                    <p fxFlex="50" class="font-w">{{ 'financing.features.financing-processing.householdBillsView.fileName' | translate }}</p>
                    <div fxFlex="10" class="font-w">{{ 'financing.features.financing-processing.householdBillsView.openDocument' | translate }}</div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start stretch" style="padding-left: 42px" *ngFor="let file of document.householdOverviewDocuments">
                    <p fxFlex="50" *ngIf="!file.fileName; else showFileName">{{ document.documentType | finprocessEnumTranslation : 'DocumentType' }}</p>
                    <ng-template #showFileName>
                        <p fxFlex="50">{{ file?.fileName }}</p>
                    </ng-template>
                    <div fxFlex="10">
                        <button
                            *ngIf="!!document.generated"
                            fxLayout="column"
                            fxLayoutAlign="center center"
                            mat-mini-fab
                            color="white"
                            class="open-icon small-mat-mini-fab mr-4"
                            (click)="openFile(file.fileId, file.mimeType, file.fileName)">
                            <mat-icon color="primary" matTooltip="Dokument öffnen">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="!document.generated">
                {{ 'financing.features.financing-processing.householdBillsView.noDocumentsGenerated' | translate }}
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
