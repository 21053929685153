import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_PERSONAL_DATA_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerPersonalDataModel',

    maritalStatus: { type: WorkflowType.String },

    surnameFormer: { type: WorkflowType.String },
}
