<ng-template #selectInputTemplate 
    let-entity="entity" 
    let-fieldName="fieldName" 
    let-items="items" 
    let-valueStorageType="valueStorageType" 
    let-overwriteValueClassType="overwriteValueClassType" 
    let-showOther="showOther">
    @if(fieldReadonly === false) {
        <finprocess-select-input
            [items]="items"
            [flexDirection]="'column'"
            [transparent]="true"
            [valueStorageType]="valueStorageType ?? ''"
            [overwriteValueClassType]="overwriteValueClassType" 
            [readonly]="fieldReadonly$"
            [entity]="entity" 
            [fieldName]="fieldName"
            [showOther]="showOther ?? false">
        </finprocess-select-input>
    } @else {
        <finprocess-overwrite-value
            [items]="items"
            [entity]="entity"
            [fieldName]="fieldName">
        </finprocess-overwrite-value>
    }
</ng-template>

<ng-template #textboxInputTemplate 
    let-entity="entity" 
    let-fieldName="fieldName" 
    let-overwriteValueClassType="overwriteValueClassType" 
    let-entityClassType="entityClassType"
    let-isFinprocessFormatNdg="isFinprocessFormatNdg">
    @if(fieldReadonly === false) {   
        <finprocess-textbox-input
            [isFinprocessFormatNdg]="isFinprocessFormatNdg ?? false"
            [flexDirection]="'column'"
            [transparent]="true"
            [entity]="entity" 
            [fieldName]="fieldName"
            [overwrite]="!overwriteValueClassType ? false : true" 
            [overwriteValueClassType]="overwriteValueClassType"
            [entityClassType]="entityClassType"
            [readonly]="fieldReadonly$">
        </finprocess-textbox-input>
    } @else {
        <div>
            @if(overwriteValueClassType) {
                <finprocess-overwrite-value
                    [entity]="entity"
                    [fieldName]="fieldName">
                </finprocess-overwrite-value>
            } @else {
                {{ entity[fieldName] ?? '-' }}
            }
        </div>
    }
</ng-template>

<ng-template #textareaInputTemplate 
    let-entity="entity" 
    let-fieldName="fieldName"
    let-overwriteValueClassType="overwriteValueClassType">
    @if(fieldReadonly === false) {
        <div class="textarea-input-wrapper">
            <finprocess-textarea-input
                [flexDirection]="'column'"
                [transparent]="true"
                [placeholder]="'...'"
                [overwriteValueClassType]="overwriteValueClassType"
                [readonly]="fieldReadonly$" 
                [entity]="entity" 
                [fieldName]="fieldName">
            </finprocess-textarea-input>
        </div>
    } @else {
        <finprocess-overwrite-value
            [entity]="entity"
            [fieldName]="fieldName">
        </finprocess-overwrite-value>
    }
</ng-template>

<ng-template #integerInputTemplate 
    let-entity="entity" 
    let-fieldName="fieldName"
    let-overwriteValueClassType="overwriteValueClassType">
    @if(fieldReadonly === false) {
        <finprocess-integer-input
            [flexDirection]="'column'"
            [transparent]="true"
            [placeholder]="'...'" 
            [overwriteValueClassType]="overwriteValueClassType"
            [readonly]="fieldReadonly$" 
            [entity]="entity"
            [fieldName]="fieldName">
        </finprocess-integer-input>
    } @else {
        <finprocess-overwrite-value
            [entity]="entity"
            [fieldName]="fieldName">
        </finprocess-overwrite-value>
    }
</ng-template>

<ng-template #datepickerInputTemplate 
    let-entity="entity" 
    let-fieldName="fieldName"
    let-overwriteValueClassType="overwriteValueClassType">
    @if(fieldReadonly === false) {
        <finprocess-datepicker-input
            [flexDirection]="'column'" 
            [transparent]="true"
            [entity]="entity" 
            [fieldName]="fieldName"
            [overwriteValueClassType]="overwriteValueClassType"
            [readonly]="fieldReadonly$">
        </finprocess-datepicker-input>
    } @else {
        <finprocess-overwrite-value
            [entity]="entity"
            [fieldName]="fieldName"
            [dateFormat]="true">
        </finprocess-overwrite-value>
        <!-- <div>{{ OverwriteHelperService.getMergedOverwriteValue(entity, fieldName, entity[fieldName], true) ? (OverwriteHelperService.getMergedOverwriteValue(entity, fieldName, entity[fieldName], true) | date : 'dd.MM.yyyy') : '-' }}</div> -->
    }
</ng-template>

<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" rigthClass="pb-0 px-4" leftClass="pb-0" [flexMd]="65">
    <div left *ngIf="(financing$ | async) as financing">
        <div class="debitor-chip-navigation">
            <ucba-chip-list *ngIf="(debitors$ | async) as debitors">
                <ucba-chip 
                    *ngFor="let deb of debitors; index as i" 
                    [color]="'primary'" 
                    (click)="debitorClicked(i)">
                    <div [style]="deb.debitor.isOwnEmployee || deb.debitor.id === (economicLeader$ | async)?.id ? 'float: left' : ''">
                        {{ deb.firstNameOverwrite }} {{ deb.lastNameOverwrite }}
                    </div>
                    <mat-icon 
                        *ngIf="deb.debitor.isOwnEmployee" 
                        class="ml-1 bank-logo"
                        style="font-size: small;"
                        svgIcon="finprocess-bank-logo">
                    </mat-icon>
                    <mat-icon 
                        *ngIf="deb.debitor.id === (economicLeader$ | async)?.id" 
                        class="ml-1">
                        star
                    </mat-icon>
                </ucba-chip>
            </ucba-chip-list>
        </div>

        <ucba-table *ngIf="selectedDebitor.value" [noAlternatingColumnColors]="true">
            <input type="hidden" [value]="selectedDebitor.value.debitor.id" />
            <!-- Allgemein -->
            <ng-template ucba-table-header>
                <div class="note-container" [attr.ucba-column-span]="'span 2'" fxLayout="row" fxLayoutAlign="space-between center">
                    <div>{{'financing.features.financing-processing.person.subHeaderGeneral' | translate}}</div>
                    <finprocess-note [type]="FinprocessEntity.Debitor" [userID]="userID ?? ''" [entityID]="(selectedDebitor | async)?.debitor?.id"></finprocess-note>
                </div>
            </ng-template>
            <!-- Haushaltsposition -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.housholdNumber' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!(fieldReadonly === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="householdNumber$"
                        [fxValueAlignment]="'start'" [format]="'1.0-0'">
                    </finprocess-calculation-display>
                </div>
            </ng-template>
            @if((branchesItems$ | async); as branches) {
                <!-- Wunschfiliale Neukunden -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.branchId' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: branches,
                            valueStorageType: ValueStorageType.Guid,
                            overwriteValueClassType: OverwriteValueClassType.FinProcessContainer,
                            entity: financing,
                            fieldName: 'branchOfChoice'
                        }">
                    </ng-container>
                </ng-template>
            }
            @if(financing.mandantType !== MandantType.FinAdvisory) {
                @if((salesPartnerCenterItems$ | async); as salesPartnerCenterItems) {
                    <!-- Vertriebspartnercenter -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.salesPartnerCenterId' | translate }}
                        </div>
                        <div [class.edit-mode-wrapper]="fieldReadonly === false">
                            {{ findArrayElementWithId(salesPartnerCenterItems, OverwriteHelperService.getMergedOverwriteValue(financing, 'salesPartnerCenterId', financing.salesPartnerCenterId, true))?.displayName ?? '-' }}
                        </div>
                    </ng-template>
                }
            }
            <!-- Voraussichtliche Kreditlaufzeit -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.person.subHeaderCreditDuration' | translate}}</div>
            </ng-template>
            <!-- Angenommene Laufzeit in Monaten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.assumedDuration' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="integerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.FinancingMapOverwriteValue,
                        entity: financing,
                        fieldName: 'assumedDuration'
                    }">
                </ng-container>
            </ng-template>
            <!-- Alter Laufzeitende in Jahren -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.ageDurationEnd' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!(fieldReadonly === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="ageDurationEndMapped$"
                        [fxValueAlignment]="'start'" [format]="'1.0-0'">
                    </finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Hinweise zu Kreditlaufzeit bei Eintritt in Pension -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.creditDurationInRetirement' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textareaInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'creditDurationInRetirement'
                    }">
                </ng-container>
            </ng-template>
            <!-- Kreditnehmer -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">
                    @if(selectedDebitor.value.firstNameOverwrite && selectedDebitor.value.lastNameOverwrite) {
                        {{ selectedDebitor.value.firstNameOverwrite }} {{ selectedDebitor.value.lastNameOverwrite }}
                    } @else {
                        {{'financing.features.financing-processing.person.debitor' | translate}}
                    }
                </div>
            </ng-template>
            <!-- Kundennummer (NDG) -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.customerNumber' | translate }}
                </div>
                <div class="w-100" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
                    <div style="flex-grow: 1">
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                            context: { 
                                isFinprocessFormatNdg: true,
                                overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                                entity: selectedDebitor.value.debitor,
                                fieldName: 'customerNumber'
                            }">
                        </ng-container>
                    </div>
                    <button 
                        *ngIf="(dgaAvailable$ | async) ?? false"
                        [disabled]="(fieldReadonly$ | async) ?? true" 
                        mat-raised-button color="primary" 
                        (click)="getNdg(selectedDebitor.value.debitor, financing.id, financing.salesPartnerCenterId, selectedDebitor.value.householdId)">
                        NDG Abfrage
                    </button>
                </div>
            </ng-template>
            <!-- GKundenNr. -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'dashboard.features.dashboard.communityCustomerNumber' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        isFinprocessFormatNdg: true,
                        overwriteValueClassType: OverwriteValueClassType.FinancingMapOverwriteValue,
                        entity: financing,
                        fieldName: 'communityCustomerNumber'
                    }">
                </ng-container>
            </ng-template>
            <!-- Anrede -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.gender' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="selectInputTemplate; 
                    context: { 
                        items: genderItems,
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'gender'
                    }">
                </ng-container>
            </ng-template>
            <!-- Titel -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.title' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'title'
                    }">
                </ng-container>
            </ng-template>
            <!-- Vorname -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.firstName' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'firstName'
                    }">
                </ng-container>
            </ng-template>
            <!-- Nachname -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.lastName' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'lastName'
                    }">
                </ng-container>
            </ng-template>
            <!-- Straße -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.street' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'street'
                    }">
                </ng-container>
            </ng-template>
            <!-- Hausnummer -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.streetNumber' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'streetNumber'
                    }">
                </ng-container>
            </ng-template>
            <!-- Stiege -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.stairway' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'stairway'
                    }">
                </ng-container>
            </ng-template>
            <!-- Top -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.top' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'top'
                    }">
                </ng-container>
            </ng-template>
            <!-- Postleitzahl -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.zip' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'zip'
                    }">
                </ng-container>
            </ng-template>
            <!-- Ort -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.city' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        entity: selectedDebitor.value.debitor.homeAddress,
                        fieldName: 'city'
                    }">
                </ng-container>
            </ng-template>
            @if((allCountriesBirthCountry$ | async); as allCountries) {
                <!-- Land -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.country' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: allCountries,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                            entity: selectedDebitor.value.debitor.homeAddress,
                            fieldName: 'country',
                            showOther: true
                        }">
                    </ng-container>
                </ng-template>
            }
            <!-- Telefonnummer (tagsüber) -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.phoneNumber' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'phoneNumber'
                    }">
                </ng-container>
            </ng-template>
            <!-- Mobil -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.mobileNumber' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'mobileNumber'
                    }">
                </ng-container>
            </ng-template>
            <!-- E-Mail-Adresse -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.email' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'email'
                    }">
                </ng-container>
            </ng-template>
            <!-- Geburtsdatum -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.birthday' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="datepickerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'birthday'
                    }">
                </ng-container>
            </ng-template>
            <!-- Geburtsort -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.birthPlace' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'birthPlace'
                    }">
                </ng-container>
            </ng-template>
            @if((allCountriesBirthCountry$ | async); as allCountries) {
                <!-- Geburtsland -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.birthcountry' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: allCountries,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'birthcountry',
                            showOther: true
                        }">
                    </ng-container>
                </ng-template>
            }
            @if((allCountriesCiticen$ | async); as allCountries) {
                <!-- Staatsbürgerschaft -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.citizenship' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: allCountries,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'citizenship',
                            showOther: true
                        }">
                    </ng-container>
                </ng-template>
            }
            @if((allCountriesCiticen$ | async); as allCountries) {
                <!-- Weitere Staatsbürgerschaften -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.additionalCitizenship' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: allCountries,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'additionalCitizenship',
                            showOther: true
                        }">
                    </ng-container>
                </ng-template>
            }
            <!-- TIN Nummer -->
            <ng-template ucba-table-row *ngIf="showTinNumber | async">
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.tinNumber' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'tinNumber'
                    }">
                </ng-container>
            </ng-template>
            @if((allCountriesCiticen$ | async); as allCountries) {
                <!-- Wohnart -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.housingType' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: housingTypeItems,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'housingType',
                        }">
                    </ng-container>
                </ng-template>
            }
            <!-- Wohnhaft seit -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.livingAtHomeAddressSince' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="datepickerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'livingAtHomeAddressSince'
                    }">
                </ng-container>
            </ng-template>
            @if((allCountriesTaxResidence$ | async); as allCountries) {
                <!-- Steuerdomizil/e -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.taxResidence' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: allCountries,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'taxResidence',
                            showOther: true
                        }">
                    </ng-container>
                </ng-template>
            }
            <!-- Familienstand -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.maritalStatus' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="selectInputTemplate; 
                    context: { 
                        items: maritalStatusItems,
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'maritalStatus',
                    }">
                </ng-container>
            </ng-template>
            <!-- Anzahl Kinder -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.childrenCount' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="integerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'childrenCount'
                    }">
                </ng-container>
            </ng-template>
            <!-- Anzahl unterhaltsberechtigter Personen für GWB und FIRE -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.dependentPersonsCount' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="integerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'dependentPersonsCount'
                    }">
                </ng-container>
            </ng-template>
            <!-- Eigenes KFZ vorhanden -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.ownCarExists' | translate }}
                </div>
                @if(fieldReadonly === false) {
                    <finprocess-boolean-input
                        [flexDirection]="'column'"
                        [transparent]="true"
                        [entity]="selectedDebitor.value.debitor" 
                        fieldName="ownCarExists"
                        [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-boolean-input>
                } @else {
                    <finprocess-overwrite-value
                        [entity]="selectedDebitor.value.debitor"
                        fieldName="ownCarExists">
                    </finprocess-overwrite-value>
                }
            </ng-template>
            <!-- Kontoverbindung -->
            @if((bankAccounts$ | async); as bankAccounts) {
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.bankAccount' | translate }}
                    </div>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; 
                        context: { 
                            items: bankAccounts,
                            valueStorageType: ValueStorageType.String,
                            overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                            entity: selectedDebitor.value.debitor,
                            fieldName: 'bankAccount'
                        }">
                    </ng-container>
                </ng-template>
            }
            <!-- Kunde wäre bereit Konto zur Bank Austria zu verlegen -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.bankAustriaStatus' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="selectInputTemplate; 
                    context: { 
                        items: bankAustriaStatusItems,
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'bankAustriaStatus'
                    }">
                </ng-container>
            </ng-template>
            <!-- Höchste abgeschlossene Ausbildung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.levelOfTraining' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="selectInputTemplate; 
                    context: { 
                        items: levelOfTrainingItems,
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'levelOfTraining'
                    }">
                </ng-container>
            </ng-template>
            <!-- Kundenrating -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.person.subHeaderCustomerRating' | translate}}</div>
            </ng-template>
            @if(selectedDebitor.value.debitor?.ratingResult?.pollingStatus === undefined) {
                <ng-template ucba-table-row>
                    <div [attr.ucba-column-span]="'span 2'">{{ 'financing.features.financing-processing.person.scoringNotCalled' | translate}}</div>
                </ng-template>
            } @else {
                <!-- Status -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.status' | translate }}
                    </div>
                    <div>{{ findArrayElementWithId(ratingPollingStatusSelectItems, selectedDebitor.value.debitor.ratingResult?.pollingStatus)?.displayName ?? '-' }}</div>
                </ng-template>
                <!-- Rating -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.rating' | translate }}
                    </div>
                    <div>{{ selectedDebitor.value.debitor.ratingResult?.rating && selectedDebitor.value.debitor.ratingResult?.rating !== '' ? selectedDebitor.value.debitor.ratingResult?.rating : '-' }}</div>
                </ng-template>
                <!-- Datum -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.date' | translate }}
                    </div>
                    <div>{{ selectedDebitor.value.debitor.ratingResult?.date ? (selectedDebitor.value.debitor.ratingResult?.date | date : 'dd.MM.yyyy') : '-' }}</div>
                </ng-template>
                <!-- Rating Status -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.ratingStatus' | translate }}
                    </div>
                    <div>{{ selectedDebitor.value.debitor.ratingResult?.ratingStatus && selectedDebitor.value.debitor.ratingResult?.ratingStatus !== '' ? selectedDebitor.value.debitor.ratingResult?.ratingStatus : '-' }}</div>
                </ng-template>
                <!-- Anmerkungen zu den Personendaten -->
                <ng-template ucba-table-row>
                    <div fxLayoutAlign="flex-start center">
                        {{ 'financing.features.financing-processing.person.notesPerson' | translate }}
                    </div>
                    <div>{{ selectedDebitor.value.debitor.ratingResult?.comment && selectedDebitor.value.debitor.ratingResult?.comment !== '' ? selectedDebitor.value.debitor.ratingResult?.comment : '-' }}</div>
                </ng-template>
            }
            <ng-template ucba-table-row>
                <div [attr.ucba-column-span]="'span 2'" class="py-2">                      
                    <div *ngIf="getDebtorNdg(selectedDebitor.value.debitor) === undefined" class="pb-1" style="color: red;">{{ 'financing.features.financing-processing.person.ratingWarn' | translate}}</div>
                    <div class="w-100" fxLayout="column" fxLayoutAlign="end end">
                        <button [disabled]="(fieldReadonly$ | async) ?? true || getDebtorNdg(selectedDebitor.value.debitor) === undefined"  mat-raised-button color="primary" (click)="refreshScoring(selectedDebitor.value.debitor, selectedDebitor.value.householdId)">
                            {{ 'financing.features.financing-processing.person.calcNewScoring' | translate }}
                        </button>
                    </div>
                </div> 
            </ng-template>
            <!-- Arbeitsverhältnis -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.person.subHeaderEmployeeStatus' | translate}}</div>
            </ng-template>
            <!-- Anstellungsverhältnis -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.employeeStatus' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="selectInputTemplate; 
                    context: { 
                        items: employeeStatusItems,
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'employeeStatus'
                    }">
                </ng-container>
            </ng-template>
            <!-- Beruf -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.profession' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'profession'
                    }">
                </ng-container>
            </ng-template>
            <!-- Arbeitgeber -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.employerName' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'employerName'
                    }">
                </ng-container>
            </ng-template>
            <!-- Branchencode Arbeitgeber -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.employerBranchCode' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                    context: { 
                        entityClassType: entityClassType.Debitor,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'employerBranchesCode'
                    }">
                </ng-container>
            </ng-template>
            <!-- Beschäftigt seit -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.employedSince' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="datepickerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'employedSince'
                    }">
                </ng-container>
            </ng-template>
            <!-- Vorbeschäftigungsdauer in Monaten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.previousEmploymentDuration' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="integerInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'previousEmploymentDuration'
                    }">
                </ng-container>
            </ng-template>
            <!-- Mitarbeiter der UCBA? -->
            <ng-template ucba-table-row *ngIf="isEmployeeBranch">
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.ucbaEmployee' | translate }}
                </div>
                @if(fieldReadonly === false) {
                    <finprocess-boolean-input
                        [flexDirection]="'column'"
                        [transparent]="true"
                        [entity]="selectedDebitor.value.debitor" 
                        fieldName="isOwnEmployee" 
                        [overwrite]="false"
                        [entityClassType]="entityClassType.Debitor" 
                        [readonly]="fieldReadonly$">
                    </finprocess-boolean-input>
                } @else {
                    <finprocess-overwrite-value
                        [entity]="selectedDebitor.value.debitor"
                        fieldName="isOwnEmployee">
                    </finprocess-overwrite-value>
                }
            </ng-template>
            @if(selectedDebitor.value.debitor.careers.length > 0) {
                <!-- Beruflicher Werdegang -->
                <ng-template ucba-table-header>
                    <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.person.career' | translate}}</div>
                </ng-template>
                @for(career of selectedDebitor.value.debitor.careers; track $index) {
                    <!-- Von -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.careerFrom' | translate }}
                        </div>
                        <ng-container *ngTemplateOutlet="datepickerInputTemplate; 
                            context: { 
                                overwriteValueClassType: OverwriteValueClassType.CareerOverwriteValue,
                                entity: career,
                                fieldName: 'from'
                            }">
                        </ng-container>
                    </ng-template>
                    <!-- Bis -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.careerTo' | translate }}
                        </div>
                        <ng-container *ngTemplateOutlet="datepickerInputTemplate; 
                            context: { 
                                overwriteValueClassType: OverwriteValueClassType.CareerOverwriteValue,
                                entity: career,
                                fieldName: 'to'
                            }">
                        </ng-container>
                    </ng-template>
                    <!-- Tätigkeit -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.careerWork' | translate }}
                        </div>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                            context: { 
                                overwriteValueClassType: OverwriteValueClassType.CareerOverwriteValue,
                                entity: career,
                                fieldName: 'work'
                            }">
                        </ng-container>
                    </ng-template>
                    <!-- Branche -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.careerBranch' | translate }}
                        </div>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; 
                            context: { 
                                overwriteValueClassType: OverwriteValueClassType.CareerOverwriteValue,
                                entity: career,
                                fieldName: 'branch'
                            }">
                        </ng-container>
                    </ng-template>
                    <!-- Anmerkungen -->
                    <ng-template ucba-table-row>
                        <div fxLayoutAlign="flex-start center">
                            {{ 'financing.features.financing-processing.person.careerNotes' | translate }}
                        </div>
                        <ng-container *ngTemplateOutlet="textareaInputTemplate; 
                            context: { 
                                overwriteValueClassType: OverwriteValueClassType.CareerOverwriteValue,
                                entity: career,
                                fieldName: 'notes'
                            }">
                        </ng-container>
                    </ng-template>
                }
            }
            <!-- Anmerkungen -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.person.subHeaderNotices' | translate}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.person.notesPerson' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textareaInputTemplate; 
                    context: { 
                        overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                        entity: selectedDebitor.value.debitor,
                        fieldName: 'notesPerson'
                    }">
                </ng-container>
            </ng-template>
        </ucba-table>
    </div>

    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$" [sortingFunction]="customBorrowerSortingFunction"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>