/**
 * ActionId Typen
 */
export enum ActionIdType {
    /**
     * Calculate
     */
    Calculate,

    /**
     * CalculateAndSave
     */
    CalculateAndSave,

    // /**
    //  * Result
    // -> nicht mehr benötigt
    // -> Im Backend-Enum aber noch vorhanden
    //  */
    // Result,
}
