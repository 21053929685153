import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AuthorizationService } from './../../../../data';

// TODO SPe: Implement

/**
 * Guard für benötigte Verantwortlichkeit (Organisationseinheit)
 */
@Injectable()
export class OrganisationalUnitFilterGuard {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(private authorizationService: AuthorizationService) {

    }

    /**
     * Stellt sicher, dass die Route nur mit der zulässigen Verantwortlichkeit (Organisationseinheit) erreicht werden kann
     *
     * @param {ActivatedRouteSnapshot} route Zu aktivierende Route
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public canActivate(route: ActivatedRouteSnapshot): boolean {
        throw new Error(`OrganisationalUnitFilterGuard: Use ${typeof(this.authorizationService)}: Not implemented`);
    }

    /**
     * Stellt sicher, dass die Kindroute nur mit der zulässigen Verantwortlichkeit (Organisationseinheit) erreicht werden kann
     *
     * @param {ActivatedRouteSnapshot} childRoute Zu aktivierende Route
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
        throw new Error(`OrganisationalUnitFilterGuard: Use ${typeof(this.authorizationService)}: Not implemented`);
    }
}
