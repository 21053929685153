import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'app/modules/shared';
import { Observable, of } from 'rxjs';

import { LoginStateType } from '../../../../data/enums';
import { ICanComponentDeactivate } from '../../interfaces';

import { AuthenticationService } from './../../../../data/';

/**
 * Guard für aktive Authentifzierung
 */
@Injectable()
export class AuthenticationGuard {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {Router} router Router-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     */
    public constructor(private authenticationService: AuthenticationService, private router: Router, private config: ConfigService) { }

    /**
     * Stellt sicher, dass die Route nur mit aktiver Authentifizierung erreicht werden kann
     *
     * @returns {Promise<boolean>} Route kann aktiviert werden
     */
    public canActivate(): Observable<boolean> {
        return this.checkAuthentication();
    }

    /**
     * Stellt sicher, dass die Kindrouten nur mit aktiver Authentifizierung erreicht werden können
     *
     * @returns {Promise<boolean>} Kindrouten können aktiviert werden
     */
    public canActivateChild(): Observable<boolean> {
        return this.checkAuthentication();
    }

    /**
     * Stellt sicher, dass ein Modul nur mit aktiver Authentifizierung geladen werden kann
     *
     * @returns {Promise<boolean>} Modul kann geladen werden
     */
    public canLoad(): Observable<boolean> {
        return this.checkAuthentication();
    }

    /**
     * Stellt sicher, dass bestimmte Routen nur unter Bedingungen verlassen werden können
     *
     * @param {any} component Komponente
     * @returns {boolean} Route kann verlassen werden
     */
    // eslint-disable-next-line class-methods-use-this
    public canDeactivate(component: ICanComponentDeactivate): boolean {
        return component.canDeactivate !== undefined ? component.canDeactivate() : true;
    }

    /**
     * Prüft, ob eine gültige Authentifizierung vorhanden ist
     *
     * @returns {Promise<boolean>} Aktive Authentifzierung vorhanden
     */
    private checkAuthentication(): Observable<boolean> {
        switch (this.authenticationService.loginState) {
            case LoginStateType.LoggedIn:
                return of(true);
            case LoginStateType.Locked:
                return new Observable(subscriber => {
                    this.router.navigateByUrl('/auth/locked')
                        .then(() => {
                            subscriber.next(true);
                            subscriber.complete();
                        })
                        .catch(err => {
                            subscriber.error(err);
                        });
                });
            case LoginStateType.NotLoggedIn:
            default:
                window.open(`${this.config.getEnvironment().baseUrl}/Authorization/LoginWithExternalAccount`, '_self')
                return of(false);
        }
    }

}
