export enum ProductPackageType {
    /**
     * Darlehen
     */
    Credit,

    /**
     * Ergänzungsdarlehen/Nachfinanzierungsdarlehen
     */
    ComfortCredit, 

    /**
     * B&W Konto: Zwischenfinanzierung ohne Anschlussfinanzierung
     */

    BWWithoutFollowUpFinancing,

    /**
     * B&W Konto: Zwischenfinanzierung mit Anschlussfinanzierung
     */
    BWWithFollowUpFinancing,

    /**
     * Garantie
     */
    Guarantee,
}
