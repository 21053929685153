import { SmartDocJobStatus } from '../../enums/smartdoc-job-status.enum';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocSortingStartedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Aktueller SmartDoc-Jobstatus
     */
    jobStatus: SmartDocJobStatus;
}

/**
 * Klasse für Aktion
 */
export class SmartDocSortingStarted {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocSortingStarted';
    /**
     * Constructor
     *
     * @param {ISmartDocSortingStartedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocSortingStartedPayloadType) { }
}
