import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { CreditLine } from '../../enums';
import { ICreditLineCalculation, ISampleCalculationContainer } from '../../interfaces';
import { CreditLineCalculationAddedOrUpdated, SampleCalculationContainerLoaded, SampleCalculationContainerSaved } from '../../states';


/**
 * Service zum Bearbeiten der Rechenbeispiele
 */
@Injectable()
export class SampleCalculationService {

    /**
     * Standard Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private authorizationService: AuthorizationService,
        private config: ConfigService,
        private httpClient: HttpClient,
        private store: Store,
    ) {

    }

    /**
     * Lädt einen Rechenbeispiel Container inklusive Kreditlinien
     *
     * @param {UUID} id ID der Finanzierung
     * @returns {Observable<ISampleCalculationContainer | undefined>} Rechenbeispiel Container
     */
    public loadSampleCalculation(id: UUID): Observable<ISampleCalculationContainer | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<ISampleCalculationContainer>(`${this.config.getEnvironment().apiUrl}/SampleCalculations/SampleCalculationWithCreditLines`, {
                    params: {
                        financingId: id,
                    },
                }).pipe(
                    mergeMap(sampleCalculationContainer => this.store.dispatch(new SampleCalculationContainerLoaded(sampleCalculationContainer)).pipe(
                        map(() => sampleCalculationContainer),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Speihert Rechenbeispiel, inklusive Kreditlinien
     *
     * @param {ISampleCalculationContainer} sampleCalculationContainer SampleCalculationContainer
     * @returns {Observable<ISampleCalculationContainer | undefined>} Rechenbeispiel Container
     */
    public saveSampleCalculation(sampleCalculationContainer: ISampleCalculationContainer): Observable<ISampleCalculationContainer | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<UUID>(`${this.config.getEnvironment().apiUrl}/SampleCalculations/SampleCalculationWithCreditLines`, sampleCalculationContainer, {
                    params: {
                        financingId: sampleCalculationContainer.id,
                    },
                }).pipe( //id ignorieren
                    mergeMap(() => this.store.dispatch(new SampleCalculationContainerSaved(sampleCalculationContainer)).pipe(
                        map(() => sampleCalculationContainer),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Aktualisiert einen Rechenbeispielcontainer
     *
     * @param {ISampleCalculationContainer} sampleCalculationContainer Rechenbeispielcontainer
     * @returns {Observable<void>} Leere Serverantwort
     */
    public updateSampleCalculation(sampleCalculationContainer: ISampleCalculationContainer): Observable<void> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/SampleCalculations/SampleCalculation`, sampleCalculationContainer, {
                    params: {
                        financingId: sampleCalculationContainer.id,
                    },
                }).pipe( //id ignorieren
                    mergeMap(() => this.store.dispatch(new SampleCalculationContainerSaved(sampleCalculationContainer))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Aktualisiert eine Kreditlinie
     *
     * @param {ISampleCalculationContainer} creditLineCalculation Kreditlinie
     * @param {string} financingId Id der Finanzierung
     * @returns {Observable<ISampleCalculationContainer | undefined>} Rechenbeispiel Container
     */
    public updateCreditLineCalculation(creditLineCalculation: ICreditLineCalculation, financingId: string): Observable<ICreditLineCalculation | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/SampleCalculations/CreditLineCalculation`, creditLineCalculation, {
                    params: {
                        financingId,
                    },
                }).pipe(
                    mergeMap(() => this.store.dispatch(new CreditLineCalculationAddedOrUpdated(creditLineCalculation)).pipe(
                        map(() => creditLineCalculation),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Hinzufügt Kreditlinie
     *
     * @param {string} financingID Financing id
     * @param {CreditLine} creditLine Kreditlinie
     * @returns {Observable<UUID | undefined>} Kreditlinie Id
     */
    public addCreditLine(financingID: string, creditLine: ICreditLineCalculation): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<UUID>(`${this.config.getEnvironment().apiUrl}/SampleCalculations/CreditLineCalculation`, creditLine, {
                    params: {
                        financingId: financingID,
                    },
                }).pipe(
                    map((id: UUID) => id),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht eine Kreditlinie
     *
     * @param {string} financingId ID der Finanzierung
     * @param {string} creditLineCalculationId ID der KReditlinie
     * @returns {Observable<void | undefined>} Leere Serverantwort
     */
    public deleteCreditLineCalculation(financingId: string, creditLineCalculationId: string): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete<void>(`${this.config.getEnvironment().apiUrl}/SampleCalculations/CreditLineCalculation`, {
                    params: {
                        financingId,
                        creditLineCalculationId,
                    },
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Ruft Rechenbeispiele und Tilgungspläne für alle Kreditlinien ab
     *
     * @param {string} financingId ID der Finanzierung
     * @returns {Observable<Blob | undefined>} Zip mit allen Rechenbeispielen
     */
    public getAllDocuments(financingId: string): Observable<Blob | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/SampleCalculations/SampleCalculationDocuments`, { params: {
                    financingId,
                },
                responseType: 'blob',
                }),
                of(undefined),
            )),
        )
    }
}
