<div [ngClass]="class" class="w-100" fxLayout="row wrap" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id">{{label}}</label>
        <mat-icon class="ml-1" *ngIf="(booleanObservable | async) !== (booleanOriginalObservable | async)"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: ((booleanOriginalObservable | async) ? 'financing.features.financing-processing.true' : 'financing.features.financing-processing.false') | translate }">
            info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start start"
        fxLayoutAlign.lt-sm="start center">
        <mat-radio-group [disabled]="true" [ngModel]="(booleanObservable | async)">
            <mat-radio-button #yes fxFlex="50" [value]="true">
                {{('financing.features.financing-processing.true' | translate)}}
            </mat-radio-button>
            <mat-radio-button #no fxFlex="50" [value]="false">
                {{('financing.features.financing-processing.false' | translate)}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>