import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { NtagReleaseNoteService } from '@ntag-ef/release-notes';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthenticationService, LoginStateType, Logout, UserService } from 'app/modules/auth/data';
import { ReleaseNotesService } from 'app/modules/navigation/data/services';
import { map, mergeMap, of, tap } from 'rxjs';

/**
 * Komponente zum Fangen der SAML Response
 */
@Component({
    selector: 'finprocess-saml',
    templateUrl: './saml.component.html',
    styleUrls: ['./saml.component.scss'],
})
export class SamlComponent implements OnInit {

    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router-Injektor
     * @param {ActivatedRoute} route ActivatedRoute Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notifactionService NotificationService-Injektor
     * @param {TranslateService} translate TranslationService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {NtagReleaseNoteService} ntagReleaseNotesService NtagReleaseNoteService-Injektor
     * @param {ReleaseNotesService} releaseNotesService ReleaseNotesService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private waiterService: WaiterService,
        private notifactionService: NotificationService,
        private translate: TranslateService,
        private userService: UserService,
        private ntagReleaseNotesService: NtagReleaseNoteService,
        private releaseNotesService: ReleaseNotesService,
        private store: Store,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        const token = this.route.snapshot.queryParams.token;

        if (!!token) {
            this.waiterService.show().pipe(
                mergeMap(() => this.store.dispatch(new Logout())),
                mergeMap(() => this.authenticationService.externalLogin(token)),
                mergeMap(result => {
                    if (result === LoginStateType.LoggedIn) {
                        return this.releaseNotesService.getUserNewestReleaseNotes(this.userService.user?.lastReadReleaseVersion).pipe(
                            mergeMap(releaseNotes => {
                                if (typeof releaseNotes !== 'boolean' && releaseNotes !== undefined) {
                                    return this.ntagReleaseNotesService.showReleases(releaseNotes).pipe(
                                        mergeMap(dialogResult => {
                                            if (dialogResult === 'ok' && Array.isArray(releaseNotes.releaseVersions) && releaseNotes.releaseVersions.length > 0) {
                                                const userId = this.userService.user?.id
                                                if (userId) {
                                                    return this.releaseNotesService.setUserReadLatestReleaseNote(userId, releaseNotes.releaseVersions[0].releaseNumber);
                                                }
                                            }
                                            return of(undefined);
                                        }),
                                    );
                                }

                                return of(undefined);
                            }),
                            map(() => result));
                    }

                    return of(result);
                }),
                tap(async result => {
                    this.waiterService.hide();
                    switch (result) {
                        case LoginStateType.LoggedIn:
                            await this.router.navigateByUrl('/');
                            break;
                        case LoginStateType.Locked:
                            await this.router.navigateByUrl('/auth/locked');
                            break;
                        case LoginStateType.NotLoggedIn:
                            this.invalidLogin();
                            break;
                        default:
                            break;
                    }
                }),
            ).subscribe();
        } else {
            this.invalidLogin();
        }
    }

    /**
     * Zeigt eine Fehlermeldung an und leitet zurück zum Login
     */
    private invalidLogin() {
        this.notifactionService.alert(
            this.translate.instant('general.error'),
            this.translate.instant('auth.features.saml.errorMessage'),
        ).subscribe(() => this.router.navigateByUrl('/'));
    }
}
