import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CollateralType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetService, AssetState, CollateralBaseType, FinancingService, IAssetInsurance, IAssetRealEstate, ICollateralAccountDetail, ICollateralInsuranceDetail, ICollateralRealEstateDetail, IFinancingStateParentDefinition, IProductPackageData } from 'app/modules/financing/data';
import { IAssetAccount } from 'app/modules/financing/data/interfaces/asset-account';
import { IProductPackage } from 'app/modules/financing/data/interfaces/product-package.interface';
import { HelperService, UUID } from 'app/modules/shared';
import { Observable, Subject, combineLatest, map, of, take, takeUntil } from 'rxjs';

/**
 * Product package collateral
 */
@Component({
    selector: 'finprocess-productpackage-collateral',
    templateUrl: './productpackage-collateral.component.html',
    styleUrls: ['./productpackage-collateral.component.scss'],
})
export class ProductpackageCollateralComponent implements OnInit, OnDestroy {

    /**
     * GenehmigungsInput
     */
    @Input()
    public approvalView = false;

    /**
     * product package id
     */
    public productPackageID?: string;

    /**
     * product package id
     */
    public riskfinancingPlanID?: string;

    /**
     * product packaga data
     */
    public productPackageData?: IProductPackageData;

    /**
     * gesamter Kreditbetrag
     */
    public totalCreditAmount = 0;

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * Arten von Sicherheiten
     */
    public collateralTypes: CollateralType[] = [];

    /**
     * CollateralTypes
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CollateralType = CollateralType;

    /**
     * CollateralBaseTypes
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CollateralBaseType = CollateralBaseType;

    /**
     * TODO: bereits erstellte Sicherheiten/AGP
     */
    public existingCollaterals = [];

    public selectedCollateralType?: CollateralType | string;

    /**
     * Collateral Data Real Estates
     */
    public collateralDataRealEstates$?: Observable<ICollateralRealEstateDetail[] | undefined>;

    /**
     * Collateral Data Insurance
     */
    public collateralDataInsurance$?: Observable<ICollateralInsuranceDetail[] | undefined>;

    /**
     * Collateral Data Accounts
     */
    public collateralDataAccounts$?: Observable<ICollateralAccountDetail[] | undefined>;

    /**
     * Asset Data Insurance
     */
    public assetInsurance?: IAssetInsurance[] | undefined;

    /**
     * Asset Data Accounts
     */
    public assetAccounts?: IAssetAccount[] | undefined;

    /**
     * Asset Data Real Estates
     */
    public assetRealEstates?: IAssetRealEstate[] | undefined;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * State Collateral Real Estates
     */
    @Select(AssetState.collateralRealEstates)
    public collateralStateRealEstates$!: Observable<ICollateralRealEstateDetail[]>;

    /**
     * State Collateral Insurance
     */
    @Select(AssetState.collateralInsurances)
    public collateralStateInsurances$!: Observable<ICollateralInsuranceDetail[]>;

    /**
     * State Collateral Accounts
     */
    @Select(AssetState.collateralAccounts)
    public collateralStateAccounts$!: Observable<ICollateralAccountDetail[]>;

    /**
     * State Asset Real Estates
     */
    @Select(AssetState.assetRealEstates)
    public assetStateRealEstates$!: Observable<IAssetRealEstate[]>;

    /**
     * State Asset Insurance
     */
    @Select(AssetState.assetInsurances)
    public assetStateInsurances$!: Observable<IAssetInsurance[]>;

    /**
     * State Asset Accounts
     */
    @Select(AssetState.assetAccounts)
    public assetStateAccounts$!: Observable<IAssetAccount[]>;

    /**
     * has AGP Real Estate
     */
    //public hasAGPRealEstate$ = new Observable<ICollateralRealEstateDetail[]>();

    /**
     * has AGP Insurance
     */
    //public hasAGPInsurance$ = new Observable<ICollateralInsuranceDetail[]>();

    /**
     * has AGP Accounts
     */
    //public hasAGPAccounts$ = new Observable<ICollateralAccountDetail[]>();


    /**
     * Konstruktor
     * 
     * @param {ActivatedRoute} activatedRoute activatedRoute
     * @param {WaiterService} waiterService waiterService
     * @param {Store} store store
     * @param {FinancingService} financingService financingService
     * @param {NotificationService} notificationService notificationservice
     * @param {TranslateService} translate translateservice
     * @param {AssetService} assetService AssetService
     * @param {ChangeDetectorRef} changeDetection ChangeDetectorRef
     */
    public constructor(private activatedRoute: ActivatedRoute, private waiterService: WaiterService, private store: Store,
        private financingService: FinancingService, private notificationService: NotificationService, private translate: TranslateService, private assetService: AssetService, private changeDetection: ChangeDetectorRef) {
        this.editingReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {

        this.collateralTypes = (HelperService.getEnumArray(CollateralType) as number[]);

        this.activatedRoute.paramMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => {
                if (!!params) {
                    this.waiterService.show();
                    const productPackageID = params.get('productPackageID') as UUID;
                    const riskFinancingPlanID = params.get('riskfinancingplanid') as UUID;
                    this.productPackageID = productPackageID;
                    this.riskfinancingPlanID = riskFinancingPlanID;
                    return { productPackageID, riskFinancingPlanID };
                } else {
                    return null;
                }
            }),
        ).subscribe({
            next: result => {
                if (!!result) {
                    const productPackage: IProductPackageData | undefined = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.productPackages);

                    //get product package data
                    if (!!productPackage) {
                        const ppClone = HelperService.clone(productPackage);
                        const filteredProductPackage = ppClone.assignProductPackages.filter(pp => pp.productPackageID === this.productPackageID);

                        this.productPackageData = {
                            assignProductPackages: filteredProductPackage,
                            financingMapID: productPackage.financingMapID,
                        };
                        this.calculateTotalCreditAmount();

                    }
                    else {
                        this.financingService.getProductPackage(result.productPackageID).pipe(take(1)).subscribe(ppData => {
                            this.productPackageData = HelperService.clone(ppData);
                            this.calculateTotalCreditAmount();
                        });
                    }
                    this.waiterService.hide();
                    this.getCollateralData();
                }
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.loadProductError'));
            },
        });
    }

    /**
     * calculate Total Credit Amount
     */
    private calculateTotalCreditAmount() {
        const selectedProductPackage: IProductPackage | undefined = this.productPackageData?.assignProductPackages.find(pp => pp.productPackageID === this.productPackageID);

        if (!!selectedProductPackage) {
            selectedProductPackage.assignProducts.forEach(pp => {
                this.totalCreditAmount += pp.creditAmount;
            });
        }
    }

    /**
     * get existing product package collaterals
     */
    public getCollateralData() {

        //get collateral data
        combineLatest([
            this.collateralStateRealEstates$,
            this.collateralStateInsurances$,
            this.collateralStateAccounts$,
        ]).pipe(take(1)).subscribe(([stateRealEstates, stateInsurances, stateAccounts]) => {
            if ((!stateRealEstates || stateRealEstates.length === 0) && (!stateInsurances || stateInsurances.length === 0) && (!stateAccounts || stateAccounts.length === 0) && !!this.productPackageID && this.riskfinancingPlanID) {
                this.waiterService.show();
                this.assetService.getCollaterals(this.productPackageID).pipe(take(1)).subscribe({
                    next: collaterals => {
                        this.collateralDataRealEstates$ = of(collaterals?.mortages);
                        this.collateralDataInsurance$ = of(collaterals?.insurances);
                        this.collateralDataAccounts$ = of(collaterals?.accounts);

                        //agp checks
                        /*  if (!!collaterals?.mortages) {
                            this.hasAGPRealEstate$ = this.hasAGPAssets(collaterals.mortages as ICollateralRealEstateDetail[]);
                        }

                        if (!!collaterals?.insurances) {
                            this.hasAGPInsurance$ = this.hasAGPAssets(collaterals.insurances as ICollateralInsuranceDetail[]);
                        }

                        if (!!collaterals?.accounts) {
                            this.hasAGPAccounts$ = this.hasAGPAssets(collaterals.accounts as ICollateralAccountDetail[]);
                        }*/

                        this.changeDetection.detectChanges();
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.getCollateralsError'));
                    },
                });
            }
            //get collateral state data
            else {
                this.collateralDataRealEstates$ = this.collateralStateRealEstates$.pipe(take(1));
                this.collateralStateRealEstates$.pipe(take(1)).subscribe(res => {
                    if (!!res) {
                        //  this.hasAGPRealEstate$ = this.hasAGPAssets(res);
                    }
                });

                this.collateralDataInsurance$ = this.collateralStateInsurances$.pipe(take(1));
                this.collateralDataInsurance$.pipe(take(1)).subscribe(res => {
                    if (!!res) {
                        //   this.hasAGPInsurance$ = this.hasAGPAssets(res);
                    }
                });

                this.collateralDataAccounts$ = this.collateralStateAccounts$.pipe(take(1));
                this.collateralDataAccounts$.pipe(take(1)).subscribe(res => {
                    if (!!res) {
                        // this.hasAGPAccounts$ = this.hasAGPAssets(res);
                    }
                });

                this.changeDetection.detectChanges();
            }
        });

        //get assets data
        combineLatest([
            this.assetStateRealEstates$,
            this.assetStateInsurances$,
            this.assetStateAccounts$,
        ]).pipe(take(1)).subscribe(([stateRealEstates, stateInsurances, stateAccounts]) => {
            if ((!stateRealEstates || stateRealEstates.length === 0) && (!stateInsurances || stateInsurances.length === 0) && (!stateAccounts || stateAccounts.length === 0) && !!this.riskfinancingPlanID) {
                this.assetService.getAssets(this.riskfinancingPlanID).subscribe({
                    next: assetData => {
                        if (!!assetData) {

                            this.assetInsurance = this.filterActiveAssets(assetData.insurances);
                            this.assetAccounts = this.filterActiveAssets(assetData.accounts);
                            this.assetRealEstates = this.filterActiveAssets(assetData.mortages);

                            this.changeDetection.detectChanges();
                            this.waiterService.hide();
                        }
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.getAssetsError'));
                    },
                });
            }
            //get assets state data
            else {
                this.assetStateRealEstates$.pipe(take(1)).subscribe(res => {
                    this.assetRealEstates = this.filterActiveAssets(res);
                    this.changeDetection.detectChanges();
                });
                this.assetStateInsurances$.pipe(take(1)).subscribe(res => {
                    this.assetInsurance = this.filterActiveAssets(res);
                    this.changeDetection.detectChanges();
                });
                this.assetStateAccounts$.pipe(take(1)).subscribe(res => {
                    this.assetAccounts = this.filterActiveAssets(res);
                    this.changeDetection.detectChanges();
                });
            }
        });
    }

    /**
     * filter active assets
     *
     * @param { IAssetRealEstate[] | IAssetInsurance[] | IAssetAccount[] } assets assets
     * @returns { IAssetRealEstate[] | IAssetInsurance[] | IAssetAccount[] } filtered active assets
     */
    private filterActiveAssets(assets?: (IAssetRealEstate | IAssetInsurance | IAssetAccount)[]) {
        if (!!assets) {
            return assets.filter((asset: IAssetRealEstate | IAssetInsurance | IAssetAccount) => asset.isActive);
        }
        else {
            return assets;
        }
    }

    /**
     * add new collateral detail
     *
     * @param {CollateralBaseType | string} selectedOption selected option
     */
    // eslint-disable-next-line complexity
    public addNewCollateralDetail(selectedOption: CollateralType | string) {
        switch (selectedOption) {
            //Konto
            case (CollateralType.BA415):
                this.createCollateral(selectedOption, CollateralBaseType.Account);
                break;
            //Wertpapiere
            case (CollateralType.BA425):
                this.createCollateral(selectedOption, CollateralBaseType.Bonds);
                break;
            //Versicherungen
            case (CollateralType.BA105):
            case (CollateralType.BA110):
            case (CollateralType.BA115):
            case (CollateralType.BA120):
            case (CollateralType.BA125):
            case (CollateralType.BA130):
            case (CollateralType.BA135):
                this.createCollateral(selectedOption, CollateralBaseType.Insurance);
                break;
            //Immobilien
            case (CollateralType.BA205):
            case (CollateralType.BA210):
            case (CollateralType.BA220):
                this.createCollateral(selectedOption, CollateralBaseType.Mortgage);
                break;
            default:
                break;
        }
    }

    /**
     * create Collateral Request
     *
     * @param {CollateralType} collateralType collateraltype
     * @param {CollateralBaseType} collateralBaseType collateralbasetype
     */
    private createCollateral(collateralType: CollateralType | string, collateralBaseType: CollateralBaseType) {

        if (!!this.productPackageID) {
            this.waiterService.show();
            this.assetService.createNewCollateral(this.productPackageID, collateralType).pipe(take(1)).subscribe({
                next: createdData => {
                    if (!!createdData) {
                        switch (collateralBaseType) {
                            case CollateralBaseType.Account:
                                this.collateralDataAccounts$ = of(createdData?.accounts);
                                break;
                            case CollateralBaseType.Bonds:
                                this.collateralDataAccounts$ = of(createdData?.accounts);
                                break;
                            case CollateralBaseType.Insurance:
                                this.collateralDataInsurance$ = of(createdData?.insurances);
                                break;
                            case CollateralBaseType.Mortgage:
                                this.collateralDataRealEstates$ = of(createdData?.mortages);
                                break;
                            default:
                                break;
                        }
                    }
                },
                complete: () => {
                    this.changeDetection.detectChanges();
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.createCollateralsError'));
                },
            });
        }
    }

    /**
     * add AGP Collateral
     * 
     * @param {string} collateralID collateralID
     * @param {CollateralBaseType} collateralType collateralType
     */
    /* public addAGPCollateral(collateralID: string, collateralType: CollateralBaseType) {

        if (!!this.productPackageID && !!collateralID) {
            this.waiterService.show();
            this.assetService.addAGPCollateral(collateralID, this.productPackageID).pipe(take(1)).subscribe({
                next: createdData => {
                    if (!!createdData) {
                        switch (collateralType) {
                            case CollateralBaseType.Account:
                                this.collateralDataAccounts$ = of(createdData?.accounts);
                                break;
                            case CollateralBaseType.Bonds:
                                this.collateralDataAccounts$ = of(createdData?.accounts);
                                break;
                            case CollateralBaseType.Insurance:
                                this.collateralDataInsurance$ = of(createdData?.insurances);
                                break;
                            case CollateralBaseType.Mortgage:
                                this.collateralDataRealEstates$ = of(createdData?.mortages);
                                break;
                            default:
                                break;
                        }
                    }
                },
                complete: () => {
                    this.changeDetection.detectChanges();
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                },
            });
        }
    } */

    /**
     * filter Assets with Source AGP
     *
     * @param {Array<ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail>} collaterals The array of collaterals to filter.
     * @returns {Observable<Array<ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail>>} The filtered array of collaterals.
     */
    /*  private hasAGPAssets<T extends ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail>(collaterals: T[]) {
        return of(collaterals.filter(collateral => collateral.source === SourceCollateral.Agp)) as Observable<T[]>;
    } */

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
