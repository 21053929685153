import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { EnumTranslationPipe, MaritalStatus } from '@ntag-ef/finprocess-enums';
import { Gender } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetProviderService, FinancingService, IAssetProvider, IDgaCustomer, IDgaSearch, OverwriteValueClassType, ValueStorageType, VerfiedCustomerService } from 'app/modules/financing/data';
import { IAddress, IBaseOverwriteValues, ICustomerBasicData, IDebitor, IUpdateModel, IVerifiedCustomer } from 'app/modules/financing/data/interfaces';
import { OverwriteHelperService } from 'app/modules/financing/util/services';
import { Observable, Subject, defer, forkJoin, mergeMap, of, startWith, take, takeUntil } from 'rxjs';
import { HelperService } from 'shared/util';

import { OrganisationalUnitResponsibilityType } from '../../../../../masterdata/data';

export interface ISelectedValuesFp<T, R, E> {
    key: keyof (T & R & E);
    value?: string | undefined;
    valueStorage: ValueStorageType;
    overwriteValueClassType: OverwriteValueClassType,
    entityForOverwriteId: string,
    entity: T | R | E,
}
export interface IDialogData {
    verifiedCustomers?: IVerifiedCustomer[];
    currentCustomer?: IDebitor;
    financingId?: string;
    responsibility?: OrganisationalUnitResponsibilityType;
    financingContainerId?: string; 
    assetId?: string;
}

export interface ICountry {
    value: string,
    label: string
}

interface ISearchFormAssetProvider {
    ndg?: FormControl<string | null>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    birthDate: FormControl<moment.Moment | null>, 
    email: FormControl<string | null>,
}

interface IResponsibility extends IBaseOverwriteValues {
    responsibility: OrganisationalUnitResponsibilityType;
}

type IManualDgaUser = FormGroup< {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    titleFirstName: FormControl<string | null>;
    titleLastName: FormControl<string | null>;
    birthDate: FormControl<null>,
    street: FormControl<string>,
    streetNumber: FormControl<string>,
    zip: FormControl<string>,
    city: FormControl<string>,
    stairway: FormControl<string | null>,
    top: FormControl<string | null>,
    countryForReporting: FormControl<string>,
    country: FormControl<string>,
}>

interface ICountryForm {
    countryName: FormControl<string>;
    countryForReporting: FormControl<string>;
}

interface IExtendedCountryForm extends ICountryForm{
    stairway: FormControl<string | null>;
    top: FormControl<string | null>;
}

/**
 * Dialog für NDG
 */
@Component({
    selector: 'finprocess-verify-customer-dialog',
    templateUrl: './verify-customer-dialog.component.html',
    styleUrls: ['./verify-customer-dialog.component.scss'],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class VerifyCustomerDialogComponent implements OnInit, OnDestroy {

    /**
     * FP Debtor Form
     */
    public debtorForm: FormGroup | undefined;

    /**
     * Search Form for Assets search
     */
    public searchForm: FormGroup | undefined;
    
    /**
     * Form für neuen Dga Nutzer (Asset)
     */
    public dgaUserForm: IManualDgaUser | undefined;

    /**
     * DGA Debtor Form
     */
    public dgaForm: FormGroup | undefined;
    
    /**
     * DGA Country Form
     */
    public countryForm: FormGroup<ICountryForm> | FormGroup<IExtendedCountryForm> | undefined;

    public showContryForm = false;

    public showManualDgaUserForm = false;

    public isAssetCall = false;

    public dgaManualSaveError = '';
    /**
     * Eigenschaftsschlüssel, deren Wert nicht geändert werden kann
     */
    private notEditableProperties: string[] = ['responsibility', 'customerNumber'];

    /**
     *  UI für Bearbeiten
     */
    public isCompareActive = false;
    
    /**
     * Values, die der Nutzer ausgewählt ist, sollen von DGA an FP gesedent werden (NDG ist Kundenr.)
     */
    public selectedValuesFromDga: ISelectedValuesFp<IDebitor, IAddress, IResponsibility>[] = [];

    /**
     * Values, die der Nutzer ausgewählt ist, sollen von FP an DGA gesedent werden 
     */
    public selectedValuesFromFp: Record<string, string>[] = [];

    /** index ein gerade geöffnetes Akkordeonelement  */
    public accordionElementIndex = 0;

    public debtorHeaderDataWithOverwrite?: Partial<IDebitor> & Partial<IAddress>;

    public filteredCountriesNameControl: ICountry[] | undefined;

    public filteredCountriesReportingControl: ICountry[] | undefined;

    public verifiedCustomers: IVerifiedCustomer[] | undefined;

    private onDestroy$ = new Subject<void>();

    /**
     * Standard konstruktor
     *
     * @param {MatDialogRef} dialogRef MatDialogRef
     * @param { IDialogData | undefined} dialogData Daten für das Dialog
     * @param {FormBuilder} formBuilder FormBuilder Injector
     * @param {DatePipe} datePipe DatePipe
     * @param {FinancingService} financingService FinancingService
     * @param {VerfiedCustomerService} verfiedCustomerService VerfiedCustomerService
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} transalte TranslateService-Injektor
     * @param {AssetProviderService} assetProviderService assetProviderService-Injektor
     */
    public constructor(
        private dialogRef: MatDialogRef<VerifyCustomerDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: IDialogData,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private financingService: FinancingService,
        private verfiedCustomerService: VerfiedCustomerService,
        private enumTranslate: EnumTranslationPipe,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private transalte: TranslateService,
        private assetProviderService: AssetProviderService,
        
    ) {}
    
    /**
     * Angular Lifecycle Hook
     */
    public ngOnInit(): void {
        this.verifiedCustomers = this.dialogData?.verifiedCustomers;

        if (!!this.dialogData.financingContainerId) {
            this.isAssetCall = true;

            this.searchForm = this.formBuilder.group<ISearchFormAssetProvider>({
                firstName:  this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
                lastName:   this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
                birthDate:  this.formBuilder.control(null, { validators: Validators.required, nonNullable: true}),
                email: this.formBuilder.control(''),
                ndg: this.formBuilder.control(''),
            });
        }
        
        if (!!this.dialogData.currentCustomer) {
            this.debtorHeaderDataWithOverwrite = {
                firstName: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'firstName', this.dialogData.currentCustomer.firstName),
                lastName: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'lastName', this.dialogData.currentCustomer.lastName),
                birthday: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'birthday', this.dialogData.currentCustomer.birthday),
                birthPlace: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'birthPlace', this.dialogData.currentCustomer.birthPlace),
                birthcountry: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'birthcountry', this.dialogData.currentCustomer.birthcountry),
                citizenship: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer, 'citizenship', this.dialogData.currentCustomer.citizenship),
                street: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'street', this.dialogData.currentCustomer.homeAddress.street),
                streetNumber: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'streetNumber', this.dialogData.currentCustomer.homeAddress.streetNumber),
                stairway: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'stairway', this.dialogData.currentCustomer.homeAddress.stairway),
                top: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'top', this.dialogData.currentCustomer.homeAddress.top),
                zip: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'zip', this.dialogData.currentCustomer.homeAddress?.zip),
                city: OverwriteHelperService.getMergedOverwriteValue(this.dialogData.currentCustomer.homeAddress, 'city', this.dialogData.currentCustomer.homeAddress.city),
            };

            this.selectedValuesFromDga = [
                {
                    key: 'customerNumber',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
                },
                { //Rechtsform Kunde
                    // TODO: Das ganze überarbeiten, damit es sauber und typsicher ist.
                    key: 'responsibility',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
                }, 
                {
                    key: 'firstName',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
    
                },
                {
                    key: 'lastName',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
    
    
                },
                {
                    key: 'birthday',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.DateTimeOffset,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
    
                },
                {
                    key: 'birthPlace',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
    
                },
                {
                    key: 'city',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                {
                    key: 'zip',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                {
                    key: 'stairway',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                {
                    key: 'top',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                {
                    key: 'gender',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.Int,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
                },
                {
                    key: 'street',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                {
                    key: 'streetNumber',
                    overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer.homeAddress,
                },
                { 
                    key: 'maritalStatus',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.Int,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
                },
                {
                    key: 'title',
                    overwriteValueClassType: OverwriteValueClassType.DebitorOverwriteValue,
                    valueStorage: ValueStorageType.String,
                    entityForOverwriteId: this.dialogData.currentCustomer.id,
                    entity: this.dialogData.currentCustomer,
                },
             
            ]
        }
    }

    /**
     * Beeendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Ändert grade geöffnetes Akkordeonelement index
     * 
     * @param {number} index index
     */
    public setStep(index: number): void {
        this.accordionElementIndex = index;
        this.isCompareActive = false;
    }

    /**
     * Ausgewählte Nutzer NDG zurücksenden
     * 
     * @param {string} ndg ndg
     */
    public onSelectedData(ndg: string): void {
        this.dialogRef.close(ndg);
    }


    /**
     * Initialisiert das Form für DGA daten
     *
     * @param {ICustomerBasicData} customerDga DGA Customer
     */
    private initDgaForm(customerDga: ICustomerBasicData): void {
        this.dgaForm = this.formBuilder.group({
            customerNumber: [customerDga?.ndg],
            firstName: [customerDga?.firstName],
            lastName: [customerDga?.surname],
            birthPlace: [customerDga?.birthPlace],
            birthday: [this.datePipe.transform(customerDga.birthDate, 'dd.MM.yyyy')],
            city: [customerDga?.city],
            zip: [customerDga?.zipCode], 
            gender: [this.enumTranslate.transform(customerDga.gender, 'Gender')],
            responsibility: [this.enumTranslate.transform(customerDga.legalType, 'LegalType')],
            street: [customerDga?.street], 
            streetNumber: [customerDga?.streetNumber],
            stairway: [customerDga?.stairway],
            top: [customerDga?.top],
            maritalStatus: [this.enumTranslate.transform(customerDga.maritalStatus, 'MaritalStatus')],
            title: [customerDga?.title], 
        })
    }

    /**
     * Initialisiert Debtor (FS) form
     *
     */
    private initDebtorForm(): void {
        this.debtorForm = this.formBuilder.group({

        });

        this.selectedValuesFromDga.forEach(element => {
            let val = this.findValue<IDebitor, IAddress, IResponsibility>(element.key, element.entity);

            if (element.key === 'birthday') {
                val = this.datePipe.transform(val, 'dd.MM.yyyy');
            }

            if (element.key === 'responsibility' && val !== undefined && (typeof val === 'string' || typeof val === 'number')) {
                val = this.enumTranslate.transform(val, 'OrganisationalUnitResponsibilityType') as string
            }
    
            if (element.key === 'gender' && val !== undefined && (typeof val === 'string' || typeof val === 'number')) {
                val = this.enumTranslate.transform(val, 'Gender') as string
            }
    
            if (element.key === 'maritalStatus' && val !== undefined && (typeof val === 'string' || typeof val === 'number')) {
                val = this.enumTranslate.transform(val, 'MaritalStatus') as string
            }

            this.debtorForm?.addControl(element.key, new FormControl(val))
        })
    }

    /**
     * Return einen überschriebenen Wert oder den originalen 
     * 
     * @param {string} fieldName feldanme/key
     * @template {IBaseOverwriteValues} T Entitätstyp
     * @param {T} entity ICustomer, oder IAddress usw
     * @returns { string | number | Date | undefined} feld value
     */
    private findValue<T extends IBaseOverwriteValues, R extends IBaseOverwriteValues, G extends IBaseOverwriteValues>(fieldName: keyof (T & R & G) & string, entity: T | R | G): string | number | Date | undefined | null {
        if (fieldName === 'responsibility') {
            return this.dialogData.responsibility;
        }

        return OverwriteHelperService.getMergedOverwriteValue(
            entity,
            fieldName,
            entity[fieldName as never],
        );
    }

    /**
     * Prüft ob die Value von die Texboxes gleich sind
     *  
     * @param {string} debtorControlVal Debtor Value
     * @param {string} dgaControlVal DGA Value
     * @returns {boolean} true/false
     */
    public areValuesSame(debtorControlVal: string, dgaControlVal: string): boolean {

        //if both controls dont have values, disable button
        if (!debtorControlVal && !dgaControlVal) {
            return true;
        }

        //if values are same, disable buttom
        if (debtorControlVal?.toString().toLowerCase() === dgaControlVal?.toString().toLowerCase()) {
            return false;
        }

        return true;
    }

    /**
     * Für not editable fields
     * 
     * @param {string} key key
     * @returns {boolean} is editable
     */
    public notEditable(key: string): boolean {
        return this.notEditableProperties.some(prop => prop === key )
    }

    /**
     * Ausgewählten Wert in richtige Variable speichern
     * 
     * @param {FormControl} debtorControl Debtor
     * @param {FormControl} dgaControl DGA
     * @param {string} controlKeyName Control key
     */
    public saveTempSelectedValue(debtorControl: FormControl, dgaControl: FormControl, controlKeyName: string): void {

        const debtorRecord = this.selectedValuesFromDga.find(result => result.key === controlKeyName) as ISelectedValuesFp<IDebitor, IAddress, IResponsibility>;
        const dgaRecord = this.selectedValuesFromFp.find(result => (result.key === controlKeyName && result.value === debtorControl.value));

        //wenn Debtor FP Wert schon ausgewählt ist, dann DGA-Wert setzen
        if (!!debtorRecord?.value && !!debtorControl.value) {
            this.selectedValuesFromFp.push({key: controlKeyName, value: debtorControl.value})
            debtorRecord.value = undefined;
        }
        //wenn DGA-Wert schon ausgewählt ist, dann Debtor FP Wert setzen
        else if (!!dgaRecord && !!dgaControl.value) {
            debtorRecord.value = dgaControl.value
            this.selectedValuesFromFp = this.selectedValuesFromFp.filter(res => res.key !== controlKeyName);
        }
        //wenn bis hier keine wert gesetzt ist, erstmal setzt FP wert wenn vorhanden 
        else if (!!debtorControl.value ) {
            this.selectedValuesFromFp = this.selectedValuesFromFp.filter(res => res.key !== controlKeyName);
            this.selectedValuesFromFp.push({key: controlKeyName, value: debtorControl.value as string})
        }
        else if (!!dgaControl.value)
        {
            debtorRecord.value = dgaControl.value
        }
    }

    /**
     * Markirt UI Value als ausgewählt
     * 
     * @param {string} key Control Key 
     * @param {string}val Control Value 
     * @returns {boolean} true/false
     */
    public isValueFromFP(key: string, val: string): boolean {
        return this.selectedValuesFromFp.some(record => record.key === key && record.value === val);
    }

    
    /**
     * Markirt UI value als ausgewählt
     * 
     * @param {string} key Control Key 
     * @param {string}val Control Value 
     * @returns {boolean} true/false
     */
    public isValueFromDga(key: string, val: string): boolean {
        return this.selectedValuesFromDga.some(rec => rec.key === key && rec.value === val);
    }

    /**
     * änderung an server senden
     * 
     * @param {IVerifiedCustomer} verifiedCustomer verifiedCustomer
     */
    public sendChanges(verifiedCustomer: IVerifiedCustomer): void {
        this.waiterService.show();
        
        const fpValuesToSave = this.selectedValuesFromDga.filter(record => this.notEditableProperties.some(key => key !== record.key) && record.value !== undefined);
        const dgaModelToSave = this.getDgaModelToSave(verifiedCustomer.externalUserData);
        
        const requests: Observable<unknown>[] = [];

        if (fpValuesToSave.length > 0) {
            requests.push(...fpValuesToSave.map(record => this.financingService.saveOverwriteValue({
                overwriteValueClassType: record.overwriteValueClassType,
                entityForOverwriteId: record.entityForOverwriteId,
                fieldName: record.key,
                valueStorageType: record.valueStorage,
                value: this.findSelectedValueForFP(record)?.toString(),
            })));
        }

        if (dgaModelToSave !== undefined) {
            requests.push(this.verfiedCustomerService.updateDgaCustomer(dgaModelToSave));
        }

        forkJoin(requests).subscribe({
            next: () => {
                this.dialogRef.close();
                this.waiterService.hide();
                this.notification.toast(
                    this.transalte.instant('financing.features.financing-processing.verify-customer.succeededAll', {
                        firstName: this.dialogData?.currentCustomer?.firstName, 
                        lastName: this.dialogData?.currentCustomer?.lastName}), 
                    {duration: 3000});

            },
            error: (err: HttpErrorResponse) => {
                this.waiterService.hide();

                if (err.status === HttpStatusCode.BadRequest) {
                    let extractedErrorMessages = '';

                    try {
                        const allKeys = Object.keys(err.error.errors);
                        
                        if (Array.isArray(allKeys)) {
                            allKeys.forEach(key => {
                                const translated = this.transalte.instant(`financing.features.financing-processing.verify-customer.${key.charAt(0).toLowerCase() + key.slice(1)}`)
                                extractedErrorMessages = extractedErrorMessages.concat('<br/>', translated)
                            })
                        }
                        
                        this.notification.alert(
                            this.transalte.instant('financing.features.financing-processing.verify-customer.error'),
                            `${extractedErrorMessages}`,
                        ) 
                    } catch {
                        this.notification.alert(
                            this.transalte.instant('general.error'), 
                            this.transalte.instant('general.unknownError'),
                        ) 
                    }

                } else {
                    this.notification.alert(
                        this.transalte.instant('general.error'), 
                        this.transalte.instant('general.unknownError'),
                    ) 

                }
            },
            complete: () => {
                this.waiterService.hide();
            },
        });
    }

    /**
     * Die richtige value suchen-umwandeln
     * 
     * @param {ISelectedValuesFp} record record
     * @returns {string | null | undefined} value
     */
    private findSelectedValueForFP(record: ISelectedValuesFp<IDebitor, IAddress, IResponsibility>): string | undefined {
        if (record.key === 'birthday') {
            return HelperService.parseGermanDateString(record.value);
        }
        if (record.key === 'gender') {
            return this.getGenderEnum(record.value)?.toString() ?? record.value;
        }

        if (record.key === 'maritalStatus') {
            return this.getMaritalStatusFromTranslatedEnum(record.value)?.toString() ?? record.value;
        }

        return record.value;
    }

    /**
     * Ausgewählte Werten löschen
     */
    public clearTempData(): void {
        this.isCompareActive = false;
        this.selectedValuesFromFp = [];
        this.dgaForm = undefined;
        // eslint-disable-next-line no-return-assign
        this.selectedValuesFromDga.map(result => result.value = undefined)

    }

    /**
     * Init view für Bearbeiten
     * 
     * @param {ICustomerBasicData} customer NDG Customer
     */
    public initCompareCustomerView(customer: ICustomerBasicData): void {
        this.clearTempData();
        this.initDgaForm(customer);
        this.initDebtorForm();
        this.isCompareActive = true;
    }

    /**
     * Prüft ob values von DGA leer sind
     * 
     * @returns {boolean} boolean
     */
    public hasSelectedDgaValue(): boolean {
        return this.selectedValuesFromDga.every(element => element.value === undefined)
    }
    /**
     * Generiert das model für DGA 
     * 
     * @param {ICustomerBasicData} dgaCustomer DGA customer
     * @returns {IUpdateModel | undefined} model to update
     */
    // eslint-disable-next-line complexity
    private getDgaModelToSave(dgaCustomer: ICustomerBasicData): IUpdateModel | undefined {
        if (this.selectedValuesFromFp.length !== 0) {
            return {
                ndg: (this.selectedValuesFromFp.find(entry => entry.key === 'customerNumber'))?.value ?? dgaCustomer.ndg,
                firstName: (this.selectedValuesFromFp.find(entry => entry.key === 'firstName'))?.value ?? dgaCustomer.firstName,
                surname: (this.selectedValuesFromFp.find(entry => entry.key === 'lastName'))?.value ?? dgaCustomer.surname,
                birthPlace: (this.selectedValuesFromFp.find(entry => entry.key === 'birthPlace'))?.value ?? dgaCustomer.birthPlace,
                birthDate: HelperService.parseGermanDateToISODate((this.selectedValuesFromFp.find(entry => entry.key === 'birthday')?.value)) ?? dgaCustomer.birthDate?.toString(),
                mainCityDescription: dgaCustomer.mainCityDescription,
                city:  (this.selectedValuesFromFp.find(entry => entry.key === 'city'))?.value ?? dgaCustomer.mainCityDescription,
                bankCode: dgaCustomer?.bankCode,
                automaticJoin: dgaCustomer.automaticJoin,
                buddyBank: dgaCustomer.buddyBank,
                businessName: dgaCustomer.businessName ?? '',
                familyMembers: dgaCustomer.familyMembers,
                gender: this.getGenderEnum((this.selectedValuesFromFp.find(entry => entry.key === 'gender'))?.value) ?? dgaCustomer.gender,
                headingType: dgaCustomer.headingType,
                language: dgaCustomer.language,
                mainCountryDescription: dgaCustomer.mainCountryDescription,
                manager: dgaCustomer.manager,
                nickname: dgaCustomer.nickname,
                provinceCode: dgaCustomer.provinceCode ?? '',
                resident: dgaCustomer.resident,
                taxCode: dgaCustomer.taxCode,
                jointHeading: dgaCustomer.jointHeading ?? '',
                legalType: dgaCustomer.legalType,
                street: (this.selectedValuesFromFp.find(entry => entry.key === 'street'))?.value ?? dgaCustomer.street, 
                streetNumber: (this.selectedValuesFromFp.find(entry => entry.key === 'streetNumber'))?.value ?? dgaCustomer.streetNumber, 
                maritalStatus: this.getMaritalStatusFromTranslatedEnum((this.selectedValuesFromFp.find(entry => entry.key === 'maritalStatus'))?.value) ?? dgaCustomer.maritalStatus,
                title: (this.selectedValuesFromFp.find(entry => entry.key === 'title'))?.value ?? dgaCustomer.title, 
                zipCode: (this.selectedValuesFromFp.find(entry => entry.key === 'zip'))?.value ?? dgaCustomer.zipCode, 
            };
        }
        return undefined;
    }

    /**
     * Umwandeln übersetzung in richtige enum
     * 
     * @param {string | undefined} value ausgewählte value
     * @returns {MaritalStatus | undefined} model to update
     */
    private getMaritalStatusFromTranslatedEnum(value: string | undefined): MaritalStatus | undefined {

        switch (value) {
            case this.enumTranslate.transform(MaritalStatus.Single, 'MaritalStatus'):
                return MaritalStatus.Single
            case this.enumTranslate.transform(MaritalStatus.Married, 'MaritalStatus'):
                return MaritalStatus.Married
            case this.enumTranslate.transform(MaritalStatus.Widowed, 'MaritalStatus'):
                return MaritalStatus.Widowed
            case this.enumTranslate.transform(MaritalStatus.Divorced, 'MaritalStatus'):
                return MaritalStatus.Divorced
            case this.enumTranslate.transform(MaritalStatus.Partnered, 'MaritalStatus'):
                return MaritalStatus.Partnered
                                
            default:
                return undefined;
        }
    }

    /**
     * Umwandeln übersetzung in richtige enum
     * 
     * @param {string | undefined} value value
     * @returns {number | undefined} model to update
     */
    private getGenderEnum(value: string | undefined): number | undefined {

        if (value === this.enumTranslate.transform(Gender.Male, 'Gender')) {
            return Gender.Male;
        }

        if (value === this.enumTranslate.transform(Gender.Female, 'Gender')) {
            return Gender.Female;
        }

        return undefined;
    }

    /*************DGA Kunde anlegen************/

    /**
     * Das Formular anzeigen / ausblenden
     */
    public toogleCountyInput(): void {
        this.showContryForm = !this.showContryForm;

        if (this.showContryForm) {
            this.initCountryForm();
        } 
        else {
            this.countryForm = undefined;

        }
    }

    /**
     * Init form für neuen DGA Benutzer
     */
    public initCountryForm(): void {
        const homeAddress = this.dialogData?.currentCustomer?.homeAddress;

        if (!homeAddress) {
            return;
        }

        this.countryForm = this.formBuilder.group({
            countryName: this.formBuilder.control('', { validators: [this.autocompleteStringValidator(ACCEPTED_COUNTRIES), Validators.required, Validators.minLength(2), Validators.maxLength(2)], nonNullable: true}),
            countryForReporting: this.formBuilder.control('', { validators: [this.autocompleteStringValidator(ACCEPTED_COUNTRIES), Validators.required, Validators.minLength(2), Validators.maxLength(2)], nonNullable: true}),
        });
       
        const stairway = OverwriteHelperService.getMergedOverwriteValue(homeAddress, 'stairway', homeAddress.stairway);
        const top = OverwriteHelperService.getMergedOverwriteValue(homeAddress, 'top', homeAddress.top);

        if (stairway && !top) {
            (this.countryForm as unknown as FormGroup<IExtendedCountryForm>).addControl('stairway', this.formBuilder.control(stairway ?? null, { validators: Validators.required}));
            (this.countryForm as unknown as FormGroup<IExtendedCountryForm>).addControl('top', this.formBuilder.control(top ?? null, { validators: Validators.required}));
        }
    
        this.filteredCountriesNameControl = ACCEPTED_COUNTRIES;
        this.filteredCountriesReportingControl = ACCEPTED_COUNTRIES;
            
        this.countryForm.controls.countryName.valueChanges.pipe(
            startWith(''),
        ).subscribe((value: string) => {
            this.filteredCountriesNameControl = ACCEPTED_COUNTRIES.filter(country => country.label.toLowerCase().includes(value.toLowerCase()));
        })
    
        this.countryForm.controls.countryForReporting.valueChanges.pipe(
            startWith(''),
        ).subscribe((value: string) => {
            this.filteredCountriesReportingControl = ACCEPTED_COUNTRIES.filter(country => country.label.toLowerCase().includes(value.toLowerCase()));
        })
    }
        
    /**
     * Init form für neuen DGA Benutzer
     */
    public initManualDgaUserForm(): void {
        this.dgaUserForm = this.formBuilder.group({
            firstName: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            lastName: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            titleFirstName: this.formBuilder.control(''),
            titleLastName: this.formBuilder.control(''),
            birthDate: this.formBuilder.control(null, { validators: Validators.required, nonNullable: true}),
            street: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            streetNumber: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            zip: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            city: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            stairway: this.formBuilder.control(''),
            top: this.formBuilder.control(''),
            countryForReporting: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
            country: this.formBuilder.control('', { validators: Validators.required, nonNullable: true}),
        });

        this.filteredCountriesNameControl = ACCEPTED_COUNTRIES;
        this.filteredCountriesReportingControl = ACCEPTED_COUNTRIES;
            
        this.dgaUserForm.controls.country?.valueChanges.pipe(
            startWith(''),
            takeUntil(this.onDestroy$),
        ).subscribe((value: string | null) => {
            if (!!value) {
                this.filteredCountriesNameControl = ACCEPTED_COUNTRIES.filter(country => country.label.toLowerCase().includes(value?.toLowerCase()));

            }
        });
    
        this.dgaUserForm.controls.countryForReporting?.valueChanges.pipe(
            startWith(''),
            takeUntil(this.onDestroy$),
        ).subscribe((value: string | null) => {
            if (!!value) {
                this.filteredCountriesReportingControl = ACCEPTED_COUNTRIES.filter(country => country.label.toLowerCase().includes(value?.toLowerCase()));
            }
        });

        this.dgaUserForm.controls.stairway.valueChanges.pipe(
            startWith(this.dgaUserForm.controls.stairway.value),
            takeUntil(this.onDestroy$),
        ).subscribe((value: string | null) => {
            if (!!value && value !== '') {
                this.dgaUserForm?.controls.top.setValidators(Validators.required);
                this.dgaUserForm?.controls.top.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            } else {
                this.dgaUserForm?.controls.top.removeValidators(Validators.required);
                this.dgaUserForm?.controls.top.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            }
        });
    }

        

    /**
     * Dga user form toogle
     */
    public toogleManualDgaUserForm(): void {
        this.showManualDgaUserForm = !this.showManualDgaUserForm;

        if (this.showManualDgaUserForm) {
            this.initManualDgaUserForm();
        } 
        else {
            this.dgaUserForm = undefined;
        }
    }

    /**
     * DGA suche
     */
    public searchDga(): void {
        this.isAssetCall = true;
        this.waiterService.show();
        if (this.searchForm?.valid) {
            const searchData = this.searchForm.getRawValue() as IDgaSearch;
            this.assetProviderService.searchInDga(searchData).subscribe(res => {
                this.verifiedCustomers = res,
                this.waiterService.hide();
            })
        }
    }
    /**
     * Funktion für die Anzeige die Landname
     *
     * @param {string} value value
     * @returns {string} name
     */
    public displayFn(value: string): string {
        return ACCEPTED_COUNTRIES.find(country => country?.value === value)?.label ?? '';
    }

    
    /**
     * Valdiator für autocomplete
     * 
     * @param {ICountry[]} validOptions gültige werte
     * @returns {ValidatorFn} Validator
     */
    public autocompleteStringValidator(validOptions: ICountry[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: unknown } | null => {
            if (validOptions.find(country => country.value === control.value)) {
                return null /* valid option selected */
            }
            return { 'invalidAutocompleteString': { value: control.value } }
        }
    }
      
    /**
     * Erstellt der DGA Benutzer
     */
    public createDgaUser(): void {
        const customer = this.dialogData.currentCustomer;
        const financingId = this.dialogData.financingId;

        if (!this.countryForm || !customer || !financingId) {
            return;
        }

        const countryName = this.countryForm.controls.countryName.value;
        const countryForReporting = this.countryForm.controls.countryForReporting.value;
        this.waiterService.show();

        defer(() => {
            if (this.isExtendedCountryForm(this.countryForm)) {
                return forkJoin([
                    this.financingService.saveOverwriteValue({
                        entityForOverwriteId: customer.homeAddress.id,
                        fieldName: 'stairway',
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        valueStorageType: ValueStorageType.String,
                        value: this.countryForm.controls.stairway.value,
                    }),
                    this.financingService.saveOverwriteValue({
                        entityForOverwriteId: customer.homeAddress.id,
                        fieldName: 'top',
                        overwriteValueClassType: OverwriteValueClassType.AddressOverwriteValue,
                        valueStorageType: ValueStorageType.String,
                        value: this.countryForm.controls.top.value,
                    }),
                ])
            }

            return of(undefined);
        }).pipe(
            mergeMap(() => this.verfiedCustomerService.createDgaCustomer(customer.id, financingId, countryName, countryForReporting)),
        ).subscribe({
            next: ndg => {
                this.dialogRef.close(ndg);
                this.notification.toast(
                    this.transalte.instant('financing.features.financing-processing.verify-customer.succeededDga', {
                        firstName: this.dialogData?.currentCustomer?.firstName, 
                        lastName: this.dialogData?.currentCustomer?.lastName}), 
                    {duration: 3000});
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide(),
                this.notification.alert(
                    this.transalte.instant('general.error'), 
                    this.transalte.instant('general.unknownError'),
                ) 
            },
            complete: () => {
                this.waiterService.hide();
            },
        });
    }

    /**
     * Erstellt der DGA Benutzer
     */
    public manuelCreateDgaUser(): void {
        if (this.dgaUserForm?.valid) {
            this.waiterService.show();
            const dgaUser = this.dgaUserForm.getRawValue() as unknown as IDgaCustomer;
            this.assetProviderService.createCustomerInDga(dgaUser).pipe(
                take(1),
            ).subscribe({
                next: assetProvider => {
                    if (!!assetProvider) {
                        this.dialogRef.close(assetProvider);
                        this.notification.toast(this.transalte.instant('financing.features.financing-processing.verify-customer.succeededCreateDgaManual'))
                    }
                    this.dgaManualSaveError = this.transalte.instant('financing.features.financing-processing.verify-customer.errorCreateDgaManual')
                    this.waiterService.hide()
                },
                error: err => {
                    if (err) {
                        this.dgaManualSaveError = this.transalte.instant('financing.features.financing-processing.verify-customer.unknownErrorCreateDgaManual')
                        this.waiterService.hide()
                    }
                },
                complete: () => this.waiterService.hide(),
            });

        }
    }

    /**
     * Nimmt den NDG Customer, mappt zu assetProvider
     *  
     * @param {IVerifiedCustomer} verifiedCustomer NDG Customer
     */
    public takeAssetProvider(verifiedCustomer: IVerifiedCustomer): void {
        if (!!this.dialogData.assetId) {
            const assetProvider: Partial<IAssetProvider> = {
                firstName: verifiedCustomer.externalUserData.firstName,
                lastName: verifiedCustomer.externalUserData.surname,
                ndg: verifiedCustomer.externalUserData.ndg,
                assetId: this.dialogData?.assetId,
            }
            this.dialogRef.close(assetProvider);
        }
    }

    /**
     * Überprüft ob das Formular für Devisenland und Berichtsland
     * ebenfalls die Felder für Stiege und Top enthält.
     * 
     * @param {FormGroup<ICountryForm> | FormGroup<IExtendedCountryForm>} countryForm Formular für Devisenland und Berichtsland
     * @returns {countryForm is FormGroup<IExtendedCountryForm>} true, wenn das Formular die Felder für Stiege und Top enthält
     */
    public isExtendedCountryForm(countryForm?: FormGroup<ICountryForm> | FormGroup<IExtendedCountryForm>): countryForm is FormGroup<IExtendedCountryForm> {
        return (countryForm as FormGroup<IExtendedCountryForm>).controls.stairway !== undefined && (countryForm as FormGroup<IExtendedCountryForm>).controls.top !== undefined;
    }
    
}

const ACCEPTED_COUNTRIES: ICountry[] = [
    { value:'AT', label:'AUSTRIA'},
    { value:'AD', label:'ANDORRA'},
    { value:'AE', label:'UNITED ARAB EMIRATES'},
    { value:'AF', label:'AFGHANISTAN'},
    { value:'AG', label:'ANTIGUA AND BARBUDA'},
    { value:'AI', label:'ANGUILLA'},
    { value:'AL', label:'ALBANIA'},
    { value:'AM', label:'ARMENIA'},
    { value:'AN', label:'NETHERLANDS ANTILLES (FORMER)'},
    { value:'AO', label:'ANGOLA'},
    { value:'AR', label:'ARGENTINA'},
    { value:'AS', label:'SAMOA'},
    { value:'AU', label:'AUSTRALIA'},
    { value:'AW', label:'ARUBA'},
    { value:'AX', label:'ALAND ISLANDS'},
    { value:'AZ', label:'AZERBAIJAN'},
    { value:'BA', label:'BOSNIA AND HERZEGOVINA'},
    { value:'BB', label:'BARBADOS'},
    { value:'BD', label:'BANGLADESH'},
    { value:'BE', label:'BELGIUM'},
    { value:'BF', label:'BURKINA FASO'},
    { value:'BG', label:'BULGARIA'},
    { value:'BH', label:'BAHRAIN'},
    { value:'BI', label:'BURUNDI'},
    { value:'BJ', label:'BENIN'},
    { value:'BL', label:'ST. BARTHELEMY'},
    { value:'BM', label:'BERMUDA'},
    { value:'BN', label:'BRUNEI DARUSSALAM'},
    { value:'BO', label:'BOLIVIA'},
    { value:'BQ', label:'BONAIRE'},
    { value:'BR', label:'BRAZIL'},
    { value:'BS', label:'BAHAMAS'},
    { value:'BT', label:'BHUTAN'},
    { value:'BV', label:'BOUVET ISLAND'},
    { value:'BW', label:'BOTSWANA'},
    { value:'BY', label:'BELARUS'},
    { value:'BZ', label:'BELIZE'},
    { value:'CA', label:'CANADA'},
    { value:'CC', label:'COCOS (KEELING) ISLANDS'},
    { value:'CD', label:'DEM.REP. OF THE CONGO'},
    { value:'CF', label:'CENTRAL AFRICAN REPUBLIC'},
    { value:'CG', label:'CONGO'},
    { value:'CH', label:'SWITZERLAND'},
    { value:'CI', label:'CÔTE D\'IVOIRE'},
    { value:'CK', label:'COOK ISLANDS'},
    { value:'CL', label:'CHILE'},
    { value:'CM', label:'CAMEROON'},
    { value:'CN', label:'CHINA'},
    { value:'CO', label:'COLOMBIA'},
    { value:'CR', label:'COSTA RICA'},
    { value:'CT', label:'CANTON AND ENDERBURY ISLANDS'},
    { value:'CU', label:'CUBA'},
    { value:'CV', label:'CAPE VERDE'},
    { value:'CW', label:'CURACAO'},
    { value:'CX', label:'CHRISTMAS ISLAND'},
    { value:'CY', label:'CYPRUS'},
    { value:'CZ', label:'CZECH REPUBLIC'},
    { value:'DE', label:'GERMANY'},
    { value:'DJ', label:'DJIBOUTI'},
    { value:'DK', label:'DENMARK'},
    { value:'DM', label:'DOMINICA'},
    { value:'DO', label:'DOMINICAN REPUBLIC'},
    { value:'DZ', label:'ALGERIA'},
    { value:'EC', label:'ECUADOR'},
    { value:'EE', label:'ESTONIA'},
    { value:'EG', label:'EGYPT'},
    { value:'EH', label:'WESTERN SAHARA'},
    { value:'ER', label:'ERITREA'},
    { value:'ES', label:'SPAIN'},
    { value:'ET', label:'ETHIOPIA'},
    { value:'FI', label:'FINLAND'},
    { value:'FJ', label:'FIJI'},
    { value:'FK', label:'FALKLAND ISLANDS'},
    { value:'FM', label:'NMICRONESIA'},
    { value:'FO', label:'FAROE ISLANDS'},
    { value:'FR', label:'FRANCE'},
    { value:'GA', label:'GABON'},
    { value:'GB', label:'UNITED KINGDOM'},
    { value:'GD', label:'GRENADA'},
    { value:'GE', label:'GEORGIA'},
    { value:'GF', label:'FRENCH GUIANA'},
    { value:'GG', label:'GUERNSEY'},
    { value:'GH', label:'GHANA'},
    { value:'GI', label:'GIBRALTAR'},
    { value:'GL', label:'GREENLAND'},
    { value:'GM', label:'GAMBIA'},
    { value:'GN', label:'GUINEA'},
    { value:'GP', label:'GUADELOUPE'},
    { value:'GQ', label:'EQUATORIAL GUINEA'},
    { value:'GR', label:'GREECE'},
    { value:'GS', label:'S. GEORGIA & S. SANDWICH ISL.'},
    { value:'GT', label:'GUATEMALA'},
    { value:'GU', label:'GUAM'},
    { value:'GW', label:'GUINEA-BISSAU'},
    { value:'GY', label:'GUYANA'},
    { value:'HK', label:'HONG KONG'},
    { value:'HM', label:'HEARD AND MCDONALD ISLANDS'},
    { value:'HN', label:'HONDURAS'},
    { value:'HR', label:'CROATIA'},
    { value:'HT', label:'HAITI'},
    { value:'HU', label:'HUNGARY'},
    { value:'IC', label:'CANARY ISLANDS'},
    { value:'ID', label:'INDONESIA'},
    { value:'IE', label:'IRELAND'},
    { value:'IL', label:'ISRAEL'},
    { value:'IM', label:'ISLE OF MAN'},
    { value:'IN', label:'INDIA'},
    { value:'IO', label:'BRITISH INDIAN OCEAN TERRITORY'},
    { value:'IQ', label:'IRAQ'},
    { value:'IR', label:'IRAN'},
    { value:'IS', label:'ICELAND'},
    { value:'IT', label:'ITALY'},
    { value:'JE', label:'JERSEY'},
    { value:'JM', label:'JAMAICA'},
    { value:'JO', label:'JORDAN'},
    { value:'JP', label:'JAPAN'},
    { value:'JT', label:'JOHNSTON ATOLL'},
    { value:'KE', label:'KENYA'},
    { value:'KG', label:'KYRGYZSTAN'},
    { value:'KH', label:'CAMBODIA'},
    { value:'KI', label:'KIRIBATI'},
    { value:'KM', label:'COMOROS'},
    { value:'KN', label:'SAINT KITTS AND NEVIS'},
    { value:'KP', label:'DEM PEOPLES REP OF KOREA'},
    { value:'KR', label:'KOREA, REP'},
    { value:'KW', label:'KUWAIT'},
    { value:'KY', label:'CAYMAN ISLANDS'},
    { value:'KZ', label:'KAZAKSTAN'},
    { value:'LA', label:'LAOS'},
    { value:'LB', label:'LEBANON'},
    { value:'LC', label:'SAINT LUCIA'},
    { value:'LI', label:'LIECHTENSTEIN'},
    { value:'LK', label:'SRI LANKA'},
    { value:'LR', label:'LIBERIA'},
    { value:'LS', label:'LESOTHO'},
    { value:'LT', label:'LITHUANIA'},
    { value:'LU', label:'LUXEMBOURG'},
    { value:'LV', label:'LATVIA'},
    { value:'LY', label:'LIBYA'},
    { value:'MA', label:'MOROCCO'},
    { value:'MC', label:'MONACO'},
    { value:'MD', label:'MOLDOVA'},
    { value:'ME', label:'MONTENEGRO'},
    { value:'MF', label:'SAINT MARTIN'},
    { value:'MG', label:'MADAGASCAR'},
    { value:'MH', label:'MARSHALL ISLANDS'},
    { value:'MI', label:'MIDWAY ATOLL'},
    { value:'MK', label:'NORTH MACEDONIA'},
    { value:'ML', label:'MALI'},
    { value:'MM', label:'MYANMAR'},
    { value:'MN', label:'MONGOLIA'},
    { value:'MO', label:'MACAU'},
    { value:'MP', label:'NORTHERN MARIANA ISLANDS'},
    { value:'MQ', label:'MARTINIQUE'},
    { value:'MR', label:'MAURITANIA'},
    { value:'MS', label:'MONTSERRAT'},
    { value:'MT', label:'MALTA'},
    { value:'MU', label:'MAURITIUS'},
    { value:'MV', label:'MALDIVES'},
    { value:'MW', label:'MALAWI'},
    { value:'MX', label:'MEXICO'},
    { value:'MY', label:'MALAYSIA'},
    { value:'MZ', label:'MOZAMBIQUE'},
    { value:'NA', label:'NAMIBIA'},
    { value:'NC', label:'NEW CALEDONIA'},
    { value:'NE', label:'NIGER'},
    { value:'NF', label:'NORFOLK ISLAND'},
    { value:'NG', label:'NIGERIA'},
    { value:'NI', label:'NICARAGUA'},
    { value:'NL', label:'NETHERLANDS'},
    { value:'NO', label:'NORWAY'},
    { value:'NP', label:'NEPAL'},
    { value:'NR', label:'NAURU'},
    { value:'NU', label:'NIUE'},
    { value:'NZ', label:'NEW ZEALAND'},
    { value:'OM', label:'OMAN'},
    { value:'PA', label:'PANAMA'},
    { value:'PE', label:'PERU'},
    { value:'PF', label:'FRENCH POLYNESIA'},
    { value:'PG', label:'PAPUA NEW GUINEA'},
    { value:'PH', label:'PHILIPPINES'},
    { value:'PK', label:'PAKISTAN'},
    { value:'PL', label:'POLAND'},
    { value:'PM', label:'SAINT PIERRE AND MIQUELON'},
    { value:'PN', label:'PITCAIRN'},
    { value:'PR', label:'PUERTO RICO'},
    { value:'PS', label:'PALESTINIAN TERRITORY, OCCUPIED'},
    { value:'PT', label:'PORTUGAL'},
    { value:'PU', label:'PACIFIC ISLAND (US)'},
    { value:'PW', label:'PALAU'},
    { value:'PY', label:'PARAGUAY'},
    { value:'PZ', label:'PANAMA CHANNEL ZONE'},
    { value:'QA', label:'QATAR'},
    { value:'RE', label:'RÉUNION'},
    { value:'RO', label:'ROMANIA'},
    { value:'RS', label:'SERBIA'},
    { value:'RU', label:'RUSSIAN FEDERATION'},
    { value:'RW', label:'RWANDA'},
    { value:'SA', label:'SAUDI ARABIA'},
    { value:'SB', label:'SOLOMON ISLANDS'},
]
