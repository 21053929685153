import { Component, Input } from '@angular/core';

/**
 * Komponente zur schönen Darstellung eines Key-Value Paars
 */
@Component({
    selector: 'finprocess-label-value',
    templateUrl: './label-value.component.html',
    styleUrls: ['./label-value.component.scss'],
})
export class LabelValueComponent {
    /**
     * Ausrichtung der Komponente
     */
    @Input()
    public flexDirection = 'column';
}
