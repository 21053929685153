import { EnumSettings } from './../enum.settings';

/**
 * Familienstand
 */
export enum MaritalStatus {
    /**
     * Single
     */
    Single = 0,

    /**
     * Verheiratet
     */
    Married = 1,

    /**
     * Verwitwet
     */
    Widowed = 2,

    /**
     * Geschieden
     */
    Divorced = 3,

    /**
     * Verpartnert bzw. eingetragene Partnerschaft
     */
    Partnered = 4,
}

export namespace MaritalStatus {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {MaritalStatus | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: MaritalStatus | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'MaritalStatus', MaritalStatus, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('MaritalStatus', plocale);
}
