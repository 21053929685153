export enum LiabilityBankType {

    /**
     * unbelegt
     */
    Unknown,

    /**
     * eigene Bank Austria
     */
    BankAustria,

    /**
     * externe Bank
     */
    ExternalBank
}
