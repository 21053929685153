<div mat-dialog-title class="container cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h2 class="headline">{{'financing.features.financing-processing.process.rejectionLetterTitle' | translate}}</h2>
  <div class="useractions">
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
</div>
<ng-container *ngIf="!isLoading">
  <mat-dialog-content class="mat-typography">
    <div class="text-box w-100">
      <form [formGroup]="rejectionForm" #form="ngForm">
        <div class="editor-container">
          <ckeditor [editor]="Editor" formControlName="rejection" cdkFocusInitial>
          </ckeditor>
          <button mat-fab (click)="deleteContent()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="sendRejection()">
      {{'financing.features.financing-processing.process.sendRejection' | translate}}
    </button>
    <button mat-raised-button [mat-dialog-close]="true">
      {{'button.cancel' | translate}}
    </button>
  </mat-dialog-actions>
</ng-container>
<div class="waiter w-100" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
  <waiter-component [isLoading]="isLoading"></waiter-component>
</div>