import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { InterestMethod, MandantType } from '@ntag-ef/finprocess-enums';
import { ObjectPurposeType, RealEstateType } from '@ntag-ef/finprocess-enums/finprocess';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';

import { FinancingMode, FinancingService, FinancingState, IDocument, IFinancing, IFinancingStateParentDefinition, OverwriteValueClassType, ValueStorageType } from '../../../../data';

import { IRealEstate } from './../../../../data/interfaces/real-estate.interface';

/**
 * Komponente für den Tab Produktrechner
 */
@Component({
    selector: 'finprocess-product-calculator',
    templateUrl: './product-calculator.component.html',
    styleUrls: ['./product-calculator.component.scss'],
})
export class ProductCalculatorComponent implements OnInit, OnDestroy {
    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    @Select(FinancingState.comfortCredit)
    public comfortCredit$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Kreditbetrag
    @Select(FinancingState.comfortCreditProcessingCharges)
    public comfortCreditProcessingCharges$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Bearbeitungsspessen
    public financingFee$!: Observable<number>; // Darlehen Rückzahlungszeit
    @Select(FinancingState.comfortCreditEstimateCharges)
    public comfortCreditEstimateCharges$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Schätzgebühr
    @Select(FinancingState.comfortCreditRegistrationCharges)
    public comfortCreditRegistrationCharges$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Eintragungsgebühr
    @Select(FinancingState.comfortCreditLegalisationFee)
    public comfortCreditLegalisationFee$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Legalisierungsgebühren
    public landRegistryRequest$!: Observable<number>; // Darlehen Grundbuchantrag
    public landRegistryExcerpt$!: Observable<number>; // Darlehen Grundbuchauszug
    @Select(FinancingState.comfortCreditPayout)
    public comfortCreditPayout$!: Observable<(withOverwrites?: boolean) => number>; // Darlehen Auszahlungsbetrag
    public interestMethod!: ISelectItem<string>[];
    public gracePeriod$!: Observable<number>; // Darlehen Rückzahlungszeit
    public requestedDebitRateHint = '';
    @Select(FinancingState.comfortCreditPlus)
    public comfortCreditPlus$!: Observable<(withOverwrites?: boolean) => number>; // Ergänzungsdarlehen Kreditbetrag
    @Select(FinancingState.comfortCreditPlusProcessingCharges)
    public comfortCreditPlusProcessingCharges$!: Observable<(withOverwrites?: boolean) => number>; // Ergänzungsdarlehen Bearbeitungsspesen
    @Select(FinancingState.comfortCreditPlusPayout)
    public comfortCreditPlusPayout$!: Observable<(withOverwrites?: boolean) => number>; // Ergänzungsdarlehen Auszahlungsbetrag
    @Select(FinancingState.comfortCreditPlusDuration)
    public comfortCreditPlusDuration$!: Observable<number>; // Ergänzungsdarlehen Kreditlaufzeit
    @Select(FinancingState.comfortCreditMonthlyDebitRate)
    public comfortCreditMonthlyDebitRate$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Monatliche Rate Darlehen
    @Select(FinancingState.comfortCreditMonthlyInterestRate)
    public comfortCreditMonthlyInterestRate$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Zinsrate Darlehen
    @Select(FinancingState.comfortCreditPlusMonthlyDebitRate)
    public comfortCreditPlusMonthlyDebitRate$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Monatliche Rate Ergänzungsdarlehen
    @Select(FinancingState.comfortCreditTotalMonthlyDebitRateToYearTen)
    public comfortCreditTotalMonthlyDebitRateToYearTen$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Gesamtrate (1. - 10. Jahr)
    @Select(FinancingState.comfortCreditTotalMonthlyDebitRateFromYearEleven)
    public comfortCreditTotalMonthlyDebitRateFromYearEleven$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Gesamtrate (ab 11. Jahr)
    @Select(FinancingState.comfortCreditTotalAmount)
    public comfortCreditTotalAmount$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Gesamter Kreditbetrag
    @Select(FinancingState.comfortCreditTotalPayout)
    public comfortCreditTotalPayout$!: Observable<(withOverwrites?: boolean) => number>; // Gesamtrate Gesamtauszahlungsbetrag
    @Select(FinancingState.acceptableCreditRate)
    public acceptableCreditRate$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Zumutbare Kreditrate
    @Select(FinancingState.mainCreditFictionalRate)
    public mainCreditFictionalRate$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Zinssatz Darlehen
    @Select(FinancingState.comfortCreditFictionalRateAmount)
    public comfortCreditFictionalRateAmount$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Monatliche fiktive Rate Darlehen
    @Select(FinancingState.comfortCreditPlusFictionalRate)
    public comfortCreditPlusFictionalRate$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Zinssatz Ergänzungsdarlehen
    @Select(FinancingState.comfortCreditPlusFictionalRateAmount)
    public comfortCreditPlusFictionalRateAmount$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Monatliche fiktive Rate Ergänzungsdarlehen
    @Select(FinancingState.comfortCreditTotalFictionalRateAmount)
    public comfortCreditTotalFictionalRateAmount$!: Observable<(withOverwrites?: boolean) => number>; // Leistbarkeit Monatliche fiktive Rate Gesamt
    @Select(FinancingState.hhrCovered)
    public hhrCovered$!: Observable<(withOeverwrites?: boolean) => boolean>; // Leistbarkeit durch HHR abgedeckt

    /**
     * Quellsystem
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.mandantType ?? MandantType.Default))
    public mandantType$!: Observable<MandantType>;

    /**
     * Für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public MandantType = MandantType;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;
    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;
    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    public loading = false;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    public isLot = false;

    public mainRealestate: IRealEstate | undefined;

    /**
     * Zum Ausblenden von RealEstate Feldern für Risikofinanzierungspläne
     */
    public isRiskFinancingPlan = false;

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     */
    public constructor(
        private financingService: FinancingService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Angular Lifecycle Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.queryParamMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => params.get('mode') as FinancingMode),
            distinctUntilChanged(),
        ).subscribe((mode: FinancingMode) => {
            this.isRiskFinancingPlan = mode === FinancingMode.RiskFinancingPlan;
        });

        this.landRegistryRequest$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(financing => financing?.financingConfiguration.landRegisterRequest ?? 0),
        );
        this.landRegistryExcerpt$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(financing => financing?.financingConfiguration.landRegisterExtract ?? 0),
        );
        this.gracePeriod$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(financing => financing?.gracePeriod ?? 0),
        );

        this.financingFee$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(financing => financing?.financingFee ?? 0),
        );

        this.financing$.pipe(takeUntil(this.onDestroy$)).subscribe(financing => {
            if (financing === undefined) {
                this.requestedDebitRateHint = '';
                return;
            }

            this.mainRealestate = financing.realEstates.find(real => real.objectPurpose === ObjectPurposeType.Finance);
            if (!!this.mainRealestate) {
                this.setIsLot(this.mainRealestate)
            }

            const requestedDebitRate = OverwriteHelperService.getMergedOverwriteValue(financing, 'requestedDebitRate', financing.requestedDebitRate, true);

            if (requestedDebitRate === undefined || requestedDebitRate <= 0) {
                this.requestedDebitRateHint = this.translate.instant('financing.features.financing-processing.calculator.requestedDebitRateHint', { i: financing.financingConfiguration.requestedDebitRateDefault});
            } else {
                this.requestedDebitRateHint = '';
            }
        })

        this.interestMethod = (HelperService.getEnumArray(InterestMethod) as number[]).filter(interestMethod => interestMethod !== InterestMethod.FixFiveYears).map(interestMethod => ({
            value: interestMethod.toString(),
            displayName: InterestMethod.translate(interestMethod) ?? '',
        })) ;


        this.documents$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(() => () => []),
        );
    }

    /**
     * Prüft ob es sich um ein Grundstück handelt
     *
     * @param {IRealEstate} realEstate Objekt
     */
    private setIsLot(realEstate: IRealEstate): void {
        const type = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'type', realEstate.type);
        this.isLot = (type === RealEstateType.Lot);
    }
    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
