import { CdkPortal } from '@angular/cdk/portal';
import { Directive, Inject, InjectionToken, TemplateRef, ViewContainerRef, contentChild, inject, input, signal } from '@angular/core';

import { UCBA_TABLE } from '../components/table/table.component';

export const UCBA_TABLE_ROW = new InjectionToken<UcbaTableRowDirective>('UCBA_TABLE_ROW');

/**
 * Directive for a header in a ucba-table-row. Has to be applied to an ng-template.
 * Needs to either be inside a ucba-table-row or a ucba-table-header.
 * 
 * Usage
 * ```html
 * <ucba-table>
 *   <ng-template ucbaTableRow>
 *     <ng-template ucbaTableRowHeader>
 *     </ng-template>
 *   </ng-template>
 * </ucba-table>
 * ```
 */
@Directive({
    selector: '[ucbaTableRowHeader], [ucba-table-row-header]',
    standalone: true,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { 'class': 'ucba-table-row-header' },
})
export class UcbaTableRowHeaderDirective extends CdkPortal {
    public closestTable = inject(UCBA_TABLE, { optional: true });
    public closestRow = inject(UCBA_TABLE_ROW, { optional: true });

    /**
     * Tries to find the closest parent row and sets a reference to itself on the row.
     * This reference will be used to render the header inside the row.
     * 
     * @param {ViewContainerRef} viewContainerRef View container reference
     * @param {TemplateRef} templateRef Template reference
     */
    public constructor(@Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(TemplateRef) templateRef: TemplateRef<unknown>) {
        super(templateRef, viewContainerRef);

        if (this.closestRow) {
            this.closestRow.rowHeader.set(this);
        }
    }
}

/**
 * Directive for a row in a ucba-table. Has to be applied to an ng-template.
 * 
 * Usage
 * ```html
 * <ucba-table>
 *    <ng-template ucba-table-row>
 *    </ng-template>
 * </ucba-table>
 * ```
 */
@Directive({
    selector: '[ucbaTableRow], [ucba-table-row]',
    standalone: true,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { 'class': 'ucba-table-row' },
    providers: [
        { provide: UCBA_TABLE_ROW, useExisting: UcbaTableRowDirective },
    ],
})
export class UcbaTableRowDirective extends CdkPortal {
    public closestTable = inject(UCBA_TABLE, { optional: true });
    public header = contentChild(UcbaTableRowHeaderDirective);
    public rowHeader = signal<UcbaTableRowHeaderDirective | undefined>(undefined);
}

/**
 * Directive for a highlighted row in a ucba-table. Has to be applied to an ng-template with ucba-table-row directive.
 * 
 * Usage
 * ```html
 * <ucba-table>
 *    <ng-template ucba-table-row ucba-highlighted-table-row>
 *    </ng-template>
 * </ucba-table>
 * ```
 */
@Directive({
    selector: '[ucbaHighlightedTableRow], [ucba-highlighted-table-row]',
    standalone: true,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { 'class': 'highlighted' },
    providers: [
        { provide: UCBA_TABLE_ROW, useExisting: UcbaHighlightedTableRowDirective },
    ],
})
export class UcbaHighlightedTableRowDirective extends UcbaTableRowDirective {
    public color = input<'light' | 'dark'>('light');
}

/**
 * Directive for a header in a ucba-table. Has to be applied to an ng-template.
 * 
 * Usage
 * ```html
 * <ucba-table>
 *   <ng-template ucbaTableHeader>
 *  </ng-template>
 * </ucba-table>
 * ```
 */
@Directive({
    selector: '[ucbaTableHeader], [ucba-table-header]',
    standalone: true,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { 'class': 'ucba-table-header' },
    providers: [
        { provide: UCBA_TABLE_ROW, useExisting: UcbaTableHeaderDirective },
    ],
})
export class UcbaTableHeaderDirective extends UcbaTableRowDirective { }
