/* eslint-disable class-methods-use-this */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';

import { IUploadContext, IUploadDocument, IUploadList } from './../../../../data/interfaces/upload-tab.interface';

/**
 * Komponente für den Rechenbeispiele-Dialog
 */
@Component({
    selector: 'finprocess-calculation-examples-dialog',
    templateUrl: './calculation-examples-dialog.component.html',
    styleUrls: ['./calculation-examples-dialog.component.scss'],
})
export class CalculationExamplesDialogComponent implements OnInit {

    public files: Pick<IUploadDocument, 'file' | 'alreadyExists' | 'fileId'>[][] = [];

    /**
     * Check für den Hinzufügen Button
     *
     * @returns {boolean} Ob der Button invalid ist
     */
    public get invalid(): boolean {
        if (this.files.every(list => list.length === 0)) {
            return true;
        }

        return false;
    }

    /**
     * Standard Konstruktor
     *
     * @param {IUploadContext} data Daten für den Dialog
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe-Injektor
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: IUploadContext,
        public dialogRef: MatDialogRef<CalculationExamplesDialogComponent>,
        private enumTranslate: EnumTranslationPipe,
    ) {
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.files = this.data.lists.map(list => list.list.map(file => ({
            file: file.file,
            alreadyExists: file.alreadyExists,
            fileId: file.fileId,
        })));
    }

    /**
     * Fügt die Dateien hinzu und schließt den Dialog
     */
    public addFiles(): void {
        const newFiles = this.files.map((fileList, index) => {
            const currentList = this.data.lists[index];

            return {
                list: fileList.map((file, fileIndex) => {
                    let newFile = file.file;

                    if ([
                        DocumentType.HouseholdBalanceSignature,
                        DocumentType.CompositionOfOtherIncomeSignature,
                        DocumentType.SelfDisclosure,
                    ].includes(currentList.type as number)) {
                        newFile = new File([file.file], `${this.data.title}_${this.enumTranslate.transform(currentList.type, 'DocumentType')}-${fileIndex + 1}.pdf`, { type: file.file.type});
                    }

                    return {
                        file: newFile,
                        type: currentList.type,
                        fileId: file.fileId,
                        parentId: this.data.parentId,
                        alreadyExists: file.alreadyExists,
                    };
                }),
                type: currentList.type,
                maxFileCount: currentList.maxFileCount,
            }
        });

        this.dialogRef.close(newFiles);
    }

    /**
     * Schließt den Dialog
     */
    public cancel(): void {
        this.dialogRef.close(undefined);
    }

    /**
     * Gibt die Dateien einer Liste zurück
     *
     * @param {IUploadList} list Liste
     * @returns {File[]} Dateien
     */
    public getFiles(list: IUploadList): File[] {
        return list.list.map(doc => doc.file);
    }

}
