import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_COMPLEMENTARY_DATA_MODEL } from './customer-complementary-data-model';

export const CREATE_OR_UPDATE_COMPLEMENTARY_DATA: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'createOrUpdateComplementaryData',

    ndg: { type: WorkflowType.String },

    createOrUpdateModel: { type: WorkflowType.Object, child: CUSTOMER_COMPLEMENTARY_DATA_MODEL },
}
