import { ArgumentError, DivisionByZeroError } from '../errors';
import { IFinancingConfiguration } from '../interfaces';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService, InternalCalculationService } from './core';

/**
 * Berechnungsmethoden zu Neuen Verpflichtungen
 */
export class NewLiabilityCalculationService extends BaseCalculationService {

    /**
     * Berechnungsservice InternalCalculationService
     */
    private pInternalCalculationService?: InternalCalculationService;

    /**
     * Berechnungsservice InternalCalculationService
     *
     * @returns {InternalCalculationService} InternalCalculationService
     */
    private get internalCalculationService() {
        if (this.pInternalCalculationService === undefined) {
            this.pInternalCalculationService = new InternalCalculationService(this.version);
        }
        return this.pInternalCalculationService;
    }

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Liefert Kreditbetrag, wenn grundbücherlich besichert
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.amount Kreditbetrag/Förderbetrag in €
     * @param {boolean | undefined} params.securedByLandRegister Grundbücherlich besichert
     * @returns {number} Kreditbetrag in €
     */
    public amountSecuredByLandRegister({ amount, securedByLandRegister }: {
        /**
         * Kreditbetrag/Förderbetrag in €
         */
        amount?: number;
        /**
         * Grundbücherlich besichert
         */
        securedByLandRegister?: boolean;
    }): number {
        return securedByLandRegister === true ? (!CalculationHelperService.isNullOrNaN(amount) ? (amount as number) : 0) : 0;
    }

    /**
     * Liefert Monatliche Rate, wenn grundbücherlich besichert
     *
     * @param {object} params Parameterobjekt
     * @param {boolean | undefined} params.securedByLandRegister Grundbücherlich besichert
     * @param {number | undefined} params.monthlyRate Monatliche Rate in €
     * @returns {number} Monatliche Rate in €
     */
    public monthlyRateSecuredByLandRegister({ securedByLandRegister, monthlyRate }: {
        /**
         * Grundbücherlich besichert
         */
        securedByLandRegister?: boolean;
        /**
         * Monatliche Rate in €
         */
        monthlyRate?: number;
    }): number {
        return securedByLandRegister === true && !CalculationHelperService.isNullOrNaN(monthlyRate) ? (monthlyRate as number) : 0;
    }

    /**
     * Liefert oder berechnet Kreditbetrag/Förderbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.amount Kreditbetrag/Förderbetrag in €
     * @param {number | undefined} params.monthlyRate Monatliche Rate in €
     * @param {number | undefined} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @returns {number} Kreditbetrag/Förderbetrag in €
     */
    public amountCalculated({ amount, monthlyRate, loanPeriodInMonths }: {
        /**
         * Kreditbetrag/Förderbetrag in €
         */
        amount?: number;
        /**
         * Monatliche Rate in €
         */
        monthlyRate?: number;
        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths?: number;
    }): number {
        if (!CalculationHelperService.isNullOrNaN(amount)) {
            return (amount as number);
        }
        else {
            return (!CalculationHelperService.isNullOrNaN(monthlyRate) ? (monthlyRate as number) : 0) * (!CalculationHelperService.isNullOrNaN(loanPeriodInMonths) ? (loanPeriodInMonths as number) : 0);
        }
    }

    /**
     * Liefert oder berechnet Monatliche Rate
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.monthlyRate Monatliche Rate in €
     * @param {number | undefined} params.amount Kreditbetrag/Förderbetrag in €
     * @param {number | undefined} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche Rate in €
     */
    public monthlyRateCalculated({ monthlyRate, amount, loanPeriodInMonths }: {
        /**
         * Monatliche Rate in €
         */
        monthlyRate?: number;
        /**
         * Kreditbetrag/Förderbetrag in €
         */
        amount?: number;
        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths?: number;
    }): number {
        if (!CalculationHelperService.isNullOrNaN(monthlyRate)) {
            return monthlyRate as number;
        }
        else if (!CalculationHelperService.isNullOrNaN(loanPeriodInMonths)) {
            if ((loanPeriodInMonths as number) > 0) {
                return (!CalculationHelperService.isNullOrNaN(amount) ? (amount as number) : 0) / (loanPeriodInMonths as number);
            }
            else if (loanPeriodInMonths === 0) {
                throw new DivisionByZeroError();
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet die fiktive Rate einer neuen Verpflichtung
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number | undefined} params.monthlyRate Monatliche Rate in €
     * @param {number | undefined} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number | undefined} params.amount Kreditbetrag in €
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Fiktive Rate in €
     */
    public fictionalAmount({ fictionalRate, monthlyRate, loanPeriodInMonths, amount, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Monatliche Rate in €
         */
        monthlyRate?: number;
        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths?: number;
        /**
         * Kreditbetrag in €
         */
        amount?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (fictionalRate === 0 || loanPeriodInMonths === 0) {
            throw new DivisionByZeroError();
        }

        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: !CalculationHelperService.isNullOrNaN(amount) ? (amount as number) : ((!CalculationHelperService.isNullOrNaN(monthlyRate) ? (monthlyRate as number) : 0) * (loanPeriodInMonths as number)), rate: fictionalRate }) || !CalculationHelperService.isGreaterThan(loanPeriodInMonths)) {
            return 0;
        }

        const tmpAmount = !CalculationHelperService.isNullOrNaN(amount) ? (amount as number) : ((!CalculationHelperService.isNullOrNaN(monthlyRate) ? (monthlyRate as number) : 0) * (loanPeriodInMonths as number));
        return tmpAmount * this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }) / (1 - (1 / Math.pow(1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }), (loanPeriodInMonths as number)))) + bankAccountFee;
    }

    /**
     * Liefert den fiktiven Zinssatz anhand der Konfiguration zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @returns {number} Fiktiver Zinssatz in %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public fictionalRate({ configuration }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
    }): number {
        this.checkConfiguration(configuration);
        return configuration.newLiabilityFictionalRate;
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten bei neuen Verpflichtungen
     *
     * @param {IFinancingConfiguration | null | undefined} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfiguration(configuration?: IFinancingConfiguration | null): void {
        if (configuration === undefined ||
            configuration === null ||
            isNaN(configuration.newLiabilityFictionalRate) ||
            (configuration.newLiabilityFictionalRate as unknown) === null) {
            throw new ArgumentError('Der Parameter \'configuration\' ist nicht valide.');
        }
    }
}
