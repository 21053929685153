import { ChangeDetectionStrategy, Component, Input, computed, input } from '@angular/core';

/**
 * Komponente für zweispaltiges Layout
 */
@Component({
    selector: 'finprocess-two-column-layout',
    templateUrl: './two-column-layout.component.html',
    styleUrls: ['./two-column-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoColumnLayoutComponent {

    //Default Werte Layout Breite
    public fxFlexDefault = input<number>(75);

    /**
     * Layout-Breite der Seiten in % für kleine Bildschirme
     */
    public flexSm = input<number>(50);

    /**
     * Layout-Breite der Seiten in % für mittlere Bildschirme
     */
    public flexMd = input<number>(75);
 
    /**
     * Layout-Breite der Seiten in % für große Bildschirme
     */
    public flexLg = input<number>(80);

    /**
     * Links ist default größer - Switch um die Breiten zu tauschen
     */
    public biggerSide = input<'left' | 'right'>('left');

    /**
     * Computed Werte für die Breiten der Seiten
     */
    public fxFlexLeft = computed(() => (this.biggerSide() === 'left' ? this.fxFlexDefault() : 100 - this.fxFlexDefault()));
    public fxFlexRight = computed(() => (this.biggerSide() === 'right' ? this.fxFlexDefault() : 100 - this.fxFlexDefault()));

    public fxFlexLeftSm = computed(() => (this.biggerSide() === 'left' ? this.flexSm() : 100 - this.flexSm()));
    public fxFlexRightSm = computed(() => (this.biggerSide() === 'right' ? this.flexSm() : 100 - this.flexSm()));

    public fxFlexLeftMd = computed(() => (this.biggerSide() === 'left' ? this.flexMd() : 100 - this.flexMd()));
    public fxFlexRightMd = computed(() => (this.biggerSide() === 'right' ? this.flexMd() : 100 - this.flexMd()));

    public fxFlexLeftLg = computed(() => (this.biggerSide() === 'left' ? this.flexLg() : 100 - this.flexLg()));
    public fxFlexRightLg = computed(() => (this.biggerSide() === 'right' ? this.flexLg() : 100 - this.flexLg()));

    /**
     * Layout-Orientierung der rechten Seite
     */
    @Input()
    public rightFxLayoutAlign = 'start center';

    /**
     * Layout-Orientierung der linken Seite
     */
    @Input()
    public leftFxLayoutAlign = 'start center';

    /**
     * Klassen der linken Seite
     */
    @Input()
    public leftClass = ' px-2 py-3';

    /**
     * Klassen der rechten Seite
     */
    @Input()
    public rigthClass = ' px-2 py-3';
}
