import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { DocumentService, EntityClassType, FinancingService, IFile, IFinancingStateParentDefinition, IFinprocessContainer, IProductPackageAccepted, IProductPackageData, ISetStatusRequest, IStatusEntry } from 'app/modules/financing/data';
import { FinancingStatus, FinancingSubStatus, HelperService, SubStatusCode, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { EMPTY, Observable, Subject, catchError, combineLatest, filter, finalize, map, mergeMap, of, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';

/**
 * Show the Customer Center
 */
@Component({
    selector: 'finprocess-customer-center',
    templateUrl: './customer-center.component.html',
    styleUrls: ['./customer-center.component.scss'],
})
export class CustomerCenterComponent implements OnInit, OnDestroy {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Liste aller Produktpakete nach Finanzierungsplan gruppiert
     */
    public riskFinancingPlans$: Observable<Array<IProductPackageData & { name: string }>> | undefined;

    /**
     * Liste der Produktpaket IDs
     */
    public productPackageIds$: Observable<UUID[]> = new Observable();

    public financingContainerId?: string;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    public expanded: boolean[][] = [];

    public page1Note?: string;

    public statusText?: string;
    public dateText?: string;

    public showSendButton = false;
    public sampleCalculationAccepted = false;

    public acceptedSampleCalculation?: IFile;

    public disableSendToReferee = false;

    /**
     * SampleCalculation Response // Notiz
     */
    public sampleCalculationResponse?: string;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Stellungnahme zur Übergabe an Referenten
     */
    public refereeStatement: string | null = null;


    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;


    // eslint-disable-next-line @typescript-eslint/naming-convention
    public EntityClassType = EntityClassType;

    public acceptedProductPackage$!: Observable<IProductPackageAccepted | undefined>;

    public productPackageEmptyMessage: string | undefined;

    public selectedIndex = 0;

    /**
     * Konstruktor
     * 
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {WaiterService} waiterService waiter service
     * @param {NotificationService} notificationService NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {DatePipe} datePipe DatePipe injector
     * @param {ActivatedRoute} route ActivatedRoute injector
     * @param {Router} router Router injector
     */
    public constructor(
        private store: Store,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private waiterService: WaiterService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    /**
     * Initialisierung
     */
    public ngOnInit() {

        this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
            const tab = params['tab'];
            if (!!tab) {
                this.selectedIndex = +tab;
            }
        });

        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
        this.riskFinancingPlans$ = this.store.select((state: IFinancingStateParentDefinition) => state.financing.riskFinancingPlans)
            .pipe(
                mergeMap(rfpDatas => {
                    this.expanded = rfpDatas.map(() => []);
                    return this.financingService.getAllProductPackagesOfAllRFPs(rfpDatas)
                }),
                tap(riskFinancingPlans => {
                    if (!Array.isArray(riskFinancingPlans) || riskFinancingPlans.length === 0) {
                        this.page1Note = this.translate.instant('financing.features.financing-processing.customer-center.messageNoSampleCalculation');
                    }
                }),
                shareReplay(1),
            );

        this.productPackageIds$ = this.riskFinancingPlans$.pipe(
            takeUntil(this.onDestroy$),
            map(riskFinancingPlans => riskFinancingPlans.reduce<UUID[]>((acc, rfp) => {
                if (!!rfp && rfp.assignProductPackages && rfp.assignProductPackages.length !== 0) {
                    const ppIds = rfp.assignProductPackages.map(({ productPackageID }) => productPackageID);
                    return [...acc, ...ppIds];
                }
                return acc;
            }, [])),
        );

        this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer).pipe(
            takeUntil(this.onDestroy$),
            // eslint-disable-next-line complexity
        ).subscribe(container => {

            if (!container) {
                return;
            }

            const sampleCalculationWaitingForAcceptionEntries = container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationWaitingForAcception);
            const sampleCalculationAcceptedEntries = container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationAccepted);
            if (sampleCalculationWaitingForAcceptionEntries.length > 1 && sampleCalculationAcceptedEntries.length === 0) {
                this.page1Note = this.translate.instant('financing.features.financing-processing.customer-center.messageRequestedCalculation');
            }

            this.financingContainerId = container.id;
            this.sampleCalculationResponse = container.sampleCalculationResponse;

            if (container.status === FinancingStatus.Open || container.status === FinancingStatus.HouseholdCalculationAccepted) {

                this.showSendButton = true;

                if (container.subStatus === FinancingSubStatus.SampleCalculationRejected) {
                    const statusEntry = sort(container.statusEntries.filter(se => se.subStatus === FinancingSubStatus.SampleCalculationRejected)).desc(it => it.created)[0];

                    this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationRejected');
                    this.dateText = statusEntry !== undefined ? this.datePipe.transform(statusEntry.created, 'short') ?? undefined : undefined;
                }
            }
            else if (container.status === FinancingStatus.SampleCalculationWaitingForAcception) {
                const statusEntry = sort(container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationWaitingForAcception)).desc(it => it.created)[0];

                this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationSended');
                this.dateText = statusEntry !== undefined ? (this.datePipe.transform(statusEntry.created, 'short') ?? undefined) : undefined;
            }
            else if ((container.status === FinancingStatus.SampleCalculationAccepted || container.status === FinancingStatus.Finalize) && container.acceptedSampleCalculation) {
                this.sampleCalculationAccepted = true;

                if (container.status === FinancingStatus.Finalize) {
                    this.disableSendToReferee = true;
                }

                this.page1Note = '';

                const statusEntry: IStatusEntry | undefined = sort(container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationAccepted)).desc(it => it.created)[0];

                this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationAccepted');
                this.dateText = statusEntry !== undefined ? (this.datePipe.transform(statusEntry.created, 'short') ?? undefined) : undefined;

                this.documentService.getFileMetadata(container.acceptedSampleCalculation)
                    .pipe(take(1))
                    .subscribe(file => {
                        this.acceptedSampleCalculation = file;
                    });
            }

            if (container.status >= FinancingStatus.Finalize) {
                this.refereeStatement = sort(container.statusEntries.filter(se => se.subStatus === FinancingSubStatus.ReadyForReferee)).desc(it => it.created)[0]?.statusInformation ?? null;
            }
        });

        combineLatest([
            this.riskFinancingPlans$,
            this.store.select((state: IFinancingStateParentDefinition) => state.financing),
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                filter(([riskFinancingPlans, financingStateModel]) => Array.isArray(riskFinancingPlans) && !!financingStateModel),
                filter(([riskFinancingPlans]) => !!riskFinancingPlans[0]?.financingMapID),
                switchMap(([riskFinancingPlans, financingStateModel]) => {
                    if (!financingStateModel.financing) {
                        // If financing doesn't exist, load it
                        return this.financingService.loadFinancing(riskFinancingPlans[0].financingMapID).pipe(map(financing => ({ riskFinancingPlans, financing })));
                    } else {
                        // If financing exists, continue with the current stream
                        return of({ riskFinancingPlans, financingStateModel });
                    }
                }),
            )
            // eslint-disable-next-line complexity
            .subscribe(data => {

                if ('financingStateModel' in data) {
                    const container = data.financingStateModel.finprocessContainer;

                    this.sampleCalculationResponse = container?.sampleCalculationResponse;

                    if ((!!container)) {
                        this.financingContainerId = container.id;

                        if (container.status === FinancingStatus.Open || container.status === FinancingStatus.HouseholdCalculationAccepted) {

                            this.showSendButton = true;

                            if (container.subStatus === FinancingSubStatus.SampleCalculationRejected) {
                                const statusEntry = sort(container.statusEntries.filter(se => se.subStatus === FinancingSubStatus.SampleCalculationRejected)).desc(it => it.created)[0];

                                this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationRejected');
                                this.dateText = statusEntry !== undefined ? this.datePipe.transform(statusEntry.created, 'short') ?? undefined : undefined;
                            }
                        }
                        else if (container.status === FinancingStatus.SampleCalculationWaitingForAcception) {
                            const statusEntry = sort(container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationWaitingForAcception)).desc(it => it.created)[0];

                            this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationSended');
                            this.dateText = statusEntry !== undefined ? (this.datePipe.transform(statusEntry.created, 'short') ?? undefined) : undefined;
                        }
                        else if ((container.status === FinancingStatus.SampleCalculationAccepted || container.status === FinancingStatus.Finalize) && container.acceptedSampleCalculation) {
                            this.sampleCalculationAccepted = true;

                            if (container.status === FinancingStatus.Finalize) {
                                this.disableSendToReferee = true;
                            }

                            const statusEntry: IStatusEntry | undefined = sort(container.statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationAccepted)).desc(it => it.created)[0];

                            this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationAccepted');
                            this.dateText = statusEntry !== undefined ? (this.datePipe.transform(statusEntry.created, 'short') ?? undefined) : undefined;

                            this.documentService.getFileMetadata(container.acceptedSampleCalculation)
                                .pipe(take(1))
                                .subscribe(file => {
                                    this.acceptedSampleCalculation = file;
                                });
                        }

                        if (container.status >= FinancingStatus.Finalize) {
                            this.refereeStatement = sort(container.statusEntries.filter(se => se.subStatus === FinancingSubStatus.ReadyForReferee)).desc(it => it.created)[0]?.statusInformation ?? null;
                        }
                    }
                }
            });

        this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing?.finprocessContainer).pipe(
            filter((financingContainer): financingContainer is IFinprocessContainer => financingContainer !== undefined && financingContainer.status >= FinancingStatus.SampleCalculationAccepted),
            mergeMap(financingContainer => this.financingService.getAcceptedProductPackage((financingContainer as IFinprocessContainer).id)),
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusCode.MethodNotAllowed && error.error?.subStatusCode === SubStatusCode.SampleCalculationMissing) {
                    this.productPackageEmptyMessage = this.translate.instant('financing.features.financing-processing.customer-center.emptyMessage');
                } else {
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.customer-center.acceptedProductPackageError'));
                }
                return EMPTY;
            }),
        ).subscribe();

        this.acceptedProductPackage$ = this.store.select((it: IFinancingStateParentDefinition) => it.financing?.acceptedProductPackage);
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * open sample calculation document
     *
     * @param {UUID} id sample calculation id
     * @param {string} fileName fileName
     */
    public openSampleCalculationDocument(id: UUID, fileName: string) {
        this.waiterService.show();
        this.financingService.getSampleCalculation(id).pipe(take(1)).subscribe({
            next: async fileContents => {
                if (fileContents === undefined || null) {
                    this.waiterService.hide();
                    return;
                }

                await HelperService.downloadFileFromBlob(fileContents, fileName);
                this.waiterService.hide();
            },
        });
    }

    /**
     * sendet alle Rechenbeispiele
     */
    public sendSampleCalculations() {
        if (!!this.financingContainerId) {
            combineLatest([
                this.waiterService.show(),
                this.productPackageIds$,
            ]).pipe(
                take(1),
                mergeMap(([, productPackageIds]) => {
                    if (!this.financingContainerId) {
                        throw new Error('No financing container id');
                    }

                    return this.financingService.sendSampleCalculations(this.financingContainerId, productPackageIds)
                }),
                mergeMap(() => {
                    if (!!this.financingContainerId) {
                        const financingID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.id);
                        if (!!financingID) {
                            this.financingService.getStatusEntries(financingID).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                                const statusEntries = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer?.statusEntries);

                                if (!!statusEntries) {
                                    const statusEntry = sort(statusEntries.filter(se => se.status === FinancingStatus.SampleCalculationWaitingForAcception)).desc(it => it.created)[0];
                                    this.statusText = this.translate.instant('financing.features.financing-processing.customer-center.messageSampleCalculationSended');
                                    this.dateText = this.datePipe.transform(statusEntry.created, 'short') ?? undefined;
                                }
                            });
                        }
                    }
                    return of(null);
                }),
            ).subscribe({
                next: () => {
                    this.showSendButton = false;
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.customer-center.sendSCError'));
                },
            });
        }
    }

    /**
     * Lädt ein Dokument
     *
     * @param {IFile} file Die zu ladende Datei
     */
    public onAcceptedSampleCalculationClick(file: IFile): void {
        this.documentService.loadFile(file.id)
            .subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }

                },
            });
    }

    /**
     * Setzt den Status auf "Colt Bearbeitung" und weist den Nutzer selbst als Referenten zu
     */
    public assignSelfAsReferee(): void {

        if (!!this.financingContainerId) {
            const financingContainerId = this.financingContainerId;
            const setStatusRequest: ISetStatusRequest = {
                status: FinancingStatus.Finalize,
                subStatus: FinancingSubStatus.InProgress,
                id: this.financingContainerId,
            }

            this.waiterService.show({
                text: 'Status für Weiterbearbeitung wird gesetzt',
            }).pipe(
                mergeMap(() => this.financingService.assignSelfAsReferee(financingContainerId)),
                mergeMap(() => this.financingService.setStatus(setStatusRequest)),
                finalize(() => this.waiterService.hide()),
            ).subscribe({
                error: () => {
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.customer-center.assignSelfAsRefereeError'));
                },
            });
        }
    }

    /**
     * Übergabe des Falls an Referenten
     */
    public sendToReferee() {

        if (!!this.financingContainerId && !!this.refereeStatement) {
            this.waiterService.show({
                text: 'Status wird zur Übergabe an Referenten geändert',
            });

            const changeStatus: ISetStatusRequest = {
                id: this.financingContainerId,
                status: FinancingStatus.Finalize,
                subStatus: FinancingSubStatus.ReadyForReferee,
                reason: this.refereeStatement,
            }

            this.financingService.setStatus(changeStatus).pipe(
                take(1),
                tap(() => { this.disableSendToReferee = true }),
                finalize(() => this.waiterService.hide()),
            ).subscribe({
                error: () => {
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.customer-center.setToReferentError'));
                },
            });
        }
    }

    /**
     * Update the URL with the new tab index
     * 
     * @param {number} index new tab index
     */
    public async updateIndex(index: number) {
        this.selectedIndex = index;
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { tab: index },
            queryParamsHandling: 'merge',
        });
    }
}
