export enum ProductDescriptionType {
    /**
     * 01 Spill Over
     */
    SpillOver = 0,

    /**
     * 02  free text field for non Spill over
     */
    NonSpillover = 2,
}
