/* eslint-disable class-methods-use-this */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { HelperService, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { Observable, Subject, iif, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { DocumentService, FinancingService, IAssignmentHistoryEntry, IFinancingStateParentDefinition, IFinprocessContainer, IStatusEntry } from '../../../../data';

/**
 * Komponente zur Anzeige der Statuseinträge
 */
@Component({
    selector: 'finprocess-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent implements OnInit, OnDestroy {

    @Select((state: IFinancingStateParentDefinition) => state.financing.finprocessContainer)
    public finprocessContainer$!: Observable<IFinprocessContainer | undefined>

    public editHistoryEntries$!: Observable<Array<IStatusEntry | IAssignmentHistoryEntry>>;

    /**
     * Ladezustand der Komponente
     */
    public loading = true;

    public exportDisabled$!: Observable<boolean>;

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Standard Konstruktor
     *
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {Store} store Store-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private translate: TranslateService,
        private store: Store,
        private waiterService: WaiterService,
        private authorizationService: AuthorizationService,
    ) {}

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(() => {
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => {
                const container = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer);

                if (!!container && container.id === id) {
                    return of(container);
                }
                return this.financingService.loadFinancingContainer(id);
            }),
            mergeMap(container => (container !== undefined ? this.financingService.getStatusEntries(container.id) : of(undefined))),
        ).subscribe(() => {
            this.loading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        this.editHistoryEntries$ = this.finprocessContainer$.pipe(
            map(finprocessContainer => {
                if (finprocessContainer === undefined) {
                    return [];
                }

                let editHistory: Array<IStatusEntry | IAssignmentHistoryEntry> = [];

                editHistory = editHistory.concat(finprocessContainer.statusEntries);
                editHistory = editHistory.concat(finprocessContainer.assignmentHistoryEntries);

                return sort(editHistory).desc(status => status.created);
            }),
        );

        this.exportDisabled$ = this.authorizationService.hasRole$(Role.FinancingMapsEditor).pipe(takeUntil(this.onDestroy$), map(hasRole => !hasRole));
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Lädt den Bearbeitungsverlauf als PDF herunter
     */
    public downloadEditHistory(): void {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.status.downloadEditHistory'),
        });

        this.store.selectOnce((it: IFinancingStateParentDefinition) => it)
            .pipe(mergeMap(
                it => iif(
                    () => it.financing.financing?.id !== undefined,
                    this.documentService.getEditHistoryExport(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        it.financing.financing!.id,
                    ),
                    of(undefined),
                ),
            )).subscribe({
                next: async arrayBuffer => {
                    if (!arrayBuffer) {
                        return;
                    }

                    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

                    await HelperService.downloadFileFromBlob(blob, this.translate.instant('financing.features.financing-processing.status.fileName'));

                    this.waiterService.hide();
                },
                error: () => this.waiterService.hide(),
            })
    }

    /**
     * Prüft ob es sich um einen StatusEintrag handelt
     *
     * @param {IStatusEntry | IAssignmentHistoryEntry} entry StatusEintrag
     * @returns {boolean} Ob es ein StatusEintrag ist
     */
    public isStatusEntry(entry: IStatusEntry | IAssignmentHistoryEntry): entry is IStatusEntry {
        return (entry as IStatusEntry).status !== undefined;
    }

    /**
     * Prüft ob es sich um einen BearbeitungsEintrag handelt
     *
     * @param {IStatusEntry | IAssignmentHistoryEntry} entry StatusEintrag
     * @returns {boolean} Ob es ein StatusEintrag ist
     */
    public isAssignment(entry: IStatusEntry | IAssignmentHistoryEntry): entry is IAssignmentHistoryEntry {
        return (entry as IAssignmentHistoryEntry).assignmentType !== undefined;
    }
}
