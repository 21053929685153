export enum PriorChargeType {
    /**
     * eigene Vorlast
     */
    Own = 3,

    /**
     * fremde Vorlast
     */
    ThirdParty = 4,
}
