export enum LoanPurpose {

    /**
     * HMMO: condominium / housestead (Eigentumswohnung)  <br/>
        verwendet für:<br/>
        Erwerb Haus / Grundstück<br/>
        Erwerb Wohnung<br/>
        Hausbau<br/>
        Grundstückskauf<br/>
        Hauserweiterung<br/>
        Sanierung<br/>
        Zubau<br/>
        Kauf Dachgeschoss<br/>
        Kauf Kleingartenahaus<br/>
     */
    HMMO,

    /**
     * HREN: renovation (Renovierung)<br/>
        verwendet für:<br/>
        Renovierung Wohnung
     */
    HREN
}
