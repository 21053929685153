import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, IUser, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ITemporaryUserViewmodel } from '../../interfaces'
import { AssignableTemporaryEditorListLoaded, AssignedAsTemporaryEditorListLoaded, UserSubstitutionConfirmed, UserSubstitutionRemoved } from '../../states';

/**
 * Service für Vertretungen
 */
@Injectable()
export class SubstitutionService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     *
     * Lädt eine Liste der Abwesender
     *
     * @returns {IUser[] | undefined} Liste der Abwesender
     */
    public getAssignableTemporaryEditorList(): Observable<IUser[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsTemporaryEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IUser[]>(`${this.config.getEnvironment().apiUrl}/TemporaryUser/AssignableTemporaryEditorList`).pipe(
                    mergeMap((users: IUser[]) => this.store.dispatch(new AssignableTemporaryEditorListLoaded(users)).pipe(
                        map(() => users),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     *
     * Lädt eine Liste der Abwesender und Vertretung
     *
     * @returns {IUser[] | undefined} Liste der Vertretenden
     */
    public getAssignedAsTemporaryEditorList(): Observable<ITemporaryUserViewmodel[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsTemporaryEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<ITemporaryUserViewmodel[]>(`${this.config.getEnvironment().apiUrl}/TemporaryUser/AssignedAsTemporaryEditorList`).pipe(
                    mergeMap((usersVM: ITemporaryUserViewmodel[]) => this.store.dispatch(new AssignedAsTemporaryEditorListLoaded(usersVM)).pipe(
                        map(() => usersVM),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Setzt Vertretung
     *
     * @param {string} substitutedId vertretenden id
     * @param {string} substitutionId vertreter id
     * @returns {Observable<boolean | undefined>} Observable
     */
    public setTemporaryEditor(substitutedId: string, substitutionId?: string): Observable<boolean | undefined> {
        let params = new HttpParams();

        params = params.append('financingsUserId', substitutedId);

        if (!!substitutionId) {
            params = params.append('temporaryUserId', substitutionId);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsTemporaryEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/TemporaryUser/AssignAsTemporaryEditor`, {}, {
                    params,
                }).pipe(mergeMap(() => this.store.dispatch(new UserSubstitutionConfirmed(substitutedId, substitutionId )).pipe(
                    map(() => true),
                ))),
                of(undefined),
            )),
        )
    }

    /**
     * Entfern Vertretung
     *
     * @param {string} substitutedId vertretenden id
     * @param {string} substitutionId vertreter id
     * @returns {Observable<boolean | undefined>} Observable
     */
    public removeTemporaryEditor(substitutedId: string, substitutionId?: string): Observable<boolean> {
        let params = new HttpParams();

        params = params.append('financingsUserId', substitutedId);

        if (!!substitutionId) {
            params = params.append('temporaryUserId', substitutionId);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsTemporaryEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/TemporaryUser/RemoveAsTemporaryEditor`, {}, {
                    params,
                }).pipe(mergeMap(() => this.store.dispatch(new UserSubstitutionRemoved(substitutedId, substitutionId )).pipe(
                    map(() => true),
                ))),
                of(false),
            )),
        )
    }


    /**
     *
     * Lädt eine Liste der Abwesender und Vertretung
     *
     * @returns {IUser[] | undefined} Liste der Vertretenden
     */
    public getAssignedAsTemporaryEditorForOwnFinancings(): Observable<ITemporaryUserViewmodel[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsTemporaryEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<ITemporaryUserViewmodel[]>(`${this.config.getEnvironment().apiUrl}/TemporaryUser/AssignedAsTemporaryEditorForOwnFinancings`),
                of(undefined),
            )));
    }
}
