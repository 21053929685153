import { EnumSettings } from '../../enum.settings';

import { DocumentType as BafDocumentType } from './baf-document-type.enum';
import { DocumentType as FinprocessDocumentType } from './finprocess-document-type.enum';
import { DocumentType as HomestoryDocumentType } from './homestory-document-type.enum';

/**
 * Dokumententyp
 */
export enum DocumentType {
    /**
     * Kaufvertrag
     */
    PurchaseAgreement = 0,

    /**
     * Wohnungsplan
     */
    FloorPlan = 1,

    /**
     * Baubewillungsbescheid
     */
    ConstructionPermit = 5,

    /**
     * Baukostenaufstellung oder Kostenvoranschläge
     */
    ConstructionCostBreakdown = 6,

    /**
     * Exposé
     */
    BrokerReport = 7,

    /**
     * Grundbuchauszug
     */
    LandRegisterExcerpt = 8,

    /**
     * Verträge bestehende Rechte/Lasten
     */
    LandRegisterExcerptContracts = 9,

    /**
     * Feuerversicherung
     */
    FireInsurance = 10,

    /**
     * Eigenmittel
     */
    OwnCapitalDocument = 16,

    /**
     * Ausweis
     */
    PhotoId = 17,

    /**
     * Scheidungsvergleich und Scheidungsbeschluß
     */
    DivorceSettlementAgreement = 18,

    /**
     * Kontoauszug
     */
    AccountStatement = 19,

    /**
     * Gehaltszettel
     */
    SalaryStatement = 20,

    /**
     * Sonstiges
     */
    Other = 34,

    /**
     * Nachweis zu zahlende Alimente
     */
    AlimonyToBePaid = 35,

    /**
     * Unterlagen abzudeckende Finanzierung
     */
    ExistingFinancingDocument = 37,

    /**
     * Nutzwertgutachten (bei Wohnungseigentum)
     */
    BenefitAnalysis = 39,

    /**
     * Nachweis erhaltene Alimente
     */
    AlimonyIncome = 40,

    /**
     * Karenzgeldbescheid
     */
    ParentalLeaveNotice = 49,

    /**
     * Datenschutzerklärung
     */
    PrivacyStatementSignature = 53,

    /**
     * Rechenbeispiel fix
     */
    SampleCalculation = 58,

    /**
     * ESIS
     */
    ESIS = 59,

    /**
     * Rechenbeispiel variabel
     */
    SampleCalculationVariable = 67,

    /**
     * Bezugsbestätigung
     */
    EmployerApproval = 69,

    /**
     * Fotos
     */
    Photos = 73,

    /**
     * Pensionsbescheid
     */
    PensionNotice = 79,
}

export namespace DocumentType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {DocumentType | BafDocumentType | HomestoryDocumentType | FinprocessDocumentType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: DocumentType | BafDocumentType | HomestoryDocumentType | FinprocessDocumentType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'DocumentType', Object.assign(DocumentType, BafDocumentType, HomestoryDocumentType, FinprocessDocumentType), plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('DocumentType', plocale);
}
