/**
 * Prüft ob das übergebene Objekt nur als Properties null oder [] hat
 * 
 * @param {Record} value zu prüfendes Objekt
 * @returns {boolean} ist Objekt leer
 */
export function isObjectEmpty<T extends object>(value: T): boolean {
    if (!!value) {
        const keys = Object.keys(value) as Array<keyof typeof value>;

        for (const key of keys) {
            const prop = value[key];
            if (prop !== null && prop !== undefined && prop !== '' && (!Array.isArray(prop) || (prop as Array<unknown>).length > 0)) {
                return false;
            }
        }
    }

    return true;
}
