import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService } from './core/base-calculation.service';

/**
 * @typedef { import('../errors').ArgumentError } ArgumentError
 */

/**
 * Berechnungsmethoden zum Haushalt
 */
export class HouseholdCalculationService extends BaseCalculationService {

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet Summe der monatlichen Haushaltsausgaben
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.operationalCosts Betriebskosten / Miete in €
     * @param {number} params.electricityCosts Energiekosten in €
     * @param {number} params.phoneCosts Telefon / Internet in €
     * @param {number} params.broadcastCosts Rundfunk / Fernsehen inkl. Pay TV / Kabel in €
     * @param {number} params.insuranceCosts Versicherungen / Vorsorgeaufwendungen in €
     * @param {number} params.livingCosts Lebenshaltungskosten in €
     * @param {number | undefined} params.carCosts KFZ-Kosten in €
     * @returns {number} Monatliche Haushaltsausgaben in €
     */
    public sumCostsPerMonth({ operationalCosts, electricityCosts, phoneCosts, broadcastCosts, insuranceCosts, livingCosts, carCosts }: {
        /**
         * Betriebskosten / Miete in €
         */
        operationalCosts: number;
        /**
         * Energiekosten in €
         */
        electricityCosts: number;
        /**
         * Telefon / Internet in €
         */
        phoneCosts: number;
        /**
         * Rundfunk / Fernsehen inkl. Pay TV / Kabel in €
         */
        broadcastCosts: number;
        /**
         * Versicherungen / Vorsorgeaufwendungen in €
         */
        insuranceCosts: number;
        /**
         * Lebenshaltungskosten in €
         */
        livingCosts: number;
        /**
         * KFZ-Kosten in €
         */
        carCosts?: number;
    }): number {
        return operationalCosts +
            electricityCosts +
            phoneCosts +
            broadcastCosts +
            (!CalculationHelperService.isNullOrNaN(carCosts) ? (carCosts as number) : 0) +
            insuranceCosts +
            livingCosts;
    }

    /**
     * Berechnet Verfügbares Einkommen
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.netIncomeTotal Monatliches Gesamteinkommen aller Kreditnehmer in €
     * @param {number} params.sumCostsPerMonth Summe der monatlichen Haushaltsausgaben in €
     * @returns {number} Verfügbares Einkommen in €
     */
    public remainingPerMonth({ netIncomeTotal, sumCostsPerMonth }: {
        netIncomeTotal: number;
        sumCostsPerMonth: number;
    }): number {
        return Math.max(0, netIncomeTotal - sumCostsPerMonth);
    }

    /**
     * Berechnet Sicherheitsreserve
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.remainingPerMonth Verfügbares Einkommen in €
     * @returns {number} Sicherheitsreserve in €
     */
    public reserve({ remainingPerMonth }: {
        /**
         * Verfügbares Einkommen in €
         */
        remainingPerMonth: number;
    }): number {
        return CalculationHelperService.round(remainingPerMonth * 0.1, 2);
    }

    /**
     * Berechnet Frei verfügbares Einkommen
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.remainingPerMonth Verfügbares Einkommen in €
     * @returns {number} Frei verfügbares Einkommen in €
     */
    public freeAccessibleIncome({ remainingPerMonth }: {
        /**
         * Verfügbares Einkommen in €
         */
        remainingPerMonth: number;
    }): number {
        return CalculationHelperService.round(remainingPerMonth * 0.9, 2);
    }
}
