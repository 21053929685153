/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DashboardLeaved, TabChanged } from 'app/modules/dashboard/data';
import { Logout } from 'auth';
import { sort } from 'fast-sort';

import { IInformationEvents } from '../../interfaces';

// Mit absolutem Import scheitern die Unit Tests, warum auch immer, deshalb relativ
import { FinancingLeaved } from './../../../../financing/data/states/financing-tab/financing-tab.actions';
import { InformationEventLoaded, InformationEventReadStatus, InformationEventsAdded } from './information-event.actions';

/**
 * Zustandsobjekt für Finanzierungsliste
 */
export interface IInformationEventStateModel {
    informationEvents: IInformationEvents[];
}

const defaultData = {
    informationEvents: [],
};

/**
 * Zustand Finanzierungsliste
 */
@State<IInformationEventStateModel>({
    name: InformationEventState.keyName,
    defaults: defaultData,
})
@Injectable()
export class InformationEventState {
    public static readonly keyName = 'informationEvent';

    /**
     * Gibt die sortierte Liste von Aktivitäten zurück
     *
     * @param {IInformationEventStateModel} state Aktueller State Kontext
     * @returns {IInformationEvents} Sortierte Liste von Aktivitäten
     */
    @Selector()
    public static allInformationEvents(state: IInformationEventStateModel): IInformationEvents[] {
        return sort(state.informationEvents.map(eventContainer => ({
            ...eventContainer,
            isRead: eventContainer.events.every(event => event.isRead),
        }))).desc(eventContainer => eventContainer.newestEventDate);
    }

    /**
     * Zustandsänderung beim Verlassen des Dashboard
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(DashboardLeaved)
    @Action(TabChanged)
    @Action(Logout)
    @Action(FinancingLeaved)
    public reset({ setState }: StateContext<IInformationEventStateModel>): void {
        setState(defaultData);
    }

    /**
     * Zustandsänderung beim Laden einer Seite aus dem Backend
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {InformationEventsAdded} action Aktion
     */
    @Action(InformationEventsAdded)
    public informationEventsAdded({ getState, setState }: StateContext<IInformationEventStateModel>, { payload }: InformationEventsAdded): void {
        const currentEvents = getState().informationEvents;

        if (Array.isArray(payload)) {
            const newEvents = currentEvents.concat(payload)
            setState({
                informationEvents: newEvents,
            });
        }

    }

    /**
     * Zustandsänderung beim Laden einer Seite aus dem Backend
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {InformationEventsAdded} action Aktion
     */
    @Action(InformationEventLoaded)
    public informationEventLoaded({ setState }: StateContext<IInformationEventStateModel>, { payload }: InformationEventLoaded): void {
        if (Array.isArray(payload)) {
            setState({
                informationEvents: payload,
            });
        }
    }

    /**
     * Zustandsänderung beim Ändern des Status von Gelesen/Ungelesen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {InformationEventReadStatus} action Aktion
     */
    @Action(InformationEventReadStatus)
    public informationEventReadStatus({ getState, setState }: StateContext<IInformationEventStateModel>, { payload }: InformationEventReadStatus): void {
        const state = getState();

        const copiedEvents = state.informationEvents.map(eventContainer => {
            if (eventContainer.financingId !== payload.request.finProcessContainerId) {
                return eventContainer;
            }



            return {
                ...eventContainer,
                events: eventContainer.events.map(event => ({
                    ...event,
                    isRead: payload.request.eventIds.includes(event.id) ? payload.read : event.isRead,
                })),
            };
        });

        setState({
            informationEvents: copiedEvents,
        });
    }
}
