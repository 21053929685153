/**
 * [MEHRFACHAUSWAHL] Ausstattungsmerkmale
 */
export enum AdditionalFacilities {
    /**
     * Wintergarten
     */
    Wintergarden = 8,

    /**
     * Photovoltaikanlage zur Stromerzeugung
     */
    PhotovoltaicSystem = 512,

    /**
     * elektrische Rollläden/Raffstores im ganzen Gebäude
     */
    ElectricBlinds = 1024,

    /**
     * Holzfenster mit Aluvorsatzschale
     */
    WoodWindows = 2048,
}
