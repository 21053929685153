import { WorkflowType } from '../../enums';
import { FormattingType, OptionalBoolenType, RealEstateClassType, ValuationClassType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const VALUATION_LTV_RELEVANT: IMidtermModel = {

    midtermName: 'valuationLtvRelevant',

    /**
     * Art der Bewertung / LTV-relevante Liegenschaft 1
     * [ltv/so]_bewart_1 (ltv_bewart_1 order so_bewart_1) bis [ltv/so]_bewart_10
     */
    bewArt: { type: WorkflowType.Enum, enumObject: ValuationClassType, enumLabel: 'ValuationClassType' },

    /**
     * Bewertungsnummer
     * [ltv/so]_bewnr_1 bis [ltv/so]_bewnr_10
     */
    bewNr: { type: WorkflowType.String },

    /**
     * PLZ erforderlich?
     * (Anmerkung: ist mit "ja" in FIRE bereits voreingestellt)
     * ltv_plz_erf_1 bis ltv_plz_erf_10
     */
    plzErf: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * PLZ mit 4 Ziffern
     * ltv_plz_1 bis ltv_plz_10
     */
    plz: { type: WorkflowType.Number },

    /**
     * Marktwert lt. Bewertung
     * [ltv/so]_marktwert_1 bis [ltv/so]_marktwert_10
     */
    marktwert: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * fremde Vorlasten inkl. eig. Vorlasten Dritter/NV gem. BA-Definition*
     */
    vorlastenBa: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * BELQ relevant?*
     */
    belqRelevant: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * fremde Vorlasten gem. KIM-V (BELQ)
     */
    fremdeVorlastenKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * eig.Vorlasten Dritter/NV gem.KIM-V (BELQ)
     */
    eigeneVorlastenKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * ∑ BA-Hypotheken Wohnimmob. gem. KIM-V (BELQ)
     */
    summeHypImmoKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * mat. Wert händisch (auch als Schalter für "Materieller Wert" bezeichnet)
     * [ltv/so]_wert_manuell_1 bis [ltv/so]_wert_manuell_10
     * --> wenn falls, dann ist SumMatValueOngoing egal, weil es bei Fire berechnet wird
     * --> wenn true, dann muss SumMatValueOngoing angegeben sein, weil es bei Fire dann nicht berechnet wird
     */
    wertManuell: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    sumManuell: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Immoblienart
     * [ltv/so]_immoart_1 bis [ltv/so]_immoart_10
     */
    immoart: { type: WorkflowType.Enum, enumObject: RealEstateClassType, enumLabel: 'RealEstateClassType' },
}
