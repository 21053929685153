/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Logout } from 'auth';

import { SystemConfigurationType } from '../../enums';
import { IExternalSystemsAvailability } from '../../interfaces';
import { SystemConfiguration } from '../../interfaces/system-configuration.interface';

import { ExternalSystemsLoaded, SystemsConfigurationLoaded as SystemConfigurationLoaded, UpdateSystemConfigurationValue } from './system-configuration.actions';

/**
 * Zustandsobjekt für Systemkonfiguration
 */
export interface ISystemConfigurationStateModel {
    externalSystemsAvailability: IExternalSystemsAvailability,
    systemConfiguration?: SystemConfiguration,
}

const defaultData: ISystemConfigurationStateModel = {
    externalSystemsAvailability: {
        assignAdvisorIsAvailable: false,
        coltIsAvailable: false,
        customerProfileIsAvailable: false,
        decisionIsAvailable: false,
        pricingIsAvailable: false,
        riskManagementIsAvailable: false,
        scoringIsAvailable: false,
    },
    systemConfiguration: undefined,
};

/**
 * Zustand für das Dashboard
 */
@State<ISystemConfigurationStateModel>({
    name: SystemConfigurationState.keyName,
    defaults: defaultData,
})
@Injectable()
export class SystemConfigurationState {
    public static readonly keyName = 'systemConfiguration';


    /**
     * Lädt sys confugration value
     *
     * @param {ISystemConfigurationStateModel} state state
     * @returns {(systemConfigurationType: SystemConfigurationType) => number | undefined} SystemConfigurationType Value
     */
    @Selector()
    public static getSystemConfiguration(state: ISystemConfigurationStateModel): (systemConfigurationType: SystemConfigurationType) => { value: number | string; freeText?: string } | undefined {
        return (systemConfigurationType: SystemConfigurationType) => state?.systemConfiguration?.[systemConfigurationType];
    }

    /**
     * Zustandsänderung beim Zurücksetzen der Systemkonfiguration
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public reset({ setState }: StateContext<ISystemConfigurationStateModel>): void {
        setState(defaultData);
    }

    /**
     * Zustandsänderung Laden der Verfügbarkeiten der externen Systeme
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {ExternalSystemsLoaded} action Aktion
     */
    @Action(ExternalSystemsLoaded)
    public externalSystemsLoaded({ patchState }: StateContext<ISystemConfigurationStateModel>, { payload }: ExternalSystemsLoaded): void {
        patchState({
            externalSystemsAvailability: payload,
        });
    }

    /**
     * Zustandsänderung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SystemConfigurationLoaded} action SystemsConfigurationLoaded
     */
    @Action(SystemConfigurationLoaded)
    public systemConfigurationLoaded({ patchState }: StateContext<ISystemConfigurationStateModel>, { payload }: SystemConfigurationLoaded): void {
        patchState({
            systemConfiguration: payload,
        });
    }

    /**
     * Zustandsänderung wenn ein Feld geändert wird
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UpdateSystemConfigurationValue} action SystemsConfigurationLoaded
     */
    @Action(UpdateSystemConfigurationValue)
    public updateSystemConfigurationValue({ setState }: StateContext<ISystemConfigurationStateModel>, { payload }: UpdateSystemConfigurationValue): void {
        setState(
            patch({
                systemConfiguration: patch({
                    [payload.type]: {
                        value: payload.value,
                        freeText: payload.freeText,
                    },
                }),
            }),
        );
    }
}
