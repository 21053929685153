import { WorkflowType } from '../../enums';
import { FormattingType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const AMOUNTS_LOAN_BANK_AUSTRIA_REMAIN: IMidtermModel = {

    midtermName: 'amountsLoanBankAustriaRemain',

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für B&W-Mortgage
     * oel_mortgage_1 oder oel_mortgage_2
     */
    mortgage: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Kleingarten Pachtgrund
     * oel_non_mortgage_kg_1 oder oel_non_mortgage_kg_2
     */
    nonMortgageKg: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite fümiete: r, Renovierung
     * oel_non_mortgage_miete_1 oder oel_non_mortgage_miete_2
     */
    nonMortgageMiete: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Genossenschaft
     * oel_non_mortgage_gen_1 oder oel_non_mortgage_gen_2
     */
    nonMortgageGen: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Ergänzungsdarlehen
     * oel_spill_over_1 oder oel_spill_over_2
     */
    spillOver: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Nachfinanzierungsergänzungsdarlehen
     * oel_nf_1 oder oel_nf_2
     */
    nf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Garantie
     * oel_garantie_1 oder oel_garantie_2
     */
    garantie: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Zwischenfinanzierung mit femder Anschlussfinanzierung
     * oel_zwischen_foerderung_1 oder oel_zwischen_foerderung_2
     */
    zwischenFoerderung: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Zwischenfinanzierung ohne fremder Anschlussfinanzierung
     * oel_zwischen_eigenmittel_1 oder oel_zwischen_eigenmittel_2
     */
    zwischenEigenmittel: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Zwischenfinanzierung für einen Liegenschaftenverkauf nach KIM-V Novelle des Antragstellers, d.h. entweder unter NDG des Einzelkunden oder der NDG der JH
     * oel_zwischen_lsverkauf_1 oder oel_zwischen_lsverkauf_2
     */
    zwischenLsVerkauf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Konsumfinanzierung
     * oel_konsum_1 oder oel_konsum_2
     */
    konsum: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Überschreitung/Kreditkarten
     * oel_ueberschreitung_1 oder oel_ueberschreitung_2
     */
    ueberschreitung: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite für Leasing
     * oel_leasing_1 oder oel_leasing_2
     */
    leasing: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-FW-Kredite für B&W-Mortgage
     * oel_mortgage_fw_1 oder oel_mortgage_fw_2
     */
    mortgageFw: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-FW-Kredite für Non-Mortgage
     * oel_non_mortgage_gen_fw_1 oder oel_non_mortgage_gen_fw_2
     */
    nonMortgageGenFw: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-FW-Kredite für Zwischenfinanzierung mit fremder Anschlussfinanzierung
     * oel_zwischen_foerderung_fw_1 oder oel_zwischen_foerderung_fw_2
     */
    zwischenFoerderungFw: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden BA-FW-Kredite für Zwischenfinanzierung ohne fremder Anschlussfinanzierung
     * oel_zwischen_eigenmittel_fw_1 oder oel_zwischen_eigenmittel_fw_2
     */
    zwischenEigenmittelFw: { type: WorkflowType.Number, formattingType: FormattingType.Currency },
}
