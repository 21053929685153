export enum CollateralBaseType {
    /**
     * Immobilie
     */
    Mortgage = 0,
    
    /**
     * Versicherung
     */
    Insurance = 1,

    /**
     * Kontoguthaben
     */
    Account = 2,

    /**
     * Wertpapiere
     */
    Bonds = 3
}
