import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropAreaViewModel } from 'app/modules/smartdoc/data';

/**
 * Komponente für die Dokumentenablage
 */
@Component({
    selector: 'finprocess-smartdoc-droparea',
    templateUrl: './smartdoc-droparea.component.html',
    styleUrls: ['./smartdoc-droparea.component.scss'],
})
export class SmartdocDropareaComponent {

    @Input()
    public dropArea?: IDropAreaViewModel;

    @Output()
    public setAsFavorite = new EventEmitter<IDropAreaViewModel>();

    @Output()
    public editDropArea = new EventEmitter<IDropAreaViewModel>();

    @Output()
    public deleteDropArea = new EventEmitter<string>();


    /**
     * DropArea alas Favorit markieren/unmarkieren
     */
    public onSetFavorite(): void {
        this.setAsFavorite.emit(this.dropArea)
    }

    /**
     * DropArea edit
     */
    public onEditDropArea(): void {
        this.editDropArea.emit(this.dropArea)
    }

    /**
     * DropArea löshen
     */
    public onDeleteDropArea(): void {
        this.deleteDropArea.emit(this.dropArea?.id)
    }
}
