import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { LiabilityType } from '@ntag-ef/finprocess-enums';
import { UserState } from 'app/modules/auth/data';
import { FinProcessEntity } from 'app/modules/note/data/enums';
import { NoteService } from 'app/modules/note/data/services/note.service';
import { Subject, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs';
import { UUID } from 'shared/util';

import { IDebitorSumLiabilities, IFinancingSumLiabilities, IHouseholdSumLiabilities, ILiabilityForSum, LiabilitiesService } from '../../../../data';


/**
 * Komponente zur Anzeige der Summen der Verbindlichkeiten pro Kreditnehmer
 */
@Component({
    selector: 'finprocess-liabilities-overview',
    templateUrl: './liabilities-overview.component.html',
    styleUrls: ['./liabilities-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilitiesOverviewComponent implements OnInit, OnDestroy {

    /**
     * Summendaten
     */
    public financingSumLiabilities: IFinancingSumLiabilities;

    /**
     * Ladezustand
     */
    public loading = false;

    /**
     * selected household
     */
    public selectedHousehold: IHouseholdSumLiabilities | null = null; 

    /**
     * selected household debitor
     */
    public selectedDebitor: IDebitorSumLiabilities | null = null; 

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinprocessEntity = FinProcessEntity;

    /**
     * Benutzer-ID für Notizen
     */
    public userID?: string;

    /**
     * Standard Konstruktor
     * 
     * @param {LiabilitiesService} liabilitiesService LiabilitiesService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetector-Injektor
     * @param {Store} store Store-Injektor
     * @param {NoteService} notesService NoteService-Injektor
     */
    public constructor(
        private liabilitiesService: LiabilitiesService,
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        private store: Store,
        private notesService: NoteService,
    ) {
        this.financingSumLiabilities = {
            households: [],
            sum: 0,
            sumFictionalRates: 0,
            sumRates: 0,
        };
    }

    /**
     * Ruft die Liste der Verbindlichkeiten ab
     */
    public ngOnInit(): void {
        this.activatedRoute.parent?.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(() => {
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => this.liabilitiesService.getLiabilitieSumOverview(id).pipe(
                tap(financingSumLiabilities => {
                    if (financingSumLiabilities) {
                        this.financingSumLiabilities = financingSumLiabilities;
                        this.financingSumLiabilities.households.sort((a, b) => a.position - b.position);
                        this.selectedHousehold = this.financingSumLiabilities.households[0];
                        this.selectedDebitor = this.selectedHousehold.debitors[0];
                    }
                    this.loading = false;
                    this.cRef.markForCheck();
                    this.cRef.detectChanges();
                }),
                mergeMap(() => this.notesService.getNotesByTypes(id, [FinProcessEntity.Liability])),
            )),
        ).subscribe(() => {
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        this.userID = this.store.selectSnapshot(UserState.user)?.id;
    }

    /**
     * Beendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * on household change -> get selected household and corresponding data
     *
     * @param {number} index chip change index
     */
    public onHouseholdChange(index: number) {
        this.selectedHousehold = this.financingSumLiabilities.households[index];
        this.selectedDebitor = this.selectedHousehold.debitors[0];
    }

    /**
     * on debitor change -> get selected debitor and corresponding data
     *
     * @param {number} index chip change index
     */
    public onDebitorChange(index: number) {
        this.selectedDebitor = this.selectedHousehold?.debitors[index] ?? null;
    }

    /**
     * Überprüft ob es mindesteins eine Verbindlichkeit gibt, die keine Rate hat
     * 
     * @returns {boolean} true, wenn mindesteins eine Verbindlichkeit keine Rate hat, sonst false
     */
    public checkRates(): boolean {
        return this.financingSumLiabilities.households.some(household => household.debitors.some(debitor => debitor.liabilities.some(liability => !liability.monthlyRate)));
    }

    /**
     * Überprüft ob bei der Verbindlichkeit die Rahmenhöhe angezeigt werden sollte
     * 
     * @param {ILiabilityForSum} liability Verbindlichkeit
     * @returns {boolean} true, wenn die Rahmenhöhe angezeigt werden soll, sonst false
     */
    public hasCreditLimit(liability: ILiabilityForSum): boolean {
        return liability.type !== undefined && [
            LiabilityType.CreditCard,
            LiabilityType.Overdraft,
            LiabilityType.ConstructionFollowUpFinancing,
            LiabilityType.ConstructionFollowUpFinancingBuildingLoan,
            LiabilityType.ConstructionInterimFinancing,
            LiabilityType.ConstructionPrefinancingInvestmentFlatHigh,
            LiabilityType.ConstructionPrefinancingInvestmentFlatLow,
        ].includes(liability.type);
    }

}
