import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const PHONES: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'phones',

    ndg: { type: WorkflowType.String },
}
