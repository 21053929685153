<div class="smartdoc-processing" *ngIf="openedId$ | async as openedId">
    <ng-container *ngIf="smartdoc$ | async as state">
        <div class= "wrapper">

        <div class="header">
            <span>{{'smartdoc.processing.assembleDocuments' | translate}}</span>
        </div>
        <div class="folder-row">
            <div class="folder-header">
                <span>{{'smartdoc.processing.startFolder' | translate}}</span>
            </div>
            <div class="folders" *ngIf="dropFolders$ | async as dropFolders">
                <div *ngIf="showBackButton">
                    <div class="folder" (click)="navigateBack()">
                        <span class="material-icons">
                            arrow_back
                        </span>
                        <span class="name">
                            ZURÜCK
                        </span>
                    </div>
                </div>
                <ng-container *ngFor="let dropFolder of dropFolders">
                    <div class="folder" (click)="openFolder(dropFolder.id)">
                        <span class="material-icons">
                            folder
                        </span>
                        <span class="name">
                            {{dropFolder.name}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="dropFolders$ | async as dropFolders">
            <ng-container *ngIf="smartdocThumbnails$ | async as smartdocThumbnails">
                <div class="quicky-row" *ngIf="isRootLevel()">
                    <div class="quicky-header"><span>{{'smartdoc.processing.quickAccess' | translate}}</span></div>
                    <div class="quicky-container">
                        <div class="quickies" >
                            <div class="quicky" *ngFor="let dropFolder of dropFolders">
                                <div class="quicky-name">
                                    <span>{{dropFolder.name}}</span>
                                </div>
                                <div class="row-container">
                                    <div
                                        class="quicky-folders"
                                        *ngFor="let dropArea of dropFolder.dropAreas; let position = index">
                                        <div
                                            class="quicky-folder"
                                            (dragover)="dragOver($event)"
                                            (drop)="drop(openedId, position, dropArea)"
                                            *ngIf="dropArea.hasQuickAccess"
                                        >
                                            <div class="overview">
                                                <span>{{dropArea.name}}</span>
                                                <span class="count">
                                                    {{getThumbnailsByDropArea(state.smartDocThumbnails, dropArea).length}}
                                                </span>
                                            </div>
                                            <div class="content">
                                                    <img
                                                        draggable="true"
                                                        (dragend)="dragEnd()"
                                                        (dragstart)="dragStart(thumbnail)"
                                                        class="file-element"
                                                        [src]="'data:image/jpeg;base64,' + thumbnail.thumbnail"
                                                        *ngFor="let thumbnail of getThumbnailsByDropArea(state.smartDocThumbnails, dropArea)"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="trashcan">
                            <div class="quickies">
                                <div class="quicky">
                                    <div class="row-container">
                                        <div class="quicky-folders">
                                            <div
                                                class="quicky-folder"
                                                (dragover)="dragOver($event)"
                                                (drop)="drop(openedId, 0, undefined)"
                                            >
                                                <div class="overview">
                                                    <span class="material-icons">
                                                        delete
                                                    </span>
                                                    <span>{{'smartdoc.processing.trashcan' | translate}}</span>
                                                    <span class="count">
                                                        {{getThrashThumbs(state.smartDocThumbnails).length}}
                                                    </span>
                                                </div>
                                                <div class="content">
                                                    <img
                                                        draggable="true"
                                                        (dragend)="dragEnd()"
                                                        (dragstart)="dragStart(thumbnail)"
                                                        class="file-element"
                                                        [src]="'data:image/jpeg;base64,' + thumbnail.thumbnail"
                                                        *ngFor="let thumbnail of getThrashThumbs(state.smartDocThumbnails)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="quicky-row" *ngIf="!isRootLevel()">
                    <div class="quicky-header"><span>{{'smartdoc.processing.documentFiling' | translate}}</span></div>
                    <div class="quicky-container">
                        <div class="quickies" >
                            <div class="quicky">
                                <div class="row-container">
                                    <div
                                    class="quicky-folders"
                                    *ngFor="let dropArea of currentDropFolder.dropAreas; let position = index">
                                    <div
                                        class="quicky-folder"
                                        (dragover)="dragOver($event)"
                                        (drop)="drop(openedId, position, dropArea)"
                                    >
                                        <div class="overview">
                                            <span>{{dropArea.name}}</span>
                                            <span class="count">
                                                {{getThumbnailsByDropArea(state.smartDocThumbnails, dropArea).length}}
                                            </span>
                                        </div>
                                        <div class="content">
                                                <img
                                                    draggable="true"
                                                    (dragend)="dragEnd()"
                                                    (dragstart)="dragStart(thumbnail)"
                                                    class="file-element"
                                                    [src]="'data:image/jpeg;base64,' + thumbnail.thumbnail"
                                                    *ngFor="let thumbnail of getThumbnailsByDropArea(state.smartDocThumbnails, dropArea)"
                                                />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="trashcan">
                            <div class="quickies">
                                <div class="quicky">
                                    <div class="row-container">
                                        <div class="quicky-folders">
                                            <div
                                                class="quicky-folder"
                                                (dragover)="dragOver($event)"
                                                (drop)="drop(openedId, 0, undefined)"
                                            >
                                                <div class="overview">
                                                    <span class="material-icons">
                                                        delete
                                                    </span>
                                                    <span>{{'smartdoc.processing.trashcan' | translate}}</span>
                                                    <span class="count">
                                                        {{getThrashThumbs(state.smartDocThumbnails).length}}
                                                    </span>
                                                </div>
                                                <div class="content">
                                                    <img
                                                        draggable="true"
                                                        (dragend)="dragEnd()"
                                                        (dragstart)="dragStart(thumbnail)"
                                                        class="file-element"
                                                        [src]="'data:image/jpeg;base64,' + thumbnail.thumbnail"
                                                        *ngFor="let thumbnail of getThrashThumbs(state.smartDocThumbnails)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        </div>
        <ng-container *ngIf="dropFolders$ | async as dropFolders">
            <ng-container *ngIf="smartdocThumbnails$ | async as smartdocThumbnails">
                <div class="overview wrapper-bottom">
                    <div class="thumbnails">
                        <div
                            class="added-documents"
                            *ngFor="let thumbnail of getSelectionSmartDocThumbnails(state.smartDocThumbnails)"
                            (click)="onClickThumbnail(thumbnail)"
                            >
                            <img
                                draggable="true"
                                (dragend)="dragEnd()"
                                (dragstart)="dragStart(thumbnail)"
                                class="file-element"
                                [src]="'data:image/jpeg;base64,' + thumbnail.thumbnail"
                            />
                        </div>
                    </div>
                    <div class="user-actions">
                        <span>{{getSelectionSmartDocThumbnails(smartdocThumbnails).length}} OFFEN</span>
                        <button
                            type="button"
                            mat-raised-button
                            color="primary"
                            [disabled]="assignedAThumbnail === false"
                            (click)="resetSortResults(state)"
                        >
                            <span class="material-icons-outlined">
                                delete
                            </span>
                            {{'smartdoc.processing.reset' | translate}}
                        </button>
                        <button
                            type="button"
                            mat-raised-button
                            color="primary"
                            [disabled]="getSelectionSmartDocThumbnails(smartdocThumbnails).length > 0 ||
                            splittingNotFinished"
                            (click)="startProcessFiles()"
                        >
                            <span class="material-icons-outlined">
                                text_rotate_vertical
                            </span>
                            {{'smartdoc.processing.assemble' | translate}}
                        </button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
