export enum SystemConfigurationType {

    /**
     * Datum des letzten Syncs mit dem IdentityServer (nicht mehr benötigt)
     */
    IdentityServerLastSync = 0,

    /**
     * Datum des letzten Buchhaltungsberichts
     */
    LastAccountigReportMonth = 1,

    /**
     * Datum des letzten Aktivitätenberichts
     */
    LastActivityReportWeek = 2,

    /**
     * Maximal erlaubte Anzahl an Rechenbeispielen
     */
    SampleCalculationMaxFileCount = 4,

    /**
     * Authorization Key
     */
    HiddenAuthorizationKey = 5,

    /**
     * Text für die Nutzervereinbarung
     */
    UserAgreementText = 6,

    /**
     * Maximal erlaubte Kreditlaufzeit in Monaten
     */
    MaximumDuration = 50,

    /**
     * Verlängerte Laufzeit ab in Monaten
     */
    ExtendedDurationGreaterThan = 51,

    /**
     * Mindestalter
     */
    MinimumAge = 52,

    /**
     * Minimaler Finanzierungsbedarf
     */
    MinimumGrossFinancingRequirement = 54,

    /**
     * Minimaler Eigenanteil in Prozent
     */
    MinimumOwnCapitalPercent = 55,

    /**
     * Maximaler Wert des Ergänzungsdarlehen
     */
    MaximumComfortCreditPlus = 56,

    /**
     * Maximales Alter für Produktsplitting
     */
    SplitAge = 57,

    /**
     * Maximale Laufzeit für Produktsplitting
     */
    MaximumDurationSplitAge = 58,
   
    /**
     * Maximale Sollzinssatz für den Rechner
     */
    AmountLimit = 100,

    /**
     * Höchstbetrag für den Rechner
     */
    InterestRate = 101,

    /**
     * Referenzzinssatz 
     */
    DefaultReferenceInterestRate = 102,

    /**
     * Aufschlag 
     */ 
    DefaultAddition = 103,

    /**
     * Aufschlag für Vergleichsrechnung
     */
    AdditionComparativeCalculation = 104,

    /**
     * Brutto - Netto Rechner URL
     */
    BruttoNettoCalculatorUrl = 105,


    /**
     * Validierungsnachricht wenn das Mindestalter unterschritten wird
     */
    ValidationMessageDebitorAgeMin = 800,

    /**
     * Validierungsnachricht wenn der minimale Finanzierungsbedarf unterschritten wird
     */
    ValidationMessageGrossFinancingRequirementMin = 801,

    /**
     * Validierungsnachricht wennn die maximale Laufzeit überschritten wird
     */
    ValidationMessageMaximumDuration = 802,

    /**
     * Validierungsnachricht wenn das Mindesteinkommen anhand der unterhaltsberechtigten Personen unterschritten wird
     */
    ValidationMessageMinimumIncomeDependentPerson = 803,

    /**
     * Validierungsnachricht wenn der minimale Anteil der Eigenmittel unterschritten wird
     */
    ValidationMessageMinimumOwnCapitalPercent = 804,

    /**
     * Validierungsnachricht wenn die Kreditrate zu hoch ist
     */
    ValidationMessageAcceptableCreditRate = 805,

    /**
     * Validierungsnachricht wenn der maximale Wert des Ergänzungsdarlehen überschritten wird
     */
    ValidationMessageMaximumComfortCreditPlus = 806,

    /**
     * Basisbetrag für unterhaltsberechtigten Kinder (pro Kind)
     */
    BaseChildrenAmount = 980,

    /**
     * Anrechnungsbetrag für Kinder für die Alimente/Unterhalt bezahlt wird
     */
    BaseChildrenObligationAmount = 981,

    /**
     * Anrechnungsbetrag für Kinder für die Alimente/Unterhalt erhalten wird
     */
    BaseChildrenRecipientAmount = 982,

    /**
     * Verbindungstest
     */
    ConnectivityTest = 999
}
