import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_ACCOUNT_IDENTIFIER } from './customer-account-identifier';

export const CUSTOMER_ADDRESS_RELATIONSHIP: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerAddressRelationship',

    accountIdentifier: { type: WorkflowType.Object, child: CUSTOMER_ACCOUNT_IDENTIFIER },

    addressType: { type: WorkflowType.String },
}
