import { UUID } from 'app/modules/shared';

import { IDocument, ILiabilityData } from '../../interfaces';

/**
 * Aktion beim Laden der Liability
 */
export class LiabilityLoaded {
    public static readonly type = 'liability.loaded';

    /**
     * Konstruktor 
     *
     * @param {ILiabilityData} payload Daten der Assets
     */
    public constructor(public payload: ILiabilityData) { }
}

/**
 * Aktion beim JointHeading File Update
 */
export class JointHeadingFileUpdate {
    public static readonly type = 'liability.jointHeadingFileUpdate';

    /**
     * Konstruktor
     *
     * @param {IDocument} payload IJointHeadingFile
     * @param {string} jointHeadingID jointHeadingID
     */
    public constructor(public payload: IDocument, public jointHeadingID: string) { }
}

/**
 * Aktion beim JointHeading File Delete
 */
export class JointHeadingFileDelete {
    public static readonly type = 'liability.jointHeadingFileDelete';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {UUID} payload.fileId ID der Datei
     * @param {UUID} payload.jointHeadingID ID des JointHeadings
     */
    public constructor(public payload: { fileId: UUID, jointHeadingID: UUID }) { }
}

/**
 * Aktion beim Aktualisieren der fiktiven Rate einer Verbindlichkeit
 */
export class FictionalRateUpdated {
    public static readonly type = 'liability.fictionalRateUpdated';

    /**
     * Konstruktor
     * 
     * @param {object} payload Payload
     * @param {UUID} payload.liabilityId ID der Verbindlichkeit
     * @param {number} payload.fictionalRate Fiktive Rate
     */
    public constructor(public payload: { liabilityId: UUID, fictionalRate: number }) { }
}
