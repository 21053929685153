import { Component, Input } from '@angular/core';
import { DocumentType } from '@ntag-ef/finprocess-enums/core';
import { DocumentService, IDocumentView, IFile } from 'app/modules/financing/data';
import { HelperService } from 'app/modules/shared';

/**
 * Komponente zur Anzeige einer Liste an Dokumenten
 */
@Component({
    selector: 'finprocess-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DocumentType = DocumentType;

    @Input()
    public documents: IDocumentView[] = [];

    @Input()
    public layout = 'row wrap';

    /**
     * Standard Konstruktor
     *
     * @param {DocumentService} documentService DocumentService-Injektor
     */
    public constructor(
        private documentService: DocumentService,
    ) {}

    /**
     * Lädt ein Dokument
     *
     * @param {IFile} file Die zu ladende Datei
     */
    public onDocumentClick(file: IFile): void {
        this.documentService.loadFile(file.id)
            .subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);
                    
                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }
                },
            });
    }
}
