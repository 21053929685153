/**
 * Aktion beim Wechseln des Tabs in der Finanzierung
 */
export class TabChanged {
    public static readonly type = 'financing.tabChanged';

    /**
     * Konstruktor
     *
     * @param {string} payload Ausgewählter Tab
     */
    public constructor(public payload: number) { }
}

/**
 * Aktion beim Verlassen der Finanzierung
 */
export class FinancingLeaved {
    public static readonly type = 'financing.leaved';
}

/**
 * Bearbeitungsmodus geändert
 */
export class EditModeChanged {
    public static readonly type = 'financing.editModeChanged';

    /**
     * Konstruktor
     *
     * @param {boolean} payload Bearbeitungsmodus
     */
    public constructor(public payload: boolean) { }
}

