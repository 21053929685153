import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { CreditType, FictionalRateSourceType, InterestMethod } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { HelperService } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { NgxCurrencyConfig } from 'ngx-currency';
import { Observable, mergeMap, of, take } from 'rxjs';

import { IFinancingConfiguration } from './../../../../data/interfaces';
import { FinancingConfigurationService } from './../../../../data/services/financing-configuration/financing-configuration.service';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';

interface ISelectItem {
    value: number;
    label: string;
}

/**
 * Komponente zur Administration von fiktiven Zinssaetzen
 */
@Component({
    selector: 'finprocess-financing-configuration',
    templateUrl: './financing-configuration.component.html',
    styleUrls: ['./financing-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancingConfigurationComponent implements OnInit {

    @Select((it: IAdministrationStateParentDefinition) => it.financingConfiguration.configuration)
    public financingConfiguration$!: Observable<IFinancingConfiguration | undefined>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Formular
     */
    public form: FormGroup;

    /**
     * Formular gesperrt oder nicht
     */
    public locked = true;

    public interestMethods: ISelectItem[] = [];

    public creditTypes: ISelectItem[] = [];

    /**
     * mainCreditFictionalRates für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get mainCreditFictionalRates(): FormGroup[] {
        return (this.form.get('mainCreditFictionalRates') as FormArray)?.controls as FormGroup[];
    }

    /**
     * fictionalRateTypeAssignments für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get fictionalRateTypeAssignments(): FormGroup[] {
        return (this.form.get('fictionalRateTypeAssignments') as FormArray)?.controls as FormGroup[];
    }

    /**
     * splittingRules für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get splittingRules(): FormGroup[] {
        return (this.form.get('splittingRules') as FormArray)?.controls as FormGroup[];
    }

    /**
     * Formatierung für Faktoren
     */
    public factorOptions: NgxCurrencyConfig;

    /**
     * Formatierung für €
     */
    public currencyOptions: NgxCurrencyConfig;

    /**
     * Formatierung für LTV
     */
    public ltvOptions: NgxCurrencyConfig;

    /**
     *
     * Standard Konstruktor
     *
     * @param {FinancingConfigurationService} financingConfigService FinancingConfigurationService-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {string} locale Lokalisierung
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef
     */
    public constructor(
        private financingConfigService: FinancingConfigurationService,
        private fb: FormBuilder,
        @Inject(LOCALE_ID) locale: string,
        private notification: NotificationService,
        private translate: TranslateService,
        private waiterService: WaiterService,
        private cRef: ChangeDetectorRef,
    ) {
        this.form = this.fb.group({
            comfortCreditPlusFictionalRate: [null, Validators.required],
            liabilityFictionalRate: [null, Validators.required],
            newLiabilityFictionalRate: [null, Validators.required],
            constructionCreditFictionalRate: [null, Validators.required],
            landRegisterRequest: [null, Validators.required],
            landRegisterExtract: [null, Validators.required],
            bankAccountFee: [null, Validators.required],
            requestedDebitRateDefault: [null, Validators.required],
            mainCreditFictionalRates: this.fb.array([]),
            fictionalRateTypeAssignments: this.fb.array([]),
            splittingRules: this.fb.array([]),
        });

        this.disableForm();

        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        const fixedLocale = locale === 'de-AT' ? 'de' : locale;

        this.factorOptions = HelperService.getStandardPercentageMask(fixedLocale);
        this.currencyOptions = HelperService.getStandardCurrencyMask(fixedLocale);
        this.ltvOptions = HelperService.getInputMask(fixedLocale, {
            precision: HelperService.getMaxDecimalPlacesByFormat('1.0-2'),
        });
    }

    /**
     * Angular Lifecycle Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.financingConfigService.get().subscribe();
        this.financingConfiguration$.subscribe(financingConfiguration => {
            if (financingConfiguration === undefined) {
                return;
            }

            this.form.get('comfortCreditPlusFictionalRate')?.patchValue(financingConfiguration.comfortCreditPlusFictionalRate);
            this.form.get('liabilityFictionalRate')?.patchValue(financingConfiguration.liabilityFictionalRate);
            this.form.get('newLiabilityFictionalRate')?.patchValue(financingConfiguration.newLiabilityFictionalRate);
            this.form.get('constructionCreditFictionalRate')?.patchValue(financingConfiguration.constructionCreditFictionalRate);
            this.form.get('landRegisterRequest')?.patchValue(financingConfiguration.landRegisterRequest);
            this.form.get('landRegisterExtract')?.patchValue(financingConfiguration.landRegisterExtract);
            this.form.get('bankAccountFee')?.patchValue(financingConfiguration.bankAccountFee);
            this.form.get('requestedDebitRateDefault')?.patchValue(financingConfiguration.requestedDebitRateDefault);

            const mainCreditFictionalRates = this.form.get('mainCreditFictionalRates') as FormArray;
            const fictionalRateTypeAssignments = this.form.get('fictionalRateTypeAssignments') as FormArray;
            const splittingRules = this.form.get('splittingRules') as FormArray;
            mainCreditFictionalRates.clear();
            fictionalRateTypeAssignments.clear();
            splittingRules.clear();

            // Bereich KomfortKredit
            for (const mainCreditFictionalRate of financingConfiguration.mainCreditFictionalRates) {
                const formGroup = this.fb.group({
                    interestMethod: [mainCreditFictionalRate.interestMethod],
                    fictionalRate: [mainCreditFictionalRate.fictionalRate, Validators.required],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                mainCreditFictionalRates.push(formGroup);
            }

            // Bereich Verwendung des angefragten Zinssatzes als fiktiver Zinssatz
            for (const fictionalRateTypeAssignment of financingConfiguration.fictionalRateTypeAssignments) {
                const formGroup = this.fb.group({
                    interestMethod: [fictionalRateTypeAssignment.interestMethod, Validators.required],
                    fictionalRateSourceType: [fictionalRateTypeAssignment.fictionalRateSourceType],
                    assumedDuration: [fictionalRateTypeAssignment.assumedDuration, Validators.required],
                    creditType: [fictionalRateTypeAssignment.creditType, Validators.required],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                fictionalRateTypeAssignments.push(formGroup);
            }

            // Bereich Produktsplitting
            for (const splittingRule of sort(financingConfiguration.splittingRules).asc([
                rule => rule.debitorCount,
                rule => rule.maxTotalMonthlyIncomeJh,
                rule => rule.ltv,
                rule => rule.morixRating,
            ])) {
                const formGroup = this.fb.group({
                    debitorCount: [splittingRule.debitorCount],
                    maxTotalMonthlyIncomeJh: [splittingRule.maxTotalMonthlyIncomeJh],
                    ltv: [splittingRule.ltv],
                    morixRating: [splittingRule.morixRating],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                splittingRules.push(formGroup);
            }

            this.cRef.markForCheck();
        });

        this.interestMethods = (HelperService.getEnumArray(InterestMethod, true) as number[]).map(value => ({
            value,
            label: InterestMethod.translate(value) ?? '',
        }));

        this.creditTypes = (HelperService.getEnumArray(CreditType, true) as number[])
            .filter(creditType => creditType !== CreditType.Inital)
            .map(value => ({
                value,
                label: CreditType.translate(value) ?? '',
            }));
    }

    /**
     * Fügt eine neue fiktionale Rate hinzu
     */
    public addFictionalRateTypeAssignment(): void {
        const fictionalRateTypeAssignments = this.form.get('fictionalRateTypeAssignments') as FormArray;

        fictionalRateTypeAssignments.push(this.fb.group({
            interestMethod: [null, Validators.required],
            fictionalRateSourceType: [FictionalRateSourceType.RequestedDebitRate],
            assumedDuration: [null, Validators.required],
            creditType: [null, Validators.required],
        }));

        this.cRef.markForCheck();
    }

    /**
     * Entfernt eine fiktionale Rate
     *
     * @param {number} index Index
     */
    public removeFictionalRateTypeAssignment(index: number): void {
        this.confirmDeletion().pipe(take(1)).subscribe(result => {
            if (result === 'submit') {
                const fictionalRateTypeAssignments = this.form.get('fictionalRateTypeAssignments') as FormArray;

                fictionalRateTypeAssignments.removeAt(index);

                this.form.markAsDirty();

                this.cRef.markForCheck();
            }
        });

    }

    /**
     * Fügt eine neue fiktionale Rate hinzu
     */
    public addSplittingRule(): void {
        const splittingRules = this.form.get('splittingRules') as FormArray;

        splittingRules.push(this.fb.group({
            debitorCount: [],
            maxTotalMonthlyIncomeJh: [],
            ltv: [],
            morixRating: [],
        }));

        this.cRef.markForCheck();
    }

    /**
     * Entfernt eine fiktionale Rate
     *
     * @param {number} index Index
     */
    public removeSplittingRule(index: number): void {
        this.confirmDeletion().pipe(take(1)).subscribe(result => {
            if (result === 'submit') {
                const splittingRules = this.form.get('splittingRules') as FormArray;

                splittingRules.removeAt(index);

                this.form.markAsDirty();

                this.cRef.markForCheck();
            }
        });
    }

    /**
     * Speichert die Konfiguration
     */
    public save(): void {
        const data = this.form.getRawValue() as IFinancingConfiguration;

        this.notification.confirmYesNo(
            this.translate.instant('button.confirm'),
            this.translate.instant('administration.features.financingConfiguration.confirmSave'),
        ).pipe(
            mergeMap(result => {
                if (result !== 'submit') {
                    return of(undefined);
                }

                return this.waiterService.show().pipe(
                    mergeMap(() => this.financingConfigService.update(data)),
                );
            }),
        ).subscribe(
            {
                next: result => {
                    if (result !== undefined) {
                        this.waiterService.hide();
                        this.disableForm();
                        this.locked = true;
                        this.form.markAsPristine();
                    }
                },
                error: () => {
                    this.waiterService.hide();
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('administration.features.financingConfiguration.error'),
                    );
                },
            },
        );
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.form.enable();
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.form.disable();
    }

    /**
     * Gibt einen Bestätigungsdialog zurück
     *
     * @returns {string | undefined} Ergebnis des Dialogs
     */
    private confirmDeletion(): Observable<string | undefined> {
        return this.notification.confirmYesNo(
            this.translate.instant('general.warning'),
            this.translate.instant('administration.features.financingConfiguration.confirmDeletion'),
        );
    }

}
