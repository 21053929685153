import { IDocument } from 'app/modules/financing/data';

import { AAGUID } from '../../types/aaguid';

export interface ISmartDocDocumentsUploadedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Dokumente, die geladen wurden
     */
    documents: IDocument[];
}

/**
 * Klasse für Aktion
 */
export class SmartDocDocumentsUploaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocDocumentsUploaded';
    /**
     * Constructor
     * 
     * @param {ISmartDocDocumentsUploadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocDocumentsUploadedPayloadType) { }
}
