/* eslint-disable no-redeclare */
import {
    BafAdditionalFacilities,
    BafBathroomFacilities,
    BafDocumentType,
    BafOutsideFacilities,
    AdditionalFacilities as BaseAdditionalFacilities,
    BathroomFacilities as BaseBathroomFacilities,
    DocumentType as BaseDocumentType,
    OutsideFacilities as BaseOutsideFacilities,
    FinprocessDocumentType,
    HomestoryDocumentType,
} from '@ntag-ef/finprocess-enums';

export {
    CreditType,
    FictionalRateSourceType,
    FinancingStatus,
    Gender,
    CreditBroker,
    MaritalStatus,
    HousingType,
    LevelOfTraining,
    EmployeeStatus,
    BankAustriaStatus,
    CreditPurpose,
    RealEstateType,
    Orientation,
    FlatOrientation,
    BasementUsage,
    BasementConstruction,
    RoofConstruction,
    Terrace,
    WintergardenType,
    AnnexType,
    HeatingSystem,
    HeatingSystemType,
    AdditionalHeatingSystem,
    ParkingSpace,
    GarageLocation,
    OriginalToType,
    MessageUserType,
    InterestMethod,
    ObjectPurposeType,
    FinProcessErrorCode,
    DebitRateAdaptionParameter,
    ComparisonType,
    MarketValueType,
    DocumentReferenceType,
    GarageFacilities,
    BuildingMaterialType,
    OrganisationalUnitResponsibilityType,
    Risk,
    Collateralization,
    MandantType,
    Language,
    FinancingPremissions,
    CoveredBy,
    LiabilityType,
    JointHeadingType,
} from '@ntag-ef/finprocess-enums';

export type BathroomFacilities = BaseBathroomFacilities | BafBathroomFacilities;
export const BathroomFacilities = Object.assign(BaseBathroomFacilities, BafBathroomFacilities);

export type AdditionalFacilities = BaseAdditionalFacilities | BafAdditionalFacilities;
export const AdditionalFacilities = Object.assign(BaseAdditionalFacilities, BafAdditionalFacilities);

export type OutsideFacilities = BaseOutsideFacilities | BafOutsideFacilities;
export const OutsideFacilities = Object.assign(BaseOutsideFacilities, BafOutsideFacilities);

export type DocumentType = BaseDocumentType | BafDocumentType | HomestoryDocumentType | FinprocessDocumentType;
export const DocumentType = Object.assign(BaseDocumentType, BafDocumentType, HomestoryDocumentType, FinprocessDocumentType);
