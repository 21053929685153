import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, input, model } from '@angular/core';

/**
 * An expansion panel component
 */
@Component({
    selector: 'ucba-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    standalone: true,
    imports: [
        NgClass,
    ],
    animations: [
        // An animation to expand the panel when the content appears
        trigger('expand', [
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                animate('250ms ease-in-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('250ms ease-in-out', style({ height: '0', opacity: 0 })),
            ]),
        ]),
    ],
})
export class UcbaExpansionPanelComponent {

    /**
     * Position of the arrow to toggle the expansion panel
     */
    public arrowPosition = input<'before' | 'after'>('before');

    /**
     * Whether to hide the toggle
     */
    public hideToggle = input<boolean>(false);

    /**
     * Whether the expansion panel is expanded
     */
    public expanded = model<boolean>(false);

    /**
     * Whether to show padding around the content
     */
    public showPadding = input<boolean>(true);

    /**
     * Toggles the expansion panel
     */
    public toggleExpanded(): void {
        this.expanded.update(expanded => !expanded);
    }
}
