export enum RatingPollingStatus {
    /**
     * Rating wurde erfolgreich von GWB abgerufen
     */
    Available,

    /**
     * Rating wurde ohne Ergebnis von GWB abgerufen
     */
    NotAvailable,

    /**
     * Beim Abruf des Ratings ist ein Fehler aufgetreten
     */
    Error
}
