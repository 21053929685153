export enum ProfessionType {
    /**
     * Schüler, Kind
     */
    StudentOrChild,

    /**
     * Lehrling
     */
    Apprentice,

    /**
     * Student
     */
    Student,

    /**
     * Angestellter
     */
    Employee,

    /**
     * Arbeiter
     */
    Worker,

    /**
     * Öffentlich Bediensteter
     */
    PublicServant,

    /**
     * Freier Mitarbeiter
     */
    Freelancer,

    /**
     * Berufsanwärter
     */
    CareerCandidate,

    /**
     * Hausfrau/-mann
     */
    HousemanHousewife,

    /**
     * Arbeitsloser
     */
    Unemployed,

    /**
     * Pensionist
     */
    Retired,

    /**
     * Sonstiger Privater
     */
    OtherPrivate,
}
