/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Logout } from 'app/modules/auth/data';

import { IFinancingConfiguration } from './../../interfaces/financing-configuration.interface';
import { FinancingConfigurationChanged, FinancingConfigurationLoaded } from './financing-configuration.action';

/**
 * Zustandsobjekt für Finanzierungskonfiguration
 */
export interface IFinancingConfigurationStateModel {

    /**
     * Aktuelle Finanzierungskonfiguration
     */
    configuration?: IFinancingConfiguration;

}

/**
 * Zustand für alle existierenden Nutzer
 */
@State<IFinancingConfigurationStateModel>({
    name: FinancingConfigurationState.keyName,
    defaults: {},
})
@Injectable()
export class FinancingConfigurationState {
    public static readonly keyName = 'financingConfiguration';

    /**
     * Zustandsänderung nach dem Laden der Finanzierungskonfiguration
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingConfigurationLoaded} action Aktion
     */
    @Action(FinancingConfigurationLoaded)
    public financingConfigurationLoaded({ patchState }: StateContext<IFinancingConfigurationStateModel>, { payload }: FinancingConfigurationLoaded): void {
        patchState({
            configuration: payload,
        });
    }

    /**
     * Zustandsänderung beim Ändern der Finanzierungskonfiguration
     *
     * @param {StateContext} context aktueller State Kontext
     * @param {FinancingConfigurationChanged} action Aktion
     */
    @Action(FinancingConfigurationChanged)
    public changed({ patchState }: StateContext<IFinancingConfigurationStateModel>, { payload }: FinancingConfigurationChanged): void {
        patchState({
            configuration: payload,
        });
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IFinancingConfigurationStateModel>): void {
        setState({});
    }
}
