import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISmartDocJobThumbnailViewModel } from 'app/modules/smartdoc/data';

export interface IThumbnailViewModel {
    thumbnail: ISmartDocJobThumbnailViewModel,
    content: string
}
/**
 * Komponente um Vollbild Thumbnail zu zeigen
 */
@Component({
    selector: 'finprocess-thumbnail-dialog',
    templateUrl: './thumbnail-dialog.component.html',
    styleUrls: ['./thumbnail-dialog.component.scss'],
})
export class ThumbnailDialogComponent {

    /**
     * Standard Konstruktor
     *
     * @param {IThumbnailViewModel} data Daten für Dialog
     * @param {MatDialogRef} dialogRef MatDialogRef
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: IThumbnailViewModel,
        private dialogRef: MatDialogRef<ThumbnailDialogComponent>,
    ) {}

    /**
     * Schließt den Dialog
     */
    public onClose(): void {
        this.dialogRef.close();
    }
}
