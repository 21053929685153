import { IsoDate } from 'shared/util';

import { IUser } from '../../interfaces';

/**
 * Aktion bei erfolgreichem Laden der Nutzerdaten
 */
export class UserDataLoaded {
    public static readonly type = 'auth.userDataLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Nutzerdaten
     * @param {IUser} payload.data Nutzerdaten
     * @param {IsoDate} payload.lastModified Letzte Änderung der Nutzerdaten
     */
    public constructor(public payload: {
        data: IUser;
        lastModified?: IsoDate;
    }) { }
}
