import { EnumSettings } from './../enum.settings';

/**
 * Höchste abgeschlossene Ausbildung
 */
export enum LevelOfTraining {
    /**
     * Pflichtschule
     */
    CompulsorySchool = 0,

    /**
     * Lehre
     */
    Apprenticeship = 1,

    /**
     * Matura
     */
    HigherSchoolCertificate = 2,

    /**
     * Meisterprüfung
     */
    MasterCertificate = 3,

    /**
     * MBA ohne akad. Ausbildung
     */
    MBAWithoutBachelor = 4,

    /**
     * akademischer Abschluss ohne Doktor
     */
    AcademicDegree = 5,

    /**
     * Doktorat
     */
    DoctorsDegree = 6,
}

export namespace LevelOfTraining {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {LevelOfTraining | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: LevelOfTraining | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'LevelOfTraining', LevelOfTraining, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('LevelOfTraining', plocale);
}
