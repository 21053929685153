<mat-tab-group mat-stretch-tabs="false" *ngIf="!!houseHoldData" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Übersicht">

        <div fxLayout="row" fxLayoutAlign="center" class="mt-3"
            *ngIf="riskHouseholdData.length > 0 || pensionHouseholdData.length > 0; else elseBlock">

            <div class="mr-2">
                <h4 class="titles"><b>{{'financing.features.financing-processing.householdBills.sumOverviewTitle' |
                        translate }}</b></h4>
                <table class="legend-table">
                    <thead>
                        <tr style="height: 49px;">
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--the rows for income -->
                        <tr>
                            <!--Einkommen-->
                            <td>{{'financing.features.financing-processing.householdBills.sumOfIncome' | translate }}
                            </td>
                        </tr>

                        <tr>
                            <!--Gemeinsames Einkommen-->
                            <td>{{'financing.features.financing-processing.householdBills.sumOfIncomeOverview' |
                                translate }}</td>
                        </tr>

                        <tr>
                            <!--Summe der Ausgaben-->
                            <td>{{'financing.features.financing-processing.householdBills.sumOfExpenditure' | translate
                                }}</td>
                        </tr>

                        <tr>
                            <!--Gemeinsame Ausgaben-->
                            <td><b>{{'financing.features.financing-processing.householdBills.expenditureOverview' |
                                    translate }}</b></td>
                        </tr>

                        <tr>
                            <!--frei verfügbares Einkommen-->
                            <td>{{'financing.features.financing-processing.householdBills.availableIncome' | translate
                                }}</td>
                        </tr>

                        <tr>
                            <!--gemeinsames frei verfügbares Einkommen-->
                            <td><b>{{'financing.features.financing-processing.householdBills.availableIncomeOverview' |
                                    translate }}</b></td>
                        </tr>

                        <tr>
                            <!--safety reserve-->
                            <td>{{'financing.features.financing-processing.householdBills.safetyReserve' | translate }}
                            </td>
                        </tr>

                        <tr>
                            <!--discretionaryIncomeOverview-->
                            <td><b>{{'financing.features.financing-processing.householdBills.discretionaryIncomeOverview'
                                    | translate}}</b></td>
                        </tr>
                        <tr>
                            <!--Neue Kredit-/Leasingraten-->
                            <td><b>{{'financing.features.financing-processing.household.newCreditLeasingRates'
                                    |
                                    translate }}</b></td>
                        </tr>
                        <tr>
                            <!--sumNewExternalLiabilities-->
                            <td>{{'financing.features.financing-processing.householdBills.sumNewExternalLiabilities' |
                                translate}}
                                <a style="font-size: 10px;">
                                    {{'financing.features.financing-processing.householdBills.sumNewExternalLiabilitiesComment'|
                                    translate }}
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <!--existingLiabilities-->
                            <td> {{'financing.features.financing-processing.householdBills.existingLiabilities' |
                                translate }}</td>
                        </tr>

                        <tr>
                            <!--loanOverview-->
                            <td><b>{{'financing.features.financing-processing.householdBills.loanOverview' | translate
                                    }}</b></td>
                        </tr>

                        <tr>
                            <!--reasonableCreditRateOverview-->
                            <td><b>{{'financing.features.financing-processing.householdBills.reasonableCreditRateOverview'
                                    | translate}}</b></td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div *ngIf="riskHouseholdData.length > 0">

                <finprocess-household-calculation-table [currentHouseHoldPage]="currentRiskOverviewPage"
                    [totalHouseHoldPages]="totalRiskOverviewPages"
                    [visibleHouseHoldOverview]="visibleRiskHouseHoldOverview" [householdData]="riskHouseholdData"
                    (previousOrNextDataEvent)="previousOrNextData(0, 'households', $event)" [calcType]="0"
                    [isRiskCalcTable]="true" (generateDocument)="generateDocumentClicked($event)"
                    [generateDocumentDisabled]="isStatusNotOpenOrWaiting" [isBaf]="isBaf">
                </finprocess-household-calculation-table>

            </div>

            <div *ngIf="pensionHouseholdData.length > 0">

                <finprocess-household-calculation-table [currentHouseHoldPage]="currentPensionOverviewPage"
                    [totalHouseHoldPages]="totalPensionOverviewPages"
                    [visibleHouseHoldOverview]="visiblePensionHouseHoldOverview" [householdData]="pensionHouseholdData"
                    (previousOrNextDataEvent)="previousOrNextData(2, 'households', $event)" [calcType]="2">
                </finprocess-household-calculation-table>

            </div>

        </div>

        <ng-template #elseBlock>
            <div fxLayoutAlign="center">{{'financing.features.financing-processing.householdBills.noHouseHoldOverview' |
                translate }}</div>
        </ng-template>

    </mat-tab>

    <!--Haushalte-->
    <mat-tab *ngFor="let household of houseHoldData.households let i = index" label="Haushalt {{i + 1}}">

        <div *ngIf="household.calculations; else elseBlock" fxLayout="column" class="mt-3">

            <!--show/hide customer household table button-->
            <ng-container *ngFor="let calculation of household.calculations">
                <div class="mb-2" *ngIf="calculation.type === 1">
                    <button style="float:right;" mat-raised-button color="primary" (click)="customerTableVisibility()">
                        <div *ngIf="showKHHRTable" fxLayout="row" fxLayoutAlign="center center">
                            {{'financing.features.financing-processing.householdBills.customerTableNotVisible' |
                            translate }}
                            <mat-icon class="ml-2">visibility_off</mat-icon>
                        </div>
                        <div *ngIf="!showKHHRTable" fxLayout="row" fxLayoutAlign="center center">
                            {{'financing.features.financing-processing.householdBills.customerTableVisible' | translate
                            }}
                            <mat-icon class="ml-2">visibility</mat-icon>
                        </div>
                    </button>
                </div>
            </ng-container>
            <div class="mb-2">
                <!--Notiz hinzufügen Button-->
                <button [disabled]="(fieldReadonly$ | async) ?? true" style="float:right;" mat-raised-button
                    color="primary" (click)="openNotesDialog(household.id)">
                    <div *ngIf="!riskHouseholdExpenditure[0]?.riskCalculationNote" fxLayout="row"
                        fxLayoutAlign="center center">
                        {{'financing.features.financing-processing.householdBills.addNote' | translate }}
                        <mat-icon class="ml-2">chat</mat-icon>
                    </div>
                    <div *ngIf="riskHouseholdExpenditure[0]?.riskCalculationNote" fxLayout="row"
                        fxLayoutAlign="center center">
                        {{'financing.features.financing-processing.householdBills.editNote' | translate }}
                        <mat-icon class="ml-2">speaker_notes</mat-icon>
                    </div>
                </button>
            </div>

            <!--Tabellen Legende-->
            <div fxLayout="row" fxLayoutAlign="center" class="mt-3">
                <div fxLayout="column">
                    <div class="mr-2">
                        <h4 class="titles"><b>{{'financing.features.financing-processing.householdBills.allIncomes' |
                                translate }}</b></h4>
                        <!--Einkünfte-->
                        <table class="legend-table">
                            <thead>
                                <tr style="height: 100px;">
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <!--the rows for income -->
                                <tr>
                                    <!--Nettoverdienst-->
                                    <td>{{'financing.features.financing-processing.householdBills.netIncome' | translate
                                        }}</td>
                                </tr>

                                <tr>
                                    <!--14. Gehälter-->
                                    <td>{{'financing.features.financing-processing.householdBills.fourteenSalary' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--bestehende Sonstige Einkünfte p.M.-->
                                    <td>{{'financing.features.financing-processing.householdBills.otherExistingIncome' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Einkünfte aus selbstständiger Tätigkeit-->
                                    <td>{{'financing.features.financing-processing.householdBills.independentIncome' |
                                        translate }}</td>
                                </tr>
                                <tr>
                                    <!--Bestehende Mieteinnahmen p.M. (12x pro Jahr)-->
                                    <td>{{'financing.features.financing-processing.householdBills.existingRentalIncome'
                                        |
                                        translate }}</td>
                                </tr>
                                <tr>
                                    <!--Einkünfte aus Mieteinnahmen-->
                                    <td>{{'financing.features.financing-processing.householdBills.rentalIncome' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Summe der Einnahmen-->
                                    <td><b>{{'financing.features.financing-processing.householdBills.sumOfIncome' |
                                            translate }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <table class="mt-3 sum-table">
                        <tbody>
                            <tr>
                                <!--Summe der gemeinsamen Einnahmen-->
                                <td><b>{{'financing.features.financing-processing.householdBills.sumOfIncomeOverview' |
                                        translate }}</b></td>
                            </tr>

                            <tr>
                                <!--30% der Einnahmen beträgt-->
                                <td>{{'financing.features.financing-processing.householdBills.thirtyPercentOfIncome' |
                                    translate }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <!--Ausgaben-->
                    <div class="mr-2">
                        <h4 class="expenses-titles mt-5" style="margin-bottom: 17px;">
                            <b>{{'financing.features.financing-processing.householdBills.sumOfExpensesTitle' | translate
                                }}</b>
                        </h4>
                        <table style="min-width:226px;">
                            <tbody>

                                <tr>
                                    <!--Miete/Betriebskosten-->
                                    <td>{{'financing.features.financing-processing.householdBills.rentalCost' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Telekommunikation-->
                                    <td>{{'financing.features.financing-processing.householdBills.telecommunicationCost'
                                        | translate }}</td>
                                </tr>

                                <tr>
                                    <!--Energiekosten-->
                                    <td>{{'financing.features.financing-processing.householdBills.energyCost' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--KFZ-Kosten-->
                                    <td>{{'financing.features.financing-processing.householdBills.carCost' | translate
                                        }}</td>
                                </tr>

                                <tr>
                                    <!--Versicherungen-->
                                    <td>{{'financing.features.financing-processing.householdBills.insuranceCost' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Alimente-->
                                    <td>{{'financing.features.financing-processing.householdBills.alimonyCost' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Lebenshaltungskosten-->
                                    <td>{{'financing.features.financing-processing.householdBills.livingCost' |
                                        translate }}</td>
                                </tr>

                                <tr>
                                    <!--Summe Ausgaben-->
                                    <td><b>{{'financing.features.financing-processing.householdBills.sumOfExpenditure' |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--Verfügbares Einkommen-->
                                    <td><b>{{'financing.features.financing-processing.householdBills.availableIncome' |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--10% Sicherheitsreserve-->
                                    <td><b>{{'financing.features.financing-processing.household.reserve' |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--Frei verfügbares Einkommen-->
                                    <td><b>{{'financing.features.financing-processing.household.freeAccessibleIncome' |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--Bestehen bleibende Kredit-/Leasingraten-->
                                    <td><b>{{'financing.features.financing-processing.household.monthlyRateNotCoveredLiabilities'
                                            |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--Neue Kredit-/Leasingraten-->
                                    <td><b>{{'financing.features.financing-processing.household.newCreditLeasingRates'
                                            |
                                            translate }}</b></td>
                                </tr>

                                <tr>
                                    <!--Zumutbare Kreditrate-->
                                    <td><b>{{'financing.features.financing-processing.household.acceptableCreditRate' |
                                            translate }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <!--Rechnungstabllen-->
                <ng-container *ngFor="let calculation of household.calculations">
                    <!--Risikohaushaltsrechnung-->
                    <div class="mr-2 ml-2" *ngIf="calculation.type === 0">
                        <div fxLayoutAlign="center center">
                            <h4 class="titles">
                                <b>{{calculation.type | finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                            </h4>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <!-- Navigation buttons -->
                            <button mat-button [disabled]="currentRiskHouseHoldPage === 0"
                                (click)="previousOrNextData(calculation.type, 'debitors', -1)">
                                <mat-icon color="primary"
                                    [style.color]="currentRiskHouseHoldPage === 0 ? 'lightgrey' : ''">arrow_back</mat-icon>
                            </button>

                            <div class="switch-debitor">
                                {{'financing.features.financing-processing.householdBills.debitorSwitch' | translate }}
                            </div>

                            <button mat-button
                                [disabled]="currentRiskHouseHoldPage === totalRiskHouseHoldDebitorPages - 1"
                                (click)="previousOrNextData(calculation.type, 'debitors', 1)">
                                <mat-icon color="primary"
                                    [style.color]="currentRiskHouseHoldPage === totalRiskHouseHoldDebitorPages - 1 ? 'lightgrey' : ''">arrow_forward</mat-icon>
                            </button>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="center">
                            <table>
                                <thead>
                                    <tr style="height: 100px;">
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <th>{{ debitor.displayName }}</th>
                                        </ng-container>
                                    </tr>

                                </thead>
                                <tbody>
                                    <!--the rows for income -->
                                    <tr>
                                        <!--Nettoverdienst-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>{{ debitor.netIncome || 0 | formatCurrency }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--14. Gehälter-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>
                                                <mat-checkbox [checked]="debitor.fourteenSalariesPerYear"
                                                [disabled]="(fieldReadonly$ | async) ?? true"></mat-checkbox>
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <tr>
                                        <!--sonstige bestehende Einkünfte-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>{{ debitor.existingOtherIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus selbstständiger Tätigkei-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>{{ debitor.independentIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Bestehende Mieteinnahmen p.M. (12x pro Jahr)-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>{{ debitor.existingRentalIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus Mieteinnahmen-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td>{{ debitor.rentalIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Summe der Einnahmen-->
                                        <ng-container *ngFor="let debitor of visibleRiskHouseHoldDebitors">
                                            <td><b>{{ debitor.sumOfIncome | formatCurrency}}</b>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="mt-3">
                                <tbody>
                                    <tr>
                                        <!--Summe der gemeinsamen Einnahmen-->
                                        <ng-container>
                                            <td><b>{{ riskHouseholdJointIncomeSum?.sum | formatCurrency}}</b>
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--30% der Einnahmen beträgt-->
                                        <ng-container>
                                            <td>{{ riskHouseholdJointIncomeSum?.thirtyPercentOfSum | formatCurrency }}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>

                            <div fxLayout="column" fxLayoutAlign="center">
                                <h4 class="expenses-titles mt-5" fxLayoutAlign="center">
                                    <b>{{calculation.type |
                                        finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                                </h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <!--Miete/Betriebskosten-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.operationalCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Telekommunikation-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td fxLayout="row" fxLayoutAlign="center center" style="height: 35px" [ngStyle]="{'color': (fieldReadonly$ | async) ? 'gray' : 'black'}">
                                                   <span class="ml-4 telecom-value">{{riskHouseholdTelecommunicationSum | formatCurrency}}</span>
                                                    <button class="mt-2 mr-2 tele-edit-icon" mat-icon-button [disabled]="(fieldReadonly$ | async) ?? true" (click)="openEditTelecommunicationDialog(calculation.type, household.id, data.broadcastCosts, data.phoneCosts)">
                                                        <mat-icon class="edit-icon">edit</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Energiekosten-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.electricityCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--KFZ-Kosten-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.carCosts"
                                                        [disabled]="(fieldReadonly$ | async) ?? true" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Versicherungen-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.insuranceCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Alimente-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.otherCosts"
                                                        [disabled]="(fieldReadonly$ | async) ?? true" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Lebenshaltungskosten-->
                                            <ng-container *ngFor="let data of riskHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.livingCosts"
                                                        currencyMask [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()"
                                                        [disabled]="(fieldReadonly$ | async) ?? true">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Summe Ausgaben-->
                                            <ng-container>
                                                <td><b>{{riskHouseholdExpenditureSum | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Verfügbares Einkommen-->
                                            <ng-container>
                                                <td><b>{{riskHouseholdAvailableIncomeSum | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--10% Sicherheitsreserve-->
                                             <ng-container>
                                                <td><b>{{riskHouseholdData[i].calculations[0].safetyReserve | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Frei verfügbares Einkommen-->
                                            <ng-container>
                                                <td><b>{{riskHouseholdData[i].calculations[0].discretionaryIncome | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Bestehen bleibende Kredit-/Leasingraten-->
                                            <ng-container>
                                                <td><b>{{riskHouseholdData[i].calculations[0].existingLiabilities | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Neue Kredit-/Leasingraten-->
                                            <ng-container>
                                                <td><b>{{riskHouseholdData[i].calculations[0].newLiabilities | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Zumutbare Kreditrate-->
                                           <ng-container>
                                                <td><b>{{riskHouseholdData[i].calculations[0].reasinableCreditRate | formatCurrency}} </b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <!--Kundenhaushaltsrechnung-->
                    <div class="mr-2 ml-2" *ngIf="calculation.type === 1 && showKHHRTable" [@fade]>

                        <div fxLayoutAlign="center">
                            <h4 class="titles">
                                <b>{{calculation.type |
                                    finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                            </h4>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <!-- Navigation buttons -->
                            <button mat-button [disabled]="currentCustomerHouseHoldPage === 0"
                                (click)="previousOrNextData(calculation.type, 'debitors', -1)">
                                <mat-icon color="primary"
                                    [style.color]="currentCustomerHouseHoldPage === 0 ? 'lightgrey' : ''">arrow_back</mat-icon>
                            </button>

                            <div class="switch-debitor">
                                {{'financing.features.financing-processing.householdBills.debitorSwitch' | translate
                                }}
                            </div>

                            <button mat-button
                                [disabled]="currentCustomerHouseHoldPage === totalCustomerHouseHoldDebitorPages - 1"
                                (click)="previousOrNextData(calculation.type, 'debitors', 1)">
                                <mat-icon color="primary"
                                    [style.color]="currentCustomerHouseHoldPage === totalCustomerHouseHoldDebitorPages - 1 ? 'lightgrey' : ''">arrow_forward</mat-icon>
                            </button>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="center">
                            <table>
                                <tr style="height: 100px;">
                                    <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                        <th>{{ debitor.displayName}}</th>
                                    </ng-container>
                                </tr>
                                <tbody>
                                    <!--the rows for income -->
                                    <tr>
                                        <!--Nettoverdienst-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>{{ debitor.netIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--14. Gehälter-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>
                                                <mat-checkbox [checked]="debitor.fourteenSalariesPerYear"
                                                    [disabled]="true"></mat-checkbox>
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <tr>
                                        <!--sonstige bestehende Einkünfte-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>{{ debitor.existingOtherIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus selbstständiger Tätigkei-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>{{ debitor.independentIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Bestehende Mieteinnahmen p.M. (12x pro Jahr)-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>{{ debitor.existingRentalIncome  || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus Mieteinnahmen-->
                                        <ng-container *ngFor="let debitor of visibleCustomerHouseHoldDebitors">
                                            <td>{{ debitor.rentalIncome || 0 | formatCurrency}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>

                            <!--making space for missing rows in customer hhr-->
                            <div class="mt-3" style="margin-bottom: 35.5%"></div>

                            <div fxLayout="column" fxLayoutAlign="center">
                                <h4 class="expenses-titles mt-5" fxLayoutAlign="center">
                                    <b>{{calculation.type |
                                        finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                                </h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <!--Miete/Betriebskosten-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.operationalCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Telekommunikation-->
                                            <ng-container>
                                                <td>{{customerHouseholdTelecommunicationSum | formatCurrency}}</td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Energiekosten-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.electricityCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--KFZ-Kosten-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.carCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Versicherungen-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.insuranceCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Alimente-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.otherCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Lebenshaltungskosten-->
                                            <ng-container *ngFor="let data of customerHouseholdExpenditure">
                                                <td>{{data.livingCosts || 0 | formatCurrency}}
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--Pensionshaushaltsrechnung-->
                    <div class="mr-2 ml-2" *ngIf="calculation.type === 2">

                        <div fxLayoutAlign="center">
                            <h4 class="titles">
                                <b>{{calculation.type |
                                    finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                            </h4>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <!-- Navigation buttons -->
                            <button mat-button [disabled]="currentPensionHouseHoldPage === 0"
                                (click)="previousOrNextData(calculation.type, 'debitors', -1)">
                                <mat-icon color="primary"
                                    [style.color]="currentPensionHouseHoldPage === 0 ? 'lightgrey' : ''">arrow_back</mat-icon>
                            </button>

                            <div class="switch-debitor">
                                {{'financing.features.financing-processing.householdBills.debitorSwitch' | translate
                                }}
                            </div>

                            <button mat-button
                                [disabled]="currentPensionHouseHoldPage === totalPensionHouseHoldDebitorPages - 1"
                                (click)="previousOrNextData(calculation.type, 'debitors', 1)">
                                <mat-icon color="primary"
                                    [style.color]="currentPensionHouseHoldPage === totalPensionHouseHoldDebitorPages - 1 ? 'lightgrey' : ''">arrow_forward</mat-icon>
                            </button>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="center">
                            <table>
                                <thead>
                                    <tr style="height: 100px;">
                                        <ng-container *ngFor="let debitor of visiblePensionHouseHoldDebitors">
                                            <th>{{ debitor.displayName }}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!--the rows for income -->
                                    <tr>
                                        <!--Nettoverdienst-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <input placeholder="€ 0,00" matInput [(ngModel)]="debitor.netIncome"
                                                    currencyMask [options]="currencyMaskOptions"
                                                    (ngModelChange)="onInputChange()"
                                                    [disabled]="(fieldReadonly$ | async) ?? true">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--14. Gehälter-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <mat-checkbox color="primary"
                                                    [disabled]="(fieldReadonly$ | async) ?? true"
                                                    [checked]="debitor.fourteenSalariesPerYear"
                                                    [(ngModel)]="debitor.fourteenSalariesPerYear"
                                                    (ngModelChange)="onInputChange()"></mat-checkbox>
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <tr>
                                        <!--sonstige bestehende Einkünfte-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <input placeholder="€ 0,00" matInput
                                                    [disabled]="(fieldReadonly$ | async) ?? true"
                                                    [(ngModel)]="debitor.existingOtherIncome" currencyMask
                                                    [options]="currencyMaskOptions" (ngModelChange)="onInputChange()">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus selbstständiger Tätigkei-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <input placeholder="€ 0,00" matInput
                                                    [disabled]="(fieldReadonly$ | async) ?? true"
                                                    [(ngModel)]="debitor.independentIncome" currencyMask
                                                    [options]="currencyMaskOptions" (ngModelChange)="onInputChange()">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Bestehende Mieteinnahmen p.M. (12x pro Jahr)-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <input placeholder="€ 0,00" matInput
                                                    [disabled]="(fieldReadonly$ | async) ?? true"
                                                    [(ngModel)]="debitor.existingRentalIncome" currencyMask
                                                    [options]="currencyMaskOptions" (ngModelChange)="onInputChange()">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Einkünfte aus Mieteinnahmen-->
                                        <ng-container
                                            *ngFor="let debitor of visiblePensionHouseHoldDebitors; let i = index;">
                                            <td (mouseleave)="updatePensionCalculationIncome(debitor)">
                                                <input placeholder="€ 0,00" matInput [(ngModel)]="debitor.rentalIncome"
                                                    [disabled]="(fieldReadonly$ | async) ?? true" currencyMask
                                                    [options]="currencyMaskOptions" (ngModelChange)="onInputChange()">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--Summe der Einnahmen-->
                                        <ng-container *ngFor="let debitor of visiblePensionHouseHoldDebitors">
                                            <td><b>{{ debitor.sumOfIncome | formatCurrency}}</b>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="mt-3">
                                <tbody>
                                    <tr>
                                        <!--Summe der gemeinsamen Einnahmen-->
                                        <ng-container>
                                            <td><b>{{ pensionHouseholdJointIncomeSum?.sum | formatCurrency}}</b>
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <!--30% der Einnahmen beträgt-->
                                        <ng-container>
                                            <td>{{pensionHouseholdJointIncomeSum?.thirtyPercentOfSum | formatCurrency}}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>

                            <div fxLayout="column" fxLayoutAlign="center">
                                <h4 class="expenses-titles mt-5" fxLayoutAlign="center">
                                    <b>{{calculation.type |
                                        finprocessEnumTranslation:"RiskHouseholdCalculationType"}}</b>
                                </h4>
                                <table>
                                    <tbody>
                                        <tr>
                                            <!--Miete/Betriebskosten-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.operationalCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Telekommunikation-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                    <td fxLayout="row" fxLayoutAlign="center center" style="height: 35px" [ngStyle]="{'color': (fieldReadonly$ | async) ? 'gray' : 'black'}">
                                                        <span class="ml-4 telecom-value">{{pensionHouseholdTelecommunicationSum | formatCurrency}}</span>
                                                         <button class="mt-2 tele-edit-icon" mat-icon-button [disabled]="(fieldReadonly$ | async) ?? true" (click)="openEditTelecommunicationDialog(calculation.type, household.id, data.broadcastCosts, data.phoneCosts)">
                                                             <mat-icon class="edit-icon">edit</mat-icon>
                                                         </button>
                                                     </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Energiekosten-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.electricityCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--KFZ-Kosten-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.carCosts"
                                                        [disabled]="(fieldReadonly$ | async) ?? true" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Versicherungen-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        [(ngModel)]="data.insuranceCosts" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Alimente-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.otherCosts"
                                                        [disabled]="(fieldReadonly$ | async) ?? true" currencyMask
                                                        [options]="currencyMaskOptions"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Lebenshaltungskosten-->
                                            <ng-container *ngFor="let data of pensionHouseholdExpenditure">
                                                <td
                                                    (mouseleave)="updateHouseholdCalculationExpenditure(data, household.id, calculation.type)">
                                                    <input placeholder="€ 0,00" matInput [(ngModel)]="data.livingCosts"
                                                        currencyMask [options]="currencyMaskOptions"
                                                        [disabled]="(fieldReadonly$ | async) ?? true"
                                                        (ngModelChange)="onInputChange()">
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Summe Ausgaben-->
                                            <ng-container>
                                                <td><b>{{pensionHouseholdExpenditureSum | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Verfügbares Einkommen-->
                                            <ng-container>
                                                <td><b>{{pensionHouseholdAvailableIncomeSum | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--10% Sicherheitsreserve-->
                                             <ng-container>
                                                <td><b>{{pensionHouseholdData[i].calculations[0].safetyReserve | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Frei verfügbares Einkommen-->
                                            <ng-container>
                                                <td><b>{{pensionHouseholdData[i].calculations[0].discretionaryIncome | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Bestehen bleibende Kredit-/Leasingraten-->
                                            <ng-container>
                                                <td><b>{{pensionHouseholdData[i].calculations[0].existingLiabilities | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Neue Kredit-/Leasingraten-->
                                            <ng-container>
                                                <td><b>{{pensionHouseholdData[i].calculations[0].newLiabilities | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <!--Zumutbare Kreditrate-->
                                           <ng-container>
                                                <td><b>{{pensionHouseholdData[i].calculations[0].reasinableCreditRate | formatCurrency}}</b>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
        </div>

        <ng-template #elseBlock>
            <div fxLayoutAlign="center">
                {{'financing.features.financing-processing.householdBills.noHouseHoldCalculation' | translate }}</div>
        </ng-template>

        <!--Bestehende Verpflichtungen Tabelle-->
        <div class="ml-3 mb-2 mt-4 titles" *ngIf="!!household.liabilities && household.liabilities.length > 0"><h4><b>{{'financing.features.financing-processing.householdBills.existingLiabilitiesTitle' | translate }}</b></h4></div>

        <div class="ml-3 mr-3 mt-3 mb-4" *ngIf="!!household.liabilities && household.liabilities.length > 0">

            <table mat-table [dataSource]="household.liabilities" style="background-color: transparent;">
                <!-- Institut-->
                <ng-container matColumnDef="liabilityBank">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities">{{'financing.features.financing-processing.householdBills.liabilityBank' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.liabilityBank | finprocessEnumTranslation: 'BankType'}} </td>
                </ng-container>

                 <!-- Art -->
                 <ng-container matColumnDef="liabilityType">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.liabilityType' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities">
                        <ng-container *ngIf="element.liabilityType === undefined">-</ng-container>
                        <ng-container *ngIf="element.liabilityType !== undefined">{{element.liabilityType | finprocessEnumTranslation:'LiabilityType'}}</ng-container>
                    </td>
                </ng-container>

                <!-- Datum Kreditaufnahme -->
                <ng-container matColumnDef="started">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.started' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.started | date:'dd.MM.YYYY'}} </td>
                </ng-container>

                <!-- ursprüngliche Kreditnominale-->
                <ng-container matColumnDef="initialAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.initialAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.initialAmount | formatCurrency}} </td>
                </ng-container>

                <!-- dzt. Aushaftung -->
                <ng-container matColumnDef="currentAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.currentAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.currentAmount | formatCurrency}} </td>
                </ng-container>

                <!-- monatliche fiktive Rate in € -->
                <ng-container matColumnDef="fictionalRateAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.fictionalRateAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.fictionalRateAmount | formatCurrency}} </td>
                </ng-container>

                <!-- monatliche Ist-Rate in € -->
                <ng-container matColumnDef="monthlyRate">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.monthlyRate' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.monthlyRate | formatCurrency}} </td>
                </ng-container>

                <!-- Gesamtlaufzeit in Monaten-->
                <ng-container matColumnDef="loanPeriodInMonths">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.loanPeriodInMonths' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.loanPeriodInMonths}} </td>
                </ng-container>

                <!-- Laufzeit über Pensionseintritt-->
                <ng-container matColumnDef="isBeyondRetirement">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.isBeyondRetirement' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities">
                        <ng-container *ngIf="element.isBeyondRetirement === true">Ja</ng-container>
                        <ng-container *ngIf="element.isBeyondRetirement === false">Nein</ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!--Neue zusätzliche Verbindlichkeiten-->
        <div class="ml-3 mb-2 mt-4 titles" *ngIf="!!household.newLiabilities && household.newLiabilities.length > 0"><h4><b>{{'financing.features.financing-processing.householdBills.newLiabilitiesTitle' | translate }}</b></h4></div>

        <div class="ml-3 mr-3 mt-3 mb-4" *ngIf="!!household.newLiabilities && household.newLiabilities.length > 0">

            <table mat-table [dataSource]="household.newLiabilities" style="background-color: transparent;">
                <!-- Institut-->
                <ng-container matColumnDef="liabilityBank">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities">{{'financing.features.financing-processing.householdBills.liabilityBank' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.liabilityBank | finprocessEnumTranslation: 'BankType'}} </td>
                </ng-container>

                 <!-- Art -->
                 <ng-container matColumnDef="liabilityType">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.liabilityType' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities">
                        <ng-container *ngIf="element.liabilityType === undefined">-</ng-container>
                        <ng-container *ngIf="element.liabilityType !== undefined">{{element.liabilityType | finprocessEnumTranslation:'LiabilityType'}}</ng-container>
                    </td>
                </ng-container>

                <!-- Datum Kreditaufnahme -->
                <ng-container matColumnDef="started">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.started' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.started | date:'dd.MM.YYYY'}} </td>
                </ng-container>

                <!-- ursprüngliche Kreditnominale-->
                <ng-container matColumnDef="initialAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.initialAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.initialAmount | formatCurrency}} </td>
                </ng-container>

                <!-- dzt. Aushaftung -->
                <ng-container matColumnDef="currentAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.currentAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.currentAmount | formatCurrency}} </td>
                </ng-container>

                <!-- monatliche fiktive Rate in € -->
                <ng-container matColumnDef="fictionalRateAmount">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.fictionalRateAmount' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.fictionalRateAmount | formatCurrency}} </td>
                </ng-container>

                <!-- monatliche Ist-Rate in € -->
                <ng-container matColumnDef="monthlyRate">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.monthlyRate' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.monthlyRate | formatCurrency}} </td>
                </ng-container>

                <!-- Gesamtlaufzeit in Monaten-->
                <ng-container matColumnDef="loanPeriodInMonths">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.loanPeriodInMonths' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities"> {{element.loanPeriodInMonths}} </td>
                </ng-container>

                <!-- Laufzeit über Pensionseintritt-->
                <ng-container matColumnDef="isBeyondRetirement">
                    <th mat-header-cell *matHeaderCellDef class="existing-liabilities"> {{'financing.features.financing-processing.householdBills.isBeyondRetirement' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="existing-liabilities">
                        <ng-container *ngIf="element.isBeyondRetirement === true">Ja</ng-container>
                        <ng-container *ngIf="element.isBeyondRetirement === false">Nein</ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-tab>

</mat-tab-group>
