import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Komponente für zweispaltiges Layout
 */
@Component({
    selector: 'finprocess-two-column-layout',
    templateUrl: './two-column-layout.component.html',
    styleUrls: ['./two-column-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoColumnLayoutComponent {

    /**
     * Layout-Breite der rechten Seite in %
     */
    @Input()
    public rightFxFlex = 75;

    /**
     * Layout-Breite der linken Seite in %
     */
    @Input()
    public leftFxFlex = 25;

    /**
     * Layout-Orientierung der rechten Seite
     */
    @Input()
    public rightFxLayoutAlign = 'start center';

    /**
     * Layout-Orientierung der linken Seite
     */
    @Input()
    public leftFxLayoutAlign = 'start center';

    /**
     * Klassen der linken Seite
     */
    @Input()
    public leftClass = ' px-2 py-3';

    /**
     * Klassen der rechten Seite
     */
    @Input()
    public rigthClass = ' px-2 py-3';
}
