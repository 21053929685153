/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { FinancingPremissions } from '@ntag-ef/finprocess-enums/finprocess';
import { HelperService } from 'shared/util';

/**
 * Verkurtz OrganisationalUnit
 */
@Pipe({
    name: 'organisationalUnitResponsibilityShort',
})
export class OrganisationalUnitResponsibilityShortPipe implements PipeTransform {

    /**
     * abgekürzter FinancingPremissions
     *
     * @param {FinancingPremissions} financingPremissions FinancingPremissions
     * @returns {string} FinancingPremissions für UI as string
     */
    public transform(financingPremissions?: FinancingPremissions): string {
        if (!!financingPremissions) {
            const bitLangs = HelperService.getBitNumbers(financingPremissions as number);

            return bitLangs.map(premission => {
                switch (premission) {

                    case FinancingPremissions.EmployeesSkill:
                        return 'Mitarbeitercenter'
                    case FinancingPremissions.PrivateCustomerSkill:
                        return 'PF'
                    case FinancingPremissions.SmallBusinessSkill:
                        return 'GK'
                    default: return 'Keine';

                }

            }).join('/');
        }
        return '';
    }
}
