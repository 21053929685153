import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IUploadDocument } from './../../../../data/interfaces/upload-tab.interface';

/**
 * Komponente für den File-Upload
 */
@Component({
    selector: 'finprocess-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
    @Input()
    public maxFileCount?: number;

    @Input()
    public files: Pick<IUploadDocument, 'file' | 'alreadyExists' | 'fileId'>[] = [];

    @Output()
    public filesChange = new EventEmitter<Pick<IUploadDocument, 'file' | 'alreadyExists' | 'fileId'>[]>();

    public error = '';

    public dragAreaClass = '';

    /**
     *
     * Standard Konstruktor
     *
     * @param {TranslateService} translate TranslateService Injektor
     */
    public constructor(private translate: TranslateService) {}

    /**
     * Wenn es eine Dateiänderung gibt
     *
     * @param {EventTarget} target übergebene Datei
     */
    public onFileChange(target: EventTarget | null): void {
        const tmp = target as HTMLInputElement;

        this.saveFiles(tmp.files);
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.dragAreaClass = 'dragarea';
    }

    /**
     * Push files ins lokale fileobj
     *
     * @param {FileList} files FileList
     */
    public saveFiles(files: FileList | null): void {
        if (files && files.length > 0) {

            this.error = '';

            if (this.maxFileCount !== undefined && this.files.length + files.length > this.maxFileCount) {
                this.error = this.translate.instant('financing.features.financing-processing.process.calculationCountError', { i: this.maxFileCount})
                return;
            }

            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);

                if (file === null) {
                    return;
                }

                if (file.size === 0) {
                    this.error = this.translate.instant('financing.features.financing-processing.process.fileSizeError', { i: file.name})
                    return;
                }

                if (file.type !== 'application/pdf') {
                    this.error = this.translate.instant('financing.features.financing-processing.process.calculationTypeError')
                    return;
                }

                this.files.push(({file, alreadyExists: false}));
            }

            this.filesChange.emit(this.files);
        }
        //nur beim Mozilla passiert, dass die files, die direkt aus .zip kommen, undefined sind
        else {
            this.error = this.translate.instant('financing.features.financing-processing.process.fileSizeErrorMozila')
        }
    }


    /**
     * Löscht eine Datei
     *
     * @param {number} index Index der zu löschenden Datei
     */
    public removeFile(index: number): void {
        this.files.splice(index, 1);
        this.filesChange.emit(this.files);
    }

    //--------------------------------------------------------------------------
    //                       H O S T L I S T E N E R
    //--------------------------------------------------------------------------
    /**
     * HostListener dragover
     *
     * @param {DragEvent} event DragEvent
     */
    @HostListener('dragover', ['$event'])
    public onDragOver(event: DragEvent): void {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    /**
     * HostListener dragenter
     *
     * @param {DragEvent} event DragEvent
     */
    @HostListener('dragenter', ['$event'])
    public onDragEnter(event: DragEvent): void {
        this.dragAreaClass = 'droparea';
        event.preventDefault();
    }

    /**
     * HostListener dragend
     *
     * @param {DragEvent} event DragEvent
     */
    @HostListener('dragend', ['$event'])
    public onDragEnd(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    /**
     * HostListener dragleave
     *
     * @param {DragEvent} event DragEvent
     */
    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
    }

    /**
     * HostListener drop
     *
     * @param {DragEvent} event DragEvent
     */
    @HostListener('drop', ['$event'])
    public onDrop(event: DragEvent): void {
        this.dragAreaClass = 'dragarea';
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer?.files) {
            this.saveFiles(event.dataTransfer?.files);
        }
    }
}
