import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const MANUAL_SEGMENT_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'manualSegmentModel',

    segment: { type: WorkflowType.String },

    segmentDescription: { type: WorkflowType.String },

    subSegment: { type: WorkflowType.String },

    subSegmentDescription: { type: WorkflowType.String },
}
