import { UUID } from 'app/modules/shared';

import { IExistingRentalIncome, IFutureRentalIncome } from '../../interfaces';

/**
 * Action when loading existing rental incomes
 */
export class ExistingRentalIncomesLoaded {
    public static readonly type = 'debitor.existingRentalIncomesLoaded';

    /**
     * Constructor 
     *
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {IExistingRentalIncome[]} payload.existingRentalIncomes List of existing rental incomes
     */
    public constructor(public payload: { debitorId: UUID, existingRentalIncomes: IExistingRentalIncome[]}) { }
}

/**
 * Action when adding a new existing rental income
 */
export class ExistingRentalIncomeAdded {
    public static readonly type = 'debitor.existingRentalIncomeAdded';

    /**
     * Constructor 
     *
     * @param {IExistingRentalIncome} payload New existing rental income
     */
    public constructor(public payload: IExistingRentalIncome) { }
}

/**
 * Action when deleting an existing rental income
 */
export class ExistingRentalIncomeDeleted {
    public static readonly type = 'debitor.existingRentalIncomeDeleted';

    /**
     * Constructor
     * 
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {UUID} payload.existingRentalIncomeId ID of the existing rental income
     */
    public constructor(public payload: { debitorId: UUID, existingRentalIncomeId: UUID }) { }
}

/**
 * Action when updating an existing rental income
 */
export class ExistingRentalIncomeUpdated {
    public static readonly type = 'debitor.existingRentalIncomeUpdated';

    /**
     * Constructor
     * 
     * @param {IExistingRentalIncome} payload Updated existing rental income
     */
    public constructor(public payload: IExistingRentalIncome) { }
}

/**
 * Action when loading future rental incomes
 */
export class FutureRentalIncomesLoaded {
    public static readonly type = 'debitor.futureRentalIncomesLoaded';

    /**
     * Constructor 
     *
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {IFutureRentalIncome[]} payload.futureRentalIncomes List of future rental incomes
     */
    public constructor(public payload: { debitorId: UUID, futureRentalIncomes: IFutureRentalIncome[]}) { }
}

/**
 * Action when adding a new future rental income
 */
export class FutureRentalIncomeAdded {
    public static readonly type = 'debitor.futureRentalIncomeAdded';

    /**
     * Constructor 
     *
     * @param {object} payload New future rental income
     */
    public constructor(public payload: IFutureRentalIncome) { }
}

/**
 * Action when deleting a future rental income
 */
export class FutureRentalIncomeDeleted {
    public static readonly type = 'debitor.futureRentalIncomeDeleted';

    /**
     * Constructor
     * 
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {UUID} payload.futureRentalIncomeId ID of the future rental income
     */
    public constructor(public payload: { debitorId: UUID, futureRentalIncomeId: UUID }) { }
}

/**
 * Action when updating a future rental income
 */
export class FutureRentalIncomeUpdated {
    public static readonly type = 'debitor.futureRentalIncomeUpdated';

    /**
     * Constructor
     * 
     * @param {IFutureRentalIncome} payload Updated future rental income
     */
    public constructor(public payload: IFutureRentalIncome) { }
}
