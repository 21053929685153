export enum LiabilityType {

    /**
     * Darlehen
     */
    Credit,

    /**
     * Ergänzungsdarlehen
     */
    ComfortCredit,

    /**
     * Überschreitung
     */
    Overdraft,

    /**
     * Einmalbarkredit
     */
    OneTimeCashLoan,

    /**
     * Garantie stand alone (Mietkautionsgarantie ohne Besicherung und Hauserrichtung mit Verpfändung Sparkonto)
     */
    GuaranteeStandAlone,

    /**
     * Garantie im Zusammenhang mit BW Finanzierung
     */
    GuaranteeConstruction,

    /**
     * BW-Konto/Zwifi ohne Anschlussfinanzierung
     */
    ConstructionInterimFinancing,

    /**
     * BW Konto ohne Anschlussfinanzierung für Vorfinanzierung Ust. einer Vorsorgewohnung bis 50.000€
     */
    ConstructionPrefinancingInvestmentFlatLow,

    /**
     * BW Konto ohne Anschlussfinanzierung für Vorfinanzierung Ust. einer Vorsorgewohnung über 50.000€:
     */
    ConstructionPrefinancingInvestmentFlatHigh,

    /**
     * BW Konto mit Anschlussfinanzierung Förderung
     */
    ConstructionFollowUpFinancing,

    /**
     * BW Konto mit Anschlussfinanzierung z.B. Bauspardarlehen
     */
    ConstructionFollowUpFinancingBuildingLoan,

    /**
     * Kreditkarte
     */
    CreditCard,

    /**
     * FW-Kredit
     */
    DevelopmentLoan,

    /**
     * Förderdarlehen
     */
    SubsidizedLoan,

    /**
     * Betrieblicher Kredit
     */
    CompanyCredit,

    /**
     * KFZ-Leasing
     */
    KfzLeasing,
}
