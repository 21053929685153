import { EnumSettings } from './../enum.settings';

/**
 * Heizungsmedium
 */
export enum HeatingSystemType {
    /**
     * Öl
     */
    Oil = 0,

    /**
     * Gas
     */
    Gas = 1,

    /**
     * Strom
     */
    Electricity = 3,

    /**
     * Solar
     */
    Solar = 4,

    /**
     * Festbrennstoffe (Hackschnitzel, Pellets)
     */
    WoodChips = 5,

    /**
     * Festbrennstoffe (Kohle/Koks, Holz, etc.)
     */
    CoalOrWood = 6,

    /**
     * Fernwärme
     */
    DistrictHeating = 7,

    /**
     * Wärmepumpe
     */
    HeatPump = 8,
}

export namespace HeatingSystemType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {HeatingSystemType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: HeatingSystemType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'HeatingSystemType', HeatingSystemType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('HeatingSystemType', plocale);
}
