export interface IErrorOccuredPayloadType {

    /**
     * error
     */
    error: unknown;

    /**
     * message
     */
    message?: string;
}

/**
 * ErrorOccured Action
 */
export class ErrorOccured {
    /**
     * key for Action
     */
    public static readonly type = 'smartdoc.errorOccured';
    /**
     * Constructor
     *
     * @param {IErrorOccuredPayloadType} payload ErrorOccuredPayloadType
     */
    public constructor(public payload: IErrorOccuredPayloadType) { }
}
