import { EnumSettings } from './../enum.settings';

/**
 * Nachrichtentyp
 */
export enum MessageUserType {
    /**
     * Keiner
     */
    None = 0,

    /**
     * FinProcess
     */
    FinProcess = 1,

    /**
     * Quellsystem
     */
     Source = 2,

    /**
     * Kunde
     */
     Customer = 4,
}

export namespace MessageUserType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {MessageUserType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: MessageUserType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'MessageUserType', MessageUserType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('MessageUserType', plocale);
}
