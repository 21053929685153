import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { ADDRESS_HEADING_INFO_ANA_REQUEST } from './address-heading-info-ana-request';
import { ADDRESS_INFO_FORCED_ANA } from './address-info-forced-ana';
import { CUSTOMER_ADDRESS_RELATIONSHIP } from './customer-address-relationship';

export const CUSTOMER_ADDRESS_IN: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerAddressIn',

    addressHeadingInfoAna: { type: WorkflowType.Object, child: ADDRESS_HEADING_INFO_ANA_REQUEST },

    addressInfoAna: { type: WorkflowType.Object, child: ADDRESS_INFO_FORCED_ANA },

    currentAddress: { type: WorkflowType.Object, child: ADDRESS_INFO_FORCED_ANA },

    /**
     * optional
     */
    normalInd: { type: WorkflowType.Boolean },

    relationship: { type: WorkflowType.Object, child: CUSTOMER_ADDRESS_RELATIONSHIP },
}
