<mat-expansion-panel *ngIf="!!assetAccount" class="bg-dark-2 mr-3 ml-3 pl-4 pr-4 pb-3" [hideToggle]="true" [expanded]="!!assetExpand">
    <mat-expansion-panel-header #ex class="mb-3 mt-3" style="cursor: default">
        <div fxLayout="row" fxLayoutAlign="space-between start" class="mb-2" (click)="$event.stopImmediatePropagation()">
            <div fxLayout="row">
                <button class="mt-2" mat-icon-button (click)="assetExpand = !assetExpand; $event.stopPropagation()">
                    <mat-icon class="expand-icon">{{ assetExpand ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
                <h3 class="mt-3 mr-2 sub-header" *ngIf="!assetAccount.isBonds">
                    <b>{{ 'asset.assetAccount.accountBalance' | translate }}</b>
                </h3>
                <h3 class="mt-3 mr-2 sub-header" *ngIf="assetAccount.isBonds">
                    <b>{{ 'asset.assetAccount.securities' | translate }}</b>
                </h3>
            </div>

            <finprocess-label-value (click)="$event.stopImmediatePropagation()" flexDirection="row" fxLayoutAlign="end start" class="pt-3">
                <span label class="pr-2">{{ 'financing.features.financing-processing.liabilities.liabilityOverview.active' | translate }}</span>
                <span value>
                    <mat-checkbox
                        #checkboxRef
                        color="primary"
                        [checked]="assetAccount.isActive"
                        (click)="onCheckboxChange(assetAccount.id, !assetAccount.isActive)"
                        [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
                    </mat-checkbox>
                </span>
            </finprocess-label-value>
        </div>

        <div class="asset-grid" (click)="$event.stopImmediatePropagation()" *ngIf="assetAccount.isBonds">
            <!-- Wertpapiere header-->
            <finprocess-decimal-input
                [overwrite]="false"
                [entityClassType]="entityClassType.AssetAccount"
                fieldName="materialValueTotal"
                [flexDirection]="inputFlexDirection"
                [hideHorizontalRule]="true"
                [readonly]="fieldReadonly$"
                [entity]="assetAccount"
                [transparent]="true"
                subscriptSizing="fixed"
                [allowNegativeValues]="true">
                <span label class="input-label">{{ 'asset.assetAccount.materialValueTotal' | translate }}</span>
            </finprocess-decimal-input>

            <finprocess-decimal-input
                class="label-balance"
                [overwrite]="false"
                [entityClassType]="entityClassType.AssetAccount"
                fieldName="balance"
                [flexDirection]="inputFlexDirection"
                [hideHorizontalRule]="true"
                [readonly]="fieldReadonly$"
                [entity]="assetAccount"
                [validators]="Validators.required"
                [transparent]="true"
                subscriptSizing="fixed"
                [allowNegativeValues]="true">
                <span label class="input-label">{{ 'asset.assetAccount.balance' | translate }}</span>
            </finprocess-decimal-input>

            <finprocess-textbox-input
                [overwrite]="false"
                [entityClassType]="entityClassType.AssetAccount"
                fieldName="accountNumber"
                [flexDirection]="inputFlexDirection"
                [hideHorizontalRule]="true"
                [readonly]="fieldReadonly$"
                [entity]="assetAccount"
                [transparent]="true"
                subscriptSizing="fixed">
                <span label class="input-label">{{ 'asset.assetAccount.accountNumber' | translate }}</span>
            </finprocess-textbox-input>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" (click)="$event.stopImmediatePropagation()" *ngIf="!assetAccount.isBonds">
            <finprocess-decimal-input
                fxFlex="50"
                [overwrite]="false"
                [entityClassType]="entityClassType.AssetAccount"
                fieldName="balance"
                [flexDirection]="inputFlexDirection"
                [hideHorizontalRule]="true"
                [readonly]="fieldReadonly$"
                [entity]="assetAccount"
                [validators]="Validators.required"
                [transparent]="true"
                subscriptSizing="fixed"
                [allowNegativeValues]="true">
                <span label class="input-label">{{ 'asset.assetAccount.balanceBond' | translate }}</span>
            </finprocess-decimal-input>

            <finprocess-select-input
                fxFlex="50"
                class="bank-guarantee"
                [items]="booleanItems"
                [overwrite]="false"
                [entityClassType]="entityClassType.AssetAccount"
                [hideHorizontalRule]="true"
                fieldName="isExclusivelyCollateral"
                [readonly]="fieldReadonly$"
                [entity]="assetAccount"
                [valueStorageType]="ValueStorageType.Bool"
                [flexDirection]="inputFlexDirection"
                [validators]="Validators.required"
                [transparent]="true"
                subscriptSizing="fixed">
                <span label class="input-label">{{ 'asset.assetAccount.isExclusivelyCollateral' | translate }}</span>
            </finprocess-select-input>
        </div>
    </mat-expansion-panel-header>
    <mat-divider style="border-width: 4px; margin-top: 2%; margin-bottom: 3%"></mat-divider>
    <!-- um das body lazy zu rendern brauchen wir eine reference zum panel-header -->
    <ng-template matExpansionPanelContent>
        <ng-container *ngIf="ex._isExpanded">
            <div class="mb-3">
                <h3 class="mt-3">{{ 'asset.assetCommon.otherInfos' | translate }}</h3>

                <div class="asset-grid">
                    <finprocess-integer-input
                        *ngIf="assetAccount.isBonds"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetAccount"
                        fieldName="loanRate"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        [readonly]="fieldReadonly$"
                        [entity]="assetAccount"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetAccount.loanRateGeos' | translate }}</span>
                    </finprocess-integer-input>

                    <finprocess-integer-input
                        *ngIf="!assetAccount.isBonds"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetAccount"
                        fieldName="loanRate"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        [readonly]="fieldReadonly$"
                        [entity]="assetAccount"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetAccount.loanRate' | translate }}</span>
                    </finprocess-integer-input>

                    <finprocess-integer-input
                        *ngIf="!assetAccount.isBonds && assetAccount.isExclusivelyCollateral === true"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetAccount"
                        fieldName="loanRate"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        [readonly]="fieldReadonly$"
                        [entity]="assetAccount"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetAccount.loanRate' | translate }}</span>
                    </finprocess-integer-input>

                    <finprocess-decimal-input
                        *ngIf="assetAccount.source === SourceCollateral.Agp"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetAccount"
                        fieldName="materialValueAlreadyInUse"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        [readonly]="fieldReadonly$"
                        [entity]="assetAccount"
                        [transparent]="true"
                        subscriptSizing="fixed"
                        [allowNegativeValues]="true">
                        <span label class="input-label">{{ 'asset.assetCommon.materialValueAlreadyInUse' | translate }}</span>
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetAccount"
                        fieldName="freeMaterialValue"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        [readonly]="fieldReadonly$"
                        [entity]="assetAccount"
                        [transparent]="true"
                        subscriptSizing="fixed"
                        [allowNegativeValues]="true">
                        <span label class="input-label">{{ 'asset.assetCommon.freeMaterialValue' | translate }}</span>
                    </finprocess-decimal-input>
                </div>
            </div>

            <div fxLayout="column" class="body">
                <finprocess-asset-provider
                    style="margin-top: 20px"
                    [entityClassType]="entityClassType.AssetAccount"
                    [assetProviders]="assetAccount.assetProviders"
                    [assetId]="assetAccount.id"></finprocess-asset-provider>
                <mat-divider class="mt-4"></mat-divider>
                <finprocess-asset-agp style="margin-top: 20px" [assetsAgps]="assetAccount.assetsAgp"></finprocess-asset-agp>
            </div>
        </ng-container>
    </ng-template>
</mat-expansion-panel>
