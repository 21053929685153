import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard, IRoleData, Role } from 'auth';

import { SmartDocProcessingComponent } from './components/smart-doc-processing/smart-doc-processing.component';
import { SmartdocComponent } from './components/smartdoc/smartdoc.component';
import { SmartdocSelectionComponent } from './components/smartdoc-selection/smartdoc-selection.component';

/**
 * @todo Abstimmungen zu Roles bzw Rights und canActivate
 */
const routes: Routes = [
    {
        path: '',
        component: SmartdocComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: {
            roles:
                Role.FinancingMapsGlobalReader |
                Role.FinancingMapsReader |
                Role.FinancingMapsEditor, needOnlyOne: true,
        } as IRoleData,
        children: [
            // {
            //     path: 'upload',
            //     component: SmartDocUploadComponent,
            //     canActivate: [AuthenticationGuard, AuthorizationGuard],
            //     // Role check
            //     data: {rights: [Role.SmartDoc]},
            // },
            {
                path: 'smartdocSelection',
                component: SmartdocSelectionComponent,
                // canActivate: [AuthenticationGuard, AuthorizationGuard ],
                // data: {rights: [Role.SmartDoc]},
            },
            {
                path: 'smartdocProcessing',
                component: SmartDocProcessingComponent,
                // canActivate: [AuthenticationGuard, AuthorizationGuard],
                // data: {rights: [Role.SmartDoc]},
            },
            {
                path: 'financing',
                loadChildren: async () => (await import('financing')).FinancingProcessingModule,
                // canActivate: [AuthGuard, RightGuard],
                // canActivateChild: [AuthGuard, RightGuard],
                // data: { rights: [Rights.Read] },
            },
        ],
    },
];

/**
 * Modul für SmartDoc-Routes
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SmartdocRoutingModule {

}
