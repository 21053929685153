import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { FinancingService, IDebitorIncomeEntries, IDebitorIncomeEntryData, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { HelperService, UUID } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Subject, take, takeUntil } from 'rxjs';

/**
 * Calculate Average Income Dialog Component
 */
@Component({
    selector: 'finprocess-calc-average-income-dialog',
    templateUrl: './calc-average-income-dialog.component.html',
    styleUrls: ['./calc-average-income-dialog.component.scss'],
})
export class CalcAverageIncomeDialogComponent implements OnInit, OnDestroy {

    /**
     * ViewChild MatTable
     */
    @ViewChild(MatTable) public table!: MatTable<IDebitorIncomeEntries[]>;

    /**
     * Form
     */
    public form!: FormGroup;

    /**
     * Total Net Income
     */
    public totalNetIncome = 0;

    /**
     * Financing Container ID
     */
    public financingContainerID?: UUID;

    /**
     * Table Columns
     */
    public displayedColumns: string[] = ['year', 'month', 'netIncomeAccToStatement', 'netIncomeMinusCleanup', 'adjustedNetIncome'];

    /**
     * 6 oder 12 Table Rows
     */
    public isChecked = signal(false);

    /**
     * Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     * Aktuelles Jahr
     */
    public currentYear!: number;
    /**
     * Vorheriges Jahr
     */
    public previousYear!: number;

    /**
     * Folgejahr Jahr
     */
    public followingYear!: number;

    /**
     * Monate
     */
    public months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

    /**
     * Initial Data
     */
    public initialData: IDebitorIncomeEntries[] = [];

    /**
     * Data Source für die Tabelle
     */
    public dataSource: IDebitorIncomeEntries[] = [...this.initialData];

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     * 
     * @param {MatDialogRef} dialogRef dialogref
     * @param {TranslateService}translate translate
     * @param {Store} store store
     * @param {UUID} debitorID debitorID
     * @param {string} locale locale
     * @param {FormBuilder} fb Formbuilder
     * @param {FinancingService} financingService financingService
     * @param {NotificationService} notificationService notificationService
     */
    public constructor(
        private dialogRef: MatDialogRef<CalcAverageIncomeDialogComponent>, 
        private translate: TranslateService,
        private store: Store, 
        @Inject(MAT_DIALOG_DATA) public debitorID: UUID, 
        @Inject(LOCALE_ID) locale: string,
        private fb: FormBuilder, 
        private financingService: FinancingService, 
        private notificationService: NotificationService,
    ) {

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
            decimal: ',',
            thousands: '.',
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        const submissionDate = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.submissionDate);
        if (!!submissionDate) {
            this.currentYear = new Date(submissionDate).getFullYear();
            this.previousYear = this.currentYear - 1;
            this.followingYear = this.currentYear + 1;
        }
        else {
            this.currentYear = new Date().getFullYear();
            this.previousYear = this.currentYear - 1;
            this.followingYear = this.currentYear + 1;
        }

        this.initializeInitialData();
        this.initializeForm();

        if (!!this.financingContainerID && !!this.debitorID) {
            this.financingService.getDebitorIncomeEntries(this.debitorID, this.financingContainerID).pipe(take(1)).subscribe(res => {
                if (!!res && res.length > 6) {
                    this.isChecked.set(true);
                    this.initializeForm();
                    this.updateFormArray(res);
                }
                if (!!res && res.length > 0 && res.length <= 6) {
                    this.form.patchValue({ data: res });
                }
            });
        }

        this.data.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.calculateTotalNetIncome());
    }


    /**
     * Initialize Initial Data
     */
    private initializeInitialData() {
        this.initialData = [
            { year: this.currentYear, month: 'Januar', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 0 },
            { year: this.currentYear, month: 'Februar', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 1 },
            { year: this.currentYear, month: 'März', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 2 },
            { year: this.currentYear, month: 'April', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 3 },
            { year: this.currentYear, month: 'Mai', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 4 },
            { year: this.currentYear, month: 'Juni', netIncomeAccToStatement: null, netIncomeMinusCleanup: 0, adjustedNetIncome: 0, position: 5 },
        ];
    }

    /**
     * Initialize Form
     */
    private initializeForm() {
        this.form = this.fb.group({
            data: this.fb.array(this.initialData.map(data => this.createDataGroup(data))),
        });
    }

    /**
     * Get Form Data
     * 
     * @returns {FormArray} Form Array
     */
    public get data(): FormArray {
        return this.form.get('data') as FormArray;
    }

    /**
     * Create Data Group
     * 
     * @param {IDebitorIncomeEntries} data data
     * @returns {FormGroup} Form Group
     */
    public createDataGroup(data: IDebitorIncomeEntries): FormGroup {
        return this.fb.group({
            year: [data.year, Validators.required],
            month: [data.month, Validators.required],
            netIncomeAccToStatement: [data.netIncomeAccToStatement, [Validators.required, Validators.min(0.01)]],
            netIncomeMinusCleanup: [data.netIncomeMinusCleanup, [Validators.required]],
            adjustedNetIncome: [{ value: data.adjustedNetIncome, disabled: true }],
            position: [data.position],
        });
    }

    /**
     * Update Form Array
     * 
     * @param {IDebitorIncomeEntries[]} data data
     */
    public updateFormArray(data: IDebitorIncomeEntries[]): void {
        const formArray = this.form.get('data') as FormArray;
        formArray.clear();
        data.forEach(entry => formArray.push(this.createDataGroup(entry)));
        this.dataSource = formArray.controls.map(control => control.value) as IDebitorIncomeEntries[];
    }

    /**
     * calculate Total Net Income
     */
    public calculateTotalNetIncome() {
        let calculatedTotalNetIncome = 0;
        const rows = this.data.controls.length;
        const divisor = this.isChecked() ? 14 : 7;

        for (let i = 0; i < rows; i++) {
            const netIncome = this.data.controls[i].get('netIncomeAccToStatement')?.value;
            const manualAdjustments = this.data.controls[i].get('netIncomeMinusCleanup')?.value;

            if (netIncome === undefined || netIncome === null || manualAdjustments === undefined || manualAdjustments === null) {
                return;
            }

            const adjustedNetIncome = netIncome - manualAdjustments;
            calculatedTotalNetIncome += adjustedNetIncome;
        }

        this.totalNetIncome = calculatedTotalNetIncome / divisor;
    }

    /**
     * Change Table Rows
     */
    public toggleChecked() {
        this.isChecked.set(!this.isChecked());

        if (this.isChecked()) {
            for (let i = 0; i < 6; i++) {
                this.data.push(this.createDataGroup({
                    year: this.currentYear,
                    month: this.months[6 + i],
                    netIncomeAccToStatement: 0,
                    netIncomeMinusCleanup: 0,
                    adjustedNetIncome: 0,
                    position: i,
                }));
            }
        } else {
            while (this.data.length > 6) {
                this.data.removeAt(this.data.length - 1);
            }
        }

        this.dataSource = this.data.controls.map(control => control.value) as IDebitorIncomeEntries[];
        this.table.renderRows();
    }

    /**
     * save Income Data
     */
    public saveIncomeData(): void {
        if (!!this.financingContainerID && !!this.debitorID) {
            const saveData: IDebitorIncomeEntryData = {
                debitorID: this.debitorID,
                containerID: this.financingContainerID,
                incomeEntries: this.data.controls.map(control => control.value) as IDebitorIncomeEntries[],
            };

            this.financingService.postDebitorIncomeEntries(saveData).pipe(take(1)).subscribe({
                next: () => {
                    this.notificationService.toast(this.translate.instant('navigation.financing.borrowerCheck.calculationSaved'));
                    const netIncome = this.totalNetIncome;
                    this.dialogRef.close({ netIncome: netIncome });
                },
                error: () => {
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('navigation.financing.borrowerCheck.calculationError'));
                    this.dialogRef.close({});
                },
            });
        }
    }

    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
