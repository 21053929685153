import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { take, tap } from 'rxjs/operators';

/**
 * Einstiegskomponente
 */
@Component({
    selector: 'finprocess-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    public isLoading = true;

    /**
     * Konstruktor
     *
     * @param {Router} router Router-Injektor
     * @param {TranslateService} translateService TranslateService Injektor
     * @param {EnumTranslationService} enumTranslate EnumTranslationService Injektor
     */
    public constructor(private router: Router, private translateService: TranslateService, private enumTranslate: EnumTranslationService) {
        this.router.events.pipe(
            tap(() => {
                this.isLoading = false;
            }),
            take(1),
        ).subscribe();

        this.translateService.setDefaultLang('de');
        this.enumTranslate.setDefaultLang('de');

        this.translateService.use('de');
        this.enumTranslate.use('de');
    }
}
