export enum UsageType {
    /**
     * Eigennutzung (default)
     */
    SelfUsed,

    /**
     * Fremdnutzung in Form v. Vermietung/Verpachtung
     */
    NotSelfUsed,
}
