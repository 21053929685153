import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { JointHeadingType, LiabilityType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { LiabilitiesService } from 'app/modules/financing/data/services/liabilities/liabilities.service';
import { HelperService, UUID } from 'app/modules/shared';

import { IAssignLiability, IFinancingStateParentDefinition, IJointHeading, ILiabilityDebitor } from '../../../../data';
import { CreateJointheadingDialogComponent } from '../create-jointheading-dialog/create-jointheading-dialog.component';

/**
 * Assign Liability
 */
@Component({
    selector: 'finprocess-assign-liability-dialog',
    templateUrl: './assign-liability-dialog.component.html',
    styleUrls: ['./assign-liability-dialog.component.scss'],
})
export class AssignLiabilityDialogComponent implements OnInit {

    /**
     * Auflisting Liability Typen
     */
    public liabilityTypes: LiabilityType[] = [];

    /**
     * Auflistung Joint Heading Daten -> Debitornamen
     */
    public jointHeadingList: IJointHeading[] = [];

    /**
     * Joint Heading Typ
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public JointHeadingType = JointHeadingType;

    /**
     * Financing Container ID
     */
    public financingContainerID?: UUID;

    /**
     * selected data from dropdowns
     */
    public selectedLiabilityType?: LiabilityType;
    public jointHeadingID?: UUID;

    public liabilityAssigned: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Konstruktor
     * 
     * @param {MatDialog} dialog dialog
     * @param {WaiterService} waiterService waiterService
     * @param {LiabilitiesService} liabilityService liabilityService
     * @param {NotificationService} notificationService notificationService
     * @param {TranslateService}translate translate
     * @param {Store} store store
     * @param {UUID} unsortedLiabilityID id from unsorted liability
     */
    public constructor(private dialog: MatDialog, private waiterService: WaiterService, private liabilityService: LiabilitiesService,
        private notificationService: NotificationService, private translate: TranslateService, private store: Store,
        @Inject(MAT_DIALOG_DATA) public unsortedLiabilityID: UUID) { }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        this.liabilityTypes = (HelperService.getEnumArray(LiabilityType) as number[]);
        this.getAllJointHeadings();
    }

    /**
     * assign Liability
     *
     */
    public assignLiability() {

        const liabilityId = (this.unsortedLiabilityID as unknown as { liabilityID: string }).liabilityID;

        const assignLiabilityData: IAssignLiability = {
            liabilityId: liabilityId.toString(),
            jointHeadingId: this.jointHeadingID ?? '', //'A170EAB2-B0AF-4C2B-3254-08DB634F7FC7'
            type: this.selectedLiabilityType,
        };

        this.liabilityService.assignLiability(assignLiabilityData).subscribe({
            next: () => {
                this.liabilityAssigned.emit();
                this.waiterService.hide();
                this.notificationService.toast(this.translate.instant('financing.features.financing-processing.liabilities.assignedLiability'));
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.assignedLiabilityError'));
            },
        });
    }


    /**
     * TODO: get all Joint Headings -> könnte in Service ausgelagert werden, da auch in liablities component verwendet
     */
    public getAllJointHeadings() {
        this.jointHeadingList = [];
        this.waiterService.show();
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        if (financingContainerID) {
            this.liabilityService.getLiabilities(financingContainerID).subscribe({
                next: liabilities => {
                    this.jointHeadingList = liabilities?.jointHeadings.filter(jh => jh.type !== JointHeadingType.AGP) || [];
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.getLiabilitiesError'));
                },
            });
        }
    }

    /**
     * open Dialog to create a new Joint Heading
     */
    public openCreateNewJointHeadingDialog(): void {
        const dialogRef = this.dialog.open(CreateJointheadingDialogComponent, {
        });

        //reload joint headings after new jh was successfully added
        dialogRef.componentInstance.jointHeadingCreated.subscribe(() => {
            this.getAllJointHeadings();
        });
    }


    /**
     * get Debitor Names for Tooltip
     * 
     * @param {ILiabilityDebitor} debitors debitor names
     * @returns {string} tooltip text
     */
    public getDebitorsTooltip(debitors: ILiabilityDebitor[]): string {
        return debitors.map(debitor => debitor.name).join(', ');
    }
}
