/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
    AdditionalFacilities,
    AdditionalHeatingSystem,
    AnnexType,
    BasementConstruction,
    BasementUsage,
    BathroomFacilities,
    BuildingMaterialType,
    Collateralization,
    DocumentType,
    FlatOrientation,
    GarageFacilities,
    GarageLocation,
    HeatingSystem,
    HeatingSystemType,
    ObjectPurposeType,
    Orientation,
    OutsideFacilities,
    ParkingSpace,
    RealEstateType,
    RoofConstruction,
    Terrace,
    WintergardenType,
} from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { FinancingState, IDocument, IRealEstate, IRealEstateDetailDialogData, OverwriteValueClassType, RealEstateService, ValueStorageType } from 'app/modules/financing/data';
import { RealEstateState } from 'app/modules/financing/data/states/real-estate/real-estate.state';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { HelperService, IMultiSelectItem, ISelectItem } from 'app/modules/shared';
import { Observable, Subject, filter, map, of, takeUntil, tap } from 'rxjs';
/**
 *
 */
@Component({
    selector: 'finprocess-real-estate-detail',
    templateUrl: './real-estate-detail.component.html',
    styleUrls: ['./real-estate-detail.component.scss'],
})
export class RealEstateDetailComponent implements OnInit, OnDestroy {

    /**
     * Enum für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/member-ordering
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Einzelauswahl - Objektart
     */
    public objectTypeSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Zweck der Angabe
     */
    public objectPurposeSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Zur Besicherung
     */
    public collateralizationSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Überwiegendes Baumaterial
     */
    public buildingMaterialSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Keller
     */
    public basementSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Dachausbau
     */
    public roofConstructionSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Terrasse
     */
    public terraceSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Wintergarten
     */
    public winterGardenSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Nebengebäude
     */
    public annexTypeSelectItems: ISelectItem<string>[] = [];

    /**
     * Mehrfachauswahl - Stellplatz
     */
    public parkingSpaceSelectItems: IMultiSelectItem[] = [];

    /**
     * Einzelauswahl - Lage (Garage)
     */
    public garageLocationSelectItems: ISelectItem<string>[] = [];

    /**
     * Mehrfachauswahl - Ausstattung Garage
     */
    public garageFacilitiesSelectItems: IMultiSelectItem[] = [];

    /**
     * Einzelauswahl - Wohnzimmer (Orientierung)
     */
    public livingRoomOrientationSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Wohnung (Orientierung)
     */
    public flatOrientationSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Heizungssystem
     */
    public heatingSystemSelectItems: ISelectItem<string>[] = [];

    /**
     * Einzelauswahl - Heizungsmedium
     */
    public heatingSystemTypeSelectItems: ISelectItem<string>[] = [];

    /**
     * Mehrfachauswahl - Keller
     */
    public basementConstructionSelectItems: IMultiSelectItem[] = [];

    /**
     * Mehrfachauswahl - Keller
     */
    public mainBathroomFacilitiesSelectItems: IMultiSelectItem[] = [];

    /**
     * Mehrfachauswahl - Zusätzliche Heizungssysteme
     */
    public additionalHeatingSystemSelectItems: IMultiSelectItem[] = [];

    /**
     * Mehrfachauswahl - Ausstattung Außenbereich
     */
    public outsideFacilitiesSelectItems: IMultiSelectItem[] = [];

    /**
     * Mehrfachauswahl - Ausstattung
     */
    public additionalFacilitiesSelectItems: IMultiSelectItem[] = [];

    /**
     * Sichtbarkeit von Feldern
     */
    public visibilityMap?: IRealEstateVisibilityMap;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public fieldReadonly$ = of(true);

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Real Estates
     */
    public realEstate$!: Observable<IRealEstate>;

    public loading = true;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Konstruktor
     * 
     * @param {IRealEstateDetailDialogData} data Dialog-Daten
     * @param {RealEstateService} realEstateService RealEstateService
     * @param {Store} store Store-Injektor
     * @param {NotificationService} notification NotificationService
     * @param {TranslateService} translate TranslateService
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: IRealEstateDetailDialogData,
        private realEstateService: RealEstateService,
        private store: Store,
        private notification: NotificationService,
        private translate: TranslateService,
    ) {
    }
    /**
     * ngOnInit
     */
    public ngOnInit(): void {

        if (!this.data.realestateId) {
            throw new Error('No realestateId provided');
        }

        this.realEstate$ = this.store.select(RealEstateState.getRealEstateById).pipe(
            takeUntil(this.onDestroy$),
            map(selectorFn => selectorFn(this.data.realestateId)),
            filter((realEstate): realEstate is IRealEstate => realEstate !== undefined),
            tap(realEstate => {
                this.initEnumsAndVisibilityMap(realEstate);
            }),
        );

        this.loading = true;
        this.realEstateService.getRealEstateById(this.data.realestateId).subscribe({
            error: () => {
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
            },
            complete: () => {
                this.loading = false;
            },
        });

        this.documents$ = this.store.select(FinancingState.allDocuments).pipe(
            map(documents => (inputDocuments: IDocument[]) => inputDocuments.filter(document => documents.includes(document) && document.type === DocumentType.ConfirmationRealEstateInformationSignature)),
        );
    }

    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Aktualisiert Enums und Visibility Map
     *
     * @param {IRealEstate} realEstate Objekt
     */
    private initEnumsAndVisibilityMap(realEstate?: IRealEstate): void {
        if (realEstate === undefined) {
            return;
        }

        this.objectTypeSelectItems = HelperService.getSortedSelectItems(RealEstateType, value => RealEstateType.translate(value));
        this.objectPurposeSelectItems = HelperService.getSortedSelectItems(ObjectPurposeType, value => ObjectPurposeType.translate(value), [ObjectPurposeType.Finance]);
        this.collateralizationSelectItems = HelperService.getSortedSelectItems(Collateralization, value => Collateralization.translate(value));
        this.buildingMaterialSelectItems = HelperService.getSortedSelectItems(BuildingMaterialType, value => BuildingMaterialType.translate(value));
        this.basementSelectItems = HelperService.getSortedSelectItems(BasementUsage, value => BasementUsage.translate(value));
        this.initRoofConstructionSelectItems(realEstate);
        this.basementConstructionSelectItems = HelperService.getSortedMultiSelectItems(BasementConstruction, value => BasementConstruction.translate(value));
        this.terraceSelectItems = HelperService.getSortedSelectItems(Terrace, value => Terrace.translate(value));
        this.winterGardenSelectItems = HelperService.getSortedSelectItems(WintergardenType, value => WintergardenType.translate(value));
        this.annexTypeSelectItems = HelperService.getSortedSelectItems(AnnexType, value => AnnexType.translate(value));
        this.parkingSpaceSelectItems = HelperService.getSortedMultiSelectItemsByValue(ParkingSpace, value => ParkingSpace.translate(value));
        this.garageLocationSelectItems = HelperService.getSortedSelectItems(GarageLocation, value => GarageLocation.translate(value));
        this.garageFacilitiesSelectItems = HelperService.getSortedMultiSelectItems(GarageFacilities, value => GarageFacilities.translate(value));
        this.livingRoomOrientationSelectItems = HelperService.getSortedSelectItems(Orientation, value => Orientation.translate(value));
        this.flatOrientationSelectItems = HelperService.getSortedSelectItems(FlatOrientation, value => FlatOrientation.translate(value));
        this.initMainBathroomFacilitiesSelectItems(realEstate);
        this.heatingSystemSelectItems = HelperService.getSortedSelectItems(HeatingSystem, value => HeatingSystem.translate(value));
        this.heatingSystemTypeSelectItems = HelperService.getSortedSelectItems(HeatingSystemType, value => HeatingSystemType.translate(value));
        this.additionalHeatingSystemSelectItems = HelperService.getSortedMultiSelectItemsByValue(AdditionalHeatingSystem, value => AdditionalHeatingSystem.translate(value));
        this.initOutsideFacilitiesSelectItems(realEstate);
        this.initAdditionalFacilitiesSelectItems(realEstate);

        this.visibilityMap = this.updateVisibilityMap(realEstate, this.data.isNotAssetData);
    }

    /**
     * Aktualisiert die Sichtbarkeit von Feldern
     *
     * @param {IRealEstate} realEstate Objekt
     * @param {boolean} hideAssetData showDataForAssets
     * @returns {IRealEstateVisibilityMap} Visibility Map
     */
    // eslint-disable-next-line complexity
    private updateVisibilityMap(realEstate: IRealEstate, hideAssetData: boolean): IRealEstateVisibilityMap {
        const objectPurpose = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'objectPurpose', realEstate.objectPurpose);
        const type = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'type', realEstate.type);
        const underConstruction = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'underConstruction', realEstate.underConstruction);
        const areaBasement = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaBasement', realEstate.areaBasement);
        const areaTerrace = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaTerrace', realEstate.areaTerrace);
        const areaWinterGarden = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaWinterGarden', realEstate.areaWinterGarden);
        const areaOutbuildings = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaOutbuildings', realEstate.areaOutbuildings);
        const areaGarage = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaGarage', realEstate.areaGarage);
        const areaTopFloor = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'areaTopFloor', realEstate.areaTopFloor);

        const isHouse = this.isHouse(realEstate);
        const isFlat = this.isFlat(realEstate);
        const isLot = this.isLot(realEstate);

        return {
            objectPurpose: objectPurpose !== ObjectPurposeType.Finance,
            type: hideAssetData,
            propertyRegisterNumber: true,
            districtCourt: true,
            lotNumber: true,
            collateralization: true,
            willBeRentedOut: objectPurpose === ObjectPurposeType.Finance,
            lotSize: isHouse || isLot,
            energyIndex: !isLot,
            floor: (isFlat || type === RealEstateType.SemiDetachedHouse) && hideAssetData,
            landRegisterNumber: type !== RealEstateType.SemiDetachedHouse,

            street: hideAssetData,
            streetNumber: hideAssetData,
            zip: hideAssetData,
            city: hideAssetData,
            stairway: isFlat && hideAssetData,
            top: isFlat && hideAssetData,

            validatedStreet: true,
            validatedStreetNumber: true,
            validatedZip: true,
            validatedCity: true,

            showConstruction: !isLot,
            underConstruction: !isLot,
            yearOfConstruction: underConstruction !== undefined && !underConstruction,
            startOfConstruction: underConstruction !== undefined && underConstruction,
            endOfConstruction: underConstruction !== undefined && underConstruction,
            precastConstruction: isHouse && type !== RealEstateType.Other,
            buildingMaterial: isHouse && type !== RealEstateType.Other,

            // Flächen
            showArea: !isLot,
            netRoomArea: isFlat,
            areaBasement: !isLot,
            areaGroundFloor: isHouse,

            // Flächen weitere Geschosse
            showAdditionalAreas: !isLot,
            areaUpperFloor: isHouse,
            areaAdditionalUpperFloor: isHouse,
            areaTopFloor: isHouse,
            areaLoggia: !isLot,
            areaRoofTerrace: isFlat,
            areaBalcony: !isLot,
            areaGarden: isFlat,
            areaTerrace: isHouse,
            areaWinterGarden: isHouse,
            areaGarage: isHouse,
            areaOutbuildings: isHouse,

            // Keller
            showBasement: !!areaBasement && isHouse,
            basementUsage: !!areaBasement && isHouse,
            basementConstruction: !!areaBasement && isHouse,

            // Dachgeschoss
            showRoof: isFlat || (isHouse && !!areaTopFloor),
            roofConstruction: !isLot,

            // Nutzbarkeit/Lage
            showLocation: isHouse,
            terrace: !!areaTerrace && isHouse,
            winterGardenType: !!areaWinterGarden && isHouse,
            annexType: !!areaOutbuildings && isHouse,

            // Garage
            showGarage: isFlat || (isHouse && !!areaGarage),
            parkingSpace: isFlat || type === RealEstateType.SemiDetachedHouse,
            garageLocation: !!areaGarage && isHouse,
            garageFacilities: !!areaGarage && isHouse,

            // Außen und Innensanierung
            showRefurbishment: isHouse || isFlat,
            refurbishmentInnerBuildungStructure: !isLot,
            refurbishmentRoof: !isLot,
            refurbishmentOuterBuildingStructure: !isLot,
            refurbishmentCommonArea: isFlat,
            refurbishmentWindow: !isLot,
            refurbishmentHeating: !isLot,
            refurbishmentElectricSanitary: !isLot,
            refurbishmentWallpapering: !isLot,
            refurbishmentInnerDoorsStairsFloorCovering: !isLot,

            // Orientierung
            showOrientation: !isLot,
            livingRoomOrientation: !isLot,
            flatOrientation: isFlat,

            // Badezimmer
            showBathroom: !isLot,
            bathroomCount: !isLot,
            mainBathroomFacilities: !isLot,

            // Heizung
            showHeating: !isLot,
            heatingSystem: !isLot,
            heatingSystemType: !isLot,
            additionalHeatingSystem: !isLot,

            // Ausstattung
            showFacilities: !isLot,
            outsideFacilities: !isLot,
            additionalFacilities: !isLot,

            showMarketValue: hideAssetData,
            marketValue: hideAssetData,
            liebeLightId: hideAssetData,
        }
    }


    /**
     * set roofConstructionSelectItems
     *
     * @param {IRealEstate} realEstate Objekt
     */
    private initRoofConstructionSelectItems(realEstate: IRealEstate): void {
        const isFlat = this.isFlat(realEstate);

        this.roofConstructionSelectItems = HelperService.getSortedSelectItems(
            RoofConstruction,
            value => RoofConstruction.translate(value),
            isFlat ? [RoofConstruction.Expandable, RoofConstruction.NoResidentalUse, RoofConstruction.ResidentialUse] : [],
        );
    }

    /**
     * set mainBathroomFacilitiesSelectItems
     *
     * @param {IRealEstate} realEstate Objekt
     */
    private initMainBathroomFacilitiesSelectItems(realEstate: IRealEstate): void {
        const isHouse = this.isHouse(realEstate);
        const isFlat = this.isFlat(realEstate);

        let removeValues: number[] = [];

        if (isFlat) {
            removeValues = [BathroomFacilities.BasinInSeparateToilet, BathroomFacilities.Bidet, BathroomFacilities.DoubleBasin];
        } else if (isHouse) {
            removeValues = [BathroomFacilities.WindowOpenable];
        }

        this.mainBathroomFacilitiesSelectItems = HelperService.getSortedMultiSelectItemsByValue(
            BathroomFacilities,
            value => BathroomFacilities.translate(value),
            removeValues,
        );
    }

    /**
     * set outsideFacilitiesSelectItems
     *
     * @param {IRealEstate} realEstate Objekt
     */
    private initOutsideFacilitiesSelectItems(realEstate: IRealEstate): void {
        const isHouse = this.isHouse(realEstate);
        const isFlat = this.isFlat(realEstate);

        let removeValues: number[] = [];

        if (isFlat) {
            removeValues = [OutsideFacilities.GardenNotVisible, OutsideFacilities.GardenWatering, OutsideFacilities.RetainingWalls];
        } else if (isHouse) {
            removeValues = [OutsideFacilities.CommonGarden];
        }

        this.outsideFacilitiesSelectItems = HelperService.getSortedMultiSelectItemsByValue(
            OutsideFacilities,
            value => OutsideFacilities.translate(value),
            removeValues,
        );
    }

    /**
     * set additionalFacilitiesSelectItems
     *
     * @param {IRealEstate} realEstate Objekt
     */
    // eslint-disable-next-line complexity
    private initAdditionalFacilitiesSelectItems(realEstate: IRealEstate): void {
        const isHouse = this.isHouse(realEstate);
        const isFlat = this.isFlat(realEstate);

        let removeValues: number[] = [];

        if (isFlat) {
            removeValues = [AdditionalFacilities.PhotovoltaicSystem, AdditionalFacilities.WoodWindows,
                AdditionalFacilities.HighQualityInnerDoors, AdditionalFacilities.ElectricBlinds, AdditionalFacilities.ElectricGarageDoor];
        } else if (isHouse) {
            removeValues = [AdditionalFacilities.Elevator];
        }


        this.additionalFacilitiesSelectItems = HelperService.getSortedMultiSelectItemsByValue(
            AdditionalFacilities,
            value => AdditionalFacilities.translate(value),
            removeValues,
        );
    }

    /**
     * Prüft ob es sich um eine Art Wohnung handelt
     * Eigentumswohnung oder Vorsorgewohnung
     *
     * @param {IRealEstate} realEstate Objekt
     * @returns {boolean} Ob es sich um eine Wohnung handelt
     */
    private isFlat(realEstate: IRealEstate): boolean {
        const type = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'type', realEstate.type);
        return type === RealEstateType.Flat || type === RealEstateType.InvestmentFlat;
    }

    /**
     * Prüft ob es sich um eine Art von Haus handelt
     * EFH, RH, MFH, DHH oder Sonstiges
     *
     * @param {IRealEstate} realEstate Objekt
     * @returns {boolean} Ob es sich um ein Haus handelt
     */
    private isHouse(realEstate: IRealEstate): boolean {
        const type = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'type', realEstate.type);
        return type === RealEstateType.DetachedHouse ||
            type === RealEstateType.SemiDetachedHouse ||
            type === RealEstateType.ApartmentHouse ||
            type === RealEstateType.RowHouse ||
            type === RealEstateType.Other;
    }

    /**
     * Prüft ob es sich um ein Grundstück handelt
     *
     * @param {IRealEstate} realEstate Objekt
     * @returns {boolean} Ob es ein Grundstück ist
     */
    private isLot(realEstate: IRealEstate): boolean {
        const type = OverwriteHelperService.getMergedOverwriteValue(realEstate, 'type', realEstate.type);
        return type === RealEstateType.Lot;
    }


}

interface IRealEstateVisibilityMap {
    // Objektangaben
    objectPurpose: boolean;
    type: boolean;
    propertyRegisterNumber: boolean;
    districtCourt: boolean;
    lotNumber: boolean;
    collateralization: boolean;
    willBeRentedOut: boolean;
    lotSize: boolean;
    energyIndex: boolean;
    floor: boolean;
    landRegisterNumber: boolean;

    // Adresse
    street: boolean;
    streetNumber: boolean;
    zip: boolean;
    city: boolean;
    stairway: boolean;
    top: boolean;

    validatedStreet: boolean;
    validatedStreetNumber: boolean;
    validatedZip: boolean;
    validatedCity: boolean;

    // Baujahr
    showConstruction: boolean;
    underConstruction: boolean;
    yearOfConstruction: boolean;
    startOfConstruction: boolean;
    endOfConstruction: boolean;
    precastConstruction: boolean;
    buildingMaterial: boolean;

    // Flächen
    showArea: boolean;
    netRoomArea: boolean;
    areaBasement: boolean;
    areaGroundFloor: boolean;

    // Flächen weitere Geschosse
    showAdditionalAreas: boolean;
    areaUpperFloor: boolean;
    areaAdditionalUpperFloor: boolean;
    areaTopFloor: boolean;
    areaLoggia: boolean;
    areaRoofTerrace: boolean;
    areaBalcony: boolean;
    areaGarden: boolean;
    areaTerrace: boolean;
    areaWinterGarden: boolean;
    areaGarage: boolean;
    areaOutbuildings: boolean;

    // Keller
    showBasement: boolean;
    basementUsage: boolean;
    basementConstruction: boolean;

    // Dachgeschoss
    showRoof: boolean;
    roofConstruction: boolean;

    // Nutzbarkeit/Lage
    showLocation: boolean;
    terrace: boolean;
    winterGardenType: boolean;
    annexType: boolean;

    // Garage
    showGarage: boolean;
    parkingSpace: boolean;
    garageLocation: boolean;
    garageFacilities: boolean;

    // Außen und Innensanierung
    showRefurbishment: boolean;
    refurbishmentInnerBuildungStructure: boolean;
    refurbishmentRoof: boolean;
    refurbishmentOuterBuildingStructure: boolean;
    refurbishmentCommonArea: boolean;
    refurbishmentWindow: boolean;
    refurbishmentHeating: boolean;
    refurbishmentElectricSanitary: boolean;
    refurbishmentWallpapering: boolean;
    refurbishmentInnerDoorsStairsFloorCovering: boolean;

    // Orientierung
    showOrientation: boolean;
    livingRoomOrientation: boolean;
    flatOrientation: boolean;

    // Badezimmer
    showBathroom: boolean;
    bathroomCount: boolean;
    mainBathroomFacilities: boolean;

    // Heizung
    showHeating: boolean;
    heatingSystem: boolean;
    heatingSystemType: boolean;
    additionalHeatingSystem: boolean;

    // Ausstattung
    showFacilities: boolean;
    outsideFacilities: boolean;
    additionalFacilities: boolean;

    showMarketValue: boolean;
    marketValue: boolean;
    liebeLightId: boolean;

}


