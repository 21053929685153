import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_COUNTRY_CODES_MODEL } from './customer-country-codes-model ';
import { CUSTOMER_INFORMATION_MODEL } from './customer-information-model';
import { CUSTOMER_NATIONALITY_MODEL } from './customer-nationality-model';
import { CUSTOMER_NATURAL_PERSON_MODEL } from './customer-natural-person-model';
import { CUSTOMER_PERSONAL_DATA_MODEL } from './customer-personal-data-model';

export const CUSTOMER_COMPLEMENTARY_DATA_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerComplementaryDataModel',

    countryCodes: { type: WorkflowType.Object, child: CUSTOMER_COUNTRY_CODES_MODEL },

    customerInformation: { type: WorkflowType.Object, child: CUSTOMER_INFORMATION_MODEL },

    nationality: { type: WorkflowType.Object, child: CUSTOMER_NATIONALITY_MODEL },

    naturalPerson: { type: WorkflowType.Object, child: CUSTOMER_NATURAL_PERSON_MODEL },

    personalData: { type: WorkflowType.Object, child: CUSTOMER_PERSONAL_DATA_MODEL },
}
