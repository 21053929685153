<finprocess-hint *ngIf="!!dataErrors" type="error">
    <span header>{{'financing.features.financing-processing.risk-decision.loadRequestError' | translate}}</span>
    <ul content>
       <li *ngFor="let error of dataErrors">{{error}}</li> 
    </ul>
</finprocess-hint>
<ng-container *ngIf="!dataErrors">
    <div fxFlexFill fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start stretch" class="content">
            <div fxFlex="50">
                <div class="p-3 request-area" [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
                    <ng-container *ngIf="!!form">
                        <ng-container *ngTemplateOutlet="WorkflowForm; context:{form: form, model: model}">
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div fxFlex="50" class="p-3 response-area">
    
                <h3 class="titles"><b>{{'financing.features.financing-processing.risk-decision.fireResult' | translate}}</b>
                </h3>
    
                <div *ngIf="fireResponse; else noFireResponse">
                    <mat-list>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.fireID' |
                                    translate}}</span>
                                <span value>{{fireResponse.proposalId}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.belq' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiBelq}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.dsti' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiDsti}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.eifa' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiEifa}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.el' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiEl | formatCurrency}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.lti' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiLti}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.ltv' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiLtv}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.kimv' |
                                    translate}}</span>
                                <span value>{{fireResponse.kpiKimVRelSchuldq}}%</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.reasonCode' |
                                    translate}}</span>
                                <span value>{{fireResponse.reasonCode | finprocessEnumTranslation:'ReasonCode'}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.reasonCodeText' |
                                    translate}}</span>
                                <span value>{{fireResponse.ergTextStatusFinal}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.addCode' |
                                    translate}}</span>
                                <span value>{{fireResponse.ergAdditionalCode | finprocessEnumTranslation:'AdditionalCode'}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.addCodeText' |
                                    translate}}</span>
                                <span value>{{fireResponse.ergTextAdditionalStatus}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.totalCommitment' |
                                    translate}}</span>
                                <span value>{{fireResponse.totalCommitment | formatCurrency}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                        <mat-list-item>
                            <finprocess-label-value>
                                <span label>{{'financing.features.financing-processing.risk-decision.totalCommitmentNoGuranatee' |
                                    translate}}</span>
                                <span value>{{fireResponse.totalCommitmentNoGuarantee | formatCurrency}}</span>
                            </finprocess-label-value>
                        </mat-list-item>
                    </mat-list>
    
                    <div *ngIf="!!fireDocumentData" class="mt-4 ml-3">
                        <h3 class="titles"><b>FIRE Dokument</b></h3>
                        <div class="mb-3 uploaded-pdf" fxLayout="row">
                            <mat-icon class="mr-1 document-icon">description</mat-icon>
                            <div class="mr-3">{{fireDocumentData.fileName}}.pdf</div>
                            <button mat-icon-button [disabled]="(fieldReadonly$ | async) ?? true"
                                (click)="downloadFireDocument()">
                                <mat-icon class="mr-2 icons mb-4" matTooltip="Öffnen">open_in_new</mat-icon>
                            </button>
                        </div>
                    </div>
    
                    <button mat-raised-button color="primary" class="mt-5" style="float:right; display: flex;"
                        (click)="switchToApproval()"
                        [disabled]="(fieldReadonly$ | async) ?? true">{{'financing.features.financing-processing.risk-decision.approvalButton'
                        | translate}}
                    </button>
    
                </div>
                <ng-template #noFireResponse>
                    <small class="no-response">{{'financing.features.financing-processing.risk-decision.noFireResponse' |
                        translate}}</small>
                </ng-template>
            </div>
        </div>
        <button [disabled]="form?.invalid || (form?.dirty && form?.invalid) || (fieldReadonly$ | async) ?? true" fxFlex="50"
            class="mt-5 .btn-flex-none" style="width: fit-content; margin-left: 15%;" mat-raised-button color="primary"
            (click)="saveForm()" [disabled]="form?.invalid" matTooltip="Formular absenden">
            {{'financing.features.financing-processing.risk-decision.decisionRequest' | translate}}
        </button>
    </div>
    
    <ng-template #WorkflowForm let-mform="form" let-model="model">
        <ng-container *ngFor="let key of getKeys(model)">
            <div fxLayout="column">
                <ng-container
                    *ngTemplateOutlet="WorkflowControl; context:{control: mform.controls[key], model: model[key], key: key, translateKey: model.midtermName }">
    
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
    
    <ng-template #WorkflowControl let-control="control" let-key="key" let-model="model" let-translateKey="translateKey"
        let-className="className">
        <ng-container [ngSwitch]="model.type">
            <mat-form-field *ngSwitchCase="WorkflowType.String" appearance="outline" floatLabel="always"
                [class]="className" [matTooltip]="'workflow.' + globalTranslateKey + translateKey + '.' + key | translate" matTooltipPosition="right">
                <mat-label>
                    {{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}
                </mat-label>
                <input matInput [formControl]="control">
                <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
            </mat-form-field>
    
            <mat-form-field *ngSwitchCase="WorkflowType.Number" appearance="outline" floatLabel="always"
                [class]="className" [matTooltip]="'workflow.' + globalTranslateKey + translateKey + '.' + key | translate" matTooltipPosition="right">
                <mat-label>
                    {{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}
                </mat-label>
    
                <input *ngIf="model.formattingType === FormattingType.Currency" matInput [formControl]="control"
                    currencyMask [options]="currencyMaskOptions">
                <input *ngIf="model.formattingType === FormattingType.Percentage" matInput [formControl]="control"
                    currencyMask [options]="percentageMaskOptions">
                <input *ngIf="model.formattingType === FormattingType.Number || model.formattingType === undefined" matInput [formControl]="control"
                    type="number">
    
                <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
            </mat-form-field>
    
            <mat-form-field *ngSwitchCase="WorkflowType.Enum" appearance="outline" floatLabel="always" [class]="className">
                <mat-label>
                    {{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}
                </mat-label>
                <mat-select [formControl]="control">
                    <mat-option *ngFor="let enumValue of getEnumArray(model.enumObject)" [value]="enumValue">
                        {{ 'workflow.' + globalTranslateKey + model.enumLabel + '.' + enumValue | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
            </mat-form-field>
            <mat-checkbox *ngSwitchCase="WorkflowType.Boolean" color="primary" [formControl]="control" class="mb-2">{{
                'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}</mat-checkbox>
            <div *ngSwitchCase="WorkflowType.Object">
                <finprocess-sub-header
                    label="{{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}">
                </finprocess-sub-header>
                <ng-container *ngTemplateOutlet="WorkflowForm; context:{ form: control, model: model.child}">
                </ng-container>
            </div>
            <div *ngSwitchCase="WorkflowType.Array">
                <div *ngIf="control.controls.length === 0" fxLayout="row" fxLayoutAlign="start center">
                    <finprocess-sub-header
                        label="{{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}">
                    </finprocess-sub-header>
                    <button mat-icon-button color="primary" (click)="addToFormArray(control, model.child)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <ng-container *ngFor="let subControl of control.controls; let i = index">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <finprocess-sub-header
                            label="{{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}: {{ i + 1 }}">
                        </finprocess-sub-header>
                        <button mat-icon-button color="primary" (click)="addToFormArray(control, model.child)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" (click)="removeFromArray(control, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="isPrimitiveArray(model.child)">
                        <ng-container
                            *ngTemplateOutlet="WorkflowControl; context:{ control: subControl, key: key, model: { type: model.child, enumObject: model.enumObject, enumLabel: model.enumLabel }, translateKey: translateKey, className: 'array' }">
                        </ng-container>
                    </div>
                    <ng-container *ngIf="!isPrimitiveArray(model.child)">
                        <ng-container *ngTemplateOutlet="WorkflowForm; context:{ form: subControl, model: model.child}">
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
    
            <div *ngSwitchCase="WorkflowType.Date">
    
                <mat-form-field appearance="outline" floatLabel="always" matTooltipPosition="right" [matTooltip]="'workflow.' + globalTranslateKey + translateKey + '.' + key | translate" class="w-100">
                    <mat-label>{{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}
                    </mat-label>
                    <input *ngIf="!!control" matInput [formControl]="control" (focus)="dateinput_key.open()" [matDatepicker]="dateinput_key">
                    <mat-datepicker-toggle matSuffix [for]="dateinput_key"></mat-datepicker-toggle>
                    <mat-datepicker #dateinput_key></mat-datepicker>
                </mat-form-field>
                <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
                <hr class="custom-hr">
            </div>
    
            <div *ngSwitchDefault>
                Feld {{ translateKey + '.' + key }} konnte nicht angezeigt werden
            </div>
        </ng-container>
    </ng-template>
</ng-container>