<div *ngIf="(financing$ | async) as financing" class="p-3">
  <!-- Kreditantrag -->
  <finprocess-sub-header class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    [label]="'financing.features.financing-processing.process.loanApplication' | translate">
  </finprocess-sub-header>

  <finprocess-select-input *ngIf="!isLoading"
    [label]="'financing.features.financing-processing.process.firstCheck' | translate"
    [items]="applicationDecisionSelectItems" [valueStorageType]="ValueStorageType.Int" [entity]="financing"
    fieldName="applicationDecision" [overwrite]="false" [entityClassType]="entityClassType.FinProcessContainer">
  </finprocess-select-input>

  <!-- Fall Kreditantrag abgelehnt -->
  <div *ngIf="!isLoading">
    <ng-container *ngIf="financing.applicationDecision === ApplicationDecisionType.Rejected">
      <p class="pt-1 pb-2">{{'financing.features.financing-processing.process.rejectionLetterTitle' | translate}}</p>
      <form [formGroup]="rejectionForm" #form="ngForm">
        <div class="editor-container">
          <ckeditor [editor]="EditorRejection" formControlName="rejection">
          </ckeditor>
          <button mat-fab (click)="deleteContent()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container
      *ngIf="financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityPF || financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityGK">
      <form [formGroup]="formResponsibility" #form2="ngForm">
        <label class="label">{{'financing.features.financing-processing.process.comment' | translate}}</label>
        <ckeditor [editor]="Editor" formControlName="additionalInformationResponsibility" (change)="true">
        </ckeditor>
      </form>
    </ng-container>
    <mat-dialog-actions align="end">
      <button
        *ngIf="financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityPF || financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityGK"
        (click)="changeResponsibility(financing.applicationDecision)" mat-flat-button color="primary"
        [disabled]="formResponsibility.invalid">
        {{ 'financing.features.financing-processing.process.rejectApplication' | translate }}
      </button>
      <button mat-raised-button color="primary"
        *ngIf="financing.applicationDecision === ApplicationDecisionType.Rejected" [disabled]="rejectionForm.invalid"
        (click)="sendRejection()">
        {{'financing.features.financing-processing.process.sendRejection' | translate}}
      </button>
      <button mat-raised-button [mat-dialog-close]="true">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div class="waiter w-100" *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
  <waiter-component [isLoading]="isLoading"></waiter-component>
</div>