<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <div fxLayout="column">
            <label [for]="id">
                <span *ngIf="!!label"></span>{{label}}
                <ng-content *ngIf="!label" select="[label]"></ng-content>
            </label>
            <small *ngIf="!!hint"><i>{{hint}}</i></small>
        </div>
        <mat-icon class="ml-1" *ngIf="overwrite && originalValue !== currentValue"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (originalValue !== undefined ? (originalValue | number:format:locale) : ( 'financing.features.financing-processing.unset' | translate)) }">
            info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" [fxLayoutAlign]="fxValueLayoutAlign"
        fxLayoutAlign.lt-sm="start center">
        <mat-form-field class="field-color slim-fella" appearance="fill" fxFlex="100">
            <input matInput
            currencyMask
            [options]="options"
            [ngModel]="currentValue"
            (ngModelChange)="save($event)"
            [ngModelOptions]="{updateOn: 'blur'}"
            [disabled]="(readonly | async) ?? false"
            [lang]="locale"
            [required]="isRequired"/>
        </mat-form-field>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr">
</div>
