<div class="finprocess-stepper py-2" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngFor="let step of steps; let i = index">
        <div fxLayout="row" fxLayoutAlign="start center" class="finprocess-stepper-step"
            [routerLink]="step.route" routerLinkActive="active" [class.disabled]="step.disabled"
            [queryParams]="step.queryParams"
            (click)="selectedStepIndexChanged.emit(i)">
            <div class="finprocess-stepper-bauble-container">
                <div class="finprocess-stepper-bauble" [ngClass]="{
                    'success': step.status === 'success',
                    'error': step.status === 'error',
                    'warn': step.status === 'warn',
                    'pending': step.status === 'pending'
                }">
                    {{i+1}}
                </div>
                <mat-icon *ngIf="step.status === 'success'" class="finprocess-stepper-bauble-icon success">check_circle</mat-icon>
                <mat-icon *ngIf="step.status === 'warn'" class="finprocess-stepper-bauble-icon warn">error</mat-icon>
                <mat-icon *ngIf="step.status === 'pending'" class="finprocess-stepper-bauble-icon pending">pending</mat-icon>
                <mat-icon *ngIf="step.status === 'error'" class="finprocess-stepper-bauble-icon error">cancel</mat-icon>
            </div>
            <span class="finprocess-stepper-label">
                {{ step.label }}
            </span>
        </div>
    </ng-container>
</div>
