/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Übersetzt den Status einer Filiale
 */
@Pipe({
    name: 'finProcessBranchStatus',
})
export class BranchStatusPipe implements PipeTransform {

    /**
     * Übersetz den Status abhängig von der Statuskombination
     *
     * @param {boolean} disabled Status der Filiale
     * @returns {string} Status der Filiale
     */
    public transform(disabled: boolean): string {
        if (disabled === undefined || disabled === null) {
            return '';
        }

        return disabled ? 'Deaktiviert' : 'Aktiv';
    }

}
