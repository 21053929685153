import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MandantType, ProductPackageType } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { DocumentService, FinancingService, IDocument, IEsisForProducts, IFile, IFinancingStateParentDefinition, IFinprocessContainer } from 'app/modules/financing/data';
import { FinancingEventType, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { FinancingStatus, FinancingSubStatus, HelperService, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, filter, map, mergeMap, of, shareReplay, take, takeUntil, tap } from 'rxjs';

interface IEsisResponse {
    response?: string;
    status: FinancingStatus.Finalize | FinancingStatus.Completed;
}

/**
 * Angepasstes Interface für Anzeige in der View
 */
interface IProductWithDocuments {
    id: UUID;
    name: string;
    productPackageId: UUID;
    productType: ProductPackageType;
    esis: IDocument[];
    contractDrafts: IDocument[];
    editTitle: boolean;
    buttonsVisible: boolean;
}

/**
 * Komponente zum Anzeigen und Hochladen von ESIS und Kreditvertrag
 */
@Component({
    selector: 'finprocess-esis',
    templateUrl: './esis.component.html',
    styleUrls: ['./esis.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.3s', style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class EsisComponent implements OnInit, OnDestroy {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DocumentType = DocumentType;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingStatus = FinancingStatus;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingSubStatus = FinancingSubStatus;

    /**
     *  Handelt es sich um einen FinService Fall
     */
    public isFinService$: Observable<boolean> = new Observable<boolean>();

    /**
     * Ist der Fall in einem Status in dem das Esis sichtbar ist
     */
    public isEsisVisible$: Observable<boolean> = new Observable<boolean>();

    /**
     * Ist der Fall in einem Status in dem das ESIS bearbeitet werden kann
     * Beachtet nur den Status, für die Anzeige von Bereichen
     */
    public isEsisEditable$: Observable<boolean> = new Observable<boolean>();

    /**
     * Der letzte Status, der für die ESIS Response relevant ist
     */
    public esisResponse$: Observable<IEsisResponse | undefined> = new Observable<IEsisResponse | undefined>();

    /**
     * Wurde der Fall mit dem neuen oder alten Prozess bearbeitet
     */
    public isNewCase = false;
    
    /**
     * Liste der Produkte mit ihren Dokumenten mit einem für die View angepassten Interface
     */
    public productsWithDocuments: IProductWithDocuments[] = [];

    /**
     * Ladezustand der Gesamtansicht
     */
    public loading = false;

    /**
     * Variable die beim Klick auf einen Upload Bereich gesetzt wird
     */
    public lastSelectedDocumentType?: DocumentType;

    /**
     * Variable die beim Klick auf einen Upload Bereich gesetzt wird
     */
    public lastSelectedProductId?: UUID;

    /**
     * Ist der Button zum Versenden der Dokumente aktiviert
     */
    public sendEnabled = false;

    /**
     * Bearbeitungszustand der Komponente
     */
    public readonly$ = new BehaviorSubject<boolean>(true);

    /**
     * Subject zum Beenden aller Subscriptions
     */
    private onDestroy$: Subject<void> = new Subject<void>();

    /**
     * ID des Finanzierungscontainers
     */
    private financingContainerId?: UUID;

    /**
     * ID des Produktpakets
     */
    private productPackageId?: UUID;

    /**
     * Aktueller Finprocesscontainer
     */
    private financingContainer$: Observable<IFinprocessContainer> = new Observable<IFinprocessContainer>();

    /**
     * Standardkonstruktor
     * 
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {Store} store Store-Injektor
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     */
    public constructor(
        private financingService: FinancingService,
        private documentService: DocumentService,
        private activatedRoute: ActivatedRoute,
        private notification: NotificationService,
        private waiterService: WaiterService,
        private translate: TranslateService,
        private cRef: ChangeDetectorRef,
        private store: Store,
        private informationEventService: InformationEventService,
    ) {}

    /**
     * Angular Lifecycle Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.financingContainer$ = this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer).pipe(
            takeUntil(this.onDestroy$),
            filter((finprocessContainer): finprocessContainer is IFinprocessContainer => finprocessContainer !== undefined),
            shareReplay(1),
        );

        this.isFinService$ = this.financingContainer$.pipe(
            takeUntil(this.onDestroy$),
            map(finprocessContainer => finprocessContainer.mandantType === MandantType.BAF),
            distinctUntilChanged(),
        );

        this.isEsisVisible$ = this.financingContainer$.pipe(
            takeUntil(this.onDestroy$),
            map(finprocessContainer => 
                (finprocessContainer.status === FinancingStatus.Finalize && 
                    (finprocessContainer.subStatus === FinancingSubStatus.Manual || finprocessContainer.subStatus === FinancingSubStatus.RetrievedByColtApplication || finprocessContainer.subStatus === FinancingSubStatus.EsisRejected))
                || finprocessContainer.status === FinancingStatus.Completed || finprocessContainer.status === FinancingStatus.EsisWaitingForAcception),
            distinctUntilChanged(),
        );

        this.isEsisEditable$ = this.financingContainer$.pipe(
            takeUntil(this.onDestroy$),
            map(finprocessContainer => finprocessContainer.status === FinancingStatus.Finalize &&
                (finprocessContainer.subStatus === FinancingSubStatus.Manual || finprocessContainer.subStatus === FinancingSubStatus.RetrievedByColtApplication || finprocessContainer.subStatus === FinancingSubStatus.EsisRejected)),
        );

        this.esisResponse$ = this.financingContainer$.pipe(
            takeUntil(this.onDestroy$),
            map<IFinprocessContainer, IEsisResponse | undefined>(finprocessContainer => {
                const lastStatus = sort(finprocessContainer.statusEntries).desc(it => it.created).find(status => (status.status === FinancingStatus.Finalize && status.subStatus === FinancingSubStatus.EsisRejected) || status.status === FinancingStatus.Completed);

                if (lastStatus && (lastStatus.status === FinancingStatus.Completed || lastStatus.status === FinancingStatus.Finalize)) {
                    return {
                        response: finprocessContainer.esisResponse,
                        status: lastStatus.status,
                    };
                }

                return undefined;
            }),
        );

        combineLatest([this.isEsisEditable$, this.financingService.editingReadonlyWithEditmodeExpert$, this.financingService.editingReadonlyWithEditmodeReferent$]).pipe(
            takeUntil(this.onDestroy$),
            map(([isEsisEditable, editmodeExpert, editmodeReferent]) => {
                if (!isEsisEditable) {
                    return true;
                }

                return editmodeExpert && editmodeReferent;
            }),
            distinctUntilChanged(),
        ).subscribe(readonly => this.readonly$.next(readonly));

        combineLatest([
            this.activatedRoute.params.pipe(
                map(params => params['financingContainerId'] as UUID),
                distinctUntilChanged(),
                filter((id): id is UUID => id !== undefined),
            ),
            this.isEsisVisible$.pipe(filter((isEsisStatus): isEsisStatus is true => isEsisStatus)),
        ]).pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(([financingContainerIdPrev, isEsisStatusPrev], [financingContainerIdCurr, isEsisStatusCurr]) => financingContainerIdPrev === financingContainerIdCurr && isEsisStatusPrev === isEsisStatusCurr),
            tap(() => {this.loading = true;}),
            mergeMap(([financingContainerId, isEsisStatus]) => {
                this.financingContainerId = financingContainerId;

                if (isEsisStatus) {
                    return this.documentService.getExistingEsisDocumentsForProducts(financingContainerId);
                }

                return of(undefined);
            }),
            filter((esisForProducts): esisForProducts is IEsisForProducts => esisForProducts !== undefined),
        ).subscribe({
            next: esisForProducts => {
                this.loading = false;
                this.mapEsisDocuments(esisForProducts);
            },
            error: () => {this.loading = false;},
        });
        
    }

    /**
     * Angular Lifecycle Hook beim Zerstören der Komponente.
     * Beendet alle Subscriptions.
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Öffnet eine Datei zum Ansehen
     *
     * @param {IFile} file Zu öffnende Datei
     * @param {'Download' | 'Open'} action Aktion
     */
    public onDocumentAction(file: IFile, action: 'Download' | 'Open'): void {
        this.documentService.loadFile(file.id).subscribe({
            next : async fileContent => {
                const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);
                if (blob) {
                    if (action === 'Download') {
                        await HelperService.downloadFileFromBlob(blob, file.name);
                    } else {
                        await HelperService.openFileFromBlob(blob);
                    }
                }
            },
        });
    }

    //#region Requests

    /**
     * Nur für den alten Prozess!
     * Fügt ein neues Produkt hinzu, damit weitere Esis und Kreditverträge hochgeladen werden können
     */
    public addNewProduct(): void {
        const financingContainerId = this.financingContainerId;
        const productPackageId = this.productPackageId;

        if (!financingContainerId || !productPackageId) {
            return; 
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.addNewProduct({
                productPackageId,
                productCreditType: ProductPackageType.Credit,
            })),
            mergeMap(() => this.documentService.getExistingEsisDocumentsForProducts(financingContainerId)),
            filter((esisForProducts): esisForProducts is IEsisForProducts => esisForProducts !== undefined),
        ).subscribe({
            next: esisForProducts => {this.waiterService.hide(); this.mapEsisDocuments(esisForProducts)},
            error: () => this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.esis.addProductError')),
        })
    }

    /**
     * Nur für den alten Prozess!
     * Löscht ein Produkt und alle zugehörigen Dokumente
     * 
     * @param {IProductWithDocuments} product Produkt, welches gelöscht werden soll
     */
    public deleteProduct(product: IProductWithDocuments): void {
        const financingContainerId = this.financingContainerId;

        if (!financingContainerId) {
            return; 
        }

        this.notification.confirmYesNo(this.translate.instant('general.attention'), 'Sollen diese Dokumente wirklich gelöscht werden?').pipe(
            mergeMap(result => {
                if (result === 'submit') {
                    return this.waiterService.show().pipe(
                        mergeMap(() => this.financingService.deleteProduct(product.id).pipe(map(() => true))),
                    );
                }

                return of(false);
            }),
            mergeMap(deleteSuccessful => (deleteSuccessful ? this.documentService.getExistingEsisDocumentsForProducts(financingContainerId) : of(undefined))),
        ).subscribe({
            next: esisForProducts => {
                this.waiterService.hide();
                if (esisForProducts !== undefined) {
                    this.mapEsisDocuments(esisForProducts);
                }
            },
            error: () => this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.esis.deleteProductError')),
        });
    }

    /**
     * Löscht ein Dokument mitsamt der Files
     * 
     * @param {IDocument} document Zu löschendes Dokument
     */
    public deleteDocumemt(document: IDocument): void {
        const financingContainerId = this.financingContainerId;

        if (!financingContainerId) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.documentService.deleteEsis({
                finProcessContainerId: financingContainerId,
                documentId: document.id,
            })),
            mergeMap(() => this.documentService.getExistingEsisDocumentsForProducts(financingContainerId)),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.esis.deleteDocumentError'));
            },
            next: esisForProducts => {
                this.waiterService.hide();

                if (esisForProducts !== undefined) {
                    this.mapEsisDocuments(esisForProducts);
                }
            },
        });
    }

    /**
     * Lädt eine gegebene Datei hoch
     * 
     * @param {DocumentType } documentType Dokumentenart
     * @param {UUID} productId ProduktID
     * @param {File} file Datei
     */
    public uploadFile(documentType: DocumentType, productId: UUID, file: File): void {
        const financingContainerId = this.financingContainerId;
        if (!financingContainerId) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => {
                if (!this.financingContainerId) {
                    throw new Error('Financing Container ID not set');
                }

                return this.documentService.uploadProductFile({
                    file,
                    mapId: this.financingContainerId,
                    productId,
                    type: documentType,
                })}),
            mergeMap(() => this.documentService.getExistingEsisDocumentsForProducts(financingContainerId)),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
            },
            next: esisForProducts => {
                this.waiterService.hide();

                if (esisForProducts !== undefined) {
                    this.mapEsisDocuments(esisForProducts);
                }
            },
        })
    }

    /**
     * Setzt den Status der Finanzierung auf Esis gesendet und sperrt die Bearbeitung
     */
    public setStatus(): void {
        const financingContainerId = this.financingContainerId;
        const productPackageId = this.productPackageId;
        if (!financingContainerId || !productPackageId) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.setStatus({
                id: financingContainerId,
                status: FinancingStatus.EsisWaitingForAcception,
                productPackageId,
            })),
            mergeMap(() => this.setEsisEventsAsRead()),
        ).subscribe({
            next: () => this.waiterService.hide(),
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.esis.setStatusError'));
            },
        })
    }

    /**
     * Aktualisiert den Produktnamen
     * 
     * @param {EventTarget | null} input Input Element, welches den Namen enthält
     * @param {IProductWithDocuments} product Produkt
     */
    public changeProductName(input: EventTarget | null, product: IProductWithDocuments): void {

        if (input instanceof HTMLInputElement) {
            product.editTitle = false;
            const value = input.value;

            if (!!value && value.length> 0 && value !== product.name) {
                this.waiterService.show().pipe(
                    mergeMap(() => this.financingService.updateProductName(product.productPackageId, product.id, value)),
                ).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        product.name = value;
                        this.cRef.detectChanges();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                    },
                });
            }
        }
    }

    /**
     * Setzt die zuletzt ausgewählte Dokumentenart und ProduktID
     * 
     * @param {DocumentType} documentType Dokumentenart
     * @param {UUID} productId ProduktID
     */
    public setDocumentTypeAndProductId(documentType: DocumentType, productId: UUID): void {
        this.lastSelectedDocumentType = documentType;
        this.lastSelectedProductId = productId;
    }

    //#endregion

    //#region File Event Handlers

    /**
     * Event Handler für das Klicken auf den Upload Bereich
     * 
     * @param {EventTarget | null} target EventTarget
     */
    public onFileUploadClick(target: EventTarget | null): void {
        if (target instanceof HTMLInputElement && target.files !== null && target.files.length > 0 && this.lastSelectedDocumentType !== undefined && this.lastSelectedProductId !== undefined) {
            this.handleFileUpload(target.files, this.lastSelectedDocumentType, this.lastSelectedProductId);
        }
    }

    /**
     * Event Handler für das DragEnter Event der File Upload Bereiche.
     * Fügt die Klasse 'file-drag-over' zu dem Element hinzu, wenn das DragEnter Event ausgelöst wird.
     * 
     * @param {DragEvent} event DragEvent
     */
    public handleOnDragEnterForFileUpload(event: DragEvent): void {
        if (!!event.currentTarget && event.currentTarget instanceof HTMLDivElement && !this.readonly$.value) {
            event.currentTarget.classList.add('file-drag-over');
        }
        event.preventDefault();
    }

    /**
     * Event Handler für das DragLeave Event der File Upload Bereiche.
     * Entfernt die Klasse 'file-drag-over' von dem Element, wenn das DragLeave Event ausgelöst wird.
     * 
     * @param {DragEvent} event DragEvent
     */
    public handleOnDragLeaveForFileUpload(event: DragEvent): void {
        if (!!event.currentTarget && event.currentTarget instanceof HTMLDivElement && !event.currentTarget.contains(event.relatedTarget as Node) && !this.readonly$.value) {
            if (event.currentTarget.classList.contains('file-drag-over')) {
                event.currentTarget.classList.remove('file-drag-over');
            }
        }
        event.preventDefault();
    }

    /**
     * Event Handler für das DragOver Event der File Upload Bereiche.
     * Muss aufgerufen werden, um das Default Verhalten des Browsers für Dateien zu unterbinden.
     * 
     * @param {DragEvent} event DragEvent
     */
    public handleOnDragOverForFileUpload(event: DragEvent): void {
        event.preventDefault();
    }

    /**
     * Event Handler für das MouseEnter Event der File Upload Bereiche.
     * Zeigt das Overlay mit den Buttons zum Löschen und Hochladen von Dateien an.
     * 
     * @param {MouseEvent} event MouseEvent
     * @param {IProductWithDocuments} product Produkt (zum Setzen des ButtonsVisible-Flags)
     */
    public handleOnMouseEnterForFileUpload(event: MouseEvent, product: IProductWithDocuments): void {
        if (event?.currentTarget && event.currentTarget instanceof HTMLDivElement) {
            product.buttonsVisible = true;
        }
    }

    /**
     * Event Handler für das MouseLeave Event der File Upload Bereiche.
     * Versteckt das Overlay mit den Buttons zum Löschen und Hochladen von Dateien.
     * 
     * @param {MouseEvent} event MouseEvent
     * @param {IProductWithDocuments} product Produkt (zum Setzen des ButtonsVisible-Flags)
     */
    public handleOnMouseLeaveForFileUpload(event: MouseEvent, product: IProductWithDocuments): void {
        if (event.currentTarget && event.currentTarget instanceof HTMLDivElement && !event.currentTarget.contains(event.relatedTarget as Node)) {
            product.buttonsVisible = false;
        }
    }

    /**
     * Event Handler für das Drop Event der File Upload Bereiche.
     * Lädt eine Datei hoch, wenn sie den Anforderungen entspricht.
     * 
     * @param {DragEvent} event DragEvent
     * @param {DocumentType} documentType Typ des Dokuments
     * @param {UUID} productId ID des Produkts
     */
    public handleOnDropForFileUpload(event: DragEvent, documentType: DocumentType, productId: UUID): void {
        event.preventDefault();
        event.stopPropagation();
        if (!!event.currentTarget && event.currentTarget instanceof HTMLDivElement && !this.readonly$.value) {
            if (event.currentTarget.classList.contains('file-drag-over')) {
                event.currentTarget.classList.remove('file-drag-over');
            }

            const files = event.dataTransfer?.files;
            if (files !== null && files !== undefined && files.length > 0) {
                this.handleFileUpload(files, documentType, productId);
            }
        }
    }

    /**
     * Nimmt eine FileList entgegen und löst den Upload aus, wenn die Datei den Anforderungen entspricht.
     * 
     * @param {FileList | null} files FileList
     * @param {DocumentType} documentType Dokumentenart
     * @param {UUID} productId ProduktID
     */
    private handleFileUpload(files: FileList, documentType: DocumentType, productId: UUID): void {
        if (files.length > 1) {
            this.notification.toast('Es können nur einzelne Dateien hochgeladen werden');
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);

            if (file === null) {
                return;
            }

            if (file.size === 0) {
                this.notification.toast('Die Datei ist leer');
                return;
            }

            if (file.type !== 'application/pdf') {
                this.notification.toast('Es können nur PDF-Dateien hochgeladen werden');
                return;
            }

            this.uploadFile(documentType, productId, file);
        }
    }

    //#endregion

    //#region Information Events

    /**
     * Setzt alle ungelesenen Aktivitäten für ESIS auf gelesen
     * 
     * @returns {Observable<void | undefined>} Observable mit leerer Antwort
     */
    private setEsisEventsAsRead(): Observable<void | undefined> {
        const financingContainerId = this.financingContainerId;
        const eventTypes = [FinancingEventType.EsisRejected, FinancingEventType.EsisRequested];

        if (!financingContainerId) {
            return of(undefined);
        }

        return combineLatest([
            this.store.selectOnce(InformationEventState.allInformationEvents).pipe(
                map(informationEvents => {
                    const filteredEvents = (informationEvents ?? []).filter(event => eventTypes.includes(event.eventType) && !event.isRead);
    
                    return filteredEvents;
                }),
            ),
            this.financingService.isEditor$.pipe(take(1)),
        ]).pipe(
            take(1),
            mergeMap(([informationEvents, isEditor]) => {
                if (!isEditor || informationEvents.length === 0) {
                    return of(undefined);
                }

                return this.informationEventService.changeReadStatus({
                    finProcessContainerId: financingContainerId,
                    eventIds: informationEvents.reduce((events, current) =>
                        events.concat(current.events.filter(event => !event.isRead).map(event => event.id)), [] as string[]),
                }, true);
            }),
        );
    }

    //#endregion

    //#region Utils

    /**
     * Mapt die Serverantwort auf eine für die View angepasste Struktur
     * 
     * @param {IEsisForProducts} esisForProducts Serverantwort für Esis Dokumente
     */
    private mapEsisDocuments(esisForProducts: IEsisForProducts): void {
        this.isNewCase = esisForProducts.isNewCase;
        this.productPackageId = esisForProducts.productPackageId;
        this.productsWithDocuments = esisForProducts.products.map(product => ({
            id: product.id,
            name: product.productName,
            productType: product.productType,
            productPackageId: product.productPackageId,
            esis: product.documents.filter(document => document.type === DocumentType.ESIS),
            contractDrafts: product.documents.filter(document => document.type === DocumentType.ContractDraft),
            editTitle: false,
            buttonsVisible: false,
        }));
        this.checkIfAllDocumentsAreValid(this.productsWithDocuments).subscribe(valid => {
            this.sendEnabled = valid;
            this.cRef.detectChanges();
        })
    }

    /**
     * Überprüft ob alle notwendigen Dokumente hochgeladen wurden
     * 
     * @param {IProductWithDocuments[]} productsWithDocuments Produkte mit Dokumenten
     * @returns {Observable<boolean>} Observable mit dem Ergebnis ob alle Dokumente hochgeladen wurden
     */
    private checkIfAllDocumentsAreValid(productsWithDocuments: IProductWithDocuments[]): Observable<boolean> {
        if (productsWithDocuments.length === 0) {
            return of(false);
        }

        return this.isFinService$.pipe(
            take(1),
            map(isFinService => productsWithDocuments.every(product => {
                let valid = true;

                if (product.esis.length === 0 || product.esis.some(esis => esis.files.length === 0)) {
                    valid = false;
                }

                if (isFinService && (product.contractDrafts.length === 0 || product.contractDrafts.some(contractDraft => contractDraft.files.length === 0))) {
                    valid = false;
                }

                return valid;
            })),
        );
    }

    //#endregion
}
