<div class="message-container h-100 w-100">
    <div *ngIf="!loading" class="p-3" style="height: calc(100% - 40px);">
        <h1 class="color-primary" *ngIf="(mainDebitor$ | async) as debitor" fxLayoutAlign="center center">{{'messages.creditRequest' | translate}}
            <span class="ml-1">{{ debitor.firstNameOverwrite }}</span>
            <span class="ml-1">{{ debitor.lastNameOverwrite }}</span>
        </h1>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <h1 class="color-primary">{{ 'messages.messages' | translate }}</h1>
            <div>
                <button *ngIf="!isReadonlyInternal" mat-icon-button class="edit-btn ml-1" color="primary" (click)="startEdit()" [disabled]="(editDisabled$ | async) === false">
                    <mat-icon class="send-icon">edit</mat-icon>
                </button>
                <button mat-icon-button class="search-btn ml-1" color="primary" (click)="openSearchDialog()">
                    <mat-icon class="send-icon">search</mat-icon>
                </button>
                <button mat-icon-button class="download-btn ml-1" color="primary" (click)="onDownload()" [disabled]="exportDisabled$ | async">
                    <mat-icon class="send-icon">download</mat-icon>
                </button>
            </div>
        </div>
        <div class="chat-container" style="height: calc(100% - 80px);" fxLayoutAlign="space-between center" fxLayout="column"
            [class.chat-form]="isInputShown">
            <mat-tab-group class="w-100" mat-stretch-tabs [class.focus-height]="focusState"
                [(selectedIndex)]="selectedTabIdx" #matTabGroupContainer (selectedTabChange)="onTabChanged($event);">
                <ng-container *ngIf="!isMandantTypeFinService">
                    <mat-tab *ngIf="(groupChat$ | async) as groupMessages"
                        label="{{ 'messages.groupChat' | translate }}">
                        <div *ngFor="let message of groupMessages; let i = index" class="chat-box">
                            <div fxLayout="column" 
                                [ngClass]="{'customer': message.from === messageUserType.Customer,
                                            'sent': message.from === messageUserType.Source,
                                            'received': message.from === messageUserType.FinProcess}"
                                            >
                                <div class="message-box" id="message-{{i}}">
                                    <span class="label">{{message.displayName}} {{message.fromExtension ? ',' :''}}
                                        {{message.fromExtension}},
                                        {{message.created | date:'dd.MM.yyyy, HH:mm'}}</span>
                                    <div class="message" [innerHtml]="message.text">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
                <mat-tab *ngIf="(internalChat$ | async) as internalMessages"
                    label="{{ 'messages.internalChat' | translate }}">
                    <div *ngFor="let message of internalMessages; let i = index" class="chat-box">
                        <div fxLayout="column"
                            [ngClass]="message.from === messageUserType.Source ? 'sent' : 'received'">
                            <div class="message-box" id="message-{{i}}">
                                <span class="label">{{message.displayName}} {{message.fromExtension ? ',' :''}}
                                    {{message.fromExtension}},
                                    {{message.created | date:'dd.MM.yyyy, HH:mm'}}</span>
                                <div class="message" [innerHtml]="message.text">
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="text-box w-100">
                <form *ngIf="!!chatForm" [formGroup]="chatForm" [hidden]="!isInputShown" #form="ngForm" #editform>
                    <ckeditor [editor]="Editor" (focus)="onFocus()" (blur)="onBlur()" formControlName="txArea">
                    </ckeditor>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-icon-button color="primary" class="delete-btn" (click)="onDelete()">
                            <mat-icon class="send-icon">delete</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" class="send-btn" (click)="onSend()"
                            [disabled]="!chatForm.controls['txArea'].value?.trim() && isSendBtnActive">
                            <mat-icon class="send-icon">send</mat-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <waiter-component [isLoading]="loading"></waiter-component>
    </div>
</div>
