/**
 * Enum für StoreColtData Methode
 */
export enum ColtSaveMode {
    /**
     * Zwischenspeichern - Validierungen werden nicht ausgeführt und Status wird nicht verändert
     */
    TemporarySave,

    /**
     * Normales Speichern - Validierungen werden ausgeführt und Status wird bei Erfolg verändert
     */
    NormalSave,

    /**
     * Erzwungenes Speichern - Validierungen werden übersprungen und Status wird verändert
     */
    ForceSave,
}
