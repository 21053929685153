import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardTab } from 'app/modules/dashboard/data';
import { AuthorizationService, Role } from 'auth';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigService, HelperService } from 'shared/util';

import { IInformationEvents, IInformationEventsRequest } from '../../interfaces';
import { IInformationEventLoad } from '../../interfaces/information-event.load.request';
import { InformationEventLoaded, InformationEventReadStatus, InformationEventsAdded } from '../../states/information-event/information-event.actions';


/**
 * Service zum Laden und Interagieren mit Finanzierungsanfragen
 */
@Injectable()
export class InformationEventService {
    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Liefert die Abfrage der Aktivitäten aus dem Backend als Observable
     *
     * @param {string} tabId Tab
     * @param {boolean} isLoadFirstBatch is erste batch
     * @param {IInformationEventLoad} eventLoad ab welcher Index aus der Aktivitätenliste start die Abfrage
     * @returns {Observable<IInformationEvents>} Abfrage der Aktivitäten aus dem Backend als Observable
     */
    public loadInformationEvents(tabId: string, isLoadFirstBatch: boolean, eventLoad: IInformationEventLoad): Observable<IInformationEvents[] | undefined> {
        if (tabId === DashboardTab.My) {
            eventLoad.loadOnlyOwnEvents = true
        } else if (tabId !== DashboardTab.All) {
            eventLoad.forTemporaryFinancingsOwnedByUserId = tabId
        }


        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IInformationEvents[]>(`${this.config.getEnvironment().apiUrl}/InformationEvent/getInformationEvents`, {
                    params: HelperService.buildHttpParams(eventLoad),
                    responseType: 'json',
                }).pipe(
                    mergeMap(informationEvents =>
                    {
                        if (isLoadFirstBatch) {
                            return this.store.dispatch(new InformationEventLoaded(informationEvents ?? [])).pipe(
                                map(() => informationEvents));
                        }
                        else {
                            return this.store.dispatch(new InformationEventsAdded(informationEvents ?? [])).pipe(
                                map(() => informationEvents));
                        }
                    })),
                of(undefined),
            )),
        );
    }

    /**
     * Liefert die Abfrage der Aktivitäten zu einer Finanzierung aus dem Backend als Observable
     *
     * @param {string} financingMapId ID der Finanzierung
     * @param {string} temporaryFinancingsOwnedByUserId ID der Vetretung
     * @returns {Observable<IInformationEvents>} Abfrage der Aktivitäten aus dem Backend als Observable
     */
    public loadInformationEvent(financingMapId: string, temporaryFinancingsOwnedByUserId?: string): Observable<IInformationEvents[] | undefined> {
        let params = new HttpParams();
        params = params.set('financingMapId', financingMapId);

        if (!!temporaryFinancingsOwnedByUserId) {
            params = params.set('forTemporaryFinancingsOwnedByUserId', temporaryFinancingsOwnedByUserId )
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IInformationEvents[]>(`${this.config.getEnvironment().apiUrl}/InformationEvent/getInformationEvent`, {
                    params,
                    responseType: 'json',
                }).pipe(
                    mergeMap(informationEvents => this.store.dispatch(new InformationEventLoaded(informationEvents)).pipe(
                        map(() => informationEvents),
                    ))),
                of(undefined),
            )),
        );
    }

    /**
     * Ändert den Status von Aktivitäten auf gelesen oder ungelesen
     *
     * @param {IInformationEventsRequest} request Request Objekt
     * @param {boolean} read Neuer Zustand
     * @returns {Observable} Leerer Rückgabewert
     */
    public changeReadStatus(request: IInformationEventsRequest, read: boolean): Observable<void> {
        const method = read ? 'MarkEventsAsRead' : 'MarkEventsAsUnread';

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/InformationEvent/${method}`, request).pipe(
                    mergeMap(() => this.store.dispatch(new InformationEventReadStatus({ request, read}))),
                ),
                of(undefined),
            )),
        );
    }
}
