export enum CreditLine {
    /**
     * Darlehen mit Fixverzinsung mit Vergleichsrechnung (mit Eintragung Deckungsstock/Deckungsregister)
     */
    CreditFixedInterestRateWithCoverRegister = 0,

    /**
     * Darlehen mit Fixverzinsung ohne Vergleichsrechnung (ohne Eintragung Deckungsstock/Deckungsregister)
     */
    CreditFixedInterestRateWithoutCoverRegister = 1,

    /**
     * Darlehen mit variabler Verzinsung
     */
    CreditVariableInterestRate = 2,

    /**
     * Ergänzungsdarlehen mit Fixverzinsung
     */
    ComplementaryCreditFixedInterestRate = 3,

    /**
     * Ergänzungsdarlehen mit variabler Verzinsung
     */
    ComplementaryCreditVariableInterestRate = 4,

    /**
     * Vorfinanzierung Eigenmittel
     */
    PreFinancingOwnCapital = 5,

    /**
     * Vorfinanzierung Förderung
     */
    PreFinancingFunding = 6,
}
