import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { SystemConfigurationType, UpdateSystemConfigurationValue } from 'app/modules/masterdata/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, forkJoin, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ISystemConfigurationValue } from '../../interfaces';

import { IRiskParametrizationResponse } from './../../interfaces/risk-parametrization.response';
import { RiskParametrizationChanged, RiskParametrizationLoaded, SystemConfigurationLoaded } from './../../states/risk-parametrization/risk-parametrization.action';

/**
 * Service für Risiko Parametrisierung
 */
@Injectable()
export class RiskParametrizationService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) { }

    /**
     * Lädt die Risikoparameter
     *
     * @returns {IRiskParametrizationResponse | undefined} Risikoparameter
     */
    public get(): Observable<IRiskParametrizationResponse | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IRiskParametrizationResponse>(`${this.config.getEnvironment().apiUrl}/RiskParameterization`).pipe(
                    mergeMap((response: IRiskParametrizationResponse) => this.store.dispatch(new RiskParametrizationLoaded(response)).pipe(
                        map(() => response),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Aktualisiert die Risikoparameter
     *
     * @param {IRiskParametrizationResponse} riskParametrization RisikoParameter
     * @returns {Observable} Observable Antwort ohne Inhalt
     */
    public update(riskParametrization: IRiskParametrizationResponse): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.RiskParameterizationAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/RiskParameterization`, riskParametrization).pipe(
                    mergeMap(() => this.store.dispatch(new RiskParametrizationChanged(riskParametrization))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt einen bestimmten Systemkonfigurationswert und gibt ein Array
     * dieses Konfigurationswertes mit eventuell mehreren MandantIds zurück
     *
     * @param {SystemConfigurationType} type Systemkonfigurationstyp
     * @returns {Observable<ISystemConfigurationValue[] | undefined>} Liste der Systemkonfigurationswerte
     */
    public getSystemConfiguration(type: SystemConfigurationType): Observable<ISystemConfigurationValue[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<ISystemConfigurationValue[]>(`${this.config.getEnvironment().apiUrl}/SystemConfiguration`,
                    { params: { requestedConfigurations: type } }).pipe(
                    mergeMap((response: ISystemConfigurationValue[]) => this.store.dispatch(new SystemConfigurationLoaded(response)).pipe(
                        map(() => response),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Aktualisiert einen bestimmten Systemkonfigurationswert
     *
     * @param {ISystemConfigurationValue} systemConfig SystemKonfiguration
     * @returns {Observable} Observable Antwort ohne Inhalt
     */
    public updateSystemConfiguration(systemConfig: ISystemConfigurationValue): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.RiskParameterizationAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/SystemConfiguration`, systemConfig).pipe(
                    mergeMap(() => forkJoin([this.store.dispatch(new SystemConfigurationLoaded([systemConfig])),
                        this.store.dispatch(new UpdateSystemConfigurationValue({
                            type: systemConfig.type,
                            value: systemConfig.value,
                            freeText: systemConfig.freeText,
                        })),
                    ])),
                    map(() => undefined),
                ),
                of(undefined),
            )),
        );
    }
}
