import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, ILiabilityConfiguration } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * Service für Liability Configuration
 */
@Injectable()
export class LiabilityConfigurationService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) {}

    /**
     * Lädt die LiabilityConfiguration
     * 
     * @returns {ILiabilityConfiguration} LiabilityConfiguration
     */
    public getConfiguration(): Observable<ILiabilityConfiguration | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(
                authorized => iif(
                    () => authorized,
                    this.httpClient.get<ILiabilityConfiguration>(`${this.config.getEnvironment().apiUrl}/liabilityconfiguration`),
                    of(undefined),
                ),
            ),
        )
    }

    /**
     * Speihert die LiabilityConfiguration
     * 
     * @param {ILiabilityConfiguration} configuration LiabilityConfiguration
     * @returns {Observable} Antwort vom Server
     */
    public saveConfiguration(configuration: ILiabilityConfiguration): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.ConditionsAdministrator, false).pipe(
            mergeMap(
                authorized => iif(
                    () => authorized,
                    this.httpClient.post(`${this.config.getEnvironment().apiUrl}/LiabilityConfiguration`, configuration, {}),
                    of(undefined),
                ),
            ),
        )
    }

}
