<ng-container *ngFor="let realEstate of collateralRealEstateDetail; let i = index">
    <mat-expansion-panel *ngIf="!!realEstate" class="bg-dark-2 mb-3" [hideToggle]="true" [expanded]="!!collateralRealEstateDetailExpand[realEstate.id]">
        <mat-expansion-panel-header class="header-1" style="cursor: default">
            <div fxLayout="column" style="width: 100%" (click)="$event.stopImmediatePropagation()">
                <div fxlayout="row">
                    <button
                        mat-icon-button
                        fxFlex="5"
                        (click)="
                            collateralRealEstateDetailExpand[realEstate.id] = !collateralRealEstateDetailExpand[realEstate.id];
                            $event.stopImmediatePropagation()
                        ">
                        <mat-icon class="grey-color">{{ collateralRealEstateDetailExpand[realEstate.id] ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                    <label [ngClass]="{ 'requirements-failed': validRealEstatesMap[realEstate.id] === false }" style="cursor: pointer" fxFlex="80" class="mt-2"
                        ><b>{{ realEstate.collateralType ?? 'Immobilie' | finprocessEnumTranslation : 'CollateralType' }}</b></label
                    >
                    <button class="delete" [disabled]="editingReadonly$ | async" fxFlex="15" mat-icon-button (click)="deleteCollateral(realEstate.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>

                <div class="mt-2 mb-2 collateral-grid" (click)="$event.stopImmediatePropagation()">
                    <!--Anzeige nur bei BA205-->
                    <finprocess-select-input
                        *ngIf="realEstate.collateralType === CollateralType.BA205"
                        [items]="booleanItems"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        [hideHorizontalRule]="true"
                        [flexDirection]="inputFlexDirection"
                        fieldName="isPartialEntry"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [valueStorageType]="ValueStorageType.Bool"
                        [validators]="Validators.required"
                        (checkChange)="checkPartialEntry($event)"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.isReducedAmount' | translate }}</span>
                    </finprocess-select-input>

                    <!--Anzeige bei  "Erfolgt eine Teil-/Mindereintragung"=ja-->
                    <finprocess-decimal-input
                        *ngIf="partialEntry === true"
                        class="gb-label"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        fieldName="gbaAmount"
                        [hideHorizontalRule]="true"
                        [flexDirection]="inputFlexDirection"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.amountGB' | translate }}</span>
                    </finprocess-decimal-input>

                    <finprocess-select-input
                        *ngIf="realEstate.collateralType === CollateralType.BA205 && partialEntry === true"
                        [items]="booleanItems"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        fieldName="ltvRelevant"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [valueStorageType]="ValueStorageType.Bool"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.ltvRelevantProperty' | translate }}</span>
                    </finprocess-select-input>

                    <finprocess-select-input
                        *ngIf="realEstate.collateralType === CollateralType.BA205 && partialEntry === false"
                        [items]="booleanItems"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        fieldName="ltvRelevant"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [valueStorageType]="ValueStorageType.Bool"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.ltvRelevantProperty' | translate }}</span>
                    </finprocess-select-input>

                    <finprocess-select-input
                        *ngIf="realEstate.collateralType !== CollateralType.BA205"
                        [items]="booleanItems"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        [flexDirection]="inputFlexDirection"
                        [hideHorizontalRule]="true"
                        fieldName="ltvRelevant"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [valueStorageType]="ValueStorageType.Bool"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.ltvRelevantProperty' | translate }}</span>
                    </finprocess-select-input>

                    <finprocess-decimal-input
                        *ngIf="realEstate.collateralType !== CollateralType.BA205"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        fieldName="pbuAmount"
                        [hideHorizontalRule]="true"
                        [flexDirection]="inputFlexDirection"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.pbuAmount' | translate }}</span>
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        *ngIf="realEstate.collateralType === CollateralType.BA205"
                        [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                        [entityClassType]="entityClassType.CollateralMortgage"
                        fieldName="pbuAmount"
                        [hideHorizontalRule]="true"
                        [flexDirection]="inputFlexDirection"
                        [readonly]="editingReadonly$"
                        [entity]="realEstate"
                        [validators]="Validators.required"
                        [transparent]="true"
                        subscriptSizing="fixed">
                        <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.pbuAmount' | translate }}</span>
                    </finprocess-decimal-input>

                    <!--Berechnung: bei "Erfolgt eine Teil-/Mindereintragung"=ja := Höhe der GB Eintragung, sonst:=Höhe der PBU-->
                    <div style="width: 80%; height: 100%" class="mt-3" fxLayout="column" fxFlex="5" *ngIf="!!realEstate.gbaAmount && partialEntry === true">
                        <span label class="input-label contractvalue-label">{{ 'asset.assetDetails.realEstateDetails.contractvalue' | translate }}</span>
                        <span [ngStyle]="{ color: (editingReadonly$ | async) ? 'rgba(0, 0, 0, 0.38)' : 'black' }">{{
                            realEstate.gbaAmount | formatCurrency
                        }}</span>
                    </div>
                    <div style="width: 80%; height: 100%" class="mt-3" fxLayout="column" fxFlex="5" *ngIf="!!realEstate.pbuAmount && partialEntry === false">
                        <span label class="input-label contractvalue-label">{{ 'asset.assetDetails.realEstateDetails.contractvalue' | translate }}</span>
                        <span [ngStyle]="{ color: (editingReadonly$ | async) ? 'rgba(0, 0, 0, 0.38)' : 'black' }">{{
                            realEstate.pbuAmount | formatCurrency
                        }}</span>
                    </div>
                </div>
            </div>
        </mat-expansion-panel-header>
        <mat-divider class="mt-2"></mat-divider>

        <!--Pflichtfelder Für Genehmigungsseite -> nur bei Quelle!=AGP -->
        <div class="mt-2 collateral-grid" *ngIf="approvalView === true && realEstate.source !== SourceCollateral.Agp">
            <finprocess-select-input
                [items]="booleanItems"
                [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                [entityClassType]="entityClassType.CollateralMortgage"
                [hideHorizontalRule]="true"
                fieldName="waiverOfUnnecessaryTrustee"
                [readonly]="editingReadonlyApprovalView$"
                [entity]="realEstate"
                [valueStorageType]="ValueStorageType.Bool"
                [validators]="Validators.required">
                <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.waiverOfUnnecessaryTrustee' | translate }}</span>
            </finprocess-select-input>

            <finprocess-select-input
                [items]="booleanItems"
                [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                fieldName="fiduciaryProcessing"
                [readonly]="editingReadonlyApprovalView$"
                [entity]="realEstate"
                [valueStorageType]="ValueStorageType.Bool"
                [validators]="Validators.required">
                <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.fiduciaryProcessing' | translate }}</span>
            </finprocess-select-input>

            <finprocess-integer-input
                [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                [entityClassType]="entityClassType.CollateralMortgage"
                fieldName="priorityNewMortgage"
                [hideHorizontalRule]="true"
                [flexDirection]="inputFlexDirection"
                [readonly]="editingReadonlyApprovalView$"
                [entity]="realEstate"
                [validators]="Validators.required">
                <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.priorityNewMortgage' | translate }}</span>
            </finprocess-integer-input>
        </div>

        <!--Liegenschaften hinzufügen-->
        <div class="mb-3 mt-4" fxLayout="row" *ngIf="!!assetRealEstate && assetRealEstate.length > 0">
            <h4 class="mr-2" style="margin-top: 6px"><b>{{'asset.assetDetails.realEstateDetails.properties' |
                    translate}}</b></h4>
            <button [disabled]="(editingReadonly$ | async)" mat-mini-fab matTooltip="Liegenschaften zuordnen" (click)="matSelect.open()"
                style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;">
                <mat-icon class="grey-color" fxLayout="column" fxLayoutAlign="center center">home</mat-icon></button>


        <mat-select style="visibility: hidden;" #matSelect [panelWidth]="350" [ngModel]="realEstate.assetAssignmentInfos" [compareWith]="compareAssetRealEstates" multiple>
            <mat-option #option id="{{estate.id}}" *ngFor="let estate of assetRealEstate" [value]="estate"  (onSelectionChange)="assignRealEstates(estate.id, realEstate.id, true, $event)">
                {{estate.assetType ?? 'keine Immobilienart angegeben' | finprocessEnumTranslation: 'RealEstateType'}}
            </mat-option>
        </mat-select>
        </div>

        <div fxLayout="row" fxLayoutAlign="start" class="mt-4" *ngIf="!assetRealEstate || assetRealEstate.length === 0">
            <h4>
                <b>{{ 'asset.assetDetails.realEstateDetails.noRealEstateDetails' | translate }}</b>
            </h4>
        </div>

        <ng-container *ngFor="let assignAsset of realEstate.assetAssignmentInfos; let j = index">
            <mat-expansion-panel class="mt-3 mb-3 expansion-2" *ngIf="!!assignAsset" [hideToggle]="true">
                <mat-expansion-panel-header class="header-2">
                    <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
                        <button fxFlex="5" mat-icon-button (click)="assetsExpand[assignAsset.assetId] = !assetsExpand[assignAsset.assetId]">
                            <mat-icon class="grey-color">{{ assetsExpand[assignAsset.assetId] ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </button>
                        <div fxFlex="15" class="mr-2" *ngIf="assignAsset.assetMortgage.assetType !== undefined">
                            <small>{{ assignAsset.assetMortgage.assetType | finprocessEnumTranslation : 'RealEstateType' }}</small>
                        </div>

                        <div fxLayout="row" fxFlex="45">
                            <div class="mr-1">
                                <small>{{ assignAsset.assetMortgage.address?.zip }}</small>
                            </div>
                            <div class="mr-1" *ngIf="!!assignAsset.assetMortgage.address?.city">
                                <small>{{ assignAsset.assetMortgage.address?.city }},</small>
                            </div>
                            <div class="mr-1">
                                <small>{{ assignAsset.assetMortgage.address?.street }}</small>
                            </div>
                            <div class="mr-1" *ngIf="!!assignAsset.assetMortgage.address?.streetNumber">
                                <small>{{ assignAsset.assetMortgage.address?.streetNumber }},</small>
                            </div>
                            <div *ngIf="!!assignAsset.assetMortgage.address?.top">
                                <small>{{ assignAsset.assetMortgage.address?.top }}</small>
                            </div>
                        </div>

                        <div fxFlex="25">
                            <small *ngIf="!!assignAsset.assetMortgage.marketValue">
                                {{ 'asset.assetDetails.realEstateDetails.marketValue' | translate }}:
                                {{ assignAsset.assetMortgage.marketValue | formatCurrency }}
                            </small>
                        </div>
                        <button
                            class="house-icon"
                            [disabled]="editingReadonly$ | async"
                            mat-icon-button
                            fxFlex="5"
                            *ngIf="assignAsset.assetMortgage.source === Source.Client"
                            (click)="showRealEstateDetail(assignAsset?.assetId, $event)">
                            <mat-icon>house</mat-icon>
                        </button>
                        <span fxFlex="5" *ngIf="assignAsset.assetMortgage.source !== Source.Client"></span>
                        <button
                            class="delete"
                            [disabled]="editingReadonly$ | async"
                            fxFlex="5"
                            mat-icon-button
                            (click)="assignRealEstates(assignAsset.assetId, realEstate.id, false)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>

                <div class="assign-body">
                    <div class="mb-3 pb-3 mt-3 collateral-grid">
                        <finprocess-select-input
                            [items]="booleanItems"
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.AssetToCollateralMortgage"
                            [hideHorizontalRule]="true"
                            fieldName="foreignPreloads"
                            [flexDirection]="inputFlexDirection"
                            [readonly]="editingReadonly$"
                            [entity]="assignAsset"
                            [valueStorageType]="ValueStorageType.Bool"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.foreignAndOwnLiabilitiesBADef' | translate }}</span>
                        </finprocess-select-input>

                        <finprocess-select-input
                            [items]="booleanItems"
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.AssetToCollateralMortgage"
                            [hideHorizontalRule]="true"
                            [flexDirection]="inputFlexDirection"
                            fieldName="belqRelevant"
                            [readonly]="editingReadonly$"
                            [entity]="assignAsset"
                            [valueStorageType]="ValueStorageType.Bool"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.relevantBelq' | translate }}</span>
                        </finprocess-select-input>
                        <div></div>
                    </div>

                    <div class="mt-3 mb-3 collateral-grid">
                        <finprocess-decimal-input
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.AssetToCollateralMortgage"
                            fieldName="preloadsKimV"
                            [hideHorizontalRule]="true"
                            [flexDirection]="inputFlexDirection"
                            [readonly]="editingReadonly$"
                            [entity]="assignAsset"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.foreignKimV' | translate }}</span>
                        </finprocess-decimal-input>

                        <finprocess-decimal-input
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.AssetToCollateralMortgage"
                            fieldName="ownPreloads"
                            [hideHorizontalRule]="true"
                            [flexDirection]="inputFlexDirection"
                            [readonly]="editingReadonly$"
                            [entity]="assignAsset"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.ownKimV' | translate }}</span>
                        </finprocess-decimal-input>

                        <finprocess-decimal-input
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.AssetToCollateralMortgage"
                            fieldName="totalBaMortgages"
                            [hideHorizontalRule]="true"
                            [flexDirection]="inputFlexDirection"
                            [readonly]="editingReadonly$"
                            [entity]="assignAsset"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.sumOfRealEstateKIMV' | translate }}</span>
                        </finprocess-decimal-input>
                    </div>

                    <div class="mt-4 mb-2">
                        <finprocess-asset-provider
                            class="asset-provider"
                            [assetId]="assignAsset.assetId"
                            [assetProviders]="assignAsset.assetMortgage.assetProviders">
                        </finprocess-asset-provider>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>

        <!--Besicherung-->
        <div class="mb-3 mt-4" fxLayout="column" fxLayoutAlign="start baseline" *ngIf="!!productPackage">
            <h4 class="mr-2">
                <b>{{ 'asset.assetDetails.collateralisation' | translate }}</b>
            </h4>

            <div *ngIf="!!dataSources" style="width: 100%">
                <table mat-table [dataSource]="dataSources.get(realEstate) || []" class="table custom-border">
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{ 'asset.assetDetails.tablePosition' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.position }}.</td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{ 'asset.assetDetails.tableProductName' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.name | finprocessEnumTranslation : 'ProductPackageType' }}</td>
                    </ng-container>

                    <!-- Duration Column -->
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{ 'asset.assetDetails.tableDuration' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.duration !== '-'">
                                {{ element.duration }} {{ 'asset.assetDetails.tableMonths' | translate }}
                            </ng-container>
                            <ng-container *ngIf="element.duration === '-'">
                                {{ element.duration }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="input-label">
                            {{ 'asset.assetDetails.tableAmount' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.amount | formatCurrency }}</td>
                    </ng-container>

                    <!-- collateralized Column -->
                    <ng-container matColumnDef="collateralized">
                        <th mat-header-cell *matHeaderCellDef class="input-label">{{ 'asset.assetDetails.tableCollateralisation' | translate }} *</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox
                                color="primary"
                                [disabled]="editingReadonly$ | async"
                                [checked]="element.collateralized"
                                (change)="productSecured(realEstate.id, element.id, $event.checked)"></mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Define column order -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
    </mat-expansion-panel>
</ng-container>
