import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'auth';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigService, UUID } from 'shared/util';

import { DebitorRatingUpdated, DebitorsRatingUpdated } from '../..';
import { IRatingResult, IRatingResultRequest, IScoringGWB } from '../../interfaces';


/**
 * Service für Scoring
 */
@Injectable()
export class ScoringService {

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */   
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Vorhandene Scoring laden
     * 
     * @param { UUID} financingPlanId financingPlanId 
     * @returns { Observable<IScoringGWB | undefined> } Scoring
     */
    public loadExistingScoring(financingPlanId: UUID): Observable<IScoringGWB | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IScoringGWB>(`${this.config.getEnvironment().apiUrl}/Scoring/GetExistingScoring`, {
                    params: {
                        financingPlanId: financingPlanId,
                    },
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Manule Scoring speihern
     * 
     * @param {IScoringGWB} scoring IScoringGWB
     * @returns { Observable<UUID | undefined> } ScoringId
     */
    public saveManualScoring(scoring: IScoringGWB): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/Scoring/PostManualScoring`, scoring, {},
                ).pipe(
                    map((id: UUID) => id),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Neue Scoring laden
     * 
     * @param { UUID} financingPlanId financingPlanId 
     * @returns { Observable<IScoringGWB | undefined> } Scoring
     */
    public calculateNewScoring(financingPlanId: UUID): Observable<IScoringGWB | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IScoringGWB>(`${this.config.getEnvironment().apiUrl}/Scoring/CalculateNewScoring`, {
                    params: {
                        financingPlanId: financingPlanId,
                    },
                }).pipe(
                    mergeMap(response => this.store.dispatch(new DebitorsRatingUpdated((response.debitorRatings))).pipe(map(() => response))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     *  Scoring laden
     * 
     * @param { IRatingResultRequest} request IRatingResultRequest 
     * @param { housholdId} housholdId housholdId 
     * @returns { Observable<IRatingResult | undefined> } IRatingResult
     */
    public refreshClientRating(request: IRatingResultRequest, housholdId: string): Observable<IRatingResult | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IRatingResult>(`${this.config.getEnvironment().apiUrl}/Scoring/RefreshClientRating`, request, {}).pipe(
                    mergeMap(response => this.store.dispatch(new DebitorRatingUpdated({ratingResult: response, debitorId: request.debitorId, housholdId: housholdId }))),
                ),
                of(undefined),
            )),
        );
    }
}
