/**
 * @copyright NT.AG 2021
 */

import { ISmartDocStatusViewModel } from '../../interfaces/smartdoc/smartdoc-status-view.model';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocStatusLoadedPayloadType {
    /**
     * Schlüssel der Kreditanfrage
     */
    id: AAGUID;

    /**
     * Status-Objekt
     */
    status: ISmartDocStatusViewModel;
}

/**
 * Klasse für Aktion
 */
export class SmartDocStatusLoaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocStatusLoaded';
    /**
     * Constructor fuer SmartDocStatusLoaded
     * 
     * @param {ISmartDocStatusLoadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocStatusLoadedPayloadType) { }
}
