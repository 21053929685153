<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between end" class="p-3">
        <h1 class="color-primary">Nutzerliste</h1>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field>
                <mat-select [panelWidth]="320" placeholder="Verwaltungsgruppe" (selectionChange)="updateFinancingPremissionsFilter($event)">
                    <mat-option *ngFor="let financingPremission of financingPremissions" [value]="financingPremission.value">
                        {{ financingPremission.displayName }}
                    </mat-option>
                    <mat-option>{{ 'general.noSelection' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Nutzerliste durchsuchen" [ngModel]="searchTerm.value" (ngModelChange)="updateSearch($event)">
            </mat-form-field>
        </div>
    </div>
    <table mat-table [dataSource]="displayedUsers" fxFlex>
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.firstName' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.lastName' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.email' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.email }}</td>
        </ng-container>
        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.phoneNumber' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.phoneNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="languages">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.languages' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.languages | finProcesslanguageShort }}</td>
        </ng-container>
        <ng-container matColumnDef="organisationalUnit">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.organisationalUnit' | translate }}</th>
            <td mat-cell *matCellDef="let user">
                <span *ngIf="!!user?.financingPermissions">{{user?.financingPermissions | organisationalUnitResponsibilityShort }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.confirmed' | translate }}</th>
            <td mat-cell *matCellDef="let user">
                <span *ngIf="!!user?.id">
                    <mat-icon *ngIf="!user?.confirmed">close</mat-icon>
                    <mat-icon *ngIf="user?.confirmed">done_all</mat-icon>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.users.edit' | translate }}</th>
            <td mat-cell *matCellDef="let user">
                <ng-container *finprocessAuthorization="Role.OrganisationalUnitAdministrator">
                    <button *ngIf="!!user?.id" mat-icon-button (click)="editUser(user)">
                        <mat-icon >edit</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="(users$ | async)?.length ?? 0" [pageSize]="pageSize" showFirstLastButtons (page)="changePage($event)"></mat-paginator>
</div>
