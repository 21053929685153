export enum ApprovalCompetence {

    /**
     * Eigenkompetenz
     */
    SelfCompetence,
   
    /**
     * Fremdkompetenz
     */
    ExternalCompetence,

    /**
     * Marktfolge
     */
    BackOffice,
}
