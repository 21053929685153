<h1 mat-dialog-title>{{'financing.features.financing-processing.jointHeading.addNewJHTitle' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutGap="20px">

        <mat-form-field appearance="fill">
            <mat-label>{{'financing.features.financing-processing.jointHeading.debitorSelection' | translate }}</mat-label>
            <mat-select [(ngModel)]="debitorSelection" multiple>
                <mat-option *ngFor="let debitor of debitorsList;" [value]="debitor.id" [matTooltip]="getDebitorsTooltip(debitor)">
                    {{debitor.firstName}} {{debitor.lastName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'financing.features.financing-processing.jointHeading.ndg' | translate }}: </mat-label>
            <input matInput [(ngModel)]="ndgValue">
        </mat-form-field>
        
    </div>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button mat-dialog-close (click)="addNewJointHeading()">{{'financing.features.financing-processing.jointHeading.addNewJHButton' | translate }}</button>
    <button mat-button mat-dialog-close>{{'financing.features.financing-processing.jointHeading.cancelNewJHButton' | translate }}</button>
</div>