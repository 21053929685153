import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { CreditBroker } from '@ntag-ef/finprocess-enums/finprocess';
import { Observable, Subject } from 'rxjs';
import { HelperService, ISelectItem } from 'shared/util';

import { EntityClassType, FinancingService, IAddress, ICompany, IFinancing, IFinancingStateParentDefinition, ISalesPartner, OverwriteValueClassType } from '../../../../data';

/**
 * Komponente für den Tab Formal
 */
@Component({
    selector: 'finprocess-formal',
    templateUrl: './formal.component.html',
    styleUrls: ['./formal.component.scss'],
})
export class FormalComponent implements OnDestroy, OnInit {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;
    
    public entityClassType = EntityClassType;
   

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;


    /**
     * Observable mit dem Vertriebspartner
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.salesPartner))
    public salesPartner$!: Observable<ISalesPartner>;

    /**
     * Observable mit dem Vertriebspartner - Adresse
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.salesPartner?.address))
    public salesPartnerAddress$!: Observable<IAddress>;

    /**
     * Observable mit dem Vertriebspartner - Unternehmen
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.salesPartner?.company))
    public salesPartnerCompany$!: Observable<ICompany>;

    /**
     * Observable mit dem Vertriebspartner - Unternehmen
     */
    @Select((state: IFinancingStateParentDefinition) => (state.financing.financing?.salesPartner?.company?.address))
    public salesPartnerCompanyAddress$!: Observable<IAddress>;

    /**
     * Einzelauswahl - Kreditvermittler
     */
    public creditBrokerSingleSelectItems: ISelectItem<string>[] = [];

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {MatDialogRef<FormalComponent>} dialogRef MatDialogRef-Injektor
     */
    public constructor(private store: Store, private financingService: FinancingService, private dialogRef: MatDialogRef<FormalComponent>) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }


    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.creditBrokerSingleSelectItems = HelperService.getSortedSelectItems(CreditBroker, value => CreditBroker.translate(value));
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Schließt den Dialog ohne Aktion
     */
    public closeDialog(): void {
        this.dialogRef.close();
    }
}
