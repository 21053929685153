import { WorkflowType } from '../../enums';
import { FormattingType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const CREDIT_PARTICIPANT: IMidtermModel = {

    midtermName: 'creditParticipant',

    /**
     * Geburtsdatum (TT.MM.JJJJ) des Einzelkunden oder des Kreditbeteiligten A bis D
     * gebdat_a bis gebdat_d
     */
    dateOfBirth: { type: WorkflowType.Date },

    /**
     * Kundennummer des Kreditbeteiligten A bis D
     * kdnr_ndg_a bis kdnr_ndg_d
     */
    customerNdg: { type: WorkflowType.Number},

    /**
     * Verhaltensscoring des Kreditbeteiligten A bis D
     * verhaltensscoring_a bis verhaltensscoring_d
     */
    behavioralScoring: { type: WorkflowType.String },

    /**
     * Einkünfte 14x des Einzelkunden oder des Kreditbeteiligten A bis D
     * kbt_einkommen_14_a bis kbt_einkommen_14_d
     */
    income14: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Einkünfte 12x des Einzelkunden oder des Kreditbeteiligten A bis D
     * kbt_einkommen_12_a bis kbt_einkommen_12_d
     */
    income12: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Unterhaltspflichtige Personen des Einzelkunden oder des Kreditbeteiligten A bis D
     * kbt_unterhaltspflichtige_anzahl_a bis kbt_unterhaltspflichtige_anzahl_d
     */
    payMaintenanceCount: { type: WorkflowType.Number},

    /**
     * zusätzliche Transferzahluangen gem. KIM-V
     */
    additionalTransferPaymentsKimV: { type: WorkflowType.Number, formattingType: FormattingType.Currency},

}
