import { EnumSettings } from './../enum.settings';

/**
 * Garagenbau
 */
export enum GarageLocation {
    /**
     * An Wohnhaus angebaut
     */
    GarageAttachedToHouse = 0,

    /**
     * Freistehend auf Grundstück
     */
    DetachedGarage = 1,
}

export namespace GarageLocation {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {GarageLocation | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: GarageLocation | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'GarageLocation', GarageLocation, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('GarageLocation', plocale);
}
