export enum FixedInterestPeriod {
    /**
     * 1 Jahr fix
     */
    OneYearFix = 1,

    /**
     * 2 Jahre fix
     */
    TwoYearsFix = 2,

    /**
     * 3 Jahre fix
     */
    ThreeYearsFix = 3,

    /**
     * 4 Jahre fix
     */
    FourYearsFix = 4,

    /**
     * 5 Jahre fix
     */
    FiveYearsFix = 5,

    /**
     * 6 Jahre fix
     */
    SixYearsFix = 6,

    /**
     * 7 Jahre fix
     */
    SevenYearsFix = 7,

    /**
     * 8 Jahre fix
     */
    EightYearsFix = 8,

    /**
     * 9 Jahre fix
     */
    NineYearsFix = 9,

    /**
     * 10 Jahre fix
     */
    TenYearsFiy = 10
}
