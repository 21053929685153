import { EnumSettings } from './../../enum.settings';
import { AdditionalFacilities as BafAdditionalFacilities } from './baf-additional-facilities.enum';

/**
 * [MEHRFACHAUSWAHL] Ausstattungsmerkmale
 */
export enum AdditionalFacilities {
    /**
     * Keine
     */
    None = 0,

    /**
     * Hochwertige Innentüren (Vollglas)
     */
    HighQualityInnerDoors = 1,

    /**
     * Flächenheizung (Wand-/ Fußbodenheizung)
     */
    PanelHeating = 2,

    /**
     * Großzügige Belichtungsflächen
     */
    LargeIllumninationAreas = 4,

    /**
     * Lift
     */
    Elevator = 16,

    /**
     * Sauna oder Infrarotkabine
     */
    SaunaOrInfraredCabin = 32,

    /**
     * Alarmanlage / Videoüberwachung
     */
    SecuritySystem = 64,

    /**
     * Elektrisches Garagentor
     */
    ElectricGarageDoor = 128,

    /**
     * Exklusive Bodenbeläge (u.a. Natursteinböden, Sternparkett etc.)
     */
    ExclusiveFloorCovering = 256,
}

export namespace AdditionalFacilities {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {AdditionalFacilities | BafAdditionalFacilities | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: AdditionalFacilities | BafAdditionalFacilities | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'AdditionalFacilities', Object.assign(AdditionalFacilities, BafAdditionalFacilities), plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('AdditionalFacilities', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {AdditionalFacilities | BafAdditionalFacilities | undefined | null} value Enum-Wert
     * @param {AdditionalFacilities | BafAdditionalFacilities} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: AdditionalFacilities | BafAdditionalFacilities | undefined | null, flag: AdditionalFacilities | BafAdditionalFacilities): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
