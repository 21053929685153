/**
 * Channel Typen
 */
export enum ChannelType
{
    /**
     * Mobil
     */
    M,

    /**
     * stationär
     */
    St,
}
