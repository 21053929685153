import { IDropAreaViewModel, IDropFolderViewModel } from 'app/modules/smartdoc/data';
import { AAGUID } from 'app/modules/smartdoc/data/types/aaguid';

/**
 * Action beim Laden SmartDoc Struktur
 */
export class SmartDocConfigurationFoldersLoaded {
    public static readonly type = 'smartDocConfiguration.loaded';

    /**
     * Konstruktor
     *
     * @param {IDropFolderViewModel[]} payload SmartDoc Folder konfiguration
     */
    public constructor(public payload: IDropFolderViewModel[]) { }
}

/**
 * Action beim Folder änderung
 */
export class AddedOrUpdatedDropFolder {
    public static readonly type = 'smartDocConfiguration.addedOrUpdatedDropFolder';


    /**
     * Konstruktor
     *
     * @param {IDropFolderViewModel} payload SmartDoc Folder konfiguration
     */
    public constructor(public payload: IDropFolderViewModel) { }
}

/**
 * Action beim Area änderung
 */
export class AddedOrUpdatedDropArea {
    public static readonly type = 'smartDocConfiguration.AddedOrUpdatedDropArea';


    /**
     * Konstruktor
     *
     * @param {IDropAreaViewModel} payload SmartDoc Area konfiguration
     */
    public constructor(public payload: IDropAreaViewModel) { }
}
/**
 *  Action beim Folder löschen
 */
export class DeletedDropFolder {
    public static readonly type = 'smartDocConfiguration.deletedDropFolder';

    /**
     * Konstruktor
     *
     * @param {AAGUID} payload SmartDoc Folder ID
     */
    public constructor(public payload: AAGUID) { }
}

/**
 * Action beim Area löschen
 */
export class DeletedDropArea {
    public static readonly type = 'smartDocConfiguration.deletedDropArea';

    /**
     * Konstruktor
     *
     * @param {AAGUID} payload SmartDoc Folder ID
     */
    public constructor(public payload: AAGUID) { }
}
