import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Komponente für den Such-Dialog
 */
@Component({
    selector: 'finprocess-search-messages-dialog',
    templateUrl: './search-messages-dialog.component.html',
    styleUrls: ['./search-messages-dialog.component.scss'],
})
export class SearchMessagesDialogComponent {
    public searchterm?: string;

    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     */
    public constructor(public dialogRef: MatDialogRef<SearchMessagesDialogComponent>) {

    }

    /**
     * Prüft Tastatureingabe auf Enter
     *
     * @param {KeyboardEvent} e Keyeingabe
     */
    public handleKeyDown(e: KeyboardEvent): void {
        if (e.key === 'Enter') {
            this.dialogRef.close(
                this.searchterm,
            );
        }
    }
}
