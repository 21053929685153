import { translations } from './translatations';

/**
 * Klasse mit Enum-Einstellungen und Enum-Überstzungen
 */
export class EnumSettings {
    /**
     * Sprache, die für die Lokalisierung verwendet wird
     */
    public static locale = 'de';

    /**
     * Default-Sprache, wenn Lokalisierung nicht existiert
     */
    private static defaultLocale = 'de';

    /**
     * Prüft, ob bei einem Bitenum ein bestimmtes Flag gesetzt ist
     *
     * @param {any} value Enum-Wert
     * @param {any} flag Zu prüfendes Flag
     * @returns {boolean} Ist bei Enum bestimmtes Flag gesetzt
     */
    public static hasFlag<T>(value: T | undefined | null, flag: T): boolean | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }
        else {
            return ((value as unknown as number) & (flag as unknown as number)) !== 0;
        }
    }

    /**
     * Übersetzt einen Enum-Wert
     *
     * @param {string | number} value Enum-Wert als Zahl oder Zeichenkette
     * @param {string} enumType Enum-Typ als Zeichenkette
     * @param {any} enumOb Enum als Objekt
     * @param {string} locale Sprache
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    public static translate<T>(value: string | number, enumType: string, enumOb: T, locale?: string): string | undefined {
        const usedLocale = EnumSettings.getLocale(locale);
        if (typeof value === 'string') {
            value = (enumOb as unknown as Record<string, number>)[value];
        }

        if (usedLocale !== undefined && (Object.prototype.hasOwnProperty.call(translations, usedLocale) as boolean) && (Object.prototype.hasOwnProperty.call(translations[usedLocale], enumType) as boolean)) {
            return translations[usedLocale][enumType][value];
        }
        else {
            return undefined;
        }
    }

    /**
     * Übersetzt das Label eines Enums
     *
     * @param {string} enumType Enum-Typ als Zeichenkette
     * @param {string} locale Sprache
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    public static translateLabel(enumType: string, locale?: string) : string | undefined {
        const usedLocale = EnumSettings.getLocale(locale);
        if (usedLocale !== undefined && Object.prototype.hasOwnProperty.call(translations, usedLocale) as boolean) {
            return translations[usedLocale]['enumLabels'][enumType] as string;
        }
        else {
            return undefined;
        }
    }

    /**
     * Liefert die Lokalisierung abhängig von der Verfügbarkeit
     *
     * @param {string | null} locale Gesuchte Sprache
     * @returns {string | undefined} Genutzte Sprache
     */
    private static getLocale(locale?: string | null) : string | undefined {
        let usedLocale = locale !== undefined && locale !== null && locale.length >= 2 ? locale : EnumSettings.locale;
        if (!(Object.prototype.hasOwnProperty.call(translations, usedLocale) as boolean)) {
            if (usedLocale.length > 2 && (Object.prototype.hasOwnProperty.call(translations, usedLocale.substr(0, 2)) as boolean)) {
                usedLocale = usedLocale.substr(0, 2);
            }
            else if (Object.prototype.hasOwnProperty.call(translations, EnumSettings.locale) as boolean) {
                usedLocale = EnumSettings.locale;
            }
            else {
                usedLocale = EnumSettings.defaultLocale;
            }
        }
        return usedLocale;
    }
}
