import { Collateralization, CreditPurpose, MarketValueType, ObjectPurposeType, RealEstateType } from '@ntag-ef/finprocess-enums';

import { IMorixRatingAssignment } from '../interfaces';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService } from './core/base-calculation.service';

/**
 * @typedef { import('../errors').ArgumentError } ArgumentError
 */

/**
 * Berechnungsmethoden zum Objekt
 */
export class RealEstateCalculationService extends BaseCalculationService {

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet Summe der Kaufnebenkosten
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.realEstateTaxes Grunderwerbssteuer in €
     * @param {number} params.landregisterEntry Eintragung Eigentumsrecht in €
     * @param {number} params.notaryCosts Errichtung Kaufvertrag/Treuhand (Notar) in €
     * @param {number} params.brokerageCosts Maklergebühr in €
     * @param {number | undefined} params.otherAdditionalCosts Sonstige Kaufnebenkosten in €
     * @returns {number} Kaufnebenkosten in €
     */
    public sumAdditionalCosts({ realEstateTaxes, landregisterEntry, notaryCosts, brokerageCosts, otherAdditionalCosts }: {
        /**
         * Grunderwerbssteuer in €
         */
        realEstateTaxes: number;
        /**
         * Eintragung Eigentumsrecht in €
         */
        landregisterEntry: number;
        /**
         * Errichtung Kaufvertrag/Treuhand (Notar) in €
         */
        notaryCosts: number;
        /**
         * Maklergebühr in €
         */
        brokerageCosts: number;
        /**
         * Sonstige Kaufnebenkosten in €
         */
        otherAdditionalCosts?: number;
    }): number {
        return realEstateTaxes + landregisterEntry + notaryCosts + brokerageCosts + (!CalculationHelperService.isNullOrNaN(otherAdditionalCosts) ? (otherAdditionalCosts as number) : 0);
    }

    /**
     * Berechnet Grunderwerbssteuer
     * Berechnung anhand Summe der für die Kaufnebenkosten relevanten Kosten erfolgt nur, wenn Grunderwerbssteuer in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumPricesRelevantForAdditionalCosts Summe der für die Kaufnebenkosten relevanten Kosten in €
     * @param {number | undefined} params.realEstateTaxesAmount Grunderwerbssteuer in €
     * @param {number | undefined} params.realEstateTaxesFee Grunderwerbssteuer in %
     * @returns {number} Grunderwerbssteuer in €
     */
    public realEstateTaxes({ sumPricesRelevantForAdditionalCosts, realEstateTaxesAmount, realEstateTaxesFee }: {
        /**
         * Summe der für die Kaufnebenkosten relevanten Kosten in €
         */
        sumPricesRelevantForAdditionalCosts: number;
        /**
         * Grunderwerbssteuer in €
         */
        realEstateTaxesAmount?: number;
        /**
         * Grunderwerbssteuer in %
         */
        realEstateTaxesFee?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(realEstateTaxesAmount) ? (realEstateTaxesAmount as number) : CalculationHelperService.round(sumPricesRelevantForAdditionalCosts * (!CalculationHelperService.isNullOrNaN(realEstateTaxesFee) ? (realEstateTaxesFee as number) : 0) / 100, 2);
    }

    /**
     * Berechnet Kosten für Eintragung Eigentumsrecht
     * Berechnung anhand Summe der für die Kaufnebenkosten relevanten Kosten erfolgt nur, wenn Eintragung Eigentumsrecht in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumPricesRelevantForAdditionalCosts Summe der für die Kaufnebenkosten relevanten Kosten in €
     * @param {number | undefined} params.landregisterEntryAmount Eintragung Eigentumsrecht in €
     * @param {number | undefined} params.landregisterEntryFee Eintragung Eigentumsrecht in %
     * @returns {number} Eintragung Eigentumsrecht in €
     */
    public landregisterEntry({ sumPricesRelevantForAdditionalCosts, landregisterEntryAmount, landregisterEntryFee }: {
        /**
         * Summe der für die Kaufnebenkosten relevanten Kosten in €
         */
        sumPricesRelevantForAdditionalCosts: number;
        /**
         * Eintragung Eigentumsrecht in €
         */
        landregisterEntryAmount?: number;
        /**
         * Eintragung Eigentumsrecht in %
         */
        landregisterEntryFee?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(landregisterEntryAmount) ? (landregisterEntryAmount as number) : CalculationHelperService.round(sumPricesRelevantForAdditionalCosts * (!CalculationHelperService.isNullOrNaN(landregisterEntryFee) ? (landregisterEntryFee as number) : 0) / 100, 2);
    }

    /**
     * Berechnet Kosten für Errichtung Kaufvertrag/Treuhand (Notar)
     * Berechnung anhand Summe der für die Kaufnebenkosten relevanten Kosten erfolgt nur, wenn Errichtung Kaufvertrag/Treuhand (Notar) in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumPricesRelevantForAdditionalCosts Summe der für die Kaufnebenkosten relevanten Kosten in €
     * @param {number | undefined} params.notaryCostsAmount Errichtung Kaufvertrag/Treuhand (Notar) in €
     * @param {number | undefined} params.notaryCostsFee Errichtung Kaufvertrag/Treuhand (Notar) in %
     * @returns {number} Errichtung Kaufvertrag/Treuhand (Notar) in €
     */
    public notaryCosts({ sumPricesRelevantForAdditionalCosts, notaryCostsAmount, notaryCostsFee }: {
        /**
         * Summe der für die Kaufnebenkosten relevanten Kosten in €
         */
        sumPricesRelevantForAdditionalCosts: number;
        /**
         * Errichtung Kaufvertrag/Treuhand (Notar) in €
         */
        notaryCostsAmount?: number;
        /**
         * Errichtung Kaufvertrag/Treuhand (Notar) in %
         */
        notaryCostsFee?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(notaryCostsAmount) ? (notaryCostsAmount as number) : CalculationHelperService.round(sumPricesRelevantForAdditionalCosts * (!CalculationHelperService.isNullOrNaN(notaryCostsFee) ? (notaryCostsFee as number) : 0) / 100, 2);
    }

    /**
     * Berechnet Maklergebühr
     * Berechnung anhand Summe der für die Kaufnebenkosten relevanten Kosten erfolgt nur, wenn Maklergebühr in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumPricesRelevantForAdditionalCosts Summe der für die Kaufnebenkosten relevanten Kosten in €
     * @param {number | undefined} params.brokerageCostsAmount Maklergebühr in €
     * @param {number | undefined} params.brokerageCostsFee Maklergebühr in %
     * @returns {number} Maklergebühr in €
     */
    public brokerageCosts({ sumPricesRelevantForAdditionalCosts, brokerageCostsAmount, brokerageCostsFee }: {
        /**
         * Summe der für die Kaufnebenkosten relevanten Kosten in €
         */
        sumPricesRelevantForAdditionalCosts: number;
        /**
         * Maklergebühr in €
         */
        brokerageCostsAmount?: number;
        /**
         * Maklergebühr in %
         */
        brokerageCostsFee?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(brokerageCostsAmount) ? (brokerageCostsAmount as number) : CalculationHelperService.round(sumPricesRelevantForAdditionalCosts * (!CalculationHelperService.isNullOrNaN(brokerageCostsFee) ? (brokerageCostsFee as number) : 0) / 100, 2);
    }

    /**
     * Berechnet Summe der Projektkosten
     *
     * @param {object} params Parameterobjekt
     * @param {CreditPurpose | undefined} params.creditPurpose Finanzierungszweck
     * @param {number} params.currentAmountCoveredLiabilites Summe derzeitiger Aushaftungen abgedeckter bestehender Verpflichtungen in €
     * @param {number} params.sumAdditionalCosts Kaufnebenkosten in €
     * @param {number | undefined} params.purchasePrice Kaufpreis in €
     * @param {number | undefined} params.lotPrice Grundpreis in €
     * @param {number | undefined} params.developmentCosts Aufschließungskosten in €
     * @param {number | undefined} params.constructionCosts Baukosten / Küche in €
     * @param {number | undefined} params.refurbishmentCosts Renovierungskosten in €
     * @param {number | undefined} params.constructionCostsOverrun Baukostenüberschreitung in €
     * @param {number | undefined} params.otherCosts Einrichtung in €
     * @param {number | undefined} params.reschedulingAmount Umschuldungsbetrag in €
     * @returns {number} Projektkosten in €
     */
    public sumProjectCosts({ creditPurpose, currentAmountCoveredLiabilites, sumAdditionalCosts, purchasePrice, lotPrice, developmentCosts, constructionCosts, refurbishmentCosts, constructionCostsOverrun, otherCosts, reschedulingAmount }: {
        /**
         * Finanzierungszweck
         */
        creditPurpose?: CreditPurpose;
        /**
         * Summe derzeitiger Aushaftungen abgedeckter bestehender Verpflichtungen in €
         */
        currentAmountCoveredLiabilites: number;
        /**
         * Kaufnebenkosten in €
         */
        sumAdditionalCosts: number;
        /**
         * Kaufpreis in €
         */
        purchasePrice?: number;
        /**
         * Grundpreis in €
         */
        lotPrice?: number;
        /**
         * Aufschließungskosten in €
         */
        developmentCosts?: number;
        /**
         * Baukosten / Küche in €
         */
        constructionCosts?: number;
        /**
         * Renovierungskosten in €
         */
        refurbishmentCosts?: number;
        /**
         * Baukostenüberschreitung in €
         */
        constructionCostsOverrun?: number;
        /**
         * Einrichtung in €
         */
        otherCosts?: number;
        /**
         * Umschuldungsbetrag in €
         */
        reschedulingAmount?: number;
    }): number {
        return (!CalculationHelperService.isNullOrNaN(purchasePrice) ? (purchasePrice as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(lotPrice) ? (lotPrice as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(developmentCosts) ? (developmentCosts as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(constructionCosts) ? (constructionCosts as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(refurbishmentCosts) ? (refurbishmentCosts as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(constructionCostsOverrun) ? (constructionCostsOverrun as number) : 0) +
            sumAdditionalCosts +
            (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(reschedulingAmount) ? (reschedulingAmount as number) : 0) +
            (creditPurpose === CreditPurpose.Rescheduling ? currentAmountCoveredLiabilites : 0);
    }

    /**
     * Berechnet Summe der für die Kaufnebenkosten relevanten Kosten
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.purchasePriceRelevantForAdditionalCosts Kaufpreis relevant für Kaufnebenkosten
     * @param {number | undefined} params.purchasePrice Kaufpreis in €
     * @param {number | undefined} params.lotPriceRelevantForAdditionalCosts Grundpreis relevant für Kaufnebenkosten
     * @param {number | undefined} params.lotPrice Grundpreis in €
     * @param {number | undefined} params.developmentCostsRelevantForAdditionalCosts Aufschließungskosten relevant für Kaufnebenkosten
     * @param {number | undefined} params.developmentCosts Aufschließungskosten in €
     * @param {number | undefined} params.constructionCostsRelevantForAdditionalCosts Baukosten / Küche relevant für Kaufnebenkosten
     * @param {number | undefined} params.constructionCosts Baukosten / Küche in €
     * @returns {number} Summe der für die Kaufnebenkosten relevanten Kosten in €
     */
    public sumPricesRelevantForAdditionalCosts({ purchasePriceRelevantForAdditionalCosts, purchasePrice, lotPriceRelevantForAdditionalCosts, lotPrice, developmentCostsRelevantForAdditionalCosts, developmentCosts, constructionCostsRelevantForAdditionalCosts, constructionCosts }: {
        /**
         * Kaufpreis relevant für Kaufnebenkosten
         */
        purchasePriceRelevantForAdditionalCosts?: boolean;
        /**
         * Kaufpreis in €
         */
        purchasePrice?: number;
        /**
         * Grundpreis relevant für Kaufnebenkosten
         */
        lotPriceRelevantForAdditionalCosts?: boolean;
        /**
         * Grundpreis in €
         */
        lotPrice?: number;
        /**
         * Aufschließungskosten relevant für Kaufnebenkosten
         */
        developmentCostsRelevantForAdditionalCosts?: boolean;
        /**
         * Aufschließungskosten in €
         */
        developmentCosts?: number;
        /**
         * Baukosten / Küche relevant für Kaufnebenkosten
         */
        constructionCostsRelevantForAdditionalCosts?: boolean;
        /**
         * Baukosten / Küche in €
         */
        constructionCosts?: number;
    }): number {
        return (purchasePriceRelevantForAdditionalCosts === true ? (!CalculationHelperService.isNullOrNaN(purchasePrice) ? (purchasePrice as number) : 0) : 0) +
            (lotPriceRelevantForAdditionalCosts === true ? (!CalculationHelperService.isNullOrNaN(lotPrice) ? (lotPrice as number) : 0) : 0) +
            (developmentCostsRelevantForAdditionalCosts === true ? (!CalculationHelperService.isNullOrNaN(developmentCosts) ? (developmentCosts as number) : 0) : 0) +
            (constructionCostsRelevantForAdditionalCosts === true ? (!CalculationHelperService.isNullOrNaN(constructionCosts) ? (constructionCosts as number) : 0) : 0);
    }

    /**
     * Berechnet den Marktwert nach, um ggf. Aufschläge zu berücksichtigen
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.marketValue Marktwert in €
     * @param {number | undefined} params.purchasePrice Kaufpreis in €
     * @param {MarketValueType | undefined} params.marketValueType Marktwertermittlungstyp
     * @param {CreditPurpose | undefined} params.creditPurpose Finanzierungszweck
     * @param {RealEstateType | undefined} params.realEstateType Objektart
     * @returns {number | undefined} Marktwert in €
     */
    public calculatedMarketValue({ marketValue, purchasePrice, marketValueType, creditPurpose, realEstateType }: {
        /**
         * Marktwert, ohne Aufschläge in €
         */
        marketValue?: number;
        /**
         *  Kaufpreis in €
         */
        purchasePrice?: number;
        /**
         * Marktwertermittlungstyp
         */
        marketValueType?: MarketValueType;
        /**
         * Finanzierungszweck
         */
        creditPurpose?: CreditPurpose;
        /**
         * Objektart
         */
        realEstateType?: RealEstateType;
    }): number | undefined {
        if (CalculationHelperService.isNullOrNaN(marketValue)) {
            return undefined;
        }
        else if (marketValueType === MarketValueType.Liebe &&
            creditPurpose === CreditPurpose.Purchase &&
            realEstateType !== undefined &&
            (realEstateType as unknown) !== null &&
            [RealEstateType.Flat, RealEstateType.SemiDetachedHouse, RealEstateType.DetachedHouse, RealEstateType.RowHouse, RealEstateType.InvestmentFlat, RealEstateType.Lot].includes(realEstateType) &&
            !CalculationHelperService.isNullOrNaN(purchasePrice) &&
            (purchasePrice as number) > (marketValue as number)) {
            return CalculationHelperService.round(Math.min((purchasePrice as number), (marketValue as number) * 1.2), 2);
        }
        else {
            return marketValue;
        }
    }

    /**
     * Liefert das MORIX-Rating zu einer Postleitzahl, wenn vorhanden
     *
     * @param {object} params Parameterobjekt
     * @param {IMorixRatingAssignment[]} params.morixRatingAssignments Zuordnungen von Postleitzahlen zu Morix-Rating
     * @param {string} params.zip Postleitzahl
     * @returns {number | undefined} MORIX-Rating
     */
    public getMorixRating({ morixRatingAssignments, zip }: {
        /**
         * Zuordnungen von Postleitzahlen zu Morix-Rating
         */
        morixRatingAssignments: IMorixRatingAssignment[];
        /**
         * Postleitzahl
         */
        zip: string;
    }): number | undefined {
        return morixRatingAssignments.find(it => it.zip === zip)?.morixRating;
    }


    /**
     * Liefert das MORIX-Rating vom dem auf die neue Finanzierung besicherten Objekt mit dem höchsten Marktwert,
     *
     * @param {object} params Parameterobjekt
     * @param {Array<object>} params.realEstates Objekte
     * @returns {number | undefined} MORIX-Rating
     */
    public getMorixRatingFromCollateralizationNewFinancingWithHighestMarketValue({ realEstates }: {
        /**
         * Objekte
         */
        realEstates: Array<{
            /**
             * Objektart
             */
            objectPurpose: ObjectPurposeType;

            /**
             * Zur Besicherung
             */
            collateralization: Collateralization;

            /**
             * Marktwert, ohne Aufschläge in €
             */
            marketValue?: number;
            /**
             *  Kaufpreis in €
             */
            purchasePrice?: number;
            /**
             * Marktwertermittlungstyp
             */
            marketValueType?: MarketValueType;
            /**
             * Finanzierungszweck
             */
            creditPurpose?: CreditPurpose;
            /**
             * Objektart
             */
            realEstateType?: RealEstateType;

            /**
             * Morix-Rating
             */
            morixRating?: number | undefined;
        }>;
    }): number | undefined {
        const filteredRealEstates = realEstates.filter(it => it.collateralization === Collateralization.NewFinancing && it.objectPurpose !== ObjectPurposeType.ForSale);
        if (filteredRealEstates.length === 0) {
            return undefined;
        }
        filteredRealEstates.sort((a, b) => {
            const aMarketValue = (a.objectPurpose === ObjectPurposeType.Finance ? this.calculatedMarketValue({ ...a }) : a.marketValue) ?? 0;
            const bMarketValue = (b.objectPurpose === ObjectPurposeType.Finance ? this.calculatedMarketValue({ ...b }) : b.marketValue) ?? 0;

            return bMarketValue - aMarketValue;
        });

        return filteredRealEstates[0].morixRating;
    }
}
