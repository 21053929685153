<div class="overwrite-value">
    <span [class.icon-padding-right]="iconPosition() === 'right'">
        @if(dateFormat() && currentValue()) {
            {{ translatedCurrentValueKey() | date : 'dd.MM.yyyy' }}
        } @else if(numberFormat() && currentValue()) {
            {{ translatedCurrentValueKey() | number : numberFormat() }}
        } @else {
            {{ translatedCurrentValueKey() | translate }}
        }
    </span>
    @if (currentValue() !== originalValue()) {
        @if(dateFormat() && originalValue()) {
            <mat-icon [class.icon-right]="iconPosition() === 'right'" [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | date : 'dd.MM.yyyy') }">
                info
            </mat-icon>
        } @else if(numberFormat() && originalValue()) {
            <mat-icon [class.icon-right]="iconPosition() === 'right'" [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | number : numberFormat() : locale) }">
                info
            </mat-icon>
        } @else {
            <mat-icon [class.icon-right]="iconPosition() === 'right'" [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | translate) }">
                info
            </mat-icon>
        }
    }
</div>