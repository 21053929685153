/* eslint-disable jsdoc/require-returns */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IDocument } from 'app/modules/financing/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, catchError, iif, mergeMap, of, tap, throwError} from 'rxjs';

/**
 * system documents service for uploading and retrieving documents
 */
@Injectable({
    providedIn: 'root',
})
export class SystemdocumentsService {
  
    /**
     * Standard Konstruktor
     *
     * @param {AuthorizationService} authorizationService Auorizarion Service
     * @param {HttpClient} httpClient httpClient
     * @param {ConfigService} config config service
     * @param {NotificationService} notificationService notification service
     */
    public constructor(private authorizationService: AuthorizationService, private httpClient: HttpClient, private config: ConfigService, private notificationService: NotificationService) {}


    /**
     * get all system documents
     */
    public getSystemDocuments(): Observable<IDocument[]> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IDocument[]>(`${this.config.getEnvironment().apiUrl}/Document/getsystemdocuments`),
                of([]),
            )),
        );
    }

    /**
     * get single system file
     *
     * @param {UUID} id id for a systemfile
     */
    public getSystemFile(id: UUID): Observable<string | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/Document/getsystemfile`, {
                    params: {
                        id,
                    },
                    responseType: 'text',
                }),
                of(undefined),
            )),
        );
    }


    /**
     * Upload system document 
     * 
     * @param {File} file file that should be uploaded (pdf, png, jpeg)
     * @param {DocumentType} type document type
     */
    public uploadSystemDocument<T extends IDocument>(file: File, type: DocumentType): Observable<T[] | undefined> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('type', type.toString());
        formData.append('mapId', '11111111-1111-1111-1111-111111111111');
        return this.authorizationService.checkPermissions$(Role.SystemDocumentsAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<T[]>(`${this.config.getEnvironment().apiUrl}/Document`, formData),
                of(undefined),
            )),
            catchError(this.handleError),
            tap(response => {
                if (!response) {
                    this.notificationService.toast('Der Upload war erfolgreich.');
                } else {
                    this.notificationService.toast('Der Upload war nicht erfolgreich.');
                }
            }),
        );
    }

    /**
     * handles Errors from file upload
     * 
     * @param {HttpErrorResponse} error error
     */
    private handleError(error: HttpErrorResponse): Observable<never> {
        let errorMessage = 'An error occurred';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(() => new Error(errorMessage))
    }
}
