import { WorkflowType } from '../../enums';
import { Gender, LegalType } from '../../enums/customer-profile';
import { IMidtermModel } from '../../interfaces';

export const UPDATE_CUSTOMER: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'updateCustomer',

    ndg :{ type: WorkflowType.String },

    automaticJoin :{ type: WorkflowType.String },
    /**
     * Bank Code
     */
    bankCode:{ type: WorkflowType.Number },

    birthDate:{ type: WorkflowType.Date },

    birthPlace:{ type: WorkflowType.String },

    buddyBank :{ type: WorkflowType.Boolean },

    businessName:{ type: WorkflowType.String },

    customerLinks:{ type: WorkflowType.String },

    familyMembers :{ type: WorkflowType.Number },

    gender:{ type: WorkflowType.Enum, enumObject: Gender, enumLabel: 'Gender' },

    headingType:{ type: WorkflowType.String },

    jointHeading:{ type: WorkflowType.String },

    language:{ type: WorkflowType.String },

    legalType :{type: WorkflowType.Enum, enumObject: LegalType, enumLabel: 'LegalType' },

    mainCityDescription :{ type: WorkflowType.String },

    mainCountryDescription :{ type: WorkflowType.String },

    manager :{ type: WorkflowType.String },

    nickname :{ type: WorkflowType.String },

    provinceCode :{ type: WorkflowType.String },

    resident :{ type: WorkflowType.String },

    firstName:{ type: WorkflowType.String },

    surname:{ type: WorkflowType.String },

    taxCode :{ type: WorkflowType.String },
}
