import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetProviderService, EntityClassType, FinancingService, IAssetProvider, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { Observable, mergeMap, of, take, tap } from 'rxjs';

import { VerifyCustomerDialogComponent } from '../verify-customer-dialog/verify-customer-dialog.component';

/**
 * Asset Provider
 */
@Component({
    selector: 'finprocess-asset-provider',
    templateUrl: './asset-provider.component.html',
    styleUrls: ['./asset-provider.component.scss'],
})
export class AssetProviderComponent implements OnInit {

    @Input()
    public assetProviders: IAssetProvider[] | undefined;

    @Input()
    public assetId!: string;

    
    @Input()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public entityClassType!: EntityClassType;


    public financingContainerID: string | undefined;

    public inputFlexDirection = 'column';

    public alwaysReadonly$: Observable<boolean> = of(true);

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;
    
    /**
     * Konstruktor
     * 
     * @param {MatDialog} dialog MatDialog Injektor
     * @param {Store} store store
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {AssetProviderService} assetProviderService AssetProviderService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {FinancingService} financingService financingService-Injektor
     */
    public constructor
    (
       private dialog: MatDialog,
       private store: Store,
       private notification: NotificationService,
       private assetProviderService: AssetProviderService,
       private translate: TranslateService,
       private waiterService: WaiterService,
       private financingService: FinancingService,
    ) {}
    /**
     * OnInit
     */
    public ngOnInit() {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing?.financingContainerID);
    }

    /**
     * Neuen Sicherheitengebern anlegen
     */
    public addAssetProvider(): void {
        const dialogRef = this.dialog.open(
            VerifyCustomerDialogComponent,
            {
                data: {
                    financingContainerId: this.financingContainerID,
                    assetId: this.assetId,
                },
                width: '80%',
                height: '80%',
                autoFocus: false,
            },
        );

        dialogRef.afterClosed().pipe(
            take(1),
            tap(() => this.waiterService.show()),
            mergeMap((data: Partial<IAssetProvider>) => {
                if (!!data) {
                    data.assetId = this.assetId;
                    return this.assetProviderService.addAssetProvider(data, this.entityClassType);
                }
                return of(void 0);
            }),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
            },
            error: err => {
                if (!!err) {
                    this.notification.toast(this.translate.instant('asset.assetProvider.notSavedToList'))
                }
                this.waiterService.hide();
            },
            complete: () => this.waiterService.hide(),                
        })
    }

    /**
     *  löschen Sicherheitengebern
     * 
     * @param {string} assetProviderId Sicherheitengebern id 
     */
    public deleteAssetProvider(assetProviderId: string): void {
        this.notification.confirmOkCancel(
            this.translate.instant('asset.assetProvider.delete'),
            this.translate.instant('asset.assetProvider.deleteAssetProvider'),
        ).subscribe(res => {
            if (res === 'submit') {
                this.waiterService.show();
                this.assetProviderService.deleteAssetProvider(this.assetId, assetProviderId, this.entityClassType).pipe(
                    take(1),
                ).subscribe({
                    next: () => {
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide()
                    },
                })
            }
        })
    }
}
