export { ADDRESSES } from './addresses';
export { COMPLEMENTARY_DATA } from './complementary-data';
export { CREATE_CUSTOMER } from './create-customer';
export { CREATE_OR_UPDATE_ADDRESSES } from './create-or-update-addresses';
export { CREATE_OR_UPDATE_COMPLEMENTARY_DATA } from './create-or-update-complementary-data';
export { CREATE_OR_UPDATE_DOCUMENTS } from './create-or-update-documents';
export { CREATE_OR_UPDATE_ECONOMIC_INFO } from './create-or-update-economic-info';
export { CREATE_OR_UPDATE_PHONES } from './create-or-update-phones';
export { DOCUMENTS } from './documents';
export { ECONOMIC_INFO } from './economic-info';
export { GET_USER_DETAILS } from './user-details';
export { IDENTIFY_CUSTOMER } from './identify-customer';
export { PHONES } from './phones';
export { UPDATE_CUSTOMER } from './update-customer';
