import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const COMPLEMENTARY_DATA: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'complementaryData',

    ndg: { type: WorkflowType.String },
}
