export enum WorkflowType {
    String,
    Number,
    Boolean,
    Array,
    Object,
    Enum,
    FlagEnum,
    Date,
    Hidden
}
