<h3>{{'asset.assetCommon.assetsAgp.header' | translate}}</h3>
<span *ngIf="assetsAgps === undefined || assetsAgps?.length === 0 " class="pl-2">{{'asset.assetCommon.assetsAgp.noData' | translate}}</span>
<span *ngFor="let assetAgp of assetsAgps" fxLayout="row" fxLayoutAlign="start" class="bg-dark-2 cool-asset ml-3 mb-3 mt-3">
    <finprocess-textbox-input *ngIf="!!assetAgp.collateralNo"
      [overwrite]="false"
      fieldName="collateralNo" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="assetAgp">
      <span label class="input-label">{{'asset.assetCommon.assetsAgp.collateralNo' | translate}}</span>
    </finprocess-textbox-input>

    <finprocess-textbox-input 
      *ngIf="!!assetAgp.withdrawalAccounts"
      [overwrite]="false"
      fieldName="withdrawalAccounts" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="assetAgp">
      <span label class="input-label">{{'asset.assetCommon.assetsAgp.withdrawalAccounts' | translate}}</span>
    </finprocess-textbox-input>

    <finprocess-select-input
    *ngIf="!!assetAgp.mortgageType"
      [items]="mortgageTypeSelectItems"
      [overwrite]="false"
      [hideHorizontalRule]="true"
      fieldName="mortgageType"
      [readonly]="alwaysReadonly$"
      [entity]="assetAgp">
      <span label class="input-label">{{'asset.assetCommon.assetsAgp.mortgageType' | translate}}</span>
    </finprocess-select-input>

    <finprocess-textbox-input 
    *ngIf="!!assetAgp.pbuValue"
      [overwrite]="false"
      fieldName="pbuValue" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="assetAgp">
      <span label class="input-label">{{'asset.assetCommon.assetsAgp.pbuValue' | translate}} </span>
    </finprocess-textbox-input>
</span>
