<div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="start center" fxFill >
    <form fxLayout="column" fxLayoutAlign="center center" #templateForm="ngForm" class="py-3 text-center">
        <h1 color="primary" class="color-primary">{{'auth.features.login.welcome' | translate }}</h1>
        <span class="mb-3">{{ 'auth.features.login.hint' | translate }}</span>
        <mat-form-field class="mb-2 mat-form-field-transparent-background" >
            <mat-label>{{ 'auth.features.login.email' | translate }}</mat-label>
            <input type="email" name="email" ngModel matInput required email>
            <mat-error *ngIf="templateForm.form.get('email')?.hasError('required')">
                {{'validation.requiredWithFieldName' | translate:{ field: ('auth.features.login.email' | translate) } }}
            </mat-error>
            <mat-error
                *ngIf="templateForm.form.get('email')?.hasError('email') && !templateForm.form.get('email')?.hasError('required')">
                {{'validation.email' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="mb-2 mat-form-field-transparent-background">
            <mat-label>{{ 'auth.features.login.password' | translate }}</mat-label>
            <input type="password" name="password" ngModel matInput required>
            <mat-error *ngIf="templateForm.form.get('password')?.hasError('required')">
                {{'validation.requiredWithFieldName' | translate:{ field: ('auth.features.login.password' | translate) } }}
            </mat-error>
        </mat-form-field>
        <mat-error *ngIf="templateForm.form.hasError('error')" class="mb-1">
            {{ 'auth.features.login.loginFailed' | translate }}
        </mat-error>
        <button [disabled]="!templateForm.valid" mat-raised-button color="primary" class="mt-2"
            (click)="login(templateForm.form.value, templateForm.form)">{{'auth.features.login.login' | translate
            }}</button>
    </form>
</div>
