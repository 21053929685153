import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigService } from 'shared/util';

import { UserDataLoaded } from '../../states';
import { IAuthStateParentDefinition } from '../../states/state.definition';

import { IUser } from './../../interfaces';

/**
 * Service zum Abruf von Nutzerdaten
 */
@Injectable()
export class UserService {

    /**
     * Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(private httpClient: HttpClient, private config: ConfigService, private store: Store) { }

    /**
     * Liefert die Daten des aktuellen Nutzers als Observable
     *
     * @returns {Observable<IUser | undefined>} Daten des aktuellen Nutzers als Observable
     */
    public get user$(): Observable<IUser | undefined> {
        return this.store.select((it: IAuthStateParentDefinition) => it.user.data);
    }

    /**
     * Liefert die Daten des aktuellen Nutzers
     *
     * @returns {IUser | undefined} Daten des aktuellen Nutzers
     */
    public get user(): IUser | undefined {
        return this.store.selectSnapshot((it: IAuthStateParentDefinition) => it.user.data);
    }

    /**
     * Läd die Daten des aktuellen Nutzers, wenn notwendig
     *
     * @returns {Observable<IUser>} Daten des nutzers als Observable
     */
    public loadUserData(): Observable<IUser> {
        return this.httpClient.head(`${this.config.getEnvironment().apiUrl}/User/UserData`, {observe: 'response'}).pipe(
            map(response => (response.headers.has('Last-Modified') && response.headers.get('Last-Modified') !== null ? response.headers.get('Last-Modified') as string : undefined)),
            mergeMap(lastModified =>
                this.store.selectOnce((it: IAuthStateParentDefinition) => it.user).pipe(mergeMap(userState =>
                    iif(
                        () => lastModified === undefined || userState.lastModified === undefined || userState.data === undefined || new Date(lastModified) > new Date(userState.lastModified),
                        this.httpClient.get<IUser>(`${this.config.getEnvironment().apiUrl}/User/UserData`).pipe(
                            mergeMap((data: IUser) =>
                                this.store.dispatch(new UserDataLoaded({
                                    data,
                                    lastModified,
                                })).pipe(
                                    map(() => data),
                                ),
                            ),
                        ),
                        of(userState.data as IUser),
                    ),
                )),
            ),
        );
    }
}
