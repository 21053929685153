<div class="modal-header">
  <h4 class="modal-title">{{'smartdoc.selection.processingDocuments' | translate}}</h4>
</div>
<div class="modal-body py-3">
  <waiter-component [isLoading]="true"></waiter-component>
  <div class="text-center">{{ textKey | translate}}</div>
</div>
<div class="modal-footer action-buttons d-flex justify-content-center">
  <button type="button" (click)="closeSmartdoc(); activeModal.closeAll()" *ngIf="closable" mat-raised-button color="primary">
  <span>{{ 'smartdoc.selection.processingFinished' | translate }}</span>
</button>
</div>
