import { EnumSettings } from './../enum.settings';

/**
 * Mandant
 */
export enum MandantType {
    /**
     * Sonstiges
     */
    Default = 0,
    /**
     * FinService
     */
    BAF = 1,
    /**
     * Homestory
     */
    Homestory = 2,
    /**
     * FinAdvisory
     */
    FinAdvisory = 3,
}

export namespace MandantType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {MandantType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: MandantType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'MandantType', MandantType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('MandantType', plocale);
}

