import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { MessagesService } from './services';
import { MessagesState } from './states';

export * from './services';

/**
 * Modul für das Laden der Dashboarddaten
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([MessagesState]),
    ],
    providers: [
        MessagesService,
    ],
})
export class MessagesDataModule { }
