import { IMessage } from './../../interfaces/messages.interface';

/**
 * Aktion beim Wechseln des Chats in den Nachrichten
 */
export class MessagesLoaded {
    public static readonly type = 'messages.chatChanged';

    /**
     * Konstruktor
     *
     * @param {string} payload Ausgewählter Chat
     */
    public constructor(public payload: IMessage[]) { }
}

/**
 * Aktion um eine neue Nachricht in den State hinzuzufügen
 */
export class AddMessages {
    public static readonly type = 'messages.addMessage';

    /**
     * Konstruktor
     *
     * @param {string} payload neue Nachricht
     */
    public constructor(public payload: IMessage) { }
}

/**
 * Aktion um den State zurückzusetzen
 */
export class ClearMessages {
    public static readonly type = 'messages.clearMessages';
}
