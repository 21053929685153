import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Komponente mit Login-Formular
 */
@Component({
    selector: 'finprocess-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {

    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router-Injektor
     */
    public constructor(private router: Router) {}

    /**
     * Navigiert zur Startseite
     *
     * @returns {Promise} Router Rückgabewert
     */
    public navigateToStart(): Promise<boolean> {
        return this.router.navigate(['/']);
    }
}
