export enum RentalIncomeType {
    /**
     * Bestehende Mieteinnahmen
     */
    ExistingRentalIncome = 0,

    /**
     * Zukünftige Mieteinnahmen
     */
    FutureRentalIncome = 1
}
