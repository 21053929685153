import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeAt from '@angular/common/locales/de-AT';
import { APP_INITIALIZER, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { translations } from '@finprocess/enums/core' //bei enum translation modul einbindung muss im appmodul die sprache im translate loader geladen werden
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ErrorHandlerModule, ON_ERROR } from '@ntag-ef/error-handler';
import { EnumTranslateLoader, EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { NtagServiceWorkerModule } from '@ntag-ef/service-worker';
import { WaiterModule } from '@ntag-ef/waiter';
import { AUTH_MODULES, AUTH_PERSISTENT_STATE_KEYS } from 'auth';
import { DASHBOARD_PERSISTENT_STATE_KEYS } from 'dashboard';
import { environment } from 'environment';
import { APP_INFO } from 'environment/app-info';
import { CONSTANTS } from 'environment/constants';
import { FINANCING_PERSISTENT_STATE_KEYS } from 'financing';
import { MasterdataDataModule } from 'masterdata';
import { NavigationModule } from 'navigation';
import { FlexLayoutModule } from 'ngx-flexible-layout'
import { LoggerModule } from 'ngx-logger';
import { PreLoadTranslationLoaderService, SharedUtilModule } from 'shared/util';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardState, DashboardType } from './modules/dashboard/data';
import { defaultData } from './modules/dashboard/data/states/dashboard/dashboard.state';
import { FinancingState } from './modules/financing/data';
import { InformationEventState } from './modules/information-events/data';
import { HttpErrorInterceptor } from './modules/shared/features/interceptors/http-error/http-error.interceptor';
import { PreLoadEnumTranslationLoaderService } from './modules/shared/util/services/pre-load-enum-translation-loader/pre-load-enum-translation-loader.service';
import { SMARTDOC_PERSITENT_STATE_KEY } from './modules/smartdoc/data/states/state.definiton';


registerLocaleData(localeDe, 'de');
registerLocaleData(localeAt, 'de-AT');

/**
 * Einstiegsmodul
 */
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        AppRoutingModule,
        SharedUtilModule.forRoot({
            appInfo: APP_INFO,
            environment,
            constants: CONSTANTS,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: PreLoadTranslationLoaderService,
            },
        }),
        EnumTranslationModule.forRoot({
            loader: {
                provide: EnumTranslateLoader,
                useClass: PreLoadEnumTranslationLoaderService, // muss translations von finprocess enumns mitladen
            },
        }),
        LoggerModule.forRoot({
            level: environment.logLevel,
            serverLoggingUrl: `${environment.apiUrl}/Log/`,
            serverLogLevel: environment.serverLogLevel,
        }),
        NotificationsModule.forRoot({
            defaultDialogConfig: {
                buttonAlign: 'end',
            },
            defaultToastConfig: {
                verticalPosition: 'top',
            },
        }),
        ErrorHandlerModule.forRoot({
            contactMail: CONSTANTS.contactMail,
            contactPhone: CONSTANTS.contactPhone,
            hideMessage: environment.errorHandlingHideMessage,
        }),
        NtagServiceWorkerModule.register({
            script: 'ngsw-worker.js',
            serviceWorkerConfig: {
                enabled: environment.production && environment.serviceWorkerEnabled,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000',
            },
            checkForUpdates: environment.checkForUpdateConfiguration,
        }),
        FlexLayoutModule,
        WaiterModule.forRoot(),
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            migrations: [
                {
                    // Ngxs erlaubt es leider nicht mehr keine Version anzugeben, deswegen dieser Workaround
                    version: undefined as never,
                    key: DashboardState.keyName,
                    migrate: state => ({
                        currentDashboard: DashboardType.Expert,
                        dashboards: {
                            [DashboardType.Expert]: state,
                            [DashboardType.Referent]: defaultData,
                        },
                        version: 2,
                    }),
                },
            ],
            key: [
                ...AUTH_PERSISTENT_STATE_KEYS,
                ...DASHBOARD_PERSISTENT_STATE_KEYS,
                ...FINANCING_PERSISTENT_STATE_KEYS,
                ...SMARTDOC_PERSITENT_STATE_KEY,
                //https://github.com/ngxs/store/blob/master/docs/plugins/storage.md
                //speichert FinancingState in local storage (local by default), key ist FinancingState.keyName
                FinancingState.keyName,
                InformationEventState.keyName,
            ],
        }),
        ...AUTH_MODULES,
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        MasterdataDataModule,
        NavigationModule,
    ],
    providers: [
        DatePipe,
        {
            provide: APP_INITIALIZER,
            useFactory: (preLoadTranslationLoaderService: PreLoadTranslationLoaderService, preLoadEnumTranslationLoaderService: PreLoadEnumTranslationLoaderService) => (async () => {
                await preLoadEnumTranslationLoaderService.cache('./assets/i18n/enums/');
                await preLoadTranslationLoaderService.cache('./assets/i18n/');
            }),
            deps: [PreLoadTranslationLoaderService, PreLoadEnumTranslationLoaderService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: environment.defaultLang,
        },
        {
            provide: ON_ERROR,
            useFactory: (matDlg: MatDialog, ngZone: NgZone) => () => {
                ngZone.run(() => {
                    matDlg.closeAll();
                });
            },
            deps: [MatDialog, NgZone],
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                showDelay: 800,
                hideDelay: 200,
                touchendHideDelay: 100,
                disableTooltipInteractivity: true,
            } as MatTooltipDefaultOptions,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpErrorInterceptor,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
