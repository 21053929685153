import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AuthenticationDirective, AuthorizationDirective, OrganisationalUnitResponsibilityDirective } from './directives';
import { AuthenticationGuard, AuthorizationGuard, LockedGuard, OrganisationalUnitFilterGuard } from './guards';

export * from './interfaces';
export { AuthenticationDirective, AuthorizationDirective, OrganisationalUnitResponsibilityDirective } from './directives';
export { AuthenticationGuard, AuthorizationGuard, LockedGuard, OrganisationalUnitFilterGuard } from './guards';

/**
 * Modul für Authentifizierung und Authorisierungsprüfung
 */
@NgModule({
    declarations: [
        AuthenticationDirective,
        AuthorizationDirective,
        OrganisationalUnitResponsibilityDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
    ],
    exports: [
        AuthenticationDirective,
        AuthorizationDirective,
        OrganisationalUnitResponsibilityDirective,
    ],
    providers: [
        AuthenticationGuard,
        AuthorizationGuard,
        LockedGuard,
        OrganisationalUnitFilterGuard,
    ],
})
export class AuthChecksModule {
}
