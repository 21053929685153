export enum MortgageType {

    /**
     * Höchstbetragshypothek
     */
    MaxAmountMortgage = 0,

    /**
     * Darlehenshypothek
     */
    FixedAmountMortgage = 1,
}
