import { ISmartDocSortResultFormModel } from '../../interfaces/smartdoc/smartdoc-sort-result-form.model';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocThumbnailSortResultStoredPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Ergebnisse, die gespeichert werden soll
     */
    results: ISmartDocSortResultFormModel[];
}

/**
 * Klasse für Aktion
 */
export class SmartDocThumbnailSortResultStored {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocThumbanilSortResultStored';
    /**
     * Constructor
     *
     * @param {ISmartDocThumbnailSortResultStoredPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocThumbnailSortResultStoredPayloadType) { }
}
