export enum CollateralType {
    /**
     * Neue Sicherheit
     */
    New = 0,

    /**
     * Bestehende Sicherheit
     */
    Existing = 1,
}
