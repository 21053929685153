import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Logout } from 'app/modules/auth/data';
import { UUID } from 'app/modules/shared';

import { IDebitor, IExistingRentalIncome, IFutureRentalIncome } from '../../interfaces';
import { FinancingLeaved } from '../../states/financing-tab/financing-tab.actions';

import { ExistingRentalIncomeAdded, ExistingRentalIncomeDeleted, ExistingRentalIncomeUpdated, ExistingRentalIncomesLoaded, FutureRentalIncomeAdded, FutureRentalIncomeDeleted, FutureRentalIncomeUpdated, FutureRentalIncomesLoaded } from './debitor.actions';

/**
 * Interface for the DebitorData state model
 * 
 * Debitor as partial for now, until the debitors are moved from the financing state to the debitor state.
 * For now this state is only used for the rental incomes.
 */
export interface IDebitorStateModel {
    debitors: Partial<IDebitor>[];
}

const defaultData: IDebitorStateModel = {
    debitors: [],
};

/**
 * State for the debitor data
 */
@State<IDebitorStateModel>({
    name: DebitorState.keyName,
    defaults: defaultData,
})
@Injectable()
export class DebitorState {

    public static readonly keyName = 'debitor';

    /**
     * Selector for existing rental incomes of a debitor
     * 
     * @param {IDebitorStateModel} state Current state
     * @returns {(debitorId: UUID) => IExistingRentalIncome[]} Function to get the existing rental incomes of a debitor
     */
    @Selector()
    public static existingRentalIncomesByDebitor(state: IDebitorStateModel): (debitorId: UUID) => IExistingRentalIncome[] {
        return (debitorId: UUID) => {
            const debitor = state.debitors.find(d => d.id === debitorId);
            return debitor?.existingRentalIncomes ?? [];
        }
    }

    /**
     * Selector for future rental incomes of a debitor
     * 
     * @param {IDebitorStateModel} state Current state
     * @returns {(debitorId: UUID) => IFutureRentalIncome[]} Function to get the future rental incomes of a debitor
     */
    @Selector()
    public static futureRentalIncomesByDebitor(state: IDebitorStateModel): (debitorId: UUID) => IFutureRentalIncome[] {
        return (debitorId: UUID) => {
            const debitor = state.debitors.find(d => d.id === debitorId);
            return debitor?.futureRentalIncomes ?? [];
        }
    }

    /**
     * Action when the existing rental incomes of a debitor are loaded
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {ExistingRentalIncomesLoaded} payload ExistingRentalIncomesLoaded Action
     */
    @Action(ExistingRentalIncomesLoaded)
    public existingRentalIncomesLoaded({ setState }: StateContext<IDebitorStateModel>, { payload }: ExistingRentalIncomesLoaded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.debitorId),
                updateItem(debitor => debitor.id === payload.debitorId, patch({
                    existingRentalIncomes: payload.existingRentalIncomes,
                })),
                insertItem({ id: payload.debitorId, existingRentalIncomes: payload.existingRentalIncomes }),
            ),
        }));
    }

    /**
     * Action when a new existing rental income is added
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {ExistingRentalIncomeAdded} payload ExistingRentalIncomeAdded Action
     */
    @Action(ExistingRentalIncomeAdded)
    public existingRentalIncomeAdded({ setState }: StateContext<IDebitorStateModel>, { payload }: ExistingRentalIncomeAdded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.assignedDebitorId),
                updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                    existingRentalIncomes: append([payload]),
                })),
                insertItem({ id: payload.assignedDebitorId, existingRentalIncomes: [payload] }),
            ),
        }));
    }

    /**
     * Action when an existing rental income is updated
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {ExistingRentalIncomeUpdated} payload ExistingRentalIncomeUpdated Action
     */
    @Action(ExistingRentalIncomeUpdated)
    public existingRentalIncomeUpdated({ setState }: StateContext<IDebitorStateModel>, { payload }: ExistingRentalIncomeUpdated): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                existingRentalIncomes: updateItem(existingRentalIncome => existingRentalIncome.id === payload.id, payload),
            })),
        }));
    }

    /**
     * Action when an existing rental income is deleted
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {ExistingRentalIncomeDeleted} payload ExistingRentalIncomeDeleted Action
     */
    @Action(ExistingRentalIncomeDeleted)
    public existingRentalIncomeDeleted({ setState }: StateContext<IDebitorStateModel>, { payload }: ExistingRentalIncomeDeleted): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.debitorId, patch({
                existingRentalIncomes: removeItem(existingRentalIncome => existingRentalIncome.id === payload.existingRentalIncomeId),
            })),
        }));
    }

    /**
     * Action when the future rental incomes of a debitor are loaded
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {FutureRentalIncomesLoaded} payload FutureRentalIncomesLoaded Action
     */
    @Action(FutureRentalIncomesLoaded)
    public futureRentalIncomesLoaded({ setState }: StateContext<IDebitorStateModel>, { payload }: FutureRentalIncomesLoaded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.debitorId),
                updateItem(debitor => debitor.id === payload.debitorId, patch({
                    futureRentalIncomes: payload.futureRentalIncomes,
                })),
                insertItem({ id: payload.debitorId, futureRentalIncomes: payload.futureRentalIncomes }),
            ),
        }));
    }

    /**
     * Action when a future rental income is added
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {FutureRentalIncomeAdded} payload FictionalRateUpdated Action
     */
    @Action(FutureRentalIncomeAdded)
    public futureRentalIncomeAdded({ setState }: StateContext<IDebitorStateModel>, { payload }: FutureRentalIncomeAdded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.assignedDebitorId),
                updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                    futureRentalIncomes: append([payload]),
                })),
                insertItem({ id: payload.assignedDebitorId, futureRentalIncomes: [payload] }),
            ),
        }));
    }

    /**
     * Action when a future rental income is updated
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {FutureRentalIncomeUpdated} payload FutureRentalIncomeUpdated Action
     */
    @Action(FutureRentalIncomeUpdated)
    public futureRentalIncomeUpdated({ setState }: StateContext<IDebitorStateModel>, { payload }: FutureRentalIncomeUpdated): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                futureRentalIncomes: updateItem(futureRentalIncome => futureRentalIncome.id === payload.id, payload),
            })),
        }));
    }

    /**
     * Action when a future rental income is deleted
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {FutureRentalIncomeDeleted} payload FutureRentalIncomeDeleted Action
     */
    @Action(FutureRentalIncomeDeleted)
    public futureRentalIncomeDeleted({ setState }: StateContext<IDebitorStateModel>, { payload }: FutureRentalIncomeDeleted): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.debitorId, patch({
                futureRentalIncomes: removeItem(futureRentalIncome => futureRentalIncome.id === payload.futureRentalIncomeId),
            })),
        }));
    }

    /**
     * Action when the user logs out or the current financing is closed
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public reset({ setState }: StateContext<IDebitorStateModel>): void {
        setState(defaultData);
    }
}

