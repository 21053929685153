export enum Gender {
    /**
     * männlich
     */
    Man,

    /**
     * weiblich
     */
    Woman,

    /**
     * divers
     */
    Other,
}
