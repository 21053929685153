<div [fxLayout]="layout" fxLayoutGap="20px">
    <div *ngFor="let document of documents" fxFlex="48" fxLayout="column" class="document">

        <mat-card appearance="outlined" class="example-card">
            <mat-card-header fxLayout="row">

                <mat-card-title style="font-size: medium;">
                    <span *ngIf="document.document.type !== DocumentType.Other || document.document.name === ''">
                        {{ document.document.type | finprocessEnumTranslation : 'DocumentType'}}
                    </span>
                    <span *ngIf="document.document.type === DocumentType.Other && document.document.name !== ''">
                        {{ document.document.name }}
                    </span>

                </mat-card-title>
                <mat-card-subtitle>{{document.parentName }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="thumbnails">
                    <div *ngFor="let file of document.document.files" fxLayoutGap="10px">
                        <mat-icon *ngIf="!file.hasThumbnail || !file.base64" (click)="onDocumentClick(file)"
                            class="file-thumbnail">description</mat-icon>

                        <img *ngIf="file.hasThumbnail && !!file.base64" [src]="'data:image/jpeg;base64,' + file.base64"
                            (click)="onDocumentClick(file)" class="file-thumbnail mr-2">
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>