<div class="container" fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
        <h1 class="color-primary">{{ 'navigation.administration.financing-configuration' | translate }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button mat-mini-fab color="primary" (click)="toggleLock()" *finprocessAuthorization="Role.ConditionsAdministrator"
                matTooltip="{{ (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate }}">
                <mat-icon *ngIf="locked">lock</mat-icon>
                <mat-icon *ngIf="!locked">lock_open</mat-icon>
            </button>
            <button  mat-mini-fab color="primary" *finprocessAuthorization="Role.ConditionsAdministrator" (click)="save()" [disabled]="form.pristine || form.invalid || locked" matTooltip="{{ 'button.save' | translate }}">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>


    <div fxLayout="column" class="p-3" [formGroup]="form" class="content p-3">
        <!-- Darlehen -->
        <div class="financing-configuration-divider p-1 my-2">{{ 'administration.features.financingConfiguration.comfortCredit' | translate }}</div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.interestMethod' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.fictionalRate' | translate }}</i>
        </div>

        <div *ngFor="let mainCreditFictionalRate of mainCreditFictionalRates" fxLayout="row" fxLayoutAlign="start center" [formGroup]="mainCreditFictionalRate" fxLayoutGap="20px">
            <span fxFlex="20">
                {{ mainCreditFictionalRate.get('interestMethod')?.value | finprocessEnumTranslation:'InterestMethod' }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="fictionalRate" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Ergänzungsdarlehen -->
        <div class="financing-configuration-divider p-1 my-2">{{ 'administration.features.financingConfiguration.comfortCreditPlus' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.fictionalRate' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="comfortCreditPlusFictionalRate" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Sonstige fiktive Zinssaetze -->
        <div class="financing-configuration-divider p-1 my-2">{{ 'administration.features.financingConfiguration.fictionalRates' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.liabilityFictionalRate' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="liabilityFictionalRate" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.newLiabilityFictionalRate' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="newLiabilityFictionalRate" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.constructionCreditFictionalRate' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="constructionCreditFictionalRate" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.requestedDebitRateDefault' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="requestedDebitRateDefault" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Sonstige Konfigurationen -->
        <div class="financing-configuration-divider p-1 my-2">{{ 'administration.features.financingConfiguration.otherConfigs' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.landRegisterRequest' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="landRegisterRequest" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.landRegisterExtract' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="landRegisterExtract" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.financingConfiguration.bankAccountFee' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="bankAccountFee" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Verwendung des angefragten Zinssatzes als fiktiver Zinssatz -->
        <div class="financing-configuration-divider p-1 my-2" fxLayout="row" fxLayoutAlign="space-between center">
             <span>{{ 'administration.features.financingConfiguration.fictionalRateTypeAssignments' | translate }}</span>
             <button mat-mini-fab color="primary" fxFlexAlign="start" (click)="addFictionalRateTypeAssignment()" [disabled]="locked" *finprocessAuthorization="Role.ConditionsAdministrator">
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.interestMethod' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.assumedDuration' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.creditType' | translate }}</i>
        </div>

        <div *ngFor="let fictionalRateTypeAssignment of fictionalRateTypeAssignments; let i = index" fxLayout="row" fxLayoutAlign="start center" [formGroup]="fictionalRateTypeAssignment" fxLayoutGap="20px">
            <mat-form-field appearance="fill" fxFlex="20">
                <mat-select formControlName="interestMethod">
                    <mat-option *ngFor="let interestMethod of interestMethods" [value]="interestMethod.value">
                        {{ interestMethod.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="assumedDuration" matInput type="number">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <mat-select formControlName="creditType">
                    <mat-option *ngFor="let creditType of creditTypes" [value]="creditType.value">
                        {{ creditType.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button fxFlexAlign="start" (click)="removeFictionalRateTypeAssignment(i)" [disabled]="locked" *finprocessAuthorization="Role.ConditionsAdministrator">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <!-- Regeln zum Produktsplitting -->
        <div class="financing-configuration-divider p-1 my-2" fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ 'administration.features.financingConfiguration.splittingRules' | translate }}</span>
            <button mat-mini-fab color="primary" fxFlexAlign="start" (click)="addSplittingRule()" [disabled]="locked" *finprocessAuthorization="Role.ConditionsAdministrator">
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.debitorCount' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.maxTotalMonthlyIncomeJh' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.ltv' | translate }}</i>
            <i fxFlex="20">{{ 'administration.features.financingConfiguration.morixRating' | translate }}</i>
        </div>

        <div *ngFor="let splittingRule of splittingRules; let i = index" fxLayout="row" fxLayoutAlign="start center" [formGroup]="splittingRule" fxLayoutGap="20px">
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="debitorCount" matInput type="number">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="maxTotalMonthlyIncomeJh" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="ltv" matInput  currencyMask [options]="ltvOptions" >
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="morixRating" matInput type="number">
            </mat-form-field>
            <button mat-icon-button fxFlexAlign="start" (click)="removeSplittingRule(i)" [disabled]="locked" *finprocessAuthorization="Role.ConditionsAdministrator">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

    </div>

</div>
