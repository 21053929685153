export enum LoanType {
    /**
     * Standard mortgage loan
     */
    MSTANDARD = 0,

    /**
     * Third party funded mortgage loan
     */
    //MWBB,

    /**
     * Mortgage loan to own employee
     */
    MOWNEMP = 2,

    /**
     * Mortgage loan to own employee with third party funding
     */
    //MOWNEMPWBB,
}
