export enum CustomerType {
    /**
     * -
     */
    CO,

    /**
     * Wirtschaftl. Führender
     */
    EconomicLeader,

    /**
     * Mitkreditnehmer
     */
    CoBorrower,
}
