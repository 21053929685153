<div class="container">
    <div *ngIf="currentDashboard === undefined" fxLayout="row" fxLayoutAlign="center center" class="p-4">
        <div class="dashboard-selection" fxFlex fxFlex.gt-sm="40" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center stretch">
            <h1 class="color-font">{{'dashboard.features.baseDashboard.listDashboards' | translate}}</h1>
            <span *ngFor="let dashboard of dashboards">
                <mat-card appearance="outlined" *finprocessAuthorization="dashboard.roles" class="dashboard-selector">
                    <mat-card-header>
                        <mat-card-title>
                            {{ dashboard.type | finprocessEnumTranslation:'DashboardType'}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions align="end">
                        <button mat-button (click)="setDashboard(dashboard.type)">{{'dashboard.features.baseDashboard.openDashboard' | translate}}</button>
                    </mat-card-actions>
                </mat-card>
            </span>
            <div *ngIf="missingRoles">{{'dashboard.features.baseDashboard.missingRoleMessage' | translate}}</div>
        </div>
    </div>
    <finprocess-dashboard *ngIf="currentDashboard !== undefined" [(dashboardType)]="currentDashboard"></finprocess-dashboard>
</div>