/**
 * Klasse für Aktion
 */
export class SmartDocStoreRequestCompleted {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocStoreRequestCompleted';
    /**
     * Constructor
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() {}
}
