import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Zusätzliche Heizsysteme
 */
export enum AdditionalHeatingSystem {
    /**
     * Keine
     */
    None = 0,

    /**
     * Wärmepumpe
     */
    HeatPump = 1,

    /**
     * Kachelofen
     */
    TiledStove = 2,

    /**
     * Offener Kamin / Schwedenofen
     */
    OpenFireplace = 4,

    /**
     * Kontrollierte Wohnraumlüftung mit Wärmerückgewinnung
     */
    ControlledDomesticVentilationWithHeatRecovery = 8,

    /**
     * Solaranlage
     */
    SolarSystem = 16,

    /**
     * Klimaanlage (zentral und fix montiert)
     */
    AirCondition = 32,
}

export namespace AdditionalHeatingSystem {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {AdditionalHeatingSystem | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: AdditionalHeatingSystem | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'AdditionalHeatingSystem', AdditionalHeatingSystem, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('AdditionalHeatingSystem', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {AdditionalHeatingSystem | undefined | null} value Enum-Wert
     * @param {AdditionalHeatingSystem} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: AdditionalHeatingSystem | undefined | null, flag: AdditionalHeatingSystem): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
