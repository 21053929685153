<ng-template #selectInputTemplate let-items="items" let-overwrite="overwrite" let-readonly="readonly" let-entity="entity" let-fieldName="fieldName" let-labelKey="labelKey" let-valueStorageType="valueStorageType" let-required="required" let-value="value">
    @if((readonly === alwaysReadonly$ && (editingReadonly$ | async) === false ) || (readonly | async) === false) {
        <finprocess-select-input
            [items]="items"
            [overwrite]="overwrite ? (entity.source === SourceCollateral.Agp ? true : false) : false"
            [entityClassType]="entityClassType.CollateralAccount"
            [hideHorizontalRule]="true"
            [fieldName]="fieldName"
            [readonly]="readonly"
            [flexDirection]="inputFlexDirection"
            [entity]="entity"
            [transparent]="true"
            subscriptSizing="fixed"
            [valueStorageType]="valueStorageType ?? null"
            [validators]="required ? Validators.required : null">
            <span label class="input-label">{{ ('asset.assetDetails.accountDetails.'+labelKey) | translate }}</span>
        </finprocess-select-input>
    } @else {
    <finprocess-label-value>
        <span label>{{ 'asset.assetDetails.accountDetails.' + labelKey | translate }}</span>
        <span value>
            @if(items === booleanItems) {
            {{ value === true ? ('general.yes' | translate) : value === false ? ('general.no' | translate) : '-' }}
            } @else {
            {{ value ?? '-' }}
            }
        </span>
    </finprocess-label-value>
    }
</ng-template>

<div *ngFor="let securityPaper of collateralSecurityPaper; let i = index; trackBy: panelTrackedBy; let last = last" #collaterals class="mb-2">
    <ucba-heading
        *ngIf="!!securityPaper"
        [expandable]="true"
        #expandableHeading
        [ngClass]="{ 'requirements-failed': validAccountsMap[securityPaper.id] === false }">
        <div ucba-heading-title>{{ securityPaper.collateralType ?? 'Wertpapierpaket' | finprocessEnumTranslation : 'CollateralType' }}</div>
        <div ucba-heading-actions>
            <button
                class="delete-btn"
                [disabled]="editingReadonly$ | async"
                mat-icon-button
                (click)="deleteCollateral(securityPaper.id)"
                [matTooltip]="'asset.assetDetails.deleteCollateral' | translate">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div ucba-heading-body-visible>
            <ucba-tile-layout>
                <!-- rechtlicher Status -->
                <ng-template ucba-tile>
                    <ng-container
                        *ngTemplateOutlet="
                            selectInputTemplate;
                            context: {
                                items: assignmentTypeControl,
                                entity: securityPaper,
                                readonly: alwaysReadonly$,
                                fieldName: 'legalStatus',
                                labelKey: 'assignmentTypeControl',
                                value: findArrayElementWithId(assignmentTypeControl ?? [], securityPaper.legalStatus)?.displayName
                            }
                        "></ng-container>
                </ng-template>
                <!-- Sind diese Wertpapiere CRR fähig? -->
                <ng-template ucba-tile>
                    <ng-container
                        *ngTemplateOutlet="
                            selectInputTemplate;
                            context: {
                                items: booleanItems,
                                overwrite: true,
                                entity: securityPaper,
                                readonly: editingReadonly$,
                                fieldName: 'isCrrCapable',
                                labelKey: 'securityPaperCRR',
                                required: true,
                                valueStorageType: ValueStorageType.Bool,
                                value: securityPaper.isCrrCapable
                            }
                        "></ng-container>
                </ng-template>
            </ucba-tile-layout>
        </div>
        <div ucba-heading-body>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.accountDetails.otherInfos' | translate }}</div>
                <div ucba-heading-body-visible>
                    <ucba-tile-layout>
                        <!-- Wertpapierdepotnummer -->
                        <ng-template ucba-tile>
                            @if((editingReadonlyApprovalView$ | async) === false) {
                                <finprocess-textbox-input
                                    [overwrite]="securityPaper.source === SourceCollateral.Agp ? true : false"
                                    [entityClassType]="entityClassType.CollateralAccount"
                                    fieldName="accountNumber"
                                    [flexDirection]="inputFlexDirection"
                                    [hideHorizontalRule]="true"
                                    [readonly]="editingReadonlyApprovalView$"
                                    [entity]="securityPaper"
                                    [validators]="Validators.required">
                                    <span label class="input-label">{{ 'asset.assetAccount.accountNumber' | translate }}</span>
                                </finprocess-textbox-input>
                            } @else {
                            <finprocess-label-value>
                                <span label>{{ 'asset.assetAccount.accountNumber' | translate }}</span>
                                <span value>{{ !!securityPaper.accountNumber && securityPaper.accountNumber !== '' ? securityPaper.accountNumber : '-' }}</span>
                            </finprocess-label-value>
                            }
                        </ng-template>
                    </ucba-tile-layout>
                </div>
            </ucba-heading>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.accountDetails.securityPaperPackages' | translate }}</div>
                <div
                    ucba-heading-actions
                    *ngIf="!!assetSecurityPaper && assetSecurityPaper.length > 0 && (editingReadonly$ | async) !== true"
                    class="add-btn"
                    (click)="matSelect.open()">
                    <span class="mr-1">{{ 'asset.assetDetails.accountDetails.assignSecurityPaper' | translate }}</span>
                    <mat-icon style="font-size: 20px">add_circle</mat-icon>
                    <mat-select
                        style="visibility: hidden"
                        #matSelect
                        [panelWidth]="350"
                        [ngModel]="securityPaper.assetAssignmentInfos"
                        [compareWith]="compareAssetAccounts"
                        multiple>
                        <mat-option
                            #option
                            id="{{ aSecurityPaper.id }}"
                            *ngFor="let aSecurityPaper of assetSecurityPaper"
                            [value]="aSecurityPaper"
                            (onSelectionChange)="assignAccounts(aSecurityPaper.id, securityPaper.id, true, false, $event)">
                            <ng-container *ngIf="aSecurityPaper.balance; else noSecurityBalance"
                                >{{ 'asset.assetDetails.accountDetails.balance' | translate }}: {{ aSecurityPaper.balance | formatCurrency }}, {{'asset.assetDetails.accountDetails.balanceNumber' | translate }}: {{aSecurityPaper.accountNumber}}
                            </ng-container>
                            <ng-template #noSecurityBalance>{{ 'asset.assetDetails.accountDetails.noSecurityBalance' | translate }}</ng-template>
                        </mat-option>
                    </mat-select>
                </div>
                <div ucba-heading-body-visible>
                    @if((!assetAccount || assetAccount.length === 0) || (!securityPaper.assetAssignmentInfos || securityPaper.assetAssignmentInfos.length ===
                    0)) {
                    <ucba-tile-layout>
                        <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.accountDetails.noSecurityPaperDetails' | translate }}</ng-template>
                    </ucba-tile-layout>
                    } @else {
                    <div *ngFor="let assignAsset of securityPaper.assetAssignmentInfos; let j = index">
                        <ucba-heading *ngIf="!!assignAsset" [type]="'small'" [expandable]="true">
                            <div ucba-heading-title *ngIf="assignAsset.assetAccount.balance !== undefined">
                                {{ 'asset.assetDetails.accountDetails.balance' | translate }}: {{ assignAsset.assetAccount.balance | formatCurrency }}
                            </div>
                            <div ucba-heading-actions>
                                <button
                                    *ngIf="(editingReadonly$ | async) !== true"
                                    class="delete-btn black"
                                    mat-icon-button
                                    [matTooltip]="'asset.assetDetails.accountDetails.deleteSecurityPaper' | translate"
                                    (click)="assignAccounts(assignAsset.assetId, securityPaper.id, false, false)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <!-- materieller Wert gesamt -->
                                <div fxLayout="row" fxFlex="35" *ngIf="!!assignAsset.assetAccount.materialValueTotal">
                                    {{ 'asset.assetAccount.materialValueTotal' | translate }}:
                                    {{ assignAsset.assetAccount.materialValueTotal | formatCurrency }}
                                </div>
                                <!-- Wertpapierdepotnummer -->
                                <div fxLayout="row" fxFlex="35" *ngIf="!!assignAsset.assetAccount.accountNumber" class="mr-4">
                                    {{ 'asset.assetAccount.accountNumber' | translate }}:
                                    {{ assignAsset.assetAccount.accountNumber }}
                                </div>
                            </div>
                            <div ucba-heading-body>
                                <ucba-tile-layout class="margin-tile-layout">
                                    @if(hasAssignedBodyData(assignAsset.assetAccount)) {
                                    <!-- Belehnsatz aus Geos -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetAccount.loanRateGeos' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.loanRate ? (assignAsset.assetAccount.loanRate | formatCurrency) : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <!-- bereits gen. mat. Wert -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.materialValueAlreadyInUse' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.materialValueAlreadyInUse
                                                    ? (assignAsset.assetAccount.materialValueAlreadyInUse | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <!-- freier materieller Wert -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.freeMaterialValue' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.freeMaterialValue
                                                    ? (assignAsset.assetAccount.freeMaterialValue | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    } @else {
                                    <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.accountDetails.noAssignedBodyData' | translate }}</ng-template>
                                    }
                                </ucba-tile-layout>
                                <finprocess-asset-provider
                                    class="asset-provider"
                                    [headingType]="'small'"
                                    [assetId]="assignAsset.assetId"
                                    [assetProviders]="assignAsset.assetAccount.assetProviders">
                                </finprocess-asset-provider>
                            </div>
                        </ucba-heading>
                        <finprocess-collateralisation
                            [collateralDetail]="securityPaper"
                            [dataSources]="dataSources.get(securityPaper)"
                            [editingReadonly$]="editingReadonly$"></finprocess-collateralisation>
                    </div>
                    }
                </div>
            </ucba-heading>
        </div>
    </ucba-heading>
</div>
<!--Kontoguthaben-->
<div *ngFor="let account of collateralAccounts; let i = index; trackBy: panelTrackedBy; let last = last" #collaterals class="mb-2">
    <ucba-heading *ngIf="!!account" [expandable]="true" #expandableAccountHeading [ngClass]="{ 'requirements-failed': validAccountsMap[account.id] === false }">
        <div ucba-heading-title>{{ account.collateralType ?? 'Kontoguthaben' | finprocessEnumTranslation : 'CollateralType' }}</div>
        <div ucba-heading-actions>
            <button
                class="delete-btn"
                [disabled]="editingReadonly$ | async"
                mat-icon-button
                (click)="deleteCollateral(account.id)"
                [matTooltip]="'asset.assetDetails.deleteCollateral' | translate">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div ucba-heading-body-visible>
            <ucba-tile-layout>
                <ng-template ucba-tile>
                    <ng-container
                        *ngTemplateOutlet="
                            selectInputTemplate;
                            context: {
                                items: assignmentTypeControl,
                                entity: account,
                                readonly: alwaysReadonly$,
                                fieldName: 'legalStatus',
                                labelKey: 'assignmentTypeControl',
                                value: findArrayElementWithId(assignmentTypeControl ?? [], account.legalStatus)?.displayName
                            }
                        "></ng-container>
                </ng-template>
                <ng-template ucba-tile>
                    <ng-container
                        *ngTemplateOutlet="
                            selectInputTemplate;
                            context: {
                                items: booleanItems,
                                entity: account,
                                overwrite: true,
                                readonly: editingReadonly$,
                                fieldName: 'isCrrCapable',
                                labelKey: 'accountCRR',
                                value: account.isCrrCapable,
                                required: true,
                                valueStorageType: ValueStorageType.Bool
                            }
                        "></ng-container>
                </ng-template>
            </ucba-tile-layout>
        </div>
        <div ucba-heading-body>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.accountDetails.otherInfos' | translate }}</div>
                <div ucba-heading-body-visible>
                    <ucba-tile-layout>
                        <!-- Kontonummer -->
                        <ng-template ucba-tile>
                            @if((editingReadonlyApprovalView$ | async) === false) {
                                <finprocess-textbox-input
                                    [overwrite]="account.source === SourceCollateral.Agp ? true : false"
                                    [entityClassType]="entityClassType.CollateralAccount"
                                    fieldName="accountNumber"
                                    [flexDirection]="inputFlexDirection"
                                    [hideHorizontalRule]="true"
                                    [readonly]="editingReadonlyApprovalView$"
                                    [entity]="account"
                                    [validators]="Validators.required"
                                    [transparent]="true"
                                    subscriptSizing="fixed">
                                    <span label class="input-label">{{ 'asset.assetAccount.accountnr' | translate }}</span>
                                </finprocess-textbox-input>
                            } @else {
                            <finprocess-label-value>
                                <span label>{{ 'asset.assetAccount.accountnr' | translate }}</span>
                                <span value>{{ !!account.accountNumber && account.accountNumber !== '' ? account.accountNumber : '-' }}</span>
                            </finprocess-label-value>
                            }
                        </ng-template>
                    </ucba-tile-layout>
                </div>
            </ucba-heading>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.accountDetails.accountAmount' | translate }}</div>
                <div
                    ucba-heading-actions
                    *ngIf="!!assetAccounts && assetAccounts.length > 0 && (editingReadonly$ | async) !== true"
                    class="add-btn"
                    (click)="matSelectAccount.open()">
                    <span class="mr-1">{{ 'asset.assetDetails.accountDetails.assignAccountAmount' | translate }}</span>
                    <mat-icon style="font-size: 20px">add_circle</mat-icon>
                    <mat-select
                        style="visibility: hidden"
                        #matSelectAccount
                        [panelWidth]="350"
                        [ngModel]="account.assetAssignmentInfos"
                        [compareWith]="compareAssetAccounts"
                        multiple>
                        <mat-option
                            #option
                            id="{{ aAccount.id }}"
                            *ngFor="let aAccount of assetAccounts"
                            [value]="aAccount"
                            (onSelectionChange)="assignAccounts(aAccount.id, account.id, true, true, $event)">
                            <ng-container *ngIf="aAccount.balance; else noBalance"
                                >{{ 'asset.assetAccount.balanceBond' | translate }}: {{ aAccount.balance | formatCurrency }}
                            </ng-container>
                            <ng-template #noBalance>{{ 'asset.assetDetails.accountDetails.noBalance' | translate }}</ng-template>
                        </mat-option>
                    </mat-select>
                </div>
                <div ucba-heading-body-visible>
                    @if((!assetAccount || assetAccount.length === 0) || (!account.assetAssignmentInfos || account.assetAssignmentInfos.length === 0)) {
                    <ucba-tile-layout>
                        <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.accountDetails.noAccountDetails' | translate }}</ng-template>
                    </ucba-tile-layout>
                    } @else {
                    <div *ngFor="let assignAsset of account.assetAssignmentInfos; let j = index">
                        <ucba-heading *ngIf="!!assignAsset" [type]="'small'" [expandable]="true">
                            <div ucba-heading-title *ngIf="assignAsset.assetAccount.balance !== undefined">
                                {{ 'asset.assetAccount.balanceBond' | translate }}: {{ assignAsset.assetAccount.balance | formatCurrency }}
                            </div>
                            <div ucba-heading-actions>
                                <button
                                    *ngIf="(editingReadonly$ | async) !== true"
                                    class="delete-btn black"
                                    mat-icon-button
                                    [matTooltip]="'asset.assetDetails.accountDetails.deleteAccount' | translate"
                                    (click)="assignAccounts(assignAsset.assetId, account.id, false, true)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <!-- Bankgarantie -->
                                <div fxLayout="row" *ngIf="assignAsset.assetAccount.isExclusivelyCollateral !== undefined">
                                    {{ 'asset.assetAccount.isExclusivelyCollateral' | translate }}:
                                    {{ assignAsset.assetAccount.isExclusivelyCollateral ? ('general.yes' | translate) : ('general.no' | translate) }}
                                </div>
                            </div>
                            <div ucba-heading-body>
                                <ucba-tile-layout class="margin-tile-layout">
                                    @if(hasAssignedBodyData(assignAsset.assetAccount)) {
                                    <!-- Belehnungssatz -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetAccount.loanRate' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.loanRate ? (assignAsset.assetAccount.loanRate | formatCurrency) : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <!-- bereits gen. mat. Wert -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.materialValueAlreadyInUse' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.materialValueAlreadyInUse
                                                    ? (assignAsset.assetAccount.materialValueAlreadyInUse | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <!-- freier materieller Wert -->
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.freeMaterialValue' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetAccount.freeMaterialValue
                                                    ? (assignAsset.assetAccount.freeMaterialValue | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    } @else {
                                    <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.accountDetails.noAssignedBodyData' | translate }}</ng-template>
                                    }
                                </ucba-tile-layout>
                                <finprocess-asset-provider
                                    class="asset-provider"
                                    [headingType]="'small'"
                                    [assetId]="assignAsset.assetId"
                                    [assetProviders]="assignAsset.assetAccount.assetProviders">
                                </finprocess-asset-provider>
                            </div>
                        </ucba-heading>
                        <finprocess-collateralisation
                            [collateralDetail]="account"
                            [dataSources]="dataSources.get(account)"
                            [editingReadonly$]="editingReadonly$"></finprocess-collateralisation>
                    </div>
                    }
                </div>
            </ucba-heading>
        </div>
    </ucba-heading>
</div>
