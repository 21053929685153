import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Sprache
 */
export enum Language {
    /**
     * Keine
     */
    None = 0,

    /**
     * Deutsch
     */
    German = 1,

    /**
     * Englisch
     */
    English = 2,
}

export namespace Language {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {Language | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: Language | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'Language', Language, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('Language', plocale);
}
