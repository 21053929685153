<div fxLayout="row" fxLayoutAlign="end" class="note-container">
    <finprocess-note [type]="FinprocessEntity.Liability" [userID]="userID ?? ''"></finprocess-note>
</div>

<div class="liabilities-overview-wrapper w-100" *ngIf="!loading">
    @if(financingSumLiabilities.households.length === 0) {
        <div class="empty-hint p-4">{{ 'financing.features.financing-processing.liabilities.liabilitySums' | translate}}</div>
    } 
    @else {
        <!-- TODO: apply new styles for tab header (uppercase, ...) -->
        <mat-tab-group mat-stretch-tabs="false" (selectedIndexChange)="onHouseholdChange($event)">
            <mat-tab *ngFor="let household of financingSumLiabilities.households let i = index" [label]="'financing.features.financing-processing.liabilities.liabilitySums.houseHoldTitle' | translate:{ number: i + 1 }">
                <div *ngIf="household.debitors.length > 0" class="debitors-chip-navigation">
                    <ucba-chip-list (selectionChange)="onDebitorChange($event)"> 
                        <ucba-chip [color]="'primary'" *ngFor="let debitor of household?.debitors let di = index">
                            <div>{{ debitor.name }}</div>
                            <mat-icon *ngIf="debitor.isHouseholdEconomicLeader" style="font-size: small;">star</mat-icon>
                        </ucba-chip>
                    </ucba-chip-list>
                </div>
                <div class="debitor-content-wrapper">
                    <ucba-table *ngIf="household.debitors.length > 0 else elseBlock" [overrideGridTemplateColumns]="'repeat(9, minmax(min-content, 1fr))'" [noAlternatingColumnColors]="true">
                        <ng-template ucba-table-header>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityType' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityDate' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilitySource' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilitySum' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityDuration' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.creditLimit' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityMonthlyRate' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityFictionalRate' | translate }}</div>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.liabilityFictionalRateAmount' | translate }}</div>
                        </ng-template>
                        <ng-template *ngFor="let liability of selectedDebitor?.liabilities" ucba-table-row>
                            <div>
                                @if(liability.type === undefined) {
                                    {{ 'general.noValue' | translate }}
                                }
                                @else 
                                {
                                    {{ liability.type | finprocessEnumTranslation:'LiabilityType'}}
                                }
                            </div>
                            <div>{{ liability.started | date: 'dd.MM.YYYY' }}</div>
                            <div>{{ liability.liabilitySource | finprocessEnumTranslation:'JointHeadingType' }}</div>
                            <div>{{ liability.sum | formatCurrency }}</div>
                            <div>{{ liability.durationInMonths}} {{ 'financing.features.financing-processing.liabilities.liabilityOverview.months' | translate }}</div>
                            <div>
                                @if(hasCreditLimit(liability))
                                {
                                    {{ liability.creditLimit | formatCurrency }}
                                } 
                                @else {
                                    -
                                }
                            </div>
                            <div [class.color-warning]="liability.monthlyRate === 0 || liability.monthlyRate === undefined">{{ liability.monthlyRate | formatCurrency }}</div>
                            <div>{{ liability.fictionalRate }}%</div>
                            <div>
                                <span>{{ liability.fictionalRateAmount | formatCurrency }}</span>
                                <mat-icon *ngIf="liability.isJointHeadingsWithMultipleDebitors" class="end-of-line-icon" color="accent" matTooltip="{{ 'financing.features.financing-processing.liabilities.liabilitySums.multipleDebitors' | translate }}">link</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template ucba-table-row ucba-highlighted-table-row>
                            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.total' | translate }}</div>
                            <div></div>
                            <div></div>
                            <div>{{ selectedDebitor?.sum | formatCurrency }}</div>
                            <div></div>
                            <div></div>
                            <div>{{ selectedDebitor?.sumRates | formatCurrency }}</div>
                            <div></div>
                            <div>{{ selectedDebitor?.sumFictionalRates | formatCurrency }}</div>
                        </ng-template>
                    </ucba-table>
                    <ng-template #elseBlock>
                        <div class="no-debitors-message" fxLayoutAlign="center">{{'financing.features.financing-processing.liabilities.liabilitySums.noHouseHoldDebitors' | translate }}</div>
                    </ng-template>
                </div>
            </mat-tab>
        </mat-tab-group>
    }
    <ucba-table class="totals-wrapper total-sums-wrapper w-100" [overrideGridTemplateColumns]="'200px 240px 340px 1fr'" [noAlternatingColumnColors]="true">
        <ng-template ucba-table-header>
            <div></div>
            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotal' | translate }}</div>
            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotalFictionalRates' | translate }}</div>
            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.sumTotalRates' | translate }}</div>
        </ng-template>
        <ng-template ucba-table-row ucba-highlighted-table-row>
            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.houseHoldTitle' | translate:{ number: (selectedHousehold?.position ?? 0) + 1 } }}</div>
            <div>{{ selectedHousehold?.sum ?? 0 | formatCurrency}}</div>
            <div>{{ selectedHousehold?.sumFictionalRates ?? 0 | formatCurrency}}</div>
            <div>{{ selectedHousehold?.sumRates ?? 0 | formatCurrency}}</div>
        </ng-template>
        <ng-template ucba-table-row ucba-highlighted-table-row [color]="'dark'">
            <div>{{ 'financing.features.financing-processing.liabilities.liabilitySums.total' | translate }}</div>
            <div>{{ financingSumLiabilities.sum | formatCurrency}}</div>
            <div>{{ financingSumLiabilities.sumFictionalRates | formatCurrency}}</div>
            <div>
                <span>{{ financingSumLiabilities.sumRates | formatCurrency}}</span>
                <ng-container *ngIf="checkRates()">
                    &nbsp;
                    <mat-icon color="warn" matTooltip="{{'financing.features.financing-processing.liabilities.liabilitySums.missingRatesHint' | translate}}">warning</mat-icon>
                </ng-container>
            </div>
        </ng-template>
    </ucba-table>
</div>
<div *ngIf="loading" class="waiter" fxFlex fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>