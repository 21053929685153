import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { FinancingService } from 'app/modules/financing/data/services/financing/financing.service';
import { INote } from 'app/modules/note/data/interfaces';
import { NoteService } from 'app/modules/note/data/services/note.service';
import { UUID } from 'app/modules/shared';
import { Observable, Subject, distinctUntilChanged, map, take, takeUntil } from 'rxjs';

import { FinProcessEntity } from '../../../data/enums';
import { NoteState } from '../../../data/states/note.state';
import { AddEditNoteDialogComponent } from '../add-edit-note-dialog/add-edit-note-dialog.component';


/**
 * Note Component
 */
@Component({
    selector: 'finprocess-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    animations: [
        // FadeInOut for the mini FAB
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0,
                transform: 'scale(0)',
            })),
            state('*', style({
                opacity: 1,
                transform: 'scale(1)',
            })),
            transition('void <=> *', animate('300ms ease-in-out')),
        ]),

        // Slide In/Out for the expansion panel
        trigger('panelAnimation', [
            state('void', style({
                transform: 'translateY(-20px)',
                opacity: 0,
            })),
            state('*', style({
                transform: 'translateY(0)',
                opacity: 1,
            })),
            transition('void <=> *', animate('300ms ease-in-out')),
        ]),
    ],
})
export class NoteComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Mat Expansion Panel öffnen/schließen
     */
    public panelOpenState = signal(false);

    /**
     * Mini Fab anzeigen/verstecken
     */
    public miniFab = signal(true);

    /**
     * Notizen, die angezeigt werden
     */
    public notes: INote[] = [];

    /**
     * Alle Notizen aus dem State
     */
    public notesFromState: INote[] = [];
    
    /**
     * custom titles for notes
     */
    public noteTitles = input<string[]>([]);

    /**
     * Typ der Entität
     */
    @Input()
    public type!: FinProcessEntity;

    /**
     * User ID
     */
    @Input()
    public userID!: UUID;

    /**
     * entity ID e.g. Debitor ID for creating a new note
     */
    @Input()
    public entityID?: UUID;

    /**
     * array of entity IDs for showing notes of different types (only needed when multiple types should be shown)
     */
    @Input()
    public entityIDs?: UUID[];
    
    /**
     * info if notes should be initially opened
     */
    @Input()
    public open?: boolean;

    /**
     * Finanzierungsmap ID
     */
    public financingMapID?: string;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * event for change of panelOpenState
     */
    @Output()
    public panelOpenStateChanged = new EventEmitter<boolean>();

    /**
     * event for note added or deleted
     */
    @Output()
    public notesChanged = new EventEmitter();

    /**
     *Konstruktor
     *
     * @param {MatDialog} dialog MatDialog
     * @param {NoteService} noteService NoteService
     * @param {WaiterService} waiterService WaiterService
     * @param {NotificationService} notificationService NotificationService
     * @param {TranslateService} translate TranslateService
     * @param {ActivatedRoute} activatedRoute ActivatedRoute
     * @param {DomSanitizer} sanitizer DomSanitizer
     * @param {Store} store Store
     * @param {FinancingService} financingService FinancingService
     */
    public constructor(private dialog: MatDialog, private noteService: NoteService, private waiterService: WaiterService,
        private notificationService: NotificationService, private translate: TranslateService, private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer, private store: Store, private financingService: FinancingService,
    ) {
        this.editingReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {

        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
        ).subscribe(id => {
            this.financingMapID = id;
        });

        this.store.select(NoteState.allNotes)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(notes => {
                this.notesFromState = notes;
                this.updateNotes();
            });

        if (this.open) {
            this.openHidePanel(true);
        }
    }

    /**
     * Angular Lifecycle beim Ändern der Komponente
     * 
     * @param {SimpleChanges} changes Änderungen
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.notes && this.notes.length > 0) {
            this.notes = this.notes.map(note => {
                const sanitizedComment = this.sanitizeContent(note.comment);
                return {
                    ...note,
                    sanitizedComment,
                };
            });
        }

        if (!this.entityIDs && changes['entityID']) {
            this.updateNotes();
        }
    }

    /**
     * Notizen aktualisieren
     */
    private updateNotes(): void {
        if (!!this.entityID) {
            this.notes = this.notesFromState
                .filter(note => (this.entityIDs ? (note.entityId && this.entityIDs.indexOf(note.entityId) !== -1) : (note.entityId === this.entityID)))
                .map(note => {
                    const sanitizedComment = this.sanitizeContent(note.comment);
                    return {
                        ...note,
                        sanitizedComment,
                    };
                });
        }
        else {
            this.notes = this.notesFromState.map(note => {
                const sanitizedComment = this.sanitizeContent(note.comment);
                return {
                    ...note,
                    sanitizedComment,
                };
            });
        }
    }


    /**
     * Mat Expansion Panel öffnen/schließen
     * 
     * @param {boolean} setState true/false
     */
    public openHidePanel(setState: boolean): void {
        this.panelOpenState.set(setState);
        this.miniFab.set(!setState);
        this.panelOpenStateChanged.emit(setState);
    }

    /**
     * Mini Fab anzeigen/verstecken
     * 
     * @param {boolean} setState true/false
     */
    public showHideMiniFab(setState: boolean): void {
        this.miniFab.set(setState);
        this.panelOpenStateChanged.emit(!setState);
    }

    /**
     * Notiz hinzufügen
     * 
     * @param {boolean} showCustomerHint true/false
     */
    public addNote(showCustomerHint: boolean = false) {

        const dialogRef = this.dialog.open(AddEditNoteDialogComponent, {
            data: { addNote: true, showCustomerHint: showCustomerHint },
            width: '60%',
            height: '60%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.waiterService.show();

                const updateNote: Partial<INote> = {
                    comment: result.noteContent,
                    userId: this.userID,
                    type: this.type,
                    entityId: this.entityID ?? undefined,
                    finProcessContainerId: this.financingMapID ?? undefined,
                };

                this.noteService.addNote(updateNote).pipe(take(1)).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        this.notesChanged.emit();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.notes.noteAddError'));
                    },
                });
            }
        });
    }

    /**
     * Notiz bearbeiten
     * 
     * @param {Partial<INote>} note Notiz
     */
    public editNote(note: INote) {
        const dialogRef = this.dialog.open(AddEditNoteDialogComponent, {
            data: { addNote: false, noteData: note, showCustomerHint: note.type === FinProcessEntity.CalculationExternal },
            width: '60%',
            height: '60%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.waiterService.show();

                const updateNote: INote = {
                    remarkId: note.remarkId,
                    comment: result.noteContent,
                    userId: note.userId,
                    type: note.type,
                    finProcessContainerId: note.finProcessContainerId,
                    createdAt: note.createdAt,
                    entityId: note.entityId,
                };

                this.noteService.updateNote(updateNote).pipe(take(1)).subscribe({
                    next: () => {
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.notes.noteUpdateError'));
                    },
                });
            }
        });
    }

    /**
     * Notiz löschen
     * 
     * @param {UUID} noteID Note ID
     */
    public deleteNote(noteID: UUID) {
        this.waiterService.show();
        this.notificationService.confirmYesNo(this.translate.instant('financing.features.financing-processing.notes.noteDeleteTitle'), this.translate.instant('financing.features.financing-processing.notes.noteDeleteConfirm')).pipe(take(1)).subscribe({
            next: result => {
                if (result === 'submit' && !!this.financingMapID) {
                    this.noteService.deleteNote(noteID, this.financingMapID).pipe(take(1)).subscribe({
                        next: () => {
                            this.waiterService.hide();
                            this.notesChanged.emit();
                        },
                        error: () => {
                            this.waiterService.hide();
                            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.notes.noteDeleteError'));
                        },
                    });
                }
                else {
                    this.waiterService.hide();
                }
            },
        });
    }

    /**
     * Notizinhalt bereinigen
     * 
     * @param {string} content note content
     * @returns {SafeHtml} safe html
     */
    public sanitizeContent(content: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
