/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Logout } from 'app/modules/auth/data';

import { IIncomeDependentPersonBase, IRiskAssessmentRule, ISystemConfiguration, ISystemConfigurationValue } from './../../interfaces';
import { RiskParametrizationChanged, RiskParametrizationLoaded, SystemConfigurationLoaded } from './risk-parametrization.action';

/**
 * Zustandsobjekt für Risikoparametrisierung
 */
export interface IRiskParametrizationStateModel {
    /**
     * Einträge zum benötigten minimalem Einkommen in Abhängigkeit von der Anzahl unterhaltsberechtigter Personen (auch Kinder)
     */
    incomeDependentPersonBases: IIncomeDependentPersonBase[];

    /**
     * Regeln zur Risikoeinschätzung
     */
    riskAssessmentRules: IRiskAssessmentRule[];

    /**
     * Systemkonfiguration
     */
    systemConfiguration?: ISystemConfiguration;

    /**
     * Einzelne Systemkonfigurationswerte
     */
    singleConfigurationValues: ISystemConfigurationValue[];
}

/**
 * Zustand für alle existierenden Nutzer
 */
@State<IRiskParametrizationStateModel>({
    name: RiskParametrizationState.keyName,
    defaults: {
        incomeDependentPersonBases: [],
        riskAssessmentRules: [],
        singleConfigurationValues: [],
        systemConfiguration: undefined,
    },
})
@Injectable()
export class RiskParametrizationState {
    public static readonly keyName = 'riskParametrization';

    /**
     * Zustandsänderung nach dem Laden der Risikoparametrisierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {RiskParametrizationLoaded} action Aktion
     */
    @Action(RiskParametrizationLoaded)
    public riskParametrizationLoaded({ getState, patchState }: StateContext<IRiskParametrizationStateModel>, { payload }: RiskParametrizationLoaded): void {
        const state = getState();

        patchState({
            incomeDependentPersonBases: payload.incomeDependentPersonBases,
            riskAssessmentRules: payload.riskAssessmentRules,
            systemConfiguration: {
                maximumDuration: payload.maximumDuration,
                extendedDurationFrom: payload.extendedDurationFrom,
                minimumAge: payload.minimumAge,
                minimumGrossFinancingRequirement: payload.minimumGrossFinancingRequirement,
                minimumOwnCapitalPercent: payload.minimumOwnCapitalPercent,
                maximumComfortCreditPlus: payload.maximumComfortCreditPlus,
                validationMessageAcceptableCreditRate: payload.validationMessageAcceptableCreditRate,
                maximumDurationSplitAge: payload.maximumDurationSplitAge,
                splitAge: payload.splitAge,
            },
            singleConfigurationValues: state.singleConfigurationValues,
        });
    }

    /**
     * Zustandsänderung beim Ändern der Risikoparametrisierung
     *
     * @param {StateContext} context aktueller State Kontext
     * @param {RiskParametrizationChanged} action Aktion
     */
    @Action(RiskParametrizationChanged)
    public changed(context: StateContext<IRiskParametrizationStateModel>, { payload }: RiskParametrizationChanged): void {

        this.riskParametrizationLoaded(context, {payload});
    }

    /**
     * Zustandsänderung beim Laden eines einzelnen Konfigurationswertes
     *
     * @param {StateContext} context aktueller State Kontext
     * @param {RiskParametrizationChanged} action Aktion
     */
    @Action(SystemConfigurationLoaded)
    public systemConfigurationLoaded({ getState, patchState }: StateContext<IRiskParametrizationStateModel>, { payload }: SystemConfigurationLoaded): void {
        const state = getState();

        const existingValues = state.singleConfigurationValues.filter(
            configValue => !payload.some(newConfigValue => newConfigValue.mandantId === configValue.mandantId && newConfigValue.type === configValue.type));

        patchState({
            incomeDependentPersonBases: state.incomeDependentPersonBases,
            riskAssessmentRules: state.incomeDependentPersonBases,
            systemConfiguration: state.systemConfiguration,
            singleConfigurationValues: existingValues.concat(payload),
        })

    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IRiskParametrizationStateModel>): void {
        setState({
            incomeDependentPersonBases: [],
            riskAssessmentRules: [],
            singleConfigurationValues: [],
        });
    }
}
