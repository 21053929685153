
<!-- Objekt/weitere Liegenschaften Nav Bar -->

<div mat-dialog-content>

    <finprocess-two-column-layout
    leftFxLayoutAlign="start start"
    rightFxLayoutAlign="center center"
    [fxFlexDefault]="60"
    biggerSide="left"
    rigthClass="pb-0 pt-3 px-5"
    leftClass="pb-0 pt-3 px-2">
    <div left style="padding-left: 15px">

    <div *ngIf="loading" fxFill fxLayout="row" fxLayoutAlign="center center" class="waiter">
        <waiter-component [isLoading]="loading"></waiter-component>
    </div>
    <ng-container *ngIf="!loading" >
        <ng-container *ngIf="(realEstate$ | async) as realEstate">
            <finprocess-sub-header
                [label]="'financing.features.financing-processing.realEstate.objectSpec' | translate">
            </finprocess-sub-header>

            <finprocess-select-input *ngIf="visibilityMap?.type"
                [label]="'financing.features.financing-processing.realEstate.type' | translate"
                [items]="objectTypeSelectItems"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                [entity]="realEstate" fieldName="type">
            </finprocess-select-input>

            <finprocess-select-input *ngIf="visibilityMap?.objectPurpose"
                [label]="'financing.features.financing-processing.realEstate.objectPurpose' | translate"
                [items]="objectPurposeSelectItems"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                [entity]="realEstate" fieldName="objectPurpose">
            </finprocess-select-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.districtCourt"
                [label]="'financing.features.financing-processing.realEstate.districtCourt' | translate"
                [entity]="realEstate" fieldName="districtCourt"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.propertyRegisterNumber"
                [label]="'financing.features.financing-processing.realEstate.propertyRegisterNumber' | translate"
                [entity]="realEstate" fieldName="propertyRegisterNumber"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.lotNumber"
                [label]="'financing.features.financing-processing.realEstate.lotNumber' | translate"
                [entity]="realEstate" fieldName="lotNumber"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>
            <finprocess-select-input *ngIf="visibilityMap?.type"
                [label]="'financing.features.financing-processing.realEstate.collateralization' | translate"
                [items]="collateralizationSelectItems"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                [entity]="realEstate" fieldName="collateralization">
            </finprocess-select-input>

            <finprocess-boolean-input *ngIf="visibilityMap?.willBeRentedOut"
                [label]="'financing.features.financing-processing.realEstate.willBeRentedOut' | translate"
                [entity]="realEstate" fieldName="willBeRentedOut"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-boolean-input>

            <finprocess-decimal-input *ngIf="visibilityMap?.lotSize"
                [label]="'financing.features.financing-processing.realEstate.lotSize' | translate"
                [entity]="realEstate" fieldName="lotSize"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$"
                [fxValueAlignment]="'start'">
            </finprocess-decimal-input>

            <finprocess-decimal-input *ngIf="visibilityMap?.energyIndex"
                [label]="'financing.features.financing-processing.realEstate.energyIndex' | translate"
                [entity]="realEstate" fieldName="energyIndex"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$"
                [fxValueAlignment]="'start'">
            </finprocess-decimal-input>

            <finprocess-integer-input *ngIf="visibilityMap?.floor"
                [label]="'financing.features.financing-processing.realEstate.floor' | translate"
                [entity]="realEstate" fieldName="floor"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-integer-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.landRegisterNumber"
                [label]="'financing.features.financing-processing.realEstate.landRegisterNumber' | translate"
                [entity]="realEstate" fieldName="landRegisterNumber"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-sub-header *ngIf="visibilityMap?.street"
                [label]="'financing.features.financing-processing.realEstate.addressProperty' | translate">
            </finprocess-sub-header>

            <finprocess-textbox-input *ngIf="visibilityMap?.street"
                [label]="'financing.features.financing-processing.street' | translate"
                [entity]="realEstate.address" fieldName="street"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.streetNumber"
                [label]="'financing.features.financing-processing.streetNumber' | translate"
                [entity]="realEstate.address" fieldName="streetNumber"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-integer-input *ngIf="visibilityMap?.zip"
                [label]="'financing.features.financing-processing.zip' | translate"
                [entity]="realEstate.address" fieldName="zip"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-integer-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.city"
                [label]="'financing.features.financing-processing.city' | translate"
                [entity]="realEstate.address" fieldName="city"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.stairway"
                [label]="'financing.features.financing-processing.stairway' | translate"
                [entity]="realEstate.address" fieldName="stairway"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.top"
                [label]="'financing.features.financing-processing.top' | translate"
                [entity]="realEstate.address" fieldName="top"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <!-- validatedAddress -->
            <finprocess-sub-header *ngIf="visibilityMap?.validatedStreet"
                [label]="'financing.features.financing-processing.realEstate.lotAddressValidated' | translate">
            </finprocess-sub-header>

            <finprocess-textbox-input *ngIf="visibilityMap?.validatedStreet"
                [label]="'financing.features.financing-processing.street' | translate"
                [entity]="realEstate.validatedAddress" fieldName="street"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.validatedStreetNumber"
                [label]="'financing.features.financing-processing.streetNumber' | translate"
                [entity]="realEstate.validatedAddress" fieldName="streetNumber"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <finprocess-integer-input *ngIf="visibilityMap?.validatedZip"
                [label]="'financing.features.financing-processing.zip' | translate"
                [entity]="realEstate.validatedAddress" fieldName="zip"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-integer-input>

            <finprocess-textbox-input *ngIf="visibilityMap?.validatedCity"
                [label]="'financing.features.financing-processing.city' | translate"
                [entity]="realEstate.validatedAddress" fieldName="city"
                [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue" [readonly]="fieldReadonly$">
            </finprocess-textbox-input>

            <!-- <finprocess-integer-input *ngIf="visibilityMap?.morixRating"
                [label]="'financing.features.financing-processing.morixRating' | translate"
                [entity]="realEstate"
                fieldName="morixRating"
                [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                [readonly]="fieldReadonly$">
            </finprocess-integer-input> -->

            <input type="hidden" [value]="realEstate.id" />

            <ng-container *ngIf="visibilityMap?.showConstruction">
                <!-- Baujahr -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.yearOfConstruction' | translate">
                </finprocess-sub-header>

                <finprocess-boolean-input *ngIf="visibilityMap?.underConstruction"
                    [label]="'financing.features.financing-processing.realEstate.underConstruction' | translate"
                    [entity]="realEstate" fieldName="underConstruction"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-integer-input *ngIf="visibilityMap?.yearOfConstruction"
                    [label]="'financing.features.financing-processing.realEstate.yearOfConstruction' | translate"
                    [entity]="realEstate" fieldName="yearOfConstruction"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-datepicker-input *ngIf="visibilityMap?.startOfConstruction"
                    [label]="'financing.features.financing-processing.realEstate.startOfConstruction' | translate"
                    [entity]="realEstate" fieldName="startOfConstruction"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-datepicker-input>

                <finprocess-datepicker-input *ngIf="visibilityMap?.endOfConstruction"
                    [label]="'financing.features.financing-processing.realEstate.endOfConstruction' | translate"
                    [entity]="realEstate" fieldName="endOfConstruction"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-datepicker-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showConstruction">
                <!-- Ergänzende Objektangaben -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.additionalObjectSpec' | translate">
                </finprocess-sub-header>

                <finprocess-boolean-input *ngIf="visibilityMap?.precastConstruction"
                    [label]="'financing.features.financing-processing.realEstate.precastConstruction' | translate"
                    [entity]="realEstate" fieldName="precastConstruction"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-select-input *ngIf="visibilityMap?.buildingMaterial"
                    [label]="'financing.features.financing-processing.realEstate.buildingMaterial' | translate"
                    [items]="buildingMaterialSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="buildingMaterial">
                </finprocess-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showArea">
                <!-- Flächen in m² -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.areasInSquareM' | translate">
                </finprocess-sub-header>

                <finprocess-decimal-input *ngIf="visibilityMap?.netRoomArea"
                    [label]="'financing.features.financing-processing.realEstate.netRoomArea' | translate"
                    [entity]="realEstate" fieldName="netRoomArea"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaBasement"
                    [label]="'financing.features.financing-processing.realEstate.areaBasement' | translate"
                    [entity]="realEstate" fieldName="areaBasement"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaGroundFloor"
                    [label]="'financing.features.financing-processing.realEstate.areaGroundFloor' | translate"
                    [entity]="realEstate" fieldName="areaGroundFloor"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showAdditionalAreas">
                <!-- Flächen weiterer Geschosse -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.otherAreas' | translate">
                </finprocess-sub-header>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaUpperFloor"
                    [label]="'financing.features.financing-processing.realEstate.areaUpperFloor' | translate"
                    [entity]="realEstate" fieldName="areaUpperFloor"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaAdditionalUpperFloor"
                    [label]="'financing.features.financing-processing.realEstate.areaAdditionalUpperFloor' | translate"
                    [entity]="realEstate" fieldName="areaAdditionalUpperFloor"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaTopFloor"
                    [label]="'financing.features.financing-processing.realEstate.areaTopFloor' | translate"
                    [entity]="realEstate" fieldName="areaTopFloor"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaLoggia"
                    [label]="'financing.features.financing-processing.realEstate.areaLoggia' | translate"
                    [entity]="realEstate" fieldName="areaLoggia"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaRoofTerrace"
                    [label]="'financing.features.financing-processing.realEstate.areaRoofTerrace' | translate"
                    [entity]="realEstate" fieldName="areaRoofTerrace"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaBalcony"
                    [label]="'financing.features.financing-processing.realEstate.areaBalcony' | translate"
                    [entity]="realEstate" fieldName="areaBalcony"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaGarden"
                    [label]="'financing.features.financing-processing.realEstate.areaGarden' | translate"
                    [entity]="realEstate" fieldName="areaGarden"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaTerrace"
                    [label]="'financing.features.financing-processing.realEstate.areaTerrace' | translate"
                    [entity]="realEstate" fieldName="areaTerrace"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaWinterGarden"
                    [label]="'financing.features.financing-processing.realEstate.areaWinterGarden' | translate"
                    [entity]="realEstate" fieldName="areaWinterGarden"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaGarage"
                    [label]="'financing.features.financing-processing.realEstate.areaGarage' | translate"
                    [entity]="realEstate" fieldName="areaGarage"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>

                <finprocess-decimal-input *ngIf="visibilityMap?.areaOutbuildings"
                    [label]="'financing.features.financing-processing.realEstate.areaOutbuildings' | translate"
                    [entity]="realEstate" fieldName="areaOutbuildings"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'">
                </finprocess-decimal-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showBasement">
                <!-- Keller -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.basementUsage' | translate">
                </finprocess-sub-header>

                <finprocess-select-input *ngIf="visibilityMap?.basementUsage"
                    [label]="'financing.features.financing-processing.realEstate.basementUsage' | translate"
                    [items]="basementSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="basementUsage">
                </finprocess-select-input>

                <finprocess-multi-select-input *ngIf="visibilityMap?.basementConstruction"
                    [items]="basementConstructionSelectItems"
                    [label]="'financing.features.financing-processing.realEstate.basementConstruction' | translate"
                    [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="realEstate" fieldName="basementConstruction">
                </finprocess-multi-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showRoof">
                <!-- Dachgeschoss -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.topFloor' | translate">
                </finprocess-sub-header>

                <finprocess-select-input *ngIf="visibilityMap?.roofConstruction"
                    [label]="'financing.features.financing-processing.realEstate.roofConstruction' | translate"
                    [items]="roofConstructionSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="roofConstruction">
                </finprocess-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showLocation">
                <!-- Nutzbarkeit/ Lage -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.usabilityLocation' | translate">
                </finprocess-sub-header>

                <finprocess-select-input *ngIf="visibilityMap?.terrace"
                    [label]="'financing.features.financing-processing.realEstate.terrace' | translate"
                    [items]="terraceSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="terrace">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="visibilityMap?.winterGardenType"
                    [label]="'financing.features.financing-processing.realEstate.winterGarden' | translate"
                    [items]="winterGardenSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="wintergardenType">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="visibilityMap?.annexType"
                    [label]="'financing.features.financing-processing.realEstate.annexType' | translate"
                    [items]="annexTypeSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="annexType">
                </finprocess-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showGarage">
                <!-- Garage -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.garage' | translate">
                </finprocess-sub-header>

                <finprocess-multi-select-input *ngIf="visibilityMap?.parkingSpace"
                    [label]="'financing.features.financing-processing.realEstate.parkingSpace' | translate"
                    [items]="parkingSpaceSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="realEstate" [autoCheckNone]="true"
                    fieldName="parkingSpace">
                </finprocess-multi-select-input>

                <finprocess-select-input *ngIf="visibilityMap?.garageLocation"
                    [label]="'financing.features.financing-processing.realEstate.garageLocation' | translate"
                    [items]="garageLocationSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="garageLocation">
                </finprocess-select-input>

                <finprocess-multi-select-input *ngIf="visibilityMap?.garageFacilities"
                    [label]="'financing.features.financing-processing.realEstate.garageFacilities' | translate"
                    [items]="garageFacilitiesSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="realEstate"
                    fieldName="garageFacilities">
                </finprocess-multi-select-input>
            </ng-container>


            <ng-container *ngIf="visibilityMap?.showRefurbishment">
                <!-- Außen- und Innensanierung - Sanierungsjahr -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.exteriorInteriorRenovationYear' | translate">
                </finprocess-sub-header>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentInnerBuildungStructure"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentInnerBuildungStructure' | translate"
                    [entity]="realEstate" fieldName="refurbishmentInnerBuildungStructure"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentRoof"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentRoof' | translate"
                    [entity]="realEstate" fieldName="refurbishmentRoof"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentOuterBuildingStructure"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentOuterBuildingStructure' | translate"
                    [entity]="realEstate" fieldName="refurbishmentOuterBuildingStructure"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentCommonArea"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentCommonArea' | translate"
                    [entity]="realEstate" fieldName="refurbishmentCommonArea"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentWindow"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentWindow' | translate"
                    [entity]="realEstate" fieldName="refurbishmentWindow"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentHeating"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentHeating' | translate"
                    [entity]="realEstate" fieldName="refurbishmentHeating"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentElectricSanitary"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentElectricSanitary' | translate"
                    [entity]="realEstate" fieldName="refurbishmentElectricSanitary"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentWallpapering"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentWallpapering' | translate"
                    [entity]="realEstate" fieldName="refurbishmentWallpapering"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-integer-input *ngIf="visibilityMap?.refurbishmentInnerDoorsStairsFloorCovering"
                    [label]="'financing.features.financing-processing.realEstate.refurbishmentInnerDoorsStairsFloorCovering' | translate"
                    [entity]="realEstate"
                    fieldName="refurbishmentInnerDoorsStairsFloorCovering"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showOrientation">
                <!-- Orientierung -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.orientation' | translate">
                </finprocess-sub-header>

                <finprocess-select-input *ngIf="visibilityMap?.livingRoomOrientation"
                    [label]="'financing.features.financing-processing.realEstate.livingRoomOrientation' | translate"
                    [items]="livingRoomOrientationSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="livingRoomOrientation">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="visibilityMap?.flatOrientation"
                    [label]="'financing.features.financing-processing.realEstate.flatOrientation' | translate"
                    [items]="flatOrientationSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="flatOrientation">
                </finprocess-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showBathroom">
                <!-- Ausstattung (Haupt)Badezimmer -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.furnishingMasterBathroom' | translate">
                </finprocess-sub-header>

                <finprocess-integer-input *ngIf="visibilityMap?.bathroomCount"
                    [label]="'financing.features.financing-processing.realEstate.bathroomCount' | translate"
                    [entity]="realEstate" fieldName="bathroomCount"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>

                <finprocess-multi-select-input *ngIf="visibilityMap?.mainBathroomFacilities"
                    [items]="mainBathroomFacilitiesSelectItems"
                    [label]="'financing.features.financing-processing.realEstate.mainBathroomFacilities' | translate"
                    [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="realEstate" fieldName="mainBathroomFacilities"
                    [autoCheckNone]="true">
                </finprocess-multi-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showHeating">
                <!-- Heizung -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.heater' | translate">
                </finprocess-sub-header>

                <finprocess-select-input *ngIf="visibilityMap?.heatingSystem"
                    [label]="'financing.features.financing-processing.realEstate.heatingSystem' | translate"
                    [items]="heatingSystemSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="heatingSystem">
                </finprocess-select-input>

                <finprocess-select-input *ngIf="visibilityMap?.heatingSystemType"
                    [label]="'financing.features.financing-processing.realEstate.heatingSystemType' | translate"
                    [items]="heatingSystemTypeSelectItems"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [readonly]="fieldReadonly$"
                    [entity]="realEstate" fieldName="heatingSystemType">
                </finprocess-select-input>

                <!-- Zusätzliche Heizungssysteme -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.additionalHeatingSystem' | translate">
                </finprocess-sub-header>

                <finprocess-multi-select-input *ngIf="visibilityMap?.additionalHeatingSystem"
                    [items]="additionalHeatingSystemSelectItems"
                    [label]="'financing.features.financing-processing.realEstate.additionalHeatingSystem' | translate"
                    [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="realEstate" fieldName="additionalHeatingSystem"
                    [autoCheckNone]="true">
                </finprocess-multi-select-input>
            </ng-container>

            <ng-container *ngIf="visibilityMap?.showFacilities">
                <!-- Ausstattung -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.furnishing' | translate">
                </finprocess-sub-header>

                <finprocess-multi-select-input *ngIf="visibilityMap?.outsideFacilities"
                    [items]="outsideFacilitiesSelectItems"
                    [label]="'financing.features.financing-processing.realEstate.outsideFacilities' | translate"
                    [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="realEstate" fieldName="outsideFacilities" [autoCheckNone]="true">
                </finprocess-multi-select-input>

                <finprocess-multi-select-input *ngIf="visibilityMap?.additionalFacilities"
                    [items]="additionalFacilitiesSelectItems"
                    [label]="'financing.features.financing-processing.realEstate.additionalFacilities' | translate"
                    [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="realEstate" fieldName="additionalFacilities"
                    [autoCheckNone]="true">
                </finprocess-multi-select-input>
            </ng-container>

            <ng-container>
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.marketValue' | translate">
                </finprocess-sub-header>

                <finprocess-decimal-input *ngIf="visibilityMap?.marketValue"
                    [label]="'financing.features.financing-processing.realEstate.marketValue' | translate"
                    [entity]="realEstate" fieldName="marketValue"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'"
                    praefix="€">
                </finprocess-decimal-input>

                <finprocess-textbox-input *ngIf="visibilityMap?.liebeLightId"
                    [label]="'financing.features.financing-processing.realEstate.liebeLightBewertungsId' | translate"
                    [entity]="realEstate" fieldName="liebeLightId"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>
            </ng-container>
        </ng-container>
    </ng-container>
    </div>

    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
    </finprocess-two-column-layout> 
</div>
<mat-dialog-actions *ngIf="!loading" align="end">
    <button mat-raised-button mat-dialog-close>{{ 'button.close' | translate }}</button>
</mat-dialog-actions>
