import { Location } from '@angular/common'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthenticationService, AuthorizationService, Role } from 'app/modules/auth/data';
import { IDocument } from 'app/modules/financing/data';
import { ConfigService, HelperService, SubStatusCode } from 'app/modules/shared';
import { SharedService } from 'app/modules/shared/services/shared.service';
import { IFileSizes } from 'app/modules/shared/util/interfaces/environment.interface';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Observable, map, of, take } from 'rxjs';

import { ISmartDocStateParentDefinition } from '..';
import {
    SmartDocClosed,
    SmartDocDocumentsLoaded,
    SmartDocJobThumbnailContentLoaded,
    SmartDocJobThumbnailsLoaded,
    SmartDocSortingStarted,
    SmartDocSplittingStarted,
    SmartDocStoreRequestCompleted,
    SmartDocThumbnailSortResultStored,
    SmartDocThumbnailsSortResultReset,
    SmartDocUploadedFileIdsLoaded,
} from '../actions';
import { LoadedDropFolders } from '../actions/smartdoc/loaded-drop-folders.action';
import { SmartDocJobStatus } from '../enums/smartdoc-job-status.enum';
import { ISmartDocJobThumbnailViewModel } from '../interfaces/smartdoc';
import { IDropFolderViewModel } from '../interfaces/smartdoc/drop-folder-view.model';
import { IMethodNotAllowedError } from '../interfaces/smartdoc/method-not-allowed-error.interface';
import { ISmartDocSortResultFormModel } from '../interfaces/smartdoc/smartdoc-sort-result-form.model';
import { AAGUID } from '../types/aaguid';

/**
 * Service zum Bearbeiten des SmartDoc-Prozesses
 */
@Injectable()
export class SmartDocService {
    /**
     * Flag, dass Zuordnung zu Dokumentenablage gerade gespeichert wird
     */
    public sortResultStoring = false;

    /**
     * Flag, ob Aktion aktiv ist
     */
    private isActionActive = false;

    /**
     * Constructor
     *
     * @param {Router} router Router
     * @param {Store} store Store
     * @param {AuthorizationService} authService AuthorizationService
     * @param {ConfigService} config ConfigService
     * @param {HttpClient} http HttpClient
     * @param {WaiterService} waiter WaiterService
     * @param {AuthenticationService} oAuthSvc AuthenticationService
     * @param {SharedService} sharedService SharedService
     * @param {TranslateService} translateSvc TranslateService
     * @param {Location} location location injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private authService: AuthorizationService,
        private config: ConfigService,
        private http: HttpClient,
        private waiter: WaiterService,
        private oAuthSvc: AuthenticationService,
        private sharedService: SharedService,
        private translateSvc: TranslateService,
        private location: Location,

    ) {

    }

    /**
     * schließt den SmartDoc-bereich
     *
     */
    public close(): void {
        this.store.dispatch(new SmartDocClosed()).subscribe(() => {
            this.location.back();
        });
    }

    /**
     * Lädt die konfigurierten Ordner
     */
    public getFolders(): void {
        this.http.get<IDropFolderViewModel[]>(
            `${this.config.getEnvironment().apiUrl}/SmartDocConfiguration/GetFolders`,
        ).subscribe((folders: IDropFolderViewModel[]) => {
            this.store.dispatch(new LoadedDropFolders(folders))
        })
    }

    /**
     * initializeSampleCalculationUploader
     *
     * @param {AAGUID} id AAGUID
     * @returns {FileUploader} fileUploader FileUploader
     */
    public initializeUploader(id: AAGUID): FileUploader {
        const uploader = new FileUploader({
            url: `${this.config.getEnvironment().apiUrl}/document/uploadsmartdocdocument`,
            additionalParameter: {
                id,
            },
            maxFileSize: (this.config.getEnvironment().fileSizes as IFileSizes).smartDocUploadSize * 1024 * 1024,
        });
        uploader.onAfterAddingFile = (file: FileItem) => {
            file.withCredentials = false;
        };

        uploader.onBeforeUploadItem = (file: FileItem) => {
            if (!this.authService.checkPermissions(Role.SmartDoc)) {
                file.cancel();
                file.remove();
                return;
            }
            uploader.setOptions({
                authToken: `Bearer ${this.oAuthSvc.token}`,
            });
        };

        uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
            let error;
            try {
                error = JSON.parse(response);
            }
            catch (e) {
                error = response;
            }

            this.sharedService.handleError(new HttpErrorResponse({
                status,
                url: uploader.options.url,
                headers: new HttpHeaders(headers),
                error,
            }), this.translateSvc.instant('errors.uploadError'));
        };

        uploader.onWhenAddingFileFailed = this.sharedService.handlingAddingFileFailed.bind(this.sharedService);
        return uploader;
    }

    /**
     * läd die SmartDoc-Dokumente
     *
     * @param {AAGUID} id AAGUID
     */
    public loadSmartDocDocuments(id: AAGUID): void {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return;
        }
        this.http.get<IDocument[]>(`${this.config.getEnvironment().apiUrl}/document/getsmartdocdocument`, {
            params: {
                financingId: id,
            },
        }).pipe(
            take(1),
        ).subscribe((data: IDocument[]) => {
            HelperService.nullToUndefined(data);
            this.store.dispatch(
                new SmartDocDocumentsLoaded(
                    {id, documents: data },
                ),
            )
        });
    }

    /**
     * läd die Id's der bereits an SmartDoc gesendeten Dateien
     *
     * @param {AAGUID} id AAGUID
     * @returns {AAGUID[]} Geladene Ids
     */
    public loadSmartDocUploadedFileIds(id: AAGUID): Observable<AAGUID[]> {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return of([]);
        }
        return this.http.get<AAGUID[]>(`${this.config.getEnvironment().apiUrl}/SmartDoc/getSmartDocJobUploadedFileIds`, {
            params: {
                financingId: id,
            },
        }).pipe(take(1), map((data: AAGUID[]) => {
            HelperService.nullToUndefined(data);
            this.store.dispatch(new SmartDocUploadedFileIdsLoaded({id, ids: data}));
            return data;
        }));
    }

    /**
     * startet einen Splitting-Prozess
     *
     * @param {AAGUID} id AAGUID
     * @param {AAGUID[]} fileIds AAGUID[]
     */
    public splitFiles(id: AAGUID, fileIds: AAGUID[]): void {
        if (!this.isActionActive) {
            if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
                this.close();
                return;
            }
            this.isActionActive = true;
            this.waiter.show();
            this.http.post<SmartDocJobStatus>(`${this.config.getEnvironment().apiUrl}/SmartDoc/startSplittingJob`, fileIds, {
                params: {
                    financingId: id,
                },
            }).pipe(take(1)).subscribe((data: SmartDocJobStatus) => {
                HelperService.nullToUndefined(data);
                this.store.dispatch(new SmartDocSplittingStarted({id, jobStatus: data}));
                if (data === SmartDocJobStatus.Uploading) {
                    this.store.dispatch(new SmartDocUploadedFileIdsLoaded({id, ids: fileIds}));
                }
                this.waiter.hide();
                this.isActionActive = false;
            });
        }
    }

    /**
     * startet das Zusammenstellen der Dateien
     *
     * @param {AAGUID} id AAGUID
     */
    public startProcessFiles(id: AAGUID): void {
        if (!this.isActionActive) {
            if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
                this.close();
                return;
            }
            this.isActionActive = true;
            this.http.put<SmartDocJobStatus>(`${this.config.getEnvironment().apiUrl}/SmartDoc/startProcessFiles`, undefined, {
                params: {
                    financingId: id,
                },
            }).pipe(take(1)).subscribe((data: SmartDocJobStatus) => {
                HelperService.nullToUndefined(data);
                this.store.dispatch(new SmartDocSortingStarted({id, jobStatus: data}));
                this.isActionActive = false;
            });
        }
    }

    /**
     * läd die Vorschaubilder zum Zuordnen zu Dokumentenablagen
     *
     * @param {AAGUID} id AAGUID
     */
    public loadThumbnails(id: AAGUID): void {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return;
        }
        this.http.get<ISmartDocJobThumbnailViewModel[]>(`${this.config.getEnvironment().apiUrl}/SmartDoc/getsmartdocjobthumbnails`, {
            params: {
                financingId: id,
            },
        }).pipe(
            take(1),
        ).subscribe((data: ISmartDocJobThumbnailViewModel[]) => {
            HelperService.nullToUndefined(data);
            this.store.dispatch(new SmartDocJobThumbnailsLoaded({id, thumbnails: data}));
        });
    }

    /**
     * speichert eine Zuordnung zu einer Dokumentenablage
     *
     * @param {AAGUID} financingId AAGUID
     * @param {ISmartDocSortResultFormModel[]} sortResults ISmartDocSortResultFormModel[]
     */
    public storeThumbnailSortResult(financingId: AAGUID, sortResults: ISmartDocSortResultFormModel[]): void {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return;
        }
        const oldThumbnails = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocThumbnails.filter(it => sortResults.some(sr => sr.id === it.id)));
        if (oldThumbnails.length === sortResults.length) {
            const oldResults = oldThumbnails.map(it => ({
                id: it.id,
                dropAreaId: it.dropAreaId,
                sortWeight: it.sortWeight,
            } as ISmartDocSortResultFormModel));
            this.store.dispatch(new SmartDocThumbnailSortResultStored({id: financingId, results: sortResults}));
            this.http.put(
                `${this.config.getEnvironment().apiUrl}/SmartDoc/storeThumbnailSortResult`,
                sortResults,
                {
                    params: {
                        financingId,
                    },
                },
            ).pipe(
                take(1),
            ).subscribe(() => {
                this.store.dispatch(new SmartDocStoreRequestCompleted());
            }, (response: HttpErrorResponse) => {
                this.store.dispatch([new SmartDocThumbnailSortResultStored({id: financingId, results: oldResults}), new SmartDocStoreRequestCompleted()]);
                if (response.status === 405 && (response.error as IMethodNotAllowedError).subStatus === SubStatusCode.DropAreaNotExists) {
                    this.sharedService.showInfo(
                        this.translateSvc.instant('smartdoc.conflictMessage'),
                        this.translateSvc.instant('smartdoc.conflict'),
                    );
                }
                else {
                    throw response;
                }
            });
        }
    }

    /**
     * setzt die Zuordnungen aller Dokumentenablagen zurück
     *
     * @param {AAGUID} financingId AAGUID
     */
    public resetThumbnailSortResults(financingId: AAGUID): void {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return;
        }
        this.waiter.show();
        this.http.put(
            `${this.config.getEnvironment().apiUrl}/SmartDoc/resetThumbnailsSort`,
            undefined,
            {
                params: {
                    financingId,
                },
            },
        ).pipe(take(1),
        ).subscribe(() => {
            this.store.dispatch(new SmartDocThumbnailsSortResultReset(financingId));
            this.waiter.hide();
        });
    }

    /**
     * lädt das Vorschaubild in Originalgröße
     *
     * @param {AAGUID} financingId AAGUID
     * @param {AAGUID} thumbnailId AAGUID
     * @returns {Observable} content as Bild
     */
    public loadThumbnailContent(financingId: AAGUID, thumbnailId: AAGUID): Observable<string> {
        if (!this.authService.checkPermissions(Role.SmartDoc, false)) {
            this.close();
            return of();
        }
        return this.http.get(
            `${this.config.getEnvironment().apiUrl}/SmartDoc/getSmartDocJobThumbnailContent`,
            {
                params: {
                    thumbnailId,
                },
                responseType: 'text',
            },
        ).pipe(take(1),
            map((content: string) => {
                HelperService.nullToUndefined(content);
                this.store.dispatch(new SmartDocJobThumbnailContentLoaded({id: financingId, thumbnailId, content}));
                return content;
            }));
    }

    /**
     * überprüft die Rechte des Nutzers und leitet zur Zielroute, wenn Rechte vorhanden
     *
     * @param {ActivatedRoute} activatedRoute ActivatedRoute
     * @param {string} route string
     * @param {Role} role Role
     */
    private async checkRightsAndNavigate(activatedRoute: ActivatedRoute, route: string, role: Role) {
        if (this.authService.checkPermissions(role, false)) {
            await this.router.navigate([route], {relativeTo: activatedRoute});
        } else {
            this.close();
        }
    }
}
