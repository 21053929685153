import { EnumSettings } from './../enum.settings';

/**
 * Geschlecht bzw. Anrede
 */
export enum Gender {
    /**
     * Männlich bzw. Herr
     */
    Male = 0,
    /**
     * Weiblich bzw. Frau
     */
    Female = 1,
}

export namespace Gender {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {Gender | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: Gender | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'Gender', Gender, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('Gender', plocale);
}
