import { HelperService, UUID } from 'shared/util';

import { IAddress, IAsset, IBaseOverwriteValues, ICareer, IDebitor, IFinancing, IHousehold, ILiability, INewLiability, IOverwriteValue, IRealEstate, ITaxOfficeArrear, OverwriteValueClassType, ValueStorageType } from '../../../data';

/**
 * Hilfsservice für Overwrites
 */
export class OverwriteHelperService {

    /**
     * Sucht nach einem bestimmten Overwrite in einer Finanzierung
     *
     * @param {IFinancing} financing Finanzierung
     * @param {object} params Parameter
     * @param {OverwriteValueClassType} params.overwriteValueClassType Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
     * @param {UUID} params.entityForOverwriteId Identifier der Entität, bei dem ein Wert überschrieben wird
     * @param {string} params.fieldName Feldname (entsprechend DB-Entität)
     * @returns {IOverwriteValue | undefined} Overwrite oder undefined
     */
    // eslint-disable-next-line complexity
    public static getOverwriteFromFinancing(financing: IFinancing, { overwriteValueClassType, entityForOverwriteId, fieldName }: {
        /**
         * Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
         */
        overwriteValueClassType: OverwriteValueClassType;
        /**
         * Identifier der Entität, bei dem ein Wert überschrieben wird
         */
        entityForOverwriteId: UUID;
        /**
         * Feldname (entsprechend DB-Entität)
         */
        fieldName: string;
    }): IOverwriteValue | undefined {
        switch (overwriteValueClassType) {
            case OverwriteValueClassType.AddressOverwriteValue:
                return OverwriteHelperService.getAllAddresses(financing).some(it => it.id === entityForOverwriteId) ?
                    OverwriteHelperService.getOverwriteFromEntity(OverwriteHelperService.getAllAddresses(financing).find(it => it.id === entityForOverwriteId) as IAddress, fieldName) :
                    undefined;
            case OverwriteValueClassType.AssetOverwriteValue:
                return financing.households.some(it => it.assets.some(d => d.id === entityForOverwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.reduce<IAsset[]>((p, c) => p.concat(c.assets), []).find(it => it.id === entityForOverwriteId) as IAsset, fieldName) :
                    undefined;
            case OverwriteValueClassType.CareerOverwriteValue:
                return financing.households.some(it => it.debitors.some(d => d.careers.some(c => c.id === entityForOverwriteId))) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.reduce<ICareer[]>((p, c) => p.concat(c.debitors.reduce<ICareer[]>((pc, cd) => pc.concat(cd.careers), [])), []).find(it => it.id === entityForOverwriteId) as ICareer, fieldName) :
                    undefined;
            case OverwriteValueClassType.CompanyOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntity(financing.salesPartner.company, fieldName);
            case OverwriteValueClassType.DebitorOverwriteValue:
                return financing.households.some(it => it.debitors.some(d => d.id === entityForOverwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.reduce<IDebitor[]>((p, c) => p.concat(c.debitors), []).find(it => it.id === entityForOverwriteId) as IDebitor, fieldName) :
                    undefined;
            case OverwriteValueClassType.FinancingMapOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntity(financing, fieldName);
            case OverwriteValueClassType.FinProcessContainer:
                return OverwriteHelperService.getOverwriteFromEntity(financing, fieldName);
            case OverwriteValueClassType.HouseholdOverwriteValue:
                return financing.households.some(it => it.id === entityForOverwriteId) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.find(it => it.id === entityForOverwriteId) as IHousehold, fieldName) :
                    undefined;
            case OverwriteValueClassType.LiabilityOverwriteValue:
                return financing.households.some(it => it.liabilities.some(d => d.id === entityForOverwriteId) || it.newLiabilities.some(d => d.id === entityForOverwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.reduce<Array<ILiability | INewLiability>>((p, c) => p.concat(c.liabilities, c.newLiabilities), []).find(it => it.id === entityForOverwriteId) as ILiability, fieldName) :
                    undefined;
            case OverwriteValueClassType.RealEstateOverwriteValue:
                return financing.realEstates.some(it => it.id === entityForOverwriteId) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.realEstates.find(it => it.id === entityForOverwriteId) as IRealEstate, fieldName) :
                    undefined;
            case OverwriteValueClassType.SalesPartnerOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntity(financing.salesPartner, fieldName);
            case OverwriteValueClassType.TaxOfficeArrearOverwriteValue:
                return financing.households.some(it => it.taxOfficeArrears.some(d => d.id === entityForOverwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntity(financing.households.reduce<ITaxOfficeArrear[]>((p, c) => p.concat(c.taxOfficeArrears), []).find(it => it.id === entityForOverwriteId) as ITaxOfficeArrear, fieldName) :
                    undefined;
            default:
                return undefined;
        }
    }

    /**
     * Sucht nach einem bestimmten Overwrite in einer Finanzierung
     *
     * @param {IFinancing} financing Finanzierung
     * @param {object} params Parameter
     * @param {OverwriteValueClassType} params.overwriteValueClassType Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
     * @param {UUID} params.overwriteId Identifier des Overwrite
     * @returns {IOverwriteValue | undefined} Overwrite oder undefined
     */
    // eslint-disable-next-line complexity
    public static getOverwriteFromFinancingById(financing: IFinancing, { overwriteValueClassType, overwriteId }: {
        /**
         * Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
         */
        overwriteValueClassType: OverwriteValueClassType;
        /**
         * Identifier der Entität, bei dem ein Wert überschrieben wird
         */
        overwriteId: UUID;
    }): IOverwriteValue | undefined {
        switch (overwriteValueClassType) {
            case OverwriteValueClassType.AddressOverwriteValue:
                return OverwriteHelperService.getAllAddresses(financing).some(it => it.overwriteValues.some(ow => ow.id === overwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntityById(OverwriteHelperService.getAllAddresses(financing).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as IAddress, overwriteId) :
                    undefined;
            case OverwriteValueClassType.AssetOverwriteValue:
                return financing.households.some(it => it.assets.some(d => d.overwriteValues.some(ow => ow.id === overwriteId))) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.reduce<IAsset[]>((p, c) => p.concat(c.assets), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as IAsset, overwriteId) :
                    undefined;
            case OverwriteValueClassType.CareerOverwriteValue:
                return financing.households.some(it => it.debitors.some(d => d.careers.some(c => c.overwriteValues.some(ow => ow.id === overwriteId)))) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.reduce<ICareer[]>((p, c) => p.concat(c.debitors.reduce<ICareer[]>((pc, cd) => pc.concat(cd.careers), [])), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as ICareer, overwriteId) :
                    undefined;
            case OverwriteValueClassType.CompanyOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntityById(financing.salesPartner.company, overwriteId);
            case OverwriteValueClassType.DebitorOverwriteValue:
                return financing.households.some(it => it.debitors.some(d => d.overwriteValues.some(ow => ow.id === overwriteId))) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.reduce<IDebitor[]>((p, c) => p.concat(c.debitors), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as IDebitor, overwriteId) :
                    undefined;
            case OverwriteValueClassType.FinancingMapOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntityById(financing, overwriteId);
            case OverwriteValueClassType.FinProcessContainer:
                return OverwriteHelperService.getOverwriteFromEntityById(financing, overwriteId);
            case OverwriteValueClassType.HouseholdOverwriteValue:
                return financing.households.some(it => it.overwriteValues.some(ow => ow.id === overwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as IHousehold, overwriteId) :
                    undefined;
            case OverwriteValueClassType.LiabilityOverwriteValue:
                return financing.households.some(it => it.liabilities.some(d => d.overwriteValues.some(ow => ow.id === overwriteId)) || it.newLiabilities.some(d => d.overwriteValues.some(ow => ow.id === overwriteId))) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.reduce<Array<ILiability | INewLiability>>((p, c) => p.concat(c.liabilities, c.newLiabilities), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as ILiability, overwriteId) :
                    undefined;
            case OverwriteValueClassType.RealEstateOverwriteValue:
                return financing.realEstates.some(it => it.overwriteValues.some(ow => ow.id === overwriteId)) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.realEstates.find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as IRealEstate, overwriteId) :
                    undefined;
            case OverwriteValueClassType.SalesPartnerOverwriteValue:
                return OverwriteHelperService.getOverwriteFromEntityById(financing.salesPartner, overwriteId);
            case OverwriteValueClassType.TaxOfficeArrearOverwriteValue:
                return financing.households.some(it => it.taxOfficeArrears.some(d => d.overwriteValues.some(ow => ow.id === overwriteId))) ?
                    OverwriteHelperService.getOverwriteFromEntityById(financing.households.reduce<ITaxOfficeArrear[]>((p, c) => p.concat(c.taxOfficeArrears), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId)) as ITaxOfficeArrear, overwriteId) :
                    undefined;
            default:
                return undefined;
        }
    }

    /**
     * Sucht nach einer Entität mit einem bestimmten Overwrite in einer Finanzierung
     *
     * @param {IFinancing} financing Finanzierung
     * @param {object} params Parameter
     * @param {OverwriteValueClassType} params.overwriteValueClassType Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
     * @param {UUID} params.overwriteId Identifier des Overwrite
     * @returns {IBaseOverwriteValues | undefined} Overwrite oder undefined
     */
    // eslint-disable-next-line complexity
    public static getOverwriteEntityFromFinancingByOverwriteId(financing: IFinancing, { overwriteValueClassType, overwriteId }: {
        /**
         * Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
         */
        overwriteValueClassType: OverwriteValueClassType;
        /**
         * Identifier des Overwrite
         */
        overwriteId: UUID;
    }): IBaseOverwriteValues | undefined {
        switch (overwriteValueClassType) {
            case OverwriteValueClassType.AddressOverwriteValue:
                return OverwriteHelperService.getAllAddresses(financing).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.AssetOverwriteValue:
                return financing.households.reduce<IAsset[]>((p, c) => p.concat(c.assets), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.CareerOverwriteValue:
                return financing.households.reduce<ICareer[]>((p, c) => p.concat(c.debitors.reduce<ICareer[]>((pc, cd) => pc.concat(cd.careers), [])), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.CompanyOverwriteValue:
                return financing.salesPartner.company;
            case OverwriteValueClassType.DebitorOverwriteValue:
                return financing.households.reduce<IDebitor[]>((p, c) => p.concat(c.debitors), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.FinancingMapOverwriteValue:
                return financing;
            case OverwriteValueClassType.FinProcessContainer:
                return financing;
            case OverwriteValueClassType.HouseholdOverwriteValue:
                return financing.households.find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.LiabilityOverwriteValue:
                return financing.households.reduce<Array<ILiability | INewLiability>>((p, c) => p.concat(c.liabilities, c.newLiabilities), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.RealEstateOverwriteValue:
                return financing.realEstates.find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            case OverwriteValueClassType.SalesPartnerOverwriteValue:
                return financing.salesPartner;
            case OverwriteValueClassType.TaxOfficeArrearOverwriteValue:
                return financing.households.reduce<ITaxOfficeArrear[]>((p, c) => p.concat(c.taxOfficeArrears), []).find(it => it.overwriteValues.some(ow => ow.id === overwriteId));
            default:
                return undefined;
        }
    }

    /**
     * Sucht nach einer Entität mit einem bestimmten Overwrite in einer Finanzierung
     *
     * @param {IFinancing} financing Finanzierung
     * @param {object} params Parameter
     * @param {OverwriteValueClassType} params.overwriteValueClassType Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
     * @param {UUID} params.entityId Identifier der Entität
     * @returns {IOverwriteValue | undefined} Overwrite oder undefined
     */
    // eslint-disable-next-line complexity
    public static getOverwriteEntityFromFinancingById(financing: IFinancing, { overwriteValueClassType, entityId }: {
        /**
         * Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
         */
        overwriteValueClassType: OverwriteValueClassType;
        /**
         * Identifier des Entität
         */
        entityId: UUID;
    }): IBaseOverwriteValues | undefined {
        switch (overwriteValueClassType) {
            case OverwriteValueClassType.AddressOverwriteValue:
                return OverwriteHelperService.getAllAddresses(financing).find(it => it.id === entityId);
            case OverwriteValueClassType.AssetOverwriteValue:
                return financing.households.reduce<IAsset[]>((p, c) => p.concat(c.assets), []).find(it => it.id === entityId);
            case OverwriteValueClassType.CareerOverwriteValue:
                return financing.households.reduce<ICareer[]>((p, c) => p.concat(c.debitors.reduce<ICareer[]>((pc, cd) => pc.concat(cd.careers), [])), []).find(it => it.id === entityId);
            case OverwriteValueClassType.CompanyOverwriteValue:
                return financing.salesPartner.company;
            case OverwriteValueClassType.DebitorOverwriteValue:
                return financing.households.reduce<IDebitor[]>((p, c) => p.concat(c.debitors), []).find(it => it.id === entityId);
            case OverwriteValueClassType.FinancingMapOverwriteValue:
                return financing;
            case OverwriteValueClassType.FinProcessContainer:
                return financing;
            case OverwriteValueClassType.HouseholdOverwriteValue:
                return financing.households.find(it => it.id === entityId);
            case OverwriteValueClassType.LiabilityOverwriteValue:
                return financing.households.reduce<Array<ILiability | INewLiability>>((p, c) => p.concat(c.liabilities, c.newLiabilities), []).find(it => it.id === entityId);
            case OverwriteValueClassType.RealEstateOverwriteValue:
                return financing.realEstates.find(it => it.id === entityId);
            case OverwriteValueClassType.SalesPartnerOverwriteValue:
                return financing.salesPartner;
            case OverwriteValueClassType.TaxOfficeArrearOverwriteValue:
                return financing.households.reduce<ITaxOfficeArrear[]>((p, c) => p.concat(c.taxOfficeArrears), []).find(it => it.id === entityId);
            default:
                return undefined;
        }
    }

    /**
     * Liefert einen Overwrite zu einem Feldnamen in einer Instanz, die überschreibbare Felder unterstützt
     *
     * @template T Generischer Type einer Entität, die Overwrites unterstützt
     * @param {T} entity Entität
     * @param {string} fieldName Feldname (entsprechend DB-Entität)
     * @returns {IOverwriteValue | undefined} Overwrite oder undefined
     */
    public static getOverwriteFromEntity<T extends IBaseOverwriteValues>(entity: T, fieldName: string): IOverwriteValue | undefined {
        return entity.overwriteValues.find(it => it.fieldName.toLowerCase() === fieldName.toLowerCase());
    }

    /**
     * Liefert einen Overwrite zu einem Feldnamen in einer Instanz, die überschreibbare Felder unterstützt
     *
     * @template T Generischer Type einer Entität, die Overwrites unterstützt
     * @param {T} entity Entität
     * @param {UUID} id Identifier des Overwrite
     * @returns {IOverwriteValue | undefined} Overwrite oder undefined
     */
    public static getOverwriteFromEntityById<T extends IBaseOverwriteValues>(entity: T, id: UUID): IOverwriteValue | undefined {
        return entity.overwriteValues.find(it => it.id === id);
    }

    /**
     * Liefert einen überschriebenen Wert oder den originalen, wenn nicht vorhanden
     *
     * @template {IBaseOverwriteValues} T Entitätstyp
     * @template IsoDate Datum als Iso-Format
     * @template {string | number | boolean | UUID | IsoDate | undefined} RT Datentyp des Feldes
     * @param {T} entity Entität
     * @param {string} fieldName Feldname (entsprechend DB-Entität)
     * @param {RT} originalValue Originalwert
     * @param {boolean} withOverwrites Overwrites miteinbeziehen
     * @returns {RT} Geparsten überschriebenen Wert oder Originalwert
     */
    public static getMergedOverwriteValue<T extends IBaseOverwriteValues, RT>(entity: T, fieldName: string, originalValue: RT, withOverwrites = true): RT {
        if (!withOverwrites) {
            return originalValue;
        }
        const overwrite = OverwriteHelperService.getOverwriteFromEntity(entity, fieldName);
        if (overwrite !== undefined && !HelperService.isNullOrEmpty(overwrite.value)) {
            switch (overwrite.valueType) {
                case ValueStorageType.Bool:
                    return (overwrite.value === 'true') as unknown as RT;
                case ValueStorageType.Int:
                    return parseInt(overwrite.value, 10) as unknown as RT;
                case ValueStorageType.Decimal:
                    return parseFloat(overwrite.value) as unknown as RT;
                case ValueStorageType.DateTimeOffset:
                case ValueStorageType.Guid:
                case ValueStorageType.String:
                default:
                    return overwrite.value as unknown as RT;
            }
        }
        return originalValue;
    }

    /**
     * Liefert entweder den überschriebenen Wert oder den originalen Wert.
     * Falls kein überschriebener Wert vorhanden ist, kann auch undefined zurückgegeben werden
     *
     * @template {IBaseOverwriteValues} T Entitätstyp
     * @template IsoDate Datum als Iso-Format
     * @template {string | number | boolean | UUID | IsoDate | undefined} RT Datentyp des Feldes
     * @param {T} entity Entität
     * @param {string} fieldName Feldname (entsprechend DB-Entität)
     * @param {RT} originalValue Originalwert
     * @param {boolean} withOverwrites Overwrites miteinbeziehen
     * @returns {RT} Geparsten überschriebenen Wert oder Originalwert
     */
    public static getUnmergedValue<T extends IBaseOverwriteValues, RT>(entity: T, fieldName: string, originalValue: RT, withOverwrites: boolean): RT | undefined {
        if (!withOverwrites) {
            return originalValue;
        }

        const overwrite = OverwriteHelperService.getOverwriteFromEntity(entity, fieldName);

        if (overwrite === undefined || HelperService.isNullOrEmpty(overwrite.value)) {
            return undefined;
        }

        switch (overwrite.valueType) {
            case ValueStorageType.Bool:
                return (overwrite.value === 'true') as unknown as RT;
            case ValueStorageType.Int:
                return parseInt(overwrite.value, 10) as unknown as RT;
            case ValueStorageType.Decimal:
                return parseFloat(overwrite.value) as unknown as RT;
            case ValueStorageType.DateTimeOffset:
            case ValueStorageType.Guid:
            case ValueStorageType.String:
            default:
                return overwrite.value as unknown as RT;
        }
    }

    /**
     * Liefert alle Adressen aus einer Finanzierung
     *
     * @param {IFinancing} financing Finanzierung
     * @returns {IAddress[]} Adressen
     */
    private static getAllAddresses(financing: IFinancing): IAddress[] {
        let result = [];
        if (financing.salesPartner.address !== undefined) {
            result.push(financing.salesPartner.address);
        }
        result.push(financing.salesPartner.company.address);

        result = result.concat(financing.realEstates.reduce<IAddress[]>((p, c) => {
            const realEstateAddresses = [c.address, c.validatedAddress];
            if (c.trusteeAddress !== undefined) {
                realEstateAddresses.push(c.trusteeAddress);
            }
            return p.concat(realEstateAddresses);
        }, []));

        result = result.concat(financing.households.reduce<IAddress[]>((p, c) => {
            const addresses = c.debitors.reduce<IAddress[]>((pa, cd) => {
                if (cd.employerAddress !== undefined) {
                    return pa.concat([cd.homeAddress, cd.employerAddress]);
                }
                return pa.concat([cd.homeAddress])
            }, []);
            return p.concat(addresses);
        }, []));

        return result.filter(it => it !== undefined);
    }
}
