import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IUser, Logout } from 'app/modules/auth/data';
import { IsoDate } from 'shared/util';

import { LoadBalancingDataLoaded } from './load-balancing.action';

/**
 * Zustandsobjekt für alle existierenden Nutzer
 */
export interface ILoadBalancingStateModel {
    /**
     * Alle aktuell existierenden Nutzer
     */
    users?: IUser[];

    /**
     * Zeitpunkt, wann Nutzerdaten das letzte Mal geändert wurden
     */
    lastModified?: IsoDate;
}

/**
 * Zustand für alle existierenden Nutzer
 */
@State<ILoadBalancingStateModel>({
    name: LoadBalancingState.keyName,
    defaults: {},
})
@Injectable()
export class LoadBalancingState {
    public static readonly keyName = 'loadBalancing';

    /**
     * Selektor für alle existierenden Nutzer
     *
     * @param {ILoadBalancingStateModel} state Zustandsobjekt für alle existierenden Nutzer
     * @returns {IUser} Alle existierenden Nutzer
     */
    @Selector()
    public static users(state: ILoadBalancingStateModel): IUser[] | undefined {
        return state.users;
    }

    /**
     * Zustandsänderung nach dem Laden aller bestehenden Nutzer
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {LoadBalancingDataLoaded} action Aktion
     */
    @Action(LoadBalancingDataLoaded)
    // eslint-disable-next-line class-methods-use-this
    public usersLoaded({ patchState }: StateContext<ILoadBalancingStateModel>, { payload }: LoadBalancingDataLoaded): void {
        patchState({
            users: payload.users,
            lastModified: payload.lastModified,
        });
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    // eslint-disable-next-line class-methods-use-this
    public resetData({ setState }: StateContext<ILoadBalancingStateModel>): void {
        setState({});
    }
}
