import { Component, computed, input } from '@angular/core';
import { EnumSettings } from '@ntag-ef/finprocess-enums';
import { IMultiSelectItem } from 'app/modules/shared';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente für Eingabe Multiselect
 */
@Component({
    selector: 'finprocess-multi-select-input',
    templateUrl: './multi-select-input.component.html',
    styleUrls: ['./multi-select-input.component.scss'],
})
export class MultiSelectInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Multiselect Items
     */
    public items = input<IMultiSelectItem[]>();

    /**
     * Ob beim Setzen von 'keine' alle anderen Felder nicht gesetzt werden
     */
    public autoCheckNone = input<boolean>(false);

    /**
     * Internal list of items
     */
    public internalItems = computed(() => {
        const items = this.items() ?? [];

        return items.map(it => {
            const item = { ...it };
            if (EnumSettings.hasFlag(this.currentValue() as number, it.value)) {
                item.isChecked = true;
            }

            return item;
        })
    });

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     */
    public translatedOriginalValueKey = computed(() => {
        const originalValue = this.originalValue();

        if (originalValue === undefined) {
            return 'financing.features.financing-processing.unset';
        }

        return this.internalItems().filter(it => EnumSettings.hasFlag(originalValue as number, it.value)).map(it => it.displayName).join(', ');
    });

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.Int, financingService);
    }  


    /**
     * alle anhaken
     *
     * @param {IMultiSelectItem} ob IMultiSelectItem
     */
    public updateAllChecked(ob: IMultiSelectItem): void {
        if (this.autoCheckNone()) {
            this.checkboxNone(ob);
        }

        this.save();
    }


    /**
     * Checkbox "Keine"
     * Wird "Keine" gesetzt dann sind alle anderen Checkoxes nicht mehrt gesetzt
     * wird eine andere Checkbox gesetzt dann ist "Keine" nicht mehr gesetzt
     *
     * @param {IMultiSelectItem} selectedItem angeklickte Checkbox
     */
    public checkboxNone(selectedItem: IMultiSelectItem): void {

        if (selectedItem.value === 0 && selectedItem.isChecked === true) {
            this.internalItems().forEach(item => {
                if (item.value !== 0) {
                    item.isChecked = false;
                }
            })
        } else {
            if (selectedItem.value !== 0) {
                const noneItem = this.internalItems().find(item => item.value === 0);

                if (!!noneItem) {
                    noneItem.isChecked = false;
                }
            }

        }
    }

    /**
     * Speichert den Overwrite
     */
    public save(): void {
        if (this.entity() === undefined) {
            return;
        }

        let value = 0;

        this.internalItems().filter(it => it.isChecked).forEach(v => {
            value |= v.value;
        });

        super.save((value !== 0 ? value : undefined) as unknown as never);
    }
}
