import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { WaiterModule } from '@ntag-ef/waiter';
import { AuthChecksModule } from 'app/modules/auth/api';
import { MasterdataDataModule } from 'app/modules/masterdata/data';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlexLayoutModule } from 'ngx-flexible-layout'

import { PipesModule } from '../../../shared/features/pipes/pipes.module';
import { AdministrationDataModule } from '../../data';

import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent, BranchComponent, ControlTablesComponent, CountryComponent, DebitRatesComponent, EditBranchDialogComponent, EditUserDialogComponent, FinancingConfigurationComponent, LiabilityConfigurationComponent, RiskParametrizationComponent, SmartdocConfigurationComponent, SmartdocDropareaComponent, SmartdocEditDialogComponent, SmartdocFolderComponent, SystemConfigComponent, UsersComponent } from './components';
import { SalespartnerRegionComponent } from './components/salespartner-region/salespartner-region.component';
import { SystemdocumentsComponent } from './components/system-documents/systemdocuments/systemdocuments.component';
import { BranchStatusPipe, LanguageShortPipe, OrganisationalUnitResponsibilityShortPipe } from './pipes';


/**
 * Modul zur Administrierung von Nutzern und Stammdaten
 */
@NgModule({
    declarations: [
        AdministrationComponent,
        BranchComponent,
        ControlTablesComponent,
        DebitRatesComponent,
        EditBranchDialogComponent,
        FinancingConfigurationComponent,
        RiskParametrizationComponent,
        UsersComponent,
        SystemConfigComponent,
        BranchStatusPipe,
        EditUserDialogComponent,
        LanguageShortPipe,
        CountryComponent,
        SmartdocConfigurationComponent,
        SmartdocFolderComponent,
        SmartdocDropareaComponent,
        SmartdocEditDialogComponent,
        SalespartnerRegionComponent,
        OrganisationalUnitResponsibilityShortPipe,
        SystemdocumentsComponent,
        LiabilityConfigurationComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgxCurrencyDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatInputModule,
        MatSidenavModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule.forChild(),
        EnumTranslationModule.forChild(),
        WaiterModule,
        NotificationsModule,
        MasterdataDataModule,
        AuthChecksModule,
        AdministrationRoutingModule,
        AdministrationDataModule,
        MatAutocompleteModule,
        MatCardModule,
        MatOptionModule,
        MatFormFieldModule,
        PipesModule,
        DragDropModule,
    ],
})
export class AdministrationModule {

    /**
     * Konstruktor zum Hinzufügen von Icons
     *
     * @param {DomSanitizer} domSanitizer DomSanitizer-Injektor
     * @param {MatIconRegistry} iconRegistry IconRegistry-Injektor
     */
    public constructor(private domSanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
        this.iconRegistry.addSvgIcon('finprocess-file-signature', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/finprocess-icons/finprocess-file-signature.svg'));
    }
}
