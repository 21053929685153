/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { debounceTime } from 'rxjs';

import { FinprocessFormArray, FinprocessFormControl, FinprocessFormGroup } from '../forms';
import { CreateFormGroupOptions, FinprocessTypedForm, FormProviderToken, IFormProvider, IProviderFunctionArray, IProviderFunctionMulti, IProviderFunctionMultiArray, IProviderFunctionSingle, IProviderWithMulti, IValidationOptions, ProviderInput } from '../interfaces';

/**
 * Helper to create a finprocess form group in a simple way
 * 
 * @param {CreateFormGroupOptions<any>} options Options for validators. If no validators are given or if the property will be overriden set the value to null
 * @param {FormProviderToken} provider A provider token for this form group
 * @param {(parent?: any) => Partial<FinprocessTypedForm<any>>} overrides An object with overrides. Every property in these overrides will be set over the options property. Use overrides
 * for properties that are not simple form controls. For example FormArrays or FormGroups
 * @returns {(parent?: any, formGroupValidationOptions?: IValidationOptions) => FinprocessFormGroup<FinprocessTypedForm<any>>} A function that initializes a finprocess form group
 */
export function createFormGroup<T>(options: CreateFormGroupOptions<T>, provider: FormProviderToken<T>, overrides?: (parent?: T) => Partial<FinprocessTypedForm<T>>): (parent?: T, formGroupValidationOptions?: IValidationOptions<any, any, any, any>) => FinprocessFormGroup<T> {
    return (parent?: T, formGroupValidationOptions?: IValidationOptions<any, any, any, any>) => {
        const overridesValue = !!overrides ? overrides(parent) : null;
        const keys = Object.keys(options) as Array<keyof T>;
        const formGroup: Partial<FinprocessTypedForm<T>> = {};
        for (const key of keys) {
            const override = !!overridesValue ? overridesValue[key as keyof typeof overridesValue] : undefined;

            if (override !== undefined) {
                formGroup[key as keyof typeof formGroup] = override;
                continue;
            }

            const validationOptions = options[key];
            const value: T[keyof T] | null = !!parent ? parent[key] : null;

            if (validationOptions !== null) {
                formGroup[key as keyof typeof formGroup] = new FinprocessFormControl(value, validationOptions) as unknown as never;
            } else {
                formGroup[key as keyof typeof formGroup] = new FinprocessFormControl(value) as unknown as never;
            }
        }

        const form = new FinprocessFormGroup<T>(formGroup as FinprocessTypedForm<T>, formGroupValidationOptions);
        form.provides = provider;

        return form;
    }
}

/**
 * Helper to create a root finprocess form group. The root is important because it will initialize all validators of children form groups
 * 
 * @param {CreateFormGroupOptions<any>} options Options for validators. If no validators are given or if the property will be overriden set the value to null
 * @param {FormProviderToken} provider A provider token for this form group
 * @param {(parent?: any) => Partial<FinprocessTypedForm<any>>} overrides An object with overrides. Every property in these overrides will be set over the options property. Use overrides
 * for properties that are not simple form controls. For example FormArrays or FormGroups
 * @returns {(parent?: any, options?: IValidationOptions) => FinprocessFormGroup<FinprocessTypedForm<any>>} A function that initializes a finprocess form group
 */
export function createRootFormGroup<T>(options: CreateFormGroupOptions<T>, provider: FormProviderToken<T>, overrides?: (parent?: T) => Partial<FinprocessTypedForm<T>>): (parent?: T, validationOptions?: IValidationOptions<any, any, any, any>, additionalProviders?: IFormProvider<any>[]) => FinprocessFormGroup<T> {
    const rootForm = (parent?: T, formGroupValidationOptions?: IValidationOptions<any, any, any, any>, additionalProviders: IFormProvider<unknown>[] = []) => {
        const formGroup = createFormGroup<T>(options, provider, overrides)(parent, formGroupValidationOptions);

        for (const additionalProvider of additionalProviders) {
            if (!!additionalProvider.group && !Array.isArray(additionalProvider.group)) {
                formGroup.injector.setProvider(additionalProvider.group, additionalProvider.multi ?? false, additionalProvider.description);
            }
        }

        formGroup.init();

        if (formGroup.subscription && !formGroup.subscription.closed) {
            formGroup.subscription.unsubscribe();
        }

        formGroup.subscription = formGroup.valueChanges.pipe(debounceTime(100)).subscribe(() => {
            formGroup.updateValidators();
            formGroup.updateVisibility();
        })

        return formGroup;
    }

    return rootForm;
}

/**
 * Parses validation options and sets validator, default and visibility functions
 * 
 * @param {FinprocessFormControl | FinprocessFormArray | FinprocessFormGroup} formControl Form Control
 * @param {IValidationOptions} options Validation options
 */
export function parseValidationOptions<T>(formControl: FinprocessFormControl<T> | FinprocessFormArray<T, any> | FinprocessFormGroup<T>, options?: IValidationOptions<any, any, any, any>): void {
    if (!!options?.default) {
        const providers: FormProviderToken<T> | FormProviderToken<T>[] | IProviderWithMulti<T> | Array<IProviderWithMulti<T>> = options.default.providers;
        formControl.defaultFunction = options.default.fn as (arg1?: any, arg2?: any, arg3?: any, arg4?: any) => T[];
        formControl.defaultProviders = (Array.isArray(providers) ? providers : [providers]).map(val => (val instanceof FormProviderToken ? { token: val, multi: false } : val));
    }

    const validator = options?.validator;

    if (!!validator) {
        if (typeof validator === 'function') {
            formControl.fixedValidator = validator;
        } else {
            const providers: FormProviderToken<T> | FormProviderToken<T>[] | IProviderWithMulti<T> | Array<IProviderWithMulti<T>> = validator.providers;
            formControl.validatorFunction = validator.fn;
            formControl.validatorProviders = (Array.isArray(providers) ? providers : [providers]).map(val => (val instanceof FormProviderToken ? { token: val, multi: false } : val));
        }
    }

    if (!!options?.visible) {
        const providers: FormProviderToken<T> | FormProviderToken<T>[] | IProviderWithMulti<T> | Array<IProviderWithMulti<T>> = options?.visible.providers;
        formControl.visibilityFunction = options.visible.fn;
        formControl.visibilityProviders = (Array.isArray(providers) ? providers : [providers]).map(val => (val instanceof FormProviderToken ? { token: val, multi: false } : val));
    }

    if (!!options?.alternativTranslationKey && formControl instanceof FinprocessFormControl) {
        const providers: FormProviderToken<T> | FormProviderToken<T>[] | IProviderWithMulti<T> | Array<IProviderWithMulti<T>> = options?.alternativTranslationKey.providers;
        formControl.translateKeyFunction = options.alternativTranslationKey.fn;
        formControl.translateKeyProviders = (Array.isArray(providers) ? providers : [providers]).map(val => (val instanceof FormProviderToken ? { token: val, multi: false } : val));
    }
}

/**
 * Creates validation options with automatic type inference
 * 
 * @param {IValidationOptions} opts Validation options
 * @returns {IValidationOptions} Options
 */
// export function createOptions<T extends FormProviderToken<any> | Array<FormProviderToken<any>>>(opts: IValidationOptions<T>): IValidationOptions<T> {
//     return opts;
// }
export function createOptions<vis extends FormProviderToken<any> | Array<FormProviderToken<any>>, val extends FormProviderToken<any> | Array<FormProviderToken<any>>, def extends FormProviderToken<any> | Array<FormProviderToken<any>>, alt extends FormProviderToken<any> | Array<FormProviderToken<any>>>(opts: IValidationOptions<vis, val, def, alt>): IValidationOptions<vis, val, def, alt> {
    return opts;
}



export function createProviderInput<T extends FormProviderToken<any>, R>(prov: IProviderFunctionSingle<T, R>): IProviderFunctionSingle<T, R>;
export function createProviderInput<T extends IProviderWithMulti<any>, R>(prov: IProviderFunctionMulti<T, R>): IProviderFunctionMulti<T, R>;
export function createProviderInput<T extends Array<FormProviderToken<any>>, R>(prov: IProviderFunctionArray<T, R>): IProviderFunctionArray<T, R>;
export function createProviderInput<T extends Array<IProviderWithMulti<any>>, R>(prov: IProviderFunctionMultiArray<T, R>): IProviderFunctionMultiArray<T, R>;
/**
 * Creates a provider input interface with automatic type inference
 * 
 * @param {ProviderInput} prov Provider Input
 * @returns {ProviderInput} Provider Input with correct types
 */
export function createProviderInput<T, R>(prov: ProviderInput<T, R>): ProviderInput<T, R> {
    return prov;
}

// /**
//  * Creates a validator function
//  * 
//  * @param {IValidationOptions} opts Validation options
//  * @returns {IValidationOptions} Options
//  */
// export function createProviderFunction<T extends FormProviderToken<any>[], R>(opts: IProviderFunction<T, R>): IProviderFunction<T, R> {
//     return opts;
// }

/**
 * Form Builder for Finprocess Forms
 */
@Injectable()
export class FinprocessFormBuilder {
    public static createFormGroup = createFormGroup;
    public static createRootFormGroup = createRootFormGroup;
    public static parseValidationOptions = parseValidationOptions;

    /**
     * Helper to create a finprocess form group in a simple way
     * 
     * @param {CreateFormGroupOptions<any>} options Options for validators. If no validators are given or if the property will be overriden set the value to null
     * @param {FormProviderToken} provider A provider token for this form group
     * @param {(parent?: any) => Partial<FinprocessTypedForm<any>>} overrides An object with overrides. Every property in these overrides will be set over the options property. Use overrides
     * for properties that are not simple form controls. For example FormArrays or FormGroups
     * @returns {(parent?: any, formGroupValidationOptions?: IValidationOptions) => FinprocessFormGroup<FinprocessTypedForm<any>>} A function that initializes a finprocess form group
     */
    public createFormGroup<T>(options: CreateFormGroupOptions<T>, provider: FormProviderToken<T>, overrides?: (parent?: T) => Partial<FinprocessTypedForm<T>>): (parent?: T, formGroupValidationOptions?: IValidationOptions<any, any, any, any>) => FinprocessFormGroup<T> {
        return createFormGroup(options, provider, overrides)
    }

    /**
     * Helper to create a root finprocess form group. The root is important because it will initialize all validators of children form groups
     * 
     * @param {CreateFormGroupOptions<any>} options Options for validators. If no validators are given or if the property will be overriden set the value to null
     * @param {FormProviderToken} provider A provider token for this form group
     * @param {(parent?: any) => Partial<FinprocessTypedForm<any>>} overrides An object with overrides. Every property in these overrides will be set over the options property. Use overrides
     * for properties that are not simple form controls. For example FormArrays or FormGroups
     * @returns {(parent?: any, options?: IValidationOptions) => FinprocessFormGroup<FinprocessTypedForm<any>>} A function that initializes a finprocess form group
     */
    public createRootFormGroup<T>(options: CreateFormGroupOptions<T>, provider: FormProviderToken<T>, overrides?: (parent?: T) => Partial<FinprocessTypedForm<T>>): (parent?: T, validationOptions?: IValidationOptions<any, any, any, any>, additionalProviders?: IFormProvider<any>[]) => FinprocessFormGroup<T> {
        return createRootFormGroup(options, provider, overrides);
    }

    /**
     * Parses validation options and sets validator, default and visibility functions
     * 
     * @param {FinprocessFormControl | FinprocessFormArray | FinprocessFormGroup} formControl Form Control
     * @param {IValidationOptions} options Validation options
     */
    public parseValidationOptions<T>(formControl: FinprocessFormControl<T> | FinprocessFormArray<T, any> | FinprocessFormGroup<T>, options?: IValidationOptions<any, any, any, any>): void {
        parseValidationOptions(formControl, options);
    }
}


// interface IFoo {
//     foo: string;
//     bar: number;
//     baz: boolean;
// }

// interface IBar {
//     foobar: string;
//     foobaz: number;
// }

// const FOO = new FormProviderToken<IFoo>('foo');
// const BAR = new FormProviderToken<IBar>('bar');

// createOptions({
//     validator: {
//         providers: FOO,
//         fn: foo => Validators.min(foo.bar),
//     },
//     // visible: {
//     //     providers: [FOO, BAR],
//     //     fn: (foo, bar) => foo.bar === bar.bar,
//     // },
//     // default: {
//     //     providers: { multi: true, token: FOO },
//     //     fn: foo => foo.bar,
//     // },
//     // alternativTranslationKey: {
//     //     providers: [{multi: true, token: FOO}],
//     //     fn: foo => foo.bar.toString(),
//     // },
// });

// const formGroup = createFormGroup({
//     bar: {
//         validator: createProviderInput({
//             providers: FOO,
//             fn: foo => Validators.min(foo.bar),
//         }),
//     },
//     baz: {
//         validator: createProviderInput({
//             providers: [{ multi: true, token: FOO}, { multi: true, token: BAR}],
//             fn: foo => Validators.min(foo.bar),
//         }),
//         visible: createProviderInput({
//             providers: [FOO, FOO, FOO],
//             fn: foo => foo.bar < 3,
//         }),
//     },
//     foo: createOptions({
//         validator: createProviderInput({
//             providers: [FOO, BAR],
//             fn: (foo, bar) => Validators.min(foo.bar + bar.foobaz),
//         }),
//     }),
// }, FOO);

// const abc = createOptions({
//     validator: {
//         providers: FOO,
//         fn: foo => Validators.min(foo.bar),
//     },
//     visible: {
//         providers: [FOO, BAR],
//         fn: foo => true,
//     },
// });

// const createFooForm = createFormGroup<IFoo>({
//     bar: {
//         validator: {
//             providers: [FOO],
//             fn: foo => Validators.min(foo.bar),
//         },
//     },
//     baz: null,
//     foo: null,
// }, FOO);


// function test(foo: boolean): boolean;
// function test(foo: number): number;
// function test(foo: string): string;
// function test(foo: string | number | boolean): string | number | boolean {
//     return foo;
// }

// var a = test(1);
// var b = test('1');


// const a = createProviderInput({
//     providers: [FOO, BAR, FOO],
//     fn: (foo, bar, baz) => foo.bar,
// });

// const b = createProviderInput({
//     providers: FOO,
//     fn: foo => foo.bar,
// });

// const c = createProviderInput({
//     providers: { multi: true, token: FOO},
//     fn: foo => foo.bar,
// });

// const d = createProviderInput({
//     providers: [{ multi: true, token: FOO}, { multi: true, token: BAR}],
//     fn: (foo, bar) => foo.bar,
// });

// a.fn;
// b.fn;
// c.fn;
// d.fn;
