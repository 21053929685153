/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormArray, ValidationErrors, ValidatorFn, ɵRawValue } from '@angular/forms';

import { FormProviderToken, IProviderWithMulti, IValidationOptions, ProviderInput } from '../interfaces';

import { FinprocessFormControl } from './finprocess-form-control';
import { FinprocessFormGroup } from './finprocess-form-group';
import { parseValidationOptions } from './form-builder';
import { FormInjector } from './form-injector';

/**
 * Finprocess Form Array, das ein normales Form Array um eine Funktion
 * zum Aktualisieren der Validatoren erweitert *
 */
// export class FinprocessFormArray<T, R extends FinprocessFormGroup<T> | FinprocessFormControl<T>> extends FormArray<R> {
export class FinprocessFormArray<T, R extends (T extends object ? FinprocessFormGroup<T> : FinprocessFormControl<T>)> extends FormArray<R> {

    public injector: FormInjector = new FormInjector();

    /**
     * Funktion für die Validierung der Form Control
     */
    public validatorFunction?: (...args: unknown[]) => ValidatorFn | null;

    /**
     * Fixer Validator, der sich nie ändert
     */
    public fixedValidator?: ValidatorFn;

    /**
     * Zugriffsproperties für Unterobjekte
     */
    public validatorProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion für die Sichtbarkeit der Form Control
     */
    public visibilityFunction?: (...args: unknown[]) => boolean;

    /**
     * Zugriffsproperties für Unterobjekte für die Sichtbarkeit
     */
    public visibilityProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion zum Berechnen des Default Werts
     */
    public defaultFunction?: (...args: unknown[]) => T[];

    /**
     * Zugriffsproperties für Unterobjekte für den Default Wert
     */
    public defaultProviders: IProviderWithMulti<unknown>[] = [];


    /**
     * Funktion für die Validierung der Form Control
     */
    private validatorChildFunction?: (...args: unknown[]) => ValidatorFn | null;

    /**
     * Fixer Validator, der sich nie ändert
     */
    private fixedChildValidator?: ValidatorFn;

    /**
     * Zugriffsproperties für Unterobjekte
     */
    private validatorChildProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Initialisiert ein FinprocessFormArray
     * 
     * @param {any} value Initialer Wert
     * @param {IValidationOptions} options Validierungsoptionen
     * @param {IValidationOptions} childValidators Validierungsoptionen für die Array Kinder, nur relevant bei FormArray von FormControls
     */
    public constructor(value?: FinprocessFormGroup<T>[] | FinprocessFormControl<T>[], options?: IValidationOptions<any, any, any, any>, childValidators?: ProviderInput<any, ValidatorFn | null> | ValidatorFn | null) {
        super((value as unknown as R[]) ?? []);

        parseValidationOptions(this, options);
        this.parseChildValidators(childValidators);
    }

    /**
     * Initialisiert die Validatoren und Default Werte aller Kinder
     */
    public init(): void {
        this.initProviders();
        this.initValidation();
    }

    /**
     * Intitialisiert alle Provider. Sollte vor den Validatoren ausgeführt werden um keine Exceptions für fehlende Provider zu bekommen
     */
    public initProviders(): void {
        for (const control of this.controls) {
            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray) {
                control.initProviders();
            }
        }
    }

    /**
     * Initialisiert Validatoren und Sichtbarkeit des FormArrays und aller Kinder
     */
    // eslint-disable-next-line complexity
    public initValidation(): void {
        if (!!this.defaultFunction && (this.value === null || this.value === undefined || (Array.isArray(this.value) && this.value.length === 0))) {
            const defaultValue = this.getDefaultValue() as unknown[];

            if (defaultValue !== null) {
                const patchArray: AbstractControl<any, any>[] = [];

                for (const v of defaultValue) {
                    // TODO zukünftig auch FormGroups auswerten
                    patchArray.push(new FinprocessFormControl(v, { validator: this.getChildValidators() }))
                }

                this.controls = patchArray as unknown as never;
            }
        }

        if (!!this.validatorFunction || !!this.fixedValidator) {
            this.updateValidators();
        }

        for (const control of this.controls) {
            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray || control instanceof FinprocessFormControl) {
                control.initValidation();
            }
        }
    }

    /**
     * Gibt einen Provider zurück
     * 
     * @param {FormProviderToken} token Providertoken
     * @param {boolean} multi Handelt es sich um einen Multiprovider?
     * @returns {object} Inhalt des Providers
     */
    public getProvider<prov>(token: FormProviderToken<prov>, multi: boolean): prov {
        const value = this.injector.getProvider<prov>({ description: token, multi });

        if (!!value) {
            return value;
        }

        if (this.parent instanceof FinprocessFormArray || this.parent instanceof FinprocessFormGroup) {
            return this.parent.getProvider<prov>(token, multi);
        }

        throw new Error(`No provider for ${token} found`);
    }

    /**
     * Aktualisiert die Validatoren der FormControl
     *
     */
    public updateValidators(): void {
        this.setValidators(this.getValidator());
        this.updateValueAndValidity({ onlySelf: true, emitEvent: false });

        for (const control of this.controls) {
            if (control instanceof FinprocessFormArray || control instanceof FinprocessFormGroup || control instanceof FinprocessFormControl) {
                control.updateValidators();
            }
        }
    }

    /**
     * Aktualisiert die Sichtbarkeit der FormControl
     *
     * @returns {boolean} Sichtbarkeit
     */
    public updateVisibility(): boolean {
        if (!!this.visibilityFunction) {
            let parameters: unknown[] = [];

            if (this.visibilityProviders) {
                parameters = this.visibilityProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.visibilityFunction(...parameters);
        }

        return true;
    }

    /**
     * Bestimmt den Validator anhand eines fixen Validators oder einer Funktion
     * 
     * @returns {ValidatorFn | null} Validator der Childrends
     */
    public getChildValidators(): ValidatorFn | null {
        if (!!this.fixedChildValidator) {
            return this.fixedChildValidator;
        }

        if (!!this.validatorChildFunction) {
            let parameters: unknown[] = [];

            if (this.validatorChildProviders) {
                parameters = this.validatorChildProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.validatorChildFunction(...parameters);
        }

        return null;
    }

    /**
     * Bestimmt den Validator anhand eines fixen Validators oder einer Funktion
     * 
     * @returns {ValidatorFn | null} Validator
     */
    private getValidator(): ValidatorFn | null {
        if (!!this.fixedValidator) {
            return this.fixedValidator;
        }

        if (!!this.validatorFunction) {
            let parameters: unknown[] = [];

            if (this.validatorProviders) {
                parameters = this.validatorProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.validatorFunction(...parameters);
        }

        return null;
    }

    /**
     * Berechnet den Default Wert
     *
     * @returns {any} Default Wert
     */
    private getDefaultValue(): T[] | null {
        if (!!this.defaultFunction) {
            let parameters: unknown[] = [];

            if (this.defaultProviders) {
                parameters = this.validatorProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.defaultFunction(...parameters);
        }

        return null;
    }

    /**
     * Sammelt alle Fehlermeldungen eines Form Arrays
     *
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    public collectErrors(): ValidationErrors | null {
        const errors: Array<ValidationErrors | null> = this.controls.map(control => {
            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray) {
                return control.collectErrors();
            }

            return control.errors;
        });

        const errorMap: Record<string, ValidationErrors | null | Record<string, unknown>> = {}

        errors.forEach((error, index) => {
            errorMap[index] = error;
        });

        if (this.errors !== null) {
            errorMap['_self'] = this.errors;
            errors.push(this.errors);
        }

        if (this.controls.every(ctrl => ctrl instanceof FinprocessFormGroup)) {
            return errors.every(error => error === null) ? null : errorMap;
        }

        return errors.every(error => error === null) ? null : errors;
    }

    /**
     * Gibt den Inhalt des FormArrays zurück
     * 
     * @returns {Array} Rohe Daten
     */
    public override getRawValue(): 0 extends 1 & R ? any[] : ɵRawValue<R>[] {
        const rawValue = super.getRawValue();
        if (rawValue.length === 0) {
            return null as any;
        }

        return rawValue;
    }

    /**
     * Wie getRawValue aber mit korrekter Typisierung
     * 
     * @returns {object} Analog getRawValue nur mit korrekter Typisierung
     */
    public getRaValue(): T[] {
        return super.getRawValue() as T[];
    }

    /**
     * Parses validation options and sets validator for childrens
     * 
     * @param {IValidationOptions} childValidators Validation options
     */
    private parseChildValidators(childValidators?: ProviderInput<any, ValidatorFn | null> | ValidatorFn | null) {
        if (typeof childValidators === 'function') {
            this.fixedChildValidator = childValidators;
        }
        else if (!!childValidators) {
            const providers: FormProviderToken<unknown> | FormProviderToken<unknown>[] | IProviderWithMulti<unknown> | Array<IProviderWithMulti<unknown>> = childValidators.providers;
            this.validatorChildFunction = childValidators.fn;
            this.validatorChildProviders = (Array.isArray(providers) ? providers : [providers]).map(val => (!(val instanceof FormProviderToken) ? val : { token: val, multi: false }));
        }
    }
}
