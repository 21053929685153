import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { MandantType } from '@ntag-ef/finprocess-enums/finprocess';
import { Role, UserService } from 'app/modules/auth/data';
import { FinancingEventType, IInformationEvents, InformationEventService } from 'app/modules/information-events/data';
import { Observable, Subject, forkJoin, map, takeUntil, throwError } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { HelperService, UUID } from 'shared/util';

import { FinancingMode, FinancingService, FinancingState, FinancingTab, IFinancingStateParentDefinition, IFinprocessContainer, TabChanged } from './../../../../data';

interface IFinancingTabVisibility {
    tab: FinancingTab;
    visible: boolean;
}

/**
 * Komponente für die Bearbeitung der Finanzierung
 */
@Component({
    selector: 'finprocess-financing',
    templateUrl: './financing.component.html',
    styleUrls: ['./financing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancingComponent implements OnInit, OnDestroy {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public MandantType = MandantType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingMode = FinancingMode;
    
    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly FinancingTab = FinancingTab;

    /**
     * Selektierter Tabindex als Observable
     */
    public selectedTabIndex$!: Observable<number>;

    public visibleTabs: IFinancingTabVisibility[] = [
        { tab: FinancingTab.Debitor, visible: true },
        { tab: FinancingTab.Household, visible: true },
        { tab: FinancingTab.FinancingPlan, visible: true },
        { tab: FinancingTab.ProductCalculator, visible: true },
        { tab: FinancingTab.Documents, visible: true },
        { tab: FinancingTab.Process, visible: true },
        { tab: FinancingTab.SampleCalculation, visible: true },
    ];



    /**
     * Observable ob der Stornieren Button aktiv ist
     */
    public chatReadonly$!: Observable<(isInternal: boolean) => boolean>;

    /**
     * Modus (Einreichung oder Risikofinanzierungsplan)
     */
    public financingMode$!: Observable<FinancingMode>;

    public loading = false;

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     * @param {UserService} userService UserService-Injektor
     */
    public constructor(
        private store: Store,
        public financingService: FinancingService,
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        private informationEventService: InformationEventService,
        private userService: UserService,
    ) { }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['riskfinancingplanid'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(() => {
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            switchMap(id => 
                forkJoin([
                    this.financingService.loadFinancing(id),
                    this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer).pipe(
                        filter((container): container is IFinprocessContainer => container !== undefined),
                        take(1),
                    ),
                ] as const),
            ),
            mergeMap(([financing, finprocessContainer]) => {
                if (financing === undefined || finprocessContainer === undefined) {
                    return throwError(() => new Error('Finanzierung nicht geladen'));
                }

                let request: Observable<IInformationEvents[] | undefined>;

                if (finprocessContainer.temporaryUser?.id === this.userService.user?.id) {
                    const editor = finprocessContainer.users.find(user => HelperService.hasBit(user.roles, Role.Expert));

                    if (editor === undefined) {
                        return throwError(() => new Error('Financing has no expert'));
                    }
                    request = this.informationEventService.loadInformationEvent(financing.id, editor.id);
                } else {
                    request = this.informationEventService.loadInformationEvent(financing.id);
                }

                return request.pipe(
                    mergeMap(informationEvents => this.store.selectOnce(FinancingState.isExpert).pipe(
                        map(isExpert => [informationEvents, isExpert] as const),
                    )),
                )
            }),
        ).subscribe(( [informationEvents, isExpert] ) => {
            this.loading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();


            if (!isExpert) {
                return;
            }

            if (Array.isArray(informationEvents)) {
                const filteredEvents = informationEvents.filter(event => [
                    FinancingEventType.NewNotice,
                    FinancingEventType.ChangeRequestVpcSended,
                ].includes(event.eventType) && !event.events.every(it => it.isRead));

                if (filteredEvents.length > 0) {
                    this.informationEventService.changeReadStatus({
                        eventIds: filteredEvents.reduce((eventIds, current) => {
                            const ids = current.events.filter(event => !event.isRead).map(event => event.id);

                            if (ids.length > 0) {
                                eventIds.push(...ids);
                            }

                            return eventIds;
                        }, [] as string[]),
                        finProcessContainerId: filteredEvents[0].financingId,
                    }, true).subscribe();
                }

            }
        });

        this.financingMode$ = this.activatedRoute.queryParamMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => params.get('mode') as FinancingMode),
            distinctUntilChanged(),
        );

        this.financingMode$.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(financingMode => {

            for (const tab of this.visibleTabs) {
                if ([FinancingTab.Debitor,
                    FinancingTab.Household,
                    FinancingTab.Documents,
                    FinancingTab.Process,
                    FinancingTab.ProductCalculator,
                    FinancingTab.SampleCalculation,
                ].includes(tab.tab)) {
                    tab.visible = financingMode !== FinancingMode.RiskFinancingPlan;
                }

                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }
        });

        this.selectedTabIndex$ = this.store.select((it: IFinancingStateParentDefinition) => it.financingTabs.currentTab).pipe(
            takeUntil(this.onDestroy$),
            map(currentTab => this.externalTabIndexToInternal(currentTab ?? 0)),
        );
    }

    /**
     * Tab wird selektiert
     *
     * @param {number} event Event, wenn sich ausgewählter Tab ändert
     */
    public tabChanged(event: number): void {
        const externalTab = this.internalTabIndexToExternal(event);

        this.store.dispatch(new TabChanged(externalTab));
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Mapt den Tabindex der mat-tab Komponente in den korrekten Index für den FinancingState
     * 
     * @param {number} index Interner Tab-Index
     * @returns {number} Externer Tab Index
     */
    public internalTabIndexToExternal(index: number): number {
        let cnt = 0;
        let externalIndex = 0;

        for (const tab of this.visibleTabs) {
            if (index === cnt) {
                return externalIndex;
            }

            if (tab.visible) {
                cnt++;
            }
            externalIndex++;
        }

        return externalIndex;
    }

    /**
     * Mapt den Index aus dem FinancingState in den korrekten Index für die mat-tab Komponente
     * 
     * @param {number} index Externer Tab-Index
     * @returns {number} Interner Tab Index 
     */
    public externalTabIndexToInternal(index: number): number {
        let cnt = 0;
        let externalIndex = 0;

        for (const tab of this.visibleTabs) {
            if (index === externalIndex) {
                return cnt;
            }

            if (tab.visible) {
                cnt++;
            }
            externalIndex++;
        }

        return cnt;
    }

    /**
     * Gibt die Sichtbarkeit eines Tabs zurück
     * 
     * @param {FinancingTab} financingTab Ausgewählter Tab 
     * @returns {boolean} Sichtbarkeit des Tabs
     */
    public getTabVisibility(financingTab: FinancingTab): boolean {
        return this.visibleTabs.find(tab => tab.tab === financingTab)?.visible ?? false;
    }
}
