import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { IIncomeDependentPerson, IRiskAssessmentRule } from '@ntag-ef/validation';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig } from 'ngx-currency';
import { Observable, mergeMap, tap } from 'rxjs';

import { IRiskParametrizationResponse, ISystemConfiguration } from './../../../../data/interfaces';
import { RiskParametrizationService } from './../../../../data/services/risk-parametrization/risk-parametrization.service';
import { IRiskParametrizationStateModel } from './../../../../data/states/risk-parametrization/risk-parametrization.state';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';

/**
 * Komponente für die Administration der Risikoparameter
 */
@Component({
    selector: 'finprocess-risk-parametrization',
    templateUrl: './risk-parametrization.component.html',
    styleUrls: ['./risk-parametrization.component.scss'],
})
export class RiskParametrizationComponent implements OnInit {

    @Select((it: IAdministrationStateParentDefinition) => it.riskParametrization)
    public riskParametrization$!: Observable<IRiskParametrizationStateModel>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Aktuelle Systemkonfiguration
     */
    public systemConfiguration$!: Observable<ISystemConfiguration>;

    /**
     * Aktuelle Risikobewertungsregeln
     */
    public riskAssessmentRules$!: Observable<IRiskAssessmentRule>;

    /**
     * Aktuelle Mindesteinkommen bei unterhaltspflichtigen Personen
     */
    public incomeDependentPersonBases$!: Observable<IIncomeDependentPerson>;

    /**
     * Ob das Formular gesperrt ist
     */
    public locked = true;

    /**
     * Formular
     */
    public form: FormGroup;

    public currencyOptions!: NgxCurrencyConfig;

    public factorOptions!: NgxCurrencyConfig;

    private locale: string;

    /**
     * incomeDependentPersonBases für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get incomeDependentPersonBases(): FormGroup[] {
        return (this.form.get('incomeDependentPersonBases') as FormArray)?.controls as FormGroup[];
    }

    /**
     * riskAssessmentRules für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get riskAssessmentRules(): FormGroup[] {
        return (this.form.get('riskAssessmentRules') as FormArray)?.controls as FormGroup[];
    }

    /**
     *
     * Standard Konstruktor
     *
     * @param {RiskParametrizationService} riskParametrizationService RiskParametrizationService-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {string} locale Lokalisierung
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     */
    public constructor(
        private riskParametrizationService: RiskParametrizationService,
        private fb: FormBuilder,
        @Inject(LOCALE_ID) locale: string,
        private notification: NotificationService,
        private translate: TranslateService,
        private waiterService: WaiterService,
    ) {
        this.form = this.fb.group({
            minimumAge: [null, Validators.required],
            maximumDuration: [null, Validators.required],
            minimumGrossFinancingRequirement: [null, Validators.required],
            maximumComfortCreditPlus: [null, Validators.required],
            validationMessageAcceptableCreditRate: [null, Validators.required],
            extendedDurationFrom: [null, Validators.required],
            minimumOwnCapitalPercent: [null, Validators.required],
            minDsti: [null, [Validators.required, Validators.min(0), Validators.max(1)]],
            minLtv: [null, [Validators.required, Validators.min(0), Validators.max(1)]],
            incomeDependentPersonBases: this.fb.array([]),
            riskAssessmentRules: this.fb.array([]),
            ruleId: [null],
            ruleValidationMessage: [null],
            splitAge: [null, Validators.required],
            maximumDurationSplitAge: [null, Validators.required],
        });

        this.disableForm();

        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        this.locale = locale === 'de-AT' ? 'de' : locale;

        this.initCurrencyMasks();
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     * Initialisiert das Formular mit allen Werten
     */
    public ngOnInit(): void {
        this.riskParametrizationService.get().subscribe();
        this.riskParametrization$.subscribe(riskParametrization => {
            if (riskParametrization === undefined) {
                return;
            }

            // Werte der Systemkonfiguration
            this.form.get('minimumAge')?.patchValue(riskParametrization.systemConfiguration?.minimumAge);
            this.form.get('maximumDuration')?.patchValue(riskParametrization.systemConfiguration?.maximumDuration);
            this.form.get('minimumGrossFinancingRequirement')?.patchValue(riskParametrization.systemConfiguration?.minimumGrossFinancingRequirement);
            this.form.get('maximumComfortCreditPlus')?.patchValue(riskParametrization.systemConfiguration?.maximumComfortCreditPlus);
            this.form.get('validationMessageAcceptableCreditRate')?.patchValue(riskParametrization.systemConfiguration?.validationMessageAcceptableCreditRate);
            this.form.get('extendedDurationFrom')?.patchValue(riskParametrization.systemConfiguration?.extendedDurationFrom);
            this.form.get('minimumOwnCapitalPercent')?.patchValue(riskParametrization.systemConfiguration?.minimumOwnCapitalPercent);
            this.form.get('splitAge')?.patchValue(riskParametrization.systemConfiguration?.splitAge);
            this.form.get('maximumDurationSplitAge')?.patchValue(riskParametrization.systemConfiguration?.maximumDurationSplitAge);

            const incomeDependentPersonBases = this.form.get('incomeDependentPersonBases') as FormArray;
            const riskAssessmentRules = this.form.get('riskAssessmentRules') as FormArray;
            incomeDependentPersonBases.clear();
            riskAssessmentRules.clear();

            // Bereich Mindesteinkommen
            for (const incomeDependentPersonBase of riskParametrization.incomeDependentPersonBases) {
                const formGroup = this.fb.group({
                    dependentPersonCount: [incomeDependentPersonBase.dependentPersonCount, Validators.required],
                    minimumIncome: [incomeDependentPersonBase.minimumIncome, Validators.required],
                    id: [incomeDependentPersonBase.id],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                incomeDependentPersonBases.push(formGroup);
            }

            // Bereich erweiterte Kriterien
            for (const riskAssessmentRule of riskParametrization.riskAssessmentRules.filter(rule => rule.loanDuration === undefined)) {
                const formGroup = this.fb.group({
                    debitorCount: [riskAssessmentRule.debitorCount],
                    maxTotalMonthlyIncomeJh: [riskAssessmentRule.maxTotalMonthlyIncomeJh],
                    dsti: [riskAssessmentRule.dsti, [Validators.required, Validators.min(0), Validators.max(1)]],
                    validationMessage: [riskAssessmentRule.validationMessage, Validators.required],
                    id: [riskAssessmentRule.id],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                riskAssessmentRules.push(formGroup);
            }

            // Einzelne Regel für die Kriterien für lange Laufzeiten
            const rule = riskParametrization.riskAssessmentRules.find(it => it.loanDuration !== undefined);

            if (!!rule) {
                this.form.get('minDsti')?.patchValue(rule.dsti);
                this.form.get('minLtv')?.patchValue(rule.ltv);
                this.form.get('ruleId')?.patchValue(rule.id);
                this.form.get('ruleValidationMessage')?.patchValue(rule.validationMessage);
            }
        });
    }

    /**
     * Speichert die Konfiguration
     */
    public save(): void {
        const { minDsti, minLtv, ruleId, ruleValidationMessage, ...toSave } = this.form.getRawValue();

        (toSave as IRiskParametrizationResponse).riskAssessmentRules.push({
            id: ruleId,
            loanDuration: toSave.extendedDurationFrom - 1,
            dsti: minDsti,
            ltv: minLtv,
            validationMessage: ruleValidationMessage,
        });

        this.waiterService.show().pipe(
            mergeMap(() => this.riskParametrizationService.update(toSave)),
            tap(() => this.waiterService.hide()),
        ).subscribe(
            {
                next: () => {
                    this.locked = true;
                    this.disableForm();
                    this.form.markAsPristine();
                },
                error: () => {
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('administration.features.riskParametrization.error'),
                    );
                },
            },
        );
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.form.enable();
        (this.form.get('incomeDependentPersonBases') as FormArray)?.controls.forEach(control => control.enable());
        (this.form.get('riskAssessmentRules') as FormArray)?.controls.forEach(control => control.enable());
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.form.disable();
        (this.form.get('incomeDependentPersonBases') as FormArray)?.controls.forEach(control => control.disable());
        (this.form.get('riskAssessmentRules') as FormArray)?.controls.forEach(control => control.disable());
    }

    /**
     * Initialisiert Masken für Inputs
     */
    private initCurrencyMasks(): void {
        this.currencyOptions = HelperService.getStandardCurrencyMask(this.locale);
        this.factorOptions = HelperService.getInputMask(this.locale, {
            suffix: '',
            precision: HelperService.getMaxDecimalPlacesByFormat('1.0-2'),
        });
    }
}
