<div mat-dialog-title class="container cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <h2 class="headline">{{'financing.features.financing-processing.transfer-contract.headerTransfer' | translate}}</h2>
  <div class="useractions">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="mat-typography container">
  <div class="user-select fix">
    <mat-form-field>
      <input matInput placeholder="Nutzerliste durchsuchen" [ngModel]="searchTerm.value"
        (ngModelChange)="updateSearch($event)">
    </mat-form-field>
    <div class="header">
      <finprocess-sub-header
        [label]="'financing.features.financing-processing.transfer-contract.selectUser' | translate">
      </finprocess-sub-header>
    </div>
    <div *ngIf="isLoading" fxFill fxLayout="row" fxLayoutAlign="center center" class="waiter">
      <waiter-component [isLoading]="isLoading"></waiter-component>
    </div>
    <table mat-table [dataSource]="displayedUsers" *ngIf="!isLoading">
      <ng-container matColumnDef="selector">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <mat-radio-group [(ngModel)]="selectedUser">
            <mat-radio-button color="primary" [value]="user" [disabled]="isBalancing"></mat-radio-button>
          </mat-radio-group>
        </td>
      </ng-container>
      <!-- Vorname-Spalte -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>{{ 'financing.features.financing-processing.firstName' | translate }}
        </th>
        <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
      </ng-container>
      <!-- Nachname-Spalte -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{ 'financing.features.financing-processing.lastName' | translate }}
        </th>
        <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>

    </table>
    <mat-paginator [length]="paginatorLength" [pageSize]="5" showFirstLastButtons (page)="changePage($event)"
      *ngIf="!isLoading"></mat-paginator>
    <mat-progress-bar mode="query" *ngIf="isBalancing"></mat-progress-bar>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-raised-button color="primary" [disabled]="!!!selectedUser || isBalancing" (click)="balanceContract()">
    <mat-icon>block</mat-icon>
    {{'dashboard.features.dashboard.balance' | translate}}
  </button>
  <button mat-raised-button mat-dialog-close [disabled]="isBalancing">
    <mat-icon>close</mat-icon>
    {{'button.discard' | translate}}
  </button>
</mat-dialog-actions>