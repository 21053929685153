import { Type } from '@angular/core';

import { AuthDataModule } from './../data';
import { AuthChecksModule } from './../features/checks';
import { AuthInterceptorsModule } from './../features/interceptors';

export { AUTH_PERSISTENT_STATE_KEYS, AuthenticationService, AuthorizationService, IAuthStateParentDefinition, IUser, LoginStateType, Logout, Role, UserService } from './../data';

export {
    AuthChecksModule,
    AuthenticationDirective,
    AuthenticationGuard,
    AuthorizationDirective,
    AuthorizationGuard,
    IRoleData,
    IExtendedRoutingData,
    OrganisationalUnitResponsibilityDirective,
    OrganisationalUnitFilterGuard,
} from './../features/checks';

export { AuthDataModule } from './../data';
export { AuthLoginModule } from './../features/login/auth-login.module';
export { AuthInterceptorsModule } from './../features/interceptors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AUTH_MODULES: Array<Type<any>> = [
    AuthDataModule,
    AuthInterceptorsModule,
    AuthChecksModule,
];
