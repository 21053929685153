/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProviderToken, IFormProvider } from '../interfaces';

import { FinprocessFormGroup } from './finprocess-form-group';

/**
 * Finprocess Form Injector
 */
export class FormInjector {

    public providers: IFormProvider<any>[] = [];

    /**
     * Setzt einen Provider
     * 
     * @param {FinprocessFormGroup<any>} value FormGroup
     * @param {boolean} multi Handelt es sich um einen Mehrfachprovider?
     * @param {FormProviderToken} token Token
     */
    public setProvider(value: FinprocessFormGroup<any>, multi: boolean, token: FormProviderToken<any>) {
        const existing = this.providers.findIndex(prov => prov.description === token && (prov.multi ?? false) === multi);

        if (multi && existing > -1) {
            const multiProvider = this.providers[existing].group as Array<FinprocessFormGroup<any>>;
            const existingMultiProvider = multiProvider.findIndex(prov => prov === value);

            if (existingMultiProvider > -1) {
                multiProvider[existingMultiProvider] = value;
            } else {
                multiProvider.push(value);
            }

        } else if (multi && existing === -1) {
            this.providers.push({ description: token, multi, group: [value]});
        } else if (existing > -1) {
            this.providers[existing] = { description: token, multi, group: value};
        } else {
            this.providers.push({ description: token, multi, group: value});
        }
    }

    /**
     * Gibt einen Provider zurück
     * 
     * @param {IFormProvider} provider Provider
     * @returns {object | null} Wert des Providers
     */
    public getProvider<T>(provider: IFormProvider<T>): T | null {
        const existing = this.providers.find(prov => prov.description === provider.description && (prov.multi ?? false) === (provider.multi ?? false)) as IFormProvider<T>;

        if (!existing?.group) {
            return null;
        }
        if (Array.isArray(existing.group)) {
            return existing.group.map(grp => grp.getRawValue()) as T;
        }

        return existing.group.getRawValue() as T;
    }

    /**
     * Entfernt einen Provider
     * 
     * @param {FinprocessFormGroup} value FormGroup
     */
    public removeProvider(value: FinprocessFormGroup<any>): void {
        this.providers = this.providers.filter(provider => {
            if (Array.isArray(provider.group)) {
                const existingIndex = provider.group.indexOf(value);

                if (existingIndex > - 1) {
                    provider.group.splice(existingIndex, 1);
                }

                return provider.group.length !== 0;
            } else {
                return provider.group !== value;
            }
        });
    }
}

