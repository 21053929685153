import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { FinancingMode, FinancingService, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { IRFPData } from 'app/modules/financing/data/interfaces/riskfinancingplans-data.interface';
import { UUID } from 'app/modules/shared';
import { Observable, Subject, take, takeUntil } from 'rxjs';

import { RiskfinancingsplansDialogComponent } from '../riskfinancingsplans-dialog/riskfinancingsplans-dialog.component';

/**
 * get and show risk financing plans (rfp)
 */
@Component({
    selector: 'finprocess-riskfinancingplans',
    templateUrl: './riskfinancingplans.component.html',
    styleUrls: ['./riskfinancingplans.component.scss'],
})
export class RiskfinancingplansComponent implements OnInit, OnDestroy {

    @Select((it: IFinancingStateParentDefinition) => it.financing.riskFinancingPlans)
    public rfpList!: Observable<IRFPData[]>;

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    //Subject beim Entfernen der Komponente
    public onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {MatDialog} dialog Dialog
     * @param {Store} store store
     * @param {FinancingService} financingService Financing Service
     * @param {NotificationService} notificationService notification Service
     * @param {WaiterService} waiterService waiter Service
     * @param {TranslateService} translate translate Service
     * @param {Router} router routing
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     */
    public constructor(public dialog: MatDialog, private store: Store, private financingService: FinancingService,
        private notificationService: NotificationService, private waiterService: WaiterService, private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.editingReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Initalisieren
     */
    public ngOnInit(): void {
        this.getAllRFP();
    }

    /**
     * get all Risk financing plans for the financing container id
     */
    public getAllRFP(): void {
        this.waiterService.show();
        // Get the financingContainerID value from the state
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);

        //get a list of every rfp
        if (financingContainerID) {
            this.financingService.getAllRiskFinancingPlans(financingContainerID).pipe((takeUntil(this.onDestroy$))).subscribe({
                next: () => {
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpLoadError'));
                },
            });
        }

    }


    /**
     * opens a dialog, getting name for new rfp and calls the postNewRFP function
     * 
     * @param {IRFPData} financingPlan Risikofinanzierungsplan
     */
    public copyNewRFP(financingPlan: IRFPData): void {
        const dialogRef = this.dialog.open(RiskfinancingsplansDialogComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const rfpName = result;

                if (financingPlan.id) {
                    this.waiterService.show();
                    this.financingService.postNewRiskFinancingPlan(financingPlan.id, rfpName).pipe(take(1)).subscribe({
                        next: () => {
                            this.waiterService.hide();
                            this.notificationService.toast(this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpPostMessage'));
                            this.getAllRFP();
                        },
                        error: () => {
                            this.waiterService.hide();
                            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpPostErrorMessage'));
                        },
                    });
                }
            }
        });
    }

    /**
     * opens an existing rfp
     *
     * @param {UUID} id id
     */
    public openRFP(id: UUID): void {
        if (id) {
            this.router.navigate(['..', 'riskfinancingplan', id], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan } }).catch(error => {
                this.notificationService.alert(this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpOpenError'), error);
            });
        }
        else {
            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpOpenError'));
        }
    }

    /**
     * open product package 
     *
     * @param {UUID} id id 
     */
    public openProductPackage(id: UUID): void {
        if (id) {
            this.router.navigate(['..', 'riskfinancingplan', id, 'product-package'], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan } }).catch(error => {
                this.notificationService.alert(this.translate.instant('financing.features.financing-processing.productPackages.loadProductError'), error);
            });
        }
        else {
            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.loadProductError'));
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
