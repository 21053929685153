<mat-dialog-content>
    <div fxLayout="column" fxFill>
        <div fxLayout="row" fxLayoutAlign="space-between end" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px">
                <h1 class="color-primary">{{ 'navigation.administration.branches' | translate }}</h1>
                <button mat-fab extended style="height: 35px;" (click)="editBranch()" color="primary" *finprocessAuthorization="Role.BranchAdministrator">
                    <mat-icon>add</mat-icon>
                    {{ 'navigation.administration.newBranch' | translate }}
                </button>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-select [panelWidth]="320" placeholder="{{ 'administration.features.branches.organisationalUnit' | translate }}"  (selectionChange)="updateOrgUnit($event)">
                        <mat-option *ngFor="let orgUnitType of organisationalResponsibilityType" [value]="orgUnitType.value">
                            {{ orgUnitType.displayName }}
                        </mat-option>
                        <mat-option>{{ 'general.noSelection' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="{{ 'administration.features.branches.search' | translate }}" [ngModel]="searchTerm.value" (ngModelChange)="updateSearch($event)">
                </mat-form-field>
            </div>
        </div>
        <table mat-table [dataSource]="displayedBranches" fxFlex>
            <ng-container matColumnDef="costCentre">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.costCentre' | translate }}</th>
                <td mat-cell *matCellDef="let branch">{{ branch?.costCentre }}</td>
            </ng-container>
            <ng-container matColumnDef="branchCode">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.branchCode' | translate }}</th>
                <td mat-cell *matCellDef="let branch">{{ branch?.branchCode }}</td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.name' | translate }}</th>
                <td mat-cell *matCellDef="let branch">{{ branch?.name }}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.status' | translate }}</th>
                <td mat-cell *matCellDef="let branch">{{ branch?.disabled | finProcessBranchStatus }}</td>
            </ng-container>
            <ng-container matColumnDef="organisationalUnit">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.organisationalUnit' | translate }}</th>
                <td mat-cell *matCellDef="let branch">
                    <span *ngIf="!!branch">{{ branch?.responsibility | finprocessEnumTranslation: "OrganisationalUnitResponsibilityType" }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.branches.actions' | translate }}</th>
                <td mat-cell *matCellDef="let branch">
                    <ng-container *finprocessAuthorization="Role.BranchAdministrator">
                        <button mat-icon-button *ngIf="!!branch" (click)="editBranch(branch)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <mat-paginator [length]="(branches$ | async)?.length ?? 0" [pageSize]="pageSize" showFirstLastButtons (page)="changePage($event)"></mat-paginator>
    </div>
</mat-dialog-content>
