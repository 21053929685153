import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { Select, Store } from '@ngxs/store';
import { FinancingStatus, FinancingSubStatus } from 'app/modules/shared';
import { Observable, Subject, iif, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { FinancingService, FinancingState, IDebitor, IFinancingStateParentDefinition } from '../../../../data';

/**
 * Komponente für den Ablehenungsbescheid-Dialog
 */
@Component({
    selector: 'finprocess-rejection-letter-dialog',
    templateUrl: './rejection-letter-dialog.component.html',
    styleUrls: ['./rejection-letter-dialog.component.scss'],
})
export class RejectionLetterDialogComponent implements OnInit {

    @Select(FinancingState.comfortCreditTotalAmount)
    public comfortCreditTotalAmount$!: Observable<(withOverwrites?: boolean) => number>;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;

    /**
     * Ablehnungsbegründung
     */
    public rejectionForm: FormGroup;

    /**
     * Loadingstate
     */
    public isLoading = false;

    /**
     * Erstellen der Anrede(n)
     */
    private salutation = '';

    /**
     * Der Ablehnungsbescheid
     */
    private rejectionLetter = '';

    private submissionDate?: string;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {FormBuilder} fb FormBuilder
     * @param {Store} store Store Injektor
     * @param {FinancingService} financingService FinancingService Injektor
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     * @param {DatePipe} datePipe DatePipe Injektor
     * @param {{debitors: IDebitor[]}} data injected data
     * @param {MAT_DIALOG_DATA} data.debitors MAT_DIALOG_DATA
     */
    public constructor(
        private fb: FormBuilder,
        private store: Store,
        private financingService: FinancingService,
        private dialogRef: MatDialogRef<RejectionLetterDialogComponent>,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: { debitors: IDebitor[] },
    ) {
        this.rejectionForm = this.fb.group({
            rejection: ['', Validators.required],
        });
        
    }

    /**
     * Initialisiert das Einreichdatum
     */
    public ngOnInit(): void {
        this.submissionDate = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.submissionDate);
        this.buildSalutation(this.data.debitors);


        this.buildRejectionLetter(this.salutation).subscribe({
            next: res => {
                this.rejectionLetter = res;
                this.rejectionForm.get('rejection')?.patchValue(this.rejectionLetter);
            },
        });
    }

    /**
     * Setzt den Inhalt des Editors zurück
     */
    public deleteContent(): void {
        this.rejectionForm.get('rejection')?.patchValue(this.rejectionLetter);
    }

    /**
     * Sendet das Ablehnungsschreiben und setzt den Status der Finanzierung auf Abgelehnt
     */
    public sendRejection(): void {
        this.isLoading = true;

        this.store.selectOnce((it: IFinancingStateParentDefinition) => it)
            .pipe(mergeMap(
                it => iif(
                    () => it.financing.financing?.id !== undefined && this.rejectionForm.get('rejection')?.value !== null,
                    this.financingService.createRejectionLetter(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        it.financing.financing!.id,
                        this.rejectionForm.get('rejection')?.value).pipe(
                        mergeMap(() => this.financingService.setStatus({
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            id: it.financing.financing!.id,
                            reason: this.rejectionForm.get('rejection')?.value,
                            status: FinancingStatus.Rejected,
                            subStatus: FinancingSubStatus.Rejected,
                        })),
                    ),
                    of(undefined),
                ),
            )).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.dialogRef.close();
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }

    /**
     * Baut die persönliche Anrede für den Ablehnugsbeleg
     *
     * @param {IDebitor[]} debitors IDebitorItem[]
     */
    private buildSalutation(debitors: IDebitor[]): void {
        for (const debitor of debitors) {
            switch (debitor.gender) {
                case 0 : {
                    this.salutation += `Sehr geehrter Herr ${debitor.lastName},<br>`;
                    break;
                }
                case 1 : {
                    this.salutation += `Sehr geehrte Frau ${debitor.lastName},<br>`;
                    break;
                }
                default: break;
            }
        }
    }

    /**
     * Gibt den REJECTION_LETTER mit personalisierter
     * Anrede zurück
     *
     * @param {string} salutation string
     * @returns {Observable<string>} Observable
     */
    private buildRejectionLetter(salutation: string): Observable<string> {
        return this.comfortCreditTotalAmount$.pipe(
            take(1),
            map(res => {
                const creditAmount = res(true);
                
                const formatter = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                });
        
                const formattedDate = this.submissionDate ? this.datePipe.transform(this.submissionDate, 'dd.MM.yyyy') : '';
        
                return `<h2>Ablehnung Ihres Kreditantrages</h2><br>
                  Information gemäß § 9 (7) Hypothekar- und Immobilienkreditgesetzes (HIKrG)<br>
                  ${salutation}<br><br>
                  wir beziehen uns auf den am ${formattedDate} eingebrachten Antrag um Gewährung eines Kredites in Höhe
                  von ${formatter.format(creditAmount)} und bedauern Ihnen mitteilen zu müssen, dass wir dem Ansuchen nicht positiv
                  entsprechen können.<br><br><br>
                  Freundliche Grüße<br>
                  <b>UniCredit Bank Austria AG</b>`;
            }),
        );
    }
}
