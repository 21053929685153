export enum TypeOfAdditionalIncome {
    /**
     * Karenzgeld
     */
    ParentalPaidLeave,

    /**
     * Nachhaltige Überstunden 100%
     */
    SustainableOvertime,

    /**
     * Nachhaltige Provisionen 50%
     */
    SustainableComission,

    /**
     * Alimente
     */
    Alimony,

    /**
     * Nachhaltige Zulagen
     */
    SustainablePerks,

    /**
     * Sonst. nachweisbare nachhaltige Einkünfte
     */
    OtherSustainableVerifableIncome,
}
