import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { DocumentType, MandantType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { FinancingEventType, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { IMasterdataParentDefinition, OrganisationalUnitResponsibilityType } from 'app/modules/masterdata/data';
import { FinancingStatus, FinancingSubStatus, HelperService, ISelectItem, LogService, SubStatusCode } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { NgxLoggerLevel } from 'ngx-logger';
import { Observable, OperatorFunction, Subject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilKeyChanged, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { RejectionLetterDialogComponent } from '..';
import {
    ApplicationDecisionType,
    DocumentService,
    EntityClassType,
    FinancingMode,
    FinancingService,
    FinancingState,
    IDebitor,
    IDocument,
    IFinancing,
    IFinancingStateParentDefinition,
    IFinprocessContainer,
    IStatusEntry,
    IUploadContext,
    IUploadDocument,
    OverwriteValueClassType,
    ValueStorageType,
} from '../../../../data';

interface IReadonlyStatus {
    visible: boolean;
    readonly: boolean;
}

/**
 * Klasse für Fehler im Prozesstab
 */
class ProcessError extends Error {}

/**
 * Komponente für den Tab Prozess
 */
@Component({
    selector: 'finprocess-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit, OnDestroy {

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer)
    public finprocessContainer$!: Observable<IFinprocessContainer | undefined>;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Verweis auf das angenommene Rechenbeispiel, wenn vorhanden
     */
    public acceptedSampleCalculation?: IUploadDocument;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ApplicationDecisionType = ApplicationDecisionType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public MandantType = MandantType;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Status = FinancingStatus;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SubStatus = FinancingSubStatus;

    /**
     * Einzelauswahl - Kreditentscheidung
     */
    public applicationDecisionSelectItems: ISelectItem<string>[] = [];

    public applicationDecisionControlReadonly$ = new Subject<boolean>();

    /**
     * Anzeige und Bearbeitungszustand der Rechenbeispiele
     */
    public applicationDecisionStatus: IReadonlyStatus = { visible: false, readonly: true };

    /**
     * Anzeige und Bearbeitungszustand der Rechenbeispiele
     */
    public sampleCalculationStatus: IReadonlyStatus = { visible: false, readonly: true };

    public entityClassType = EntityClassType;

    /**
     * Check ob Rechenbeispiele abgesendet werden können
     *
     * @returns {boolean} Ob Rechenbeispiele abgesendet werden können
     */
    public get valid(): boolean {
        return this.sampleCalculationContext?.lists.some(list => list.list.length > 0);
    }

    /**
     * Form für Zusatzinformationen und nachzureichende Unterlagen
     */
    public form: FormGroup;

    /**
     * Form für Wechsel Zuständigkeit PF-GK
     */
    public formResponsibility: FormGroup;

    /**
     * Formular für den Statuswechsel zum Referenten
     */
    public refereeStatementForm: FormGroup<{statement: FormControl<string | null>}>;

    // Upload Listen

    public sampleCalculationContext!: IUploadContext;
    public householdContexts!: IUploadContext[];
    public debitorContexts!: IUploadContext[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;

    /**
     * Ladezustand der Komponente
     */
    public loading = true;

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Zum Überprüfen ob die Dateien bereits geladen wurden
     */
    private filesAlreadyLoaded = false;

    private calculationService = new DebitorCalculationService();

    /**
     * Finanzierung ID
     */
    public financingID?: string;

    /**
     * Dokumente gesendet
     */
    public documentsSend = false;

    /**
     * Standard Konstruktor
     *
     * @param {MatDialog} dialog MatDialog-Injektor
     * @param {Store} store Store-Injektor
     * @param {EnumTranslationPipe} enumTrans EnumTranslationPipe-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {LogService} logService LogService-Injektor
     * @param {AuthorizationService} authService AuthorizationService-Injektor
     * @param {NotificationService} notificationService NotificationService-Injektor
     * @param {Router} router Router-Injektor
     * @param {ChangeDetectorRef} changeDetection ChangeDetectorRef-Injektor
     */
    public constructor(
        private dialog: MatDialog,
        private store: Store,
        private enumTrans: EnumTranslationPipe,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private informationEventService: InformationEventService,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private cRef: ChangeDetectorRef,
        private logService: LogService,
        private authService: AuthorizationService,
        private notificationService: NotificationService,
        private router: Router,
        private changeDetection: ChangeDetectorRef,
    ) {
        this.form = this.fb.group({
            additionalInformation: ['', Validators.required],
            employerApproval: false,
            purchaseAgreement: false,
            offerRequiredInsurance: false,
        });

        this.formResponsibility = this.fb.group({
            additionalInformationResponsibility: ['', Validators.required],
        });

        this.refereeStatementForm = new FormGroup({
            statement: new FormControl<string | null>(null, Validators.required),
        });
    }


    /**
     * changes current status to a new status and substatus via buttons
     *
     * @param {FinancingStatus} newFinancingStatus new status
     * @param {FinancingSubStatus} newFinancingSubStatus new substatus
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public changeStatus(newFinancingStatus: FinancingStatus, newFinancingSubStatus: FinancingSubStatus) {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.process.changingProcessStatus'),
        });
        this.executeWithFinancing([
            this.saveStatus(newFinancingStatus, newFinancingSubStatus),
        ]).subscribe({
            next: () => this.waiterService.hide(),
            error: () => {
                this.waiterService.hide(),
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.process.errorChangingProcessStatus')); //text ändern -> Fehler beim Ändern des Status
            },
        });
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.applicationDecisionSelectItems = HelperService.getSortedSelectItems(
            ApplicationDecisionType,
            value => this.enumTrans.transform(value, 'ApplicationDecisionType') as string,
        );

        this.documents$ = this.financing$.pipe(takeUntil(this.onDestroy$), map(() => documents => documents.filter(document => ([
            DocumentType.SampleCalculationVariable,
            DocumentType.SampleCalculation,
            DocumentType.HouseholdBalanceSignature,
            DocumentType.CompositionOfOtherIncomeSignature,
            DocumentType.SelfDisclosure,
        ] as DocumentType[]).includes(document.type))));

        this.loading = true;

        combineLatest([
            this.financing$.pipe(
                filter(it => it !== undefined) as OperatorFunction<IFinancing | undefined, IFinancing>,
                distinctUntilKeyChanged('id'),
            ),
            this.finprocessContainer$.pipe(
                filter(it => it !== undefined) as OperatorFunction<IFinprocessContainer | undefined, IFinprocessContainer>,
                distinctUntilKeyChanged('id'),
            ),
        ]).pipe(
            tap(() => {this.loading = true;}),
            takeUntil(this.onDestroy$),
        ).subscribe(([financing, finprocessContainer]) => {
            this.form.get('additionalInformation')?.patchValue(financing.additionalSampleCalculationInformationTemplate ?? '');
            this.form.get('employerApproval')?.patchValue(financing.requiredDocuments.includes(DocumentType.EmployerApproval));
            this.form.get('purchaseAgreement')?.patchValue(financing.requiredDocuments.includes(DocumentType.PurchaseAgreement));
            this.form.get('offerRequiredInsurance')?.patchValue(financing.requiredDocuments.includes(DocumentType.OfferRequiredInsurance));

            const responsibilityReason = sort(finprocessContainer.statusEntries).desc(entry => entry.created)
                .find(st => st.status === FinancingStatus.Rejected && (st.subStatus === FinancingSubStatus.RejectedByResponsibilityGK || st.subStatus === FinancingSubStatus.RejectedByResponsibilityPF));

            const referentStatement = sort(finprocessContainer.statusEntries).desc(entry => entry.created)
                .find(st => st.status === FinancingStatus.Finalize && (st.subStatus === FinancingSubStatus.ReadyForReferee));

            if (!!responsibilityReason) {
                this.formResponsibility?.get('additionalInformationResponsibility')?.patchValue(responsibilityReason.statusInformation ?? '');
            }

            if (!!referentStatement) {
                this.refereeStatementForm.controls.statement.patchValue(referentStatement.statusInformation ?? '');
            }

            if (!this.filesAlreadyLoaded) {
                this.filesAlreadyLoaded = true;
                this.loadExistingFiles(financing);
            }

            this.financingID = financing.id;


            if (finprocessContainer.status >= FinancingStatus.SampleCalculationWaitingForAcception) {
                this.documentsSend = true;
                this.changeDetection.detectChanges();
            }

            this.loading = false;
        });

        // Ein Observable welches Statuswechsel durchführt
        combineLatest([
            this.finprocessContainer$.pipe(filter(container => container !== undefined) as OperatorFunction<IFinprocessContainer | undefined, IFinprocessContainer>),
            this.financing$.pipe(filter(financing => financing !== undefined) as OperatorFunction<IFinancing | undefined, IFinancing>),
            this.financingService.isEditor$,
            this.store.select((it: IFinancingStateParentDefinition) => it.financingTabs.editMode),
            this.store.select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters),
        ]).pipe(
            takeUntil(this.onDestroy$),
            debounceTime(50),
        ).subscribe(([container, financing, isEditor, editMode, salesPartnerCenters]) => {
            this.setVisibilityAndReadonly(container, financing, editMode, isEditor);

            const responsibilitySelfEmployed = salesPartnerCenters.find(center => center.id === container?.salesPartnerCenterId)?.responsibility === OrganisationalUnitResponsibilityType.SelfEmployed ?? false;
            const isStationary = container.mandantType === MandantType.FinAdvisory;

            this.applicationDecisionSelectItems = this.filterSelectItems(this.applicationDecisionSelectItems, responsibilitySelfEmployed, isStationary);

            this.updateControlStatus();
            this.updateUploadContexts(financing);
        });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.applicationDecisionControlReadonly$.next(true);
        this.applicationDecisionControlReadonly$.complete();
    }

    /**
     * Öffnet den Ablehungsbescheid-Dialog
     */
    public openRejectionLetter(): void {
        const debitors = this.store.selectSnapshot(FinancingState.debitors);
        this.dialog.open(RejectionLetterDialogComponent, {
            data: {
                debitors: sort(debitors).desc(deb => deb.gender),
            },
            height: '90%',
            width: '60%',
        });
    }

    /**
     * Wechsel Zuständigkeit PF-GK
     *
     * @param {ApplicationDecisionType} decision Entscheidung
     */
    public changeResponsibility(decision: ApplicationDecisionType): void {
        this.waiterService.show();

        const subStatus = decision === ApplicationDecisionType.RejectedByResponsibilityGK ? FinancingSubStatus.RejectedByResponsibilityGK : FinancingSubStatus.RejectedByResponsibilityPF;

        this.executeWithFinancing([this.saveStatus(FinancingStatus.Rejected, subStatus, this.formResponsibility?.get('additionalInformationResponsibility')?.value)])
            .subscribe({
                next: () => this.waiterService.hide(),
                error: () => {
                    this.waiterService.hide(),
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            });
    }

    /**
     * Sendet alle Dokumente ans Backend, setzt die Zusatzinformationen und den Status
     */
    public sendAllDocuments(): void {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.process.sendingCalculationExamples'),
        });
        /* speichert alle Dokumente */
        this.executeWithFinancing(this.saveFinancingMapFiles(this.sampleCalculationContext))
            .pipe(
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorSampleCalculations'))),
                mergeMap(() => this.executeWithFinancing(this.saveHouseholdFiles(this.householdContexts))),
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorHouseholdFiles'))),
                mergeMap(() => this.executeWithFinancing(this.saveDebitorFiles(this.debitorContexts))),
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorDebitorFiles'))),
                mergeMap(() => this.executeWithFinancing(this.saveInternalValues([
                    {fieldName: 'additionalSampleCalculationInformationTemplate', value: this.form.get('additionalInformation')?.value},
                    {fieldName: 'requiredDocuments', value: this.getRequiredDocuments()},
                ]))),
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorLaterSuppliedDocs'))),
                mergeMap(() => this.executeWithFinancing([this.saveStatus(
                    FinancingStatus.SampleCalculationWaitingForAcception,
                    null,
                    this.form.get('additionalInformation')?.value,
                )])),
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorStatus'))),
                mergeMap(() => this.executeWithFinancing([this.setInformationEventStatus([FinancingEventType.SampleCalculationRequested])])),
                catchError(error => this.handleProcessError(error, this.translate.instant('financing.features.financing-processing.process.errorInformationEvent'))),
                
            ).subscribe({
                next: () => {
                    this.documentsSend = true;
                    this.changeDetection.detectChanges();
                    this.waiterService.hide();
                },
                error: (error: ProcessError) => {
                    this.waiterService.hide();
                    this.logService.saveLog(NgxLoggerLevel.ERROR, new Date, error.message, undefined, undefined, error.stack === undefined ? undefined : [error.name, error.stack]).pipe(take(1)).subscribe();

                    if (error instanceof ProcessError) {
                        this.notification.alert(this.translate.instant('general.error'), error.message);
                    }
                    else {
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                    }
                },
            });

    }

    /**
     * Öffnet Datei zur Anzeige
     *
     * @param {File} file Datei
     */
    public async openFile(file: File): Promise<void> {
        await HelperService.openFileFromBlob(file);
    }

    /**
     * Initialisiert oder aktualisiert die Upload Kontexte.
     * Entfernt variable Rechenbeispiele im Fall von Kreditentscheidung nur Fixzinssätze.
     *
     * @param {IFinancing} financing Finanzierung
     */
    private updateUploadContexts(financing: IFinancing): void {
        if (this.sampleCalculationContext === undefined) {
            this.sampleCalculationContext = {
                lists: [
                    { list: [], type: DocumentType.SampleCalculation, maxFileCount: 3},
                ],
                buttonLabel: this.translate.instant('financing.features.financing-processing.process.uploadCalculationExample'),
                title: this.translate.instant('financing.features.financing-processing.process.calculationExampleDialogTitle'),
                parentId: financing.id,
            }
            if (financing.applicationDecision === ApplicationDecisionType.Possible) {
                this.sampleCalculationContext.lists.push({ list: [], type: DocumentType.SampleCalculationVariable, maxFileCount: 3});
            }
        } else {
            if (financing.applicationDecision === ApplicationDecisionType.PossibleFix) {
                const variableListIndex = this.sampleCalculationContext.lists.findIndex(list => list.type === DocumentType.SampleCalculationVariable);

                if (variableListIndex >= 0) {
                    this.sampleCalculationContext.lists.splice(variableListIndex, 1);
                }

                this.sampleCalculationContext.buttonHint = this.translate.instant('financing.features.financing-processing.process.onlyFixzinssaetze');
            } else if (financing.applicationDecision === ApplicationDecisionType.Possible && this.sampleCalculationContext.lists.every(list => list.type !== DocumentType.SampleCalculationVariable)) {
                this.sampleCalculationContext.lists.push({ list: [], type: DocumentType.SampleCalculationVariable, maxFileCount: 3});

                this.sampleCalculationContext.buttonHint = undefined;
            }
        }

        if (this.householdContexts === undefined) {
            this.householdContexts = sort(financing.households).asc(household => household.position).map((household, index) => ({
                title: this.translate.instant('financing.features.financing-processing.process.householdNumber', { i: index + 1}),
                buttonLabel: this.translate.instant('financing.features.financing-processing.process.householdUpload', { i: index + 1}),
                parentId: household.id,
                lists: [
                    { list: [], type: DocumentType.HouseholdBalanceSignature, maxFileCount: 5 },
                    { list: [], type: DocumentType.CompositionOfOtherIncomeSignature, maxFileCount: 5 },
                ],
            }));
        }

        if (this.debitorContexts === undefined) {
            this.debitorContexts = sort(financing.households.reduce((debitors, currentHousehold) => debitors.concat(currentHousehold.debitors), [] as IDebitor[]))
                .desc(debitor => this.calculationService.netIncomeTotal({netIncome: debitor.netIncome, fourteenSalariesPerYear: debitor.fourteenSalariesPerYear, otherIncome: debitor.otherIncome}))
                .map(debitor => ({
                    title: `${debitor.firstName} ${debitor.lastName}`,
                    buttonLabel: this.translate.instant('financing.features.financing-processing.process.debitorUpload', { deb: `${debitor.firstName} ${debitor.lastName}`}),
                    parentId: debitor.id,
                    lists: [ { list: [], type: DocumentType.SelfDisclosure, maxFileCount: 5} ],
                }));
        }
    }

    /**
     * Lädt die bestehenden Dateien nach
     *
     * @param {IFinancing} financing Finanzierung
     */
    private loadExistingFiles(financing: IFinancing) {
        this.store.selectOnce(FinancingState.allDocuments).pipe(
            map(documents => documents.filter(document => [
                DocumentType.SampleCalculation,
                DocumentType.SampleCalculationVariable,
                DocumentType.HouseholdBalanceSignature,
                DocumentType.CompositionOfOtherIncomeSignature,
                DocumentType.SelfDisclosure,
            ].includes(document.type as number))),
            mergeMap(documents => forkJoin(
                documents
                    .reduce((files, currentDocument) => files.concat(currentDocument.files.map(
                        file => ({ name: file.name, extension: file.extension, id: file.id, type: currentDocument.type, parentId: currentDocument.parentId }),
                    )), [] as Array<{name: string; extension: string; id: string; type: DocumentType; parentId: string;}>)
                    .map(file => this.documentService.loadFile(file.id).pipe(
                        map(fileContent => {
                            const byteCharacters = window.atob(fileContent ?? '');

                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);

                            return { file: new File([byteArray], `${file.name}${file.extension}`, { type: 'application/pdf'}), type: file.type, alreadyExists: true, parentId: file.parentId, fileId: file.id }
                        }),
                    )),
            )),
        ).subscribe((documents: IUploadDocument[]) => {

            for (const document of documents) {
                switch (document.type) {
                    case DocumentType.SampleCalculation:
                        this.sampleCalculationContext.lists.find(list => list.type === DocumentType.SampleCalculation)?.list.push(document);
                        break;
                    case DocumentType.SampleCalculationVariable:
                        this.sampleCalculationContext.lists.find(list => list.type === DocumentType.SampleCalculationVariable)?.list.push(document);
                        break;
                    case DocumentType.HouseholdBalanceSignature:
                        this.householdContexts.find(context => context.parentId === document.parentId)?.lists.find(list => list.type === DocumentType.HouseholdBalanceSignature)?.list.push(document);
                        break;
                    case DocumentType.CompositionOfOtherIncomeSignature:
                        this.householdContexts.find(context => context.parentId === document.parentId)?.lists.find(list => list.type === DocumentType.CompositionOfOtherIncomeSignature)?.list.push(document);
                        break;
                    case DocumentType.SelfDisclosure:
                        this.debitorContexts.find(context => context.parentId === document.parentId)?.lists.find(list => list.type === DocumentType.SelfDisclosure)?.list.push(document);
                        break;
                    default:
                        break;
                }

                if (!!financing.acceptedSampleCalculation && financing.acceptedSampleCalculation.fileId === document.fileId) {
                    this.acceptedSampleCalculation = document;
                }
            }

            this.cRef.markForCheck();
        });
    }

    /**
     * Aktualisiert den Status der Controls der Form
     */
    private updateControlStatus() {
        if (this.applicationDecisionStatus.readonly) {
            this.formResponsibility.disable();
        } else {
            this.formResponsibility.enable();
        }

        if (this.sampleCalculationStatus.readonly) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    /**
     * Speichert interne Werte
     *
     * @param {Array} values Zu speichernde Werte
     * @returns {Function} Funktion zum Ausführen mit einer FinancingID
     */
    private saveInternalValues(values: Array<{fieldName: keyof IFinancing & string, value: IFinancing[keyof IFinancing], storageType?: ValueStorageType}>): Array<(financing: IFinancing) => Observable<unknown>> {
        return values.map(value => (financing: IFinancing) => this.financingService.saveInternalField<IFinancing>({
            fieldName: value.fieldName,
            entityId: financing.id,
            value: value.value,
            valueStorageType: value.storageType,
            entityClassType: EntityClassType.FinProcessContainer,
        }).pipe(take(1)));
    }

    /**
     * Speichert Dokumente
     *
     * @param {IUploadContext} uploadContext Kontext für Rechenbeispiele
     * @returns {Function} Funktion zum Ausführen mit einer FinancingID
     */
    private saveFinancingMapFiles(uploadContext: IUploadContext): Array<(financing: IFinancing) => Observable<unknown>> {
        return uploadContext.lists.reduce((acc, current) => acc.concat(current.list), [] as IUploadDocument[])
            .map(document => (financing: IFinancing) => {
                if (document.alreadyExists) {
                    return of(void 0);
                }

                return this.documentService.uploadFinancingMapFile({
                    file: document.file,
                    type: document.type,
                    mapId: financing.id,
                }).pipe(take(1), tap(() => {document.alreadyExists = true;}));
            });
    }

    /**
     * Speichert Haushaltsrechnungen
     *
     * @param {IUploadContext[]} uploadContexts Kontexte für Haushalte
     * @returns {Function} Funktion zum Ausführen mit einer FinancingID
     */
    private saveHouseholdFiles(uploadContexts: IUploadContext[]): Array<(financing: IFinancing) => Observable<unknown>> {
        return uploadContexts.reduce((documents, currentContext) => documents.concat(currentContext.lists.reduce((acc, current) => acc.concat(current.list), [] as IUploadDocument[])), [] as IUploadDocument[])
            .map(document => (financing: IFinancing) => {
                if (document.alreadyExists) {
                    return of (void 0);
                }

                return this.documentService.uploadHouseholdFile({
                    file: document.file,
                    type: document.type,
                    mapId: financing.id,
                    householdId: document.parentId ?? '',
                }).pipe(take(1), tap(() => {document.alreadyExists = true;}))
            });
    }

    /**
     * Speichert Selbstauskuenfte
     *
     * @param {IUploadContext[]} uploadContexts Kontexte für Debitoren
     * @returns {Function} Funktion zum Ausführen mit einer FinancingID
     */
    private saveDebitorFiles(uploadContexts: IUploadContext[]): Array<(financing: IFinancing) => Observable<unknown>> {
        return uploadContexts.reduce((documents, currentContext) => documents.concat(currentContext.lists.reduce((acc, current) => acc.concat(current.list), [] as IUploadDocument[])), [] as IUploadDocument[])
            .map(document => (financing: IFinancing) => {
                if (document.alreadyExists) {
                    return of(void 0);
                }

                return this.documentService.uploadDebitorFile({
                    file: document.file,
                    type: document.type,
                    mapId: financing.id,
                    debitorId: document.parentId ?? '',
                }).pipe(take(1), tap(() => {document.alreadyExists = true;}))
            });
    }

    /**
     * Setzt den Status der Finanzierung
     *
     * @param {FinancingStatus} status Neuer Status der Finanzierung
     * @param {FinancingSubStatus} subStatus Neuer Substatus der Finanzierung
     * @param {string} reason Begründung für den Status
     * @returns {Observable} Serverantwort mit StatusEntry
     */
    public saveStatus(status: FinancingStatus, subStatus?: FinancingSubStatus | null, reason?: string): (financing: IFinancing) => Observable<IStatusEntry | undefined> {
        return (financing: IFinancing) => this.financingService.setStatus({
            id: financing.id,
            status,
            subStatus,
            reason,
        }).pipe(take(1));
    }

    /**
     * Setzt alle Aktivitäten des gegebenen Typs auf gelesen wenn der Nutzer Bearbeiter des Falls ist
     *
     * @param {FinancingEventType[]} types Aktivitätstypen
     * @returns {Observable} Observable
     */
    private setInformationEventStatus(types: FinancingEventType[]): (financing: IFinancing) => Observable<void | undefined> {
        const isExpert = this.store.selectSnapshot(FinancingState.isExpert);
        
        if (!isExpert) {
            return () => of(undefined);
        }

        const informationEvents = this.store.selectSnapshot(InformationEventState.allInformationEvents).filter(event => types.includes(event.eventType) && !event.isRead);

        return (financing: IFinancing) => this.informationEventService.changeReadStatus({
            finProcessContainerId: financing.id,
            eventIds: informationEvents.reduce<string[]>((events, current) =>
                events.concat(current.events.filter(event => !event.isRead).map(event => event.id)), []),
        }, true);
    }

    /**
     * Führt die übergebenen Funktionen mit einer FinancingID aus
     *
     * @param {Function[]} functions Funktionen mit Paramter FinancingID zum Aufrufen
     * @returns {Observable} Observable
     */
    public executeWithFinancing(functions: Array<(financing: IFinancing) => Observable<unknown>>): Observable<unknown> {
        if (functions.length === 0) {
            return of(undefined);
        }

        const operators = functions.map(fn => mergeMap((financing: IFinancing | undefined) => {
            if (financing === undefined) {
                return throwError(() => 'Keine Finanzierung gefunden');
            }

            return fn(financing).pipe(map(() => financing));
        }));

        // Siehe https://github.com/ReactiveX/rxjs/issues/3989
        // Hohe Kunst des RXJS
        return operators.reduce((ob, op) => ob.pipe(op), this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.financing));
    }

    /**
     * Gibt einen String mit den IDs der nachgforderten Unterlagen zurück
     *
     * @returns {string} DocumentString zum Speichern im Backend
     */
    private getRequiredDocuments(): string {
        const requiredDocuments = [];

        if (this.form.get('employerApproval')?.value) {
            requiredDocuments.push(DocumentType.EmployerApproval);
        }

        if (this.form.get('purchaseAgreement')?.value) {
            requiredDocuments.push(DocumentType.PurchaseAgreement);
        }

        if (this.form.get('offerRequiredInsurance')?.value) {
            requiredDocuments.push(DocumentType.OfferRequiredInsurance);
        }

        return requiredDocuments.join(',');
    }

    /**
     * Behandelt einen Fehler beim Versenden von Dokumenten
     *
     * @param {any} error Allgemeiner Fehler oder ProcessError
     * @param {string} message Fehlermeldung
     * @returns {Observable} Observable, dass mit einem ProcessError abschließt
     */
    // eslint-disable-next-line class-methods-use-this
    private handleProcessError(error: unknown, message: string): Observable<never> {
        if (error instanceof ProcessError) {
            return throwError(() => error);
        } else if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.MethodNotAllowed && error.error.subStatus === SubStatusCode.NotCurrentUserOfMap) {
            return throwError(() => new ProcessError(this.translate.instant('financing.features.financing-processing.process.errorReplacement')));
        }

        return throwError(() => new ProcessError(message));
    }

    /**
     * Filtert die Auswahlmöglichkeiten für den Erstcheck
     *
     * @param {ISelectItem[]} selectItems Auswahlmöglichkeiten
     * @param {boolean} isSelfEmployed Auswahlmöglichkeiten
     * @param {boolean} isStationary Auswahlmöglichkeiten
     * @returns {ISelectItem[]} Gefilterte Auswahlmöglichkeiten
     */
    private filterSelectItems(selectItems: ISelectItem<string>[], isSelfEmployed: boolean, isStationary: boolean): ISelectItem<string>[] {
        if (isStationary) {
            // Im Stationären Betrieb gibt es keinen Wechsel der Zuständigkeiten
            return selectItems.filter(item => ![ApplicationDecisionType.RejectedByResponsibilityGK.toString(), ApplicationDecisionType.RejectedByResponsibilityPF.toString()].includes(item.value));
        }

        if (isSelfEmployed) {
            return selectItems.filter(item => item.value !== ApplicationDecisionType.RejectedByResponsibilityGK.toString());
        } else {
            return selectItems.filter(item => item.value !== ApplicationDecisionType.RejectedByResponsibilityPF.toString());
        }
    }

    /**
     * Aktualisiert die Sichtbarkeiten und den Schreibschutz für die Teile des Prozess Tabs
     * 
     * @param {IFinprocessContainer} finprocessContainer Finprocess Container
     * @param {IFinancing} financing Finanzierung
     * @param {boolean} editMode Bearbeitungssperre
     * @param {boolean} isEditor Ist der Nutzer Bearbeiter der Finanzierung
     */
    // eslint-disable-next-line complexity
    private setVisibilityAndReadonly(finprocessContainer: IFinprocessContainer, financing: IFinancing, editMode: boolean, isEditor: boolean): void {
        this.setDefaultVisibilityAndReadonly(this.applicationDecisionStatus);
        this.setDefaultVisibilityAndReadonly(this.sampleCalculationStatus);

        // Sichtbarkeit der Prozessentscheidung
        this.applicationDecisionStatus.visible = true;
        if (editMode && isEditor && this.authService.hasRole(Role.Expert) && [FinancingStatus.Open, FinancingStatus.HouseholdCalculationAccepted, FinancingStatus.HouseholdCalculationWaitingForAcception].includes(finprocessContainer.status)) {
            this.applicationDecisionStatus.readonly = false;
        }

        // Sichtbarkeit der Rechenbeispiele
        if ((financing.applicationDecision === ApplicationDecisionType.Possible || financing.applicationDecision === ApplicationDecisionType.PossibleFix)) {
            this.sampleCalculationStatus.visible = true;

            const isEditing = finprocessContainer.status === FinancingStatus.Open && (finprocessContainer.subStatus === FinancingSubStatus.Editing || finprocessContainer.subStatus === FinancingSubStatus.SampleCalculationRejected);
            const isHouseholdCalculationStatus = finprocessContainer.status === FinancingStatus.HouseholdCalculationAccepted || finprocessContainer.status === FinancingStatus.HouseholdCalculationWaitingForAcception;

            if (editMode && isEditor && this.authService.hasRole(Role.Expert) && isEditing && !isHouseholdCalculationStatus) {
                this.sampleCalculationStatus.readonly = false;
            }
        }

        this.applicationDecisionControlReadonly$.next(this.applicationDecisionStatus.readonly);
    }

    /**
     * Setzt die Sichtbarkeits- und Readonly Werte zurück
     * 
     * @param {IReadonlyStatus} readonlyStatus Status mit Sichtbarkeit und Readonly
     */
    private setDefaultVisibilityAndReadonly(readonlyStatus: IReadonlyStatus) {
        readonlyStatus.readonly = true;
        readonlyStatus.visible = false;
    }

    /**
     * Route to Customer Center
     */
    public openCustomerCenterPage(): void {
        if (!!this.financingID) {
            this.router.navigate(['/financing', this.financingID, 'customer-center'], { queryParams: { mode: FinancingMode.RiskFinancingPlan, tab: 1 } }).catch(error => {
                this.notificationService.alert(this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpOpenError'), error);
            });
        }
    }
}
