<div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>
<div *ngIf="!loading" fxLayout="column" class="ml-3" fxLayoutGap="24px">
    <ng-container *ngIf="(productPackage$ | async) as productPackage">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" class="pr-3" fxLayoutGap="48px">
            <!-- Hinweistexte -->
            <div fxLayout="column" fxFlex fxFlex.gt-sm="40">
                <ng-container
                    *ngIf="productPackage.status >= ProductPackageStatus.DecisionSuccesful && !approvalCompetenceLoading">
                    <h2 class="color-font">
                        <span
                            *ngIf="productPackage.inApprovalPolicies === undefined && approvalCompetence$.value !== ApprovalCompetence.BackOffice">{{'financing.features.financing-processing.approval.determineCompetence'
                            | translate}}</span>
                        <span
                            *ngIf="productPackage.inApprovalPolicies === false || approvalCompetence$.value === ApprovalCompetence.BackOffice">{{'financing.features.financing-processing.approval.marketCompetence'
                            | translate}}</span>
                        <span
                            *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value !== ApprovalCompetence.BackOffice">
                            <span>{{'financing.features.financing-processing.approval.salesCompetence' |
                                translate}}:&nbsp;</span>
                            <ng-container *ngIf="!approvalCompetenceLoading">
                                <span *ngIf="approvalCompetence$.value === ApprovalCompetence.SelfCompetence">
                                    {{'financing.features.financing-processing.approval.ownCompetence' |
                                    translate}}</span>
                                <span
                                    *ngIf="approvalCompetence$.value === ApprovalCompetence.ExternalCompetence">{{'financing.features.financing-processing.approval.externalCompetence'
                                    | translate}}</span>
                            </ng-container>
                        </span>
                    </h2>
                    <ng-container *ngIf="approvalCompetence$.value !== ApprovalCompetence.BackOffice">
                        <finprocess-hint *ngIf="productPackage.inApprovalPolicies === undefined">
                            <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                                translate}}</span>
                            <p content>{{'financing.features.financing-processing.approval.infoDetermineCompetence' |
                                translate}}</p>
                        </finprocess-hint>
                        <finprocess-hint type="warn" *ngIf="productPackage.inApprovalPolicies === false">
                            <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                                translate}}</span>
                            <p content>
                                <span *ngIf="productPackageSumHint">{{ productPackageSumHint}} </span>
                                {{'financing.features.financing-processing.approval.infoMarketCompetence' | translate}}
                            </p>
                        </finprocess-hint>
                        <finprocess-hint type="info"
                            *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value === ApprovalCompetence.SelfCompetence">
                            <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                                translate}}</span>
                            <p content>{{ productPackageMinCompetence?.levelAddition }}</p>
                        </finprocess-hint>
                        <finprocess-hint type="warn"
                            *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value === ApprovalCompetence.ExternalCompetence">
                            <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                                translate}}</span>
                            <p content>{{ productPackageMinCompetence?.levelAddition }}</p>
                        </finprocess-hint>
                    </ng-container>
                    <finprocess-hint type="warn" *ngIf="approvalCompetence$.value === ApprovalCompetence.BackOffice">
                        <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                            translate}}</span>
                        <p content>
                            <span *ngIf="productPackageSumHint">{{ productPackageSumHint}} </span>
                            {{'financing.features.financing-processing.approval.infoMarketCompetence' | translate}}
                        </p>
                    </finprocess-hint>
                </ng-container>
                <waiter-component *ngIf="approvalCompetenceLoading"
                    [isLoading]="approvalCompetenceLoading"></waiter-component>
                <ng-container *ngIf="productPackage.status === ProductPackageStatus.DecisionFailed">
                    <finprocess-hint type="error" *ngIf="(fireData$ | async) as fireData">
                        <span header>{{'financing.features.financing-processing.approval.fireDecisionFailed' |
                            translate}}</span>
                        <p content
                            innerHTML="{{'financing.features.financing-processing.approval.fireDecisionFailedLong' | translate:fireData}}">
                        </p>
                    </finprocess-hint>
                </ng-container>
            </div>

            <!-- Fire Anzeigewerte -->
            <div fxFlex fxFlex.gt-sm="40" fxLayout="column" fxLayoutGap="12px">
                <h3 class="color-font">{{'financing.features.financing-processing.approval.fireDecision' | translate}}
                </h3>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="12px" *ngIf="(fireData$ | async) as fireData">
                    <ng-container *ngIf="!fireDataLoading">
                        <finprocess-label-value>
                            <span label>{{'financing.features.financing-processing.approval.additionalCode' |
                                translate}}</span>
                            <span value>{{ fireData.ergAdditionalCode | finprocessEnumTranslation:'AdditionalCode'
                                }}</span>
                        </finprocess-label-value>
                        <finprocess-label-value>
                            <span label>{{'financing.features.financing-processing.approval.reasonCode' |
                                translate}}</span>
                            <span value>{{ fireData.reasonCode | finprocessEnumTranslation:'ReasonCode' }}</span>
                        </finprocess-label-value>
                    </ng-container>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="12px" *ngIf="fireDataLoading">
                    <div class="waiter" *ngIf="fireDataLoading" fxLayout="row" fxLayoutAlign="center center">
                        <waiter-component style="height: 44px;" [isLoading]="fireDataLoading"></waiter-component>
                    </div>
                </div>

                <!-- FIRE Dokument -->
                <finprocess-label-value>
                    <span label>{{'financing.features.financing-processing.approval.fireDocument' | translate}}</span>
                    <div value fxLayout="row" fxLayoutAlign="start center">
                        <div class="waiter w-100" *ngIf="fireDocumentLoading" fxLayout="row"
                            fxLayoutAlign="center center">
                            <waiter-component [isLoading]="fireDocumentLoading"></waiter-component>
                        </div>
                        <ng-container *ngIf="!fireDocumentLoading">
                            <ng-container *ngIf="(fireDocumentData$ | async) as fireDocumentData">
                                <span
                                    *ngIf="!fireDocumentData || fireDocumentData.fileName === undefined">{{'financing.features.financing-processing.approval.nofireDocument'
                                    | translate}}</span>
                                <ng-container *ngIf="!!fireDocumentData && fireDocumentData.fileName !== undefined">
                                    <span fxLayout="row">
                                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                                        <div class="mr-4">{{fireDocumentData?.fileName}}.pdf</div>
                                    </span>
                                    <button mat-icon-button
                                        [disabled]="!this.fireDocumentData?.fileId || (fieldReadonlyExpert$ | async) ?? true"
                                        color="primary" (click)="downloadFireDocument(fireDocumentData)">
                                        <mat-icon class="mr-2"
                                            [matTooltip]="!this.fireDocumentData?.fileId ? 'Kein Dokument vorhanden' : 'Öffnen'">
                                            open_in_new
                                        </mat-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </finprocess-label-value>

                <finprocess-label-value
                    *ngIf="productPackage.status === ProductPackageStatus.ApprovalGrantedOnCondition || productPackage.status === ProductPackageStatus.ApprovalGranted">
                    <span label>{{'financing.features.financing-processing.approval.approvedBy' | translate}}</span>
                    <span value
                        *ngIf="!!productPackage.approver && !!productPackage.approver.firstName && !!productPackage.approver.lastName">{{productPackage.approver.firstName}}
                        {{productPackage.approver.lastName}}</span>
                    <span value
                        *ngIf="!productPackage.approver">{{'financing.features.financing-processing.approval.noApproverName'
                        | translate}}</span>
                </finprocess-label-value>

                <finprocess-boolean-input
                    *ngIf="productPackage.status >= ProductPackageStatus.DecisionSuccesful && (fireSalesCompetence$ | async) === true && approvalCompetence$.value !== ApprovalCompetence.BackOffice"
                    class="approval-input" flexDirection="column" [hideHorizontalRule]="true" [entity]="productPackage"
                    fieldName="inApprovalPolicies" [overwrite]="false"
                    [entityClassType]="EntityClassType.ProductPackage" [readonly]="inApprovalPoliciesReadonly$">
                    <span label
                        class="input-label">{{'financing.features.financing-processing.approval.inApprovalGuidelines' |
                        translate}}</span>
                </finprocess-boolean-input>
            </div>
        </div>

        <!-- Export -->
        <div
            *ngIf="productPackage.inApprovalPolicies === false || (fireSalesCompetence$ | async) === false || approvalCompetence$.value === ApprovalCompetence.BackOffice">
            <h3 class="color-font">Export der Finanzierungsmappe</h3>
            <button mat-raised-button color="primary" [disabled]="(fieldReadonlyExpert$ | async) ?? true || collateralRequirementSuccessful === false"
                (click)="downloadExcelExport()">Exportieren</button>
        </div>

        <mat-divider></mat-divider>
        <finprocess-sub-header label="Zusammenfassung der Sicherheitendetails" class="ml-3"></finprocess-sub-header>
        <finprocess-productpackage-collateral [approvalView]="true"></finprocess-productpackage-collateral>
        <mat-divider></mat-divider>

        <div fxLayout="row" fxLayoutGap="30" class="px-1">
            <!--Mittelverwendung-->
            <div fxLayout="column" fxFlex="50">
                <h3 class="color-font"><b>
                        {{'financing.features.financing-processing.approval.capitalUse' | translate}}
                    </b></h3>
                <table mat-table [dataSource]="useOfFundsDataSource" class="mat-elevation-z8 table-style">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'financing.features.financing-processing.approval.category' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'financing.features.financing-processing.approval.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <!--Mittelherkunft-->
            <div fxLayout="column" fxFlex="50">
                <h3 class="color-font"><b>
                        {{'financing.features.financing-processing.approval.capitalSource' | translate}}
                    </b></h3>
                <table mat-table [dataSource]="sourceOfFundsDataSource" class="mat-elevation-z8 table-style">
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'financing.features.financing-processing.approval.category' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element?.category}} </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'financing.features.financing-processing.approval.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element?.amount | currency:'EUR'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

        <mat-divider></mat-divider>


        <!-- Stellungnahme Experte -->
        <div fxLayout="column" *ngIf="expertStatementStatus.visible">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex fxFlex.gt-sm="80" fxLayoutGap="12px">
                    <h4 class="color-font"><b>{{'financing.features.financing-processing.approval.expertStatement' |
                            translate}}</b></h4>
                    <ckeditor fxFlex class="statement-editor" [editor]="Editor" (change)="true"
                        [(ngModel)]="expertStatement" name="expertStatement"
                        [disabled]="expertStatementStatus.readonly">
                    </ckeditor>

                    <div fxFlexAlign="end" fxLayout="column">
                        <mat-form-field appearance="fill"
                            *ngIf="approvalCompetence$.value === ApprovalCompetence.ExternalCompetence || selectedAction?.value === SelfCompetenceAction.Transfer">
                            <input type="text" matInput [(ngModel)]="approver"
                                placeholder="{{'financing.features.financing-processing.approval.approver'}}"
                                [matAutocomplete]="autocompleteApprover" (ngModelChange)="filterEditors($event)"
                                [disabled]="expertStatementStatus.readonly || collateralRequirementSuccessful === false" name="approver">
                            <mat-autocomplete #autocompleteApprover="matAutocomplete" [displayWith]="getNameFromUser">
                                <mat-option *ngFor="let approver of (approversFiltered$ | async)" [value]="approver">
                                    {{ approver.firstName }} {{ approver.lastName}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <button *ngIf="approvalCompetence$.value === ApprovalCompetence.ExternalCompetence"
                            mat-raised-button color="primary"
                            [disabled]="!approver || !expertStatement || expertStatementStatus.readonly || collateralRequirementSuccessful === false"
                            (click)="setToApprovalAwaiting()">
                            {{'financing.features.financing-processing.approval.transferToApprover' | translate}}
                        </button>
                        <finprocess-select-button
                            *ngIf="approvalCompetence$.value === ApprovalCompetence.SelfCompetence" [actions]="actions"
                            class="select-button self-competence" name="selectedAction" [(ngModel)]="selectedAction"
                            color="primary" [disabled]="expertStatementStatus.readonly" name="selectedAction">
                        </finprocess-select-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Stellungnahme Genehmiger -->
        <div fxLayout="column" class="mb-5" *ngIf="approverStatementStatus.visible">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex fxFlex.gt-sm="80" fxLayoutGap="12px">
                    <h4 class="color-font"><b>{{'financing.features.financing-processing.approval.approverStatement' |
                            translate}}</b></h4>
                    <ckeditor class="statement-approver" fxFlex [editor]="Editor" (change)="true"
                        [(ngModel)]="approverStatement" [disabled]="approverStatementStatus.readonly"
                        name="approverStatement">
                    </ckeditor>
                    <div fxFlexAlign="end" fxLayout="column">
                        <mat-form-field appearance="fill">
                            <mat-label>{{'financing.features.financing-processing.approval.decision' |
                                translate}}</mat-label>
                            <mat-select [(ngModel)]="approvalDecision" [disabled]="approverStatementStatus.readonly || collateralRequirementSuccessful === false"
                                name="approvalDecision">
                                <mat-option *ngFor="let decision of approvalDecisions" [value]="decision">
                                    {{ decision | finprocessEnumTranslation:'ApprovalDecision'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-raised-button color="primary"
                            [disabled]="approvalDecision === undefined || !approverStatement || approverStatementStatus.readonly || collateralRequirementSuccessful === false"
                            (click)="setApprovalDecision()">
                            <ng-container [ngSwitch]="approvalDecision">
                                <span
                                    *ngSwitchCase="ApprovalDecision.Approved">{{'financing.features.financing-processing.approval.actionApprove'
                                    | translate}}</span>
                                <span
                                    *ngSwitchCase="ApprovalDecision.NotApproved">{{'financing.features.financing-processing.approval.actionDeny'
                                    | translate}}</span>
                                <span
                                    *ngSwitchCase="ApprovalDecision.OpenQuestions">{{'financing.features.financing-processing.approval.actionReject'
                                    | translate}}</span>
                                <span *ngSwitchDefault>{{'financing.features.financing-processing.approval.action' |
                                    translate}}</span>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</div>