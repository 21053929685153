import { IDocument } from 'app/modules/financing/data';

import { AAGUID } from '../../types/aaguid';

export interface ISmartDocOpenedPayloadType {
    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Handelt sich um einen SmartDoc-Fall
     */
    isSmartDocMap: boolean;

    /**
     * Dokumente, die bearbeiten werden
     */
    documents?: IDocument[];

    /**
     * Lesemodus
     */
    isReadonly: boolean;
}

/**
 * Klasse für Aktion
 */
export class SmartDocOpened {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'finprocess.smartDocOpened';

    /**
     * Constructor
     *
     * @param {ISmartDocOpenedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocOpenedPayloadType) { }
}
