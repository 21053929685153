/**
 * Reason Codes für FIRE
 */
export enum ReasonCode {
    /**
     * Kreditrisiko Parameter OK
     */
    RC051,

    /**
     * ABLEHNUNG: Verhaltensscoring eines Kreditbeteiligten ist schlechter als 6-
     */
    RC450,

    /**
     * ABLEHNUNG: LTV ist überschritten
     */
    RC2050,

    /**
     * ABLEHNUNG: LTV für Verwendungszweck Vermietung ist überschritten
     */
    RC2054,

    /**
     * ABLEHNUNG: LTV für den Bezirk der zu finanzierenden Liegenschaft / Immobilienrating-relevanten Liegenschaft überschritten
     */
    RC2055,

    /**
     * ABLEHNUNG: LTV für Seniorenkredit ist überschritten
     */
    RC2056,

    /**
     * ABLEHNUNG: Die maximal mögliche DSTI wurde überschritten
     */
    RC2070,

    /**
     * ABLEHNUNG: Reduziertes Einkommen + DSTI überschritten
     */
    RC2071,

    /**
     * ABLEHNUNG: DSTI zu hoch für Konsumobligo
     */
    RC2073,

    /**
     * ABLEHNUNG: Die maximal mögliche DSTI für Verwendungszweck Vermietung ist überschritten
     */
    RC2074,

    /**
     * ABLEHNUNG: Kombination aus hoher DSTI + hohem EL (zur Info: keine LTV-rel. Besicherung)
     */
    RC4050,

    /**
     * ABLEHNUNG: Reduziertes Einkommen + Kombination aus hoher DSTI u. hohem EL (zur Info: keine LTV-rel. Besicherung)
     */
    RC4051,

    /**
     * ABLEHNUNG: Antragscoring schlechter als 6-
     */
    RC4080,

    /**
     * ABLEHNUNG: Immobilienrating schlechter als 6 und Verwendungszweck Vermietung
     */
    RC4082,

    /**
     * ABLEHNUNG: Mindesteinkommen für Verwendungszweck Vermietung ist unterschritten 0
     */
    RC5020,
}
