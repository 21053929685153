import { Location } from '@angular/common'
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { SharedService } from 'app/modules/shared/services/shared.service';
import { Observable, Subject } from 'rxjs';
// eslint-disable-next-line import/no-internal-modules
import { takeUntil } from 'rxjs/operators';

import { ISmartDocStateModel, ISmartDocStateParentDefinition, SmartDocJobStatus } from '../../../../data';
import { LoadedJobStatus, SmartDocClosed, SmartDocJobStatusChanged } from '../../../../data/actions';
import { AAGUID } from '../../../../data/types/aaguid';
/**
 * waiter dialog component
 */
@Component({
    selector: 'finprocess-smartdoc-waiter-dialog',
    templateUrl: './smartdoc-waiter-dialog.component.html',
    styleUrls: ['./smartdoc-waiter-dialog.component.scss'],
})
export class SmartDocWaiterDialogComponent implements OnInit {
    /**
     * aktueller Prozesszustand der SmartDoc-Bearbeitung
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc)
    public state$!: Observable<ISmartDocStateModel>;

    /**
     * IDocument[] des geoeffneten Finanzierungsfalls
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.openedId)
    public openedId$!: Observable<string>;

    /**
     * Übersetzungsschlüssel für anzuzeigenden Text
     */
    @Input()
    public textKey = '';

    /**
     * eindeutiger Schlüssel zum Fall
     */
    @Input()
    public id: AAGUID = '';

    /**
     * Status bei dem der Dialog automatisch geschlossen wird
     */
    @Input()
    public waitForStatus: SmartDocJobStatus[] = [];

    /**
     * indicates if dialog iss closable
     */
    public closable = false;

    /**
     * Subject, das zum Aufräumen der Komponente gecalled wird
     */
    private onDestroy$ = new Subject();

    /**
     * Constructor
     *
     * @param {MatDialog} activeModal MatDialog
     * @param {SharedService} sharedSvc SharedService
     * @param {Actions} actions$ Actions
     * @param {{aaguid: string}} data {aaguid: string}
     * @param {string} data.aaguid {string}
     * @param {MatDialogRef<SmartDocWaiterDialogComponent>} dialogRef  MatDialogRef<SmartDocWaiterDialogComponent>
     * @param {Store} store Store
     * @param { Location} location Location service
     */
    public constructor (
        public activeModal: MatDialog,
        private sharedSvc: SharedService,
        private actions$: Actions,
        @Inject(MAT_DIALOG_DATA) public data: { aaguid: string },
        public dialogRef: MatDialogRef<SmartDocWaiterDialogComponent>,
        private store: Store,
        private location: Location,
    ) {}

    /**
     * Angular hook on initialize component
     */
    public ngOnInit(): void {
        this.sharedSvc.loadJobStatus(this.data.aaguid, /*error*/() => {
            this.activeModal.closeAll();
        });
        this.actions$.pipe(ofActionSuccessful(LoadedJobStatus), takeUntil(this.onDestroy$)).subscribe(({payload}: LoadedJobStatus) => {
            if (payload) {
                this.store.dispatch(new SmartDocJobStatusChanged({id: this.data.aaguid, jobStatus: payload.jobStatus}))
                if (SmartDocJobStatus.Open === payload.jobStatus ||
                    SmartDocJobStatus.RequestingThumbnails === payload.jobStatus ||
                    SmartDocJobStatus.SplittingFinished === payload.jobStatus ) {
                    this.closable = true;
                } else {
                    setTimeout( () => {
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        this.sharedSvc.loadJobStatus(this.data.aaguid, /*error*/() => {});
                    }, 1000 );
                }
            }
        });
    }

    /**
     * Schließt Smartdoc und routet zur letze Seite
     *
     */
    public closeSmartdoc(): void {
        const jobStatus = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.status?.jobStatus);
        if (SmartDocJobStatus.Open === jobStatus ) {
            this.store.dispatch(new SmartDocClosed()).subscribe(() => {
                this.location.back();
            });

        }
    }
}
