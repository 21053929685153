import { InterestMethod } from '@ntag-ef/finprocess-enums';

import { ArgumentError, DivisionByZeroError } from '../errors';
import { IFinancingConfiguration } from '../interfaces';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService, InternalCalculationService } from './core';

/**
 * Berechnungsmethoden zum alten Produktrechner
 */
export class ProductCalculationService extends BaseCalculationService {

    /**
     * Berechnungsservice InternalCalculationService
     */
    private pInternalCalculationService?: InternalCalculationService;

    /**
     * Berechnungsservice InternalCalculationService
     *
     * @returns {InternalCalculationService} InternalCalculationService
     */
    private get internalCalculationService() {
        if (this.pInternalCalculationService === undefined) {
            this.pInternalCalculationService = new InternalCalculationService(this.version);
        }
        return this.pInternalCalculationService;
    }

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet Auszahlungsbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.sumFinancingAdditionalCharges Finanzierungsnebenkosten in €
     * @param {number} params.legalisationFee Legalisierungsgebühr in €
     * @returns {number} Auszahlungsbetrag in €
     */
    public sumPayout({ grossFinancingRequirement, sumFinancingAdditionalCharges, legalisationFee }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Finanzierungsnebenkosten in €
         */
        sumFinancingAdditionalCharges: number;
        /**
         * Legalisierungsgebühr in €
         */
        legalisationFee: number;
    }): number {
        return Math.max(0, grossFinancingRequirement - sumFinancingAdditionalCharges + legalisationFee);
    }

    /**
     * Berechnet Zinsrate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.requestedDebitRate Zinssatz in %
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @returns {number} Zinsrate in €
     */
    public interestRateAmount({ grossFinancingRequirement, requestedDebitRate, bankAccountFee }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Zinssatz in %
         */
        requestedDebitRate?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: grossFinancingRequirement, rate: requestedDebitRate })) {
            return 0;
        }
        return CalculationHelperService.round(grossFinancingRequirement * this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate }) + bankAccountFee, 2);
    }

    /**
     * Berechnet Monatliche Kreditrate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.requestedDebitRate Zinssatz in %
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche Kreditrate in €
     */
    public monthlyDebitRate({ grossFinancingRequirement, requestedDebitRate, assumedDuration, gracePeriod, bankAccountFee }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Zinssatz in %
         */
        requestedDebitRate?: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: grossFinancingRequirement, rate: requestedDebitRate }) || !CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }
        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }
        const divisor2 = 1 - Math.pow(1 / divisor1, (!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0));
        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }
        return CalculationHelperService.round(grossFinancingRequirement * this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate }) / divisor2 + bankAccountFee, 2);
    }

    /**
     * berechnet Voraussichtlicher Rückzahlungsbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.monthlyDebitRate Monatliche Kreditrate in €
     * @param {number} params.interestRateAmount Zinsrate in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @returns {number} Voraussichtlicher Rückzahlungsbetrag in €
     */
    public expectedRepaymentAmount({ monthlyDebitRate, interestRateAmount, assumedDuration, gracePeriod }: {
        /**
         * Monatliche Kreditrate in €
         */
        monthlyDebitRate: number;
        /**
         * Zinsrate in €
         */
        interestRateAmount: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
    }): number {
        if (!CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }
        return monthlyDebitRate * ((!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0)) + interestRateAmount * (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0);
    }

    /**
     * Berechnet Fiktive Rate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Fiktive Rate in €
     */
    public fictionalAmount({ fictionalRate, grossFinancingRequirement, assumedDuration, gracePeriod, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: grossFinancingRequirement, rate: fictionalRate }) || !CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }
        const divisor = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
        if (divisor === 0) {
            throw new DivisionByZeroError();
        }
        const divisor2 = 1 - Math.pow(1 / divisor, (!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0));
        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }
        return CalculationHelperService.round(grossFinancingRequirement * this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }) /
            divisor2 + bankAccountFee, 2);
    }

    /**
     * Gibt den fiktiven Zinssatz anhand der Konfiguration zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @param {InterestMethod | undefined} params.interestMethod Zinsbindung
     * @returns {number} Fiktiver Zinssatz in %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public fictionalRate({ configuration, interestMethod }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
        /**
         * Zinsbindung
         */
        interestMethod?: InterestMethod;
    }): number | undefined {
        this.checkConfiguration(configuration);
        const configEntry = configuration.mainCreditFictionalRates.find(it => it.interestMethod === interestMethod);
        return configEntry?.fictionalRate;
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten
     *
     * @param {IFinancingConfiguration | undefined | null} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfiguration(configuration?: IFinancingConfiguration | null) {
        if (configuration === undefined ||
            configuration === null ||
            !Array.isArray(configuration.mainCreditFictionalRates) ||
            configuration.mainCreditFictionalRates.length === 0) {
            throw new ArgumentError('Der Parameter \'configuration\' ist nicht valide.');
        }
    }
}
