import { Component } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { isMoment } from 'moment';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente zur Eingabe eines Datumsfeldes
 */
@Component({
    selector: 'finprocess-datepicker-input',
    templateUrl: './datepicker-input.component.html',
    styleUrls: ['./datepicker-input.component.scss'],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class DatepickerInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Liefert den ursprünglichen Wert
     * (keinen besseren Weg gefunden um generisch einzuschraenken, dass fieldName ein keyof T ist, wo T[fieldName]: Date)
     *
     * @returns {string | undefined} Wert
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get originalValue(): any {
        if (this.entity === undefined) {
            return undefined;
        }

        return this.entity[this.fieldName] as string | Date;
    }

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.DateTimeOffset, financingService);
    }

    /**
     * Speichert den Wert
     * 
     * @param {unknown} value Zu speichernder Wert
     */
    public save(value: T[keyof T & string] | undefined): void {
        super.save(isMoment(value) ? (value.toISOString() as never): value);
    }
}
