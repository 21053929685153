/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { JointHeadingType } from '@ntag-ef/finprocess-enums';
import { Logout } from 'app/modules/auth/data';

import { IDocument, IFilteredJointHeading, IHouseholdJointHeading, IJointHeading, ILiabilityDataByHousehold, ILiabilityDetail } from '../../interfaces';
import { FinancingLeaved } from '../financing-tab/financing-tab.actions';

import { FictionalRateUpdated, JointHeadingFileDelete, JointHeadingFileUpdate, LiabilityLoaded } from './liability.actions';


export interface ILiabilityDataStateModel {
    jointHeadings: IJointHeading[];
    unsortedLiabilities: ILiabilityDetail[];
}

const defaultData: ILiabilityDataStateModel = {
    jointHeadings: [],
    unsortedLiabilities: [],
};

/**
 * Zustand der LiabilityData
 */
@State<ILiabilityDataStateModel>({
    name: LiabilityState.keyName,
    defaults: defaultData,
})
@Injectable()
export class LiabilityState {

    public static readonly keyName = 'liability';

    /**
     * Selector for unsorted liabilities
     * 
     * @param {ILiabilityDataStateModel} state Current state
     * @returns {ILiabilityDetail[]} The list of unsorted liabilities
     */
    @Selector()
    public static unsortedLiabilities(state: ILiabilityDataStateModel): ILiabilityDetail[] {
        return state.unsortedLiabilities;
    }


    /**
     * Selector for the liabilities component.
     * Orders the liabilities by household and combines joint headings if they have the same NDG.
     * Also filters the liabilities by type, for easier access in the component view.
     * 
     * @param {ILiabilityDataStateModel} state Current state
     * @returns {ILiabilityDataByHousehold} The liabilities ordered by household
     */
    @Selector()
    public static jointHeadingsByHousehold(state: ILiabilityDataStateModel): ILiabilityDataByHousehold {
        return {
            households: state.jointHeadings.reduce<IHouseholdJointHeading[]>((households, jointHeading) => {
                const existingHousehold = households.find(household => household.position === jointHeading.household);

                if (existingHousehold) {
                    const existingJointHeading = existingHousehold.jointHeadings.find(jh => jh.ndg === jointHeading.ndg);

                    if (existingJointHeading) {
                        this.createOrUpdateFilteredJointHeading(jointHeading, existingJointHeading);
                    } else {
                        existingHousehold.jointHeadings.push(this.createOrUpdateFilteredJointHeading(jointHeading));
                    }
                } else {
                    households.push({
                        position: jointHeading.household,
                        jointHeadings: [this.createOrUpdateFilteredJointHeading(jointHeading)],
                    });
                }

                return households;
            }, []),
        }
    }

    /**
     * Takes a joint heading and creates a filtered joint heading, where the different liability types are separated.
     * If an existing filtered joint heading is passed, the new liabilities are added to the existing ones.
     * 
     * @param {IJointHeading} jointHeading The joint heading to create the filtered joint heading from
     * @param {IFilteredJointHeading} existingJointHeading The existing filtered joint heading
     * @returns {IFilteredJointHeading} The filtered joint heading
     */
    private static createOrUpdateFilteredJointHeading(jointHeading: IJointHeading, existingJointHeading?: IFilteredJointHeading): IFilteredJointHeading {
        if (existingJointHeading) {
            if (jointHeading.type === JointHeadingType.AGP) {
                existingJointHeading.agpLiabilities = existingJointHeading.agpLiabilities.concat(jointHeading.liabilities.map(liability => ({...liability, jointHeading })));
            } else if (jointHeading.type === JointHeadingType.KSV) {
                existingJointHeading.ksvLiabilities = existingJointHeading.ksvLiabilities.concat(jointHeading.liabilities.map(liability => ({...liability, jointHeading })));
            } else {
                existingJointHeading.newLiabilities = existingJointHeading.newLiabilities.concat(jointHeading.liabilities.map(liability => ({...liability, jointHeading })));
            }

            return existingJointHeading;
        }
        return {
            ...jointHeading,
            agpLiabilities: jointHeading.type === JointHeadingType.AGP ? jointHeading.liabilities.map(liability => ({...liability, jointHeading })) : [],
            ksvLiabilities: jointHeading.type === JointHeadingType.KSV ? jointHeading.liabilities.map(liability => ({...liability, jointHeading })) : [],
            newLiabilities: jointHeading.type === JointHeadingType.New ? jointHeading.liabilities.map(liability => ({...liability, jointHeading })) : [],
        }
    }

    /**
     * Action for loading liabilities
     * 
     * @param {StateContext} ctx current state context
     * @param {LiabilityLoaded} action Action
     */
    @Action(LiabilityLoaded)
    public liabilityLoaded({ setState }: StateContext<ILiabilityDataStateModel>, { payload }: LiabilityLoaded): void {
        setState({
            jointHeadings: payload.jointHeadings,
            unsortedLiabilities: payload.unsortedLiabilities,
        });
    }
    

    /**
     * Updates an existing document or inserts a new document into a joint heading
     *
     * @param {StateContext} ctx current state context
     * @param {JointHeadingFileUpdate} action Action
     */
    @Action(JointHeadingFileUpdate)
    public jointHeadingFilesUpdate({ setState }: StateContext<ILiabilityDataStateModel>, { payload, jointHeadingID }: JointHeadingFileUpdate): void {
        setState(
            patch({
                jointHeadings: updateItem<IJointHeading>(jointHeading => jointHeading.id === jointHeadingID, patch<IJointHeading>({
                    documents: iif(
                        documents => documents.some(doc => doc.id === payload.id),
                        updateItem<IDocument>(doc => doc.id === payload.id, payload),
                        insertItem(payload),
                    ),
                })),
            }),
        );
    }

    /**
     * Updates the fictional rate of a liability
     * 
     * @param {StateContext} ctx current state context
     * @param {FictionalRateUpdated} action Action
     */
    @Action(FictionalRateUpdated)
    public fictionalRateUpdated({ setState }: StateContext<ILiabilityDataStateModel>, { payload }: FictionalRateUpdated): void {
        setState(patch({
            unsortedLiabilities: updateItem<ILiabilityDetail>(liability => liability.id === payload.liabilityId, patch<ILiabilityDetail>({
                fictionalRate: payload.fictionalRate,
            })),
            jointHeadings: updateItem<IJointHeading>(jointHeading => jointHeading.liabilities.some(liability => liability.id === payload.liabilityId), patch<IJointHeading>({
                liabilities: updateItem<ILiabilityDetail>(liability => liability.id === payload.liabilityId, patch<ILiabilityDetail>({
                    fictionalRate: payload.fictionalRate,
                })),
            })),
        }));
    }

    /**
     * Delete JointHeading File
     * 
     * @param {StateContext} ctx current state context
     * @param {JointHeadingFileDelete} action Aktion
     */
    @Action(JointHeadingFileDelete)
    public jointHeadingFilesDelete({ setState }: StateContext<ILiabilityDataStateModel>, { payload }: JointHeadingFileDelete): void {
        setState(patch({
            jointHeadings: updateItem<IJointHeading>(jointHeading => jointHeading.id === payload.jointHeadingID, patch<IJointHeading>({
                documents: existingDocuments => existingDocuments.reduce<IDocument[]>((documents, existingDocument) => {
                    if (existingDocument.files.some(file => file.id === payload.fileId)) {
                        if (existingDocument.files.length === 1) {
                            return documents;
                        }

                        documents.push({
                            ...existingDocument,
                            files: existingDocument.files.filter(file => file.id !== payload.fileId),
                        });

                        return documents;
                    }

                    documents.push(existingDocument);
                    return documents;
                }, []),
            })),
        }));
    }

    /**
     * Zustandsänderung beim Verlassen der Finanzierung
     *
     * @param {StateContext} ctx current state context
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public financingLeaved({ setState }: StateContext<ILiabilityDataStateModel>): void {
        setState(defaultData);
    }


}
