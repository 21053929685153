import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetState, EntityClassType, IAssetInsurance, IAssetProvider, IAssetRealEstate, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { IAssetAccount } from 'app/modules/financing/data/interfaces/asset-account';
import { AssetService, FinancingService } from 'app/modules/financing/data/services';
import { UUID } from 'app/modules/shared/util';
import { Observable, Subject, combineLatest, map, take, takeUntil } from 'rxjs';

/**
 * Asset-Container
 */
@Component({
    selector: 'finprocess-assets-container',
    templateUrl: './assets-container.component.html',
    styleUrls: ['./assets-container.component.scss'],
})
export class AssetsContainerComponent implements OnInit, OnDestroy, AfterViewChecked {

    /**
     * ID des aktuellen Finanzierungscontainers
     */
    public financingContainerID?: UUID;

    @Select(AssetState.assetRealEstates)
    public assetRealEstates$!: Observable<IAssetRealEstate[]>;

    @Select(AssetState.assetInsurances)
    public assetInsurances$!: Observable<IAssetInsurance[]>;

    @Select(AssetState.assetAccounts)
    public assetAccounts$!: Observable<IAssetAccount[]>;

    public assetsEmpty$: Observable<boolean> = new Observable();

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public entityClassType = EntityClassType;

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * ViewChildren für die Assets
     */
    @ViewChildren('asset') public assets?: QueryList<ElementRef>;

    /**
     * Flag, ob ein neues Asset hinzugefügt wurde
     */
    public newlyAdded = false;

    /**
     * Konstuktor 
     * 
     * @param {AssetService} assetService AssetService
     * @param {Store} store Store
     * @param {WaiterService} waiterService Waiterservice
     * @param {ChangeDetectorRef} changeDetector changedetection
     * @param {FinancingService} financingService financingService
     */
    public constructor(
        private assetService: AssetService,
        private store: Store,
        private waiterService: WaiterService,
        private changeDetector: ChangeDetectorRef,
        private financingService: FinancingService,
    ) { }

    /**
     * Angular Lifecycle nach dem View geändert wurde
     */
    public ngAfterViewChecked() {
        if (this.newlyAdded) {
            this.scrollToLastAsset();
            this.newlyAdded = false;
        }
    }

    /**
     *onInit
     */
    public ngOnInit() {

        this.editingReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.assetsEmpty$ = combineLatest([
            this.assetRealEstates$,
            this.assetInsurances$,
            this.assetAccounts$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([assetRealEstates, assetInsurances, assetAccounts]) => assetRealEstates.length === 0 && assetInsurances.length === 0 && assetAccounts.length === 0),
        );

        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing?.financingContainerID);

        if (!!this.financingContainerID) {
            this.waiterService.show();
            this.assetService.getAssets(this.financingContainerID).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                this.waiterService.hide();
                this.changeDetector.markForCheck();
            });
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Nene Sicherheit anlegen
     *
     * @param {EntityClassType} entityClassType entityClassType
     * @param {boolean} isBonds Wertpapiere
     */
    public addNewAsset(entityClassType: EntityClassType, isBonds?: boolean) {
        if (!!this.financingContainerID) {
            this.assetService.createNewAsset(this.financingContainerID, entityClassType, isBonds).pipe(
                take(1),
            ).subscribe(() => {
                this.changeDetector.markForCheck();
                this.newlyAdded = true;
            });
        }
    }

    /**
     * Scrollt zum letzten Asset, wenn ein neues Asset hinzugefügt wurde
     */
    private scrollToLastAsset() {
        if (!!this.assets) {
            const lastAsset = this.assets.last;
            if (lastAsset) {
                lastAsset.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    /**
     * Methode um das panel state zu traken
     * https://github.com/angular/components/issues/8476
     * 
     * @param {number} index  index
     * @param { IAssetRealEstate } item item
     * @returns { string } id
     */
    public panelTrackedBy(index: number, item: IAssetRealEstate | IAssetInsurance | IAssetAccount | IAssetProvider): string {
        return item.id
    }
}
