import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginModule, AuthenticationGuard, AuthorizationGuard, IRoleData, Role } from 'auth';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'dashboard',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren:  async () => (await import('dashboard')).DashboardModule,
    },
    {
        path: 'financing',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren:  async () => (await import('financing')).FinancingProcessingModule,
    },
    {
        path: 'workflow',
        canLoad: [AuthenticationGuard, AuthorizationGuard],
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.FinancingMapsEditor } as IRoleData,
        loadChildren:  async () => (await import('./modules/financing/features/workflow-processing')).WorkflowProcessingModule,
    },
    {
        path: 'smartdoc',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren:  async () => (await import('smartdoc')).SmartdocModule,
    },
    {
        path: 'auth',
        loadChildren: () => AuthLoginModule,
    },
    {
        path: 'administration',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren: async () => (await import('administration')).AdministrationModule,
    },
    {
        path: 'user-area',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren: async () => (await import('user-area')).UserAreaModule,
    },
    {
        path: 'load-balancing',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren: async () => (await import('load-balancing-external')).LoadBalancingExternalModule,
    },
    {
        path: 'calculator',
        canLoad: [AuthenticationGuard],
        canActivate: [AuthenticationGuard],
        loadChildren: async () => (await import('calculators')).CalculatorsModule, 
    },
    { path: '**', redirectTo: '' },
];

/**
 * Zentrales Routing-Modul der Anwendung
 */
@NgModule({
    imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
    exports: [RouterModule],
})
export class AppRoutingModule { }
