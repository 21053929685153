/* eslint-disable class-methods-use-this */
import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { UserState } from 'app/modules/auth/data/states';
import { DashboardState, DashboardTab, DashboardType } from 'app/modules/dashboard/data';
import { FinancingMode, TabChanged } from 'app/modules/financing/data';
import { FinancingEventType, IInformationEvents, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { WindowsService } from 'app/modules/shared/services/windows.service';
import { BehaviorSubject, Subject, combineLatest, debounceTime, iif, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Klasse zur Anzeige einer Liste an Aktivitäten
 */
@Component({
    selector: 'finprocess-information-events',
    templateUrl: './information-events.component.html',
    styleUrls: ['./information-events.component.scss'],
})
export class InformationEventsComponent implements OnDestroy, OnInit, AfterViewInit {

    public dashboardType: DashboardType = DashboardType.Expert;

    public informationEvents: IInformationEvents[] = [];

    /**
     * Ladezustand
     */
    public loading = true;
    public waiterLoadingBatch = false;

    //um UI expirience zu verbessern
    public searchActive = false;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Inhalt des Suchfeldes
     */
    public search = new BehaviorSubject<string>('');

    /**
     * Aktuell ausgewählter Tab
     */
    public tabId?: DashboardTab;

    /**
     * Enum für View
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DashboardTab = DashboardTab;

    @ViewChild(CdkVirtualScrollViewport) private scrollViewport!: CdkVirtualScrollViewport;

    //Anzahl events
    private initBatchCount = 15;

    //Start Index auf dem Backend
    private initBatchStart = 0;

    //um search string zu speihern / vergleichen
    private trackSearchString: string | undefined = '';

    //flag für loading
    private isLoadStartBatch = true;

    private financingEventToIgnoreExpertDashboard = undefined;

    private financingEventToIgnoreReferentDashboard = [ FinancingEventType.MessageReceived, FinancingEventType.SampleCalculationRequested, FinancingEventType.EsisRequested, FinancingEventType.ChangeRequestVpcSended, FinancingEventType.NewNotice, FinancingEventType.EsisRejected, FinancingEventType.DocumentsReceived ];

    /**
     * Standard Konstruktor
     *
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     * @param {Store} store Store-Injektor
     * @param {NotificationService} notification NotificationService Injektor
     * @param {Router} router Router
     * @param {TranslateService} translateService TranslateService
     * @param {WindowsService} windowsService windowsService-Injektor
     */
    public constructor(
        private informationEventService: InformationEventService,
        private store: Store,
        private notification: NotificationService,
        private router: Router,
        private translateService: TranslateService,
        private windowsService: WindowsService,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        combineLatest([
            this.store.select(DashboardState.currentTab),
            this.store.select(DashboardState.currentDashboard),
        ]).pipe(
            takeUntil(this.onDestroy$),
            filter(([tabId, currentDashboard]) => (tabId !== undefined && currentDashboard !== undefined )),
            tap(([tabId, currentDashboard]) => {
                this.dashboardType = currentDashboard as DashboardType ;
                this.tabId = tabId as DashboardTab;
                this.loading = true;
                this.isLoadStartBatch = true;
                this.search.next('');
            }),
            mergeMap(([tabId]) => this.store.select(UserState.user).pipe(map(user => ({user, tabId})))),
            switchMap(({user, tabId}) =>
                iif(
                    () => !!user,
                    this.informationEventService.loadInformationEvents(tabId as string, this.isLoadStartBatch, { startIndex: this.initBatchStart, count: this.initBatchCount, financingEventTypes: this.dashboardType === DashboardType.Referent ? this.financingEventToIgnoreReferentDashboard : this.financingEventToIgnoreExpertDashboard}),
                    of([]),
                )),
        ).subscribe(() => {
            this.loading = false;
            this.isLoadStartBatch = false;
        });

        this.store.select(InformationEventState.allInformationEvents)
            .pipe(
                takeUntil(this.onDestroy$),
            ).subscribe(events => {
                this.informationEvents = events
            });
    }

    /**
     * Angular Hook AfterInit
     */
    public ngAfterViewInit(): void {
        combineLatest([
            this.scrollViewport.renderedRangeStream,
            this.search.pipe(debounceTime(300)),
        ]).pipe(
            map(([range, search]) => this.getNextBatch(range, search)),
        ).subscribe();
    }


    /**
     * Rüft nächte Dataladung aus dem Server
     *
     * @param {ListRange} range ListRange
     * @param {string} search Search string
     */
    private getNextBatch(range: ListRange, search?: string): void {
        //wenn der Benutzer scrollt zum ende der Liste aus Viewport und Viewport Daten lenght ist gleich als die Liste lenght und die Liste ist nicht großer als Batch count
        const isRangeEnd = range.end !== 0 && range.end === this.scrollViewport.getDataLength() && range.end >= this.initBatchCount;
        const isSearchSame = this.trackSearchString === search;

        if ( isRangeEnd || !isSearchSame) {
            this.waiterLoadingBatch = true;

            if (!isSearchSame) {
                this.trackSearchString = search;
                this.isLoadStartBatch = true;
                this.searchActive = true;
            }

            if (!!this.tabId) {
                this.informationEventService.loadInformationEvents( this.tabId, this.isLoadStartBatch, { startIndex: this.isLoadStartBatch ? this.initBatchStart : range.end, count: this.initBatchCount, searchString: search ?? undefined, financingEventTypes: this.dashboardType === DashboardType.Expert ? this.financingEventToIgnoreExpertDashboard : this.financingEventToIgnoreReferentDashboard})
                    .pipe(
                        takeUntil(this.onDestroy$),
                    ).subscribe(() => {
                        this.waiterLoadingBatch = false;
                        this.isLoadStartBatch = false;
                        this.searchActive = false;
                    });
            }
        }
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Aktualisiert die Suche
     *
     * @param {string} search Suchstring
     */
    public updateSearch(search: string): void {
        this.search.next(search);
    }

    /**
     * Setzt den Status aller Aktivitäten des Container auf gelesen/ungelesen
     *
     * @param {IInformationEvents} event AktivitätenContainer
     * @param {boolean} read Gelesen-Status
     */
    public setReadStatus(event: IInformationEvents, read: boolean): void {
        if (!Array.isArray(event.events) || event.events.length === 0 || this.tabId === DashboardTab.All) {
            return;
        }

        this.informationEventService.changeReadStatus({
            finProcessContainerId: event.financingId,
            eventIds: event.events.map(it => it.id),
        }, read).subscribe({
            error: () => this.notification.alert(
                this.translateService.instant('general.error'),
                this.translateService.instant('dashboard.features.dashboard.errorStatus'),
            ),
        });
    }

    /**
     * Gibt das Icon für den Eventtyp zurück
     *
     * @param {FinancingEventType} financingEventType Event Typ
     * @returns {string} Material Icon Name
     */
    public getIconByType(financingEventType: FinancingEventType): string {
        switch (financingEventType) {
            case FinancingEventType.EsisRequested:
            case FinancingEventType.EsisRejected:
                return 'credit_card';
            case FinancingEventType.DocumentsReceived:
                return 'folder_open';
            case FinancingEventType.MessageReceived:
                return 'chat';
            case FinancingEventType.SampleCalculationRequested:
                return 'calculate';
            case FinancingEventType.ChangeRequestVpcSended:
                return 'repeat';
            default:
                return 'notifications';
        }
    }

    /**
     * Navigiert zu einer Finanzierung
     *
     * @param  {IInformationEvents} eventContainer Aktivitätencontainer
     * @returns {Promise} Rückgabe des Router Events
     */
    public async navigateIntoFinancing(eventContainer: IInformationEvents): Promise<void> {
        const latestEvent = eventContainer.events[0];

        if (latestEvent === undefined) {
            return;
        }

        switch (latestEvent.eventType) {
            case FinancingEventType.EsisRejected:
                await this.router.navigate(['financing', eventContainer.financingId, 'esis']);
                break;
            case FinancingEventType.EsisRequested:
                await this.router.navigate(['financing', eventContainer.financingId, 'customer-center'], { queryParams: { mode: FinancingMode.RiskFinancingPlan, tab: 1 }});
                break;
            case FinancingEventType.SampleCalculationRequested:
                this.store.dispatch(new TabChanged(8)).subscribe(() => this.router.navigate(['financing', eventContainer.financingId, 'financingmap', eventContainer.financingId]));
                break;
            case FinancingEventType.DocumentsReceived:
                this.store.dispatch(new TabChanged(7)).subscribe(() => this.router.navigate(['financing', eventContainer.financingId, 'financingmap', eventContainer.financingId]));
                break;
            case FinancingEventType.MessageReceived:
                this.windowsService.openMessagesWindow(eventContainer.financingId);
                break;
            default:
                await this.router.navigate(['financing', eventContainer.financingId, 'financingmap', eventContainer.financingId]);
                break;
        }
    }

    /**
     * Zählt die Anzahl der ungelesenen Aktivitäten
     *
     * @param {IInformationEvents} eventContainer EventContainer
     * @returns {number} Anzahl der ungelesen Aktivitäten
     */
    public getUnreadCount(eventContainer: IInformationEvents): number {
        return eventContainer.events.reduce((sum, event) => (sum += event.isRead ? 0 : 1), 0)
    }
}
