import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, IUser, Role } from 'app/modules/auth/api';
import { Observable, iif, map, mergeMap, of } from 'rxjs';
import { ConfigService, UUID } from 'shared/util';

import { IEditorModel } from '../../interfaces';
import { LoadBalancingDataLoaded } from '../../states';

/**
 * Service zum Verwalten von Finanzierungsmappen
 */
@Injectable()
export class LoadBalancingService {

    /**
     * Konstruktor
     *
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(private httpClient: HttpClient,
                       private config: ConfigService,
                       private authorizationService: AuthorizationService,
                       private store: Store) { }

    /**
     * Lädt alle aktuellen Bearbeiter
     *
     * @param {UUID} financingId Die ID der Finanzierungsmappe
     * @param {Role} userpurpose Rolle
     * @returns {Observable<IUser[]>} Die Liste aller aktuellen Bearbeiter als Observable
     */
    public getEditors(financingId: UUID, userpurpose: Role): Observable<IUser[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.LoadBalancingInternal, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IUser[]>(`${this.config.getEnvironment().apiUrl}/loadbalancing/geteditors?id=${financingId}`, {
                    params: {
                        requestedUserPurpose: userpurpose,
                    },
                }).pipe(
                    mergeMap((users: IUser[]) =>
                        this.store.dispatch(new LoadBalancingDataLoaded({
                            users,
                        })).pipe(
                            map(() => users),
                        ),
                    ),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Weist eine Finanzierung einem Bearbeiter zu
     *
     * @param {UUID} financeId Die ID der Finanzierungsmappe
     * @param {UUID} editorId Die ID des Bearbeiters
     * @param {Role} userPurpose userPurpose 
     * @returns {Observable} Antwort vom Server
     */
    public setEditor(financeId: UUID, editorId: UUID, userPurpose: Role): Observable<unknown> {
        const editorModel: IEditorModel = {
            id: financeId,
            editorId,
            userPurpose,
        };

        return this.authorizationService.checkPermissions$(Role.LoadBalancingInternal, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IEditorModel>(`${this.config.getEnvironment().apiUrl}/loadbalancing/seteditor`, editorModel),
                of(undefined),
            )),
        );
    }
}
