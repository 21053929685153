/**
 * Dokumententyp
 */
export enum DocumentType {
    /**
     * Machbarkeit
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    Feasibility = 41,

    /**
     * Finanzierungsbeispiel
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    FinancingExample = 42,

    /**
     * Zusage
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    Commitment = 50,

    /**
     * Weitere Schritte
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    NextSteps = 61,

    /**
     * Unterlagencheckliste
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    DocumentChecklist = 62,

    /**
     * Loadprotokoll
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    LeadProtocol = 64,

    /**
     * Lead-Datensatz
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    LeadData = 65,

    /**
     * Datenschutzerklärung Intern
     * Dieser Wert ist nicht zur Verwendung durch Dritte freigegeben, da es sich um ein internes Dokument handelt
     */
    PrivacyStatementHSIntern = 66,

    /**
     * Gerichtlicher Scheidungsbeschluss
     */
    JudicialDivorceDecree = 71,

    /**
     * Restschuldbestätigung
     */
    RemainingDebtConfirmation = 72,
}
