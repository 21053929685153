import { IBranchStateModel, IDebitRatesStateModel, IFinancingConfigurationStateModel, ILoadBalancingStateModel, IRiskParametrizationStateModel, IUserAdministrationStateModel } from '.';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface IAdministrationStateParentDefinition {
    /**
     * Zustand für Filialen
     */
    branch: IBranchStateModel;

    /**
     * Zustand für Konditionen
     */
    debitRates: IDebitRatesStateModel;

    /**
     * Zustand für Ausgleich
     */
    loadBalancing: ILoadBalancingStateModel;

    /**
     * Zustand für Nutzeradministration
     */
    userAdministration: IUserAdministrationStateModel;

    /**
     * Zustand für Risikoparametrisierung
     */
    riskParametrization: IRiskParametrizationStateModel;

    /**
     * Zustand für Finanzierungskonfiguration
     */
    financingConfiguration: IFinancingConfigurationStateModel;
}

export const ADMINISTRATION_PERSISTENT_STATE_KEYS = [];
