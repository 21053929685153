/* eslint-disable jsdoc/check-param-names */
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DocumentType, MandantType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role, UserService } from 'app/modules/auth/data';
import { UserState } from 'app/modules/auth/data/states';
import { IRFPData } from 'app/modules/financing/data/interfaces/riskfinancingplans-data.interface';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { FinancingEventType, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { FinancingStatus, FinancingSubStatus } from 'app/modules/shared';
import { WindowsService } from 'app/modules/shared/services/windows.service';
import { SmartDocClosed, SmartDocOpened } from 'app/modules/smartdoc/data';
import { environment } from 'environment';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, forkJoin, iif, map, of, takeUntil, throwError } from 'rxjs';
import { mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Environment, HelperService, UUID } from 'shared/util';

import { AllowanceDialogComponent } from '../allowance-dialog/allowance-dialog.component';
import { CancelContractDialogComponent } from '../cancel-contract-dialog/cancel-contract-dialog.component';
import { FormalComponent } from '../formal/formal.component';
import { GeneralComponent } from '../general/general.component';

import { DocumentService, EditModeChanged, FinancingContainerIDLoaded, FinancingLeaved, FinancingMode, FinancingService, FinancingState, IDebitor, IFinancing, IFinancingStateParentDefinition, IFinprocessContainer } from './../../../../data';


/**
 * Finanzierungscontainer-Komponente
 */
@Component({
    selector: 'finprocess-financing-container',
    templateUrl: './financing-container.component.html',
    styleUrls: ['./financing-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancingContainerComponent implements OnInit, OnDestroy {

    /**
     * Finanzierungsmodus
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingMode = FinancingMode;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * ID der Einreichung
     */
    public defaultFinancingMapId?: string;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    public riskFinancingPlans: IRFPData[] = [];

    /**
     * Ladezustand der Komponente
     */
    public loading = true;

    /**
     * Ausklappen der einzelnen Expansion Panels
     */
    public rfpExpanded = true;
    public liabilitiesExpanded = true;
    public householdCalculationsExpanded = true;
    public borrowerCheck = true;


    /** Side Buttons stuff */

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;


    public disableAllowance = true;

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer)
    public finprocessContainer$!: Observable<IFinprocessContainer | undefined>;

    /**
     * Sortiere Debitors
     */
    @Select(FinancingState.sortedEconomicDebitors)
    public sortedDebitors$!: Observable<(withOverwrites?: boolean) => IDebitor[]>

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable ob Nutzer auch Bearbeiter der Finanzierung ist
     */
    public isEditor$!: Observable<boolean>;

    /**
     * Observable ob der Stornieren Button aktiv ist
     */
    public cancelReadonly$!: Observable<boolean>;

    /**
     * Observable ob Smartdoc verfügbar ist
     */
    public smartdocDisabled$!: Observable<boolean>;

    /**
     * Is it the live environment
     */
    public isLiveEnvironment = false;

    /**Observable ob SmartDoc export button active */
    public isSmartDocExportButtonActive$!: Observable<boolean>;

    /**
     * Observable ob der Button zum Eskalieren von Fällen sichtbar ist
     */
    public showEscalation$!: Observable<boolean>;

    private editingHistoryWindowsName = 'editingHistory';

    /**
     * Debitoren sortiert nach Einkommen
     */
    public debitors: IDebitor[] = [];

    /**
     * Debitoren aufgeklappt
     */
    public isExpanded = signal(false);

    /**
     * Community Customer Number
     */
    public communityCustomerNumber: string | undefined;

    /**
     * Mandantentyp
     */
    public mandantType: MandantType | undefined;

    /**
     * Mandatentyp HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public MandantType = MandantType;
    
    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {Router} router Router-Injektors
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {MatDialog} dialog Angular Dialog-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translateService TranslateService
     * @param {DatePipe} datePipe DatePipe
     * @param {WindowsService} windowsService windowsService-Injektor
     */
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    constructor(
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        public financingService: FinancingService,
        private store: Store,
        private authorizationService: AuthorizationService,
        private router: Router,
        private documentService: DocumentService,
        private dialog: MatDialog,
        private waiterService: WaiterService,
        private translate: TranslateService,
        private informationEventService: InformationEventService,
        private userService: UserService,
        private notification: NotificationService,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private windowsService: WindowsService,
    ) {
        this.editingReadonly$ = combineLatest([
            this.financingService.editingReadonlyExpert$,
            this.financingService.editingReadonlyReferent$,
            this.financingService.editingReadonlyApprover$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([readonlyExpert, readonlyReferent, readonlyApprover]) => readonlyExpert && readonlyReferent && readonlyApprover),
        )

        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmode$;
        this.isEditor$ = this.financingService.isEditor$;
        this.isSmartDocExportButtonActive$ = combineLatest([this.financingService.smartDocDocumentsExists$, this.financingService.isEditor$]).pipe(
            takeUntil(this.onDestroy$),
            map(([smartDocDocumentExists, isEditor]) => smartDocDocumentExists && isEditor),
        );
        this.isLiveEnvironment = environment.environment >= Environment.Demo;
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(id => {
                this.defaultFinancingMapId = id;
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => this.store.dispatch(new FinancingContainerIDLoaded(id)).pipe(map(() => id))),
            switchMap(id => forkJoin([this.financingService.getAllRiskFinancingPlans(id), this.financingService.loadFinancingContainer(id)])),
        ).subscribe(() => {
            this.loading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        this.store.select((state: IFinancingStateParentDefinition) => state.financing.riskFinancingPlans).subscribe(rfpData => {
            this.riskFinancingPlans = rfpData;
            this.loading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        this.finprocessContainer$.pipe(takeUntil(this.onDestroy$))
            .subscribe(finprocessContainer => {
                if (finprocessContainer === undefined) {
                    return;
                }

                this.mandantType = finprocessContainer?.mandantType;

                this.disableAllowance = finprocessContainer.status === FinancingStatus.Rejected || finprocessContainer.status === FinancingStatus.Canceled || finprocessContainer.status >= FinancingStatus.SampleCalculationWaitingForAcception;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            });

        this.cancelReadonly$ = combineLatest([
            this.financingService.isEditor$,
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer),
        ]).pipe(takeUntil(this.onDestroy$), map(([isEditor, financing]) => {
            if (financing === undefined) {
                return true;
            }

            return !isEditor || [
                FinancingStatus.Completed,
                FinancingStatus.Canceled,
                FinancingStatus.Rejected,
            ].includes(financing.status);
        }));

        this.showEscalation$ = combineLatest([
            this.authorizationService.hasRole$(Role.FilterAutomaticallyRejected),
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer),
        ]).pipe(takeUntil(this.onDestroy$), map(([hasRole, financing]) => {
            if (financing === undefined) {
                return false;
            }

            return hasRole && financing.status === FinancingStatus.Rejected && financing.subStatus === FinancingSubStatus.AutomaticallyRejected;
        }));

        //Get community customer number
        this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing).pipe(
            filter((financing): financing is IFinancing => financing !== undefined),
            map(financing => {
                const communityCustomerNumber = OverwriteHelperService.getMergedOverwriteValue(financing, 'communityCustomerNumber', financing.communityCustomerNumber, true);
                return { communityCustomerNumber };
            }),
        ).pipe(takeUntil(this.onDestroy$)).subscribe(({ communityCustomerNumber }) => {
            this.communityCustomerNumber = communityCustomerNumber;
        });

        this.sortedDebitors$.pipe(takeUntil(this.onDestroy$)).subscribe(debitors => {
            this.debitors = debitors(true);
        });

        this.smartdocDisabled$ = this.authorizationService.hasRole$(Role.SmartDoc).pipe(takeUntil(this.onDestroy$), map(hasRole => !hasRole));
    }

    /**
     * ngOnDestory
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.store.dispatch(new FinancingLeaved());
        this.windowsService.closeWindow(this.editingHistoryWindowsName)
    }

    /**
     * Wechselt den Bearbeitungsmodus
     */
    public toggleEditMode(): void {
        this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financingTabs.editMode).pipe(
            mergeMap(editMode => this.store.dispatch(new EditModeChanged(!editMode))),
        ).subscribe();
    }


    /**
     * Lädt alle Nachreichungen herunter
     */
    public downloadAllFiles(): void {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.downloadDocuments'),
        });

        combineLatest([
            this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer),
            this.store.selectOnce(InformationEventState.allInformationEvents),
        ])
            .pipe(take(1), mergeMap(
                ([financing, informationEvents]) => {
                    if (financing === undefined) {
                        return throwError(() => undefined);
                    }

                    const documentEvent = informationEvents.find(event => event.eventType === FinancingEventType.DocumentsReceived);
                    const userId = this.userService.user?.id;
                    const fileIds: string[] = [];
                    const eventIds: string[] = [];

                    if (documentEvent === undefined) {
                        fileIds.push(...this.store.selectSnapshot(FinancingState.allDocuments).reduce((ids, document) => ids.concat(document.files.map(file => file.id)), [] as string[]));
                    } else {
                        for (const event of documentEvent?.events ?? []) {
                            if (Array.isArray(event.eventValues) && event.eventValues.length > 0) {
                                fileIds.push(...event.eventValues.filter(eventValue => eventValue.key === 'FileId').map(eventValue => eventValue.value as string));
                            }

                            eventIds.push(event.id);
                        }
                    }


                    const requests: Observable<ArrayBuffer | null | undefined | void>[] = [
                        this.documentService.downloadFiles({
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            id: financing.id,
                            fileIds,
                        }),
                    ];


                    if (documentEvent !== undefined && (financing.users.some(usr => usr.id === userId) || userId === financing.temporaryUser?.id)) {
                        requests.push(this.informationEventService.changeReadStatus({
                            finProcessContainerId: financing.id,
                            eventIds,
                        }, true));
                    }

                    return forkJoin(requests);
                })).subscribe({
                next: async ([arrayBuffer]) => {
                    if (!arrayBuffer) {
                        return;
                    }

                    const blob = new Blob([arrayBuffer], { type: 'application/zip' });

                    const mainDebitor = this.store.selectSnapshot(FinancingState.mainDebitor);
                    const mainDebitorName = !!mainDebitor ? `${mainDebitor.lastName}_${mainDebitor.firstName}` : '';
                    const now = new Date();
                    const dateString = `${now.getFullYear()}${now.getMonth().toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`

                    await HelperService.downloadFileFromBlob(blob, `Belege_${mainDebitorName}_${dateString}`);

                    this.waiterService.hide();
                }, error: () => {
                    this.waiterService.hide();

                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('financing.features.financing-processing.downloadDocumentsError'),
                    );
                },
            });
    }

    /**
     * Lädt alle Dateien herunter
     */
    public financingMapExport(): void {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.downloadExport'),
        });

        this.store.selectOnce((it: IFinancingStateParentDefinition) => it)
            .pipe(mergeMap(
                it => iif(
                    () => it.financing.finprocessContainer?.id !== undefined,
                    this.financingService.financingMapExport(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        it.financing.finprocessContainer!.id,
                    ),
                    of(undefined),
                ),
            )).subscribe({
                next: async arrayBuffer => {
                    if (!arrayBuffer) {
                        return;
                    }

                    const blob = new Blob([arrayBuffer], { type: 'application/zip' });

                    const mainDebitor = this.store.selectSnapshot(FinancingState.mainDebitor);
                    const mainDebitorName = !!mainDebitor ? `${mainDebitor.lastName}_${mainDebitor.firstName}` : '';
                    const now = new Date();
                    const dateString = `${now.getFullYear()}${now.getMonth().toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`

                    await HelperService.downloadFileFromBlob(blob, `Auftragsmappe_Export_${mainDebitorName}_${dateString}`);

                    this.waiterService.hide();
                }, error: () => this.waiterService.hide(),
            })
    }

    /**
     * Öffnet den Dialog zum Auftrag stornieren
     */
    public cancelContract(): void {
        this.store.selectOnce((it: IFinancingStateParentDefinition) => it)
            .subscribe(financingDefinition => {
                const financingState = financingDefinition.financing.finprocessContainer;

                if (!financingState) {
                    return;
                }

                const dialogRef = this.dialog.open(CancelContractDialogComponent, {
                    minWidth: '70%',
                });

                dialogRef.afterClosed().subscribe((reason: string) => {
                    if (reason === undefined || !reason.length || reason.length < 10) {
                        return;
                    }

                    this.financingService.setStatus({
                        reason: reason,
                        status: FinancingStatus.Canceled,
                        subStatus: null,
                        id: financingState.id,
                    }).subscribe(() => {
                        void this.router.navigateByUrl('/dashboard');
                    });
                });
            });
    }

    /**
     * Erlaubt eine Einreichung einer automatisch zurückgestellten Finanzierung
     */
    public allowSubmission(): void {
        const id = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer?.id);

        this.notification.confirmOkCancel(
            this.translate.instant('financing.features.financing-processing.escalationTitle'),
            this.translate.instant('financing.features.financing-processing.escalationText'),
        ).pipe(
            mergeMap(result => {
                if (id === undefined) {
                    return throwError(() => new Error());
                }

                if (result !== 'submit') {
                    return of(undefined);
                }

                return this.waiterService.show().pipe(
                    mergeMap(() => this.financingService.overwriteRejection(id)),
                    tap(() => this.router.navigate(['/dashboard'])),
                )
            }),
        ).subscribe({
            next: () => { this.waiterService.hide() },
            error: () => { this.waiterService.hide() },
        });
    }

    /**
     * Öffnet den Bearbeitungsverlauf
     */
    public openEditHistory(): void {
        const id = this.store.selectSnapshot((financingDefinition: IFinancingStateParentDefinition) => financingDefinition.financing.finprocessContainer?.id);
        this.windowsService.openWindow(this.editingHistoryWindowsName, `/financing/${id}/status`, 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
    }

    /**
     * Ruft die Smartdoc Bearbeitung auf
     */
    public openSmartdoc(): void {
        const finprocessContainer = this.store.selectSnapshot((financingDefinition: IFinancingStateParentDefinition) => financingDefinition.financing.finprocessContainer);
        const user = this.store.selectSnapshot(UserState.user);

        if (!user) {
            return;
        }

        //für smatDoc brauchen wir die Documents, wenn FM schon geladen ist dann sind die Documents nur gefiltert und an SmartDoc geschickt
        if (!!finprocessContainer) {
            /**
             * @todo temporary user
             */
            const readonly = !finprocessContainer.users.some(usr => usr.id === user.id) || [FinancingStatus.Rejected, FinancingStatus.Canceled, FinancingStatus.Completed].includes(finprocessContainer.status);
            let documents = this.store.selectSnapshot(FinancingState.allDocuments);

            if (documents !== undefined) {
                documents = documents.filter(it =>
                    ![
                        DocumentType.SampleCalculation,
                        DocumentType.SampleCalculationVariable,
                        DocumentType.ESIS,
                    ].some(type => it.type === type) &&
                    !HelperService.hasBit(it.tag, 1),
                );
            }

            this.store.dispatch(new SmartDocClosed).pipe(
                mergeMap(() =>
                    this.store.dispatch(new SmartDocOpened(
                        {
                            id: finprocessContainer.id,
                            isSmartDocMap: false,
                            isReadonly: readonly,
                            documents,
                        },
                    )),
                ),
            ).subscribe(() => this.router.navigate(['/smartdoc'], { skipLocationChange: true }));
        }
        //wenn FM nicht geladen bzw Documents, laden wir Documents
        else {
            this.waiterService.show();
            const financingContainer = this.store.selectSnapshot((financingDefinition: IFinancingStateParentDefinition) => financingDefinition.financing.finprocessContainer);

            if (!!financingContainer) {
                this.documentService.loadDocuments(financingContainer.id).pipe(
                    take(1),
                    map(documents => {

                        const readonly = !financingContainer.users.some(usr => usr.id === user.id) || [FinancingStatus.Rejected, FinancingStatus.Canceled, FinancingStatus.Completed].includes(financingContainer.status);

                        if (documents !== undefined) {
                            documents = documents.filter(it =>
                                ![
                                    DocumentType.SampleCalculation,
                                    DocumentType.SampleCalculationVariable,
                                    DocumentType.ESIS,
                                ].some(type => it.type === type) &&
                                !HelperService.hasBit(1, it.tag),
                            );
                        }
                        return { readonly, documents }

                    }),
                    map(data => {
                        this.store.dispatch(new SmartDocClosed);
                        return data;
                    }),
                    map(data => {
                        this.store.dispatch(new SmartDocOpened(
                            {
                                id: financingContainer.id,
                                isSmartDocMap: false,
                                isReadonly: data.readonly,
                                documents: data.documents,
                            },
                        ))
                    }),
                    tap(() => this.waiterService.hide()),
                ).subscribe(() => this.router.navigate(['/smartdoc'], { skipLocationChange: true }))
            }
        }
    }

    /**
     * SmartDoc Exportieren
     */
    public smartDocExport(): void {
        const financingId = this.store.selectSnapshot((financingDefinition: IFinancingStateParentDefinition) => financingDefinition.financing.finprocessContainer?.id);

        if (!!financingId) {
            this.waiterService.show();
            this.financingService.exportSmartDoc(financingId).subscribe({
                next: async file => {
                    if (!!file) {
                        await HelperService.downloadFileFromBlob(file, `${this.translateService.instant('financing.features.financing-processing.exportFileName')}_${this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')}`),
                        this.waiterService.hide();
                    }
                },
                error: () => {
                    this.waiterService.hide(),
                    this.notification.alert(this.translateService.instant('general.error'), this.translateService.instant('general.unknownError'))
                },
            })
        }
    }

    /**
     * Navigiert zum Workflow Tool
     */
    public toWorkflowTool(): void {
        this.activatedRoute.params.pipe(take(1)).subscribe(async params => {
            await this.router.navigate(['workflow', params['financingContainerId']]);
        });
    }

    /**
     * Öffnet Process component
     */
    public openProcess(): void {
        this.dialog.open(AllowanceDialogComponent, {
            width: '60%',
            height: '90%',
        });
    }

    /**
     * Navigiert zum sampleCalculation
     */
    public toSampleCalculation(): void {
        this.activatedRoute.params.pipe(take(1)).subscribe(async params => {
            await this.router.navigate(['financing', params['financingContainerId'], 'sample-calculation']);
        });
    }

    /**
     * prüfung ob Rolle OrganisationalUnitAdministrator oder FinancingMapsGlobalReader ist
     *
     * @returns {boolean} true if Admin, Leader
     */
    public isAdminOrLeader(): boolean {
        if (this.authorizationService.hasRole(Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor)) {
            return true;
        }
        return false;
    }
    /**
     * Schließt die Finanzierung
     */
    public async close(): Promise<void> {
        await this.router.navigateByUrl('/dashboard');
    }

    /**
     * Öffnet den Nachrichtenbereich in einem separaten Fenster
     */
    public openMessages(): void {
        if (!!this.defaultFinancingMapId) {
            this.windowsService.openMessagesWindow(this.defaultFinancingMapId)
        }
    }

    /**
     * Toggle Debitor Expansion
     */
    public toggleExpansion() {
        this.isExpanded.set(!this.isExpanded());
    }

    /**
     * Öffnet den Dialog zur Bearbeitung von allgemeinen Komponenten
     */
    public openGeneralComponentDialog(): void {
        const financing = this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing);

        if (!!financing) {
            this.dialog.open(GeneralComponent, {});
        }
        else if (!!this.defaultFinancingMapId) {
            this.financingService.loadFinancing(this.defaultFinancingMapId).pipe(take(1)).subscribe(() => {
                this.dialog.open(GeneralComponent, {});
            });
        }
        else {
            this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.generalComponentError'));
        }
    }

    /**
     * Open Formal Dialog Component
     */
    public openFormalDialogComponent(): void {
        const financing = this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing);

        if (!!financing) {
            this.dialog.open(FormalComponent, {});
        }
        else if (!!this.defaultFinancingMapId) {
            this.financingService.loadFinancing(this.defaultFinancingMapId).pipe(take(1)).subscribe(() => {
                this.dialog.open(FormalComponent, {});
            });
        }
        else {
            this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.formalComponentError'));
        }
    }
}
