/**
 * Parameter-Objekt zum Ermitteln des Übersetzungsschlüssels
 */
export interface IEnumParameter {
    /**
     * Enum-Wert als zahl oder Zeichenketter
     */
    value: string | number;

    /**
     * Enum-Typ als Zeichenkette
     */
    type: string;

    /**
     * Präfix für Übersetzungsschlüssel
     */
    translatePraefix?: string;
}
