import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const ADDRESS_INFO_FORCED_ANA: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'addressInfoForcedAna',

    addressInfoType: { type: WorkflowType.String },

    careOf: { type: WorkflowType.String },

    city: { type: WorkflowType.String },

    company: { type: WorkflowType.String },

    complement: { type: WorkflowType.String },

    countryCode: { type: WorkflowType.String },

    hamlet: { type: WorkflowType.String },

    houseNumber: { type: WorkflowType.String },

    nation: { type: WorkflowType.String },

    office: { type: WorkflowType.String },

    postalCode: { type: WorkflowType.String },

    postalLocation: { type: WorkflowType.String },

    province: { type: WorkflowType.String },

    region: { type: WorkflowType.String },

    street: { type: WorkflowType.String },
}
