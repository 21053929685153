<mat-dialog-content>
    <div *ngIf="!!form">
        <!-- form für smartDoc folder -->
        <ng-container *ngIf="!this.data.dropArea">
            <h3 *ngIf="!!this.data.dropFolder?.name" class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
                cdkDragHandle>{{'administration.features.smartDocConfiguration.editFolder' |
                translate}}</h3>
            <h3 *ngIf="!this.data.dropFolder?.name" class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
                cdkDragHandle>{{'administration.features.smartDocConfiguration.createFolder' |
                translate}}</h3>
            <div [formGroup]="form" fxLayout="column">
                <mat-form-field>
                    <input formControlName="dropFolderName"
                        placeholder="{{'administration.features.smartDocConfiguration.folderName' | translate}}" matInput>
                </mat-form-field>
            </div>
        </ng-container>
    
        <!-- form für DropArea -->
        <ng-container *ngIf="!!this.data.dropArea">
            <h3 *ngIf="!this.data.dropArea?.id">{{'administration.features.smartDocConfiguration.createArea' |
                translate}}</h3>
            <h3 *ngIf="!!this.data.dropArea?.id">{{'administration.features.smartDocConfiguration.editArea' |
                translate}}</h3>
            <div [formGroup]="form" fxLayout="column">
                <mat-form-field>
                    <input formControlName="dropAreaName"
                        placeholder="{{'administration.features.smartDocConfiguration.areaName' | translate}}" matInput>
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="exportName"
                        placeholder="{{'administration.features.smartDocConfiguration.areaShortName' | translate}}"
                        matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'administration.features.smartDocConfiguration.areaDocumentType' |
                        translate}}</mat-label>
                    <mat-select formControlName="document">
                        <mat-option [value]="undefined">{{ 'general.noSelection' | translate }}</mat-option>
                        <mat-option *ngFor="let doc of documentType" [value]="doc.value" [matTooltip]="doc.displayName">
                            {{doc.displayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox color="primary"
                    formControlName="hasQuickAccess">{{'administration.features.smartDocConfiguration.areaQuickAccess' |
                    translate}}</mat-checkbox>
    
            </div>
        </ng-container>
    
        <mat-dialog-actions fxLayoutAlign="center start" class="action">
            <button mat-raised-button color="primary" (click)="save()" [disabled]="form.pristine || !form.valid">{{
                'button.save' | translate}}</button>
            <button mat-raised-button (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>