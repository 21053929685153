import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IFile } from 'app/modules/financing/data';
import { LoadedJobStatus, MapRevoked, MapTemporaryRevokedAccepted } from 'app/modules/smartdoc/data/actions';
import { ErrorOccured } from 'app/modules/smartdoc/data/actions/error.action';
import { MethodNotAllowed } from 'app/modules/smartdoc/data/actions/open-credit-request/method-not-allowed.action';
import { SmartDocJobStatusChanged } from 'app/modules/smartdoc/data/actions/smartdoc/smartdoc-jobstatus-changed-action';
import { IDropFolderViewModel, ISmartDocStatusViewModel } from 'app/modules/smartdoc/data/interfaces/smartdoc';
import { IBreadcrumbPathElement } from 'app/modules/smartdoc/data/interfaces/smartdoc/breadcrumb-path-element.interface';
import { IMethodNotAllowedError } from 'app/modules/smartdoc/data/interfaces/smartdoc/method-not-allowed-error.interface';
import { AAGUID } from 'app/modules/smartdoc/data/types/aaguid';
import { FileLikeObject, FileUploaderOptions } from 'ng2-file-upload';
import { EMPTY, Observable, iif, of, throwError } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { ConfigService, HelperService, SubStatusCode } from '..';

import { WindowsService } from './windows.service';


/**
 * SharedService
 */
@Injectable({
    providedIn: 'root',
})
export class SharedService {

    /**
     * renderer
     */
    private renderer: Renderer2;

    // /**
    //  * modalRef
    //  */
    // private dialogRef0?: MatDialogRef<InformationDialogComponent>;

    // /**
    //  * modalRef
    //  */
    // private dialogRef403?: MatDialogRef<InformationDialogComponent>;

    /**
     * Constructor
     *
     * @param {TranslateService} translateSvc TranslateService
     * @param {ConfigService} config ConfigService
     * @param {Actions} actions$ Actions
     * @param {Store} store Store
     * @param {RendererFactory2} rendererFactory RendererFactory2
     * @param {WaiterService} waiterService WaiterService
     * @param {HttpClient} httpClient HttpClient
     * @param {AuthorizationService} authorizationService AuthorizationService
     * @param {NotificationService} notificationService NotificationService
     * @param {WindowsService} windowsService windowsService-Injektor
     */
    public constructor(
        private translateSvc: TranslateService,
        private config: ConfigService,
        private actions$: Actions,
        private store: Store,
        rendererFactory: RendererFactory2,
        private waiterService: WaiterService,
        private httpClient: HttpClient,
        private authorizationService: AuthorizationService,
        private notificationService: NotificationService,
        private windowsService: WindowsService,

    ) {
        this.renderer = rendererFactory.createRenderer(document.body, null);
    }

    /**
     * handling adding failed
     *
     * @param {FileLikeObject} item FileLikeObject
     * @param {Record<string, string> | null} filter Record<string, string> | null
     * @param {FileUploaderOptions} options FileUploaderOptions
     */
    public handlingAddingFileFailed(
        item: FileLikeObject,
        filter: Record<string, string> | null | undefined,
        options: FileUploaderOptions,
    ): void {
        if (filter !== undefined &&
            filter !== null) {
            switch (filter.name) {
                case 'fileSize': {
                    // eslint-disable-next-line no-case-declarations
                    const fileSize = (options.maxFileSize as number) / 1024 / 1024;
                    this.showInfo(this.translateSvc.instant('common.maxFileSizeReached', { size: fileSize.toString() }));
                    break;
                }
                case 'queueLimit': {
                    this.showInfo(this.translateSvc.instant('common.maxQueueSizeReached', { limit: (options.queueLimit as number).toString() }));
                    break;
                }
                case 'mimeType': {
                    this.showInfo(this.translateSvc.instant('common.wrongMimeType',
                        {
                            mimeTypes: (options.allowedMimeType as string[])
                                .map(it => this.translateSvc.instant(`fileUploaderMimeTypes.${it}`))
                                .filter((value, index, that) => that.indexOf(value) === index).join(', '),
                        }),
                    );
                    break;
                }
                case 'fileType': {
                    this.showInfo(this.translateSvc.instant('common.wrongMimeType',
                        {
                            mimeTypes: (options.allowedFileType as string[]).map(it => this.translateSvc.instant(`fileUploaderMimeTypes.${it}`)).join(', '),
                        }),
                    );
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    /**
     * handleError
     *
     * @param {HttpResponse<HttpErrorResponse> | HttpErrorResponse} err HttpResponse<unknown> | HttpErrorResponse
     * @param {string} customErrorMessage string
     * @returns {Observable<HttpErrorResponse>} error Observable<unknown>
     */
    public handleError(err: HttpResponse<HttpErrorResponse> | HttpErrorResponse, customErrorMessage?: string): Observable<HttpErrorResponse> {
        this.waiterService.hide();
        switch (err.status) {
            case 404: { break; }
            case 504: { break; }
            case 0: {
                this.notificationService.alert(
                    this.translateSvc.instant('common.information'),
                    this.translateSvc.instant('informations.serverUnreachable'),
                    false,
                );
                return EMPTY;
            }
            case 401: {
                // if ((this.oAuthSvc.token as unknown) !== undefined) {
                //     return from(this.oAuthSvc.token().catch(this.oAuthSvc.loadDiscoveryDocumentAndLogin.bind(this.oAuthSvc)));
                // }
                // else {
                //     return from(this.oAuthSvc.loadDiscoveryDocumentAndLogin());
                // }
                break;
            }
            case 403: {
                this.notificationService.alert(
                    this.translateSvc.instant('common.information'),
                    this.translateSvc.instant('informations.rightsRevoked'),
                    false,
                );
                return EMPTY;
            }
            case 405:
                return this.handle405(err as HttpErrorResponse, customErrorMessage);
            default:
                this.store.dispatch(new ErrorOccured({ error: err, message: customErrorMessage }));
                return throwError(() => new Error(err.statusText));
        }
        return EMPTY;
    }

    /**
     * openFile
     *
     * @param {IFile} fileModel IFile
     */
    public openFile(fileModel: IFile): void {

        if (fileModel.base64) {
            const raw = atob(fileModel.base64);


            const uint8Array = new Uint8Array(raw.length);
            for (let y = 0; y < raw.length; y++) {
                uint8Array[y] = raw.charCodeAt(y);
            }


            const file = new Blob([uint8Array], { type: fileModel.mimeType });
            const fileURL = URL.createObjectURL(file);
            const win = this.windowsService.openWindow(fileModel.id, '', 'location=yes,height=960,width=750,scrollbars=yes,status=yes')
            if (win === null) {
                this.showInfo(this.translateSvc.instant('common.popupBlocked'));
            }
            else {
                win.document.title = fileModel.name + fileModel.extension;
                /**
                 * direkt open funktioniert in Chrome mit Data-Url nicht mehr, z.B. Fehler 'Not allowed to navigate top frame to data URL'
                 * bzw. gibt es auch die Gefahr, dass es als Popup geblockt wird
                 * daher Rückgriff auf iframe wrapper
                 */
                win.document.write(`<iframe src="${fileURL}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
            }

        }
    }

    /**
     * Lädt eine Datei
     *
     * @param  {AAGUID} id ID der Datei
     * @returns {Observable} Datei
     */
    public loadFile(id: AAGUID): Observable<string | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/document/getfile`, {
                    params: {
                        id,
                    },
                    responseType: 'text',
                }),
                of(undefined),
            )),
        );
    }

    /**
     * showInfo
     *
     * @param {string} message string
     * @param {string} title string
     */
    public showInfo(message: string, title?: string): void {
        this.notificationService.alert(
            title !== undefined ? title : this.translateSvc.instant('common.information'),
            message,
            false,
        );
    }

    /**
     * showConfirm
     *
     * @param {string} message string
     * @param {string} title string
     */
    public showConfirm(
        message: string,
        title?: string,
    ): void {

        this.notificationService.confirmYesNo(
            title !== undefined ? title : this.translateSvc.instant('common.confirm'),
            message,
            false,
        );

    }


    /**
     * läd den aktuellen Prozessstatus eines SmartDocJobs
     *
     * @param {AAGUID} id AAGUID
     * @param {() => void} closeFunction () => void
     */
    public loadJobStatus(
        id: AAGUID,
        closeFunction: (error?: unknown) => void,
    ): void {
        if (!this.authorizationService.checkPermissions(Role.SmartDoc, false)) {
            closeFunction(new Error('UnauthorizedAccessException'));
        }
        this.httpClient.get<ISmartDocStatusViewModel>(
            `${this.config.getEnvironment().apiUrl}/SmartDoc/getSmartDocJobState`,
            {
                params: {
                    financingId: id,
                },
            },
        ).pipe(
            take(1),
        ).subscribe((response: ISmartDocStatusViewModel) => {
            HelperService.nullToUndefined(response);
            this.store.dispatch(new LoadedJobStatus(response));
        }, () => {
            closeFunction();
        });
    }

    /**
     * läd den aktuellen Prozessstatus eines SmartDocJobs
     *
     * @param {AAGUID} id AAGUID
     */
    public setJobStatusState(
        id: AAGUID,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.loadJobStatus(id, /*error*/() => {});

        this.actions$.pipe(ofActionSuccessful(LoadedJobStatus)).subscribe(({payload}: LoadedJobStatus) => {
            this.store.dispatch(new SmartDocJobStatusChanged({id: id, jobStatus: payload.jobStatus}))
        });
    }

    /**
     * generiert den Brotkrumenpfad für SmartDoc
     *
     * @param {IDropFolderViewModel[]} dropFolders IDropFolderViewModel[]
     * @param {IDropFolderViewModel} currentDropFolder IDropFolderViewModel
     * @returns {IBreadcrumbPathElement[]} resultPaths IBreadcrumbPathElement[]
     */
    public buildBreadcrumb(
        dropFolders: IDropFolderViewModel[],
        currentDropFolder?: IDropFolderViewModel,
    ): IBreadcrumbPathElement[] {
        const resultPaths: IBreadcrumbPathElement[] = [
            {
                name: this.translateSvc.instant(
                    'admin.smartDocConfiguration.start',
                ),
            },
        ];
        if (currentDropFolder === undefined) {
            return resultPaths;
        } else {
            resultPaths.push({
                name: currentDropFolder.name,
                folder: currentDropFolder,
            });
            if (currentDropFolder.parentDropFolderId !== undefined) {
                let currentFolder = currentDropFolder;
                while (
                    currentFolder.parentDropFolderId !== undefined &&
                    dropFolders.some(
                        // eslint-disable-next-line no-loop-func
                        it => it.id === currentFolder.parentDropFolderId,
                    )
                ) {
                    const parentFolder = dropFolders.find(
                        // eslint-disable-next-line no-loop-func
                        it => it.id === currentFolder.parentDropFolderId,
                    );
                    resultPaths.splice(1, 0, {
                        name: (parentFolder as IDropFolderViewModel).name,
                        folder: parentFolder as IDropFolderViewModel,
                    });
                    currentFolder = parentFolder as IDropFolderViewModel;
                }
            }
            return resultPaths;
        }
    }

    //--------------------------------------------------------------------------
    //                      P R I V A T E  M E T H O D S
    //--------------------------------------------------------------------------

    /**
     * handle405
     *
     * @param {HttpErrorResponse} response HttpErrorResponse
     * @param {string} customErrorMessage string
     * @returns {Observable<HttpErrorResponse>} observable Observable<HttpErrorResponse>
     */
    private handle405(response: HttpErrorResponse, customErrorMessage?: string): Observable<HttpErrorResponse> {
        const error = response.error as IMethodNotAllowedError;
        switch (error.subStatus) {
            case SubStatusCode.NotCurrentUserOfMap: {
                return this.store.dispatch(new MapRevoked(error.id as AAGUID));
            }
            case SubStatusCode.TemporaryUserIsEditing: {
                return this.store.dispatch(new MapTemporaryRevokedAccepted(error.id as AAGUID));
            }
            case SubStatusCode.ActionNotAllowedInState: {
                return this.store.dispatch(new MethodNotAllowed(error.id as AAGUID));
            }
            case SubStatusCode.SampleCalculationMissing: {
                return this.store.dispatch(new ErrorOccured({ error, message: this.translateSvc.instant('errors.sampleCalculationMissing') }));
            }
            case SubStatusCode.ESISMissing: {
                return this.store.dispatch(new ErrorOccured({ error, message: this.translateSvc.instant('errors.esisCalculationMissing') }));
            }
            case SubStatusCode.MediaTypeNotAccepted: {
                return this.store.dispatch(new ErrorOccured({ error, message: this.translateSvc.instant('errors.mediaTypeNotAccepted') }));
            }
            case SubStatusCode.ZipFileIsPasswordProtected: {
                return this.store.dispatch(new ErrorOccured({ error, message: this.translateSvc.instant('errors.zipFileIsPasswordProtected') }));
            }
            case SubStatusCode.PdfFileIsPasswordProtected: {
                return this.store.dispatch(new ErrorOccured({ error, message: this.translateSvc.instant('errors.pdfFileIsPasswordProtected') }));
            }
            case SubStatusCode.DocumentTypeAlreadyExists: { break; }
            case SubStatusCode.DropFolderNotExists: { break; }
            case SubStatusCode.DropAreaNotExists: { break; }
            case SubStatusCode.DocumentsExists: { break; }
            case SubStatusCode.SmartDocMapAlreadyExists: {
                return throwError(() => response);
            }
            default: {
                return this.store.dispatch(new ErrorOccured({ error, message: customErrorMessage }));
                // return throwError(() => response);
            }
        }
        return EMPTY
    }
}
