import { IInformationEvents, IInformationEventsRequest } from './../../interfaces';

/**
 * Aktion beim Laden aller für den aktuellen Tab relevanten Aktivitäten
 */
export class InformationEventsAdded {
    public static readonly type = 'informationEvent.added';

    /**
     * Konstruktor
     *
     * @param {IInformationEvents[]} payload Liste der Aktivitäten
     */
    public constructor(public payload: IInformationEvents[]) { }
}

/**
 * Aktion beim Laden aller für den aktuellen Tab relevanten Aktivitäten, die filtriert sind
 */
export class InformationEventLoaded {
    public static readonly type = 'informationEvent.withSearchLoaded';

    /**
     * Konstruktor
     *
     * @param {IInformationEvents[]} payload Liste der Aktivitäten
     */
    public constructor(public payload: IInformationEvents[]) { }
}

/**
 * Aktion beim Ändern des Status von Aktivitäten zwischen Gelesen/Ungelesen
 */
export class InformationEventReadStatus {
    public static readonly type = 'informationEvent.readStatusChange';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload Objekt
     * @param {IInformationEventsRequest} payload.request Request
     * @param {boolean} payload.read Gelesen/Ungelesen
     */
    public constructor(public payload: { request: IInformationEventsRequest, read: boolean}) { }
}
