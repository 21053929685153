/* eslint-disable class-methods-use-this */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { HelperService } from 'app/modules/shared';
import { throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { DocumentService, IFinancingStateParentDefinition, IUploadContext, IUploadDocument, IUploadList } from '../../../../data';
import { CalculationExamplesDialogComponent } from '../calculation-examples-dialog/calculation-examples-dialog.component';

/**
 * Komponente für Upload Tab
 */
@Component({
    selector: 'finprocess-upload-tab',
    templateUrl: './upload-tab.component.html',
    styleUrls: ['./upload-tab.component.scss'],
})
export class UploadTabComponent {

    @Input()
    public uploadContexts: IUploadContext[] = [];

    @Input()
    public readonly = false;

    /**
     * Standard Konstruktor
     *
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {MatDialog} dialog MatDialog Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef
     * @param {WaiterService} waiterService WaiterService
     * @param {NotificationService} notification NotificiationService
     * @param {Store} store Store-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(
        private documentService: DocumentService,
        private dialog: MatDialog,
        private cRef: ChangeDetectorRef,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private store: Store,
        private translate: TranslateService,
    ) {}

    /**
     * Öffnet den Dateiupload
     *
     * @param {IUploadContext} context Uploadkontext
     */
    public openFileUpload(context: IUploadContext): void {
        const dialogRef = this.dialog.open(CalculationExamplesDialogComponent, {
            minWidth: '70%',
            data: context,
        });

        dialogRef.afterClosed().subscribe((newFiles: IUploadList[]) => {
            if (newFiles === undefined) {
                return;
            }

            context.lists = newFiles;
            this.cRef.markForCheck();
        });
    }

    /**
     * Öffnet eine Datei zur Ansicht
     *
     * @param {IUploadDocument} document Document
     * @returns {Promise} Promise
     */
    public openFile(document: IUploadDocument): Promise<void> {
        return HelperService.openFileFromBlob(document.file);
    }

    /**
     * Entfernt eine Datei
     *
     * @param {IUploadDocument[]} list Liste
     * @param {IUploadDocument} element Datei
     */
    // eslint-disable-next-line class-methods-use-this
    public removeFile(list: IUploadDocument[], element: IUploadDocument): void {
        if (element.alreadyExists) {
            this.waiterService.show();

            this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.financing)
                .pipe(
                    mergeMap(financing => {
                        if (financing === undefined) {
                            return throwError(() => this.translate.instant('financing.features.financing-processing.process.errorMissingFinancing'));
                        }

                        switch (element.type) {
                            case DocumentType.SampleCalculation:
                            case DocumentType.SampleCalculationVariable:
                                return this.documentService.deleteSampleCalculation({
                                    id: element.fileId ?? '',
                                    mapId: element.parentId ?? '',
                                });
                            case DocumentType.HouseholdBalanceSignature:
                            case DocumentType.CompositionOfOtherIncomeSignature:
                                return this.documentService.deleteHouseholdFile({
                                    id: element.fileId ?? '',
                                    mapId: financing.id,
                                    documentType: element.type,
                                });
                            case DocumentType.SelfDisclosure:
                                return this.documentService.deleteSelfDisclosure({
                                    id: element.fileId ?? '',
                                    mapId: financing.id,
                                });
                            case DocumentType.ESIS:
                                throw new Error('Deprecated!. The upload-tab is not able do delete esis files in the new workflow anymore.');
                            default:
                                return throwError(() => this.translate.instant('financing.features.financing-processing.process.errorDocumentType'));
                        }
                    },
                    )).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        list.splice(list.indexOf(element), 1);
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notification.alert(
                            this.translate.instant('general.error'),
                            this.translate.instant('financing.features.financing-processing.process.errorDocument'),
                        );
                    },
                });

            this.documentService.deleteSampleCalculation({
                id: element.fileId ?? '',
                mapId: element.parentId ?? '',
            });
        } else {
            list.splice(list.indexOf(element), 1);
        }

    }
}
