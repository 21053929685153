import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import {
    DocumentService,
    EntityClassType,
    FinancingService,
    FinancingState,
    IDebitor,
    IDocument,
    IFile,
    IFinancing,
    IFinancingStateParentDefinition,
    IFinprocessContainer,
    IHousehold,
    IUploadContext,
    IUploadList,
    OverwriteValueClassType,
    ValueStorageType,
} from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { FinancingStatus, HelperService } from 'app/modules/shared';
import { SharedService } from 'app/modules/shared/services/shared.service';
import { sort } from 'fast-sort';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    concatMap,
    filter,
    from,
    map,
    mergeMap,
    of,
    take,
    takeUntil,
    tap,
} from 'rxjs';

import { CalculationExamplesDialogComponent } from '../calculation-examples-dialog/calculation-examples-dialog.component';

/**
 * IHouseholdItem
 */
export interface IHouseholdItem {
    household: IHousehold;
    pos: number;
}
/**
 * Komponente für de Tab Haushalt
 */
@Component({
    selector: 'finprocess-household',
    templateUrl: './household.component.html',
    styleUrls: ['./household.component.scss'],
})
export class HouseholdComponent implements OnInit, OnDestroy {
    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public sustainableIncomeReadonly$!: Observable<(debitor: IDebitor) => boolean>;

    /**
     * VisibilityMap für die Anzeige der felder
     */
    public visibilityMap?: IHouseholdVisbilityMap;

    /**
     * ausgewählter Haushalt
     */
    public selectedHousehold = new BehaviorSubject<IHouseholdItem | undefined>(undefined);

    public overviewSelected?: boolean = true;

    private calculationService = new DebitorCalculationService();

    private financingId: string | undefined;

    public entityTypeDebtor = EntityClassType.Debitor;

    //--------------------------------------------------------------------------
    //                      O B S E R V A L B E L S
    //--------------------------------------------------------------------------

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();


    /**
     * Haushalte
     *
     * @returns {Observable<IHouseholdItem[]>} Observable Haushalte sortiert
     */
    public households$!: Observable<IHouseholdItem[]>;

    /**
     * Kreditnehmer
     *
     * @returns {Observable<IDebitor[]>} Observable Kreditnehmer sortiert
     */
    public debitors$!: Observable<IDebitor[]>;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Finprocess Container
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer)
    public finprocessContainer$!: Observable<IFinprocessContainer | undefined>;

    /**
     * Sicherheitsreserve
     */
    @Select(FinancingState.reserve)
    public reserve$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Zumutbare Kreditrate
     */
    @Select(FinancingState.freeAccessibleIncome)
    public freeAccessibleIncome$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Alimente für alle Haushalte
     */
    @Select(FinancingState.otherCostsTotal)
    public otherCostsTotal$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Neue Raten Förderung/Bauspardarlehen
     */
    @Select(FinancingState.newMonthlyRates)
    public newMonthlyRates$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * bleibende Kredit-/Leasingraten
     */
    @Select(FinancingState.monthlyRateNotCoveredLiabilities)
    public monthlyRateNotCoveredLiabilities$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Zumutbare Kreditrate
     */
    @Select(FinancingState.acceptableCreditRate)
    public acceptableCreditRate$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Summe der gemeinsamen Einnahmen für alle Haushalte
     */
    @Select(FinancingState.incomeTotal)
    public incomeTotal$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Fiktive Rate
     */
    @Select(FinancingState.fictionalRateAmount)
    public fictionalRateAmount$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Summe der Haushaltsausgaben für alle Haushalte
     */
    @Select(FinancingState.costsTotal)
    public costsTotal$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Summe der Haushaltsausgaben für alle Haushalte
     */
    @Select(FinancingState.remainingTotal)
    public remainingTotal$!: Observable<(withOverwrites?: boolean) => number>

    /**
     * Wirtschaftlich führender Kreditnehmer
     */
    @Select(FinancingState.economicLeader)
    public economicLeader$!: Observable<IDebitor | undefined>

    public incomePerHousehold$!: Observable<(withOverwrites?: boolean) => number>

    public costsPerHousehold$!: Observable<(withOverwrites?: boolean) => number>

    public remainingPerHousehold$!: Observable<(withOverwrites?: boolean) => number>

    public fileUploadInProgress = new BehaviorSubject<boolean>(false);

    /**
     * check financing status to enable/disbale sustainable buttons
     */
    public statusEnable = new BehaviorSubject<boolean>(false);

    public calculatorUrl: string | undefined;

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService documentService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ChangeDetectorRef} changeDetection change detection
     * @param {MatDialog} dialog MatDialog
     * @param {SharedService} sharedService SharedService
     */
    public constructor(
        private store: Store,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        private changeDetection: ChangeDetectorRef,
        private dialog: MatDialog,
        private sharedService: SharedService,
    ) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
        this.sustainableIncomeReadonly$ = combineLatest([this.fieldReadonly$, this.fileUploadInProgress]).pipe(map(([fieldReadonly, uploadInProgress]) => debitor => !this.getExistingSustainableFile(debitor) || fieldReadonly || uploadInProgress))
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.documents$ = this.selectedHousehold.pipe(
            takeUntil(this.onDestroy$),
            map(selectedHousehold => (documents: IDocument[]) => documents.filter(document => {
                if (document.parentId === selectedHousehold?.household.id) {
                    return true;
                }

                return selectedHousehold?.household.debitors.some(deb => deb.id === document.parentId && document.type === DocumentType.SustainableIncome);

            })),
        );

        this.households$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(it => {
                if (it === undefined) {
                    return [];
                }

                const temp: IHouseholdItem[] = [];

                it.households.forEach(household => temp.push({
                    household: household,
                    isSelected: false,
                    pos: household.position,
                } as IHouseholdItem))

                const sorted = sort(temp).asc(b => b.household.position)

                if (this.selectedHousehold.value !== undefined) {
                    this.selectedHousehold.next(sorted.find(household => household.household.id === this.selectedHousehold.value?.household.id));
                }

                return sorted;
            }),
            tap(() => {
                if (this.selectedHousehold.value !== undefined) {
                    this.updateVisibilityMap(this.selectedHousehold.value);
                }
            }),
        );

        this.debitors$ = this.selectedHousehold.pipe(
            map(selectedHousehold => {
                const debitors = selectedHousehold?.household.debitors ?? [];
                return sort(debitors).desc(debitor => this.calculationService.netIncomeTotal({ netIncome: debitor.netIncome, fourteenSalariesPerYear: debitor.fourteenSalariesPerYear, otherIncome: debitor.otherIncome }));
            }));

        this.incomePerHousehold$ = combineLatest([this.store.select(FinancingState.incomePerHousehold), this.selectedHousehold]).pipe(
            takeUntil(this.onDestroy$),
            map(([incomePerHouseholdFn, household]) => {
                if (!household?.household.id) {
                    return () => 0;
                }

                return (withOverwrites?: boolean) => incomePerHouseholdFn(household.household.id, withOverwrites);
            }),
        );

        this.costsPerHousehold$ = combineLatest([this.store.select(FinancingState.costsPerHousehold), this.selectedHousehold]).pipe(
            takeUntil(this.onDestroy$),
            map(([costsPerHouseholdFn, household]) => {
                if (!household?.household.id) {
                    return () => 0;
                }

                return (withOverwrites?: boolean) => costsPerHouseholdFn(household.household.id, withOverwrites);
            }),
        );

        this.remainingPerHousehold$ = combineLatest([this.store.select(FinancingState.remainingPerHousehold), this.selectedHousehold]).pipe(
            takeUntil(this.onDestroy$),
            map(([remainingPerHouseholdFn, household]) => {
                if (!household?.household.id) {
                    return () => 0;
                }

                return (withOverwrites?: boolean) => remainingPerHouseholdFn(household.household.id, withOverwrites);
            }),
        );

        this.finprocessContainer$.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(finprocessContainer => {
            this.financingId = finprocessContainer?.id;

            this.statusEnable.next(finprocessContainer?.status !== FinancingStatus.Open);
        });

        this.calculatorUrl = this.store.selectSnapshot(SystemConfigurationState.getSystemConfiguration)(SystemConfigurationType.BruttoNettoCalculatorUrl) as string;
    }

    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Haushalt angeklickt
     *
     * @param {IHouseholdItem} household Haushalt
     */
    public householdClicked(household: IHouseholdItem): void {
        this.selectedHousehold.next(household);
        this.overviewSelected = false;
        this.updateVisibilityMap(household);
    }


    /**
     * Hochrechnung auf 12 Monate
     *
     * @param {IDebitor} debtor Debtor
     * @returns {() => number} Summe
     */
    public calculateMonthlyNetIncome(debtor: IDebitor): Observable<() => number> {
        return combineLatest([this.store.select(FinancingState.calculateSustainableMonthlyNetIncome), this.selectedHousehold])
            .pipe(
                map(([calculationFunction, selectedHousehold]) => {
                    if (!selectedHousehold?.household.id) {
                        return () => 0;
                    }
                    return () => calculationFunction(selectedHousehold.household.id, debtor.id);
                }),
            );
    }


    /**
     * Summe nachhaltige monatlichen Einnahme
     *
     * @param {IDebitor} debtor Debtor
     * @returns {() => number} Summe
     */
    public calculateTotalSustainable(debtor: IDebitor): Observable<() => number> {
        return combineLatest([this.store.select(FinancingState.calculateSustainableTotalIncome), this.selectedHousehold])
            .pipe(
                map(([calculationFunction, selectedHousehold]) => {
                    if (!selectedHousehold?.household.id) {
                        return () => 0;
                    }

                    return () => calculationFunction(selectedHousehold.household.id, debtor.id);
                }),
            );
    }

    /**
     * Haushaltsübersicht angeklickt
     */
    public householdOverviewClicked(): void {
        this.overviewSelected = true;
        this.selectedHousehold.next(undefined);
    }

    /**
     * Aktualisiert die Sichtbarkeiten von Feldern
     *
     * @param {IHouseholdItem} household Haushalt
     */
    private updateVisibilityMap(household: IHouseholdItem): void {
        this.visibilityMap = {
            debitors: household.household.debitors.map(debitor => this.getDebitorVisibilityMap(debitor)),
        };
    }

    /**
     * Gibt die VisibilityMap für einen Debitor zurück
     *
     * @param {IDebitor} debitor Kreditnehmer
     * @returns {IDebitorVisibilityMap} VisibilityMap
     */
    // eslint-disable-next-line class-methods-use-this
    private getDebitorVisibilityMap(debitor: IDebitor): IDebitorVisibilityMap {
        const otherIncome = OverwriteHelperService.getMergedOverwriteValue(debitor, 'otherIncome', debitor.otherIncome);
        const otherIncomeContainsAlimony = OverwriteHelperService.getMergedOverwriteValue(debitor, 'otherIncomeContainsAlimony', debitor.otherIncomeContainsAlimony);

        return {
            notesIncome: otherIncome !== undefined && otherIncome > 0,
            otherIncomeContainsAlimony: otherIncome !== undefined && otherIncome > 0,
            notesOtherIncome: otherIncomeContainsAlimony ?? false,
        };
    }

    /**
     * Gibt vorhanden Nachhaltiges Einkommen beleg zurück
     *
     * @param {IDebitor} debtor Debtor
     * @returns {IFile | undefined} vorhanden Nachhaltiges Einkommen
     */
    public getExistingSustainableFile(debtor: IDebitor): IFile[] | undefined {
        //debtor hat immer nur einen Nachhaltigesbeleg
        return debtor.documents.filter(doc => doc.type === DocumentType.SustainableIncome).map(doc => doc?.files)?.reduce((acc, files) => acc?.concat(files), [])
    }


    /**
     * Öffnet Datei zur Anzeige
     *
     * @param {File} file Datei
     */
    public openFile(file: IFile):void {
        this.waiterService.show();
        this.sharedService.loadFile(file.id)
            .subscribe((fileBase64: string | undefined) => {
                this.sharedService.openFile({
                    id: file.id,
                    mimeType: file.mimeType,
                    base64: fileBase64,
                    name: file.name,
                    extension: file.extension,
                } as IFile);
                this.waiterService.hide();
            },
            );
    }

    /**
     * Lädt Nachhaltiges Einkommen beleg
     *
     * @param {IFile} file IFile
     */
    public sustainableDocumentDownload(file: IFile): void {
        this.waiterService.show();
        if (!!file) {
            this.documentService.loadFile(file?.id).pipe(
                take(1),
            ).subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                    if (blob) {
                        await HelperService.downloadFileFromBlob(blob, file.name);
                    }

                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide(),
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            })
        }
    }

    /**
     * Löscht einen Nachhaltiges Beleg
     *
     * @param {string} debtorId Debtor
     * @param {IFile} file IFile
     */
    public sustainableDocumentDelete(debtorId: string, file: IFile): void {

        if (!!file && !!this.financingId) {
            this.notification.confirmYesNo(
                this.translate.instant('financing.features.financing-processing.debitor.deleteLasting'),
                this.translate.instant('financing.features.financing-processing.debitor.deleteLastingMsg'),
            ).pipe(
                tap(() => { this.waiterService.show(); this.fileUploadInProgress.next(false); }),
                mergeMap(result => {
                    if (result === 'submit') {
                        return this.documentService.deleteDebtorFile({ fileId: file?.id, debtorId: debtorId, mapId: this.financingId as string, documentType: DocumentType.SustainableIncome })
                    }
                    return of(void 0);

                }),
            ).subscribe({
                next: res => {
                    if (!!res) {
                        this.notification.toast(this.translate.instant('financing.features.financing-processing.debitor.lastingDeleted'))
                    }
                    this.waiterService.hide();
                    this.fileUploadInProgress.next(false);
                    this.changeDetection.detectChanges();
                },
                error: () => {
                    this.waiterService.hide();
                    this.fileUploadInProgress.next(false);
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            });

        }
    }
    /**
     * Öffnet den Multiupload
     *
     * @param {string} debtorId debtorId
     */
    public uploadMultiFiles(debtorId: string): void {
        const uploadContext: IUploadContext = {
            title: 'Nachhaltiges Einkommen',
            buttonLabel: '',
            parentId: debtorId,
            lists: [
                { list: [], type: DocumentType.SustainableIncome },
            ],
        };

        const dialogRef = this.dialog.open(CalculationExamplesDialogComponent, {
            minWidth: '70%',
            data: uploadContext,
        });

        dialogRef.afterClosed().pipe(
            take(1),
            tap(() => this.waiterService.show()),
            map((newFiles: IUploadList[]) => {
                if (!!newFiles && newFiles.length > 0) {
                    const requests: Observable<IDocument | undefined>[] = [];

                    newFiles[0].list.forEach(uploadDocument => {
                        requests.push(this.documentService.uploadDebitorFile({
                            debitorId: debtorId,
                            file: uploadDocument.file,
                            mapId: this.financingId as string,
                            type: DocumentType.SustainableIncome,
                        }),
                        )
                    });
                    return requests;
                } else {
                    return undefined
                }

            }),
            filter((requests): requests is Observable<IDocument | undefined>[] => Array.isArray(requests)),
            mergeMap(requests =>
                // Using `from` to emit each request observable one by one and `concatMap` to process them sequentially
                from(requests).pipe(
                    concatMap(request => request),
                ),
            ),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.notification.toast('Belege für geprüftes Einkommen wurden erfolgreich hochgeladen.');
                this.fileUploadInProgress.next(false);
                this.changeDetection.detectChanges();
            },
            error: () => {
                this.waiterService.hide(),
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                this.fileUploadInProgress.next(false);
            },
            complete: () => this.waiterService.hide(),
        });
    }
}

interface IHouseholdVisbilityMap {
    debitors: IDebitorVisibilityMap[];
}

interface IDebitorVisibilityMap {
    notesIncome: boolean;
    otherIncomeContainsAlimony: boolean;
    notesOtherIncome: boolean;
}
