export enum AssignmentType {
    /**
     * Abtretung
     */
     Assignment = 1,

    /**
     * Verpfändung
     */
     Pledge = 2
}
