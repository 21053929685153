export enum PeriodicityType {
    /**
     *  monatlich
     */
    Monthly = 1,

    /**
     * vierteljährlich
     */
    Quarterly = 3,

    /**
     *  halbjährlich
     */
    Halfyearly = 6,

    /**
     * jährlich
     */
    Yearly = 12,
}
