import { IUser } from 'app/modules/auth/data';

import { ITemporaryUserViewmodel } from '../../interfaces';


/**
 * Aktion bei erfolgreichem Laden Vertretenden
 */
export class AssignableTemporaryEditorListLoaded {
    public static readonly type = 'substitution.assignableTemporaryEditorListLoaded';

    /**
     * Konstruktor
     *
     * @param {IUser[]} payload TODO
     */
    public constructor(public payload: IUser[]) { }
}

/**
 * Aktion bei erfolgreichem Laden Vertretenden und Vertreter
 */
export class AssignedAsTemporaryEditorListLoaded {
    public static readonly type = 'substitution.assignedAsTemporaryEditorListLoaded';

    /**
     * Konstruktor
     *
     * @param {ITemporaryUserViewmodel[]} payload TODO
     */
    public constructor(public payload: ITemporaryUserViewmodel[]) { }
}

/**
 * Aktion beim setzt Vertretung
 */
export class UserSubstitutionConfirmed {
    public static readonly type = 'substitution.userSubstitutionConfirmed';

    /**
     * Konstruktor
     *
     * @param {string} payload substituted ID
     * @param {string} payload2 substitute ID
     */
    public constructor(public payload: string, public payload2?: string) { }
}

/**
 * Aktion beim entfernt Vertretung
 */
export class UserSubstitutionRemoved {
    public static readonly type = 'substitution.userSubstitutionRemoved';

    /**
     * Konstruktor
     *
     * @param {string} payload substituted ID
     * @param {string} payload2 substitute ID
     */
    public constructor(public payload: string, public payload2?: string) { }
}
