<ng-template #financingScopePropertyTemplate let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ 'financing.features.financing-processing.productPackages.texts.' + labelKey | translate }}</span>
        <span *ngIf="property" value>{{ property | formatCurrency }}</span>
        <span *ngIf="property === undefined || property === 0" value>{{
            'financing.features.financing-processing.productPackages.texts.unknown' | translate
        }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyValueTemplate let-product="product" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ 'financing.features.financing-processing.productPackages.texts.' + labelKey | translate }}</span>
        <span value>{{
            product.controls[property].value !== undefined && product.controls[property].value !== null ? product.controls[property].value : '-'
        }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyCurrencyValueTemplate let-product="product" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ 'financing.features.financing-processing.productPackages.texts.' + labelKey | translate }}</span>
        <span value>{{
            product.controls[property].value !== undefined && product.controls[property].value !== null
                ? (product.controls[property].value | formatCurrency)
                : '-'
        }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyPercentValueTemplate let-product="product" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ 'financing.features.financing-processing.productPackages.texts.' + labelKey | translate }}</span>
        <span value>{{
            product.controls[property].value !== undefined && product.controls[property].value !== null
                ? (product.controls[property].value | number : '1.3-3') + '%'
                : '-'
        }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyBooleanValueTemplate let-product="product" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ 'financing.features.financing-processing.productPackages.texts.' + labelKey | translate }}</span>
        <span value>{{ product.controls[property].value ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
    </finprocess-label-value>
</ng-template>

@if(!loading) {
<div class="w-100">
    <div class="financing-scope" fxLayout="row" fxLayoutAlign="center">
        <finprocess-label-value>
            <span label>{{ 'financing.features.financing-processing.productPackages.texts.creditAmountLongTerm' | translate }}</span>
            <span *ngIf="grossFinancingRequirement || grossFinancingRequirement === 0" value>{{ grossFinancingRequirement | formatCurrency }}</span>
            <span *ngIf="grossFinancingRequirement === undefined" value>{{
                'financing.features.financing-processing.productPackages.texts.unknown' | translate
            }}</span>
        </finprocess-label-value>
        <ng-container
            *ngTemplateOutlet="financingScopePropertyTemplate; context: { labelKey: 'creditAmountShortTerm', property: comfortCreditPlus }"></ng-container>
        <ng-container
            *ngTemplateOutlet="financingScopePropertyTemplate; context: { labelKey: 'bridgingFinancing', property: bridgingFinancing }"></ng-container>
        <ng-container *ngTemplateOutlet="financingScopePropertyTemplate; context: { labelKey: 'guarantee', property: guaranteeAmount }"></ng-container>
        <ng-container *ngTemplateOutlet="financingScopePropertyTemplate; context: { labelKey: 'totalCommitment', property: totalCommitment }"></ng-container>
    </div>

    <div fxLayout="row" fxLayoutAlign="end">
        <button
            [disabled]="(fieldReadonly$ | async) ?? true"
            mat-raised-button
            color="primary"
            class="add-product-btn mt-4 mb-4"
            (click)="openProductPackageDialog(true)"
            style="width: fit-content">
            {{ 'financing.features.financing-processing.productPackages.texts.addProductPackage' | translate }}
        </button>
    </div>

    <div *ngFor="let productPackage of productPackageForms; let productPackageIndex = index; trackBy: trackByFn; let last = last"
        #addedproductpackages [formGroup]="productPackage" class="mt-2 mb-2">
        <ucba-heading [expandable]="true">
            <div ucba-heading-title>
                <div>{{ 'financing.features.financing-processing.productPackages.texts.productPackage' | translate }} {{ productPackageIndex + 1 }}</div>
                <div class="product-package-status" fxLayout="row" fxLayoutAlign="flex-start center">
                    <ng-container [ngSwitch]="productPackage.controls.status.value ?? 100">
                        <mat-icon *ngSwitchCase="ProductPackageStatus.Editing" color="primary">edit</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.DecisionFailed" class="error">local_fire_department</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.DecisionSuccesful" class="success">local_fire_department</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalAwaiting" color="primary">hourglass_empty</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalRejected" class="warn">contact_support</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalDenied" class="error">error</mat-icon>
                        <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalGranted" class="success">check_circle</mat-icon>
                        <mat-icon *ngSwitchDefault>edit</mat-icon>
                    </ng-container>
                    &nbsp;
                    <span class="product-package-status-label">{{
                        productPackage.controls.status.value ?? 100 | finprocessEnumTranslation : 'ProductPackageStatus'
                    }}</span>
                </div>
            </div>
            <div class="package-name">
                @if((fieldReadonly$ | async) === false) {
                <mat-form-field class="mat-form-field-transparent-background">
                    <mat-label *ngIf="!productPackage.controls.name.value">{{
                        'financing.features.financing-processing.productPackages.texts.packageName' | translate
                    }}</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
                } @else {
                {{ productPackage.controls.name.value }}
                }
            </div>
            <div ucba-heading-actions fxLayout="row" fxLayoutAlign="center center">
                <button
                    mat-icon-button
                    [matTooltip]="'financing.features.financing-processing.productPackages.texts.tooltipDeleteProductPackage' | translate"
                    (click)="deleteProductPackage(productPackage.controls.productPackageID.value)"
                    class="header-button delete-product"
                    [disabled]="(fieldReadonly$ | async) ?? true">
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [matTooltip]="'financing.features.financing-processing.productPackages.texts.tooltipCopyProductPackage' | translate"
                    (click)="copyProductPackage(productPackage.controls.productPackageID.value)"
                    class="header-button config-product"
                    [disabled]="(fieldReadonly$ | async) ?? true">
                    <mat-icon>file_copy</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [matTooltip]="'financing.features.financing-processing.productPackages.texts.tooltipAddDescription' | translate"
                    (click)="openDescriptionDialog(productPackage.getRawFinprocessValue())"
                    class="header-button config-product mr-4"
                    [disabled]="(fieldReadonly$ | async) ?? true">
                    <mat-icon>description</mat-icon>
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    [disabled]="productPackage.invalid"
                    (click)="openRiskDecision(productPackage.controls.productPackageID.value)"
                    [matTooltip]="'financing.features.financing-processing.productPackages.texts.tooltipOpenProductPackage' | translate"
                    [matTooltipDisabled]="productPackage.valid">
                    {{ 'financing.features.financing-processing.productPackages.texts.openProductPackage' | translate }}
                </button>
            </div>
            <div ucba-heading-body>
                <mat-tab-group
                    [(selectedIndex)]="selectedProductTabIndexes[productPackageIndex]"
                    mat-stretch-tabs="false"
                    [animationDuration]="tabAnimationDuration">
                    <mat-tab *ngFor="let product of productPackage.controls.assignProducts.controls; let productIndex = index">
                        <ng-template mat-tab-label>
                            {{ product.controls.position.value }}.
                            <span *ngIf="product.controls.productCreditType.value !== null">{{
                                product.controls.productCreditType.value | finprocessEnumTranslation : 'ProductPackageType'
                            }}</span>
                            <button
                                *ngIf="(fieldReadonly$ | async) === false"
                                mat-mini-fab
                                class="remove-product ml-2"
                                (click)="deleteProduct(product.controls.productID.value, productPackageIndex, productIndex)"
                                [disabled]="(fieldReadonly$ | async) ?? true">
                                <mat-icon class="circle-icon remove">remove</mat-icon>
                            </button>
                        </ng-template>
                        <form [formGroup]="product">
                            <!-- Kreditanfrage -->
                            <ucba-heading [type]="'medium'">
                                <div ucba-heading-title>{{ 'financing.features.financing-processing.productPackages.texts.creditRequest' | translate }}</div>
                                <div ucba-heading-body-visible>
                                    <ucba-tile-layout [columns]="4">
                                        <!-- Kreditbetrag -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.creditAmount">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.creditAmount' | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    name="creditAmount"
                                                    currencyMask
                                                    [options]="currencyMaskOptions"
                                                    formControlName="creditAmount" />
                                                <mat-hint
                                                    class="title-warning"
                                                    *ngIf="
                                                        !!productPackage.errors?.creditSum &&
                                                        product.controls.productCreditType.value === ProductPackageType.Credit
                                                    ">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.financingScopeWarning' | translate }}
                                                </mat-hint>
                                                <mat-hint
                                                    class="title-warning"
                                                    *ngIf="
                                                        !!productPackage.errors?.comfortCreditSum &&
                                                        product.controls.productCreditType.value === ProductPackageType.ComfortCredit
                                                    ">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.financingScopeWarning' | translate }}
                                                </mat-hint>
                                                <mat-hint
                                                    class="title-warning"
                                                    *ngIf="
                                                        !!productPackage.errors?.interimFinancingSum &&
                                                        (product.controls.productCreditType.value === ProductPackageType.BWWithFollowUpFinancing ||
                                                            product.controls.productCreditType.value === ProductPackageType.BWWithoutFollowUpFinancing)
                                                    ">
                                                    {{
                                                        'financing.features.financing-processing.productPackages.texts.financingScopeWarning' | translate
                                                    }}</mat-hint
                                                >
                                                <mat-error *ngIf="!!product.controls.creditAmount.errors">
                                                    {{ product.controls.creditAmount.errors | finProcessFormError }}
                                                </mat-error>
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyCurrencyValueTemplate;
                                                    context: { product: product, labelKey: 'creditAmount', property: 'creditAmount' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Garantiebetrag -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.guaranteeAmount">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label fxLayoutAlign="flex-start center">
                                                    <span>{{
                                                        'financing.features.financing-processing.productPackages.texts.guaranteeAmount' | translate
                                                    }}</span>
                                                    <mat-icon
                                                        color="primary"
                                                        class="info-icon ml-1"
                                                        [matTooltip]="
                                                            'financing.features.financing-processing.productPackages.texts.tooltipGuaranteeAmount' | translate
                                                        ">
                                                        info
                                                    </mat-icon>
                                                </mat-label>
                                                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="guaranteeAmount" />
                                                <mat-hint
                                                    class="title-warning"
                                                    *ngIf="
                                                        !!productPackage.errors?.guaranteeSum &&
                                                        product.controls.productCreditType.value === ProductPackageType.Guarantee
                                                    ">
                                                    {{
                                                        'financing.features.financing-processing.productPackages.texts.financingScopeWarningGuarantee'
                                                            | translate
                                                    }}
                                                </mat-hint>
                                                <mat-error *ngIf="product.controls.guaranteeAmount.errors"
                                                    >{{ product.controls.guaranteeAmount.errors | finProcessFormError }}
                                                </mat-error>
                                            </mat-form-field>
                                            } @else {
                                            <finprocess-label-value>
                                                <div label fxLayoutAlign="flex-start center">
                                                    <span>{{
                                                        'financing.features.financing-processing.productPackages.texts.guaranteeAmount' | translate
                                                    }}</span>
                                                    <mat-icon
                                                        color="primary"
                                                        class="info-icon ml-1"
                                                        [matTooltip]="
                                                            'financing.features.financing-processing.productPackages.texts.tooltipGuaranteeAmount' | translate
                                                        ">
                                                        info
                                                    </mat-icon>
                                                </div>
                                                <div value>
                                                    {{
                                                        product.controls.guaranteeAmount.value !== undefined && product.controls.guaranteeAmount.value !== null
                                                            ? (product.controls.guaranteeAmount.value | formatCurrency)
                                                            : '-'
                                                    }}
                                                </div>
                                            </finprocess-label-value>
                                            }
                                        </ng-template>
                                        <!-- Hiervon tilgungsfreier Zeitraum -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.interestOnlyPeriod">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.interestOnlyPeriod' | translate
                                                }}</mat-label>
                                                <input matInput currencyMask [options]="numberMaskOptions" formControlName="interestOnlyPeriod" />
                                                <mat-hint style="margin-bottom: 50px" *ngIf="(product.controls.interestOnlyPeriod.value ?? 0) > 408">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.interestOnlyPeriodWarning' | translate }}
                                                </mat-hint>
                                                <mat-error *ngIf="!!product.controls.interestOnlyPeriod.errors">
                                                    {{ product.controls.interestOnlyPeriod.errors | finProcessFormError }}
                                                </mat-error>
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyValueTemplate;
                                                    context: { product: product, labelKey: 'interestOnlyPeriod', property: 'interestOnlyPeriod' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Gesamtlaufzeit ohne ASD Periode -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.durationWithoutASD">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label
                                                    >{{
                                                        'financing.features.financing-processing.productPackages.texts.durationWithoutASD' | translate
                                                    }}
                                                    *</mat-label
                                                >
                                                <input matInput currencyMask [options]="numberMaskOptions" formControlName="durationWithoutASD" />
                                                <mat-hint *ngIf="(product.controls.durationWithoutASD.value ?? 0) > 408">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.durationWarning' | translate }}
                                                </mat-hint>
                                                <mat-error *ngIf="!!product.controls.durationWithoutASD.errors">
                                                    {{ product.controls.durationWithoutASD.errors | finProcessFormError }}
                                                </mat-error>
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyValueTemplate;
                                                    context: { product: product, labelKey: 'durationWithoutASD', property: 'durationWithoutASD' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Zusätzliche ASD Periode -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.extraASDPeriod">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.extraASDPeriod' | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    name="extraASDPeriod"
                                                    currencyMask
                                                    [options]="numberMaskOptionsASD"
                                                    formControlName="extraASDPeriod" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyValueTemplate;
                                                    context: { product: product, labelKey: 'extraASDPeriod', property: 'extraASDPeriod' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Sonderfinanzierung -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.specialFinancing">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label fxLayoutAlign="flex-start center">
                                                    <span>{{
                                                        'financing.features.financing-processing.productPackages.texts.specialFinancing' | translate
                                                    }}</span>
                                                    <mat-icon
                                                        color="primary"
                                                        class="info-icon ml-1"
                                                        [matTooltip]="
                                                            'financing.features.financing-processing.productPackages.texts.tooltipSpecialFinancing' | translate
                                                        ">
                                                        info
                                                    </mat-icon>
                                                </mat-label>
                                                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="specialFinancing" />
                                                <mat-hint
                                                    *ngIf="(product.controls.specialFinancing.value ?? 0) > (product.controls.guaranteeAmount.value ?? 0)"
                                                    class="guarantee-warning">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.guaranteeWarning' | translate }}
                                                </mat-hint>
                                            </mat-form-field>
                                            } @else {
                                            <finprocess-label-value>
                                                <div label fxLayoutAlign="flex-start center">
                                                    <span>{{
                                                        'financing.features.financing-processing.productPackages.texts.specialFinancing' | translate
                                                    }}</span>
                                                    <mat-icon
                                                        color="primary"
                                                        class="info-icon ml-1"
                                                        [matTooltip]="
                                                            'financing.features.financing-processing.productPackages.texts.tooltipSpecialFinancing' | translate
                                                        ">
                                                        info
                                                    </mat-icon>
                                                </div>
                                                <div value>
                                                    {{
                                                        product.controls.specialFinancing.value !== undefined &&
                                                        product.controls.specialFinancing.value !== null
                                                            ? (product.controls.specialFinancing.value | formatCurrency)
                                                            : '-'
                                                    }}
                                                </div>
                                            </finprocess-label-value>
                                            }
                                        </ng-template>
                                        <!-- Laufzeit für die Sonderfinanzierung -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.specialFinancingDuration">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.durationSpecialFinancing' | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    name="specialFinancingDuration"
                                                    currencyMask
                                                    [options]="numberMaskOptions"
                                                    formControlName="specialFinancingDuration" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyValueTemplate;
                                                    context: { product: product, labelKey: 'durationSpecialFinancing', property: 'specialFinancingDuration' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Sonderfinanzierung LTV relevant? -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.specialFinancingLTV">
                                            @if((fieldReadonly$ | async) === false) {
                                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-start center">
                                                <mat-checkbox
                                                    class="mr-2"
                                                    color="primary"
                                                    formControlName="specialFinancingLTV"
                                                    (change)="blur($event)"></mat-checkbox>
                                                <mat-label class="checkbox-label">{{
                                                    'financing.features.financing-processing.productPackages.texts.specialFinancingLTV' | translate
                                                }}</mat-label>
                                            </div>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyBooleanValueTemplate;
                                                    context: { product: product, labelKey: 'specialFinancingLTV', property: 'specialFinancingLTV' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Summe -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.creditSpecialFinancingSum">
                                            <finprocess-label-value>
                                                <span label>{{ 'financing.features.financing-processing.productPackages.texts.sum' | translate }}</span>
                                                <span value>{{
                                                    (product.controls.creditAmount.value ?? 0) + (product.controls.specialFinancing.value ?? 0) | formatCurrency
                                                }}</span>
                                            </finprocess-label-value>
                                        </ng-template>
                                        <!-- Garantiesumme -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.guaranteeSum">
                                            <!--(Garantiebetrag - Sonderfinanzierung)-->
                                            <finprocess-label-value>
                                                <div label [class.edit-mode-margin-top]="(fieldReadonly$ | async) === false" fxLayoutAlign="flex-start center">
                                                    <span>{{ 'financing.features.financing-processing.productPackages.texts.guaranteeSum' | translate }}</span>
                                                    <mat-icon
                                                        class="info-icon ml-1"
                                                        color="primary"
                                                        [matTooltip]="
                                                            'financing.features.financing-processing.productPackages.texts.tooltipGuaranteeSum' | translate
                                                        ">
                                                        info
                                                    </mat-icon>
                                                </div>
                                                <div
                                                    value
                                                    [class.edit-mode-margin-bottom]="(fieldReadonly$ | async) === false"
                                                    [class.title-warning]="
                                                        (product.controls.specialFinancing.value ?? 0) > (product.controls.guaranteeAmount.value ?? 0)
                                                    ">
                                                    {{
                                                        (product.controls.guaranteeAmount.value ?? 0) - (product.controls.specialFinancing.value ?? 0)
                                                            | formatCurrency
                                                    }}
                                                </div>
                                            </finprocess-label-value>
                                        </ng-template>
                                    </ucba-tile-layout>
                                </div>
                            </ucba-heading>
                            <!-- Zinssätze -->
                            <ucba-heading [type]="'medium'" *ngIf="product.controls.productCreditType.value !== ProductPackageType.Guarantee">
                                <div ucba-heading-title>{{ 'financing.features.financing-processing.productPackages.texts.interestRates' | translate }}</div>
                                <div ucba-heading-body-visible>
                                    <ucba-tile-layout [columns]="4">
                                        <!-- Kim-V relevanter Echtzinssatz -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.kimVRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{ 'financing.features.financing-processing.productPackages.texts.kimV' | translate }}</mat-label>
                                                <input matInput name="kimVRate" currencyMask [options]="percentageMaskOptions" formControlName="kimVRate" />
                                                <mat-error *ngIf="!!product.controls.kimVRate.errors">
                                                    {{ product.controls.kimVRate.errors | finProcessFormError }}
                                                </mat-error>
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: { product: product, labelKey: 'kimV', property: 'kimVRate' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Variabler Echtzinssatz" -> Referenzzinssatz + Aufschlag -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.variableRealInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.variableRealInterestRate' | translate
                                                }}</mat-label>
                                                <input matInput currencyMask [options]="percentageMaskOptions" formControlName="variableRealInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: { product: product, labelKey: 'variableRealInterestRate', property: 'variableRealInterestRate' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Fiktiver EUR Zinssatz variabel -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.fictionalEURInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.fictionalEURInterestRate' | translate
                                                }}</mat-label>
                                                <input matInput currencyMask [options]="percentageMaskOptions" formControlName="fictionalEURInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: { product: product, labelKey: 'fictionalEURInterestRate', property: 'fictionalEURInterestRate' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Fiktiver Zinssatz fix 25 Jahre -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.fictionalInterestRateFix25Years">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.fictionalInterestRateFix25Years' | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    currencyMask
                                                    [options]="percentageMaskOptions"
                                                    formControlName="fictionalInterestRateFix25Years" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'fictionalInterestRateFix25Years',
                                                        property: 'fictionalInterestRateFix25Years'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- ohne Deckungsregister -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.withoutCoverageRegister">
                                            @if((fieldReadonly$ | async) === false) {
                                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-start center">
                                                <mat-checkbox
                                                    class="mr-2"
                                                    color="primary"
                                                    formControlName="withoutCoverageRegister"
                                                    (change)="blur($event)"></mat-checkbox>
                                                <mat-label
                                                    class="checkbox-label"
                                                    [ngClass]="{
                                                        'disabled-grey':
                                                            (product.controls.subsidizedLoan.value ?? true) && !product.controls.withoutCoverageRegister.value
                                                    }">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.withoutCoverageRegister' | translate }}
                                                </mat-label>
                                            </div>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyBooleanValueTemplate;
                                                    context: { product: product, labelKey: 'withoutCoverageRegister', property: 'withoutCoverageRegister' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Förderdarlehen -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.subsidizedLoan">
                                            @if((fieldReadonly$ | async) === false) {
                                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-start center">
                                                <mat-checkbox
                                                    class="mr-2"
                                                    color="primary"
                                                    formControlName="subsidizedLoan"
                                                    (change)="blur($event)"></mat-checkbox>
                                                <mat-label
                                                    class="checkbox-label"
                                                    [ngClass]="{
                                                        'disabled-grey':
                                                            (product.controls.withoutCoverageRegister.value ?? true) && !product.controls.subsidizedLoan.value
                                                    }">
                                                    {{ 'financing.features.financing-processing.productPackages.texts.subsidizedLoan' | translate }}
                                                </mat-label>
                                            </div>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyBooleanValueTemplate;
                                                    context: { product: product, labelKey: 'subsidizedLoan', property: 'subsidizedLoan' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender fiktiver Zinssatz (ohne Deckungsregister) -->
                                        <!-- depending on checkboxes "Ohne Deckungsregister" -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingFictionalInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field
                                                class="w-100 mat-form-field-transparent-background"
                                                [matTooltip]="
                                                    'financing.features.financing-processing.productPackages.texts.deviatingFictionalInterestRate' | translate
                                                "
                                                matTooltipPosition="above">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingFictionalInterestRate' | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    currencyMask
                                                    [options]="percentageMaskOptions"
                                                    formControlName="deviatingFictionalInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'deviatingFictionalInterestRate',
                                                        property: 'deviatingFictionalInterestRate'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender Zinssatz (Förderdarlehen) -->
                                        <!-- depending on checkboxes "Förderdarlehen" -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingInterestRateSubsidizedLoan">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingInterestRateSubsidizedLoan'
                                                        | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    [options]="percentageMaskOptions"
                                                    currencyMask
                                                    formControlName="deviatingInterestRateSubsidizedLoan" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'deviatingInterestRateSubsidizedLoan',
                                                        property: 'deviatingInterestRateSubsidizedLoan'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender Zinssatz (Aufschlag für Vergleichsrechnung) -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field
                                                class="w-100 mat-form-field-transparent-background"
                                                [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingInterestRate' | translate"
                                                matTooltipPosition="above">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingInterestRate' | translate
                                                }}</mat-label>
                                                <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: { product: product, labelKey: 'deviatingInterestRate', property: 'deviatingInterestRate' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender fiktiver Fixzinssatz über Gesamtlaufzeit -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingFictionalFixRateOverDuration">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixRateOverDuration'
                                                        | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    currencyMask
                                                    [options]="percentageMaskOptions"
                                                    formControlName="deviatingFictionalFixRateOverDuration" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'deviatingFictionalFixRateOverDuration',
                                                        property: 'deviatingFictionalFixRateOverDuration'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Vereinbarter Zinssatz für Anschluss-/Sonderfinanzierung -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.agreedInterestRateFollowUpOrSpecialFinancing">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field
                                                class="w-100 mat-form-field-transparent-background"
                                                [matTooltip]="
                                                    'financing.features.financing-processing.productPackages.texts.agreedInterestRateFollowUpOrSpecialFinancing'
                                                        | translate
                                                ">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.agreedInterestRateFollowUpOrSpecialFinancing'
                                                        | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    currencyMask
                                                    [options]="percentageMaskOptions"
                                                    formControlName="agreedInterestRateFollowUpOrSpecialFinancing" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'agreedInterestRateFollowUpOrSpecialFinancing',
                                                        property: 'agreedInterestRateFollowUpOrSpecialFinancing'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender EUR-Zinssatz -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingEURInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingEURInterestRate' | translate
                                                }}</mat-label>
                                                <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingEURInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: { product: product, labelKey: 'deviatingEURInterestRate', property: 'deviatingEURInterestRate' }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                        <!-- Abweichender fiktiver Fixzinssatz über Gesamtlaufzeit -->
                                        <ng-template ucba-tile *ngIf="product.visibilityMap?.deviatingFictionalFixInterestRate">
                                            @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{
                                                    'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixInterestRate'
                                                        | translate
                                                }}</mat-label>
                                                <input
                                                    matInput
                                                    name="deviatingFictionalFixInterestRate"
                                                    currencyMask
                                                    [options]="percentageMaskOptions"
                                                    formControlName="deviatingFictionalFixInterestRate" />
                                            </mat-form-field>
                                            } @else {
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    defaultReadonlyPercentValueTemplate;
                                                    context: {
                                                        product: product,
                                                        labelKey: 'deviatingFictionalFixInterestRate',
                                                        property: 'deviatingFictionalFixInterestRate'
                                                    }
                                                "></ng-container>
                                            }
                                        </ng-template>
                                    </ucba-tile-layout>
                                </div>
                            </ucba-heading>
                        </form>
                    </mat-tab>
                    <mat-tab disabled="true" *ngIf="(fieldReadonly$ | async) === false">
                        <ng-template mat-tab-label>
                            <button
                                mat-mini-fab
                                color="primary"
                                class="add-product-button"
                                (click)="$event.stopPropagation()"
                                (click)="
                                    openProductPackageDialog(
                                        false,
                                        productPackage.controls.productPackageID.value,
                                        productPackageIndex,
                                        productPackage.controls.assignProducts.length
                                    )
                                "
                                [disabled]="(fieldReadonly$ | async) ?? true">
                                <mat-icon class="circle-icon">add</mat-icon>
                            </button>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </ucba-heading>
    </div>
</div>
} @else {
<div class="waiter" fxFlex fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>
}
