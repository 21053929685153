<div
    *ngIf="state$ | async as state"
    class="container"
>
    <div
        *ngIf="state"
        class="wrapper"
    >
        <div class="menu" [class.collapsed]="isCollapsed">
            <div
                class="flex-grow-1 flex-center top"
                (click)="closeSmartdoc()"
            >
                <div *ngIf="!isCollapsed">
                    {{ 'smartdoc.smartdoc' | translate }}
                </div>
                <mat-icon>
                    close
                </mat-icon>
            </div>
            <div
                class="flex-grow-2 flex-center"
                routerLink="smartdocSelection"
                routerLinkActive="active"
                skipLocationChange="true"
            >
                <div *ngIf="!isCollapsed">
                    {{ 'smartdoc.dataSplitting' | translate }}
                </div>
                <mat-icon>
                    <span class="material-icons-outlined">
                        content_cut
                    </span>
                </mat-icon>
            </div>
            <div
                class="flex-grow-2 flex-center"
                routerLink="smartdocProcessing"
                routerLinkActive="active"
                skipLocationChange="true"
            >
                <div *ngIf="!isCollapsed">
                    {{ 'smartdoc.assignment' | translate }}
                </div>
                <mat-icon>
                    text_rotate_vertical
                </mat-icon>
            </div>
            <div class="flex-grow-1 flex-center" (click)="menuExpander()">
                <mat-icon *ngIf="isCollapsed">keyboard_arrow_right</mat-icon>
                <mat-icon *ngIf="!isCollapsed">keyboard_arrow_left</mat-icon>
            </div>
        </div>
        <div class="content"
        [class.collapsed]="isCollapsed"
        >
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
