import { ISmartDocStatusViewModel } from '../../interfaces/smartdoc';

/**
 * Klasse für Aktion
 */
export class LoadedJobStatus {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.loadedJobStatus';
    /**
     * Constructor
     * 
     * @param {ISmartDocStatusViewModel} payload Payload
     */
    public constructor(public payload: ISmartDocStatusViewModel) { }
}
