import { DivisionByZeroError } from '../errors';

/**
 * Service mit Hilfsmethoden für Berechnungen
 */
export class CalculationHelperService {

    /**
     * Rundet einen Wert
     *
     * @param {number} value Zu rundender Wert
     * @param {number | undefined} exp Anzahl der Nachkommastellen auf die gerundet werden soll
     * @returns {number} Gerundete Zahl
     */
    public static round(value: number, exp?: number): number {
        if (CalculationHelperService.isNullOrNaN(value)) {
            return NaN;
        }

        if (exp === undefined || exp === 0) {
            return Math.round(value as number);
        }
        else if (exp % 1 !== 0) {
            return NaN;
        }

        // Shift
        let valueArr = (value as number).toString().split('e');
        value = Math.round(+(`${valueArr[0]}e${valueArr.length > 1 ? (+valueArr[1] + exp) : exp}`));

        // Shift back
        valueArr = value.toString().split('e');
        return +(`${valueArr[0]}e${valueArr.length > 1 ? (+valueArr[1] - exp) : -exp}`);
    }

    /**
     * Prüft ob a größer als b ist
     * Prüfung in der Reihenfolge a - b
     * Wenn nicht übergeben, dann 0
     *
     * @param {number | null | undefined} a Wert 1
     * @param {number | null | undefined} b Wert 2
     * @returns {boolean} Ist a größer als b
     */
    public static isGreaterThan(a?: number | null, b?: number | null): boolean {
        return (!CalculationHelperService.isNullOrNaN(a) ? (a as number) : 0) - (!CalculationHelperService.isNullOrNaN(b) ? (b as number) : 0) > 0;
    }

    /**
     * Prüft ob Parameter eine Zahl ist
     * Auch NULL ist in dieser Prüfung keine Zahl
     *
     * @param {number | null | undefined} value prüfender Parameter
     * @returns {boolean} Ist übergebener Wert keine Zahl
     */
    public static isNullOrNaN(value?: number | null): value is null | undefined {
        return value === undefined || value === null || isNaN(value as number);
    }

    /**
     * Berechnet die Jahre von einem Datum zu einem Referenzdatum
     *
     * @param {Date} date Datum
     * @param {Date | undefined} refDate Referenzdatum
     * @returns {number} Jahre
     */
    public static calculateYears(date: Date, refDate?: Date): number {
        if (refDate === undefined) {
            refDate = new Date();
        }
        let age = refDate.getFullYear() - date.getFullYear();
        if (refDate.getMonth() < date.getMonth()) {
            age--;
        }
        if (refDate.getMonth() === date.getMonth() && refDate.getDate() < date.getDate()) {
            age--;
        }
        return age;
    }

    /**
     * Berechnet die Monate von einem Datum zu einem Referenzdatum
     *
     * @param {Date} date Datum
     * @param {Date | undefined} refDate Referenzdatum
     * @returns {number} Monate
     */
    public static calculateMonths(date: Date, refDate?: Date): number {
        if (refDate === undefined) {
            refDate = new Date();
        }
        let months = Math.abs(refDate.getFullYear() - date.getFullYear()) * 12;

        months -= (refDate > date ? date.getMonth() : refDate.getMonth());
        months += (refDate > date ? refDate.getMonth() : date.getMonth());

        if (refDate.getMonth() === date.getMonth() && refDate.getDate() < date.getDate()) {
            months--;
        }
        return months <= 0 ? 0 : months;
    }

    /**
     * Handler für Aufrufe, die {DivisionByZeroError} auslösen
     *
     * @param {() => number} action Funktionsaufruf
     * @param {number} defaultValue Alternativwert
     * @returns {number} Ergebnis der übergebenen FUnktion oder Default-Wert bei genannter Exception
     */
    public static handleDivisionByZeroError(action: () => number, defaultValue = 0): number {
        try {
            return action();
        }
        catch (e) {
            if (e instanceof DivisionByZeroError && e.name === DivisionByZeroError.errorName) {
                return defaultValue;
            }
            else {
                throw e;
            }
        }
    }

}
