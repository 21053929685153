import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ConfigService } from 'shared/util';

import { FinancingsService } from './services';
import { DashboardState, FinancingsState } from './states';
import { DASHBOARD_CONFIG } from './tokens';

export * from './enums';
export * from './interfaces';
export * from './services';
export * from './states';
export * from './tokens';
export * from './types';

/**
 * Modul für das Laden der Dashboarddaten
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([DashboardState, FinancingsState]),
    ],
    providers: [
        FinancingsService,
        {
            provide: DASHBOARD_CONFIG,
            useFactory: (configService: ConfigService) => (() => configService.getEnvironment().dashboardConfig),
            deps: [ConfigService],
        },
    ],
})
export class DashboardDataModule { }
