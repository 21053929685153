/* eslint-disable no-invalid-this */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IDropFolderViewModel } from '..';

/**
 * Service for manging drop folders
 */
@Injectable({
    providedIn: 'root',
})
export class DropFoldersService {
    private dropFolderHistory: string[] = [];
    private currentDropFolders: IDropFolderViewModel[] = [];
    private prevDropFolders: IDropFolderViewModel[] = [];
    private dropFolders: IDropFolderViewModel[] = [];
    private quickAccessFolders: IDropFolderViewModel[] = [];

    private currentDropFolders$: BehaviorSubject<IDropFolderViewModel[]>
        = new BehaviorSubject<IDropFolderViewModel[]>(this.currentDropFolders);
    private prevDropFolders$: BehaviorSubject<IDropFolderViewModel[]>
        = new BehaviorSubject<IDropFolderViewModel[]>(this.prevDropFolders);
    private dropFolders$: BehaviorSubject<IDropFolderViewModel[]>
        = new BehaviorSubject<IDropFolderViewModel[]>(this.dropFolders);
    private quickAccessFolders$: BehaviorSubject<IDropFolderViewModel[]>
        = new BehaviorSubject<IDropFolderViewModel[]>(this.quickAccessFolders);

    /**
     * Constructor
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() { }

    /**
     * get the current drop folder as an Observable
     *
     * @returns {Observable<IDropFolderViewModel>} an Observable of current drop folder
     */
    public getCurrentDropFolderAsObservable(): Observable<IDropFolderViewModel[]> {
        return this.currentDropFolders$.asObservable()
    }

    /**
     * get the current quick access folders
     *
     * @returns {Observable<IDropFolderViewModel[]>} an Observable of current quick access folders
     */
    public getCurrentQuickAccessFoldersAsObservable(): Observable<IDropFolderViewModel[]> {
        return this.quickAccessFolders$.asObservable();
    }

    /**
     * set the current drop folder
     *
     * @param {string} dropFolderId given drop folder id
     */
    public setCurrentDropFolder(dropFolderId: string): void {
        this.prevDropFolders = this.currentDropFolders;
        this.currentDropFolders = this.dropFolders.filter(
            (dropFolder: IDropFolderViewModel) =>
                dropFolder.parentDropFolderId === dropFolderId);
        this.prevDropFolders$.next(this.prevDropFolders);
        this.currentDropFolders$.next(this.currentDropFolders);
        this.dropFolderHistory.push(this.prevDropFolders[0].parentDropFolderId || '')
    }

    /**
     * storing all fetched drop folders
     *
     * @param {IDropFolderViewModel[]} dropFolders all drop folders
     */
    public setDropFolders(dropFolders: IDropFolderViewModel[]): void {
        if (!dropFolders) { return }
        this.dropFolders = dropFolders;
        this.dropFolders$.next(this.dropFolders);
        if (this.currentDropFolders$.getValue().length === 0) {
            this.currentDropFolders = this.dropFolders.filter(
                (dropFolder: IDropFolderViewModel) => !dropFolder.parentDropFolderId);
            this.currentDropFolders$.next(this.currentDropFolders);
        }
    }

    /**
     * get an indicator if prev drop folder is empty or not
     *
     * @returns {boolean} true if prev drop folder exists else false
     */
    public hasPrevDropFolders(): boolean {
        if (this.dropFolderHistory.length > 0) { return true }
        else { return false }
    }

    /**
     * navgate back and return current drop folder id
     *
     * @returns {string} current drop folder id
     */
    public navigateBack(): string {
        if (this.dropFolderHistory[this.dropFolderHistory.length - 1] !== '') {
            this.currentDropFolders = this.dropFolders.filter(
                (dropFolder: IDropFolderViewModel) =>
                    dropFolder.parentDropFolderId ===
                    this.dropFolderHistory[this.dropFolderHistory.length - 1],
            )
        } else {
            this.currentDropFolders = this.dropFolders.filter(
                (dropFolder: IDropFolderViewModel) => !dropFolder.parentDropFolderId);
        }
        this.currentDropFolders$.next(this.currentDropFolders);
        const folderToReturn = this.dropFolderHistory[this.dropFolderHistory.length - 1]
        this.dropFolderHistory.pop();
        return folderToReturn;
    }

    /**
     * get sub folder of current parrent folder by id
     *
     * @param {string} subFolderId parrent folder id
     * @returns {IDropFolderViewModel[]} sub folders
     */
    public getSubFoldersById(subFolderId: string): IDropFolderViewModel[] {
        return this.dropFolders.filter((dropFolder: IDropFolderViewModel) => dropFolder.parentDropFolderId === subFolderId)
    }

    /**
     * get a drop folder by id
     *
     * @param {string} dropFolderId drop folders id
     * @returns {IDropFolderViewModel} drop folder
     */
    public getDropFolderById(dropFolderId: string): IDropFolderViewModel {
        const dropFolder = this.dropFolders.find((folder: IDropFolderViewModel) => folder.id === dropFolderId);
        if (dropFolder) { return dropFolder; }
        return {} as IDropFolderViewModel;
    }
}
