import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, mergeMap, of } from 'rxjs';

import { ApprovalAuthoritiesFile, ControlTableType } from '../../enums';
import { IBaseControlTable, IUploadApprovalControlTableRequest, IUploadControlTableRequest } from '../../interfaces';


/**
 * Service für Steuertabellen
 */
@Injectable()
export class ControlTableService {


    /**
     * Standard Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     */
    public constructor(private authorizationService: AuthorizationService, private httpClient: HttpClient, private config: ConfigService) { }

    /**
     * Lädt eine Steuertabelle mit dem gegebenen Typ
     *
     * @param {ApprovalAuthoritiesFile |  ControlTableType} controlTableValue control table type
     * @param {boolean} isApprovalTable ist eine Approval Steuertabelle
     * @returns {IBaseControlTable} Steuertabelle
     */
    public loadControlTable<T extends IBaseControlTable>(controlTableValue: ApprovalAuthoritiesFile | ControlTableType, isApprovalTable: boolean): Observable<T[] | undefined> {

        const endpoint = isApprovalTable === true
            ? 'ApprovalAuthoritiesAdministration/ApprovalAuthoritiesData'
            : 'ControlTableAdministration/controlTableData';

        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<T[]>(`${this.config.getEnvironment().apiUrl}/${endpoint}`, { params: { controlTableValue } }),
                of(undefined),
            )),
        );
    }


    /**
     * Lädt eine Steuertabelle hoch
     *
     * @param {IUploadControlTableRequest | IUploadApprovalControlTableRequest} request Request mit Datei und Typ
     * @returns {IBaseControlTable} Steuertabelle
     */
    public uploadControlTable<T extends IBaseControlTable>(
        request: IUploadControlTableRequest | IUploadApprovalControlTableRequest,
    ): Observable<T[] | undefined> {
        const formData = new FormData();
        formData.append('file', request.file, request.file.name);

        if (this.isControlTableRequest(request)) {
            formData.append('controlTableType', (request as IUploadControlTableRequest).controlTableType.toString());
            return this.uploadControlTableRequest<T>('ControlTableAdministration/importControlTableFile', formData);
        } else {
            formData.append('fileType', (request as IUploadApprovalControlTableRequest).fileType.toString());
            return this.uploadControlTableRequest<T>('ApprovalAuthoritiesAdministration/importApprovalAuthoritiesFile', formData);
        }
    }

    /**
     * Steuertabelle hochladen Request
     *
     * @param { string } endpoint endpoint
     * @param {FormData} data data
     * @returns {Observable} observable
     */
    private uploadControlTableRequest<T extends IBaseControlTable>(endpoint: string, data: FormData): Observable<T[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.ControlTableDefaultAdministrator, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<T[]>(`${this.config.getEnvironment().apiUrl}/${endpoint}`, data, {}),
                    of(undefined),
                ),
            ),
        );
    }


    /**
     * Überprüft welche Art von Request es ist
     *
     * @param {IUploadControlTableRequest | IUploadApprovalControlTableRequest} request Request
     * @returns {boolean} Ist der Request ein ControlTableRequest
     */
    private isControlTableRequest(request: IUploadControlTableRequest | IUploadApprovalControlTableRequest): request is IUploadControlTableRequest {
        return 'controlTableType' in request;
    }
}
