<div [class.d-none]="isLoading" class="w-100 h-100">
    <finprocess-navigation>
        <router-outlet></router-outlet>
    </finprocess-navigation>
</div>
<div class="w-100 h-100" *ngIf="isLoading">
    <div fxLayout="column" fxFill fxLayoutAlign="center space-evenly" class="splashscreen-container bg">
        <img fxFlexAlign="center" class="splashscreen-logo" src="./assets/images/Splashscreen_Logo.svg" />
        <waiter-component [isLoading]="isLoading"></waiter-component>
    </div>
</div>

