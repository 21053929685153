<ng-container *ngIf="(loginState$ | async) as loginState">
<span [fxHide]="hideNavigation">
    <mat-toolbar class="navigation bg-dark px-3 py-1 color-font">
        <div fxFlex="30" fxLayout="column" fxLayoutAlign="center start" >
            <img class="logo" src="assets/images/logo48x48.png" (click)="toStart()">
        </div>
        <div fxFlex="40" fxLayout="column" fxLayoutAlign="center center">
            <span [textContent]="titleKey | translate" class="navigation-title mat-subtitle-2"></span>
        </div>
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="end center" class="navigation-actions" fxShow="true"
            fxHide.lt-sm="true" fxLayoutGap="12px">
            <span *ngIf="saluteUser" [textContent]="saluteUser" class="user-email mat-subtitle-2"></span>
            <mat-icon (click)="enterUserArea()" *finprocessAuthorization="Role.FinancingMapsEditor"
                [matTooltip]="'navigation.user-area.title' | translate">person</mat-icon>
            <mat-icon (click)="openCalculator()" *finprocessAuthorization="Role.FinancingMapsEditor"
                [matTooltip]="'navigation.rateCalculator' | translate">calculate</mat-icon>
                <mat-icon (click)="openTimeCalculator()" *finprocessAuthorization="Role.FinancingMapsEditor"
                [matTooltip]="'navigation.calculator-time' | translate">date_range</mat-icon>
            <ng-container *ngIf="showLoadBalancing">
                <mat-icon (click)="enterExternalBalancing()" *finprocessAuthorization="Role.LoadBalancingExternal"
                    [matTooltip]="'navigation.load-balancing' | translate">balance</mat-icon>
            </ng-container>
            <mat-icon (click)="enterAdmin()" *finprocessAuthorization="Role.AdministrationAreaReader"
                [matTooltip]="'navigation.settings' | translate">settings</mat-icon>
            <mat-icon (click)="showVersion()" *finprocessAuthentication="LoginStateType.LoggedIn"
                [matTooltip]="'navigation.version' | translate">info</mat-icon>
            <mat-icon (click)="showReleaseNotes()" *finprocessAuthentication="LoginStateType.LoggedIn"
                [matTooltip]="'navigation.releaseNotes' | translate">event_note</mat-icon>
            <mat-icon (click)="logout()" *finprocessAuthentication="LoginStateType.Locked"
                [matTooltip]="'navigation.logout' | translate">logout</mat-icon>
        </div>
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="center end" fxShow="true" fxHide.gt-xs="true"
            [class.d-none]="loginState === LoginStateType.NotLoggedIn">
            <button class="sidenav-toggle" mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </mat-toolbar>
</span>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav fxLayout="column" mode="over" position="end" opened="false" fxHide.gt-xs="true" class="bg"
            [class.d-none]="loginState === LoginStateType.NotLoggedIn">
            <div fxLayout="column" fxLayoutAlign="center start" class="navigation-actions color-font">
                <div class="w-100 p-2" *finprocessAuthentication="LoginStateType.LoggedIn">
                    <div fxLayout="row" fxLayoutAlign="end center" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="sidenav.close(); enterUserArea()">
                        <span class="pr-3">{{'navigation.user-area' | translate}}</span>
                        <mat-icon>person</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" *finprocessAuthorization="Role.LoadBalancingExternal"
                        (click)="sidenav.close(); enterExternalBalancing()">
                        <span class="pr-3">{{'navigation.load-balancing' | translate}}</span>
                        <mat-icon>balance</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center"
                        *finprocessAuthorization="Role.OrganisationalUnitAdministrator"
                        (click)="sidenav.close(); enterAdmin()">
                        <span class="pr-3">{{'navigation.settings' | translate}}</span>
                        <mat-icon>settings</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" (click)="sidenav.close(); showVersion()">
                        <span class="pr-3">{{'navigation.version' | translate}}</span>
                        <mat-icon>info</mat-icon>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" class="w-100 p-2"
                    *finprocessAuthentication="LoginStateType.Locked" (click)="logout(); sidenav.close()">
                    <span class="pr-3">{{'navigation.logout' | translate}}</span>
                    <mat-icon>logout</mat-icon>
                </div>
                <mat-divider class="w-100"></mat-divider>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="content color-font">
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
