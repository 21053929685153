import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const IDENTIFY_CUSTOMER: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'identifyCustomerModel',

    email: { type: WorkflowType.String },

    lastName: { type: WorkflowType.String },

    firstName: { type: WorkflowType.String },

    // ndgTypeList: { type: WorkflowType.Array, child: WorkflowType.String },
};
