import { ArgumentError } from '../../../errors';
import { ILegalisationFeeBase } from '../../../interfaces';
import { CalculationHelperService } from '../../calculation-helper.service';
import { BaseCalculationService } from '../base-calculation.service';

/**
 * Service für interne Berechnungen
 */
export class InternalCalculationService extends BaseCalculationService {

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet einen jährlichen Zinssatz auf einen Monat runter
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.rate Zinssatz in %
     * @returns {number} korrigierter Zinssatz als Faktor
     */
    public interestRateMonthCorrected({ rate }: { rate?: number }): number {
        return (!CalculationHelperService.isNullOrNaN(rate) ? (rate as number) : 0) / 100 / 12 / 360 * 365.25;
    }

    /**
     * Prüft, ob ein Kreditbetrag und ein Zinssatz jeweils größer 0 sind
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.amount Kreditbetrag in €
     * @param {number | undefined} params.rate Zinssatz in %
     * @returns {boolean} Sind Betrag und Zinssatz größer 0
     */
    public isCreditAmountOrRateIllegal({ amount, rate }: {
        /**
         * Kreditbetrag in €
         */
        amount?: number;
        /**
         * Zinssatz in %
         */
        rate?: number;
    }): boolean {
        return (!CalculationHelperService.isNullOrNaN(amount) ? (amount as number) : 0) <= 0 || CalculationHelperService.isNullOrNaN(rate);
    }

    /**
     * Ermittelt die Legalisierungsgebühr anhand des Kreditbetrages
     *
     * @param {object} params Parameterobjekt
     * @param {ILegalisationFeeBase[]} params.legalisationFeeBases Matrix zur Berechnung der Legalisierungsgebühr
     * @param {number} params.creditAmount Kreditbetrag in €
     * @returns {number} Legalisierungsgebühr in €
     * @throws {ArgumentError} Der Parameter 'legalisationFeeBases' ist nicht valide.
     */
    public getLegalisationFee({ legalisationFeeBases, creditAmount }: {
        /**
         * Matrix zur Berechnung der Legalisierungsgebühr
         */
        legalisationFeeBases: ILegalisationFeeBase[];
        /**
         * Kreditbetrag in €
         */
        creditAmount: number;
    }): number | undefined {
        this.checkLegalisationFeeBases(legalisationFeeBases);
        const definedEntries = legalisationFeeBases.filter(it => !CalculationHelperService.isNullOrNaN(it.creditAmount));
        definedEntries.sort((a, b) => (a.creditAmount ?? 0) - (b.creditAmount ?? 0));
        const definedFee = definedEntries.find(it => !CalculationHelperService.isNullOrNaN(it.creditAmount) && (it.creditAmount as number) > (creditAmount * 1.2));
        if (definedFee !== undefined) {
            return definedFee.legalisationFee;
        }
        else {
            const undefinedFee = legalisationFeeBases.find(it => CalculationHelperService.isNullOrNaN(it.creditAmount));
            return undefinedFee !== undefined ? undefinedFee.legalisationFee : undefined;
        }
    }

    /**
     * Überprüft die Konfiguration für die Matrix zur Berechnung der Legalisierungsgebühr
     *
     * @param {ILegalisationFeeBase[] | null | undefined} legalisationFeeBases Matrix zur Berechnung der Legalisierungsgebühr
     * @throws {ArgumentError} Der Parameter 'legalisationFeeBases' ist nicht valide.
     */
    private checkLegalisationFeeBases(legalisationFeeBases?: ILegalisationFeeBase[] | null) {
        if (!Array.isArray(legalisationFeeBases) ||
            legalisationFeeBases.length === 0 ||
            !legalisationFeeBases.some(it => it.creditAmount === undefined || (it.creditAmount as unknown) === null)) {
            throw new ArgumentError('Der Parameter \'legalisationFeeBases\' ist nicht valide.');
        }
    }
}
