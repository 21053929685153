import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { translations } from '../../../core/translatations';

/**
 * Abstrakte Klasse für das Implementieren eines Services zum Laden von Enum-Übersetzungen
 */
export abstract class EnumTranslateLoader {
    /**
     * Läd Lokalisierung
     *
     * @param lang Zu ladende Sprache
     * @returns {Observable<Record<string, any>>} Übersetzungen als Observable
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract getTranslation(lang: string): Observable<Record<string, any>>;
}

/**
 * Service zum Laden von Enum-Übersetzungen
 */
@Injectable()
export class EnumTranslationLoaderService extends EnumTranslateLoader {
    /**
     * Läd Übersetzungen zu einer Sprache
     *
     * @param {string} lang Zu ladende Sprache
     * @returns {Observable<Record<string, any>>} Übersetzungen als Observable
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
    public getTranslation(lang: string): Observable<Record<string, any>> {
        if (Object.prototype.hasOwnProperty.call(translations, lang) as boolean) {
            return of(translations[lang]);
        }
        else if (lang.includes('-') && (Object.prototype.hasOwnProperty.call(translations, lang.substr(0, 2)) as boolean)) {
            return of(translations[lang.substr(0, 2)]);
        }
        else {
            return of({});
        }
    }
}
