import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Dachbauweise
 */
export enum RoofConstruction {
    /**
     * Dachschräge vorhanden
     */
    SlopingRoof = 1,

    /**
     * Flachdach
     */
    FlatRoof = 2,

    /**
     * Wohnung ist nicht im Dachgeschoss
     */
    NotAvailable = 4,

    /**
     * Für Wohnzwecke genutzt/nutzbar
     */
    ResidentialUse = 8,

    /**
     * Ausbau möglich
     */
    Expandable = 16,

    /**
     * Nicht Für Wohnzwecke genutzt/nutzbar
     */
    NoResidentalUse = 32,
}

export namespace RoofConstruction {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {RoofConstruction | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: RoofConstruction | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'RoofConstruction', RoofConstruction, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('RoofConstruction', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {RoofConstruction | undefined | null} value Enum-Wert
     * @param {RoofConstruction} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: RoofConstruction | undefined | null, flag: RoofConstruction): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
