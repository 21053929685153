/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MessageUserType } from '@ntag-ef/finprocess-enums';
import { Logout } from 'app/modules/auth/data';
import { HelperService } from 'app/modules/shared';
import { sort } from 'fast-sort';

import { IMessage } from './../../interfaces/messages.interface';
import { AddMessages, ClearMessages, MessagesLoaded } from './messages.actions';

export interface IMessagesStateModel {
    messages?: IMessage[];
}

const defaulData: IMessagesStateModel = {};

/**
 * Zustand für die Nachrichten
 */
@State<IMessagesStateModel>({
    name: MessagesState.keyName,
    defaults: defaulData,
})
@Injectable()
export class MessagesState {
    public static readonly keyName = 'message';

    /**
     * Liefert Gruppen Chat zurück
     *
     * @param {IMessagesStateModel} state Zustand
     * @returns {IMessage | undefined} Chat
     */
    @Selector()
    public static groupChat(state: IMessagesStateModel): IMessage[] | undefined {
        if (state.messages !== undefined) {
            const groupMessages = state.messages.filter((msg: IMessage) =>
                HelperService.hasBit(msg.to, MessageUserType.Customer) ||
                HelperService.hasBit(msg.from, MessageUserType.Customer))
                .map((msg: IMessage) => ({
                    ...msg,
                    text: HelperService.toHTMLBreakes(msg.text),
                }));
            return sort(groupMessages).asc(msg => msg.created);
        }
        return undefined;
    }

    /**
     * Liefert Internen Chat zurück
     *
     * @param {IMessagesStateModel} state Zustand
     * @returns {IMessage | undefined} Chat
     */
    @Selector()
    public static internalChat(state: IMessagesStateModel): IMessage[] | undefined {
        if (state.messages !== undefined) {
            const internalMessages = state.messages.filter((msg: IMessage) =>
                !HelperService.hasBit(msg.to, MessageUserType.Customer) &&
                !HelperService.hasBit(msg.from, MessageUserType.Customer)).
                map((msg: IMessage) => ({
                    ...msg,
                    text: HelperService.toHTMLBreakes(msg.text),
                }));
            return sort(internalMessages).asc(msg => msg.created);
        }
        return undefined;
    }

    /**
     * Aktualisiert die Nachrichten im State
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {MessagesLoaded} action Aktion
     */
    @Action(MessagesLoaded)
    public messagesLoaded({ patchState }: StateContext<IMessagesStateModel>, { payload }: MessagesLoaded): void {
        patchState({
            messages: payload,
        });
    }

    /**
     * Fügt Nachricht dem State hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AddMessages} action Aktion
     */
    @Action(AddMessages)
    public addMessages({ patchState, getState }: StateContext<IMessagesStateModel>, { payload }: AddMessages): void {
        const state = getState();
        patchState({
            messages: state.messages?.concat(payload),
        });
    }

    /**
     * Setzt den State zurück
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(ClearMessages)
    @Action(Logout)
    public clearMessages({ setState}: StateContext<IMessagesStateModel>): void {
        setState(defaulData);
    }
}
