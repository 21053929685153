import { Component, Input } from '@angular/core';

/**
 * Component to display a bootstrap like hint
 */
@Component({
    selector: 'finprocess-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss'],
})
export class HintComponent {

    @Input()
    public type: 'info' | 'warn' | 'error' | 'success' | 'help' = 'info';
}
