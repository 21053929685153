/* eslint-disable class-methods-use-this */
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { FinancingPremissions } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { IUser, Role } from 'app/modules/auth/data';
import { IMasterdataParentDefinition, ISalesPartnerCenter } from 'app/modules/masterdata/data';
import { SubStatusCode } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, combineLatest, map, take, takeUntil } from 'rxjs';
import { HelperService } from 'shared/util';

import { EditUserDialogComponent } from '..';

import { UserAdministrationService } from './../../../../data/services/user-administration/user-administration.service';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';


export interface IMultiSelectItem {
    value: number;
    displayName: string;
}
/**
 * Komponente für die Nutzerliste im Administrationsbereich
 */
@Component({
    selector: 'finprocess-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnDestroy, OnInit {

    /**
     * Observable für die Liste der Nutzer
     */
    public users$!: Observable<IUser[]>;

    /**
     * Observable für die Liste der SalesPartnerCenter
     */
    @Select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters)
    public salesPartnerCenters$!: Observable<ISalesPartnerCenter[]>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Subject zum Filtern der Seiten
     */
    public currentPage = new BehaviorSubject<number>(0);

    /**
     * Subject zum Filtern der FinancingPremissions
     */
    public selectedFinancingPermisson = new BehaviorSubject<FinancingPremissions | undefined>(undefined);

    public financingPremissions: IMultiSelectItem[] = [];

    /**
     * Subject für die Textsuche
     */
    public searchTerm = new BehaviorSubject<string>('');


    public pageSize = 12;

    public displayedColumns: string[] = [ 'firstName', 'lastName', 'email', 'phoneNumber', 'languages' , 'organisationalUnit', 'confirmed', 'edit'];
    public displayedUsers: IUser[] = [];

    private onDestroy$ = new Subject<void>();

    public partnerCenters: ISalesPartnerCenter[] | undefined;

    /**
     * Standard Konstruktor
     *
     * @param {UserAdministrationService} userAdministration UserAdministrationService-Injektor
     * @param {MatDialog} matDialog MatDialog-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {EnumTranslationPipe} enumTranslation EnumTranslation-Pipe Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private userAdministration: UserAdministrationService,
        private matDialog: MatDialog,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        private enumTranslation: EnumTranslationPipe,
        private store: Store,
    ) {}

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {

        this.financingPremissions = sort((HelperService.getEnumArray(FinancingPremissions) as number[]).map(premission => ({
            value: premission,
            displayName: FinancingPremissions.translate(premission as FinancingPremissions) ?? '',
        })).filter(x => x.value !== 0)).asc(premission => premission.displayName);

        this.userAdministration.getList()
            .pipe(take(1))
            .subscribe();

        this.users$ = this.store.select((it: IAdministrationStateParentDefinition) => it.userAdministration.users).pipe(takeUntil(this.onDestroy$));

        combineLatest([this.users$, this.currentPage, this.searchTerm, this.selectedFinancingPermisson]).pipe(
            takeUntil(this.onDestroy$),
            map(([users, currentPage, searchTerm, financingPermisson]) => {

                const filteredUsers = this.searchUsers(users, searchTerm, financingPermisson);

                const startIndex = currentPage * this.pageSize;
                const displayedUsers = filteredUsers.slice(startIndex, startIndex + this.pageSize);

                if (displayedUsers.length < this.pageSize) {
                    // Nutzer mit leeren Einträgen auffuellen damit die Tabelle gut aussieht
                    displayedUsers.push(...Array(this.pageSize - displayedUsers.length).fill({}))
                }

                return displayedUsers;
            }),
        ).subscribe(users => {
            this.displayedUsers = users;
        });

    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Ändert die angezeigten Benutzer wenn die Seite des Paginators geändert wird
     *
     * @param {PageEvent} pageEvent Das PageEvent des Paginators
     */
    public changePage(pageEvent: PageEvent): void {
        this.currentPage.next(pageEvent.pageIndex);
    }

    /**
     * Bestätigt einen Nutzer
     *
     * @param {IUser} user Nutzer
     */
    public editUser(user: IUser): void {
        const dialogRef = this.matDialog.open(EditUserDialogComponent, {
            data: user,
            autoFocus: false,
            minWidth: '30vw',
        });

        const subDataChanges = dialogRef.componentInstance.userDataChanged.subscribe(userToSave => {
            if (!!userToSave) {
                this.waiterService.show();
                this.userAdministration.update(userToSave).pipe(take(1)).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        dialogRef.close();
                        this.notification.toast(this.translate.instant('administration.features.users.userSuccessfullyUpdated', { firstName: user.firstName, lastName: user.lastName }));
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status === HttpStatusCode.MethodNotAllowed) { //TODO muss durch die entsprechende ENUM ersetzt werden!
                            this.waiterService.hide();

                            if (error.error.subStatusCode === SubStatusCode.UserHasOpenFinancingMapWithFinancingPermissions) {
                                this.notification.alert(
                                    this.translate.instant('administration.features.users.errorTitleMissingPermissions'),
                                    `${this.translate.instant('administration.features.users.errorBodyMissingPermissions')}:<br><br>${error.error.permissions.map((permission: number) => this.enumTranslation.transform(permission, 'FinancingPremissions')).join('<br>')}`,
                                );
                            } else if (error.error.subStatusCode === SubStatusCode.UserHasOpenFinancingMapWithSalesPartnerCenter) {
                                this.notification.alert(
                                    this.translate.instant('administration.features.users.errorTitleMissingSalesPartnerCenters'),
                                    `${this.translate.instant('administration.features.users.errorBodyMissingSalesPartnerCenters')}:<br><br>${error.error.salesPartnerCenters.join('<br>')}`,
                                );
                            } else if (error.error.subStatusCode === SubStatusCode.UserHasOpenFinancingMapWithUserPurpose) {
                                this.notification.alert(
                                    this.translate.instant('administration.features.users.errorTitleMissingUserPurposes'),
                                    `${this.translate.instant('administration.features.users.errorBodyMissingUserPurposes')}:<br><br>${error.error.userPurposes.map((userPurposes: number) => this.enumTranslation.transform(userPurposes, 'Role')).join('<br>')}`,
                                );
                            }
                        }
                        else if (error.status === HttpStatusCode.NotModified) {
                            this.waiterService.hide();
                            this.notification.alert(
                                this.translate.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'),
                                this.translate.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText1'));
                        }
                        else if (error.status === HttpStatusCode.Conflict) {
                            this.waiterService.hide();
                            this.notification.alert(
                                this.translate.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'),
                                this.translate.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText3'));
                        }
                        else {
                            this.waiterService.hide();
                            this.notification.alert(
                                this.translate.instant('general.error'),
                                this.translate.instant('administration.features.users.errorUser'),
                            );
                        }
                    },
                })
            }
        })

        dialogRef.afterClosed().pipe().subscribe(() => subDataChanges.unsubscribe())
    }

    /**
     * Suche aktualisieren
     *
     * @param {string} search Suchbegriff
     */
    public updateSearch(search: string): void {
        this.searchTerm.next(search);
    }

    /**
     * Filter für Organisationseinheit aktualisieren
     *
     * @param {MatSelectChange} event MatSelect Event
     */
    public updateFinancingPremissionsFilter(event: MatSelectChange): void {
        this.selectedFinancingPermisson.next(event.value);
    }

    /**
     * Durchsucht die Liste der Nutzer
     *
     * @param {IUser[]} users Nutzer
     * @param {string} searchTerm Suchbegriff
     * @param {FinancingPremissions} financingPermisson financingPermisson
     * @returns {IUser[]} gefilterte Liste von Nutzern
     */
    private searchUsers(users: IUser[], searchTerm: string, financingPermisson?: FinancingPremissions): IUser[] {
        return users.filter(user => searchTerm.split(' ').map(term => term.toLowerCase()).every(term =>
            (user.firstName.toLowerCase().includes(term) ||
            user.lastName.toLowerCase().includes(term) ||
            user.email.toLowerCase().includes(term)) &&
            (!!financingPermisson ? HelperService.hasBit(user.financingPermissions, financingPermisson) : true ),
        ));
    }
}
