/**
 * Channel Typen
 */
export enum CustomerIdType {
    /**
     * 1=PI (rot)
     */
    Pi = 1,

    /**
     * 2=SB/PI (grün)
     */
    //SbPi = 2,

    /**
     * SB/SB (gelb)
     */
    //SbSb = 3,

    /**
     * SB/PI non Mortgage (lila)
     */
    //SbPiNonMortgage = 4,
}


