import { Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * Direktive um eine Änderung der Eingabetypnummer mit der Maus zu verhindern
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[type="number"]',
})

export class IgnoreWheelDirective {

    /**
     * Konstruktor 
     *
     * @param { ElementRef } el input element
     * @param {Renderer2 } renderer Renderer2
     */
    public constructor (
      private el: ElementRef, 
      private renderer: Renderer2) 
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.renderer.listen(el.nativeElement, 'wheel', (event: any) => {
            this.el.nativeElement.blur();
            event.stopPropagation();
        });
    }
}
