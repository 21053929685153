import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';
import { Observable } from 'rxjs';

import { IEditLiabilities } from '../../../../../financing/data/interfaces';

// Full solution found here: https://stackoverflow.com/a/41187919/74276
// and then changed to use the function-based method of doing route guards
// Updated solution found here: https://stackoverflow.com/a/75769104/74276

/**
 * Guard für Ausstehende Änderungen
 */
@Injectable()
export class PendingChangesGuard {

    /**
     * Konstruktor
     *
     * @param {NotificationService} notificationService notificationService-Injektor
     * @param {TranslateService} translate translation service
     */
    public constructor(
        private notificationService: NotificationService,
        private translate: TranslateService, 
    ) { }

    /**
     * Prüft, ob die Seite verlassen werden kann
     *
     * @param { IEditLiabilities } component Liabilities
     * @returns {boolean | Observable<boolean>} true / false
     */
    public canDeactivate(component: IEditLiabilities): boolean | Observable<boolean> {
        return new Observable<boolean>(observer => {
            if (!!component.canDeactivate && component.canDeactivate()) {
                observer.next(true);
                observer.complete();
                return;
            }
            
            this.notificationService.confirmYesNo(
                this.translate.instant('auth.features.liabilities.title'),
                this.translate.instant('auth.features.liabilities.message'),
            ).subscribe(result => {
                if ( result === 'cancel') {
                    observer.next(false)
                }

                if ( result === 'submit') {
                    observer.next(true);
                }
                
                observer.complete();
            })
        })
    }
}
