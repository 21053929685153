/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IUser, Logout } from 'app/modules/auth/data';

import { ITemporaryUserViewmodel } from '../../interfaces';

import { AssignableTemporaryEditorListLoaded, AssignedAsTemporaryEditorListLoaded, UserSubstitutionConfirmed, UserSubstitutionRemoved } from './substitution.action';


/**
 * Zustandsobjekt für Vertretungen
 */
export interface ISubstitutionStateModel {

    /**
     *   Liste der Vertretenden
     */
    assignableTemporaryEditorList: IUser[];

    /**
     * Liste der Vertretenden und Vertretung
     */
    temporaryAndUserViewModel: ITemporaryUserViewmodel[];

}

/**
 * Zustand für alle existierenden Nutzer
 */
@State<ISubstitutionStateModel>({
    name: SubstitutionState.keyName,
    defaults: {
        assignableTemporaryEditorList: [],
        temporaryAndUserViewModel: [],
    },
})
@Injectable()
export class SubstitutionState {
    public static readonly keyName = 'substitution';
    /**
     * Zustandsänderung nach dem Laden aller Vertretenden
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssignableTemporaryEditorListLoaded} action Aktion
     */
    @Action(AssignableTemporaryEditorListLoaded)
    public assignableTemporaryEditorListLoaded({ patchState }: StateContext<ISubstitutionStateModel>, { payload }: AssignableTemporaryEditorListLoaded): void {
        patchState({
            assignableTemporaryEditorList: payload,
        });
    }

    /**
     * Zustandsänderung nach dem Laden alle Vertretungen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssignableTemporaryEditorListLoaded} action Aktion
     */
    @Action(AssignedAsTemporaryEditorListLoaded)
    public assignedAsTemporaryEditorListLoaded({ patchState }: StateContext<ISubstitutionStateModel>, { payload }: AssignedAsTemporaryEditorListLoaded): void {
        patchState({
            temporaryAndUserViewModel: payload,
        });
    }

    /**
     * Zustandsänderung der Liste Vertretenden und Vertretungen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserSubstitutionConfirmed} action Aktion
     */
    @Action(UserSubstitutionConfirmed)
    public userSubstitutionConfirmed({ getState, patchState }: StateContext<ISubstitutionStateModel>, { payload, payload2 }: UserSubstitutionConfirmed): void {
        const substitute = getState().assignableTemporaryEditorList.find(x => (x.id === payload));
        const substituted = getState().assignableTemporaryEditorList.find(x => (x.id === payload2));

        if (!!substitute) {
            const newTemporaryAndUserVM = getState().temporaryAndUserViewModel.concat([{ currentUser: substitute, differentTemporaryUser: substituted }])
            patchState({ temporaryAndUserViewModel: newTemporaryAndUserVM })
        }
    }

    /**
     * Zustandsänderung beim löschen Vertretung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserSubstitutionRemoved} action Aktion
     */
    @Action(UserSubstitutionRemoved)
    public userSubstitutionRemoved({ getState, patchState }: StateContext<ISubstitutionStateModel>, { payload, payload2 }: UserSubstitutionRemoved): void {
        const substitute = getState().temporaryAndUserViewModel.find(x => (x.currentUser.id === payload && x.differentTemporaryUser?.id === payload2));
        if (!!substitute) {
            const newTemporaryAndUserVM = getState().temporaryAndUserViewModel.filter(x => !(x.currentUser.id === substitute.currentUser.id && x.differentTemporaryUser?.id === substitute.differentTemporaryUser?.id))
            patchState({ temporaryAndUserViewModel: newTemporaryAndUserVM });
        }
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<ISubstitutionStateModel>): void {
        setState({ assignableTemporaryEditorList: [], temporaryAndUserViewModel: [] });
    }
}
