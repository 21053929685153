import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of, tap } from 'rxjs';

import { FinProcessEntity } from '../enums';
import { INote } from '../interfaces';
import { NoteAdded, NoteDeleted, NoteEdited, NotesLoaded } from '../states/note.action';

/**
 * Note Service
 */
@Injectable()
export class NoteService {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService 
     * @param {HttpClient} httpClient httpClient
     * @param {ConfigService} config configService
     * @param {Store} store Store-Injector
     */
    public constructor(
        private authorizationService: AuthorizationService,
        private httpClient: HttpClient,
        private config: ConfigService,
        private store: Store,
    ) { }

    /**
     * get all Notes
     *
     * @param {UUID} id financing container id
     * @returns {Observable} INote[] | undefined
     */
    public getAllNotes(id: UUID): Observable<INote[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<INote[]>(`${this.config.getEnvironment().apiUrl}/Remark/getall`,
                    {
                        params: { id: id },
                    }).pipe(
                    mergeMap(data => this.store.dispatch(new NotesLoaded(data)).pipe(
                        map(() => data),
                    )),
                ) : of(undefined)),
            ),
        );
    }

    /**
     * get a Note
     *
     * @param {UUID} id note id
     * @returns {Observable} INote[] | undefined
     */
    public getNote(id: UUID): Observable<INote | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.get<INote>(`${this.config.getEnvironment().apiUrl}/Remark/get`,
                    {
                        params: {
                            id: id,
                        },
                    }) : of(undefined)),
            ),
        );
    }

    /**
     * get notes by entity ids, container id and type
     *
     * @param {UUID} containerID containerID
     * @param {FinProcessEntity[]} types types
     * @param {UUID[]} entityIDs entityIDs
     * @returns {Observable} INote[] | undefined
     */
    public getNotesByEntities(containerID: UUID, types: FinProcessEntity[], entityIDs: UUID[]): Observable<INote[] | undefined> {
        const data = {
            finProcessContainerId: containerID,
            types: types,
            entityIds: entityIDs,
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                (authorized ? this.httpClient.post<INote[]>(`${this.config.getEnvironment().apiUrl}/Remark/getRemarksByEntitiesAndTypes`, data, {},
                ).pipe(
                    mergeMap(notes => this.store.dispatch(new NotesLoaded(notes)).pipe(
                        map(() => notes),
                    )),
                ) : of(undefined)),
            ),
        );
    }

    /**
     * get notes by types
     *
     * @param {UUID} containerID containerID
     * @param {FinProcessEntity[]} types types
     * @returns {Observable} INote[] | undefined
     */
    public getNotesByTypes(containerID: UUID, types: FinProcessEntity[]): Observable<INote[] | undefined> {
        const data = {
            finProcessContainerId: containerID,
            types: types,
        }
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<INote[]>(`${this.config.getEnvironment().apiUrl}/Remark/getRemarksByContainerAndTypes`, data).pipe(
                    mergeMap((notes: INote[]) => this.store.dispatch(new NotesLoaded(notes)).pipe(
                        map(() => notes),
                    )), 
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Add new Note
     * 
     * @param {INote} noteData note data
     * @returns {Observable} Observable
     */
    public addNote(noteData: Partial<INote>): Observable<Partial<INote> | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<INote>(
                        `${this.config.getEnvironment().apiUrl}/Remark/add`, noteData, {},
                    ).pipe(
                        mergeMap(note => this.store.dispatch(new NoteAdded(note)).pipe(
                            map(() => note),
                        )),
                    ),
                    of(undefined),
                ),
            ),
        );
    }

    /**
     * update Note
     * 
     * @param {INote} updateNoteData update Note data
     * @returns {Observable} Observable
     */
    public updateNote(updateNoteData: INote): Observable<INote[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.patch<INote[]>(`${this.config.getEnvironment().apiUrl}/Remark/update`, updateNoteData,
                ).pipe(
                    mergeMap(note => this.store.dispatch(new NoteEdited(note)).pipe(
                        map(() => note),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * delete Note
     *
     * @param {UUID} noteID Note ID
     * @param {UUID} containerID container ID
     * @returns {Observable} observable
     */
    public deleteNote(noteID: UUID, containerID: UUID): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.delete<void>(`${this.config.getEnvironment().apiUrl}/Remark/delete`, {
                        params: {
                            id: noteID,
                            finProcessContainerId: containerID,
                        },
                    }).pipe(
                        tap(() => {
                            this.store.dispatch(new NoteDeleted(noteID));
                        }),
                        map(() => noteID),
                    ),
                    of(undefined),
                ),
            ),
        );
    }
}
