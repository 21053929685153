<finprocess-two-column-layout
    leftFxLayoutAlign="start start"
    rightFxLayoutAlign="center center"
    rigthClass="pb-0 pt-3 px-5"
    leftClass="pb-0 pt-3 px-2"
    [flexMd]="65">
    <div left style="padding-left: 15px">
        <ng-container *ngIf="debitors$ | async as debitors">
            <div class="w-100 mb-0" fxLayout="row wrap">
                <div fxLayout="column" style="height: 48px" *ngFor="let debitorHeader of debitors; index as i">
                    <div
                        class="nav-item-custom"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        [class.nav-item-selected-custom]="selectedDebitor.value && debitorHeader.debitor.id === selectedDebitor.value.debitor.id"
                        (click)="changeSelectedDebtor(debitorHeader, i)">
                        {{ debitorHeader.firstNameOverwrite }} {{ debitorHeader.lastNameOverwrite }}
                        <mat-icon *ngIf="debitorHeader.debitor.id === (economicLeader$ | async)?.id" color="primary" class="ml-1">star</mat-icon>
                        <mat-icon *ngIf="debitorHeader.debitor.isOwnEmployee" class="ml-1 bank-logo" svgIcon="finprocess-bank-logo"></mat-icon>
                    </div>
                    <hr [class.border-animated]="selectedDebitor.value && debitorHeader.debitor.id === selectedDebitor.value.debitor.id" />
                </div>
            </div>
            <hr class="custom-hr" style="position: relative" />

            <div class="area-container" *ngIf="selectedDebitor | async as debitor">
                <div fxLayout="row" fxLayoutAlign="end" class="note-container">
                    <finprocess-note [type]="FinprocessEntity.Debitor" [userID]="userID ?? ''" [entityID]="(selectedDebitor | async)?.debitor?.id"></finprocess-note>
                </div>
                
                <!-- Einkommen Kreditnehmer nicht editierbar -->

                <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="100px" style="padding-top: 20px">
                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.debitor.nettoIncome' | translate }}</span>
                        <span value>{{ debitor.debitor.netIncome | formatCurrency }}</span>
                    </finprocess-label-value>

                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.debitor.fourteenSalary' | translate }}</span>
                        <span value *ngIf="debitor.debitor.fourteenSalariesPerYear === true">Ja</span>
                        <span value *ngIf="debitor.debitor.fourteenSalariesPerYear === false">Nein</span>
                    </finprocess-label-value>

                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.debitor.otherIncome' | translate }}</span>
                        <span value>{{ debitor.debitor.otherIncome | formatCurrency }}</span>
                    </finprocess-label-value>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" style="padding-top: 40px">
                    <b class="mr-1">{{ 'financing.features.financing-processing.debitor.sustainableIncome' | translate }}</b>
                    <mat-icon
                        class="overalleconomicleader-icon"
                        color="primary"
                        *ngIf="(economicLeader$ | async)?.id === debitor.debitor.id"
                        matTooltip="wirtschaftlich Führender"
                        >star</mat-icon
                    >

                    <button
                        mat-stroked-button
                        class="mr-1 ml-1"
                        (click)="openCalculatorUrl()"
                        matTooltip="Bruttonettorechner"
                        matTooltipPosition="above"
                        [disabled]="fieldReadonly$ | async">
                        <b>BNR</b>
                    </button>

                    <button
                        mat-stroked-button
                        (click)="openDSBDialog(debitor.debitor.id)"
                        matTooltip="Durchschnittsberechnung"
                        matTooltipPosition="above"
                        [disabled]="fieldReadonly$ | async">
                        <span style="font-size: 2em; line-height: 1em">⌀</span>
                    </button>
                </div>

                <finprocess-boolean-input
                    [entityClassType]="entityTypeDebtor"
                    [overwrite]="false"
                    [label]="'financing.features.financing-processing.debitor.sustainableFourteenSalariesPerYear' | translate"
                    [entity]="debitor.debitor"
                    fieldName="sustainableFourteenSalariesPerYear"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-decimal-input
                    [overwrite]="false"
                    [entityClassType]="entityTypeDebtor"
                    [label]="'financing.features.financing-processing.debitor.sustainableNetIncome' | translate"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [entity]="debitor.debitor"
                    fieldName="sustainableNetIncome"
                    [readonly]="fieldReadonly$">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    *ngIf="!!monthlyNetIncome"
                    [numberFunctionObservable]="monthlyNetIncome"
                    [label]="'financing.features.financing-processing.debitor.sustainableIncome14Months' | translate">
                </finprocess-calculation-display>
                <finprocess-decimal-input
                    [overwrite]="false"
                    [entityClassType]="entityTypeDebtor"
                    [label]="'financing.features.financing-processing.debitor.sustainableOtherIncome' | translate"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [entity]="debitor.debitor"
                    fieldName="sustainableOtherIncome"
                    [readonly]="fieldReadonly$">
                </finprocess-decimal-input>

                <finprocess-decimal-input
                    [overwrite]="false"
                    [entityClassType]="entityTypeDebtor"
                    [label]="'financing.features.financing-processing.debitor.sustainableIndependentIncome' | translate"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [entity]="debitor.debitor"
                    fieldName="sustainableIndependentIncome"
                    [readonly]="fieldReadonly$">
                </finprocess-decimal-input>

                <ucba-expansion-panel [hideToggle]="loadingRentalIncomes">
                    <div ucba-expansion-panel-header class="rental-income-header">
                        <b> {{ 'financing.features.financing-processing.debitor.sustainableExistingRentalIncome' | translate }}</b>
                        <button
                            mat-icon-button
                            color="primary"
                            (click)="openRentalIncomeDialog('existing', debitor.debitor.id); $event.stopPropagation()"
                            [disabled]="fieldReadonly$ | async">
                            <mat-icon>add</mat-icon>
                        </button>
                        <waiter-component [isLoading]="loadingRentalIncomes" [diameter]="30" style="height: 30px; width: 30px"></waiter-component>
                        
                        <finprocess-decimal-input
                            [overwrite]="false"
                            [entityClassType]="entityTypeDebtor"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [entity]="debitor.debitor"
                            fieldName="sustainableExistingRentalIncome"
                            [readonly]="sustainableRentalIncomeReadonly$"
                            (click)="$event.stopPropagation()">
                        </finprocess-decimal-input>
                    </div>

                    <div ucba-expansion-panel-content>
                        <div class="rental-income-labels">
                            <span>{{ 'financing.features.financing-processing.rental-income.objectName' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.type' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.netRentPerMonth2' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.netRent70PercentPerMonth' | translate }}</span>
                            <div style="width: 48px"></div>
                            <div style="width: 48px"></div>
                        </div>
                        @for (existingRentalIncome of existingRentalIncomes; track existingRentalIncome.id) {
                        <div class="rental-income">
                            <span>{{ existingRentalIncome.objectName }}</span>
                            <span> {{ 'financing.features.financing-processing.rental-income.existing' | translate }}</span>
                            <span>{{ existingRentalIncome.netRentPerMonth | formatCurrency }}</span>
                            <span>{{ existingRentalIncome.netRent70PercentPerMonth | formatCurrency }}</span>
                            <button
                                mat-icon-button
                                (click)="openRentalIncomeDialog('existing', debitor.debitor.id, existingRentalIncome)"
                                [disabled]="fieldReadonly$ | async">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteRentalIncome(existingRentalIncome)" [disabled]="fieldReadonly$ | async">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        } @empty { Noch keine Mieteinnahmen angegeben }
                    </div>
                </ucba-expansion-panel>

                <ucba-expansion-panel [hideToggle]="loadingRentalIncomes">
                    <div ucba-expansion-panel-header class="rental-income-header">
                        <b> {{ 'financing.features.financing-processing.debitor.sustainableRentalIncome' | translate }}</b>
                        <button
                            mat-icon-button
                            color="primary"
                            (click)="openRentalIncomeDialog('future', debitor.debitor.id); $event.stopPropagation()"
                            [disabled]="fieldReadonly$ | async">
                            <mat-icon>add</mat-icon>
                        </button>
                        <waiter-component [isLoading]="loadingRentalIncomes" [diameter]="30" style="height: 30px; width: 30px"></waiter-component>
                        <finprocess-decimal-input
                            [overwrite]="false"
                            [entityClassType]="entityTypeDebtor"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [entity]="debitor.debitor"
                            fieldName="sustainableRentalIncome"
                            [readonly]="sustainableRentalIncomeReadonly$">
                        </finprocess-decimal-input>
                    </div>
                    <div ucba-expansion-panel-content>
                        <div class="rental-income-labels">
                            <span>{{ 'financing.features.financing-processing.rental-income.objectName' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.type' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.netRentPerMonth2' | translate }}</span>
                            <span>{{ 'financing.features.financing-processing.rental-income.netRent70PercentPerMonth' | translate }}</span>
                            <div style="width: 48px"></div>
                            <div style="width: 48px"></div>
                        </div>
                        @for (futureRentalIncome of futureRentalIncomes; track futureRentalIncome.id) {
                        <div class="rental-income">
                            <span>{{ futureRentalIncome.objectName }}</span>
                            <span> {{ 'financing.features.financing-processing.rental-income.future' | translate }}</span>
                            <span>{{ futureRentalIncome.netRentPerMonth | formatCurrency }}</span>
                            <span>{{ futureRentalIncome.netRent70PercentPerMonth | formatCurrency }}</span>
                            <button
                                mat-icon-button
                                (click)="openRentalIncomeDialog('future', debitor.debitor.id, futureRentalIncome)"
                                [disabled]="fieldReadonly$ | async">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteRentalIncome(futureRentalIncome)" [disabled]="fieldReadonly$ | async">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        } @empty { Noch keine Mieteinnahmen angegeben }
                    </div>
                </ucba-expansion-panel>

                <ng-container *ngIf="sustainableTotalRentalIncome | async as income">
                    <finprocess-hint type="warn" *ngIf="income() > 2000">
                        <span header>{{ 'financing.features.financing-processing.debitor.sustainableIncomeOver2000WarningTitle' | translate }}</span>
                        <div content>{{ 'financing.features.financing-processing.debitor.sustainableIncomeOver2000Warning' | translate }}</div>
                    </finprocess-hint>
                </ng-container>

                <finprocess-calculation-display
                    [numberFunctionObservable]="sustainableTotalRentalIncome"
                    [label]="'financing.features.financing-processing.debitor.sustainableTotalRentalIncome' | translate">
                </finprocess-calculation-display>

                <finprocess-calculation-display
                    [numberFunctionObservable]="totalSustainable"
                    [label]="'financing.features.financing-processing.debitor.sustainableTotal' | translate">
                </finprocess-calculation-display>

                <div class="p-2" fxLayout="column">
                    <div fxlayout="row">
                        <label fxFlex="50">{{ 'financing.features.financing-processing.debitor.lastingProof' | translate }}</label>
                        <span fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                            <!--uploadMultiFiles-->
                            <button
                                mat-icon-button
                                color="primary"
                                [matTooltip]="'financing.features.financing-processing.debitor.uploadSustainableFile' | translate"
                                (click)="uploadMultiFiles(debitor.debitor.id)"
                                [disabled]="(fieldReadonly$ | async) === true || (statusEnable | async)">
                                <mat-icon>upload_file</mat-icon>
                            </button>
                        </span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-evenly" *ngIf="debitor.sustainableFiles.length > 0">
                        <div fxLayout="row" *ngFor="let file of debitor.sustainableFiles">
                            <div fxLayout="row" class="mt-2" fxFlex="50">
                                <mat-icon class="mr-1 document-icon">description</mat-icon>
                                <div> <span>{{file.name}}</span><span *ngIf="!file.name.includes('.pdf')">.pdf</span></div>
                            </div>
                            <div fxFlex="50" fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="space-evenly start">
                                <button
                                    [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                    (click)="openFile(file)"
                                    mat-icon-button
                                    color="primary">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                                <button
                                    [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                    mat-icon-button
                                    color="primary"
                                    (click)="sustainableDocumentDownload(file)">
                                    <mat-icon>download</mat-icon>
                                </button>
                                @if(file.documentType !== DocumentType.IncomeEntriesDocumentation) {
                                    <button
                                        [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                        class="delete"
                                        mat-icon-button
                                        color="primary"
                                        (click)="sustainableDocumentDelete(debitor.debitor.id, file)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                } @else {
                                    <span style="width: 48px"></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <finprocess-boolean-input
                    [label]="'financing.features.financing-processing.debitor.otherIncomeContainsAlimony' | translate"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [entity]="debitor.debitor"
                    fieldName="otherIncomeContainsAlimony"
                    [readonly]="fieldReadonly$">
                </finprocess-boolean-input>

                <finprocess-textarea-input
                    [label]="'financing.features.financing-processing.debitor.notesIncome' | translate"
                    [placeholder]="'...'"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [entity]="debitor.debitor"
                    fieldName="notesIncome">
                </finprocess-textarea-input>

                <finprocess-textarea-input
                    [label]="'financing.features.financing-processing.debitor.notesOtherIncome' | translate"
                    [placeholder]="'...'"
                    [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [entity]="debitor.debitor"
                    fieldName="notesOtherIncome">
                </finprocess-textarea-input>

                <ucba-expansion-panel #kimvExpansionPanel>
                    <div ucba-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
                        <b class="color-primary">{{ 'financing.features.financing-processing.debitor.transferChargesKimVSubtitle' | translate }}</b>
                        <div>
                            {{ 'financing.features.financing-processing.debitor.childrenCountCustomer' | translate }}:
                            {{ numberOfChildrenWithOverwrites$ | async }}
                        </div>
                    </div>
                    <div *ngIf="!kimvExpansionPanel.expanded()" ucba-expansion-panel-subheader>
                        <finprocess-calculation-display
                            [numberFunctionObservable]="transferChargesKimV$"
                            [label]="'financing.features.financing-processing.debitor.transferChargesKimV' | translate">
                        </finprocess-calculation-display>
                    </div>
                    <div ucba-expansion-panel-content>
                        <finprocess-integer-input
                            [label]="'financing.features.financing-processing.debitor.numberOfDependentChildren' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [overwrite]="false"
                            [entityClassType]="entityTypeDebtor"
                            [readonly]="fieldReadonly$"
                            [entity]="debitor.debitor"
                            fieldName="numberOfDependentChildren">
                        </finprocess-integer-input>
                        <finprocess-integer-input
                            [label]="'financing.features.financing-processing.debitor.numberOfChildrenForChildSupport' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [overwrite]="false"
                            [entityClassType]="entityTypeDebtor"
                            [readonly]="fieldReadonly$"
                            [entity]="debitor.debitor"
                            fieldName="numberOfChildrenForChildSupport">
                        </finprocess-integer-input>
                        <finprocess-integer-input
                            [label]="'financing.features.financing-processing.debitor.numberOfChildrenReceivingChildSupport' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [overwrite]="false"
                            [entityClassType]="entityTypeDebtor"
                            [readonly]="fieldReadonly$"
                            [entity]="debitor.debitor"
                            fieldName="numberOfChildrenReceivingChildSupport">
                        </finprocess-integer-input>
                        <finprocess-calculation-display
                            [numberFunctionObservable]="transferChargesKimV$"
                            [label]="'financing.features.financing-processing.debitor.transferChargesKimV' | translate">
                        </finprocess-calculation-display>
                    </div>
                </ucba-expansion-panel>
            </div>
        </ng-container>
    </div>

    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
