<ng-container *ngIf="(tabSettings$ | async) as tabSettings">
    <div *ngIf="(financingState$ | async) as financingState" fxFill>
        <div class="list-wrapper">
            <div *ngIf="loading" fxFill fxLayout="row" fxLayoutAlign="center center">
                <waiter-component [isLoading]="loading"></waiter-component>
            </div>
            <div *ngIf="!loading" fxFill>
                <div fxFill fxLayout="row" fxLayoutAlign="center center"
                    *ngIf="financingState.financings[tabSettings.currentPage - 1] === undefined || financingState.financings[tabSettings.currentPage - 1].length === 0">
                    <span>{{'dashboard.features.dashboard.noRequests' | translate }}</span>
                </div>
                <mat-card appearance="outlined" [class.mt-1]="i > 0"
                    *ngFor="let financing of financingState.financings[tabSettings.currentPage - 1]; index as i"
                    fxLayout="row wrap" fxLayoutAlign="start start">
                    <div fxFlex="20" fxFlex.lt-lg="33" fxFlex.lt-md="50" fxFlex.lt-xs="100" fxLayout="column"
                        fxLayoutAlign="space-between start" fxFlexAlign="stretch">
                        <div class="pt-2"><span class="mat-subtitle-2">{{financing.debitors[0].firstName}}
                                {{ financing.debitors[0].lastName }}</span></div>
                        <div class="pb-2">{{ financing.debitors[0].customerNumber !== undefined ?
                            ('dashboard.features.dashboard.customerNumber' | translate) : '' }}
                            {{ financing.debitors[0].customerNumber }}</div>
                    </div>
                    <div fxFlex="20" fxFlex.lt-lg="33" fxFlex.lt-md="50" fxFlex.lt-xs="100" fxLayout="column"
                        fxLayoutAlign="space-between start" fxFlexAlign="stretch">
                        <div class="pt-2"><span class="mat-subtitle-2">{{ financing.debitors[1]?.firstName }}
                                {{ financing.debitors[1]?.lastName }}</span></div>
                        <div class="pb-2" *ngIf="financing.debitors[1]?.customerNumber">
                            {{ 'dashboard.features.dashboard.customerNumber' | translate }}: {{
                            financing.debitors[1].customerNumber }}
                        </div>
                    </div>
                    <div fxFlex="15" fxFlex.lt-lg="33" fxFlex.lt-md="50" fxFlex.lt-xs="100" fxLayout="column"
                        fxLayoutAlign="space-between start" fxFlexAlign="stretch">
                        <div class="pt-2"><span class="mat-subtitle-2">{{ financing.submissionDate | date:'EEEEEE, dd.MM.HH:mm' }}</span></div>
                        <div *ngIf="!!financing.communityCustomerNumber">
                            {{'dashboard.features.dashboard.communityCustomerNumber' | translate}}
                            {{financing.communityCustomerNumber}}
                        </div>
                    </div>
                    <div fxFlex="20" fxFlex.lt-lg="33" fxFlex.lt-md="50" fxFlex.lt-xs="100" fxLayout="column"
                        fxLayoutAlign="space-between start" fxFlexAlign="stretch">
                        <div class="pt-2">{{'dashboard.features.dashboard.systemNumber' | translate}} {{
                            financing.systemNumber }}
                        </div>
                        <div class="pb-2">{{ financing.financingStatus | finProcessStatus:financing.financingSubStatus}}
                        </div>
                    </div>
                    <div fxFlex="10" fxFlex.lt-lg="33" fxFlex.lt-md="50" fxFlex.lt-xs="100" fxLayout="column"
                        fxLayoutAlign="space-between start" fxFlexAlign="stretch">
                        <div class="pt-2"
                            matTooltip="{{financing.editor?.firstName + ' ' + financing.editor?.lastName}}"
                            matTooltipPosition="above">
                            {{ financing.editor?.shortName }}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px">
                            <div class="pb-2" *ngIf="isEnglish(financing.requestedLanguages)" matTooltip="{{ 'dashboard.features.dashboard.englishRequested' | translate }}">
                                <mat-icon svgIcon="finprocess-uk"></mat-icon>
                            </div>
                            <div class="pb-2" *ngIf="isEmployeesSkill(financing.financingPermissions)" matTooltip="{{ 'dashboard.features.dashboard.employeeLoan' | translate }}">
                                <mat-icon svgIcon="finprocess-bank-logo"></mat-icon>
                            </div>
                        </div>
                    </div>

                    <div fxFlex fxLayout="column" fxLayoutAlign="center end" fxFlexAlign="stretch">
                        <div class="py-2" fxLayout="row" fxLayoutAlign="end center">
                            <ng-container
                                *ngIf="tabId === DashboardTab.All && !(financing.financingStatus === FinancingStatus.Rejected && financing.financingSubStatus === FinancingSubStatus.AutomaticallyRejected)">
                                <button class="bg-dark"
                                    [matTooltip]="'dashboard.features.dashboard.balance' | translate" mat-mini-fab
                                    *finprocessAuthorization="Role.LoadBalancingInternal"
                                    (click)="balance(financing.id)">
                                    <mat-icon style="font-size: 20px; margin-top: 3px; color: #000;">switch_account</mat-icon>
                                </button>
                            </ng-container>
                            <button class="ml-3"  [matTooltip]="'dashboard.features.dashboard.open' | translate"
                                mat-mini-fab color="primary"  *finprocessAuthorization="readRoleObject"
                                (click)="open(financing.id)">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="paginator-wrapper">
            <mat-divider></mat-divider>
            <mat-paginator [length]="financingState.financingsCount"
                [pageSize]="tabSettings.currentPageSize ?? financingState.financingsCount"
                [pageSizeOptions]="getPageSizeOptions(financingState.financingsCount)"
                [pageIndex]="tabSettings.currentPage - 1" [disabled]="financingState.financingsCount <= 10"
                showFirstLastButtons (page)="changePageOrPageSize($event, tabSettings.currentPageSize ?? financingState.financingsCount)">
            </mat-paginator>
        </div>
    </div>
</ng-container>
