import { EnumSettings } from './../enum.settings';

/**
 * Finanzierungszweck
 */
export enum CreditPurpose {
    /**
     * Errichtung
     */
    Construction = 0,

    /**
     * Kauf
     */
    Purchase = 1,

    /**
     * Erweiterung / Ausbau
     */
    Expansion = 2,

    /**
     * Renovierung
     */
    Refurbishment = 3,

    /**
     * Technische Ausstattung
     */
    TechEquipment = 4,

    /**
     * Sonstiges
     */
    Other = 5,

    /**
     * Umschuldung
     */
    Rescheduling = 6,
}

export namespace CreditPurpose {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {CreditPurpose | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: CreditPurpose | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'CreditPurpose', CreditPurpose, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('CreditPurpose', plocale);
}
