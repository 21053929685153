<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start stretch"  class="container">

    <div fxLayout="column" fxLayoutGap="15px">
        <h3>{{ "administration.features.country.birthCountries" | translate}}</h3>
        <mat-list>
            <mat-list-item *ngFor="let forBirthCountry of useForBirthCountryList">
                {{forBirthCountry.name}}
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
        <mat-form-field appearance="fill">
            <mat-label>{{ "administration.features.country.birthCountry" | translate}}</mat-label>
                <mat-select [(value)]="selectedCountryBirth" [disabled]="!userCanEdit">
                    <mat-option>{{ "general.noSelection" | translate}}</mat-option>
                    <mat-option *ngFor="let country of notUseForBirthCountryList" [value]="country">
                        {{country.name}}
                    </mat-option>
                </mat-select>
        </mat-form-field>
        <button (click)="onEditBirthCountry()" [disabled]="!selectedCountryBirth" mat-flat-button color="primary" *finprocessAuthorization="Role.SelectListsAdministrator">
            <mat-icon>add</mat-icon>
            {{ "button.add" | translate}}
        </button>
    </div>

    <mat-divider fxHide.lt-md [vertical]="true" class="cute-divider"></mat-divider>

    <div fxLayout="column" fxLayoutGap="15px"  >
    <h3>{{ "administration.features.country.citizenshipCountries" | translate}}</h3>
        <mat-list>
            <mat-list-item *ngFor="let forCiticenship of useForCitizenshipList">
                {{forCiticenship.name}}
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
        <mat-form-field appearance="fill">
            <mat-label>{{ "administration.features.country.citizenshipCountry" | translate}}</mat-label>
                <mat-select [(value)]="selectedCountryCitizenship" [disabled]="!userCanEdit">
                    <mat-option>{{ "general.noSelection" | translate}}</mat-option>
                    <mat-option *ngFor="let country of notUseForCitizenshipList" [value]="country">
                        {{country.name}}
                    </mat-option>
                </mat-select>
        </mat-form-field>
        <button (click)="onEditCitizenshipCountry()" [disabled]="!selectedCountryCitizenship" mat-flat-button color="primary" *finprocessAuthorization="Role.SelectListsAdministrator">
            <mat-icon>add</mat-icon>
            {{ "button.add" | translate}}
        </button>
    </div>

    <mat-divider fxHide.lt-md [vertical]="true" class="cute-divider"></mat-divider>

    <div fxLayout="column" fxLayoutGap="15px" >
        <h3>{{ "administration.features.country.taxCountries" | translate}}</h3>
        <mat-list>
            <mat-list-item *ngFor="let forTaxResidence of useForTaxResidenceList">
                {{forTaxResidence.name}}
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
        <mat-form-field appearance="fill">
            <mat-label>{{ "administration.features.country.taxCountry" | translate}}</mat-label>
                <mat-select [(value)]="selectedCountryTaxResidence" [disabled]="!userCanEdit">
                    <mat-option>{{ "general.noSelection" | translate}}</mat-option>
                    <mat-option *ngFor="let country of notUseForTaxResidenceList" [value]="country">
                        {{country.name}}
                        <mat-divider></mat-divider>
                    </mat-option>
                </mat-select>
        </mat-form-field>
        <button (click)="onEditResidenceCountry()" [disabled]="!selectedCountryTaxResidence" mat-flat-button color="primary" *finprocessAuthorization="Role.SelectListsAdministrator">
            <mat-icon>add</mat-icon>
            {{ "button.add" | translate}}
        </button>
    </div>

</div>
