export enum SubStatusCode
{
    NotCurrentUserOfMap = 1,
    ActionNotAllowedInState = 2,
    SampleCalculationMissing = 3,
    ESISMissing = 4,
    TemporaryUserIsEditing = 5,
    MediaTypeNotAccepted = 6,
    DropFolderNotExists = 7,
    DropAreaNotExists = 8,
    DocumentTypeAlreadyExists = 9,
    DocumentsExists = 10,
    SmartDocMapAlreadyExists = 11,
    ZipFileIsPasswordProtected = 12,
    PdfFileIsPasswordProtected = 13,
    NoSmartDocSortResultsToSend = 14,
    NotAssignedSmartDocSortResultsExists = 15,
    TemporaryUserDoesNotHaveEnoughSkill = 16,
    UserHasOpenFinancingMapWithFinancingPermissions = 17,
    UserHasOpenFinancingMapWithSalesPartnerCenter = 18,
    ActionNotAllowedForMandantId = 19,
    UserHasOpenFinancingMapWithUserPurpose = 20,
    ApproverCompetenceTooLow = 21,
    NotCurrentApproverOfProductPackage = 22,
    NotCurrentExpertOfMap = 23,
    NotBackOffice = 24,
}
