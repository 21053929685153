export enum KindOfResidence {
    /**
     * Eigentumswohnung
     */
    Condominium,

    /**
     * Gemeindewohnung
     */
    CouncilFlat,

    /**
     * Miete
     */
    RentedFlatOrHouse,

    /**
     * Genossenschaftswohnung
     */
    CooparativeFlat,

    /**
     * Untermiete
     */
    Sublease,

    /**
     * Eigenheim
     */
    Homestead,
}
