import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService, AuthorizationService, LoginStateType } from 'auth';
import { Observable, Subject, take } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { ConfigService } from 'shared/util';

import { IExternalSystemsAvailability } from '../../interfaces';
import { SystemConfiguration } from '../../interfaces/system-configuration.interface';
import { ExternalSystemsLoaded, SystemsConfigurationLoaded } from '../../states/system-configuration/system-configuration.actions';


/**
 * Service zum Abruf der Systemkonfiguration
 */
@Injectable()
export class SystemConfigurationService implements OnDestroy {

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {Store} store Stroe-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private authenticationService: AuthenticationService,
    ) {

    }

    /**
     * Angular-Hook beim Enfernen des Service
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Initialisiert die Systemkonfiguration
     */
    public initialize(): void {
        this.authenticationService.loginSuccessful$.pipe(
            takeUntil(this.onDestroy$),
            mergeMap(() => this.loadExternalSystemsAvailability()),
            mergeMap(() => this.loadSystemConfiguration()),
        ).subscribe();


    }

    /**
     * Lädt die Systemkonfiguration
     *
     * @returns {Observable<void>} Observable, wenn Stammdaten geladen
     */
    public loadExternalSystemsAvailability(): Observable<IExternalSystemsAvailability> {
        return this.authenticationService.loginState$.pipe(
            take(1),
            mergeMap(() => this.httpClient.get<IExternalSystemsAvailability>(`${this.config.getEnvironment().apiUrl}/globalfunctions/ExternalSystemsAvailability`)),
            mergeMap(availability => this.store.dispatch(new ExternalSystemsLoaded(availability))),
        );
    }

    /**
     * Lädt die Systemkonfiguration
     *
     * @returns {Observable<void>} Observable, wenn Stammdaten geladen
     */
    public loadSystemConfiguration(): Observable<SystemConfiguration | undefined> {
        return this.authenticationService.loginState$.pipe(
            filter(loginState => loginState === LoginStateType.LoggedIn),
            take(1),
            mergeMap(() => this.httpClient.get<SystemConfiguration>(`${this.config.getEnvironment().apiUrl}/AdminAreaConfiguration`)),
            mergeMap(systemConfiguration => this.store.dispatch(new SystemsConfigurationLoaded(systemConfiguration))),
        );
    }
}
