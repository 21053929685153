import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { WaiterService } from '@ntag-ef/waiter';
import { UserService } from 'app/modules/auth/data';
import { FinancingEventType, IInformationEvent, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { DocumentTag, ISmartDocJobThumbnailViewModel, ISmartDocStateParentDefinition } from 'app/modules/smartdoc/data';
import { sort } from 'fast-sort';
import { Observable, Subject, combineLatest, forkJoin, iif, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HelperService } from 'shared/util';

interface ILateSubmission {
    documents: IDocumentView[];

    event: IInformationEvent;
}

import {
    DocumentService,
    FinancingService,
    FinancingState,
    IDocument,
    IDocumentView,
    IFile,
    IFinancing,
    IFinancingStateParentDefinition,
} from '../../../../data';
/**
 * Komponente für den Tab Belege
 */
@Component({
    selector: 'finprocess-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {

    @Select(FinancingState.allDocuments)
    public documents$!: Observable<IDocument[]>;

    /**
     * Dokumente der Einreichung vom Kunden
     */
    public submittedDocuments: IDocumentView[] = [];

    /**
     * Rechenbeispiele und ESIS
     */
    public calculations: IDocumentView[] = [];

    /**
     * Nachweise für nachhaltiges Einkommen und Haushaltsrechnungen
     */
    public incomeDocuments: IDocumentView[] = [];

    /**
     * Nachreichungen
     */
    public lateSubmissions: ILateSubmission[] = [];

    /**
     * Durch Smartdoc verarbeitete Dokumente
     */
    public arrangedDocuments: IDocumentView[] = [];

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Smartdoc thumbnails
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocThumbnails)
    public smartdocThumbnails$!: Observable<ISmartDocJobThumbnailViewModel[]>

    public isEditor$!: Observable<boolean>;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {DocumentService} documentService DocumentService,
     * @param {Store} store Store
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {InformationEventService} informationEventService InformationEventService-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(
        private documentService: DocumentService,
        private store: Store,
        private waiterService: WaiterService,
        private translate: TranslateService,
        private userService: UserService,
        private informationEventService: InformationEventService,
        private financingService: FinancingService,
    ) { }


    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.init();
    }

    /**
     * Opens clicked file in a new tab
     *
     * @param {IFile} file given file
     */
    public openFileInNewTab(file: IFile): void {
        this.documentService.loadFile(file.id)
            .subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }

                },
            });
    }

    /**
     * Init
     */
    public init(): void {
        this.isEditor$ = this.financingService.isEditor$;

        combineLatest([this.documents$.pipe(
            takeUntil(this.onDestroy$),
            map(documents => documents.map(document => this.store.selectSnapshot(FinancingState.documentViewModel)(document))),
            map(documents => sort(documents).asc([
                doc => DocumentType.translate(doc.document.type),
                doc => doc.position,
            ])),
        ), this.store.select(InformationEventState.allInformationEvents).pipe(
            takeUntil(this.onDestroy$),
            map(events => sort(events.filter(event => event.eventType === FinancingEventType.DocumentsReceived)
                .reduce((acc, currentEvent) => acc.concat(currentEvent.events), [] as IInformationEvent[])).desc(event => event.eventDate)),
        )])
            .subscribe(([documents, informationEvents]) => {
                this.sortDocuments(documents, informationEvents);
            });
    }

    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Lädt alle relevanten Nachreichungen als zip herunter
     * In #1564 stehen die Filterkriterien
     *
     * @param {ILateSubmission} lateSubmission Ausgewählte Nachreichung
     */
    public downloadSubsequents(lateSubmission: ILateSubmission): void {
        this.waiterService.show({
            text: this.translate.instant('financing.features.financing-processing.downloadDocuments'),
        });

        const container = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer);
        const isExpert = this.store.selectSnapshot(FinancingState.isExpert);

        if (!container) {
            return;
        }

        forkJoin([
            this.documentService.downloadFiles({
                id: container.id,
                fileIds: lateSubmission.documents.reduce<string[]>((fileIds, currentDocument) => fileIds.concat(currentDocument.document.files.map(file => file.id)), []),
            }),
            iif(
                () => isExpert && lateSubmission.event !== undefined && !lateSubmission.event.isRead,
                this.informationEventService.changeReadStatus({
                    eventIds: [lateSubmission.event.id],
                    finProcessContainerId: container.id,
                }, true),
                of(undefined),
            ),
        ]).subscribe({
            next: async ([arrayBuffer]) => {

                if (!arrayBuffer) {
                    return;
                }
                const blob = new Blob([arrayBuffer], { type: 'application/zip' });
                const mainDebitor = this.store.selectSnapshot(FinancingState.mainDebitor);
                const mainDebitorName = !!mainDebitor
                    ? `${mainDebitor.lastName}_${mainDebitor.firstName}`
                    : '';
                const now = new Date();
                const dateString = `${now.getFullYear()}${now.getMonth().toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`

                await HelperService.downloadFileFromBlob(blob, `Belege_${mainDebitorName}_${dateString}`);

                this.waiterService.hide();
            }, error: () => this.waiterService.hide(),
        });
    }

    /**
     * Sortiert die Komponente in Einreichung, Rechenbeispiele und Nachreichungen
     *
     * @param {IDocumentView[]} documents Alle Dokumente der Finanzierung
     * @param {IInformationEvent[]} informationEvents DocumentReceived Events
     */
    public sortDocuments(documents: IDocumentView[], informationEvents: IInformationEvent[]): void {
        // Rechenbeispiele und ESIS
        const calculations: IDocumentView[] = [];

        // Dokumente von Quellsystem hochgeladen
        const submittedDocuments: IDocumentView[] = [];

        // Nachweise für nachhaltiges Einkommen und Haushaltsrechnung
        const incomeDocuments: IDocumentView[] = [];

        for (const document of documents) {
            if (HelperService.hasBit(document.document.tag, DocumentTag.SmartDocProcessed)) {
                this.arrangedDocuments.push(document);
            } else if ([
                DocumentType.SampleCalculation,
                DocumentType.SampleCalculationVariable,
                DocumentType.ESIS,
            ].includes(document.document.type as number)) {
                calculations.push(document);
            } else if ([
                DocumentType.SustainableIncome,
            ].includes(document.document.type as number)) {
                incomeDocuments.push(document);
            }
            else {
                submittedDocuments.push(document);
            }
        }

        const lateSubmissions: ILateSubmission[] = [];

        for (const informationEvent of informationEvents) {
            const fileIds = informationEvent.eventValues?.filter(eventValue => eventValue.key === 'FileId').map(eventValue => eventValue.value as string) ?? [];

            const lateSubmission: ILateSubmission = {
                documents: [],
                event: informationEvent,
            };

            for (const document of submittedDocuments) {
                const files: IFile[] = [];

                for (const file of document.document.files) {
                    if (fileIds.includes(file.id)) {
                        files.push(file);
                    }
                }

                if (files.length > 0) {
                    lateSubmission.documents.push({
                        parentName: document.parentName,
                        position: document.position,
                        document: {
                            ...document.document,
                            files,
                        },
                    });
                }
            }

            if (lateSubmission.documents.length > 0) {
                lateSubmissions.push(lateSubmission);
            }
        }

        this.calculations = calculations;
        this.lateSubmissions = lateSubmissions;
        this.incomeDocuments = incomeDocuments;
        this.submittedDocuments = submittedDocuments.map(document => {
            let correctFiles = [...document.document.files];

            for (const lateSubmission of lateSubmissions) {
                const lateSubmissionFileIds = lateSubmission.documents.reduce((acc, current) => acc.concat(current.document.files.map(file => file.id)), [] as string[]);

                correctFiles = correctFiles.filter(file => !lateSubmissionFileIds.includes(file.id));
            }

            if (correctFiles.length === document.document.files.length) {
                return document;
            } else if (correctFiles.length === 0) {
                return undefined;
            } else {
                return {
                    parentName: document.parentName,
                    position: document.position,
                    document: {
                        ...document.document,
                        files: correctFiles,
                    },
                } as IDocumentView;
            }
        }).filter(doc => doc !== undefined) as IDocumentView[];
    }
}
