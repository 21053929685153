import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const GET_USER_DETAILS: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'getUserDetails',

    /**
     * ID eines im System bekannten Debitors
     */
    ndg:{ type: WorkflowType.String },
}
