<mat-tab-group mat-stretch-tabs="false" *ngIf="sheets.length > 0">
    <mat-tab *ngFor="let sheet of sheets; let i = index" label="Haushalt {{i + 1}}" >
    <div  fxLayout="row" fxLayoutAlign="end start" class="mr-2 mt-2" >
            <button mat-raised-button color="primary" class="note-icon mr-2" matTooltip="Dokument öffnen"
                *ngIf="mandantType.BAF !== financingMandantType"
                [disabled]="sheet?.additionalSheetFileId === undefined || isSheetChangedNewDocumentNotGenerated || (fieldReadonly$ | async)"
                (click)="openFile(sheet?.additionalSheetFileId)">
                {{ "financing.features.financing-processing.additionalSheets.openDocument" | translate }}
            </button>

            <button mat-raised-button color="primary" class="note-icon"
                *ngIf="mandantType.BAF !== financingMandantType"
                (click)="generateDocument(sheet.householdId)"
                [disabled]="(!sheet.creditDurationInRetirementAdditionalSheet && !sheet.otherIncomeContainsAlimonyAdditionalSheet && !sheet.compositionOfIncomeAdditionalSheet) || isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                {{ "financing.features.financing-processing.additionalSheets.generateDocument" | translate }}
            </button>
    </div>
    <div fxLayout="row wrap" class="ml-3">
        <div class="mt-3 mr-3">
            <mat-card appearance="outlined" class="example-card">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title fxLayout="row" fxLayoutAlign="start space-between">
                            <mat-checkbox *ngIf="mandantType.BAF !== financingMandantType" color="primary" class="mr-2" 
                                [checked]="sheet.creditDurationInRetirementAdditionalSheet"
                                [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)"
                                (change)="changeStatus(documentType.CreditDurationInRetirementSignature, !sheet.creditDurationInRetirementAdditionalSheet, sheet)">
                            </mat-checkbox>
                            <div class="card-title">
                                {{ "financing.features.financing-processing.additionalSheets.pensionTitle" | translate }}
                            </div>
                        </mat-card-title>
                        
                        <mat-card-subtitle class="card-subtitle" 
                            [innerHTML]="onSanitize(sheet?.creditDurationInRetirementAdditionalSheetText)">
                        </mat-card-subtitle>
                    </mat-card-title-group>
                </mat-card-header>

                <mat-card-content>
                    <div *ngIf="!!sheet?.additionalSheetDocumentCreate" fxLayout="column" fxLayoutAlign="center center" class="mb-2">
                        <div style="font-size:small;"> 
                                {{ "financing.features.financing-processing.additionalSheets.docDate" | translate }}
                                {{ sheet?.additionalSheetDocumentCreate | date }}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-stroked-button class="mr-2 note-icon"
                            *ngIf="mandantType.BAF !== financingMandantType"
                            (click)="showHideNoteEditor(documentType.CreditDurationInRetirementSignature)" 
                            [disabled]="!sheet.creditDurationInRetirementAdditionalSheet || isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                                {{ "financing.features.financing-processing.additionalSheets.note" | translate }}
                                <mat-icon *ngIf="!showNoteEditorPension">visibility</mat-icon>
                                <mat-icon *ngIf="showNoteEditorPension">visibility_off</mat-icon>
                        </button>

                        <a *ngIf="mandantType.BAF === financingMandantType" mat-raised-button href="javascript:void(0)" color="primary" class="mr-2" (click)="file.click()"
                            [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                            <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false" 
                                (change)="uploadFile($event.target, sheet, documentType.CreditDurationInRetirementSignature, sheet?.creditDurationInRetirementAdditionalSheetFileId)"/>
                            {{ "financing.features.financing-processing.additionalSheets.uploadDocument" | translate }}
                        </a>

                        <button mat-stroked-button class="note-icon" matTooltip="Dokument öffnen"
                            *ngIf="mandantType.BAF === financingMandantType"
                            [disabled]="sheet?.creditDurationInRetirementAdditionalSheetFileId === undefined || (fieldReadonly$ | async)"
                            (click)="openFile(sheet?.creditDurationInRetirementAdditionalSheetFileId)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="showNoteEditorPension && sheet.creditDurationInRetirementAdditionalSheet" class="mt-3">
                        <ckeditor [editor]="editor" 
                                [data]="sheet?.creditDurationInRetirementAdditionalSheetText ?? ''" 
                                [config]="editorConfig"
                                (change)="onEditorChangeNote($event, sheet, documentType.CreditDurationInRetirementSignature)">
                        </ckeditor>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="mt-3 mr-3">
            <mat-card appearance="outlined" class="example-card">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title fxLayout="row" fxLayoutAlign="start space-between">
                            <mat-checkbox color="primary" class="mr-2"
                                *ngIf="mandantType.BAF !== financingMandantType"  
                                [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)"
                                [checked]="sheet.otherIncomeContainsAlimonyAdditionalSheet"
                                (change)="changeStatus(documentType.CompositionOfOtherIncomeSignature, !sheet.otherIncomeContainsAlimonyAdditionalSheet, sheet)">
                            </mat-checkbox>
                            <div class="card-title">
                                {{ "financing.features.financing-processing.additionalSheets.alTitle" | translate }}
                            </div>
                        </mat-card-title>
                        <mat-card-subtitle class="card-subtitle"
                            [innerHTML]="onSanitize(sheet?.otherIncomeContainsAlimonyAdditionalSheetText)">
                        </mat-card-subtitle>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>

                    <div *ngIf="!!sheet?.additionalSheetDocumentCreate" fxLayout="column" fxLayoutAlign="center center" class="mb-2">
                        <div style="font-size:small;">
                            {{ "financing.features.financing-processing.additionalSheets.docDate" | translate }}
                            {{ sheet?.additionalSheetDocumentCreate | date }}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-stroked-button class="mr-2 note-icon"
                            *ngIf="mandantType.BAF !== financingMandantType"
                            (click)="showHideNoteEditor(documentType.CompositionOfOtherIncomeSignature)"
                            [disabled]="!sheet.otherIncomeContainsAlimonyAdditionalSheet || isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                            {{ "financing.features.financing-processing.additionalSheets.note" | translate }}
                            <mat-icon *ngIf="!showNoteEditorAlimony">visibility</mat-icon>
                            <mat-icon *ngIf="showNoteEditorAlimony">visibility_off</mat-icon>
                        </button>

                        <a *ngIf="mandantType.BAF === financingMandantType" mat-raised-button href="javascript:void(0)" color="primary" class="mr-2" (click)="file.click()"
                            [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                            <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false" 
                                (change)="uploadFile($event.target, sheet, documentType.CompositionOfOtherIncomeSignature, sheet?.otherIncomeContainsAlimonyAdditionalSheetFileId)"/>
                            {{ "financing.features.financing-processing.additionalSheets.uploadDocument" | translate }}
                        </a>

                        <button mat-stroked-button class="note-icon" matTooltip="Dokument öffnen"
                            *ngIf="mandantType.BAF === financingMandantType"
                            [disabled]="sheet?.otherIncomeContainsAlimonyAdditionalSheetFileId === undefined || (fieldReadonly$ | async)"
                            (click)="openFile(sheet?.otherIncomeContainsAlimonyAdditionalSheetFileId)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="showNoteEditorAlimony && sheet.otherIncomeContainsAlimonyAdditionalSheet" class="mt-3">
                        <ckeditor 
                            [editor]="editor" 
                            [data]="sheet?.otherIncomeContainsAlimonyAdditionalSheetText ?? ''" 
                            [config]="editorConfig"
                            (change)="onEditorChangeNote($event, sheet, documentType.CompositionOfOtherIncomeSignature)">
                        </ckeditor>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="mt-3 mr-3">
            <mat-card appearance="outlined" class="example-card">
                <mat-card-header>
                    <mat-card-title-group>

  
                        <mat-card-title fxLayout="row" fxLayoutAlign="start space-between">
                            <mat-checkbox *ngIf="mandantType.BAF !== financingMandantType" color="primary" class="mr-2"
                                [checked]="sheet.compositionOfIncomeAdditionalSheet"
                                [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)"
                                (change)="changeStatus(documentType.CompositionOfIncomeSignature, !sheet.compositionOfIncomeAdditionalSheet, sheet)">
                            </mat-checkbox>
                            <div class="card-title">
                                {{ "financing.features.financing-processing.additionalSheets.incTitle" | translate }}
                            </div>
                        </mat-card-title>
                        <mat-card-subtitle class="card-subtitle" 
                            [innerHTML]="onSanitize(sheet?.compositionOfIncomeAdditionalSheetText)">
                        </mat-card-subtitle>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>

                    <div *ngIf="!!sheet?.additionalSheetDocumentCreate" fxLayout="column" fxLayoutAlign="center center" class="mb-2">
                        <div style="font-size:small;">
                            {{ "financing.features.financing-processing.additionalSheets.docDate" | translate }}
                            {{ sheet?.additionalSheetDocumentCreate | date }}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-stroked-button class="mr-2 note-icon"
                            *ngIf="mandantType.BAF !== financingMandantType"
                            [disabled]="!sheet.compositionOfIncomeAdditionalSheet || isStatusNotOpenOrWaiting || (fieldReadonly$ | async)"
                            (click)="showHideNoteEditor(documentType.CompositionOfIncomeSignature)">                                
                                {{ "financing.features.financing-processing.additionalSheets.note" | translate }}
                                <mat-icon *ngIf="!showNoteEditorIncome">visibility</mat-icon>
                                <mat-icon *ngIf="showNoteEditorIncome">visibility_off</mat-icon>
                        </button>

                        <a *ngIf="mandantType.BAF === financingMandantType" mat-raised-button href="javascript:void(0)" color="primary" class="mr-2" (click)="file.click()"
                            [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                            <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false" 
                                (change)="uploadFile($event.target, sheet, documentType.CompositionOfIncomeSignature, sheet?.compositionOfIncomeAdditionalSheetFileId)"/>
                            {{ "financing.features.financing-processing.additionalSheets.uploadDocument" | translate }}
                        </a>

                        <button mat-stroked-button class="note-icon" matTooltip="Dokument öffnen"
                            *ngIf="mandantType.BAF === financingMandantType"
                            [disabled]="sheet?.compositionOfIncomeAdditionalSheetFileId === undefined || (fieldReadonly$ | async)"
                            (click)="openFile(sheet?.compositionOfIncomeAdditionalSheetFileId)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="showNoteEditorIncome && sheet.compositionOfIncomeAdditionalSheet" class="mt-3">
                        <ckeditor 
                            [editor]="editor" 
                            [data]="sheet?.compositionOfIncomeAdditionalSheetText ?? ''" 
                            [config]="editorConfig"
                            (change)="onEditorChangeNote($event, sheet, documentType.CompositionOfIncomeSignature)">
                        </ckeditor>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    </mat-tab>
</mat-tab-group>