/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IUser, Logout } from 'app/modules/auth/data';

import { UserAdministrationLoaded, UserAdministrationUserConfirmed, UserEdited, UsersEdited} from './user-administration.action';

/**
 * Zustandsobjekt für alle existierenden Nutzer
 */
export interface IUserAdministrationStateModel {
    /**
     * Alle aktuell existierenden Nutzer
     */
    users: IUser[];
}

/**
 * Zustand für alle existierenden Nutzer
 */
@State<IUserAdministrationStateModel>({
    name: UserAdministrationState.keyName,
    defaults: {
        users: [],
    },
})
@Injectable()
export class UserAdministrationState {
    public static readonly keyName = 'userAdministration';

    /**
     * Selektor für alle existierenden Nutzer
     *
     * @param {UserAdministrationState} state Zustandsobjekt für alle existierenden Nutzer
     * @returns {IUser} Alle existierenden Nutzer
     */
    @Selector()
    public static users(state: IUserAdministrationStateModel): IUser[] {
        return state.users;
    }

    /**
     * Zustandsänderung nach dem Laden aller bestehenden Nutzer
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserAdministrationLoaded} action Aktion
     */
    @Action(UserAdministrationLoaded)
    public usersLoaded({ patchState }: StateContext<IUserAdministrationStateModel>, { payload }: UserAdministrationLoaded): void {
        patchState({
            users: payload,
        });
    }

    /**
     * Zustandsänderung beim Bestätigen eines Nutzers
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserAdministrationUserConfirmed} action Aktion
     */
     @Action(UserAdministrationUserConfirmed)
    public userConfirmed({ getState, patchState }: StateContext<IUserAdministrationStateModel>, { payload }: UserAdministrationUserConfirmed): void {
        const users = getState().users.map(user => ({
            ...user,
            confirmed: user.id === payload ? true : user.confirmed,
        }));

        patchState({ users });
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IUserAdministrationStateModel>): void {
        setState({ users: []});
    }

    /**
     * Zustandsänderung beim Editieren einer Filiale
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserEdited} action Aktion
     */
    @Action(UserEdited)
    public userEdited({ getState, patchState }: StateContext<IUserAdministrationStateModel>, { payload }: UserEdited): void {
        const users = getState().users.map(user => {
            if (user.id === payload.id) {
                return {
                    ...user,
                    confirmed: payload.confirmed ?? false,
                    salesPartnerCenterId: payload.salesPartnerCenterId,
                    phoneNumber: payload.phoneNumber,
                    languages: payload.languages,
                    financingPermissions: payload.financingPermissions,
                }
            }
            return user;
        });

        patchState({ users });
    }

    /**
     * Zustandsänderung beim Editieren einer Filiale bei mehrere Users
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserEdited} action Aktion
     */
    @Action(UserEdited)
    public usersEdited({ getState, patchState }: StateContext<IUserAdministrationStateModel>, { payload }: UsersEdited): void {

     if (Array.isArray(payload)) {
         const newUsers = getState().users.map(user => {
             const newUser = payload.find(x => x.id === user.id)

                 if (!!newUser) {
                 return {
                     ...user,
                     confirmed: newUser.confirmed ?? false,
                     salesPartnerCenterId: newUser.salesPartnerCenterId,
                     phoneNumber: newUser.phoneNumber,
                     languages: newUser.languages,
                     financingPermissions: newUser.financingPermissions,
                 }
                 }
                 return user;
         })

         patchState({ users: newUsers });
         }
     }
}
