import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_PHONE_INFO_IN_V2 } from './customer-phone-info-in-v2';

export const CREATE_OR_UPDATE_PHONES: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'createOrUpdatePhones',

    ndg: { type: WorkflowType.String },

    createOrUpdateModel: { type: WorkflowType.Array, child: CUSTOMER_PHONE_INFO_IN_V2 },
}
