import { Component, EventEmitter, Input, Output, computed, input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ISelectItem } from 'app/modules/shared';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Wert für Auswahl Sonstiges
 */
const OTHER_KEY = 'otherSelect';

/**
 * Eingabefeld - Einfachauswahl
 */
@Component({
    selector: 'finprocess-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent<T extends IBase> extends OverwriteInputComponent<T> {


    /**
     * List of select items for the mat-select
     */
    public items = input<ISelectItem<T[keyof T] | string>[]>();

    /**
     * Should a selection for 'other' be shown which allows entering a custom value
     */
    public showOther = input<boolean>(false);

    /**
     * Should the tooltip be shown
     */
    public showTooltip = input<boolean>(false);

    /**
     * Check Changes Event
     */
    @Output() public checkChange = new EventEmitter<unknown>();

    /**
     * Internal list of items for the mat-select
     * Contains the items from the input and the 'other' option if selected
     */
    public internalItems = computed(() => {
        const items = this.items() ?? [];

        if (this.showOther()) {
            items.push({ value: OTHER_KEY, displayName: this.translate.instant('general.otherSelect')})
        }

        return items;
    });

    /**
     * If the 'other' option is selected
     */
    public otherIsSelected = computed(() => {
        const items = this.items() ?? [];
        const selectedValue = this.selectedValue();

        if (selectedValue === undefined || selectedValue === null) {
            return false;
        }

        return selectedValue === OTHER_KEY || items.every(item => item.value !== selectedValue);
    });


    /**
     * Variablentyp
     */
    @Input()
    public set valueStorageType(valueStorageType: ValueStorageType) {
        this.valueStorageTypeInternal = valueStorageType;
    }


    /**
     * Returns the tooltip for the icon showing the original value
     */
    public translatedOriginalValueKey = computed(() => {
        const items = this.internalItems();
        const originalValue = this.originalValue();

        const item = items.find(it => this.compareStringFallback(it.value, this.originalValue()));

        if (originalValue === undefined) {
            return item?.displayName ?? 'financing.features.financing-processing.unset';
        }

        return item?.displayName ?? originalValue?.toString() ?? '';
    });
    
    public selectedValue = computed(() => {
        const items = this.internalItems();
        const currentValue = this.currentValue();

        if (currentValue === undefined || currentValue === null) {
            return currentValue;
        }

        if (items.every(item => !this.compareStringFallback(item.value, currentValue))) {
            return OTHER_KEY;
        }

        if (items.every(item => typeof item.value === 'string')) {
            return currentValue.toString();
        }

        return currentValue;
    });

    public selectedOtherValue = computed(() => {
        const items = this.internalItems();
        const currentValue = this.currentValue();

        if (currentValue === undefined || currentValue === null) {
            return currentValue;
        }

        if (items.every(item => !this.compareStringFallback(item.value, currentValue))) {
            return currentValue;
        }

        return undefined;
    });

    public forceShowOtherField: boolean = false;

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(financingService: FinancingService, private translate: TranslateService) {
        super(ValueStorageType.Int, financingService);
    }

    /**
     * Save wrapper for the select input. If the value equals the OTHER_KEY, the other field is shown
     * 
     * @param {string | undefined} value Value
     * @param {NgModel} model Model
     */
    public saveSelectValue(value: T[keyof T & string] | undefined, model?: NgModel): void {
        if (value === OTHER_KEY) {
            this.forceShowOtherField = true;
        } else {
            this.forceShowOtherField = false;
        }

        this.save(value, model);
    }

    /**
     * Saves the overwrite
     *
     * @param {string | undefined} value Wert
     * @param {NgModel} model Model
     */
    public save(value: T[keyof T & string] | undefined, model?: NgModel): void {
        if (value !== OTHER_KEY && this.internalItems().some(item => item.value === value)) {
            const toSaveValue = this.changeValueIfInt(value);
            this.checkChange.emit(toSaveValue);
            super.save(toSaveValue, model);
        } else if (value !== OTHER_KEY) {
            const toSaveValue = this.changeValueIfInt(value);
            this.checkChange.emit(toSaveValue);
            super.save(toSaveValue, model);
        }

    }

    /**
     * Umwandeln value um Integer falls storage typ int
     * 
     * @param {string | undefined} value Wert
     * @returns {string | undefined} returnvalue
     */
    private changeValueIfInt(value: T[keyof T & string] | undefined): T[keyof T & string] | undefined {
        if (value !== undefined && value !== null && this.valueStorageTypeInternal === ValueStorageType.Int) {
            return parseInt(value.toString(), 10) as T[keyof T & string];
        } 
        return value;
    }
    /**
     * Comparing values of any types and fall back to string if types are not equal
     * 
     * @param {unknown} a First value 
     * @param {unknown} b Second value
     * @returns {boolean} Are a and b equal
     */
    private compareStringFallback<S, O>(a: S, b: O): boolean {
        if (typeof a === typeof b) {
            return a === (b as unknown as S);
        }

        return a?.toString() === b?.toString();
    }
}
