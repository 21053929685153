<h1 mat-dialog-title>{{'financing.features.financing-processing.householdBills.telecomTitle' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutGap="20px">

        <mat-form-field appearance="fill">
            <mat-label>{{'financing.features.financing-processing.householdBills.phoneCost' | translate }}</mat-label>
            <input matInput [(ngModel)]="newPhoneCost" currencyMask [options]="currencyMaskOptions">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'financing.features.financing-processing.householdBills.broadcastCost' | translate }}</mat-label>
            <input matInput [(ngModel)]="newBroadcastCost" currencyMask [options]="currencyMaskOptions">
        </mat-form-field>
        
    </div>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="saveTelecommunicationCosts()">{{'financing.features.financing-processing.householdBills.saveDialog' | translate }}</button>
    <button mat-button mat-dialog-close>{{'financing.features.financing-processing.householdBills.cancelDialog' | translate }}</button>
</div>