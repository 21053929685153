import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DomSanitizer } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CoveredBy, JointHeadingType, LiabilityBankType, LiabilityType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { IJointHeading } from 'app/modules/financing/data/interfaces/joint-heading.interface';
import { HelperService, ILiabilityConfiguration, UUID } from 'app/modules/shared';
import moment from 'moment';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { BehaviorSubject, Observable, Subject, combineLatest, startWith, takeUntil } from 'rxjs';

import { LiabilityCalculationService } from '../../../../../../../../../../libs/calculations/src/lib/services';
import { IFinancing, IFinancingStateParentDefinition, ILiabilityDebitor, ILiabilityDetail, ILiabilityUpdateForm, LiabilitiesService } from '../../../../data';
import { CreateJointheadingDialogComponent } from '../create-jointheading-dialog/create-jointheading-dialog.component';

type LiabilityRequest = 'add' | 'edit' | 'fictionalRate';

interface ILiabilityDialogData {
    editLiabilityData: ILiabilityDetail;
    editJointHeadingData: IJointHeading;
    liabilityRequest: LiabilityRequest;
}

interface ILiabilityForm {
    jointHeadingId: FormControl<UUID | null>;
    numberOfBorrowersInThisApplication: FormControl<number | null>;
    numberOfBorrowers: FormControl<number | null>;
    liabilityType: FormControl<LiabilityType | null>;
    ibanCreditor: FormControl<string | null>;
    liabilityBank: FormControl<LiabilityBankType | null>;
    started: FormControl<moment.Moment | null>;
    loanPeriodInMonths: FormControl<number | null>;
    initialAmount: FormControl<number | null>;
    currentAmount: FormControl<number | null>;
    interestRate: FormControl<number | null>;
    monthlyRate: FormControl<number | null>;
    creditLimit: FormControl<number | null>;
    fictionalRateAmount: FormControl<number | null>;
    covered: FormControl<boolean | null>;
    coveredBy: FormControl<CoveredBy | null>;
    collateralsValue: FormControl<number | null>;
    note: FormControl<string | null>;
    loanValue: FormControl<number | null>;
    isMonthlyRateManualInput: FormControl<boolean>;
}

/**
 * Anlegen von neuen Bestandskrediten
 */
@Component({
    selector: 'finprocess-add-liability-dialog',
    templateUrl: './add-liability-dialog.component.html',
    styleUrls: ['./add-liability-dialog.component.scss'],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class AddLiabilityDialogComponent implements OnInit, OnDestroy {

    /**
     * Formular
     */
    public liabilityForm: FormGroup<ILiabilityForm>;

    /**
     * Liste aller Joint Headings für die Auswahl
     */
    public jointHeadingList: IJointHeading[] = [];

    /**
     * ID des aktuellen Finanzierungscontainers
     */
    public financingContainerID?: UUID;

    /**
     * Liste der Standard Verbindlichkeitstypen für HTML
     */
    public liabilityType: LiabilityType[] = [];

    /**
     * Liste der Sonderfälle von Verbindlichkeitstypen für HTML
     */
    public liabilityTypeSpecial: LiabilityType[] = [];

    /**
     * Liste der Banktypen für HTML
     */
    public liabilityBank: LiabilityBankType[] = [];

    /**
     * Liste der Abdeckungsarten für HTML
     */
    public coveredBy: CoveredBy[] = [];

    /**
     * Aktueller fiktionaler Zinssatz für die ausgewählte Verbindlichkeitsart
     */
    public fictionalRate$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

    /**
     * Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;
    /**
     * Percentage Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;
    /**
     * Number Mask
     */
    public numberMaskOptions: NgxCurrencyConfig;

    /**
     * Berechnetes Laufzeitende der Verbindlichkeit
     */
    public calculatedEndDate?: string;

    /**
     * Berechnete Restlaufzeit der Verbindlichkeit
     */
    //public calculatedRemainingTimeInMonths?: number;
    public calculatedRemainingTimeInMonths$ = new BehaviorSubject<number | undefined>(undefined);

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Aktuelle Verbindlichkeitskonfiguration
     */
    private currentLiabilityConfiguration?: ILiabilityConfiguration;

    private onDestroy$ = new Subject<void>();

    //CK-Editor
    public editor = ClassicEditorBuild;
    public currentNoteLength = 0;
    public readonly maxCharCount = 4000;

    public editorConfig = {
        height: 500,
        autoGrow: {
            minHeight: 500,
        },
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
            ],
        },
    };

    //Notes Panel
    public panelOpenState = false;

    //list of liabilitiy types to check for creditlimit/initialAmount
    public checkLiabilityTypes = [
        LiabilityType.Overdraft,
        LiabilityType.CreditCard,
        LiabilityType.ConstructionInterimFinancing,
        LiabilityType.ConstructionPrefinancingInvestmentFlatLow,
        LiabilityType.ConstructionPrefinancingInvestmentFlatHigh,
        LiabilityType.ConstructionFollowUpFinancing,
        LiabilityType.ConstructionFollowUpFinancingBuildingLoan,
    ];

    /**
     * Calculation Version Berechnungsbibliothek
     */
    public calculationVersion?: number;

    // Auf Grund der Berechnungen müssen Kreditaufnahme (started) und Ursprüngliche Laufzeit (loanPeriodInMonths)
    // Backendseitig befüllt werden. Wir zeigen die Felder nicht, 
    // wenn Art der Verbindlichkeit "Überschreitung" oder "Kreditkarte" ist #13368
    public showStarted = true;

    /**
     * Show Security Value
     */
    public showSecurityValue = true;

    /**
     * Konstruktor
     *
     * @param {MatDialogRef} currentDialogRef MatDialogRef
     * @param {WaiterService} waiterService waiterService
     * @param {MatDialog} dialog matDialog
     * @param {Store} store store
     * @param {LiabilitiesService} liabilityService liabilityService
     * @param {NotificationService} notificationService notificationService
     * @param {TranslateService} translate translate service
     * @param {string} locale Locale Code
     * @param {ILiabilityDialogData} data liability parent data
     * @param {DomSanitizer} domSanitizer DomSanitizer Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     */
    public constructor(
        private currentDialogRef: MatDialogRef<AddLiabilityDialogComponent>,
        private waiterService: WaiterService,
        public dialog: MatDialog,
        private store: Store,
        private liabilityService: LiabilitiesService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        @Inject(LOCALE_ID) locale: string,
        @Inject(MAT_DIALOG_DATA) public data: ILiabilityDialogData,
        private domSanitizer: DomSanitizer,
        private fb: FormBuilder) {
        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });

        this.numberMaskOptions = HelperService.getInputMask(locale, {
            precision: 0,
            thousands: '',
            decimal: '',
            inputMode: NgxCurrencyInputMode.Natural,
            nullable: true,
        });

        const fictionalRateMode = this.data.liabilityRequest === 'fictionalRate';
        const started = this.fb.control(this.data?.editLiabilityData?.started !== undefined ? moment(this.data?.editLiabilityData?.started) : null);
        const loanPeriodInMonths = this.fb.control(this.data?.editLiabilityData?.loanPeriodInMonths);
        const liabilityType = this.fb.control(this.data?.editLiabilityData?.liabilityType, Validators.required);
        const covered = this.fb.control({ value: this.data?.editLiabilityData?.covered ?? null, disabled: fictionalRateMode });
        const coveredBy = this.fb.control({ value: this.data?.editLiabilityData?.coveredBy ?? null, disabled: fictionalRateMode });
        const note = this.fb.control({ value: this.data.editLiabilityData?.note ?? null, disabled: fictionalRateMode }, Validators.maxLength(4000));
        const initialAmount = this.fb.control(this.data?.editLiabilityData?.initialAmount);
        const currentAmount = this.fb.control(this.data?.editLiabilityData?.currentAmount ?? null);
        const creditLimit = this.fb.control(this.data?.editLiabilityData?.creditLimit);
        const loanValue = this.fb.control(this.data?.editLiabilityData?.loanValue);
        const fictionalRateAmount = this.fb.control(this.data?.editLiabilityData?.fictionalRateAmount, this.data?.editLiabilityData?.active ? Validators.required : null);


        let liabilityBankValue;

        if (this.data?.editLiabilityData?.liabilitySource === JointHeadingType.AGP) {
            liabilityBankValue = LiabilityBankType.BankAustria;
        } else if (this.data?.editLiabilityData?.liabilitySource === JointHeadingType.KSV) {
            liabilityBankValue = LiabilityBankType.ExternalBank;
        }
        else {
            liabilityBankValue = this.data?.editLiabilityData?.liabilityBank;
        }

        if (this.data?.editLiabilityData?.liabilitySource !== JointHeadingType.AGP) {
            this.showSecurityValue = false;
        }
        else {
            this.showSecurityValue = true;
        }

        this.liabilityForm = this.fb.group<ILiabilityForm>({
            jointHeadingId: this.fb.control<UUID>(fictionalRateMode === true ? this.data?.editLiabilityData.jointHeadingId : this.data?.editJointHeadingData?.id, Validators.required),
            numberOfBorrowers: this.fb.control(this.data?.editLiabilityData?.numberOfBorrowers, Validators.min(1) ),
            numberOfBorrowersInThisApplication: this.fb.control(this.data?.editLiabilityData?.numberOfBorrowersInThisApplication, [Validators.required, Validators.min(1)]),
            liabilityType,
            ibanCreditor: this.fb.control({ value: this.data?.editLiabilityData?.ibanCreditor, disabled: fictionalRateMode }),
            liabilityBank: this.fb.control({ value: liabilityBankValue, disabled: fictionalRateMode || this.data?.editLiabilityData?.liabilitySource === JointHeadingType.AGP || this.data?.editLiabilityData?.liabilitySource === JointHeadingType.KSV }, Validators.required),
            started,
            loanPeriodInMonths,
            initialAmount,
            currentAmount,
            interestRate: this.fb.control({ value: this.data?.editLiabilityData?.interestRate, disabled: fictionalRateMode }),
            monthlyRate: this.fb.control(this.data?.editLiabilityData?.monthlyRate, Validators.required),
            creditLimit,
            fictionalRateAmount,
            covered,
            coveredBy,
            collateralsValue: this.fb.control({ value: this.data?.editLiabilityData?.collateralsValue, disabled: fictionalRateMode }),
            note,
            loanValue,
            isMonthlyRateManualInput: this.fb.control(this.data?.editLiabilityData?.isMonthlyRateManualInput ?? false, { nonNullable: true }),
        });

        combineLatest([
            started.valueChanges.pipe(startWith(started.value)),
            loanPeriodInMonths.valueChanges.pipe(startWith(loanPeriodInMonths.value)),
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(([startedValue, loanPeriodInMonthsValue]) => this.calculateCreditEndsDate(startedValue, loanPeriodInMonthsValue));

        liabilityType.valueChanges.pipe(
            takeUntil(this.onDestroy$),
            startWith(liabilityType.value),
        ).subscribe(liabilityTypeValue => this.updateFictionalRate(liabilityTypeValue));

        covered.valueChanges.pipe(takeUntil(this.onDestroy$), startWith(covered.value)).subscribe(coveredValue => {
            if (coveredValue) {
                coveredBy.enable();
            } else {
                coveredBy.disable();
                coveredBy.reset();
            }
        });

        note.valueChanges.pipe(startWith(note.value), takeUntil(this.onDestroy$)).subscribe(noteValue => {
            const sanitizedNote = this.domSanitizer.sanitize(SecurityContext.HTML, noteValue);
            // Extract the text content from the element.
            const tempElement = document.createElement('div');
            tempElement.innerHTML = sanitizedNote ?? '';
            const textContent = tempElement.textContent || '';

            // Calculate the number of characters in the text content.
            const charCount = textContent.length;

            // Calculate the number of characters remaining.
            this.currentNoteLength = charCount;
        });

        fictionalRateAmount.valueChanges.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(value => this.liabilityForm.controls.isMonthlyRateManualInput.patchValue(value !== null, { emitEvent: false, onlySelf: true }));

        combineLatest([
            liabilityType.valueChanges.pipe(startWith(liabilityType.value)),
            currentAmount.valueChanges.pipe(startWith(currentAmount.value)),
            initialAmount.valueChanges.pipe(startWith(initialAmount.value)),
            this.fictionalRate$,
            loanPeriodInMonths.valueChanges.pipe(startWith(loanPeriodInMonths.value)),
            this.calculatedRemainingTimeInMonths$,
            creditLimit.valueChanges.pipe(startWith(creditLimit.value)),
            loanValue.valueChanges.pipe(startWith(loanValue.value)),
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(
            ([liabilityTypeValue, currentAmountValue, initialAmountValue, fictionalRate, loanPeriodInMonthsValue, remainingTimeInMonths, creditLimitValue, loanValueValue]) => {
                const monthlyRate = this.calculateMonthlyFictionalRate(
                    liabilityTypeValue,
                    initialAmountValue,
                    currentAmountValue,
                    fictionalRate,
                    creditLimitValue,
                    loanPeriodInMonthsValue,
                    remainingTimeInMonths,
                    loanValueValue,
                );

                if (monthlyRate !== undefined && !this.liabilityForm.controls.isMonthlyRateManualInput.value) {
                    this.liabilityForm.controls.fictionalRateAmount.patchValue(monthlyRate, { emitEvent: false, onlySelf: true });
                }
            },
        );
    }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        this.calculationVersion = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.calculationVersion);

        this.coveredBy = (HelperService.getEnumArray(CoveredBy) as number[]);
        this.liabilityBank = (HelperService.getEnumArray(LiabilityBankType) as number[]);
        this.getAllJointHeadings(this.data?.editLiabilityData?.liabilitySource ?? JointHeadingType.New); // JointHeadingType.New wird als Defaultwert verwendet wenn es um "Verbindlickeit erfassen" handelt
        this.initializeLiabilityTypes();

        this.liabilityService.getCurrentLiabilityConfiguration().subscribe(result => {
            if (result) {
                this.currentLiabilityConfiguration = result;
            }

            const liabilityType = this.liabilityForm.controls.liabilityType.value;
            this.updateFictionalRate(liabilityType);
            this.calculateMonthlyFictionalRate(liabilityType);
        });

        this.changeStartedValidatorRequired();
        this.amountValidator();
    }

    /**
     * check for changes in liabilityType and change required validator of started field
     */
    public changeStartedValidatorRequired(): void {
        const liabilityTypeControl = this.liabilityForm.controls.liabilityType;
        const startedControl = this.liabilityForm.controls.started;
        const loanPeriodInMonthsControl = this.liabilityForm.controls.loanPeriodInMonths;

        liabilityTypeControl?.valueChanges.pipe(
            takeUntil(this.onDestroy$),
            startWith(liabilityTypeControl.value),
        ).subscribe(liabilityType => {
            if (liabilityType !== LiabilityType.Overdraft && liabilityType !== LiabilityType.CreditCard) {
                startedControl?.setValidators([Validators.required]);
                loanPeriodInMonthsControl?.setValidators([Validators.required]);
                this.showStarted = true;
            } else {
                startedControl?.setValidators([]);
                loanPeriodInMonthsControl?.setValidators([]);
                this.showStarted = false;
            }
            startedControl?.updateValueAndValidity();
            loanPeriodInMonthsControl?.updateValueAndValidity();
        });
    }

    /**
     * Setzt Validatoren für die Beträge & Saldo
     * 
     */
    public amountValidator() {

        const initialAmountControl = this.liabilityForm.controls.initialAmount;
        const creditLimitControl = this.liabilityForm.controls.creditLimit;
        const currentAmountControl = this.liabilityForm.controls.currentAmount;

        combineLatest([
            initialAmountControl.valueChanges.pipe(startWith(initialAmountControl.value)),
            creditLimitControl.valueChanges.pipe(startWith(creditLimitControl.value)),
            currentAmountControl.valueChanges.pipe(startWith(currentAmountControl.value)),
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(() => {
  
            if (initialAmountControl.value === null && creditLimitControl.value === null && currentAmountControl.value === null) {
                initialAmountControl.setValidators([Validators.required]);
                creditLimitControl.setValidators([Validators.required]);
                currentAmountControl.setValidators([Validators.required]);
            } else if (currentAmountControl.value !== null) {
                initialAmountControl.setValidators([]);
                creditLimitControl.setValidators([]);
                currentAmountControl.setValidators([Validators.required]);
            } else if (initialAmountControl.value !== null) {
                initialAmountControl.setValidators([Validators.required]);
                creditLimitControl.setValidators([]);
                currentAmountControl.setValidators([]);
            } else if (creditLimitControl.value !== null) {
                initialAmountControl.setValidators([]);
                creditLimitControl.setValidators([Validators.required]);
                currentAmountControl.setValidators([]);
            } else {
                initialAmountControl.setValidators([Validators.required]);
                creditLimitControl.setValidators([Validators.required]);
                currentAmountControl.setValidators([Validators.required]);
            }

            //required star only disappears when field is touched
            currentAmountControl.markAsTouched();
            initialAmountControl.markAsTouched();
            creditLimitControl.markAsTouched();

            initialAmountControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            creditLimitControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            currentAmountControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });

        });

    }

    /**
     * Beendet Subsriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next(),
        this.onDestroy$.complete();
    }

    /**
     * Aktualisiert eine bestehende Liability oder fügt eine neue hinzu
     * 
     */
    public updateLiability(): void {

        const startedControl = this.liabilityForm.controls.started;

        const addLiabilityData: ILiabilityUpdateForm = {
            ...this.liabilityForm.getRawValue(),
            started: startedControl.value?.toISOString(true),
            liabilityId: this.data?.liabilityRequest === 'add' ? undefined : this.data.editLiabilityData.id,
        }

        const request = this.data.liabilityRequest === 'add' ? this.liabilityService.createNewLiability(addLiabilityData) : this.liabilityService.updateLiability(addLiabilityData);

        this.waiterService.show();
        request.subscribe({
            next: updatedLiability => {
                this.notificationService.toast(this.translate.instant(`financing.features.financing-processing.liabilities.${this.data.liabilityRequest === 'add' ? 'addedNewLiability' : 'editedNewLiability'}`));

                this.liabilityService.getCurrentLiabilityConfiguration().subscribe(result => {

                    this.waiterService.hide();
                    if (result) {
                        const newFictionalRate = HelperService.getInterestRateFromLiabilityConfiguration(result, addLiabilityData.liabilityType);

                        if (this.fictionalRate$.value !== newFictionalRate) {
                            const message = `Der fiktive Zinssatz hat sich während des Anlegens geändert von ${this.fictionalRate$.value}% zu ${newFictionalRate}%`;
                            this.notificationService.confirmOkCancel('Information', message);
                        }
                    }

                    if (!!updatedLiability) {
                        this.currentDialogRef.close(updatedLiability);
                    }
                });
            },
            error: () => {
                this.currentDialogRef.close();
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.addLiabilityError'));
            },
        });
    }


    /**
     * get all Joint Headings
     * 
     * @param { JointHeadingType } jointHeadingType jointHeadingType
     */
    public getAllJointHeadings(jointHeadingType: JointHeadingType): void {
        this.waiterService.show();
        if (this.financingContainerID) {
            this.liabilityService.getLiabilities(this.financingContainerID).subscribe({
                next: liabilities => {
                    // Joint Headings Einreichtwelt
                    if (jointHeadingType === JointHeadingType.AutoCreated) {
                        this.jointHeadingList = (liabilities?.jointHeadings || [])?.filter(jh => jh.type === jointHeadingType || jh.type === JointHeadingType.New);

                    } else {
                        this.jointHeadingList = (liabilities?.jointHeadings || [])?.filter(jh => jh.type === jointHeadingType);
                    }
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.getLiabilitiesError'));
                },
            });
        }
    }

    /**
     * open Dialog to create a new Joint Heading
     */
    public openCreateNewJointHeadingDialog(): void {
        const dialogRef = this.dialog.open(CreateJointheadingDialogComponent, {
        });

        //reload joint headings after new jh was successfully added
        dialogRef.componentInstance.jointHeadingCreated.subscribe(() => {
            this.getAllJointHeadings(this.data?.editLiabilityData?.liabilitySource ?? JointHeadingType.New);
        });
    }

    /**
     * Calculate Credit ends Date
     * 
     * @param {Date | null} started Kreditaufnahme
     * @param {number | null} loanPeriodInMonths Laufzeit in Monaten
     */
    public calculateCreditEndsDate(started?: moment.Moment | null, loanPeriodInMonths?: number | null): void {
        if (!started || typeof loanPeriodInMonths !== 'number') {
            // this.calculatedRemainingTimeInMonths = undefined;
            this.calculatedRemainingTimeInMonths$.next(undefined)
            this.calculatedEndDate = undefined;
            return;
        }

        const today = moment();

        const calculatedEndDate = moment(started).add(loanPeriodInMonths, 'months');

        this.calculatedEndDate = calculatedEndDate.format('DD.MM.yyyy');
        //this.calculatedRemainingTimeInMonths = this.calculateRemainingTimeDifference(today, calculatedEndDate);
        this.calculatedRemainingTimeInMonths$.next(this.calculateRemainingTimeDifference(today, calculatedEndDate));
    }


    /**
     * Calculate the remaining time of a credit
     *
     * @param {Date} startDate the date of today
     * @param {Date} endDate credit ends date
     * @returns {number} months
     */
    private calculateRemainingTimeDifference(startDate: moment.Moment, endDate: moment.Moment): number {
        return endDate.diff(startDate, 'months');
    }

    /**
     * get Debitor Names for Tooltip
     * 
     * @param {ILiabilityDebitor} debitors debitor names
     * @returns {string} tooltip text
     */
    public getDebitorsTooltip(debitors: ILiabilityDebitor[]): string {
        return debitors.map(debitor => debitor.name).join(', ');
    }

    /**
     * Aktualisiert die fiktionale Rate für den Hinweis unter dem Dropdown
     *
     * @param {LiabilityType | null} liabilityType Art der Verbindlichkeit 
     */
    public updateFictionalRate(liabilityType?: LiabilityType | null): void {
        if (liabilityType !== undefined && liabilityType !== null && !!this.currentLiabilityConfiguration) {
            this.fictionalRate$.next(HelperService.getInterestRateFromLiabilityConfiguration(this.currentLiabilityConfiguration, liabilityType));
        }
    }

    /**
     * Initialisiert die Verbindlichkeitstypen
     */
    private initializeLiabilityTypes(): void {
        // LiabilityTypes nicht alphabetisch sortiert, sondern mit einer festen Reihenfolge, siehe #11222
        this.liabilityType = [
            LiabilityType.OneTimeCashLoan,
            LiabilityType.Credit,
            LiabilityType.CreditCard,
            LiabilityType.KfzLeasing,
            LiabilityType.SubsidizedLoan,
            LiabilityType.ComfortCredit,
            LiabilityType.DevelopmentLoan,
            LiabilityType.CompanyCredit,
        ];

        this.liabilityTypeSpecial = [
            LiabilityType.GuaranteeConstruction,
            LiabilityType.GuaranteeStandAlone,
            LiabilityType.ConstructionInterimFinancing,
            LiabilityType.ConstructionPrefinancingInvestmentFlatLow,
            LiabilityType.ConstructionPrefinancingInvestmentFlatHigh,
            LiabilityType.ConstructionFollowUpFinancing,
            LiabilityType.ConstructionFollowUpFinancingBuildingLoan,
        ];

        // Falls neue Verbindlichkeitstypen hinzugefügt werden, werden diese hier automatisch ergänzt
        const allLiabilityTypes = HelperService.getEnumArray(LiabilityType) as number[];

        for (const liabilityType of allLiabilityTypes) {
            if (!this.liabilityType.includes(liabilityType) && !this.liabilityTypeSpecial.includes(liabilityType)) {
                this.liabilityType.push(liabilityType);
            }
        }
    }

    /**
     * calculate monthly fictional Rate
     *
     * @param {LiabilityType} liabilityType Verbindlichkeitsart
     * @param {number} initialAmount Ursprünglicher Kreditbetrag in €
     * @param {number} currentAmount Dzt. Aushaftung gem. Nachweis in €
     * @param {number} fictionalRate fiktiver Zinssatz
     * @param {number} creditLimit Rahmenhöhe
     * @param {number} loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number} remainingTimeInMonths Berechnete Restlaufzeit
     * @param {number} loanValue Belehnwert (bei Fremdwährungskrediten)
     * @returns {number | undefined} monatliche fiktive Rate in Euro
     */
    public calculateMonthlyFictionalRate(
        liabilityType: LiabilityType | null,
        initialAmount?: number | null,
        currentAmount?: number | null,
        fictionalRate?: number | null,
        creditLimit?: number | null,
        loanPeriodInMonths?: number | null,
        remainingTimeInMonths?: number | null,
        loanValue?: number | null): number | undefined {
        if (fictionalRate === undefined) {
            return undefined;
        }

        const liabilityCalculationService = new LiabilityCalculationService(this.calculationVersion);
        return liabilityCalculationService.calculateFictionalRate({
            liabilityType,
            initialAmount,
            currentAmount,
            fictionalRate,
            creditLimit,
            loanPeriodInMonths,
            remainingTimeInMonths,
            loanValue,
        });
    }
}
