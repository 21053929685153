<!-- Mini FAB Button: Initially visible -->
<div fxLayout="column" fxLayoutAlign="end">
    @if (miniFab()) {
        <button class="mr-2 notes-button" 
            mat-mini-fab color="primary" 
            (click)="openHidePanel(true)" 
            aria-label="Add note" 
            [disabled]="(editingReadonly$ | async)" 
            [matTooltip]="'financing.features.financing-processing.notes.tooltipNotes' | translate"
            [@fadeInOut]
        >
            <mat-icon>chat</mat-icon>
        </button>
    }

    <!-- Expansion Panel: Initially hidden, will appear when FAB is clicked -->
    @if (!miniFab()) {
        <mat-expansion-panel
            [expanded]="panelOpenState()"
            (opened)="panelOpenState.set(true)"
            (closed)="panelOpenState.set(false)"
            [@panelAnimation]
            hideToggle>
            <mat-expansion-panel-header style="padding: 0 !important">
                <mat-panel-title class="ml-4">
                    <mat-icon class="grey-button mt-1">
                        @if (panelOpenState()) {
                            keyboard_arrow_down
                        } @else {
                            keyboard_arrow_up
                        }
                    </mat-icon>
                    <span class="ml-2 notes-title">{{'financing.features.financing-processing.notes.notes' | translate }}</span>
                     <!-- custom add note button -->
                    <div #ref><ng-content select="[finprocess-note-add-button], [finprocessNoteAddButton]"></ng-content></div>
                    <!-- default add note button -->
                    @if(!ref.children.length) {
                        <button mat-icon-button 
                            color="primary" 
                            (click)="addNote(); $event.stopPropagation();" 
                            [disabled]="(editingReadonly$ | async)" 
                            [matTooltip]="'financing.features.financing-processing.notes.addNote' | translate"
                        >
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    }
                </mat-panel-title>

                <button mat-icon-button class="grey-button" (click)="showHideMiniFab(true);">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-expansion-panel-header>

            @if (panelOpenState()) { 
                @for (note of notes; track note.remarkId; let i = $index) {
                    <mat-card class="example-card mb-3" appearance="outlined">
                        <mat-card-header>
                            <mat-card-title>
                                @if(!noteTitles || !noteTitles() || !noteTitles()?.[i]) {
                                    <!-- default note title -->
                                    <h3>{{'financing.features.financing-processing.notes.note' | translate }} {{ i + 1 }}</h3>
                                } @else {
                                    <h3 [innerHTML]="noteTitles()?.[i]"></h3>
                                }
                            </mat-card-title>
                            <mat-card-subtitle>
                                <h5>{{ note.createdAt | date : 'dd.MM.yyyy, HH:mm:ss' }}</h5>
                            </mat-card-subtitle>

                            <div class="buttons">
                                <button mat-icon-button color="primary" 
                                    (click)="editNote(note)" 
                                    [disabled]="(editingReadonly$ | async)"
                                    [matTooltip]="'financing.features.financing-processing.notes.editNote' | translate"
                                >
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" class="delete" 
                                    (click)="deleteNote(note.remarkId)" 
                                    [disabled]="(editingReadonly$ | async)"
                                    [matTooltip]="'financing.features.financing-processing.notes.deleteNote' | translate"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </mat-card-header>

                        <mat-card-content>
                            <div class="note-content" [innerHTML]="note.sanitizedComment"></div>
                        </mat-card-content>
                    </mat-card>
                } @empty {
                    <div class="no-notes-text">{{'financing.features.financing-processing.notes.noNotes' | translate }}</div>
                }
            }
        </mat-expansion-panel>
    }
</div>
