<ng-template #selectInputTemplate let-required="required" let-label="label" let-items="items" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-select-input
            [items]="items"
            [overwrite]="false"
            [flexDirection]="inputFlexDirection"
            [entityClassType]="entityClassType.AssetRealEstate"
            [hideHorizontalRule]="true"
            [fieldName]="fieldName"
            [readonly]="fieldReadonly$"
            [entity]="assetRealEstate()"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-select-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ value ?? '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #decimalInputTemplate let-required="required" let-label="label" let-fieldName="fieldName" let-editable="editable" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-decimal-input
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetRealEstate"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="editable ? fieldReadonly$ : alwaysReadonly$"
            [entity]="assetRealEstate()"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-decimal-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? (value | number: '1.2-2' : locale) : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #integerInputTemplate let-required="required" let-label="label" let-entityClassType="entityClassType" let-entity="entity" let-fieldName="fieldName" let-editable="editable" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-integer-input
            [overwrite]="false"
            [entityClassType]="entityClassType"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="editable ? fieldReadonly$ : alwaysReadonly$"
            [entity]="entity"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-integer-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? value : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #textboxInputTemplate let-required="required" let-label="label" let-entityClassType="entityClassType" let-entity="entity" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-textbox-input
            [overwrite]="false"
            [entityClassType]="entityClassType"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="entity"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-textbox-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== null && value !== undefined && value !== '') ? value : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #autocompleteTemplate let-required="required" let-label="label" let-entityClassType="entityClassType" let-entity="entity" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-autocomplete-input
            [overwrite]="false"
            [entityClassType]="entityClassType"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="entity"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            [autocompleteData$]="autocomleteData$"
            (searchChanged)="onSearchChanged($event)"
            [saveFn]="saveLandRegisterNumber.bind(this)"
            [displayWith]="displayLandRegisterNumber.bind(this)"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-autocomplete-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== null && value !== undefined && value !== '') ? value : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ucba-heading [expandable]="true" [ngClass]="{ 'requirements-failed': validRealEstatesMap[assetRealEstate().id] === false }">
    <div ucba-heading-title>
        <b>{{ 'asset.assetRealEstate.header' | translate }}</b>
    </div>
    <div ucba-heading-actions fxLayout="row" fxLayoutAlign="center center">
        <button
            *ngIf="assetRealEstate().source === SourceCollateral.Client"
            class="mr-2 heading-button"
            mat-icon-button
            [disabled]="(fieldReadonly$ | async) ?? true"
            (click)="openRealestateDetail($event, assetRealEstate().id)">
            <mat-icon>house</mat-icon>
        </button>
        <mat-checkbox
            #checkboxRef
            class="heading-checkbox"
            color="primary"
            [ngModel]="isActive()"
            (click)="onCheckboxChange(assetRealEstate().id, !isActive())"
            [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true"
            [disabled]="!validRealEstatesMap[assetRealEstate().id]">
        </mat-checkbox>
    </div>
    <div ucba-heading-body-visible>
        <ucba-tile-layout>
            <!-- Immobilienart enum -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="selectInputTemplate; context: { label: 'asset.assetRealEstate.assetType', items: objectTypeSelectItems, fieldName: 'assetType', required: true, value: findArrayElementWithId(objectTypeSelectItems, assetRealEstate().assetType)?.displayName }"></ng-container>
            </ng-template>
    
            <!-- Eigentumsform enum -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="selectInputTemplate; context: { label: 'asset.assetRealEstate.ownershipType', items: typeOfShareSelectItems, fieldName: 'ownershipType', required: true, value: findArrayElementWithId(typeOfShareSelectItems, assetRealEstate().ownershipType)?.displayName }"></ng-container>
            </ng-template>
    
            <!-- Sonderabwicklung enum -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="selectInputTemplate; context: { label: 'asset.assetRealEstate.specialProcessing', items: propertyTypeSelectItems, fieldName: 'specialProcessing', value: findArrayElementWithId(propertyTypeSelectItems, assetRealEstate().specialProcessing)?.displayName }"></ng-container>
            </ng-template>
    
            <!-- Marktwert -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetRealEstate.marketValue', fieldName: 'marketValue', editable: true, required: true, value: assetRealEstate().marketValue }"></ng-container>
            </ng-template>
    
            <!-- materieller Wert gesamt -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetRealEstate.materialValueTotal', fieldName: 'materialValueTotal', editable: false, value: assetRealEstate().materialValueTotal }"></ng-container>
            </ng-template>
    
            <!-- Postleitzahl -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetRealEstate.address.zip', fieldName: 'zip', editable: true, entityClassType: entityClassType.Address, entity: assetRealEstate().address, required: true, value: assetRealEstate().address?.zip }"></ng-container>
            </ng-template>
    
            <!-- Ort -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.address.city', fieldName: 'city', entityClassType: entityClassType.Address, entity: assetRealEstate().address, required: true, value: assetRealEstate().address?.city }"></ng-container>
            </ng-template>
    
            <!-- Straße -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.address.street', fieldName: 'street', entityClassType: entityClassType.Address, entity: assetRealEstate().address, required: true, value: assetRealEstate().address?.street }"></ng-container>
            </ng-template>
    
            <!-- Hausnummer -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.address.streetNumber', fieldName: 'streetNumber', entityClassType: entityClassType.Address, entity: assetRealEstate().address, required: true, value: assetRealEstate().address?.streetNumber }"></ng-container>
            </ng-template>
    
            <ng-template ucba-tile>
                <div fxLayout="row">
                    <!-- Stiege -->
                    <div class="input-padding-right" fxFlex="50">
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.address.stairway', fieldName: 'stairway', entityClassType: entityClassType.Address, entity: assetRealEstate().address, value: assetRealEstate().address?.stairway }"></ng-container>
                    </div>
    
                    <!-- Top -->
                    <div class="input-padding-left" fxFlex="50">
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.address.top', fieldName: 'top', entityClassType: entityClassType.Address, entity: assetRealEstate().address, required: assetRealEstate().ownershipType === TypeOfShare.Property, value: assetRealEstate().address?.top }"></ng-container>
                    </div>
                </div>
            </ng-template>
        </ucba-tile-layout>
    </div>
    <div ucba-heading-body>
        <!-- Bewertung -->
        <ucba-heading [type]="'medium'">
            <div ucba-heading-title>{{ 'asset.assetRealEstate.valuation' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!-- Bewertungsart -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="selectInputTemplate; context: { label: 'asset.assetRealEstate.valuationType', items: valuationTypeSelectItems, fieldName: 'valuationType', required: true, value: findArrayElementWithId(valuationTypeSelectItems, assetRealEstate().valuationType)?.displayName }"></ng-container>
                    </ng-template>
    
                    <!-- Bewertungsnummer -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.valuationNumber', fieldName: 'valuationNumber', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), required: true, value: assetRealEstate().valuationNumber }"></ng-container>
                    </ng-template>
    
                    <!-- AMG-ID -->
                    <ng-template *ngIf="assetRealEstate().source === SourceCollateral.Agp" ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.amgId', fieldName: 'amgId', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().amgId }"></ng-container>
                    </ng-template>
    
                    <!-- Belehnwert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetRealEstate.loanValue', fieldName: 'loanValue', editable: false, entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().loanValue }"></ng-container>
                    </ng-template>
    
                    <!-- bestehende Vorlasten -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetRealEstate.existingPreloads', fieldName: 'existingPreloads', editable: true, entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().existingPreloads }"></ng-container>
                    </ng-template>
    
                    <!-- bereits gen. mat. Wert -->
                    <ng-template *ngIf="assetRealEstate().source === SourceCollateral.Agp" ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.materialValueAlreadyInUse', fieldName: 'materialValueAlreadyInUse', editable: true, value: assetRealEstate().materialValueAlreadyInUse }"></ng-container>
                    </ng-template>
    
                    <!-- freier materieller Wert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.freeMaterialValue', fieldName: 'freeMaterialValue', editable: true, value: assetRealEstate().freeMaterialValue }"></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>

        <!-- Grundbuchsdaten -->
        <ucba-heading [type]="'medium'">
            <div ucba-heading-title>{{ 'asset.assetRealEstate.landRegisterDataHeader' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!-- Katastralgemeinde Nr. -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="autocompleteTemplate; context: { label: 'asset.assetRealEstate.cadastralNumber', fieldName: 'cadastralNumber', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), required: true, value: assetRealEstate().cadastralNumber }"></ng-container>
                    </ng-template>
    
                    <!-- Katastralgemeinde -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.cadastralName', fieldName: 'cadastralName', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), required: true, value: assetRealEstate().cadastralName }"></ng-container>
                    </ng-template>
    
                    <!-- Bezirksgericht -->
                    <ng-template ucba-tile>        
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.districtCourt', fieldName: 'districtCourt', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().districtCourt }"></ng-container>
                    </ng-template>
    
                    <!-- Einlagezahl -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { label: 'asset.assetRealEstate.depositNumber', fieldName: 'depositNumber', entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), required: true, value: assetRealEstate().depositNumber }"></ng-container>
                    </ng-template>
    
                    <!-- Anzahl Eigentümer -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetRealEstate.numberOfOwners', fieldName: 'numberOfOwners', editable: true, entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().numberOfOwners }"></ng-container>
                    </ng-template>
    
                    <!-- Anteile -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetRealEstate.shares', fieldName: 'shares', editable: true, entityClassType: entityClassType.AssetRealEstate, entity: assetRealEstate(), value: assetRealEstate().shares }"></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>

        <finprocess-asset-provider
            [entityClassType]="entityClassType.AssetRealEstate"
            [assetId]="assetRealEstate().id"
            [assetProviders]="assetRealEstate().assetProviders">
        </finprocess-asset-provider>
        <finprocess-asset-agp [assetsAgps]="assetRealEstate().assetsAgp"></finprocess-asset-agp>
    </div>
</ucba-heading>
