<mat-dialog-content>
    <h2 mat-dialog-title>{{'smartdoc.processing.thumbnailDialogTitle' | translate}}</h2>
    
    <mat-dialog-content *ngIf="!!data.thumbnail && !!data.thumbnail.mimeType" class="w-100">
        <img [src]="'data:' + data.thumbnail.mimeType + ';base64,' + data.content"/>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onClose()">{{'button.close' | translate}}</button>
    </mat-dialog-actions>
</mat-dialog-content>