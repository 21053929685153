import { EnumSettings } from './../enum.settings';

/**
 * Zinsbindung
 */
export enum InterestMethod {
    /**
     * Variabel mit 3-Mo Euribor
     */
    VariableThreeMo = 1,

    /**
     * Variabel mit 6-Mo Euribor
     */
    VariableSixMo = 2,

    /**
     * Fix 5 Jahre
     */
    FixFiveYears = 4,

    /**
     * Fix 10 Jahre
     */
    FixTenYears = 5,

    /**
     * Fix 15 Jahre
     */
    FixFifteenYears = 6,

    /**
     * Fix 20 Jahre
     */
    FixTwentyYears = 7,

    /**
     * Fix 25 Jahre
     */
    FixTwentyfiveYears = 9,
}

export namespace InterestMethod {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {InterestMethod | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: InterestMethod | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'InterestMethod', InterestMethod, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('InterestMethod', plocale);
}
