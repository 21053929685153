<h3 class="ml-4 mt-3">Prozessschritte</h3>
@if (!!data) {
    <mat-dialog-content>
        <mat-accordion multi *ngFor="let stepResult of data.stepResults; let i = index">
            <mat-expansion-panel [disabled]="!stepResult?.errorMessage">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="stepResult.isSuccess" class="color-success">check_circle</mat-icon>
                        <mat-icon *ngIf="!stepResult.isSuccess" class="color-danger">cancel</mat-icon>
                        &nbsp;
                        {{ stepResult.displayName }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                {{ stepResult?.errorMessage }}
            </mat-expansion-panel>
        </mat-accordion>
    </mat-dialog-content>
}
@if (!data) {
    <mat-dialog-content>
        {{ 'financing.features.financing-processing.financing-plan.multiStepNoData' | translate }}
    </mat-dialog-content>
}
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true">{{ 'button.close' | translate }}</button>
</mat-dialog-actions>