<ng-container *ngIf="!loading">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="p-3">
        <h1 class="color-primary">
            {{ 'financing.features.financing-processing.status.title' | translate }}
        </h1>
        <button mat-icon-button class="edit-history-download" (click)="downloadEditHistory()" [disabled]="exportDisabled$ | async">
            <mat-icon>file_download</mat-icon>
        </button>
    </div>
    <div *ngFor="let entry of editHistoryEntries$ | async" class="px-4">
        <div class="px-3">
            <ng-container *ngIf="isStatusEntry(entry)">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div><b>{{ 'financing.features.financing-processing.status.statusChanged' | translate }}: {{ entry.status | finProcessStatus:entry.subStatus}}</b></div>
                    <div><b>{{ entry.created | date:'dd.MM.yyyy HH:mm' }}</b></div>
                </div>
                <div [innerHtml]="entry.statusInformation"></div>
            </ng-container>
            <ng-container *ngIf="isAssignment(entry)">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div><b>{{ entry.assignmentType | finprocessEnumTranslation:'AssignmentType'}}</b></div>
                    <div><b>{{ entry.created | date:'dd.MM.yyyy HH:mm' }}</b></div>
                </div>
                <div>
                    Alt: {{ entry.oldUser?.firstName ?? 'Kein'}} {{ entry.oldUser?.lastName ?? 'Bearbeiter'}} |
                    Neu: {{ entry.newUser?.firstName ?? 'Kein'}} {{ entry.newUser?.lastName ?? 'Bearbeiter'}}
                </div>
                <div [innerHtml]="entry.additionalInformation"></div>
            </ng-container>
        </div>
        <hr>
    </div>
</ng-container>
<div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>