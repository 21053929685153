import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ReleaseNotesModule } from '@ntag-ef/release-notes';
import { PipesModule } from 'app/modules/shared/features/pipes';
import { AuthChecksModule } from 'auth';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlexLayoutModule } from 'ngx-flexible-layout'

import { NavigationComponent } from './components';

export { NavigationComponent } from './components';

/**
 * Module für die Navigationsleister
 */
@NgModule({
    declarations: [
        NavigationComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FlexLayoutModule,
        MatIconModule,
        AuthChecksModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatDividerModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSliderModule,
        FormsModule,
        ReactiveFormsModule,
        ReleaseNotesModule,
        MatDialogModule,
        PipesModule,
        NgxCurrencyDirective,
        MatInputModule,
    ],
    exports: [
        NavigationComponent,
    ],
})
export class NavigationModule { }
