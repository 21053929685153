import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DomSanitizer } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';
import { UcbaExpansionPanelComponent } from '@ntag-ef/components/expansion-panel';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { WaiterModule } from '@ntag-ef/waiter';
import { LoadBalancingInternalModule } from 'app/modules/administration/api';
import { AuthChecksModule } from 'app/modules/auth/api';
import { PendingChangesGuard } from 'app/modules/auth/features/checks/guards';
import { InformationEventDataModule } from 'app/modules/information-events/data';
import { SelectButtonComponent } from 'app/modules/shared/components';
import { SmartdocModule } from 'app/modules/smartdoc/api';
import { MessagesModule } from 'messages';
import { NgxCurrencyDirective } from 'ngx-currency';
import { FlexLayoutModule } from 'ngx-flexible-layout'
import { PipesModule } from 'shared/features/pipes';
import { SharedUiModule } from 'shared/ui';

import { FinancingDataModule, FinancingTab } from '../../data';
import { MidtermService } from '../workflow-processing/services';

import {
    AddHouseholdNotesDialogComponent,
    AddLiabilityDialogComponent,
    AddProductPackageDialogComponent,
    AdditionalSheetsComponent,
    AllowanceDialogComponent,
    ApprovalComponent,
    AssetAccountComponent,
    AssetAgpComponent,
    AssetCommonComponent,
    AssetInsuranceComponent,
    AssetProviderComponent,
    AssetRealEstateComponent,
    AssignLiabilityDialogComponent,
    AsssetsContainerComponent,
    BooleanCalculationDisplayComponent,
    BooleanInputComponent,
    BorrowerCheckCustomerDataComponent,
    BorrowerCheckIncomeComponent,
    CalcAverageIncomeDialogComponent,
    CalculationDisplayComponent,
    CalculationExamplesDialogComponent,
    CancelContractDialogComponent,
    CollateralAccountDetailsComponent,
    CollateralInsuranceDetailsComponent,
    CollateralRealEstateDetailsComponent,
    CreateJointheadingDialogComponent,
    DatepickerInputComponent,
    DebitorComponent,
    DecimalInputComponent,
    DocumentListComponent,
    DocumentViewerComponent,
    DocumentsComponent,
    EditHouseholdCalcTelecomDialogComponent,
    EsisComponent,
    FileUploadComponent,
    FinancingComponent,
    FinancingContainerComponent,
    FinancingDetailsComponent,
    FinancingPlanComponent,
    FormalComponent,
    GeneralComponent,
    GenerateSampleCalculationComponent,
    GenericFormRiskDecisionComponent,
    HintComponent,
    HouseholdBillsViewComponent,
    HouseholdCalculationTableComponent,
    HouseholdCalculationsComponent,
    HouseholdComponent,
    IntegerInputComponent,
    LabelValueComponent,
    LiabilitiesComponent,
    LiabilitiesOverviewComponent,
    MultiSelectInputComponent,
    MultiStepDialogComponent,
    ProcessComponent,
    ProductCalculatorComponent,
    ProductPackageComponent,
    ProductPackageDescriptionDialogComponent,
    ProductPackageWorkflowComponent,
    ProductpackageCollateralComponent,
    RealEstateComponent,
    RealEstateDetailComponent,
    RejectionLetterDialogComponent,
    RentalIncomeDialogComponent,
    RiskDecisionComponent,
    RiskFinancingPlanComponent,
    RiskfinancingplansComponent,
    RiskfinancingsplansDialogComponent,
    SampleCalculationComponent,
    ScoringComponent,
    SecuredRealestatesArrayDisplayComponent,
    SelectInputComponent,
    StatusComponent,
    StepperComponent,
    TextareaInputComponent,
    TextboxInputComponent,
    UploadTabComponent,
    VerifyCustomerDialogComponent,
} from './components';
import { CustomerCenterComponent } from './components/customer-center/customer-center.component';
import { FinancingProcessingRoutingModule } from './financing-processing-routing.module';
// eslint-disable-next-line @typescript-eslint/naming-convention
const enums = { FinancingTab };

/**
 * Modul zur Bearbeitung einer Finanzierung
 */
@NgModule({
    declarations: [
        RiskFinancingPlanComponent,
        ScoringComponent,
        StatusComponent,
        FinancingComponent,
        FormalComponent,
        GeneralComponent,
        DebitorComponent,
        HouseholdComponent,
        FinancingPlanComponent,
        ProductCalculatorComponent,
        DocumentsComponent,
        RealEstateComponent,
        CalculationDisplayComponent,
        BooleanCalculationDisplayComponent,
        MultiSelectInputComponent,
        SelectInputComponent,
        BooleanInputComponent,
        DatepickerInputComponent,
        TextareaInputComponent,
        IntegerInputComponent,
        TextboxInputComponent,
        DecimalInputComponent,
        AsssetsContainerComponent,
        AssetCommonComponent,
        AssetAccountComponent,
        AssetRealEstateComponent,
        AssetProviderComponent,
        AssetAgpComponent,
        AssetInsuranceComponent,
        RejectionLetterDialogComponent,
        CalculationExamplesDialogComponent,
        FileUploadComponent,
        DocumentViewerComponent,
        CancelContractDialogComponent,
        DocumentListComponent,
        UploadTabComponent,
        SampleCalculationComponent,
        SecuredRealestatesArrayDisplayComponent,
        ProcessComponent,
        VerifyCustomerDialogComponent,
        FinancingContainerComponent,
        RiskfinancingplansComponent,
        RiskfinancingsplansDialogComponent,
        LiabilitiesComponent,
        AddLiabilityDialogComponent,
        CreateJointheadingDialogComponent,
        AssignLiabilityDialogComponent,
        LabelValueComponent,
        LiabilitiesOverviewComponent,
        HouseholdCalculationsComponent,
        AdditionalSheetsComponent,
        HouseholdBillsViewComponent,
        EditHouseholdCalcTelecomDialogComponent,
        AddHouseholdNotesDialogComponent,
        HouseholdCalculationTableComponent,
        ProductPackageComponent,
        AddProductPackageDialogComponent,
        ProductPackageDescriptionDialogComponent,
        RiskDecisionComponent,
        ProductPackageWorkflowComponent,
        GenericFormRiskDecisionComponent,
        FinancingDetailsComponent,
        GenerateSampleCalculationComponent,
        ApprovalComponent,
        AllowanceDialogComponent,
        EsisComponent,
        BorrowerCheckIncomeComponent,
        BorrowerCheckCustomerDataComponent,
        CustomerCenterComponent,
        HintComponent,
        StepperComponent,
        CollateralRealEstateDetailsComponent,
        CollateralInsuranceDetailsComponent,
        CollateralAccountDetailsComponent,
        ProductpackageCollateralComponent,
        RealEstateDetailComponent,
        MultiStepDialogComponent,
        CalcAverageIncomeDialogComponent,
        RentalIncomeDialogComponent,
    ],
    imports: [
        MatAutocompleteModule,
        CKEditorModule,
        CommonModule,
        FormsModule,
        FinancingProcessingRoutingModule,
        FlexLayoutModule,
        SharedUiModule,
        MatDialogModule,
        NgxCurrencyDirective,
        MatTabsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatRadioModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDividerModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MessagesModule,
        ReactiveFormsModule,
        MatChipsModule,
        TranslateModule.forChild(),
        EnumTranslationModule.forChild({
            customEnums: enums,
        }),
        FinancingDataModule,
        InformationEventDataModule,
        WaiterModule,
        PipesModule,
        SmartdocModule,
        MatExpansionModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatExpansionModule,
        MatSliderModule,
        MatNativeDateModule,
        MatStepperModule,
        MatButtonToggleModule,
        LoadBalancingInternalModule,
        DragDropModule,
        AuthChecksModule,
        SelectButtonComponent,
        UcbaExpansionPanelComponent,
    ],
    providers: [MidtermService, PendingChangesGuard],
})
export class FinancingProcessingModule {
    /**
     * Konstruktor zum Hinzufügen von Icons
     *
     * @param {DomSanitizer} domSanitizer DomSanitizer-Injektor
     * @param {MatIconRegistry} iconRegistry IconRegistry-Injektor
     */
    public constructor(
        private domSanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry,
    ) {
        this.iconRegistry.addSvgIcon(
            'finprocess-bank-logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/unicredit-logo.svg',
            ),
        );
    }
}
