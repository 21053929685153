<div *ngIf="loading" fxFill fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>
<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="p-3">
        <mat-form-field fxFill>
            <input name="search" matInput [ngModel]="search.value" (ngModelChange)="updateSearch($event)"
                [placeholder]="'dashboard.features.dashboard.searchPlaceholder' | translate">
        </mat-form-field>
    </div>
    <span class="ml-3" *ngIf="informationEvents?.length === 0" fxFill fxLayout="row" fxLayoutAlign="center center">{{'dashboard.features.dashboard.noInformationEvents' | translate }}</span>
</div>
<cdk-virtual-scroll-viewport class="list" itemSize="70">
    <div *ngIf="!searchActive">
        <div  *cdkVirtualFor="let eventContainer of informationEvents;" class="event-container py-2 mx-2"
            [class.unread]="!eventContainer.isRead" fxLayout="row">
            <div fxFlex="10" fxLayoutAlign="center center" class="left"
                (click)="setReadStatus(eventContainer, !eventContainer.isRead)"
                [class.inactive]="tabId === DashboardTab.All">
                <mat-icon color="primary" *ngIf="!eventContainer.isRead">markunread</mat-icon>
                <mat-icon class="unchecked" *ngIf="eventContainer.isRead">drafts</mat-icon>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex (click)="navigateIntoFinancing(eventContainer)"
                class="right">
                <div fxLayout="row" class="py-1">
                    <span fxFlex="50">{{ eventContainer.debitorFirstName }} {{ eventContainer.debitorLastName }}</span>
                    <span fxFlex="40">{{ eventContainer.newestEventDate | date:'dd.MM.yyyy HH:mm'}}</span>
                    <span fxFlex="10" style="text-align: center;" matTooltip="{{ eventContainer.editor?.firstName }} {{ eventContainer.editor?.lastName }}">{{ eventContainer.editor?.shortName }}</span>
                </div>
                <div fxLayout="row" class="py-1">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="90">
                        <mat-icon>{{ getIconByType(eventContainer.eventType) }}</mat-icon>
                        &nbsp;
                        <span>{{eventContainer.eventType | finprocessEnumTranslation : 'FinancingEventType'}}</span>
                    </div>
                    <div fxFlex="10" class="notification-counter">
                        <ng-container *ngIf="getUnreadCount(eventContainer) as unreadCount">
                            <span *ngIf="unreadCount > 1">{{ unreadCount }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <waiter-component [isLoading]="waiterLoadingBatch"></waiter-component>
</cdk-virtual-scroll-viewport>
