export enum MaritalStatus {
    /**
     * UNBEKANNT
     */
    Unknown,

    /**
     * LEDIG
     */
    NotMarried,

    /**
     * VERHEIRATET
     */
    Married,

    /**
     * GESCHIEDEN
     */
    Divorced,

    /**
     * VERWITWET
     */
    Widowed,

    /**
     * VERPARTNERT
     */
    VERPARTNERT,
}
