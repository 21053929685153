<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" [rightFxFlex]="40"
    [leftFxFlex]="60" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2">
    <div left>
        <ng-container *ngIf="(financing$ | async) as financing">
            <!-- Haushalte Nav Bar -->
            <ng-container *ngIf="(households$ | async) as households">
                <div class="w-100 nav-container" fxLayout="row wrap">
                    <div class="nav-item" fxLayoutAlign="center center" [class.nav-item-selected]="overviewSelected"
                        (click)="householdOverviewClicked()">
                        {{'financing.features.financing-processing.household.overviewHousehold' | translate}}
                    </div>

                    <div *ngFor="let household of households; index as i" class="nav-item" fxLayoutAlign="center center"
                        [class.nav-item-selected]="selectedHousehold.value && household.household.id === selectedHousehold.value.household.id"
                        (click)="householdClicked(household)">
                        {{'financing.features.financing-processing.household.household' | translate}} {{i + 1}}
                    </div>
                </div>
            </ng-container>

            <!-- Haushaltsübersicht -->
            <ng-container *ngIf="overviewSelected">
                <div class="area-container">
                    <!-- Übersicht Haushaltsrechnungen -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.overviewHouseholdCalculations' | translate">
                    </finprocess-sub-header>

                    <!-- Summe der gemeinsamen Einnahmen aller Haushalte -->
                    <finprocess-calculation-display [numberFunctionObservable]="incomeTotal$"
                        [label]="'financing.features.financing-processing.household.incomeTotal' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="costsTotal$"
                        [label]="'financing.features.financing-processing.household.costsTotal' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="remainingTotal$"
                        [label]="'financing.features.financing-processing.household.remainingTotal' | translate">
                    </finprocess-calculation-display>

                    <!-- Zumutbare Kreditrate in EUR -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.acceptableCreditRateSubHeadline' | translate">
                    </finprocess-sub-header>

                    <finprocess-calculation-display [numberFunctionObservable]="reserve$"
                        [label]="'financing.features.financing-processing.household.reserve' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="freeAccessibleIncome$"
                        [label]="'financing.features.financing-processing.household.freeAccessibleIncome' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="newMonthlyRates$"
                        [label]="'financing.features.financing-processing.household.newMonthlyRates' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="monthlyRateNotCoveredLiabilities$"
                        [label]="'financing.features.financing-processing.household.monthlyRateNotCoveredLiabilities' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="otherCostsTotal$"
                        [label]="'financing.features.financing-processing.household.otherCostsTotal' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="acceptableCreditRate$"
                        [label]="'financing.features.financing-processing.household.acceptableCreditRate' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="fictionalRateAmount$"
                        [label]="'financing.features.financing-processing.household.fictionalRateAmount' | translate">
                    </finprocess-calculation-display>
                </div>
                <div *ngIf="(economicLeader$ | async) as economicLeader" fxLayoutAlign="row">
                    <mat-icon class="overalleconomicleader-icon" color="primary">star</mat-icon>
                    {{'financing.features.financing-processing.household.isOverallEconomicLeader' | translate}}
                    {{economicLeader.firstName + " " + economicLeader.lastName }}
                </div>
            </ng-container>
            <!-- Einzelner Haushalt -->
            <ng-container *ngIf="selectedHousehold.value && !!visibilityMap">
                <input type="hidden" [value]="selectedHousehold.value.household.id" />
                <div class="area-container">
                    <ng-container *ngFor="let debitor of debitors$ | async; let debIdx = index">
                        <!-- Einkommen Kreditnehmer -->

                        <finprocess-sub-header label="{{ debitor.firstName }} {{ debitor.lastName}}">
                        </finprocess-sub-header>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.debitor.nettoIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="netIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-boolean-input
                            [label]="'financing.features.financing-processing.debitor.fourteenSalary' | translate"
                            [entity]="debitor" fieldName="fourteenSalariesPerYear"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.debitor.otherIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="otherIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <div fxLayout="row" class="m-2">
                            <b class="mr-1">{{'financing.features.financing-processing.debitor.sustainableIncome' |
                                translate}}</b>
                            <mat-icon class="overalleconomicleader-icon" color="primary"
                                *ngIf="(economicLeader$ | async)?.id === debitor.id">star</mat-icon>
                            <div>
                                <a class="m-2 p-1 bnr" href={{calculatorUrl}}
                                    target="_blank" matTooltip="Bruttonetterrechner" matTooltipPosition="right"><b>BNR</b></a>
                            </div>
                        </div>
                        
                        <finprocess-boolean-input [entityClassType]="entityTypeDebtor" [overwrite]="false"
                            [label]="'financing.features.financing-processing.debitor.sustainableFourteenSalariesPerYear' | translate"
                            [entity]="debitor" fieldName="sustainableFourteenSalariesPerYear"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityTypeDebtor"
                            [label]="'financing.features.financing-processing.debitor.sustainableNetIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="sustainableNetIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>
                        <finprocess-calculation-display [numberFunctionObservable]="calculateMonthlyNetIncome(debitor)"
                            [label]="'financing.features.financing-processing.debitor.sustainableIncome14Months' | translate">
                        </finprocess-calculation-display>
                        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityTypeDebtor"
                            [label]="'financing.features.financing-processing.debitor.sustainableOtherIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="sustainableOtherIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>
                        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityTypeDebtor"
                            [label]="'financing.features.financing-processing.debitor.sustainableRentalIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="sustainableRentalIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>
                        <finprocess-decimal-input [overwrite]="false" [entityClassType]="entityTypeDebtor"
                            [label]="'financing.features.financing-processing.debitor.sustainableIndependentIncome' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="sustainableIndependentIncome" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>
                        <finprocess-calculation-display [numberFunctionObservable]="calculateTotalSustainable(debitor)"
                            [label]="'financing.features.financing-processing.debitor.sustainableTotal' | translate">
                        </finprocess-calculation-display>

                        <div class="p-2" fxLayout="column">
                            <div fxlayout="row">

                            <label fxFlex="50">{{'financing.features.financing-processing.debitor.lastingProof' |
                                translate}}</label>
                            <span fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                                <button mat-button color="primary" (click)="uploadMultiFiles(debitor.id)" 
                                    [disabled]="(fieldReadonly$ | async) === true || (statusEnable | async)"><mat-icon>upload_file</mat-icon></button>
                            </span>
                        </div>

                            <div fxLayout="column" fxLayoutAlign="space-evenly" *ngIf="!!getExistingSustainableFile(debitor)">
                                <div fxLayout="row" *ngFor="let file of getExistingSustainableFile(debitor)">
                                    <div fxLayout="row" class="mt-2" fxFlex="50">
                                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                                        <div> {{file.name}}.pdf</div>
                                    </div>
                                    <div fxFlex="50" fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="space-evenly start">
                                        <button
                                            [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                            mat-icon-button color="primary" (click)="openFile(file)">
                                            <mat-icon>open_in_new</mat-icon>
                                        </button>
                                        <button (click)="sustainableDocumentDownload(file)"
                                            [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                            mat-icon-button color="primary">
                                            <mat-icon>download</mat-icon>
                                        </button>
                                        <button  (click)="sustainableDocumentDelete(debitor.id, file)"
                                            [disabled]="(fieldReadonly$ | async) || (fileUploadInProgress | async)"
                                            class="delete" mat-icon-button color="primary">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <finprocess-boolean-input *ngIf="visibilityMap.debitors[debIdx].otherIncomeContainsAlimony"
                            [label]="'financing.features.financing-processing.debitor.otherIncomeContainsAlimony' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue" [entity]="debitor"
                            fieldName="otherIncomeContainsAlimony" [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-textarea-input *ngIf="visibilityMap.debitors[debIdx].notesIncome"
                            [label]="'financing.features.financing-processing.debitor.notesIncome' | translate"
                            [placeholder]="'...'"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [readonly]="fieldReadonly$" [entity]="debitor" fieldName="notesIncome">
                        </finprocess-textarea-input>

                        <finprocess-textarea-input *ngIf="visibilityMap.debitors[debIdx].notesOtherIncome"
                            [label]="'financing.features.financing-processing.debitor.notesOtherIncome' | translate"
                            [placeholder]="'...'"
                            [overwriteValueClassType]="OverwriteValueClassType.DebitorOverwriteValue"
                            [readonly]="fieldReadonly$" [entity]="debitor" fieldName="notesOtherIncome">
                        </finprocess-textarea-input>
                    </ng-container>


                    <!-- Wohnkosten nach Finanzierung -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.livingCostsFinancing' | translate">
                    </finprocess-sub-header>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.operationalCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="operationalCosts"
                        [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.electricityCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="electricityCosts"
                        [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.phoneCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="phoneCosts" [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.broadcastCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="broadcastCosts"
                        [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <!-- Monatliche Verpflichtungen -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.monthlyObligations' | translate">
                        *ngIf=""
                    </finprocess-sub-header>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.carCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="carCosts" [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.insuranceCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="insuranceCosts"
                        [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.livingCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="livingCosts"
                        [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <finprocess-decimal-input
                        [label]="'financing.features.financing-processing.household.otherCosts' | translate"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [entity]="selectedHousehold.value.household" fieldName="otherCosts" [readonly]="fieldReadonly$">
                    </finprocess-decimal-input>

                    <!-- Bestehende Verpflichtungen/Bestandskredite Kreditnehmer -->
                    <finprocess-sub-header *ngIf="selectedHousehold.value.household.liabilities.length > 0"
                        [label]="'financing.features.financing-processing.household.existingObligationsDebitor' | translate">
                    </finprocess-sub-header>

                    <div *ngFor="let liability of selectedHousehold.value.household.liabilities">

                        <finprocess-boolean-input
                            [label]="'financing.features.financing-processing.liability.isCorporateCredit' | translate"
                            [entity]="liability" fieldName="isCorporateCredit"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.liability.ibanCreditor' | translate"
                            [entity]="liability" fieldName="ibanCreditor"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.liability.initialAmount' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [entity]="liability" fieldName="initialAmount" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.liability.currentAmount' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [entity]="liability" fieldName="currentAmount" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-integer-input
                            [label]="'financing.features.financing-processing.liability.loanPeriodInMonths' | translate"
                            [placeholder]="'...'"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$" [entity]="liability" fieldName="loanPeriodInMonths">
                        </finprocess-integer-input>

                        <finprocess-datepicker-input
                            [label]="'financing.features.financing-processing.liability.started' | translate"
                            [entity]="liability" fieldName="started"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-datepicker-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.liability.monthlyRate' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [entity]="liability" fieldName="monthlyRate" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-boolean-input
                            [label]="'financing.features.financing-processing.liability.covered' | translate"
                            [entity]="liability" fieldName="covered"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-boolean-input
                            [label]="'financing.features.financing-processing.liability.securedByLandRegister' | translate"
                            [entity]="liability" fieldName="securedByLandRegister"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-secured-realestates-array-display
                            [label]="'financing.features.financing-processing.liability.securedRealEstates' | translate"
                            [entity]="liability" fieldName="securedRealEstateIds"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue">
                        </finprocess-secured-realestates-array-display>


                    </div>

                    <!-- Neue zusätzliche Verpflichtungen -->
                    <finprocess-sub-header *ngIf="selectedHousehold.value.household.newLiabilities.length > 0"
                        [label]="'financing.features.financing-processing.household.newObligations' | translate">
                    </finprocess-sub-header>

                    <div *ngFor="let newLiability of selectedHousehold.value.household.newLiabilities">

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.newLiability.creditorName' | translate"
                            [entity]="newLiability" fieldName="creditorName"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.newLiability.amount' | translate"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [entity]="newLiability" fieldName="amount" [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-integer-input
                            [label]="'financing.features.financing-processing.newLiability.loanPeriodInMonths' | translate"
                            [placeholder]="'...'"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$" [entity]="newLiability" fieldName="loanPeriodInMonths">
                        </finprocess-integer-input>

                        <finprocess-datepicker-input
                            [label]="'financing.features.financing-processing.newLiability.starts' | translate"
                            [entity]="newLiability" fieldName="starts"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-datepicker-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.newLiability.monthlyRate' | translate"
                            [entity]="newLiability" fieldName="monthlyRate"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-boolean-input
                            [label]="'financing.features.financing-processing.newLiability.securedByLandRegister' | translate"
                            [entity]="newLiability" fieldName="securedByLandRegister"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-boolean-input>

                        <finprocess-secured-realestates-array-display
                            [label]="'financing.features.financing-processing.newLiability.securedRealEstates' | translate"
                            [entity]="newLiability" fieldName="securedRealEstateIds"
                            [overwriteValueClassType]="OverwriteValueClassType.LiabilityOverwriteValue">
                        </finprocess-secured-realestates-array-display>
                    </div>
                    <!-- taxOfficeArrear -->
                    <finprocess-sub-header *ngIf="selectedHousehold.value.household.taxOfficeArrears.length > 0"
                        [label]="'financing.features.financing-processing.household.taxOfficeArrears' | translate">
                    </finprocess-sub-header>

                    <div *ngFor="let taxOffice of selectedHousehold.value.household.taxOfficeArrears">
                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.household.taxOfficeArrearInstitute' | translate"
                            [entity]="taxOffice" fieldName="institute"
                            [overwriteValueClassType]="OverwriteValueClassType.TaxOfficeArrearOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.household.taxOfficeArrearArrear' | translate"
                            [entity]="taxOffice" fieldName="arrear"
                            [overwriteValueClassType]="OverwriteValueClassType.TaxOfficeArrearOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-datepicker-input
                            [label]="'financing.features.financing-processing.household.taxOfficeArrearDueDate' | translate"
                            [entity]="taxOffice" fieldName="dueDate"
                            [overwriteValueClassType]="OverwriteValueClassType.TaxOfficeArrearOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-datepicker-input>
                    </div>

                    <!-- assets -->
                    <finprocess-sub-header *ngIf="selectedHousehold.value.household.assets.length > 0"
                        [label]="'financing.features.financing-processing.household.assets' | translate">
                    </finprocess-sub-header>

                    <div *ngFor="let asset of selectedHousehold.value.household.assets">
                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.household.assetType' | translate"
                            [entity]="asset" fieldName="type"
                            [overwriteValueClassType]="OverwriteValueClassType.AssetOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>

                        <finprocess-decimal-input
                            [label]="'financing.features.financing-processing.household.assetValue' | translate"
                            [entity]="asset" fieldName="value"
                            [overwriteValueClassType]="OverwriteValueClassType.AssetOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-decimal-input>

                        <finprocess-textbox-input
                            [label]="'financing.features.financing-processing.household.assetVerification' | translate"
                            [entity]="asset" fieldName="verification"
                            [overwriteValueClassType]="OverwriteValueClassType.AssetOverwriteValue"
                            [readonly]="fieldReadonly$">
                        </finprocess-textbox-input>
                    </div>

                    <!-- Haushaltsrechnung in EUR -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.householdInvoice' | translate">
                    </finprocess-sub-header>

                    <finprocess-calculation-display [numberFunctionObservable]="incomePerHousehold$"
                        [label]="'financing.features.financing-processing.household.sumOwnCapital' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="costsPerHousehold$"
                        [label]="'financing.features.financing-processing.household.costsPerHousehold' | translate">
                    </finprocess-calculation-display>

                    <finprocess-calculation-display [numberFunctionObservable]="remainingPerHousehold$"
                        [label]="'financing.features.financing-processing.household.remainingPerHousehold' | translate">
                    </finprocess-calculation-display>

                    <!-- Anmerkungen Haushalt -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.household.commentHousehold' | translate">
                    </finprocess-sub-header>

                    <finprocess-textarea-input
                        [label]="'financing.features.financing-processing.household.notesHousehold' | translate"
                        [entity]="selectedHousehold.value.household" fieldName="notesHousehold"
                        [overwriteValueClassType]="OverwriteValueClassType.HouseholdOverwriteValue"
                        [readonly]="fieldReadonly$">
                    </finprocess-textarea-input>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>