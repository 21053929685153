import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { INote } from '../interfaces';

import { NoteAdded, NoteDeleted, NoteEdited, NotesLoaded } from './note.action';

/**
 * Zustandsobjekt für Finanzierungsliste
 */
export interface INoteStateModel {
    notes: INote[];
}

const defaultData = {
    notes: [],
}

/**
 * Zustand für Note
 */
@State<INoteStateModel>({
    name: NoteState.keyName,
    defaults: defaultData,
})
@Injectable()
export class NoteState {
    public static readonly keyName = 'notes';

    /**
     * Gib alle Notizen zurück
     * 
     * @param {INoteStateModel} state Aktueller State Kontext
     * @returns {INote[]} Liste von Notizen
     */
    @Selector()
    public static allNotes(state: INoteStateModel): INote[] {
        return state.notes;
    }

    /**
     *Gibt die Notiz zu einer EntityId zurück
     * 
     * @param {INoteStateModel} state Aktueller State Kontext
     * @returns {(state: INoteStateModel) => INote | undefined} INote | undefined
     */
    @Selector()
    public static notesByEntityId(state: INoteStateModel): (entityId: string) => INote | undefined {
        return (entityId: string) => state.notes.find(note => note.entityId === entityId);
    }

    /**
     * Zustandsänderung beim Verlassen Financing
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    /*   @Action(Logout)
     @Action(FinancingLeaved)
    public reset({ setState }: StateContext<INoteStateModel>): void {
        setState(defaultData);
    } */

    /**
     * Zustandsänderung beim Laden von Notizen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {NotesLoaded} action Aktion
     */
    @Action(NotesLoaded)
    public notesLoaded({ patchState }: StateContext<INoteStateModel>, { payload }: NotesLoaded): void {
        patchState({
            notes: payload ?? [],
        });
    }

    /**
     * Zustandsänderung beim Hinzufügen einer Notiz
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {NoteAdded} action Aktion
     */
    @Action(NoteAdded)
    public noteAdded({ getState, setState }: StateContext<INoteStateModel>, { payload }: NoteAdded): void {
        const currentNotes = getState().notes;
        setState({
            notes: currentNotes.concat(payload),
        });
    }

    /**
     * Zustandsänderung beim Löschen einer Notiz
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {NoteDeleted} action Aktion
     */
    @Action(NoteDeleted)
    public noteDeleted({ getState, setState }: StateContext<INoteStateModel>, { payload }: NoteDeleted): void {
        const currentNotes = getState().notes;
        setState({
            notes: currentNotes.filter(note => note.remarkId !== payload),
        });
    }

    /**
     * Zustandsänderung beim Bearbeiten einer Notiz
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {NoteEdited} action Aktion
     */
    @Action(NoteEdited)
    public noteEdited({ getState, setState }: StateContext<INoteStateModel>, { payload }: NoteEdited): void {
        const currentNotes = getState().notes;
        const updatedNotes = currentNotes.map(note => {
            const updatedNote = payload.find(p => p.remarkId === note.remarkId);
            return updatedNote ? { ...note, comment: updatedNote.comment } : note;
        });
    
        setState({
            notes: updatedNotes,
        });
    }
}
