import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBaseOverwriteValues } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { ISelectItem } from 'app/modules/shared';

/**
 * Wert für Auswahl Sonstiges
 */
const OTHER_KEY = 'otherSelect';

/**
 * Komponente zur Darstellung eines Overwrite Values
 */
@Component({
    selector: 'finprocess-overwrite-value',
    templateUrl: './overwrite-value.component.html',
    styleUrls: ['./overwrite-value.component.scss'],
    standalone: true,
    providers: [
        DatePipe,
    ],
    imports: [
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        DatePipe,
        DecimalPipe,
    ],
})
export class OverwriteValueComponent<T extends IBaseOverwriteValues, K extends keyof T & string> {
    /**
     * The entity containing the field, that is edited by this input.
     * The entity is used to determine the original and current value via overwrites.
     */
    public entity = input<T>();

    /**
     * The fieldname of the entity, that is edited by this input.
     * Has to be a key of the entity.
     */
    public fieldName = input.required<K>();

    /**
     * List of select items for the mat-select
     */
    public items = input<ISelectItem<T[keyof T] | string>[]>();

    /**
     * Should a selection for 'other' be shown which allows entering a custom value
     */
    public showOther = input<boolean>(false);

    /**
     * Should value be formatted as date
     */
    public dateFormat = input<boolean>(false);

    /**
     * Format for number values
     */
    public numberFormat = input<string | undefined>(undefined);

    /**
     * Position where the info icon should be displayed in case of overwrite value
     */
    public iconPosition = input<'left' | 'right'>('left');

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Konstruktor
     *
     * @param {string} locale Locale
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(@Inject(LOCALE_ID) locale: string, private translate: TranslateService) {
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * Internal list of items for the mat-select
     * Contains the items from the input and the 'other' option if selected
     */
    public internalItems = computed(() => {
        const items = this.items() ?? [];

        if (this.showOther()) {
            items.push({ value: OTHER_KEY, displayName: this.translate.instant('general.otherSelect')})
        }

        return items;
    });

    /**
     * Gets the current value of the entity at the fieldname.
     * The overwritten value is taken from the overwrite values if available.
     */
    public currentValue = computed(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        if ('overwriteValues' in entity) {
            return OverwriteHelperService.getMergedOverwriteValue(entity, this.fieldName(), entity[this.fieldName()], true);
        }

        return entity[this.fieldName()];
    });

    /**
     * Translated label to display the current value
     */
    public translatedCurrentValueKey = computed(() => {
        const currentValue = this.currentValue();

        if (currentValue === undefined || currentValue === null || currentValue === '') {
            return '-';
        }

        if (this.items()) {
            const items = this.internalItems();
            const item = items.find(it => this.compareStringFallback(it.value, this.currentValue()));
            
            return item?.displayName ?? currentValue?.toString() ?? '';
        } else {
            if (typeof currentValue === 'boolean') {
                return currentValue ? this.translate.instant('general.yes') : this.translate.instant('general.no');
            } else {
                return currentValue.toString();
            }
        }
    });

    /**
     * Gets the original value of the entity at the fieldname.
     */
    public originalValue = computed(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        return entity[this.fieldName()];
    });

    /**
     * Translated label for the tooltip for the original value
     */
    public translatedOriginalValueKey = computed(() => {
        const originalValue = this.originalValue();

        if (originalValue === undefined || originalValue === null) {
            return 'financing.features.financing-processing.unset';
        }

        if (this.items()) {
            const items = this.internalItems();
            const item = items.find(it => this.compareStringFallback(it.value, this.originalValue()));

            return item?.displayName ?? originalValue?.toString() ?? '';
        } else {
            if (typeof originalValue === 'boolean') {
                return originalValue ? this.translate.instant('general.yes') : this.translate.instant('general.no');
            } else {
                return originalValue.toString();
            }
        }
    });

    /**
     * Comparing values of any types and fall back to string if types are not equal
     * 
     * @param {unknown} a First value 
     * @param {unknown} b Second value
     * @returns {boolean} Are a and b equal
     */
    private compareStringFallback<S, O>(a: S, b: O): boolean {
        if (typeof a === typeof b) {
            return a === (b as unknown as S);
        }

        return a?.toString() === b?.toString();
    }
}
