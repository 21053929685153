import { Component, ViewEncapsulation, input, model } from '@angular/core';

/**
 * A chip component
 */
@Component({
    selector: 'ucba-chip',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    standalone: true,
})
export class UcbaChipComponent {

    /**
     * If the chip is selected
     */
    public selected = model<boolean>(false);

    /**
     * The color of the chip
     */
    public color = input<'primary' | 'accent'>();

    /**
     * On click event handler for the chip
     * 
     * @param { MouseEvent }event Mouse event
     */
    public onClick(event: MouseEvent) {
        this.rippleEffect(event);
        this.selected.update(selected => !selected);
    }

    /**
     * Shows a ripple effect on the chip if clicked
     * 
     * @param {MouseEvent} event The mouse event
     */
    public rippleEffect(event: MouseEvent) {
        const ripple = document.createElement('span');
        ripple.classList.add('ripple');

        if (event.currentTarget instanceof HTMLSpanElement) {
            event.currentTarget.appendChild(ripple);
            const x = event.clientX - event.currentTarget.getBoundingClientRect().left;
            const y = event.clientY - event.currentTarget.getBoundingClientRect().top;

            ripple.style.left = `${x}px`;
            ripple.style.top = `${y}px`;

            setTimeout(() => {
                ripple.remove();
            }, 300);
        }
    }
}
