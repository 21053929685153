export enum CollateralType {
    /**
     * BA105 Klassische LV
     */
    BA105,

    /**
     * BA110 Fondsgebund. LV Aktienanteil max. 40 %
     */
    BA110,

    /**
     * BA115 Fondsgebund. LV Aktienanteil max. 41-80%
     */
    BA115,

    /**
     * BA120 Fondsgebund. LV Aktienanteil über 80 %
     */
    BA120,

    /**
     * BA125 Fondsorientierte Lebensversicherung
     */
    BA125,

    /**
     * BA130 Risikoablebensversicherung
     */
    BA130,

    /**
     * BA135 Kreditrestschuldversicherung
     */
    BA135,

    /**
     * BA205 eingetragene HH
     */
    BA205,

    /**
     * BA210 eingetragene Darlehenshypothek (Inl.)
     */
    BA210,

    /**
     * BA220 Hinterlegte Pfandbestellungsurkunde Inland (IPBU)
     */
    BA220,

    /**
     * BA330 Wechsel des KN
     */
    BA330,

    /**
     * BA415 Eigenes Kontoguthaben
     */
    BA415,

    /**
     * BA425 Eigener Wertpapierplan/-depot
     */
    BA425,
}
