export enum Currency {
    /**
     * USD
     */
    USD = 1,

    /**
     * CHF
     */
    CHF = 2,

    /**
     * JPY
     */
    JPY = 3,
}
