import { DashboardState, IDashboardStateModel } from './dashboard/dashboard.state';
import { IFinancingsStateModel } from './financings/financings.state';
/**
 * Interface für globalen Zustandsspeicher
 */
export interface IDashboardStateParentDefinition {
    /**
     * Zustand für Dashboard
     */
    dashboard: IDashboardStateModel;

    /**
     * Zustand für aktuell sichtbare Finanzierungsliste
     */
    financings: IFinancingsStateModel;
}

export const DASHBOARD_PERSISTENT_STATE_KEYS = [DashboardState.keyName];
