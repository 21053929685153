<waiter-component [isLoading]="isLoading"></waiter-component>

<div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
  <h1 class="color-primary">{{ 'navigation.administration.debit-rates' | translate }}</h1>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button mat-mini-fab color="primary" (click)="toggleLock()" *finprocessAuthorization="Role.ConditionsAdministrator"
          matTooltip="{{ (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate }}">
          <mat-icon *ngIf="locked">lock</mat-icon>
          <mat-icon *ngIf="!locked">lock_open</mat-icon>
      </button>
  </div>
</div>

<div *ngIf="!!liabilityForm && !isLoading">
  <div [formGroup]="liabilityForm" class="m-3" fxLayout="row wrap" fxLayoutGap="32px">

    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.creditFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask currencyMask [options]="percentageMaskOptions" matInput formControlName="creditFictionalRate">
          <mat-error>{{ liabilityForm.get('creditFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.comfortCreditFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="comfortCreditFictionalRate">
          <mat-error>{{ liabilityForm.get('comfortCreditFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.overdraftFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="overdraftFictionalRate">
          <mat-error>{{ liabilityForm.get('overdraftFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.oneTimeCashLoanFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="oneTimeCashLoanFictionalRate">
          <mat-error>{{ liabilityForm.get('oneTimeCashLoanFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.guaranteeStandAloneFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="guaranteeStandAloneFictionalRate">
          <mat-error>{{ liabilityForm.get('guaranteeStandAloneFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.guaranteeConstructionFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="guaranteeConstructionFictionalRate">
          <mat-error>{{ liabilityForm.get('guaranteeConstructionFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.constructionInterimFinancingFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="constructionInterimFinancingFictionalRate">
          <mat-error>{{ liabilityForm.get('constructionInterimFinancingFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{'administration.features.liabilityConfiguration.constructionPrefinancingInvestmentFlatLowFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="constructionPrefinancingInvestmentFlatLowFictionalRate">
          <mat-error>{{ liabilityForm.get('constructionPrefinancingInvestmentFlatLowFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{'administration.features.liabilityConfiguration.constructionPrefinancingInvestmentFlatHighFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="constructionPrefinancingInvestmentFlatHighFictionalRate">
          <mat-error>{{ liabilityForm.get('constructionPrefinancingInvestmentFlatHighFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.constructionFollowUpFinancingFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="constructionFollowUpFinancingFictionalRate">
          <mat-error>{{ liabilityForm.get('constructionFollowUpFinancingFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.constructionFollowUpFinancingBuildingLoanFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="constructionFollowUpFinancingBuildingLoanFictionalRate">
          <mat-error>{{ liabilityForm.get('constructionFollowUpFinancingBuildingLoanFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.creditCardFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="creditCardFictionalRate">
          <mat-error>{{ liabilityForm.get('creditCardFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.developmentLoanFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="developmentLoanFictionalRate">
          <mat-error>{{ liabilityForm.get('developmentLoanFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.subsidizedLoanFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="subsidizedLoanFictionalRate">
          <mat-error>{{ liabilityForm.get('subsidizedLoanFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.companyCreditFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="companyCreditFictionalRate">
          <mat-error>{{ liabilityForm.get('companyCreditFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
    <div fxLayout="column" fxFlex="47">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span span fxFlex="70">{{ 'administration.features.liabilityConfiguration.kfzFictionalRate' | translate }}</span>
        <mat-form-field appearance="fill" fxFlex="30">
          <input currencyMask [options]="percentageMaskOptions" matInput formControlName="kfzLeasingFictionalRate">
          <mat-error>{{ liabilityForm.get('kfzLeasingFictionalRate')?.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <hr class="custom-hr">
    </div>
  </div>
    <button *finprocessAuthorization="Role.ConditionsAdministrator" color="primary" class="btn-fix" [disabled]="liabilityForm.pristine || !liabilityForm.valid" mat-raised-button (click)="onSave()">{{ 'button.save' | translate }}</button>
</div>
