export enum EntityClassType {
    FinancingMap,
    Debitor,
    FinProcessContainer,
    ProductPackage,
    AssetRealEstate,
    AssetInsurance,
    AssetAccount,
    CollateralMortgage,
    CollateralInsurance,
    CollateralAccount,
    AssetToCollateralMortgage,
    AssetToCollateralInsurance,
    AssetToCollateralAccount,
    Address
}
