<div class="container" fxLayout="column">

    <h1 class="color-primary">{{ 'navigation.administration.salespartner-region' | translate }}</h1>

    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-label class="margin-15">{{ 'administration.features.salespartnerRegion.region' | translate }}</mat-label>
        <mat-form-field class="width-250">
            <mat-select (valueChange)="selectedSalesPartnerChanged($event)" [(value)]="test" [disabled]="!userCanEdit">
                <mat-option [value]="''">{{ 'general.noSelection' | translate }}</mat-option>
                <mat-option *ngFor="let salesCenter of salesPartnerCenters$ | async" [value]="salesCenter">
                    {{ salesCenter.name }},
                    {{ salesCenter.responsibility | finprocessEnumTranslation: "OrganisationalUnitResponsibilityType" }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <!-- Mitarbeiter zu Region hinzufügen -->
    <div class="salespartner-region-divider p-1 my-2">{{ 'administration.features.salespartnerRegion.headerAddEmployee'
        | translate }}</div>

    <div fxLayout="row" fxLayoutAlign="start center">

        <mat-icon class="margin-15">search</mat-icon>
        <mat-form-field class="width-250" type="search">
            <mat-label *ngIf="!!selectedSalesPartnerCenter">{{ 'administration.features.salespartnerRegion.search' |
                translate }}</mat-label>
            <mat-label *ngIf="!selectedSalesPartnerCenter">{{
                'administration.features.salespartnerRegion.chooseRegionFromList' | translate }}</mat-label>
            <input type="text" [readonly]="!selectedSalesPartnerCenter" matInput [formControl]="myControl"
                [matAutocomplete]="auto">
            <mat-autocomplete (optionSelected)="addToSelectedUsers($event.option.value)" #auto="matAutocomplete"
                [displayWith]="displayFn">
                <mat-option *ngFor="let user of unassignedUsersForAutocomplete" [value]="user">
                    {{user.firstName}} {{user.lastName}}, {{user.email}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <!-- Ausgewählte Mitarbeiter -->
    <div *ngIf="selectedUsers.length" class="p-1 my-2">{{
        'administration.features.salespartnerRegion.headerSelectedEmployees'
        | translate }}</div>

    <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
            <div *ngFor="let user of selectedUsers" fxLayout="row" fxLayoutAlign="start center" class="selected-user">
                <div class="margin-15"> {{user.firstName}} {{user.lastName}}, {{user.email}}</div>
                <button mat-icon-button (click)="removeFromSelectedUsers(user)">
                    <mat-icon>canceled</mat-icon>
                </button>
            </div>
        </div>
        <div fxLayoutAlign="end end">
            <button mat-button class="btn-to" color="primary" [disabled]="this.selectedUsers.length === 0"
                (click)="addSalespartnerCenterToUsers()">Hinzufügen</button>
        </div>
    </div>

    <!-- Mitarbeiter zu Region hinzufügen -->
    <div class="salespartner-region-divider p-1 my-2">{{
        'administration.features.salespartnerRegion.headerAssignedEmployees' | translate }}</div>

    <div *ngFor="let user of assignedUsers" fxLayout="row" fxLayoutAlign="start center" class="selected-user">
        <div class="margin-15"> {{user.firstName}} {{user.lastName}}, {{user.email}}</div>
        <button mat-icon-button (click)="deleteSalesCenterFromUser(user)">
            <mat-icon>canceled</mat-icon>
        </button>
    </div>
</div>
