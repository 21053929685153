import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_COUNTRY_CODES_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerCountryCodesModel',

    countryForReporting: { type: WorkflowType.String },

    countryForTaxLiability: { type: WorkflowType.String },

    secondCountryForTaxLiability: { type: WorkflowType.String },

    thirdCountryForTaxLiability: { type: WorkflowType.String },
}
