import { Component, Input } from '@angular/core';

/**
 * Komponente für einheitliche Zwischenüberschrift
 */
@Component({
    selector: 'finprocess-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent {

    @Input()
    public label!: string;
}
