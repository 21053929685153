import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { mergeMap, tap } from 'rxjs/operators';

import { ICanComponentDeactivate } from '../../../checks';

import { AuthenticationService, LoginStateType, Logout } from './../../../../data';

/**
 * Komponente mit Login-Formular
 */
@Component({
    selector: 'finprocess-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements ICanComponentDeactivate {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {Router} router Router-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(private authenticationService: AuthenticationService, private waiterService: WaiterService, private router: Router, private store: Store) { }

    /**
     * Prüft, ob die Komponente verlassen werden kann
     *
     * @returns {boolean} Kann die Komponente verlassen werden
     */
    public canDeactivate(): boolean {
        return this.authenticationService.loginState !== LoginStateType.NotLoggedIn;
    }

    /**
     * Führt eine Anmeldung durch
     *
     * @param {object} param Nutzerdaten
     * @param {string} param.email E-Mail
     * @param {string} param.password Passwort
     * @param {FormGroup} form Formular
     */
    public login({ email, password }: { email: string; password: string; }, form: FormGroup): void {
        this.waiterService.show().pipe(
            mergeMap(() => this.store.dispatch(new Logout())),
            mergeMap(() => this.authenticationService.login(email, password)),
            tap(async result => {
                this.waiterService.hide();
                switch (result) {
                    case LoginStateType.LoggedIn:
                        await this.router.navigateByUrl('/');
                        break;
                    case LoginStateType.Locked:
                        await this.router.navigateByUrl('/auth/locked');
                        break;
                    case LoginStateType.NotLoggedIn:
                    default:
                        form.setErrors({
                            'error': true,
                        });
                        break;
                }
            }),
        ).subscribe({error: () => {form.setErrors({error: true}); this.waiterService.hide()}, complete: () => this.waiterService.hide()});
    }
}
