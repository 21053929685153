import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationsModule } from '@ntag-ef/notifications';
import { iif, of } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';

import { LoginStateType } from './enums';
import { AuthenticationService, AuthorizationService, UserService } from './services';
import { AuthenticationState, UserState } from './states';

export { Logout, IUserStateModel, UserState } from './states';
export { AuthenticationService, AuthorizationService, UserService } from './services';
export { LoginStateType, Role } from './enums';
export { AUTH_PERSISTENT_STATE_KEYS, IAuthStateParentDefinition } from './states/state.definition';
export { IUser } from './interfaces';

/**
 * Modul für Datenverwaltung und Datenaustausch bzgl. Authentifizierung und Authorisierung
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([AuthenticationState, UserState]),
        TranslateModule.forChild(),
        NotificationsModule.forChild(),
    ],
    providers: [
        AuthenticationService,
        UserService,
        AuthorizationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (authenticationService: AuthenticationService, userService: UserService) => (() =>
                authenticationService.initialize().pipe(
                    mergeMap(() => authenticationService.loginState$),
                    mergeMap(loginState =>
                        iif(
                            () => loginState !== LoginStateType.NotLoggedIn,
                            userService.loadUserData().pipe(
                                catchError(() => of(void 0)),
                                tap(user => {
                                    if (user !== undefined) {
                                        authenticationService.notifySuccessfullyLoggedIn();
                                    }
                                }),
                            ),
                            of(void 0),
                        ),
                    ),
                    take(1),
                )),
            deps: [AuthenticationService, UserService],
            multi: true,
        },
    ],
})
export class AuthDataModule {
}
