import { ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { DocumentService, FinancingMode, FinancingService, IDecisionRequestModel, IFinancingStateParentDefinition, IFireRiskDecision } from 'app/modules/financing/data';
import { HelperService, UUID } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs';

import { HttpMethod, WorkflowType } from '../../../workflow-processing/enums';
import { FormattingType } from '../../../workflow-processing/enums/fire';
import { IMidtermModel, IMidtermProperty, IWorkflowRouteData } from '../../../workflow-processing/interfaces';
import { CREDIT_PARTICIPANT, EXTERNAL_REMAIN_BANK_LOAN, VALUATION_LTV_RELEVANT, VALUATION_OTHER } from '../../../workflow-processing/models';
import { MidtermService } from '../../../workflow-processing/services';
import { IFileData } from '../risk-decision/risk-decision.component';

/**
 * Interface für Serverantworten mit zwei Models
 */
interface IMultiResponse {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PackageResponse: unknown;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ApiResponse: unknown;
}

/**
 * Testkomponente zum Auslösen von Requests an die Testcontroller für die Midterm Solution
 *
 * Über die Route muss ein IWorkflowRouteData Objekt übergeben werden, welches das Model
 * sowie einen API Pfad und eine Http Methode enthält
 */
@Component({
    selector: 'finprocess-generic-form-risk-decision',
    templateUrl: './generic-form-risk-decision.component.html',
    styleUrls: ['./generic-form-risk-decision.component.scss'],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class GenericFormRiskDecisionComponent implements OnInit, OnDestroy {

    /**
     * Model, welches über Route Data übergeben werden muss
     */
    @Input()
    public model: IMidtermModel = { midtermName: '' };

    /**
     * Ob die Antwort im API Format oder im NT.AG Package Format angezeigt werden soll.
     * Irrelevant wenn hasSplitResponse = false
     */
    public showResponseApi = false;

    /**
     * Ist die Antwort zweigeteilt, in eine NT.AG Package und eine API Antwort
     */
    public hasSplitResponse = false;

    /**
     * Request im JSON Format
     */
    public requestStringRaw = '';

    /**
     * Antwort im JSON Format
     */
    public responseString = '';

    /**
     * Antwort im JSON Format (für das API Model)
     */
    public responseStringApi = '';

    /**
     * API Pfad, welcher über Route Data übergeben werden muss
     */
    @Input()
    public apiPath = '';

    /**
     * HTTP Methode, welcher über Route Data übergeben werden muss
     */
    @Input()
    public method = HttpMethod.Get;

    /**
     * Übersetzungskey, welcher über Route Data übergeben werden kann
     */
    @Input()
    public globalTranslateKey = '';

    /**
     * Formular
     */
    @Input()
    public form?: FormGroup;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public WorkflowType = WorkflowType;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FormattingType = FormattingType;

    private onDestroy$ = new Subject<void>();

    @Input()
    public data?: Observable<IWorkflowRouteData>;

    /**
     * Fire Result
     */
    public fireResponse?: IFireRiskDecision;

    /**
     * productPackageID
     */
    public productPackageID?: string;

    /**
     * Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     * Currency Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;

    /**
     * Fire Document Informations
     */
    public fireDocumentData?: IFileData;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;


    /**
     * Modus (Einreichung oder Risikofinanzierungsplan)
     */
    public productPackageId$!: Observable<string | null>;

    public dataErrors: string[] | undefined;
    /**
     * Standard Konstruktor
     *
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {MidtermService} midtermService MidtermService
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef Injektor
     * @param {string} locale locale
     * @param {TranslateService} translate TranslateService
     * @param {NotificationService} notificationService NotificationService
     * @param {Router} router router
     * @param {FinancingService} financingService financing service
     * @param {DocumentService} documentService document service
     * @param {Store} store store
     */
    public constructor(
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private midtermService: MidtermService,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private cRef: ChangeDetectorRef,
        @Inject(LOCALE_ID) locale: string,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private router: Router,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private store: Store,
    ) {
        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
        });
    }

    /**
     * Angular Lifecycle Hook
     */
    public ngOnInit(): void {

        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.productPackageId$ = this.activatedRoute.paramMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => {
                if (!!params) {
                    const productPackageID = params.get('productPackageID') as UUID;
                    return productPackageID;
                } else {
                    return null;
                }
            }),
            distinctUntilChanged(),
        )

        this.productPackageId$.pipe((takeUntil(this.onDestroy$))).subscribe(productPackageId => {
            if (!!productPackageId) {
                this.productPackageID = productPackageId.toString();
                this.getData(this.productPackageID);

                this.financingService.getProductPackage(productPackageId).pipe((takeUntil(this.onDestroy$))).subscribe(productpackage => {
                    if (!!productpackage) {
                        const isRiskDecisionFromApi = productpackage.assignProductPackages[0].isRiskDecisionFromApi;

                        if (isRiskDecisionFromApi === true) {
                            this.getFireDocumentAPIData();
                        }
                    }
                });
            }
        });


        if (!!this.data) {
            combineLatest([
                this.productPackageId$,
                this.data,
            ]).pipe(
                takeUntil(this.onDestroy$),
                tap(() => this.waiterService.show()),
                filter(([productPackageId, data]) => (productPackageId !== undefined && productPackageId !== null && data !== undefined)),
                map(([productPackageId, data]) => {
                    this.model = (data as IWorkflowRouteData).model;
                    this.apiPath = (data as IWorkflowRouteData).apiPath;
                    this.method = (data as IWorkflowRouteData).method;
                    this.globalTranslateKey = `${(data as IWorkflowRouteData).translateKey}.`
                    this.form = this.initFormGroup(this.model)
                    return productPackageId;
                }),
                mergeMap(productPackageId => this.financingService.getDecisionRequest(productPackageId as string)),
                filter(value => value !== undefined),
            ).subscribe(data => {

                if (data?.valid && !!data?.pefilledRequestData) {
                    this.createMissingFormParts(data);
                    this.form?.patchValue(data?.pefilledRequestData);
                    this.cRef.detectChanges();
                }
                else {
                    this.dataErrors = data?.validationErrors;
                    this.cRef.detectChanges()
                }
                this.waiterService.hide();
            })
        }
    }

    /**
     * Angular Lifecycle Hook
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Fehlende Formularteile erstellen
     *  
     * @param {IDecisionRequestModel} data DataModel
     */
    // eslint-disable-next-line complexity
    private createMissingFormParts(data: IDecisionRequestModel): void {

        if (!data.pefilledRequestData) {
            return;
        }

        //Kreditbeteiligte max. 4 (von A-D)
        if (Array.isArray(data.pefilledRequestData.creditParticipants) && data.pefilledRequestData.creditParticipants.length > ((this.form?.controls.creditParticipants as FormArray)?.length ?? 0)) {
            for (let i = 0; i <= data.pefilledRequestData.creditParticipants.length - (this.form?.controls.creditParticipants as FormArray)?.length ?? 0; i++) {
                (this.form?.controls.creditParticipants as FormArray).push(this.initFormGroup(CREDIT_PARTICIPANT));
            }
        }
        //Kontonummern
        if (Array.isArray(data.pefilledRequestData.ownCoveredAccountNumbers) && data.pefilledRequestData.ownCoveredAccountNumbers.length > ((this.form?.controls.ownCoveredAccountNumbers as FormArray)?.length ?? 0)) {
            for (let i = 0; i <= data.pefilledRequestData.ownCoveredAccountNumbers.length - (this.form?.controls.ownCoveredAccountNumbers as FormArray)?.length ?? 0; i++) {
                (this.form?.controls.ownCoveredAccountNumbers as FormArray).push(this.fb.control(null));
            }
        }
        //Fremdbankkredit / Bestandskredit Fremd
        if (Array.isArray(data.pefilledRequestData.externalRemainBankLoans) && data.pefilledRequestData.externalRemainBankLoans.length > ((this.form?.controls.externalRemainBankLoans as FormArray)?.length ?? 0)) {
            for (let i = 0; i <= data.pefilledRequestData.externalRemainBankLoans.length - (this.form?.controls.externalRemainBankLoans as FormArray)?.length ?? 0; i++) {
                (this.form?.controls.externalRemainBankLoans as FormArray).push(this.initFormGroup(EXTERNAL_REMAIN_BANK_LOAN));
            }
        }
        //Bewertung LTV-relevante Liegenschaften
        if (Array.isArray(data.pefilledRequestData.valuationLtvRelevants) && data.pefilledRequestData.valuationLtvRelevants.length > ((this.form?.controls.valuationLtvRelevants as FormArray)?.length ?? 0)) {
            for (let i = 0; i <= data.pefilledRequestData.valuationLtvRelevants.length - (this.form?.controls.valuationLtvRelevants as FormArray)?.length ?? 0; i++) {
                (this.form?.controls.valuationLtvRelevants as FormArray).push(this.initFormGroup(VALUATION_LTV_RELEVANT));
            }
        }
        //Bewertung sonstige Liegenschaften
        if (Array.isArray(data.pefilledRequestData.valuationOthers) && data.pefilledRequestData.valuationOthers.length > ((this.form?.controls.valuationOthers as FormArray)?.length ?? 0)) {
            for (let i = 0; i <= data.pefilledRequestData.valuationOthers.length - (this.form?.controls.valuationOthers as FormArray)?.length ?? 0; i++) {
                (this.form?.controls.valuationOthers as FormArray).push(this.initFormGroup(VALUATION_OTHER));
            }
        }
    }

    /**
     * Abspeichern des Formulars
     */
    public saveForm(): void {
        const model: IMidtermModel = this.form?.getRawValue() ?? { midtermName: '' };

        // Create optionalParams as an object with product package id
        if (!!this.productPackageID) {
            const optionalParams = { productPackageID: this.productPackageID };

            this.waiterService.show().pipe(
                mergeMap(() => this.midtermService.callApi(model, this.apiPath, this.method, optionalParams)),
            ).subscribe({
                next: response => {
                    this.waiterService.hide();

                    if (typeof response === 'object' && response !== null && Object.prototype.hasOwnProperty.call(response, 'PackageResponse') && Object.prototype.hasOwnProperty.call(response, 'ApiResponse')) {
                        this.hasSplitResponse = true;
                        this.responseString = `<pre><code>${JSON.stringify((response as IMultiResponse).PackageResponse, null, '  ')}</code></pre>`;
                        this.responseStringApi = `<pre><code>${JSON.stringify((response as IMultiResponse).ApiResponse, null, '  ')}</code></pre>`;
                    } else {
                        this.responseString = `<pre><code>${JSON.stringify(response, null, '  ')}</code></pre>`;
                    }

                    this.fireResponse = response as IFireRiskDecision;

                    if (!!this.fireResponse.messages && this.fireResponse.messages.length > 0) {
                        this.fireErrorMessage(this.fireResponse);    
                    }

                    //Get product package status entries to enable approval component
                    const financingContainerId = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
                    if (!!this.productPackageID && !!financingContainerId) {
                        this.financingService.getProductPackageStatusEntries(this.productPackageID, financingContainerId).subscribe();
                    }

                    this.getFireDocumentAPIData();
                    this.cRef.detectChanges();
                }, error: err => {

                    if (!!err && !!err.message && err.message.length > 0) {
                        this.fireErrorMessage(err);    
                    }

                    this.waiterService.hide();
                    this.notification.alert('Error', JSON.stringify(err));
                },
                complete: () => {
                    this.waiterService.hide();
                },
            });
        }
    }

    /**
     * get Data of Risk Decision
     *
     * @param {string} productPackageID productPackageID
     */
    public getData(productPackageID: string) {

        const model = { productPackageId: productPackageID };

        this.waiterService.show().pipe(
            mergeMap(() => this.midtermService.callApi(model, 'decision/GetExistingDecision', HttpMethod.Get)),
        ).subscribe({
            next: response => {
                this.fireResponse = response as IFireRiskDecision;

                this.waiterService.hide();

                if (typeof response === 'object' && response !== null && Object.prototype.hasOwnProperty.call(response, 'PackageResponse') && Object.prototype.hasOwnProperty.call(response, 'ApiResponse')) {
                    this.hasSplitResponse = true;
                    this.responseString = `<pre><code>${JSON.stringify((response as IMultiResponse).PackageResponse, null, '  ')}</code></pre>`;
                    this.responseStringApi = `<pre><code>${JSON.stringify((response as IMultiResponse).ApiResponse, null, '  ')}</code></pre>`;
                } else {
                    this.responseString = `<pre><code>${JSON.stringify(response, null, '  ')}</code></pre>`;
                }

                this.cRef.detectChanges();
            }, error: err => {
                this.waiterService.hide();
                this.notification.alert('Error', JSON.stringify(err));
            },
            complete: () => {
                this.waiterService.hide();
            },
        });

    }


    /**
     * Gibt die Keys eines Objektes zurück
     *
     * @param {IMidtermModel} model Model
     * @returns {Array<string>} Keys
     */
    public getKeys(model: IMidtermModel): Array<string> {
        return Object.keys(model).filter(key => key !== 'midtermName');
    }

    /**
     * Gibt ein Array an Enum Werten zurück
     *
     * @param {Record<string, string | number>} record Record
     * @returns {Array<number>} Enum Array
     */
    public getEnumArray(record: Record<string, string | number>): Array<number | string> {
        const stringValues = HelperService.getEnumArray(record, false) as string[];
        const numValues = HelperService.getEnumArray(record, true) as number[];
        return numValues.length > 0 ? numValues : stringValues;
    }

    /**
     * Überprüft ob es sich um eine Zahl handelt.
     * Wenn ja besteht das FormArray aus primitiven Typen
     *
     * @param {any} value Wert
     * @returns {boolean} Ob es sich um eine Zahl handelt
     */
    public isPrimitiveArray(value: unknown): value is number {
        return typeof value === 'number';
    }

    /**
     * Entfernt einen Wert aus einem FormArray
     *
     * @param {FormArray} control FormArray
     * @param {number} index Index
     */
    public removeFromArray(control: FormArray, index: number): void {
        control.removeAt(index);
    }

    /**
     * Fügt einen neuen Wert zu einem FormArray hinzu
     *
     * @param {FormArray} control FormArray
     * @param {IMidtermModel} model Model
     */
    public addToFormArray(control: FormArray, model: IMidtermModel | WorkflowType): void {
        if (this.isPrimitiveArray(model)) {
            control.push(this.fb.control(null));
        } else {
            control.push(this.initFormGroup(model));
        }
    }

    /**
     * Gibt eine Fehlermeldung zurück
     *
     * @param {AbstractControl} control Control
     * @returns {string | undefined } Fehlerbeschreibung
     */
    public getError(control: AbstractControl): string | undefined {
        if (control.hasError('required')) {
            return 'This field is required';
        }

        const min = control.getError('min');

        if (!!min) {
            return `Value below min value ${min.min}`;
        }

        const max = control.getError('max');

        if (!!max) {
            return `Value above max value ${max.max}`;
        }

        const minLength = control.getError('minlength');

        if (!!minLength) {
            return `Needs to be at least ${minLength.requiredLength} characters`;
        }

        const maxLength = control.getError('maxlength');

        if (!!maxLength) {
            return `Needs to be at most ${maxLength.requiredLength} characters`;
        }

        return undefined;
    }

    /**
     * Initialisiert eine FormGroup
     *
     * @param {IMidtermModel} model Model
     * @returns {FormGroup} FormGroup
     */
    private initFormGroup(model: IMidtermModel): FormGroup {
        const keys = this.getKeys(model);

        const controls: AbstractControl[] = keys.map(key => {
            const value = model[key] as IMidtermProperty;

            let defaultValue: boolean;
            // For boolean types, set the default value to false
            if (value.type === WorkflowType.Boolean) {
                defaultValue = false;
                return this.fb.control(value.default = defaultValue);
            }

            if (value.type === WorkflowType.Array && value.child !== undefined) {
                return this.initFormArray(value.child, value.validators, value.defaultValue);
            }

            if (value.type === WorkflowType.Object && value.child !== undefined) {
                return this.initFormGroup(value.child as IMidtermModel);
            }


            if (value.validators === null) {
                return this.fb.control(value.defaultValue ?? null);
            } else if (Array.isArray(value.validators)) {
                return this.fb.control(value.defaultValue ?? null, { validators: Validators.compose(value.validators) });
            } else {
                return this.fb.control(value.defaultValue ?? null, { validators: value.validators });
            }
        });

        const formGroup = this.fb.group({});

        for (let i = 0; i < controls.length; i++) {
            formGroup.addControl(keys[i], controls[i]);
        }

        return formGroup;
    }

    /**
     * Initialisiert ein FormArray
     *
     * @param {IMidtermModel} model Model
     * @param {Validators} validators optionale Validatoren
     * @param {defaultValue} defaultValue optionale defaultValue
     * @returns {FormArray} FormArray
     */
    private initFormArray(model: IMidtermModel | WorkflowType | Record<string, string | number>, validators?: ValidatorFn | ValidatorFn[] | null, defaultValue?: number | string): FormArray {
        if (this.isPrimitiveArray(model)) {
            return this.fb.array([
                this.fb.control(defaultValue ?? null, { validators: validators, updateOn: 'change' }),
            ]);
        } else {
            return this.fb.array([
                this.initFormGroup(model as IMidtermModel),
            ]);
        }
    }

    /**
     * Mappt den Inhalt des requeststrings auf das Formular
     *
     *
     * @param { EventTarget | null } target  fdfs
     */
    public jsonToFormData(target: EventTarget | null): void {

        const textArea = target as HTMLInputElement;

        this.requestStringRaw = textArea.value;

        const requestStringRawAsObject = JSON.parse(this.requestStringRaw);

        this.form?.patchValue(requestStringRawAsObject);

        this.cRef.detectChanges();
    }

    /**
     * switch to approval component
     */
    public switchToApproval() {
        this.router.navigate(['..', 'approval'], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan } }).catch(error => {
            this.notificationService.alert(this.translate.instant('general.routingError'), error);
        });
    }

    /**
     * get fire document informations (name, id, date) from fire api
     */
    public getFireDocumentAPIData() {
        if (!!this.productPackageID) {
            this.financingService.getProductPackageFireDocumentInfos(this.productPackageID).subscribe({
                next: result => {
                    if (!!result) {
                        this.fireDocumentData = {
                            fileId: result.fileId,
                            fileName: result.fileName,
                            created: result.created,
                        };
                        this.cRef.detectChanges();
                    }
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.risk-decision.loadError'));
                },
            });
        }
    }

    /**
     * download Fire Document
     */
    public downloadFireDocument() {
        if (!!this.fireDocumentData) {
            this.documentService.loadFile(this.fireDocumentData.fileId).subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, 'application/pdf');

                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }
                },
            });
        }
    }

    /**
     * Fire Error Message
     * 
     * @param {IFireRiskDecision} riskDecision riskDecision
     */
    private fireErrorMessage(riskDecision: IFireRiskDecision): void {
        let messageText = '';
        riskDecision.messages?.forEach((message, index) => {
            const messageNumber = index + 1;
            messageText += `
            <strong><mat-icon>${this.getSeverityIcon(message.severity)}</mat-icon> ${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageTitle2')}:</strong> ${messageNumber}<hr class="custom-hr"><br>
            <strong>${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageID')}:</strong> ${message.id ?? 'nicht definiert'}<br>
            <strong>${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageErrorCode')}:</strong> ${message.code ?? 'nicht definiert'}<br>
            <strong>${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageSeverity')}:</strong> ${message.severity ?? 'nicht definiert'}<br>
            <strong>${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageText')}:</strong> ${message.text ?? 'nicht definiert'}<br>
            <strong>${this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageReference')}:</strong> ${message.refField ?? 'nicht definiert'}<br><br>`;
        });
        this.notificationService.alert(this.translate.instant('financing.features.financing-processing.risk-decision.fireMessageTitle'), messageText);
    }

    /**
     * Get Severity icon for Fire Error Message
     * 
     * @param {string} severity severity
     * @returns {string} icon
     */
    private getSeverityIcon(severity: string): string {
        switch (severity) {
            case 'E':
            case null:
                return '&#10071;'; //error exclamation
            case 'W':
            case 'H':
                return '&#9888;'; //warning
            case 'I':
            case 'INFO':
                return '&#9432;'; //info
            default:
                return '';
        }
    }

}
