/**
 * Additional Code für FIRE
 */
export enum AdditionalCode {
    /**
     * Allgemeiner FIRE-Check OK
     */
    AC051,

    /**
     * Es wurde eine Auffälligkeit gefunden
     */
    AC122,

    /**
     * Es wurde eine ABLEHNUNG gefunden 
     */
    AC133,
}
