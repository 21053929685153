import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Kellerbauweise
 */
export enum BasementConstruction {
    /**
     * Ebenerdiger Ausgang in Garten
     */
    ExitAtGroundLevel = 1,

    /**
     * Rohbaukeller
     */
    BasicStructure = 2,
}

export namespace BasementConstruction {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {BasementConstruction | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: BasementConstruction | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'BasementConstruction', BasementConstruction, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('BasementConstruction', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {BasementConstruction | undefined | null} value Enum-Wert
     * @param {BasementConstruction} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: BasementConstruction | undefined | null, flag: BasementConstruction): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
