import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, combineLatest, forkJoin, map, mergeMap, take, takeUntil, tap } from 'rxjs';

import { ISystemConfigurationValue } from '../../../../data/interfaces';
import { RiskParametrizationService } from '../../../../data/services';
import { IAdministrationStateParentDefinition } from '../../../../data/states';

/**
 * Komponente zur Verwaltung der Nutzervereinbarung
 */
@Component({
    selector: 'finprocess-system-config',
    templateUrl: './system-config.component.html',
    styleUrls: ['./system-config.component.scss'],
})
export class SystemConfigComponent implements OnDestroy, OnInit {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Formular
     */
    public form: FormGroup;

    /**
     * Ob die Komponente zur Bearbeitung gesperrt ist
     */
    public locked = true;


    private viewLeft$ = new Subject<void>();

    /**
     * Formatierung für Faktoren
     */
    public factorOptionsThree: NgxCurrencyConfig;

    /**
     * Formatierung für €
     */
    public currencyOptions: NgxCurrencyConfig;

    /**
     * Standard Konstruktor
     *
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {RiskParametrizationService} riskParametrizationService RiskParametrizationService-Injektor
     * @param {Store} store Store-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {string} locale Lokalisierung
     */
    public constructor(
        private fb: FormBuilder,
        private riskParametrizationService: RiskParametrizationService,
        private store: Store,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        @Inject(LOCALE_ID) locale: string,
    ) {
        this.form = this.fb.group({
            userAgreement: [null],
            amountLimit: [null],
            interestRate: [null],
            defaultReferenceInterestRate: [null],
            defaultAddition: [null],
            additionComparativeCalculation: [null],
            bruttoNettoCalculatorUrl: [null],
            baseChildrenAmount: [null],
            baseChildrenObligationAmount: [null],
            baseChildrenRecipientAmount: [null],
            safetyMarginExpenses: [null],
            safetyMarginExpensesForPension: [null],

            operationalCostsHHR: [null],
            operationalCostsTextHHR: [null],
            phoneCostsHHR: [null],
            phoneCostsTextHHR: [null],
            electricityCostsHHR: [null],
            electricityCostsTextHHR: [null],
            carCostsHHR: [null],
            carCostsTextHHR: [null],
            insuranceCostsHHR: [null],
            insuranceCostsTextHHR: [null],
            otherCostsHHR: [null],
            otherCostsTextHHR: [null],
            livingCostsDebitorHHR: [null],
            livingCostsDebitorTextHHR: [null],
            livingCostsOfDependentChildrenHHR: [null],
            livingCostsOfDependentChildrenTextHHR: [null],
            additionalExpensesForChildrenHHR: [null],
            additionalExpensesForChildrenTextHHR: [null],
            expensesForAdditionalResidentialpropertiesForPersonalUseHHR: [null],
            expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR: [null],
            operationalCostsPHHR: [null],
            operationalCostsTextPHHR: [null],
            phoneCostsPHHR: [null],
            phoneCostsTextPHHR: [null],
            electricityCostsPHHR: [null],
            electricityCostsTextPHHR: [null],
            carCostsPHHR: [null],
            carCostsTextPHHR: [null],
            insuranceCostsPHHR: [null],
            insuranceCostsTextPHHR: [null],
            otherCostsPHHR: [null],
            otherCostsTextPHHR: [null],
            livingCostsDebitorPHHR: [null],
            livingCostsDebitorTextPHHR: [null],
            livingCostsOfDependentChildrenPHHR: [null],
            livingCostsOfDependentChildrenTextPHHR: [null],
            additionalExpensesForChildrenPHHR: [null],
            additionalExpensesForChildrenTextPHHR: [null],
            expensesForAdditionalResidentialpropertiesForPersonalUsePHHR: [null],
            expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR: [null],
        });

        this.currencyOptions = HelperService.getInputMask(locale, {
            align: 'left',
            allowNegative: false,
            prefix: '€ ',
            precision: 2,
            thousands: '.',
            allowZero: true,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.factorOptionsThree = HelperService.getInputMask(locale, {
            align: 'left',
            suffix: '%',
            precision: 3,
            allowZero: true,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.disableForm();
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {

        combineLatest([
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AmountLimit))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.InterestRate))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.DefaultReferenceInterestRate))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.DefaultAddition))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AdditionComparativeCalculation))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BruttoNettoCalculatorUrl))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenAmount))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenObligationAmount))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.BaseChildrenRecipientAmount))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.SafetyMarginExpenses))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.SafetyMarginExpensesForPension))),

            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.OperationalCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.PhoneCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.ElectricityCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.CarCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.InsuranceCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.OtherCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.LivingCostsHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.LivingCostsOfDependentChildrenHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AdditionalExpensesForChildrenHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUseHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.OperationalCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.PhoneCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.ElectricityCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.CarCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.InsuranceCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.OtherCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.LivingCostsPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.LivingCostsOfDependentChildrenPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.AdditionalExpensesForChildrenPHHR))),
            this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(map(fn => fn(SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUsePHHR))),
        ]).subscribe(([amountLimit, interestRate, defaultReferenceInterestRate, defaultAddition, additionComparativeCalculation,
            bruttoNettoCalculatorUrl, baseChildrenAmount, baseChildrenObligationAmount, baseChildrenRecipientAmount, safetyMarginExpenses, safetyMarginExpensesForPension,

            operationalCostsHHR, phoneCostsHHR, electricityCostsHHR, carCostsHHR, insuranceCostsHHR,
            otherCostsHHR, livingCostsHHR, livingCostsOfDependentChildrenHHR, additionalExpensesForChildrenHHR,
            expensesForAdditionalResidentialpropertiesForPersonalUseHHR,
            operationalCostsPHHR, phoneCostsPHHR, electricityCostsPHHR, carCostsPHHR, insuranceCostsPHHR,
            otherCostsPHHR, livingCostsPHHR, livingCostsOfDependentChildrenPHHR, additionalExpensesForChildrenPHHR,
            expensesForAdditionalResidentialpropertiesForPersonalUsePHHR,
        ]) => {
            this.form.get('amountLimit')?.patchValue(amountLimit?.value);
            this.form.get('interestRate')?.patchValue(interestRate?.value);
            this.form.get('defaultReferenceInterestRate')?.patchValue(defaultReferenceInterestRate?.value);
            this.form.get('defaultAddition')?.patchValue(defaultAddition?.value);
            this.form.get('additionComparativeCalculation')?.patchValue(additionComparativeCalculation?.value);
            this.form.get('bruttoNettoCalculatorUrl')?.patchValue(bruttoNettoCalculatorUrl?.value);
            this.form.get('baseChildrenAmount')?.patchValue(baseChildrenAmount?.value);
            this.form.get('baseChildrenObligationAmount')?.patchValue(baseChildrenObligationAmount?.value);
            this.form.get('baseChildrenRecipientAmount')?.patchValue(baseChildrenRecipientAmount?.value);
            this.form.get('safetyMarginExpenses')?.patchValue(safetyMarginExpenses?.value);
            this.form.get('safetyMarginExpensesForPension')?.patchValue(safetyMarginExpensesForPension?.value);

            this.form.get('operationalCostsHHR')?.patchValue(operationalCostsHHR?.value);
            this.form.get('operationalCostsTextHHR')?.patchValue(operationalCostsHHR?.freeText);
            this.form.get('phoneCostsHHR')?.patchValue(phoneCostsHHR?.value);
            this.form.get('phoneCostsTextHHR')?.patchValue(phoneCostsHHR?.freeText);
            this.form.get('electricityCostsHHR')?.patchValue(electricityCostsHHR?.value);
            this.form.get('electricityCostsTextHHR')?.patchValue(electricityCostsHHR?.freeText);
            this.form.get('carCostsHHR')?.patchValue(carCostsHHR?.value);
            this.form.get('carCostsTextHHR')?.patchValue(carCostsHHR?.freeText);
            this.form.get('insuranceCostsHHR')?.patchValue(insuranceCostsHHR?.value);
            this.form.get('insuranceCostsTextHHR')?.patchValue(insuranceCostsHHR?.freeText);
            this.form.get('otherCostsHHR')?.patchValue(otherCostsHHR?.value);
            this.form.get('otherCostsTextHHR')?.patchValue(otherCostsHHR?.freeText);
            this.form.get('livingCostsDebitorHHR')?.patchValue(livingCostsHHR?.value);
            this.form.get('livingCostsDebitorTextHHR')?.patchValue(livingCostsHHR?.freeText);
            this.form.get('livingCostsOfDependentChildrenHHR')?.patchValue(livingCostsOfDependentChildrenHHR?.value);
            this.form.get('livingCostsOfDependentChildrenTextHHR')?.patchValue(livingCostsOfDependentChildrenHHR?.freeText);
            this.form.get('additionalExpensesForChildrenHHR')?.patchValue(additionalExpensesForChildrenHHR?.value);
            this.form.get('additionalExpensesForChildrenTextHHR')?.patchValue(additionalExpensesForChildrenHHR?.freeText);
            this.form.get('expensesForAdditionalResidentialpropertiesForPersonalUseHHR')?.patchValue(expensesForAdditionalResidentialpropertiesForPersonalUseHHR?.value);
            this.form.get('expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR')?.patchValue(expensesForAdditionalResidentialpropertiesForPersonalUseHHR?.freeText);

            this.form.get('operationalCostsPHHR')?.patchValue(operationalCostsPHHR?.value);
            this.form.get('operationalCostsTextPHHR')?.patchValue(operationalCostsPHHR?.freeText);
            this.form.get('phoneCostsPHHR')?.patchValue(phoneCostsPHHR?.value);
            this.form.get('phoneCostsTextPHHR')?.patchValue(phoneCostsPHHR?.freeText);
            this.form.get('electricityCostsPHHR')?.patchValue(electricityCostsPHHR?.value);
            this.form.get('electricityCostsTextPHHR')?.patchValue(electricityCostsPHHR?.freeText);
            this.form.get('carCostsPHHR')?.patchValue(carCostsPHHR?.value);
            this.form.get('carCostsTextPHHR')?.patchValue(carCostsPHHR?.freeText);
            this.form.get('insuranceCostsPHHR')?.patchValue(insuranceCostsPHHR?.value);
            this.form.get('insuranceCostsTextPHHR')?.patchValue(insuranceCostsPHHR?.freeText);
            this.form.get('otherCostsPHHR')?.patchValue(otherCostsPHHR?.value);
            this.form.get('otherCostsTextPHHR')?.patchValue(otherCostsPHHR?.freeText);
            this.form.get('livingCostsDebitorPHHR')?.patchValue(livingCostsPHHR?.value);
            this.form.get('livingCostsDebitorTextPHHR')?.patchValue(livingCostsPHHR?.freeText);
            this.form.get('livingCostsOfDependentChildrenPHHR')?.patchValue(livingCostsOfDependentChildrenPHHR?.value);
            this.form.get('livingCostsOfDependentChildrenTextPHHR')?.patchValue(livingCostsOfDependentChildrenPHHR?.freeText);
            this.form.get('additionalExpensesForChildrenPHHR')?.patchValue(additionalExpensesForChildrenPHHR?.value);
            this.form.get('additionalExpensesForChildrenTextPHHR')?.patchValue(additionalExpensesForChildrenPHHR?.freeText);
            this.form.get('expensesForAdditionalResidentialpropertiesForPersonalUsePHHR')?.patchValue(expensesForAdditionalResidentialpropertiesForPersonalUsePHHR?.value);
            this.form.get('expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR')?.patchValue(expensesForAdditionalResidentialpropertiesForPersonalUsePHHR?.freeText);
        });


        this.riskParametrizationService.getSystemConfiguration(SystemConfigurationType.UserAgreementText).pipe(
            take(1),
        ).subscribe();

        this.store.select((it: IAdministrationStateParentDefinition) => it.riskParametrization.singleConfigurationValues).pipe(
            takeUntil(this.viewLeft$),
        ).subscribe(configurationValues => {
            const userAgreement = configurationValues.find(value => value.type === SystemConfigurationType.UserAgreementText);

            if (!!userAgreement) {
                this.form.get('userAgreement')?.patchValue(userAgreement.value);
            }
        });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
        this.viewLeft$.complete();
    }

    /**
     * Speichert die Nutzervereinbarung
     */
    // eslint-disable-next-line complexity
    public save(): void {
        const requests: Observable<unknown>[] = [];

        if (this.shoudUpdateControl('amountLimit')) {
            requests.push(this.getRequest('amountLimit', SystemConfigurationType.AmountLimit))
        }

        if (this.shoudUpdateControl('interestRate')) {
            requests.push(this.getRequest('interestRate', SystemConfigurationType.InterestRate))
        }

        if (this.shoudUpdateControl('defaultReferenceInterestRate')) {
            requests.push(this.getRequest('defaultReferenceInterestRate', SystemConfigurationType.DefaultReferenceInterestRate))
        }

        if (this.shoudUpdateControl('defaultAddition')) {
            requests.push(this.getRequest('defaultAddition', SystemConfigurationType.DefaultAddition))
        }

        if (this.shoudUpdateControl('additionComparativeCalculation')) {
            requests.push(this.getRequest('additionComparativeCalculation', SystemConfigurationType.AdditionComparativeCalculation))
        }

        if (this.shoudUpdateControl('userAgreement')) {
            requests.push(this.getRequest('userAgreement', SystemConfigurationType.UserAgreementText))
        }

        if (this.shoudUpdateControl('bruttoNettoCalculatorUrl')) {
            requests.push(this.getRequest('bruttoNettoCalculatorUrl', SystemConfigurationType.BruttoNettoCalculatorUrl))
        }

        if (this.shoudUpdateControl('baseChildrenAmount')) {
            requests.push(this.getRequest('baseChildrenAmount', SystemConfigurationType.BaseChildrenAmount))
        }

        if (this.shoudUpdateControl('baseChildrenObligationAmount')) {
            requests.push(this.getRequest('baseChildrenObligationAmount', SystemConfigurationType.BaseChildrenObligationAmount))
        }

        if (this.shoudUpdateControl('baseChildrenRecipientAmount')) {
            requests.push(this.getRequest('baseChildrenRecipientAmount', SystemConfigurationType.BaseChildrenRecipientAmount))
        }

        if (this.shoudUpdateControl('safetyMarginExpenses')) {
            requests.push(this.getRequest('safetyMarginExpenses', SystemConfigurationType.SafetyMarginExpenses))
        }

        if (this.shoudUpdateControl('safetyMarginExpensesForPension')) {
            requests.push(this.getRequest('safetyMarginExpensesForPension', SystemConfigurationType.SafetyMarginExpensesForPension))
        }

        // HHR
        if (this.shoudUpdateControl('operationalCostsHHR')) {
            requests.push(this.getRequest('operationalCostsHHR', SystemConfigurationType.OperationalCostsHHR, 'operationalCostsTextHHR'))
        }
        if (this.shoudUpdateControl('operationalCostsTextHHR')) {
            requests.push(this.getRequest('operationalCostsHHR', SystemConfigurationType.OperationalCostsHHR, 'operationalCostsTextHHR'))
        }

        if (this.shoudUpdateControl('phoneCostsHHR')) {
            requests.push(this.getRequest('phoneCostsHHR', SystemConfigurationType.PhoneCostsHHR, 'phoneCostsTextHHR'))
        }
        if (this.shoudUpdateControl('phoneCostsTextHHR')) {
            requests.push(this.getRequest('phoneCostsHHR', SystemConfigurationType.PhoneCostsHHR, 'phoneCostsTextHHR'))
        }

        if (this.shoudUpdateControl('electricityCostsHHR')) {
            requests.push(this.getRequest('electricityCostsHHR', SystemConfigurationType.ElectricityCostsHHR, 'electricityCostsTextHHR'))
        }
        if (this.shoudUpdateControl('electricityCostsTextHHR')) {
            requests.push(this.getRequest('electricityCostsHHR', SystemConfigurationType.ElectricityCostsHHR, 'electricityCostsTextHHR'))
        }

        if (this.shoudUpdateControl('carCostsHHR')) {
            requests.push(this.getRequest('carCostsHHR', SystemConfigurationType.CarCostsHHR, 'carCostsTextHHR'))
        }
        if (this.shoudUpdateControl('carCostsTextHHR')) {
            requests.push(this.getRequest('carCostsHHR', SystemConfigurationType.CarCostsHHR, 'carCostsTextHHR'))
        }

        if (this.shoudUpdateControl('insuranceCostsHHR')) {
            requests.push(this.getRequest('insuranceCostsHHR', SystemConfigurationType.InsuranceCostsHHR, 'insuranceCostsTextHHR'))
        }
        if (this.shoudUpdateControl('insuranceCostsTextHHR')) {
            requests.push(this.getRequest('insuranceCostsHHR', SystemConfigurationType.InsuranceCostsHHR, 'insuranceCostsTextHHR'))
        }

        if (this.shoudUpdateControl('otherCostsHHR')) {
            requests.push(this.getRequest('otherCostsHHR', SystemConfigurationType.OtherCostsHHR, 'otherCostsTextHHR'))
        }
        if (this.shoudUpdateControl('otherCostsTextHHR')) {
            requests.push(this.getRequest('otherCostsHHR', SystemConfigurationType.OtherCostsHHR, 'otherCostsTextHHR'))
        }

        if (this.shoudUpdateControl('livingCostsDebitorHHR')) {
            requests.push(this.getRequest('livingCostsDebitorHHR', SystemConfigurationType.LivingCostsHHR, 'livingCostsDebitorTextHHR'))
        }
        if (this.shoudUpdateControl('livingCostsDebitorTextHHR')) {
            requests.push(this.getRequest('livingCostsDebitorHHR', SystemConfigurationType.LivingCostsHHR, 'livingCostsDebitorTextHHR'))
        }

        if (this.shoudUpdateControl('livingCostsOfDependentChildrenHHR')) {
            requests.push(this.getRequest('livingCostsOfDependentChildrenHHR', SystemConfigurationType.LivingCostsOfDependentChildrenHHR, 'livingCostsOfDependentChildrenTextHHR'))
        }
        if (this.shoudUpdateControl('livingCostsOfDependentChildrenTextHHR')) {
            requests.push(this.getRequest('livingCostsOfDependentChildrenHHR', SystemConfigurationType.LivingCostsOfDependentChildrenHHR, 'livingCostsOfDependentChildrenTextHHR'))
        }

        if (this.shoudUpdateControl('additionalExpensesForChildrenHHR')) {
            requests.push(this.getRequest('additionalExpensesForChildrenHHR', SystemConfigurationType.AdditionalExpensesForChildrenHHR, 'additionalExpensesForChildrenTextHHR'))
        }
        if (this.shoudUpdateControl('additionalExpensesForChildrenTextHHR')) {
            requests.push(this.getRequest('additionalExpensesForChildrenHHR', SystemConfigurationType.AdditionalExpensesForChildrenHHR, 'additionalExpensesForChildrenTextHHR'))
        }

        if (this.shoudUpdateControl('expensesForAdditionalResidentialpropertiesForPersonalUseHHR')) {
            requests.push(this.getRequest('expensesForAdditionalResidentialpropertiesForPersonalUseHHR', SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUseHHR, 'expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR'))
        }
        if (this.shoudUpdateControl('expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR')) {
            requests.push(this.getRequest('expensesForAdditionalResidentialpropertiesForPersonalUseHHR', SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUseHHR, 'expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR'))
        }

        if (this.shoudUpdateControl('operationalCostsPHHR')) {
            requests.push(this.getRequest('operationalCostsPHHR', SystemConfigurationType.OperationalCostsPHHR, 'operationalCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('operationalCostsTextPHHR')) {
            requests.push(this.getRequest('operationalCostsPHHR', SystemConfigurationType.OperationalCostsPHHR, 'operationalCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('phoneCostsPHHR')) {
            requests.push(this.getRequest('phoneCostsPHHR', SystemConfigurationType.PhoneCostsPHHR, 'phoneCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('phoneCostsTextPHHR')) {
            requests.push(this.getRequest('phoneCostsPHHR', SystemConfigurationType.PhoneCostsPHHR, 'phoneCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('electricityCostsPHHR')) {
            requests.push(this.getRequest('electricityCostsPHHR', SystemConfigurationType.ElectricityCostsPHHR, 'electricityCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('electricityCostsTextPHHR')) {
            requests.push(this.getRequest('electricityCostsPHHR', SystemConfigurationType.ElectricityCostsPHHR, 'electricityCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('carCostsPHHR')) {
            requests.push(this.getRequest('carCostsPHHR', SystemConfigurationType.CarCostsPHHR, 'carCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('carCostsTextPHHR')) {
            requests.push(this.getRequest('carCostsPHHR', SystemConfigurationType.CarCostsPHHR, 'carCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('insuranceCostsPHHR')) {
            requests.push(this.getRequest('insuranceCostsPHHR', SystemConfigurationType.InsuranceCostsPHHR, 'insuranceCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('insuranceCostsTextPHHR')) {
            requests.push(this.getRequest('insuranceCostsPHHR', SystemConfigurationType.InsuranceCostsPHHR, 'insuranceCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('otherCostsPHHR')) {
            requests.push(this.getRequest('otherCostsPHHR', SystemConfigurationType.OtherCostsPHHR, 'otherCostsTextPHHR'))
        }
        if (this.shoudUpdateControl('otherCostsTextPHHR')) {
            requests.push(this.getRequest('otherCostsPHHR', SystemConfigurationType.OtherCostsPHHR, 'otherCostsTextPHHR'))
        }

        if (this.shoudUpdateControl('livingCostsDebitorPHHR')) {
            requests.push(this.getRequest('livingCostsDebitorPHHR', SystemConfigurationType.LivingCostsPHHR, 'livingCostsDebitorTextPHHR'))
        }
        if (this.shoudUpdateControl('livingCostsDebitorTextPHHR')) {
            requests.push(this.getRequest('livingCostsDebitorPHHR', SystemConfigurationType.LivingCostsPHHR, 'livingCostsDebitorTextPHHR'))
        }

        if (this.shoudUpdateControl('livingCostsOfDependentChildrenPHHR')) {
            requests.push(this.getRequest('livingCostsOfDependentChildrenPHHR', SystemConfigurationType.LivingCostsOfDependentChildrenPHHR, 'livingCostsOfDependentChildrenTextPHHR'))
        }
        if (this.shoudUpdateControl('livingCostsOfDependentChildrentextPHHR')) {
            requests.push(this.getRequest('livingCostsOfDependentChildrenPHHR', SystemConfigurationType.LivingCostsOfDependentChildrenPHHR, 'livingCostsOfDependentChildrenTextPHHR'))
        }

        if (this.shoudUpdateControl('additionalExpensesForChildrenPHHR')) {
            requests.push(this.getRequest('additionalExpensesForChildrenPHHR', SystemConfigurationType.AdditionalExpensesForChildrenPHHR, 'additionalExpensesForChildrenTextPHHR'))
        }
        if (this.shoudUpdateControl('additionalExpensesForChildrenTextPHHR')) {
            requests.push(this.getRequest('additionalExpensesForChildrenPHHR', SystemConfigurationType.AdditionalExpensesForChildrenPHHR, 'additionalExpensesForChildrenTextPHHR'))
        }

        if (this.shoudUpdateControl('expensesForAdditionalResidentialpropertiesForPersonalUsePHHR')) {
            requests.push(this.getRequest('expensesForAdditionalResidentialpropertiesForPersonalUsePHHR', SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUsePHHR, 'expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR'))
        }
        if (this.shoudUpdateControl('expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR')) {
            requests.push(this.getRequest('expensesForAdditionalResidentialpropertiesForPersonalUsePHHR', SystemConfigurationType.ExpensesForAdditionalResidentialpropertiesForPersonalUsePHHR, 'expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR'))
        }

        if (requests.length > 0) {
            this.waiterService.show().pipe(
                mergeMap(() => forkJoin(requests)),
                tap(() => this.waiterService.hide()),
            ).subscribe(
                {
                    next: () => {
                        this.waiterService.hide();
                        this.locked = true;
                        this.disableForm();
                        this.form.markAsPristine();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notification.alert(
                            this.translate.instant('general.error'),
                            this.translate.instant('administration.features.userAgreement.error'),
                        );
                    },
                },
            );
        }
    }

    /**
     * Check muss control updated werden
     *
     * @param {string} controlName controlName
     * @returns {boolean  } boolean
     */
    private shoudUpdateControl(controlName: string): boolean {
        return this.form.get(controlName)?.dirty ?? false;
    }

    /**
     *  Erstellt ein Update Requests 
     * 
     * @param {string} controlName controlName 
     * @param {SystemConfigurationType} type SystemConfigurationType
     * @param {string} controlNameFreeText controlNameFreeText
     * @returns {Observable<unknown> } Request für Server
     */
    private getRequest(controlName: string, type: SystemConfigurationType, controlNameFreeText?: string): Observable<unknown> {

        if (!!controlNameFreeText) {
            const config: ISystemConfigurationValue = {
                type: type,
                mandantId: undefined,
                value: this.form.get(controlName)?.value?.toString(),
                freeText: this.form.get(controlNameFreeText)?.value?.toString(),
            };
            return this.riskParametrizationService.updateSystemConfiguration(config);
        }
        else {
            const config: ISystemConfigurationValue = {
                type: type,
                mandantId: undefined,
                value: this.form.get(controlName)?.value?.toString(),
                freeText: '',
            };
            return this.riskParametrizationService.updateSystemConfiguration(config);
        }
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.form.enable();
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.form.disable();
    }

    /**
     * check if Form has any error
     * 
     * @param {FormGroup} form FormGroup
     * @returns {boolean} true/false
     */
    public hasAnyError(form: FormGroup): boolean {
        return Object.keys(form.controls).some(controlName => {
            const control = form.get(controlName);
            return control && control.invalid && control.dirty;
        });
    }
}
