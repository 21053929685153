import { IDocument } from '../../interfaces';

/**
 * Aktion beim Laden der Dokumente
 */
export class DocumentsLoaded {
    public static readonly type = 'document.loaded';

    /**
     * Konstruktor
     *
     * @param {IDocument} payload Dokumente der Finanzierung
     */
    public constructor(public payload: IDocument[]) { }
}
