import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { MANUAL_SEGMENT_MODEL } from './manual-segment-model';

export const CUSTOMER_ECONOMIC_INFO_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerEconomicInfoModel',

    manualSegment: { type: WorkflowType.Object, child: MANUAL_SEGMENT_MODEL },
}
