import { EnumSettings } from './../enum.settings';

/**
 * Orientierung des Wohnzimmers
 */
export enum Orientation {
    /**
     * Nord
     */
    North = 0,

    /**
     * Süd
     */
    South = 1,

    /**
     * Westen
     */
    West = 2,

    /**
     * Osten
     */
    East = 3,
}

export namespace Orientation {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {Orientation | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: Orientation | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'Orientation', Orientation, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('Orientation', plocale);
}
