/**
 * ActionId Typen
 */
export enum LegalType {
    /**
     * FREE PROFESSIONS
     */
     FREEP,

    /**
     * PRIVATE PERSON
     */
     PRIV,

     /**
      * SELFEMPLOYED PERSON
      */
     SELFE

}
