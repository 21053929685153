<div class="p-2">
    <div class="button-group mt-3">
        <button
            mat-raised-button
            [disabled]="
                !hasEveryDebitorCustomerNumber || (editingReadonly$ | async) || (financingStatus !== Status.Open && financingSubStatus !== SubStatus.Editing)
            "
            (click)="callExternalSystemData()">
            {{ 'financing.features.financing-processing.liabilities.liabilityButtons.agp-request' | translate }}
        </button>
        <button
            mat-raised-button
            [disabled]="(editingReadonly$ | async) || (financingStatus !== Status.Open && financingSubStatus !== SubStatus.Editing)"
            (click)="requestJointHeadingAGP()">
            {{ 'financing.features.financing-processing.liabilities.liabilityButtons.jointHeadingRequest' | translate }}
        </button>
        <button
            mat-raised-button
            [disabled]="(editingReadonly$ | async) || (financingStatus !== Status.Open && financingSubStatus !== SubStatus.Editing)"
            class="openAddLiabilityDialog"
            (click)="openAddLiabilityDialog()">
            {{ 'financing.features.financing-processing.liabilities.liabilityButtons.addNewLiability' | translate }}
        </button>
    </div>
    <div *ngIf="!hasEveryDebitorCustomerNumber" align="right" class="pt-2">
        <b>{{ 'financing.features.financing-processing.liabilities.liabilityWarning' | translate }}</b>
    </div>

    <div class="expansion-panels" *ngIf="liabilityData$ | async as liabilityData">
        <div
            *ngIf="liabilityData.households.length === 0"
            class="empty-hint p-4"
            innerHtml="{{ 'financing.features.financing-processing.liabilities.emptyJointHeadings' | translate }}"></div>
        <ng-container *ngFor="let household of liabilityData.households; let i = index">
            <mat-expansion-panel style="background-color: transparent; box-shadow: none" expanded>
                <mat-expansion-panel-header>
                    <div class="color-primary">
                        <b *ngIf="household.position !== undefined"
                            >{{ 'financing.features.financing-processing.liabilities.liabilityOverview.household' | translate }} {{ household.position + 1 }}</b
                        >
                        <b *ngIf="household.position === undefined">{{
                            'financing.features.financing-processing.liabilities.otherJointHeadings' | translate
                        }}</b>
                    </div>
                </mat-expansion-panel-header>

                <div *ngFor="let jointHeading of household.jointHeadings; let i = index" class="bg-dark-2 p-2 my-2 debitor-container">
                    <div class="mb-3">
                        <button mat-icon-button (click)="debitorExpand[jointHeading.ndg ?? ''] = !debitorExpand[jointHeading.ndg ?? '']">
                            <mat-icon>{{ debitorExpand[jointHeading.ndg ?? ''] ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </button>

                        <b *ngFor="let debitor of jointHeading.debitors; let debIndex = index">
                            <span>{{ debitor.name }}</span>
                            <span *ngIf="debIndex !== (jointHeading.debitors?.length || 0) - 1">,</span>
                            &nbsp;
                        </b>

                        <span *ngIf="jointHeading.ndg">{{ jointHeading.ndg | formatNdg }}</span>
                    </div>

                    <div
                        *ngIf="jointHeading.agpLiabilities.length === 0 && jointHeading.ksvLiabilities.length === 0 && jointHeading.newLiabilities.length === 0"
                        class="ml-3">
                        {{ 'financing.features.financing-processing.liabilities.liabilityOverview.noAssignedLiabilities' | translate }}
                    </div>

                    <mat-expansion-panel [expanded]="!debitorExpand[jointHeading.ndg ?? '']" class="bg-dark-2" style="box-shadow: none">
                        <ng-container *ngIf="jointHeading.agpLiabilities.length > 0">
                            <span>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.agpJh' | translate }}</span>
                            <div *ngFor="let liability of jointHeading.agpLiabilities" class="mb-3">
                                <ng-container *ngTemplateOutlet="liabilityTemplate; context: { liability, jointHeading }"></ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="jointHeading.ksvLiabilities.length > 0">
                            <span>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.ksvJh' | translate }}</span>
                            <div *ngFor="let liability of jointHeading.ksvLiabilities" class="mb-3">
                                <ng-container *ngTemplateOutlet="liabilityTemplate; context: { liability, jointHeading }"></ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="jointHeading.newLiabilities.length > 0">
                            <span>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.manualJh' | translate }}</span>
                            <div *ngFor="let liability of jointHeading.newLiabilities" class="mb-3">
                                <ng-container *ngTemplateOutlet="liabilityTemplate; context: { liability, jointHeading }"></ng-container>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>

                    <!--Dokumente-->
                    <mat-expansion-panel style="background-color: transparent; box-shadow: none" [hideToggle]="true">
                        <mat-expansion-panel-header>
                            <button mat-icon-button (click)="documentsExpand[jointHeading.id] = !documentsExpand[jointHeading.id]">
                                <mat-icon>{{ documentsExpand[jointHeading.id] ? 'expand_less' : 'expand_more' }}</mat-icon>
                            </button>
                            <span class="mt-2" (click)="$event.stopImmediatePropagation()"
                                ><b>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.documents' | translate }}</b></span
                            >
                        </mat-expansion-panel-header>

                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            class="ml-3"
                            *ngIf="!documentTypeExists(jointHeading, DocumentType.LiabilitiesInternal)">
                            <b>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.agpUpload' | translate }}</b>
                            <button mat-icon-button matTooltip="Hochladen" (click)="file.click()" [disabled]="editingReadonly$ | async">
                                <mat-icon class="icons">note_add</mat-icon>
                                <input
                                    accept="application/pdf"
                                    style="display: none"
                                    type="file"
                                    #file
                                    [multiple]="true"
                                    (change)="uploadJointHeadingFiles($event.target, jointHeading.id, DocumentType.LiabilitiesInternal)" />
                            </button>
                            <mat-divider></mat-divider>
                        </div>

                        <div
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            class="ml-3"
                            *ngIf="!documentTypeExists(jointHeading, DocumentType.LiabilitiesExternal)">
                            <b>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.ksvUpload' | translate }}</b>
                            <button mat-icon-button matTooltip="Hochladen" (click)="file.click()" [disabled]="editingReadonly$ | async">
                                <mat-icon class="icons">note_add</mat-icon>
                                <input
                                    accept="application/pdf"
                                    style="display: none"
                                    type="file"
                                    #file
                                    [multiple]="true"
                                    (change)="uploadJointHeadingFiles($event.target, jointHeading.id, DocumentType.LiabilitiesExternal)" />
                            </button>
                            <mat-divider></mat-divider>
                        </div>

                        <div *ngIf="!!jointHeading.documents && jointHeading.documents.length > 0">
                            <div *ngFor="let document of jointHeading.documents">
                                <!--agp-->
                                <div class="ml-3 mt-3 mb-2" *ngIf="document.type === FinprocessDocumentType.LiabilitiesInternal && document.files.length > 0">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <b>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.agp' | translate }}</b>
                                        <button
                                            class="mb-1"
                                            mat-icon-button
                                            matTooltip="Hochladen"
                                            (click)="file.click()"
                                            [disabled]="editingReadonly$ | async">
                                            <mat-icon class="icons">note_add</mat-icon>
                                            <input
                                                accept="application/pdf"
                                                style="display: none"
                                                type="file"
                                                #file
                                                [multiple]="true"
                                                (change)="uploadJointHeadingFiles($event.target, jointHeading.id, DocumentType.LiabilitiesInternal)" />
                                        </button>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div *ngFor="let file of document.files" class="mt-2 mb-2" fxLayout="row" fxLayoutAlign="space-between">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50">
                                            <mat-icon class="document-icon mr-1">description</mat-icon>
                                            <span class="mr-2">{{ file.name }}.pdf</span>
                                        </div>
                                        <div fxFlex="50">
                                            <button
                                                mat-icon-button
                                                class="documents-btn"
                                                [disabled]="editingReadonly$ | async"
                                                (click)="openDownloadDocument(file, 'open')"
                                                matTooltip="Öffnen">
                                                <mat-icon>open_in_new</mat-icon>
                                            </button>
                                            <button
                                                mat-icon-button
                                                class="documents-btn"
                                                [disabled]="editingReadonly$ | async"
                                                (click)="openDownloadDocument(file, 'download')"
                                                matTooltip="Download">
                                                <mat-icon>download</mat-icon>
                                            </button>
                                            <button
                                                class="delete"
                                                mat-icon-button
                                                [disabled]="editingReadonly$ | async"
                                                (click)="deleteDocument(jointHeading.id, file)"
                                                matTooltip="Löschen">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!--ksv-->
                                <div class="ml-3 mt-4 mb-2" *ngIf="document.type === FinprocessDocumentType.LiabilitiesExternal">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <b>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.ksv' | translate }}</b>
                                        <button
                                            class="mb-1"
                                            mat-icon-button
                                            matTooltip="Hochladen"
                                            (click)="file.click()"
                                            [disabled]="editingReadonly$ | async">
                                            <mat-icon class="icons">note_add</mat-icon>
                                            <input
                                                accept="application/pdf"
                                                style="display: none"
                                                type="file"
                                                #file
                                                [multiple]="true"
                                                (change)="uploadJointHeadingFiles($event.target, jointHeading.id, DocumentType.LiabilitiesExternal)" />
                                        </button>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div *ngFor="let file of document.files" class="mt-2 mb-2" fxLayout="row" fxLayoutAlign="space-between">
                                        <div fxLayout="row" fxLayoutAlign="start" fxFlex="50">
                                            <mat-icon class="document-icon mr-1">description</mat-icon>
                                            <span class="mr-2">{{ file.name }}.pdf</span>
                                        </div>
                                        <div fxFlex="50">
                                            <button
                                                mat-icon-button
                                                class="documents-btn"
                                                [disabled]="editingReadonly$ | async"
                                                (click)="openDownloadDocument(file, 'open')"
                                                matTooltip="Öffnen">
                                                <mat-icon>open_in_new</mat-icon>
                                            </button>
                                            <button
                                                mat-icon-button
                                                class="documents-btn"
                                                [disabled]="editingReadonly$ | async"
                                                (click)="openDownloadDocument(file, 'download')"
                                                matTooltip="Download">
                                                <mat-icon>download</mat-icon>
                                            </button>
                                            <button
                                                mat-icon-button
                                                class="delete"
                                                [disabled]="editingReadonly$ | async"
                                                (click)="deleteDocument(jointHeading.id, file)"
                                                matTooltip="Löschen">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </div>
</div>

<!--unsortierte liabilities zuordnen-->
<ng-container *ngIf="unsortedLiabilities$ | async as unsortedLiabilities">
    <div class="liability-assignment p-2" *ngIf="unsortedLiabilities.length > 0">
        <h1 class="color-primary">
            {{ 'financing.features.financing-processing.liabilities.assignLiability.assignLiabilityTitle' | translate }}
        </h1>
        <div class="px-4">
            <mat-card *ngFor="let data of unsortedLiabilities; let i = index" class="mb-3 bg-dark-2">
                <mat-card-content>
                    <div class="joint-heading-elements">
                        <div>
                            {{ 'financing.features.financing-processing.liabilities.liabilityOverview.type' | translate }}:
                            {{ data.liabilityType ? (data.liabilityType | finprocessEnumTranslation: 'LiabilityType') : 'keine Angabe' }}
                        </div>
                        <div>
                            {{ 'financing.features.financing-processing.liabilities.liabilityOverview.amount' | translate }}:
                            {{ data.currentAmount | formatCurrency }}
                        </div>
                        <div>
                            {{ data.loanPeriodInMonths }} {{ 'financing.features.financing-processing.liabilities.liabilityOverview.months' | translate }}
                        </div>
                        <div>
                            {{ 'financing.features.financing-processing.liabilities.liabilityOverview.source' | translate }}:
                            {{ data.liabilitySource !== undefined ? (data.liabilitySource | finprocessEnumTranslation: 'JointHeadingType') : 'keine Angabe' }}
                        </div>

                        <button
                            mat-raised-button
                            [disabled]="editingReadonly$ | async"
                            matTooltip="Verbindlichkeit zuordnen"
                            (click)="assignLiabilityDialog(data.id)">
                            <mat-icon>assignment</mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-container>

<ng-template #liabilityTemplate let-liability="liability" let-jointHeading="jointHeading">
    <div style="font-size: medium; width: 100%" fxLayoutAlign="start center" fxLayout="row">
        <span fxFlex="5">
            <button mat-icon-button (click)="liabilityExpanded[liability.id] = !liabilityExpanded[liability.id]">
                <mat-icon>{{ !liabilityExpanded[liability.id] ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
        </span>
        <finprocess-label-value fxFlex="25">
            <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.type' | translate }} </span>
            <span *ngIf="liability.liabilityType !== undefined" value>{{ liability.liabilityType | finprocessEnumTranslation: 'LiabilityType' }}</span>
            <span *ngIf="liability.liabilityType === undefined" value>{{ 'general.noValue' | translate }}</span>
        </finprocess-label-value>

        @if (!!liability.initialAmount) {
            <finprocess-label-value fxFlex="15">
                <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditAmount' | translate }} </span>
                <span value>{{ liability.initialAmount | formatCurrency }}</span>
            </finprocess-label-value>
        }

        @if (!!liability.creditLimit) {
            <finprocess-label-value fxFlex="15">
                <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditLimit' | translate }} </span>
                <span value>{{ liability.creditLimit | formatCurrency }}</span>
            </finprocess-label-value>
        }

        <finprocess-label-value fxFlex="15">
            <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.duration' | translate }} </span>
            <span value>{{ liability.loanPeriodInMonths }}</span>
        </finprocess-label-value>

        <finprocess-label-value fxFlex="10">
            <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.liabilitySource' | translate }} </span>
            <span value>{{ liability.liabilitySource | finprocessEnumTranslation: 'JointHeadingType' }}</span>
        </finprocess-label-value>

        <finprocess-label-value fxFlex="10">
            <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.coverage' | translate }} </span>
            <span value>{{ liability.covered ? 'Ja' : 'Nein' }}</span>
        </finprocess-label-value>

        <finprocess-label-value fxFlex="10">
            <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.active' | translate }} </span>
            <span value>
                <mat-checkbox
                    color="primary"
                    (click)="setLiabilityActive(liability, jointHeading)"
                    [checked]="liability.active"
                    [disabled]="editingReadonly$ | async">
                </mat-checkbox>
            </span>
        </finprocess-label-value>
        <div class="joint-heading-buttons" fxFlex="10">
            <button
                mat-mini-fab
                color="primary"
                matTooltip="Bearbeiten"
                [disabled]="editingReadonly$ | async"
                (click)="editLiabilityDialog(liability, liability.jointHeading)">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>
    <hr style="height: 1px; border: none; color: #ccc; background-color: #ccc" />

    <mat-expansion-panel [expanded]="liabilityExpanded[liability.id]" class="bg-dark-2 mb-3 liability-panel" style="box-shadow: none">
        <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutAlign="start start">
                <div fxFlex="5"></div>
                <finprocess-label-value fxFlex="25">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditStartDate' | translate }}</span>
                    <span value>{{ liability.started | date: 'dd.MM.yyyy' }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditEndDate' | translate }}</span>
                    <span value>{{ liability.calculatedEnd | date: 'dd.MM.yyyy' }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.duration' | translate }}</span>
                    <span value>{{ liability.loanPeriodInMonths }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="40">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.remainingTime' | translate }}</span>
                    <span value>{{ liability.calculatedRemainingTermInMonths }}</span>
                </finprocess-label-value>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <div fxFlex="5"></div>
                <finprocess-label-value fxFlex="25">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditInstitution' | translate }}</span>
                    <span value>{{ liability.ibanCreditor }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.saldo' | translate }}</span>
                    <span value>{{ liability.currentAmount | formatCurrency }}</span>
                </finprocess-label-value>
                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.numberOfBorrowers' | translate }}</span>
                    <span value>{{ liability.numberOfBorrowers }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="30">
                    <span label>{{
                        'financing.features.financing-processing.liabilities.liabilityOverview.numberOfBorrowersInThisApplication' | translate
                    }}</span>
                    <span value>{{ liability.numberOfBorrowersInThisApplication }}</span>
                </finprocess-label-value>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <div fxFlex="5"></div>
                <finprocess-label-value fxFlex="25">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.creditLimit' | translate }}</span>
                    <span value>{{ liability.creditLimit | formatCurrency }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.valueOfSecurity' | translate }}</span>
                    <span value>{{ liability.collateralsValue | formatCurrency }}</span>
                </finprocess-label-value>

                <div fxFlex="15"></div>
                <div fxFlex="40"></div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <div fxFlex="5"></div>
                <finprocess-label-value fxFlex="25">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.interestRate' | translate }} </span>
                    <span value>{{ liability.interestRate / 100 | percent: '1.3-3' : 'en-EN' }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.monthlyRate' | translate }} </span>
                    <span value>{{ liability.monthlyRate | formatCurrency }}</span>
                </finprocess-label-value>

                <finprocess-label-value fxFlex="15">
                    <span label>{{ 'financing.features.financing-processing.liabilities.liabilityOverview.fictionalRate' | translate }}</span>
                    <span value>{{ liability.fictionalRate / 100 | percent: '1.3-3' : 'en-EN' }}</span>
                </finprocess-label-value>

                <div fxFlex="40"></div>
            </div>
            <div *ngIf="liability.note" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start start" class="mt-3">
                <mat-expansion-panel class="liability-note-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'financing.features.financing-processing.liabilities.liabilityOverview.showNote' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [innerHTML]="liability.note" class="liability-note"></div>
                </mat-expansion-panel>
            </div>
        </div>
    </mat-expansion-panel>
</ng-template>
