import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISelectItem } from 'app/modules/shared';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Wert für Auswahl Sonstiges
 */
const OTHER_KEY = 'otherSelect';

/**
 * Eingabefeld - Einfachauswahl
 */
@Component({
    selector: 'finprocess-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent<T extends IBase> extends OverwriteInputComponent<T> implements OnChanges, OnInit {

    /**
     * Multiselect Items
     */
    @Input()
    public set items(items: ISelectItem<T[keyof T] | string>[] | undefined) {
        this.externalItems = items ?? [];

        this.internalItems = items ?? [];

        if (this.showOtherInternal) {
            this.internalItems.push({ value: OTHER_KEY, displayName: this.translate.instant('general.otherSelect')})
        }
    }

    /**
     * Setter für die Anzeige der Auswahlmöglichkeit Sonstiges
     */
    @Input()
    public set showOther(value: boolean) {
        this.showOtherInternal = value;

        const internalItemsContainsOther = this.internalItems.some(item => item.value === OTHER_KEY);

        if (value && !internalItemsContainsOther) {
            this.internalItems.push({ value: OTHER_KEY, displayName: this.translate.instant('general.otherSelect')})
        } else if (!value && internalItemsContainsOther) {
            this.internalItems = this.items ?? [];
        }
    }

    /**
     * Variablentyp
     */
    @Input()
    public set valueStorageType(valueStorageType: ValueStorageType) {
        this.valueStorageTypeInternal = valueStorageType;
    }

    /**
     * Anzeige des Tooltips
     */
    @Input() public showTooltip = false;


    /**
     * Interne Liste
     */
    public internalItems: ISelectItem<T[keyof T] | string>[] = [];

    /**
     * Externe Liste
     */
    public externalItems: ISelectItem<T[keyof T] | string>[] = [];

    /**
     * Interner Wert ob Sonstiges als Auswahlmöglichkeit angezeigt werden soll
     */
    public showOtherInternal = false;

    /**
     * Ob Sonstiges ausgewählt ist
     */
    public otherIsSelected = false;

    /**
     * Liefert den aktuellen Wert wenn es sich um eine sonstige Eingabe handelt
     */
    public selectedOtherValue?: string;

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     *
     * @returns {string} Anzeigeschlüssel
     */
    public get translatedOriginalValueKey(): string {
        const item = this.internalItems.find(it => this.compareStringFallback(it.value, this.originalValue));

        if (this.originalValue === undefined) {
            return item?.displayName ?? 'financing.features.financing-processing.unset';
        }

        return item?.displayName ?? this.originalValue?.toString() ?? '';
    }

    public selectedValue?: T[keyof T] | string;
    
    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(financingService: FinancingService, private translate: TranslateService) {
        super(ValueStorageType.Int, financingService);
    }

    /**
     * Lifecycle Hook
     * 
     */
    public ngOnChanges(): void {

        if (this.externalItems.length > 0 && this.externalItems.every(item => !this.compareStringFallback(item.value, this.currentValue))) {
            this.otherIsSelected = true;
            this.selectedOtherValue = this.currentValue?.toString() ?? undefined;
            this.selectedValue = OTHER_KEY;
        } else {
            this.otherIsSelected = false;
            this.selectedOtherValue = undefined;
                
            if (this.internalItems.every(item => typeof item.value === 'string')) {
                this.selectedValue = this.currentValue?.toString();
            } else {
                this.selectedValue = this.currentValue;
            }
        }
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        super.ngOnInit();
        this.otherIsSelected = this.selectedValue === OTHER_KEY;
    }

    /**
     * Speichert den Overwrite
     *
     * @param {string | undefined} value Wert
     */
    public save(value: T[keyof T & string] | undefined): void {
 
        if (value === OTHER_KEY) {
            this.otherIsSelected = true;
        } else if (value !== OTHER_KEY && this.externalItems.some(item => item.value === value)) {
            this.otherIsSelected = false;
            const toSaveValue = this.changeValueIfInt(value)
            super.save(toSaveValue);
        } else {
            const toSaveValue = this.changeValueIfInt(value)
            super.save(toSaveValue);
        }

    }

    /**
     * Umwandeln value um Integer falls storage typ int
     * 
     * @param {string | undefined} value Wert
     * @returns {string | undefined} returnvalue
     */
    private changeValueIfInt(value: T[keyof T & string] | undefined): T[keyof T & string] | undefined {
        if (value !== undefined && value !== null && this.valueStorageTypeInternal === ValueStorageType.Int) {
            return parseInt(value.toString(), 10) as T[keyof T & string];
        } 
        return value;
    }
    /**
     * Comparing values of any types and fall back to string if types are not equal
     * 
     * @param {unknown} a First value 
     * @param {unknown} b Second value
     * @returns {boolean} Are a and b equal
     */
    private compareStringFallback<S, O>(a: S, b: O): boolean {
        if (typeof a === typeof b) {
            return a === (b as unknown as S);
        }

        return a?.toString() === b?.toString();
    }
}
