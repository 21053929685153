import { SmartDocJobStatus } from '../../enums/smartdoc-job-status.enum';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocSplittingStartedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Aktueller SmartDoc-Jobstatus
     */
    jobStatus: SmartDocJobStatus;
}

/**
 * Klasse für Aktion
 */
export class SmartDocSplittingStarted {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocSplittingStarted';
    /**
     * Comnstructor
     *
     * @param {ISmartDocSplittingStartedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocSplittingStartedPayloadType) { }
}
