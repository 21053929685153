<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2" [flexMd]="65">
    <div left>
        <ng-container *ngIf="!!selectedRealEstate">
            <!-- Liegenschaften Nav Bar -->
            <div *ngIf="(financing$ | async) as financing">
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderFinancingScope' | translate ">
                </finprocess-sub-header>
                <finprocess-select-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [valueStorageType]="ValueStorageType.Int" [entity]="selectedRealEstate" [items]="creditPurpose"
                    [label]="'financing.features.financing-processing.financing-plan.financingPurpose' | translate"
                    fieldName="creditPurpose">
                </finprocess-select-input>
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderFinancingCosts' | translate ">
                </finprocess-sub-header>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.purchasePrice' | translate"
                    fieldName="purchasePrice" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate"
                    fieldName="purchasePriceRelevantForAdditionalCosts">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.lotPrice' | translate"
                    fieldName="lotPrice" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate"
                    fieldName="lotPriceRelevantForAdditionalCosts">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.developmentCosts' | translate"
                    fieldName="developmentCosts" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate"
                    fieldName="developmentCostsRelevantForAdditionalCosts">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCosts' | translate"
                    fieldName="constructionCosts" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate"
                    fieldName="constructionCostsRelevantForAdditionalCosts">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.refurbishmentCosts' | translate"
                    fieldName="refurbishmentCosts">
                </finprocess-decimal-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCostsOverrun' | translate"
                    fieldName="constructionCostsOverrun">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.additionalCosts' | translate"
                    [numberFunctionObservable]="sumAdditionalCosts$">
                </finprocess-calculation-display>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.preDebtCoverage' | translate"
                    [numberFunctionObservable]="currentAmountCoveredLiabilites$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="selectedRealEstate"
                    [label]="'financing.features.financing-processing.financing-plan.reschedulingAmount' | translate"
                    fieldName="reschedulingAmount">
                </finprocess-decimal-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="selectedRealEstate"
                    [label]="'financing.features.financing-processing.financing-plan.furnishing' | translate"
                    fieldName="otherCosts">
                </finprocess-decimal-input>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.sumProjectCosts' | translate"
                    [numberFunctionObservable]="sumProjectCosts$">
                </finprocess-calculation-display>
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderCapital' | translate ">
                </finprocess-sub-header>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.cash' | translate"
                    fieldName="cash">
                </finprocess-decimal-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.salesRevenue' | translate"
                    fieldName="salesRevenue" [class]="'dim-hr'">
                </finprocess-decimal-input>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.sumMarketValues' | translate"
                    [hint]="'financing.features.financing-processing.financing-plan.sumMarketValuesHint' | translate"
                    [numberFunctionObservable]="sumMarketValues$">
                </finprocess-calculation-display>

                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.prefinancing' | translate"
                    fieldName="prefinancingSales">
                </finprocess-boolean-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.redemptionInsurance' | translate"
                    fieldName="redemptionInsurance" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.prefinancing' | translate"
                    fieldName="prefinancingInsurance">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.bausparCreditBalance' | translate"
                    fieldName="bausparCreditBalance" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.prefinancing' | translate"
                    fieldName="prefinancingBausparCreditBalance">
                </finprocess-boolean-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.sumCapital' | translate"
                    [numberFunctionObservable]="sumOwnCapital$">
                </finprocess-calculation-display>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.funding' | translate"
                    fieldName="funding" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.prefinancing' | translate"
                    fieldName="prefinancingFunding">
                </finprocess-boolean-input>
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.otherOwnCapital' | translate"
                    fieldName="otherOwnCapital" [class]="'dim-hr'">
                </finprocess-decimal-input>
                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.prefinancing' | translate"
                    fieldName="prefinancingOtherOwnCapital">
                </finprocess-boolean-input>
            </div>
            <finprocess-sub-header
                [label]="'financing.features.financing-processing.financing-plan.subHeaderFinancingNeeds' | translate ">
            </finprocess-sub-header>
            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.financing-plan.sumPrefinancing' | translate"
                [numberFunctionObservable]="sumPrefinancing$">
            </finprocess-calculation-display>
            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.financing-plan.netFinancingRequirement' | translate"
                [numberFunctionObservable]="netFinancingRequirement$">
            </finprocess-calculation-display>
            <!-- aktuell fehler -->
            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.financing-plan.sumFinancingAdditionalCharges' | translate"
                [numberFunctionObservable]="sumFinancingAdditionalCharges$">
            </finprocess-calculation-display>
            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.financing-plan.grossFinancingRequirement' | translate"
                [numberFunctionObservable]="grossFinancingRequirement$">
            </finprocess-calculation-display>
            
            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.financing-plan.grossFinancingRequirementUpdate' | translate"
                [numberFunctionObservable]="newCalculatedgrossFinancingRequirement$">
            </finprocess-calculation-display>

            <finprocess-calculation-display
                [label]="'financing.features.financing-processing.household.fictionalRateAmount' | translate"
                [numberFunctionObservable]="fictionalRateAmount$">
            </finprocess-calculation-display>
            <div *ngIf="(financing$ | async) as financing">

                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderPurchaseAdditionalCosts' | translate ">
                </finprocess-sub-header>

                <finprocess-decimal-input [readonly]="fieldReadonly$" [format]="'1.2-3'"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.realEstateTransferTaxPercentage' | translate"
                    fieldName="realEstatePurchaseTaxes">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.realEstateTransferTax' | translate"
                    [numberFunctionObservable]="realEstateTaxes$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$" [format]="'1.2-3'"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.rightOfOwnershipPercentage' | translate"
                    fieldName="landregisterEntryFee">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.rightOfOwnership' | translate"
                    [numberFunctionObservable]="landregisterEntry$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$" [format]="'1.2-3'"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.notaryFeeInputPercentage' | translate"
                    fieldName="notaryFee">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.notaryFeeInput' | translate"
                    [numberFunctionObservable]="notaryCosts$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$" [format]="'1.2-3'"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.brokerageFeeInputPercentage' | translate"
                    fieldName="brokerageFee">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.brokerageFeeInput' | translate"
                    [numberFunctionObservable]="brokerageCosts$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$" [format]="'1.2-3'"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [entity]="selectedRealEstate"
                    [label]="'financing.features.financing-processing.financing-plan.otherAdditionalCosts' | translate"
                    fieldName="otherAdditionalCosts">
                </finprocess-decimal-input>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.purchasingAdditionalCostsSum' | translate"
                    [numberFunctionObservable]="sumAdditionalCosts$">
                </finprocess-calculation-display>

                <!-- Finanzierungsnebenkosten in EUR  -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderFinancingAdditionalCosts' | translate ">
                </finprocess-sub-header>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.handlingFeePercentage' | translate"
                    fieldName="processingChargesPercent" format="1.0-3">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.handlingFee' | translate"
                    [numberFunctionObservable]="processingCharges$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.valuationFeePercentage' | translate"
                    fieldName="estimateChargesPercent" format="1.0-3">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.valuationFee' | translate"
                    [numberFunctionObservable]="estimateCharges$">
                </finprocess-calculation-display>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.valuationFeeExternal' | translate"
                    fieldName="valuationFeeExternal" [class]="'dim-hr'">
                </finprocess-decimal-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.registrationChargesAmountPercentage' | translate"
                    fieldName="registrationChargesPercent" format="1.0-3">
                </finprocess-decimal-input>
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.registrationChargesAmount' | translate"
                    [numberFunctionObservable]="registrationCharges$">
                </finprocess-calculation-display>
                <!--
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.registrationChargesAmountPercentage' | translate"
                    fieldName="landRegisterRequest">
                </finprocess-decimal-input> -->
                <!--
                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.registrationChargesAmountPercentage' | translate"
                    fieldName="landRegisterExpect">
                </finprocess-decimal-input> -->
                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.landRegistryRequest' | translate"
                    [numberFunctionObservable]="landRegistryRequest$">
                </finprocess-calculation-display>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.landRegistryExcerpt' | translate"
                    [numberFunctionObservable]="landRegistryExcerpt$">
                </finprocess-calculation-display>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.legalisationFee' | translate"
                    [numberFunctionObservable]="legalisationFee$">
                </finprocess-calculation-display>

                <finprocess-calculation-display
                    [label]="'financing.features.financing-processing.financing-plan.financingAdditionalCostsSum' | translate"
                    [numberFunctionObservable]="sumFinancingAdditionalCharges$">
                </finprocess-calculation-display>

                <!-- Sicherheiten -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderSecurity' | translate ">
                </finprocess-sub-header>

                <finprocess-boolean-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.guarantees' | translate"
                    fieldName="guarantees">
                </finprocess-boolean-input>

                <finprocess-textarea-input
                    [label]="'financing.features.financing-processing.financing-plan.notesGuarantees' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="financing" fieldName="notesGuarantees">
                </finprocess-textarea-input>

                <!-- Garantie für Firma -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderCompanyGuarantee' | translate ">
                </finprocess-sub-header>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.guaranteeAmount' | translate"
                    fieldName="guaranteeAmount">
                </finprocess-decimal-input>

                <finprocess-datepicker-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.guaranteeDate' | translate"
                    fieldName="guaranteeDate">
                </finprocess-datepicker-input>

                <finprocess-integer-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.guaranteeDuration' | translate"
                    fieldName="guaranteeDuration">
                </finprocess-integer-input>

                <!-- Vor/Zwischenfinanzierung kurzfristig -->
                <!-- <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderPrefinancing' | translate ">
                </finprocess-sub-header>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditAmount' | translate"
                    fieldName="constructionCreditAmount">
                </finprocess-decimal-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditDebitRate' | translate"
                    fieldName="constructionCreditDebitRate" suffix="%">
                </finprocess-decimal-input>

                <finprocess-integer-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditDuration' | translate"
                    fieldName="constructionCreditDuration">
                </finprocess-integer-input>

                <finprocess-decimal-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditProcessingCharges' | translate"
                    fieldName="constructionCreditProcessingCharges">
                </finprocess-decimal-input>

                <finprocess-textbox-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditCoveredNotes' | translate"
                    fieldName="constructionCreditCoveredNotes">
                </finprocess-textbox-input>

                <finprocess-textbox-input [readonly]="fieldReadonly$"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                    [label]="'financing.features.financing-processing.financing-plan.constructionCreditGuaranteeNotes' | translate"
                    fieldName="constructionCreditGuaranteeNotes">
                </finprocess-textbox-input> -->


                <!--Anmerkungen zum Finanzplan  -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.financing-plan.subHeaderFinancingNotes' | translate ">
                </finprocess-sub-header>

                <finprocess-textarea-input
                    [label]="'financing.features.financing-processing.financing-plan.financingNotes' | translate"
                    [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="financing" fieldName="financingNotes">
                </finprocess-textarea-input>

            </div>
        </ng-container>

        <div *ngIf="isRiskFinancingPlan" class="mt-4">
            <h2><b class="scoring">{{'financing.features.financing-processing.financing-plan.scoring' | translate}}</b>
            </h2>
            <finprocess-scoring></finprocess-scoring>
        </div>

    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>