import { EnumSettings } from './../../enum.settings';
import { BathroomFacilities as BafBathroomFacilities } from './baf-bathroom-facilities.enum';

/**
 * [MEHRFACHAUSWAHL] Ausstattung (Haupt)Badezimmer
 */
export enum BathroomFacilities {
    /**
     * Keine
     */
    None = 0,

    /**
     * Dusche
     */
    Shower = 1,

    /**
     * Badewanne
     */
    BathTub = 2,

    /**
     * Zu öffnendes Fenster
     */
    WindowOpenable = 4,

    /**
     * WC
     */
    Toilet = 8,

    /**
     * Separate Fußbodenheizung (Keine Raumheizung)
     */
    SeparateFloorHeating = 16,

    /**
     * Wellnesselemente im Badezimmer (z.B.: Whirlpool, Erlebnis- oder Dampfduschkabine, etc.)
     */
    WellnessElements = 256,
}

export namespace BathroomFacilities {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {BathroomFacilities | BafBathroomFacilities | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: BathroomFacilities | BafBathroomFacilities | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'BathroomFacilities', Object.assign(BathroomFacilities, BafBathroomFacilities), plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('BathroomFacilities', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {BathroomFacilities | BafBathroomFacilities | undefined | null} value Enum-Wert
     * @param {BathroomFacilities | BafBathroomFacilities} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: BathroomFacilities | BafBathroomFacilities | undefined | null, flag: BathroomFacilities | BafBathroomFacilities): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
