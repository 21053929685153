import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NoteService } from './services/note.service';
import { NoteState } from './states/note.state';

export * from './interfaces';
export * from './states';

/**
 * Modul für das Laden und Bearbeiten einer Notes
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([NoteState]),
    ],
    providers: [
        NoteService,
    ],
})
export class NoteDataModule { }
