import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IDocument, IFile } from 'app/modules/financing/data';
import { SharedService } from 'app/modules/shared/services/shared.service';
import { Observable, take } from 'rxjs';
import { HelperService } from 'shared/util';

import {
    DocumentTag,
    ISmartDocStateModel,
    ISmartDocStateParentDefinition,
    SmartDocJobStatus,
    SmartDocService,
    SmartDocSplittingStarted,
} from '../../../../data';
import { AAGUID } from '../../../../data/types/aaguid';
import { SmartDocWaiterDialogComponent } from '../smartdoc-waiter-dialog/smartdoc-waiter-dialog.component';

/**
 * Komponente fuer die Auswahl der Dokumente
 */
@Component({
    selector: 'finprocess-smartdoc-selection',
    templateUrl: './smartdoc-selection.component.html',
    styleUrls: ['./smartdoc-selection.component.scss'],
})
export class SmartdocSelectionComponent implements OnInit {
    /**
     * aktueller Prozesszustand der SmartDoc-Bearbeitung
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc)
    public state$!: Observable<ISmartDocStateModel>;

    /**
     * smartdoc opened id
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.openedId)
    public openedId$!: Observable<string>;

    /**
     * IDocument[] des geoeffneten Finanzierungsfalls
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.documents)
    public documents$!: Observable<IDocument[]>;

    /**
     * Aray of selected DocumentIds
     */
    public selectedFiles: AAGUID[] = [];

    /**
     * Indicator all files selected
     */
    public allFilesSelected = false;


    /**
     * gibt an, dass die Dokumente geladen werden
     */
    private documentsLoading = true;

    /**
     * gibt an, dass die Id's zu den bereits an SMartDoc geschickten Dokumenten geladen werden
     */
    private idsLoading = true;

    /**
     * gibt an, ob sich der Smartdoc Prozess im Splitting finished Schritt befindet
     */
    public splittingFinished = false;



    /**
     * Constructor
     *
     * @param {SmartDocService} smartdocService SmartDocService
     * @param {Store} store Store
     * @param {Actions} actions$ Actions
     * @param {AuthorizationService} authService AuthorizationService
     * @param {SharedService} sharedService SharedService
     * @param {WaiterService} waiterService WaiterService
     * @param {MatDialog} modalService MatDialog
     */
    public constructor(
        private smartdocService: SmartDocService,
        private store: Store,
        private actions$: Actions,
        private authService: AuthorizationService,
        private sharedService: SharedService,
        private waiterService: WaiterService,
        private modalService: MatDialog,
    ) { }

    /**
     * Angular onInit-Hook
     */
    public ngOnInit(): void {
        const financingId = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.openedId)

        if (!financingId) {
            return;
        }


        this.smartdocService.loadThumbnails(financingId);


        this.sharedService.setJobStatusState(financingId);
        const jobStatus = (this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.status?.jobStatus));
        if (SmartDocJobStatus.SplittingFinished === jobStatus) {
            this.splittingFinished = true;
        }


        this.smartdocService.loadSmartDocUploadedFileIds(financingId).subscribe(ids => {
            this.selectedFiles.push(...ids);
        });
    }


    /**
     * marks splitted documents
     *
     * @param {string} id documentid
     * @returns {boolean} boolean
     */
    public filterSplittedDocuments(id: string): boolean {
        const uploadedIds = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocUploadedIds);
        if (uploadedIds) {
            if (uploadedIds.filter(e => e === id).length > 0) {
                return true;
            }
        }
        return false;
    }

    /**
     * startet das Splitten von Dokumenten
     *
     * @param {ISmartDocStateModel} state state
     */
    public startCutting(state: ISmartDocStateModel): void {
        if (state.openedId !== undefined && !state.isReadonly) {
            this.actions$.pipe(ofActionSuccessful(SmartDocSplittingStarted), take(1)).subscribe(() => {
                this.openWaiterDialog(state);
            });
            this.smartdocService.splitFiles(state.openedId, this.selectedFiles);
        }
    }

    /**
     * gibt an, ob Dateien gesplittet werden oder lediglich Seiten im nächsten Schritt neu zugeordnet werden
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     * @returns {boolean} indicates if splitting is needed
     */
    public isSplittingNeeded(state: ISmartDocStateModel): boolean {
        return this.selectedFiles.length === 0 || !state.smartDocUploadedIdsLoaded || this.selectedFiles.filter(it => !state.smartDocUploadedIds.includes(it)).length > 0;
    }

    /**
     * prüft ob nur schon gesplitete files selektiert sind
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     * @returns {boolean} indicates if only uploaded/splited files are selected
     */
    public isOnlyUploadedFilesSelected(state: ISmartDocStateModel): boolean {
        return this.selectedFiles.every(it => state.smartDocUploadedIds?.includes(it))
    }

    /**
     * prüft ob es sich um einen schon zusammengestellte File handelt wie zB Expose, Photos...
     *
     * @param {IFile} file Datei
     * @returns {boolean} true wenn nicht zusameerstellt
     */
    public isNotProcessed(file: IFile): boolean {
        return !HelperService.hasBit(file.tag, DocumentTag.SmartDocProcessed)
    }

    /**
     * gibt an, ob Dateien gesplittet werden oder lediglich Seiten im nächsten Schritt neu zugeordnet werden
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     * @returns {boolean} indicates if staus is readonly
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // eslint-disable-next-line class-methods-use-this
    public isStatusReadonly(state: ISmartDocStateModel): boolean {
        return ![
            undefined,
            SmartDocJobStatus.Open,
            SmartDocJobStatus.ProcessingFinished,
            SmartDocJobStatus.SplittingFinished,
        ].includes(state.status?.jobStatus);

    }

    /**
     * öffnet den Warte-Dialog
     *
     * @param {ISmartDocStateModel} state state
     */
    private openWaiterDialog(state: ISmartDocStateModel): void {
        if (state.openedId !== undefined) {
            this.modalService.open(SmartDocWaiterDialogComponent, {
                data: {
                    aaguid: state.openedId,
                },
            });
        }
        else {
            this.smartdocService.close();
        }
    }

    /**
     * set or unset a file
     *
     * @param {MatCheckboxChange} event checkbox clicked
     * @param {string} aaguid id
     */
    public setSelectedFile(event: MatCheckboxChange, aaguid: string): void {
        if (event.checked && !this.selectedFiles.some(it => it === aaguid)) {
            this.selectedFiles.push(aaguid)
        } else if (!event.checked && this.selectedFiles.some(it => it === aaguid)) {
            this.selectedFiles = this.selectedFiles.filter(it => it !== aaguid);
            this.allFilesSelected = false;
        }
    }

    /**
     * set or unset all files
     *
     * @param {ISmartDocStateModel} state given state
     */
    public setAllFilesSelected(state: ISmartDocStateModel): void {
        if (!this.allFilesSelected) {
            this.selectedFiles = state.documents.reduce<AAGUID[]>((acc, curr) => [...acc, ...curr.files.map(({ id }) => id)], []);
        } else {
            this.selectedFiles = [...this.store.selectSnapshot((st: ISmartDocStateParentDefinition) => st.smartdoc.smartDocUploadedIds)];
        }
        this.allFilesSelected = !this.allFilesSelected;
    }

    /**
     * Opens clicked file in a new tab
     *
     * @param {IFile} fileModel given file
     */
    public openFileInNewTab(fileModel: IFile): void {
        if (this.authService.checkPermissions(Role.SmartDoc)) {
            this.waiterService.show();
            this.sharedService.loadFile(fileModel.id)
                .subscribe(
                    (fileBase64: string | undefined) => {
                        this.sharedService.openFile({
                            id: fileModel.id,
                            mimeType: fileModel.mimeType,
                            base64: fileBase64,
                            name: fileModel.name,
                            extension: fileModel.extension,
                        } as IFile);
                        this.waiterService.hide();
                    },
                );
        }
    }

}
