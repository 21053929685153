import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { RealEstateLoaded, RealEstatesLoaded } from '../..';
import { IRealEstate } from '../../interfaces';

/**
 * Reaestate Service
 */
@Injectable()
export class RealEstateService {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService 
     * @param {HttpClient} httpClient httpClient
     * @param {ConfigService} config configService
     * @param {Store} store storeService
     */
    public constructor(
        private authorizationService: AuthorizationService, 
        private httpClient: HttpClient,
        private config: ConfigService,
        private store: Store,
    ) { }

    /**
     * get RealEstates
     *
     * @param {UUID} financingContainerId financing container id
     * @returns {Observable} IRealEstate[] | undefined
     */
    public getRealEstates(financingContainerId: UUID): Observable<IRealEstate[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IRealEstate[]>(`${this.config.getEnvironment().apiUrl}/realestate/getall`, { params: { containerId: financingContainerId }}).pipe(
                    mergeMap((realEstates: IRealEstate[]) => this.store.dispatch(new RealEstatesLoaded(realEstates)).pipe(
                        map(() => realEstates),
                    )), 
                ),
                of(undefined),
            )),
        );
    }

    /**
     * get one IRealEstate
     *
     * @param {UUID} realEstateId realEstateId
     * @returns {Observable} IRealEstate | undefined
     */
    public getRealEstateById(realEstateId: UUID): Observable<IRealEstate | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IRealEstate>(`${this.config.getEnvironment().apiUrl}/realestate`, { params: { realEstateId: realEstateId } }).pipe(
                    mergeMap((realEstate: IRealEstate) => this.store.dispatch(new RealEstateLoaded(realEstate)).pipe(
                        map(() => realEstate),
                    )),
                ),          
                of(undefined),
            )),
        );
    }

}
