<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center" *ngIf="!hideLabel">
        <label [for]="id" align="left">
            <span *ngIf="!!label"></span>{{label}}
            <ng-content *ngIf="!label" select="[label]"></ng-content>
        </label>
        <mat-icon class="ml-1" *ngIf="overwrite && ((!otherIsSelected && currentValue !== originalValue) || (otherIsSelected && selectedOtherValue !== originalValue))"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (translatedOriginalValueKey | translate) }">
            info</mat-icon>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start strech">
        <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutAlign.lt-sm="start center">
            <mat-form-field class="field-color slim-fella" appearance="fill" fxFlex="100">
                <mat-select [(ngModel)]="selectedValue" [disabled]="(readonly | async) ?? false"
                    (ngModelChange)="save($event)">
                    <mat-option *ngFor="let item of internalItems" [value]="item.value" [matTooltip]="showTooltip ? item.displayName : ''">
                        {{item.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="otherIsSelected && showOtherInternal" class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start center"
            fxLayoutAlign.lt-sm="start center">
            <mat-form-field class="field-color slim-fella" appearance="fill" fxFlex="100">
                <input matInput [(ngModel)]="selectedOtherValue" [disabled]="(readonly | async) ?? false"
                    (ngModelChange)="save($event)">
            </mat-form-field>
        </div>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr">
</div>
