export enum LiquidityCostAdvantage {
    /**
     * nein
     */
    No = 0,

    /**
     * Deckungsstockvorteil (PKMB & PB)
     */
    CoverPoolBenefit = 2,
}
