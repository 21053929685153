import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { EntityClassType } from 'app/modules/financing/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { IAssetProvider, IDgaCustomer, IDgaSearch, IVerifiedCustomer } from '../../interfaces';
import { AssetsAddProvider, AssetsDeleteProvider } from '../../states';


/**
 *
 */
@Injectable()
export class AssetProviderService {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService
     * @param {HttpClient} httpClient HTTPClient
     * @param {ConfigService} config ConfigService
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private authorizationService: AuthorizationService,
        private httpClient: HttpClient,
        private config: ConfigService,
        private store: Store,
    ) { }

    /**
     * Fügt einen neuen AssetProvider hinzu
     * 
     * @param {Partial<IAssetProvider>} assetProvider IAssetProvider
     * @param {EntityClassType} entityClassType EntityClassType
     * @returns {Observable<unknown>} Observable
     */
    public addAssetProvider(assetProvider: Partial<IAssetProvider>, entityClassType: EntityClassType): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, 
                    this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/assetProvider/Create`,assetProvider, {}).pipe(
                        mergeMap(newId => this.store.dispatch(new AssetsAddProvider( {assetProvider: {...assetProvider, id: newId} as IAssetProvider, entityClassType: entityClassType}))),
                    ),
                    of(undefined),
                )),
        );
    }


    /**
     * Erstellt einen DGA Benutzer
     * 
     * @param {IDgaCustomer} customerToCreate CustomerID
     * @returns {Partial<IAssetProvider>} Partial<IAssetProvider>
     */
    public createCustomerInDga(customerToCreate: IDgaCustomer): Observable<Partial<IAssetProvider> | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<{ ndg: string}>(`${this.config.getEnvironment().apiUrl}/assetProvider/CreateInDga`, customerToCreate)
                    .pipe(map(result => {
                        const assetProvider: Partial<IAssetProvider> = {
                            firstName: customerToCreate.firstName,
                            lastName:customerToCreate.lastName,
                            ndg: result.ndg,
                        };
                        return assetProvider;
                    })),
                of(undefined),
            )),
        )
    }

    /**
     * Suche für DGA
     * 
     * @param {IDgaSearch} search IDgaSearch 
     * @returns {Observable<string | undefined>} Observable
     */
    public searchInDga(search: IDgaSearch): Observable<IVerifiedCustomer[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<{verifiedCustomer: IVerifiedCustomer[]}>(`${this.config.getEnvironment().apiUrl}/assetProvider/VerifyCustomer`, search).pipe(map(result => result.verifiedCustomer)),
                of(undefined),
            )),
        )
    }

    /**
     * AssetProvider Löshen
     * 
     * @param {string} assetId assetId
     * @param {string} assetProviderId assetProviderId
     * @param {EntityClassType} entityClassType entityClassType
     * @returns {Observable<unknown>} Observable
     */
    public deleteAssetProvider(assetId: string, assetProviderId: string, entityClassType: EntityClassType): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, 
                    this.httpClient.delete(`${this.config.getEnvironment().apiUrl}/assetProvider/delete`,
                        {
                            params:
                            {
                                assetProviderId: assetProviderId,
                            },
                        }).pipe(
                        mergeMap(() => this.store.dispatch(new AssetsDeleteProvider( {assetId: assetId, assetProviderId: assetProviderId, entityClassType: entityClassType}))),
                    ),
                    of(undefined),
                )),
        );
    }

}
