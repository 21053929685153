import { Pipe, PipeTransform } from '@angular/core';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { FinancingStatus, FinancingSubStatus } from 'shared/data';

/**
 * Übersetzt den Status einer Finanzierungsanfrage
 */
@Pipe({
    name: 'finProcessStatus',
})
export class StatusPipe implements PipeTransform {

    /**
     * Konstruktor
     *
     * @param {EnumTranslationPipe} enumTranslationPipe EnumTranslationPipe-Injektor
     */
    public constructor(private enumTranslationPipe: EnumTranslationPipe) {

    }

    /**
     * Übersetz den Status abhängig von der Statuskombination
     *
     * @param {FinancingStatus} value Hauptstatus
     * @param {FinancingSubStatus} subStatus Interne Statusspezifikation
     * @returns {any} Übersetzung des der Statuskombination
     */
    public transform(value: FinancingStatus, subStatus?: FinancingSubStatus): unknown {
        if (subStatus !== undefined) {
            return this.enumTranslationPipe.transform(subStatus, 'FinancingSubStatus');
        }
        return this.enumTranslationPipe.transform(value, 'FinancingStatus');
    }

}
