import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService } from './core/base-calculation.service';

/**
 * @typedef { import('../errors').ArgumentError } ArgumentError
 */

/**
 * Berechnungsmethoden zum Kreditnehmer
 */
export class DebitorCalculationService extends BaseCalculationService {

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet Monatliches Gesamteinkommen eines Kreditnehmers mit Berücksichtung von 14 Gehältern und Sonstigem Einkommen
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.netIncome Monatliches Nettoeinkommen in €
     * @param {boolean} params.fourteenSalariesPerYear 14 Gehälter
     * @param {number | undefined} params.otherIncome Sonstiges Einkommen in €
     * @returns {number} Monatliches Gesamteinkommen in €
     */
    public netIncomeTotal({ netIncome, fourteenSalariesPerYear, otherIncome }: {
        /**
         * Monatliches Nettoeinkommen in €
         */
        netIncome: number;
        /**
         * 14 Gehälter
         */
        fourteenSalariesPerYear: boolean;
        /**
         * Sonstiges Einkommen in €
         */
        otherIncome?: number;
    }): number {
        return (fourteenSalariesPerYear ? CalculationHelperService.round(netIncome * 14 / 12, 2) : netIncome) +
            (!CalculationHelperService.isNullOrNaN(otherIncome) ? (otherIncome as number) : 0);
    }

    /**
     * Berechnet das Alter eines Kreditnehmers zu Laufzeitende des Kredits
     *
     * @param {object} params Parameterobjekt
     * @param {Date} params.birthday Geburtsdatum
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {Date | undefined} params.plannedFinancingStart Erste Rate am
     * @param {Date | undefined} params.plannedFinancingEnd Geplantes Finanzierungsende
     * @returns {number} Jahre
     */
    public ageDurationEnd({ birthday, assumedDuration, plannedFinancingStart, plannedFinancingEnd }: {
        /**
         * Geburtsdatum
         */
        birthday: Date;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Erste Rate am
         */
        plannedFinancingStart?: Date;
        /**
         * Geplantes Finanzierungsende
         */
        plannedFinancingEnd?: Date;
    }): number | undefined {
        if (CalculationHelperService.isNullOrNaN(assumedDuration) && (!(plannedFinancingEnd instanceof Date) || CalculationHelperService.isNullOrNaN(plannedFinancingEnd.valueOf()))) {
            return undefined;
        }
        if (plannedFinancingEnd instanceof Date && !CalculationHelperService.isNullOrNaN(plannedFinancingEnd.valueOf())) {
            return CalculationHelperService.calculateYears(birthday, plannedFinancingEnd);
        }
        else if (plannedFinancingStart instanceof Date && !CalculationHelperService.isNullOrNaN(plannedFinancingStart.valueOf())) {
            const end = new Date(plannedFinancingStart.getTime());
            end.setMonth(end.getMonth() + (assumedDuration as number));
            return CalculationHelperService.calculateYears(birthday, end);
        }
        else {
            const end = new Date();
            end.setMonth(end.getMonth() + (assumedDuration as number));
            return CalculationHelperService.calculateYears(birthday, end);
        }
    }
}
