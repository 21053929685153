<ng-container *ngIf="!loading">
    <div class="editing-history-container h-100 w-100">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="p-3">
            <h1>{{ 'financing.features.financing-processing.status.title' | translate }}</h1>
            <button mat-icon-button color="primary" [matTooltip]="'financing.features.financing-processing.status.tooltipDownload' | translate" (click)="downloadEditHistory()" [disabled]="exportDisabled$ | async">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
        <div class="history-entries-container">
            <div *ngFor="let entry of editHistoryEntries$ | async" class="history-entry-wrapper px-3">
                <div class="history-entry">
                    <ng-container *ngIf="isStatusEntry(entry)">
                        <div fxLayout="row" fxLayoutAlign="space-between flex-start" fxLayoutGap="20px">
                            <div class="medium-font-weight">{{ 'financing.features.financing-processing.status.statusChanged' | translate }}: {{ entry.status | finProcessStatus:entry.subStatus}}</div>
                            <div class="medium-font-weight align-right">{{ entry.created | date:'dd.MM.yyyy HH:mm' }}</div>
                        </div>
                        <div *ngIf="entry.statusInformation" class="mt-1" [innerHtml]="entry.statusInformation"></div>
                    </ng-container>
                    <ng-container *ngIf="isAssignment(entry)">
                        <div fxLayout="row" fxLayoutAlign="space-between flex-start" fxLayoutGap="20px">
                            <div class="medium-font-weight">{{ entry.assignmentType | finprocessEnumTranslation:'AssignmentType'}}</div>
                            <div class="medium-font-weight align-right">{{ entry.created | date:'dd.MM.yyyy HH:mm' }}</div>
                        </div>
                        <div class="mt-1">
                            <span>{{ 'financing.features.financing-processing.status.old' | translate }}</span>
                            <span>
                                @if(entry.oldUser) {
                                    {{ entry.oldUser?.firstName ?? ''}} {{ entry.oldUser?.lastName ?? ''}} 
                                } @else {
                                    {{ 'financing.features.financing-processing.status.noUser' | translate }}
                                }
                            | </span>
                            <span>{{ 'financing.features.financing-processing.status.new' | translate }}</span>
                            <span>
                                @if(entry.newUser) {
                                    {{ entry.newUser?.firstName ?? ''}} {{ entry.newUser?.lastName ?? ''}} 
                                } @else {
                                    {{ 'financing.features.financing-processing.status.noUser' | translate }}
                                }
                            </span>
                        </div>
                        <div [innerHtml]="entry.additionalInformation"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>