import { ISubstitutionStateModel } from '.';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface IUserAreaStateParentDefinition {
    /**
     * Zustand für Vertretungen
     */
    substitution: ISubstitutionStateModel;

}

export const USER_AREA_PERSISTENT_STATE_KEYS = [];
