import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';

import { NoteDataModule } from './data/note-data.module';
import { NoteComponent } from './features/components';

/**
 * Note Module
 */
@NgModule({
    declarations: [
        NoteComponent,
    ],
    imports: [
        CommonModule,
        NoteDataModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        MatTooltipModule,
        TranslateModule.forChild(),
        MatCardModule,
        CKEditorModule,
    ],
    exports: [
        NoteComponent,
    ],
})
export class NoteModule {}
