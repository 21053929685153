import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { AdditionalCode, DocumentService, FinancingService, IFileUploadRequest, IFinancingStateParentDefinition, IFireRiskDecision, ReasonCode } from 'app/modules/financing/data';
import { Environment, HelperService, UUID } from 'app/modules/shared';
import { environment } from 'environment';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, map, mergeMap, of, takeUntil } from 'rxjs';

import { HttpMethod } from '../../../workflow-processing/enums';
import { IWorkflowRouteData } from '../../../workflow-processing/interfaces';
import { DECISION_REQUEST_DATA } from '../../../workflow-processing/models';


interface IManualFireForm {
    proposalId: FormControl<string>;
    kpiBelq: FormControl<number>;
    kpiDsti: FormControl<number>;
    kpiLtv: FormControl<number>;
    kpiKimVRelSchuldq: FormControl<number>;
    totalCommitment: FormControl<number>;
    totalCommitmentNoGuarantee: FormControl<number>;
    reasonCode: FormControl<ReasonCode | null>;
    ergAdditionalCode: FormControl<AdditionalCode | null>;
    ergTextAdditionalStatus: FormControl<string>;
    ergTextStatusFinal: FormControl<string>;
}

export interface IUploadFireResult extends IFileUploadRequest {
    productPackageId: UUID;
}

export interface IFileData {
    created: string;
    fileId: string;
    fileName: string;
}

/**
 * Risk Decision Component
 */
@Component({
    selector: 'finprocess-risk-decision',
    templateUrl: './risk-decision.component.html',
    styleUrls: ['./risk-decision.component.scss'],
})
export class RiskDecisionComponent implements OnInit, OnDestroy {

    public fireAPI = false;
    public fireManual = false;
    public fireOptions = '';

    /**
     * Manueller Fire Input
     */
    public fireData?: IFireRiskDecision;

    /**
     * ProductPackage ID
     */
    public productPackageID?: UUID;

    /**
     * FinancingMap ID
     */
    public financingMapID?: UUID

    /**
     * Fire Document Informations
     */
    public fireDocumentData?: IFileData;

    /**
     * uploaded Data
     */

    public uploadedData?: IUploadFireResult;

    /**
     * Boolean -> Fire Data von Manueller Eingabe oder von API
     */
    public isRiskDecisionFromApi?: boolean;

    /**
     *  Number Mask
     */
    public numberMaskOptions: NgxCurrencyConfig;

    /**
     *  Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     *  percentage Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;

    public percentageMaskOptions999: NgxCurrencyConfig;

    public fireForm: FormGroup<IManualFireForm>;

    /**
     * List of reason codes for fire
     */
    public reasonCodes: ReasonCode[] = [];

    /**
     * List of additional codes for fire
     */
    public additionalCodes: AdditionalCode[] = [];

    /**
     * Checks if this is test or dev to display the fire id generator button
     * 
     * @returns {boolean} If it is the test environment
     */
    public get isTestEnvironment(): boolean {
        return environment.environment <= Environment.Test;
    }

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    public data$: Observable<IWorkflowRouteData> = of({
        roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor,
        needOnlyOne: true,
        model: DECISION_REQUEST_DATA,
        apiPath: 'decision/GetDecision',
        method: HttpMethod.Post,
        translateKey: 'fire',
    });

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Konstruktor
     *
     * @param {string} locale locale id
     * @param {FormBuilder} fb formbuilder
     * @param {FinancingService} financingService financing service
     * @param {DocumentService} documentService document service
     * @param {ActivatedRoute} activatedRoute activated route
     * @param {WaiterService} waiterService waiterservice
     * @param {ChangeDetectorRef} changeDetec changedetectorref
     * @param {NotificationService} notificationService notificationService 
     * @param {TranslateService} translate translate
     * @param {Store} store store
     * @param {EnumTranslationService} enumTranslate EnumTranslationService-Injektor
     */
    public constructor(@Inject(LOCALE_ID) locale: string, private fb: FormBuilder, private financingService: FinancingService,
        private documentService: DocumentService, private activatedRoute: ActivatedRoute, private waiterService: WaiterService,
        private changeDetec: ChangeDetectorRef, private notificationService: NotificationService, private translate: TranslateService,
        private store: Store, private enumTranslate: EnumTranslationService) {

        this.numberMaskOptions = HelperService.getInputMask(locale, {
            precision: 0,
            thousands: '',
            decimal: '',
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });

        this.percentageMaskOptions999 = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 999,
        });

        const reasonCode = this.fb.control(null, Validators.required);
        const ergAdditionalCode = this.fb.control(null, Validators.required);
        const ergTextAdditionalStatus = this.fb.control('', {validators: Validators.required, nonNullable: true});
        const ergTextStatusFinal = this.fb.control('', {validators: Validators.required, nonNullable: true});
        const totalCommitment = this.fb.control(0, {validators: Validators.required, nonNullable: true});
        const totalCommitmentNoGuarantee = this.fb.control(0, {validators: Validators.required, nonNullable: true});

        this.fireForm = this.fb.group<IManualFireForm>({
            proposalId: this.fb.control('', { validators: [Validators.required, Validators.pattern('^CU\\d{8}-\\d{8}-\\d{6}BE$')], nonNullable: true}),
            kpiBelq: this.fb.control(0, { validators: Validators.required, nonNullable: true}),
            kpiDsti: this.fb.control(0, {validators: Validators.required, nonNullable: true}),
            kpiLtv: this.fb.control(0, {validators: Validators.required, nonNullable: true}),
            kpiKimVRelSchuldq: this.fb.control(0, {validators: Validators.required, nonNullable: true}),
            totalCommitment,
            totalCommitmentNoGuarantee,
            reasonCode,
            ergAdditionalCode,
            ergTextAdditionalStatus,
            ergTextStatusFinal,
        });

        reasonCode.valueChanges.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe((reasonCodeValue: ReasonCode | null) => {
            if (reasonCodeValue !== null) {
                const translation = this.enumTranslate.instant(`ReasonCodeLongText.${reasonCodeValue}`);

                if (typeof translation === 'string') {
                    this.fireForm.controls.ergTextStatusFinal.setValue(translation);
                }
            }
        });

        ergAdditionalCode.valueChanges.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe((ergAdditionalCodeValue: AdditionalCode | null) => {
            if (ergAdditionalCodeValue !== null) {
                const translation = this.enumTranslate.instant(`AdditionalCodeLongText.${ergAdditionalCodeValue}`);

                if (typeof translation === 'string') {
                    this.fireForm.controls.ergTextAdditionalStatus.setValue(translation);
                }
            }
        });

        totalCommitment.valueChanges.pipe(
            takeUntil(this.onDestroy$),
        ).subscribe((totalCommitmentValue: number) => {
            totalCommitmentNoGuarantee.setValidators([Validators.required, Validators.max(totalCommitmentValue)]);
            totalCommitmentNoGuarantee.updateValueAndValidity();
        });

        this.reasonCodes = HelperService.getEnumArray(ReasonCode, true) as ReasonCode[];
        this.additionalCodes = HelperService.getEnumArray(AdditionalCode, true) as AdditionalCode[];
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.activatedRoute.paramMap.pipe(map(params => {
            if (!!params) {
                this.waiterService.show();
                const productPackageID = params.get('productPackageID') as UUID;
                const financingMapID = params.get('riskfinancingplanid') as UUID;
                this.productPackageID = productPackageID;
                this.financingMapID = financingMapID;
                return { productPackageID, financingMapID };
            } else {
                return null;
            }
        }),
        takeUntil(this.onDestroy$)).subscribe(res => {
            if (!!res) {
                this.financingService.getProductPackage(res.productPackageID).subscribe(productpackage => {
                    if (!!productpackage) {
                        this.isRiskDecisionFromApi = productpackage.assignProductPackages[0].isRiskDecisionFromApi;

                        if (this.isRiskDecisionFromApi === true) {
                            this.fireAPI = true;
                            this.fireOptions = '1';
                            this.changeDetec.detectChanges();
                        }

                        if (this.isRiskDecisionFromApi === false) {
                            this.fireManual = true;
                            this.fireOptions = '2';
                            // Get manual fire risk decision data
                            this.financingService.getManualFireRiskDecisionData(res.productPackageID).subscribe({
                                next: result => {
                                    if (!!result) {
                                        this.fireForm.patchValue(result);
                                        this.getFireDocumentData();
                                        this.changeDetec.detectChanges();
                                    }
                                },
                                error: () => {
                                    this.waiterService.hide();
                                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.risk-decision.loadError'));
                                },
                            });
                        }
                    }
                });
            }
        })
        this.waiterService.hide();
        this.changeDetec.detectChanges();
    }


    /**
     * fire option change
     *
     * @param {string} fireOption fire option value
     */
    public onFireOptionChange(fireOption: string) {
        if (fireOption === '1') {
            this.fireAPI = true;
            this.fireManual = false;
        }
        if (fireOption === '2') {
            this.fireManual = true;
            this.fireAPI = false;
        }
    }

    /**
     * upload Fire Document
     * 
     * @param {EventTarget | null} target Event Target
     * @param {boolean} replaceDocument check if document is newly uploaded or needs to be replaced
     */
    public uploadFireDocument(target: EventTarget | null, replaceDocument: boolean) {

        if (!!this.productPackageID) {
            const tmp = target as HTMLInputElement;

            //es kann nur eine datei hochgeladen werden
            if (tmp.files && tmp.files.length > 1) {
                this.notificationService.toast(this.translate.instant('financing.features.financing-processing.risk-decision.uploadErrorLength'), { duration: 3000 });
                return;
            }

            const file = tmp.files?.item(0);

            if (!!file && (file.size === 0 || file.type !== 'application/pdf')) {
                this.notificationService.toast(this.translate.instant('general.errorUploadPdfFile'));
                tmp.value = '';
                this.waiterService.hide();
                return;
            }

            if (!!file) {
                this.notificationService.confirmOkCancel(
                    this.translate.instant('financing.features.financing-processing.additionalSheets.uploadDocument'),
                    this.translate.instant('financing.features.financing-processing.additionalSheets.confirmDialogContent', {
                        name: file.name,
                    }),
                ).pipe(
                    mergeMap(result => {
                        if (result !== 'submit') {
                            return of(void 0);
                        }
                        else {
                            this.waiterService.show();

                            if (!!this.productPackageID && !!this.financingMapID) {

                                //bereits existierende datei überschreiben
                                if (!!this.fireDocumentData) {
                                    this.uploadedData = {
                                        productPackageId: this.productPackageID,
                                        type: DocumentType.FireResult,
                                        file: file,
                                        mapId: this.financingMapID,
                                        oldFileId: this.fireDocumentData.fileId,
                                    };

                                    if (replaceDocument === true) {
                                        this.saveFireDocument();
                                    }

                                    this.changeDetec.detectChanges();
                                }
                                //neue datei anlegen
                                else {
                                    this.uploadedData = {
                                        productPackageId: this.productPackageID,
                                        type: DocumentType.FireResult,
                                        file: file,
                                        mapId: this.financingMapID,
                                    }

                                    if (replaceDocument === true) {
                                        this.saveFireDocument();
                                    }
                                    this.changeDetec.detectChanges();
                                }
                                this.waiterService.hide();
                                return of(void 0);
                            }
                            else {
                                this.waiterService.hide();
                                return of(void 0);
                            }
                        }
                    }),
                ).subscribe();
            }
        }
    }


    /**
     * get fire document informations (name, id, date) from uploaded files
     */
    public getFireDocumentData() {
        if (!!this.productPackageID) {
            this.financingService.getProductPackageFireDocumentInfos(this.productPackageID).subscribe({
                next: result => {
                    if (!!result) {
                        this.fireDocumentData = {
                            fileId: result.fileId,
                            fileName: result.fileName,
                            created: result.created,
                        };
                        this.changeDetec.detectChanges();
                    }
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.risk-decision.loadFireDocument'));
                },
            });
        }
    }

    /**
     * download Fire Document
     */
    public downloadFireDocument() {
        this.getFireDocumentData();
        if (!!this.fireDocumentData?.fileId) {
            this.documentService.loadFile(this.fireDocumentData.fileId).subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, 'application/pdf');
                    
                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }
                },
            });
        }
    }

    /**
     * save manual fire data + fire document 
     */
    public saveManualFireData() {

        const formData = this.fireForm.getRawValue();

        if (formData.reasonCode === null || formData.ergAdditionalCode === null) {
            return;
        }

        const fireData: IFireRiskDecision = {...formData, reasonCode: formData.reasonCode, ergAdditionalCode: formData.ergAdditionalCode };

        this.waiterService.show().pipe(
            mergeMap(() => this.validateAndShowWarnings()),
            mergeMap(result => {
                if (result !== 'submit' || !this.productPackageID) {
                    return of(void 0);
                }

                return this.financingService.addManualFireRiskDecision(fireData, this.productPackageID).pipe(map(() => result));
            })).subscribe({
            next: result => {
                if (result !== 'submit') {
                    this.waiterService.hide();
                    return;
                }
                const financingContainerId = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);

                if (!!this.productPackageID && !!financingContainerId) {
                    this.financingService.getProductPackageStatusEntries(this.productPackageID, financingContainerId).subscribe();
                }

                this.notificationService.toast(this.translate.instant('financing.features.financing-processing.risk-decision.saveSucess'));
                
                this.saveFireDocument();
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.risk-decision.saveError'));
            },
        });
            
        
    }

    /**
     * save fire document
     */
    public saveFireDocument() {
        if (!!this.uploadedData) {
            this.documentService.addProductPackageFireDocument(this.uploadedData).subscribe({
                next: () => {
                    this.notificationService.toast(this.translate.instant('financing.features.financing-processing.risk-decision.saveSucessDocument'));
                    this.getFireDocumentData();
                    this.changeDetec.detectChanges();
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.risk-decision.saveError'));
                },
            });
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Generates an id for fire
     * 
     * @param {Event} event Mouse event to stop propagation to the input field
     */
    public generateCubeId(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        const randomNumberA = Math.floor(Math.random() * 100000000).toString().padStart(8, '0');
        const randomNumberB = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        const randomNumberC = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
        this.fireForm.controls.proposalId.patchValue(`CU${randomNumberA}-${new Date().getFullYear()}${randomNumberB}-${randomNumberC}BE`);
    }

    /**
     * Validates the form and shows warnings if necessary
     * 
     * @returns {Observable<string | undefined>} Observable with the dialog result
     */
    private validateAndShowWarnings(): Observable<string | undefined> {
        const warnings: string[] = [];

        if (this.fireForm.controls.kpiBelq.value > 90 || this.fireForm.controls.kpiKimVRelSchuldq.value > 40) {
            warnings.push(this.translate.instant('financing.features.financing-processing.risk-decision.kimvWarning'));
        }

        if (warnings.length > 0) {
            return this.notificationService.confirmOkCancel(this.translate.instant('general.warning'), warnings.join('<br>'));
        }

        return of('submit');
    }
}
