/**
 * Kreditentscheidung
 */
export enum ApplicationDecisionType {
    /**
     * abgelehnt
     */
    Rejected,
    /**
     * vorstellbar
     */
    Possible,
    /**
     * private financing
     */
    RejectedByResponsibilityPF,
    /**
     * small business, geschäftskunde
     */
    RejectedByResponsibilityGK,
    /**
     * vorstellbar mit fixzins
     */
    PossibleFix
}
