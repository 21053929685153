import { EnumSettings } from './../enum.settings';

/**
 * Finanzierungsgenehmigungen
 */

export enum FinancingPremissions {
    /**
     * Keine
     */
    None = 0,

    /**
     * Mitarbeiter Skill
     */
    EmployeesSkill = 1,

    /**
     * Privatkunden-Skill
     */
    PrivateCustomerSkill = 2,

    /**
     * Geschäftskunden/Selbständigen Skill
     */
    SmallBusinessSkill = 4
}

export namespace FinancingPremissions {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {FinancingPremissions | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: FinancingPremissions | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'FinancingPremissions', FinancingPremissions, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('FinancingPremissions', plocale);
}
