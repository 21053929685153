export enum CustomerType {
    /**
     * Privatkunde
     */
    Privatkunde = 31,

    /**
     * Gehobene Kunden
     */
    GehobeneKunden = 32,

    /**
     * Erfolgskunden A
     */
    ErfolgskundenA = 33,

    /**
     * Erfolgskunden B
     */
    ErfolgskundenB = 34,

    /**
     * Topkunden
     */
    Topkunden = 35,

    /**
     * Jugendliche
     */
    Jugendliche = 36,

    /**
     * Studenten
     */
    Studenten = 37,

    /**
     * Unselbständige Freiberufler
     */
    UnselbständigeFreiberufler = 40,

    /**
     * Devisenausländer
     */
    Devisenausländer = 41,

    /**
     * Devisenausländer/Standard
     */
    DevisenausländerStandard = 43,

    /**
     * Grossbetriebe > 40 Mio. EUR
     */
    Grossbetriebe = 1,

    /**
     * Mittelbetriebe < 40 Mio. EUR
     */
    Mittelbetriebe = 2,

    /**
     * Jungunternehmer
     */
    Jungunternehmer = 3,

    /**
     * Geschäftskunden (< 1,5 Mio EUR)
     */
    Geschäftskunden = 4,

    /**
     * Gewerbekunden (< 350 Tsd. EUR)
     */
    Gewerbekunden = 5,

    /**
     * Kleinbetriebe (< 7 Mio. EUR)
     */
    Kleinbetriebe = 6,

    /**
     * Ausl. Firmenkunden
     */
    AuslFirmenkunden = 7,

    /**
     * Gemeinn. Wohnbautraeger
     */
    GemeinnWohnbautraeger = 9,

    /**
     * Kommerzielle Wohnbautraeger
     */
    KommerzielleWohnbautraeger = 10,

    /**
     * Immobilienmakler/-verwalter
     */
    Immobilienmaklerverwalter = 11,

    /**
     * Immobiliendeveloper/-investoren
     */
    Immobiliendeveloperinvestoren = 12,

    /**
     * Arbeitsgemeinschaften
     */
    Arbeitsgemeinschaften = 13,

    /**
     * Vertragsversicherungen
     */
    Vertragsversicherungen = 14,

    /**
     * Freiberufler
     */
    Freiberufler = 15,

    /**
     * Gemeinde Wien
     */
    GemeindeWien = 16,

    /**
     * Gemeinde Wien nahe Betr.
     */
    GemeindeWiennaheBetr = 17,

    /**
     * Oeff. Hand nahe Betriebe
     */
    OeffHandnaheBetriebe = 18,

    /**
     * Laender
     */
    Laender = 19,

    /**
     * Sozialversicherungen
     */
    Sozialversicherungen = 20,

    /**
     * Gemeinden
     */
    Gemeinden = 21,

    /**
     * Bund
     */
    Bund = 22,

    /**
     * Sonstige (Vereine,etc.)
     */
    Sonstige = 23,

    /**
     * Voelkerr. priv. Koerpersch.
     */
    VoelkerrprivKoerpersch = 24,

    /**
     * Inl. Kreditinstitute
     */
    InlKreditinstitute = 25,

    /**
     * Finanzinstitute
     */
    Finanzinstitute = 26,

    /**
     * Ausl. Kreditinstitute
     */
    AuslKreditinstitute = 27,

    /**
     * Unechte WEG / MEG
     */
    UnechteWEGMEG = 28,

    /**
     * Echte WEG
     */
    EchteWEG = 29,

    /**
     * Stiftungen
     */
    Stiftungen = 30,
}
