import { EnumSettings } from './../enum.settings';

/**
 * Kreditvermittler
 */
export enum CreditBroker {

    /**
     * Berater
     */
    Consultant = 1,

    /**
     * Unternehmen
     */
    Company = 2,
}

export namespace CreditBroker {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {CreditBroker | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: CreditBroker | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'CreditBroker', CreditBroker, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('CreditBroker', plocale);
}
