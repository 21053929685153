import { Validators } from '@angular/forms';

import { WorkflowType } from '../../enums';
import { ActionIdType, ChannelType, CustomerIdType, FireKzType, FormattingType, OptionalBoolenType, RequiredBooleanType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

import { AMOUNTS_LOAN_BANK_AUSTRIA_REMAIN } from './amounts-loans-bank-austria-remain';
import { CREDIT_PARTICIPANT } from './credit-participant';
import { DURATIONS_FINANCING_REQUEST } from './durations-financing-request';
import { EXTERNAL_REMAIN_BANK_LOAN } from './external-remain-bank-loan';
import { FINANCING_REQUEST_NEW } from './financing-request-new';
import { GO_RELEVANT_LOANS } from './go-relevant-loans';
import { INTEREST_RATES_FINANCING_REQUEST } from './interest-rates-financing-request';
import { VALUATION_LTV_RELEVANT } from './valuation-ltv-relevant';
import { VALUATION_OTHER } from './valuation-other';


export const DECISION_REQUEST_DATA: IMidtermModel = {

    midtermName: 'decisionRequestData',

    /**
     * Kundennummer Kreditnehmer (NDG Einzelkunde oder NDG JH)
     * z.B.: n123456
     * user_id
     */
    userId: { type: WorkflowType.String, validators: Validators.required },

    /**
     * action_id
     * Input & Output
     */
    actionId: { type: WorkflowType.Enum, enumObject: ActionIdType, enumLabel: 'ActionIdType', validators: Validators.required },

    /**
     * Kundennummer Kreditnehmer (NDG Einzelkunde oder NDG JH)
     * kdnr_ndg
     */
    customerNdg: { type: WorkflowType.Number, validators: Validators.required, formattingType: FormattingType.Number },

    /**
     * channel
     */
    channel: { type: WorkflowType.Enum, enumObject: ChannelType, enumLabel: 'ChannelType' },

    /**
     * KIM-V Definition JH für Geringfügigkeit erfüllt?
     * kimv_jh_gf
     */
    kimVJhGf: { type: WorkflowType.Enum, enumObject: RequiredBooleanType, enumLabel: 'RequiredBooleanType', validators: Validators.required }, //

    /**
     * Anzahl Kreditbeteiligte
     * anzahl_kreditbeteiligte
     */
    numberOfCreditParticipants: { type: WorkflowType.Number },

    /**
     * zumutbare Kreditrate aus HHR
     * hhr_max_kreditrate
     */
    hhrMaxCreditRate: { type: WorkflowType.Number, validators: Validators.required, formattingType: FormattingType.Currency },

    /**
     * zumutbare Kreditrate aus Pensions-HHR
     * hhr_pension_max_kreditrate
     */
    hhrPensionMaxCreditRate: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Antragsscoring
     * antragsscoring
     */
    requestScoring: { type: WorkflowType.String, validators: Validators.required },

    /**
     * Verhaltensscoring Kreditnehmer (VSC Einzelkunde oder JH)
     * verhaltensscoring
     */
    behavioralScoring: { type: WorkflowType.String, validators: Validators.required },

    /**
     * Kurzarbeit
     * kurzarbeit_status
     */
    shortTimeWorkStatus: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * FIRE-Kennzeichnung
     * fire_kz
     */
    fireKz: { type: WorkflowType.Enum, enumObject: FireKzType, enumLabel: 'FireKzType' },

    /**
     * related_parties
     */
    relatedParties: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * NT-FinAdvisory proposal ID
     * proposal_id_nt
     * Input & Output
     */
    proposalIdNt: { type: WorkflowType.String, validators: Validators.required },

    /**
     * customer_id
     */
    customerId: { type: WorkflowType.Enum, enumObject: CustomerIdType, enumLabel: 'CustomerIdType', validators: Validators.required, defaultValue: CustomerIdType.Pi },

    /**
     * Kreditbeteiligte max. 4 (von A-D)
     */
    creditParticipants: { type: WorkflowType.Array, child: CREDIT_PARTICIPANT },

    /**
     * Sind eigene abzudeckende Konten vorhanden?
     * eak_active
     */
    ownCoveredAccountsActive: { type: WorkflowType.Enum, enumObject: RequiredBooleanType, enumLabel: 'RequiredBooleanType', validators: Validators.required }, //

    /**
     * Kontonummern (11 Ziffern) von eigenen abzudeckenden Konten
     * eak_number_1 bis eak_number_10
     */
    ownCoveredAccountNumbers: { type: WorkflowType.Array, child: WorkflowType.Number },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite des Antragstellers, d.h. entweder unter NDG des Einzelkunden oder der NDG der JH
     */
    applicantAmountsLoansRemain: { type: WorkflowType.Object, child: AMOUNTS_LOAN_BANK_AUSTRIA_REMAIN },

    /**
     * Summe der eigenen bestehenbleibenden BA-Kredite der weiteren für GO relevanten NDGs (="Rest")
     */
    goRelevantNdgsAmountsLoansRemain: { type: WorkflowType.Object, child: GO_RELEVANT_LOANS },

    /**
     * Summe der eigenen bestehenbleibenden LTV-relevanten Mortgage-Finanzierungen
     * summe_ltv_relevant
     */
    sumLtvRelevant: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * hievon rev.P-Kredite u. endf.P-Kredite gem. KIM-V*
     * sum_pkredit_kimv
     */
    sumPCreditKimV: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden Ratenbelastungen gem. HHR für Einmalbarkredite
     * summe_ratenbel_ebk_hhr
     */
    sumOwnRemainingRatesHHROneTimeCredit: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden Ratenbelastungen gem. KIM-V für Einmalbarkredite
     * summe_ratenbel_ebk_kimv
     */
    sumOwnRemainingRatesKIMVOneTimeCredit: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der Verpflichtungen aus übernommenen Haftungen
     * summe_haftungen
     */
    sumLiabilities: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der zu leistenden Alimente
     * summe_alimente
     */
    sumAlimony: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * sind bestehenbleibende Fremdbankkredite Antragstellers (Einzelkunde oder JH) vorhanden?
     * fbk_active
     */
    externalRemainBankLoansActive: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Fremdbankkredit / Bestandskredit Fremd
     * Belegbar von 0 - 9
     */
    externalRemainBankLoans: { type: WorkflowType.Array, child: EXTERNAL_REMAIN_BANK_LOAN },

    /**
     * Finanzierungswunsch
     */
    financingRequest: { type: WorkflowType.Object, child: FINANCING_REQUEST_NEW },

    /**
     * Laufzeiten Konsum & Finanzierungswunsch
     */
    durationsFinancingRequest: { type: WorkflowType.Object, child: DURATIONS_FINANCING_REQUEST },

    /**
     * Zinssätze Konsum & Finanzierungswunsch
     */
    interestRatesFinancingRequest: { type: WorkflowType.Object, child: INTEREST_RATES_FINANCING_REQUEST },

    /**
     * Bewertung LTV-relevante Liegenschaften
     */
    valuationLtvRelevants: { type: WorkflowType.Array, child: VALUATION_LTV_RELEVANT },

    /**
     * Bewertung sonstige Liegenschaften
     */
    valuationOthers: { type: WorkflowType.Array, child: VALUATION_OTHER },

    /**
     * Rückkaufswert Lebensversicherung
     * rueckkaufwert_lv
     */
    surrenderValueOfLifeInsurance: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Wertpapierdepot (mat. Wert aus GEOS)
     * wp_depot
     */
    portfolio: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Barwerte (Kontoguthaben, Sparbücher etc., gilt nicht für neue Garantie außerhalb von Doppelobligo)
     * barwerte
     */
    cashValues: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Barwerte f. neue Garantie (nicht Doppelobligo)
     * barwerte_doppelobligo
     */
    cashValuesDoubleObligation: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Tilgungsträger bestehenbl. LTV-relevante Kredite*
     * tt_ltv
     */
    ttLtv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Tilgungsträger bestehenbl. sonstige Kredite*
     * tt_so
     */
    ttSo: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * hievon mat. Werte für neue Mortgage-Finanzierung
     * mat_werte_neue_fin
     */
    matValuesNewFinacing: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * hievon sonst. CRR-Sicherheiten z.G. Wohnimmob.
     * sonst_crr_sicherheiten
     */
    otherCCR: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden Ratenbelastungen gem. HHR für Einmalbarkredite
     */
    sumOwnRemainingRatesHHR: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe der eigenen bestehenbleibenden Ratenbelastungen gem. KIM-V für Einmalbarkredite
     */
    sumOwnRemainingRatesKIMV: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Zus. Abweichungen von den Vergaberichtlinien?
     */
    additionalDifferenceToRuleset: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

}
