import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AuthenticationService } from '../../../../data';
import { LoginStateType } from '../../../../data/enums';

/**
 * Direktive zu gesteuerten Anzeige nur bei aktiver Authentifzierung
 */
@Directive({
    selector: '[finprocessAuthentication]',
})
export class AuthenticationDirective implements OnDestroy, OnInit {
    /**
     * Ist Template gerendert
     */
    private hasView?: boolean;

    /**
     * Subject, welches beim Entfernen der Komponente einen Wert emitted
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Subscription für Änderung der Bedingung
     */
    private subscription?: Subscription;

    /**
     * setzt den Handler für den LoginState anhand eines Mindeststates
     */
    @Input()
    public set finprocessAuthentication(minLogState: LoginStateType | '') {
        if (minLogState === '') {
            return;
        }
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
        this.subscription = this.authenticationService.loginState$.pipe(
            takeUntil(this.onDestroy$),
            tap(loginState => {
                const show = loginState >= minLogState;
                if (show && this.hasView !== true) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                    this.hasView = true;
                }
                else if (!show && this.hasView === true) {
                    this.viewContainerRef.clear();
                    this.hasView = false;
                }
            }),
        ).subscribe();
    }

    /**
     * Konstruktor
     *
     * @param {ViewContainerRef} viewContainerRef ViewContainerRef-Injektor
     * @param {TemplateRef} templateRef TemplateRef-Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     */
    public constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private authenticationService: AuthenticationService,
    ) { }

    /**
     * Angular-Hook Initialisieren der Direktive
     */
    public ngOnInit(): void {
        if (this.subscription === undefined) {
            this.subscription = this.authenticationService.loginState$.pipe(
                takeUntil(this.onDestroy$),
                tap(loginState => {
                    const show = loginState === LoginStateType.LoggedIn;
                    if (show && this.hasView !== true) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    }
                    else if (!show && this.hasView === true) {
                        this.viewContainerRef.clear();
                        this.hasView = false;
                    }
                }),
            ).subscribe();
        }
    }

    /**
     * Angular-Hook Entfernen der Direktive
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
