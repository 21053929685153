/**
 * Fehler bei der Division durch 0
 */
export class DivisionByZeroError extends Error {
    /**
     * Name des Fehlers
     */
    public static errorName = 'DivisionByZeroError';

    /**
     * Name des Fehlers
     */
    public name: string = DivisionByZeroError.errorName;

    /**
     * Nachricht des Fehlers
     */
    public message = 'Das Dividieren durch 0 ist nicht zulässig.';
}
