export enum PurposeId {
    /**
     * Eigentumswohnung oder Haus
     */
    CondominiumOrHousestead,

    /**
     * Renovierung
     */
    Renovation,
}
