import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';
import { EMPTY, Observable, catchError, mergeMap, throwError } from 'rxjs';

import { IContactInformation } from '../../../data/interfaces';

/**
 * Interceptor, der Fehler von Legacy Systemen abfängt und einen Dialog anzeigt
 */
@Injectable({
    providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {

    /**
     * Standard Konstruktor
     *
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(private notification: NotificationService, private translate: TranslateService) {

    }

    /**
     * Fängt Requests mit Fehlern von Legacy Systemen ab und zeigt einen Dialog an
     *
     * @param {HttpRequest} request Request
     * @param {HttpHandler} next Handler
     * @returns {Observable<HttpEvent>} Observable mit Request
     */
    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const errorRequest = request.clone();
        return next.handle(errorRequest).pipe(catchError(error => {

            // Http Fehler abfangen
            if (error instanceof HttpErrorResponse) {
                // Auf Legacy System Fehler & Fehler-Type prüfen
                if (error.status === HttpStatusCode.FailedDependency && error.headers.has('External-System-Error-Type')) {
                    if (error.headers.get('External-System-Error-Type') === 'Preparation') {
                        const contactInformation: IContactInformation[] = error?.error?.ContactInformations ?? [];
                        
                        let errorMessage: string = this.translate.instant('general.internalSystemsPreparationError', {
                            error: error.error?.Message ?? error.error.ExternalSystemResponse.ErrorMessage ?? this.translate.instant('general.unknownError'),
                            code: error.error.ExternalSystemIdentifier ?? '',
                        });

                        if (contactInformation.length > 0) {
                            errorMessage = errorMessage.concat(`<b>${this.translate.instant('general.contactInformation')}</b><br>`).concat(contactInformation.map(info => `<b>${info.Key}</b>: ${info.Value}`).join('<br>'));
                        }

                        const event = this.notification.alert(
                            this.translate.instant('general.internalSystemsPreparationErrorTitle'),
                            errorMessage);

                        return event.pipe(mergeMap(() => EMPTY));
                    }
                    else {
                        return this.handleExternalSystemsDefaultError(error).pipe(mergeMap(() => EMPTY));
                    }
                }
                else if (error.status === HttpStatusCode.NotAcceptable) {
                    return this.handleValidationError(error).pipe(mergeMap(() => EMPTY));
                }
            }
            return throwError(() => error);

        }));
    }


    /**
     * Verarbeitet die Fehlermeldungen vom Typ ExternalSystemsException, wenn != PreperationException
     *
     * @param {HttpErrorResponse} error geworfener Fehler vom Backend
     * @returns {Observable<string|undefined>} Observable mit Notfication
     */
    private handleExternalSystemsDefaultError(error: HttpErrorResponse): Observable<string | undefined> {
        const contactInformation: IContactInformation[] = error?.error?.ContactInformations ?? [];

        let errorMessage: string = this.translate.instant('general.internalSystemsError', {
            error: error.error?.Message ?? error.error.ExternalSystemResponse.ErrorMessage ?? this.translate.instant('general.unknownError'),
            code: error.error.ExternalSystemIdentifier ?? '',
        });

        if (contactInformation.length > 0) {
            errorMessage = errorMessage.concat(`<b>${this.translate.instant('general.contactInformation')}</b><br>`).concat(contactInformation.map(info => `<b>${info.Key}</b>: ${info.Value}`).join('<br>'));
        }

        return this.notification.alert(
            this.translate.instant('general.internalSystemsErrorTitle'),
            errorMessage);
    }

    /**
     * Verarbeitet die Fehlermeldungen vom Typ Validierungsexception
     *
     * @param {HttpErrorResponse} error geworfener Fehler vom Backend
     *  @returns {Observable<string|undefined>} Observable mit Notfication
     */
    private handleValidationError(error: HttpErrorResponse): Observable<string | undefined> {
        let errorMessage = 'Folgende Fehler wurden festgestellt:<br><ul>';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error.error.ValidationMessages.forEach((item: any) => {
            errorMessage = errorMessage.concat(`<li>${item?.Message ?? ''}</li>`);
        });

        errorMessage = errorMessage.concat('</ul>');
        return this.notification.alert(
            error.error?.ValidationHeading,
            errorMessage);
    }
}
