<div class="wrapper">

<ng-container *ngIf="state$ | async as state">
    <div class="select-all">
        <mat-checkbox
            color="primary"
            labelPosition="before"
            [ngModel]="allFilesSelected"
            (change)="setAllFilesSelected(state)"
        >
            <span>{{ 'smartdoc.selection.selectData' | translate }}</span>
        </mat-checkbox>
    </div>
    <div class="documents">
        <ng-container *ngIf="documents$ | async as documents">
            <div
                *ngIf="state.documents.length === 0"
                class="pl-2 mb-3"
            >
                {{ 'smartdoc.selection.noDocuments' | translate }}
            </div>
            <div *ngFor="let document of documents" class="file">
                <div *ngIf="document" class="file-pdf" >
                    <ng-container *ngFor="let file of document.files">
                        <div *ngIf="isNotProcessed(file)" class="file-container" [ngClass]="filterSplittedDocuments(file.id) ? 'is-splitted' : ''">
                            <div class="user-actions-container">
                                <mat-checkbox
                                    color="primary"
                                    class="checkbox"
                                    [disabled]="filterSplittedDocuments(file.id)"
                                    [ngModel]="selectedFiles.includes(file.id)"
                                    (change)="setSelectedFile($event, file.id)"
                                ></mat-checkbox>
                                <div
                                    class="image-container"
                                    (click)="openFileInNewTab(file)"
                                >
                                    <img
                                        *ngIf="file.hasThumbnail"
                                        [title]="file.name"
                                        [src]="
                                            'data:image/jpeg;base64,' +
                                            file.base64
                                        "
                                        class="file-element"
                                    />
                                    <mat-icon
                                        class="material-icons-outlined"
                                        *ngIf="!file.hasThumbnail"
                                        [title]="file.name"
                                        width="45"
                                        class="file-element"
                                    >
                                        picture_as_pdf
                                    </mat-icon>
                                </div>
                            </div>
                            <div class="file-additional-information">
                                <h5 *ngIf="!!document.name" class="mb-0 hyphens" font-size-14>
                                    {{ document.name }}
                                </h5>
                                <h5 *ngIf="!document.name" class="mb-0 hyphens" font-size-14>
                                    {{ document.type | finprocessEnumTranslation:'DocumentType' }}
                                </h5>
                                <span font-size-12 class="font-italic">
                                    {{ document.created | date: 'dd.MM.yyyy' }}
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                </div>
        </ng-container>
    </div>
</ng-container>
<div class="navigation-bottom">
    <div
        *ngIf="state$ | async as state"
        class="col-12 pr-0 d-flex align-items-center justify-content-end"
    >
    <button
        class="start-splitting"
        type="button"
        [disabled]=" state.isReadonly ||
        isStatusReadonly(state) ||
        selectedFiles.length === 0 ||
        isOnlyUploadedFilesSelected(state)"
        (click)="startCutting(state)"
        mat-raised-button
        color="primary"
    >
        <mat-icon>content_cut</mat-icon>
        <span>{{ 'smartdoc.startSplitting' | translate }}</span>
    </button>
</div>
</div>
