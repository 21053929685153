<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" [rightFxFlex]="40"
    [leftFxFlex]="60" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2">
    <div left>
        <ng-container *ngIf="(financing$ | async) as financing">
            <div class="area-container">

                <!-- Darlehen -->
                <finprocess-sub-header [label]="'financing.features.financing-processing.calculator.loan' | translate">
                </finprocess-sub-header>

                <finprocess-calculation-display [numberFunctionObservable]="comfortCredit$"
                    [label]="'financing.features.financing-processing.calculator.financingAmount' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditProcessingCharges$"
                    [label]="'financing.features.financing-processing.calculator.handlingFee' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display *ngIf="(mandantType$ | async) === MandantType.BAF" [numberFunctionObservable]="financingFee$"
                    [label]="'financing.features.financing-processing.calculator.financingFee' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditEstimateCharges$"
                    [label]="'financing.features.financing-processing.calculator.valuationFee' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditRegistrationCharges$"
                    [label]="'financing.features.financing-processing.calculator.registrationChargesAmount' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditLegalisationFee$"
                    [label]="'financing.features.financing-processing.calculator.legalisationFee' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="landRegistryRequest$"
                    [label]="'financing.features.financing-processing.calculator.landRegistryRequest' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="landRegistryExcerpt$"
                    [label]="'financing.features.financing-processing.calculator.landRegistryExcerpt' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPayout$"
                    [label]="'financing.features.financing-processing.calculator.payoutSum' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-integer-input 
                    [label]="'financing.features.financing-processing.calculator.assumedDuration' | translate"
                    [entity]="financing" fieldName="assumedDuration"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>
                <finprocess-integer-input
                    [label]="'financing.features.financing-processing.calculator.gracePeriod' | translate"
                    [entity]="financing" fieldName="gracePeriod"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-integer-input>
                <finprocess-select-input
                    [label]="'financing.features.financing-processing.calculator.interestMethod' | translate"
                    [items]="interestMethod" [valueStorageType]="ValueStorageType.Int"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$" [entity]="financing" fieldName="interestMethod">
                </finprocess-select-input>
                <finprocess-decimal-input
                    [label]="'financing.features.financing-processing.calculator.referenceInterest' | translate"
                    [entity]="financing" fieldName="referenceInterestRate"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$"
                    [fxValueAlignment]="'start'" suffix="%"
                    format="1.0-3">
                </finprocess-decimal-input>
                <finprocess-decimal-input
                    [label]="'financing.features.financing-processing.calculator.requestedDebitRate' | translate"
                    [entity]="financing" fieldName="requestedDebitRate"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [hint]="requestedDebitRateHint"
                    [fxValueAlignment]="'start'" suffix="%"
                    [format]="'1.2-3'"
                    [readonly]="fieldReadonly$">
                </finprocess-decimal-input>
                <finprocess-textarea-input [placeholder]="'...'"
                    [label]="'financing.features.financing-processing.calculator.requestedDebitRateNotes' | translate"
                    [entity]="financing" fieldName="requestedDebitRateNotes"
                    [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textarea-input>

                <!-- Ergänzungsdarlehen -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.calculator.complementaryLoans' | translate">
                </finprocess-sub-header>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlus$"
                    [label]="'financing.features.financing-processing.calculator.financingAmount' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusProcessingCharges$"
                    [label]="'financing.features.financing-processing.calculator.handlingFee' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusPayout$"
                    [label]="'financing.features.financing-processing.calculator.payoutSum' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusDuration$"
                    [label]="'financing.features.financing-processing.calculator.assumedDuration' | translate"
                    [fxValueAlignment]="'start'" format="1.0-0" suffix="Monate">
                </finprocess-calculation-display>


                <!-- Gesamtrate -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.calculator.totalRate' | translate">
                </finprocess-sub-header>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditMonthlyDebitRate$"
                    [label]="'financing.features.financing-processing.calculator.monthlyRateConfort' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditMonthlyInterestRate$"
                    [label]="'financing.features.financing-processing.calculator.interestRateConfort' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusMonthlyDebitRate$"
                    [label]="'financing.features.financing-processing.calculator.monthlyRateComfortPlus' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display
                    [numberFunctionObservable]="comfortCreditTotalMonthlyDebitRateToYearTen$"
                    [label]="'financing.features.financing-processing.calculator.totalRateTo10' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display
                    [numberFunctionObservable]="comfortCreditTotalMonthlyDebitRateFromYearEleven$"
                    [label]="'financing.features.financing-processing.calculator.totalRateFrom11' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditTotalAmount$"
                    [label]="'financing.features.financing-processing.calculator.totalCreditAmount' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditTotalPayout$"
                    [label]="'financing.features.financing-processing.calculator.totalPayoutAmount' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>

                <!-- Leistbarkeit -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.calculator.affordability' | translate">
                </finprocess-sub-header>

                <finprocess-calculation-display [numberFunctionObservable]="acceptableCreditRate$"
                    [label]="'financing.features.financing-processing.household.acceptableCreditRate' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>

                <span class="acceptable-credit-rate-labeling">
                    {{'financing.features.financing-processing.calculator.reasonableLoanRateComment' | translate}}
                </span>

                <finprocess-calculation-display [numberFunctionObservable]="mainCreditFictionalRate$"
                    [label]="'financing.features.financing-processing.calculator.debitRate' | translate"
                    [fxValueAlignment]="'start'" suffix="%" format="1.2-3">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditFictionalRateAmount$"
                    [label]="'financing.features.financing-processing.calculator.monthlyFictitiousRateComfort' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusFictionalRate$"
                    [label]="'financing.features.financing-processing.calculator.debitRatePlus' | translate"
                    [fxValueAlignment]="'start'" suffix="%">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditPlusFictionalRateAmount$"
                    [label]="'financing.features.financing-processing.calculator.monthlyFictitiousRateComfortPlus' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-calculation-display [numberFunctionObservable]="comfortCreditTotalFictionalRateAmount$"
                    [label]="'financing.features.financing-processing.calculator.monthlyTotalFictitiousRateComfort' | translate"
                    [fxValueAlignment]="'start'" praefix="€">
                </finprocess-calculation-display>
                <finprocess-boolean-calculation-display [booleanFunctionObservable]="hhrCovered$"
                    [label]="'financing.features.financing-processing.calculator.isCoveredByHousehold' | translate">
                </finprocess-boolean-calculation-display>

            </div>

            <!-- Garantie für Firma -->
            <finprocess-sub-header
                [label]="'financing.features.financing-processing.financing-plan.subHeaderCompanyGuarantee' | translate ">
            </finprocess-sub-header>

            <finprocess-decimal-input [readonly]="fieldReadonly$"
                [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                [label]="'financing.features.financing-processing.financing-plan.guaranteeAmount' | translate"
                fieldName="guaranteeAmount">
            </finprocess-decimal-input>

            <finprocess-datepicker-input [readonly]="fieldReadonly$"
                [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                [label]="'financing.features.financing-processing.financing-plan.guaranteeDate' | translate"
                fieldName="guaranteeDate">
            </finprocess-datepicker-input>

            <finprocess-integer-input [readonly]="fieldReadonly$"
                [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                [label]="'financing.features.financing-processing.financing-plan.guaranteeDuration' | translate"
                fieldName="guaranteeDuration">
            </finprocess-integer-input>

            <ng-container *ngIf="!!mainRealestate && !isLot && !isRiskFinancingPlan">
                <!-- Treuhänder -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.trustee' | translate">
                </finprocess-sub-header>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.realEstate.name' | translate"
                    [entity]="mainRealestate" fieldName="trusteeName"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.realEstate.tel' | translate"
                    [entity]="mainRealestate" fieldName="trusteePhoneNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.realEstate.fax' | translate"
                    [entity]="mainRealestate" fieldName="trusteeFaxNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <!-- Treuhänder Adresse -->
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.realEstate.trusteeAddress' | translate">
                </finprocess-sub-header>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.street' | translate"
                    [entity]="mainRealestate.trusteeAddress" fieldName="street"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.streetNumber' | translate"
                    [entity]="mainRealestate.trusteeAddress" fieldName="streetNumber"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.zip' | translate"
                    [entity]="mainRealestate.trusteeAddress" fieldName="zip"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>

                <finprocess-textbox-input
                    [label]="'financing.features.financing-processing.city' | translate"
                    [entity]="mainRealestate.trusteeAddress" fieldName="city"
                    [overwriteValueClassType]="OverwriteValueClassType.AddressOverwriteValue"
                    [readonly]="fieldReadonly$">
                </finprocess-textbox-input>
             </ng-container>
        </ng-container>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
