import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const ECONOMIC_INFO: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'economicInfo',

    ndg: { type: WorkflowType.String },
}
