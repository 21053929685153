import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ICountry, IMasterdataParentDefinition } from 'app/modules/masterdata/data';
import { Observable, Subject, takeUntil } from 'rxjs';

import { CountryService } from './../../../../data/services/countries/country.service';

/**
 * Komponente für Länder
 */
@Component({
    selector: 'finprocess-country',
    templateUrl: './country.component.html',
    styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit, OnDestroy {

    @Select((it: IMasterdataParentDefinition) => it.masterdata.countries)
    public allCountries$!: Observable<ICountry[]>;

    public useForBirthCountryList: ICountry[] = [];

    public notUseForBirthCountryList: ICountry[] = [];

    public useForCitizenshipList: ICountry[] = [];

    public notUseForCitizenshipList: ICountry[] = [];

    public useForTaxResidenceList: ICountry[] = [];

    public notUseForTaxResidenceList: ICountry[] = [];

    public selectedCountryBirth: ICountry | undefined;

    public selectedCountryCitizenship: ICountry | undefined;

    public selectedCountryTaxResidence: ICountry | undefined;

    public userCanEdit = false;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    private onDestroy$ = new Subject<void>();

    /**
     * Standard Kostruktor
     *
     * @param {CountryService} countryService countryService-Injektor
     * @param {WaiterService} waiterService waiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private countryService: CountryService,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.waiterService.show();

        this.allCountries$
            .pipe(
                takeUntil(this.onDestroy$),
            ).subscribe((allCountries: ICountry[]) => {

                if (allCountries.length > 0) {
                    this.useForBirthCountryList = [];
                    this.notUseForBirthCountryList = [];
                    this.useForCitizenshipList = [];
                    this.notUseForCitizenshipList = [];
                    this.useForTaxResidenceList = [];
                    this.notUseForTaxResidenceList = [];

                    for (const country of allCountries) {

                        if (country.useForBirthCountry) {
                            this.useForBirthCountryList.push(country)
                        }
                        else {
                            this.notUseForBirthCountryList.push(country)
                        }

                        if (country.useForCiticenship) {
                            this.useForCitizenshipList.push(country)
                        }
                        else {
                            this.notUseForCitizenshipList.push(country)
                        }

                        if (country.useForTaxResidence) {
                            this.useForTaxResidenceList.push(country)
                        }
                        else {
                            this.notUseForTaxResidenceList.push(country)
                        }
                    }
                    this.waiterService.hide();
                }
            })

        this.authorizationService.hasRole$(Role.SelectListsAdministrator).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(hasRole => {this.userCanEdit = hasRole;});

    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
    /**
     * änderung an Geburtsland
     */
    public onEditBirthCountry(): void {
        if (!!this.selectedCountryBirth) {
            this.editCountry({...this.selectedCountryBirth, useForBirthCountry: true});
            this.selectedCountryBirth = undefined;
        }
    }

    /**
     * änderung an Steuerdomizil
     */
    public onEditCitizenshipCountry(): void {
        if (!!this.selectedCountryCitizenship) {

            this.editCountry({...this.selectedCountryCitizenship, useForCiticenship: true});
            this.selectedCountryCitizenship = undefined;
        }
    }

    /**
     * änderung an Steuerdomizil
     */
    public onEditResidenceCountry(): void {
        if (!!this.selectedCountryTaxResidence) {

            this.editCountry({...this.selectedCountryTaxResidence, useForTaxResidence: true});
            this.selectedCountryTaxResidence = undefined;
        }
    }

    /**
     * rüft service und ändert Land
     *
     * @param {ICountry} country Land
     */
    private editCountry(country: ICountry): void {
        this.waiterService.show();

        this.countryService.editCountry(country).subscribe({
            next: () => this.waiterService.hide(),
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
            },
        },
        )
    }
}
