import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { WaiterModule } from '@ntag-ef/waiter';
import { FlexLayoutModule } from 'ngx-flexible-layout'
import { PipesModule } from 'shared/features/pipes';
import { SharedUiModule } from 'shared/ui';

import { MessagesDataModule } from './../../data';
import { MessagesComponent } from './components';
import { SearchMessagesDialogComponent } from './components/search-messages-dialog/search-messages-dialog.component';

/**
 * Modul für die Anzeige des Dashboard
 */
@NgModule({
    declarations: [
        MessagesComponent,
        SearchMessagesDialogComponent,
    ],
    imports: [
        CommonModule,
        MessagesDataModule,
        FlexLayoutModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        SharedUiModule,
        PipesModule,
        WaiterModule.forChild(),
        NotificationsModule.forChild(),
        MatFormFieldModule,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        CKEditorModule,
        EnumTranslationModule.forChild(),
    ],
    exports: [
        MessagesComponent,
    ],
})

export class MessagesModule { }
