/**
 * Status eines Produktpakets
 */
export enum ProductPackageStatus {
    /**
     * In Bearbeitung
     */
    Editing = 100,

    /**
     * Sicherheiten erfolgreich
     */
    CollateralSuccessful = 150,

    /**
     * Fire fehlgeschlagen
     */
    DecisionFailed = 200,

    /**
     * Fire erfolgreich
     */
    DecisionSuccesful = 300,

    /**
     * Genehmigung ausstehend
     */
    ApprovalAwaiting = 400,

    /**
     * Genehmigung zurückgestellt
     */
    ApprovalRejected = 500,

    /**
     * Genehmigung abgelehnt
     */
    ApprovalDenied = 600,

    /**
     * Genehmigung erteilt (mit Auflagen)
     */
    ApprovalGrantedOnCondition = 650,

    /**
     * Genehmigung erteilt
     */
    ApprovalGranted = 700,
}
