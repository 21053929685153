<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id">
            <span *ngIf="!!label"></span>{{label}}
            <ng-content *ngIf="!label" select="[label]"></ng-content>
        </label>
        <mat-icon class="ml-1" *ngIf="overwrite && currentValue !== originalValue" [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (originalValue !== undefined && originalValue !== '' ? originalValue : ( 'financing.features.financing-processing.unset' | translate)) }">info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="start center">
        <mat-form-field class="field-color slim-fella" appearance="fill" fxFlex="100">
            <input matInput [disabled]="(readonly | async) ?? false" [ngModelOptions]="{ updateOn: 'blur' }" [ngModel]="currentValue" (ngModelChange)="save($event)">
        </mat-form-field>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr">
</div>
