import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiabilityType } from '@ntag-ef/finprocess-enums';
import { Subject, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs';
import { UUID } from 'shared/util';

import { IFinancingSumLiabilities, ILiabilityForSum, LiabilitiesService } from '../../../../data';


/**
 * Komponente zur Anzeige der Summen der Verbindlichkeiten pro Kreditnehmer
 */
@Component({
    selector: 'finprocess-liabilities-overview',
    templateUrl: './liabilities-overview.component.html',
    styleUrls: ['./liabilities-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilitiesOverviewComponent implements OnInit, OnDestroy {

    /**
     * Summendaten
     */
    public financingSumLiabilities: IFinancingSumLiabilities;

    /**
     * Ladezustand
     */
    public loading = false;

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Standard Konstruktor
     * 
     * @param {LiabilitiesService} liabilitiesService LiabilitiesService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetector-Injektor
     */
    public constructor(
        private liabilitiesService: LiabilitiesService,
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
    ) {
        this.financingSumLiabilities = {
            households: [],
            sum: 0,
            sumFictionalRates: 0,
            sumRates: 0,
        };
    }


    /**
     * Ruft die Liste der Verbindlichkeiten ab
     */
    public ngOnInit(): void {
        this.activatedRoute.parent?.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(() => {
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => this.liabilitiesService.getLiabilitieSumOverview(id)),
        ).subscribe(financingSumLiabilities => {
            if (!!financingSumLiabilities) {
                this.financingSumLiabilities = financingSumLiabilities;
                this.financingSumLiabilities.households.sort((a, b) => a.position - b.position);
            }

            this.loading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });
    }

    /**
     * Beendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Überprüft ob es mindesteins eine Verbindlichkeit gibt, die keine Rate hat
     * 
     * @returns {boolean} true, wenn mindesteins eine Verbindlichkeit keine Rate hat, sonst false
     */
    public checkRates(): boolean {
        return this.financingSumLiabilities.households.some(household => household.debitors.some(debitor => debitor.liabilities.some(liability => !liability.monthlyRate)));
    }

    /**
     * Überprüft ob bei der Verbindlichkeit die Rahmenhöhe angezeigt werden sollte
     * 
     * @param {ILiabilityForSum} liability Verbindlichkeit
     * @returns {boolean} true, wenn die Rahmenhöhe angezeigt werden soll, sonst false
     */
    public hasCreditLimit(liability: ILiabilityForSum): boolean {
        return liability.type !== undefined && [
            LiabilityType.CreditCard,
            LiabilityType.Overdraft,
            LiabilityType.ConstructionFollowUpFinancing,
            LiabilityType.ConstructionFollowUpFinancingBuildingLoan,
            LiabilityType.ConstructionInterimFinancing,
            LiabilityType.ConstructionPrefinancingInvestmentFlatHigh,
            LiabilityType.ConstructionPrefinancingInvestmentFlatLow,
        ].includes(liability.type);
    }

}
