import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ReleaseNotesModule } from '@ntag-ef/release-notes';
import { WaiterModule } from '@ntag-ef/waiter';
import { FlexLayoutModule } from 'ngx-flexible-layout'

import { AuthLoginRoutingModule } from './auth-login-routing.module';
import { LockedComponent, LoginComponent, LogoutComponent, SamlComponent } from './components';

export { LockedComponent, LoginComponent } from './components';

/**
 * Feature-Modul für das Login und die Sperrseite
 */
@NgModule({
    declarations: [
        LoginComponent,
        LockedComponent,
        LogoutComponent,
        SamlComponent,
    ],
    imports: [
        CommonModule,
        AuthLoginRoutingModule,
        TranslateModule.forChild(),
        FlexLayoutModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        WaiterModule.forChild(),
        ReleaseNotesModule,
    ],
})
export class AuthLoginModule { }
