import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_ACCOUNT_IDENTIFIER: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: ' customerAccountIdentifier',

    accontType: { type: WorkflowType.String },

    accountBranch: { type: WorkflowType.String },

    accountNumber: { type: WorkflowType.String },
}
