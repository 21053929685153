import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard, IRoleData, Role } from 'auth';

import { BaseDashboardComponent } from './components';

const routes: Routes = [
    {
        path: '',
        component: BaseDashboardComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
    },
];

/**
 * Modul für Routen zum Dashboard
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {

}
