/* eslint-disable class-methods-use-this */
import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Logout, UserService } from 'auth';
import { ISortBy, ISortByObjectSorter, sort } from 'fast-sort';
import { FinancingStatus, FinancingSubStatus, SortOrder } from 'shared/data';
import { HelperService, IDashboardConfig } from 'shared/util';

import { IDashboardDebitor, IDashboardFinancing } from '../../interfaces';
import { DashboardSorting, DashboardStatusMap } from '../../types';
import { DashboardLeaved, SalesPartnerCenterFilterChanged, TabChanged } from '../dashboard/dashboard.actions';

import { DashboardFilter, DashboardFinancingsSortValues, DashboardTab } from './../../enums';
import { DASHBOARD_CONFIG } from './../../tokens';
import { PageSizeChanged, SearchChanged, SortingChanged, StatusFilterChanged } from './../dashboard/dashboard.actions';
import { ClientFilterSorting, FinancingsLoaded, FinancingsPageLoaded } from './financings.actions';

/**
 * Zustandsobjekt für Finanzierungsliste
 */
export interface IFinancingsStateModel {
    allFinancings: IDashboardFinancing[];
    financings: Array<IDashboardFinancing[]>;
    financingsCount: number;
    clientLogicTabLoadedStates: DashboardStatusMap;
}

const defaultData = {
    allFinancings: [],
    financings: [],
    financingsCount: 0,
    clientLogicTabLoadedStates: {},
};

/**
 * Zustand Finanzierungsliste
 */
@State<IFinancingsStateModel>({
    name: FinancingsState.keyName,
    defaults: defaultData,
})
@Injectable()
export class FinancingsState {
    public static readonly keyName = 'financings';

    /**
     * Konstruktor
     *
     * @param {IDashboardConfig} dashboardConfig DASHBOARD_CONFIG-Injektor
     * @param {UserService} userService UserService-Injektor
     */
    public constructor(@Inject(DASHBOARD_CONFIG) private dashboardConfig: IDashboardConfig, private userService: UserService) {

    }
    private static statusSortMap: FinancingStatus[] = [
        FinancingStatus.Open,
        FinancingStatus.SampleCalculationWaitingForAcception,
        FinancingStatus.SampleCalculationAccepted,
        FinancingStatus.Finalize,
        FinancingStatus.EsisWaitingForAcception,
        FinancingStatus.Completed,
        FinancingStatus.Rejected,
        FinancingStatus.Canceled,
    ];

    private static subStatusSortMap: FinancingSubStatus[] = [
        FinancingSubStatus.Open,
        FinancingSubStatus.SampleCalculationRejected,
        FinancingSubStatus.Editing,
        FinancingSubStatus.EsisRejected,
        FinancingSubStatus.SampleCalculationAccepted,
        FinancingSubStatus.Rejected,
        FinancingSubStatus.RejectedByResponsibilityGK,
        FinancingSubStatus.RejectedByResponsibilityPF,
        FinancingSubStatus.AutomaticallyRejected,
        FinancingSubStatus.ViaColtApplication,
        FinancingSubStatus.Ready,
        FinancingSubStatus.RetrievedByColtApplication,
        FinancingSubStatus.Manual,
    ];

    /**
     * Liefert die zu ladenden Status bei Client zentrierter Logik
     *
     * @param {IFinancingsStateModel} state Zustandsobjekt
     * @returns {() => DashboardStatusMap} Callback-Funktion
     */
    @Selector()
    public static getUnloadedStatesByStateMap(state: IFinancingsStateModel) {
        /**
         * Callback-Funktion, um die zu ladenden Status bei Client zentrierter Logik zu ermitteln
         *
         * @param {DashboardStatusMap} loadingMap Benötigte Statuskombinationen
         * @returns {DashboardStatusMap} Zu ladende Statuskombinationen
         */
        return (loadingMap: DashboardStatusMap): DashboardStatusMap => {
            const loadedStatusMap = state.clientLogicTabLoadedStates;
            for (const statusString in loadingMap) {
                if (statusString in loadingMap) {
                    const status = parseInt(statusString, 10) as FinancingStatus;
                    if (loadedStatusMap[status] !== undefined) {
                        loadingMap[status] = (loadingMap[status] as FinancingSubStatus[]).filter(it => !(loadedStatusMap[status] as FinancingSubStatus[]).includes(it));
                        if ((loadingMap[status] as FinancingSubStatus[]).length === 0) {
                            delete loadingMap[status];
                        }
                    }
                }
            }
            return loadingMap;
        };
    }

    /**
     * Zustandsänderung beim Verlassen des Dashboard
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(DashboardLeaved)
    @Action(TabChanged)
    @Action(Logout)
    public reset({ setState }: StateContext<IFinancingsStateModel>): void {
        setState(defaultData);
    }

    /**
     * Zustandsänderung beim Laden einer Seite aus dem Backend
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingsPageLoaded} action Aktion
     */
    @Action(FinancingsPageLoaded)
    public financingsPageLoaded({ getState, setState }: StateContext<IFinancingsStateModel>, { payload }: FinancingsPageLoaded): void {
        const state = getState();
        const newFinancings = [];

        if (!!payload.keepData) {
            state.financings.forEach((value, index) => {
                if (value !== undefined) {
                    newFinancings[index] = value;
                }
            });
        }

        newFinancings[payload.page - 1] = payload.financings;
        setState({
            allFinancings: newFinancings.reduce((p, c) => (c !== undefined ? p.concat(c) : p), []),
            financings: newFinancings,
            financingsCount: payload.maxResultsCount,
            clientLogicTabLoadedStates: {},
        });
    }

    /**
     * Zustandsänderung beim Laden einer Seite aus dem Backend
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingsLoaded} action Aktion
     */
    @Action(FinancingsLoaded)
    public financingsLoaded({ getState, setState }: StateContext<IFinancingsStateModel>, { payload }: FinancingsLoaded): void {
        const state = getState();
        const newTabLoadedStates: DashboardStatusMap = {};

        for (const enumValue of HelperService.getEnumArray(FinancingStatus) as FinancingStatus[]) {
            if (state.clientLogicTabLoadedStates[enumValue] !== undefined) {
                newTabLoadedStates[enumValue] = [...(state.clientLogicTabLoadedStates[enumValue] as FinancingSubStatus[])];
            }

            if (payload.statusMap[enumValue] !== undefined && newTabLoadedStates[enumValue] === undefined) {
                newTabLoadedStates[enumValue] = payload.statusMap[enumValue];
            }
            else if (payload.statusMap[enumValue] !== undefined) {
                newTabLoadedStates[enumValue] = [
                    ...(newTabLoadedStates[enumValue] as FinancingSubStatus[]).filter(it => !(payload.statusMap[enumValue] as FinancingSubStatus[]).some(sts => it === sts)),
                    ...payload.statusMap[enumValue] as FinancingSubStatus[],
                ];
            }
        }

        setState({
            allFinancings: [...state.allFinancings.filter(it => !payload.financings.some(pit => pit.id === it.id))],
            financings: [],
            financingsCount: 0,
            clientLogicTabLoadedStates: newTabLoadedStates,
        });
    }

    /**
     * Zustandsänderung beim Wechseln von Filter/Sortierung bei serverseitigem Filter
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {PageSizeChanged | StatusFilterChanged | SearchChanged | SortingChanged} action Aktion
     */
    @Action(PageSizeChanged)
    @Action(StatusFilterChanged)
    @Action(SearchChanged)
    @Action(SortingChanged)
    @Action(SalesPartnerCenterFilterChanged)
    public resetServerLoaded({ setState }: StateContext<IFinancingsStateModel>, { payload }: PageSizeChanged | StatusFilterChanged | SearchChanged | SortingChanged | SalesPartnerCenterFilterChanged): void {
        if (
            (payload.tabId === DashboardTab.All && !this.dashboardConfig.useClientFilterSortingOnAllTab) ||
            (payload.tabId !== DashboardTab.All && !this.dashboardConfig.useClientFilterSortingOnUserTab)
        ) {
            setState(defaultData);
        }
    }

    /**
     * Zustandsänderung beim Wechseln von Filter/Sortierung bei serverseitigem Filter
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {FinancingsLoaded} action Aktion
     */
    @Action(ClientFilterSorting)
    public clientFilteringSorting({ getState, patchState }: StateContext<IFinancingsStateModel>, { payload }: ClientFilterSorting): void {
        if (
            (payload.tabId === DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnAllTab) ||
            (payload.tabId !== DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnUserTab)
        ) {
            const state = getState();
            let newFinancings = this.clientFiltering(payload.tabId, state.allFinancings, payload.options.currentFilter);
            newFinancings = this.clientSearch(newFinancings, payload.options.currentSearch);
            newFinancings = this.clientSorting(newFinancings, payload.options.currentSorting);
            const pagedFinancings = [];
            if (payload.options.currentPageSize === undefined) {
                pagedFinancings[0] = newFinancings;
            }
            else {
                const financingsToCut = [...newFinancings];
                let i = 0;
                do {
                    pagedFinancings[i] = financingsToCut.splice(0, payload.options.currentPageSize);
                    i++;
                }
                while (financingsToCut.length > 0);
            }

            patchState({
                allFinancings: newFinancings,
                financings: pagedFinancings,
                financingsCount: newFinancings.length,
            })
        }
    }

    /**
     * Filtert die Finanzierungsanfragen nach Filter und Tab
     *
     * @param {string} tabId Tab
     * @param {IDashboardFinancing[]} financings Ungefilterte Finanzierungsanfragen
     * @param {DashboardFilter} filter Filter
     * @returns {IDashboardFinancing[]} Finanzierungsanfragen
     */
    // eslint-disable-next-line complexity
    private clientFiltering(tabId: string, financings: IDashboardFinancing[], filter: DashboardFilter): IDashboardFinancing[] {
        let result = [...financings];
        if (tabId === DashboardTab.My) {
            result = result.filter(it => this.userService.user !== undefined && it.editor?.id === this.userService.user.id);
        }
        else if (tabId !== DashboardTab.All) {
            result = result.filter(it => it.editor !== null && it.editor?.id === tabId);
        }

        switch (filter) {
            case DashboardFilter.StatusOpen:
                return result.filter(it => it.financingStatus === FinancingStatus.Open && (it.financingSubStatus === FinancingSubStatus.Open || (it.financingSubStatus === undefined && it.editor === undefined)));
            case DashboardFilter.Editing:
                return result.filter(it => it.financingStatus === FinancingStatus.Open && (it.financingSubStatus === FinancingSubStatus.Editing || (it.financingSubStatus === undefined && it.editor !== undefined)));
            case DashboardFilter.StatusSampleCalculationRejected:
                return result.filter(it => it.financingStatus === FinancingStatus.Open && it.financingSubStatus === FinancingSubStatus.SampleCalculationRejected);
            case DashboardFilter.StatusSampleCalculationWaitingForAcception:
                return result.filter(it => it.financingStatus === FinancingStatus.SampleCalculationWaitingForAcception);
            case DashboardFilter.StatusSampleCalculationAccepted:
                return result.filter(it => it.financingStatus === FinancingStatus.SampleCalculationAccepted && (it.financingSubStatus === FinancingSubStatus.SampleCalculationAccepted || it.financingSubStatus === undefined));
            case DashboardFilter.StatusEsisRejected:
                return result.filter(it => it.financingStatus === FinancingStatus.SampleCalculationAccepted && it.financingSubStatus === FinancingSubStatus.EsisRejected);
            case DashboardFilter.StatusEsisWaitingForAcception:
                return result.filter(it => it.financingStatus === FinancingStatus.EsisWaitingForAcception);
            case DashboardFilter.StatusCompleted:
                return result.filter(it => it.financingStatus === FinancingStatus.Completed);
            case DashboardFilter.StatusRejectedByResponsibility:
                return result.filter(it => it.financingStatus === FinancingStatus.Rejected && [FinancingSubStatus.RejectedByResponsibilityPF, FinancingSubStatus.RejectedByResponsibilityGK].some(subS => subS === it.financingSubStatus));
            case DashboardFilter.StatusRejected:
                return result.filter(it => it.financingStatus === FinancingStatus.Rejected && ![FinancingSubStatus.AutomaticallyRejected, FinancingSubStatus.RejectedByResponsibilityPF, FinancingSubStatus.RejectedByResponsibilityGK].some(subS => subS === it.financingSubStatus));
            case DashboardFilter.StatusAutomaticallyRejected:
                return result.filter(it => it.financingStatus === FinancingStatus.Rejected && it.financingSubStatus === FinancingSubStatus.AutomaticallyRejected);
            case DashboardFilter.StatusCanceled:
                return result.filter(it => it.financingStatus === FinancingStatus.Canceled);
            case DashboardFilter.Finalize:
                return result.filter(it => it.financingStatus === FinancingStatus.Finalize);
            case DashboardFilter.Accounting:
                return result;
            case DashboardFilter.None:
            default:
                return result.filter(it => [FinancingStatus.Canceled, FinancingStatus.Completed, FinancingStatus.Rejected].indexOf(it.financingStatus) === -1);
        }
    }

    /**
     * Filtert die Finanzierungsanfragen nach Filter und Tab
     *
     * @param {IDashboardFinancing[]} financings Undurchsuchte Finanzierungsanfragen
     * @param {string} search Suche
     * @returns {IDashboardFinancing[]} Finanzierungsanfragen
     */
    private clientSearch(financings: IDashboardFinancing[], search?: string): IDashboardFinancing[] {
        if (HelperService.isNullOrEmpty(search)) {
            return financings;
        }
        const splittedValue = (search as string).replace(/\s\s+/g, ' ').toLowerCase().split(' ');

        return financings.filter((financing: IDashboardFinancing) => splittedValue.some((searchVal: string) =>
            financing.debitors.some((debitor: IDashboardDebitor, index: number) =>
                // TODO SPe: index entfernen, wenn Suche über mehr als 2 Kreditnehmer
                index < 2 && (
                    debitor.firstName.toLowerCase().indexOf(searchVal) > -1 ||
                    debitor.lastName.toLowerCase().indexOf(searchVal) > -1 ||
                    debitor.customerNumber.toLowerCase().indexOf(searchVal) > -1
                ),
            ) ||
            financing.systemNumber.toLowerCase().indexOf(searchVal) > -1 ||
            (financing.editor !== undefined && (financing.editor.shortName as unknown) !== undefined && financing.editor.shortName.toLowerCase().indexOf(searchVal) > -1) ||
            (financing.editor !== undefined && (financing.editor.firstName as unknown) !== undefined && financing.editor.firstName.toLowerCase().indexOf(searchVal) > -1) ||
            (financing.editor !== undefined && (financing.editor.lastName as unknown) !== undefined && financing.editor.lastName.toLowerCase().indexOf(searchVal) > -1)));
    }

    /**
     * Sortiert die Finanzierungsanfragen
     *
     * @param {IDashboardFinancing[]} financings Undurchsuchte Finanzierungsanfragen
     * @param {DashboardSorting} sorting Sortierungen
     * @returns {IDashboardFinancing[]} Finanzierungsanfragen
     */
    private clientSorting(financings: IDashboardFinancing[], sorting?: DashboardSorting): IDashboardFinancing[] {
        if (sorting === undefined) {
            return financings;
        }
        let sortings: Array<ISortByObjectSorter<IDashboardFinancing>> = [];

        for (const key in sorting) {
            if (key in sorting) {
                const enumKey = parseInt(key, 10) as DashboardFinancingsSortValues;
                const sortOrder = sorting[enumKey] as SortOrder;
                const sortFunctions = this.getSortingFunctionByProperty(enumKey)
                switch (sortOrder) {
                    case SortOrder.Descending:
                        if (Array.isArray(sortFunctions)) {
                            sortings = [...sortings, ...sortFunctions.map((it: ISortBy<IDashboardFinancing>) => ({ desc: it }))];
                        }
                        else {
                            sortings.push({
                                desc: sortFunctions,
                            });
                        }
                        break;
                    case SortOrder.Ascending:
                    default:
                        if (Array.isArray(sortFunctions)) {
                            sortings = [...sortings, ...sortFunctions.map((it: ISortBy<IDashboardFinancing>) => ({ asc: it }))];
                        }
                        else {
                            sortings.push({
                                asc: sortFunctions,
                            });
                        }
                        break;
                }
            }
        }

        if (sortings.length > 0) {
            return sort(financings).by(sortings);
        }

        return financings;
    }

    /**
     * Liefert die Sortierfunktion für ein Feld
     *
     * @param {DashboardFinancingsSortValues} field Feld, nach dem sortiert werden soll
     * @returns {ISortBy<IDashboardFinancing> | Array<ISortBy<IDashboardFinancing>>} Sortierfunktion für ein Feld
     */
    private getSortingFunctionByProperty(field: DashboardFinancingsSortValues): ISortBy<IDashboardFinancing> | Array<ISortBy<IDashboardFinancing>> {
        switch (field) {
            case DashboardFinancingsSortValues.EditorShortName:
                return (financing: IDashboardFinancing) => financing.editor?.shortName;
            case DashboardFinancingsSortValues.FinancingStatus:
                return [
                    (financing: IDashboardFinancing) => FinancingsState.statusSortMap.indexOf(financing.financingStatus),
                    (financing: IDashboardFinancing) => (financing.financingSubStatus !== undefined ? FinancingsState.subStatusSortMap.indexOf(financing.financingSubStatus) : 0),
                ];
            case DashboardFinancingsSortValues.FirstDebitorCustomerNumber:
                return (financing: IDashboardFinancing) => financing.debitors[0]?.customerNumber;
            case DashboardFinancingsSortValues.FirstDebitorFirstname:
                return (financing: IDashboardFinancing) => financing.debitors[0]?.firstName;
            case DashboardFinancingsSortValues.FirstDebitorLastname:
                return (financing: IDashboardFinancing) => financing.debitors[0]?.lastName;
            case DashboardFinancingsSortValues.SecondDebitorCustomerNumber:
                return (financing: IDashboardFinancing) => financing.debitors[1]?.customerNumber;
            case DashboardFinancingsSortValues.SecondDebitorFirstname:
                return (financing: IDashboardFinancing) => financing.debitors[1]?.firstName;
            case DashboardFinancingsSortValues.SecondDebitorLastname:
                return (financing: IDashboardFinancing) => financing.debitors[1]?.lastName;
            case DashboardFinancingsSortValues.SystemNumber:
                return (financing: IDashboardFinancing) => financing.systemNumber;
            case DashboardFinancingsSortValues.SubmissionDate:
            default:
                return (financing: IDashboardFinancing) => financing.submissionDate;
        }
    }
}
