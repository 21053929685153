import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationsModule } from '@ntag-ef/notifications';

import { SubstitutionService } from './services';
import { SubstitutionState } from './states';

export * from './interfaces';
export * from './services';
export * from './states';

/**
 * Modul für Datenverwaltung und Datenaustausch bzgl. Nutzerbereich
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            SubstitutionState,
        ]),
        TranslateModule.forChild(),
        NotificationsModule.forChild(),
    ],
    providers: [
        SubstitutionService,
    ],
})
export class UserAreaDataModule {
}
