/* eslint-disable class-methods-use-this */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { IBranch } from 'app/modules/masterdata/data';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, combineLatest, concat, map, take, takeUntil } from 'rxjs';
import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { HelperService } from 'shared/util';

import { EditBranchDialogComponent } from '..';

import { IEditBranchDialogResult } from './../../../../data/interfaces/edit-branch-dialog-result.interface';
import { BranchService } from './../../../../data/services/branch/branch.service';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';

export interface ISelectedItem {
    value: number;
    displayName: string;
}

/**
 * Komponente für die Nutzerliste im Administrationsbereich
 */
@Component({
    selector: 'finprocess-branch',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.scss'],
})
export class BranchComponent implements OnDestroy, OnInit {

    @Select((it: IAdministrationStateParentDefinition) => it.branch.branches)
    public branches$!: Observable<IBranch[]>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Subject zum Filtern der Seiten
     */
    public currentPage = new BehaviorSubject<number>(0);

    /**
     * Subject zum Filtern der Organisationseinheiten
     */
    public selectedBranchResponsibility = new BehaviorSubject<OrganisationalUnitResponsibilityType | undefined>(undefined);

    public organisationalResponsibilityType: ISelectedItem[] = [];

    /**
     * Subject für die Textsuche
     */
    public searchTerm = new BehaviorSubject<string>('');


    public pageSize = 12;

    public displayedColumns: string[] = ['costCentre', 'branchCode', 'name', 'disabled', 'organisationalUnit', 'actions'];
    public displayedBranches: IBranch[] = [];

    private onDestroy$ = new Subject<void>();


    /**
     * Standard Konstruktor
     *
     * @param {BranchService} branchService BranchService-Injektor
     * @param {MatDialog} matDialog MatDialog-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(
        private branchService: BranchService,
        private matDialog: MatDialog,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
    ) {}

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.branchService.getList()
            .pipe(take(1))
            .subscribe();

        this.organisationalResponsibilityType = sort((HelperService.getEnumArray(OrganisationalUnitResponsibilityType) as number[]).map(premission => ({
            value: premission,
            displayName: OrganisationalUnitResponsibilityType.translate(premission as OrganisationalUnitResponsibilityType) ?? '',
        })).filter(x => x.value !== 0)).asc(premission => premission.displayName);

        combineLatest([this.branches$, this.currentPage, this.searchTerm, this.selectedBranchResponsibility]).pipe(
            takeUntil(this.onDestroy$),
            map(([branches, currentPage, searchTerm, selectedBranchResponsibility]) => {

                if (!Array.isArray(branches)) {
                    return [];
                }

                const filteredUsers = this.searchBranches(branches, searchTerm, selectedBranchResponsibility);

                const startIndex = currentPage * this.pageSize;
                const displayedBranches = filteredUsers.slice(startIndex, startIndex + this.pageSize);

                if (displayedBranches.length < this.pageSize) {
                    // Filialen mit leeren Einträgen auffuellen damit die Tabelle gut aussieht
                    displayedBranches.push(...Array(this.pageSize - displayedBranches.length).fill(undefined))
                }

                return displayedBranches;
            }),
        ).subscribe(branches => {this.displayedBranches = branches;});
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Ändert die angezeigten Benutzer wenn die Seite des Paginators geändert wird
     *
     * @param {PageEvent} pageEvent Das PageEvent des Paginators
     */
    public changePage(pageEvent: PageEvent): void {
        this.currentPage.next(pageEvent.pageIndex);
    }

    /**
     * Suche aktualisieren
     *
     * @param {string} search Suchbegriff
     */
    public updateSearch(search: string): void {
        this.searchTerm.next(search);
    }

    /**
     * Filter für Organisationseinheit aktualisieren
     *
     * @param {MatSelectChange} event MatSelect Event
     */
    public updateOrgUnit(event: MatSelectChange): void {
        this.selectedBranchResponsibility.next(event.value);
    }


    /**
     * Bearbeitet eine Filiale oder fügt eine neue hinzu
     *
     * @param {IBranch} branch Filiale
     */
    public editBranch(branch?: IBranch): void {
        const dialogRef = this.matDialog.open(EditBranchDialogComponent, {
            data: branch,
            width: '400px',
            height: '450px',
        });

        dialogRef.afterClosed().pipe(
            filter(result => result !== undefined),
            tap(() => this.waiterService.show()),
            mergeMap((result: IEditBranchDialogResult) => {
                const requests: Observable<unknown>[] = [];

                if (!!result.addRequest) {
                    requests.push(this.branchService.addBranch(result.addRequest).pipe(first()));
                }

                if (!!result.editRequest) {
                    requests.push(this.branchService.editBranch(result.editRequest).pipe(first()));
                }

                if (!!result.statusRequest) {
                    requests.push(this.branchService.setStatus(result.statusRequest).pipe(first()));
                }

                return concat(...requests);
            }),
        ).subscribe({
            next: () => {this.waiterService.hide();},
            error: () => {
                this.waiterService.hide();
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('administration.features.branches.errorEditBranch'));
            },
        });
    }

    /**
     * Durchsucht die Liste der Filialen
     *
     * @param {IBranch[]} branches Filialen
     * @param {string} searchTerm Suchbegriff
     * @param {OrganisationalUnitResponsibilityType} selectedBranchResponsibility (optional)selectedBranchResponsibility
     * @returns {IBranch[]} gefilterte Liste von Filialen
     */
    private searchBranches(branches: IBranch[], searchTerm: string, selectedBranchResponsibility?: OrganisationalUnitResponsibilityType): IBranch[] {
        return branches.filter(branch => searchTerm.split(' ').map(term => term.toLowerCase()).every(term =>
            branch.name.toLowerCase().includes(term) &&
           (selectedBranchResponsibility === undefined || branch.responsibility === selectedBranchResponsibility), //TODO!!!!
        ));
    }
}
