<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" rigthClass="pb-0 pt-3 px-2" leftClass="pb-0 pt-3 px-2" [flexMd]="65">
    <div left>
        <ng-container *ngIf="(finprocessContainer$ | async) as finprocessContainer">
            <ng-container *ngIf="(financing$ | async) as financing">
                <div class="area-container">
                    <!-- Kreditantrag -->
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.process.loanApplication' | translate">
                    </finprocess-sub-header>
    
                    <finprocess-select-input
                        [label]="'financing.features.financing-processing.process.firstCheck' | translate"
                        [items]="applicationDecisionSelectItems" [valueStorageType]="ValueStorageType.Int"
                        [entity]="financing" fieldName="applicationDecision" [readonly]="applicationDecisionControlReadonly$"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.FinProcessContainer"></finprocess-select-input>
                </div>
    
                <!-- Fall Kreditantrag abgelehnt -->
                <ng-container *ngIf="financing.applicationDecision === ApplicationDecisionType.Rejected">
                    <finprocess-sub-header [label]="'financing.features.financing-processing.process.process' | translate">
                    </finprocess-sub-header>
                    <div class="center">
                        <button mat-raised-button color="primary" class="mt-2 center" (click)="openRejectionLetter()"
                            [disabled]="applicationDecisionStatus.readonly">
                            {{'financing.features.financing-processing.process.rejectionLetter' | translate}}
                        </button>
                    </div>
                </ng-container>
    
                <ng-container *ngIf="sampleCalculationStatus.visible">
    
                    <ng-container *ngIf="financing.mandantType === MandantType.FinAdvisory">
                        <finprocess-sub-header
                            [label]="'financing.features.financing-processing.process.signatureDocuments' | translate">
                        </finprocess-sub-header>
    
                        <mat-tab-group animationDuration="0ms">
                            <div>
                                <mat-tab
                                    label="{{'financing.features.financing-processing.process.householdCosts' | translate}}">
                                    <finprocess-upload-tab [uploadContexts]="householdContexts" [readonly]="sampleCalculationStatus.readonly">
                                    </finprocess-upload-tab>
                                </mat-tab>
                                <mat-tab
                                    label=" {{'financing.features.financing-processing.process.selfDisclosure' | translate}}">
                                    <finprocess-upload-tab [uploadContexts]="debitorContexts" [readonly]="sampleCalculationStatus.readonly">
                                    </finprocess-upload-tab>
                                </mat-tab>
                            </div>
                        </mat-tab-group>
                    </ng-container>
    
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.process.calculationExamples' | translate">
                    </finprocess-sub-header>
                    <mat-tab-group animationDuration="0ms">
                        <div>
                            <mat-tab
                                label="{{'financing.features.financing-processing.process.calculationExample' | translate}}">
                                <finprocess-upload-tab *ngIf="!!sampleCalculationContext"[uploadContexts]="[sampleCalculationContext]" [readonly]="sampleCalculationStatus.readonly">
                                </finprocess-upload-tab>
                            </mat-tab>
                        </div>
                    </mat-tab-group>
    
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.process.laterSuppliedDocuments' | translate">
                    </finprocess-sub-header>
                    <form [formGroup]="form">
                        <div class="later-supplied-documents">
                            <p>
                                <mat-checkbox color="primary" formControlName="employerApproval">
                                    {{
                                    'financing.features.financing-processing.process.employerApproval' | translate }}
                                </mat-checkbox>
                            </p>
                            <p>
                                <mat-checkbox color="primary"
                                    formControlName="offerRequiredInsurance">{{
                                    'financing.features.financing-processing.process.offerRequiredInsurance' | translate }}
                                </mat-checkbox>
                            </p>
                            <p>
                                <mat-checkbox color="primary"
                                    formControlName="purchaseAgreement">{{
                                    'financing.features.financing-processing.process.purchaseAgreement' | translate }}
                                </mat-checkbox>
                            </p>
                        </div>
                        <finprocess-sub-header
                            [label]="'financing.features.financing-processing.process.additionalInformations' | translate">
                        </finprocess-sub-header>
                        <div class="text-box w-100">
                            <ckeditor [editor]="Editor" formControlName="additionalInformation">
                            </ckeditor>
                        </div>
    
                        <ng-container *ngIf="finprocessContainer.status >= Status.SampleCalculationAccepted || finprocessContainer.subStatus === SubStatus.SampleCalculationRejected">
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px" class="process-alert p-2 my-2" [ngClass]="{'success': finprocessContainer.subStatus !== SubStatus.SampleCalculationRejected, 'fail': finprocessContainer.subStatus === SubStatus.SampleCalculationRejected}">
                                <mat-icon *ngIf="finprocessContainer.subStatus !== SubStatus.SampleCalculationRejected">check_circle</mat-icon>
                                <mat-icon *ngIf="finprocessContainer.subStatus === SubStatus.SampleCalculationRejected">cancel</mat-icon>
                                <div fxLayout="column" fxLayoutGap="6px">
                                    <span *ngIf="finprocessContainer.subStatus !== SubStatus.SampleCalculationRejected" class="header">{{'financing.features.financing-processing.process.sampleCalculationAccepted' | translate }}</span>
                                    <span *ngIf="finprocessContainer.subStatus === SubStatus.SampleCalculationRejected" class="header">{{'financing.features.financing-processing.process.sampleCalculationRejected' | translate }}</span>
                                    <mat-chip-listbox *ngIf="!!acceptedSampleCalculation">
                                        <mat-chip-option (click)="openFile(acceptedSampleCalculation.file)">{{ acceptedSampleCalculation.file.name }}</mat-chip-option>
                                    </mat-chip-listbox>
                                    <div>{{ finprocessContainer.sampleCalculationResponse }}</div>
                                </div>
                            </div>
                        </ng-container>
    
                    </form>
                    
                    <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-raised-button color="primary" class="mt-2 center" (click)="sendAllDocuments()"
                            [disabled]="!valid || sampleCalculationStatus.readonly">
                            {{'financing.features.financing-processing.process.sendAllDocuments' | translate}}
                        </button>

                        <button class="ml-3 mt-2 center" mat-raised-button color="primary" [disabled]="documentsSend === false"
                        (click)="openCustomerCenterPage()">{{'financing.features.financing-processing.process.routeToCustomerCenter'| translate}}</button>
                    </div>
                </ng-container>
     
                <ng-container *ngIf="financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityPF || financing.applicationDecision === ApplicationDecisionType.RejectedByResponsibilityGK">
                    <div *ngIf="!!formResponsibility">
                        <finprocess-sub-header [label]="'financing.features.financing-processing.process.rejectApplication' | translate">
                        </finprocess-sub-header>
                        <form [formGroup]="formResponsibility">
                            <label  class="label" >{{'financing.features.financing-processing.process.comment' | translate}}</label>
                            <ckeditor [editor]="Editor" formControlName="additionalInformationResponsibility" (change)="true">
                            </ckeditor>
                            <div fxLayout="row" fxLayoutAlign="end center" class="align-right py-2">
                                <button (click)="changeResponsibility(financing.applicationDecision)" mat-flat-button color="primary" [disabled]="formResponsibility.invalid || applicationDecisionStatus.readonly">
                                    {{ 'financing.features.financing-processing.process.rejectApplication' | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
