<div [ngClass]="dropFolder?.isDeletable ? 'header-multi' : 'header'" fxLayout="row"  >
    <mat-icon (click)="onDeleteFolder()"
              *ngIf="dropFolder?.isDeletable"
              class="icon-delete"
              matTooltip="{{'administration.features.smartDocConfiguration.deleteFolder' | translate}}">delete
    </mat-icon>
    <mat-icon (click)="onEditFolder()"
              class="icon-edit"
              matTooltip="{{'administration.features.smartDocConfiguration.editFolder' | translate}}">edit
    </mat-icon>
</div>
<div fxLayout="column" class="card" (click)="onOpenFolder()">
    <div fxFlex="20"></div>
    <div class="body" >
        <mat-icon  class="icon-folder">folder</mat-icon>
    </div>
    <div class="footer">
        {{dropFolder?.name}}
    </div>
</div>
