<div fxLayout="column" class="container p-3">
  <mat-form-field appearance="fill" fxFlex="60">
  <mat-select [ngModel]="riskFinancing?.status" disabled="true">
    <mat-option *ngFor="let status of financingPlanStatuses" [value]="status" >
      {{ status | finprocessEnumTranslation:'StatusScoring' }}
    </mat-option>
  </mat-select>
  </mat-form-field>
  <div fxLayout="column" *ngIf="!!form" [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="40">{{'financing.features.financing-processing.scoringGwb.rating' | translate}}</div>
      <mat-form-field appearance="fill" fxFlex="60">
        <input formControlName="productRating" matInput>
      </mat-form-field>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="40">{{'financing.features.financing-processing.scoringGwb.zone' | translate}}</div>
  
      <mat-form-field appearance="fill" fxFlex="60">
        <mat-select formControlName="zone">
          <mat-option *ngFor="let zone of zones" [value]="zone.value">
            {{zone.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
    </div>
  
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="40">
        <button mat-raised-button color="primary" [disabled]="!this.isScoringAvailable || (isReadonly$ | async) === true" (click)="getNewScoring()" >
          {{'financing.features.financing-processing.scoringGwb.scoringNew' | translate}}
        </button>
        <button mat-raised-button color="primary" [disabled]="form?.pristine || 
                                                              form?.invalid || 
                                                              form?.disabled || 
                                                              (isReadonly$ | async) === true" 
                                                              
          (click)="sendScoring()" >
        {{'financing.features.financing-processing.scoringGwb.sendScoring' | translate}}
      </button>
    </div>
  </div>
</div>