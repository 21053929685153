import { IInformationEventStateModel } from './information-event/information-event.state';
/**
 * Interface für globalen Zustandsspeicher
 */
export interface IInformationEventStateParentDefinition {
    /**
     * Zustand für Aktivitäten
     */
    dashboard: IInformationEventStateModel;
}

export const INFORMATION_EVENTS_PERSISTENT_STATE_KEYS = [];
