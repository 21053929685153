<h1 mat-dialog-title class="mb-5 cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{'financing.features.financing-processing.householdBills.addNote' | translate }}
</h1>
<div mat-dialog-content class="mb-3">

    <ckeditor [editor]="editor" [(ngModel)]="editorData" (ngModelChange)="onEditorDataChange($event)" [config]="editorConfig" ngDefaultControl name="editorData">
    </ckeditor>
    <div>{{charsRemaining}} / {{maxCharCount}}</div>
    <div *ngIf="blockSaveButton" class="warning-text">
        {{'financing.features.financing-processing.householdBills.warningNote' | translate: {charsRemaining:
        charsRemaining - maxCharCount} }}
    </div>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button color="primary" mat-dialog-close [disabled]="blockSaveButton"
        (click)="saveNote()">{{'financing.features.financing-processing.householdBills.saveDialog' | translate
        }}</button>
    <button mat-raised-button mat-dialog-close>{{'financing.features.financing-processing.householdBills.cancelDialog' |
        translate }}</button>
</div>