import { EnumSettings } from './../enum.settings';

/**
 * Status einer Kreditanfrage in FinProcess
 */
export enum FinancingStatus {
    /**
     * Offen
     */
    Open = 1,

    /**
     * Storniert
     */
    Canceled = 2,

    /**
     * Zurückgestellt
     */
    Rejected = 3,

    /**
     * Rechenbeispiel gesendet
     */
    SampleCalculationWaitingForAcception = 4,

    /**
     * Rechenbeispiel angenommen
     */
    SampleCalculationAccepted = 5,

    /**
     * ESIS gesendet
     */
    EsisWaitingForAcception = 6,

    /**
     * Abgeschlossen
     */
    Completed = 7,
}

export namespace FinancingStatus {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {FinancingStatus | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: FinancingStatus | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'FinancingStatus', FinancingStatus, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('FinancingStatus', plocale);
}

