<div class="dropdown-area" fxLayoutAlign="center center">
  <mat-form-field appearance="fill">
    <mat-label>Kategorie auswählen</mat-label>
    <mat-select matInput (selectionChange)="handleOption($event.value)">
      <mat-option value="Systemdokumente">{{'administration.features.system-documents.systemDocuments' | translate }}</mat-option>
      <mat-option value="Kurzantrag">{{'administration.features.system-documents.shortApplicationDocuments' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<!--listing every uploaded document-->
<div class="document-list" fxLayout="row wrap" fxLayoutGap="10px">
  <ng-container *ngFor="let document of documents">
    <div *ngIf="document.name !== 'fehlendes Dokument'" fxFlex="calc(33% - 50px)" class="document">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div class="document-name">
            <mat-card-title *ngIf="document.name !== ''">{{document.name}} </mat-card-title>
            <mat-card-subtitle>{{'administration.features.system-documents.documentType' | translate }} {{document.typeName}}</mat-card-subtitle>
          </div>
        </mat-card-header>
        <mat-card-content fxLayout="column" fxLayoutAlign="center center">
          <div *ngFor="let documentfile of document.files" fxLayoutGap="10px">
            <img *ngIf="!documentfile.hasThumbnail || !documentfile.base64" (click)="onDocumentClick(documentfile)"
              mat-card-image src="assets/images/document-placeholder.png" alt="Platzhalterdokument">
            <img *ngIf="documentfile.hasThumbnail && !!documentfile.base64"
              [src]="'data:image/jpeg;base64,' + documentfile.base64" (click)="onDocumentClick(documentfile)">
            <mat-card-actions>
              <div class="action-open">
                <button mat-button
                  (click)="onDocumentClick(documentfile, 'show')">{{'administration.features.system-documents.openDocument' |
                  translate }}</button>
                  <mat-icon>open_in_new</mat-icon>
                <button mat-button
                (click)="onDocumentClick(documentfile, 'download')">{{'administration.features.system-documents.downloadDocument' |
                translate }}</button>
              <mat-icon>save_alt</mat-icon>
              </div>
              <div class="action-replace">
                <button mat-button (click)="file.click()" [disabled]="!userCanEdit"> {{'administration.features.system-documents.replaceDocument'
                  |
                  translate }}</button>
                <input type="file" #file [multiple]="true" (change)="uploadFile($event.target, document.type)"
                  [accept]="acceptedFormats.join(',')" style="display: none;" />
                <mat-icon>swap_verts</mat-icon>
              </div>
            </mat-card-actions>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

  <!--listing the placeholders for each empty/not uploaded document -->
  <ng-container *ngFor="let document of documents">
    <div *ngIf="document.name === 'fehlendes Dokument'" fxFlex="calc(33% - 50px)" class="document">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div class="document-name">
            <mat-card-title>{{document.name}}</mat-card-title>
            <mat-card-subtitle>{{'administration.features.system-documents.documentType' | translate }} {{document.typeName}}</mat-card-subtitle>
          </div>
        </mat-card-header>
        <mat-card-content fxLayout="column" fxLayoutAlign="center center">
          <mat-card-actions>
            <button mat-button (click)="file.click()"> {{'administration.features.system-documents.uploadDocument' |
              translate }}</button>
            <input type="file" #file [multiple]="true" (change)="uploadFile($event.target, document.type)"
            [accept]="acceptedFormats" style="display: none;" />
            <mat-icon>cloud_upload</mat-icon>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>