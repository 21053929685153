/**
 * Rollen zur Zugriffssteuerung
 */
export enum Role {
    None = 0,
    FinancingMapsReader = 1,
    FinancingMapsEditor = 1 << 1,
    OrganisationalUnitAdministrator = 1 << 2,
    SalesPartnerCenterAdministrator = 1 << 3,
    BranchAdministrator = 1 << 4,
    ConditionsAdministrator = 1 << 5,
    SystemDocumentsAdministrator = 1 << 6,
    SelectListsAdministrator = 1 << 7,
    RiskParameterizationAdministrator = 1 << 8,
    CalculationAdministrator = 1 << 9,
    SmartDocAdministrator = 1 << 10,
    FilterAccounting = 1 << 11,
    FilterAutomaticallyRejected = 1 << 12,
    LoadBalancingInternal = 1 << 13,
    LoadBalancingExternal = 1 << 14,
    FinancingMapsTemporaryEditor = 1 << 15,
    SmartDoc = 1 << 16,
    FinancingMapsGlobalReader = 1 << 17,
    Expert = 1 << 18,
    Referent = 1 << 19,
    Approver = 1 << 20,
    AdministrationAreaReader = 1 << 21,
    ControlTableDefaultAdministrator = 1 << 22,
    ControlTableUserCompetenceAdministrator = 1 << 23,
    ScoringAdministrator = 1 << 24,
}
