/**
 * Enum für die Art des Dashboards (Experte, Referent und potentiell noch Genehmiger in Zukunft)
 */
export enum DashboardType {
    /**
     * Experte
     */
    Expert,

    /**
     * Referent
     */
    Referent,

    /**
     * Genehmiger
     */
    Approver,
}
