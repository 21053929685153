<h1 mat-dialog-title>{{'financing.features.financing-processing.liabilities.assignLiability.assignLiabilityTitle' | translate }}</h1>
<div mat-dialog-content>
        <div fxLayoutGap="10px" fxLayoutAlign="center center" fxLayout="column wrap">
            <mat-form-field class="fields" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.assignLiability.liabilityType' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedLiabilityType">
                    <mat-option *ngFor="let type of liabilityTypes; let i = index" [value]="type" [matTooltip]="(type | finprocessEnumTranslation:'LiabilityType')!.toString()">
                        {{type | finprocessEnumTranslation:'LiabilityType'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="fields" appearance="fill" >
                <mat-label>{{'financing.features.financing-processing.liabilities.assignLiability.debitor' | translate }}</mat-label>
                <mat-select [(ngModel)]="jointHeadingID">
                    <mat-option *ngFor="let data of jointHeadingList; let i = index" [value]="data.id" [matTooltip]="getDebitorsTooltip(data.debitors)">
                        <span *ngFor="let debitor of data.debitors; let debIndex = index">
                            <span>{{debitor.name}}</span>
                            <span *ngIf="debIndex !== data.debitors.length - 1">,</span>
                            &nbsp;
                        </span>
                    </mat-option>
                    <mat-option (click)="openCreateNewJointHeadingDialog()">
                        <mat-icon>add</mat-icon>
                        {{'financing.features.financing-processing.liabilities.assignLiability.addNewJH' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button mat-dialog-close (click)="assignLiability()">{{'financing.features.financing-processing.liabilities.assignLiability.addNewJHButton' | translate }}</button>
    <button mat-button mat-dialog-close>{{'financing.features.financing-processing.liabilities.assignLiability.cancelNewJHButton' | translate }}</button>
</div>