import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';
import { EnumTranslationPipe, FinancingPremissions } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { HelperService, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import moment from 'moment';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, filter, forkJoin, map, mergeMap, of, skip, startWith, take, takeUntil, tap } from 'rxjs';

import { CreditLine, FinancingService, FinancingState, ICreditLineCalculation, IDebitor, IFinancing, IFixedInterestRateEndDateHelper, ISampleCalculationContainer, InterestMethodVariable, RepaymentPeriod } from './../../../../data';
import { SampleCalculationService } from './../../../../data/services/sample-calculation/sample-calculation.service';
import { IFinancingStateParentDefinition } from './../../../../data/states/state.definition';

interface IFixedRateEndFormData extends IFixedInterestRateEndDateHelper {
    active: boolean;
}

type IFixedInterestRateEndForm = FormGroup<{
    active: FormControl<boolean | null>,
    includedInSummary: FormControl<boolean | null>,
    fixedInterestRate: FormControl<number | null>,
    endDateOfFixedInterestRate: FormControl<string | null>,
    fixedInterestRateInYears: FormControl<number | null>,
}>

/**
 * Komponente für die Bearbeitung der Finanzierung
 */
@Component({
    selector: 'finprocess-sample-calculation',
    templateUrl: './sample-calculation.component.html',
    styleUrls: ['./sample-calculation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class SampleCalculationComponent implements OnDestroy, OnInit {

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Ladezustand der Komponente
     */
    public loading = true;

    /**
     * Speicherzustand für ausstehende Änderungen
     */
    public syncing = false;

    public form?: FormGroup;

    public creditLineEnumArray: number[] = [];

    public selected = new FormControl(0);

    public readonly = true;

    public isEmployeeCase = false;

    public repaymentPeriodSelectItems: Array<{ value: number, label: string }> = [];

    public interestMethodSelectItems: Array<{ value: number, label: string }> = [];

    public currencyMaskOptions: NgxCurrencyConfig;

    public percentageMaskOptions: NgxCurrencyConfig;

    private saveContainer$: Subject<ISampleCalculationContainer> = new Subject<ISampleCalculationContainer>();

    private saveCreditLine$: Subject<ICreditLineCalculation> = new Subject<ICreditLineCalculation>();

    private calculationService = new DebitorCalculationService();

    /**
     * holt Kreditlinie FormGroup
     *
     * @returns {FormGroup[]} Kreditlinie FormGroup
     */
    public get creditLineCalculations(): FormGroup[] {
        return (this.form?.get('creditLineCalculations') as FormArray).controls as FormGroup[];
    }

    /**
     * Standard Konstruktor
     *
     * @param {SampleCalculationService} sampleCalculationService SampleCalculationService-Injektor
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoutes
     * @param {ChangeDetectorRef} cRef ChangeDetector
     * @param {Store} store Store Injector
     * @param {FormBuilder} fb FormBuilder Injector
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {string} locale Locale Code
     * @param {NotificationService} notificationService NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(
        private sampleCalculationService: SampleCalculationService,
        private financingService: FinancingService,
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        private store: Store,
        private fb: FormBuilder,
        private notification: NotificationService,
        private enumTranslate: EnumTranslationPipe,
        private waiterService: WaiterService,
        @Inject(LOCALE_ID) locale: string,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });
        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            allowNegative: true,
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(() => {
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => {
                const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
                if (!financing) {
                    return this.financingService.loadFinancing(id).pipe(map(() => id))
                }

                return of(id);
            }),
            mergeMap(id => forkJoin([this.sampleCalculationService.loadSampleCalculation(id), this.financingService.isEditor$.pipe(take(1))])),
            mergeMap(([sampleCalculationContainer, isEditor]) => {
                const newContainer = this.createForm(sampleCalculationContainer);
                const creditLine = newContainer.creditLineCalculations[0] as Partial<ICreditLineCalculation>;
                delete creditLine.id;
                newContainer.creditLineCalculations = [];

                if (!sampleCalculationContainer && isEditor) {
                    return this.sampleCalculationService.saveSampleCalculation(newContainer).pipe(
                        mergeMap(() => this.sampleCalculationService.addCreditLine(newContainer.id, creditLine as ICreditLineCalculation)),
                        tap(id => (this.form?.get('creditLineCalculations') as FormArray).controls[0].patchValue({ id }, { emitEvent: false, onlySelf: true })),
                    );
                }

                return of(undefined);
            }),
            mergeMap(() => this.financingService.editingReadonlyWithEditmode$.pipe(take(1))),
            tap(readonly => {
                this.readonly = readonly;
                if (readonly) {
                    this.form?.disable({ emitEvent: false });
                }
            }),
        ).subscribe({
            next: () => {
                this.loading = false;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            },
            error: () => {
                this.loading = false;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            },
        });

        this.financingService.editingReadonlyWithEditmode$.pipe(takeUntil(this.onDestroy$)).subscribe(readonly => {
            this.readonly = readonly;

            if (readonly && this.form?.enabled) {
                this.form?.disable({ emitEvent: false });
            } else if (!readonly && this.form?.disabled) {
                this.form?.enable({ emitEvent: false });
            }
        });


        this.creditLineEnumArray = HelperService.getEnumArray(CreditLine, true) as number[];

        this.repaymentPeriodSelectItems = HelperService.getEnumArray(RepaymentPeriod, true).map(value => ({
            value: value as number,
            label: this.enumTranslate.transform(value, 'RepaymentPeriod') as string,
        }));

        this.interestMethodSelectItems = sort(HelperService.getEnumArray(InterestMethodVariable, true).map(value => ({
            value: value as number,
            label: this.enumTranslate.transform(value, 'InterestMethod') as string,
        }))).asc(it => it.label);

        this.saveCreditLine$.pipe(
            takeUntil(this.onDestroy$),
            tap(() => this.setSyncing(true)),
            debounceTime(2000),
            mergeMap(creditLine => this.sampleCalculationService.updateCreditLineCalculation(creditLine, this.form?.get('id')?.value ?? '')),
        ).subscribe(() => {
            this.setSyncing(false);
        });

        this.saveContainer$.pipe(
            takeUntil(this.onDestroy$),
            tap(() => this.setSyncing(true)),
            debounceTime(2000),
            mergeMap(sampleCalculationContainer => this.sampleCalculationService.updateSampleCalculation(sampleCalculationContainer)),
        ).subscribe(() => {
            this.setSyncing(false);
        });

        const financingPermissions = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.financingPermissions);
        this.isEmployeeCase = HelperService.hasBit(financingPermissions, FinancingPremissions.EmployeesSkill);
    }

    /**
     * Lädt alle Rechenbeispiele und Tilgungspläne herunter
     */
    public downloadSampleCalculations(): void {
        const financingId = this.form?.get('id')?.value;

        if (!financingId) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.sampleCalculationService.getAllDocuments(financingId)),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.sample-calculation.sampleCalculationDownloadError'));
            },
            next: async fileContent => {
                this.waiterService.hide();
                if (!!fileContent) {
                    await HelperService.downloadFileFromBlob(fileContent, 'RechenbeispielExport');
                }
            },
        })
    }

    /**
     * die Funktion die eine Form erstellt
     *
     * @param {ISampleCalculationContainer} sampleCalculation Rechenbeispiel
     * @returns {ISampleCalculationContainer} sampleCalculation Rechenbeispiel
     */
    private createForm(sampleCalculation?: ISampleCalculationContainer): ISampleCalculationContainer {
        if (!!sampleCalculation) {
            this.form = this.fb.group({
                id: sampleCalculation.id,
                customerNames: [sampleCalculation.customerNames, Validators.required],
                customerNumber: [sampleCalculation.customerNumber, Validators.required],
                creditLineCalculations: this.fb.array(
                    sampleCalculation.creditLineCalculations.map(creditLine => this.fb.group({
                        id: [creditLine.id],
                        creditLine: [creditLine.creditLine, Validators.required],
                        grossFinancingRequirement: [creditLine.grossFinancingRequirement, Validators.required],
                        assumedDuration: [creditLine.assumedDuration, Validators.required],
                        payoutDate: [creditLine.payoutDate, Validators.required],
                        repaymentPeriod: [creditLine.repaymentPeriod, Validators.required],
                        rateCount: [creditLine.rateCount, Validators.required],
                        gracePeriod: [creditLine.gracePeriod, Validators.required],
                        repaymentStart: [creditLine.repaymentStart, Validators.required],
                        fixedInterestRateEndDateContainerArray: this.getFixedInterestRateEnds(creditLine),
                        referenceInterestRate: [creditLine.referenceInterestRate, [Validators.required, Validators.max(100)]],
                        addition: [creditLine.addition, [Validators.required, Validators.max(100)]],
                        interestRate: [creditLine.interestRate, [Validators.required, Validators.max(100)]],
                        indicatorEuribor: [creditLine.indicatorEuribor, Validators.required],
                        additionComparativeCalculation: [creditLine.additionComparativeCalculation, Validators.required],
                        processingCharges: [creditLine.processingCharges, Validators.required],
                        estimateCharges: [creditLine.estimateCharges, Validators.required],
                        bankAccountFee: [creditLine.bankAccountFee, Validators.required],
                        legalisationFee: [creditLine.legalisationFee, Validators.required],
                        agencyCosts: [creditLine.agencyCosts, Validators.required],
                        landRegisterEntryFee: [creditLine.landRegisterEntryFee, Validators.required],
                        rankingFee: [creditLine.rankingFee, Validators.required],
                        landRegisterExtract: [creditLine.landRegisterExtract, Validators.required],
                        landRegisterRequest: [creditLine.landRegisterRequest, Validators.required],
                    })),
                ),
            });
        } else {
            const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);

            if (!financing) {
                throw new Error('Financing could not be found')
            }

            this.form = this.fb.group({
                id: financing?.id,
                customerNames: [this.getCustomerNames(financing), Validators.required],
                customerNumber: [this.getCustomerNumber(financing), Validators.required],
                creditLineCalculations: this.fb.array([this.initCreditLine(financing, CreditLine.CreditFixedInterestRateWithCoverRegister)]),
            });
        }

        this.form?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((container: ISampleCalculationContainer) => {
            this.saveContainer$.next(container);
        });

        for (const creditLine of this.creditLineCalculations) {
            creditLine.valueChanges.pipe(takeUntil(this.onDestroy$))
                .subscribe(creditLineCalculation => {
                    if (creditLine.valid) {
                        this.saveCreditLine$.next(this.transformCreditLine(creditLineCalculation));
                    }
                });

            this.setDurationAndRepaymentPeriodListeners(creditLine);
            this.setInterestRateListeners(creditLine);
        }

        this.cRef.markForCheck();
        this.cRef.detectChanges();

        const returnValue = this.form.getRawValue();

        for (const creditLineCalculation of returnValue.creditLineCalculations) {
            creditLineCalculation.fixedInterestRateEndDateContainerArray = this.transformFixedInterestRateEnds(creditLineCalculation.fixedInterestRateEndDateContainerArray);
        }

        return returnValue;
    }

    /**
     * Legt eine neue Kreditlinie an
     *
     * @param {CreditLine} creditLine Typ der neuen Kreditlinie
     */
    public addCreditLine(creditLine: CreditLine): void {
        const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);

        if (!financing) {
            return;
        }

        const creditLineCalculationForm = this.initCreditLine(financing, creditLine);
        const creditLineCalculation = creditLineCalculationForm.getRawValue();
        creditLineCalculation.fixedInterestRateEndDateContainerArray = this.transformFixedInterestRateEnds(creditLineCalculation.fixedInterestRateEndDateContainerArray);
        delete creditLineCalculation.id;

        this.setDurationAndRepaymentPeriodListeners(creditLineCalculationForm);
        this.setInterestRateListeners(creditLineCalculationForm);

        this.waiterService.show().pipe(
            mergeMap(() => this.sampleCalculationService.addCreditLine(financing.id, creditLineCalculation)),
        ).subscribe({
            next: id => {
                this.waiterService.hide();
                creditLineCalculationForm.get('id')?.patchValue(id, { emitEvent: false, onlySelf: true });
                (this.form?.get('creditLineCalculations') as FormArray).push(creditLineCalculationForm);
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            },
            error: () => {
                this.waiterService.hide();
                // TODO: Fehlermeldung anzeigen
            },
        });
    }

    /**
     * Löscht eine Kreditline
     *
     * @param {number} index Index der Kreditlinie
     */
    public removeCreditLine(index: number): void {
        const creditLineId = this.creditLineCalculations[index].get('id')?.value;
        const financingId = this.form?.get('id')?.value;

        if (!creditLineId || !financingId) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.sampleCalculationService.deleteCreditLineCalculation(financingId, creditLineId)),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                const creditLineCalculations = this.form?.get('creditLineCalculations') as FormArray;
                creditLineCalculations.removeAt(index, { emitEvent: false });
                this.selected.setValue(0);
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            },
            error: () => {
                this.waiterService.hide();
                // TODO: Fehler anzeigen
            },
        })
    }

    /**
     * Initializiert FormGroup
     *
     * @param {IFinancing} financing Financierung
     * @param {CreditLine} creditLine CreditLine
     * @param {string} creditLinieId creditLinie id
     * @returns {FormGroup} FormGroup
     */
    private initCreditLine(financing: IFinancing, creditLine: CreditLine, creditLinieId?: string): FormGroup {
        const firstDayOfNextMonth = moment(this.getDayOfNextMonth(1));

        const defaultReferenceInterestRate = this.store.selectSnapshot(SystemConfigurationState.getSystemConfiguration)(SystemConfigurationType.DefaultReferenceInterestRate) as number;
        const defaultAddition = this.store.selectSnapshot(SystemConfigurationState.getSystemConfiguration)(SystemConfigurationType.DefaultAddition) as number;
        const additionComparativeCalculation = this.store.selectSnapshot(SystemConfigurationState.getSystemConfiguration)(SystemConfigurationType.AdditionComparativeCalculation) as number;

        const hasComparativeCalculation = creditLine === CreditLine.CreditFixedInterestRateWithCoverRegister;
        const isFixed = [
            CreditLine.ComplementaryCreditFixedInterestRate,
            CreditLine.CreditFixedInterestRateWithCoverRegister,
            CreditLine.CreditFixedInterestRateWithoutCoverRegister,
        ].includes(creditLine);

        const formGroup = this.fb.group({
            id: creditLinieId, // TODO: Nach Abspeichern bekommen wir die ID erst vom Backend
            creditLine: [creditLine, Validators.required],
            grossFinancingRequirement: [this.store.selectSnapshot((FinancingState.grossFinancingRequirement))(true), Validators.required],
            assumedDuration: [financing.assumedDuration ?? 0, Validators.required],
            payoutDate: [firstDayOfNextMonth.toISOString(), Validators.required],
            repaymentPeriod: [RepaymentPeriod.Monthly, Validators.required],
            rateCount: [financing.assumedDuration / RepaymentPeriod.Monthly, Validators.required],
            gracePeriod: [0, Validators.required],
            repaymentDay: [1, Validators.required], // TODO: In ein Feld zusammenfassen
            repaymentStart: [moment.utc(firstDayOfNextMonth).add(1, 'month').toISOString(), Validators.required],
            fixedInterestRateEndDateContainerArray: isFixed ? this.getFixedInterestRateEnds() : this.fb.array([]),
            referenceInterestRate: [defaultReferenceInterestRate, [Validators.required, Validators.max(100)]],
            addition: [defaultAddition, [Validators.required, Validators.max(100)]],
            interestRate: [defaultReferenceInterestRate + defaultAddition, [Validators.required, Validators.max(100)]],
            indicatorEuribor: [InterestMethodVariable.VariableThreeMo, Validators.required],
            additionComparativeCalculation: hasComparativeCalculation ? [additionComparativeCalculation, Validators.required] : null,
            processingCharges: [this.store.selectSnapshot(FinancingState.processingCharges)(true), Validators.required],
            estimateCharges: [this.store.selectSnapshot(FinancingState.estimateCharges)(true), Validators.required],
            bankAccountFee: [financing.financingConfiguration.bankAccountFee, Validators.required],
            legalisationFee: [this.store.selectSnapshot(FinancingState.legalisationFee)(true), Validators.required],
            agencyCosts: [0, Validators.required],
            landRegisterEntryFee: [this.store.selectSnapshot(FinancingState.landregisterEntry)(true), Validators.required],
            rankingFee: [0, Validators.required],
            landRegisterExtract: [financing.financingConfiguration.landRegisterExtract, Validators.required],
            landRegisterRequest: [financing.financingConfiguration.landRegisterRequest, Validators.required],
        });

        return formGroup;
    }

    /**
     * Gibt den gegebenen Tag des nächsten Monats zurück
     *
     * @param {number} day den Tag zu holen
     * @returns {string} Datum
     */
    private getDayOfNextMonth(day: number): string {
        return moment.utc().add(1, 'month').startOf('month').add(day - 1, 'days').startOf('day').toISOString();
    }

    /**
     * fetch die Namen zusammen
     *
     * @param {IFinancing} financing Financierung
     * @returns {string} namen
     */
    private getCustomerNames(financing: IFinancing): string {
        const allDebitors = financing.households.reduce((debitors, currentHousehold) => debitors.concat(currentHousehold.debitors), [] as IDebitor[]);
        return sort(allDebitors).desc(debitor => this.calculationService.netIncomeTotal({ netIncome: debitor.netIncome, fourteenSalariesPerYear: debitor.fourteenSalariesPerYear, otherIncome: debitor.otherIncome })).map(deb => `${deb.firstName} ${deb.lastName}`).join(' und ');
    }

    /**
     * holt die Debitor nummer
     *
     * @param {IFinancing} financing Financierung
     * @returns {string} Debitor nummer
     */
    private getCustomerNumber(financing: IFinancing): string {
        const allDebitors = financing.households.reduce((debitors, currentHousehold) => debitors.concat(currentHousehold.debitors), [] as IDebitor[]);

        if (allDebitors.length > 1) {
            return financing.communityCustomerNumber ?? '';
        }

        return allDebitors[0].customerNumber ?? '';
    }

    /**
     * Hilfsfunktion für Template, um die FormControls für die Laufzeiten zu erhalten
     *
     * @param {FormGroup} form Formular
     * @returns {FormGroup[]} FormControls für die Laufzeiten
     */
    public getFixedRateEndControls(form: FormGroup): FormGroup[] {
        return (form.get('fixedInterestRateEndDateContainerArray') as FormArray).controls as FormGroup[];
    }

    /**
     * Hilfsfunktion um das Formular für die Laufzeitenden aus dem ViewModel zu generieren
     *
     * @param {ICreditLineCalculation} creditLineCalculation Kreditlinie
     * @returns {FormArray} FormArray welches die Laufzeitenden enthält
     */
    private getFixedInterestRateEnds(creditLineCalculation?: ICreditLineCalculation): FormArray {

        const fbArray: FormArray<IFixedInterestRateEndForm> = this.fb.array([
            this.fb.group({ active: false, includedInSummary: false, fixedInterestRate: [{ value: null, disabled: true }], endDateOfFixedInterestRate: null, fixedInterestRateInYears: 5 }) as IFixedInterestRateEndForm,
            this.fb.group({ active: false, includedInSummary: false, fixedInterestRate: [{ value: null, disabled: true }], endDateOfFixedInterestRate: null, fixedInterestRateInYears: 10 }) as IFixedInterestRateEndForm,
            this.fb.group({ active: false, includedInSummary: false, fixedInterestRate: [{ value: null, disabled: true }], endDateOfFixedInterestRate: null, fixedInterestRateInYears: 15 }) as IFixedInterestRateEndForm,
            this.fb.group({ active: false, includedInSummary: false, fixedInterestRate: [{ value: null, disabled: true }], endDateOfFixedInterestRate: null, fixedInterestRateInYears: 20 }) as IFixedInterestRateEndForm,
            this.fb.group({ active: false, includedInSummary: false, fixedInterestRate: [{ value: null, disabled: true }], endDateOfFixedInterestRate: null, fixedInterestRateInYears: 25 }) as IFixedInterestRateEndForm,
        ]);

        if (!creditLineCalculation || !Array.isArray(creditLineCalculation.fixedInterestRateEndDateContainerArray)) {
            return fbArray;
        }

        for (const item of fbArray.controls) {
            const existing = creditLineCalculation.fixedInterestRateEndDateContainerArray?.find(it => it.fixedInterestRateInYears === item.get('fixedInterestRateInYears')?.value);

            if (existing) {
                const fixedInterestRate = item.get('fixedInterestRate') as FormControl;
                item.get('active')?.patchValue(true, { emitEvent: false, onlySelf: true });
                item.get('includedInSummary')?.patchValue(existing.includedInSummary, { emitEvent: false, onlySelf: true });
                item.get('endDateOfFixedInterestRate')?.patchValue(existing.endDateOfFixedInterestRate, { emitEvent: false, onlySelf: true });
                fixedInterestRate.patchValue(existing.fixedInterestRate, { emitEvent: false, onlySelf: true });
                fixedInterestRate.enable();
                fixedInterestRate.setValidators([Validators.required, Validators.max(100)]);
            }
        }

        const customEnds = sort(creditLineCalculation.fixedInterestRateEndDateContainerArray?.filter(element => !element.fixedInterestRateInYears)).asc(it => it.endDateOfFixedInterestRate);

        for (const customFixedRateEnd of customEnds) {
            fbArray.push(this.fb.group({
                active: true,
                includedInSummary: customFixedRateEnd.includedInSummary,
                endDateOfFixedInterestRate: customFixedRateEnd.endDateOfFixedInterestRate,
                fixedInterestRate: [customFixedRateEnd.fixedInterestRate, Validators.required],
                fixedInterestRateInYears: null,
            }) as IFixedInterestRateEndForm);
        }

        this.setFixedRateEndListeners(fbArray);

        return fbArray;
    }

    /**
     * Transformiert die Forumulardaten für Fixzinsslaufzeiten zurück in das ViewModel
     *
     * @param {IFixedRateEndFormData[]} fixedInterestRateEnds Formulardaten für Laufzeiten
     * @returns {IFixedInterestRateEndDateHelper[]} Laufzeiten im ViewModel Format
     */
    private transformFixedInterestRateEnds(fixedInterestRateEnds: IFixedRateEndFormData[]): IFixedInterestRateEndDateHelper[] {
        return fixedInterestRateEnds.filter(it => it.active && !!it.fixedInterestRate).map(it => ({
            includedInSummary: it.includedInSummary,
            fixedInterestRateInYears: it.fixedInterestRateInYears,
            fixedInterestRate: it.fixedInterestRate,
            endDateOfFixedInterestRate: moment.utc().add(1, 'month').startOf('month').add(it.fixedInterestRateInYears ?? 0, 'years').toISOString(),
        }));
    }

    /**
     * Transformiert die Formulardaten für eine Kreditlinie in das ViewModel
     *
     * @param {any} creditLineCalculationFormData Formulardaten
     * @returns {ICreditLineCalculation} Kreditlinie
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private transformCreditLine(creditLineCalculationFormData: any): ICreditLineCalculation {
        creditLineCalculationFormData.fixedInterestRateEndDateContainerArray = this.transformFixedInterestRateEnds(creditLineCalculationFormData.fixedInterestRateEndDateContainerArray);

        return creditLineCalculationFormData;
    }

    /**
     * Zeigt einen Dialog zum Bestätigen des Löschens an
     *
     * @param {number} index Index des Tabs
     */
    public removeTabDialog(index: number): void {

        this.notification.confirmYesNo(
            'Berechnung Löschen',
            'wollen sie wirklich eine Kreditlinie löschen ?',
        ).subscribe(result => {
            if (result === 'submit') {
                this.removeCreditLine(index)
            }
        });
    }

    /**
     * Setzt die ChangeListener für Laufzeit und Rückzahlungsperiode
     * und aktualisiert tilgungsfreien Zeitraum sowie Anzahl der Raten
     *
     * @param {FormGroup} creditLineCalculation FormGroup eines CreditLineCalculation Objekts
     */
    private setDurationAndRepaymentPeriodListeners(creditLineCalculation: FormGroup): void {
        const assumedDuration = creditLineCalculation.get('assumedDuration') as FormControl;
        const repaymentPeriod = creditLineCalculation.get('repaymentPeriod') as FormControl;
        const rateCount = creditLineCalculation.get('rateCount') as FormControl;
        const gracePeriod = creditLineCalculation.get('gracePeriod') as FormControl;
        const payoutDate = creditLineCalculation.get('payoutDate') as FormControl;
        const repaymentStart = creditLineCalculation.get('repaymentStart') as FormControl;

        combineLatest([
            assumedDuration.valueChanges.pipe(startWith(assumedDuration.value)),
            repaymentPeriod.valueChanges.pipe(startWith(repaymentPeriod.value)),
        ]).pipe(takeUntil(this.onDestroy$), debounceTime(1000), skip(1)).subscribe(([assumedDurationValue, repaymentPeriodValue]) => {
            const newRateCount = Math.floor(assumedDurationValue / repaymentPeriodValue);

            rateCount.setValue(newRateCount, { onlySelf: true, emitEvent: false });
            gracePeriod.setValue(assumedDurationValue - newRateCount * repaymentPeriodValue, { onlySelf: true, emitEvent: false });
            repaymentStart.setValue(moment.utc(payoutDate.value).add(1, 'month').startOf('month').add(gracePeriod.value, 'months'), { onlySelf: true, emitEvent: false });

            if (creditLineCalculation.valid) {
                this.saveCreditLine$.next(this.transformCreditLine(creditLineCalculation.getRawValue()));
            }

            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        rateCount.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(1000)).subscribe(rateCountValue => {
            gracePeriod.setValue(assumedDuration.value - rateCountValue * repaymentPeriod.value, { onlySelf: true, emitEvent: false });
            if (payoutDate.valid) {
                repaymentStart.setValue(moment.utc(payoutDate.value).add(1, 'month').startOf('month').add(gracePeriod.value, 'months'), { onlySelf: true, emitEvent: false });
            }

            if (creditLineCalculation.valid) {
                this.saveCreditLine$.next(this.transformCreditLine(creditLineCalculation.getRawValue()));
            }
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        payoutDate.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(1000)).subscribe(payoutDateValue => {
            if (payoutDate.valid) {
                repaymentStart.setValue(moment.utc(payoutDateValue).add(1, 'month').startOf('month').add(gracePeriod.value, 'months'), { onlySelf: true, emitEvent: false });
            }

            if (creditLineCalculation.valid) {
                this.saveCreditLine$.next(this.transformCreditLine(creditLineCalculation.getRawValue()));
            }
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });
    }

    /**
     * Setzt die ChangeListener für Referenzzinssatz und Aufschlag
     *
     * @param {FormGroup} creditLineCalculation FormGroup eines CreditLineCalculation Objekts
     */
    private setInterestRateListeners(creditLineCalculation: FormGroup): void {
        const referenceInterestRate = creditLineCalculation.get('referenceInterestRate') as FormControl;
        const addition = creditLineCalculation.get('addition') as FormControl;
        const interestRate = creditLineCalculation.get('interestRate') as FormControl;

        combineLatest([
            referenceInterestRate.valueChanges.pipe(startWith(referenceInterestRate.value)),
            addition.valueChanges.pipe(startWith(addition.value)),
        ]).pipe(takeUntil(this.onDestroy$), skip(1)).subscribe(([referenceInterestRateValue, additionValue]) => {
            interestRate.setValue(referenceInterestRateValue + additionValue, { onlySelf: true, emitEvent: false });

            if (creditLineCalculation.valid && referenceInterestRate.valid && addition.valid && interestRate.valid) {
                this.saveCreditLine$.next(this.transformCreditLine(creditLineCalculation.getRawValue()));
            }
        });
    }

    /**
     * Setzt die ChangeListener für die Laufzeitenden, welche Komponenten deaktivieren und Validatoren setzen
     *
     * @param {FormArray} fixedRateEndFormArray FormArray der Laufzeitenden
     */
    private setFixedRateEndListeners(fixedRateEndFormArray: FormArray): void {
        for (const control of fixedRateEndFormArray.controls as FormGroup[]) {
            const activeControl = control.get('active') as FormControl;
            const fixedInterestRateControl = control.get('fixedInterestRate') as FormControl;

            activeControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(active => {
                if (active) {
                    fixedInterestRateControl.enable();
                    fixedInterestRateControl.setValidators([Validators.required, Validators.max(100)]);
                    fixedInterestRateControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                } else {
                    fixedInterestRateControl.disable();
                    fixedInterestRateControl.setValidators(null);
                    fixedInterestRateControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                }
            });
        }
    }

    /**
     * Setzt den Ladezustand und aktualisiert das UI
     *
     * @param {boolean} syncing Ladezustand
     */
    private setSyncing(syncing: boolean) {
        if (syncing !== this.syncing) {
            this.syncing = syncing;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        }
    }
}
