import { EnumSettings } from './../../enum.settings';
import { OutsideFacilities as BafOutsideFacilities } from './baf-outside-facilities.enum';

/**
 * [MEHRFACHAUSWAHL] Ausstattungsmerkmale Außenanlagen
 */
export enum OutsideFacilities {
    /**
     * Keine
     */
    None = 0,

    /**
     * Einfriedung aus hochwertigen Elementen (Edelstahl, etc.)
     */
    HighQualityFence = 1,

    /**
     * Schwimmbad oder Schwimmteich
     */
    SwimmingPool = 2,

    /**
     * Aufwendige Gartengestaltung (Wege, Beleuchtung, Bepflanzung, etc.)
     */
    ElaborateGardenArchitecture = 4,

    /**
     * Hof: nicht öffentlicher Gemeinschaftsgarten/Spielplatz für Hausbewohner
     */
    CommonGarden = 8,

    /**
     * Carport
     */
    Carport = 16,
}

export namespace OutsideFacilities {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {OutsideFacilities | BafOutsideFacilities | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: OutsideFacilities | BafOutsideFacilities | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'OutsideFacilities', Object.assign(OutsideFacilities, BafOutsideFacilities), plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('OutsideFacilities', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {OutsideFacilities | BafOutsideFacilities | undefined | null} value Enum-Wert
     * @param {OutsideFacilities | BafOutsideFacilities} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: OutsideFacilities | BafOutsideFacilities | undefined | null, flag: OutsideFacilities | BafOutsideFacilities): boolean | undefined => EnumSettings.hasFlag(value, flag);
}

