import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';

import { AuthorizationService } from '../../../../data';
import { IRoleData } from '../../interfaces';

/**
 * Guard für benötigte Rollen
 */
@Injectable()
export class AuthorizationGuard {

    /**
     * Konstruktor
     *
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {NotificationService} notificationService NotificationService-Injektor
     * @param {TranslateService} translateService TranslateService-Injektor
     */
    public constructor(private authorizationService: AuthorizationService, private notificationService: NotificationService, private translateService: TranslateService) {

    }

    /**
     * Stellt sicher, dass die Route nur mit den notwendigen Rollen erreicht werden kann
     *
     * @param {ActivatedRouteSnapshot} route Zu aktivierende Route
     * @returns {boolean} Kann Route aktiviert werden
     */
    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const data = route.data as IRoleData;
        return this.checkRoles(data);
    }

    /**
     * Stellt sicher, dass die Kindrouten nur mit den notwendigen Rollen erreicht werden können
     *
     * @param {ActivatedRouteSnapshot} childRoute Zu aktivierende Route
     * @returns {boolean} Kann Route aktiviert werden
     */
    public canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
        const data = childRoute.parent?.data as IRoleData | undefined;
        return this.checkRoles(data);
    }

    /**
     * Stellt sicher, dass ein Modul nur mit den notwendigen Rollen geladen werden kann
     *
     * @param {Route} route Zu aktivierende Route
     * @returns {boolean} Modul kann geladen werden
     */
    public canLoad(route: Route): boolean {
        const data = route.data as IRoleData | undefined;
        return this.checkRoles(data);
    }

    /**
     * Prüft notwendige Rollen des Nutzers
     *
     * @param {IRoleData | undefined} data Rollendaten
     * @returns {boolean} Hat Nutzer notwendige Rollen
     */
    private checkRoles(data?: IRoleData): boolean {
        if (data === undefined) {
            return true;
        }
        let result = true;
        if (data.needOnlyOne === true) {
            result = this.authorizationService.hasOneRoleOf(data.roles);
        } else {
            result = this.authorizationService.hasRole(data.roles);
        }

        if (result === false) {
            this.notificationService.alert(this.translateService.instant('auth.features.checks.missinRoleDialogTitle'), this.translateService.instant('auth.features.checks.missingRoleDialogText'));
        }

        return result;
    }

}
