/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidatorFn } from '@angular/forms';

/**
 * A token to be used for validation to give type interference to interfaces
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FormProviderToken<T> {
    public readonly key: string;

    /**
     * Default constructor
     * 
     * @param {string} key key
     */
    public constructor(key: string) {
        this.key = key;
    }
}

// Helper type to extract the type from a token
export type TypeFromProvider<X> = X extends FormProviderToken<infer T> ? T : never;
export type TypeFromMultiProvider<X> = X extends IProviderWithMulti<infer T> ? T : never;
//type TypeFromProvider<X> = X extends FormProviderToken<infer T> ? T : X extends IProviderWithMulti<infer T> ? T[] : never;


export interface IProviderWithMulti<T> {
    token: FormProviderToken<T>;
    multi?: boolean;
}

export type InferFromArray<T> = T extends Array<infer U> ? U : never;

// type ProviderInput<T extends Array<FormProviderToken<any>>> = FormProviderToken<InferFromArray<T>> | T | IProviderWithMulti<InferFromArray<T>> | IProviderWithMulti<InferFromArray<T>>[];

// Define the interface
// export interface IProviderFunction<T extends Array<FormProviderToken<any>>, R> {
//     providers: [...T] | InferFromArray<T> | IProviderWithMulti<InferFromArray<T>> | IProviderWithMulti<InferFromArray<T>>[];
//     fn: (...args: { [K in keyof T]: TypeFromProvider<T[K]> }) => R;
// }

export interface IProviderFunctionArray<T extends Array<FormProviderToken<any>>, R> {
    providers: [...T],
    fn: (...args: { [K in keyof T]: TypeFromProvider<T[K]> }) => R;
}

export interface IProviderFunctionSingle<T extends FormProviderToken<T>, R> {
    providers: T,
    fn: (...args: TypeFromProvider<T>[]) => R;
}

export interface IProviderFunctionMulti<T extends IProviderWithMulti<any>, R> {
    providers: T,
    fn: (...args: TypeFromMultiProvider<T>[]) => R;
}

export interface IProviderFunctionMultiArray<T extends Array<IProviderWithMulti<any>>, R> {
    providers: [...T],
    fn: (...args: { [K in keyof T]: TypeFromMultiProvider<T[K]> }) => R;
}

export type IProviderSingle<T extends FormProviderToken<any>, R> = IProviderFunctionSingle<T, R> | IProviderFunctionMulti<IProviderWithMulti<TypeFromProvider<T>>, R>;
export type IProviderArrayLike<T extends Array<FormProviderToken<any>>, R> = IProviderFunctionArray<T, R> | IProviderFunctionMultiArray<IProviderWithMulti<TypeFromProvider<T>>[], R>;

export type ProviderType = Array<FormProviderToken<any>> | FormProviderToken<any>;

//export type ProviderInput<T extends Array<FormProviderToken<any>> | FormProviderToken<any>, R> = T extends any[] ? IProviderArrayLike<T, R> : IProviderSingle<T, R>;

export type ProviderInput<T, R> = T extends Array<FormProviderToken<any>> ? IProviderArrayLike<T, R> : T extends FormProviderToken<any> ? IProviderSingle<T, R> : never;

//export type ProviderInput<T extends FormProviderToken<any> | Array<FormProviderToken<any>>, R> = IProviderFunctionArray<T extends any[] ? T : T[], R> | IProviderFunctionSingle<T extends any[] ? never : T, R> | IProviderFunctionMulti<IProviderWithMulti<TypeFromProvider<T>>, R> | IProviderFunctionMultiArray<IProviderWithMulti<TypeFromProvider<T>>[], R>;
// export type ProviderInput<T extends FormProviderToken<any> | Array<FormProviderToken<any>>, R> = IProviderFunctionArray<T extends any[] ? T : T[], R> |
//     IProviderFunctionSingle<T extends any[] ? never : T, R> |
//     IProviderFunctionMulti<IProviderWithMulti<TypeFromProvider<T extends any[] ? never : T>>, R> |
//     IProviderFunctionMultiArray<T extends any[] ? IProviderWithMulti<TypeFromProvider<InferFromArray<T>>>[] : never, R>;


// export interface IValidationOptions<T extends FormProviderToken<any>[]> {
//     visible?: IProviderFunction<T, boolean>;
//     validator?: IProviderFunction<T, ValidatorFn | null> | ValidatorFn | null;
//     default?: IProviderFunction<T, any>;
//     alternativTranslationKey?: IProviderFunction<T, string | null>;
//     removeIfEmpty?: boolean;
// }

// export interface IValidationOptions<T extends FormProviderToken<any> | Array<FormProviderToken<any>>> {
//     visible?: ProviderInput<T, boolean>;
//     validator?: ProviderInput<T, ValidatorFn | null> | ValidatorFn | null;
//     default?: ProviderInput<T, any>;
//     alternativTranslationKey?: ProviderInput<T, string | null>;
//     removeIfEmpty?: boolean;
// }

export interface IValidationOptions<vis extends FormProviderToken<any> | Array<FormProviderToken<any>>, val extends FormProviderToken<any> | Array<FormProviderToken<any>>, def extends FormProviderToken<any> | Array<FormProviderToken<any>>, alt extends FormProviderToken<any> | Array<FormProviderToken<any>>> {
    visible?: ProviderInput<vis, boolean>;
    validator?: ProviderInput<val, ValidatorFn | null> | ValidatorFn | null;
    default?: ProviderInput<def, any>;
    alternativTranslationKey?: ProviderInput<alt, string | null>;
    removeIfEmpty?: boolean;
}

// export interface IValidationOptions<vis extends ProviderInput<any, boolean>, val extends ProviderInput<any, ValidatorFn | null>, def extends ProviderInput<any, any>, alt extends ProviderInput<any, string | null>> {
//     visible?: vis;
//     validator?: val | ValidatorFn | null;
//     default?: def;
//     alternativTranslationKey?: alt;
//     removeIfEmpty?: boolean;
// }

// export type CreateFormGroupOptions<T, R extends FormProviderToken<any> | Array<FormProviderToken<any>>> = { 
//     [P in keyof T]-?: IValidationOptions<R> | null;
// }

// export type CreateFormGroupOptions<T, R extends FormProviderToken<any> | Array<FormProviderToken<any>>> = { 
//     [P in keyof T]-?: IValidationOptions<R> | null;
// }

export type CreateFormGroupOptions<T> = { 
    [P in keyof T]-?: IValidationOptions<any, any, any, any> | null;
}

