export enum OrderType{
    /**
     * Mit Geldfluss
     */
    CashFlow = 1,

    /**
     * Einverleibung Eigentumsrecht
     */
    IncorporateOwnership = 2,

    /**
     * Einverleibung Hypothek
     */
    IncorporateMortgage = 3
}
