import { ChangeDetectorRef, Directive, ElementRef, Inject, Input } from '@angular/core';
import { TranslateDirective } from '@ngx-translate/core';

import { IEnumParameter } from '../../interfaces/enum-parameter.interface';
import { EnumTranslationService } from '../../providers/enum-translation/enum-translation.service';
import { Helper } from '../../utils/helper';

import { UnionValueSingleType } from './../../utils/union-value-type';

/**
 * Direktive zum Übersetzen von Enum-Werten
 */
@Directive({
    selector: '[finprocessEnumTranslation]',
})
export class EnumTranslationDirective extends TranslateDirective {

    /**
     * Schlüssel für Enum-Übersetzung als Objekt
     */
    @Input()
    public set translateObject(ob: IEnumParameter | undefined | null) {
        if (ob !== undefined && ob !== null) {
            this.key = Helper.getKeyByEnum(ob) as string;
            this.checkNodes();
        }
    }

    /**
     * Konstruktor
     *
     * @param {EnumTranslationService} translateService EnumTranslationService-Injektor
     * @param {ElementRef} element ElementRef-Injektor
     * @param {ChangeDetectorRef} ref ChangeDetectorRef-Injektor
     */
    public constructor(
        translateService: EnumTranslationService,
        @Inject(ElementRef) element: ElementRef,
        @Inject(ChangeDetectorRef) ref: ChangeDetectorRef) {
        super(translateService, element, ref);
    }

    /**
     * Aktualisiert einen übersetzen Wert
     *
     * @param {UnionValueSingleType} key Parameter zum Ermitteln des Übersetzungsschlüssels
     * @param {any} node Knoten in JSON-Übersetzungsobjekt
     * @param {any} translations Übersetzungen
     */
    public updateValue(key: UnionValueSingleType, node: unknown, translations: unknown): void {
        super.updateValue(Helper.getKeyByEnum(key) as string, node, translations);
    }

}
