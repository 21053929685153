export enum TypeOfFinancedObject {
    /**
     * Reihenhaus
     */
    SemidetachedHouse,

    /**
     * Ausbau
     */
    Extension,

    /**
     * Einfamilienhaus
     */
    OneFamilyHouse,

    /**
     * Eigentumswohnung
     */
    Condominium,

    /**
     * Cooperative Shares
     */
    CooperativeShares,

    /**
     * RealEstate
     */
    RealEstate,

    /**
     * Mehrfamilienhaus
     */
    MultifamilyResidence,

    /**
     * Einrichtung
     */
    Furniture,

    /**
     * Renovierung
     */
    Renovation,

    /**
     * Sonstiges
     */
    Miscellaneous,

    /**
     * Technische Ausstattung
     */
    TechnicalFacilities,

    /**
     * Vorsorgewohnung
     */
    ProvidentFlat,

    /**
     * Kleingarten in Pacht
     */
    GardenPlotRented,
}
