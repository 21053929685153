import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Logout } from 'app/modules/auth/data';
import { Subject, Subscription, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

import { UUID } from '../util';

/**
 * SharedWindows service
 */
@Injectable({
    providedIn: 'root',
})
export class WindowsService implements OnDestroy {
    /**
     * Ein Array von alle windows die geöffnet sind
     *  
     */ 
    private allWindows: Array<Window | null> = [];

    private messageWindowSubscription?: Subscription;

    private onDestroy$: Subject<void> = new Subject();
    
    /**
     * Constructor
     *
     * @param {Actions} actions Actions
     * @param {Router} router Router-Injector
     */
    public constructor(
        private actions: Actions,
        private router: Router,
    ) {
        fromEvent(window, 'beforeunload').pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.closeAllWindows();
        });

        this.actions.pipe(
            ofActionDispatched(Logout),
            takeUntil(this.onDestroy$),
        ).subscribe(() => this.closeAllWindows());
    }

    /**
     * Subscriptions beenden
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Öffnet ein Fenster mit der Nachrichtenseite
     * 
     * @param {UUID} containerId ID des Containers
     */
    public openMessagesWindow(containerId: UUID): void {
        const window = this.openWindow('messagesWindow', `financing/${containerId}/messages`, 'location=yes,height=800,width=750,scrollbars=yes,status=yes');
        this.updateUrl(containerId, window);

        this.messageWindowSubscription?.unsubscribe();

        this.messageWindowSubscription = this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map(event => {
                const regex = /financing\/([\d\w]{8}-[\d\w]{4}-[\d\w]{4}-[\d\w]{4}-[\d\w]{12})/;
                const match = event.url.match(regex);

                if (match && match[1]) {
                    return match[1];
                }

                return undefined;
            }),
            filter((financingContainerId): financingContainerId is string => financingContainerId !== undefined),
            distinctUntilChanged(),
            takeUntil(this.onDestroy$),
        ).subscribe(financingContainerId => {
            this.updateUrl(financingContainerId, window);
        });
    }

    /**
     * Öffnet ein window
     * 
     * @param { string} nameOrTarget Window target or name 
     * @param { string} url Window url 
     * @param { string} features Window features
     * @returns { Window } window 
     */
    public openWindow(nameOrTarget: string, url?: string | undefined, features?: string | undefined): Window | null {
        const existingWindow = this.allWindows.find(w => w?.name.toLowerCase() === nameOrTarget.toLocaleLowerCase() && !window.closed);
        if (!!existingWindow) {
            existingWindow?.focus()
            return existingWindow;
        }
        
        const newWindow = window.open(url, nameOrTarget, features);
        this.allWindows.push(newWindow);
        newWindow?.focus();
        return newWindow;
    }

    /**
     *  Schließt Fenster, falls vorhanden
     * 
     * @param {string} name Fenster Name
     */
    public closeWindow(name: string): void {
        const existingWindow = this.allWindows.find(w => w?.name.toLowerCase() === name.toLocaleLowerCase() && !window.closed);
        existingWindow?.close();
        this.allWindows = this.allWindows.filter(w => w !== existingWindow);
    }

    /**
     * Schließt alle offenen
     */
    private closeAllWindows(): void {
        this.allWindows.forEach(window => {
            window?.close();
        });

        this.allWindows = [];
    }

    /**
     * Aktualisiert die URL eines Fensters, wenn sie sich geändert hat
     * 
     * @param {string} containerId ID des Containers
     * @param {Window | null} window Window Referenz
     */
    private updateUrl(containerId: string, window?: Window | null): void {
        const url = `${document.baseURI}financing/${containerId}/messages`;

        if (!!window && !window.closed && window.location.href !== url) {
            window.location.href = url;
        }
    }
}
