<div *ngIf="!loading">
    <div *ngIf="!!form" [formGroup]="form" fxLayout="column">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
                <finprocess-sub-header
                    [label]="'financing.features.financing-processing.sample-calculation.masterData' |translate">
                </finprocess-sub-header>
                <mat-form-field appearance="fill">
                    <mat-label>{{'financing.features.financing-processing.sample-calculation.customerNames' |translate}}
                    </mat-label>
                    <input matInput formControlName="customerNames">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{'financing.features.financing-processing.sample-calculation.customerNumber'|translate}}
                    </mat-label>
                    <input matInput formControlName="customerNumber">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100">
                    <finprocess-sub-header
                        [label]="'financing.features.financing-processing.sample-calculation.calculation' |translate">
                    </finprocess-sub-header>

                    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
                        <mat-tab *ngFor="let creditLine of creditLineCalculations; let index = index">
                            <ng-template mat-tab-label class="mat-tab-label">{{creditLine.get('creditLine')?.value |
                                finprocessEnumTranslation:'CreditLine'}}
                                <!-- <button mat-icon-button class="delete-tab-button" (click)="removeTabDialog(index)">
                                    <mat-icon class="mat-icon-delete">close</mat-icon>
                                </button> -->
                            </ng-template>
                            <div class="tab-content">
                                <div [formGroup]="creditLine" fxLayout="column">
                                    <div fxLayout="row" fxLayoutGap="20px">
                                        <div fxLayout="column" fxFlex="30">
                                            <finprocess-sub-header
                                                [label]="'financing.features.financing-processing.sample-calculation.creditRequest' |translate">
                                            </finprocess-sub-header>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.grossFinancingRequirement'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="grossFinancingRequirement" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.assumedDuration'|translate}}
                                                </mat-label>
                                                <input matInput type="number" formControlName="assumedDuration">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.payoutDate'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="payoutDate"
                                                    [matDatepicker]="pickerPayoutDate"
                                                    (focus)="pickerPayoutDate.open()">
                                                <mat-datepicker-toggle matSuffix [for]="pickerPayoutDate">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerPayoutDate></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.repaymentPeriod'|translate}}
                                                </mat-label>
                                                <mat-select formControlName="repaymentPeriod">
                                                    <mat-option *ngFor="let item of repaymentPeriodSelectItems"
                                                        [value]="item.value">{{item.label}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.rateCount'|translate}}
                                                </mat-label>
                                                <input matInput type="number" formControlName="rateCount">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.gracePeriod'|
                                                    translate}}</mat-label>
                                                <input matInput type="number" readonly formControlName="gracePeriod">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.repaymentStart'|translate}}
                                                </mat-label>
                                                <input matInput [disabled]="readonly"
                                                    [value]="creditLine.get('repaymentStart')?.value | date: 'dd.MM.yyyy'">
                                            </mat-form-field>
                                            <!-- <mat-form-field appearance="fill">
                                                <mat-label>{{'financing.features.financing-processing.sample-calculation.fixedInterestRateEndDateContainerArray' | translate}}</mat-label>
                                                <input matInput formControlName="fixedInterestRateEndDateContainerArray">
                                            </mat-form-field> -->
                                        </div>
                                        <div fxLayout="column" fxFlex="30">
                                            <finprocess-sub-header
                                                [label]="'financing.features.financing-processing.sample-calculation.chargesExpenses'|translate">
                                            </finprocess-sub-header>
                                            <mat-form-field appearance="fill">
                                                <mat-label *ngIf="!isEmployeeCase">
                                                    {{'financing.features.financing-processing.sample-calculation.processingCharges'|translate}}
                                                </mat-label>
                                                <mat-label *ngIf="isEmployeeCase">
                                                    {{'financing.features.financing-processing.sample-calculation.riskFee'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="processingCharges" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.estimateCharges'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="estimateCharges" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.bankAccountFee'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="bankAccountFee" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.legalisationFee'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="legalisationFee" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.agencyCosts'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="agencyCosts" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.landRegisterEntryFee'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="landRegisterEntryFee" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.rankingFee'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="rankingFee" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.landRegisterExtract'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="landRegisterExtract" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.landRegisterRequest'|translate}}
                                                </mat-label>
                                                <input matInput formControlName="landRegisterRequest" currencyMask
                                                    [options]="currencyMaskOptions">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="30">
                                            <finprocess-sub-header label="Zinssätze">
                                            </finprocess-sub-header>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.indicatorEuribor'|translate}}
                                                </mat-label>
                                                <mat-select formControlName="indicatorEuribor">
                                                    <mat-option *ngFor="let item of interestMethodSelectItems"
                                                        [value]="item.value">{{ item.label }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.referenceInterestRate'|
                                                    translate}}</mat-label>
                                                <input matInput formControlName="referenceInterestRate" currencyMask
                                                    [options]="percentageMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.addition'|
                                                    translate}}
                                                </mat-label>
                                                <input matInput formControlName="addition" currencyMask
                                                    [options]="percentageMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.interestRate'|
                                                    translate}}
                                                </mat-label>
                                                <input matInput formControlName="interestRate" currencyMask
                                                    [options]="percentageMaskOptions">
                                            </mat-form-field>
                                            <mat-form-field appearance="fill">
                                                <mat-label>
                                                    {{'financing.features.financing-processing.sample-calculation.additionComparativeCalculation'
                                                    | translate}}
                                                </mat-label>
                                                <input matInput formControlName="additionComparativeCalculation"
                                                    currencyMask [options]="percentageMaskOptions">
                                            </mat-form-field>

                                            <finprocess-sub-header [label]="'financing.features.financing-processing.sample-calculation.periodFixedInterestRate'
                                            | translate">
                                            </finprocess-sub-header>
                                            <div *ngIf="getFixedRateEndControls(creditLine) as fixedRateEnds">
                                                <div [formGroup]="fixedRateEnds[0]" fxLayout="row"
                                                    fxLayoutAlign="start center" fxLayoutGap="20px">
                                                    <mat-checkbox formControlName="active" color="primary">Fix 5 Jahre
                                                    </mat-checkbox>
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{'financing.features.financing-processing.sample-calculation.fixedInterestRate'|translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="fixedInterestRate" currencyMask
                                                            [options]="percentageMaskOptions">
                                                    </mat-form-field>
                                                </div>
                                                <div [formGroup]="fixedRateEnds[1]" fxLayout="row"
                                                    fxLayoutAlign="start center" fxLayoutGap="12px">
                                                    <mat-checkbox formControlName="active" color="primary">Fix 10 Jahre
                                                    </mat-checkbox>
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{'financing.features.financing-processing.sample-calculation.fixedInterestRate'|translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="fixedInterestRate" currencyMask
                                                            [options]="percentageMaskOptions">
                                                    </mat-form-field>
                                                </div>
                                                <div [formGroup]="fixedRateEnds[2]" fxLayout="row"
                                                    fxLayoutAlign="start center" fxLayoutGap="12px">
                                                    <mat-checkbox formControlName="active" color="primary">Fix 15 Jahre
                                                    </mat-checkbox>
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{'financing.features.financing-processing.sample-calculation.fixedInterestRate'|translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="fixedInterestRate" currencyMask
                                                            [options]="percentageMaskOptions">
                                                    </mat-form-field>
                                                </div>
                                                <div [formGroup]="fixedRateEnds[3]" fxLayout="row"
                                                    fxLayoutAlign="start center" fxLayoutGap="12px">
                                                    <mat-checkbox formControlName="active" color="primary">Fix 20 Jahre
                                                    </mat-checkbox>
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{'financing.features.financing-processing.sample-calculation.fixedInterestRate'|translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="fixedInterestRate" currencyMask
                                                            [options]="percentageMaskOptions">
                                                    </mat-form-field>
                                                </div>
                                                <div [formGroup]="fixedRateEnds[4]" fxLayout="row"
                                                    fxLayoutAlign="start center" fxLayoutGap="12px">
                                                    <mat-checkbox formControlName="active" color="primary">Fix 25 Jahre
                                                    </mat-checkbox>
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{'financing.features.financing-processing.sample-calculation.fixedInterestRate'|translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="fixedInterestRate" currencyMask
                                                            [options]="percentageMaskOptions">
                                                    </mat-form-field>
                                                </div>
                                                <div *ngFor="let fixedRateEnd of fixedRateEnds | slice:5; let i = index"
                                                    fxLayout="row" [formGroup]="fixedRateEnd">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput formControlName="endDateOfFixedInterestRate"
                                                            [matDatepicker]="fixedInterestRateEndPicker"
                                                            (focus)="fixedInterestRateEndPicker.open()">
                                                        <mat-datepicker-toggle matSuffix
                                                            [for]="fixedInterestRateEndPicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #fixedInterestRateEndPicker></mat-datepicker>
                                                    </mat-form-field>
                                                    <mat-checkbox></mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxFlex="30" fxFlexAlign="center">
                                        <button mat-raised-button color="primary"
                                            [disabled]="creditLine.invalid || readonly || syncing || form.get('customerNames')?.hasError('required')"
                                            (click)="downloadSampleCalculations()">
                                            {{'financing.features.financing-processing.sample-calculation.generateSampleCalculation'| translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <!-- <mat-tab disabled>
                            <ng-template mat-tab-label>
                                <button mat-icon-button class="add-tab-button" [matMenuTriggerFor]="menu"
                                    aria-label="add">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="menu">
                                    <div *ngFor="let creditLine of creditLineEnumArray; let i = index">
                                        <button mat-menu-item *ngIf="i !== (creditLineEnumArray.length - 1)"
                                            (click)="addCreditLine(creditLine)">
                                            <span>{{creditLine | finprocessEnumTranslation :'CreditLine'}}</span>
                                        </button>
                                    </div>
                                </mat-menu>
                            </ng-template>
                        </mat-tab> -->
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="waiter w-100 h-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>