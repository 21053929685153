export enum PriorityAdmissionValueType {
    /**
     * bestehend
     */
    Existing = 1,

    /**
     * noch einzuverleibend
     */
    New = 2,
}
