<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id">
            <span *ngIf="!!label"></span>{{label}}
            <ng-content *ngIf="!label" select="[label]"></ng-content>
        </label>
        <mat-icon class="ml-1" *ngIf="overwrite && currentValue !== originalValue" [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (translatedOriginalValueKey | translate) }">info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="start center">
        <mat-radio-group [disabled]="(readonly | async) ?? false" [ngModel]="currentValue" (ngModelChange)="save($event)">
            <mat-radio-button #yes fxFlex="50" [value]="true" (click)="checkReset($event, yes)">{{ trueLabel ?? ('financing.features.financing-processing.true' | translate)}}</mat-radio-button>
            <mat-radio-button #no fxFlex="50" [value]="false" (click)="checkReset($event, no)">{{ falseLabel ?? ('financing.features.financing-processing.false' | translate)}}</mat-radio-button>
        </mat-radio-group>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr">
</div>
