import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role, UserService } from 'app/modules/auth/data';
import { DashboardType, DashboardTypeChanged, IDashboardStateParentDefinition } from 'app/modules/dashboard/data';

/**
 * Einstiegskomponente für das Dashboard, welche die verschiedenen Dashboards für verschiedene Rollen anzeigt
 */
@Component({
    selector: 'finprocess-base-dashboard',
    templateUrl: './base-dashboard.component.html',
    styleUrls: ['./base-dashboard.component.scss'],
})
export class BaseDashboardComponent implements OnInit {

    /**
     * Liste der Dashboards und Berechtigungen
     */
    public dashboards = [
        { type: DashboardType.Expert, roles: Role.Expert},
        { type: DashboardType.Referent, roles: Role.Referent},
        { type: DashboardType.Approver, roles: Role.Approver},
    ]

    /**
     * Aktuell angezeigtes Dashboard
     */
    public currentDashboard?: DashboardType;

    /**
     * Check ob User keine der beiden Rollen hat
     */
    public missingRoles = false;

    /**
     * Standard Konstruktor
     * 
     * @param {Store} store Store-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {UserService} user UserService-Injektor
     */
    public constructor(
        private store: Store,
        private authorizationService: AuthorizationService,
        private user: UserService,
    ) {

    }

    /**
     * Lädt das zuletzt ausgewählte Dashboard aus dem State
     */
    public ngOnInit(): void {
        const currentDashboard = this.store.selectSnapshot((it: IDashboardStateParentDefinition) => it.dashboard.currentDashboard);
        this.missingRoles = !(this.authorizationService.hasRole(Role.Referent) || this.authorizationService.hasRole(Role.Expert));
        
        if (currentDashboard === DashboardType.Expert && !this.authorizationService.hasRole(Role.Expert)) {
            this.currentDashboard = undefined;
        } else if (currentDashboard === DashboardType.Referent && !this.authorizationService.hasRole(Role.Referent)) {
            this.currentDashboard = undefined;
        } else if (currentDashboard === DashboardType.Approver && !this.authorizationService.hasRole(Role.Approver)) {
            this.currentDashboard = undefined;
        } else {
            this.currentDashboard = currentDashboard;
        }
    }

    /**
     * Wechselt das Dashboard
     * 
     * @param {DashboardType} type Typ des Dashboards
     */
    public setDashboard(type: DashboardType): void {
        this.currentDashboard = type;
        this.store.dispatch(new DashboardTypeChanged(type));
    }
}
