<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection()" fxLayoutAlign="start start">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id()">
            @if (!!label() && !hideLabel()) {
            <span>{{ label() }}</span>
            } @else if (!label() && !hideLabel()) {
            <ng-content select="[label]"></ng-content>
            } @if (isRequired()) {
            <span class="required-indicator"> *</span>
            }
        </label>
        @if (overwrite() && currentValue() !== originalValue()) {
        <mat-icon
            class="ml-1"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | translate) }"
            >info</mat-icon
        >
        }
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100">
        <mat-radio-group [disabled]="(readonly() | async) ?? false" [ngModel]="currentValue()" (ngModelChange)="save($event)">
            <mat-radio-button #yes fxFlex="50" [value]="true" (click)="checkReset($event, yes)">{{
                trueLabel() ?? ('financing.features.financing-processing.true' | translate)
            }}</mat-radio-button>
            <mat-radio-button #no fxFlex="50" [value]="false" (click)="checkReset($event, no)">{{
                falseLabel() ?? ('financing.features.financing-processing.false' | translate)
            }}</mat-radio-button>
        </mat-radio-group>
    </div>
    @if (!hideHorizontalRule()) {
    <hr class="custom-hr" />
    }
</div>
