import { CalculationVersionHelper } from './../../calculation-version-helper';
import { ArgumentError } from './../../errors';

/**
 * Basis-Service für Berechnungen
 */
export class BaseCalculationService {

    /**
     * Zu nutzende Berechnungsversion
     */
    private internalVersion: number = CalculationVersionHelper.currentCalculationVersion;

    private internalVersionDescription: string = Object.keys(CalculationVersionHelper.calculationVersionHistory).some(it => it === CalculationVersionHelper.currentCalculationVersion.toString()) ? CalculationVersionHelper.calculationVersionHistory[CalculationVersionHelper.currentCalculationVersion.toString()] : '';

    /**
     * Zu nutzende Berechnungsversion
     *
     * @returns {number} Berechnungsversion
     */
    public get version(): number {
        return this.internalVersion;
    }

    /**
     * Beschreibung der Berechnungsversion
     *
     * @returns {string} Beschreibung der Berechnungsversion
     */
    public get versionDescription(): string {
        return this.internalVersionDescription;
    }

    /**
     * Erstellt eine neue Instanz
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    protected constructor(version?: number) {
        this.setCalculationVersion(version);
    }

    /**
     * Setzt die Berechnungsversion
     *
     * @param {number | undefined} version Zu nutzende Berechnungsversion
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public setCalculationVersion(version?: number): void {
        if (version !== undefined && version > CalculationVersionHelper.currentCalculationVersion) {
            throw new ArgumentError('Der Parameter \'version\' ist nicht valide.');
        }
        this.internalVersion = version ?? CalculationVersionHelper.currentCalculationVersion;
        this.internalVersionDescription = Object.keys(CalculationVersionHelper.calculationVersionHistory).some(it => it === this.internalVersion.toString()) ? CalculationVersionHelper.calculationVersionHistory[this.internalVersion.toString()] : '';
    }
}
