/**
 * [MEHRFACHAUSWAHL] Ausstattung (Haupt)Badezimmer
 */
export enum BathroomFacilities {
    /**
     * Handwaschbecken im getrennten WC
     */
    BasinInSeparateToilet = 32,

    /**
     * Verfliesung des gesamten Bades (tür- bzw. raumhoch verfliest)
     */
    Tiling = 64,

    /**
     * Bidet im Badezimmer
     */
    Bidet = 128,

    /**
     * Doppelwaschbecken
     */
    DoubleBasin = 512,
}
