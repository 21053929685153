import { IRealEstate } from '../../financing-data.module';

/**
 * Aktion beim Laden der Immobilien
 */
export class RealEstatesLoaded {
    public static readonly type = 'realEstate.getAll';

    /**
     * Konstruktor
     *
     * @param {IRealEstate} payload Daten der Immobilie
     */
    public constructor(public payload: IRealEstate[]) { }
}


/**
 * Aktion beim Laden der Immobilie
 */
export class RealEstateLoaded {
    public static readonly type = 'realEstate.loadedOne';

    /**
     * Konstruktor
     *
     * @param {IRealEstate} payload Daten der Immobilie
     */
    public constructor(public payload: IRealEstate) { }
}
