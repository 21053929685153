import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { SmartDocConfigurationState } from 'app/modules/administration/data/states';
import { SubStatusCode } from 'app/modules/shared';
import { IMethodNotAllowedError } from 'app/modules/smartdoc/data/interfaces/smartdoc/method-not-allowed-error.interface';
import { Observable, Subject, takeUntil } from 'rxjs';

import { IDropAreaViewModel } from './../../../../../smartdoc/data/interfaces/smartdoc/drop-area-view.model';
import { IDropFolderViewModel } from './../../../../../smartdoc/data/interfaces/smartdoc/drop-folder-view.model';
import { ISmartDocDialogData } from './../../../../data/interfaces/smartdoc-dialog-data';
import { SmartDocConfigurationService } from './../../../../data/services/smartdoc-configuration/smartdoc-configuration.service';
import { SmartdocEditDialogComponent } from './smartdoc-edit-dialog/smartdoc-edit-dialog.component';


/**
 * Komponente für SmartDoc konfiguration
 */
@Component({
    selector: 'finprocess-smartdoc-configuration',
    templateUrl: './smartdoc-configuration.component.html',
    styleUrls: ['./smartdoc-configuration.component.scss'],
})
export class SmartdocConfigurationComponent implements OnInit, OnDestroy {

    @Select(SmartDocConfigurationState.getDropFoldersConfiguration)
    public smartDocConfigurationsFolders$!: Observable<IDropFolderViewModel[]>;

    /**liste von alle folders */
    private allSmartFolders: IDropFolderViewModel[] | undefined;

    /**liste von erste ebene folders */
    public firstLevelSmartFolders: IDropFolderViewModel[] | undefined;

    /** um zu folder Navigation zu ermöglichen */
    public trackFoldersNavigation: IDropFolderViewModel[] = [];

    /**Folders, die aktuell parent Folder enthaltet */
    public childFolders: IDropFolderViewModel[] | undefined;

    /**Folder, der angezeigt ist */
    public currentFolder: IDropFolderViewModel | undefined;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     *
     * Standard Konstruktor
     *
     * @param {SmartDocConfigurationService} smartDocConfigService SmartDocService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {MatDialog} matDialog matDialog-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(
        private smartDocConfigService: SmartDocConfigurationService,
        private waiterService: WaiterService,
        private matDialog: MatDialog,
        private notification: NotificationService,
        private translate: TranslateService,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.smartDocConfigService.loadDropFolders().subscribe();

        this.smartDocConfigurationsFolders$.pipe(
            takeUntil(this.viewLeft$),
        ).subscribe(docs => {
            this.allSmartFolders = docs;
            this.firstLevelSmartFolders = docs.filter(doc => !doc.parentDropFolderId);
            //ersetzt currenFolder mit new CurrentFolder aus der States
            if (!!this.currentFolder) {
                const newFolder = docs.find(folder => folder.id === this.currentFolder?.id)
                if (!!newFolder) {
                    const index = this.trackFoldersNavigation.indexOf(this.currentFolder);
                    this.trackFoldersNavigation[index] = newFolder
                    this.currentFolder = newFolder;
                }
            }
            this.openFolder(this.currentFolder);
        })
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Funktion die einen folder offnet
     *
     * @param {IDropFolderViewModel} folderToOpen dropFolder
     */
    public openFolder(folderToOpen?: IDropFolderViewModel): void {

        //wenn folder keine Value hat, dann ist von goBack angeruft
        if (!!folderToOpen) {
            const isFromHistory = this.trackFoldersNavigation.includes(folderToOpen)
            if (!isFromHistory) {
                this.trackFoldersNavigation.push(folderToOpen);
            }
        }

        const lastFolder = this.trackFoldersNavigation[this.trackFoldersNavigation.length - 1]

        if (!!lastFolder) {
            this.childFolders = this.allSmartFolders?.filter(folder => folder.parentDropFolderId === lastFolder.id);
            this.currentFolder = lastFolder;
        }
        else {
            this.childFolders = undefined;
            this.currentFolder = undefined;
        }
    }

    /**
     * Züruck funktion für UI
     */
    public goBack(): void {
        //letze navigation Value muss gelöscht werden
        this.trackFoldersNavigation.pop();
        this.openFolder();
    }

    /**
     * Erstellt euen Folder
     * falls keine smartFolder Value, handelt sich um erste ebene von SmartDoc folder
     *
     * @param {string} parentFolderId parentFolderID falls vorhanden
     */
    public addFolder(parentFolderId?: string): void {
        const dataDialog: ISmartDocDialogData = {
            dropFolder: {
                parentDropFolderId : parentFolderId,
                dropAreas: [],
                isDeletable: true,
            },
        };
        this.openSmartDocDialogSendChanges(dataDialog)
    }

    /**
     * Edit Ordner
     *
     * @param {IDropFolderViewModel} dropFolder dropFolder
     */
    public editFolder(dropFolder: IDropFolderViewModel): void {
        const dataDialog: ISmartDocDialogData = {
            dropFolder: {
                id: dropFolder.id,
                name: dropFolder.name,
                parentDropFolderId: dropFolder.parentDropFolderId,
            },
        };

        this.openSmartDocDialogSendChanges(dataDialog)
    }

    /**
     * Offnet ein Dialog und rüft SmartDocConfigurationService
     *
     * @param {ISmartDocDialogData} dataDialog daten für dialog
     */
    private openSmartDocDialogSendChanges(dataDialog: ISmartDocDialogData): void {

        const dialogRef = this.matDialog.open(SmartdocEditDialogComponent, {
            data: dataDialog,
            width: '400px',
        })

        //folder ändert
        dialogRef.componentInstance.folderChanged
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(dropFolder => {
                this.waiterService.show();

                this.smartDocConfigService.addOrUpdateDropFolder(dropFolder).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        dialogRef.close();
                    },
                    error: e => {
                        this.waiterService.hide();
                        this.handleError(e)
                    },
                })
            })

        //dropArea ändert
        dialogRef.componentInstance.dropAreaChanged
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(dropArea => {
                this.waiterService.show();

                this.smartDocConfigService.addOrUpdateDropArea(dropArea).subscribe({
                    next: () => {
                        this.waiterService.hide();
                        dialogRef.close();
                    },
                    error: e => {
                        this.waiterService.hide();
                        this.handleError(e)
                    },
                })
            })
    }

    /**
     * DropAreas alas favorit markieren/unmarkieren
     *
     * @param {IDropAreaViewModel} dropArea dropArea
     */
    public setFavorite(dropArea: IDropAreaViewModel): void {
        this.smartDocConfigService.addOrUpdateDropArea({...dropArea, hasQuickAccess: !dropArea.hasQuickAccess}).subscribe();
    }

    /**
     * Edit drop Area
     *
     * @param {IDropAreaViewModel} dropAreaEdit daten für den dialog
     */
    public editDropArea(dropAreaEdit: IDropAreaViewModel): void {
        const dataDialog: ISmartDocDialogData = {
            dropArea: dropAreaEdit,
        };

        this.openSmartDocDialogSendChanges(dataDialog)
    }

    /**
     * Erstellt neuen drop Area
     *
     * @param {string} dropFolderId daten für dialog
     */
    public addDropArea(dropFolderId?: string): void {
        if (!!dropFolderId) {
            const dataDialog: ISmartDocDialogData = {
                dropArea : {
                    dropFolderId: dropFolderId,
                    isDeletable: true,
                },
                dropFolder: {
                    dropAreas: this.currentFolder?.dropAreas,
                },
            };
            this.openSmartDocDialogSendChanges(dataDialog)
        }
    }

    /**
     * löscht Area
     *
     * @param {string} areaId dropArea id
     */
    public deleteDropArea(areaId: string): void {
        this.notification.confirmOkCancel(
            this.translate.instant('administration.features.smartDocConfiguration.confirmDeleteDropAreaTitle'),
            this.translate.instant('administration.features.smartDocConfiguration.confirmDeleteDropAreaMsg'))
            .subscribe(res => {
                if (res === 'submit') {
                    this.waiterService.show();
                    this.smartDocConfigService.deleteDropArea(areaId).subscribe({
                        next: () => this.waiterService.hide(),
                        error: e => {
                            this.waiterService.hide(),
                            this.handleError(e)
                        },
                    })

                }
            })
    }

    /**
     * löscht Ordner
     *
     * @param {string} folderId dropFolder id
     */
    public deleteFolder(folderId: string): void {
        this.notification.confirmOkCancel(
            this.translate.instant('administration.features.smartDocConfiguration.confirmDeleteDropFolderTitle'),
            this.translate.instant('administration.features.smartDocConfiguration.confirmDeleteDropFolderMsg'))
            .subscribe(res => {
                if (res === 'submit') {
                    this.waiterService.show();
                    this.smartDocConfigService.deleteDropFolder(folderId).subscribe({
                        next: () => this.waiterService.hide(),
                        error: e => {
                            this.waiterService.hide(),
                            this.handleError(e)
                        },
                    })

                }
            })

    }

    /**
     * behandelt Fehler des Backends
     *
     * @param {HttpErrorResponse} response antwort von den Server
     */
    private handleError(response: HttpErrorResponse) {
        const error = (response.error as IMethodNotAllowedError);
        if (response.status === 405 && [
            SubStatusCode.DropFolderNotExists,
            SubStatusCode.DropAreaNotExists,
            SubStatusCode.DocumentTypeAlreadyExists,
            SubStatusCode.DocumentsExists,
        ].some(er => er === error.subStatus)) {
            this.notification.alert(
                this.translate.instant('administration.features.smartDocConfiguration.conflict'),
                this.translate.instant('administration.features.smartDocConfiguration.conflictMessage'),
            )
        }
        else {
            this.notification.alert(
                this.translate.instant('general.error'),
                this.translate.instant('general.unknownError'),
            )
        }
    }

}
