import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from 'ngx-flexible-layout'

import { SubHeaderComponent, TwoColumnLayoutComponent } from './components';


/**
 * Module für allgemeine UI-Komponentent
 */
@NgModule({
    declarations: [
        TwoColumnLayoutComponent,
        SubHeaderComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatPaginatorModule,
    ],
    exports: [
        TwoColumnLayoutComponent,
        SubHeaderComponent,
    ],
})
export class SharedUiModule { }
