
import { ISystemConfigurationValue } from 'app/modules/administration/data/interfaces';

import { IExternalSystemsAvailability } from '../../interfaces';
import { SystemConfiguration } from '../../interfaces/system-configuration.interface';

/**
 * Aktion bei erfolgreichem Laden Verfügbarkeiten der externen Systeme
 */
export class ExternalSystemsLoaded {
    public static readonly type = 'systemConfiguration.externalSystemsLoaded';

    /**
     * Konstruktor
     *
     * @param {IExternalSystemsAvailability} payload Verfügbarkeit der externen Systeme
     */
    public constructor(public payload: IExternalSystemsAvailability) { }
}

/**
 * Aktion bei erfolgreichem Laden Ratenrechner data
 */
export class SystemsConfigurationLoaded {
    public static readonly type = 'systemConfiguration.systemsConfigurationLoaded';

    /**
     * Konstruktor
     *
     * @param {SystemConfiguration} payload Verfügbarkeit der externen Systeme
     */
    public constructor(public payload: SystemConfiguration) { }
}


/**
 * Aktion beim Ändern der Systemkonfiguration
 */
export class UpdateSystemConfigurationValue {
    public static readonly type = 'systemConfiguration.systemsConfigurationUpdate';
    
    /**
     * Konstruktor 
     * 
     * @param {ISystemConfigurationValue} payload payload
     */
    public constructor(public payload: ISystemConfigurationValue) {}
}
