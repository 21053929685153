<div right class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
  <waiter-component [isLoading]="loading"></waiter-component>
</div>

<div class="container" class="mt-3">
  
  <finprocess-stepper [steps]="[financingStep, productPackageStep]"></finprocess-stepper>
   
  <router-outlet></router-outlet>
</div>
