import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IStepperStep } from 'app/modules/financing/data';

/**
 * Component to dislay a stepper similiar to the material stepper, but with easier usage for routing
 */
@Component({
    selector: 'finprocess-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {

    @Input()
    public steps: IStepperStep[] = [];

    @Output()
    public selectedStepIndexChanged = new EventEmitter<number>();
}
