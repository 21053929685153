import { EnumSettings } from './../enum.settings';

/**
 * Heizungssystem
 */
export enum HeatingSystem {
    /**
     * Zentralheizung
     */
    Central = 1,

    /**
     * Individualheizung (Einzelöfen)
     */
    Individual = 2,
}

export namespace HeatingSystem {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {HeatingSystem | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: HeatingSystem | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'HeatingSystem', HeatingSystem, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('HeatingSystem', plocale);
}

