import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { InputService } from './input.service';

/**
 * Custom Directive for NDG formatting
 */
@Directive({
    selector: '[finprocessFormatNdg]',
    standalone: true,
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormatNdgDirective),
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormatNdgDirective),
            multi: true,
        },
    ],
})
export class FormatNdgDirective implements ControlValueAccessor, Validator {
   
    public readonly inputService: InputService;
    
    private onChange: () => void = () => undefined;
    
    private abstractControl: AbstractControl | null = null;

    /**
     * Konstructor
     *
     * @param {ElementRef<HTMLInputElement>} inputElementRef inputElementRef
     */
    public constructor (
      private readonly inputElementRef: ElementRef<HTMLInputElement>,
    ) 
    {
        this.inputService = new InputService(this.inputElementRef.nativeElement);
    }

     
    /**
     * This method is called by the forms API on initialization to update the form
     *
     * @param {(value: string | null) => void} callbackFunction callbackFunction
     */
    public registerOnChange(callbackFunction: (value: string | null) => void): void {
        this.inputService.setOnModelChange(callbackFunction);
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * 
     * @param {() => void} callbackFunction callbackFunction
     */
    public registerOnTouched(callbackFunction: () => void): void {
        this.inputService.setOnModelTouched(callbackFunction);
    }

    /**
     *  Set disabled state (not sure if this is needed)
     *
     * @param {boolean} isDisabled fsds 
     */
    public setDisabledState(isDisabled: boolean): void {
        this.inputElementRef.nativeElement.disabled = isDisabled;
    }

    /**
     *  Write new (initial) value for the element
     *
     * @param {number | string} value value 
     */
    public writeValue(value: number | string): void {
        this.inputService.setMaskedValue(value?.toString());
    }

    /**
     * Listen to the blur input event
     * update model and html value on blur
     *
     * @param {FocusEvent} event FocusEvent
     */
    @HostListener('blur', ['$event'])
    public handleBlur(event: FocusEvent) {
        //set input as touched - this is required for the form validation
        this.inputService.getOnModelTouched().apply(event);

        // on change will call custom validate method
        this.onChange();

        //if control has errors do not update
        //but if has error required and input has value and control has not, value should be updated!
        if (!!this.abstractControl?.errors && !(this.abstractControl.errors.required && !!this.inputElementRef.nativeElement.value && !this.inputService.storedInputVal)) {
            return;
        }
        else if (this.inputService.isInputRequred() && this.inputService.isNewValueNllOrEmptyButOldNot()) {
            this.inputService.restoreOldValueToInput();
        }
        if (this.inputService.isInputValid() && !this.inputService.areValuesEqual()) {
            this.inputService.setNewValueAfterBlur();
            //workaround for the issue with the form control not updating the value (borrower-check-customer-data.component) 
            this.inputService.getOnModelTouched().apply(event);
        }
    }

    /*************** Validator *************************/

    /**
     *  Registers a callback function to call when the validator inputs change
     * 
     * @param { () => void } fn callback function
     */
    public registerOnValidatorChange?(fn: () => void): void {
        this.onChange = fn;
    }

    /**
     * Validate the input and set error to FormControl
     * no need for minvalue validation as the input is always min 8 digits (input service has a mask)
     * 
     * @param {AbstractControl} control AbstractControl
     * @returns {ValidationErrors | null} ValidationErrors | null
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.abstractControl) {
            this.abstractControl = control;
        } 

        //validator max length
        if (this.inputElementRef.nativeElement?.value?.length > this.inputService.ndgStandardLenght) {
            return {
                maxlength: {
                    requiredLength: this.inputService.ndgStandardLenght,
                },
            };
        } 
        //valdiator no whitespace
        else if (this.inputElementRef.nativeElement?.value.includes(' ')) {
            return {
                noSpace: '',
            };
        } 
        else {
            return null;
        }
    }
  

    /**
     * Listen to the input event and validate the input in real time
     * 
     */
    @HostListener('input')
    public handleInput(): void {
        this.onChange();
    }
}

