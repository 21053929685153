import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { IFinancingStateModel } from 'app/modules/financing/data';
import { SharedService } from 'app/modules/shared/services/shared.service';
import {
    Observable,
    Subscription,
    map,
    take,
} from 'rxjs';

import {
    IDropAreaViewModel,
    IDropFolderViewModel,
    ISmartDocJobThumbnailViewModel,
    ISmartDocSortResultFormModel,
    ISmartDocStateModel,
    ISmartDocStateParentDefinition,
    SmartDocJobStatus,
    SmartDocService,
    SmartDocSortingStarted,
} from '../../../../data';
import { DropFoldersService } from '../../../../data/services/drop-folders.service';
import { AAGUID } from '../../../../data/types/aaguid';
import { SmartDocWaiterDialogComponent } from '../smartdoc-waiter-dialog/smartdoc-waiter-dialog.component';
import { ThumbnailDialogComponent } from '../thumbnail-dialog/thumbnail-dialog.component';

/**
 * Compponent für das Zuorden von IDocuments
 */
@Component({
    selector: 'finprocess-smart-doc-processing',
    templateUrl: './smart-doc-processing.component.html',
    styleUrls: ['./smart-doc-processing.component.scss'],
})
export class SmartDocProcessingComponent implements OnInit, OnDestroy {
    /**
     * smartdoc-state
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc)
    public smartdoc$!: Observable<ISmartDocStateModel>;

    /**
     * actual drop folders
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.dropFolders)
    public state$!: Observable<IDropFolderViewModel[]>;

    /**
     * IDocument[] des geoeffneten Finanzierungsfalls
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.openedId)
    public openedId$!: Observable<string>;

    /**
     * smartdoc thumbnails
     */
    @Select((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocThumbnails)
    public smartdocThumbnails$!: Observable<ISmartDocJobThumbnailViewModel[]>

    /**
     * to memorize dragged thumbnail
     */
    public thumbnailsToStore: ISmartDocJobThumbnailViewModel[] = [];

    /**
     * to memorize current drop folder
     */
    public currentDropFolder: IDropFolderViewModel = {} as IDropFolderViewModel;

    /**
     * all drop folders
     */
    public dropFolders$!: Observable<IDropFolderViewModel[]>;

    /**
     * drop folder is opened
     */
    public openedDropFolder?: IDropFolderViewModel[] = undefined;

    /**
     * show back button
     */
    public showBackButton = false;

    /**
     * indicate if at least one thumbnail was assingend to an drop area
     */
    public assignedAThumbnail = false;
    /**
     * array to store subscriptions
     */
    private subscriptions$: Subscription[] = [];

    /**
     * gibt an, ob sich der Smartdoc Prozess im Splitting finished Schritt befindet
     */
    public splittingNotFinished = false;



    /**
     * Constructor
     *
     * @param {SmartDocService} smartdocService SmartDocService
     * @param {DropFoldersService} dropFoldersService DropFoldersService
     * @param {Store} store Store
     * @param {ActivatedRoute} activatedRoute ActivatedRoute
     * @param {Actions} actions$ Actions
     * @param {SharedService} sharedService SharedService
     * @param {TranslateService} translateService TranslateService
     * @param {MatDialog} modalService MatDialog
     * @param {NotificationService} notificationService NotificationService
     * @param {WaiterService} waiterService WaiterService
     */
    public constructor (
      private smartdocService: SmartDocService,
      private dropFoldersService: DropFoldersService,
      private store: Store,
      private activatedRoute: ActivatedRoute,
      private actions$: Actions,
      private sharedService: SharedService,
      private translateService: TranslateService,
      private modalService: MatDialog,
      private notificationService: NotificationService,
      private waiterService: WaiterService,
    ) { }

    /**
     * Angular hook on initialize component
     */
    public ngOnInit (): void {
        const financingId = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.openedId)
        if (!financingId) {
            return;
        }

        this.smartdocService.getFolders();
        this.subscriptions$.push(this.state$.subscribe((dropFolders: IDropFolderViewModel[]) => {
            this.dropFoldersService.setDropFolders(dropFolders);
            this.dropFolders$ = this.dropFoldersService.getCurrentDropFolderAsObservable();
        }))


        this.subscriptions$.push(this.openedId$.subscribe((aaguid: string) => {
            if (aaguid) {
                this.smartdocService.loadThumbnails(aaguid);
            }
        }))


        this.sharedService.setJobStatusState(financingId);

        this.subscriptions$.push(
            this.store.select((state: ISmartDocStateParentDefinition) => state.smartdoc.status?.jobStatus).subscribe(jobStatus => {
                this.splittingNotFinished = SmartDocJobStatus.SplittingFinished !== jobStatus;
            }),
        );

        const thumbnails = (this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocThumbnails));
        if (thumbnails.length > this.getSelectionSmartDocThumbnails(thumbnails).length) {
            this.assignedAThumbnail = true;
        }

        this.showBackButton = this.dropFoldersService.hasPrevDropFolders();

    }

    /**
     * Angular hook on leaving component
     */
    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions$) {
            subscription.unsubscribe();
        }
    }

    /**
     * open next drop folders
     *
     * @param {string} dropFolderId given drop folder id
     */
    public openFolder (dropFolderId: string): void {
        this.dropFoldersService.setCurrentDropFolder(dropFolderId);
        this.currentDropFolder = this.dropFoldersService.getDropFolderById(dropFolderId);
        this.showBackButton = this.dropFoldersService.hasPrevDropFolders();
    }

    /**
     * navigate to prev drop folders
     */
    public navigateBack (): void {
        if (this.showBackButton) {
            const currentDropFolderId = this.dropFoldersService.navigateBack();
            this.currentDropFolder = this.dropFoldersService.getDropFolderById(currentDropFolderId);
        }
        this.showBackButton = this.dropFoldersService.hasPrevDropFolders();
    }

    /**
     * get sub folders of current drop folder
     *
     * @param {string} subFolderId parrent folder id
     * @returns {IDropFolderViewModel[]} sub folders
     */
    public getSubFoldersById (subFolderId: string): IDropFolderViewModel[] {
        return this.dropFoldersService.getSubFoldersById(subFolderId);
    }

    /**
     * get thumbnails filtered by assigned drop folder id
     *
     * @param {ISmartDocJobThumbnailViewModel[]} smartdocThumbnails ISmartDocJobThumbnailViewModel[]
     * @param {IDropAreaViewModel} dropArea IDropAreaViewModel
     * @returns {ISmartDocJobThumbnailViewModel[]} filtered smartDoc thumbnails
     */
    // eslint-disable-next-line class-methods-use-this
    public getThumbnailsByDropArea (
        smartdocThumbnails: ISmartDocJobThumbnailViewModel[],
        dropArea: IDropAreaViewModel,
    ): ISmartDocJobThumbnailViewModel[] {
        return smartdocThumbnails.filter(
            (thumbnail: ISmartDocJobThumbnailViewModel) =>
                thumbnail.dropAreaId === dropArea.id,
        )
    }

    /**
     * quickaccess if drop folder doesnt have a parrent
     *
     * @returns {boolean} isRootLevel
     */
    public isRootLevel (): boolean {
        if (this.dropFoldersService.hasPrevDropFolders()) { return false; }
        return true;
    }

    /**
     * start processing of document assignments
     */
    public startProcessFiles (): void {
        const snapshot = this.store.selectSnapshot(
            (state: ISmartDocStateParentDefinition) => state.smartdoc,
        )
        if (snapshot.openedId !== undefined &&
            !snapshot.isReadonly && /*!this.isStatusReadonly(snapshot) &&*/
            this.assignmentsComplete(snapshot)
        ) {
            this.actions$.pipe(
                ofActionSuccessful(SmartDocSortingStarted), take(1))
                .subscribe(() => {
                    this.openWaiterDialog(snapshot);
                });
            this.smartdocService.startProcessFiles(snapshot.openedId);
        }
    }

    /**
     * indicate component is read only
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     * @returns {boolean} is read only?
     */
    // eslint-disable-next-line class-methods-use-this
    public isStatusReadonly (state: ISmartDocStateModel): boolean {
        return state.status?.jobStatus !== SmartDocJobStatus.SplittingFinished;
    }

    /**
     * indicate all thumbs are assigned
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     * @returns {boolean} everything assigned?
     */
    // eslint-disable-next-line class-methods-use-this
    public assignmentsComplete (state: ISmartDocStateModel): boolean {
        return (
            state.smartDocThumbnails.length > 0 &&
            !state.smartDocThumbnails.some(
                it =>
                    it.sortWeight === undefined && it.dropAreaId === undefined,
            )
        );
    }

    /**
     * returns smartdoc thumbnails which can be assigned to drop areas
     *
     * @param {ISmartDocJobThumbnailViewModel[]} thumbnails ISmartDocJobThumbnailViewModel[]
     * @returns {ISmartDocJobThumbnailViewModel[]} thumbs for selection
     */
    // eslint-disable-next-line class-methods-use-this
    public getSelectionSmartDocThumbnails (
        thumbnails: ISmartDocJobThumbnailViewModel[],
    ): ISmartDocJobThumbnailViewModel[] {
        return thumbnails.filter(
            (aThumbnail: ISmartDocJobThumbnailViewModel) =>
                aThumbnail.dropAreaId === undefined && aThumbnail.sortWeight === undefined,
        );
    }

    /**
     * resets all thumbnails assigned to drop areas
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     */
    public resetSortResults (state: ISmartDocStateModel): void {
        if (state.openedId !== undefined) {

            this.notificationService.confirmYesNo(
                this.translateService.instant('common.confirm'),
                this.translateService.instant('smartdoc.confirmReset'),
                false,
            ).subscribe(result => {
                if (result === 'submit') {
                    this.splittingNotFinished = false;
                    this.smartdocService.resetThumbnailSortResults(state.openedId as AAGUID);
                    this.assignedAThumbnail = false;
                }
            });
        }
    }

    /**
     * get thumbs for trash area
     *
     * @param {ISmartDocJobThumbnailViewModel[]} thumbnails ISmartDocJobThumbnailViewModel[]
     * @returns {ISmartDocJobThumbnailViewModel[]} by drop area === undefined filtered thumbs
     */
    // eslint-disable-next-line class-methods-use-this
    public getThrashThumbs (thumbnails: ISmartDocJobThumbnailViewModel[]): ISmartDocJobThumbnailViewModel[] {
        return thumbnails.filter((thumbnail: ISmartDocJobThumbnailViewModel) =>
            thumbnail.dropAreaId === undefined && thumbnail.sortWeight !== undefined,
        )
    }

    //--------------------------------------------------------------------------
    //                      D R A G S T U F F
    //--------------------------------------------------------------------------

    /**
     * clear thumbnails to store after drag process
     */
    public dragEnd (): void {
        this.thumbnailsToStore = [];
    }

    /**
     * pushes dragged thumbnail on thumbnailsToStore
     *
     * @param {ISmartDocJobThumbnailViewModel} thumbnail ISmartDocJobThumbnailViewModel
     */
    public dragStart (thumbnail: ISmartDocJobThumbnailViewModel): void {
        this.thumbnailsToStore.push(thumbnail);
    }

    /**
     * supress defasult behaviour for drag over events
     *
     * @param {DragEvent} event DragEvent
     */
    public dragOver (event: DragEvent): void {
        if (this.thumbnailsToStore) {
            event.preventDefault();
        }
    }

    /**
     * stores thumbnail result on drop
     *
     * @param {string} openedId string
     * @param {number} position number
     * @param {IDropAreaViewModel} subFolder IDropAreaViewModel
     */
    public drop (openedId: string, position: number, subFolder?: IDropAreaViewModel): void {
        if (this.thumbnailsToStore.length === 0) { return; }
        this.smartdocService.storeThumbnailSortResult(
            openedId,
            this.thumbnailsToStore.map((thumbnail, index) => ({
                id: thumbnail.id,
                dropAreaId: subFolder?.id,
                sortWeight: index + position,
            } as ISmartDocSortResultFormModel)));
        this.thumbnailsToStore = [];
        this.assignedAThumbnail = true;
    }

    /**
     * Detailansicht der Vorschaubilder
     *
     * @param {ISmartDocJobThumbnailViewModel} thumbnail thumbnail
     */
    public onClickThumbnail(thumbnail: ISmartDocJobThumbnailViewModel): void {
        this.waiterService.show();

        const contentThumbnail = this.store.selectSnapshot((state: ISmartDocStateParentDefinition) => state.smartdoc.smartDocThumbnailContents?.find(cont => cont.id === thumbnail.id));

        if (!!contentThumbnail) {
            this.modalService.open(ThumbnailDialogComponent, {
                minWidth: '700px',
                data: { content: contentThumbnail.content, thumbnail: thumbnail },
            });

            this.waiterService.hide();
        } else {
            const financingId = this.store.selectSnapshot((state: IFinancingStateModel) => state.financing?.id) as string;

            this.smartdocService.loadThumbnailContent(financingId, thumbnail.id).pipe(
                take(1),
                map(content => this.modalService.open(ThumbnailDialogComponent, {
                    minWidth: '700px',
                    data: { content: content, thumbnail: thumbnail },
                })),

            ).subscribe(() => this.waiterService.hide());
        }
    }
    //--------------------------------------------------------------------------
    //                 P R I V A T E  M E T H O D S
    //--------------------------------------------------------------------------

    /**
     * opens the smartdoc-waiter-dialog, not the default dialog
     * and this is important to know
     *
     * @param {ISmartDocStateModel} state ISmartDocStateModel
     */
    private openWaiterDialog(state: ISmartDocStateModel) {
        if (state.openedId !== undefined) {
            this.modalService.open(SmartDocWaiterDialogComponent, { data: {
                aaguid: state.openedId,
            }});
        } else {
            this.smartdocService.close();
        }
    }
}
