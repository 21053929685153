import { WorkflowType } from '../../enums';
import { GwbAccess } from '../../enums/scoring';
import { IMidtermModel } from '../../interfaces';

export const GET_CLIENT_RATING: IMidtermModel = {

    /**
     * Name für Übersetzungen
     */
    midtermName: 'getClientRating',

    /**
     * Kundennummer / NDG
     */
    ndg: { type: WorkflowType.String },

    /**
     * GWB Access
     */
    gwbAccess: { type: WorkflowType.Enum, enumObject: GwbAccess, enumLabel: 'gwbAccess' },
}
