import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_DOCUMENT_DTO } from './customer-document-dto';

export const CREATE_OR_UPDATE_DOCUMENTS: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'documents',

    ndg: { type: WorkflowType.String },

    createOrUpdateModel: { type: WorkflowType.Array, child: CUSTOMER_DOCUMENT_DTO },
}
