<div class="ucba-table" #ucbaTable>
    @for (row of allRowsWithType$(); track $index;) {
        <div class="ucba-table-row" [class.ucba-table-header]="row.type === 'header'" [class.highlighted-light]="row.type === 'highlightedLight'" [class.highlighted-dark]="row.type === 'highlightedDark'" [class.no-alternating-column-colors]="noAlternatingColumnColors()">
                @if (row.row.rowHeader(); as rowHeader) {
                    <div class="ucba-table-row-header">
                        <ng-template [cdkPortalOutlet]="rowHeader"></ng-template>
                    </div>
                }
            <ng-template [cdkPortalOutlet]="row.row"></ng-template>
        </div>
    }
    <ng-content></ng-content>
</div>