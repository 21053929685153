import { WorkflowType } from '../../enums';
import { GwbAccess } from '../../enums/scoring';
import { SelectionType } from '../../enums/scoring/selection-type.enum';
import { IMidtermModel } from '../../interfaces';

import { SCORING_DATA } from './scoring-data';

export const RATING: IMidtermModel =
{
    midtermName: 'rating',

    /**
     * gwbAccess
     */
    gwbAccess: { type: WorkflowType.Enum, enumObject: GwbAccess, enumLabel: 'gwbAccess' },

    /**
     * "H"=hist. Data display (BOHR), "B"= actual Rating Display (BOBE), " "= All Screen Data.
     */
    selection: { type: WorkflowType.Enum, enumObject: SelectionType, enumLabel: 'SelectionType' },

    /**
     * Weitere Daten
     */
    scoringData: { type: WorkflowType.Object, child: SCORING_DATA },
}
