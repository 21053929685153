import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { sort } from 'fast-sort';
import { Observable, Subject, map, takeUntil } from 'rxjs';

import { IRealEstateItem } from '../real-estate/real-estate.component';

import { FinancingService, IBase, IFinancing, IFinancingStateParentDefinition, ValueStorageType } from './../../../../data';
import { OverwriteInputComponent } from './../base-input/base-input';
/**
 * Komponente für die Darstellung und ermittlung von besicherten Objecten
 */
@Component({
    selector: 'finprocess-secured-realestates-array-display',
    templateUrl: './secured-realestates-array-display.component.html',
    styleUrls: ['./secured-realestates-array-display.component.scss'],
})
export class SecuredRealestatesArrayDisplayComponent<T extends IBase> extends OverwriteInputComponent<T> implements OnInit {

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Platzhalter
     */
    @Input()
    public placeholder!: string;

    /**
     * Maximale Zeichenanzahl
     */
    @Input()
    public maxLength?: number;
   
    public realEstates$!: Observable<IRealEstateItem[]>;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Liefert den anzuzeigenden Wert
     *
     * @param {string} id test
     * @param {IRealEstateItem[]} realEstates test
     * @returns {IRealEstateItem | undefined} Text-Wert
     */
    public getCurrentIRealEstateItem(id: string, realEstates: IRealEstateItem[]): IRealEstateItem | undefined {
        return realEstates.find(re => re.realEstate.id === id);
    }

    /**
     * Liefert den anzuzeigenden Wert
     *
     * @returns {boolean | undefined} Boolean-Wert
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get currentValue(): any {
        if (this.entity === undefined) {
            return undefined;
        }

        return this.entity[this.fieldName] as string[];
    }

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     *
     * @returns {string} Anzeigeschlüssel
     */
    public get translatedOriginalValueKey(): string {
        if (this.originalValue === undefined || this.originalValue === null) {
            return 'financing.features.financing-processing.unset';
        }

        return this.originalValue.toString();
    }

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.String, financingService);
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        super.ngOnInit();
        this.realEstates$ = this.financing$.pipe(takeUntil(this.onDestroy$), map(it => {
            const temp: IRealEstateItem[] = [];

            if (it === undefined) {
                return temp;
            }

            it.realEstates.forEach(realEstate => temp.push({
                realEstate: realEstate,
                isSelected: false,
                position: realEstate.position,
            } as IRealEstateItem))

            const sorted = sort(temp).asc(b => b.realEstate.position)


            return sorted;
        }));
    }

    /**
     * Speichert den Overwrite
     *
     * @param {string} value Zu speichernder Wert
     */
    public save(value?: T[keyof T & string]): void {
        const toSaveValue = value !== undefined && value !== this.originalValue ? value : undefined;
        super.save((toSaveValue !== '' ? toSaveValue : undefined));
    }
}
