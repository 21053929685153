import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'app/modules/shared/util';

/**
 * Custom Pipe for formatting currency
 */
@Pipe({
    name: 'formatCurrency',
})
@Injectable({
    providedIn: 'root',
})
export class FormatCurrencyPipe implements PipeTransform {
    
    /**
     * format Currency
     * e.g.: 3000 -> € €3.000,00
     *
     * @param {number | undefined} value number
     * @returns {string} formatted currency
     */
    public transform(value: number | undefined | null): string {
        return HelperService.formatCurrency(value);
    }
}
