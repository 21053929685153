@if (data.addNote) {
    <h1 mat-dialog-title class="mb-2 cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{'financing.features.financing-processing.notes.addNote' | translate }}</h1>
}
@if (!data.addNote) {
    <h1 mat-dialog-title class="mb-2 cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{'financing.features.financing-processing.notes.editNote' | translate }}</h1>
}
@if (data.showCustomerHint === true) {
    <div class="customer-hint">{{'financing.features.financing-processing.notes.hint-customer' | translate}}</div>
}

<div mat-dialog-content>
    <ckeditor [editor]="editor" [(ngModel)]="editorData" (ngModelChange)="onEditorDataChange($event)" [config]="editorConfig" ngDefaultControl name="editorData">
    </ckeditor>
    <div>{{charsRemaining}} / {{maxCharCount}}</div>
    <div *ngIf="blockSaveButton" class="warning-text">
        {{'financing.features.financing-processing.notes.warningNote' | translate: {charsRemaining: charsRemaining - maxCharCount} }}
    </div>
</div>

<div mat-dialog-actions class="dialog-actions mb-1">
    <button mat-raised-button [disabled]="blockSaveButton" color="primary" (click)="saveNote()">
        {{'financing.features.financing-processing.notes.save' | translate}}
    </button>
    <button mat-raised-button mat-dialog-close>
        {{'financing.features.financing-processing.notes.cancel' | translate}}
    </button>
</div>