export enum GwbAccess {
    /* 
    * Read current rating function
    * only for <see cref="UcbaScoringService.GetClientRating(string, string,GwbAccess)"/>
    */ 
    ReadCurrentRating,

    /* 
    * Read stand alone rating function
    * only for <see cref="UcbaScoringService.GetClientRating(string, string,GwbAccess)"/>
    */
    ReadStandAloneRating,

    /* 
    * Read applied rating function
    * only for <see cref="UcbaScoringService.GetClientRating(string, string,GwbAccess)"/>
    */
    ReadAppliedRating,

    /* 
    * For Scoring calculation
    * only for <see cref="UcbaScoringService.CalculateNewScoring(Model.ScoringData, string, GwbAccess)"/>
    */ 
    ScoringCalculation,

    /* 
    * For Read soft facts
    * only for <see cref="UcbaScoringService.CalculateNewScoring(Model.ScoringData, string, GwbAccess)"/>
    */ 
    ReadSoftFacts

}
