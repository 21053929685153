<div fxFill fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <finprocess-sub-header
            label="Belege">
        </finprocess-sub-header>
        <div (click)="toggleShowAll()" fxLayout="row" fxLayoutAlign="end center" class="show-all-button">
            <mat-icon *ngIf="showAll">visibility</mat-icon>
            <mat-icon *ngIf="!showAll">visibility_off</mat-icon>
            <i>{{'financing.features.financing-processing.document-viewer.showAll' | translate}}</i>
        </div>
    </div>
    <finprocess-document-list [documents]="(specificDocuments$ | async) ?? []" layout="column"></finprocess-document-list>
    <ng-container *ngIf="showAll">
        <finprocess-sub-header
            label="Weitere Belege">
        </finprocess-sub-header>
        <finprocess-document-list [documents]="(otherDocuments$ | async) ?? []" layout="column"></finprocess-document-list>
    </ng-container>
</div>
