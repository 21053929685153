import { Component, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Store } from '@ngxs/store';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, EntityClassType, FinancingService, IFinancingStateParentDefinition, ValueStorageType } from 'app/modules/financing/data';
import { Observable, of, take } from 'rxjs';

/**
 * Common for all Asset
 */
@Component({
    template: '',
})

export class AssetCommonComponent {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    public entityClassType = EntityClassType;

    public inputFlexDirection = 'column';

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Observable immer Schreibschutz mit Bearbeitungsmodus
     */
    public alwaysReadonly$: Observable<boolean> = of(true);

    /**
     * Checkbox
     */
    @ViewChild('checkboxRef') public checkbox!: MatCheckbox;

    /**
     * Standard Constructor
     * 
     * @param {AssetService} assetService AssetService-Injector
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     */
    public constructor(
        protected assetService: AssetService,
        protected enumTranslate: EnumTranslationPipe,
        protected store: Store,
        protected financingService: FinancingService,
    ) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
    }

    /**
     * Set Asset as Active - prevent the default checkbox change behavior and set checkbox to the correct value
     * 
     * @param {string} assetId assetId
     * @param {boolean} isActive is active
     */
    public onCheckboxChange(assetId: string, isActive: boolean): void {
        this.setAssetActive(assetId, isActive).subscribe(success => {
            if (!!success && success === true) {
                this.checkbox.checked = isActive;
            } else {
                this.checkbox.checked = !isActive;
            }
        });
    }

    /**
     * Set Asset as Active
     * 
     * @param {string} assetId assetId 
     * @param { boolean }isActive boolean
     * @returns {Observable<boolean>} Observable for Error Handling
     */
    public setAssetActive(assetId: string, isActive: boolean): Observable<boolean> {
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        
        if (!financingContainerID) {
            return of(false);
        }
    
        return new Observable<boolean>(subscriber => {
            this.assetService.setAssetActive(financingContainerID, assetId, isActive).pipe(take(1)).subscribe({
                next: () => {
                    subscriber.next(true);
                    subscriber.complete();
                },
                error: () => {
                    subscriber.next(false);
                    subscriber.complete();
                },
                complete: () => {
                    if (!subscriber.closed) {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                },
            });
        });
    }    
}
