import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LoggerModule } from 'ngx-logger';

import { IConfigurationData } from './interfaces';
import { APP_INFO_TOKEN, CONSTANTS, ConfigService, ENVIRONMENT, FinProcessMatPaginatorIntlService, LogService, PreLoadEnumTranslationLoaderService, PreLoadTranslationLoaderService } from './services';

export { ConfigService, PreLoadTranslationLoaderService, HelperService, LogService } from './services';
export * from './interfaces';
export * from './types';
export * from './enums';
/**
 * Modul für geteilte Utils
 */
@NgModule({
    imports: [
        LoggerModule.forChild(),
    ],
})
export class SharedUtilModule {
    /**
     * Gibt das Modul mit Providern zurück
     *
     * @param {IConfigurationData} config Konfigurationsdaten
     * @returns {ModuleWithProviders<SharedUtilModule>} Modul mit Providern
     */
    public static forRoot(config: IConfigurationData): ModuleWithProviders<SharedUtilModule> {
        return {
            ngModule: SharedUtilModule,
            providers: [
                { provide: APP_INFO_TOKEN, useValue: config.appInfo },
                { provide: CONSTANTS, useValue: config.constants },
                { provide: ENVIRONMENT, useValue: config.environment },
                ConfigService,
                PreLoadEnumTranslationLoaderService,
                PreLoadTranslationLoaderService,
                LogService,
                { provide: MatPaginatorIntl, useClass: FinProcessMatPaginatorIntlService },
            ],
        };
    }
}
