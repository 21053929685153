import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Kellernutzung
 */
export enum BasementUsage {
    /**
     * Für Wohnzwecke genutzt/nutzbar
     */
    LivingPurposes = 0,

    /**
     * Gut ausgestatteter Keller (z.B. Nutzkeller)
     */
    Utilisable = 1,

    /**
     * Nicht für Wohnzwecke nutzbar
     */
    Storage = 2,
}

export namespace BasementUsage {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {BasementUsage | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: BasementUsage | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'BasementUsage', BasementUsage, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('BasementUsage', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {BasementUsage | undefined | null} value Enum-Wert
     * @param {BasementUsage} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: BasementUsage | undefined | null, flag: BasementUsage): boolean | undefined => (value === 0 && flag === BasementUsage.LivingPurposes ? true : EnumSettings.hasFlag(value, flag));
}
