import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MandantType } from '@ntag-ef/finprocess-enums';

import { IAppInfo, IConstants, IEnvironment } from './../../interfaces';

export const APP_INFO_TOKEN = new InjectionToken<IAppInfo>('APP_INFO_TOKEN');
export const ENVIRONMENT = new InjectionToken<IEnvironment>('ENVIRONMENT');
export const CONSTANTS = new InjectionToken<IConstants>('CONSTANTS');

/**
 * Service, um Konfigurationen abzurufen
 */
@Injectable()
export class ConfigService {
    /**
     * Konstruktor
     *
     * @param {IAppInfo} appInfo Allgemeine Anwendungsinformationen
     * @param {IEnvironment} environment Umgebungsspezifische Konfigurationen
     * @param {IConstants} constants Konstanten
     */
    public constructor(
        @Inject(APP_INFO_TOKEN) private appInfo: IAppInfo,
        @Inject(ENVIRONMENT) private environment: IEnvironment,
        @Inject(CONSTANTS) private constants: IConstants,
    ) {}

    /**
     * Gibt die Allgemeinen Anwendungsinformationen zurück
     *
     * @returns {IAppInfo} Allgemeine Anwendungsinformationen
     */
    public getAppInfo(): IAppInfo {
        return this.appInfo;
    }

    /**
     * Gibt die Konstanten zurück
     *
     * @returns {IConstants} Konstanten
     */
    public getConstants(): IConstants {
        return this.constants;
    }

    /**
     * Gibt die Umgebungsspezifischen Konfigurationen zurück
     *
     * @param {MandantType} mandantType Mandant
     * @returns {IEnvironment} Umgebungsspezifische Konfigurationen
     */
    public getEnvironment(mandantType?: MandantType): IEnvironment {
        if (
            mandantType === undefined ||
            this.environment.mandantConfig === undefined ||
            this.environment.mandantConfig[mandantType] === undefined
        ) {
            return this.environment;
        }
        return {
            ...this.environment,
            mandantConfig: undefined,
            ...this.environment.mandantConfig[mandantType],
        };
    }

    /**
     * Gibt den Wert einer Umgebungsspezifischen Konfiguration zurück
     *
     * @param {string} key Schlüssel
     * @param {MandantType} mandantType Mandant
     * @returns {any} Umgebungsspezifische Konfiguration zu Schlüssel
     */
    public getEnvironmentValue(
        key: string,
        mandantType?: MandantType,
    ): unknown {
        if (
            mandantType === undefined ||
            this.environment.mandantConfig === undefined ||
            this.environment.mandantConfig[mandantType] === undefined ||
            (this.environment.mandantConfig[mandantType] as IEnvironment)[key] === undefined
        ) {
            return this.environment[key];
        }
        return (this.environment.mandantConfig[mandantType] as IEnvironment)[key];
    }
}
