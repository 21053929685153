import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { UserService } from 'app/modules/auth/data';
import { AdditionalCode, ApprovalCompetence, ApprovalDecision, AssetState, DocumentService, EntityClassType, FinancingService, FinancingState, IApprovalData, IApprovalProductPackageCompetences, IApproveProductPackageRequest, IFinancing, IFinancingStateParentDefinition, IFireRiskDecision, IProductPackage, ISetApproverRequest, ReasonCode } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { IApprovalCompetence, IMasterdataParentDefinition } from 'app/modules/masterdata/data';
import { HelperService, IEditor, ISelectButtonAction, ProductPackageStatus, UUID } from 'app/modules/shared';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, filter, map, mergeMap, of, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';

import { IFileData } from '../risk-decision/risk-decision.component';

enum SelfCompetenceAction {
    Approve,
    Transfer,
}

interface IReadonlyStatus {
    visible: boolean;
    readonly: boolean;
}

interface IFinancingTableData {
    category?: string;
    amount?: number;
}

/**
 * Genehmigungsseite
 */
@Component({
    selector: 'finprocess-approval',
    templateUrl: './approval.component.html',
    styleUrls: ['./approval.component.scss'],
})
export class ApprovalComponent implements OnInit, OnDestroy {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public EntityClassType = EntityClassType;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ApprovalDecision = ApprovalDecision;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ApprovalCompetence = ApprovalCompetence;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ProductPackageStatus = ProductPackageStatus;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SelfCompetenceAction = SelfCompetenceAction;

    /**
     * Aktuelles Produktpaket
     */
    public productPackage$?: Observable<IProductPackage>;

    /**
     * Fire Entscheidung zum Produktpaket
     */
    public fireData$: Observable<IFireRiskDecision> = new Observable();

    /**
     * Zeigt die Fire Entscheidung Vertriebskompetenz an
     */
    public fireSalesCompetence$: Observable<boolean> = new Observable();

    /**
     * Ausgewählter Genehmiger
     */
    public approver?: IEditor;

    /**
     * Liste an möglichen Genehmigern
     */
    public approvers$?: Observable<IEditor[]>;

    /**
     * Gefilterte Liste an möglichen Genehmigern
     */
    public approversFiltered$?: Observable<IEditor[]>;

    /**
     * Aktuelle Eingabe für das Autocomplete für Genehmiger
     */
    public approverFilter$: BehaviorSubject<string | IEditor> = new BehaviorSubject<string | IEditor>('');

    /**
     * Genehmigungsentscheidung
     */
    public approvalDecision?: ApprovalDecision;

    /**
     * Stellungnahme Experte
     */
    public expertStatement?: string;

    /**
     * Stellungnahme Genehmiger
     */
    public approverStatement?: string;

    /**
     * Approval Comptence Art Observable
     */
    public approvalCompetence$ = new BehaviorSubject<ApprovalCompetence | undefined>(undefined);

    /**
     * Approval Comptence Art
     */
    public productPackageMinCompetence?: IApprovalCompetence;

    /**
     * Approval Decision Enum
     */
    public approvalDecisions: ProductPackageStatus[] = [];

    /**
     * approval data
     */
    public approvalData!: IApprovalData;

    /**
     * fire document data
     */
    public fireDocumentData$?: Observable<IFileData | undefined>;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonlyExpert$!: Observable<boolean>;

    /**
     * Observable Schreibschutz für Auswahl Innerhalb der VGR
     */
    public inApprovalPoliciesReadonly$!: Observable<boolean>;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonlyApprover$!: Observable<boolean>;

    /**
     * Status des Expertenfeldes
     */
    public expertStatementStatus: IReadonlyStatus = { visible: false, readonly: true };

    /**
     * Status des Genehmigerfeldes
     */
    public approverStatementStatus: IReadonlyStatus = { visible: false, readonly: true };

    /**
     * Actions für den Button für Eigenkompetenz
     */
    public actions: ISelectButtonAction[] = [
        {
            label: 'Genehmigen',
            disabled: () => !this.expertStatement,
            action: () => this.setApprovalDecision(ApprovalDecision.Approved, this.expertStatement),
            value: SelfCompetenceAction.Approve,
        },
        {
            label: 'An Kompetenzträger übermitteln',
            disabled: () => !this.approver,
            action: () => this.setToApprovalAwaiting(),
            value: SelfCompetenceAction.Transfer,
        },
    ];

    /**
     * Ausgewählte Action des Eigenkompetenz Select Button
     */
    public selectedAction: ISelectButtonAction = this.actions[0];

    /**
     * Produktpaket ID
     */
    public productPackageId?: string;

    /**
     * Hinweis für Summe des Produktpakets
     */
    public productPackageSumHint?: string;

    /**
     * Ladezustand der Komponente
     */
    public loading = false;

    /**
     * Ladezustand des FIRE Dokuments
     */
    public fireDocumentLoading = false;

    /**
     * Ladezustand der FIRE Daten
     */
    public fireDataLoading = true;

    /**
     * Ladezustand der Genehmigungskompetenzlevel
     */
    public approvalCompetenceLoading = false;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Mitteltabelle Columns und Data
     */
    public displayedColumns: string[] = ['category', 'amount'];
    public useOfFundsDataSource = new MatTableDataSource<IFinancingTableData>();
    public sourceOfFundsDataSource = new MatTableDataSource<IFinancingTableData>();

    /**
     * Collateral Requirement Successful
     */
    public collateralRequirementSuccessful = false;

    /**
     * Konstruktor
     * 
     * @param {FinancingService} financingService FinancingService
     * @param {ActivatedRoute} activatedRoute ActivatedRoute
     * @param {WaiterService} waiterService WaiterService
     * @param {NotificationService} notificationService NotificationService
     * @param {TranslateService} translate TranslateService
     * @param {DatePipe} datePipe DatePipe
     * @param {Store} store Store
     * @param {DocumentService} documentService DocumentService
     * @param {UserService} userService UserService
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef
     * @param {Router} router Router-Injektors
     */
    public constructor(
        private financingService: FinancingService,
        private activatedRoute: ActivatedRoute,
        private waiterService: WaiterService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private store: Store,
        private documentService: DocumentService,
        public userService: UserService,
        private cRef: ChangeDetectorRef,
        private router: Router,
    ) { }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.productPackage$ = combineLatest([
            this.store.select(FinancingState.productPackagebyId),
            this.activatedRoute.params.pipe(
                map(params => params['productPackageID'] as UUID),
                distinctUntilChanged(),
                filter(id => id !== undefined),
                tap(id => { this.loading = true; this.productPackageId = id }),
            ),
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([productPackageSelectorFn, productPackageId]) => productPackageSelectorFn(productPackageId)),
            filter((productPackage): productPackage is IProductPackage => productPackage !== undefined),
            tap(productPackage => {
                const financingRequirement = productPackage.assignProducts.reduce((sum, current) => sum + current.creditAmount, 0);
                this.productPackageSumHint = financingRequirement === 0 ? this.translate.instant('financing.features.financing-processing.approval.hintSumZero') : undefined;
                this.loading = false;
            }),
            shareReplay(1),
        )

        this.approvers$ = this.productPackage$.pipe(
            takeUntil(this.onDestroy$),
            switchMap(productPackage => {
                if (!productPackage.inApprovalPolicies) {
                    return of([]);
                }

                if (!!productPackage.approver) {
                    return of([productPackage.approver]);
                }

                return this.financingService.getApproversList(productPackage.id);
            }),
            shareReplay(1),
        );

        this.approversFiltered$ = combineLatest([
            this.approvers$,
            this.approverFilter$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([approvers, approverFilter]) => {
                if (approverFilter === undefined || approverFilter === '') {
                    return approvers;
                }

                const filterName = typeof approverFilter === 'string' ? approverFilter : this.getNameFromUser(approverFilter);

                return approvers.filter(approver => filterName.split(' ').every(filterWord => this.getNameFromUser(approver).toLowerCase().includes(filterWord.toLowerCase())));
            }),
        )

        this.fireDocumentData$ = this.productPackage$.pipe(
            takeUntil(this.onDestroy$),
            switchMap(productPackage => this.financingService.getProductPackageFireDocumentInfos(productPackage.productPackageID)),
        );

        combineLatest([
            this.productPackage$,
            this.approvalCompetence$,
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(([productPackage, approvalCompetence]) => {
            const statusEntries = sort(productPackage.statusEntries).desc(statusEntry => statusEntry.created);

            this.approver = productPackage.approver;
            this.approvalDecision = this.productPackageStatusToApprovalDecision(productPackage.status);
            const approverStatement = statusEntries.find(it => [ProductPackageStatus.ApprovalRejected, ProductPackageStatus.ApprovalDenied, ProductPackageStatus.ApprovalGranted].includes(it.status))?.statusInformation ?? '';
            const expertStatement = statusEntries.find(it => it.status === ProductPackageStatus.ApprovalAwaiting)?.statusInformation ?? '';
            this.expertStatement = approvalCompetence === ApprovalCompetence.SelfCompetence ? approverStatement : expertStatement;
            this.approverStatement = approverStatement;
        });

        this.fireData$ = this.productPackage$.pipe(
            takeUntil(this.onDestroy$),
            mergeMap(productPackage => this.financingService.getManualFireRiskDecisionData(productPackage.id)),
            filter((fireData): fireData is IFireRiskDecision => fireData !== undefined && fireData !== null),
            tap(() => { this.fireDataLoading = false; }),
            shareReplay(1),
        );

        this.fireSalesCompetence$ = this.fireData$.pipe(
            takeUntil(this.onDestroy$),
            map(fireData => fireData.ergAdditionalCode === AdditionalCode.AC051 && fireData.reasonCode === ReasonCode.RC051 && !fireData.ergTextStatusFinal.toLowerCase().includes('ablehnung')),
        );

        combineLatest([
            this.productPackage$,
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.financingContainerID).pipe(
                filter((containerId): containerId is string => containerId !== undefined),
            ),
            this.fireSalesCompetence$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(([previousProductPackage, previousFinancingContainerId, previousSalesCompetence], [currentProductPackage, currentFinancingContainerId, currentSalesCompetence]) =>
                previousProductPackage.id === currentProductPackage.id && previousFinancingContainerId === currentFinancingContainerId && previousProductPackage.inApprovalPolicies === currentProductPackage.inApprovalPolicies && previousSalesCompetence === currentSalesCompetence),
            filter(([productPackage, , salesCompetence]) => productPackage.inApprovalPolicies !== undefined || !salesCompetence),
            mergeMap(([productPackage, financingContainerId]) => {
                this.approvalCompetenceLoading = true;
                return this.financingService.getApprovalCompetence(financingContainerId, productPackage.id);
            }),
            filter((approvalCompetence): approvalCompetence is IApprovalProductPackageCompetences => approvalCompetence !== undefined),
        ).subscribe(approvalCompetence => {
            this.approvalCompetence$.next(approvalCompetence.approvalCompetenceLevel);
            this.productPackageMinCompetence = this.store.selectSnapshot((it: IMasterdataParentDefinition) => it.masterdata.approvalCompetence).find(it => it.minLevel === approvalCompetence.requiredMinCompetence);
            this.approvalCompetenceLoading = false;
            this.setApprovalApprovalDecisions(approvalCompetence.approvalCompetenceLevel);
            this.cRef.detectChanges();
        });

        this.fieldReadonlyExpert$ = combineLatest([
            this.financingService.editingReadonlyWithEditmodeExpert$,
            this.productPackage$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            shareReplay(1),
            map(([readonly, productPackage]) => readonly || ![ProductPackageStatus.DecisionSuccesful, ProductPackageStatus.ApprovalRejected].includes(productPackage.status)),
        );

        this.inApprovalPoliciesReadonly$ = combineLatest([
            this.productPackage$,
            this.fieldReadonlyExpert$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([productPackage, fieldReadOnly]) => fieldReadOnly || productPackage.status !== ProductPackageStatus.DecisionSuccesful),
        );

        this.fieldReadonlyApprover$ = combineLatest([
            this.financingService.editingReadonlyWithEditmode$,
            this.productPackage$,
            this.userService.user$,
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([editMode, productPackage, currentUser]) => editMode || productPackage.status !== ProductPackageStatus.ApprovalAwaiting || productPackage.approver?.id !== currentUser?.id),
            shareReplay(1),
        );

        combineLatest([
            this.fieldReadonlyExpert$,
            this.fieldReadonlyApprover$,
            this.productPackage$,
            this.approvalCompetence$,
        ]).pipe(
            takeUntil(this.onDestroy$),
        )
            .subscribe({
                next: ([fieldReadonlyExpert, fieldReadonlyApprover, productPackage, approvalCompetence]) => {
                    this.expertStatementStatus = {
                        visible: productPackage.status >= ProductPackageStatus.DecisionSuccesful && approvalCompetence !== ApprovalCompetence.BackOffice && approvalCompetence !== undefined,
                        readonly: fieldReadonlyExpert || approvalCompetence === ApprovalCompetence.BackOffice ||
                            ![ProductPackageStatus.DecisionSuccesful, ProductPackageStatus.ApprovalRejected].includes(productPackage.status),
                    }

                    this.approverStatementStatus = {
                        visible: (productPackage.status >= ProductPackageStatus.ApprovalAwaiting && approvalCompetence === ApprovalCompetence.ExternalCompetence) || approvalCompetence === ApprovalCompetence.BackOffice,
                        readonly:
                            // Marktfolge: Experte beschreibt selbst das Feld
                            !(approvalCompetence === ApprovalCompetence.BackOffice && !fieldReadonlyExpert && productPackage.status === ProductPackageStatus.DecisionSuccesful) &&
                            // Fremdkompetenz: Genehmiger beschreibt das Feld
                            !(approvalCompetence === ApprovalCompetence.ExternalCompetence && !fieldReadonlyApprover && productPackage.status === ProductPackageStatus.ApprovalAwaiting),


                    }
                },
            });

        // eslint-disable-next-line complexity
        this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing).pipe(
            filter((financing): financing is IFinancing => financing !== undefined),
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
        ).subscribe(financing => {
            this.initializeSourceOfFunds();
            this.initializeUseOfFunds(financing);
        });

        this.store.select(AssetState.validateCollateralRealEstatesForApproval).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
            if (!!res) {
                if (res.length > 0) {
                    this.collateralRequirementSuccessful = res.every(item => item.valid);
                } else {
                    this.collateralRequirementSuccessful = true; 
                }
            } else {
                this.collateralRequirementSuccessful = false; 
            }
        });
    }


    /**
     * Download excel export
     */
    public downloadExcelExport(): void {
        const financingPlanId = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing?.id);
        if (!!financingPlanId) {
            this.waiterService.show();
            this.financingService.financingMapExport(financingPlanId).pipe((take(1))).subscribe({
                next: async file => {
                    if (!!file) {
                        const now = new Date();
                        const blob = new Blob([file], { type: 'application/zip' });
                        const mainDebitor = this.store.selectSnapshot(FinancingState.mainDebitor);
                        const mainDebitorName = !!mainDebitor ? `${mainDebitor.lastName}_${mainDebitor.firstName}` : '';
                        const formattedDate = this.datePipe.transform(now, 'yyyyMMddHHmmss');
                        await HelperService.downloadFileFromBlob(blob, `Auftragsmappe_Export_${mainDebitorName}_${formattedDate}`);
                        this.waiterService.hide();
                    }
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.approval.downloadExcelError'));
                },
            })
        }
    }

    /**
     * Sets the product package to ApprovalAwaiting and assigns the chosen approver to the product package
     */
    public setToApprovalAwaiting(): void {
        const financingContainerId = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        const approver = this.approver;
        if (!approver || !this.expertStatement || !financingContainerId || !this.productPackageId) {
            return;
        }

        const request: ISetApproverRequest = {
            containerId: financingContainerId,
            productPackageId: this.productPackageId,
            statement: this.expertStatement,
            userId: approver.id,
        };

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.setApprover(request, approver)),
            take(1),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.approval.assignApproverError'));
            },
            complete: () => this.waiterService.hide(),
        })
    }

    /**
     * Calls the correct request depending on the approval competence
     * 
     * @param {ApprovalDecision} approvalDecision Optional approval decision to override class wide one
     * @param {string} statement Optional statement to override class wide one
     */
    public setApprovalDecision(approvalDecision?: ApprovalDecision, statement?: string): void {
        if (this.approvalCompetence$.value === ApprovalCompetence.BackOffice) {
            this.setApprovalDecisionFromBackOffice(approvalDecision, statement);
        } else {
            this.setApprovalDecisionWithSalesCompetence(approvalDecision, statement);
        }
    }

    /**
     * Sets the approval decision with sales competence for either the expert or approver
     * 
     * @param {ApprovalDecision} approvalDecision Optional approval decision to override class wide one
     * @param {string} statement Optional statement to override class wide one
     */
    public setApprovalDecisionWithSalesCompetence(approvalDecision?: ApprovalDecision, statement?: string): void {
        const request = this.mapApprovalDecision(approvalDecision, statement);

        if (!request) {
            return;
        }

        this.waiterService.show().pipe(
            take(1),
            mergeMap(() => this.financingService.approveProductPackage(request)),
            filter(() => this.approvalCompetence$.value === ApprovalCompetence.ExternalCompetence),
            mergeMap(() => this.notificationService.alert('', this.translate.instant('financing.features.financing-processing.approval.redirected'))),
            mergeMap(() => this.router.navigate(['/dashboard'])),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.approval.approveProductPackageError'));
            },
            complete: () => this.waiterService.hide(),
        })
    }

    /**
     * Sets the approval decision after back office has approved the product package
     * 
     * @param {ApprovalDecision} approvalDecision Optional approval decision to override class wide one
     * @param {string} statement Optional statement to override class wide one
     */
    public setApprovalDecisionFromBackOffice(approvalDecision?: ApprovalDecision, statement?: string): void {
        const request = this.mapApprovalDecision(approvalDecision, statement);

        if (!request) {
            return;
        }

        if (request.productPackageStatus !== ProductPackageStatus.ApprovalGranted && request.productPackageStatus !== ProductPackageStatus.ApprovalDenied) {
            throw new Error(`Setting status to ${request.productPackageStatus} is not allowed for market competence`);
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.approveProductPackageWithBackOffice(request)),
            take(1),
        ).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.approval.approveProductPackageError'));
            },
            complete: () => this.waiterService.hide(),
        })
    }

    /**
     * Downloads the fire document
     * 
     * @param {IFileData} fireDocumentData Fire Document Metadata
     */
    public downloadFireDocument(fireDocumentData: IFileData): void {
        if (!!fireDocumentData?.fileId) {
            this.documentService.loadFile(fireDocumentData.fileId).subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, 'application/pdf');

                    if (blob) {
                        await HelperService.openFileFromBlob(blob);
                    }
                },
            });
        }
    }

    /**
     * Angular Lifecycle Hook
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Checks if the given user competence is enough to approve the given product package competence
     * 
     * @param {IApprovalCompetence} productPackageCompetence Competence level required for the product package
     * @param {IApprovalCompetence} userCompetence Competence level of the user
     * @returns {boolean} Is the given user allowed to approve the given product package
     */
    public canUserApproveProductPackage(productPackageCompetence?: IApprovalCompetence, userCompetence?: IApprovalCompetence): boolean {
        if (!productPackageCompetence || !userCompetence) {
            return false;
        }

        return productPackageCompetence.minLevel <= userCompetence.minLevel && productPackageCompetence.lowerLimit <= userCompetence.lowerLimit;
    }

    /**
     * Returns the formatted name for an editor
     * 
     * @param {IEditor} editor Editor 
     * @returns {string} Formatted name
     */
    public getNameFromUser(editor?: IEditor): string {
        return `${editor?.firstName ?? ''} ${editor?.lastName ?? ''}`;
    }

    /**
     * Updates the filter for the approvers list
     * 
     * @param {string} value Filterinhalt
     */
    public filterEditors(value: string): void {
        this.approverFilter$.next(value);
    }

    /**
     *  Entscheidung (Im Falle einer Marktfolge wird "Offene Fragen" nicht angezeigt #12851)
     * 
     * @param {ApprovalCompetence} approvalCompetence ApprovalCompetence
     */
    private setApprovalApprovalDecisions(approvalCompetence: ApprovalCompetence) {
        if (approvalCompetence === ApprovalCompetence.BackOffice || approvalCompetence === ApprovalCompetence.SelfCompetence) {
            this.approvalDecisions = HelperService.getEnumArray(ApprovalDecision, true, [ApprovalDecision.OpenQuestions]) as number[];
        }
        else {
            this.approvalDecisions = HelperService.getEnumArray(ApprovalDecision, true) as number[];
        }
    }

    /**
     * Returns the matching approval decision for a product package status
     * 
     * @param {ProductPackageStatus} status Product package status
     * @returns {ApprovalDecision | undefined} Matching approval decision
     */
    private productPackageStatusToApprovalDecision(status?: ProductPackageStatus): ApprovalDecision | undefined {
        switch (status) {
            case ProductPackageStatus.ApprovalDenied:
                return ApprovalDecision.NotApproved;
            case ProductPackageStatus.ApprovalGranted:
                return ApprovalDecision.Approved;
            case ProductPackageStatus.ApprovalRejected:
                return ApprovalDecision.OpenQuestions;
            default:
                return undefined;
        }
    }

    /**
     * Maps the current approval decision and statement to an approve product package request
     * 
     * @param {ApprovalDecision} approvalDecision Optional approval decision to override class wide one
     * @param {string} statement Optional statement to override class wide one
     * @returns {IApproveProductPackageRequest | undefined} Mapped approve product package request
     */
    private mapApprovalDecision(approvalDecision?: ApprovalDecision, statement?: string): IApproveProductPackageRequest | undefined {
        const financingContainerId = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        const approvalDecisionInternal = approvalDecision ?? this.approvalDecision;
        const statementInternal = statement ?? this.approverStatement;

        if (approvalDecisionInternal === undefined || !statementInternal || !financingContainerId || !this.productPackageId) {
            return undefined;
        }

        let status: ProductPackageStatus = ProductPackageStatus.ApprovalDenied;

        switch (approvalDecisionInternal) {
            case ApprovalDecision.Approved:
                status = ProductPackageStatus.ApprovalGranted;
                break;
            case ApprovalDecision.NotApproved:
                status = ProductPackageStatus.ApprovalDenied;
                break;
            case ApprovalDecision.OpenQuestions:
                status = ProductPackageStatus.ApprovalRejected;
                break;
            default:
                throw new Error(`Unknown Approval Decision ${status}`);
        }

        return {
            productPackageStatus: status,
            containerId: financingContainerId,
            productPackageId: this.productPackageId,
            reason: statementInternal,
        };
    }

    /**
     * Initializes the source of funds table
     */
    private initializeSourceOfFunds(): void {
        const grossFinancingRequirement = this.store.selectSnapshot(FinancingState.grossFinancingRequirement)(true) ?? 0;
        const comfortCreditPlus = this.store.selectSnapshot(FinancingState.comfortCreditPlus)(true) ?? 0;
        const sumOwnCapitalImmediatelyAvailable = this.store.selectSnapshot(FinancingState.sumOwnCapitalImmediatelyAvailable)(true) ?? 0;


        const salesRevenue = this.store.selectSnapshot(FinancingState.salesRevenue)(true) ?? 0;

        const sourceOfFunds: IFinancingTableData[] = [
            { category: 'Langfristige Finanzierung', amount: grossFinancingRequirement },
            { category: 'Ergänzungsdarlehen', amount: comfortCreditPlus },
            { category: 'Zwischenfinanzierung', amount: salesRevenue },
            { category: 'Eigenmittel sofort verfügbar', amount: sumOwnCapitalImmediatelyAvailable },
            { category: 'Summe', amount: grossFinancingRequirement + comfortCreditPlus + salesRevenue + sumOwnCapitalImmediatelyAvailable },
        ];
        this.sourceOfFundsDataSource = new MatTableDataSource<IFinancingTableData>(sourceOfFunds);
    }

    /**
     * Initializes the use of funds table
     * 
     * @param {IFinancing} financing Financing
     */
    private initializeUseOfFunds(financing: IFinancing): void {
        const mainRealEstate = this.store.selectSnapshot(FinancingState.mainRealEstate);
        const sumAdditionalCosts = this.store.selectSnapshot(FinancingState.sumAdditionalCosts)(true) ?? 0;
        const sumFinancingAdditionalCharges = this.store.selectSnapshot(FinancingState.sumFinancingAdditionalCharges)(true) ?? 0;
        const purchasePrice = OverwriteHelperService.getMergedOverwriteValue(financing, 'purchasePrice', financing.purchasePrice) ?? 0;
        const reschedulingAmount = mainRealEstate !== undefined ? OverwriteHelperService.getMergedOverwriteValue(mainRealEstate, 'reschedulingAmount', mainRealEstate.reschedulingAmount) ?? 0 : 0;
        const otherCosts = mainRealEstate !== undefined ? OverwriteHelperService.getMergedOverwriteValue(mainRealEstate, 'otherCosts', mainRealEstate.otherCosts) ?? 0 : 0;

        const useOfFunds: IFinancingTableData[] = [
            { category: 'Kaufpreis/Errichtung', amount: purchasePrice },
            { category: 'Bau-/Sanierungskosten (mit Vertrag)', amount: 0 },
            { category: 'Bau-/Sanierungskosten (ohne Vertrag)', amount: 0 },
            { category: 'Umschuldung', amount: reschedulingAmount },
            { category: 'Einrichtung', amount: otherCosts },
            { category: 'Kaufnebenkosten', amount: sumAdditionalCosts },
            { category: 'Finanzierungsnebenkosten', amount: sumFinancingAdditionalCharges },
            { category: 'Summe', amount: purchasePrice + reschedulingAmount + otherCosts + sumAdditionalCosts + sumFinancingAdditionalCharges },
        ];

        this.useOfFundsDataSource = new MatTableDataSource<IFinancingTableData>(useOfFunds);
    }
}
