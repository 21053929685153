import { Component, Input } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Komponente zur Eingabe eines Ja/Nein-Feldes
 */
@Component({
    selector: 'finprocess-boolean-input',
    templateUrl: './boolean-input.component.html',
    styleUrls: ['./boolean-input.component.scss'],
})
export class BooleanInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Optionales Label für den True-Wert
     */
    @Input()
    public trueLabel?: string;

    /**
     * Optionales Label für den False-Wert
     */
    @Input()
    public falseLabel?: string;

    /**
     * Liefert den Anzeigeschlüssel zum Korrekten Anzeigen des überschriebenen Wertes
     *
     * @returns {string} Anzeigeschlüssel
     */
    public get translatedOriginalValueKey(): string {
        if (this.originalValue === undefined) {
            return 'financing.features.financing-processing.unset';
        }

        if (this.originalValue === true) {
            return this.trueLabel ?? 'financing.features.financing-processing.true';
        }
        return this.falseLabel ?? 'financing.features.financing-processing.false';
    }

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.Bool, financingService);
    }

    /**
     * Prüft, ob ein Radio zurückgesetzt werden kann und speichert dies ggf.
     *
     * @param {MouseEvent} event Klick-Event
     * @param {MatRadioButton} matRadio Radio-Button
     */
    public checkReset(event: MouseEvent, matRadio: MatRadioButton): void {
        if (!matRadio.disabled && matRadio.checked && this.originalValue === undefined) {
            event.preventDefault();
            matRadio.checked = false;

            super.save(undefined);
        }
    }
}
