import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { FinancingSubStatus } from 'shared/data';

import { FormErrorPipe, StatusPipe } from './pipes';
import { FormatCurrencyPipe } from './pipes/format-currency/format-currency.pipe';
import { FormatNdgPipe } from './pipes/format-ndg/format-ndg.pipe';

// eslint-disable-next-line @typescript-eslint/naming-convention
const enums = { FinancingSubStatus };

/**
 * Modul für allgemeine Pipes
 */
@NgModule({
    declarations: [StatusPipe, FormErrorPipe, FormatCurrencyPipe, FormatNdgPipe],
    imports: [
        CommonModule,
        EnumTranslationModule.forChild({
            customEnums: enums,
        }),
    ],
    exports: [StatusPipe, FormErrorPipe, FormatCurrencyPipe, FormatNdgPipe],
})
export class PipesModule {}
