import { Component, EventEmitter, Output, computed, input, model } from '@angular/core';
import { ControlValueAccessor, FormControl, NgModel, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SubscriptSizing } from '@angular/material/form-field';
import { EntityClassType, FinancingService, IBase, IBaseOverwriteValues, OverwriteValueClassType, ValueStorageType } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Base Input Component
 */
@Component({
    template: '',
})
export class BaseInputComponent<T extends IBase, K extends keyof T & string> implements ControlValueAccessor {

    /**
     * An optional text label for the input.
     * Can also be transcluded via a label with the `label` attribute.
     */
    public label = input<string>();

    /**
     * Additional CSS class for div element, that wraps the input
     */
    public class = input<string>('base-input');

    /**
     * Should the entire label, including the icon for overwrites be hidden
     */
    public hideLabel = input<boolean>(false);

    /**
     * Direction of the flex layout
     */
    public flexDirection = input<'row' | 'row wrap' | 'column'>('row wrap');

    /**
     * Hides the horizontal line below the input
     */
    public hideHorizontalRule = input<boolean>(false);

    /**
     * Observable to set the input as readonly
     */
    public readonly = input.required<Observable<boolean>>();

    /**
     * The entity containing the field, that is edited by this input.
     * The entity is used to determine the original and current value via overwrites.
     */
    public entity = input<T>();

    /**
     * The fieldname of the entity, that is edited by this input.
     * Has to be a key of the entity.
     */
    public fieldName = input.required<K>();

    /**
     * Id given to the label element as an identifier
     */
    public id = model<string>(uuidv4());

    /**
     * If set to true, the input will use the saveOverwriteMethod and save the value as an overwrite.
     * If set to false the input will use the saveInternalFieldMethod and directly mutate the value.
     * 
     * Generally for data provided by FinService or FinAdvisory overwrites should be used to not
     * change the original data provided by the customer.
     */
    public overwrite = input<boolean>(true);

    /**
     * Allow negative values
     */
    public allowNegativeValues = input<boolean>(false);

    /**
     * Liefert den anzuzeigenden Wert
     *
     * @returns {boolean | undefined} Boolean-Wert
     */
    public entityClassType = input<EntityClassType>(EntityClassType.FinancingMap);

    /**
     * Optional validators for the input
     */
    public validators = input<ValidatorFn | ValidatorFn[] | null>();

    /**
     * Optional placeholder for the input
     */
    public placeholder = input<string>('');

    /**
     * Sets the subscript sizing property of mat-form-fields. Default is 'dynamic'.
     * Dynamic increases the size of the form field if errors or hints are displayed.
     * Fixed reserves one line of space for errors and hints.
     */
    public subscriptSizing = input<SubscriptSizing>('dynamic');

    /**
     * If set to true, the background of the input will be transparent
     */
    public transparent = input<boolean>(false);

    /**
     * Event-Emitter für Fehler
     */
    @Output()
    public errors = new EventEmitter<ValidationErrors | null>();


    /**
     * Gets the current value of the entity at the fieldname
     * For the base-input there are no overwrites, so the original value is returned.
     */
    public currentValue = computed(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        return entity[this.fieldName()];
    });

    /**
     * Gets the original value of the entity at the fieldname.
     */
    public originalValue = computed(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        return entity[this.fieldName()];
    });

    /**
     * Checks if one of the validators is a required validator.
     * Used to add a * to the label.
     */
    public isRequired = computed(() => {
        const validators = this.validators();

        if (Array.isArray(validators)) {
            return validators.includes(Validators.required);
        }

        return validators === Validators.required;
    });

    /**
     * Interner Speicher für Fehler
     */
    public errorsInternal: ValidationErrors | null = null;

    /**
     * Diabled State
     */
    public disabled: boolean = false;

    public value?: T[K] | null;

    /**
     * On Touched Handler for ControlValueAccessor
     */
    protected onTouched?: () => void;

    /**
     * On Change Handler for ControlValueAccessor
     */
    protected onChange?: (value: unknown) => void;

    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(protected financingService: FinancingService) {

    }

    /**
     * Updates the value of the internal control
     * 
     * @param {unknown | null} value New value
     */
    public writeValue(value: T[K] | null): void {
        this.value = value;
    }

    /**
     * Registers the onChange Handler for the ControlValueAccessor
     * 
     * @param {Function} fn Function to call on change
     */
    public registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouched Handler for the ControlValueAccessor
     * 
     * @param {Function} fn Function to call on touch
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Updates the disabled state
     * 
     * @param {boolean} isDisabled New disabled state
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Saves the internal value
     * 
     * @param {unknown} value Value of the entity at key fieldName
     */
    public save(value?: T[K]): void {
        const entity = this.entity();
        if (entity === undefined) {
            return;
        }

        const toSaveValue = value !== undefined && value !== this.originalValue() ? value : undefined;
        this.checkForErrors(toSaveValue);

        if (this.errorsInternal !== null) {
            return;
        }

        this.financingService.saveInternalField<T>({
            fieldName: this.fieldName(),
            entityId: entity.id,
            value: toSaveValue,
            entityClassType: this.entityClassType(),
        }).subscribe();
    }

    /**
     * Checks for errors and emits them
     * 
     * @param {unknown} value Value to check
     * @param {NgModel} ngModel NgModel
     * @returns {ValidationErrors | null} Validation Errors
     */
    protected checkForErrors(value: unknown, ngModel?: NgModel): ValidationErrors | null {
        const newErrors = this.validate(value);
        const entity = this.entity();

        if (newErrors !== this.errorsInternal) {
            this.errorsInternal = newErrors;
            this.errors.emit(this.errorsInternal);

            if (!!ngModel) {
                ngModel.control.setErrors(this.errorsInternal);
            }

            if (!!entity?.id) {
                this.financingService.emitInternalFieldError<T>({
                    fieldName: this.fieldName(),
                    entityId: entity.id,
                    error: this.errorsInternal,
                    entityClassType: this.entityClassType(),
                });
            }
        }

        return newErrors;
    }

    
    /**
     * Validates the value and returns errors
     * 
     * @param {unknown} value Value to validate
     * @returns {ValidationErrors | null} Validation Errors
     */
    protected validate(value: unknown): ValidationErrors | null {
        if (this.validators) {
            const errors: ValidationErrors = {};
            const validators = Array.isArray(this.validators) ? this.validators : [this.validators];

            for (const validator of validators) {
                const error = validator(new FormControl(value));

                if (error) {
                    Object.assign(errors, error);
                }
            }

            return Object.keys(errors).length > 0 ? errors : null;
        }
        return null;
    }

}

/**
 * 
 */
@Component({
    template: '',
})
export class OverwriteInputComponent<T extends IBase | IBaseOverwriteValues> extends BaseInputComponent<T, keyof T & string> {

    public overwriteValueClassType = input<OverwriteValueClassType>();

    
    /**
     * Gets the current value of the entity at the fieldname.
     * The overwritten value is taken from the overwrite values if available.
     */
    public currentValue = computed(() => {
        const entity = this.entity();

        if (entity === undefined) {
            return undefined;
        }

        if ('overwriteValues' in entity) {
            return OverwriteHelperService.getMergedOverwriteValue(entity, this.fieldName(), entity[this.fieldName()], true);
        }

        return entity[this.fieldName()];
    });

    protected valueStorageTypeInternal = ValueStorageType.String;

    /**
     * Standard Constructor
     * 
     * @param {ValueStorageType} valueStorageType Value Storage Type
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(valueStorageType: ValueStorageType, financingService: FinancingService) {
        super(financingService);
        this.valueStorageTypeInternal = valueStorageType;
    }

    /**
     * Saves the internal value
     * 
     * @param {unknown} value Value of the entity at key fieldName
     * @param {NgModel} ngModel NgModel
     */
    public save(value?: T[keyof T & string], ngModel?: NgModel): void {
        if (this.entity === undefined) {
            return;
        }

        const toSaveValue = value !== undefined && value !== this.originalValue() ? value : undefined;
        this.checkForErrors(toSaveValue, ngModel);

        if (this.errorsInternal !== null) {
            return;
        }

        this.saveValue(toSaveValue);
    }

    /**
     * Internal method to save the value
     *
     * @param {unknown} toSaveValue The value to be saved.
     */
    private saveValue(toSaveValue: T[keyof T & string] | undefined) {
        const overwriteValueClassType = this.overwriteValueClassType();
        const entity = this.entity();

        if (this.overwrite() && entity && overwriteValueClassType !== undefined) {
            this.financingService.saveOverwriteValue({
                overwriteValueClassType: overwriteValueClassType,
                entityForOverwriteId: entity.id,
                fieldName: this.fieldName(),
                valueStorageType: this.valueStorageTypeInternal,
                value: toSaveValue === undefined || toSaveValue === null ? undefined : toSaveValue.toString(),
            }).subscribe();
        } else if (!this.overwrite() && entity) {
            this.financingService.saveInternalField<T>({
                fieldName: this.fieldName(),
                entityId: entity.id,
                value: toSaveValue,
                entityClassType: this.entityClassType(),
            }).subscribe();
        }
    }
}

