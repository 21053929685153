import { SmartDocJobStatus } from '../../enums/smartdoc-job-status.enum';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocJobStatusChangedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Aktueller SmartDoc-Jobstatus
     */
    jobStatus?: SmartDocJobStatus;
}

/**
 * Klasse für Aktion
 */
export class SmartDocJobStatusChanged {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.jobStatusChanged';
    /**
     * Comnstructor
     *
     * @param {ISmartDocJobStatusChangedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocJobStatusChangedPayloadType) { }
}
