<div class="file" *ngFor="let file of files; let i = index" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{ file.file.name }} </span>
    <div>
        <button *ngIf="!file.alreadyExists" mat-icon-button (click)="removeFile(i)">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
<div class="placeholder"></div>
<div class="mt-3" draggable="true" ngClass="{{dragAreaClass}}" *ngIf="!maxFileCount || files.length < maxFileCount">
    <div class="row">
        <div class="col-md-14 text-center">
            <mat-icon class="mb-1">cloud_upload</mat-icon>
            <div class="mb-2">Datei hierhin ziehen</div>
            <div class="mb-2" style="font-size: 14px">oder</div>
            <button mat-stroked-button (click)="file.click()">Zum Öffnen klicken</button>

            <input type="file" #file [multiple]="true" (change)="onFileChange($event.target)" accept=".pdf"
                style="display: none;" />
        </div>
    </div>
</div>
<div class="error" *ngIf="error" [innerHtml]="error">
</div>
