<mat-sidenav-container hasBackdrop="false" autosize>
    <mat-sidenav #drawer mode="side" opened>
        <div fxLayout="column" fxLayoutAlign="start center" fxFill>
            <mat-nav-list>
                <mat-list-item routerLinkActive [activated]="usersRouter.isActive" #usersRouter="routerLinkActive"
                    routerLink="users" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.users' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>group</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.users' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="branchesRouter.isActive" #branchesRouter="routerLinkActive"
                    routerLink="branches" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.branches' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>store</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.branches' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="debitRouter.isActive" #debitRouter="routerLinkActive"
                    routerLink="debit-rates" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.debit-rates' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>web</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.debit-rates' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="riskRouter.isActive" #riskRouter="routerLinkActive"
                    routerLink="risk-parametrization" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.risk-parametrization' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>room_preferences</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.risk-parametrization' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="countryRouter.isActive" #countryRouter="routerLinkActive"
                    routerLink="country" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.country' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>language</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.country' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="financingRouter.isActive" #financingRouter="routerLinkActive"
                    routerLink="financing-configuration" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.financing-configuration' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>percent</mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.financing-configuration' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="systemconfigRouter.isActive" #systemconfigRouter="routerLinkActive"
                    routerLink="systemconfig" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.systemconfig' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon svgIcon="finprocess-file-signature"></mat-icon>
                    &nbsp;
                    <span *ngIf="isExpanded">{{ 'navigation.administration.systemconfig' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="smartdocRouter.isActive" #smartdocRouter="routerLinkActive"
                    routerLink="smartdoc-configuration" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.smartdoc-configuration' | translate }}" *finprocessAuthorization="Role.SmartDocAdministrator">
                    <mat-icon>view_module</mat-icon>
                    <span *ngIf="isExpanded">{{ 'navigation.administration.smartdoc-configuration' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="salespartnerRouter.isActive" #salespartnerRouter="routerLinkActive"
                    routerLink="salespartner-region" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.salespartner-region' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>public</mat-icon>
                    <span *ngIf="isExpanded">{{ 'navigation.administration.salespartner-region' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="controlRouter.isActive" #controlRouter="routerLinkActive"
                    routerLink="control-tables" routerLinkActive="active-link" matTooltip="{{ 'navigation.administration.control-tables' | translate }}" *finprocessAuthorization="controlTableAuthorization">
                    <mat-icon>summarize</mat-icon>
                    <span *ngIf="isExpanded">{{ 'navigation.administration.control-tables' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="systemRouter.isActive" #systemRouter="routerLinkActive"
                    routerLink="system-documents" routerLinkActive="active-link" matTooltip="{{'navigation.administration.system-documents' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>attach_file</mat-icon>
                    <span *ngIf="isExpanded">{{ 'navigation.administration.system-documents' | translate }}</span>
                </mat-list-item>
                <mat-list-item routerLinkActive [activated]="liabilityRouter.isActive" #liabilityRouter="routerLinkActive"
                    routerLink="liability-configuration" routerLinkActive="active-link" matTooltip="{{'navigation.administration.liability-configuration' | translate }}" *finprocessAuthorization="Role.AdministrationAreaReader">
                    <mat-icon>data_thresholding</mat-icon>
                    <span *ngIf="isExpanded">{{ 'navigation.administration.liability-configuration' | translate }}</span>
                </mat-list-item>
            </mat-nav-list>

            <div fxFlex></div>

            <button mat-icon-button (click)="isExpanded=!isExpanded" [fxFlexAlign]="isExpanded ? 'end' : 'center'">
                <mat-icon *ngIf="!isExpanded">chevron_right</mat-icon>
                <mat-icon *ngIf="isExpanded">chevron_left</mat-icon>
            </button>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
