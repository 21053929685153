<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id">
            <span *ngIf="!!label"></span>{{label}}
            <ng-content *ngIf="!label" select="[label]"></ng-content>
        </label>
        <mat-icon class="ml-1" *ngIf="currentValue !== originalValue" [matTooltip]="'financing.features.financing-processing.originalValue' | translate:{ value: (originalValue !== undefined ? (originalValue | date:'dd.MM.yyyy') : ( 'financing.features.financing-processing.unset' | translate)) }">info</mat-icon>
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="start center">
        <mat-form-field class="field-color slim-fella" appearance="fill" fxFlex="100">
            <input matInput [matDatepicker]="picker" [disabled]="(readonly | async) ?? false" [ngModel]="currentValue" [ngModelOptions]="{updateOn: 'blur'}" (ngModelChange)="save($event)" (focus)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr">
</div>
