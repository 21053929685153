import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Setzt Fokus, markiert Input Value nach Initialisierung der Seite
 */
@Directive({
    selector: '[finprocessSelectInputValue]',
})
export class SelectInputValueDirective implements AfterViewInit {
    
    /**
     * Konstruktor 
     * 
     * @param {ElementRef} hostElement input element
     */
    public constructor(private hostElement: ElementRef) {}

    /**
     * ngAfterViewInit
     */
    public ngAfterViewInit(): void {
        this.hostElement.nativeElement.select();
        this.hostElement.nativeElement.focus();
    }
}
