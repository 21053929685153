import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateDefaultParser, TranslateFakeCompiler } from '@ngx-translate/core';

import { EnumMissingTranslationHandler, EnumTranslateCompiler, EnumTranslateParser, EnumTranslateStore } from './lib/classes';
import { EnumTranslationDirective } from './lib/directives/enum-translation/enum-translation.directive';
import { IEnumTranslateModuleConfig } from './lib/interfaces';
import { EnumTranslationPipe } from './lib/pipes/enum-translation/enum-translation.pipe';
import { CUSTOM_ENUMS, ENUMS_DEFAULT_LANGUAGE, ENUMS_USE_DEFAULT_LANG, ENUMS_USE_EXTEND, ENUMS_USE_STORE, EnumTranslateLoader, EnumTranslationLoaderService, EnumTranslationService, MissingEnumTranslationService } from './lib/providers';

export { EnumTranslationPipe } from './lib/pipes/enum-translation/enum-translation.pipe';
export { EnumTranslationDirective } from './lib/directives/enum-translation/enum-translation.directive';

export { EnumSettings } from './core/enum.settings';
export * from './lib/classes';
export * from './core';
export * from './core/translatations';
export * from './lib/providers';

export * from './lib/interfaces';

/**
 * Modul zum Übersetzen von Enum-Werten
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        EnumTranslationPipe,
        EnumTranslationDirective,
    ],
    exports: [
        EnumTranslationPipe,
        EnumTranslationDirective,
    ],
})
export class EnumTranslationModule {
    /**
     * Liefert das Modul mit Providern für die globale Verwendung zurück
     *
     * @param {IEnumTranslateModuleConfig} config Konfiguration des Moduls
     * @returns {ModuleWithProviders<EnumTranslationModule>} Modul mit Providern
     */
    public static forRoot(config: IEnumTranslateModuleConfig = {}): ModuleWithProviders<EnumTranslationModule> {
        return {
            ngModule: EnumTranslationModule,
            providers: [
                config.loader !== undefined ? config.loader : { provide: EnumTranslateLoader, useClass: EnumTranslationLoaderService },
                config.compiler !== undefined ? config.compiler : { provide: EnumTranslateCompiler, useClass: TranslateFakeCompiler },
                config.parser !== undefined ? config.parser : { provide: EnumTranslateParser, useClass: TranslateDefaultParser },
                config.missingTranslationHandler !== undefined ? config.missingTranslationHandler : { provide: EnumMissingTranslationHandler, useClass: MissingEnumTranslationService },
                EnumTranslateStore,
                { provide: CUSTOM_ENUMS, useValue: config.customEnums },
                { provide: ENUMS_USE_STORE, useValue: config.isolate },
                { provide: ENUMS_USE_DEFAULT_LANG, useValue: config.useDefaultLang },
                { provide: ENUMS_USE_EXTEND, useValue: config.extend },
                { provide: ENUMS_DEFAULT_LANGUAGE, useValue: config.defaultLanguage },
                EnumTranslationService,
                EnumTranslationPipe,
            ],
        };
    }

    /**
     * Liefert das Modul mit Providern für die Verwendung in Child-Modulen zurück
     *
     * @param {IEnumTranslateModuleConfig} config Konfiguration des Moduls
     * @returns {ModuleWithProviders<EnumTranslationModule>} Modul mit Providern
     */
    public static forChild(config?: IEnumTranslateModuleConfig | null): ModuleWithProviders<EnumTranslationModule> {

        const result : ModuleWithProviders<EnumTranslationModule> = {
            ngModule: EnumTranslationModule,
            providers: [],
        };

        result.providers?.push(EnumTranslationService);
        result.providers?.push(EnumTranslationPipe);

        if (config === undefined || config === null) {
            return result;
        }

        if (config.defaultLanguage !== undefined) {
            result.providers?.unshift({ provide: ENUMS_DEFAULT_LANGUAGE, useValue: config.defaultLanguage });
        }

        if (config.extend !== undefined) {
            result.providers?.unshift({ provide: ENUMS_USE_EXTEND, useValue: config.extend });
        }

        if (config.useDefaultLang !== undefined) {
            result.providers?.unshift({ provide: ENUMS_USE_DEFAULT_LANG, useValue: config.useDefaultLang });
        }

        if (config.isolate !== undefined) {
            result.providers?.unshift({ provide: ENUMS_USE_STORE, useValue: config.isolate });
        }

        if (config.customEnums !== undefined) {
            result.providers?.unshift({ provide: CUSTOM_ENUMS, useValue: config.customEnums });
        }

        if (config.missingTranslationHandler !== undefined) {
            result.providers?.unshift(config.missingTranslationHandler);
        }

        if (config.parser !== undefined) {
            result.providers?.unshift(config.parser);
        }

        if (config.compiler !== undefined) {
            result.providers?.unshift(config.compiler);
        }

        if (config.loader !== undefined) {
            result.providers?.unshift(config.loader);
        }

        return result;
    }
}

