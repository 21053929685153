/**
 * Zugehörige Entitätsklasse zum Overwrite
 */
export enum OverwriteValueClassType {
    AddressOverwriteValue,
    AssetOverwriteValue,
    CareerOverwriteValue,
    CompanyOverwriteValue,
    DebitorOverwriteValue,
    FinancingMapOverwriteValue,
    HouseholdOverwriteValue,
    LiabilityOverwriteValue,
    RealEstateOverwriteValue,
    SalesPartnerOverwriteValue,
    TaxOfficeArrearOverwriteValue,
    FinProcessContainer
}
