import { DashboardStatusMap } from '../../types';
import { IDashboardTabOptions } from '../dashboard/dashboard.state';

import { IDashboardFinancing } from './../../interfaces';

/**
 * Aktion beim Laden aller für den aktuellen Statusfilter relevanten Anfragen
 */
export class FinancingsLoaded {
    public static readonly type = 'dashboard.financingsLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {DashboardStatusMap} payload.statusMap Geladene Statusmap
     * @param {IDashboardFinancing[]} payload.financings Finanzierungen
     */
    public constructor(public payload: { statusMap: DashboardStatusMap; financings: IDashboardFinancing[]; }) { }
}

/**
 * Aktion zum Filtern, Suchen und Sortieren bei Clientlogik
 */
export class ClientFilterSorting {
    public static readonly type = 'dashboard.clientFilterSorting';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {string} payload.tabId Tab
     * @param {IDashboardTabOptions} payload.options Einstellungen vom Tab
     */
    public constructor(public payload: { tabId: string, options: IDashboardTabOptions }) { }
}

/**
 * Aktion beim Laden einer Seite aus dem Backend
 */
export class FinancingsPageLoaded {
    public static readonly type = 'dashboard.financingsPageLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Parameterobjekt
     * @param {boolean | undefined} payload.keepData Sollen bereits geladene Daten erhalten bleiben
     * @param {number} payload.page Seite
     * @param {IDashboardFinancing[]} payload.financings Finanzierungen
     * @param {number} payload.maxResultsCount maximale Anzahl an Einträgen
     */
    public constructor(public payload: { keepData?: boolean; page: number; financings: IDashboardFinancing[]; maxResultsCount: number; }) { }
}
