/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@ntag-ef/finprocess-enums/finprocess';
import { HelperService } from 'shared/util';

/**
 * Verkurtz sprache
 */
@Pipe({
    name: 'finProcesslanguageShort',
})
export class LanguageShortPipe implements PipeTransform {

    /**
     * abgekürzter Name der Sprache
     *
     * @param {Language[]} languages User sprachen
     * @returns {string} sprachen für UI as string
     */
    public transform(languages?: Language): string {
        if (!!languages) {
            const bitLangs = HelperService.getBitNumbers(languages as number);
            return bitLangs.map(lang => Language.translate(lang)?.slice(0,2) ?? '').join('/');
        }
        return '';
    }
}
