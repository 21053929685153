/**
 * Fehler Parameterübergabe
 */
export class ArgumentError extends Error {
    /**
     * Name des Fehlers
     */
    public static errorName = 'ArgumentError';

    /**
     * Name des Fehlers
     */
    public name: string = ArgumentError.errorName;
}
