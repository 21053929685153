import { WorkflowType } from '../../enums';
import { BusinessType, Collateral, CollateralType, Currency, CustomerType, Euribor, FixedInterestPeriod, LiquidityCostAdvantage, Product, PropertyType, Rating, TotalObligation } from '../../enums/pricing';
import { IMidtermModel } from '../../interfaces';

export const PRICING_REQUEST_DATA: IMidtermModel = {

    /**
     * Name für Übersetzungen
     */
    midtermName: 'pricingRequestData',

    /**
     * Kundennummer
     */
    kundennummer: { type: WorkflowType.Number },

    /**
     * Produkt
     */
    produkt: { type: WorkflowType.Enum, enumObject: Product, enumLabel: 'Product' },

    /**
     * Whg
     */
    whg: { type: WorkflowType.Enum, enumObject: Currency, enumLabel: 'Currency' },

    /**
     * Zinsbindungsdauer
     */
    zinsbindungsdauer: { type: WorkflowType.Enum, enumObject: FixedInterestPeriod, enumLabel: 'FixedInterestPeriod' },

    /**
     * Indikatorbindung
     */
    indikatorbindung: { type: WorkflowType.Enum, enumObject: Euribor, enumLabel: 'Euribor' },

    /**
     * Kreditnominale
     */
    kreditnominale: { type: WorkflowType.Number },

    /**
     * Kreditlaufzeit
     */
    kreditlaufzeit: { type: WorkflowType.Number },

    /**
     * Gesamtobligo
     */
    gesamtobligo: { type: WorkflowType.Enum, enumObject: TotalObligation, enumLabel: 'TotalObligation' },

    /**
     * LiKoVorteil
     */
    liKoVorteil: { type: WorkflowType.Enum, enumObject: LiquidityCostAdvantage, enumLabel: 'LiquidityCostAdvantage' },

    /**
     * MarktwertLiegenschaft
     */
    marktwertLiegenschaft: { type: WorkflowType.Number },

    /**
     * Vorlasten
     */
    vorlasten: { type: WorkflowType.Number },

    /**
     * HoeheHypothek
     */
    hoeheHypothek: { type: WorkflowType.Number },

    /**
     * Geschaeftsfeld
     */
    geschaeftsfeld: { type: WorkflowType.Enum, enumObject: BusinessType, enumLabel: 'BusinessType' },

    /**
     * KundenKategorie
     */
    kundenKategorie: { type: WorkflowType.Enum, enumObject: CustomerType, enumLabel: 'CustomerType' },

    /**
     * GueltigeBonitaet
     */
    gueltigeBonitaet: { type: WorkflowType.Enum, enumObject: Rating, enumLabel: 'Rating' },

    /**
     * Sicherheit
     */
    sicherheit: { type: WorkflowType.Array, child: WorkflowType.Enum, enumObject: Collateral, enumLabel: 'Collateral' }, //Todo Array of Enum

    /**
     * SichMatBewerteterWert
     */
    sichMatBewerteterWert: { type: WorkflowType.Array, child: WorkflowType.Number },

    /**
     * SicherheitsArt
     */
    sicherheitsArt: { type: WorkflowType.Array, child: WorkflowType.Enum, enumObject: CollateralType, enumLabel: 'CollateralType' }, //Todo Array of Enum

    /**
     * Immobilienart
     */
    immobilienart: { type: WorkflowType.Array, child: WorkflowType.Enum, enumObject: PropertyType, enumLabel: 'PropertyType' }, //Todo Array of Enum

    /**
     * ReferenzzinsatzManuell
     */
    referenzzinsatzManuell: { type: WorkflowType.Number },

    /**
     * LikostenManuell
     */
    likostenManuell: { type: WorkflowType.Number },

    /**
     * IndikatorbindnungReferenzprodukt
     */
    indikatorbindnungReferenzprodukt: { type: WorkflowType.Enum, enumObject: Euribor, enumLabel: 'Euribor' },

    /**
     * UserID
     */
    userID: { type: WorkflowType.String },
};
