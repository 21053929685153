import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { InterestMethod, ProductPackageType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { FinancingService, IFinancingStateParentDefinition, IProductPackageData, ISampleCalculationData, ISampleCalculationProducts, InterestMethodVariable, RepaymentPeriod } from 'app/modules/financing/data';
import { IProductPackageItems } from 'app/modules/financing/data/interfaces/product-package-items.interface';
import { HelperService } from 'app/modules/shared';

type ValidationTypes = InterestMethodVariable | RepaymentPeriod | number;

interface IValidationRule<T extends ValidationTypes> {
    field: keyof IProductPackageItems;
    errorMessage: string;
    validate: (value: T) => boolean;
}

/**
 * Select Product Interest Rates and generate Sample Caluclation
 */
@Component({
    selector: 'finprocess-generate-sample-calculation',
    templateUrl: './generate-sample-calculation.component.html',
    styleUrls: ['./generate-sample-calculation.component.scss'],
})
export class GenerateSampleCalculationComponent implements OnInit {
    /**
     * Product Package Data
     */
    public productPackage?: IProductPackageData;

    /**
     * RFP position
     */
    public rfpPosition?: number;

    /**
     * InterestMethod Enum
     */
    public interestMethod: InterestMethod[] = [];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public InterestMethod = InterestMethod;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ProductPackageType = ProductPackageType;

    /**
     * Konstruktor
     *
     * @param {IProductPackageData} data product package data
     * @param {IProductPackageData} data.productPackageData product package data
     * @param {number} data.rfpPosition rfp position
     * @param {string} data.productPackageID product package id 
     * @param {FinancingService} financingService financing service
     * @param {WaiterService} waiterService waiter service
     * @param {TranslateService} translate translate service
     * @param {MatDialogRef} dialogRef dialog ref
     * @param {NotificationService} notificationService notification service
     * @param {Store} store store
     */
    public constructor(@Inject(MAT_DIALOG_DATA) public data: { rfpPosition: number, productPackageID: string },
        private financingService: FinancingService, private waiterService: WaiterService, private translate: TranslateService,
        public dialogRef: MatDialogRef<GenerateSampleCalculationComponent>, private notificationService: NotificationService,
        private store: Store) {

        const productPackage = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.productPackages);

        if (!!productPackage) {
            const ppClone = HelperService.clone(productPackage);

            if (!!ppClone) {
                const filteredProductPackage = ppClone.assignProductPackages.filter(pp => pp.productPackageID === data.productPackageID);
    
                this.productPackage = {
                    assignProductPackages: filteredProductPackage,
                    financingMapID: ppClone?.financingMapID,
                };
            }
        }
        this.rfpPosition = data.rfpPosition;
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.interestMethod = (HelperService.getEnumArray(InterestMethod) as number[]);
    }

    /**
     * check if generate RB button needs to be disabled or not
     * 
     * @returns {boolean} true or false
     */
    public isGenerateRBDisabled(): boolean {
        return !!this.productPackage?.assignProductPackages.find(product => 
            product.assignProducts.some(assignProduct => 
                assignProduct.productCreditType !== ProductPackageType.Guarantee &&
                assignProduct.sampleCalculationSelectedOption === undefined,
            ));
    }

    /**
     * return true or false if product section should be visible or not
     *
     * @param {IProductPackageItems} products products 
     * @returns {boolean} true or false
     */
    public showProductSection(products: IProductPackageItems): boolean {
        if (products.variableInterest > 0) { return true; }
        if (products.variableInterestFix5Year > 0) { return true; }
        if (products.variableInterestFix10Year > 0) { return true; }
        if (products.variableInterestFix15Year > 0) { return true; }
        if (products.variableInterestFix20Year > 0) { return true; }
        if (products.variableInterestFix25Year > 0) { return true; }

        return false;
    }

    /**
     * generate Sample Calculation
     */
    public generateSampleCalculation() {

        const getData = this.generateSampleCalculationData();
        const validateData = this.validateProductPackagaData();

        if (!!this.productPackage && !!getData && validateData.length === 0) {
            this.waiterService.show();
            const data: ISampleCalculationData = {
                productpackageID: this.productPackage.assignProductPackages[0].productPackageID,
                sampleCalculationName: getData.pdfNameResult,
                sampleCalculationProducts: getData.productChoices,
            }
            this.financingService.addNewSampleCalculation(data).subscribe({
                next: () => {
                    this.waiterService.hide();
                    this.notificationService.toast(this.translate.instant('financing.features.financing-processing.financing-details.saveSCsuccess'));
                    this.dialogRef.close({});
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.financing-details.saveSCerror'));
                    this.dialogRef.close({});
                },
                complete: () => {
                    this.waiterService.hide();
                },
            });
        }
        else if (validateData.length > 0) {
            const formattedErrors = validateData.map(error => `• ${error}`).join('<br>');
            this.notificationService.alert(this.translate.instant('financing.features.financing-processing.financing-details.validation'), `${this.translate.instant('financing.features.financing-processing.financing-details.validationError')}<br><br>${formattedErrors}`);
        }
        else {
            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.financing-details.loadPPDataError'));
        }
    }

    /**
     * generate data and pdf name for sample caluclation
     * 
     * @returns {{ pdfName: string, productChoices: ISampleCalculationProducts[] } | undefined} data
     */
    public generateSampleCalculationData() {
        const rb = 'RB_';
        const planPosition = this.rfpPosition;
        const paketPosition = this.productPackage?.assignProductPackages[0].position;

        const products = this.productPackage?.assignProductPackages[0].assignProducts;

        if (!!products) {
            const productChoices: ISampleCalculationProducts[] = products.map(element => ({
                productID: element.productID,
                interestChoice: element.sampleCalculationSelectedOption,
            }));

            const productChoicesForName = products.map(element => ({
                productType: element.productCreditType,
                productPosition: element.position,
                interestChoice: element.sampleCalculationSelectedOption,
            }));

            const pdfName = productChoicesForName.map(choice => {
                if (choice.productType !== undefined && choice.interestChoice !== undefined) {
                    const productPosition = choice.productPosition;
                    const productTypeString = this.mapProductTypeToString(choice.productType);
                    const interestChoiceString = this.mapInterestMethodToString(choice.interestChoice);
                    return productPosition + productTypeString + interestChoiceString;
                }
                return '';
            }).join('_');

            const currentDate = new Date();
            const datePipe = new DatePipe('de-DE');
            const dateResult = datePipe.transform(currentDate, 'dd-MM-yyyyTHH:mm:ss');
            const sanitizedDateResult = dateResult?.replace(/:/g, '-'); //: cannot be saved as file name

            const pdfNameResult = `${rb}RFP${planPosition}_PP${paketPosition}_${pdfName}_${sanitizedDateResult}`;
            return { pdfNameResult, productChoices };
        }
        return undefined;
    }

    /**
     * validate ProductPackage Data
     * 
     * @returns {string[]} true or false
     */
    private validateProductPackagaData(): string[] {

        const errors = [];

        if (!!this.productPackage) {
            const fieldsToValidate: Array<IValidationRule<ValidationTypes>> = [
                { field: 'indicator', errorMessage: 'Indikator nicht gesetzt', validate: (value: InterestMethodVariable) => value !== undefined || value !== null },
                { field: 'repaymentPeriod', errorMessage: 'Rückzahlungsart in (Tilgungsart) nicht gesetzt', validate: (value: RepaymentPeriod) => !!value },
                { field: 'ratesCount', errorMessage: 'Anzahl Raten ist <= 0', validate: (value: number) => value > 0 },
                { field: 'interestReferenceRate', errorMessage: 'Referenzzinssatz ist <= 0', validate: (value: number) => value > 0 },
                { field: 'addition', errorMessage: 'Aufschlag ist <= 0', validate: (value: number) => value > 0 },
                { field: 'durationWithoutASD', errorMessage: 'Gesamtlaufzeit ohne ASD Periode <= 0', validate: (value: number) => value > 0 },
                { field: 'creditAmount', errorMessage: 'Kreditbetrag <= 0', validate: (value: number) => value > 0 },
            ];

            for (const product of this.productPackage.assignProductPackages[0].assignProducts) {
                if (product.productCreditType !== ProductPackageType.Guarantee) {
                    for (const { field, errorMessage, validate } of fieldsToValidate) {
                        if (!validate(product[field as keyof IProductPackageItems] as ValidationTypes)) {
                            errors.push(errorMessage);
                        }
                    }

                    // Check for withoutCoverageRegister
                    if (
                        (product.withoutCoverageRegister === false && product.additionForComparativeAnalysis <= 0) ||
                        (product.withoutCoverageRegister === true && product.deviatingFictionalInterestRate <= 0)
                    ) {
                        errors.push('Aufschlag für Vergleichsrechnung oder Abweichender fiktiver Zinssatz ist <= 0');
                    }
                }
            }
        }

        return errors.length > 0 ? errors : [];
    }


    /**
     * map product type to string for naming the sample calculation
     * 
     * @param {ProductPackageType} productType product package type
     * @returns {string} anfangsbuchstabe
     */
    public mapProductTypeToString(productType: ProductPackageType): string {
        switch (productType) {
            case ProductPackageType.Credit: return 'D';
            case ProductPackageType.ComfortCredit: return 'E';
            case ProductPackageType.BWWithoutFollowUpFinancing: return 'BW-';
            case ProductPackageType.BWWithFollowUpFinancing: return 'BW+';
            case ProductPackageType.Guarantee: return 'G';
            default: return '';
        }
    }

    /**
     * map product type to string for naming the sample calculation
     * 
     * @param {InterestMethod} interestChoice selected interest method option
     * @returns {string} jahre
     */
    public mapInterestMethodToString(interestChoice: InterestMethod): string {
        switch (interestChoice) {
            case InterestMethod.FixFiveYears: return 'Fix5';
            case InterestMethod.FixTenYears: return 'Fix10';
            case InterestMethod.FixFifteenYears: return 'Fix15';
            case InterestMethod.FixTwentyYears: return 'Fix20';
            case InterestMethod.FixTwentyfiveYears: return 'Fix25';
            case InterestMethod.VariableThreeMo: return 'Var';
            default: return '';
        }
    }
}
