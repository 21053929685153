import { Validators } from '@angular/forms';

import { WorkflowType } from '../../enums';
import { CustomerPurposeType, FormattingType, OptionalBoolenType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';


export const FINANCING_REQUEST_NEW: IMidtermModel = {

    midtermName: 'financingRequestNew',

    /**
     * wenn "finwu_sonstige" (nJ30_SON) befüllt ist: llt, dann muß LTV_RELEVANT mit "ja" oder "nein" befüllt werden: llt, andernfalls blank
     * finwu_ltv_relevant
     */
    ltvRelevant: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Zwifi Förderung gem. KIM-V Novelle?*
     * zwifi_foerderung_maf_kimv
     */
    zwifiFoerderungMafKimv: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Eingetragene hypothekarische Sicherheit?
     * fw_kunde_hyp_sicherheit
     */
    kundeHypSicherheit: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * zur Eigennutzung oder zur Vermietung
     * Lieferung entweder "Eigenbedarf" oder "Vermietung"
     * fw_kunde_verwendungszweck
     */
    kundeVerwendungszweck: { type: WorkflowType.Enum, enumObject: CustomerPurposeType, enumLabel: 'CustomerPurposeType', defaultValue: CustomerPurposeType.PersonalUse, validators: Validators.required},

    /**
     * "is zu beantworten wenn fw_kunde_verwendungszweck = Vermietung --> Ist die HHR ohne zukünftige Mieteinnahmen?"
     * fw_kunde_leistbar_ohne_mieteinnahmen
     */
    kundeLeistbarOhneMieteinnahmen: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * zukünftige Mieteinnahmen ->  ist zu erfassen wenn FW_KUNDE_LEISTBAR_OHNE_MIETEINNAHMEN = nein
     * zuk_mieteinnahmen
     */
    zukMieteinnahmen: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Gesamtinvestitionskosten
     * fw_kunde_gesamtinvestition
     */
    kundeGesamtinvestition: { type: WorkflowType.Number, validators: Validators.required, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] B&W Mortgage Linie 1
     * finwu_bw1 / finwu_kb_bw1
     */
    bw1: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] B&W Mortgage Linie 2
     * finwu_bw2 / finwu_kb_bw2
     */
    bw2: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Kleingarten Pacht
     * finwu_kg / finwu_kb_kg
     */
    kg: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Miete, Renovierung
     * finwu_ren / finwu_kb_ren
     */
    ren: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Genossenschaft
     * finwu_gen / finwu_kb_gen
     */
    gen: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Ergänzungsdarlehen
     * finwu_erg / finwu_kb_erg
     */
    erg: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Nachfinanzierungsergänzungsdarlehen
     * finwu_nf / finwu_kb_nf
     */
    nf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Garantie (NICHT Doppelobligo)
     * finwu_gar / finwu_kb_gar
     */
    gar: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Zwischenfinanzierung mit fremder Anschlussfinanzierung
     * finwu_zf_maf / finwu_kb_zf_maf
     */
    zfMaf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Zwischenfinanzierung ohne fremder Anschlussfinanzierung
     * finwu_zf_oaf / finwu_kb_zf_oaf
     */
    zfOaf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Konsum
     * finwu_konsum_betrag / finwu_kb_konsum
     */
    konsum: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * neuer Finanzierungswunsch Zwischenfinanzierung LS-Verkauf gem. KIMV Novelle
     * finwu_zf_lsverkauf / finwu_kb_zf_lsverkauf
     */
    zfLsVerkauf: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Überschreitung/Kreditkarten
     * finwu_konsum_ueberschreitung / finwu_kb_ueb
     */
    konsumUeberschreitung: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Garantie / Sonderfinanzierungsarten (=Doppelobligo)
     * finwu_doppelobligo / finwu_kb_doppelobligo
     */
    doppelobligo: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * [neuer Finanzierungswunsch / Finanzierungswunsch Kreditbetrag] Sonderfinanzierungsarten (Überhang LS-Verkauf oder Ust-Vorfinanzierung über threshold)
     * finwu_sonstige / finwu_kb_sonderfin
     */
    sonstige: { type: WorkflowType.Number, formattingType: FormattingType.Currency },
}
