<div fxLayout="column" class="container">
    <div class="p-3">
        <h1 class="color-primary">{{ 'navigation.administration.control-tables' | translate }}</h1>
    </div>
    <div class="px-3 pb-3">
        <mat-form-field appearance="fill">
            <mat-select [panelWidth]="350" [(ngModel)]="selectedTableType" (selectionChange)="changeControlTableType($event)" placeholder="Steuertabelle auswählen">
                <mat-option *ngFor="let type of controlTableTypes | async" [value]="type">
                    {{ type | finprocessEnumTranslation:'ControlTableType' }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" class="mx-3">
        <div *ngIf="!readonly" #uploadArea class="upload-area" fxLayout="column" fxLayoutAlign="center center">
            <a *ngIf="selectedTableType !== undefined" mat-raised-button href="javascript:void(0)" color="primary" (click)="file.click()">
                {{ 'administration.features.control-tables.uploadButton' | translate }}
            </a>
            <input type="file" #file [multiple]="false" (change)="uploadFile($event.target)" accept=".xlsx,.xls" [disabled]="readonly" 
                style="display: none;" />
        </div>
        <mat-form-field appearance="fill">
            <input matInput placeholder="{{ 'administration.features.control-tables.tableSearch' | translate }}" [ngModel]="search$.value" (ngModelChange)="updateSearch($event)" [disabled]="selectedTableType === undefined || readonly">
        </mat-form-field>
    </div>
    <div class="p-3" fxFlex>
        <div *ngIf="selectedTableType === undefined" class="table-skeleton" fxLayout="column" fxFlex>
            <div class="skeleton-header">
            </div>
            <div class="skeleton-content" fxFlex fxLayout="column" fxLayoutAlign="center center">
                <h3>{{ 'administration.features.control-tables.tablePlaceholder' | translate }}</h3>
            </div>
        </div>
        <div *ngIf="selectedTableType !== undefined && tableColumns.length > 0" fxFlex fxLayout="column">
          <table mat-table [dataSource]="(displayedData$ | async) ?? []" fxFlex>
            <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>{{ 'administration.features.control-tables.' + translateKey + '.' + column | translate }}</th>
              <td mat-cell *matCellDef="let sourceValue">
                <ng-container *ngIf="(column === 'completionGrade') && (sourceValue ?? {}).completionGrade !== undefined">
                  <span *ngIf="(sourceValue ?? {}).completionGrade === 1">{{ 'administration.features.control-tables.collateral-factors-for-asset.completionGrade1' | translate }}</span>
                  <span *ngIf="(sourceValue ?? {}).completionGrade === 2">{{ 'administration.features.control-tables.collateral-factors-for-asset.completionGrade2' | translate }}</span>
                  <span *ngIf="(sourceValue ?? {}).completionGrade !== 1 && (sourceValue ?? {}).completionGrade !== 2">{{ (sourceValue ?? {})[column] }}</span>
                </ng-container>
                <ng-container *ngIf="!(column === 'completionGrade') || (sourceValue ?? {}).completionGrade === undefined">{{ (sourceValue ?? {})[column] }}</ng-container>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
          </table>
        
          <mat-paginator [length]="(filteredData$ | async)?.length ?? 0" [pageSize]="pageSize" showFirstLastButtons (page)="changePage($event)"></mat-paginator>
        </div>
    </div>
</div>