/* eslint-disable @typescript-eslint/naming-convention */

import { WorkflowType } from '../../enums'
import { EducationLevel, KindOfResidence, MaritalStatus, PiNaceCode, PurposeId } from '../../enums/scoring'
import { IMidtermModel } from '../../interfaces'

export const SCORING_DATA: IMidtermModel = {

    midtermName: 'scoringData',

    ndg: { type: WorkflowType.String },

    proposalId: { type: WorkflowType.String },

    loanId: { type: WorkflowType.String },

    purposeId: { type: WorkflowType.Enum, enumObject: PurposeId, enumLabel: 'PurposeId' },

    materialStatus: { type: WorkflowType.Enum, enumObject: MaritalStatus, enumLabel: 'MaritalStatus' },

    piNaceCode: { type: WorkflowType.Enum, enumObject: PiNaceCode, enumLabel: 'PiNaceCode' },

    kindOfResidence: { type: WorkflowType.Enum, enumObject: KindOfResidence, enumLabel: 'KindOfResidence' },

    zipCode: { type: WorkflowType.String },

    capitalAmount: { type: WorkflowType.Number },

    numberOfCoborrower: { type: WorkflowType.Number },

    educationLevel: { type: WorkflowType.Enum, enumObject: EducationLevel,enumLabel: 'EducationLevel' },

}
