import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Nebengebäude
 */
export enum AnnexType {
    /**
     * Keine
     */
    None = 0,

    /**
     * Leichtbau
     */
    LightWeight = 1,

    /**
     * Massivbau
     */
    Solid = 2,
}

export namespace AnnexType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {AnnexType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: AnnexType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'AnnexType', AnnexType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('AnnexType', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {AnnexType | undefined | null} value Enum-Wert
     * @param {AnnexType} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: AnnexType | undefined | null, flag: AnnexType): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
