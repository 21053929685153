import { HelperService } from 'app/modules/shared/util';

/**
 *
 */
export class InputService {
    public readonly minInputLength = 8;
    public readonly ndgStandardLenght = 16;
    //stored value in the input
    public storedInputVal: string | null = null;

    //model change callback function (contains model value)
    public onModelChange: (value: string | null) => void = () => undefined;
    private onModelTouched: () => void = () => undefined;

    /**
     * Konstructor
     * 
     * @param {HTMLInputElement} htmlInputElement HTMLInputElement 
     */
    public constructor(
        private htmlInputElement: HTMLInputElement,
    ) {}

    /**
     * 
     * Set Masked Value to input 
     *
     * @param {string | null} value value
     */
    public setMaskedValue(value: string | null) {
        const maskedValue = this.applyMask(value);
        this.storedInputVal = maskedValue;
        if (!!this.htmlInputElement) {
            this.htmlInputElement.value = maskedValue ?? '';
        }
    }
  
    /**
     * Set on model change callback function
     *
     * @param {(value: string | null) => void} callbackFunction callbackFunction
     */
    public setOnModelChange(callbackFunction: (value: string | null) => void): void {
        this.onModelChange = callbackFunction;
    }

    /**
     *  Return the callback model change function
     *
     * @returns {(value: string | null) => void} onModelChange
     */
    public getOnModelChange(): (value: string | null) => void {
        return this.onModelChange;
    }


    /**
     *  Check if html old value is equal to the new value
     * 
     * @returns {boolean} areValuesEqual
     */
    public areValuesEqual(): boolean { 
        return this.storedInputVal === this.applyMask(this.htmlInputElement.value); 
    }
    /**
     * set new value to the model and input element
     */
    public setNewValueAfterBlur(): void {
        if (!HelperService.isNullOrEmpty(this.htmlInputElement.value)) {
            this.updateModel((this.htmlInputElement.value.slice()));
            this.changeHtmlInputValue(this.htmlInputElement.value);
        }
    }

    /**
     * Update the model value
     * 
     * @param {string} inputValue  inputValue
     */
    private updateModel(inputValue: string): void {
        //set the value to the model (add leading zeros if needed)
        if (inputValue.length < this.ndgStandardLenght) { 
            const newNdg = '0'.repeat(this.ndgStandardLenght - inputValue.length) + inputValue;
            this.onModelChange(newNdg);
        } else {
            this.onModelChange(inputValue);
        }
    }

    /**
     *  Update the html input value to min input length
     * 
     * @param { string } inputValue input value 
     */
    private changeHtmlInputValue(inputValue: string): void {
        if (inputValue.length < this.minInputLength) {
            this.htmlInputElement.value = '0'.repeat(this.minInputLength - inputValue.length) + inputValue
            this.storedInputVal = this.htmlInputElement.value;
        } else {
            this.setMaskedValue(inputValue);
        }
    }

    /**
     * format NDG to remove leading zeros
     *
     * @param {string | undefined | null} value input value
     * @returns {string} formatted NDG
     */
    public applyMask(value: string | undefined | null): string {
        return HelperService.maskNdg(16, 8, '', value);
    }

    /**
     *  Set onModelTouched callback function
     *
     * @param {() => void} callbackFunction callbackFunction
     */
    public setOnModelTouched(callbackFunction: () => void) {
        this.onModelTouched = callbackFunction;
    }

    /**
     * 
     *  Return the callback model touched function
     * 
     * @returns {() => void } onModelTouched 
     */
    public getOnModelTouched(): () => void {
        return this.onModelTouched;
    }

    /**
     *   Check if the HTML input is valid
     * 
     * @returns {boolean} isInputValid
     */
    public isInputValid(): boolean { 
        return this.htmlInputElement.willValidate;
    }

    /**
     *  Set old value to html input
     */
    public restoreOldValueToInput() {
        this.htmlInputElement.value = this.applyMask(this.storedInputVal);
    }

    /**
     *  Check if new value empty/null and old value is not 
     *  (when old value is not empty that means model is not empty but html input is)
     * 
     * @returns {boolean} isNewValueDifferent
     */
    public isNewValueNllOrEmptyButOldNot(): boolean {
        return HelperService.isNullOrEmpty(this.htmlInputElement.value) && !HelperService.isNullOrEmpty(this.storedInputVal);
    }

    /**
     * Check if the input is HTML required
     * 
     * @returns {boolean} requred
     */
    public isInputRequred(): boolean {
        return this.htmlInputElement.required;
    }

    /**
     *  Get the value of the HTML input
     * 
     * @returns {string} value
     */
    public getInputValue(): string {
        return this.htmlInputElement.value;
    }
}
