/**
 * Abstrakte Klasse zum Implementieren eines Ressourcen-Parsers
 */
export abstract class EnumTranslateParser {
    /**
     * Ersetzt Platzhalter in übersetztem Wert
     */
    public abstract interpolate(expr: string | (() => string), params?: unknown): string;

    /**
     * Liefert den Wert aus einem Objekt
     */
    public abstract getValue(target: unknown, key: string): unknown;
}
