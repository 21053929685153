import { EnumSettings } from './../enum.settings';

/**
 * [MEHRFACHAUSWAHL] Ausstattung Garage
 */
export enum GarageFacilities
    {
    /**
     * Keine
     */
    None = 0,

    /**
     * Massivbauweise
     */
    SolidConstruction = 1,

    /**
     * Direkter Zugang zum Wohnhaus
     */
    DirectAccessToBuilding = 2,

    /**
     * Überdachter Außenzugang zum Wohnhaus
     */
    RoofedAccessToBuilding = 4,

    /**
     * Wasseranschluss in der Garage
     */
    WaterSupply = 8,
}

export namespace GarageFacilities {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {GarageFacilities | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: GarageFacilities | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'GarageFacilities', GarageFacilities, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('GarageFacilities', plocale);

    /**
     * Prüft, ob bei einem Wert ein bestimmtes Flag gesetzt ist
     *
     * @param {GarageFacilities | undefined | null} value Enum-Wert
     * @param {GarageFacilities} flag Zu prüfendes Flag
     * @returns {boolean | undefined} Flag gesetzt
     */
    export const hasFlag = (value: GarageFacilities | undefined | null, flag: GarageFacilities): boolean | undefined => EnumSettings.hasFlag(value, flag);
}
