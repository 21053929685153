import { AuthenticationState, IAuthenticationStateModel } from './authentication/authentication.state';
import { IUserStateModel } from './user/user.state';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface IAuthStateParentDefinition {
    /**
     * Zustand für aktuelle Authentifizierung
     */
    authentication: IAuthenticationStateModel;

    /**
     * Daten des aktuellen Nutzers
     */
    user: IUserStateModel;
}

export const AUTH_PERSISTENT_STATE_KEYS = [AuthenticationState.keyName];
