import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { WaiterModule } from '@ntag-ef/waiter';
import { LoadBalancingInternalModule } from 'app/modules/administration/api';
import { InformationEventDataModule } from 'app/modules/information-events/data';
import { UserAreaDataModule } from 'app/modules/user-area/data';
import { AuthChecksModule } from 'auth';
import { FlexLayoutModule } from 'ngx-flexible-layout'
import { PipesModule } from 'shared/features/pipes';
import { SharedUiModule } from 'shared/ui';

import { DashboardDataModule, DashboardFilter } from './../../data';
import { BaseDashboardComponent, DashboardComponent, FinancingListComponent, InformationEventsComponent } from './components';
import { DashboardRoutingModule } from './dashboard-routing.module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const enums = { DashboardFilter };

/**
 * Modul für die Anzeige des Dashboard
 */
@NgModule({
    declarations: [
        DashboardComponent,
        FinancingListComponent,
        InformationEventsComponent,
        BaseDashboardComponent,
    ],
    imports: [
        ScrollingModule,
        CommonModule,
        DashboardRoutingModule,
        DashboardDataModule,
        InformationEventDataModule,
        UserAreaDataModule,
        LoadBalancingInternalModule,
        FlexLayoutModule,
        TranslateModule.forChild(),
        EnumTranslationModule.forChild({
            customEnums: enums,
        }),
        FormsModule,
        AuthChecksModule,
        SharedUiModule,
        PipesModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        MatCardModule,
        WaiterModule.forChild(),
        NotificationsModule.forChild(),
    ],
})
export class DashboardModule {

    /**
     * Konstruktor zum Hinzufügen von Icons
     *
     * @param {DomSanitizer} domSanitizer DomSanitizer-Injektor
     * @param {MatIconRegistry} iconRegistry IconRegistry-Injektor
     */
    public constructor(private domSanitizer: DomSanitizer, private iconRegistry: MatIconRegistry) {
        this.iconRegistry.addSvgIcon('finprocess-uk', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flags/uk.svg'));
        this.iconRegistry.addSvgIcon('finprocess-bank-logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/unicredit-logo.svg'));
    }
}
