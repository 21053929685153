
/**
 * Nummern für Zeitraum passend der Anzahl der Monate gewählt.
 * Soll das Rechnen mit dem Enum möglich vereinfachen.
 */
export enum RepaymentPeriod {
    Monthly = 1,
    Quarterly = 3,
    HalfYearly = 6,
    Yearly = 12,
}
