<h1 mat-dialog-title>Wählen Sie einen Namen für den neuen Finanzierungsplan</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="rfpName" required>
    <mat-error class="materror" *ngIf="!rfpName"> Der Name ist erforderlich. </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancelDialog()">Abbrechen</button>
  <button mat-button class="submit-button" [mat-dialog-close]="rfpName" [disabled]="!rfpName">Erstellen</button>
</div>
