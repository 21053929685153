import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService, AuthorizationService, Role } from 'auth';
import { Observable, Subject, forkJoin, iif, of, take } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { ConfigService } from 'shared/util';

import { IApprovalCompetence, IBankAccount, IBranch, ICountry, ILegalisationFeeBase, ISalesPartnerCenter } from '../../interfaces';

import { ApprovalCompetenceLoaded, BankAccountsLoaded, BranchesLoaded, CountriesLoaded, IMasterdataParentDefinition, IMasterdataStateModel, LegalisationFeeBasesLoaded, SalesPartnerCentersLoaded } from './../../states';

/**
 * Service zum Abruf der Stammdaten
 */
@Injectable()
export class MasterdataService implements OnDestroy {

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {Store} store Stroe-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private authenticationService: AuthenticationService,
    ) {

    }

    /**
     * Erstellt eine Klasseninstanz einer Action anhand eines generischer Parameters
     *
     * @template T Datentyp
     * @template AT Klassentyp
     * @param {ConstructorParameters} type Klassentyp
     * @param {object} data Daten der Klasse
     * @param {T[]} data.data Stammdaten
     * @param {string | undefined} data.lastModified Stammdaten zuletzt geändert am
     * @returns {T} Klasseninstanz
     */
    private static createActionClass<T, AT>(
        type: (new (payload: { data: T[], lastModified?: string; }) => AT),
        data: { data: T[], lastModified?: string; }): AT {
        return new type(data);
    }

    /**
     * Angular-Hook beim Enfernen des Service
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Initialisiert die Stammdaten
     */
    public initialize(): void {
        this.authenticationService.loginSuccessful$.pipe(
            takeUntil(this.onDestroy$),
            mergeMap(() => this.loadAllMasterData()),
        ).subscribe();
    }

    /**
     * Lädt die Stammdaten
     *
     * @returns {Observable<void>} Observable, wenn Stammdaten geladen
     */
    public loadAllMasterData(): Observable<void> {
        return forkJoin([
            this.loadSalesPartnerCenters(),
            this.loadBranches(),
            this.loadLegalisationFeeBases(),
            this.loadBankAccounts(),
            this.loadCountries(),
            this.loadApprovalCompetence(),
        ]).pipe(
            mergeMap(() => of(void 0)),
        );
    }

    /**
     * Lädt die Kontoverbindungen
     *
     * @returns {Observable<IBankAccount[] | void>} Kontoverbindungen als Observable
     */
    public loadBankAccounts(): Observable<IBankAccount[] | void> {
        return this.loadMasterData<IBankAccount, BankAccountsLoaded>(
            '/MasterData/bankAccount',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.SelectListsAdministrator,
            (masterdataState: IMasterdataStateModel) => masterdataState.bankAccountsLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.bankAccounts,
            BankAccountsLoaded,
        );
    }

    /**
     * Lädt die Filialen
     *
     * @returns {Observable<IBranch[] | void>} Filialen als Observable
     */
    public loadBranches(): Observable<IBranch[] | void> {
        return this.loadMasterData<IBranch, BranchesLoaded>(
            '/MasterData/branch',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.BranchAdministrator,
            (masterdataState: IMasterdataStateModel) => masterdataState.branchesLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.branches,
            BranchesLoaded,
        );
    }

    /**
     * Lädt die Länder
     *
     * @returns {Observable<ICountry[] | void>} Länder als Observable
     */
    public loadCountries(): Observable<ICountry[] | void> {
        return this.loadMasterData<ICountry, CountriesLoaded>(
            '/MasterData/country',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.SelectListsAdministrator,
            (masterdataState: IMasterdataStateModel) => masterdataState.countriesLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.countries,
            CountriesLoaded,
        );
    }

    /**
     * Lädt die Matrix zur Ermittlung der Legalisierungsgebühren
     *
     * @returns {Observable<ILegalisationFeeBase[] | void>} Matrix zur Ermittlung der Legalisierungsgebühren als Observable
     */
    public loadLegalisationFeeBases(): Observable<ILegalisationFeeBase[] | void> {
        return this.loadMasterData<ILegalisationFeeBase, LegalisationFeeBasesLoaded>(
            '/MasterData/legalisationFeeBase',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader,
            (masterdataState: IMasterdataStateModel) => masterdataState.legalisationFeeBasesLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.legalisationFeeBases,
            LegalisationFeeBasesLoaded,
        );
    }

    /**
     * Lädt die Vertriebspartnercenter
     *
     * @returns {Observable<ISalesPartnerCenter[] | void>} Vertriebspartnercenter als Observable
     */
    public loadSalesPartnerCenters(): Observable<ISalesPartnerCenter[] | void> {
        return this.loadMasterData<ISalesPartnerCenter, SalesPartnerCentersLoaded>(
            '/MasterData/salesPartnerCenter',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.SalesPartnerCenterAdministrator,
            (masterdataState: IMasterdataStateModel) => masterdataState.salesPartnerCentersLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.salesPartnerCenters,
            SalesPartnerCentersLoaded,
        );
    }

    /**
     * Lädt die Kompetenztablle
     *
     * @returns {Observable<IApprovalCompetence[] | void>} Kompetenztabelle als Observable
     */
    public loadApprovalCompetence(): Observable<IApprovalCompetence[] | void> {
        return this.loadMasterData<IApprovalCompetence, ApprovalCompetenceLoaded>(
            '/MasterData/approvalCompetence',
            Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.SalesPartnerCenterAdministrator,
            (masterdataState: IMasterdataStateModel) => masterdataState.approvalCompetenceLastModified,
            (masterdataState: IMasterdataStateModel) => masterdataState.approvalCompetence,
            ApprovalCompetenceLoaded,
        );
    }

    /**
     * Lädt die Masterdaten anhand von Parametern
     *
     * @template T Type der Stammdaten
     * @template AT Type der zugehörigen State-Action
     * @param {string} url Endpunkt
     * @param {Role} roles Benötigte Rollen
     * @param {(masterdataState: IMasterdataStateModel) => string | undefined} lastModifiedSelector Selektor für das Feld Zuletzt geändert am
     * @param {(masterdataState: IMasterdataStateModel) => T[]} dataSelector Selektor für die Daten
     * @param {ConstructorParameters} actionType Type für State-Action
     * @param {boolean} needAll Werden alle Rollen benötigt
     * @returns {Observable<T[] | void>} Stammdaten als Observable
     */
    private loadMasterData<T, AT>(
        url: string,
        roles: Role,
        lastModifiedSelector: (
            masterdataState: IMasterdataStateModel) =>
                string | undefined, dataSelector: (masterdataState: IMasterdataStateModel) => T[],
        actionType: (new (payload: { data: T[], lastModified?: string; }) => AT),
        needAll = false): Observable<T[] | void> {
        return this.authorizationService.checkPermissions$(
            roles,
            needAll,
            false,
        ).pipe(
            take(1),
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.head(`${this.config.getEnvironment().apiUrl}${url}`, { observe: 'response' }).pipe(
                    map(response => (response.headers.has('Last-Modified') && response.headers.get('Last-Modified') !== null ? response.headers.get('Last-Modified') as string : undefined)),
                    mergeMap(lastModified => this.store.selectOnce((it: IMasterdataParentDefinition) => it.masterdata).pipe(
                        mergeMap(masterdataState => iif(
                            () => {
                                const storedLastModified = lastModifiedSelector(masterdataState);
                                return lastModified === undefined || storedLastModified === undefined || new Date(lastModified) > new Date(storedLastModified)
                            },
                            this.httpClient.get<T[]>(`${this.config.getEnvironment().apiUrl}${url}`).pipe(
                                mergeMap((data: T[]) =>
                                    this.store.dispatch(MasterdataService.createActionClass<T, AT>(actionType, {
                                        data,
                                        lastModified,
                                    })).pipe(
                                        map(() => data),
                                    ),
                                ),
                            ),
                            of(dataSelector(masterdataState)),
                        )),
                    )),
                ),
                of(void 0),
            )),
        );
    }
}
