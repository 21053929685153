/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { IToken } from './../../interfaces';
import { Login, Logout, TokenExpired } from './authentication.actions';

/**
 * Zustandsobjekt für aktuelle Authentifizierung
 */
export interface IAuthenticationStateModel {
    /**
     * Authentifizierungstoken
     */
    token?: IToken;

    /**
     * Ob es sich um einen externen Login handelt
     */
    externalLogin?: boolean;
}

/**
 * Zustand für aktuelle Authentifizierung
 */
@State<IAuthenticationStateModel>({
    name: AuthenticationState.keyName,
    defaults: {},
})
@Injectable()
export class AuthenticationState {

    public static readonly keyName = 'authentication';

    /**
     * Selektor für Authentiifzierungstoken
     *
     * @param {IAuthenticationStateModel} state Zustandsobjekt für aktuellen Nutzer
     * @returns {IToken} Authentiifzierungstoken
     */
    @Selector()
    public static token(state: IAuthenticationStateModel): IToken | undefined {
        return state.token;
    }

    /**
     * Zustandsänderung nach erfolgreichen Login
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {Login} action Aktion
     */
    @Action(Login)
    public login({ patchState }: StateContext<IAuthenticationStateModel>, { payload }: Login): void {
        patchState({
            token: payload.token,
            externalLogin: payload.externalLogin,
        });
    }

    /**
     * Zustandsänderung nach erfolgreichen Logout
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public logout({ setState }: StateContext<IAuthenticationStateModel>): void {
        setState({});
    }

    /**
     * Zustandsänderung bei abgelaufenem Authentifizierungstoken
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(TokenExpired)
    public tokenExpired({ patchState }: StateContext<IAuthenticationStateModel>): void {
        patchState({
            token: undefined,
        });
    }
}
