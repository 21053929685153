<div class="finprocess-select-button-container" #selectButtonContainer>
    <button mat-raised-button [color]="color" type="button" [disabled]="disabled || (!!selectedAction?.disabled && selectedAction?.disabled())" class="finprocess-select-button-action" (click)="executeAction()">
        <span *ngIf="selectedAction?.label">{{ selectedAction?.label }}</span>
        <ng-content></ng-content>
    </button>
    <button mat-raised-button type="button" [disabled]="disabled" [color]="color" class="finprocess-select-button-select" (click)="toggleOverlay()">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <ng-template #overlayTemplate>
        <div class="finprocess-select-button-list mat-elevation-z4">
            <mat-option *ngFor="let action of actions" (click)="chooseOption(action)">{{ action?.label }}</mat-option>
        </div>
    </ng-template>
</div>