<h1 mat-dialog-title class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <ng-container [ngSwitch]="data.liabilityRequest">
        <span *ngSwitchCase="'add'">{{'financing.features.financing-processing.liabilities.addLiability.addLiabilityTitle' | translate }}</span>
        <span *ngSwitchCase="'update'">{{'financing.features.financing-processing.liabilities.addLiability.editLiabilityTitle' | translate }}</span>
        <span *ngSwitchCase="'fictionalRate'">{{'financing.features.financing-processing.liabilities.addLiability.fictionalRateTitle' | translate }}</span>
        <span *ngSwitchDefault>{{'financing.features.financing-processing.liabilities.addLiability.addLiabilityTitle' | translate }}</span>
    </ng-container>
</h1>

<mat-dialog-content>
    <form>
        <div class="liability-grid" [formGroup]="liabilityForm">
            <h4 class="color-primary" style="grid-row: 1; grid-column: 1 / span 2;">
                {{'financing.features.financing-processing.liabilities.addLiability.assignmentTitle' | translate }}
            </h4>

            <mat-form-field appearance="fill" style="grid-row: 2; grid-column: 1 / span 2;">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.assignTo' |
                    translate}}</mat-label>
                <mat-select formControlName="jointHeadingId">
                    <mat-option *ngFor="let data of jointHeadingList; let i = index" [value]="data.id"
                        [matTooltip]="getDebitorsTooltip(data.debitors)">
                        <span *ngFor="let debitor of data.debitors; let debIndex = index">
                            <span>{{debitor.name}}</span>
                            <span *ngIf="debIndex !== data.debitors.length - 1">,</span>
                            &nbsp;
                        </span>
                    </mat-option>
                    <mat-option (click)="openCreateNewJointHeadingDialog()">
                        <mat-icon>add</mat-icon>
                        {{'financing.features.financing-processing.liabilities.addLiability.addNewJH' | translate }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ liabilityForm.get('jointHeadingId')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" style="grid-row: 3; grid-column: 1 / span 2;">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.liabilityType' |
                    translate}}</mat-label>
                <mat-select formControlName="liabilityType"
                    matTooltip="{{ liabilityForm.controls.liabilityType.value !== null && liabilityForm.controls.liabilityType !== undefined ? (liabilityForm.controls.liabilityType.value| finprocessEnumTranslation:'LiabilityType') : 'general.noSelection' | translate}}" > 
                    <mat-option *ngFor="let type of liabilityType" [value]="type" matTooltip="{{type | finprocessEnumTranslation:'LiabilityType'}}">
                        {{type | finprocessEnumTranslation:'LiabilityType'}}
                    </mat-option>
                    <mat-optgroup label="{{ 'financing.features.financing-processing.liabilities.addLiability.specialCases' | translate }}">
                        <mat-option *ngFor="let type of liabilityTypeSpecial" [value]="type" matTooltip="{{type | finprocessEnumTranslation:'LiabilityType'}}">
                            {{type | finprocessEnumTranslation:'LiabilityType'}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-hint align="start" class="mat-hint" *ngIf="fictionalRate$.value !== undefined">
                    Fiktiver Zinsatz: {{fictionalRate$.value}}%
                </mat-hint>
                <mat-hint align="start" class="mat-hint"
                    *ngIf="!fictionalRate$.value === undefined && liabilityForm.controls.liabilityType.value !== null">
                    {{'financing.features.financing-processing.liabilities.addLiability.noFictionalRate' |
                    translate}}
                </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill" style="grid-row: 4; grid-column: 1;">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.numberOfBorrowers' |
                    translate}} </mat-label>
                <input matInput formControlName="numberOfBorrowers" currencyMask
                    [options]="numberMaskOptions">
                <mat-error>
                    {{ liabilityForm.controls.numberOfBorrowers.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" style="grid-row: 4; grid-column: 2;">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.numberOfBorrowersInThisApplication' |
                    translate}} </mat-label>
                <input matInput formControlName="numberOfBorrowersInThisApplication" currencyMask [options]="numberMaskOptions">
                <mat-error>
                    {{ liabilityForm.controls.numberOfBorrowersInThisApplication.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <h4 class="color-primary" style="grid-row: 5; grid-column: 1 / span 2;">
                {{'financing.features.financing-processing.liabilities.addLiability.creditInformationTitle' | translate
                }}
            </h4>

            <mat-form-field style="grid-row: 6; grid-column: 1" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.creditInstitution' |
                    translate }}: </mat-label>
                <input matInput formControlName="ibanCreditor" type="string">
                <mat-error>
                    {{ liabilityForm.get('ibanCreditor')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field style="grid-row: 6; grid-column: 2" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.bankType' | translate
                    }}</mat-label>
                <mat-select formControlName="liabilityBank">
                    <mat-option *ngFor="let type of liabilityBank; let i = index" [value]="type"
                        [matTooltip]="(type | finprocessEnumTranslation:'BankType')!.toString()">
                        {{type | finprocessEnumTranslation:'BankType'}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{ liabilityForm.controls.liabilityBank.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <ng-container *ngIf="showStarted">
                <mat-form-field style="grid-row: 7; grid-column: 1;" appearance="fill">
                    <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.creditStartDate' |
                        translate
                        }}:</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="started">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>
                        {{liabilityForm.get('started')?.errors | finProcessFormError}}
                    </mat-error>
                    <mat-hint align="start" class="mat-hint" *ngIf="calculatedEndDate">
                        {{'financing.features.financing-processing.liabilities.addLiability.creditEndDate' |
                        translate}}:
                        {{ calculatedEndDate }}
                    </mat-hint>
                </mat-form-field>
    
                <mat-form-field style="grid-row: 7; grid-column: 2;" appearance="fill">
                    <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.duration' |
                        translate}}</mat-label>
                    <input matInput currencyMask formControlName="loanPeriodInMonths" [options]="numberMaskOptions">
                    <mat-error>
                        {{ liabilityForm.get('loanPeriodInMonths')?.errors | finProcessFormError}}
                    </mat-error>
                    <mat-hint *ngIf="(calculatedRemainingTimeInMonths$ | async) !== undefined" align="start" class="mat-hint">
                        {{'financing.features.financing-processing.liabilities.addLiability.remainingTime' |
                        translate}}:
                        {{calculatedRemainingTimeInMonths$ | async}}</mat-hint>
                </mat-form-field>
            </ng-container>


            <mat-form-field *ngIf="liabilityForm.controls.liabilityType.value !== null && !checkLiabilityTypes.includes(liabilityForm.controls.liabilityType.value)" style="grid-row: 8; grid-column: 1;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.originalAmount' |
                    translate
                    }}: </mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="initialAmount">
                <mat-error>
                    {{ liabilityForm.get('initialAmount')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="liabilityForm.controls.liabilityType.value !== null &&  checkLiabilityTypes.includes(liabilityForm.controls.liabilityType.value)" style="grid-row: 8; grid-column: 1;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.creditLimit' |
                    translate
                    }}: </mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="creditLimit">
                <mat-error>
                    {{ liabilityForm.get('creditLimit')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field style="grid-row: 8; grid-column: 2;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.saldo' | translate }}
                </mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="currentAmount">
                <mat-error>
                    {{ liabilityForm.get('currentAmount')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>
            
            <mat-hint *ngIf="!!liabilityForm.get('currentAmount')?.errors || liabilityForm.get('initialAmount')?.errors || liabilityForm.get('initialAmount')?.errors" style="grid-row: 9; grid-column: 1 / span 2;" class="ml-3 custom-hint">
                {{'financing.features.financing-processing.liabilities.addLiability.hintMissing' | translate }}
            </mat-hint>

            <mat-form-field style="grid-row: 10; grid-column: 1;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.interestRate' |
                    translate}}
                </mat-label>
                <input matInput currencyMask [options]="percentageMaskOptions" formControlName="interestRate">
            </mat-form-field>


            <mat-form-field style="grid-row: 10; grid-column: 2;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.monthlyRate' |
                    translate}}
                </mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="monthlyRate">
                <mat-error>
                    {{liabilityForm.get('monthlyRate')?.errors | finProcessFormError}}
                </mat-error>
            </mat-form-field>

            <mat-form-field style="grid-row: 11; grid-column: 1;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.liability.fictionalRateAmount' |
                    translate}}</mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="fictionalRateAmount">
                <mat-error>{{ liabilityForm.get('fictionalRateAmount')?.errors | finProcessFormError }}</mat-error>
            </mat-form-field>

            <mat-form-field style="grid-row: 11; grid-column: 2;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.loanValue' |
                    translate}}</mat-label>
                <input matInput currencyMask [options]="currencyMaskOptions" formControlName="loanValue">
                <mat-error>{{ liabilityForm.get('loanValue')?.errors | finProcessFormError }}</mat-error>
            </mat-form-field>

            <h4 style="grid-row: 12; grid-column: 1 / span 2;" class="color-primary">
                {{'financing.features.financing-processing.liabilities.addLiability.coverage' | translate }}</h4>

            <mat-form-field style="grid-row: 13; grid-column: 1 / span 2;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.coverage' | translate }}
                </mat-label>
                <mat-select formControlName="covered">
                    <mat-option [value]="true">{{ 'general.yes' | translate }}</mat-option>
                    <mat-option [value]="false">{{ 'general.no' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="grid-row: 14; grid-column: 1 / span 2;" appearance="fill">
                <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.coverageType' |
                    translate}}
                </mat-label>
                <mat-select formControlName="coveredBy">
                    <mat-option *ngFor="let coverageType of coveredBy; let i = index" [value]="coverageType">
                        {{coverageType | finprocessEnumTranslation:"CoveredBy"}}
                    </mat-option>
                    <mat-option [value]="null">{{ 'general.noSelection' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

            <h4 *ngIf="showSecurityValue" style="grid-row: 15; grid-column: 1 / span 2;" class="color-primary">
                {{'financing.features.financing-processing.liabilities.addLiability.valueOfSecurity' | translate }}
            </h4>
            <div *ngIf="showSecurityValue" style="grid-row: 16; grid-column: 1 / span 2;" fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label>{{'financing.features.financing-processing.liabilities.addLiability.valueOfSecurity'
                        |
                        translate}} </mat-label>
                    <input matInput currencyMask [options]="currencyMaskOptions" formControlName="collateralsValue">
                </mat-form-field>
            </div>

            <mat-expansion-panel style="grid-row: 17; grid-column: 1 / span 2;" (opened)="true" (closed)="false"
                class="mb-3">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'financing.features.financing-processing.liabilities.addLiability.addNote' |
                        translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div matExpansionPanelContent>
                    <ckeditor [editor]="editor" [config]="editorConfig" formControlName="note"></ckeditor>

                    <mat-error>{{ liabilityForm.controls.note.errors | finProcessFormError}}</mat-error>
                    <div>{{currentNoteLength}} / {{maxCharCount}}</div>
                </div>
            </mat-expansion-panel>
        </div>

        <div mat-dialog-actions align="end">
            <button mat-raised-button color="primary" [disabled]="!liabilityForm.valid" (click)="updateLiability()">
                <span
                    *ngIf="data.liabilityRequest === 'edit' || data.liabilityRequest === 'fictionalRate'">{{'financing.features.financing-processing.liabilities.addLiability.updateLiabilityButton'
                    | translate }}</span>
                <span
                    *ngIf="data.liabilityRequest === 'add'">{{'financing.features.financing-processing.liabilities.addLiability.addNewLiabilityButton'
                    | translate }}</span>
            </button>
            <button mat-raised-button
                mat-dialog-close>{{'financing.features.financing-processing.liabilities.addLiability.cancelNewLiabilityButton'
                | translate }}</button>
        </div>
    </form>
</mat-dialog-content>