import packageJson from '../../package.json';

/**
 * Hilfsservice für Berechnungsversionen
 */
export class CalculationVersionHelper {

    /**
     * Aktuelle Version der Berechnungen
     */
    public static readonly currentCalculationVersion: number = packageJson.calculationVersion;

    /**
     * Historie der Berechnungsversionen
     */
    public static readonly calculationVersionHistory: Record<string,string> = packageJson.calculationVersionHistory;
}
