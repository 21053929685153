<ng-template #documentTemplate 
    let-checked="checked" 
    let-documentType="documentType" 
    let-changeStatusTo="changeStatusTo"
    let-title="title"
    let-note="note"
    let-showEditor="showEditor"
    let-oldFileId="oldFileId"
>
    <ucba-list-element>
        <mat-checkbox
            ucba-list-content-left
            class="mr-2"
            *ngIf="mandantType.BAF !== financingMandantType"
            color="primary"
            [checked]="checked"
            [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)"
            (change)="
                changeStatus(documentType, changeStatusTo, selectedHousehold!)
            ">
        </mat-checkbox>
        <div class="document-info-wrapper" ucba-list-content-center>
            <div><b>{{ title | translate }}</b></div>
            <div class="document-note-wrapper" *ngIf="note" [innerHTML]="onSanitize(note)"></div>
            <div *ngIf="!!selectedHousehold?.additionalSheetDocumentCreate">
                {{ 'financing.features.financing-processing.additionalSheets.docDate' | translate }}
                {{ selectedHousehold?.additionalSheetDocumentCreate | date }}
            </div>
        </div>
        <div class="document-action-buttons-wrapper ml-2" ucba-list-content-right>
            <button
                mat-mini-fab 
                *ngIf="mandantType.BAF !== financingMandantType"
                [matTooltip]="!showEditor ? ('financing.features.financing-processing.additionalSheets.tooltipEditNotes' | translate) : ('financing.features.financing-processing.additionalSheets.tooltipStopEditNotes' | translate)" 
                color="primary"
                class="note-icon"
                (click)="showHideNoteEditor(documentType)"
                [disabled]="!checked || isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                <mat-icon *ngIf="!showEditor">edit_note</mat-icon>
                <mat-icon *ngIf="showEditor">edit_off</mat-icon>
            </button>

            <a
                *ngIf="mandantType.BAF === financingMandantType"
                mat-mini-fab
                [matTooltip]="'financing.features.financing-processing.additionalSheets.tooltipUploadDocument' | translate"
                href="javascript:void(0)"
                color="primary"
                class="mr-2"
                (click)="file.click()"
                [disabled]="isStatusNotOpenOrWaiting || (fieldReadonly$ | async)">
                <input
                    accept="application/pdf"
                    style="display: none"
                    type="file"
                    #file
                    [multiple]="false"
                    (change)="
                        uploadFile(
                            $event.target,
                            selectedHousehold!,
                            documentType,
                            oldFileId
                        )
                    " 
                />
                <mat-icon>upload</mat-icon>
            </a>

            <button
                mat-mini-fab
                *ngIf="mandantType.BAF === financingMandantType"
                class="note-icon"
                color="primary"
                [matTooltip]="'financing.features.financing-processing.additionalSheets.tooltipOpenDocument' | translate"
                [disabled]="oldFileId === undefined || (fieldReadonly$ | async)"
                (click)="openFile(oldFileId)">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </div>
        <div [class.hidden-editor]="!(showEditor && checked)" class="editor-wrapper ml-5 mr-1">
            <div class="mt-2">
                <ckeditor
                    [disabled]="!(showEditor && checked)"
                    [editor]="editor"
                    [data]="note ?? ''"
                    [config]="editorConfig"
                    (blur)="onEditorChangeNote($event, selectedHousehold!, documentType)">
                </ckeditor>
            </div>
        </div>
    </ucba-list-element>
</ng-template>

<div *ngIf="sheets.length > 0; else elseBlock">
    <div class="households-chip-navigation">
        <ucba-chip-list (selectionChange)="onHouseholdChange($event)">
            <ucba-chip [color]="'primary'" *ngFor="let sheet of sheets let i = index">{{ 'financing.features.financing-processing.additionalSheets.houseHoldTitle' | translate:{ number: i + 1 } }}</ucba-chip>
        </ucba-chip-list>
    </div>
    <div fxLayout="row" fxLayoutAlign="end start" class="mr-3 mt-2">
        <button
            mat-raised-button
            color="primary"
            class="note-icon mr-2"
            *ngIf="mandantType.BAF !== financingMandantType"
            [disabled]="selectedHousehold?.additionalSheetFileId === undefined || isSheetChangedNewDocumentNotGenerated || (fieldReadonly$ | async)"
            (click)="openFile(selectedHousehold?.additionalSheetFileId)">
            {{ 'financing.features.financing-processing.additionalSheets.openDocument' | translate }}
        </button>
    
        <button
            mat-raised-button
            color="primary"
            class="note-icon"
            *ngIf="mandantType.BAF !== financingMandantType"
            (click)="generateDocument(selectedHousehold?.householdId!)"
            [disabled]="
                (!selectedHousehold?.creditDurationInRetirementAdditionalSheet &&
                    !selectedHousehold?.otherIncomeContainsAlimonyAdditionalSheet &&
                    !selectedHousehold?.compositionOfIncomeAdditionalSheet) ||
                isStatusNotOpenOrWaiting ||
                (fieldReadonly$ | async)
            ">
            {{ 'financing.features.financing-processing.additionalSheets.generateDocument' | translate }}
        </button>
    </div>
    
    <div class="mt-3">
        <ucba-list>
            <ng-container *ngTemplateOutlet="documentTemplate; 
                context: {
                    checked: selectedHousehold?.creditDurationInRetirementAdditionalSheet, 
                    documentType: documentType.CreditDurationInRetirementSignature, 
                    changeStatusTo: !selectedHousehold?.creditDurationInRetirementAdditionalSheet, 
                    title: 'financing.features.financing-processing.additionalSheets.pensionTitle',
                    note: selectedHousehold?.creditDurationInRetirementAdditionalSheetText,
                    showEditor: showNoteEditorPension,
                    oldFileId: selectedHousehold?.creditDurationInRetirementAdditionalSheetFileId,
                }"
            ></ng-container>
            <ng-container *ngTemplateOutlet="documentTemplate; 
                context: {
                    checked: selectedHousehold?.otherIncomeContainsAlimonyAdditionalSheet, 
                    documentType: documentType.CompositionOfOtherIncomeSignature, 
                    changeStatusTo: !selectedHousehold?.otherIncomeContainsAlimonyAdditionalSheet, 
                    title: 'financing.features.financing-processing.additionalSheets.alTitle',
                    note: selectedHousehold?.otherIncomeContainsAlimonyAdditionalSheetText,
                    showEditor: showNoteEditorAlimony,
                    oldFileId: selectedHousehold?.otherIncomeContainsAlimonyAdditionalSheetFileId,
                }"
            ></ng-container>
            <ng-container *ngTemplateOutlet="documentTemplate; 
                context: {
                    checked: selectedHousehold?.compositionOfIncomeAdditionalSheet, 
                    documentType: documentType.CompositionOfIncomeSignature, 
                    changeStatusTo: !selectedHousehold?.compositionOfIncomeAdditionalSheet, 
                    title: 'financing.features.financing-processing.additionalSheets.incTitle',
                    note: selectedHousehold?.compositionOfIncomeAdditionalSheetText,
                    showEditor: showNoteEditorIncome,
                    oldFileId: selectedHousehold?.compositionOfIncomeAdditionalSheetFileId,
                }"
            ></ng-container>
        </ucba-list>
    </div>
</div>
<ng-template #elseBlock>
    <div class="pt-5 pb-5 pl-3 pr-3" fxLayoutAlign="center">
        {{ 'financing.features.financing-processing.additionalSheets.noAdditionalSheets' | translate }}
    </div>
</ng-template>
