import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AuthenticationService, AuthorizationService, LoginStateType, Role } from '../../../../data';

import { IRoleData } from './../../interfaces';

/**
 * Direktive zu gesteuerten Anzeige nur bei notwendigen Rollen
 */
@Directive({
    selector: '[finprocessAuthorization]',
})
export class AuthorizationDirective implements OnDestroy, OnInit {
    /**
     * Ist Template gerendert
     */
    private hasView?: boolean;

    /**
     * Subject, welches beim Entfernen der Komponente einen Wert emitted
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Subscription zum Ändern der Anzeige des Template
     */
    private subscription?: Subscription;

    /**
     * Setzt die notwendigen Rollendaten und rendert das Template oder entfernt dieses
     */
    @Input()
    public set finprocessAuthorization(data: Role | IRoleData) {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
        const roles = Role[data as unknown as number] !== undefined ? (data as Role) : (data as IRoleData).roles;
        const needOnlyOne = Role[data as unknown as number] !== undefined ? false : ((data as IRoleData).needOnlyOne ?? false);
        if (needOnlyOne) {
            this.subscription = this.authorizationService.hasOneRoleOf$(roles).pipe(
                takeUntil(this.onDestroy$),
                tap(hasRole => {
                    if (hasRole && this.hasView !== true) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    }
                    else if (!hasRole && this.hasView === true) {
                        this.viewContainerRef.clear();
                        this.hasView = false;
                    }
                }),
            ).subscribe();
        }
        else {
            this.subscription = this.authorizationService.hasRole$(roles).pipe(
                takeUntil(this.onDestroy$),
                tap(hasRole => {
                    if (hasRole && this.hasView !== true) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    }
                    else if (!hasRole && this.hasView === true) {
                        this.viewContainerRef.clear();
                        this.hasView = false;
                    }
                }),
            ).subscribe();
        }
    }

    /**
     * Konstruktor
     *
     * @param {ViewContainerRef} viewContainerRef ViewContainerRef-Injektor
     * @param {TemplateRef} templateRef TemplateRef-Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Angular-Hook Initialisieren der Direktive
     */
    public ngOnInit(): void {
        if (this.subscription === undefined) {
            this.subscription = this.authenticationService.loginState$.pipe(
                takeUntil(this.onDestroy$),
                tap(loginState => {
                    const show = loginState === LoginStateType.LoggedIn;
                    if (show && this.hasView !== true) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    }
                    else if (!show && this.hasView === true) {
                        this.viewContainerRef.clear();
                        this.hasView = false;
                    }
                }),
            ).subscribe();
        }
    }

    /**
     * Angular-Hook Entfernen der Direktive
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
