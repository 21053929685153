import { EnumSettings } from './../enum.settings';

/**
 * Herkunft des ermittelten Marktwerts
 */
export enum MarketValueType {
    /**
     * LIEBE Light
     */
    Liebe = 0,

    /**
     * Abschlagsrechnung
     */
    DiscountCalculation = 1,

    /**
     * Manuelle Eingabe
     */
    Input = 2,
}

export namespace MarketValueType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {MarketValueType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: MarketValueType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'MarketValueType', MarketValueType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('MarketValueType', plocale);
}
