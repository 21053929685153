import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FinprocessFormBuilder } from './forms/form-builder';

/**
 * Finprocess Forms Module
 */
@NgModule({
    imports: [FormsModule],
    providers: [
        FinprocessFormBuilder,
    ],
})
export class FinProcessFormsModule {}

