/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { HelperService } from 'app/modules/shared';
import { UserSubstitutionRemoved } from 'app/modules/user-area/data';
import { Logout } from 'auth';
import { FinancingStatus, FinancingSubStatus, SortOrder } from 'shared/data';

import { IDashboardSorting } from '../../interfaces';
import { DashboardSorting, DashboardStatusMap } from '../../types';

import { DashboardFilter, DashboardFinancingsSortValues, DashboardTab, DashboardType, SalesPartnerCenterFilter } from './../../enums/';
import { DashboardTypeChanged, PageChanged, PageSizeChanged, SalesPartnerCenterFilterChanged, SearchChanged, SortingChanged, StatusFilterChanged, TabChanged, TabsSynced } from './dashboard.actions';

/**
 * Optionen für Dashboard-Tab
 */
export interface IDashboardTabOptions {
    /**
     * Identifier des Tab
     */
    tabId: string;

    /**
     * Name des Tabs
     */
    name?: string;

    /**
     * Handelt es sich um ein Vertretungstab
     */
    isTemporaryUser: boolean;

    /**
     * Aktuelle Seite
     */
    currentPage: number;

    /**
     * Einträge pro Seite
     */
    currentPageSize?: number;

    /**
     * Aktueller Filter
     */
    currentFilter: DashboardFilter;

    /**
     * Aktuelle Sortierungen
     */
    currentSorting: DashboardSorting;

    /**
     * Aktuelle Volltextsuche
     */
    currentSearch?: string;
}

/**
 * Zustandsobjekt für Dashboard
 */
export interface IDashboardStateData {
    currentTab: string;
    salesPartnerCenterFilter: SalesPartnerCenterFilter,
    salesPartnerCenterIds: string[],
    tabState: IDashboardTabOptions[];
}

type Dashboards = {
    [key in DashboardType]: IDashboardStateData;
}

export interface IDashboardStateModel {
    dashboards: Dashboards,
    currentDashboard?: DashboardType,
    version?: string,
}

export const defaultData: IDashboardStateData = {
    currentTab: DashboardTab.My,
    salesPartnerCenterFilter: SalesPartnerCenterFilter.None,
    salesPartnerCenterIds: [],
    tabState: [
        {
            tabId: DashboardTab.All,
            isTemporaryUser: false,
            currentPage: 1,
            currentPageSize: 20,
            currentFilter: DashboardFilter.None,
            currentSorting: {
                [DashboardFinancingsSortValues.SubmissionDate]: SortOrder.Ascending,
            },
        },
        {
            tabId: DashboardTab.My,
            isTemporaryUser: false,
            currentPage: 1,
            currentPageSize: 20,
            currentFilter: DashboardFilter.None,
            currentSorting: {
                [DashboardFinancingsSortValues.SubmissionDate]: SortOrder.Descending,
            },
        },
    ],
};

const defaultModel: IDashboardStateModel = {
    dashboards: {
        [DashboardType.Expert]: defaultData,
        [DashboardType.Referent]: defaultData,
        [DashboardType.Approver]: defaultData,
    },
    version: '2',
}

/**
 * Zustand für das Dashboard
 */
@State<IDashboardStateModel>({
    name: DashboardState.keyName,
    defaults: defaultModel,
})
@Injectable()
export class DashboardState {
    public static readonly keyName = 'dashboard';

    /**
     * Liefert die benötigten Statusmap zum Laden vomn Daten
     *
     * @param {DashboardFilter} filter Filter
     * @param {DashboardType} type Typ des Dashboards
     * @returns {DashboardStatusMap} Statusmap
     */
    // eslint-disable-next-line complexity
    public static getStatusMapByFilter(filter: DashboardFilter, type: DashboardType): DashboardStatusMap {
        switch (filter) {
            case DashboardFilter.StatusOpen:
            case DashboardFilter.Editing:
            case DashboardFilter.StatusSampleCalculationRejected:
                return {
                    [FinancingStatus.Open]: this.getSubStatusByFilter(filter, FinancingStatus.Open),
                };
            case DashboardFilter.StatusCanceled:
                return {
                    [FinancingStatus.Canceled]: this.getSubStatusByFilter(filter, FinancingStatus.Canceled),
                };
            case DashboardFilter.StatusRejected:
            case DashboardFilter.StatusAutomaticallyRejected:
            case DashboardFilter.StatusRejectedByResponsibility:
                return {
                    [FinancingStatus.Rejected]: this.getSubStatusByFilter(filter, FinancingStatus.Rejected),
                };
            case DashboardFilter.StatusSampleCalculationWaitingForAcception:
                return {
                    [FinancingStatus.SampleCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationWaitingForAcception),
                };
            case DashboardFilter.StatusSampleCalculationAccepted:
                return {
                    [FinancingStatus.SampleCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationAccepted),
                };
            case DashboardFilter.StatusEsisRejected:
                return {
                    [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                };
            case DashboardFilter.StatusEsisWaitingForAcception:
                return {
                    [FinancingStatus.EsisWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.EsisWaitingForAcception),
                };
            case DashboardFilter.StatusCompleted:
                return {
                    [FinancingStatus.Completed]: this.getSubStatusByFilter(filter, FinancingStatus.Completed),
                };
            case DashboardFilter.Finalize:
                return {
                    [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                };
            case DashboardFilter.ReadyForReferee:
                return {
                    [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                };
            case DashboardFilter.Accounting:
                return {
                    [FinancingStatus.Open]: this.getSubStatusByFilter(filter, FinancingStatus.Open),
                    [FinancingStatus.SampleCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationWaitingForAcception),
                    [FinancingStatus.SampleCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationAccepted),
                    [FinancingStatus.EsisWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.EsisWaitingForAcception),
                    [FinancingStatus.Canceled]: this.getSubStatusByFilter(filter, FinancingStatus.Canceled),
                    [FinancingStatus.Rejected]: this.getSubStatusByFilter(filter, FinancingStatus.Rejected),
                    [FinancingStatus.Completed]: this.getSubStatusByFilter(filter, FinancingStatus.Completed),
                    [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                };
            case DashboardFilter.None:
            default:
                if (type === DashboardType.Expert || type === DashboardType.Approver) {
                    return {
                        [FinancingStatus.Open]: this.getSubStatusByFilter(filter, FinancingStatus.Open),
                        [FinancingStatus.SampleCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationWaitingForAcception),
                        [FinancingStatus.SampleCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationAccepted),
                        [FinancingStatus.HouseholdCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.HouseholdCalculationWaitingForAcception),
                        [FinancingStatus.HouseholdCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.HouseholdCalculationAccepted),
                        [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                        [FinancingStatus.EsisWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.EsisWaitingForAcception),
                    };
                } else if (type === DashboardType.Referent) {
                    return {
                        [FinancingStatus.SampleCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationAccepted),
                        [FinancingStatus.EsisWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.EsisWaitingForAcception),
                        [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                    };
                }
                return {
                    [FinancingStatus.Open]: this.getSubStatusByFilter(filter, FinancingStatus.Open),
                    [FinancingStatus.SampleCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationWaitingForAcception),
                    [FinancingStatus.SampleCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.SampleCalculationAccepted),
                    [FinancingStatus.EsisWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.EsisWaitingForAcception),
                    [FinancingStatus.Finalize]: this.getSubStatusByFilter(filter, FinancingStatus.Finalize),
                    [FinancingStatus.HouseholdCalculationWaitingForAcception]: this.getSubStatusByFilter(filter, FinancingStatus.HouseholdCalculationWaitingForAcception),
                    [FinancingStatus.HouseholdCalculationAccepted]: this.getSubStatusByFilter(filter, FinancingStatus.HouseholdCalculationAccepted),
                };
        }
    }

    /**
     * Liefert die benötigten spezifischen Prozessstatus
     *
     * @param {DashboardFilter} filter Filter
     * @param {FinancingStatus} status Hauptprozessstatus
     * @returns {FinancingSubStatus[]} benötigten spezifischen Prozessstatus
     */
    // eslint-disable-next-line complexity
    private static getSubStatusByFilter(filter: DashboardFilter, status: FinancingStatus): FinancingSubStatus[] {
        switch (filter) {
            case DashboardFilter.Editing:
                return status === FinancingStatus.Open ? [FinancingSubStatus.Editing] : [];
            case DashboardFilter.StatusOpen:
                return status === FinancingStatus.Open ? [FinancingSubStatus.Open] : [];
            case DashboardFilter.StatusSampleCalculationRejected:
                return status === FinancingStatus.Open ? [FinancingSubStatus.SampleCalculationRejected] : [];
            case DashboardFilter.StatusRejected:
                return status === FinancingStatus.Rejected ? [FinancingSubStatus.Rejected] : [];
            case DashboardFilter.StatusAutomaticallyRejected:
                return status === FinancingStatus.Rejected ? [FinancingSubStatus.AutomaticallyRejected] : [];
            case DashboardFilter.StatusRejectedByResponsibility:
                return status === FinancingStatus.Rejected ? [FinancingSubStatus.RejectedByResponsibilityPF, FinancingSubStatus.RejectedByResponsibilityGK] : [];
            case DashboardFilter.StatusSampleCalculationAccepted:
                return status === FinancingStatus.SampleCalculationAccepted ? [FinancingSubStatus.SampleCalculationAccepted] : [];
            case DashboardFilter.StatusEsisRejected:
                return status === FinancingStatus.Finalize ? [FinancingSubStatus.EsisRejected] : [];
            case DashboardFilter.ReadyForReferee:
                return status === FinancingStatus.Finalize ? [FinancingSubStatus.ReadyForReferee] : [];
            case DashboardFilter.Finalize:
            case DashboardFilter.StatusCanceled:
            case DashboardFilter.StatusSampleCalculationWaitingForAcception:
            case DashboardFilter.StatusEsisWaitingForAcception:
            case DashboardFilter.StatusCompleted:
            case DashboardFilter.Accounting:
            case DashboardFilter.None:
            default:
                return [];
        }
    }

    /**
     * Vertretenden Tabs
     *
     * @param {IDashboardStateModel} state Dashboard state
     * @returns {IDashboardTabOptions[]} Optionen für Dashboard-Tab
     */
    @Selector()
    public static temporaryUserTabs(state: IDashboardStateModel): (dashboardType: DashboardType) => IDashboardTabOptions[] {
        return (dashboardType: DashboardType) => state.dashboards[dashboardType].tabState.filter(tabState => tabState.tabId !== DashboardTab.All && tabState.tabId !== DashboardTab.My);
    }

    /**
     * Gibt die Optionen des aktiven Tabs eines Dashboards zurück
     * 
     * @param {IDashboardStateModel} state Dashboard state
     * @returns {(dashboardType: DashboardType) => IDashboardTabOptions | undefined} Optionen des Tabs
     */
    @Selector()
    public static tabSettings(state: IDashboardStateModel): (dashboardType: DashboardType) => IDashboardTabOptions | undefined {
        return (dashboardType: DashboardType) => state.dashboards[dashboardType].tabState.find(tab => tab.tabId === state.dashboards[dashboardType].currentTab);
    }

    /**
     * Gibt den ausgewählten Tab des aktuell aktiven Dashboards zurück
     * 
     * @param {IDashboardStateModel} state Dashboard state
     * @returns {string | undefined} Aktuell ausgewählter Tab
     */
    @Selector()
    public static currentTab(state: IDashboardStateModel): string | undefined {
        if (state.currentDashboard === undefined) {
            return undefined;
        }


        return state.dashboards[state.currentDashboard].currentTab;
    }

    /**
     * Gibt den aktiven Dashboards zurück
     * 
     * @param {IDashboardStateModel} state Dashboard state
     * @returns {string | undefined} Aktuell ausgewählter Tab
     */
    @Selector()
    public static currentDashboard(state: IDashboardStateModel): DashboardType | undefined {
        return state?.currentDashboard;
    }

    /**
     * Gibt die aktuelle Sortierung als Array zurück
     * 
     * @param {IDashboardStateModel} state Dashboard state
     * @returns {(dashboardType: DashboardType) => Array<IDashboardSorting>} Sortierung
     */
    @Selector()
    public static currentSorting(state: IDashboardStateModel): (dashboardType: DashboardType) => Array<IDashboardSorting> {
        return (dashboardType: DashboardType) => {
            const dashboard = state.dashboards[dashboardType];
            if (!dashboard) {
                return [];
            }

            const currentTab = dashboard.tabState.find(tab => tab.tabId === dashboard.currentTab);

            if (!currentTab) {
                return [];
            }

            const keys = Object.keys(currentTab.currentSorting).map(key => parseInt(key, 10)) as unknown as Array<keyof DashboardSorting>;
            const sortings: Array<IDashboardSorting> = [];

            for (const key of keys) {
                const sortOrder = currentTab.currentSorting[key];

                if (sortOrder === undefined) {
                    continue;
                }

                sortings.push({ value: key, order: sortOrder });
            }

            return sortings;
        }
    }

    /**
     * Zustandsänderung nach Wechsel des Tabs
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {TabChanged} action Aktion
     */
    @Action(TabChanged)
    public tabChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: TabChanged): void {
        const dashboards = getState().dashboards;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    currentTab: payload.tabId,
                },
            },
        });
    }

    /**
     * Zustandsänderung bei Synchronisation der Vetretungstabs
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {TabsSynced} action Aktion
     */
    @Action(TabsSynced)
    public tabsSynced({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: TabsSynced): void {
        const state = getState();
        const newDashboards: Partial<Dashboards> = {};
        const dashboardKeys = Object.keys(state.dashboards) as unknown as DashboardType[];

        for (const dashboardKey of dashboardKeys) {
            const dashboard = state.dashboards[dashboardKey];

            newDashboards[dashboardKey] = {
                ...dashboard,
                tabState: [
                    dashboard.tabState.find(it => it.tabId === DashboardTab.All) as IDashboardTabOptions,
                    dashboard.tabState.find(it => it.tabId === DashboardTab.My) as IDashboardTabOptions,
                ],
            };

            if (dashboardKey.toString() === DashboardType.Approver.toString()) {
                continue;
            }

            for (const tab of payload) {
                if (dashboard.tabState.some(it => it.tabId === tab.id)) {
                    newDashboards[dashboardKey]?.tabState.push(dashboard.tabState.find(it => it.tabId === tab.id) as IDashboardTabOptions);
                }
                else {
                    newDashboards[dashboardKey]?.tabState.push({
                        tabId: tab.id,
                        name: `${tab.firstName} ${tab.lastName}`,
                        isTemporaryUser: true,
                        currentPage: 1,
                        currentPageSize: 20,
                        currentFilter: DashboardFilter.None,
                        currentSorting: {
                            [DashboardFinancingsSortValues.SubmissionDate]: SortOrder.Ascending,
                        },
                    });
                }
            }
        }

        patchState({
            dashboards: newDashboards as Dashboards,
        });
    }

    /**
     * Zustandsänderung bei Beenden einer Vertretung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {TabsSynced} action Aktion
     */
    @Action(UserSubstitutionRemoved)
    public userSubstitutionRemoved({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: UserSubstitutionRemoved): void {
        const state = getState();
        const dashboardKeys = Object.keys(state.dashboards) as unknown as DashboardType[];
        const newDashboards: Partial<Dashboards> = {};

        for (const dashboardKey of dashboardKeys) {
            const dashboard = state.dashboards[dashboardKey];
            if (!!dashboard) {
                newDashboards[dashboardKey] = {
                    ...dashboard,
                    tabState: dashboard.tabState.filter(tab => tab.tabId !== payload),
                };
            }
        }

        patchState({
            dashboards: newDashboards as Dashboards,
        });
    }

    /**
     * Zustandsänderung beim Wechsel der Einträge pro Seite
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {PageSizeChanged} action Aktion
     */
    @Action(PageSizeChanged)
    public pageSizeChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: PageSizeChanged): void {
        const dashboards = getState().dashboards;
        const tabState = dashboards[payload.dashboardType].tabState;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    tabState: this.getUpdateTabs(tabState, {
                        ...tabState.find(it => it.tabId === payload.tabId) as IDashboardTabOptions,
                        currentPageSize: payload.pageSize,
                        currentPage: 1,
                    }),
                },
            },
        });
    }

    /**
     * Zustandsänderung beim Wechsel der Seite
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {PageChanged} action Aktion
     */
    @Action(PageChanged)
    public pageChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: PageChanged): void {
        const dashboards = getState().dashboards;
        const tabState = dashboards[payload.dashboardType].tabState;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    tabState: this.getUpdateTabs(tabState, {
                        ...tabState.find(it => it.tabId === payload.tabId) as IDashboardTabOptions,
                        currentPage: payload.page,
                    }),
                },
            },
        });
    }

    /**
     * Zustandsänderung beim Ändern des Statusfilters
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {StatusFilterChanged} action Aktion
     */
    @Action(StatusFilterChanged)
    public statusFilterChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: StatusFilterChanged): void {
        const dashboards = getState().dashboards;
        const tabState = dashboards[payload.dashboardType].tabState;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    tabState: this.getUpdateTabs(tabState, {
                        ...tabState.find(it => it.tabId === payload.tabId) as IDashboardTabOptions,
                        currentFilter: payload.filter,
                        currentPage: 1,
                    }),
                },
            },
        });
    }

    /**
     * Zustandsänderung beim Ändern des Regionsfilters
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SalesPartnerCenterFilterChanged} action Aktion
     */
    @Action(SalesPartnerCenterFilterChanged)
    public salesParnterCenterFilterChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: SalesPartnerCenterFilterChanged): void {
        const dashboards = getState().dashboards;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    salesPartnerCenterFilter: payload.filter,
                    salesPartnerCenterIds: payload.salesPartnerCenterIds,
                },
            },
        });
    }

    /**
     * Zustandsänderung beim Ändern des Volltextsuche
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SearchChanged} action Aktion
     */
    @Action(SearchChanged)
    public searchChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: SearchChanged): void {
        const dashboards = getState().dashboards;
        const tabState = dashboards[payload.dashboardType].tabState;

        patchState({
            dashboards: {
                ...dashboards,
                [payload.dashboardType]: {
                    ...dashboards[payload.dashboardType],
                    tabState: this.getUpdateTabs(tabState, {
                        ...tabState.find(it => it.tabId === payload.tabId) as IDashboardTabOptions,
                        currentSearch: payload.search,
                        currentPage: 1,
                    }),
                },
            },
        });
    }

    /**
     * Zustandsänderung beim Ändern der Sortierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SortingChanged} action Aktion
     */
    @Action(SortingChanged)
    public sortingChanged({ setState }: StateContext<IDashboardStateModel>, { payload }: SortingChanged): void {
        let sorting = payload.sorting;

        if (Object.keys(sorting).length === 0) {
            sorting = defaultData.tabState.find(it => it.tabId === payload.tabId)?.currentSorting || {};
        }

        setState(patch({
            dashboards: patch({
                [payload.dashboardType]: patch({
                    tabState: updateItem<IDashboardTabOptions>(it => it.tabId === payload.tabId, patch({
                        currentSorting: sorting,
                    })),
                })}),
        }));
    }

    /**
     * Zustandsänderung beim Wechseln des Dashboards
     * 
     * @param {StateContext} ctx aktueller State Kontext 
     * @param {DashboardTypeChanged} action Aktion 
     */
    @Action(DashboardTypeChanged)
    public dashboardTypeChanged({ getState, patchState }: StateContext<IDashboardStateModel>, { payload }: DashboardTypeChanged): void {
        const dashboards = getState().dashboards;

        if (dashboards[payload] === undefined) {
            const newDashboards = HelperService.clone(dashboards);
            newDashboards[payload] = defaultData;

            patchState({
                dashboards: newDashboards,
                currentDashboard: payload,
            });
        }

        patchState({
            currentDashboard: payload,
        })
    }

    /**
     * Zustandsänderung beim Verlassen des Dashboard
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public dashboardLeaved({ setState, getState }: StateContext<IDashboardStateModel>): void {
        const state = getState();
        setState({ ...defaultModel, version: state.version, currentDashboard: state.currentDashboard });
    }

    /**
     * Aktualisiert das Tabarray unter Beibehaltung der Reihenfolge
     *
     * @param {IDashboardTabOptions[]} tabs Unmodifizierte Tabs
     * @param {IDashboardTabOptions} tabToUpdate Modifizierter Tab
     * @returns {IDashboardTabOptions[]} Modifizierte Tabs
     */
    private getUpdateTabs(tabs: IDashboardTabOptions[], tabToUpdate: IDashboardTabOptions): IDashboardTabOptions[] {
        const index = tabs.findIndex(it => it.tabId === tabToUpdate.tabId);
        const newTabState = tabs.filter(it => it.tabId !== tabToUpdate.tabId);
        newTabState.splice(index, 0, tabToUpdate);

        return newTabState;
    }
}
