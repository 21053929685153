import { EnumSettings } from './../enum.settings';

/**
 * Zuständigkeit der Organisationseinheit
 */
export enum OrganisationalUnitResponsibilityType {
    /**
     * Keine
     */
    None = 0,

    /**
     * Privatkunden
     */
    PrivateCustomers = 1,

    /**
     * Selbständige und Freiberufler
     */
    SelfEmployed = 2,
}

export namespace OrganisationalUnitResponsibilityType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {OrganisationalUnitResponsibilityType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: OrganisationalUnitResponsibilityType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'OrganisationalUnitResponsibilityType', OrganisationalUnitResponsibilityType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('OrganisationalUnitResponsibilityType', plocale);
}
