<h1 mat-dialog-title>{{'navigation.financing.borrowerCheck.calcAverageIncomeTitle' | translate }}</h1>
<div mat-dialog-content>

    <mat-checkbox class="mb-2 ml-2" [checked]="isChecked()" (change)="toggleChecked()">
        {{ isChecked() ? '12 Zeilen deaktivieren' : '12 Zeilen aktivieren' }}
    </mat-checkbox>
    
    <div fxLayoutGap="10px" fxLayoutAlign="center center" fxLayout="column wrap">
        <form [formGroup]="form">
            <table mat-table [dataSource]="data.controls" class=" custom-table" formArrayName="data">
              <!-- Jahr Column -->
              <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef> {{'navigation.financing.borrowerCheck.calcAverageYear' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" style="width: 80px;">
                  <mat-select formControlName="year">
                    <mat-option [value]="currentYear">{{ currentYear }}</mat-option>
                    <mat-option [value]="previousYear">{{ previousYear }}</mat-option>
                  </mat-select>
                </td>
              </ng-container>
          
              <!-- Monat Column -->
              <ng-container matColumnDef="month">
                <th mat-header-cell *matHeaderCellDef> {{'navigation.financing.borrowerCheck.calcAverageMonth' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" style="width: 110px;">
                  <mat-select formControlName="month">
                      <mat-option *ngFor="let month of months" [value]="month">
                          {{ month }}
                      </mat-option>
                  </mat-select>
              </td>
              </ng-container>
          
              <!-- NetIncome Column -->
              <ng-container matColumnDef="netIncomeAccToStatement">
                <th mat-header-cell *matHeaderCellDef> {{'navigation.financing.borrowerCheck.calcAverageIncome' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <input placeholder="€ 0,00" matInput formControlName="netIncomeAccToStatement" currencyMask [options]="currencyMaskOptions">
                </td>
              </ng-container>
          
              <!-- netIncomeMinusCleanup Column -->
              <ng-container matColumnDef="netIncomeMinusCleanup">
                <th mat-header-cell *matHeaderCellDef> {{'navigation.financing.borrowerCheck.calcAverageIncomeMinusCleanup' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <input placeholder="€ 0,00" matInput formControlName="netIncomeMinusCleanup" currencyMask [options]="currencyMaskOptions">
                </td>
              </ng-container>
          
              <!-- AdjustedNetIncome Column -->
              <ng-container matColumnDef="adjustedNetIncome">
                <th mat-header-cell *matHeaderCellDef> {{'navigation.financing.borrowerCheck.calcAverageCleanedIncome' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <div *ngIf="element.get('netIncomeAccToStatement').value !== undefined && element.get('netIncomeMinusCleanup').value !== undefined">
                    {{ element.get('netIncomeAccToStatement').value - element.get('netIncomeMinusCleanup').value | formatCurrency }}
                  </div>
                  <div *ngIf="element.get('netIncomeAccToStatement').value === undefined || element.get('netIncomeMinusCleanup').value === undefined">-</div>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </form>

        <!--TotalNetIncome-->
        <div>
          {{'navigation.financing.borrowerCheck.calcAverageIncomeSum' | translate }} {{ totalNetIncome | formatCurrency }}
        </div>
        
    </div>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button [disabled]="!form.valid"
        (click)="saveIncomeData()">{{'navigation.financing.borrowerCheck.saveCalculation' | translate }}</button>
    <button mat-button mat-dialog-close>{{'button.cancel' | translate }}</button>
</div>