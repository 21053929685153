import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { FinancingPremissions } from '@ntag-ef/finprocess-enums';
import { Language } from '@ntag-ef/finprocess-enums/finprocess';
import { IMasterdataParentDefinition, ISalesPartnerCenter, OrganisationalUnitResponsibilityType } from 'app/modules/masterdata/data';
import { IUser, Role } from 'auth';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { HelperService } from 'shared/util';

import { IUpdateUserRequest } from './../../../../data/interfaces/update-user.request';


export interface IMultiSelectItem {
    value: number;
    displayName: string;
}

type EditUserForm = FormGroup<{
    salesPartnerCenter: FormControl<string[]>,
    phoneNumber: FormControl<string | null>,
    userPurpose: FormControl<Role[]>,
    language: FormControl<Language[]>,
    confirmed: FormControl<boolean>,
    financingPermissions: FormControl<FinancingPremissions[]>
}>;

/**
 *Componente um einen User zu edit
 */
@Component({
    selector: 'finprocess-edit-user-dialog',
    templateUrl: './edit-user-dialog.component.html',
    styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent implements OnInit {

    @Select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters)
    public salesPartnerCenters$: Observable<ISalesPartnerCenter[]> | undefined;

    public filteredSalesPartnerCenters: ISalesPartnerCenter[] | undefined;
    public allSalesPartnerCenters: ISalesPartnerCenter[] | undefined;
    public userPurposes: Role[] = [Role.Expert, Role.Referent];

    public salesPartnerCentersItems: IMultiSelectItem[] = [];

    public form: EditUserForm;
    public languageItems: IMultiSelectItem[] = [];

    public financingPremisons: IMultiSelectItem[] = [];

    public selectedUserResponsibility = new BehaviorSubject<FinancingPremissions | undefined>(undefined);

    @Output()
    public userDataChanged = new EventEmitter<IUpdateUserRequest>();

    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef MatDialogRef
     * @param {IUser} user übergebener User
     * @param {FormBuilder} fb FormBuilder-Injektor
     */
    public constructor(
        private dialogRef: MatDialogRef<EditUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public user: IUser | undefined,
        private fb: FormBuilder,
    )
    {
        this.form = this.fb.group({
            confirmed: this.fb.control(user?.confirmed ?? false, { nonNullable: true}),
            salesPartnerCenter: this.fb.control(user?.salesPartnerCenterId ?? [], { nonNullable: true, validators: Validators.required}),
            userPurpose: this.fb.control(HelperService.getBitNumbers((user?.roles ?? 0) & (Role.Expert | Role.Referent)), { nonNullable: true, validators: [Validators.required, Validators.minLength(1)]}),
            language: this.fb.control(HelperService.getBitNumbers(user?.languages ?? 0), {nonNullable: true}),
            financingPermissions: this.fb.control(HelperService.getBitNumbers(user?.financingPermissions ?? 0), { nonNullable: true}),
            phoneNumber: this.fb.control(user?.phoneNumber ?? null, { validators: [Validators.minLength(5), Validators.maxLength(30), Validators.pattern('[+ 0-9]+')]}),
        });
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.salesPartnerCenters$?.pipe(take(1)).subscribe(centers => {
            this.allSalesPartnerCenters = centers;

            if (!!this.user?.financingPermissions) {
                const enumArray = HelperService.getBitNumbers(this.user.financingPermissions) as number[]
                this.filterSalesPartnerCenters(enumArray);
            }
        })

        this.languageItems = sort((HelperService.getEnumArray(Language) as number[]).map(language => ({
            value: language,
            displayName: Language.translate(language as Language) ?? '',
        })).filter(x => x.value !== 0)).asc(language => language.displayName);

        this.financingPremisons = sort((HelperService.getEnumArray(FinancingPremissions) as number[]).map(premission => ({
            value: premission,
            displayName: FinancingPremissions.translate(premission as FinancingPremissions) ?? '',
        })).filter(x => x.value !== 0)).asc(premission => premission.displayName);
    }

    /**
     * Schließt den Dialog ohne Aktion
     */
    public cancel(): void {
        this.dialogRef.close();
    }

    /**
     * Schließt dem Dialog mit Speichern
     */
    public save(): void {
        if (!!this.form && this.form.valid && !!this.user?.id) {
            let phoneNumber: string | undefined | null= this.form.controls.phoneNumber.value ?? undefined;
            if (phoneNumber === '') {
                phoneNumber = null;
            }
            const updateUser: IUpdateUserRequest = {
                id: this.user.id,
                salesPartnerCenterId: this.form.controls.salesPartnerCenter.value,
                phoneNumber,
                languages: HelperService.convertArrayToFlag(this.form.controls.language.value) ?? 0,
                confirmed: this.form.controls.confirmed.value,
                financingPermissions: HelperService.convertArrayToFlag(this.form.controls.financingPermissions.value) ?? 0,
                userPurpose: HelperService.convertArrayToFlag(this.form.controls.userPurpose.value) ?? Role.None,
            }
            this.userDataChanged.emit(updateUser);
        }
    }

    /**
     * filtriert SalesPartner center für Dialog
     *
     * @param {number[]} premissions premissions
     */
    public filterSalesPartnerCenters(premissions: number[]): void {

        if (this.allSalesPartnerCenters === undefined) {
            return;
        }

        const responsibilityTypes = this.permissionsToResponsibilityTypes(premissions);

        this.filteredSalesPartnerCenters = this.allSalesPartnerCenters.filter(center => responsibilityTypes.includes(center.responsibility));
    }

    /**
     * deselektiert values in salesPartnerCenter dropdown wenn sich Berechtigungen ändert
     *
     * @param {number []} premissions User Premissions
     */
    public setValuesInControl(premissions: number []): void {
        this.filterSalesPartnerCenters(premissions);

        const selectedSalesPartnerCenterIDs: string[] = this.form.controls.salesPartnerCenter.value;

        if (!!selectedSalesPartnerCenterIDs) {

            const responsibilityTypes = this.permissionsToResponsibilityTypes(premissions);

            this.form.controls.salesPartnerCenter.setValue(selectedSalesPartnerCenterIDs.filter(id => {
                const salesPartnerCenter = this.allSalesPartnerCenters?.find(it => it.id === id);

                return !!salesPartnerCenter && responsibilityTypes.includes(salesPartnerCenter.responsibility);
            }));
        }
    }

    /**
     * Mappt eine Liste von FinancingPermissions auf die dazugehörigen ResponsibilityTypes
     *
     * @param {FinancingPremissions[]} permissions Liste von FinancingPermissions
     * @returns {OrganisationalUnitResponsibilityType[]} Liste von ResponsibilityTypes
     */
    private permissionsToResponsibilityTypes(permissions: FinancingPremissions[]): OrganisationalUnitResponsibilityType[] {
        const responsibilityTypes: OrganisationalUnitResponsibilityType[] = [];

        for (const permission of permissions) {
            switch (permission) {
                case FinancingPremissions.PrivateCustomerSkill:
                    responsibilityTypes.push(OrganisationalUnitResponsibilityType.PrivateCustomers);
                    break;
                case FinancingPremissions.SmallBusinessSkill:
                    responsibilityTypes.push(OrganisationalUnitResponsibilityType.SelfEmployed);
                    break;
                default:
                    break;
            }
        }

        return responsibilityTypes;
    }
}
