export enum FinancingEventType {

    /**
     * Neue Nachricht erhalten
     */
    MessageReceived = 0,

    /**
     * Alternatives Rechenbeispiel angefordert
     */
    SampleCalculationRequested = 1,

    /**
     * Alternatives ESIS angefordert
     */
    EsisRequested = 2,

    /**
     * Änderungsanfrage eingereicht
     */
    ChangeRequestVpcSended = 3,

    /**
     * Neue Notiz
     */
    NewNotice = 4,

    /**
     * ESIS abgelehnt
     */
    EsisRejected = 5,

    /**
     * Dokumente nachgereicht
     */
    DocumentsReceived = 6
}
