/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';

import { FinprocessTypedForm, FormProviderToken, IProviderWithMulti, IValidationOptions, VisibilityMap } from '../interfaces';
import { isObjectEmpty } from '../util/helper';

import { FinprocessFormControl } from './finprocess-form-control';
import { FinprocessFormArray } from './finprocess-form.array';
import { parseValidationOptions } from './form-builder';
import { FormInjector } from './form-injector';

const MAX_ITERATIONS = 25;

type FormControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

/**
 * Finprocess Form Group, die eine normale FormGroup um eine Funktion
 * zum Aktualisieren von Validatoren erweitert
 */
export class FinprocessFormGroup<T> extends FormGroup<FinprocessTypedForm<T>> {

    public injector: FormInjector = new FormInjector();

    public provides?: FormProviderToken<T>;

    public visibilityMap$ = new ReplaySubject<VisibilityMap<T>>(1);

    public visibilityMap?: VisibilityMap<T>;

    /**
     * Subscription für die Root Form Group. Wird von initRootFormGroup gesetzt und wieder unsubscribed
     */
    public subscription?: Subscription;

    /**
     * Funktion für die Validierung der Form Control
     */
    public validatorFunction?: (...args: unknown[]) => ValidatorFn | null;

    /**
     * Fixer Validator, der sich nie ändert
     */
    public fixedValidator?: ValidatorFn;

    /**
     * Zugriffsproperties für Unterobjekte
     */
    public validatorProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion für die Sichtbarkeit der Form Control
     */
    public visibilityFunction?: (...args: unknown[]) => boolean;

    /**
     * Zugriffsproperties für Unterobjekte für die Sichtbarkeit
     */
    public visibilityProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * Funktion zum Berechnen des Default Werts
     */
    public defaultFunction?: (...args: unknown[]) => T[];

    /**
     * Zugriffsproperties für Unterobjekte für den Default Wert
     */
    public defaultProviders: IProviderWithMulti<unknown>[] = [];

    /**
     * entfernt beim Speichern das ganze Objekt (setzt es auf null) wenn jede 
     * Property null oder ein leeres Array ist
     */
    private removeIfEmpty: boolean;

    /**
     * Initialisiert eine FinprocessFormGroup
     * 
     * @param {any} value Initialer Wert
     * @param {IValidationOptions} options Validierungsoptionen
     */
    public constructor(value: FinprocessTypedForm<T>, options?: IValidationOptions<any, any, any, any>) {
        super(value);

        parseValidationOptions(this, options);
        this.removeIfEmpty = !!options?.removeIfEmpty;
    }

    /**
     * Initialisiert die Validatoren und Default Werte aller Kinder
     */
    public init(): void {
        this.initProviders();
        this.initValidation();
        this.updateValueAndValidity();
    }

    /**
     * Initialisiert Validatoren und Sichtbarkeit der FormGroup und aller Kinder
     */
    public initValidation(): void {
        for (const key of Object.keys(this.controls)) {
            const control = this.controls[key as keyof typeof this.controls];
            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray || control instanceof FinprocessFormControl) {
                control.initValidation();
            }
        }

        this.updateVisibility();
    }

    /**
     * Intitialisiert alle Provider. Sollte vor den Validatoren ausgeführt werden um keine Exceptions für fehlende Provider zu bekommen
     */
    public initProviders(): void {
        if (!!this.provides) {
            this.setProvider(this.provides);
        }

        for (const key of Object.keys(this.controls)) {
            const control = this.controls[key as keyof typeof this.controls];
            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray) {
                control.initProviders();
            }
        }

    }

    /**
     * Gibt einen Provider zurück
     * 
     * @param {FormProviderToken} token Provider
     * @param {boolean} multi Mehrfachprovider? 
     * @returns {any} Provider Wert
     */
    public getProvider<R>(token: FormProviderToken<R>, multi: boolean): R {
        const value = this.injector.getProvider<R>({ description: token, multi });

        if (!!value) {
            return value;
        }

        if (this.parent instanceof FinprocessFormArray || this.parent instanceof FinprocessFormGroup) {
            return this.parent.getProvider<R>(token, multi);
        }

        throw new Error(`No provider for ${token} found`);
    }

    /**
     * Setzt einen Provider
     * 
     * @param {FormProviderToken} token Provider token 
     */
    public setProvider<R>(token: FormProviderToken<R>): void {
        this.injector.setProvider(this, false, token);

        let parent = this.parent;
        let hitFormArray = false;

        for (let i = 0; i < MAX_ITERATIONS; i++) {
            if (parent instanceof FinprocessFormGroup) {
                parent.injector.setProvider(this, hitFormArray, token);
            } else if (parent instanceof FinprocessFormArray) {
                parent.injector.setProvider(this, true, token);
                hitFormArray = true;
            } else {
                break;
            }

            parent = parent.parent;
        }
    }

    /**
     * Entfernt Provider
     */
    public removeProvider(): void {
        this.injector.removeProvider(this);

        let parent = this.parent;
        for (let i = 0; i < MAX_ITERATIONS; i++) {
            if (parent instanceof FinprocessFormGroup || parent instanceof FinprocessFormArray) {
                parent.injector.removeProvider(this);
            } else {
                break;
            }

            parent = parent.parent;
        }
    }

    /**
     * Aktualisiert die Validatoren aller Controls innerhalb der Form Group
     *
     * Unterobjekte nicht validiert werden.
     */
    public updateValidators(): void {
        const keys = Object.keys(this.controls) as Array<keyof typeof this.controls>;

        for (const key of keys) {
            const control = this.controls[key];

            if (control instanceof FinprocessFormControl || control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray) {
                control.updateValidators();
            }
        }

        this.setValidators(this.getValidator());

        this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        this.updateStatus();
    }

    /**
     * Gibt eine Visibility Map der Form Group zurück
     *
     * @returns {boolean} Sichtbarkeit der FormGroup
     */
    // eslint-disable-next-line complexity
    public updateVisibility(): boolean {
        const keys = Object.keys(this.controls) as Array<keyof typeof this.controls>;
        const visibilityMap: VisibilityMap<T> = {} as VisibilityMap<T>;

        for (const key of keys) {
            const control = this.controls[key];
            const currentVisibility = !!this.visibilityMap ? this.visibilityMap[key as keyof typeof this.visibilityMap] : false;

            if ((control instanceof FinprocessFormControl) && !!control.visibilityFunction) {
                const newVisibility = control.updateVisibility();
                visibilityMap[key as keyof typeof visibilityMap] = newVisibility;
                if (newVisibility === false && currentVisibility === true) {
                    control.reset(null, { onlySelf: true, emitEvent: false });
                }

            } else if (control instanceof FinprocessFormGroup) {
                const newVisibility = control.updateVisibility();
                visibilityMap[key as keyof typeof visibilityMap] = newVisibility;
                if (newVisibility === false && currentVisibility === true) {
                    control.reset(undefined, { onlySelf: true, emitEvent: false });
                    const subKeys = Object.keys(control.controls) as Array<keyof typeof control.controls>

                    for (const subKey of subKeys) {
                        if (typeof subKey === 'string' || typeof subKey === 'number') {
                            const subControl: any = control.controls[subKey];
                            if (subControl instanceof FinprocessFormArray) {
                                subControl.clear({ emitEvent: false });
                            }
                        }
                    }
                }
            }
            else if (control instanceof FinprocessFormArray) {
                for (const subControl of control.controls) {
                    if (subControl instanceof FinprocessFormGroup) {
                        subControl.updateVisibility();
                    }
                }

                if (!!control.visibilityFunction) {
                    const newVisibility = control.updateVisibility();
                    visibilityMap[key as keyof typeof visibilityMap] = newVisibility;
                    if (newVisibility === false && currentVisibility === true) {
                        control.clear({ emitEvent: false });
                    }
                } else {
                    visibilityMap[key as keyof typeof visibilityMap] = true;
                }
            }
            else {
                visibilityMap[key as keyof typeof visibilityMap] = true;
            }
        }

        this.visibilityMap$.next(visibilityMap);
        this.visibilityMap = visibilityMap;

        if (!!this.visibilityFunction) {
            let parameters: unknown[] = [];

            if (this.visibilityProviders) {
                parameters = this.visibilityProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.visibilityFunction(...parameters);
        }

        return true;
    }

    /**
     * Beendet die Subscription für Root Form Groups
     */
    public dispose(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Bestimmt den Validator anhand eines fixen Validators oder einer Funktion
     * 
     * @returns {ValidatorFn | null} Validator Funktion
     */
    private getValidator(): ValidatorFn | null {
        if (!!this.fixedValidator) {
            return this.fixedValidator;
        }

        if (!!this.validatorFunction) {
            let parameters: unknown[] = [];

            if (this.validatorProviders) {
                parameters = this.validatorProviders.map(provider => {
                    if (this.parent instanceof FinprocessFormGroup || this.parent instanceof FinprocessFormArray) {
                        return this.parent.getProvider(provider.token, provider.multi ?? false);
                    }

                    throw new Error('FinprocessFormControl must be within a FinprocessFormGroup or FinprocessFormArray to be used with providers');
                });
            }

            return this.validatorFunction(...parameters);
        }

        return null;
    }


    /**
     * Sammelt alle Fehlermeldungen einer FormGroup
     *
     * @returns {ValidationErrors | null} Objekt mit mehreren Validierungsfehlern
     */
    public collectErrors(): ValidationErrors | null {
        const keys = Object.keys(this.controls) as Array<keyof typeof this.controls>;
        const errorMap: ValidationErrors = {};
        let noErrors = true;

        for (const key of keys) {
            const control = this.controls[key];

            if (control instanceof FinprocessFormGroup || control instanceof FinprocessFormArray) {
                const childErrors = control.collectErrors();
                errorMap[key as string] = childErrors;

                if (childErrors !== null) {
                    noErrors = false;
                }

            } else {
                errorMap[key as string] = control.errors;

                if (control.errors !== null) {
                    noErrors = false;
                }
            }
        }

        if (this.errors !== null) {
            errorMap['_self'] = this.errors;
            noErrors = false;
        }

        return noErrors ? null : errorMap;
    }

    /**
     * Updates the status of a form and emits a statusChange event
     */
    public updateStatus(): void {
        (this.statusChanges as EventEmitter<FormControlStatus>).emit(this.status as FormControlStatus);
    }

    /**
     * Wie getRawValue aber mit korrekter Typisierung
     * 
     * @returns {any} Wert der FormGroup
     */
    public getRawFinprocessValue(): T {
        return super.getRawValue() as T;
    }

    /** 
     * Gibt ein Objekt zurück bei dem nur die sichtbaren Properties gesetzt sind.
     * Nicht sichtbare Properties werden mit null belegt.
     * Nicht sichtbare Objekte werden mit null belegt.
     * Nicht sichtbare Arrays werden mit einem leeren Array belegt.
     * 
     * @returns {any} Objekt mit ausschließlich sichtbaren Properties
     */
    public getVisibleRawValue(): T {
        const keys = Object.keys(this.controls) as Array<keyof typeof this.controls>;
        const result: Partial<T> = {};

        for (const key of keys) {
            const isVisible = !!this.visibilityMap && this.visibilityMap[key as keyof T];
            const control = this.controls[key];

            if (control instanceof FinprocessFormControl) {
                result[key as keyof T] = isVisible ? control.value : null;
            }
            else if (control instanceof FinprocessFormGroup) {
                result[key as keyof T] = isVisible && !(control.removeIfEmpty && isObjectEmpty(control.value)) ? control.getVisibleRawValue() : null;
            }
            else if (control instanceof FinprocessFormArray) {
                if (isVisible) {
                    const formArrayResult: Array<unknown> = [];

                    if (control.controls.length > 0) {
                        for (const subControl of control.controls) {
                            if (subControl instanceof FinprocessFormGroup) {
                                formArrayResult.push(subControl.getVisibleRawValue());
                            }
                            else {
                                formArrayResult.push(subControl.value);
                            }
                        }
                    }

                    result[key as keyof T] = formArrayResult as T[keyof T];
                }
                else {
                    result[key as keyof T] = null as T[keyof T];
                }
            }
        }

        return result as T;
    }
}
