import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetService, EntityClassType, FinancingService, ICollateralAccountDetail, IProductPackageData } from 'app/modules/financing/data';
import { IAssetAccount } from 'app/modules/financing/data/interfaces/asset-account';
import { AssignmentType } from 'app/modules/financing/features/colt/enums';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, of, take } from 'rxjs';

import { CollateralType, ProductPackageType } from '../../../../../../../../../../../libs/enums/src/core/enums';
import { EnumTranslationPipe } from '../../../../../../../../../../../libs/enums/src/lib/pipes/enum-translation/enum-translation.pipe';
import { AssetCommonComponent } from '../../asset-common/asset-common';
import { ICollateralTableInterface } from '../collateral-real-estate-details/collateral-real-estate-details.component';

/**
 * Asset Wertpapierpaket Details
 */
@Component({
    selector: 'finprocess-collateral-account-details',
    templateUrl: './collateral-account-details.component.html',
    styleUrls: ['./collateral-account-details.component.scss'],
})
export class CollateralAccountDetailsComponent extends AssetCommonComponent implements OnInit, OnChanges {

    /**
     * Collateral Account Data
     */
    @Input()
    public collateralAccountDetail?: ICollateralAccountDetail[] | undefined | null;

    /**
     * Asset Account Data
     */
    @Input()
    public assetAccount?: IAssetAccount[] | undefined;

    /**
     * Genehmigungsseite
     */
    @Input()
    public approvalView = false;

    /**
     * ProductPackage
     */
    @Input()
    public productPackage?: IProductPackageData;

    /**
     * filtered collateral account data
     */
    public collateralAccounts?: ICollateralAccountDetail[] | undefined;
    public collateralSecurityPaper?: ICollateralAccountDetail[] | undefined;

    /**
     * filtered asset account data
     */
    public assetAccounts?: ICollateralAccountDetail[] | undefined;
    public assetSecurityPaper?: ICollateralAccountDetail[] | undefined;

    public entityClassType = EntityClassType;
    public inputFlexDirection = 'column';

    /**
     * Mat expansion Expand
     */
    public collateralAccountDetailExpand: Record<string, boolean> = {};

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * Observable Schreibschutz
     */
    public editingReadonlyApprovalView$!: Observable<boolean>;

    public displayedColumns: string[] = ['position', 'name', 'duration', 'amount', 'collateralized'];
    public dataSources: Map<ICollateralAccountDetail, ICollateralTableInterface[]> = new Map();

    /**
     * Dropdown ConfirmationType
     */
    public booleanItems?: ISelectItem<boolean>[] = [];

    /**
     * Dropdown AssignmentTyoe
     */
    public assignmentTypeControl?: ISelectItem<number>[] = [];

    /**
     * selected Insurance
     */
    public selectedSecurityPapers: IAssetAccount[] = [];
    /**
     * selected accounts
     */
    public selectedAccounts: IAssetAccount[] = [];

    /**
     * FinancingMapID
     */
    public financingMapID?: string;

    /**
     * ProductPackageID
     */
    public productPackageID?: string;
    
    /**
     * Konstruktor
     * 
     * @param {FinancingService} financingService financingservice
     * @param {TranslateService} translate TranslateService
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {AssetService} assetService assetservice
     * @param {WaiterService} waiterService waiterservice
     * @param {NotificationService} notificationService notificationservice
     * @param {ChangeDetectorRef} changeDetection change detection
     * @param {Store} store store
     */
    public constructor(financingService: FinancingService, private translate: TranslateService,
        enumTranslate: EnumTranslationPipe, assetService: AssetService, private waiterService: WaiterService,
        private notificationService: NotificationService, private changeDetection: ChangeDetectorRef, store: Store) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
    }

    /**
     * ngOnChanges
     *
     * @param {SimpleChanges} changes changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.collateralAccountDetail && changes.collateralAccountDetail.previousValue !== changes.collateralAccountDetail.currentValue) {
            this.collateralAccounts = this.collateralAccountDetail?.filter(accounts => accounts.collateralType === CollateralType.BA415);
            this.collateralSecurityPaper = this.collateralAccountDetail?.filter(accounts => accounts.collateralType === CollateralType.BA425);
        }
        if (changes.assetAccount && changes.assetAccount.previousValue !== changes.assetAccount.currentValue) {
            this.assetAccounts = this.assetAccount?.filter(accounts => accounts.isBonds === false);
            this.assetSecurityPaper = this.assetAccount?.filter(accounts => accounts.isBonds === true);
        }
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.editingReadonly$ = this.approvalView ? of(true) : this.financingService.editingReadonlyWithEditmodeExpert$;
        this.editingReadonlyApprovalView$ = this.financingService.editingReadonlyWithEditmodeExpert$;
        
        this.collateralAccounts = this.collateralAccountDetail?.filter(accounts => accounts.collateralType === CollateralType.BA415);
        this.collateralSecurityPaper = this.collateralAccountDetail?.filter(accounts => accounts.collateralType === CollateralType.BA425);

        this.assetAccounts = this.assetAccount?.filter(accounts => accounts.isBonds === false);
        this.assetSecurityPaper = this.assetAccount?.filter(accounts => accounts.isBonds === true);

        this.assignmentTypeControl = HelperService.getSortedSelectItems(AssignmentType, value => this.enumTranslate.transform(value, 'AssignmentType') as string, [], true);

        this.booleanItems = [true, false].map(value => ({
            value: value,
            displayName: value ? 'Ja' : 'Nein',
        }));

        this.defineDataSource();
    }

    /**
     * define Data Source
     */
    public defineDataSource() {
        // Create a map to hold the data source for each real estate
        const dataSources: Map<ICollateralAccountDetail, ICollateralTableInterface[]> = new Map();

        if (!!this.productPackage) {
            this.financingMapID = this.productPackage.financingMapID;
            const products = this.productPackage?.assignProductPackages[0].assignProducts;

            this.productPackageID = this.productPackage.assignProductPackages[0].id;

            // Iterate over each real estate
            this.collateralAccountDetail?.forEach(detail => {
                const newData: ICollateralTableInterface[] = [];

                //Iterate over products
                products?.forEach(element => {
                    let isCollateralized = false;

                    // Find all productAssignmentInfos with the same productID
                    const productAssignmentInfos = detail.productAssignmentInfos?.find(info => info.productId === element.productID);

                    // If any productAssignmentInfo is found and isUsedAsCollateralForProduct is true, set isCollateralized to true
                    if (productAssignmentInfos && productAssignmentInfos.isUsedAsCollateralForProduct === true) {
                        isCollateralized = true;
                    }
                    else {
                        isCollateralized = false;
                    }

                    const data: ICollateralTableInterface = {
                        position: element.position,
                        name: element.productCreditType,
                        duration: element.productCreditType !== ProductPackageType.Guarantee ? element.durationWithoutASD : '-',
                        amount: element.productCreditType !== ProductPackageType.Guarantee ? element.creditAmount : element.guaranteeAmount,
                        collateralized: isCollateralized,
                        id: element.productID,
                    };

                    newData.push(data);
                });

                dataSources.set(detail, newData);
                this.dataSources = dataSources;
            });
        }
    }

    /**
     * assign insurance
     * 
     * @param {string} assignedAccountID assignedAccountID 
     * @param {string} collateralID collateralid
     * @param {boolean} isCheckboxDelete true/false
     * @param {MatOptionSelectionChange} event event
     */
    public assignAccounts(assignedAccountID: string, collateralID: string, isCheckboxDelete: boolean, event?: MatOptionSelectionChange) {
        if (!!event && !event.isUserInput) {
            return;
        }

        //wird es über die checkbox/dropdown gelöscht oder über button
        if (isCheckboxDelete === true) {
            if (!!this.productPackageID && !!assignedAccountID) {
                this.assignCollateralRequest(collateralID, assignedAccountID, this.productPackageID);
            }
        }
        else {
            this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteCollateralDetail'), this.translate.instant('asset.assetDetails.deleteCollateralDetailQuestion')).subscribe(result => {
                if (result === 'submit' && !!this.productPackageID && !!assignedAccountID) {
                    this.assignCollateralRequest(collateralID, assignedAccountID, this.productPackageID);
                }
            });
        }
    }

    /**
     * assign account request
     * 
     * @param {string} collateralID collateralID
     * @param {string} accountsID accountsID
     * @param {string} productpackageID productpackageID
     */
    public assignCollateralRequest(collateralID: string, accountsID: string, productpackageID: string) {
        this.waiterService.show();
        this.assetService.assignCollateral(collateralID, accountsID, productpackageID).pipe(take(1)).subscribe({
            next: data => {
                if (!!data && !!data.accounts) {

                    this.collateralAccounts = data.accounts.filter(accounts => accounts.collateralType === CollateralType.BA415);
                    this.collateralSecurityPaper = data.accounts.filter(accounts => accounts.collateralType === CollateralType.BA425);
                    this.changeDetection.detectChanges();
                }
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.assignCollateralError'));
            },
        });
    }

    /**
     * checkbox is selected for assigned account
     * 
     * @param {IAssetAccount} account account 
     * @param {string} collateralID collateralid
     * @param {string} accountType securityPaper || account
     * @returns {boolean} true/false
     */
    public isAccountSelected(account: IAssetAccount, collateralID: string, accountType: 'securityPaper' | 'account'): boolean {
        const data = accountType === 'securityPaper' ? this.collateralSecurityPaper : this.collateralAccounts;

        const accountDetail = data?.find(detail => detail.id === collateralID);
        if (!!accountDetail && !!accountDetail.assetAssignmentInfos) {
            const assetAssignmentInfo = accountDetail.assetAssignmentInfos.find(info => info.assetId === account.id);
            return assetAssignmentInfo ? true : false;
        }
        return false;
    }


    /**
     * delete Collateral
     * 
     * @param {string} collateralID collateral id
     */
    public deleteCollateral(collateralID: string) {
        this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteCollateralDetail'), this.translate.instant('asset.assetDetails.deleteCollateralDetailQuestion')).subscribe(result => {
            if (result === 'submit' && !!this.productPackage) {
                this.waiterService.show();
                this.assetService.deleteCollateral(this.productPackage.assignProductPackages[0].productPackageID, collateralID).pipe(take(1)).subscribe({
                    next: data => {
                        if (!!data && !!data.accounts) {
                            this.collateralAccounts = data.accounts.filter(accounts => accounts.collateralType === CollateralType.BA415);
                            this.collateralSecurityPaper = data.accounts.filter(accounts => accounts.collateralType === CollateralType.BA425);

                            this.changeDetection.detectChanges();
                        }
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.realEstateDetails.deleteError'));
                    },
                });
            }
        });
    }

    /**
     * secure Product to Collateral
     *
     * @param {string} collateralID collateralid
     * @param {string} productID productID
     * @param {boolean} isSecured true/false
     */
    public productSecured(collateralID: string, productID: string, isSecured: boolean) {
        if (!!this.productPackage?.assignProductPackages[0].productPackageID) {
            this.assetService.secureProductToCollateral(collateralID, productID, this.productPackage?.assignProductPackages[0].productPackageID, isSecured).pipe(take(1)).subscribe({
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.secureProductCollateralError'));
                },
            });
        }
    }

    /**
     * check assigned accounts data to avoid empty body
     *
     * @param {IAssetAccount} assetAccount assetInsurance
     * @returns {boolean} true/false
     */
    public hasAssignedBodyData(assetAccount: IAssetAccount): boolean {
        return !!(
            assetAccount.loanRate ||
            assetAccount.materialValueAlreadyInUse ||
            assetAccount.freeMaterialValue
        );
    }
}
