import { EnumSettings } from './../enum.settings';

/**
 * Wohnart
 */
export enum HousingType {
    /**
     * Hauptmiete
     */
    Rent = 0,

    /**
     * Genossenschaft
     */
    Association = 1,

    /**
     * Eigentum
     */
    Property = 2,

    /**
     * Gemeindewohnung
     */
    CouncilFlat = 3,

    /**
     * Dienstwohnung
     */
    CompanyFlat = 4,

    /**
     * Mietwohnung/Untermiete/Sonstiges
     */
    Other = 5,
}

export namespace HousingType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {HousingType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: HousingType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'HousingType', HousingType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('HousingType', plocale);
}
