import { Validators } from '@angular/forms';

import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const ASSIGN_ADVISOR: IMidtermModel = {

    /**
     * Name für Übersetzungen
     */
    midtermName: 'assignAdvisorRequestData',

    /**
     * Betreuer Code manager of nucleo
     * Advisor Code - respective Relationship Manager (Name autom.)
     */
    managerCode: { type: WorkflowType.String, validators: [Validators.maxLength(5)] },

    /**
     * Filial-Nr. (OE-Nr.)
     */
    branch: { type: WorkflowType.String, validators: [Validators.maxLength(5)] },

    /**
     * List of NDG
     */
    ndgList: { type: WorkflowType.Array, child: WorkflowType.String },

    /**
     * Namenszeile
     */
    householdHeading: { type: WorkflowType.String, validators: [Validators.maxLength(64)] },

    /**
     * Manuelles Segment
     * Code 1st level segmentation Individual business (manual customer segmentation)
     */
    householdSegment: { type: WorkflowType.String, validators: [Validators.maxLength(10)] },

    /**
     * Manuelles Sub-Segment
     * Code 2nd level segmentation
     */
    householdSubSegment: { type: WorkflowType.String, validators: [Validators.maxLength(10)] },

    /**
     * Aktualisierung Online
     * Flag OnLine Update Advisor
     */
    onlineUpdateAdvisor: { type: WorkflowType.String, validators: [Validators.minLength(1), Validators.maxLength(1)]},


}
