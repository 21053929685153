import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SmartDocService } from './services/smartdoc.service';
import { SmartDocState } from './states/smartdoc/smartdoc.state';

export * from './enums';
export * from './interfaces';
export * from './states';
export * from './services/smartdoc.service';

/**
 * Modul für SmartDoc
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([SmartDocState]),
    ],
    providers: [
        SmartDocService,
    ],
})
export class SmartdocDataModule { }
