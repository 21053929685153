/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { CollateralType } from '@ntag-ef/finprocess-enums';
import { Logout } from 'app/modules/auth/data';

import { EntityClassType, ValueStorageType } from '../../enums';
import { IAddress, IAssetAccount, IAssetInsurance, IAssetProvider, IAssetRealEstate, ICollateralAccountDetail, ICollateralInsuranceDetail, ICollateralRealEstateDetail, ISaveInternalFieldRequest } from '../../interfaces';
import { InternalFieldUpdated } from '../financing/financing.actions';
import { FinancingLeaved } from '../financing-tab/financing-tab.actions';

import { AssetsActive, AssetsAddProvider, AssetsCreate, AssetsDeleteProvider, AssetsLoaded, ClearCollateral, CollateralCreate, CollateralLoaded } from './asset.actions';


export interface IAssetStateModel {
    assetRealEstates: IAssetRealEstate[],
    assetInsurances: IAssetInsurance[],
    assetAccounts: IAssetAccount[],
    collateralRealEstates: ICollateralRealEstateDetail[],
    collateralInsurances: ICollateralInsuranceDetail[],
    collateralAccounts: ICollateralAccountDetail[],
}

const defaultData: IAssetStateModel = {
    assetRealEstates: [],
    assetInsurances: [],
    assetAccounts: [],
    collateralRealEstates: [],
    collateralInsurances: [],
    collateralAccounts: [],
};

const defaultDataCollaterals: Partial<IAssetStateModel> = {
    collateralRealEstates: [],
    collateralInsurances: [],
    collateralAccounts: [],
};

/**
 * Zustand der Assets
 */
@State<IAssetStateModel>({
    name: AssetState.keyName,
    defaults: defaultData,
})
@Injectable()
export class AssetState {

    public static readonly keyName = 'asset';

    /**
     * Selektor für die Immobilien
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {IAssetRealEstate[]} Immobilien
     */
    @Selector()
    public static assetRealEstates(state: IAssetStateModel): IAssetRealEstate[] {
        return state.assetRealEstates;
    }

    /**
     * Selektor für die Collateral Immobilien
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {ICollateralRealEstateDetail[]} Immobilien
     */
    @Selector()
    public static collateralRealEstates(state: IAssetStateModel): ICollateralRealEstateDetail[] {
        return state.collateralRealEstates;
    }

    /**
     * Selektor für die Versicherungen
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {IAssetInsurance[]} Versicherungen
     */
    @Selector()
    public static assetInsurances(state: IAssetStateModel): IAssetInsurance[] {
        return state.assetInsurances;
    }

    /**
     * Selektor für die Collateral Versicherungen
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {ICollateralInsuranceDetail[]} Versicherungen
     */
    @Selector()
    public static collateralInsurances(state: IAssetStateModel): ICollateralInsuranceDetail[] {
        return state.collateralInsurances;
    }

    /**
     * Selektor für die Konten
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {IAssetAccount[]} Konten
     */
    @Selector()
    public static assetAccounts(state: IAssetStateModel): IAssetAccount[] {
        return state.assetAccounts;
    }

    /**
     * Selektor für die Collateral Konten
     * 
     * @param {IAssetStateModel} state Zustand
     * @returns {ICollateralAccountDetail[]} Konten
     */
    @Selector()
    public static collateralAccounts(state: IAssetStateModel): ICollateralAccountDetail[] {
        return state.collateralAccounts;
    }

    /**
     * Validierung der Collateral Versicherungen
     * 
     * @param {IAssetStateModel} state State
     * @returns {{ id: string, valid: boolean }[]} Validierung
     */
    @Selector()
    public static validateCollateralInsurance(state: IAssetStateModel): { id: string, valid: boolean }[] {
        return state.collateralInsurances.map(insurance => {
            let valid = true;

            if (insurance.legalStatus === undefined) {
                valid = false;
            }
            if ((insurance.collateralType !== CollateralType.BA130 && insurance.collateralType !== CollateralType.BA135) && insurance.isCrrCapable === undefined) {
                valid = false;
            }

            return { id: insurance.id, valid };
        });
    }


    /**
     * Validierung der Collateral Real Estates
     * 
     * @param {IAssetStateModel} state State
     * @returns {{ id: string, valid: boolean }[]} Validierung
     */
    @Selector()
    public static validateCollateralRealEstates(state: IAssetStateModel): { id: string, valid: boolean }[] {
        return state.collateralRealEstates.map(realEstates => {
            let valid = true;

            if (realEstates.ltvRelevant === undefined) {
                valid = false;
            }
            if (realEstates.pbuAmount === undefined) {
                valid = false;
            }
            if (realEstates.collateralType === CollateralType.BA205 && realEstates.isPartialEntry === undefined) {
                valid = false;
            }
            if (realEstates.isPartialEntry === true && realEstates.gbaAmount === undefined) {
                valid = false;
            }

            //bei assigned elements
            if (!!realEstates.assetAssignmentInfos && realEstates.assetAssignmentInfos?.length > 0) {
                realEstates.assetAssignmentInfos.forEach(assignedElements => {

                    if (assignedElements.foreignPreloads === undefined) {
                        valid = false;
                    }
                    if (assignedElements.belqRelevant === undefined) {
                        valid = false;
                    }
                    if (assignedElements.preloadsKimV === undefined) {
                        valid = false;
                    }
                    if (assignedElements.ownPreloads === undefined) {
                        valid = false;
                    }
                    if (assignedElements.totalBaMortgages === undefined) {
                        valid = false;
                    }
                });
            }

            return { id: realEstates.id, valid };
        });
    }

    /**
     * Validierung der Collateral Real Estates Genehmigung
     * 
     * @param {IAssetStateModel} state State
     * @returns {{ id: string, valid: boolean }[]} Validierung
     */
    @Selector()
    public static validateCollateralRealEstatesForApproval(state: IAssetStateModel): { id: string, valid: boolean }[] {
        return state.collateralRealEstates.map(realEstates => {
            let valid = true;

            if (realEstates.waiverOfUnnecessaryTrustee === undefined) {
                valid = false;
            }
            if (realEstates.fiduciaryProcessing === undefined) {
                valid = false;
            }
            if (realEstates.priorityNewMortgage === undefined) {
                valid = false;
            }

            return { id: realEstates.id, valid };
        });
    }

    /**
     * Validierung der Collateral Accounts
     * 
     * @param {IAssetStateModel} state State
     * @returns {{ id: string, valid: boolean }[]} Validierung
     */
    @Selector()
    public static validateCollateralAccounts(state: IAssetStateModel): { id: string, valid: boolean }[] {
        return state.collateralAccounts.map(accounts => {
            let valid = true;

            if (accounts.accountNumber === undefined) {
                valid = false;
            }
            if (accounts.isCrrCapable === undefined) {
                valid = false;
            }

            return { id: accounts.id, valid };
        });
    }

    /**
     * Zustandsänderung beim Laden der Assets
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssetsLoaded} payload Assets Loaded Action 
     */
    @Action(AssetsCreate)
    @Action(AssetsLoaded)
    public assetsLoaded({ patchState }: StateContext<IAssetStateModel>, { payload }: AssetsLoaded): void {
        patchState({
            assetRealEstates: payload.mortages ?? [],
            assetInsurances: payload.insurances ?? [],
            assetAccounts: payload.accounts ?? [],
        });
    }

    /**
     * Zustandsänderung beim Ändern des Aktiv Zustands eines Assets
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssetsActive} payload Assets Active Action
     */
    @Action(AssetsActive)
    public setAssetActive({ setState }: StateContext<IAssetStateModel>, { payload }: AssetsActive): void {
        setState(patch({
            assetRealEstates: updateItem(item => item.id === payload.assetId, patch<IAssetRealEstate>({ isActive: payload.isActive })),
            assetInsurances: updateItem(item => item.id === payload.assetId, patch<IAssetInsurance>({ isActive: payload.isActive })),
            assetAccounts: updateItem(item => item.id === payload.assetId, patch<IAssetAccount>({ isActive: payload.isActive })),
        }));
    }

    /**
     * Zustandsänderung beim hinzufügen einen Asset-Provider
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssetsLoaded} payload Assets Loaded Action 
     */
    @Action(AssetsAddProvider)
    public assetsAddProvider({ setState }: StateContext<IAssetStateModel>, { payload }: AssetsAddProvider): void {

        let asset: keyof IAssetStateModel | undefined;

        if (payload.entityClassType === EntityClassType.AssetRealEstate) {
            asset = 'assetRealEstates';
        } else if (payload.entityClassType === EntityClassType.AssetInsurance) {
            asset = 'assetInsurances';
        } else if (payload.entityClassType === EntityClassType.AssetAccount) {
            asset = 'assetAccounts';
        }

        if (!asset) {
            return;
        }

        setState(
            patch<IAssetStateModel>({
                [asset]: updateItem(item => item.id === payload.assetProvider.assetId, patch<IAssetRealEstate | IAssetInsurance | IAssetAccount>({
                    assetProviders: append([payload.assetProvider]),
                })),
            }),
        );
    }

    /**
     * Zustandsänderung beim hinzufügen einen Asset-Provider
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {AssetsLoaded} payload Assets Loaded Action 
     */
    @Action(AssetsDeleteProvider)
    public assetsDeleteProvider({ setState }: StateContext<IAssetStateModel>, { payload }: AssetsDeleteProvider): void {

        let asset: keyof IAssetStateModel | undefined;

        if (payload.entityClassType === EntityClassType.AssetRealEstate) {
            asset = 'assetRealEstates';
        } else if (payload.entityClassType === EntityClassType.AssetInsurance) {
            asset = 'assetInsurances';
        } else if (payload.entityClassType === EntityClassType.AssetAccount) {
            asset = 'assetAccounts';
        }

        if (!asset) {
            return;
        }

        setState(
            patch<IAssetStateModel>({
                [asset]: updateItem(item => item.id === payload.assetId, patch<IAssetRealEstate | IAssetInsurance | IAssetAccount>({
                    assetProviders: removeItem<IAssetProvider>(provider => provider.id === payload.assetProviderId),
                })),
            }),
        );
    }

    /**
     * Zustandsänderung beim Laden der Collaterals
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {CollateralLoaded} payload Collateral Loaded Action 
     */
    @Action(CollateralCreate)
    @Action(CollateralLoaded)
    public collateralLoaded({ patchState }: StateContext<IAssetStateModel>, { payload }: CollateralLoaded): void {
        patchState({
            collateralRealEstates: payload.mortages ?? [],
            collateralInsurances: payload.insurances ?? [],
            collateralAccounts: payload.accounts ?? [],
        });
    }

    /**
     * Zustandsänderung beim Speichern eines internen Werts
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {InternalFieldUpdated} action Aktion
     */
    @Action(InternalFieldUpdated)
    public internalFieldUpdated<T extends IAssetRealEstate | IAssetInsurance | IAssetAccount | IAddress | ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail>({ setState }: StateContext<IAssetStateModel | IAssetStateModel>, { payload }: InternalFieldUpdated<T>): void {

        let fieldName: keyof IAssetStateModel | undefined;

        if (payload.entityClassType === EntityClassType.AssetRealEstate) {
            fieldName = 'assetRealEstates';
        } else if (payload.entityClassType === EntityClassType.AssetInsurance) {
            fieldName = 'assetInsurances';
        } else if (payload.entityClassType === EntityClassType.AssetAccount) {
            fieldName = 'assetAccounts';
        } else if (payload.entityClassType === EntityClassType.CollateralMortgage || payload.entityClassType === EntityClassType.AssetToCollateralMortgage) {
            fieldName = 'collateralRealEstates';
        } else if (payload.entityClassType === EntityClassType.CollateralInsurance) {
            fieldName = 'collateralInsurances';
        } else if (payload.entityClassType === EntityClassType.CollateralAccount) {
            fieldName = 'collateralAccounts';
        }

        if (!fieldName) {
            return;
        }

        if (payload.entityClassType === EntityClassType.AssetToCollateralMortgage) {
            fieldName = 'collateralRealEstates';

            setState(
                patch<IAssetStateModel>({
                    [fieldName]: updateItem(collateral =>
                        !!collateral.assetAssignmentInfos && collateral.assetAssignmentInfos.some(info => info.id === payload.entityId),
                    patch({
                        assetAssignmentInfos: updateItem(info => info.id === payload.entityId, patch({
                            [payload.fieldName]: this.patchSaveInternalField(payload),
                        })),
                    }),
                    ),
                }),
            );
        } else {
            setState(
                patch<IAssetStateModel>({
                    [fieldName]: updateItem(item => item.id === payload.entityId, patch<IAssetRealEstate | IAssetInsurance | IAssetAccount | ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail>({
                        [payload.fieldName]: this.patchSaveInternalField(payload),
                    })),
                }),
            );
        }
    }

    /**
     * Zustandsänderung beim Verlassen der Finanzierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public financingLeaved({ setState }: StateContext<IAssetStateModel | IAssetStateModel>): void {
        setState(defaultData);
    }

    /**
     * Collaterals leeren
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(ClearCollateral)
    public clearCollaterals({ patchState }: StateContext<Partial<IAssetStateModel> | IAssetStateModel>): void {
        patchState(defaultDataCollaterals);
    }

    /**
     * Patchen des internen Feldes
     *
     * @param {ISaveInternalFieldRequest} payload Payload
     * @returns {unknown} Wert
     */
    private patchSaveInternalField<T>(payload: ISaveInternalFieldRequest<T>) {
        if (payload.valueStorageType === ValueStorageType.Int && payload.value !== undefined && payload.value !== null) {
            return parseInt(payload.value.toString(), 10);
        }
        return payload.value;
    }
}
