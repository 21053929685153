<ng-template #selectInputTemplate let-realEstate="realEstate" let-readonly="readonly" let-entity="entity" let-entityClassType="entityClassType" let-fieldName="fieldName" let-labelKey="labelKey" let-value="value">
    @if((readonly | async) === false) {
        <finprocess-select-input          
            [items]="booleanItems"
            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
            [entityClassType]="entityClassType"
            [hideHorizontalRule]="true"
            [flexDirection]="inputFlexDirection"
            [fieldName]="fieldName"
            [readonly]="readonly"
            [entity]="entity"
            [valueStorageType]="ValueStorageType.Bool"
            [validators]="Validators.required"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ ('asset.assetDetails.realEstateDetails.'+labelKey) | translate }}</span>
        </finprocess-select-input>
    } @else {
        <finprocess-label-value>
            <span label>{{('asset.assetDetails.realEstateDetails.'+labelKey) | translate}}</span>
            <span value>{{value === true ? ('general.yes' | translate) : (value === false ? ('general.no' | translate) : '-') }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #decimalInputTemplate let-realEstate="realEstate" let-entity="entity" let-entityClassType="entityClassType" let-fieldName="fieldName" let-labelKey="labelKey" let-value="value">
    @if((editingReadonly$ | async) === false) {
        <finprocess-decimal-input
            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
            [entityClassType]="entityClassType"
            [fieldName]="fieldName"
            [hideHorizontalRule]="true"
            [flexDirection]="inputFlexDirection"
            [readonly]="editingReadonly$"
            [entity]="entity"
            [validators]="Validators.required"
            [transparent]="true" 
            subscriptSizing="fixed">
            <span label class="input-label">{{ ('asset.assetDetails.realEstateDetails.'+labelKey) | translate }}</span>
        </finprocess-decimal-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ ('asset.assetDetails.realEstateDetails.'+labelKey) | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? (value | number: '1.2-2' : locale) : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<div *ngFor="let realEstate of collateralRealEstateDetail; let i = index; trackBy: panelTrackedBy; let last = last" #collaterals class="mb-2">
    <ucba-heading *ngIf="!!realEstate" [expandable]="true" #expandableHeading [ngClass]="{ 'requirements-failed': validRealEstatesMap[realEstate.id] === false }">
        <div ucba-heading-title>{{ realEstate.collateralType ?? 'Immobilie' | finprocessEnumTranslation : 'CollateralType' }}</div>
        <div ucba-heading-actions>
            <button 
                class="delete-btn" 
                [disabled]="editingReadonly$ | async" 
                mat-icon-button 
                (click)="deleteCollateral(realEstate.id)" 
                [matTooltip]="'asset.assetDetails.deleteCollateral' | translate"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div ucba-heading-body-visible>
            <ucba-tile-layout>
                <!-- only shown when BA205 -->
                 <!-- Erfolgt eine Teil-/Mindereintragung? -->
                <ng-template ucba-tile *ngIf="realEstate.collateralType === CollateralType.BA205">
                    <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonly$, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'isPartialEntry', labelKey: 'isReducedAmount', value: realEstate.isPartialEntry }"></ng-container>
                </ng-template>
                <!-- only shown when "Erfolgt eine Teil-/Mindereintragung"=ja -->
                 <!-- Höhe der GB Eintragung -->
                <ng-template ucba-tile *ngIf="realEstate.isPartialEntry === true">
                    <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { realEstate: realEstate, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'gbaAmount', labelKey: 'amountGB', value: realEstate.gbaAmount }"></ng-container>
                </ng-template>
                <!-- LTV relevante Liegenschaft -->
                <ng-template ucba-tile *ngIf="realEstate.collateralType !== CollateralType.BA205 || (realEstate.isPartialEntry === true || realEstate.isPartialEntry === false)">
                    <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonly$, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'ltvRelevant', labelKey: 'ltvRelevantProperty', value: realEstate.ltvRelevant }"></ng-container>
                </ng-template>
                <!-- Höhe der PBU -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { realEstate: realEstate, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'pbuAmount', labelKey: 'pbuAmount', value: realEstate.pbuAmount }"></ng-container>
                </ng-template>
                <!-- calculation: if "Erfolgt eine Teil-/Mindereintragung"=ja := Höhe der GB Eintragung, else:=Höhe der PBU -->
                <!-- Erwarteter Vertragswert -->
                <ng-template ucba-tile *ngIf="!!realEstate.gbaAmount && realEstate.isPartialEntry === true">  
                    <div [class.edit-mode-label-value]="(editingReadonly$ | async) === false">
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetDetails.realEstateDetails.contractvalue' | translate }}</span>
                            <span value>{{ realEstate.gbaAmount | formatCurrency }}</span>
                        </finprocess-label-value>
                    </div>                
                </ng-template>
                <ng-template ucba-tile *ngIf="!!realEstate.pbuAmount && realEstate.isPartialEntry === false">  
                    <div>
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetDetails.realEstateDetails.contractvalue' | translate }}</span>
                            <span value>{{ realEstate.pbuAmount | formatCurrency }}</span>
                        </finprocess-label-value>
                    </div>
                </ng-template>
            </ucba-tile-layout>
        </div>
        <div ucba-heading-body>
            <!-- required fields for approval page -> only when source!=AGP -->
            <ucba-tile-layout *ngIf="approvalView === true && realEstate.source !== SourceCollateral.Agp" class="margin-tile-layout">
                <!-- Verzicht auf notwendigen Treuhänder -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonlyApprovalView$, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'waiverOfUnnecessaryTrustee', labelKey: 'waiverOfUnnecessaryTrustee', value: realEstate.waiverOfUnnecessaryTrustee }"></ng-container>
                </ng-template>
                <!-- Treuhändische Abwicklung -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonlyApprovalView$, entityClassType: entityClassType.CollateralMortgage, entity: realEstate, fieldName: 'fiduciaryProcessing', labelKey: 'fiduciaryProcessing', value: realEstate.fiduciaryProcessing }"></ng-container>
                </ng-template>
                <!-- Geldrang neue Hypothek -->
                <ng-template ucba-tile>
                    @if((editingReadonlyApprovalView$ | async) === false) {
                        <finprocess-integer-input
                            [overwrite]="isSourceCollateralAgp(realEstate) ? true : false"
                            [entityClassType]="entityClassType.CollateralMortgage"
                            fieldName="priorityNewMortgage"
                            [hideHorizontalRule]="true"
                            [flexDirection]="inputFlexDirection"
                            [readonly]="editingReadonlyApprovalView$"
                            [entity]="realEstate"
                            [transparent]="true"
                            [validators]="Validators.required">
                            <span label class="input-label">{{ 'asset.assetDetails.realEstateDetails.priorityNewMortgage' | translate }}</span>
                        </finprocess-integer-input>
                    } @else {
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetDetails.realEstateDetails.priorityNewMortgage' | translate }}</span>
                            <span value>{{ (realEstate.priorityNewMortgage !== undefined && realEstate.priorityNewMortgage !== null) ? realEstate.priorityNewMortgage : '-' }}</span>
                        </finprocess-label-value>
                    }
                </ng-template>
            </ucba-tile-layout>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.realEstateDetails.properties' | translate }}</div>
                <div 
                    ucba-heading-actions
                    *ngIf="!!assetRealEstate && assetRealEstate.length > 0 && (editingReadonly$ | async) !== true"
                    class="add-btn"
                    (click)="matSelect.open()">
                    <span class="mr-1">{{ 'asset.assetDetails.realEstateDetails.assignProperties' | translate }}</span>
                    <mat-icon style="font-size: 20px;">add_circle</mat-icon>
                    <mat-select style="visibility: hidden;" #matSelect [panelWidth]="350" [ngModel]="realEstate.assetAssignmentInfos" [compareWith]="compareAssetRealEstates" multiple>
                        <mat-option #option id="{{estate.id}}" *ngFor="let estate of assetRealEstate" [value]="estate" (onSelectionChange)="assignRealEstates(estate.id, realEstate.id, true, $event)">
                            <ng-container>{{estate.assetType ?? '' | finprocessEnumTranslation: 'RealEstateType'}}, </ng-container>
                            <ng-container *ngIf="estate.address?.zip"> {{estate.address?.zip}},</ng-container>
                            <ng-container *ngIf="estate.address?.city"> {{estate.address?.city}},</ng-container>
                            <ng-container *ngIf="estate.address?.street"> {{estate.address?.street}},</ng-container>
                            <ng-container *ngIf="estate.address?.streetNumber"> {{estate.address?.streetNumber}},</ng-container>
                            <ng-container *ngIf="estate.address?.top"> {{estate.address?.top}},</ng-container>
                            <ng-container *ngIf="estate.marketValue"> Marktwert: {{estate.marketValue | formatCurrency}}</ng-container>
                        </mat-option>
                    </mat-select>
                </div>                      
                <div ucba-heading-body-visible>
                    @if((!assetRealEstate || assetRealEstate.length === 0) || (!realEstate.assetAssignmentInfos || realEstate.assetAssignmentInfos.length === 0)) {
                        <ucba-tile-layout>
                            <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.realEstateDetails.noRealEstateDetails' | translate }}</ng-template>
                        </ucba-tile-layout>
                    } @else {
                        <div *ngFor="let assignAsset of realEstate.assetAssignmentInfos; let j = index">
                            <ucba-heading *ngIf="!!assignAsset" [type]="'small'" [expandable]="true">
                                <div ucba-heading-title *ngIf="assignAsset.assetMortgage.assetType !== undefined">{{ assignAsset.assetMortgage.assetType | finprocessEnumTranslation : 'RealEstateType' }}</div>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxLayout="row" fxFlex="40">
                                        <div>{{assignAsset.assetMortgage.address?.zip}}</div>
                                        <div *ngIf="!!assignAsset.assetMortgage.address?.city">&nbsp;{{assignAsset.assetMortgage.address?.city}},</div>
                                        <div>&nbsp;{{assignAsset.assetMortgage.address?.street}}</div>
                                        <div *ngIf="!!assignAsset.assetMortgage.address?.streetNumber">&nbsp;{{assignAsset.assetMortgage.address?.streetNumber}}</div>
                                        <div *ngIf="!!assignAsset.assetMortgage.address?.top">, Top: {{assignAsset.assetMortgage.address?.top}}</div>
                                    </div>
                                    <div fxLayout="row" fxFlex="30" *ngIf="!!assignAsset.assetMortgage.marketValue" class="mr-4">
                                        {{ 'asset.assetDetails.realEstateDetails.marketValue' | translate }}:
                                        {{ assignAsset.assetMortgage.marketValue | formatCurrency }}
                                    </div>
                                </div>
                                <div ucba-heading-actions>
                                    <button 
                                        *ngIf="(editingReadonly$ | async) !== true" 
                                        class="delete-btn black" 
                                        (click)="assignRealEstates(assignAsset.assetId, realEstate.id, false)"
                                        [matTooltip]="'asset.assetDetails.realEstateDetails.deleteProperty' | translate"
                                        mat-icon-button>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div ucba-heading-body>
                                    <ucba-tile-layout>
                                        <!-- Fremde Vorlasten inkl. Eigene Vorlasten Dritter/NV gem. BA Definition -->
                                        <ng-template ucba-tile [span]="2">
                                            <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonly$, entityClassType: entityClassType.AssetToCollateralMortgage, entity: assignAsset, fieldName: 'foreignPreloads', labelKey: 'foreignAndOwnLiabilitiesBADef', value: assignAsset.foreignPreloads }"></ng-container>
                                        </ng-template>
                                        <!-- BELQ relevant -->
                                        <ng-template ucba-tile>
                                            <ng-container *ngTemplateOutlet="selectInputTemplate; context: { realEstate: realEstate, readonly: editingReadonly$, entityClassType: entityClassType.AssetToCollateralMortgage, entity: assignAsset, fieldName: 'belqRelevant', labelKey: 'relevantBelq', value: assignAsset.belqRelevant }"></ng-container>
                                        </ng-template>
                                        <!-- Fremde Vorlasten KIM-V -->
                                        <ng-template ucba-tile>
                                            <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { realEstate: realEstate, entityClassType: entityClassType.AssetToCollateralMortgage, entity: assignAsset, fieldName: 'preloadsKimV', labelKey: 'foreignKimV', value: assignAsset.preloadsKimV }"></ng-container>
                                        </ng-template>
                                        <!-- Eigene Vorlasten Dritter / NV gem. KIM-V -->
                                        <ng-template ucba-tile>
                                            <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { realEstate: realEstate, entityClassType: entityClassType.AssetToCollateralMortgage, entity: assignAsset, fieldName: 'ownPreloads', labelKey: 'ownKimV', value: assignAsset.ownPreloads }"></ng-container>
                                        </ng-template>
                                        <!-- Summe BA-Hypotheken Wohnimmoblien gem. KIM-V -->
                                        <ng-template ucba-tile>
                                            <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { realEstate: realEstate, entityClassType: entityClassType.AssetToCollateralMortgage, entity: assignAsset, fieldName: 'totalBaMortgages', labelKey: 'sumOfRealEstateKIMV', value: assignAsset.totalBaMortgages }"></ng-container>
                                        </ng-template>
                                    </ucba-tile-layout>
                                    <!-- TODO: update providers immediately when saved -->
                                    <finprocess-asset-provider
                                        class="asset-provider"
                                        [headingType]="'small'"
                                        [assetId]="assignAsset.assetId"
                                        [assetProviders]="assignAsset.assetMortgage.assetProviders">
                                    </finprocess-asset-provider>
                                </div>
                            </ucba-heading>
                            <finprocess-collateralisation [collateralDetail]="realEstate" [dataSources]="dataSources.get(realEstate)" [editingReadonly$]="editingReadonly$"></finprocess-collateralisation>
                        </div>
                    }
                </div>
            </ucba-heading>
        </div>
    </ucba-heading>
</div>
