import { IDocument, IFinancingPlanStatusEntry, IInternalFieldError, IProductPackageAccepted, IProductPackageStatusEntry, IRatingResult } from 'app/modules/financing/data';
import { IEditor } from 'app/modules/shared';
import { UUID } from 'shared/util';

import { ICreditLineCalculation, IFinancing, IFinprocessContainer, IOverwriteCreateOrUpdateRequest, IOverwriteDelete, IProductPackageData, ISampleCalculationContainer, ISaveInternalFieldRequest, IStatusEntry } from '../../interfaces';
import { IRFPData } from '../../interfaces/riskfinancingplans-data.interface';

/**
 * Aktion beim Laden der Finanzierung
 */
export class FinancingLoaded {
    public static readonly type = 'financing.loaded';

    /**
     * Konstruktor
     *
     * @param {IFinancing} payload Daten der Finanzierung
     */
    public constructor(public payload: IFinancing) { }
}

/**
 * Aktion beim Laden des Containers
 */
export class FinProcessContainerLoaded {
    public static readonly type = 'financing.finProcessContainerLoaded';

    /**
     * Konstruktor
     *
     * @param {IFinancing} payload Daten des Containers
     */
    public constructor(public payload: IFinprocessContainer) { }
}

/**
 * Aktion beim Laden der FinancingContainerID für Risikofinanzierungspläne
 */
export class FinancingContainerIDLoaded {
    public static readonly type = 'financing.financingContainerIdLoaded';

    /**
     * Konstruktor
     *
     * @param {string} financingContainerID Daten der Finanzierung
     */
    public constructor(public financingContainerID: string) { }
}

/**
 * Aktion beim Laden der RiskFinancingPlans für Risikofinanzierungspläne
 */
export class RiskFinancingPlansLoaded {
    public static readonly type = 'financing.riskFinancingPlansLoaded';

    /**
     * Konstruktor
     *
     * @param {IRFPData[]} riskFinancingPlansLoaded Daten der Finanzierung
     */
    public constructor(public riskFinancingPlansLoaded: IRFPData[]) { }
}

/**
 * Aktion beim Löschen eines Overwrites
 */
export class OverwriteDeleted {
    public static readonly type = 'financing.overwriteDeleted';

    /**
     * Konstruktor
     *
     * @param {IFinancing} payload Daten der Finanzierung
     */
    public constructor(public payload: IOverwriteDelete) { }
}


/**
 * Aktion beim Hinzufügen oder Aktualisieren eines Overwrites
 */
export class OverwriteCreatedOrUpdated {
    public static readonly type = 'financing.overwriteCreatedOrUpdated';

    /**
     * Konstruktor
     *
     * @param {IOverwriteCreateOrUpdateRequest} payload Daten der Finanzierung
     * @param {UUID} overwriteId Id des Overwrite
     */
    public constructor(public payload: IOverwriteCreateOrUpdateRequest, public overwriteId: UUID) { }
}

/**
 * Aktion beim Speichern eines internen Wertes
 */
export class InternalFieldUpdated<T> {
    public static readonly type = 'financing.internalFieldUpdated';

    /**
     * Konstruktor
     *
     * @param {ISaveInternalFieldRequest} payload Daten der Finanzierung
     */
    public constructor(public payload: ISaveInternalFieldRequest<T>) { }
}

/**
 * Aktion bei einem Validierungsfehler eines Feldes
 */
export class InternalFieldError<T> {
    public static readonly type = 'financing.internalFieldError';

    /**
     * Konstruktor
     * 
     * @param {IInternalFieldError} payload Fehlerobjekt
     */
    public constructor(public payload: IInternalFieldError<T>) { }
}

/**
 * Aktion beim Ändern des Status
 */
export class StatusUpdated {
    public static readonly type = 'financing.statusUpdated';

    /**
     * Konstruktor
     *
     * @param {IStatusEntry} payload Daten des Status
     */
    public constructor(public payload: IStatusEntry) { }
}

/**
 * Aktion beim Ändern des FinancingContainerStatus
 */
export class StatusUpdatedFinancingContainer {
    public static readonly type = 'financing.statusUpdatedFinancingContainer';

    /**
     * Konstruktor
     *
     * @param {IStatusEntry} payload Daten des Status
     */
    public constructor(public payload: IStatusEntry) { }
}

/**
 * Aktion beim Laden der Status Einträge
 */
export class StatusLoaded {
    public static readonly type = 'financing.statusLoaded';

    /**
     * Konstruktor
     *
     * @param {IStatusEntry} payload Status Einträge
     */
    public constructor(public payload: IStatusEntry[]) { }
}

/**
 * Aktion beim Laden der Status Einträge für Risikofinanzierungspläne
 */
export class RiskFinancingPlanStatusLoaded {
    public static readonly type = 'financingplan.statusLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload payload
     * @param {IFinancingPlanStatusEntry} payload.financingPlanStatusEntry Status Einträge
     * @param {string} payload.riskFinancingId Status Einträge
     */
    public constructor(public payload: {financingPlanStatusEntry: IFinancingPlanStatusEntry[], riskFinancingId: string}) { }
}

/**
 * Aktion beim Laden des Rechenbeispiel Containers
 */
export class SampleCalculationContainerLoaded {
    public static readonly type = 'financing.sampleCalculationContainerLoaded';

    /**
     * Konstruktor
     *
     * @param {ISampleCalculationContainer} payload Rechenbeispiel Container
     */
    public constructor(public payload: ISampleCalculationContainer) { }
}

/**
 * Aktion beim beim Ändern des Rechenbeispiel Containers
 */
export class SampleCalculationContainerSaved {
    public static readonly type = 'financing.sampleCalculationSaved';

    /**
     * Konstruktor
     *
     * @param {ISampleCalculationContainer} payload Rechenbeispiel Container
     */
    public constructor(public payload: ISampleCalculationContainer) { }
}

/**
 * Aktion beim beim Ändern des Kreditlinie
 */
export class CreditLineCalculationAddedOrUpdated {
    public static readonly type = 'financing.creditLineCalculationAddedOrUpdated';

    /**
     * Konstruktor
     *
     * @param {ICreditLineCalculation} payload Rechenbeispiel Container
     */
    public constructor(public payload: ICreditLineCalculation) { }
}


/**
 * Aktion beim Financing Document upload
 */
export class FinancingDocumentUploaded {
    public static readonly type = 'financing.documentuploaded';

    /**
     * Konstruktor
     *
     * @param {IDocument} payload IDocument
     */
    public constructor(public payload: IDocument) { }
}


/**
 * Aktion beim Household Document Upload
 */
export class HouseholdDocumentUploaded {
    public static readonly type = 'financing.householdocument';

    /**
     * Konstruktor
     *
     * @param {IDocument} payload IDocument
     */
    public constructor(public payload: IDocument) { }
}

/**
 * Aktion beim Debtor Document Upload
 */
export class DebtorDocumentUploaded {
    public static readonly type = 'financing.debtordocument';

    /**
     * Konstruktor
     *
     * @param {IDocument} payload IDocument
     */
    public constructor(public payload: IDocument) { }
}

/**
 * Aktion beim Debtor Document Upload
 */
export class DeleteDebtorFile {
    public static readonly type = 'financing.debtordocument';

    /**
     * Konstruktor 
     * 
     * @param {object} payload Payload
     * @param {string} payload.debtorId debtor id
     * @param {string} payload.fileId fileId
     */
    public constructor(public payload: {debtorId: string, fileId: string}) { }
}

/**
 * Product Packages
 */
export class ProductPackages {
    public static readonly type = 'productpackages';

    /**
     * Konstruktor
     * 
     * @param {IProductPackageData} productPackages productPackages
     */
    public constructor(public productPackages: IProductPackageData) { }
}

/**
 * Product Packages
 */
export class AcceptedProductPackageLoaded {
    public static readonly type = 'loadedAcceptedProductPackage';

    /**
     * Konstruktor
     * 
     * @param {IProductPackageAccepted} acceptedProductPackage productPackages
     */
    public constructor(public acceptedProductPackage: IProductPackageAccepted) { }
}


/**
 * Update Product Packages
 */
export class UpdateProductPackages {
    public static readonly type = 'updateProductpackages';

    /**
     * Konstruktor
     * 
     * @param {IProductPackageData} productPackage productPackages
     */
    public constructor(public productPackage: IProductPackageData) { }
}

/**
 * Update Product Packages Decision
 */
export class UpdateProductPackageDecision {
    public static readonly type = 'updateProductpackagesDecision';

    /**
     * Konstruktor
     * 
     * @param {IProductPackageData} productPackage productPackages
     */
    public constructor(public productPackage: IProductPackageData) { }
}

/**
 * Aktion beim Übergeben eines Produktpakets an einen Genehmiger
 */
export class AssignedApprover {
    public static readonly type = 'financing.assignedApprover';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IProductPackageStatusEntry} payload.status Neuer Status Eintrag
     * @param {IEditor} payload.approver Genehmiger
     * @param {string} payload.productPackageId Produktpaket Id
     */
    public constructor(public payload: { status: IProductPackageStatusEntry, approver: IEditor, productPackageId: string}) { }
}

/**
 * Aktion beim Aktualisieren des Status eines Produktpakets
 */
export class ProductPackageStatusUpdated {
    public static readonly type = 'financing.productPackageStatusUpdated';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IProductPackageStatusEntry} payload.status Statuseintrag
     * @param {string} payload.productPackageId Produktpaket ID
     */
    public constructor(public payload: {status: IProductPackageStatusEntry, productPackageId: string}) { }
}

/**
 * Aktion beim Aktualisieren des Status eines Produktpakets
 */
export class ProductPackageStatusLoaded {
    public static readonly type = 'financing.productPackageStatusLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IProductPackageStatusEntry} payload.statusEntries Statuseintrag
     * @param {string} payload.productPackageId Produktpaket ID
     */
    public constructor(public payload: {statusEntries: IProductPackageStatusEntry[], productPackageId: string}) { }
}

/**
 * Aktion beim Aktualisieren des Rating einen Debitor
 */
export class DebitorRatingUpdated {
    public static readonly type = 'financing.debitorRatingUpdated';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IRatingResult} payload.ratingResult IRatingResult
     * @param {string} payload.housholdId housholdId 
     * @param {string} payload.debitorId debitorId
     */
    public constructor(public payload: {ratingResult: IRatingResult, housholdId: string, debitorId: string }) { }
}

/**
 * Aktion beim Aktualisieren des Rating mehrere Debitors
 */
export class DebitorsRatingUpdated {
    public static readonly type = 'financing.debitorsRatingUpdated';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IRatingResult} payload.ratingResult IRatingResult
     */
    public constructor(public payload: IRatingResult[]) { }
}
