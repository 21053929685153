export enum EducationLevel {
    /**
     * Pflichtschule
     */
    CompulsorySchool,

    /**
     * Lehrling
     */
    Apprenticeship,

    /**
     * Matura
     */
    SchoolLeavingExamination,

    /**
     * Meister
     */
    MasterCraftsman,

    /**
     * MBA ohne akademische Studien
     */
    MbaWithoutAcademicStudies,

    /**
     * Akademische Studien mit/ohne MBA ohne Doktor
     */
    AcademicStudiesWithOrWithoutMbaWithoutDoctor,

    /**
     * Doktorat
     */
    DoctorWithOrWithoutMba,

    /**
     * Unbekannt
     */
    Unknown,
}
