import { IUser } from 'app/modules/auth/data';

/**
 * Aktion bei erfolgreichem Laden der Nutzerdaten
 */
export class UserAdministrationLoaded {
    public static readonly type = 'userAdministration.loaded';

    /**
     * Konstruktor
     *
     * @param {IUser[]} payload Bestehende Nutzerdaten
     */
    public constructor(public payload: IUser[]) { }
}

/**
 * Aktion beim Bestätigen eines Nutzers
 */
export class UserAdministrationUserConfirmed {
    public static readonly type = 'userAdministration.userConfirmed';

    /**
     * Konstruktor
     *
     * @param {string} payload Nutzer ID
     */
    public constructor(public payload: string) { }
}

/**
 * Aktion beim Editieren einen User
 */
export class UserEdited {
    public static readonly type = 'auth.userEdited';

    /**
     * Konstruktor
     *
     * @param {IUser} payload Aktualisierte einen User
     */
    public constructor(public payload: Partial<IUser>) { }

}

/**
 * Aktion beim Editieren mehrere Users
 */
export class UsersEdited {
    public static readonly type = 'auth.userEdited';

    /**
     * Konstruktor
     *
     * @param {IUser[]} payload Aktualisierte mehrere User
     */
    public constructor(public payload: Partial<IUser>[]) { }

}
