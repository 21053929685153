export enum TrusteeType {
    /**
     * Notar
     */
    Notary = 1,

    /**
     * Rechtsanwalt
     */
    Lawyer = 2,

    /**
     * Genossenschaft/Bauträger
     */
    ConstructionCompany = 3,
}
