import { WorkflowType } from '../../enums';
import { FormattingType, OptionalBoolenType, RealEstateClassType, ValuationClassType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const VALUATION_OTHER: IMidtermModel = {

    midtermName: 'valuationOther',

    /**
     * Art der Bewertung / LTV-relevante Liegenschaft 1
     * [ltv/so]_bewart_1 (ltv_bewart_1 order so_bewart_1) bis [ltv/so]_bewart_10
     */
    bewArt: { type: WorkflowType.Enum, enumObject: ValuationClassType, enumLabel: 'ValuationClassType' },

    /**
     * Bewertungsnummer
     * [ltv/so]_bewnr_1 bis [ltv/so]_bewnr_10
     */
    bewNr: { type: WorkflowType.String },

    /**
     * Marktwert lt. Bewertung
     * [ltv/so]_marktwert_1 bis [ltv/so]_marktwert_10
     */
    marktwert: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * fremde Vorlasten inkl. eig. Vorlasten Dritter/NV gem. BA-Definition*
     * [ltv/so]_vorlasten_ba
     */
    vorlastenBa: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * fremde Vorlasten / sonstige Liegenschaft
     * Neue Bezeichnung: fremde Vorlasten gem.KIM-V(BELQ)
     * [ltv/so]_fremde_vorlasten_kimv
     */
    fremdeVorlastenKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * eig. Vorlasten Dritter/NV gem. KIM-V (BELQ)
     * [ltv/so]_eigene_vorlasten_kimv
     */
    eigeneVorlastenKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * ∑ BA-Hypotheken Wohnimmob. gem. KIM-V (BELQ)
     * [ltv/so]_summe_hyp_immo_kimv
     */
    summeHypImmoKimv: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * LS-Verkauf gem. KIM-V Novelle
     * so_lsverkauf_kimv
     */
    lsVerkaufKimv: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * mat. Wert händisch (auch als Schalter für "Materieller Wert" bezeichnet)
     * [ltv/so]_wert_manuell_1 bis [ltv/so]_wert_manuell_10
     * --> wenn falls, dann ist SumMatValueOngoing egal, weil es bei Fire berechnet wird
     * --> wenn true, dann muss SumMatValueOngoing angegeben sein, weil es bei Fire dann nicht berechnet wird
     */
    wertManuell: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Immoblienart
     * [ltv/so]_immoart_1 bis [ltv/so]_immoart_10
     */
    immoart: { type: WorkflowType.Enum, enumObject: RealEstateClassType, enumLabel: 'RealEstateClassType' },

    sumManuell: { type: WorkflowType.Number, formattingType: FormattingType.Currency },

    /**
     * Summe mat. Wert händisch
     * (Nur eingebbar, wenn [ltv/so]_wert_manuell = true, ansonsten mit 0 übergeben.
     * [ltv/so]_wert_manuell
     */
    belqRelevant: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },
}
