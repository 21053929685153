import { Component, computed } from '@angular/core';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Eingabefeld - Ganzzahl ohne Dezimalzeichen
 */
@Component({
    selector: 'finprocess-integer-input',
    templateUrl: './integer-input.component.html',
    styleUrls: ['./integer-input.component.scss'],
})
export class IntegerInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Translated label for the tooltip for the original value
     */
    public translatedOriginalValueKey = computed(() => {
        const originalValue = this.originalValue();

        if (originalValue === undefined || originalValue === null) {
            return 'financing.features.financing-processing.unset';
        }

        return originalValue.toString();
    });

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService FinancingService-Injektor
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.Int, financingService);
    }
}
