export enum EarlyRepaymentType {
    /**
     * De:  Mit hyp. Sicherstellung
     * ENG: with mortgage
     */
    HSM_EARLY_REPAY_TYPE_1,

    /**
     * Ohne hyp. Sicherstellung
     * without mortgage
     */
    HSM_EARLY_REPAY_TYPE_2,

    /**
     * Verzicht auf Pönaleklausel
     * no penalty clause
     */

    HSM_EARLY_REPAY_TYPE_3,

    /**
     * Verzicht auf 6monatige Kündigungsfrist
     * no 6 months period
     */

    HSM_EARLY_REPAY_TYPE_4,

    /**
     * Ohne hyp. Sicherstellung abweichender Betrag
     * without mortgage, other amount
     */

    HSM_EARLY_REPAY_TYPE_5,

    /**
     * Mit hyp. Sicherstellung abweichender Betrag
     * with mortgage oter amount
     */

    HSM_EARLY_REPAY_TYPE_6,

    /**
     * Mit hyp. Sicherstell. abw. Betrag u. Verzicht 6-m. Kündigungsfrist
     * with mortgage, other amount no 6 months period
     */

    HSM_EARLY_REPAY_TYPE_7,
}
