import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { EnumMissingTranslationHandler, EnumTranslateCompiler, EnumTranslateParser, EnumTranslateStore } from '../../classes';
import { Helper, UnionValueSingleType, UnionValueType } from '../../utils';
import { EnumTranslateLoader } from '../enum-translation-loader/enum-translation-loader.service';

/**
 * Nutzung des EnumTranslateStore
 */
export const ENUMS_USE_STORE = new InjectionToken<string>('ENUMS_USE_STORE');

/**
 * Nutzung der Standardsprache
 */
export const ENUMS_USE_DEFAULT_LANG = new InjectionToken<string>('ENUMS_USE_DEFAULT_LANG');

/**
 * Standardsprache
 */
export const ENUMS_DEFAULT_LANGUAGE = new InjectionToken<string>('ENUMS_DEFAULT_LANGUAGE');

/**
 * Übersetzungen von übergeordnetem Modul nutzen
 */
export const ENUMS_USE_EXTEND = new InjectionToken<string>('ENUMS_USE_EXTEND');

/**
 * Service zum Übersetzen von Enums
 */
@Injectable()
export class EnumTranslationService extends TranslateService {
    /**
     * Konstruktor
     *
     * @param {EnumTranslateStore} store EnumTranslateStore-Injektor
     * @param {EnumTranslateLoader} currentLoader EnumTranslateLoader-Injektor
     * @param {EnumTranslateCompiler} compiler EnumTranslateCompiler-Injektor
     * @param {EnumTranslateParser} parser EnumTranslateParser-Injektor
     * @param {EnumMissingTranslationHandler} missingTranslationHandler EnumMissingTranslationHandler-Injektor
     * @param {string} defaultLanguage ENUMS_DEFAULT_LANGUAGE-Injektor
     * @param {boolean} useDefaultLang ENUMS_USE_DEFAULT_LANG-Injektor
     * @param {boolean} isolate ENUMS_USE_STORE-Injektor
     * @param {boolean} extend ENUMS_USE_EXTEND-Injektor
     */
    public constructor(public store: EnumTranslateStore,
        currentLoader: EnumTranslateLoader,
        compiler: EnumTranslateCompiler,
        parser: EnumTranslateParser,
        missingTranslationHandler: EnumMissingTranslationHandler,
        @Inject(ENUMS_DEFAULT_LANGUAGE) defaultLanguage: string,
        @Inject(ENUMS_USE_DEFAULT_LANG) useDefaultLang = true,
        @Inject(ENUMS_USE_STORE) isolate = false,
        @Inject(ENUMS_USE_EXTEND) extend = false) {
        super(store, currentLoader, compiler, parser, missingTranslationHandler, useDefaultLang, isolate, extend, defaultLanguage);
    }

    /**
     * Liefert das geparste Ergebnis einer Übersetzung
     *
     * @param {any} translations Übsertzungen
     * @param {UnionValueType} value Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @returns {any} Geparstes Ergebnis einer Übersetzung
     */
    public getParsedResult(translations: unknown, value: UnionValueType, interpolateParams?: Record<string,unknown>): unknown {
        return super.getParsedResult(translations, Helper.getKeyByEnum(value), interpolateParams);
    }

    /**
     * Liefert ein Observable mit dem/den übersetzten Enum(s)
     *
     * @param {UnionValueType} key Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @returns {Observable<any>} Observable mit dem/den übersetzten Enum(s)
     */
    public get(key: UnionValueType, interpolateParams?: Record<string,unknown>): Observable<unknown> {
        return super.get(Helper.getKeyByEnum(key), interpolateParams);
    }

    /**
     * Liefert einen Datenstrom mit dem/den übersetzten Enum(s), welcher immer aktualisiert wird, wenn die Übersetzungen sich ändern
     *
     * @param {UnionValueType} key Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @returns {Observable<any>} Datenstrom mit dem/den übersetzten Enum(s)
     */
    public getStreamOnTranslationChange(key: UnionValueType, interpolateParams?: Record<string,unknown>): Observable<unknown> {
        return super.getStreamOnTranslationChange(Helper.getKeyByEnum(key), interpolateParams);
    }

    /**
     * Liefert einen Datenstrom mit dem/den übersetzten Enum(s), welcher immer aktualisiert wird, wenn die Sprache sich ändert
     *
     * @param {UnionValueType} key Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @returns {Observable<any>} Datenstrom mit dem/den übersetzten Enum(s)
     */
    public stream(key: UnionValueType, interpolateParams?: Record<string,unknown>): Observable<unknown> {
        return super.stream(Helper.getKeyByEnum(key), interpolateParams);
    }

    /**
     * Liefert übersetzte(s) Enum(s) aus dem aktuellen Zustand der geladenen Übersetzungen
     *
     * @param {UnionValueType} key Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @returns {any} Übersetzte(s) Enum(s)
     */
    public instant(key: UnionValueType, interpolateParams?: Record<string,unknown>): unknown {
        return super.instant(Helper.getKeyByEnum(key), interpolateParams);
    }

    /**
     * Setzt übersetzten Enum-Wert nach Kompilieren
     *
     * @param {UnionValueSingleType} key Parameter zum Ermitteln des Übersetzungsschlüssels
     * @param {string} value Übersetzter Enum-Wert
     * @param {string} lang Sprache
     */
    public set(key: UnionValueSingleType, value: string, lang: string = this.currentLang): void {
        super.set(Helper.getKeyByEnum(key) as string, value, lang);
    }
}
