/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MandantType, MessageUserType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role, UserService } from 'app/modules/auth/data';
import { FinancingService, IFinprocessContainer } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { FinancingEventType, InformationEventService, InformationEventState } from 'app/modules/information-events/data';
import { MessagesService } from 'app/modules/messages/data';
import { ClearMessages, MessagesState } from 'app/modules/messages/data/states';
import { FinancingStatus, FinancingSubStatus, HelperService, UUID } from 'app/modules/shared';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, distinctUntilChanged, filter, forkJoin, map, mergeMap, of, take, takeUntil, tap } from 'rxjs';

import { SearchMessagesDialogComponent } from '../search-messages-dialog/search-messages-dialog.component';

import { IMessage } from './../../../../data/interfaces/messages.interface';

interface IDebtorNamesOverwrite {
    lastNameOverwrite: string,
    firstNameOverwrite: string,
}
/**
 * Komponente für das Dashboard
 */
@Component({
    selector: 'finprocess-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent implements OnInit, OnDestroy {

    /**
     * Observable für den aktuellen FinProcessContainer
     */
    public finprocessContainer$: ReplaySubject<IFinprocessContainer> = new ReplaySubject(1);

    /**
     * Ist der interne Chat readonly
     */
    public isReadonlyInternal = true;

    /**
     * Formular für Nachrichten
     */
    public chatForm: FormGroup | undefined;

    /**
     * ID der aktuellen Finanzierung
     */
    public financingMapId: string | undefined;

    /**
     * Enum für Template Nutzung
     */
    public messageUserType = MessageUserType;

    /**
     * Ist der Senden Button aktiv
     */
    public isSendBtnActive = true;

    /**
     * Ist der aktuell ausgewählte Chat der interne Chat
     */
    public isInternal = new BehaviorSubject<boolean>(false);

    /**
     * Index des ausgewählten Chats
     */
    public selectedTabIdx = 0;

    /**
     * Ist das Textfeld für die Nachrichteneingabe sichtbar
     */
    public isInputShown = false;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;

    /**
     * Wird gesetzt wenn der Chat fokussiert ist, um eine Klasse an der TabGroup zu setzen
     */
    public focusState = false;

    /**
     * Ladezustand der Komponente
     */
    public loading = true;

    /**
     * Observable mit dem Gruppenchat
     */
    public groupChat$!: Observable<IMessage[] | undefined>;

    /**
     * Observable mit dem Internen Chat
     */
    public internalChat$!: Observable<IMessage[] | undefined>;

    /**
     * Ist der Export deaktiviert
     */
    public exportDisabled$!: Observable<boolean>;

    /**
     * Edit deaktivieren
     */
    public editDisabled$!: Observable<boolean>;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Für Template-Nutzung
     */
    public isMandantTypeFinService: boolean | undefined;

    /**
     * Observable mit dem Hauptkreditnehmer
     */
    public mainDebitor$: Subject<IDebtorNamesOverwrite> = new ReplaySubject<IDebtorNamesOverwrite>(1);

    private receiver: null | undefined | MessageUserType;
    private receiverGroupChat = HelperService.convertArrayToFlag([MessageUserType.Source, MessageUserType.Customer]);
    private receiverInternalChat = HelperService.convertArrayToFlag([MessageUserType.Source]);
    
    /**
     *Standard Konstuktor
     *
     * @param {MessagesService} messagesService MessagesService Injector
     * @param {ActivatedRoute} activatedRoute ActivatedRoute Injector
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef Injector
     * @param {DomSanitizer} domSanitziser DomSanitizer Injector
     * @param {Store} store Store Injector
     * @param {MatDialog} dialog Angular Dialog-Injektor
     * @param {WaiterService} waiterService WaiterService
     * @param {NotificationService} notificationService NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {InformationEventService} informationEventService InformationEventService
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {FinancingService} financingService financingService
     */
    public constructor(
        private messagesService: MessagesService,
        private activatedRoute: ActivatedRoute,
        private cRef: ChangeDetectorRef,
        private domSanitziser: DomSanitizer,
        private store: Store,
        private dialog: MatDialog,
        private waiterService: WaiterService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private userService: UserService,
        private informationEventService: InformationEventService,
        private authorizationService: AuthorizationService,
        public financingService: FinancingService,
    ) {
        this.groupChat$ = this.store.select(MessagesState.groupChat)
            .pipe(
                filter(messages => Array.isArray(messages)),
                map(messages => (messages as IMessage[]).map(msg => ({
                    ...msg,
                    text: this.domSanitziser.sanitize(SecurityContext.HTML, msg.text),
                }))),
                map(messages => messages.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())),
            ) as Observable<IMessage[]>;

        this.internalChat$ = this.store.select(MessagesState.internalChat)
            .pipe(
                filter(messages => Array.isArray(messages)),
                map(messages => (messages as IMessage[]).map(msg => ({
                    ...msg,
                    text: this.domSanitziser.sanitize(SecurityContext.HTML, msg.text),
                }))),
                map(messages => messages.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())),
            ) as Observable<IMessage[]>;
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.params.pipe(
            takeUntil(this.viewLeft$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            tap(id => {
                this.financingMapId = id;
                this.loading = true;
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            mergeMap(id => forkJoin([
                this.financingService.loadFinancingContainer(id),
                this.financingService.getMainDebitor(id),
                this.messagesService.getMessages(id),
            ])),
            tap(([finprocessContainer, mainDebitor]) => {
                if (!!finprocessContainer) {
                    this.finprocessContainer$.next(finprocessContainer);
                }

                const debitor: IDebtorNamesOverwrite = {
                    firstNameOverwrite: mainDebitor ? OverwriteHelperService.getMergedOverwriteValue(mainDebitor, 'firstName', mainDebitor.firstName) : '',
                    lastNameOverwrite: mainDebitor ? OverwriteHelperService.getMergedOverwriteValue(mainDebitor, 'lastName', mainDebitor.lastName) : '',
                }
                this.mainDebitor$.next(debitor);
                
            }),
        ).subscribe(([finprocessContainer]) => {
            this.loading = false;
            this.isMandantTypeFinService = finprocessContainer?.mandantType === MandantType.BAF;
            if (this.isMandantTypeFinService) {
                this.selectedTabIdx = 1;
                this.isInternal.next(true);
            }

            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });

        
        this.chatForm = new FormGroup({ txArea: new FormControl() });

        combineLatest([
            this.finprocessContainer$,
            this.store.select(InformationEventState.allInformationEvents),
        ]).pipe(
            takeUntil(this.viewLeft$),
            filter(([finprocessContainer]) => finprocessContainer?.users?.some(usr => usr.id === this.userService.user?.id && HelperService.hasBit(usr.roles, Role.Expert)) || finprocessContainer.temporaryUser?.id === this.userService.user?.id),
            mergeMap(([finprocessContainer, informationEvents]) => {
                if (!Array.isArray(informationEvents) || informationEvents.some(event => event.financingId !== finprocessContainer.id)) {
                    return this.informationEventService.loadInformationEvent(finprocessContainer.id).pipe(map(
                        newEvents => [finprocessContainer, newEvents ?? []] as const,
                    ));
                }

                return of([finprocessContainer, informationEvents] as const);
            }),
        ).subscribe(([container, informationEvents]) => {
            const unreadEventIds = informationEvents.filter(event => event.eventType === FinancingEventType.MessageReceived)
                .reduce((unreadEvents, currentEvent) => unreadEvents.concat(currentEvent.events.filter(subEvent => !subEvent.isRead).map(subEvent => subEvent.id)), [] as UUID[]);

            if (unreadEventIds.length > 0) {
                this.informationEventService.changeReadStatus({
                    eventIds: unreadEventIds,
                    finProcessContainerId: container.id,
                }, true);
            }
        });

        combineLatest([
            this.finprocessContainer$,
            this.isInternal,
        ]).pipe(
            takeUntil(this.viewLeft$),
            map(([financing, isInternal]) => {
                const isEditor = financing.users?.some(usr => usr.id === this.userService.user?.id && HelperService.hasBit(usr.roles, Role.Expert)) || financing.temporaryUser?.id === this.userService.user?.id;

                if (financing === undefined || !isEditor) {
                    return true;
                }
    
                if ((financing.status === FinancingStatus.Rejected && financing.subStatus === FinancingSubStatus.AutomaticallyRejected) || (financing.status === FinancingStatus.Canceled && !isInternal)) {
                    return true;
                }
                return false;
            }),
        ).subscribe(readonly => {
            this.isReadonlyInternal = readonly;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
        });
       
        this.exportDisabled$ = this.authorizationService.hasRole$(Role.FinancingMapsEditor).pipe(takeUntil(this.viewLeft$), map(hasRole => !hasRole));
        this.editDisabled$ = this.authorizationService.hasRole$(Role.Expert).pipe(takeUntil(this.viewLeft$));
    }



    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
        this.viewLeft$.complete();

        this.store.dispatch(new ClearMessages());
    }

    /**
     * setzt den Fokus auf true
     */
    public onFocus(): void {
        this.focusState = true;
    }

    /**
     * setzt den Fokus auf false
     */
    public onBlur(): void {
        this.focusState = false;
    }

    /**
     * Textfeld leeren
     */
    public onDelete(): void {
        if (!!this.chatForm) {
            this.chatForm?.reset();
            this.isInputShown = false;
        }
    }

    /**
     * Wenn Tab sich ändert
     *
     * @param {MatTabChangeEvent} event MatTabChange Event
     */
    public onTabChanged(event: MatTabChangeEvent): void {
        this.isInputShown = false;
        if (!this.isMandantTypeFinService) {
            if (event.index === 0) {
                this.isInternal.next(false);
            } else {
                this.isInternal.next(true);
            }
        }
    }

    /**
     * Nachricht speichern
     */
    public onSend(): void {
        this.isSendBtnActive = false;

        if (!!this.chatForm) {

            const text = this.chatForm.get('txArea')?.value

            this.receiver = this.isInternal.value ? this.receiverInternalChat : this.receiverGroupChat;

            const msg: Partial<IMessage> = {
                financingMapId: this.financingMapId,
                text: text,
                to: HelperService.hasValue(this.receiver) ? this.receiver : undefined,
            };

            this.messagesService.sendMessage(msg).pipe(
                take(1),
                tap(() => {
                    this.isSendBtnActive = true;
                }),
            ).subscribe(() => {
                this.chatForm?.reset();
            });
        }
        this.isInputShown = false;
    }

    /**
     * Lädt den Nachrichtenverlauf herunter
     */
    public onDownload(): void {
        this.waiterService.show()

        const s = this.messagesService.getMessageExport(String(this.financingMapId), this.isInternal.value).subscribe(
            async result => {
                if (!result) {
                    return;
                }
                await HelperService.downloadFileFromBlob(result, 'Nachrichtendownload');
                this.waiterService.hide();
            },
            () => {
                s.unsubscribe;
            });
    }

    /**
     * Suchdialog anzeigen
     */
    public openSearchDialog(): void {
        const dialogRef = this.dialog.open(SearchMessagesDialogComponent, {
            minWidth: '70%',
        });
        dialogRef.afterClosed().subscribe(searchterm => {
            if (this.selectedTabIdx === 0) {
                this.runSearch(this.groupChat$, searchterm)
            }

            if (this.selectedTabIdx === 1) {
                this.runSearch(this.internalChat$, searchterm)
            }

        });
    }

    /**
     * Durchsucht die nachrichten nach dem suchbegriff
     *
     * @param {Observable<IMessage[] | undefined>} chat Ausgewählter chat
     * @param {string} searchterm Suchbegriff
     */
    public runSearch(chat: Observable<IMessage[] | undefined>, searchterm: string): void {
        chat.subscribe(messages => {
            if (messages) {
                const indices = [];
                for (const [index, it] of messages.entries()) {
                    const safeHtml = this.domSanitziser.sanitize(SecurityContext.HTML, it.text);
                    if (safeHtml?.indexOf(searchterm) !== -1) {
                        indices.push(index)
                    }
                }
                const itemToScrollTo = document.getElementById(`message-${indices[0]}`);
                if (itemToScrollTo) {
                    itemToScrollTo.scrollIntoView(true);
                }
            }
        });
    }

    /**
     * Schaltet die Editierfunktion frei
     */
    public startEdit(): void {
        if (this.selectedTabIdx === 0) {
            this.notificationService.alert(
                this.translate.instant('messages.editWarningTitle'),
                this.translate.instant('messages.editWarningContent'),
            ).subscribe(result => {
                if (result === 'submit') {
                    this.isInputShown = true;
                    this.cRef.detectChanges();
                }

            });
        }
        if (this.selectedTabIdx === 1) {
            this.isInputShown = true;
        }
    }

}
