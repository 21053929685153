import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CalculationHelperService } from '@ntag-ef/finprocess-calculations';
import { ProductPackageType } from '@ntag-ef/finprocess-enums';
import { FinprocessFormArray, FinprocessFormControl, FinprocessFormGroup, FormProviderToken, VisibilityMap, createFormGroup, createProviderInput, createRootFormGroup } from '@ntag-ef/finprocess-forms';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { FinancingMode, FinancingService, FinancingState, IFinancing, IFinancingStateParentDefinition, IProductPackageData, InterestMethodVariable, RepaymentPeriod } from 'app/modules/financing/data';
import { IProductPackageItems } from 'app/modules/financing/data/interfaces/product-package-items.interface';
import { IProductPackage } from 'app/modules/financing/data/interfaces/product-package.interface';
import { OverwriteHelperService } from 'app/modules/financing/util/services';
import { HelperService, IsoDate, ProductPackageStatus, UUID } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { EMPTY, Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, forkJoin, map, mergeMap, startWith, switchMap, take, takeUntil, tap, throwError } from 'rxjs';

import { AddProductPackageDialogComponent, ProductPackageDescriptionDialogComponent } from '..';


const PRODUCT_PACKAGE: FormProviderToken<IProductPackage> = new FormProviderToken('product.productPackage');
const PRODUCT: FormProviderToken<IProductPackageItems> = new FormProviderToken('product.product');

const productForm = createFormGroup({
    accountFee: null,
    addition: null,
    additionForComparativeAnalysis: null,
    creditAmount: {
        validator: createProviderInput({
            providers: PRODUCT,
            fn: product => (product.productCreditType !== ProductPackageType.Guarantee ? Validators.compose([Validators.required, Validators.min(1)]) : null),
        }),
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    creditSpecialFinancingSum: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing && !CalculationHelperService.isNullOrNaN(product.agreedInterestRateFollowUpOrSpecialFinancing) && product.agreedInterestRateFollowUpOrSpecialFinancing > 0,
        }),
    },
    decisionArea: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit,
        }),
    },
    deviatingEURInterestRate: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithoutFollowUpFinancing,
        }),
    },
    deviatingFictionalFixInterestRate: {
        visible: createProviderInput({
            providers: [],
            fn: () => false,
        }),
    },
    deviatingFictionalFixRateOverDuration: {
        visible: createProviderInput({
            providers: PRODUCT_PACKAGE,
            fn: productPackage => productPackage.status === ProductPackageStatus.DecisionFailed,
        }),
    },
    agreedInterestRateFollowUpOrSpecialFinancing: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing,
        }),
    },
    deviatingFictionalInterestRate: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.withoutCoverageRegister && product.productCreditType !== ProductPackageType.ComfortCredit,
        }),
    },
    deviatingInterestRate: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit || (product.productCreditType === ProductPackageType.ComfortCredit && product.withoutCoverageRegister),
        }),
    },
    deviatingInterestRateSubsidizedLoan: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.subsidizedLoan === true,
        }),
    },
    durationWithoutASD: {
        validator: createProviderInput({
            providers: PRODUCT,
            fn: product => (product.productCreditType !== ProductPackageType.Guarantee ? Validators.compose([Validators.required, Validators.min(12), Validators.max(408)]) : null),
        }),
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    extraASDPeriod: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit || product.productCreditType === ProductPackageType.ComfortCredit,
        }),
    },
    fictionalEURInterestRate: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    fictionalInterestRateFix25Years: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    guaranteeAmount: {
        validator: createProviderInput({
            providers: PRODUCT,
            fn: product => (product.productCreditType === ProductPackageType.Guarantee ? Validators.compose([Validators.required, Validators.min(1)]) : null),
        }),
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Guarantee,
        }),
    },
    guaranteeSum: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Guarantee,
        }),
    },
    interestOnlyPeriod: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit || product.productCreditType === ProductPackageType.ComfortCredit,
        }),
        validator: createProviderInput({
            providers: PRODUCT,
            fn: product => Validators.max(Math.min(408, product.durationWithoutASD ?? 0)),
        }),
    },
    interestReferenceRate: null,
    indicator: null,
    kimVRate: {
        validator: createProviderInput({
            providers: PRODUCT,
            fn: product => (product.productCreditType !== ProductPackageType.Guarantee ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    productCreditType: null,
    productID: null,
    ratesCount: null,
    repaymentDate: null,
    repaymentPeriod: null,
    riskAmount: null,
    specialFinancing: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing || product.productCreditType === ProductPackageType.Guarantee,
        }),
    },
    specialFinancingDuration: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing,
        }),
    },
    specialFinancingLTV: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing && !CalculationHelperService.isNullOrNaN(product.specialFinancing) && product.specialFinancing > 0,
        }),
    },
    subsidizedLoan: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit,
        }),
    },
    variableRate: null,
    variableInterest: null,
    variableInterestFix5Year: null,
    variableInterestFix10Year: null,
    variableInterestFix15Year: null,
    variableInterestFix20Year: null,
    variableInterestFix25Year: null,
    variableRealInterestRate: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType !== ProductPackageType.Guarantee,
        }),
    },
    withoutCoverageRegister: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: product => product.productCreditType === ProductPackageType.Credit || product.productCreditType === ProductPackageType.ComfortCredit,
        }),
    },
    processingFee: null,
    valuationFee: null,
    sumAccountFee: null,
    legalFee: null,
    brokerage: null,
    landRegisterEntryFee: null,
    priorityRankingFee: null,
    landRegisterRecord: null,
    landRegisterApplication: null,
    externalValuationFee: null,
    disbursementDate: null,
    position: null,
    sampleCalculationSelectedOption: null,
}, PRODUCT);

const productPackageForm = createRootFormGroup({
    name: null,
    description: null,
    assignProducts: null,
    assignedSampleCalculations: null,
    position: null,
    grossFinancingProductPackages: null,
    fireResultDocument: null,
    isRiskDecisionFromApi: null,
    isApproved: null,
    id: null,
    approver: null,
    inApprovalPolicies: null,
    productPackageID: null,
    status: null,
    statusEntries: null,
}, PRODUCT_PACKAGE, (parent?: IProductPackage) => ({
    assignProducts: new FinprocessFormArray((parent?.assignProducts ?? []).map(product => productForm(product)), {
        validator: Validators.required,
    }),
}));

/**
 * Validator für Darlehen
 * 
 * @param {number} creditSum Maximale Darlehenssume
 * @returns {Function} Validatorfunktion
 */
function creditValidator(creditSum?: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control instanceof FinprocessFormGroup && Array.isArray(control.value.assignProducts)) {
            const credits = control.value.assignProducts.filter(product => product.productCreditType === ProductPackageType.Credit);
            const creditSumActual = credits.reduce((sum, credit) => sum + (credit.creditAmount ?? 0), 0);

            if (creditSumActual !== (creditSum ?? 0)) {
                return { creditSum: { max: creditSum, actual: creditSumActual } };
            }
        }
        return null;
    }
}

/**
 * Validator für Ergänzunsdarlehen
 * 
 * @param {number} comfortCreditSum Maximale Ergänzungsdarlehenssumme
 * @returns {Function} Validatorfunktion
 */
function comfortCreditValidator(comfortCreditSum?: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control instanceof FinprocessFormGroup && Array.isArray(control.value.assignProducts)) {
            const comfortCredits = control.value.assignProducts.filter(product => product.productCreditType === ProductPackageType.ComfortCredit);
            const comfortCreditSumActual = comfortCredits.reduce((sum, credit) => sum + (credit.creditAmount ?? 0), 0);

            if (comfortCreditSumActual !== (comfortCreditSum ?? 0)) {
                return { comfortCreditSum: { max: comfortCreditSum, actual: comfortCreditSumActual } };
            }
        }
        return null;
    }
}

/**
 * Validator für Garantien
 * 
 * @param {number} guaranteeSum Maximale Garantiesumme
 * @returns {Function} Validatorfunktion
 */
function guaranteeValidator(guaranteeSum?: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control instanceof FinprocessFormGroup && Array.isArray(control.value.assignProducts)) {
            const guarantees = control.value.assignProducts.filter(product => product.productCreditType === ProductPackageType.Guarantee);
            const guaranteeSumActual = guarantees.reduce((sum, credit) => sum + (credit.guaranteeAmount ?? 0), 0);

            if (guaranteeSumActual !== (guaranteeSum ?? 0)) {
                return { guaranteeSum: { max: guaranteeSum, actual: guaranteeSumActual } };
            }
        }
        return null;
    }
}

/**
 * Validator für Zwischenfinanzierung
 * 
 * @param {number} interimFinancingSum Maximale Zwischenfinanzierungssumme
 * @returns {Function} Validatorfunktion
 */
function interimFinancingValidator(interimFinancingSum?: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
        if (control instanceof FinprocessFormGroup && Array.isArray(control.value.assignProducts)) {
            const interimFinancings = control.value.assignProducts.filter(product => product.productCreditType === ProductPackageType.BWWithFollowUpFinancing || product.productCreditType === ProductPackageType.BWWithoutFollowUpFinancing);
            const interimFinancingSumActual = interimFinancings.reduce((sum, credit) => sum + (credit.creditAmount ?? 0), 0);

            if (interimFinancingSumActual !== (interimFinancingSum ?? 0)) {
                return { interimFinancingSum: { max: interimFinancingSum, actual: interimFinancingSumActual } };
            }
        }
        return null;
    }
}


const productPackageFormWithValidators = (
    productPackage: IProductPackage,
    maximumCreditSum: number | undefined,
    maximumComfortCreditSum: number | undefined,
    maximumGuaranteeSum: number | undefined,
    maximumInterimFinancingSum: number | undefined,
) => productPackageForm(productPackage, {
    validator: Validators.compose([creditValidator(maximumCreditSum), comfortCreditValidator(maximumComfortCreditSum), guaranteeValidator(maximumGuaranteeSum), interimFinancingValidator(maximumInterimFinancingSum)]),
});

/**
 * VisibilityMap Interfaces
 */
export interface IProductPackageView extends Omit<IProductPackage, 'assignProducts'> {
    assignProducts: IProductWithVisibility[];
}

export interface IProductWithVisibility {
    product: IBaseProduct;
    visibilityMap: VisibilityMap<IProductPackageItems>;
}

export interface IBaseProduct {
    productID: UUID;
    productCreditType: ProductPackageType;
}

/**
 * interface data to create a new product package
 */
export interface ICreateNewProductPackage {
    financingMapId: string,
    productPackageType: ProductPackageType
}

/**
 * interface data to create a new product
 */
export interface ICreateNewProductPackageItem {
    productPackageId: string;
    productCreditType: ProductPackageType;
}

/**
 * interface data to update a product package
 */
export interface IUpdateProductPackage {
    productPackageID: string;
    description: string;
    packageName: string;

    //Values für Finanzierungsdetails
    grossFinancingProductPackages: number;
}

/**
 * interface data to update a product
 */
export interface IUpdateProductPackageItem {
    productPackageID: string,
    productID: UUID;
    productCreditType: ProductPackageType;
    creditAmount: number;
    guaranteeAmount: number;
    interestOnlyPeriod: number;
    durationWithoutASD: number;
    extraASDPeriod: number;
    specialFinancing: number;
    specialFinancingDuration: number;
    specialFinancingLTV: boolean;
    guaranteeSum: number;
    kimVRate: number;
    withoutCoverageRegister: boolean;
    subsidizedLoan: boolean;
    deviatingInterestRate: number;
    deviatingFictionalInterestRate: number;
    deviatingInterestRateSubsidizedLoan: number;
    deviatingEURInterestRate: number;
    deviatingFictionalFixInterestRate: number;
    agreedInterestRateFollowUpOrSpecialFinancing: number;
    creditSpecialFinancingSum: number;
    decisionArea: string;
    deviatingFictionalFixRateOverDuration: number;

    //Values für Finanzierungsdetails
    disbursementDate: IsoDate;
    repaymentPeriod: RepaymentPeriod | 0;
    ratesCount: number;
    repaymentDate: IsoDate;
    accountFee: number;
    indicator: InterestMethodVariable | 0;
    interestReferenceRate: number;
    addition: number;
    variableRate: number;
    additionForComparativeAnalysis: number;
    variableInterest: number;
    variableInterestFix5Year: number;
    variableInterestFix10Year: number;
    variableInterestFix15Year: number;
    variableInterestFix20Year: number;
    variableInterestFix25Year: number;

    riskAmount: number;
    processingFee: number;
    valuationFee: number;
    sumAccountFee: number;
    legalFee: number;
    brokerage: number;
    landRegisterEntryFee: number;
    priorityRankingFee: number;
    landRegisterRecord: number;
    landRegisterApplication: number;
    externalValuationFee: number;
    fictionalEURInterestRate: number;
    variableRealInterestRate: number;
    fictionalInterestRateFix25Years: number;
}

/**
 * product package component
 */
@Component({
    selector: 'finprocess-product-package',
    templateUrl: './product-package.component.html',
    styleUrls: ['./product-package.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('500ms', style({ opacity: 0 })),
            ]),
        ]),
    ],

})
export class ProductPackageComponent implements OnInit, OnDestroy, AfterViewChecked {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ProductPackageStatus = ProductPackageStatus;

    public productPackageForms: FinprocessFormGroup<IProductPackage>[] = [];

    /**
     *  Number Mask
     */
    public numberMaskOptions: NgxCurrencyConfig;

    /**
     * Number Mask for extra ASD
     */
    public numberMaskOptionsASD: NgxCurrencyConfig;

    /**
     *  Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     *  percentage Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;


    /**
     * product package type
     */
    public productPackageType: ProductPackageType[] = [];

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ProductPackageType = ProductPackageType;

    /**
     * Id of the current financing plan
     */
    public financingPlanId?: string;

    //Subject beim Entfernen der Komponente
    public onDestroy$ = new Subject<void>();

    /**
     * Neuer kurzfristiger Finanzierungsbedarf (autom. berechnet Ergänzungsdarlehen)
     */
    @Select(FinancingState.comfortCreditPlus)
    public comfortCreditPlus$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Langfristiger Finanzierungsbedarf Brutto Selector - neu berechnet
     */
    @Select(FinancingState.newCalculatedgrossFinancingRequirement)
    public newCalculatedgrossFinancingRequirement$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Neuer kurzfristiger Finanzierungsbedarf (autom. berechnet Ergänzungsdarlehen)
     */
    public comfortCreditPlus = 0;

    /**
     * langfristiger Finanzierungsbedarf - Finanzierungsrahmen
     */
    public grossFinancingRequirement = 0;

    /**
     * Zwischenfinanzierung
     */
    public bridgingFinancing = 0;

    /**
     * Garantiebetrag
     */
    public guaranteeAmount = 0;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    private fieldReadonly = true;

    /**
     * Gesamtobligo
     */
    public totalCommitment?: number;

    /**
     * Sammelobjekt um Subscriptions für Produkte zu beenden
     */
    public productSubscriptions: Record<string, Subject<void>> = {};

    /**
     * Sammelobjekt um Subscriptions für Produkt-Pakete zu beenden
     */
    public productPackageSubscriptions: Record<string, Subject<void>> = {};

    /**
     * Ladezustand der Komponente
     */
    public loading = false;

    /**
     * Produktpackages
     */
    public productPackages: IProductPackageData | undefined;

    /**
     * selected product tab index for every product package
     */
    public selectedProductTabIndexes: Array<number> = [];

    /**
     * tabs animation duration
     */
    public tabAnimationDuration: string = '500ms';

    /**
     * ViewChildren für die Assets
     */
    @ViewChildren('addedproductpackages') public addedproductpackages?: QueryList<ElementRef>;

    /**
     * Flag, ob ein neues Asset hinzugefügt wurde
     */
    public newlyAdded = false;

    /**
     * Konstruktor
     *
     * @param {MatDialog} dialog dialog
     * @param {string} locale locale
     * @param {ChangeDetectorRef} changeDetec change detector ref
     * @param {FinancingService} financingService financing service
     * @param {Store} store store
     * @param {NotificationService} notificationService notificationservice
     * @param {WaiterService} waiterService waiter service
     * @param {TranslateService} translate translate
     * @param {Router} router router
     * @param {ActivatedRoute} activatedRoute activated route
     */
    public constructor(private dialog: MatDialog, @Inject(LOCALE_ID) locale: string, private changeDetec: ChangeDetectorRef,
        private financingService: FinancingService, private store: Store, private notificationService: NotificationService,
        private waiterService: WaiterService, private translate: TranslateService, private router: Router,
        private activatedRoute: ActivatedRoute) {

        this.numberMaskOptions = HelperService.getInputMask(locale, {
            precision: 0,
            thousands: '',
            decimal: '',
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.numberMaskOptionsASD = HelperService.getInputMask(locale, {
            precision: 0,
            thousands: '',
            decimal: '',
            max: 24,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.productPackageType = (HelperService.getEnumArray(ProductPackageType) as number[]);

        this.activatedRoute.paramMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => {
                const riskFinancingPlanId = params.get('riskfinancingplanid');

                if (!riskFinancingPlanId) {
                    throw new Error('No risk financing plan id provided');
                }

                this.financingPlanId = riskFinancingPlanId;
                return riskFinancingPlanId;
            }),
            distinctUntilChanged(),
            tap(() => { this.loading = true; }),
            mergeMap(riskFinancingPlanId => this.store.select((it: IFinancingStateParentDefinition) => it.financing.financing).pipe(
                filter((financing): financing is IFinancing => financing !== undefined && financing.id === riskFinancingPlanId),
                take(1),
                tap(financing => this.initializeCreditAmounts(financing)),
            )),
            mergeMap(financing => forkJoin([
                this.financingService.getAllProductPackages(financing.id),
                this.financingService.editingReadonlyWithEditmodeExpert$.pipe(take(1)),
            ])),
        ).subscribe({
            next: ([productPackages, readonly]) => {
                this.productPackages = productPackages;
                this.productPackages?.assignProductPackages.forEach(() => {
                    this.selectedProductTabIndexes.push(0);
                });
                this.fieldReadonly = readonly;
                this.initializeForms(this.productPackages?.assignProductPackages ?? []);
                this.loading = false;
            },
            error: () => {
                this.loading = false;
            },
        });

        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$.pipe(
            tap(disabled => {
                this.fieldReadonly = disabled;

                if (disabled) {
                    for (const productPackage of this.productPackageForms) {
                        productPackage.disable({ emitEvent: false, onlySelf: true });
                    }
                } else {
                    for (const productPackage of this.productPackageForms) {
                        productPackage.enable({ emitEvent: false, onlySelf: true });

                        for (const product of productPackage.controls.assignProducts.controls) {
                            this.disableProductCheckboxes(product.controls.subsidizedLoan, product.controls.subsidizedLoan.value ?? false, product.controls.withoutCoverageRegister, product.controls.withoutCoverageRegister.value ?? false);
                        }
                    }
                }
            }),
        );
    }


    /**
     * Retreives the product packages and updates the forms
     * 
     * @returns {Observable<IProductPackageData>} Product packages
     */
    public getProductPackages(): Observable<IProductPackageData> {
        if (!this.financingPlanId) {
            return throwError(() => ('No risk financing plan id provided'));
        }

        return this.financingService.getAllProductPackages(this.financingPlanId).pipe(
            filter((productPackages): productPackages is IProductPackageData => productPackages !== undefined),
            tap(productPackages => {
                this.initializeForms(productPackages.assignProductPackages);
            }),
        );
    }

    /**
     * Initializes the credit amounts displayed on the top of the page
     * 
     * @param {IFinancing} financing Current financing
     */
    public initializeCreditAmounts(financing: IFinancing): void {
        //Garantiebetrag
        this.guaranteeAmount = OverwriteHelperService.getMergedOverwriteValue(financing, 'guaranteeAmount', financing.guaranteeAmount) ?? 0;

        //zwischenfinanzierung -> für B&W Konten
        const activeBridingFinancing = OverwriteHelperService.getMergedOverwriteValue(financing, 'prefinancingSales', financing.prefinancingSales);

        if (activeBridingFinancing) {
            this.bridgingFinancing = OverwriteHelperService.getMergedOverwriteValue(financing, 'salesRevenue', financing.salesRevenue) ?? 0;
        }

        this.comfortCreditPlus = this.store.selectSnapshot(FinancingState.sumPrefinancing)(true) ?? 0;
        this.grossFinancingRequirement = this.store.selectSnapshot(FinancingState.newCalculatedgrossFinancingRequirement)(true) ?? 0;

        if (this.guaranteeAmount > this.grossFinancingRequirement) {
            const newGuaranteeAmount = this.guaranteeAmount - this.grossFinancingRequirement;
            this.totalCommitment = this.grossFinancingRequirement + this.bridgingFinancing + this.comfortCreditPlus + newGuaranteeAmount;
        } else if (this.guaranteeAmount <= this.grossFinancingRequirement) {
            this.totalCommitment = this.grossFinancingRequirement + this.bridgingFinancing + this.comfortCreditPlus;
        }
    }

    /**
     * Initializes the product package forms
     * 
     * @param {IProductPackage[]} productPackages Product packages
     */
    public initializeForms(productPackages: IProductPackage[]) {
        this.unsubscribeAllProducts();
        this.productPackageForms = productPackages.map(
            productPackage => {
                const form = productPackageFormWithValidators(productPackage, this.grossFinancingRequirement, this.comfortCreditPlus, this.guaranteeAmount, this.bridgingFinancing);
                form.updateValueAndValidity();

                if (this.fieldReadonly === true) {
                    form.disable({ emitEvent: false, onlySelf: true });
                }
                else {
                    form.enable({ emitEvent: false, onlySelf: true });
                }

                const productPackageSubject$ = new Subject<void>();
                this.productPackageSubscriptions[productPackage.productPackageID] = productPackageSubject$;

                form.controls.name.valueChanges.pipe(distinctUntilChanged()).pipe(
                    takeUntil(productPackageSubject$),
                    debounceTime(500),
                ).subscribe(() => {
                    if (form.controls.name.valid) {
                        this.updateProductPackage(form.getRawFinprocessValue(), false);
                    }
                });

                for (const product of form.controls.assignProducts.controls) {
                    const productSubject$ = new Subject<void>();
                    const subsidizedLoan = product.controls.subsidizedLoan;
                    const withoutCoverageRegister = product.controls.withoutCoverageRegister;
                    this.productSubscriptions[product.controls.productID.value ?? ''] = productSubject$;

                    combineLatest([
                        subsidizedLoan.valueChanges.pipe(startWith(subsidizedLoan.value)),
                        withoutCoverageRegister.valueChanges.pipe(startWith(withoutCoverageRegister.value))]).pipe(
                        takeUntil(productSubject$),
                    ).subscribe(([subsidizedLoanValue, withoutCoverageRegisterValue]) => {
                        this.disableProductCheckboxes(subsidizedLoan, subsidizedLoanValue ?? false, withoutCoverageRegister, withoutCoverageRegisterValue ?? false);
                    });

                    product.valueChanges.pipe(
                        takeUntil(productSubject$),
                        debounceTime(500),
                    ).subscribe((() => {
                        if (product.valid) {
                            this.updateProduct(product.getRawFinprocessValue(), productPackage.id);
                        }
                    }));
                }
                return form;
            },
        );
    }

    /**
     * updates the disabled attribute of the subsidizedLoan checkbox and the withoutCoverageRegister checkbox depending on what is checked
     * 
     * @param {FinprocessFormControl} subsidizedLoan subsidizedLoan form control
     * @param {boolean} subsidizedLoanValue subsidizedLoan value
     * @param {FinprocessFormControl} withoutCoverageRegister withoutCoverageRegister form control
     * @param {boolean} withoutCoverageRegisterValue withoutCoverageRegister value
     */
    private disableProductCheckboxes(subsidizedLoan: FinprocessFormControl<boolean>, subsidizedLoanValue: boolean, withoutCoverageRegister: FinprocessFormControl<boolean>, withoutCoverageRegisterValue: boolean) {
        if (subsidizedLoanValue === withoutCoverageRegisterValue) {
            subsidizedLoan.enable({ emitEvent: false, onlySelf: true });
            withoutCoverageRegister.enable({ emitEvent: false, onlySelf: true });
        } else if (subsidizedLoanValue) {
            withoutCoverageRegister.disable({ emitEvent: false, onlySelf: true });
        } else if (withoutCoverageRegisterValue) {
            subsidizedLoan.disable({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * adding a new product package or product
     *
     * @param {boolean} packageOrProduct product package oder product element
     * @param {string} productPackageID productPackageID
     * @param {number} productPackageIndex productPackageIndex
     * @param {number} productsCount productsCount
     */
    public openProductPackageDialog(packageOrProduct: boolean, productPackageID?: string | null, productPackageIndex?: number, productsCount?: number) {
        if (this.fieldReadonly) {
            return;
        }


        const dialogRef = this.dialog.open(AddProductPackageDialogComponent, {
            width: '30%',
            data: {
                grossFinancingRequirement: this.grossFinancingRequirement,
                comfortCreditPlus: this.comfortCreditPlus,
                bridgingFinancing: this.bridgingFinancing,
                existingProductPackages: this.productPackageForms.map(form => form.getRawFinprocessValue()),
                productPackageID: productPackageID,
                guaranteeAmount: this.guaranteeAmount,
                isProductPackage: packageOrProduct,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                // Add a new product package
                if (packageOrProduct === true) {
                    this.addProductPackage(result.selection);
                }
                // Add a new product to an existing product package
                else {
                    if (!!productPackageID) {
                        this.addProduct(productPackageID, result.selection, productPackageIndex!, productsCount!);
                    }
                }
            }
        });
    }

    /**
     * remove product from product package
     * 
     * @param {string} productId productId
     * @param {number} productPackageIndex productPackageIndex
     * @param {number} productIndex productIndex
     */
    public deleteProduct(productId?: string | null, productPackageIndex: number = 0, productIndex: number = 0) {
        if (!productId || this.fieldReadonly) {
            return;
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.deleteProduct(productId)),
            mergeMap(() => this.getProductPackages()),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.tabAnimationDuration = '0ms';
                this.selectedProductTabIndexes[productPackageIndex] = productIndex - 1;
                setTimeout(() => {
                    this.tabAnimationDuration = '500ms';
                }, 0);
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.deleteProductError'));
            },
        });
    }

    /**
     * add product to product package
     *
     * @param {string} productPackageID productPackageID
     * @param {ProductPackageType} productType productType
     * @param {number} productPackageIndex productPackageIndex
     * @param {number} productsCount productsCount
     */
    public addProduct(productPackageID: string, productType: ProductPackageType, productPackageIndex: number, productsCount: number) {

        const newProduct: ICreateNewProductPackageItem = {
            productPackageId: productPackageID,
            productCreditType: productType,
        }

        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.addNewProduct(newProduct)),
            mergeMap(() => this.getProductPackages()),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.tabAnimationDuration = '0ms';
                this.selectedProductTabIndexes[productPackageIndex] = productsCount;
                setTimeout(() => {
                    this.tabAnimationDuration = '500ms';
                }, 0);
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProduct'));
            },
        });
    }

    /**
     * Adds a new product package with one product of the given type
     * 
     * @param {ProductPackageType} type Type for the first product
     */
    public addProductPackage(type: ProductPackageType): void {
        if (!this.financingPlanId || this.fieldReadonly) {
            return;
        }

        const newPackage: ICreateNewProductPackage = { financingMapId: this.financingPlanId, productPackageType: type };
        this.waiterService.show().pipe(
            mergeMap(() => this.financingService.addNewProductPackage(newPackage)),
            mergeMap(() => this.getProductPackages()),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.newlyAdded = true;
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProduct'));
            },
        });
    }


    /**
     * delete product package
     * 
     * @param {string} productPackageID panelindex
     */
    public deleteProductPackage(productPackageID?: string | null) {
        if (!productPackageID || this.fieldReadonly) {
            return;
        }

        this.notificationService.confirmOkCancel(this.translate.instant('financing.features.financing-processing.productPackages.deleteProductPackage'), this.translate.instant('financing.features.financing-processing.productPackages.deleteProductPackageWarning')).pipe(
            mergeMap(result => {
                if (result !== 'submit') {
                    return EMPTY;
                }

                return this.waiterService.show().pipe(
                    mergeMap(() => this.financingService.deleteProductPackage(productPackageID)),
                    mergeMap(() => this.getProductPackages()),
                );
            }),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.deleteProductPackageError'));
            },
        });
    }

    /**
     * copy product package 
     * 
     * @param {string} productPackageID panel index
     */
    public copyProductPackage(productPackageID?: string | null) {
        if (!productPackageID) {
            return;
        }

        if (!!this.financingPlanId && !this.fieldReadonly) {
            this.financingService.copyProductPackage(productPackageID).pipe(
                take(1),
                switchMap(() => this.getProductPackages().pipe(take(1))),
            ).subscribe({
                next: () => {
                    this.changeDetec.detectChanges();
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.copyProduct'));
                },
            });
        }
    }

    /**
     * Adds or updates the description of a product package
     *
     * @param {IProductPackage} product Product package of which to change the description
     */
    public openDescriptionDialog(product: IProductPackage) {
        if (!this.fieldReadonly) {
            const dialogRef = this.dialog.open(ProductPackageDescriptionDialogComponent, {
                data: { previousDescription: product.description },
                width: '40%',
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.updateProductPackage({ ...product, description: result.newDescription }, true);
                }
            });
        }
    }

    /**
     * update product package name or description
     *
     * @param {IProductPackage} productPackage Product package
     * @param {boolean} updateDescription update description and reload pp to show description in dialog
     */
    public updateProductPackage(productPackage: IProductPackage, updateDescription: boolean) {
        const updateProductPackage: Partial<IUpdateProductPackage> = {
            productPackageID: productPackage.productPackageID,
            description: productPackage.description,
            packageName: productPackage.name,
        }

        this.financingService.updateProductPackage(updateProductPackage).pipe(
            take(1),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProductPackage'));
            },
            complete: () => {
                if (updateDescription === true) {
                    this.getProductPackages().subscribe();
                }
            },
        });
    }

    /**
     * Updates a product
     *
     * @param {IProductPackageItems} product Product
     * @param {string} productPackageId ID of the product package
     */
    public updateProduct(product: IProductPackageItems, productPackageId: string): void {
        if (!this.financingPlanId) {
            return;
        }

        product.creditSpecialFinancingSum = (product.creditAmount ?? 0) + (product.specialFinancing ?? 0);

        const ignoreKeys: Array<keyof IProductPackageItems> = ['decisionArea'];
        const booleanKeys: Array<keyof IProductPackageItems> = ['subsidizedLoan', 'withoutCoverageRegister', 'specialFinancingLTV'];
        const keys = (Object.keys(product) as [keyof typeof product]).filter(key => !ignoreKeys.includes(key));
        for (const key of keys) {
            if (product[key] === null) {
                product[key] = (booleanKeys.includes(key) ? false : 0) as never;
            }
        }

        this.financingService.updateProductPackageItem({ ...product, productPackageID: productPackageId }).pipe(take(1)).subscribe({
            next: () => {
                if (this.financingPlanId) {
                    this.financingService.getAllProductPackages(this.financingPlanId);
                }
            },
            error: () => {
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProduct'));
            },
        });

    }

    /**
     * track product package for animation
     *
     * @param {number} index index
     * @param {IProductPackage} item product package
     * @returns {string} productpackageID
     */
    public trackByFn(index: number, item: FinprocessFormGroup<IProductPackage>) {
        return item.controls.productPackageID.value;
    }

    /**
     * switch to risk desicion page
     *
     * @param {string} productPackageID productpackageID
     */
    public openRiskDecision(productPackageID?: string | null) {
        if (!productPackageID) {
            return;
        }

        this.router.navigate(['..', 'product-package-decision', productPackageID, 'collateral-details'], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan } }).catch(error => {
            this.notificationService.alert(this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpOpenError'), error);
        });
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     * Beendet alle Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();

        this.unsubscribeAllProducts();
    }

    /**
     * Unsubscribes all subscriptions for products and productpackages
     */
    private unsubscribeAllProducts(): void {
        for (const productSubscription of Object.values(this.productSubscriptions)) {
            productSubscription.next();
            productSubscription.complete();
        }

        for (const productPackageSubscription of Object.values(this.productPackageSubscriptions)) {
            productPackageSubscription.next();
            productPackageSubscription.complete();
        }
    }

    /**
     * Angular Lifecycle nach dem View geändert wurde
     */
    public ngAfterViewChecked() {
        if (this.newlyAdded) {
            this.scrollToLastAsset();
            this.newlyAdded = false;
        }
    }

    /**
     * Scrollt zum letzten Asset, wenn ein neues Asset hinzugefügt wurde
     */
    private scrollToLastAsset() {
        if (!!this.addedproductpackages) {
            const lastAsset = this.addedproductpackages.last;
            if (lastAsset && lastAsset.nativeElement) {
                lastAsset.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    /**
     * dispatch blur event for mat-checkbox when value changed, so that view adjustments are done immediately
     *
     * @param {any} event event
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public blur(event: any) {
        setTimeout(() => {
            event?.source?._inputElement?.nativeElement?.blur();
        }, 500);
    }
}
