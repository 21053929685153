import { AAGUID } from '../../types/aaguid';

export interface ISmartDocJobThumbnailContentLoadedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Eindeutiger Schlüssel zum Vorschaubild
     */
    thumbnailId: AAGUID;

    /**
     * Vorschaubild in Originalgröße
     */
    content: string;
}

/**
 * Klasse für Aktion
 */
export class SmartDocJobThumbnailContentLoaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocJobThumbnailContentLoaded';
    /**
     * Constructor
     *
     * @param {ISmartDocJobThumbnailContentLoadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocJobThumbnailContentLoadedPayloadType) { }
}
