import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

/**
 * Service zum Übersetzen des Paginator
 */
@Injectable()
export class FinProcessMatPaginatorIntlService implements MatPaginatorIntl {

    /**
     * Subject für Änderungen
     */
    public changes = new Subject<void>()

    /**
     * Label Einträge pro Seite
     */
    public itemsPerPageLabel: string;

    /**
     * Label Nächste Seite
     */
    public nextPageLabel: string;

    /**
     * Label Vorherige Seite
     */
    public previousPageLabel: string;

    /**
     * Label Erste Seite
     */
    public firstPageLabel: string;

    /**
     * Label Letzte Seite
     */
    public lastPageLabel: string;

    /**
     * Konstruktor
     *
     * @param {TranslateService} translateService TranslateService-Injektor
     */
    public constructor(private translateService: TranslateService) {
        this.itemsPerPageLabel = this.translateService.instant('paginator.itemsPerPageLabel');
        this.firstPageLabel = this.translateService.instant('paginator.firstPageLabel');
        this.lastPageLabel = this.translateService.instant('paginator.lastPageLabel');
        this.nextPageLabel = this.translateService.instant('paginator.nextPageLabel');
        this.previousPageLabel = this.translateService.instant('paginator.previousPageLabel');
        this.changes.next();

        this.translateService.get('paginator.itemsPerPageLabel').subscribe(translation => {
            this.itemsPerPageLabel = translation;
            this.changes.next();
        });
        this.translateService.get('paginator.firstPageLabel').subscribe(translation => {
            this.firstPageLabel = translation;
            this.changes.next();
        });
        this.translateService.get('paginator.lastPageLabel').subscribe(translation => {
            this.lastPageLabel = translation;
            this.changes.next();
        });
        this.translateService.get('paginator.nextPageLabel').subscribe(translation => {
            this.nextPageLabel = translation;
            this.changes.next();
        });
        this.translateService.get('paginator.previousPageLabel').subscribe(translation => {
            this.previousPageLabel = translation;
            this.changes.next();
        });

        this.translateService.get('paginator.rangeLabel').subscribe(() => {
            this.changes.next();
        });
    }

    /**
     * Liefert das Label für die Seitenanzeige
     *
     * @param {number} page Indexbasierte Seite
     * @param {number} pageSize Einträge pro Seite
     * @param {number} length Maximale Anzahl an Einträgen
     * @returns {string} Label für die Seitenanzeige
     */
    public getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return this.translateService.instant('paginator.rangeLabel', { page: 1, amountPages: 1 });
        }
        const amountPages = Math.ceil(length / pageSize);
        return this.translateService.instant('paginator.rangeLabel', { page: page + 1, amountPages });
    }
}
