<finprocess-two-column-layout leftClass="bg-dark-2" biggerSide="right">
    <div left fxFill class="information-events-container">
        <finprocess-information-events></finprocess-information-events>
    </div>
    <div right fxFill fxLayout="column">
        <div fxLayout="row" fxLayoutGap="12px" fxLayout.lt-lg="row wrap" fxLayoutAlign="start center"
            *ngIf="(currentTabSettings$ | async) as currentTabSettings">
            <div *ngIf="visibleDashboards.length > 0" fxFlex="1 0 auto">
                <mat-button-toggle-group (change)="dashboardChanged($event)" *ngIf="visibleDashboards.length >= 2">
                    <mat-button-toggle *ngFor="let dashboard of visibleDashboards" [ngClass]="dashboard.colorClass" [class.inactive]="dashboardType !== dashboard.type" [value]="dashboard.type">
                        <span *ngIf="dashboard.type === dashboardType">{{ dashboard.label }}</span>
                        <mat-icon *ngIf="dashboard.type !== dashboardType">dashboard</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <mat-form-field class="mat-form-field-transparent-background" appearance="fill" fxFlex="1 1 auto">
                <mat-icon matPrefix class="mr-2">filter_alt</mat-icon>
                <mat-select [panelWidth]="330" [value]="currentTabSettings.currentFilter"
                    (selectionChange)="filterStatus(currentTabSettings.tabId, $event)">
                    <mat-option *ngFor="let option of (currentFilterOptions$ | async)" [value]="option">
                        {{option | finprocessEnumTranslation : 'DashboardFilter'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *finprocessAuthorization="Role.FinancingMapsGlobalReader" class="mat-form-field-transparent-background" appearance="fill" fxFlex="1 1 auto">
                <mat-icon matPrefix class="mr-2">filter_alt</mat-icon>
                <mat-select  [panelWidth]="330" [(ngModel)]="selectedSalesPartnerCenter" multiple>
                    <mat-optgroup *ngFor="let group of salesPartnerCenterGroups" [label]="group.name" >
                        <mat-option #selectGroup (click)="selectOption(currentTabSettings.tabId, selectGroup)" [value]="group.salesPartnerCenterFilter">
                            <span *ngIf="group.salesPartnerCenterFilter !== SalesPartnerCenterFilter.MobilePF">
                                {{group.name}}
                                {{'dashboard.features.dashboard.selected' | translate}}
                            </span>
                            <span *ngIf="group.salesPartnerCenterFilter === SalesPartnerCenterFilter.MobilePF">
                                {{'dashboard.features.dashboard.selectAll' | translate}}
                            </span>
                        </mat-option>
                        <mat-option #selectSalesPartner (click)="selectOption(currentTabSettings.tabId, selectSalesPartner)" *ngFor="let salesPartnerCenter of group.salesPartnerCenters" [value]="salesPartnerCenter.id">
                            {{salesPartnerCenter.name}}
                            </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-form-field-transparent-background" appearance="fill" fxFlex="1 1 auto">
                <mat-icon matPrefix class="mr-2">search</mat-icon>
                <input name="search" matInput [ngModel]="currentTabSettings.currentSearch"
                    (ngModelChange)="delaySearch(currentTabSettings.tabId, $event)"
                    [placeholder]="'dashboard.features.dashboard.searchPlaceholder' | translate">
            </mat-form-field>
            <mat-form-field class="mat-form-field-transparent-background" appearance="fill" fxFlex="1 1 auto">
                <mat-icon matPrefix class="mr-2">swap_vert</mat-icon>
                <mat-select [panelWidth]="330" [value]="currentSorting" multiple [compareWith]="compareSortings"
                    (selectionChange)="updateSorting(currentTabSettings.tabId, currentSorting, $event.value)">
                    <mat-option *ngFor="let option of sortOptions" [value]="option">
                        {{ option.value |finprocessEnumTranslation : 'DashboardFinancingsSortValues'}}: {{ option.order | finprocessEnumTranslation : 'SortOrder'}}
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button (click)="updateSorting(currentTabSettings.tabId, currentSorting, []); $event.stopPropagation()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            
            <div class="pt-2" fxFlex="1 0 auto" fxFlex.lt-lg="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center center" fxLayoutGap="12px">
                <ng-container *ngIf="!isLiveEnvironment">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="toApiTestPage()">
                        API Test
                    </button>
                </ng-container>
                <ng-container *ngIf="(currentTabSettings.tabId === DashboardTab.All || currentTabSettings.tabId === DashboardTab.My) && dashboardType !== DashboardType.Approver">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="assignMe()">
                        {{'dashboard.features.dashboard.requestAssignment' | translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="currentTabSettings.tabId !== DashboardTab.All && currentTabSettings.tabId !== DashboardTab.My">
                    <button mat-raised-button color="primary" *finprocessAuthorization="Role.FinancingMapsEditor"
                        (click)="removeSubstitution(currentTabSettings.tabId)">
                        {{'dashboard.features.dashboard.endSubstitution' | translate}}
                    </button>
                </ng-container>
            </div>
        </div>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex$ | async">
            <ng-container *ngIf="dashboardType !== DashboardType.Approver">
                <mat-tab #tabAll *finprocessAuthorization="Role.FinancingMapsGlobalReader" [label]="DashboardTab.All">
                    <ng-template mat-tab-label>{{'dashboard.features.dashboard.allRequests' | translate}}</ng-template>
                    <finprocess-financing-list [tabId]="DashboardTab.All" *ngIf="tabAll.isActive" [loading]="loading" [dashboardType]="dashboardType">
                    </finprocess-financing-list>
                </mat-tab>
            </ng-container>
            <mat-tab #tabMy [label]="DashboardTab.My">
                <ng-template mat-tab-label>{{'dashboard.features.dashboard.myRequests' | translate}}</ng-template>
                <finprocess-financing-list [tabId]="DashboardTab.My" *ngIf="tabMy.isActive" [loading]="loading" [dashboardType]="dashboardType"></finprocess-financing-list>
            </mat-tab>
            <mat-tab *ngFor="let temporaryUserTab of temporaryUserTabs$ | async; let i = index" [label]="temporaryUserTab.tabId">
                <ng-template mat-tab-label>{{ temporaryUserTab.name }}</ng-template>
                <ng-template matTabContent>
                    <finprocess-financing-list [tabId]="temporaryUserTab.tabId" [loading]="loading" [dashboardType]="dashboardType"></finprocess-financing-list>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</finprocess-two-column-layout>
