import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const ADDRESS_HEADING_INFO_ANA_REQUEST: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'addressHeadingInfoAnaRequest',

    headingRow1: { type: WorkflowType.String },

    headingRow2: { type: WorkflowType.String },

    headingRow3: { type: WorkflowType.String },

    headingRow4: { type: WorkflowType.String },

    headingType: { type: WorkflowType.String },
}
