import { Component, InjectionToken, ViewEncapsulation } from '@angular/core';

export const UCBA_LIST = new InjectionToken<UcbaListComponent>('UCBA_LIST');

/**
 * A list component that is used with ng-template to automatically create a list layout.
 * Similiar to mat-tabs, content can be added with ng-template.
 * 
 * Usage:
 * ```html
 * <ucba-list>
 *     <ucba-list-element>
 *         <ng-template ucba-list-content-left>
 *         </ng-template>
 *         <ng-template ucba-list-content-center>
 *         </ng-template>
 *         <ng-template ucba-list-content-right>
 *         </ng-template>
 *     </ucba-list-element>
 * </ucba-list>
 * ```
 * 
 */
@Component({
    selector: 'ucba-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: true,
    providers: [
        { provide: UCBA_LIST, useExisting: UcbaListComponent },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class UcbaListComponent {}
