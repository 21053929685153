import { Component, computed, input } from '@angular/core';

import { OverwriteInputComponent } from '../base-input/base-input';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';

/**
 * Eingabefeld - Mehrzeiligier Text
 */
@Component({
    selector: 'finprocess-textarea-input',
    templateUrl: './textarea-input.component.html',
    styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Maximum length of the input
     */
    public maxLength = input<number>();

    /**
     * Returns the translated label for the tooltip for the original value
     */
    public translatedOriginalValueKey = computed(() => {
        const originalValue = this.originalValue();

        if (originalValue === undefined) {
            return 'financing.features.financing-processing.unset';
        }

        return originalValue?.toString() ?? '';
    });

    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.String, financingService);
    }    
}
