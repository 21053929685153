import { ISmartDocStateModel, SmartDocState } from './smartdoc/smartdoc.state';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface ISmartDocStateParentDefinition {
    /**
     * Zustand für aktuelle Authentifizierung
     */
    smartdoc: ISmartDocStateModel;
}

export const SMARTDOC_PERSITENT_STATE_KEY = [SmartDocState.typeName];
