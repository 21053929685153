/**
 * Abstrakte Klasse zum Implementieren eines Compilers
 */
export abstract class EnumTranslateCompiler {
    /**
     * Kompiliert Wert zu Lokalisierung
     *
     * @param {string} value Wert
     * @param {string} lang Lokalisierung
     */
    public abstract compile(value: string, lang: string): string | (() => string);

    /**
     * Kompiliert Übersetzungsressourcen zu Lokalisierung
     *
     * @param {any} translations Übersetzungen
     * @param {string} lang Lokalisierung
     */
    public abstract compileTranslations(translations: unknown, lang: string): unknown;
}
