import { EnumSettings } from './../enum.settings';

/**
 * Anstellungsverhältnis
 */
export enum EmployeeStatus {
    /**
     * Angestellte/-r
     */
    Employed = 0,

    /**
     * Keine Beschäftigung
     */
    Unemployed = 1,

    /**
     * Karenz
     */
    UnpaidLeave = 2,

    /**
     * Selbständige/-r / Freiberufliche/-r
     */
    SelfEmployed = 3,

    /**
     * Probezeit
     */
    Probation = 4,

    /**
     * Öffentlich Bedienstete/-r
     */
    PublicServant = 5,

    /**
     * Pensionist/-in
     */
    Retiree = 6,

    /**
     * Arbeiter/-in
     */
    Worker = 7,
}

export namespace EmployeeStatus {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {EmployeeStatus | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: EmployeeStatus | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'EmployeeStatus', EmployeeStatus, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('EmployeeStatus', plocale);
}
