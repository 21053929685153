<div class="container" fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
        <h1 class="color-primary">{{ 'navigation.administration.risk-parametrization' | translate }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button mat-mini-fab color="primary" (click)="toggleLock()" *finprocessAuthorization="Role.RiskParameterizationAdministrator"
                matTooltip="{{ (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate }}">
                <mat-icon *ngIf="locked">lock</mat-icon>
                <mat-icon *ngIf="!locked">lock_open</mat-icon>
            </button>
            <button *finprocessAuthorization="Role.RiskParameterizationAdministrator" mat-mini-fab color="primary" (click)="save()" [disabled]="form.pristine || form.invalid" matTooltip="{{ 'button.save' | translate }}">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>


    <div fxLayout="column" class="p-3" [formGroup]="form" class="content p-3">

        <!-- Basiskriterien -->
        <div class="risk-divider p-1 my-2">{{ 'administration.features.riskParametrization.baseCriteria' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.minimumAge' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minimumAge" type="number" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.maximumDuration' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="maximumDuration" type="number" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.minimumGrossFinancingRequirement' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minimumGrossFinancingRequirement" matInput currencyMask [options]="currencyOptions">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.maximumComfortCreditPlus' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="maximumComfortCreditPlus" matInput currencyMask [options]="currencyOptions">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.validationMessageAcceptableCreditRate' | translate }}
            </span>
            <span fxFlex="18">
                {{ 'administration.features.riskParametrization.validationMessageHint' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="60">
                <textarea formControlName="validationMessageAcceptableCreditRate" matInput></textarea>
            </mat-form-field>
        </div>

        <!-- Erweiterte Kriterien -->
        <div class="risk-divider p-1 my-2">{{ 'administration.features.riskParametrization.advancedCriteria' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <i fxFlex="20">
                {{ 'administration.features.riskParametrization.debitorCount' | translate }}
            </i>
            <i fxFlex="20">
                {{ 'administration.features.riskParametrization.maxTotalMonthlyIncomeJh' | translate }}
            </i>
            <i fxFlex="18">
                {{ 'administration.features.riskParametrization.dsti' | translate }}
            </i>
            <i fxFlex="40">
                {{ 'administration.features.riskParametrization.validationMessage' | translate }}
            </i>
        </div>

        <div *ngFor="let base of riskAssessmentRules; let i = index" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="base">
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="debitorCount" type="number" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="maxTotalMonthlyIncomeJh" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="18">
                <input formControlName="dsti" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="40">
                <textarea formControlName="validationMessage" matInput></textarea>
            </mat-form-field>
        </div>

        <!-- Kriterien für lange Laufzeiten -->
        <div class="risk-divider p-1 my-2">{{ 'administration.features.riskParametrization.longDurationCriteria' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.extendedDurationFrom' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="extendedDurationFrom" type="number" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.splitAge' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="splitAge" type="number" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.maximumDurationSplitAge' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="maximumDurationSplitAge" type="number" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.minimumOwnCapitalPercent' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minimumOwnCapitalPercent" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.minDsti' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minDsti"  currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="20">
                {{ 'administration.features.riskParametrization.minLtv' | translate }}
            </span>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minLtv" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Mindesteinkommen -->
        <div class="risk-divider p-1 my-2">{{ 'administration.features.riskParametrization.minimumIncomeCriteria' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <i fxFlex="20">
                {{ 'administration.features.riskParametrization.dependentPersonCount' | translate }}
            </i>
            <i fxFlex="20">
                {{ 'administration.features.riskParametrization.minimumIncome' | translate }}
            </i>
        </div>

        <div *ngFor="let base of incomeDependentPersonBases; let i = index" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" [formGroup]="base">
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="dependentPersonCount" type="number" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
                <input formControlName="minimumIncome" currencyMask [options]="currencyOptions" matInput>
            </mat-form-field>
        </div>
    </div>

</div>
