import { Component } from '@angular/core';

import { FinancingService, IBase, ValueStorageType } from './../../../../data';
import { OverwriteInputComponent } from './../base-input/base-input';

/**
 * Komponente zur Eingabe einer einfachen Textbox
 */
@Component({
    selector: 'finprocess-textbox-input',
    templateUrl: './textbox-input.component.html',
    styleUrls: ['./textbox-input.component.scss'],
})
export class TextboxInputComponent<T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.String, financingService);
    }
}
