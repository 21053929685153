<div class="p-2">
    <div>
        <h1 class="color-primary">{{'financing.features.financing-processing.riskfinancingplans.rfpTitle' | translate}}</h1>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px">
        <mat-card appearance="outlined" class="mb-3" *ngFor="let rfp of rfpList | async" fxFlex.gt-sm="calc(50% - 16px)">
            <mat-card-header>
                <mat-card-title>
                    {{rfp.description}}
                </mat-card-title>
                <mat-card-subtitle>
                    {{ rfp.status | finprocessEnumTranslation:'RiskFinancingPlanStatus' }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p><b>{{'financing.features.financing-processing.riskfinancingplans.createdAt' | translate}}:</b> {{rfp.created | date:'dd.MM.yyyy - HH:mm:ss'}}</p>
                <p><b>{{'financing.features.financing-processing.riskfinancingplans.lastChange' | translate}}:</b> {{rfp.modified | date:'dd.MM.yyyy - HH:mm:ss'}}</p>
                <br>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" (click)="openRFP(rfp.id)">Öffnen</button>
                <button mat-raised-button color="primary" [disabled]="editingReadonly$ | async" class="copy-button ml-2" (click)="copyNewRFP(rfp)">{{'financing.features.financing-processing.riskfinancingplans.copyRFP' | translate}}</button>
                <button mat-raised-button color="primary" (click)="openProductPackage(rfp.id)" class="ml-2">{{'financing.features.financing-processing.riskfinancingplans.openProductPackage' | translate}}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>