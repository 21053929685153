import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Komponente zur Anzeige eines berechneten Boolean Wertes
 */
@Component({
    selector: 'finprocess-boolean-calculation-display',
    templateUrl: 'boolean-calculation-display.component.html',
    styleUrls: ['boolean-calculation-display.component.scss'],
})
export class BooleanCalculationDisplayComponent implements OnInit {
    /**
     * Liefert das Flexlayout-Alignment des Wertes
     *
     * @returns {string} Flexlayout-Alignment des Wertes
     */
    public get fxValueLayoutAlign(): string {
        return `${this.fxValueAlignment} center`;
    }

    /**
     * Angezeigtes Label
     */
    @Input()
    public label!: string;

    /**
     * Anzeige der Berechnung als Observable
     */
    @Input()
    public booleanFunctionObservable!: Observable<((withOverwrites?: boolean) => boolean) | boolean>;

    /**
     * Anzeige der Berechnung als Funktion
     */
    @Input()
    /* public booleanFunction?: ((withOverwrites?: boolean) => boolean) | boolean; */

    /**
     * CSS-Klassen
     */
    @Input()
    public class = '';

    /**
     * Ausrichtung Anzeigewert
     */
    @Input()
    public fxValueAlignment = 'end';

    /**
     * Identifier im DOM
     */
    public id!: string;

    /**
     * Observable mit dem Originalwert
     */
    public booleanOriginalObservable!: Observable<boolean>;

    /**
     * Observable mit dem aktuellen Wert
     */
    public booleanObservable!: Observable<boolean>

    /**
     * Lokalisierung
     */
    public locale: string;


    /**
     * Konstruktor
     *
     * @param {string} locale Lokalisierung
     */
    public constructor(@Inject(LOCALE_ID)locale: string) {
        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }



    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.id = uuidv4();

        /*
         const booleanFunction = this.booleanFunctionObservable ?? of(this.booleanFunction ?? 0);
         */

        this.booleanObservable = this.booleanFunctionObservable.pipe(
            map(fn => {
                if (typeof fn === 'function') {
                    return fn();
                }
                return fn;
            }),
        );
        this.booleanOriginalObservable = this.booleanFunctionObservable.pipe(
            map(fn => {
                if (typeof fn === 'function') {
                    return fn(false);
                }
                return fn;
            }),
        );

    }
}
