<h1 mat-dialog-title class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ 'asset.assetProvider.assetProviderInfos' | translate }}
</h1>
<div mat-dialog-content>
    <ng-template #labelValueTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? value : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueBirthdayTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value | date : 'shortDate') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueBuddyBankTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value ? 'Ja' : 'Nein') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueGenderTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value | finprocessEnumTranslation : 'Gender') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueLegalTypeTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value | finprocessEnumTranslation : 'LegalType') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueNdgStatusTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value | finprocessEnumTranslation : 'NdgStatus') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-template #labelValueMaritalStatusTemplate let-fieldName="fieldName" let-value="value">
        <finprocess-label-value>
            <span label>{{ 'asset.assetProvider.' + fieldName | translate }}</span>
            <span value>{{ value !== null && value !== undefined && value !== '' ? (value | finprocessEnumTranslation : 'MaritalStatus') : '-' }}</span>
        </finprocess-label-value>
    </ng-template>

    <ng-container *ngIf="!!assetProviders">
        <ucba-heading [type]="headingType">
            <div ucba-heading-title>{{ 'asset.assetProvider.personalData' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!-- NDG -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'ndg', value: assetProviders.externalUserData.ndg }"></ng-container>
                    </ng-template>

                    <!--NDG Status-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueNdgStatusTemplate;
                                context: { fieldName: 'ndgStatus', value: assetProviders.externalUserData.ndgStatus }
                            "></ng-container>
                    </ng-template>

                    <!--NDG Type-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'ndgType', value: assetProviders.externalUserData.ndgType }
                            "></ng-container>
                    </ng-template>

                    <!-- Titel -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'title', value: assetProviders.externalUserData.title }
                            "></ng-container>
                    </ng-template>

                    <!-- Vorname -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'firstName', value: assetProviders.externalUserData.firstName }
                            "></ng-container>
                    </ng-template>

                    <!-- Nachname -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'lastName', value: assetProviders.externalUserData.surname }
                            "></ng-container>
                    </ng-template>

                    <!-- Geburtstag-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueBirthdayTemplate;
                                context: { fieldName: 'birthDate', value: assetProviders.externalUserData.birthDate }
                            "></ng-container>
                    </ng-template>

                    <!-- Geburtsort -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'birthPlace', value: assetProviders.externalUserData.birthPlace }
                            "></ng-container>
                    </ng-template>

                    <!--Geschlecht-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueGenderTemplate;
                                context: { fieldName: 'gender', value: assetProviders.externalUserData.gender }
                            "></ng-container>
                    </ng-template>

                    <!-- Sprache -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'language', value: assetProviders.externalUserData.language }
                            "></ng-container>
                    </ng-template>

                    <!-- Spitzname -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'nickname', value: assetProviders.externalUserData.nickname }
                            "></ng-container>
                    </ng-template>

                    <!-- Familienstand -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueMaritalStatusTemplate;
                                context: { fieldName: 'maritalStatus', value: assetProviders.externalUserData.maritalStatus }
                            "></ng-container>
                    </ng-template>

                    <!-- Familienmitglieder -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'familyMembers', value: assetProviders.externalUserData.familyMembers }
                            "></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>
        <br /><br />
        <ucba-heading [type]="headingType">
            <!-- Address Information -->
            <div ucba-heading-title>{{ 'asset.assetProvider.addressData' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!--Land-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'country', value: assetProviders.externalUserData.country }
                            "></ng-container>
                    </ng-template>

                    <!--Stadt-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'city', value: assetProviders.externalUserData.city }"></ng-container>
                    </ng-template>

                    <!-- PLZ -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'zipCode', value: assetProviders.externalUserData.zipCode }
                            "></ng-container>
                    </ng-template>

                    <!-- Straße -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'street', value: assetProviders.externalUserData.street }
                            "></ng-container>
                    </ng-template>

                    <!-- Hausnummer -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'streetNumber', value: assetProviders.externalUserData.streetNumber }
                            "></ng-container>
                    </ng-template>

                    <!-- Stockwerk -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'stairway', value: assetProviders.externalUserData.stairway }
                            "></ng-container>
                    </ng-template>

                    <!-- Top -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'top', value: assetProviders.externalUserData.top }"></ng-container>
                    </ng-template>

                    <!-- Stockwerk und Top -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'complementStairwayAndTop', value: assetProviders.externalUserData.complementStairwayAndTop }
                            "></ng-container>
                    </ng-template>

                    <!-- Provinz -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'province', value: assetProviders.externalUserData.province }
                            "></ng-container>
                    </ng-template>

                    <!-- Provinz Code -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'provinceCode', value: assetProviders.externalUserData.provinceCode }
                            "></ng-container>
                    </ng-template>

                    <!-- Stadt Code -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'mainCityCode', value: assetProviders.externalUserData.mainCityCode }
                            "></ng-container>
                    </ng-template>

                    <!-- Stadt Beschreibung -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'mainCityDescription', value: assetProviders.externalUserData.mainCityDescription }
                            "></ng-container>
                    </ng-template>

                    <!-- Land Code -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'mainCountryCode', value: assetProviders.externalUserData.mainCountryCode }
                            "></ng-container>
                    </ng-template>

                    <!-- Land Beschreibung -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'mainCountryDescription', value: assetProviders.externalUserData.mainCountryDescription }
                            "></ng-container>
                    </ng-template>

                    <!-- Provinz Code -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'mainProvinceAddressCode', value: assetProviders.externalUserData.mainProvinceAddressCode }
                            "></ng-container>
                    </ng-template>

                    <!-- Residenz -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'resident', value: assetProviders.externalUserData.resident }
                            "></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>
        <br /><br />
        <ucba-heading [type]="headingType">
            <!-- Work Related -->
            <div ucba-heading-title>{{ 'asset.assetProvider.workRelatedData' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!-- Firmenname -->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'businessName', value: assetProviders.externalUserData.businessName }
                            "></ng-container>
                    </ng-template>

                    <!--Lead Company-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'leadCompany', value: assetProviders.externalUserData.leadCompany }
                            "></ng-container>
                    </ng-template>

                    <!--Legal Form-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'legalForm', value: assetProviders.externalUserData.legalForm }
                            "></ng-container>
                    </ng-template>

                    <!--Legal Type-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueLegalTypeTemplate;
                                context: { fieldName: 'legalType', value: assetProviders.externalUserData.legalType }
                            "></ng-container>
                    </ng-template>

                    <!--Bank Code-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'bankCode', value: assetProviders.externalUserData.bankCode }
                            "></ng-container>
                    </ng-template>

                    <!--befreudete Bank-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueBuddyBankTemplate;
                                context: { fieldName: 'buddyBank', value: assetProviders.externalUserData.buddyBank }
                            "></ng-container>
                    </ng-template>

                    <!--Steuercode-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'taxCode', value: assetProviders.externalUserData.taxCode }
                            "></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>

        <br /><br />
        <ucba-heading [type]="headingType">
            <!-- Other -->
            <div ucba-heading-title>{{ 'asset.assetProvider.otherData' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!--automaticJoin-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'automaticJoin', value: assetProviders.externalUserData.automaticJoin }
                            "></ng-container>
                    </ng-template>

                    <!--dataCompleteness-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'dataCompleteness', value: assetProviders.externalUserData.dataCompleteness }
                            "></ng-container>
                    </ng-template>

                    <!--extinctionReason-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'extinctionReason', value: assetProviders.externalUserData.extinctionReason }
                            "></ng-container>
                    </ng-template>

                    <!--Manager-->
                    <ng-template ucba-tile>
                        <ng-container
                            *ngTemplateOutlet="
                                labelValueTemplate;
                                context: { fieldName: 'manager', value: assetProviders.externalUserData.manager }
                            "></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>
    </ng-container>

    @if (!assetProviders) {
    <ucba-tile-layout>
        <ng-template ucba-tile [span]="5">
            {{ 'asset.assetProvider.noAdditionalData' | translate }}
        </ng-template>
    </ucba-tile-layout>
    }

    <div mat-dialog-actions class="dialog-actions">
        <button mat-raised-button mat-dialog-close color="primary">{{ 'asset.assetProvider.close' | translate }}</button>
    </div>
</div>
