import { EnumSettings } from './../enum.settings';

/**
 * Zur Besicherung
 */
export enum Collateralization {
    /**
     * Neue Finanzierung
     */
    NewFinancing,

    /**
     * Vor-/Zwischenfinanzierung
     */
    InterimFinancing,
}

export namespace Collateralization {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {Collateralization | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: Collateralization | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'Collateralization', Collateralization, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('Collateralization', plocale);
}
