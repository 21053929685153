<div class="message-container h-100 w-100">
    <div class="h-100 w-100" *ngIf="!loading">
        <div class="p-3" fxLayout="row" fxLayoutAlign="space-between center">            
            <h1 *ngIf="(mainDebitor$ | async) as debitor">{{'messages.creditRequest' | translate}}
                <span class="ml-1">{{ debitor.firstNameOverwrite }}</span>
                <span class="ml-1">{{ debitor.lastNameOverwrite }}</span>
            </h1>
            <div class="action-buttons-container">
                @if(!isInputShown) {
                    <button *ngIf="!isReadonlyInternal" mat-icon-button [matTooltip]="'messages.tooltip-edit' | translate" class="edit-btn ml-1" color="primary" (click)="startEdit()" [disabled]="(editDisabled$ | async) === false">
                        <mat-icon>edit</mat-icon>
                    </button>
                } 
                @else {
                    <button *ngIf="!isReadonlyInternal" mat-icon-button [matTooltip]="'messages.tooltip-stop-edit' | translate" class="edit-btn ml-1" color="primary" (click)="stopEdit()">
                        <mat-icon>canceled</mat-icon>
                    </button>
                }
                <button mat-icon-button [matTooltip]="'messages.tooltip-search' | translate" class="search-btn ml-1" color="primary" (click)="openSearchDialog()">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="'messages.tooltip-download' | translate" class="download-btn ml-1" color="primary" (click)="onDownload()" [disabled]="exportDisabled$ | async">
                    <mat-icon>download</mat-icon>
                </button>
            </div>
        </div>
        <div class="chat-container" id="chat-container" [class.smaller-height]="isInputShown">
            <mat-tab-group class="w-100" mat-stretch-tabs [class.focus-height]="focusState"
                [(selectedIndex)]="selectedTabIdx" #matTabGroupContainer (selectedTabChange)="onTabChanged($event);">
                <ng-container *ngIf="!isMandantTypeFinService">
                    <mat-tab *ngIf="(groupChat$ | async) as groupMessages" label="{{ 'messages.groupChat' | translate }}">
                        <div #groupMessagesContainer class="chat-messages-container p-3">
                            @for (message of groupMessages; track message.id; let i = $index) {
                                <div class="chat-box">
                                    <div fxLayout="column" 
                                        [ngClass]="{'customer': message.from === messageUserType.Customer,
                                                    'sent': message.from === messageUserType.Source,
                                                    'received': message.from === messageUserType.FinProcess}"
                                                    >
                                        <div class="message-box" id="message-{{i}}">
                                            <div class="label">{{message.displayName}}{{message.fromExtension ? ', ' :''}}{{message.fromExtension}}</div>
                                            <div class="message" [innerHtml]="message.text"></div>
                                            <div class="label time">{{message.created | date:'dd.MM.yyyy, HH:mm'}}</div>
                                        </div>
                                    </div>
                                </div>
                            } 
                            @empty {
                                <div class="no-messages-text m-5">{{ 'messages.no-group-messages' | translate }}</div>
                            }
                        </div>
                    </mat-tab>
                </ng-container>
                <mat-tab *ngIf="(internalChat$ | async) as internalMessages" label="{{ 'messages.internalChat' | translate }}">
                    <div #internalMessagesContainer class="chat-messages-container p-3">
                        @if(internalMessages.length > 0) {
                            <div *ngFor="let message of internalMessages; let i = index" class="chat-box">
                                <div fxLayout="column"
                                    [ngClass]="message.from === messageUserType.Source ? 'sent' : 'received'">
                                    <div class="message-box" id="message-{{i}}">
                                        <div class="label">{{message.displayName}}{{message.fromExtension ? ', ' :''}}{{message.fromExtension}}</div>
                                        <div class="message" [innerHtml]="message.text"></div>
                                        <div class="label time">{{message.created | date:'dd.MM.yyyy, HH:mm'}}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        @else {
                            <div class="no-messages-text m-5">{{ 'messages.no-internal-messages' | translate }}</div>
                        }
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="isInputShown" class="editor-container p-3 w-100">
            <form *ngIf="!!chatForm" [formGroup]="chatForm" [hidden]="!isInputShown" #form="ngForm" #editform>
                <ckeditor [editor]="Editor" (focus)="onFocus()" (blur)="onBlur()" (change)="onChange($event)" formControlName="txArea"></ckeditor>
                <button mat-mini-fab [matTooltip]="'messages.tooltip-send' | translate" color="primary" (click)="onSend()"
                    [disabled]="!chatForm.controls['txArea'].value?.trim() && isSendBtnActive">
                        <mat-icon>send</mat-icon>
                </button>
            </form>
        </div>
    </div>
    <div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <waiter-component [isLoading]="loading"></waiter-component>
    </div>
</div>
