import { UUID } from 'app/modules/shared';

import { IRentalIncome } from '../../interfaces';

/**
 * Action when loading rental incomes
 */
export class RentalIncomesLoaded {
    public static readonly type = 'debitor.rentalIncomesLoaded';

    /**
     * Constructor 
     *
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {IRentalIncome[]} payload.rentalIncomes List of rental incomes
     */
    public constructor(public payload: { debitorId: UUID, rentalIncomes: IRentalIncome[]}) { }
}

/**
 * Action when adding a new rental income
 */
export class RentalIncomeAdded {
    public static readonly type = 'debitor.rentalIncomeAdded';

    /**
     * Constructor 
     *
     * @param {IRentalIncome} payload New rental income
     */
    public constructor(public payload: IRentalIncome) { }
}

/**
 * Action when deleting an rental income
 */
export class RentalIncomeDeleted {
    public static readonly type = 'debitor.rentalIncomeDeleted';

    /**
     * Constructor
     * 
     * @param {object} payload Payload
     * @param {UUID} payload.debitorId ID of the debitor
     * @param {UUID} payload.rentalIncomeId ID of the rental income
     */
    public constructor(public payload: { debitorId: UUID, rentalIncomeId: UUID }) { }
}

/**
 * Action when updating a rental income
 */
export class RentalIncomeUpdated {
    public static readonly type = 'debitor.rentalIncomeUpdated';

    /**
     * Constructor
     * 
     * @param {IRentalIncome} payload Updated rental income
     */
    public constructor(public payload: IRentalIncome) { }
}
