<ng-template #defaultReadonlyValueTemplate let-index="index" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{('financing.features.financing-processing.'+labelKey) | translate}}</span>
        <span value>{{ ($any(form.controls.productPackages).controls[index].controls[property].value !== undefined && $any(form.controls.productPackages).controls[index].controls[property].value !== null) ? $any(form.controls.productPackages).controls[index].controls[property].value : '-' }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultSelectValueTemplate let-index="index" let-property="property" let-translation="translation">
    <finprocess-label-value>
        <span label>{{('financing.features.financing-processing.financing-details.'+property) | translate}}</span>
        <span value>{{ ($any(form.controls.productPackages).controls[index].controls[property].value !== undefined && $any(form.controls.productPackages).controls[index].controls[property].value !== null) ? ($any(form.controls.productPackages).controls[index].controls[property].value | finprocessEnumTranslation:translation) : '-' }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyCurrencyValueTemplate let-index="index" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{ ('financing.features.financing-processing.'+labelKey) | translate }}</span>
        <span value>{{ ($any(form.controls.productPackages).controls[index].controls[property].value !== undefined && $any(form.controls.productPackages).controls[index].controls[property].value !== null) ? ($any(form.controls.productPackages).controls[index].controls[property].value | formatCurrency) : '-' }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyPercentValueTemplate let-index="index" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{('financing.features.financing-processing.financing-details.'+labelKey) | translate}}</span>
        <span value>{{ ($any(form.controls.productPackages).controls[index].controls[property].value !== undefined && $any(form.controls.productPackages).controls[index].controls[property].value !== null) ? (($any(form.controls.productPackages).controls[index].controls[property].value | number:'1.3-3') +'%') : '-' }}</span>
    </finprocess-label-value>
</ng-template>

<ng-template #defaultReadonlyDateValueTemplate let-index="index" let-labelKey="labelKey" let-property="property">
    <finprocess-label-value>
        <span label>{{('financing.features.financing-processing.financing-details.'+labelKey) | translate}}</span>
        <span value>{{ ($any(form.controls.productPackages).controls[index].controls[property].value !== undefined && $any(form.controls.productPackages).controls[index].controls[property].value !== null) ? ($any(form.controls.productPackages).controls[index].controls[property].value | date : 'dd.MM.yyyy') : '-' }}</span>
    </finprocess-label-value>
</ng-template>

<mat-tab-group fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="!!productPackageData">

    <form [formGroup]="form">
        <div formArrayName="productPackages">
            <mat-tab *ngFor="let products of productPackageData.assignProductPackages[0].assignProducts; let i = index"
                [formGroupName]="i"
                [label]="products.position + '. ' + getTranslatedProductType(products.productCreditType)">

                <!-- Finanzierungsbedarf überschritten Warnung -->
                <div class="mt-3 mb-3" fxLayout="column" fxLayoutAlign="start center" *ngIf="warningFee">
                    <div fxLayout="row">
                        <mat-icon class="mr-2 mb-3 financing-scope-warning">warning</mat-icon>
                        <p class="financing-scope-warning">
                            {{'financing.features.financing-processing.financing-details.financing-scope-warning' | translate}}
                        </p>
                    </div>
                    <div>
                        {{'financing.features.financing-processing.financing-details.approved-financing-scope' | translate}}: {{grossFinancingRequirement | formatCurrency}}
                    </div>
                    <div>
                        <b>
                            {{'financing.features.financing-processing.financing-details.exceeded-financing-scope' | translate}} {{differenceGrossFinancing | formatCurrency}}
                        </b>
                    </div>
                </div>

                <!-- Kreditanfrage -->
                <ucba-heading *ngIf="products.productCreditType !== ProductPackageType.Guarantee" [type]="'medium'">
                    <div ucba-heading-title>{{'financing.features.financing-processing.financing-details.creditRequest' | translate}}</div>
                    <div ucba-heading-body-visible>
                        <ucba-tile-layout>
                            <!-- Auszahlungsdatum -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>
                                            {{'financing.features.financing-processing.financing-details.disbursementDate' | translate}}
                                        </mat-label>
                                        <input matInput [matDatepicker]="mydatepicker" formControlName="disbursementDate"
                                            (blur)="updateProducts()" (dateChange)="updateProducts()">
                                        <mat-datepicker-toggle matSuffix [for]="mydatepicker" class="mr-2"></mat-datepicker-toggle>
                                        <mat-datepicker #mydatepicker></mat-datepicker>
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyDateValueTemplate; context: { index: i, labelKey: 'disbursementDate', property: 'disbursementDate' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Rückzahlung in (Tilgungsart) -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.repaymentPeriod' | translate}}</mat-label>
                                        <mat-select formControlName="repaymentPeriod" (blur)="updateProducts()">
                                            <mat-option *ngFor="let rp of repaymentPeriod" [value]="rp">
                                                {{ rp | finprocessEnumTranslation:'RepaymentPeriod' }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultSelectValueTemplate; context: { index: i, property: 'repaymentPeriod', translation: 'RepaymentPeriod' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Rückzahlungsbeginn ab -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>
                                            {{'financing.features.financing-processing.financing-details.repaymentDate' | translate}}
                                        </mat-label>
                                        <input matInput [matDatepicker]="mydatepicker2" formControlName="repaymentDate"
                                            (blur)="updateProducts()" (dateChange)="updateProducts()">
                                        <mat-datepicker-toggle matSuffix [for]="mydatepicker2" class="mr-2"></mat-datepicker-toggle>
                                        <mat-datepicker #mydatepicker2></mat-datepicker>
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyDateValueTemplate; context: { index: i, labelKey: 'repaymentDate', property: 'repaymentDate' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Kreditbetrag -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.creditAmount' | translate}}</mat-label>
                                        <input matInput currencyMask [options]="currencyMaskOptions" formControlName="creditAmount"
                                            (blur)="updateProducts()">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'productPackages.texts.creditAmount', property: 'creditAmount' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Hiervon tilgungsfreier Zeitraum -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field
                                        *ngIf="products.productCreditType === ProductPackageType.Credit || products.productCreditType === ProductPackageType.ComfortCredit"
                                        class="w-100 mat-form-field-transparent-background"
                                        appearance="fill">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.interestOnlyPeriod' | translate}}</mat-label>
                                        <input matInput currencyMask [options]="numberMaskOptions" (blur)="updateProducts()"
                                            formControlName="interestOnlyPeriod">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyValueTemplate; context: { index: i, labelKey: 'productPackages.texts.interestOnlyPeriod', property: 'interestOnlyPeriod' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Laufzeit -->
                            <ng-template ucba-tile *ngIf="!!products.durationWithoutASD">
                                <!-- duration not editable -->
                                <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false">
                                    <finprocess-label-value>
                                        <span label>{{'financing.features.financing-processing.productPackages.texts.duration' | translate}}</span>
                                        <span value>{{products.durationWithoutASD}} {{'financing.features.financing-processing.productPackages.texts.month' | translate}}</span>
                                    </finprocess-label-value>
                                </div>
                            </ng-template>
                            <!-- Anzahl Raten -->
                            <ng-template ucba-tile>
                                <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false">
                                    <finprocess-label-value>
                                        <span label>{{'financing.features.financing-processing.financing-details.ratesCount' | translate}}</span>
                                        <span value>{{products.ratesCount}}</span>
                                    </finprocess-label-value>
                                </div>
                            </ng-template>
                        </ucba-tile-layout>
                    </div>
                </ucba-heading>
                <!-- Zinssätze -->
                <ucba-heading *ngIf="products.productCreditType !== ProductPackageType.Guarantee" [type]="'medium'">
                    <div ucba-heading-title>{{'financing.features.financing-processing.financing-details.rates' | translate}}</div>
                    <div ucba-heading-body-visible>
                        <ucba-tile-layout>
                            <!-- Indikator -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.indicator' | translate}}</mat-label>
                                        <mat-select formControlName="indicator" (blur)="updateProducts()">
                                            <mat-option *ngFor="let i of indicator" [value]="i">
                                                {{ i | finprocessEnumTranslation:'InterestMethod' }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultSelectValueTemplate; context: { index: i, property: 'indicator', translation: 'InterestMethod' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Referenzzinssatz -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.interestReferenceRate' | translate}}</mat-label>
                                        <input matInput formControlName="interestReferenceRate" currencyMask (blur)="updateProducts()" [options]="percentageMaskOptions">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestReferenceRate', property: 'interestReferenceRate' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Aufschlag -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.addition' | translate}}</mat-label>
                                        <input matInput formControlName="addition" currencyMask [options]="percentageMaskOptions" (blur)="updateProducts()">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'addition', property: 'addition' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Aufschlag für Vergleichsrechnung -->
                            <ng-template ucba-tile *ngIf="products.additionForComparativeAnalysis !== undefined && products.withoutCoverageRegister === true">
                                <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false">
                                    <finprocess-label-value>
                                        <span label>{{'financing.features.financing-processing.financing-details.additionForComparativeAnalysis' | translate}}</span>
                                        <span value>{{ products.additionForComparativeAnalysis }}%</span>
                                    </finprocess-label-value>
                                </div>
                            </ng-template>
                            <!-- Legalisierungsgebühr -->
                            <ng-template ucba-tile *ngIf="legalisationFee$ | async as legalisationFee">
                                <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false">
                                    <finprocess-label-value>
                                        <span label>{{'financing.features.financing-processing.financing-details.legalisationFee' | translate}}</span>
                                        <span value>{{ legalisationFee(true) | formatCurrency }}</span>
                                    </finprocess-label-value>
                                </div>
                            </ng-template>
                        </ucba-tile-layout>
                    </div>
                </ucba-heading>
                <!-- Garantie -->
                <ucba-heading *ngIf="products.productCreditType === ProductPackageType.Guarantee" [type]="'medium'">
                    <div ucba-heading-title>{{'financing.features.financing-processing.financing-details.guaranteeData' | translate}}</div>
                    <div ucba-heading-body-visible>
                        <ucba-tile-layout>
                            <!-- Garantiebetrag -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" 
                                        class="w-100 mat-form-field-transparent-background"
                                        [matTooltip]="'financing.features.financing-processing.financing-details.guaranteeAmount' | translate" 
                                        matTooltipPosition="above"
                                    >
                                        <mat-label>{{'financing.features.financing-processing.financing-details.guaranteeAmount' | translate}}</mat-label>
                                        <input matInput formControlName="guaranteeAmount" currencyMask [options]="currencyMaskOptions" (blur)="updateProducts()">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.guaranteeAmount', property: 'guaranteeAmount' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Sonderfinanzierung -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill"
                                        class="w-100 mat-form-field-transparent-background"
                                        [matTooltip]="'financing.features.financing-processing.financing-details.guaranteeSpecialFinancing' | translate"
                                        matTooltipPosition="above"
                                    >
                                        <mat-label>{{'financing.features.financing-processing.financing-details.guaranteeSpecialFinancing' | translate}}</mat-label>
                                        <input matInput formControlName="specialFinancing" currencyMask [options]="currencyMaskOptions" (blur)="updateProducts()">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.guaranteeSpecialFinancing', property: 'specialFinancing' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Garantiesumme -->
                            <ng-template ucba-tile>
                                <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false">
                                    <finprocess-label-value>
                                        <span label>{{'financing.features.financing-processing.productPackages.texts.guaranteeSum' | translate}}</span>
                                        <div value>{{guaranteeSum | formatCurrency}}</div>
                                    </finprocess-label-value>
                                    <mat-hint class="hint-danger" *ngIf="!!guaranteeSum && guaranteeSum < 0">
                                        {{'financing.features.financing-processing.productPackages.texts.guaranteeWarning' | translate}}
                                    </mat-hint>
                                </div>
                            </ng-template>
                        </ucba-tile-layout>
                    </div>
                </ucba-heading>
                <!-- Verzinsungsarten -->
                <ucba-heading *ngIf="products.productCreditType !== ProductPackageType.Guarantee" [type]="'medium'">
                    <div ucba-heading-title>{{'financing.features.financing-processing.financing-details.rateTypes' | translate}}</div>
                    <div ucba-heading-body-visible>
                        <ucba-tile-layout>
                            <!-- Variable Verzinsung -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterest' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterest" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterest' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                            <!-- Verzinsung fix 5 Jahre -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterestFix5Year' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterestFix5Year" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterestFix5Year' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                            <!-- Verzinsung fix 10 Jahre -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterestFix10Year' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterestFix10Year" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterestFix10Year' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                            <!-- Verzinsung fix 15 Jahre -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterestFix15Year' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterestFix15Year" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterestFix15Year' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                            <!-- Verzinsung fix 20 Jahre -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterestFix20Year' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterestFix20Year" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterestFix20Year' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                            <!-- Verzinsung fix 25 Jahre -->
                            <ng-template ucba-tile>
                                <finprocess-label-value>
                                    <div [class.edit-mode-label-value]="(fieldReadonly$ | async) === false" label>{{'financing.features.financing-processing.financing-details.variableInterestFix25Year' | translate}}</div>
                                    <div value>
                                        @if((fieldReadonly$ | async) === false) {
                                            <mat-form-field class="w-100 mat-form-field-transparent-background">
                                                <mat-label>{{'financing.features.financing-processing.financing-details.interestRate' | translate}}</mat-label>
                                                <input matInput formControlName="variableInterestFix25Year" currencyMask
                                                    [options]="percentageMaskOptions" (blur)="updateProducts()">
                                            </mat-form-field>
                                        } @else {
                                            <ng-container *ngTemplateOutlet="defaultReadonlyPercentValueTemplate; context: { index: i, labelKey: 'interestRate', property: 'variableInterestFix25Year' }"></ng-container>
                                        }
                                    </div>
                                </finprocess-label-value>
                            </ng-template>
                        </ucba-tile-layout>
                    </div>
                </ucba-heading>

                <!-- Gebühren und Spesen -->
                <ucba-heading *ngIf="products.productCreditType !== ProductPackageType.Guarantee" [type]="'medium'">
                    <div ucba-heading-title>{{'financing.features.financing-processing.financing-details.fees' | translate}}</div>
                    <div ucba-heading-body-visible>
                        <ucba-tile-layout>
                            <!-- Risikobeitrag -->
                            <ng-template ucba-tile *ngIf="isOwnEmployee === true">
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.riskAmount' | translate}}</mat-label>
                                        <input matInput formControlName="riskAmount" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.riskAmount', property: 'riskAmount' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Bearbeitungsspesen -->
                            <ng-template ucba-tile *ngIf="isOwnEmployee === false">
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.processingFees' | translate}}</mat-label>
                                        <input matInput formControlName="processingFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.processingFees', property: 'processingFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Schätzgebühr -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.valuationFee' | translate}}</mat-label>
                                        <input matInput formControlName="valuationFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.valuationFee', property: 'valuationFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Schätzgebühr extern -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.externalValuationFee' | translate}}</mat-label>
                                        <input matInput formControlName="externalValuationFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.externalValuationFee', property: 'externalValuationFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Kontoführungsgebühr -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.accountFee' | translate}}</mat-label>
                                        <input matInput formControlName="accountFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.accountFee', property: 'accountFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Legalisierungsgebühr -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.legalFee' | translate}}</mat-label>
                                        <input matInput formControlName="legalFee" currencyMask [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.legalFee', property: 'legalFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Vermittlungskosten (einmalig) -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.brokerage' | translate}}</mat-label>
                                        <input matInput formControlName="brokerage" currencyMask [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.brokerage', property: 'brokerage' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Grundbucheintragsungsgebühr -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterEntryFee' | translate}}</mat-label>
                                        <input matInput formControlName="landRegisterEntryFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.landRegisterEntryFee', property: 'landRegisterEntryFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Rangordnungsgebühr -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.priorityRankingFee' | translate}}</mat-label>
                                        <input matInput formControlName="priorityRankingFee" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.priorityRankingFee', property: 'priorityRankingFee' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Grundbuchauszug -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterRecord' | translate}}</mat-label>
                                        <input matInput formControlName="landRegisterRecord" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.landRegisterRecord', property: 'landRegisterRecord' }"></ng-container>
                                }
                            </ng-template>
                            <!-- Grundbuchgesuch -->
                            <ng-template ucba-tile>
                                @if((fieldReadonly$ | async) === false) {
                                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                                        <mat-label>{{'financing.features.financing-processing.financing-details.landRegisterApplication' | translate}}</mat-label>
                                        <input matInput formControlName="landRegisterApplication" currencyMask
                                            [options]="currencyMaskOptions"
                                            (blur)="updateProducts(productPackageData.assignProductPackages[0])">
                                    </mat-form-field>
                                } @else {
                                    <ng-container *ngTemplateOutlet="defaultReadonlyCurrencyValueTemplate; context: { index: i, labelKey: 'financing-details.landRegisterApplication', property: 'landRegisterApplication' }"></ng-container>
                                }
                            </ng-template>
                        </ucba-tile-layout>
                    </div>
                </ucba-heading>
            </mat-tab>
        </div>
    </form>

    <!--Rechenbeispiel generieren-->
    <mat-tab label="Rechenbeispiel generieren">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="start">
                <div class="mt-3 mb-2" fxLayout="row" fxLayoutAlign="end">
                    <button mat-raised-button color="primary" style="width: fit-content"
                        (click)="openGenerateSampleCalculation()"
                        [disabled]="(fieldReadonly$ | async) ?? true || disableCreatingSampleCalculations === true || productPackageData.assignProductPackages[0].assignProducts.length === 0">
                        {{'financing.features.financing-processing.financing-details.addSampleCalculation' | translate}}
                    </button>
                </div>
                <div class="ml-3 mr-3">
                    <finprocess-sub-header [label]="'financing.features.financing-processing.financing-details.generatedSampleCalculations' | translate"></finprocess-sub-header>
                </div>
                <ucba-list>
                    <ucba-list-element *ngFor="let documents of sampleCalculationDocuments;">
                        <div ucba-list-content-left fxLayoutAlign="flex-start center">
                            <mat-icon class="mr-2 document-icon">insert_drive_file</mat-icon>
                            <span>{{documents.sampleCalculationName}}.pdf</span>
                        </div>
                        <div ucba-list-content-right>
                            <button mat-mini-fab color="primary" [disabled]="(fieldReadonly$ | async) === true"
                                class="ml-4 mr-2 icon-pointer open-icon"
                                [matTooltip]="'financing.features.financing-processing.financing-details.tooltipOpenSampleCalculation' | translate"
                                (click)="openSampleCalculationDocument(documents.sampleCalculationId, documents.sampleCalculationName)">
                                <mat-icon>open_in_new</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" [disabled]="(fieldReadonly$ | async) === true"
                                class="icon-pointer delete-icon"
                                [matTooltip]="'financing.features.financing-processing.financing-details.tooltipDeleteSampleCalculation' | translate"
                                (click)="deleteSampleCalcDocument(documents.sampleCalculationId)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </ucba-list-element>
                </ucba-list>
                <div *ngIf="sampleCalculationDocuments?.length === 0" class="rb-info ml-3 mr-3">
                    {{'financing.features.financing-processing.financing-details.sampleCalculationInfo' | translate}}
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>