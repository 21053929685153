import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_DOCUMENT_DTO: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerDocumentDto',

    certification: { type: WorkflowType.String },

    certificationCode: { type: WorkflowType.String },

    certificationDate: { type: WorkflowType.String },

    country: { type: WorkflowType.String },

    countryCode: { type: WorkflowType.Number },

    district: { type: WorkflowType.String },

    districtCode: { type: WorkflowType.Number },

    documentNumber: { type: WorkflowType.String },

    documentType: { type: WorkflowType.String },

    documentTypeCode: { type: WorkflowType.String },

    expirationDate: { type: WorkflowType.String },

    issueingOffice: { type: WorkflowType.String },

    issueingOfficeCode: { type: WorkflowType.String },

    legitimationStatus: { type: WorkflowType.String },

    legitimationStatusCode: { type: WorkflowType.String },

    municipality: { type: WorkflowType.String },

    municipalityDistrict: { type: WorkflowType.String },

    municipalityDistrictCode: { type: WorkflowType.Number },

    ndgPresentationDoc: { type: WorkflowType.String },

    notes: { type: WorkflowType.String },

    postalCode: { type: WorkflowType.String },

    presentationDate: { type: WorkflowType.String },

    publicAuthority: { type: WorkflowType.String },

    publicAuthorityCountry: { type: WorkflowType.String },

    publicAuthorityCountryCode: { type: WorkflowType.Number },

    releaseDate: { type: WorkflowType.String },
}
