<ng-template
    #selectInputTemplate
    let-items="items"
    let-insurance="insurance"
    let-readonly="readonly"
    let-fieldName="fieldName"
    let-labelKey="labelKey"
    let-valueStorageType="valueStorageType"
    let-required="required"
    let-value="value">
    @if((editingReadonly$ | async) === false) {
    <finprocess-select-input
        [items]="items"
        [overwrite]="insurance.source === SourceCollateral.Agp ? true : false"
        [entityClassType]="entityClassType.CollateralInsurance"
        [hideHorizontalRule]="true"
        [flexDirection]="inputFlexDirection"
        [transparent]="true"
        [fieldName]="fieldName"
        [readonly]="readonly"
        [entity]="insurance"
        [valueStorageType]="valueStorageType ?? null"
        [validators]="required ? Validators.required : null">
        <span label class="input-label">{{ 'asset.assetDetails.insuranceDetails.' + labelKey | translate }}</span>
    </finprocess-select-input>
    } @else {
    <finprocess-label-value>
        <span label>{{ 'asset.assetDetails.insuranceDetails.' + labelKey | translate }}</span>
        <span value>
            @if(items === booleanItems) {
            {{ value === true ? ('general.yes' | translate) : value === false ? ('general.no' | translate) : '-' }}
            } @else {
            {{ value ?? '-' }}
            }
        </span>
    </finprocess-label-value>
    }
</ng-template>

<div *ngFor="let insurance of collateralInsuranceDetail; let i = index; trackBy: panelTrackedBy; let last = last" #collaterals class="mb-2">
    <ucba-heading *ngIf="!!insurance" [expandable]="true" #expandableHeading [ngClass]="{ 'requirements-failed': validInsurancesMap[insurance.id] === false }">
        <div ucba-heading-title>{{ insurance.collateralType ?? 'Versicherung' | finprocessEnumTranslation : 'CollateralType' }}</div>
        <div ucba-heading-actions>
            <button
                class="delete-btn"
                [disabled]="editingReadonly$ | async"
                mat-icon-button
                (click)="deleteCollateral(insurance.id)"
                [matTooltip]="'asset.assetDetails.deleteCollateral' | translate">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div ucba-heading-body-visible>
            <ucba-tile-layout>
                <!-- rechtlicher Status -->
                <ng-template ucba-tile>
                    <ng-container
                        *ngTemplateOutlet="
                            selectInputTemplate;
                            context: {
                                items: assignmentTypeControl,
                                insurance: insurance,
                                readonly: editingReadonly$,
                                fieldName: 'legalStatus',
                                labelKey: 'assignmentTypeControl',
                                required: true,
                                value: findArrayElementWithId(assignmentTypeControl ?? [], insurance.legalStatus)?.displayName
                            }
                        "></ng-container>
                </ng-template>
            </ucba-tile-layout>
        </div>
        <div ucba-heading-body>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.insuranceDetails.otherInfos' | translate }}</div>
                <div ucba-heading-body-visible>
                    <ucba-tile-layout>
                        <!-- Ist diese Versicherung CRR fähig? -->
                        <!-- "Nicht nötig bei: BA130, BA135" -->
                        <ng-template ucba-tile *ngIf="insurance.collateralType !== CollateralType.BA130 && insurance.collateralType !== CollateralType.BA135">
                            <ng-container
                                *ngTemplateOutlet="
                                    selectInputTemplate;
                                    context: {
                                        items: booleanItems,
                                        insurance: insurance,
                                        readonly: editingReadonly$,
                                        fieldName: 'isCrrCapable',
                                        labelKey: 'insuranceCRR',
                                        valueStorageType: ValueStorageType.Bool,
                                        required: true,
                                        value: insurance.isCrrCapable
                                    }
                                "></ng-container>
                        </ng-template>
                        <!-- Selbstmordklausel -->
                        <!-- required when approval view, else not required  -->
                        <ng-template ucba-tile>
                            <ng-container
                                *ngTemplateOutlet="
                                    selectInputTemplate;
                                    context: {
                                        items: booleanItems,
                                        insurance: insurance,
                                        readonly: editingReadonlyApprovalView$,
                                        fieldName: 'isSuicideClause',
                                        labelKey: 'isSuicideClause',
                                        valueStorageType: ValueStorageType.Bool,
                                        required: approvalView,
                                        value: insurance.isSuicideClause
                                    }
                                "></ng-container>
                        </ng-template>
                    </ucba-tile-layout>
                </div>
            </ucba-heading>
            <ucba-heading [type]="'medium'">
                <div ucba-heading-title>{{ 'asset.assetDetails.insuranceDetails.insurance' | translate }}</div>
                <div
                    ucba-heading-actions
                    *ngIf="!!assetInsurance && assetInsurance.length > 0 && (editingReadonly$ | async) !== true"
                    class="add-btn"
                    (click)="matSelect.open()">
                    <span class="mr-1">{{ 'asset.assetDetails.insuranceDetails.assignInsurance' | translate }}</span>
                    <mat-icon style="font-size: 20px">add_circle</mat-icon>
                    <mat-select
                        style="visibility: hidden"
                        #matSelect
                        [panelWidth]="350"
                        [ngModel]="insurance.assetAssignmentInfos"
                        [compareWith]="compareAssetInsurances"
                        multiple>
                        <mat-option
                            #option
                            id="{{ aInsurance.id }}"
                            *ngFor="let aInsurance of assetInsurance"
                            [value]="aInsurance"
                            (onSelectionChange)="assignInsurances(aInsurance.id, insurance.id, true, $event)">
                            <ng-container>{{ aInsurance.insuranceType ?? '' | finprocessEnumTranslation : 'CollateralType' }}, </ng-container>
                            <ng-container *ngIf="aInsurance.insuranceSum"> {{ aInsurance.insuranceSum | formatCurrency }},</ng-container>
                            <ng-container *ngIf="aInsurance.insuranceCompanyName"> {{ aInsurance.insuranceCompanyName }}</ng-container>
                        </mat-option>
                    </mat-select>
                </div>
                <div ucba-heading-body-visible>
                    @if(!insurance || (!insurance.assetAssignmentInfos || insurance.assetAssignmentInfos.length === 0)) {
                    <ucba-tile-layout>
                        <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.insuranceDetails.noInsuranceDetails' | translate }}</ng-template>
                    </ucba-tile-layout>
                    } @else {
                    <div *ngFor="let assignAsset of insurance.assetAssignmentInfos; let j = index">
                        <ucba-heading *ngIf="!!assignAsset" [type]="'small'" [expandable]="true">
                            <div ucba-heading-title *ngIf="assignAsset.assetInsurance.insuranceType !== undefined">
                                {{ assignAsset.assetInsurance.insuranceType | finprocessEnumTranslation : 'CollateralType' }}
                            </div>
                            <div ucba-heading-actions>
                                <button
                                    *ngIf="(editingReadonly$ | async) !== true"
                                    class="delete-btn black"
                                    mat-icon-button
                                    [matTooltip]="'asset.assetDetails.insuranceDetails.deleteInsurance' | translate"
                                    (click)="assignInsurances(assignAsset.assetId, insurance.id, false)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <div ucba-heading-body-visible>
                                <ucba-tile-layout>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.insuranceCompanyName' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.insuranceCompanyName && assignAsset.assetInsurance.insuranceCompanyName !== ''
                                                    ? assignAsset.assetInsurance.insuranceCompanyName
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>

                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.insuranceSum' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.insuranceSum ? (assignAsset.assetInsurance.insuranceSum | formatCurrency) : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>

                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.deathSum' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.deathSum ? (assignAsset.assetInsurance.deathSum | formatCurrency) : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>

                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.materialValueTotal' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.materialValueTotal
                                                    ? (assignAsset.assetInsurance.materialValueTotal | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                </ucba-tile-layout>
                            </div>
                            <div ucba-heading-body>
                                <ucba-tile-layout class="margin-tile-layout">
                                    @if(hasAssignedBodyData(assignAsset.assetInsurance)) {
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.redemptionValueCurrency' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.redemptionValueCurrency ? assignAsset.assetInsurance.redemptionValueCurrency : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.currentCollateralValue' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.currentCollateralValue
                                                    ? (assignAsset.assetInsurance.currentCollateralValue | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.currentCollateralValue' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.currentCollateralValue
                                                    ? (assignAsset.assetInsurance.currentCollateralValue | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.currentCollateralValuePer' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.currentCollateralValuePer
                                                    ? (assignAsset.assetInsurance.currentCollateralValuePer | date : 'dd.MM.yyyy')
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.applicationNo' | translate }}</span>
                                            <span value>{{ !!assignAsset.assetInsurance.applicationNo ? assignAsset.assetInsurance.applicationNo : '-' }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.materialValueAlreadyInUse' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.materialValueAlreadyInUse
                                                    ? (assignAsset.assetInsurance.materialValueAlreadyInUse | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetCommon.freeMaterialValue' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.freeMaterialValue
                                                    ? (assignAsset.assetInsurance.freeMaterialValue | formatCurrency)
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.insuranceStart' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.insuranceStart
                                                    ? (assignAsset.assetInsurance.insuranceStart | date : 'dd.MM.yyyy')
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    <ng-template ucba-tile>
                                        <finprocess-label-value>
                                            <span label>{{ 'asset.assetInsurance.insuranceEnd' | translate }}</span>
                                            <span value>{{
                                                !!assignAsset.assetInsurance.insuranceEnd
                                                    ? (assignAsset.assetInsurance.insuranceEnd | date : 'dd.MM.yyyy')
                                                    : '-'
                                            }}</span>
                                        </finprocess-label-value>
                                    </ng-template>
                                    } @else {
                                    <ng-template ucba-tile [span]="5">{{ 'asset.assetDetails.insuranceDetails.noAssignedBodyData' | translate }}</ng-template>
                                    }
                                </ucba-tile-layout>
                                <finprocess-asset-provider
                                    class="asset-provider"
                                    [headingType]="'small'"
                                    [assetId]="assignAsset.assetId"
                                    [assetProviders]="assignAsset.assetInsurance.assetProviders">
                                </finprocess-asset-provider>
                            </div>
                        </ucba-heading>
                    </div>
                    }
                </div>
            </ucba-heading>
            <finprocess-collateralisation
                [collateralDetail]="insurance"
                [dataSources]="dataSources.get(insurance)"
                [editingReadonly$]="editingReadonly$"></finprocess-collateralisation>
        </div>
    </ucba-heading>
</div>
