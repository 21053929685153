/**
 * Mögliche Sortierungsfelder
 */
export enum DashboardFinancingsSortValues
{
    SystemNumber,
    FirstDebitorFirstname,
    FirstDebitorLastname,
    FirstDebitorCustomerNumber,
    SecondDebitorFirstname,
    SecondDebitorLastname,
    SecondDebitorCustomerNumber,
    EditorShortName,
    FinancingStatus,
    SubmissionDate,
}
