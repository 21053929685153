import { IBranch } from 'app/modules/masterdata/data';
import { UUID } from 'app/modules/shared';

import { IAddBranchRequest, IEditBranchRequest } from '../../interfaces';

/**
 * Aktion bei erfolgreichem Laden der Filialliste
 */
export class BranchesLoaded {
    public static readonly type = 'branch.loaded';

    /**
     * Konstruktor
     *
     * @param {IBranch[]} payload Filialliste
     */
    public constructor(public payload: IBranch[]) { }
}

/**
 * Aktion beim Hinzufügen einer Filiale
 */
export class BranchAdded {
    public static readonly type = 'branch.added';

    /**
     * Konstruktor
     *
     * @param {object} payload Payload
     * @param {IAddBranchRequest} payload.branch Neue Filiale
     * @param {UUID} payload.id ID der neuen Filiale
     */
    public constructor(public payload: { branch: IAddBranchRequest, id: UUID}) {}
}

/**
 * Aktion beim Editieren einer Filiale
 */
export class BranchEdited {
    public static readonly type = 'branch.edited';

    /**
     * Konstruktor
     *
     * @param {IBranch} payload Aktualisierte Filiale
     */
    public constructor(public payload: IEditBranchRequest) { }
}



/**
 * Aktion beim Ändern des Status einer Filiale
 */
export class BranchStatusChanged {
    public static readonly type = 'branch.statusChanged';

    /**
     * Konstruktor
     *
     * @param {object} payload StatusUpdate
     * @param {string} payload.id ID der Filiale
     * @param {boolean} payload.disabled Neuer Status der Filiale
     */
    public constructor(public payload: {id: string, disabled: boolean}) { }
}
