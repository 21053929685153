<div class="households-chip-navigation">
    <ucba-chip-list (selectionChange)="onTabChange($event)"> 
        <ucba-chip [color]="'primary'">{{'financing.features.financing-processing.householdBills.houseHoldOverviewTitle' | translate }}</ucba-chip>
        <ucba-chip [color]="'primary'" *ngFor="let household of houseHoldData?.households let i = index">{{'financing.features.financing-processing.householdBills.houseHoldTitle' | translate:{ number: i + 1 } }}</ucba-chip>
    </ucba-chip-list>
</div>

<!-- template for displayed row headers in household overview table -->
<ng-template #houseHoldOverviewRowHeaderTemplate let-row="row">
    <span>{{ row.headerKey | translate }}</span>
    <span *ngIf="row.headerCommentKey" class="row-header-comment">{{ row.headerCommentKey | translate }}</span>
</ng-template>

<!-- template for cell in household overview table -->
<ng-template #houseHoldOverviewCellTemplate let-dataArray="dataArray" let-row="row" let-$index="$index" let-isOptional="isOptional" let-id="id">
    <div>
        @if(row.dataKey) {
            @if(isOptional) {
                {{ findArrayElementWithId(dataArray, id)?.[row.dataKey] | formatCurrency }}
            } 
            @else {
                {{ dataArray[$index][row.dataKey] | formatCurrency }}
            }
        }
        @else if(row.dataKeyCalculation) {
            @if(isOptional) {
                {{ findArrayElementWithId(dataArray, id)?.calculations[0][row.dataKeyCalculation] | formatCurrency }}
            }
            @else {
                {{ dataArray[$index].calculations[0][row.dataKeyCalculation] | formatCurrency }}
            }
        } 
        @else if(row.dataKeyExpenditureCalculation && row.dataKeyExpenditureObject) {
            @if(isOptional) {
                {{ findArrayElementWithId(dataArray, id)?.calculations[0][row.dataKeyExpenditureCalculation]?.[row.dataKeyExpenditureObject] | formatCurrency }}
            }
            @else {
                {{ dataArray[$index].calculations[0][row.dataKeyExpenditureCalculation]?.[row.dataKeyExpenditureObject] | formatCurrency }}
            }
        } 
    </div>
</ng-template>

<!-- template for displayed cells in household overview table -->
<ng-template #houseHoldOverviewCellsTemplate let-row="row">
    @for(household of houseHoldData?.households; track $index) {
        <ng-container *ngTemplateOutlet="houseHoldOverviewCellTemplate; context: {dataArray: riskHouseholdData, row: row, $index: $index, id: household.id }"></ng-container>
        @if(findArrayElementWithId(pensionHouseholdData, household.id)) {
            <ng-container *ngTemplateOutlet="houseHoldOverviewCellTemplate; context: {dataArray: pensionHouseholdData, row: row, $index: $index, id: household.id, isOptional: true }"></ng-container>
        }
    }
</ng-template>

<!-- template for inputs in household table -->
<ng-template #householdTableInputTemplate let-dataArray="dataArray" let-dataKey="dataKey" let-$index="$index" let-id="id" let-sameValueForAllDebitors="sameValueForAllDebitors">
    @if(sameValueForAllDebitors)
    {
        <input placeholder="€ 0,00" matInput [(ngModel)]="dataArray[0][dataKey]"
            currencyMask [options]="currencyMaskOptions"
            (ngModelChange)="onInputChange()"
            [disabled]="(fieldReadonly$ | async) ?? true"
        >
    }
    @else {
        <input placeholder="€ 0,00" matInput [(ngModel)]="findArrayElementWithId(dataArray,id)[dataKey]"
            currencyMask [options]="currencyMaskOptions"
            (ngModelChange)="onInputChange()"
            [disabled]="(fieldReadonly$ | async) ?? true"
        >
    }
</ng-template>

<!-- template for cell value in household table -->
<ng-template #houseHoldCellValueTemplate let-dataArray="dataArray" let-dataKey="dataKey" let-$index="$index" let-id="id" let-inCalculationsArray="inCalculationsArray" let-editable="editable" let-sameValueForAllDebitors="sameValueForAllDebitors">
    @if(inCalculationsArray) {
        @if(sameValueForAllDebitors)
        {
            <div>{{ dataArray[0]?.calculations[0][dataKey] || 0 | formatCurrency}}</div>
        }
        @else {
            <div>{{ findArrayElementWithId(dataArray,id)?.calculations[0][dataKey] || 0 | formatCurrency}}</div>
        }
    } 
    @else {
        @if(editable && !((fieldReadonly$ | async) ?? true)) {
            @if(dataArray === pensionHouseHoldDebitors)
            {
                <div class="fixed-table-row-height-container" (mouseleave)="updatePensionCalculationIncome(findArrayElementWithId(pensionHouseHoldDebitors, id))"> 
                    <ng-container *ngTemplateOutlet="householdTableInputTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id }"></ng-container>
                </div>
            }
            @else if(dataArray === riskHouseholdExpenditure) {
                <div class="fixed-table-row-height-container" (mouseleave)="updateHouseholdCalculationExpenditure(riskHouseholdExpenditure[0], houseHoldData?.households?.[selectedHousehold]?.id!, riskHouseholdCalculationType)">
                    <ng-container *ngTemplateOutlet="householdTableInputTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id, sameValueForAllDebitors: true }"></ng-container>
                </div>
            }
            @else if(dataArray === pensionHouseholdExpenditure) {
                <div class="fixed-table-row-height-container" (mouseleave)="updateHouseholdCalculationExpenditure(pensionHouseholdExpenditure[0], houseHoldData?.households?.[selectedHousehold]?.id!, pensionHouseholdCalculationType)">
                    <ng-container *ngTemplateOutlet="householdTableInputTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id, sameValueForAllDebitors: true }"></ng-container>
                </div>
            }
        } 
        @else {
            @if(sameValueForAllDebitors)
            {
                <div>{{ dataArray[0]?.[dataKey] || 0 | formatCurrency}}</div>
            }
            @else {
                <div>{{ findArrayElementWithId(dataArray,id)?.[dataKey] || 0 | formatCurrency}}</div>
            }
        }
    }
</ng-template>

<!-- template for cell in household table -->
<ng-template #houseHoldCellTemplate let-dataArray="dataArray" let-dataKey="dataKey" let-$index="$index" let-id="id" let-noCustomerHouseholdValue="noCustomerHouseholdValue" let-inCalculationsArray="inCalculationsArray" let-editable="editable">
    @if(dataArray === riskHouseHoldDebitors || dataArray === riskHouseholdExpenditure || dataArray === riskHouseholdData)
    {
        <ng-container *ngTemplateOutlet="houseHoldCellValueTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id, inCalculationsArray: inCalculationsArray ?? false, editable: editable, sameValueForAllDebitors: dataArray === riskHouseholdExpenditure }"></ng-container>
    }
    @else if(dataArray === customerHouseHoldDebitors || dataArray === customerHouseholdExpenditure || dataArray === customerHouseholdData )
    {
        <div *ngIf="showKHHRTable">
            @if(!noCustomerHouseholdValue) {
                <ng-container *ngTemplateOutlet="houseHoldCellValueTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id, inCalculationsArray: inCalculationsArray ?? false, sameValueForAllDebitors: dataArray === customerHouseholdExpenditure }"></ng-container>
            }
        </div>
    }
    @else if(dataArray === pensionHouseHoldDebitors || dataArray === pensionHouseholdExpenditure || dataArray === pensionHouseholdData)
    {
        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">
            <ng-container *ngTemplateOutlet="houseHoldCellValueTemplate; context: {dataArray: dataArray, dataKey: dataKey, $index: $index, id: id, inCalculationsArray: inCalculationsArray ?? false, editable: editable, sameValueForAllDebitors: dataArray === pensionHouseholdExpenditure }"></ng-container>
        </div>
    }
</ng-template>

<!-- template for income row cells in household table -->
<ng-template #defaultHouseHoldRowCellsTemplate let-dataKey="dataKey" let-noCustomerHouseholdValue="noCustomerHouseholdValue" let-editable="editable">
    @for(debitor of debitors; track $index) {
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: riskHouseHoldDebitors, dataKey: dataKey, $index: $index, id: debitor.id }"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: customerHouseHoldDebitors, dataKey: dataKey, $index: $index, id: debitor.id, noCustomerHouseholdValue: noCustomerHouseholdValue ?? false }"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: pensionHouseHoldDebitors, dataKey: dataKey, $index: $index, id: debitor.id, editable: editable}"></ng-container>
    }
</ng-template>

 <!-- template for row cells in household table that include values of some househouldExpenditure array -->
<ng-template #expenditureHouseHoldRowCellsTemplate let-dataKey="dataKey" let-noCustomerHouseholdValue="noCustomerHouseholdValue" let-editable="editable">
    @for(debitor of debitors; track $index) {
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: riskHouseholdExpenditure, dataKey: dataKey, $index: $index, id: debitor.id, editable: editable}"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: customerHouseholdExpenditure, dataKey: dataKey, $index: $index, id: debitor.id, noCustomerHouseholdValue: noCustomerHouseholdValue ?? false }"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: pensionHouseholdExpenditure, dataKey: dataKey, $index: $index, id: debitor.id, editable: editable}"></ng-container>
    }
</ng-template>

<!-- template for row cells in household table that include values of calculations array of some householdData array -->
<ng-template #calculationsHouseHoldRowCellsTemplate let-dataKey="dataKey" let-noCustomerHouseholdValue="noCustomerHouseholdValue">
    @for(debitor of debitors; track $index) {
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: riskHouseholdData, dataKey: dataKey, $index: $index, id: selectedHouseholdId, inCalculationsArray: true }"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: customerHouseholdData, dataKey: dataKey, $index: $index, id: selectedHouseholdId, noCustomerHouseholdValue: noCustomerHouseholdValue ?? false, inCalculationsArray: true }"></ng-container>
        <ng-container *ngTemplateOutlet="houseHoldCellTemplate; context: {dataArray: pensionHouseholdData, dataKey: dataKey, $index: $index, id: selectedHouseholdId, inCalculationsArray: true }"></ng-container>
    }
</ng-template>

<!-- template for liability rows -->
<ng-template #liabilityRowTemplate let-liability="liability">
    <div>{{ liability.liabilityBank | finprocessEnumTranslation: 'BankType'}}</div>
    <div>{{ liability.liabilityType | finprocessEnumTranslation: 'LiabilityType'}}</div>
    <div>{{ liability.initialAmount | formatCurrency}}</div>
    <div>{{ liability.started | date:'dd.MM.YYYY'}}</div>
    <div>{{ liability.currentAmount | formatCurrency}}</div>
    <div>{{ liability.fictionalRateAmount | formatCurrency}}</div>
    <div>{{ liability.monthlyRate | formatCurrency}}</div>
    <div>{{ liability.loanPeriodInMonths }}</div>
    <div>
        @if(liability.isBeyondRetirement) {
            {{ 'general.yes' | translate }}
        } 
        @else {
            {{ 'general.no' | translate }}
        }
    </div>
</ng-template>

<ng-template #teleEditButton let-$index="$index" let-calculationType="calculationType" let-houseHoldDataArray="houseHoldDataArray" let-houseHoldExpenditureArray="houseHoldExpenditureArray">
    <button class="tele-edit-icon" 
        *ngIf="!((fieldReadonly$ | async) ?? true)"
        mat-icon-button 
        [disabled]="(fieldReadonly$ | async) ?? true" 
        (click)="openEditTelecommunicationDialog(calculationType, houseHoldDataArray?.[selectedHousehold]?.id!, houseHoldExpenditureArray[0].broadcastCosts, houseHoldExpenditureArray[0].phoneCosts)"
    >
        <mat-icon class="edit-icon">edit</mat-icon>
    </button>
</ng-template>

<div class="household-calculations-tab-content-wrapper">
    <!-- Haushaltsübersicht -->
    <div *ngIf="selectedHousehold < 0; else selectedHouseholdBlock">

        <ucba-table *ngIf="riskHouseholdData.length > 0 || pensionHouseholdData.length > 0; else elseBlock">
            <ng-template ucba-table-header>
                <div>{{'financing.features.financing-processing.householdBills.sumOverviewTitle' | translate }}</div>
                <div *ngFor="let household of houseHoldData?.households let i = index" [attr.ucba-column-span]="'span ' + (findArrayElementWithId(pensionHouseholdData, houseHoldData?.households?.[i]?.id!) ? '2' : '1')">{{'financing.features.financing-processing.householdBills.houseHoldTitle' | translate:{ number: i + 1 } }}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div></div>
                @for(household of houseHoldData?.households; track $index) {
                    <div class="household-table-sub-header">
                        <span>{{'financing.features.financing-processing.householdBills.riskTitle' | translate }}</span>
                        <button mat-stroked-button color="primary" class="ml-3" style="width: 30px; height: 30px;" 
                            [disabled]="isStatusNotOpenOrWaiting || isBaf || (fieldReadonly$ | async)"
                            [matTooltip]="riskHouseHoldOverview[$index]?.householdCalculationExist === true ? ('financing.features.financing-processing.householdBills.generateDocumentsAgainButton' | translate) : ('financing.features.financing-processing.householdBills.generateDocumentsButton' | translate)"
                            [matTooltipDisabled]="isBaf"
                            (click)="generateDocumentClicked(riskHouseHoldOverview[$index].id)">
                            <mat-icon 
                                [matTooltip]="'financing.features.financing-processing.householdBills.notPossible' |  translate"
                                [matTooltipDisabled]="!isBaf"
                            > file_copy</mat-icon>
                            <span *ngIf="riskHouseHoldOverview[$index]?.householdCalculationExist" style="position: relative; top: -5px;">*</span>
                        </button>
                    </div>
                    <div class="household-table-sub-header" *ngIf="findArrayElementWithId(pensionHouseholdData, household.id)">{{'financing.features.financing-processing.householdBills.pensionTitle' | translate }}</div>
                }
            </ng-template>
            @for(row of householdOverviewTableRows; track row) {
                @if(row.highlighted) {
                    <ng-template ucba-table-row ucba-highlighted-table-row> 
                        <ng-template ucba-table-row-header>
                            <ng-container *ngTemplateOutlet="houseHoldOverviewRowHeaderTemplate; context: { row: row }"></ng-container>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="houseHoldOverviewCellsTemplate; context: { row: row }"></ng-container>
                    </ng-template>
                } @else {
                    <ng-template ucba-table-row> 
                        <ng-template ucba-table-row-header>
                            <ng-container *ngTemplateOutlet="houseHoldOverviewRowHeaderTemplate; context: { row: row }"></ng-container>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="houseHoldOverviewCellsTemplate; context: { row: row }"></ng-container>
                    </ng-template>
                }
            } 
        </ucba-table>
        <ng-template #elseBlock>
            <div fxLayoutAlign="center">{{'financing.features.financing-processing.householdBills.noHouseHoldOverview' | translate }}</div>
        </ng-template>
    </div>
    <!-- Einzelner Haushalt -->
    <ng-template #selectedHouseholdBlock>
        <div class="household-buttons-wrapper">
            <div>
                <!--Notiz hinzufügen Button-->
                <button [disabled]="(fieldReadonly$ | async) ?? true" mat-raised-button color="primary" (click)="openNotesDialog(houseHoldData?.households?.[selectedHousehold]?.id!)">
                    <div *ngIf="!riskHouseholdExpenditure[0]?.riskCalculationNote" fxLayoutAlign="center center">
                        {{'financing.features.financing-processing.householdBills.addNote' | translate }}
                        <mat-icon class="ml-2">chat</mat-icon>
                    </div>
                    <div *ngIf="riskHouseholdExpenditure[0]?.riskCalculationNote" fxLayoutAlign="center center">
                        {{'financing.features.financing-processing.householdBills.editNote' | translate }}
                        <mat-icon class="ml-2">speaker_notes</mat-icon>
                    </div>
                </button>
            </div>
            <!--show/hide customer household table button-->
            <ng-container *ngFor="let calculation of houseHoldData?.households?.[selectedHousehold]?.calculations">
                <div *ngIf="calculation.type === customerHouseholdCalculationType">
                    <button mat-raised-button color="primary" (click)="customerTableVisibility()">
                        <div *ngIf="showKHHRTable" fxLayoutAlign="center center">
                            {{'financing.features.financing-processing.householdBills.customerTableNotVisible' |
                            translate }}
                            <mat-icon class="ml-2">visibility_off</mat-icon>
                        </div>
                        <div *ngIf="!showKHHRTable" fxLayoutAlign="center center">
                            {{'financing.features.financing-processing.householdBills.customerTableVisible' | translate
                            }}
                            <mat-icon class="ml-2">visibility</mat-icon>
                        </div>
                    </button>
                </div>
            </ng-container>
        </div>
    
        <div *ngIf="houseHoldData?.households?.[selectedHousehold]?.calculations; else elseBlock">
            <ucba-table>
                <!-- Alle Einkünfte -->
                <ng-template ucba-table-header>
                    <div>{{'financing.features.financing-processing.householdBills.allIncomes' | translate }}</div>
                    <div *ngFor="let debitor of debitors; let i = index;">{{ debitor.displayName }}</div>
                </ng-template>
                <ng-template ucba-table-row>
                    <div></div>
                    @for(debitor of debitors; track $index) {
                        <div class="household-table-sub-header">{{'financing.features.financing-processing.householdBills.riskTitle' | translate }}</div>
                        <div class="household-table-sub-header" *ngIf="showKHHRTable">{{'financing.features.financing-processing.householdBills.customerTitle' | translate }}</div>
                        <div class="household-table-sub-header" *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">{{'financing.features.financing-processing.householdBills.pensionTitle' | translate }}</div>
                    }
                </ng-template>
                <!-- Nettoverdienst p.M. -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.netIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'netIncome', editable: true }"></ng-container>
                </ng-template>
                <!-- 14 Gehälter -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.fourteenSalary' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div>
                            <mat-radio-group [ngModel]="riskHouseHoldDebitors[$index].fourteenSalariesPerYear">
                                <mat-radio-button color="primary" [value]="true" [disabled]="true">{{'general.yes' | translate}}</mat-radio-button>
                                <mat-radio-button color="primary" [value]="false" [disabled]="true">{{'general.no' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="showKHHRTable">
                            <mat-radio-group [ngModel]="customerHouseHoldDebitors[$index].fourteenSalariesPerYear">
                                <mat-radio-button color="primary" [value]="true" [disabled]="true">{{'general.yes' | translate}}</mat-radio-button>
                                <mat-radio-button color="primary" [value]="false" [disabled]="true">{{'general.no' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">
                            <mat-radio-group 
                                [ngModel]="findArrayElementWithId(pensionHouseHoldDebitors,debitor.id).fourteenSalariesPerYear" 
                                (ngModelChange)="onInputChange()" 
                                (mouseleave)="updatePensionCalculationIncome(findArrayElementWithId(pensionHouseHoldDebitors,debitor.id))"
                            >
                                <mat-radio-button color="primary" [value]="true" [disabled]="(fieldReadonly$ | async) ?? true">{{'general.yes' | translate}}</mat-radio-button>
                                <mat-radio-button color="primary" [value]="false" [disabled]="(fieldReadonly$ | async) ?? true">{{'general.no' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    }
                </ng-template>
                <!-- bestehende Sonstige Einkünfte p.M. -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.otherExistingIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'otherExistingIncome', editable: true }"></ng-container>
                </ng-template>
                <!-- Einkünfte aus selbstständiger Tätigkeit -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.independentIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'independentIncome', editable: true }"></ng-container>
                </ng-template>
                <!-- Bestehende Mieteinnahmen p.M. -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.existingRentalIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'existingRentalIncome', editable: true }"></ng-container>
                </ng-template>
                <!-- Zukünftige Einkünfte aus Mieteinnahmen -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.rentalIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'rentalIncome', editable: true }"></ng-container>
                </ng-template>
                <!-- Summe der Einnahmen -->
                <ng-template ucba-table-row ucba-highlighted-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.sumOfIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="defaultHouseHoldRowCellsTemplate; context: {dataKey: 'sumOfIncome', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
                <!-- Summe der gemeinsamen Einnahmen -->
                <ng-template ucba-table-row ucba-highlighted-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.sumOfIncomeOverview' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div>{{ riskHouseholdJointIncomeSum?.sum | formatCurrency }}</div>
                        <div *ngIf="showKHHRTable"></div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">{{ pensionHouseholdJointIncomeSum?.sum | formatCurrency }}</div>
                    }
                </ng-template>
                <!-- 30% der Einnahmen beträgt -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.thirtyPercentOfIncome' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div>{{ riskHouseholdJointIncomeSum?.thirtyPercentOfSum | formatCurrency }}</div>
                        <div *ngIf="showKHHRTable"></div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">{{ pensionHouseholdJointIncomeSum?.thirtyPercentOfSum | formatCurrency }}</div>
                    }
                </ng-template>
                <!-- Summe aller Haushaltsausgaben -->
                <ng-template ucba-table-header>
                    <div>{{'financing.features.financing-processing.householdBills.sumOfExpensesTitle' | translate }}</div>
                    <div *ngFor="let debitor of debitors; let i = index;"></div>
                </ng-template>
                <!-- Miete/Betriebskosten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.rentalCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'operationalCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- Telekommunikationskosten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.telecommunicationCost' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div class="no-wrap fixed-table-row-height-container">
                            <span>{{ riskHouseholdTelecommunicationSum | formatCurrency }}</span>
                            <ng-container *ngTemplateOutlet="teleEditButton; context: { $index: $index, calculationType: riskHouseholdCalculationType, houseHoldDataArray: riskHouseholdData, houseHoldExpenditureArray: riskHouseholdExpenditure }"></ng-container>
                        </div>
                        <div *ngIf="showKHHRTable">{{ customerHouseholdTelecommunicationSum | formatCurrency }}</div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)" class="no-wrap fixed-table-row-height-container">
                            <span>{{ pensionHouseholdTelecommunicationSum | formatCurrency }}</span>
                            <ng-container *ngTemplateOutlet="teleEditButton; context: { $index: $index, calculationType: pensionHouseholdCalculationType, houseHoldDataArray: pensionHouseholdData, houseHoldExpenditureArray: pensionHouseholdExpenditure }"></ng-container>
                        </div>
                    }
                </ng-template>
                <!-- Energiekosten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.energyCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'electricityCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- KFZ-Kosten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.carCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'carCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- Versicherungen -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.insuranceCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'insuranceCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- Alimente -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.alimonyCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'otherCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- Lebenserhaltungskosten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.livingCost' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="expenditureHouseHoldRowCellsTemplate; context: {dataKey: 'livingCosts', editable: true }"></ng-container>
                </ng-template>
                <!-- Summe der Ausgaben -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.sumOfExpenditure' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div>{{ riskHouseholdExpenditureSum | formatCurrency }}</div>
                        <div *ngIf="showKHHRTable"></div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">{{ pensionHouseholdExpenditureSum | formatCurrency }}</div>
                    }
                </ng-template>
                <!-- Verfügbares Einkommen -->
                <ng-template ucba-table-row ucba-highlighted-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.householdBills.availableIncome' | translate }}</ng-template>
                    @for(debitor of debitors; track $index) {
                        <div>{{ riskHouseholdAvailableIncomeSum | formatCurrency }}</div>
                        <div *ngIf="showKHHRTable"></div>
                        <div *ngIf="findArrayElementWithId(pensionHouseholdData,selectedHouseholdId!)">{{ pensionHouseholdAvailableIncomeSum | formatCurrency }}</div>
                    }
                </ng-template>
                <!-- -10% Sicherheitsreserve -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.household.reserve' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="calculationsHouseHoldRowCellsTemplate; context: {dataKey: 'safetyReserve', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
                <!-- Frei verfügbares Einkommen -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.household.freeAccessibleIncome' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="calculationsHouseHoldRowCellsTemplate; context: {dataKey: 'discretionaryIncome', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
                <!-- Bestehend bleibende Kredit-/Leasingraten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.household.monthlyRateNotCoveredLiabilities' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="calculationsHouseHoldRowCellsTemplate; context: {dataKey: 'existingLiabilities', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
                <!-- Neue Kredit-/Leasingraten -->
                <ng-template ucba-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.household.newCreditLeasingRates' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="calculationsHouseHoldRowCellsTemplate; context: {dataKey: 'newLiabilities', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
                <!-- Zumutbare Kreditrate -->
                <ng-template ucba-table-row ucba-highlighted-table-row>
                    <ng-template ucba-table-row-header>{{'financing.features.financing-processing.household.acceptableCreditRate' | translate }}</ng-template>
                    <ng-container *ngTemplateOutlet="calculationsHouseHoldRowCellsTemplate; context: {dataKey: 'reasinableCreditRate', noCustomerHouseholdValue: true }"></ng-container>
                </ng-template>
            </ucba-table>
    
            <!--Bestehende Verpflichtungen Tabelle-->
            <div class="households-table-wrapper" *ngIf="!!houseHoldData?.households?.[selectedHousehold]?.liabilities && houseHoldData?.households?.[selectedHousehold]?.liabilities!.length > 0">
                <br><br>
                <ucba-table>
                    <ng-template ucba-table-header>
                        <div>{{'financing.features.financing-processing.householdBills.existingLiabilitiesTitle' | translate }}</div>
        
                    </ng-template>
                    <ng-template ucba-table-row>
                        @for(col of displayedLiabilityColumns; track $index) {
                            <div class="household-table-sub-header">{{'financing.features.financing-processing.householdBills.' + col | translate }}</div>
                        }
                    </ng-template>
                    @for(liability of houseHoldData?.households?.[selectedHousehold]?.liabilities; track $index) {
                        <ng-template ucba-table-row>
                            <ng-container *ngTemplateOutlet="liabilityRowTemplate; context: {liability: liability}"></ng-container>
                        </ng-template>
                    }
                </ucba-table>
            </div>
    
            <!--Neue zusätzliche Verbindlichkeiten-->
            <div class="households-table-wrapper" *ngIf="!!houseHoldData?.households?.[selectedHousehold]?.newLiabilities && houseHoldData?.households?.[selectedHousehold]?.newLiabilities!.length > 0">
                <br><br>
                <ucba-table>
                    <ng-template ucba-table-header>
                        <div>{{'financing.features.financing-processing.householdBills.newLiabilitiesTitle' | translate }}</div>
        
                    </ng-template>
                    <ng-template ucba-table-row>
                        @for(col of displayedLiabilityColumns; track $index) {
                            <div class="household-table-sub-header">{{'financing.features.financing-processing.householdBills.' + col | translate }}</div>
                        }
                    </ng-template>
                    @for(liability of houseHoldData?.households?.[selectedHousehold]?.newLiabilities; track $index) {
                        <ng-template ucba-table-row>
                            <ng-container *ngTemplateOutlet="liabilityRowTemplate; context: {liability: liability}"></ng-container>
                        </ng-template>
                    }
                </ucba-table>
            </div>
        </div>
        <ng-template #elseBlock>
            <div fxLayoutAlign="center">
                {{ 'financing.features.financing-processing.householdBills.noHouseHoldCalculation' | translate }}
            </div>
        </ng-template>
    </ng-template>
</div>
