import { EnumSettings } from './../enum.settings';

/**
 * Orientierung der Wohnung
 */
export enum FlatOrientation {
    /**
     * Zu Parkanlagen, Grünanlagen
     */
    Green = 1,

    /**
     * In Innenhof, Wohnstr., Sackgasse…
     */
    Courtyard = 2,

    /**
     * Zu Nebenstraße, Seitenstraße
     */
    SideStreet = 4,

    /**
     * Zu Durchgangsstr., Bahnanlagen,  Gewerbe-/Industriegebiet
     */
    Railway = 8,
}

export namespace FlatOrientation {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {FlatOrientation | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: FlatOrientation | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'FlatOrientation', FlatOrientation, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('FlatOrientation', plocale);
}
