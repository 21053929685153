import { Environment } from 'app/modules/shared/util/enums';
import { IFileSizes } from 'app/modules/shared/util/interfaces/environment.interface';
import { NgxLoggerLevel } from 'ngx-logger';
import { IEnvironment } from 'shared/util';

export const environment: IEnvironment = {
    baseUrl: 'https://devapi.finprocess.nt.ag',
    apiUrl: 'https://devapi.finprocess.nt.ag/api',
    environment: Environment.Dev,
    defaultLang: 'de-AT',
    errorHandlingHideMessage: false,
    logLevel: NgxLoggerLevel.INFO,
    production: false,
    showDebugInfo: true,
    serverLogLevel: NgxLoggerLevel.ERROR,
    serviceWorkerEnabled: false,
    checkForUpdateConfiguration: {
        enabled: false,
    },
    supportedLanguages: ['de'],
    authConfig: {
        tokenLifeTime: 3600000,
        refreshTokenLifeTime: 86400000,
        refreshAfterLifeTimeFactor: 0.75,
    },
    dashboardConfig: {
        useClientFilterSortingOnAllTab: false,
        useClientFilterSortingOnUserTab: true,
        searchDelay: 300,
    },
    fileSizes: {} as IFileSizes,
    sync: {
        userInterval: 360000,
        messagesInterval: 600000,
        mapInterval: 600000,
        smartDocMapInterval: 600000,
        updateCheckInterval: 3600000,
        eventInterval: 600000,
        smartDocStatusInterval: 5000,
    },
};
