import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { INote } from 'app/modules/note/data/interfaces';

/**
 * Add or Edit Note Dialog Component
 */
@Component({
    selector: 'finprocess-add-edit-note-dialog',
    standalone: true,
    imports: [
        CommonModule, 
        CKEditorModule, 
        MatDialogModule,
        FormsModule,
        TranslateModule,
        MatButtonModule,
        DragDropModule,
    ],
    providers: [
        TranslateService],
    templateUrl: './add-edit-note-dialog.component.html',
    styleUrl: './add-edit-note-dialog.component.scss',
})
export class AddEditNoteDialogComponent {

    public editor = ClassicEditor;
    public editorData!: string;
    public charsRemaining = 0;
    public maxCharCount = 4000;
    public blockSaveButton = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editorConfig = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
            ],
        },
    };

    /**
     * Konstruktor
     * 
     * @param {MatDialogRef} dialogRef dialog ref
     * @param {string} data data
     * @param {string} data.addNote adding new Note (true) or editing existing Note (false)
     * @param {INote} data.noteData note data
     * @param {boolean} data.showCustomerHint show hint that customer can read that note above editor
     */
    public constructor(public dialogRef: MatDialogRef<AddEditNoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { addNote: boolean, noteData?: INote, showCustomerHint?: boolean }) {

        if (data.addNote) {
            this.editorData = '';
        }
        else if (data.addNote === false && !!data.noteData) {
            this.editorData = data.noteData?.comment;
            this.checkCharCount();
        }
    }

    /**
     * save description input
     */
    public saveNote() {
        if (this.editorData.length && this.editorData.length > 0) {
            this.dialogRef.close({ noteContent: this.editorData });
        }
        else {
            this.dialogRef.close({});
        }
    }

    /**
     * onEditorChange
     *
     * @param {string} newData event
     */
    public onEditorDataChange(newData: string) {
        this.editorData = newData;
        this.checkCharCount();
    }

    /**
     * check character count of editor content
     */
    public checkCharCount() {
        // Extract the text content from the element.
        const tempElement = document.createElement('div');
        tempElement.innerHTML = this.editorData;
        const textContent = tempElement.textContent || '';

        // Calculate the number of characters in the text content.
        const charCount = textContent.length;

        // Calculate the number of characters remaining.
        this.charsRemaining = charCount;

        // Check if the number of characters has exceeded the maximum allowed.
        if (charCount > this.maxCharCount) {
            this.blockSaveButton = true;
        } else {
            this.blockSaveButton = false;
        }
    }
}
