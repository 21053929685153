import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
/**
 *
 */
@Component({
    selector: 'finprocess-riskfinancingsplans-dialog',
    templateUrl: './riskfinancingsplans-dialog.component.html',
    styleUrls: ['./riskfinancingsplans-dialog.component.scss'],
})
export class RiskfinancingsplansDialogComponent {

    public rfpName!: string;

    /**
     * Konstruktor
     * 
     * @param {MatDialogRef} dialogRef MatDialogRef
     */
    public constructor(public dialogRef: MatDialogRef<RiskfinancingsplansDialogComponent>) { }

    /**
     * Dialog abbrechen
     */
    public cancelDialog(): void {
        this.dialogRef.close();
    }
}
