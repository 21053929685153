import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { Logout } from 'app/modules/auth/data';

import { FinancingLeaved } from '../financing-tab/financing-tab.actions';

import { IRealEstate } from './../../interfaces/real-estate.interface';
import { RealEstateLoaded, RealEstatesLoaded } from './real-estate.actions';

export interface IRealEstateStateModel { 
    realEstates: IRealEstate[];
}


const defaultData: IRealEstateStateModel = {
    realEstates: [],
};


/**
 * RealEstate State
 */
@State<IRealEstateStateModel>({
    name: RealEstateState.keyName,
    defaults: defaultData,
})
@Injectable()
export class RealEstateState { 
    
    public static readonly keyName = 'realestate';

    /**
     * get realEstates
     * 
     * @param {IRealEstateStateModel} state state
     * @returns {IRealEstate[]} state IRealEstate[]
     */
    @Selector()
    public static getRealEstates(state: IRealEstateStateModel): IRealEstate[] {
        return state.realEstates;
    }

    /**
     * Get ein realEstates by Id
     * 
     * @param {IRealEstateStateModel} state state
     * @returns {IRealEstate[]} state IRealEstate[]
     */
    @Selector()
    public static getRealEstateById(state: IRealEstateStateModel): (realEstateId: string) => IRealEstate | undefined {
        return (realEstateId: string): IRealEstate | undefined => state.realEstates.find((realEstate: IRealEstate) => realEstate.id === realEstateId);
    }

    /**
     * Zustandsänderung beim Verlassen der Finanzierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public financingLeaved({ setState }: StateContext<IRealEstateStateModel>): void {
        setState(defaultData);
    }

    /**
     * Patch RealEstate
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {RealEstateLoaded} action Aktion
     */
    @Action(RealEstatesLoaded)
    public realEstatesLoaded({ setState }: StateContext<IRealEstateStateModel>, {payload}: RealEstatesLoaded): void {
        setState({
            realEstates: payload,
        });
    }

    
    /**
     * Patch RealEstate
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {RealEstateLoaded} action Aktion
     */
    @Action(RealEstateLoaded)
    public realEstateLoaded({ setState }: StateContext<IRealEstateStateModel>, {payload}: RealEstateLoaded): void {
        setState(
            patch({
                realEstates: iif(
                    realEstates => realEstates.some(realEstate => realEstate.id === payload.id),
                    updateItem(realEstates => realEstates.id === payload.id, payload),
                    insertItem(payload),
                ),
            }),
        );
    }

}

