export enum FinProcessEntity {
    Address,
    Asset,
    Assetaccount,
    Assetinsurance,
    Assetrealestate,
    Branch,
    Career,
    Collateralaccount,
    Collateralinsurance,
    Collateralmortgage,
    Company,
    Debitor,
    Document,
    FinancingMap,
    FinProcessContainer,
    Household,
    Liability,
    Product,
    ProductPackage,
    RealEstate,
    SalesPartner,
    TaxOffice,
    CalculationInternal,
    CalculationExternal,
}
