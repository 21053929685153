import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'app/modules/shared/util';

/**
 * Custom Pipe for NDG formatting
 */
@Pipe({
    name: 'formatNdg',
})
export class FormatNdgPipe implements PipeTransform {

    /**
     * format NDG to remove leading zeros
     *
     * @param {string | undefined | null} value input value
     * @returns {string} formatted NDG
     */
    public transform(value: string | undefined | null): string {
        return HelperService.maskNdg(16, 8, '', value);
    }
}
