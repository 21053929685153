import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Komponente für den Stornierungs-Dialog
 */
@Component({
    selector: 'finprocess-cancel-contract-dialog',
    templateUrl: './cancel-contract-dialog.component.html',
    styleUrls: ['./cancel-contract-dialog.component.scss'],
})
export class CancelContractDialogComponent {
    public cancellationReason?: string;

    /**
     * Standard Konstruktor
     * 
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     */
    public constructor(public dialogRef: MatDialogRef<CancelContractDialogComponent>) { }


    /**
     * Funkion zum Stornieren des Auftrags
     */
    public cancelContract(): void {
        this.dialogRef.close(
            this.cancellationReason,
        );
    }
}
