/**
 * Zuständigkeit
 */
export enum OrganisationalUnitResponsibilityType {
    /**
     * Keine
     */
    None = 0,

    /**
     * Privatkunden
     */
    PrivateCustomers = 1,

    /**
     * Selbständige und Freiberufler
     */
    SelfEmployed = 2,

   /**
    * Eigene Angestellte
    */
    OwnCustomers = 3,
}
