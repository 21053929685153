import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard, IRoleData, Role } from 'auth';

import { AdministrationComponent, BranchComponent, ControlTablesComponent, CountryComponent, DebitRatesComponent, FinancingConfigurationComponent, LiabilityConfigurationComponent, RiskParametrizationComponent, SalespartnerRegionComponent, SmartdocConfigurationComponent, SystemConfigComponent, UsersComponent } from './components';
import { SystemdocumentsComponent } from './components/system-documents/systemdocuments/systemdocuments.component';

const routes: Routes = [
    {
        path: '',
        component: AdministrationComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
        children: [
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'branches',
                component: BranchComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'debit-rates',
                component: DebitRatesComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'risk-parametrization',
                component: RiskParametrizationComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'financing-configuration',
                component: FinancingConfigurationComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'systemconfig',
                component: SystemConfigComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'salespartner-region',
                component: SalespartnerRegionComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'country',
                component: CountryComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'smartdoc-configuration',
                component: SmartdocConfigurationComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.SmartDocAdministrator, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'control-tables',
                component: ControlTablesComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'system-documents',
                component: SystemdocumentsComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'liability-configuration',
                component: LiabilityConfigurationComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.AdministrationAreaReader, needOnlyOne: true } as IRoleData,
            },
        ],
    },
];

/**
 * Modul für Routen zum Dashboard
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdministrationRoutingModule {

}
