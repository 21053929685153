/**
 * ControlTableType
 */
export enum ControlTableType {
    /**
     * Steuertabelle für den Belehnungssatz
     * partieller Dateiname: Excel automatic upload collateral factor
     * oder LoanToValueRatios als name?
     */
    CollateralFactors = 0,

    /**
     * Liste für Bauträger
     * partieller Dateiname: Bauträgerliste inkl NDG
     */
    BuildingPromoters = 1,

    /**
     * Liste für Katastralgemeinden
     * partieller Dateiname: AMECST - AUSTRIAN TERITORIAL
     */
    CadastralCommunities = 2,

    /**
     * Liste für Belehnungssätze für Assets
     * partieller Dateiname: AMG_Steuertabelle
     */
    CollateralFactorsForAssets = 3,

    /**
     * Liste für Approval User Level
     */
    UserApprovalLevel = 4,

    /**
     * Liste für ApprovalMatrix
     */
    ApprovalMatrix = 5,
}
