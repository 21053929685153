import { EnumSettings } from './../enum.settings';

/**
 * Überwiegendes Baumaterial
 */
export enum BuildingMaterialType {
    /**
     * Ziegel/Beton
     */
    BrickOrConcrete = 1,

    /**
     * Holzkonstruktion mit Installationsebene
     */
    WoodWithInstallation = 2,

    /**
     * Holzkonstruktion ohne Installationsebene
     */
    Wood = 3,

    /**
     * Sonstige Bauweise (Holzblockbau, Steinbau, Stahlbau, etc.)
     */
    Other = 4,
}

export namespace BuildingMaterialType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {BuildingMaterialType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: BuildingMaterialType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'BuildingMaterialType', BuildingMaterialType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('BuildingMaterialType', plocale);
}
