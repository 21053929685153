export enum SalesChannelType {
    /**
     * in case of sales partner | VERTRIEBSPARTNER
     */
    M,

    /**
     * in case of no sales partner and field sales channel is blank | STATIONÄRER VERTRIEB
     */
    B,

    /**
     * in case of no sales partner and field sales channel is filled value selected(e.g.I- INTERNET)
     */
    I,
}
