import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { EnumTranslationPipe, MandantType, OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { IMasterdataParentDefinition } from 'app/modules/masterdata/data';
import { FinancingStatus, FinancingSubStatus, HelperService } from 'app/modules/shared';
import { ISelectItem } from 'app/modules/shared/util/interfaces';
import { sort } from 'fast-sort';
import { Observable, Subject, combineLatest, iif, of, throwError } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import {
    ApplicationDecisionType,
    EntityClassType,
    FinancingService,
    FinancingState,
    IDebitor,
    IFinancing,
    IFinancingStateParentDefinition,
    IStatusEntry,
    ValueStorageType,
} from '../../../../data';

/**
 *
 */
@Component({
    selector: 'finprocess-allowance',
    templateUrl: './allowance-dialog.component.html',
    styleUrls: ['./allowance-dialog.component.scss'],
})
export class AllowanceDialogComponent implements OnInit, OnDestroy {

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Einzelauswahl - Kreditentscheidung
     */
    public applicationDecisionSelectItems: ISelectItem<string>[] = [];

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    public entityClassType = EntityClassType;

    /**
     * Form für Wechsel Zuständigkeit PF-GK
     */
    public formResponsibility: FormGroup;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ApplicationDecisionType = ApplicationDecisionType;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Editor = ClassicEditorBuild;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public EditorRejection = ClassicEditorBuild;

    public alwaysReadonly$: Observable<boolean> = of(true);

    /**
     * Erstellen der Anrede(n)
     */
    private salutation = '';

    /**
     * Der Ablehnungsbescheid
     */
    private rejectionLetter = '';

    /**
     * Ablehnungsbegründung
     */
    public rejectionForm: FormGroup;

    /**
     * Loadingstate
     */
    public isLoading = false;

    @Select(FinancingState.comfortCreditTotalAmount)
    public comfortCreditTotalAmount$!: Observable<(withOverwrites?: boolean) => number>;

    public submissionDate?: string;

    /**
     * Standard Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {EnumTranslationPipe} enumTrans EnumTranslationPipe-Injektor
     * @param {FinancingService} financingService financingService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {MatDialogRef} dialogRef DialogRef Injektor
     * @param {DatePipe} datePipe DatePipe-Injektor
     */
    public constructor(
        private store: Store,
        private enumTrans: EnumTranslationPipe,
        private financingService: FinancingService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private notification: NotificationService,
        private dialogRef: MatDialogRef<AllowanceDialogComponent>,
        private datePipe: DatePipe,
    ) {
        this.formResponsibility = this.fb.group({
            additionalInformationResponsibility: ['', Validators.required],
        });

        this.rejectionForm = this.fb.group({
            rejection: [this.rejectionLetter, Validators.required],
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        const filteredApplicationDecisionType = HelperService.removeValuesFromEnum(ApplicationDecisionType, [ApplicationDecisionType.Possible, ApplicationDecisionType.PossibleFix])
        this.applicationDecisionSelectItems = HelperService.getSortedSelectItems(
            filteredApplicationDecisionType,
            value => this.enumTrans.transform(value, 'ApplicationDecisionType') as string,
        );

        this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(financing => {
                if (financing === undefined) {
                    return [];
                }

                this.submissionDate = financing.submissionDate;

                return sort(financing.households.reduce((debitors, currentHousehold) => debitors.concat(currentHousehold.debitors), [] as IDebitor[]))
                    .desc(b => b.gender);
            }),
            mergeMap(debitors => {
                this.buildSalutation(debitors);
                return this.buildRejectionLetter(this.salutation);
            }),
            tap(rejectionLetter => {
                this.rejectionLetter = rejectionLetter;
                this.rejectionForm.get('rejection')?.patchValue(rejectionLetter);
            }),
        ).subscribe();

        combineLatest([this.financing$, this.store.select((it: IMasterdataParentDefinition) => it.masterdata)])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(([financing, masterdata]) => {
                if (financing === undefined) {
                    return;
                }

                const responsibilitySelfEmployed = masterdata.salesPartnerCenters.find(center => center.id === financing?.salesPartnerCenterId)?.responsibility === OrganisationalUnitResponsibilityType.SelfEmployed ?? false;
                const isStationary = financing.mandantType === MandantType.FinAdvisory;
                this.applicationDecisionSelectItems = this.filterSelectItems(this.applicationDecisionSelectItems, responsibilitySelfEmployed, isStationary);
            });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Wechsel Zuständigkeit PF-GK
     *
     * @param {ApplicationDecisionType} decision Entscheidung
     */
    public changeResponsibility(decision: ApplicationDecisionType | undefined): void {

        if (!!decision) {
            this.isLoading = true;

            const subStatus = decision === ApplicationDecisionType.RejectedByResponsibilityGK ? FinancingSubStatus.RejectedByResponsibilityGK : FinancingSubStatus.RejectedByResponsibilityPF;

            this.executeWithFinancing([this.saveStatus(FinancingStatus.Rejected, subStatus, this.formResponsibility?.get('additionalInformationResponsibility')?.value)])
                .subscribe({
                    next: () => {
                        this.isLoading = false
                        this.dialogRef.close()
                    },
                    error: () => {
                        this.isLoading = false,
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                    },
                });
        }
    }

    /**
     * Führt die übergebenen Funktionen mit einer FinancingID aus
     *
     * @param {Function[]} functions Funktionen mit Paramter FinancingID zum Aufrufen
     * @returns {Observable} Observable
     */
    public executeWithFinancing(functions: Array<(financing: IFinancing) => Observable<unknown>>): Observable<unknown> {
        if (functions.length === 0) {
            return of(undefined);
        }

        const operators = functions.map(fn => mergeMap((financing: IFinancing | undefined) => {
            if (financing === undefined) {
                return throwError(() => 'Keine Finanzierung gefunden');
            }

            return fn(financing).pipe(map(() => financing));
        }));

        // Siehe https://github.com/ReactiveX/rxjs/issues/3989
        // Hohe Kunst des RXJS
        return operators.reduce((ob, op) => ob.pipe(op), this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.financing));
    }

    /**
     * Setzt den Status der Finanzierung
     *
     * @param {FinancingStatus} status Neuer Status der Finanzierung
     * @param {FinancingSubStatus} subStatus Neuer Substatus der Finanzierung
     * @param {string} reason Begründung für den Status
     * @returns {Observable} Serverantwort mit StatusEntry
     */
    public saveStatus(status: FinancingStatus, subStatus?: FinancingSubStatus | null, reason?: string): (financing: IFinancing) => Observable<IStatusEntry | undefined> {
        return (financing: IFinancing) => this.financingService.setStatus({
            id: financing.id,
            status,
            subStatus,
            reason,
        }).pipe(take(1));
    }

    /**
     * Baut die persönliche Anrede für den Ablehnugsbeleg
     *
     * @param {IDebitor[]} debitors IDebitorItem[]
     */
    private buildSalutation(debitors: IDebitor[]): void {
        this.salutation = '';
        for (const debitor of debitors) {
            switch (debitor.gender) {
                case 0: {
                    this.salutation += `Sehr geehrter Herr ${debitor.lastName},<br>`;
                    break;
                }
                case 1: {
                    this.salutation += `Sehr geehrte Frau ${debitor.lastName},<br>`;
                    break;
                }
                default: break;
            }
        }
    }

    /**
     * Gibt den REJECTION_LETTER mit personalisierter
     * Anrede zurück
     *
     * @param {string} salutation string
     * @returns {Observable<string>} Observable
     */
    private buildRejectionLetter(salutation: string): Observable<string> {
        return this.comfortCreditTotalAmount$.pipe(
            take(1),
            map(res => {
                const creditAmount = res(true);
                
                const formatter = new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                });
        
                const formattedDate = this.submissionDate ? this.datePipe.transform(this.submissionDate, 'dd.MM.yyyy') : '';
        
                return `<h2>Ablehnung Ihres Kreditantrages</h2><br>
                  Information gemäß § 9 (7) Hypothekar- und Immobilienkreditgesetzes (HIKrG)<br>
                  ${salutation}<br><br>
                  wir beziehen uns auf den am ${formattedDate} eingebrachten Antrag um Gewährung eines Kredites in Höhe
                  von ${formatter.format(creditAmount)} und bedauern Ihnen mitteilen zu müssen, dass wir dem Ansuchen nicht positiv
                  entsprechen können.<br><br><br>
                  Freundliche Grüße<br>
                  <b>UniCredit Bank Austria AG</b>`;
            }),
        );
    }

    /**
     * Setzt den Inhalt des Editors zurück
     */
    public deleteContent(): void {
        this.rejectionForm.get('rejection')?.patchValue(this.rejectionLetter);
    }

    /**
     * Sendet das Ablehnungsschreiben und setzt den Status der Finanzierung auf Abgelehnt
     */
    public sendRejection(): void {
        this.isLoading = true;

        this.store.selectOnce((it: IFinancingStateParentDefinition) => it)
            .pipe(mergeMap(
                it => iif(
                    () => it.financing.financing?.id !== undefined && this.rejectionForm.get('rejection')?.value !== null,
                    this.financingService.createRejectionLetter(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        it.financing.financing!.id,
                        this.rejectionForm.get('rejection')?.value).pipe(
                        mergeMap(() => this.financingService.setStatus({
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            id: it.financing.financing!.id,
                            reason: this.rejectionForm.get('rejection')?.value,
                            status: FinancingStatus.Rejected,
                            subStatus: FinancingSubStatus.Rejected,
                        })),
                    ),
                    of(undefined),
                ),
            )).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.dialogRef.close();
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }

    /**
     * Filtert die Auswahlmöglichkeiten für den Erstcheck
     *
     * @param {ISelectItem[]} selectItems Auswahlmöglichkeiten
     * @param {boolean} isSelfEmployed Auswahlmöglichkeiten
     * @param {boolean} isStationary Auswahlmöglichkeiten
     * @returns {ISelectItem[]} Gefilterte Auswahlmöglichkeiten
     */
    private filterSelectItems(selectItems: ISelectItem<string>[], isSelfEmployed: boolean, isStationary: boolean): ISelectItem<string>[] {
        if (isStationary) {
            // Im Stationären Betrieb gibt es keinen Wechsel der Zuständigkeiten
            return selectItems.filter(item => ![ApplicationDecisionType.RejectedByResponsibilityGK.toString(), ApplicationDecisionType.RejectedByResponsibilityPF.toString()].includes(item.value));
        }

        if (isSelfEmployed) {
            return selectItems.filter(item => item.value !== ApplicationDecisionType.RejectedByResponsibilityGK.toString());
        } else {
            return selectItems.filter(item => item.value !== ApplicationDecisionType.RejectedByResponsibilityPF.toString());
        }
    }
}
