import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Komponente zur Anzeige eines berechneten Wertes
 */
@Component({
    selector: 'finprocess-calculation-display',
    templateUrl: './calculation-display.component.html',
    styleUrls: ['./calculation-display.component.scss'],
})
export class CalculationDisplayComponent implements OnInit {

    /**
     * Liefert das Flexlayout-Alignment des Wertes
     *
     * @returns {string} Flexlayout-Alignment des Wertes
     */
    public get fxValueLayoutAlign(): string {
        return `${this.fxValueAlignment} center`;
    }

    /**
     * Angezeigtes Label
     */
    @Input()
    public label!: string;

    /**
     * Hinweistext unter dem Label
     */
    @Input()
    public hint?: string;

    /**
     * Anzeige der Berechnung als Observable
     */
    @Input()
    public numberFunctionObservable?: Observable<((withOverwrites?: boolean) => number) | number>;

    /**
     * Anzeige der Berechnung als Funktion
     */
    @Input()
    public numberFunction?: ((withOverwrites?: boolean) => number) | number;


    /**
     * Anzeigeformat
     */
    @Input()
    public format = '1.2-2';

    /**
     * Präfix
     */
    @Input()
    public praefix?: string;

    /**
     * Suffix
     */
    @Input()
    public suffix?: string;

    /**
     * CSS-Klassen
     */
    @Input()
    public class = '';

    /**
     * Ausrichtung Anzeigewert
     */
    @Input()
    public fxValueAlignment = 'end';

    /**
     * Identifier im DOM
     */
    public id!: string;

    /**
     * Observable mit dem Originalwert
     */
    public numberOriginalObservable!: Observable<number>;

    /**
     * Observable mit dem aktuellen Wert
     */
    public numberObservable!: Observable<number>

    /**
     * Lokalisierung
     */
    public locale: string;

    /**
     * Konstruktor
     *
     * @param {string} locale Lokalisierung
     */
    public constructor(@Inject(LOCALE_ID) locale: string) {
        // Bei östereichischer lokalisierung dennoch deutsches Zahlenformat
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.id = uuidv4();

        const numberFunction = this.numberFunctionObservable ?? of(this.numberFunction ?? 0);

        this.numberObservable = numberFunction.pipe(
            map(fn => {
                if (typeof fn === 'function') {
                    return fn();
                }
                return fn;
            }),
        );
        this.numberOriginalObservable = numberFunction.pipe(
            map(fn => {
                if (typeof fn === 'function') {
                    return fn(false);
                }
                return fn;
            }),
        );

    }
}
