import { CdkPortalOutlet } from '@angular/cdk/portal';
import { Component, ElementRef, InjectionToken, ViewEncapsulation, computed, contentChildren, input, viewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

import { UCBA_TABLE_ROW, UcbaHighlightedTableRowDirective, UcbaTableHeaderDirective } from '../../directives/table.directives';

export const UCBA_TABLE = new InjectionToken<UcbaTableComponent>('UCBA_TABLE');

interface IUcbaTableDimensions {
    rows: number;
    columns: number;
    hasHeader: boolean;
    rowDimensions: IUcbaRowDimensions[];
}

interface IUcbaRowDimensions {
    columns: number;
    hasHeader: boolean;
    element?: HTMLElement;
    children: HTMLElement[];
}

/**
 * A table component that is used with ng-template to automatically create a grid layout.
 * Similiar to mat-tabs, content can be added with ng-template.
 * 
 * The ucba-table-row directive is used to define a row in the table.
 * Each row can have an optional ucba-table-row-header. This header will always be placed on the left side of the row
 * and take up only as much space as it needs.
 * 
 * The ucba-table-header directive is used to define the header of the table. This header will always be placed
 * at the top of the table. With attribute ucba-column-span custom widths for the header columns can be set.
 * 
 * The directives might have to all be imported as standalone directives for the table to work.
 * 
 * Usage:
 * ```html
 * <ucba-table>
 *     <!-- Optional Table header -->
 *     <ng-template ucba-table-header>
 *          <div [attr.ucba-column-span]="'span 3'"></div>
 *          <div [attr.ucba-column-span]="'span 1'"></div>
 *     </ng-template>
 *     <ng-template ucba-table-row>
 *        <!-- Optional header for the table row -->
 *        <ng-template ucba-table-row-header>
 *       </ng-template>
 *     </ng-template>
 *     <!-- ...add more ucba-table-rows -->
 * </ucba-table>
 * ```
 * 
 */
@Component({
    selector: 'ucba-table',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: true,
    providers: [
        { provide: UCBA_TABLE, useExisting: UcbaTableComponent },
    ],
    imports: [
        CdkPortalOutlet,
    ],
})
export class UcbaTableComponent {

    /**
     * Override style for the grid template columns. If set, this will override the default
     */
    public overrideGridTemplateColumns = input<string | undefined>(undefined);

    /**
     * If set to true, the table will not have alternating column colors
     */
    public noAlternatingColumnColors = input<boolean>(false);
    
    /**
     * The div element that contains the table that will have the grid layout applied
     */
    protected table = viewChild<ElementRef<HTMLDivElement>>('ucbaTable');

    /**
     * All rows in the table identified by the table row injection token.
     * This would also allow external components to be added as a row, if they provide
     * the table row injection token.
     */
    protected allRows$ = contentChildren(UCBA_TABLE_ROW)

    /**
     * Computed signal that adds a type to each row for identification in the template.
     */
    protected allRowsWithType$ = computed(() => this.allRows$().map(row => {
        let type: 'header' | 'highlightedLight' | 'highlightedDark' | 'normal' = 'normal';

        if (row instanceof UcbaTableHeaderDirective) {
            type = 'header';
        } else if (row instanceof UcbaHighlightedTableRowDirective) {
            if (row.color() === 'light') {
                type = 'highlightedLight';
            } else {
                type = 'highlightedDark';
            }
        }

        return { type, row };
    }))

    /**
     * Internal observable for the table viewchild
     */
    private table$: Observable<ElementRef<HTMLDivElement> | undefined>;

    /**
     * Mutation observer for the table
     */
    private observer?: MutationObserver;

    /**
     * Subscribe on changes to the table viewchild and reinitialize the table
     */
    public constructor() {
        this.table$ = toObservable(this.table);

        this.table$.subscribe(table => {
            if (!table) {
                return;
            }

            this.setupObserver(table);
            this.initializeTable(table);
        });
    }

    /**
     * Initializes the css for the table. Sets the grid template columns and rows
     * and sets the grid column span for each cell.
     * 
     * @param {ElementRef<HTMLDivElement>} table Table element
     */
    private initializeTable(table: ElementRef<HTMLDivElement>) {
        const dimensions = this.calculateDimensions(table);
        const overrideGridTemplateColumns = this.overrideGridTemplateColumns();

        if (overrideGridTemplateColumns) {
            table.nativeElement.style['gridTemplateColumns'] = overrideGridTemplateColumns;
        } 
        else if (dimensions.hasHeader) {
            table.nativeElement.style['gridTemplateColumns'] = `auto repeat(${dimensions.columns - 1}, minmax(120px, 1fr))`;
        } 
        else {
            table.nativeElement.style['gridTemplateColumns'] = `repeat(${dimensions.columns}, minmax(120px, 1fr))`;
        }

        table.nativeElement.style['gridTemplateRows'] = `repeat(${dimensions.rows}, auto)`;

        for (const row of dimensions.rowDimensions) {
            if (row.columns === dimensions.columns && !dimensions.hasHeader) {
                continue;
            }

            const maximumTableColumns = dimensions.hasHeader ? dimensions.columns - 1 : dimensions.columns;
            const rowColumns = dimensions.hasHeader ? row.columns - 1 : row.columns;
            const divisor = Math.floor(maximumTableColumns / rowColumns);
            const remainder = maximumTableColumns % rowColumns;

            for (let i = (dimensions.hasHeader ? 1 : 0); i < row.children.length; i++) {
                const element = row.children[i];

                if (element.hasAttribute('ucba-column-span')) {
                    element.style.gridColumn = element.getAttribute('ucba-column-span') ?? '';
                    continue;
                }

                if (divisor === 1 && rowColumns - i > remainder) {
                    element.style.gridColumn = '';
                } else {
                    if (rowColumns <= remainder) {
                        element.style.gridColumn = `span ${divisor + 1}`;
                    } else {
                        element.style.gridColumn = `span ${divisor}`;
                    }
                }
            }

        }
    }

    /**
     * Sets up a mutation observer to watch for changes to the table
     * 
     * @param {ElementRef<HTMLDivElement>} element Table element
     */
    private setupObserver(element: ElementRef<HTMLDivElement>) {
        if (this.observer) {
            this.observer.disconnect();
        }

        this.observer = new MutationObserver(() => {
            this.initializeTable(element);
        });

        const options: MutationObserverInit = {
            subtree: true,
            childList: true,
        }

        this.observer.observe(element.nativeElement, options);
    }

    /**
     * Check if a given element is a table row. Table rows are embedded into divs
     * with either the class 'ucba-table-row' or 'ucba-table-header'
     * 
     * @param {Element | null} element Element to check
     * @returns {boolean} True if the element is a table row
     */
    private isTableRow(element: Element | null): boolean {
        return !!element && (element.classList.contains('ucba-table-row') || element?.classList.contains('ucba-table-header'));
    }

    /**
     * Calculate the dimensions of the table
     * 
     * @param {ElementRef<HTMLDivElement>} table Table element
     * @returns {IUcbaTableDimensions} Table dimensions
     */
    private calculateDimensions(table: ElementRef<HTMLDivElement>): IUcbaTableDimensions {
        const rows = table.nativeElement.children ?? new HTMLCollection();
        const rowDimensions: IUcbaRowDimensions[] = [];
        let maximumColumns = 0;
        let totalRows = 0;
        let hasHeader = false;


        for (let i = 0; i < rows.length; i++) {
            const child = rows.item(i);

            if (!child || !this.isTableRow(child)) {
                continue;
            }

            totalRows++;
            const dimensions = this.calculateRowDimensions(child);
            rowDimensions.push(dimensions);
            maximumColumns = Math.max(maximumColumns, dimensions.columns);

            if (dimensions.hasHeader) {
                hasHeader = true;
            }
        }

        return {
            rows: totalRows,
            columns: maximumColumns,
            hasHeader,
            rowDimensions,
        }
    }

    /**
     * Calculates the dimensions of a row. 
     * The number of columns, if the row has a header and the children of the row are calculated.
     * 
     * @param {Element} element Row element
     * @returns {IUcbaRowDimensions} Row dimensions
     */
    private calculateRowDimensions(element: Element): IUcbaRowDimensions {
        const children: HTMLElement[] = [];
        let columns = 0;
        let hasHeader = false;

        for (let i = 0; i < element.childNodes.length; i++) {
            const cell = element.childNodes.item(i);
            if (cell?.nodeType === Node.ELEMENT_NODE || cell?.nodeType === Node.TEXT_NODE) {
                columns++;
                if (cell instanceof HTMLElement) {
                    children.push(cell);

                    if (cell.classList.contains('ucba-table-row-header')) {
                        hasHeader = true;
                    }
                }
            }
        }

        return {
            columns,
            hasHeader,
            element: element instanceof HTMLElement ? element : undefined,
            children,
        }
    }
}
