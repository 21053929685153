import { IDropFolderViewModel } from '../../interfaces/smartdoc';

/**
 * AKlasse für Aktion
 */
export class LoadedDropFolders {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.loadedDropFolders';

    /**
     * Constructor
     *
     * @param {IDropFolderViewModel[]} payload IDropFolderViewModel[]
     */
    public constructor(public payload: IDropFolderViewModel[]) {}
}
