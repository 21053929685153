import { IDocument } from 'app/modules/financing/data';

import { AAGUID } from '../../types/aaguid';

export interface ISmartDocDocumentsLoadedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Dokumente, die geladen wurden
     */
    documents: IDocument[];
}

/**
 * Klasse für Aktion
 */
export class SmartDocDocumentsLoaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocDocumentsLoaded';
    /**
     * Constructor
     *
     * @param {ISmartDocDocumentsLoadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocDocumentsLoadedPayloadType) { }
}
