import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Logout } from 'app/modules/auth/data';
import { UUID } from 'app/modules/shared';

import { IDebitor, IRentalIncome } from '../../interfaces';
import { FinancingLeaved } from '../../states/financing-tab/financing-tab.actions';

import { RentalIncomeAdded, RentalIncomeDeleted, RentalIncomeUpdated, RentalIncomesLoaded } from './debitor.actions';

/**
 * Interface for the DebitorData state model
 * 
 * Debitor as partial for now, until the debitors are moved from the financing state to the debitor state.
 * For now this state is only used for the rental incomes.
 */
export interface IDebitorStateModel {
    debitors: Partial<IDebitor>[];
}

const defaultData: IDebitorStateModel = {
    debitors: [],
};

/**
 * State for the debitor data
 */
@State<IDebitorStateModel>({
    name: DebitorState.keyName,
    defaults: defaultData,
})
@Injectable()
export class DebitorState {

    public static readonly keyName = 'debitor';

    /**
     * Selector for rental incomes of a debitor
     * 
     * @param {IDebitorStateModel} state Current state
     * @returns {(debitorId: UUID) => IRentalIncome[]} Function to get the rental incomes of a debitor
     */
    @Selector()
    public static rentalIncomesByDebitor(state: IDebitorStateModel): (debitorId: UUID) => IRentalIncome[] {
        return (debitorId: UUID) => {
            const debitor = state.debitors.find(d => d.id === debitorId);
            return debitor?.rentalIncomes ?? [];
        }
    }

    /**
     * Action when the rental incomes of a debitor are loaded
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {RentalIncomesLoaded} payload RentalIncomesLoaded Action
     */
    @Action(RentalIncomesLoaded)
    public rentalIncomesLoaded({ setState }: StateContext<IDebitorStateModel>, { payload }: RentalIncomesLoaded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.debitorId),
                updateItem(debitor => debitor.id === payload.debitorId, patch({
                    rentalIncomes: payload.rentalIncomes,
                })),
                insertItem({ id: payload.debitorId, rentalIncomes: payload.rentalIncomes }),
            ),
        }));
    }

    /**
     * Action when a new rental income is added
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {RentalIncomeAdded} payload RentalIncomeAdded Action
     */
    @Action(RentalIncomeAdded)
    public rentalIncomeAdded({ setState }: StateContext<IDebitorStateModel>, { payload }: RentalIncomeAdded): void {
        setState(patch({
            debitors: iif(
                debitors => debitors.some(d => d.id === payload.assignedDebitorId),
                updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                    rentalIncomes: append([payload]),
                })),
                insertItem({ id: payload.assignedDebitorId, rentalIncomes: [payload] }),
            ),
        }));
    }

    /**
     * Action when a rental income is updated
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {RentalIncomeUpdated} payload RentalIncomeUpdated Action
     */
    @Action(RentalIncomeUpdated)
    public rentalIncomeUpdated({ setState }: StateContext<IDebitorStateModel>, { payload }: RentalIncomeUpdated): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.assignedDebitorId, patch({
                rentalIncomes: updateItem(rentalIncome => rentalIncome.id === payload.id, payload),
            })),
        }));
    }

    /**
     * Action when a rental income is deleted
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     * @param {RentalIncomeDeleted} payload RentalIncomeDeleted Action
     */
    @Action(RentalIncomeDeleted)
    public rentalIncomeDeleted({ setState }: StateContext<IDebitorStateModel>, { payload }: RentalIncomeDeleted): void {
        setState(patch({
            debitors: updateItem(debitor => debitor.id === payload.debitorId, patch({
                rentalIncomes: removeItem(rentalIncome => rentalIncome.id === payload.rentalIncomeId),
            })),
        }));
    }

    /**
     * Action when the user logs out or the current financing is closed
     * 
     * @param {StateContext<IDebitorStateModel>} state State context
     */
    @Action(Logout)
    @Action(FinancingLeaved)
    public reset({ setState }: StateContext<IDebitorStateModel>): void {
        setState(defaultData);
    }
}

