import { EnumSettings } from './../enum.settings';

/**
 * Objektart
 */
export enum RealEstateType {
    /**
     * Einfamilienhaus
     */
    DetachedHouse = 0,

    /**
     * Doppelhaushälfte
     */
    SemiDetachedHouse = 1,

    /**
     * Reihenhaus
     */
    RowHouse = 2,

    /**
     * Mehrfamilienhaus
     */
    ApartmentHouse = 3,

    /**
     * Grundstück
     */
    Lot = 4,

    /**
     * Eigentumswohnung
     */
    Flat = 5,

    /**
     * Vorsorgewohnung
     */
    InvestmentFlat = 7,

    /**
     * Sonstiges
     */
    Other = 8,
}

export namespace RealEstateType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {RealEstateType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: RealEstateType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'RealEstateType', RealEstateType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('RealEstateType', plocale);
}
