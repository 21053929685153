export enum CustomerPurposeType {
    /**
     * Eigenbedarf
     */
    PersonalUse = 0,

    /**
     * Vermietung
     */
    Renting = 1,
}
