import { Collateralization, CreditPurpose, CreditType, FictionalRateSourceType, InterestMethod, MarketValueType, ObjectPurposeType, RealEstateType } from '@ntag-ef/finprocess-enums';

import { COMFORTCREDIT_PLUS_ASSUMEDDURATION } from '../constants';
import { ArgumentError, DivisionByZeroError } from '../errors';
import { IFinancingConfiguration, ILegalisationFeeBase, IRiskAssessmentRule, ISplittingRule } from '../interfaces';
import { UUID } from '../types';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService, InternalCalculationService } from './core';
import { LiabilityCalculationService } from './liability-calculation.service';
import { NewLiabilityCalculationService } from './new-liability-calculation.service';
import { RealEstateCalculationService } from './real-estate-calculation.service';

const configurationInvalid = 'Der Parameter \'configuration\' ist nicht valide.';
/**
 * Berechnungsmethoden zum KomfortKredit
 */
export class ComfortProductCalculationService extends BaseCalculationService {

    /**
     * Berechnungsservice InternalCalculationService
     */
    private pInternalCalculationService?: InternalCalculationService;

    /**
     * Berechnungsservice InternalCalculationService
     *
     * @returns {InternalCalculationService} InternalCalculationService
     */
    private get internalCalculationService() {
        if (this.pInternalCalculationService === undefined) {
            this.pInternalCalculationService = new InternalCalculationService(this.version);
        }
        return this.pInternalCalculationService;
    }

    /**
     * Berechnungsservice RealEstateCalculationService
     */
    private pRealEstateCalculationService?: RealEstateCalculationService;

    /**
     * Berechnungsservice RealEstateCalculationService
     *
     * @returns {RealEstateCalculationService} RealEstateCalculationService
     */
    private get realEstateCalculationService() {
        if (this.pRealEstateCalculationService === undefined) {
            this.pRealEstateCalculationService = new RealEstateCalculationService(this.version);
        }
        return this.pRealEstateCalculationService;
    }

    /**
     * Berechnungsservice LiabilityCalculationService
     */
    private pLiabilityCalculationService?: LiabilityCalculationService;

    /**
     * Berechnungsservice LiabilityCalculationService
     *
     * @returns {LiabilityCalculationService} LiabilityCalculationService
     */
    private get liabilityCalculationService() {
        if (this.pLiabilityCalculationService === undefined) {
            this.pLiabilityCalculationService = new LiabilityCalculationService(this.version);
        }
        return this.pLiabilityCalculationService;
    }

    /**
     * Berechnungsservice NewLiabilityCalculationService
     */
    private pNewLiabilityCalculationService?: NewLiabilityCalculationService;

    /**
     * Berechnungsservice NewLiabilityCalculationService
     *
     * @returns {NewLiabilityCalculationService} NewLiabilityCalculationService
     */
    private get newLiabilityCalculationService() {
        if (this.pNewLiabilityCalculationService === undefined) {
            this.pNewLiabilityCalculationService = new NewLiabilityCalculationService(this.version);
        }
        return this.pNewLiabilityCalculationService;
    }

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Ermittelt Produktsplitting
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCredit KomfortKredit (ohne Berücksichtigung Splitting) in €
     * @param {boolean} params.useLtvSplitting Splitting durch LTV
     * @param {boolean} params.useRatioSplitting Splitting durch Verhältnis Kosten zu Eigenmittel
     * @returns {boolean} Wird Kredit gesplittet
     */
    public useProductSplitting({ grossFinancingRequirement, comfortCredit, useLtvSplitting, useRatioSplitting }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * KomfortKredit (ohne Berücksichtigung Splitting) in €
         */
        comfortCredit: number;
        /**
         * Splitting durch LTV
         */
        useLtvSplitting: boolean;
        /**
         * Splitting durch Verhältnis Kosten zu Eigenmittel
         */
        useRatioSplitting: boolean;
    }): boolean {
        switch (this.version) {
            case 0:
                return false;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            default:
                return grossFinancingRequirement > comfortCredit &&
                    (useLtvSplitting || useRatioSplitting);
        }
    }

    /**
     * Berechnet den LTV
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {Array<object>} params.liabilities Bestehende Verpflichtungen
     * @param {Array<object>} params.newLiabilities Neuen Verpflichtungen
     * @param {Array<object>} params.realEstates Objekte
     * @throws {DivisionByZeroError}
     * @returns {number} LTV
     */
    public ltv({ grossFinancingRequirement, liabilities, newLiabilities, realEstates }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;

        /**
         * Bestehende Verpflichtungen
         */
        liabilities: Array<{
            /**
             * Wird abgedeckt
             */
            covered?: boolean;

            /**
             * Dzt. Aushaftung gem. Nachweis in €
             */
            currentAmount: number;

            /**
             * Grundbücherlich besichert
             */
            securedByLandRegister?: boolean;

            /**
             * Besichert auf Objekt
             */
            securedRealEstateIds?: UUID[];
        }>;

        /**
         * Neue Verpflichtungen
         */
        newLiabilities: Array<{
            /**
             * Kreditbetrag/Förderbetrag
             */
            amount: number;

            /**
             * Grundbücherlich besichert
             */
            securedByLandRegister?: boolean;

            /**
             * Besichert auf Objekt
             */
            securedRealEstateIds?: UUID[];
        }>;

        /**
         * Objekte
         */
        realEstates: Array<{
            /**
             * Identifier des Objekts
             */
            id: UUID;

            /**
             * Objektart
             */
            objectPurpose: ObjectPurposeType;

            /**
             * Zur Besicherung
             */
            collateralization: Collateralization;

            /**
             * Marktwert, ohne Aufschläge
             */
            marketValue?: number;

            /**
             * Art des Markrtwerts
             */
            marketValueType?: MarketValueType;

            /**
             * Kaufpreis
             */
            purchasePrice?: number;

            /**
             * Finanzierungszweck
             */
            creditPurpose?: CreditPurpose;

            /**
             * Objektart
             */
            realEstateType?: RealEstateType;
        }>;

    }): number {
        switch (this.version) {
            case 0:
            case 1:
            case 2:
            case 3:
                // eslint-disable-next-line no-case-declarations
                const realEstate = realEstates.find(it => it.objectPurpose === ObjectPurposeType.Finance && it.collateralization === Collateralization.NewFinancing);
                if (realEstate === undefined) {
                    return 0;
                }
                // eslint-disable-next-line no-case-declarations
                const marketValue = this.realEstateCalculationService.calculatedMarketValue({
                    marketValue: realEstate.marketValue,
                    purchasePrice: realEstate.purchasePrice,
                    marketValueType: realEstate.marketValueType,
                    creditPurpose: realEstate.creditPurpose,
                    realEstateType: realEstate.realEstateType,
                });
                if (CalculationHelperService.isNullOrNaN(marketValue) || (marketValue as number) < 0) {
                    return 0;
                }
                else if (marketValue === 0) {
                    throw new DivisionByZeroError();
                }
                else {
                    return (grossFinancingRequirement + liabilities.reduce((pV, cL) => pV + this.liabilityCalculationService.currentAmountSecuredByLandRegisterNotCovered({
                        currentAmount: cL.currentAmount,
                        covered: cL.covered,
                        securedByLandRegister: cL.securedByLandRegister,
                    }), 0) + newLiabilities.reduce((pV, cNL) => pV + this.newLiabilityCalculationService.amountSecuredByLandRegister({
                        amount: cNL.amount,
                        securedByLandRegister: cNL.securedByLandRegister,
                    }), 0)) / (marketValue as number);
                }
            case 4:
            case 5:
            case 6:
            default:
                // eslint-disable-next-line no-case-declarations
                const filteredLiabilties = liabilities.filter(it => it.covered !== true && it.securedByLandRegister === true && realEstates.some(r => r.id === it.securedRealEstateIds?.find(realEstatesId => realEstatesId === r.id) && r.collateralization === Collateralization.NewFinancing && r.objectPurpose !== ObjectPurposeType.ForSale));
                // eslint-disable-next-line no-case-declarations
                const filteredNewLiabilties = newLiabilities.filter(it => it.securedByLandRegister === true && realEstates.some(r => r.id === it.securedRealEstateIds?.find(realEstatesId => realEstatesId === r.id) && r.collateralization === Collateralization.NewFinancing && r.objectPurpose !== ObjectPurposeType.ForSale));
                // eslint-disable-next-line no-case-declarations
                const currentAmountSecuredByLandRegisterNotCoveredLiabilites = filteredLiabilties.reduce((pV, cL) => pV + pV + this.liabilityCalculationService.currentAmountSecuredByLandRegisterNotCovered({
                    currentAmount: cL.currentAmount,
                    covered: cL.covered,
                    securedByLandRegister: cL.securedByLandRegister,
                }), 0);
                // eslint-disable-next-line no-case-declarations
                const amountSecuredByLandRegister = filteredNewLiabilties.reduce((pV, cNL) => pV + this.newLiabilityCalculationService.amountSecuredByLandRegister({
                    amount: cNL.amount,
                    securedByLandRegister: cNL.securedByLandRegister,
                }), 0);

                // eslint-disable-next-line no-case-declarations
                const filteredRealEstates = realEstates.filter(it => it.collateralization === Collateralization.NewFinancing && it.objectPurpose !== ObjectPurposeType.ForSale);
                // eslint-disable-next-line no-case-declarations
                const marketValueSum = filteredRealEstates.reduce((pV, cR) => {
                    if (cR.objectPurpose === ObjectPurposeType.Finance) {
                        return pV + (this.realEstateCalculationService.calculatedMarketValue({
                            marketValue: cR.marketValue,
                            purchasePrice: cR.purchasePrice,
                            marketValueType: cR.marketValueType,
                            creditPurpose: cR.creditPurpose,
                            realEstateType: cR.realEstateType,
                        }) ?? 0);
                    }
                    else {
                        return pV + (cR.marketValue ?? 0);
                    }
                }, 0);

                if (marketValueSum < 0 || filteredRealEstates.length === 0) {
                    return 0;
                }
                else if (marketValueSum === 0) {
                    throw new DivisionByZeroError();
                }

                return (grossFinancingRequirement + currentAmountSecuredByLandRegisterNotCoveredLiabilites + amountSecuredByLandRegister) / marketValueSum;
        }

    }

    /**
     * Berechnet den DSTI
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalAmountNotCoveredLiabilities Summe der fiktiven Raten aller nicht abgedeckten bestehenden Verpflichtungen in €
     * @param {number} params.newRate Spezielle fiktive Rate zur Berücksichtigung im DSTI in €
     * @param {number} params.netIncomePerMonth Zu berücksichtigendes Nettoeinkommen aller Kreditnehmer in €
     * @param {number | undefined} params.otherCosts Sonstiges Haushaltskosten in €
     * @throws {DivisionByZeroError}
     * @returns {number} DSTI
     */
    public dsti({ fictionalAmountNotCoveredLiabilities, newRate, netIncomePerMonth, otherCosts }: {
        /**
         * Summe der fiktiven Raten aller nicht abgedeckten bestehenden Verpflichtungen in €
         */
        fictionalAmountNotCoveredLiabilities: number;
        /**
         * Spezielle fiktive Rate zur Berücksichtigung im DSTI in €
         */
        newRate: number;
        /**
         * Zu berücksichtigendes Nettoeinkommen aller Kreditnehmer in €
         */
        netIncomePerMonth: number;
        /**
         * Sonstiges Haushaltskosten in €
         */
        otherCosts?: number;
    }): number {
        if ((netIncomePerMonth - (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0)) === 0) {
            throw new DivisionByZeroError();
        }
        else {
            return (fictionalAmountNotCoveredLiabilities + newRate) / (netIncomePerMonth - (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0));
        }
    }

    /**
     * Berechnet spezielle fiktive Rate zur Berechnung des DST1
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.constructionCreditFictionalRate Fiktive Rate für Wohnbaukonto in %
     * @param {number} params.fictionalAmount Fiktive Rate in €
     * @param {number} params.fictionalAmountNewLiabilities Summe aller fiktiven Raten neuer Verpflichtungen in €
     * @param {number | undefined} params.constructionCreditAmount Wohnbaukonto - Kreditbetrag in €
     * @returns {number} Fiktive Rate zur Berechnung des DST1 in €
     */
    public newRate({ constructionCreditFictionalRate, fictionalAmount, fictionalAmountNewLiabilities, constructionCreditAmount }: {
        /**
         * Fiktive Rate für Wohnbaukonto in %
         */
        constructionCreditFictionalRate: number;
        /**
         * Fiktive Rate in €
         */
        fictionalAmount: number;
        /**
         * Summe aller fiktiven Raten neuer Verpflichtungen in €
         */
        fictionalAmountNewLiabilities: number;
        /**
         * Wohnbaukonto - Kreditbetrag in €
         */
        constructionCreditAmount?: number;
    }): number {
        return (!CalculationHelperService.isNullOrNaN(constructionCreditAmount) ? ((constructionCreditAmount as number) * this.internalCalculationService.interestRateMonthCorrected({ rate: constructionCreditFictionalRate })) : 0) + fictionalAmount + fictionalAmountNewLiabilities;
    }

    /**
     * Ermittelt Splitting durch Verhältnis Kosten zu Eigenmittel
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumAdditionalCosts Kaufnebenkosten in €
     * @param {number} params.sumOwnCapital Summe Eigenmittel in €
     * @param {number | undefined} params.otherCosts Einrichtung in €
     * @param {number | undefined} params.funding Förderung in €
     * @param {number | undefined} params.otherOwnCapital Sonstige Mittel in €
     * @returns {boolean} Wird Kredit gesplittet
     */
    public useRatioSplitting({ sumAdditionalCosts, sumOwnCapital, otherCosts, funding, otherOwnCapital }: {
        /**
         * Kaufnebenkosten in €
         */
        sumAdditionalCosts: number;
        /**
         * Summe Eigenmittel in €
         */
        sumOwnCapital: number;
        /**
         * Einrichtung in €
         */
        otherCosts?: number;
        /**
         * Förderung in €
         */
        funding?: number;
        /**
         * Sonstige Mittel in €
         */
        otherOwnCapital?: number;
    }): boolean {
        return ((!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0) + sumAdditionalCosts) >
            (sumOwnCapital + (!CalculationHelperService.isNullOrNaN(funding) ? (funding as number) : 0) + (!CalculationHelperService.isNullOrNaN(otherOwnCapital) ? (otherOwnCapital as number) : 0));
    }

    /**
     * Berechnet KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCredit1 Kreditbetrag berechnet nach Marktwert und LTV in €
     * @param {number} params.comfortCredit2 Kreditbetrag berechnet nach Kosten und Eigenmittel in €
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {boolean | undefined} params.splitting Wird Kredit gesplittet
     * @returns {number} KomfortKredit in €
     */
    public comfortCredit({ comfortCredit1, comfortCredit2, grossFinancingRequirement, splitting }: {
        /**
         * Kreditbetrag berechnet nach Marktwert und LTV in €
         */
        comfortCredit1: number;
        /**
         * Kreditbetrag berechnet nach Kosten und Eigenmittel in €
         */
        comfortCredit2: number;
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Wird Kredit gesplittet
         */
        splitting?: boolean;
    }): number {
        const result = Math.max(0, Math.min(comfortCredit1, comfortCredit2, grossFinancingRequirement));
        if (splitting === undefined) {
            return result;
        }
        else if (splitting === true) {
            return Math.floor(result / 1000) * 1000;
        }
        else {
            return Math.ceil(result / 1000) * 1000;
        }
    }

    /**
     * Berechnet Eintragungsgebühr KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.registrationChargesPercent Eintragungsgebühr in %
     * @param {number | undefined} params.registrationChargesGrossFinancingRequirement Eintragungsgebühr des Langfristigen Finanzierungsbedarf Brutto in €
     * @returns {number} Eintragungsgebühr KomfortKredit in €
     */
    public comfortCreditRegistrationCharges({ grossFinancingRequirement, comfortCredit, registrationChargesPercent, registrationChargesGrossFinancingRequirement }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Eintragungsgebühr in %
         */
        registrationChargesPercent?: number;
        /**
         * Eintragungsgebühr des Langfristigen Finanzierungsbedarf Brutto in €
         */
        registrationChargesGrossFinancingRequirement?: number;
    }): number {
        if (!CalculationHelperService.isNullOrNaN(registrationChargesPercent)) {
            return CalculationHelperService.round(Math.max(0, (registrationChargesPercent as number) / 100 * comfortCredit), 2);
        }
        else if (!CalculationHelperService.isNullOrNaN(registrationChargesGrossFinancingRequirement)) {
            return CalculationHelperService.round(Math.max(0, (registrationChargesGrossFinancingRequirement as number) / grossFinancingRequirement * comfortCredit), 2);
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet Schätzgebühr KomfortKredit
     * Ergebnis mind. 300€
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.estimateChargesPercent Schätzgbühr in %
     * @param {number | undefined} params.estimateChargesGrossFinancingRequirement Schätzgbühr des Langfristigen Finanzierungsbedarf Brutto in €
     * @returns {number} Schätzgebühr KomfortKredit in €
     */
    public comfortCreditEstimateCharges({ grossFinancingRequirement, comfortCredit, estimateChargesPercent, estimateChargesGrossFinancingRequirement }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Schätzgbühr in %
         */
        estimateChargesPercent?: number;
        /**
         * Schätzgbühr des Langfristigen Finanzierungsbedarf Brutto in €
         */
        estimateChargesGrossFinancingRequirement?: number;
    }): number {
        if (!CalculationHelperService.isNullOrNaN(estimateChargesPercent)) {
            return CalculationHelperService.round(Math.max(300, (estimateChargesPercent as number) / 100 * comfortCredit), 2);
        }
        else if (!CalculationHelperService.isNullOrNaN(estimateChargesGrossFinancingRequirement)) {
            return CalculationHelperService.round(Math.max(300, (estimateChargesGrossFinancingRequirement as number) / grossFinancingRequirement * comfortCredit), 2);
        }
        else {
            return 300;
        }
    }

    /**
     * Berechnet Bearbeitungsspesen KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.processingChargesPercent Bearbeitungsspesen in %
     * @param {number | undefined} params.processingChargesGrossFinancingRequirement Bearbeitungsspesen des Langfristigen Finanzierungsbedarf Brutto in €
     * @returns {number} Bearbeitungsspesen KomfortKredit in €
     */
    public comfortCreditProcessingCharges({ grossFinancingRequirement, comfortCredit, processingChargesPercent, processingChargesGrossFinancingRequirement }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Bearbeitungsspesen in %
         */
        processingChargesPercent?: number;
        /**
         * Bearbeitungsspesen des Langfristigen Finanzierungsbedarf Brutto in €
         */
        processingChargesGrossFinancingRequirement?: number;
    }): number {
        if (!CalculationHelperService.isNullOrNaN(processingChargesPercent)) {
            return CalculationHelperService.round(Math.max(0, (processingChargesPercent as number) / 100 * comfortCredit), 2);
        }
        else if (!CalculationHelperService.isNullOrNaN(processingChargesGrossFinancingRequirement)) {
            return CalculationHelperService.round(Math.max(0, (processingChargesGrossFinancingRequirement as number) / grossFinancingRequirement * comfortCredit), 2);
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet KomfortKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCredit KomfortKredit in €
     * @returns {number} KomfortKredit Plus in €
     */
    public comfortCreditPlus({ splitting, grossFinancingRequirement, comfortCredit }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * KomfortKredit in €
         */
        comfortCredit: number;
    }): number {
        if (splitting) {
            return Math.max(0, grossFinancingRequirement - comfortCredit);
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet Bearbeitungsspesen KomfortKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Produktsplitting angewendet
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.comfortCreditPlus Kreditbetrag KomfortKredit Plus in €
     * @param {number | undefined} params.processingChargesPercent Bearbeitungsspesen in %
     * @param {number | undefined} params.processingChargesGrossFinancingRequirement Bearbeitungsspesen des Langfristigen Finanzierungsbedarf Brutto in €
     * @returns {number} Bearbeitungsspesen KomfortKredit Plus in €
     */
    public comfortCreditPlusProcessingCharges({ splitting, grossFinancingRequirement, comfortCreditPlus, processingChargesPercent, processingChargesGrossFinancingRequirement }: {
        /**
         * Wird Produktsplitting angewendet
         */
        splitting: boolean;
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Kreditbetrag KomfortKredit Plus in €
         */
        comfortCreditPlus: number;
        /**
         * Bearbeitungsspesen in %
         */
        processingChargesPercent?: number;
        /**
         * Bearbeitungsspesen des Langfristigen Finanzierungsbedarf Brutto in €
         */
        processingChargesGrossFinancingRequirement?: number;
    }): number {
        if (splitting) {
            if (!CalculationHelperService.isNullOrNaN(processingChargesPercent)) {
                return CalculationHelperService.round(Math.max(0, (processingChargesPercent as number) / 100 * comfortCreditPlus), 2);
            }
            else if (!CalculationHelperService.isNullOrNaN(processingChargesGrossFinancingRequirement)) {
                return CalculationHelperService.round(Math.max(0, (processingChargesGrossFinancingRequirement as number) / grossFinancingRequirement * comfortCreditPlus), 2);
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet Auszahlungsbetrag KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number} params.comfortCreditProcessingCharges Bearbeitungsspesen KomfortKredit in €
     * @param {number} params.comfortCreditEstimateCharges Schätzgebühr KomfortKredit in €
     * @param {number} params.comfortCreditRegistrationCharges Eintragungsgebühr KomfortKredit in €
     * @param {number} params.legalisationFee Legalisierungsgebühr KomfortKredit in €
     * @param {number} params.landRegisterRequest Grundbuchgesuch in €
     * @param {number} params.landRegisterExtract Grundbuchauszug in €
     * @returns {number} Auszahlungsbetrag KomfortKredit in €
     */
    public comfortCreditPayout({ comfortCredit, comfortCreditProcessingCharges, comfortCreditEstimateCharges, comfortCreditRegistrationCharges, legalisationFee, landRegisterRequest, landRegisterExtract }: {
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Bearbeitungsspesen KomfortKredit in €
         */
        comfortCreditProcessingCharges: number;
        /**
         * Schätzgebühr KomfortKredit in €
         */
        comfortCreditEstimateCharges: number;
        /**
         * Eintragungsgebühr KomfortKredit in €
         */
        comfortCreditRegistrationCharges: number;
        /**
         * Legalisierungsgebühr KomfortKredit in €
         */
        legalisationFee: number;
        /**
         * Grundbuchgesuch in €
         */
        landRegisterRequest: number;
        /**
         * Grundbuchauszug in €
         */
        landRegisterExtract: number;
    }): number {
        return Math.max(0, comfortCredit -
            comfortCreditProcessingCharges -
            comfortCreditEstimateCharges -
            comfortCreditRegistrationCharges -
            legalisationFee -
            landRegisterRequest -
            landRegisterExtract);
    }

    /**
     * Berechnet Auszahlungsbetrag KomfortKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditPlus Kreditbetrag KomfortKredit Plus in €
     * @param {number} params.comfortCreditPlusProcessingCharges Bearbeitungsspesen KomfortKredit Plus in €
     * @returns {number} Auszahlungsbetrag KomfortKredit Plus in €
     */
    public comfortCreditPlusPayout({ splitting, comfortCreditPlus, comfortCreditPlusProcessingCharges }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Kreditbetrag KomfortKredit Plus in €
         */
        comfortCreditPlus: number;
        /**
         * Bearbeitungsspesen KomfortKredit Plus in €
         */
        comfortCreditPlusProcessingCharges: number;
    }): number {
        return splitting ? Math.max(0, comfortCreditPlus - comfortCreditPlusProcessingCharges) : 0;
    }

    /**
     * Berechnet Gesamtkreditbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number} params.comfortCreditPlus Kreditbetrag KomfortKredit Plus in €
     * @returns {number} Gesamtkreditbetrag in €
     */
    public comfortCreditTotalAmount({ splitting, comfortCredit, comfortCreditPlus }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Kreditbetrag KomfortKredit Plus in €
         */
        comfortCreditPlus: number;
    }): number {
        return Math.max(0, comfortCredit + (splitting ? comfortCreditPlus : 0));
    }

    /**
     * Berechnet Gesamtauszahlungsbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditPayout Auszahlungsbetrag KomfortKredit in €
     * @param {number} params.comfortCreditPlusPayout Auszahlungsbetrag KomfortKredit Plus in €
     * @returns {number} Gesamtauszahlungsbetrag in €
     */
    public comfortCreditTotalPayout({ splitting, comfortCreditPayout, comfortCreditPlusPayout }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Auszahlungsbetrag KomfortKredit in €
         */
        comfortCreditPayout: number;
        /**
         * Auszahlungsbetrag KomfortKredit Plus in €
         */
        comfortCreditPlusPayout: number;
    }): number {
        return Math.max(0, comfortCreditPayout + (splitting ? comfortCreditPlusPayout : 0));
    }

    /**
     * Berechnet Monatliche Rate KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.requestedDebitRate Zinssatz in %
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche Rate KomfortKredit in €
     */
    public comfortCreditMonthlyDebitRate({ comfortCredit, requestedDebitRate, assumedDuration, gracePeriod, bankAccountFee }: {
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Zinssatz in %
         */
        requestedDebitRate?: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: comfortCredit, rate: requestedDebitRate }) || !CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }
        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }
        const divisor2 = 1 - Math.pow(1 / divisor1, (!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0));
        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }

        return CalculationHelperService.round(comfortCredit * this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate }) / divisor2 + bankAccountFee, 2);
    }

    /**
     * Berechnet Zinsrate KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.requestedDebitRate Zinssatz in %
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @returns {number} Zinsrate KomfortKredit in €
     */
    public comfortCreditMonthlyInterestRate({ comfortCredit, requestedDebitRate, bankAccountFee }: {
        /**
         *  Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Zinssatz in %
         */
        requestedDebitRate?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: comfortCredit, rate: requestedDebitRate })) {
            return 0;
        }
        return CalculationHelperService.round(comfortCredit * this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate }) + bankAccountFee, 2);
    }

    /**
     * Berechnet Monatliche Rate KomfortKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditPlus Kreditbetrag KomfortKredit Plus in €
     * @param {number | undefined} params.requestedDebitRate Zinssatz in %
     * @param {number | undefined} params.duration Laufzeit in Monaten - default: 120
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche Rate KomfortKredit Plus in €
     */
    public comfortCreditPlusMonthlyDebitRate({ splitting, comfortCreditPlus, requestedDebitRate, duration }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         *  Kreditbetrag KomfortKredit Plus in €
         */
        comfortCreditPlus: number;
        /**
         * Zinssatz in %
         */
        requestedDebitRate?: number;
        /**
         * Laufzeit in Monaten - default: 120
         */
        duration?: number;
    }): number {
        duration = !CalculationHelperService.isNullOrNaN(duration) ? (duration as number) : COMFORTCREDIT_PLUS_ASSUMEDDURATION;
        if (!splitting || this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: comfortCreditPlus, rate: requestedDebitRate })) {
            return 0;
        }
        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }
        const divisor2 = 1 - Math.pow(1 / divisor1, duration);

        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }
        return CalculationHelperService.round(comfortCreditPlus * this.internalCalculationService.interestRateMonthCorrected({ rate: requestedDebitRate }) / divisor2, 2);
    }

    /**
     * Berechnet Gesamtrate(1. - 10. Jahr)
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditMonthlyDebitRate Monatliche Rate KomfortKredit in €
     * @param {number} params.comfortCreditPlusMonthlyDebitRate Monatliche Rate KomfortKredit Plus in €
     * @returns {number} Gesamtrate(1. - 10. Jahr) in €
     */
    public comfortCreditTotalMonthlyDebitRateToYearTen({ splitting, comfortCreditMonthlyDebitRate, comfortCreditPlusMonthlyDebitRate }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Monatliche Rate KomfortKredit in €
         */
        comfortCreditMonthlyDebitRate: number;
        /**
         * Monatliche Rate KomfortKredit Plus in €
         */
        comfortCreditPlusMonthlyDebitRate: number;
    }): number {
        return Math.max(0, comfortCreditMonthlyDebitRate + (splitting ? comfortCreditPlusMonthlyDebitRate : 0));
    }

    /**
     * Berechnet Gesamtrate(ab 11. Jahr)
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCreditMonthlyDebitRate Monatliche Rate KomfortKredit in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.comfortCreditPlusDuration Laufzeit KomfortKredit Plus in Monaten - default: 120
     * @returns {number} Gesamtrate(ab 11. Jahr) in €
     */
    public comfortCreditTotalMonthlyDebitRateFromYearEleven({ comfortCreditMonthlyDebitRate, assumedDuration, comfortCreditPlusDuration }: {
        /**
         * Monatliche Rate KomfortKredit in €
         */
        comfortCreditMonthlyDebitRate: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Laufzeit KomfortKredit Plus in Monaten - default: 120
         */
        comfortCreditPlusDuration?: number;
    }): number {
        comfortCreditPlusDuration = !CalculationHelperService.isNullOrNaN(comfortCreditPlusDuration) ? (comfortCreditPlusDuration as number) : COMFORTCREDIT_PLUS_ASSUMEDDURATION;
        return CalculationHelperService.isNullOrNaN(assumedDuration) || (assumedDuration as number) <= comfortCreditPlusDuration ? 0 : comfortCreditMonthlyDebitRate;
    }

    /**
     * Berechnet Monatliche fiktive Rate KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.comfortCredit Kreditbetrag KomfortKredit in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche fiktive Rate KomfortKredit in €
     */
    public comfortCreditFictionalAmount({ fictionalRate, comfortCredit, assumedDuration, gracePeriod, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Kreditbetrag KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: comfortCredit, rate: fictionalRate }) || !CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }

        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }

        const divisor2 = 1 - Math.pow(1 / divisor1, (!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0));
        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }

        return CalculationHelperService.round(comfortCredit * this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }) / divisor2 + bankAccountFee, 2);
    }

    /**
     * Berechnet Monatliche fiktive Rate KomfortKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditPlus Kreditbetrag KomfortKredit Plus in €
     * @param {number | undefined} params.duration Laufzeit KomfortKredit Plus in Monaten - default: 120
     * @throws {DivisionByZeroError}
     * @returns {number} Monatliche fiktive Rate KomfortKredit Plus in €
     */
    public comfortCreditPlusFictionalAmount({ fictionalRate, splitting, comfortCreditPlus, duration }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Wird Kredit gesplitte
         */
        splitting: boolean;
        /**
         * Kreditbetrag KomfortKredit Plus in €
         */
        comfortCreditPlus: number;
        /**
         * Laufzeit KomfortKredit Plus in Monaten - default: 120
         */
        duration?: number;
    }): number {
        duration = !CalculationHelperService.isNullOrNaN(duration) ? (duration as number) : COMFORTCREDIT_PLUS_ASSUMEDDURATION;
        if (!splitting || this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: comfortCreditPlus, rate: fictionalRate })) {
            return 0;
        }

        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }

        const divisor2 = 1 - Math.pow(1 / divisor1, duration);
        if (divisor2 === 0) {
            throw new DivisionByZeroError();
        }

        return CalculationHelperService.round(comfortCreditPlus * this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }) / divisor2, 2);
    }

    /**
     * Berechnet Monatliche fiktive Rate Gesamt
     *
     * @param {object} params Parameterobjekt
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {number} params.comfortCreditFictionalAmount Monatliche fiktive Rate KomfortKredit in €
     * @param {number} params.comfortCreditPlusFictionalAmount Monatliche fiktive Rate KomfortKredit Plus in €
     * @returns {number} Monatliche fiktive Rate Gesamt in €
     */
    public comfortCreditTotalFictionalAmount({ splitting, comfortCreditFictionalAmount, comfortCreditPlusFictionalAmount }: {
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Monatliche fiktive Rate KomfortKredit in €
         */
        comfortCreditFictionalAmount: number;
        /**
         * Monatliche fiktive Rate KomfortKredit Plus in €
         */
        comfortCreditPlusFictionalAmount: number;
    }): number {
        return CalculationHelperService.round(Math.max(0, comfortCreditFictionalAmount + (splitting ? comfortCreditPlusFictionalAmount : 0)), 2);
    }

    /**
     * Ermittelt Durch HHR gedeckt
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.comfortCreditTotalFictionalAmount Monatliche fiktive Rate Gesamt in €
     * @param {number} params.acceptableCreditRate Zumutbare Kreditrate in €
     * @returns {boolean} Durch Haushaltsrechnung gedeckt
     */
    public hhrCovered({ comfortCreditTotalFictionalAmount, acceptableCreditRate }: {
        /**
         * Monatliche fiktive Rate Gesamt in €
         */
        comfortCreditTotalFictionalAmount: number;
        /**
         * Zumutbare Kreditrate in €
         */
        acceptableCreditRate: number;
    }): boolean {
        return comfortCreditTotalFictionalAmount < acceptableCreditRate;
    }

    /**
     * Berechnet KomfortKredit nach Marktwert und LTV
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.currentAmountSecuredByLandRegisterNotCoveredLiabilites Summe der Dzt. Aushaftung aller grundbücherlich besicherter, nicht abgedeckter bestehender Verpflichtungen in €
     * @param {number} params.amountSecuredByLandRegisterNewLiabilites Summe der Kreditbeträge aller grundbücherlich besicherter neuen Verpflichtungen in €
     * @param {number | undefined} params.marketValue Marktwert (ggf. aufgeschlagen) in €
     * @param {number | undefined} params.ltvFactor LTV-Faktor
     * @returns {number} KomfortKredit nach Marktwert und LTV in €
     */
    public comfortCredit1({ currentAmountSecuredByLandRegisterNotCoveredLiabilites, amountSecuredByLandRegisterNewLiabilites, marketValue, ltvFactor }: {
        /**
         * Summe der Dzt. Aushaftung aller grundbücherlich besicherter, nicht abgedeckter bestehender Verpflichtungen in €
         */
        currentAmountSecuredByLandRegisterNotCoveredLiabilites: number;
        /**
         * Summe der Kreditbeträge aller grundbücherlich besicherter neuen Verpflichtungen in €
         */
        amountSecuredByLandRegisterNewLiabilites: number;
        /**
         * Marktwert (ggf. aufgeschlagen) in €
         */
        marketValue?: number;
        /**
         * LTV-Faktor
         */
        ltvFactor?: number;
    }): number {
        if (CalculationHelperService.isNullOrNaN(marketValue) || CalculationHelperService.isNullOrNaN(ltvFactor)) {
            return 0;
        }
        return Math.max(0, (marketValue as number) * (ltvFactor as number) - currentAmountSecuredByLandRegisterNotCoveredLiabilites - amountSecuredByLandRegisterNewLiabilites);
    }

    /**
     * Berechnet KomfortKredit nach Kosten und Eigenmittel
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number} params.sumAdditionalCosts Kaufnebenkosten in €
     * @param {number} params.sumOwnCapital Summe Eigenmittel in €
     * @param {number | undefined} params.otherCosts Einrichtung in €
     * @param {number | undefined} params.funding Förderung in €
     * @param {number | undefined} params.otherOwnCapital Sonstige Mittel in €
     * @returns {number} KomfortKredit nach Kosten und Eigenmittel in €
     */
    public comfortCredit2({ grossFinancingRequirement, sumAdditionalCosts, sumOwnCapital, otherCosts, funding, otherOwnCapital }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Kaufnebenkosten in €
         */
        sumAdditionalCosts: number;
        /**
         * Summe Eigenmittel in €
         */
        sumOwnCapital: number;
        /**
         * Einrichtung in €
         */
        otherCosts?: number;
        /**
         * Förderung in €
         */
        funding?: number;
        /**
         * Sonstige Mittel in €
         */
        otherOwnCapital?: number;
    }): number {
        switch (this.version) {
            case 0:
            case 1:
                return Math.max(0, grossFinancingRequirement -
                    sumAdditionalCosts -
                    (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0) +
                    sumOwnCapital +
                    (!CalculationHelperService.isNullOrNaN(funding) ? (funding as number) : 0) +
                    (!CalculationHelperService.isNullOrNaN(otherOwnCapital) ? (otherOwnCapital as number) : 0));
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            default:
                return Math.max(0, grossFinancingRequirement -
                    (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0) +
                    sumOwnCapital +
                    (!CalculationHelperService.isNullOrNaN(funding) ? (funding as number) : 0) +
                    (!CalculationHelperService.isNullOrNaN(otherOwnCapital) ? (otherOwnCapital as number) : 0));
        }
    }

    /**
     * Berechnet die maximale Verschuldensrate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.netIncome12 Monatliches Gesamteinkommen (Berechnungsmethode 12/12) aller Kreditnehmer in €
     * @param {number | undefined} params.otherCosts Alimente in €
     * @param {number | undefined} params.assignedDsti Zugewiesener DSTI-Faktor
     * @returns {number} Maximale Verschuldensrate in €
     */
    public maxDebtRate({ netIncome12, otherCosts, assignedDsti }: {
        /**
         * Monatliches Gesamteinkommen (Berechnungsmethode 12/12) aller Kreditnehmer in €
         */
        netIncome12: number;
        /**
         * Alimente in €
         */
        otherCosts?: number;
        /**
         * Zugewiesener DSTI-Faktor
         */
        assignedDsti?: number;
    }): number {
        if (CalculationHelperService.isNullOrNaN(assignedDsti)) {
            return 0;
        }
        return (netIncome12 - (!CalculationHelperService.isNullOrNaN(otherCosts) ? (otherCosts as number) : 0)) * (assignedDsti as number);
    }

    /**
     * Berechnet die maximale fiktive Kreditrate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.maxDebtRate Maximale Verschuldensrate in €
     * @param {number} params.fictionalAmountNotCoveredLiabilities Fiktive Raten aller nicht abgedeckten bestehenden Kredite in €
     * @param {number} params.fictionalAmountNewLiabilities Fiktive Raten neuer Kredite in €
     * @returns {number} Maximale fiktive Kreditrate in €
     */
    public maxComfortCreditFictionalTotalAmount({ maxDebtRate, fictionalAmountNotCoveredLiabilities, fictionalAmountNewLiabilities }: {
        /**
         * Maximale Verschuldensrate in €
         */
        maxDebtRate: number;
        /**
         * Fiktive Raten aller nicht abgedeckten bestehenden Kredite in €
         */
        fictionalAmountNotCoveredLiabilities: number;
        /**
         * Fiktive Raten neuer Kredite in €
         */
        fictionalAmountNewLiabilities: number;
    }): number {
        return Math.max(0, CalculationHelperService.round(maxDebtRate - (fictionalAmountNotCoveredLiabilities + fictionalAmountNewLiabilities), 2));
    }

    /**
     * Berechnet den hochgerechneten Kreditbetrag anhand der maximalen fiktiven Rate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.maxComfortCreditFictionalTotalAmount Maximale fiktive Kreditrate in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.gracePeriod Tilgungsfreier Zeitraum in Monaten
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @returns {number} Hochgerechneter Kreditbetrag anhand der maximalen fiktiven Rate €
     * @throws {DivisionByZeroError}
     */
    public extrapolatedComfortCredit({ fictionalRate, maxComfortCreditFictionalTotalAmount, assumedDuration, gracePeriod, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Maximale fiktive Kreditrate in €
         */
        maxComfortCreditFictionalTotalAmount: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Tilgungsfreier Zeitraum in Monaten
         */
        gracePeriod?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: maxComfortCreditFictionalTotalAmount, rate: fictionalRate }) || !CalculationHelperService.isGreaterThan(assumedDuration, gracePeriod)) {
            return 0;
        }

        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }

        const divisor2 = 1 - Math.pow(1 / divisor1, (!CalculationHelperService.isNullOrNaN(assumedDuration) ? (assumedDuration as number) : 0) - (!CalculationHelperService.isNullOrNaN(gracePeriod) ? (gracePeriod as number) : 0));

        return (maxComfortCreditFictionalTotalAmount - bankAccountFee) * divisor2 / this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
    }

    /**
     * Berechnet den maximal möglichen KomforKredit
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.extrapolatedComfortCredit Hochgerechneter Kreditbetrag anhand der maximalen fiktiven Rate in €
     * @param {number} params.comfortCredit KomfortKredit in €
     * @returns {number} Maximal möglicher KomforKredit in €
     */
    public maxComfortCredit({ extrapolatedComfortCredit, comfortCredit }: {
        /**
         * Hochgerechneter Kreditbetrag anhand der maximalen fiktiven Rate in €
         */
        extrapolatedComfortCredit: number;
        /**
         * KomfortKredit in €
         */
        comfortCredit: number;
    }): number {
        if (extrapolatedComfortCredit > comfortCredit) {
            return comfortCredit;
        }
        else {
            return extrapolatedComfortCredit;
        }
    }

    /**
     * Berechnet die maximale fiktive Rate für den KomforKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.extrapolatedComfortCredit Hochgerechneter Kreditbetrag anhand der maximalen fiktiven Rate in €
     * @param {number} params.maxComfortCreditFictionalTotalAmount Maximale fiktive Kreditrate in €
     * @param {number} params.comfortCredit KomfortKredit in €
     * @param {number} params.comfortCreditFictionalAmount Fiktive Rate des KomfortKredit in €
     * @returns {number} Maximale fiktive Rate für den KomforKredit Plus in €
     */
    public maxComfortCreditPlusFictionalAmount({ extrapolatedComfortCredit, maxComfortCreditFictionalTotalAmount, comfortCredit, comfortCreditFictionalAmount }: {
        /**
         * Hochgerechneter Kreditbetrag anhand der maximalen fiktiven Rate in €
         */
        extrapolatedComfortCredit: number;
        /**
         * Maximale fiktive Kreditrate in €
         */
        maxComfortCreditFictionalTotalAmount: number;
        /**
         * KomfortKredit in €
         */
        comfortCredit: number;
        /**
         * Fiktive Rate des KomfortKredit in €
         */
        comfortCreditFictionalAmount: number;
    }): number {
        if (extrapolatedComfortCredit > comfortCredit) {
            return maxComfortCreditFictionalTotalAmount - comfortCreditFictionalAmount;
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet den maximalen KomforKredit Plus
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.maxComfortCreditPlusFictionalAmount Maximale fiktive Rate für den KomforKredit Plus in €
     * @param {number | undefined} params.duration Laufzeit KomfortKredit Plus in Monaten - default: 120
     * @returns {number} Maximaler KomforKredit Plus in €
     * @throws {DivisionByZeroError}
     */
    public maxComfortCreditPlus({ fictionalRate, maxComfortCreditPlusFictionalAmount, duration }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Maximale fiktive Rate für den KomforKredit Plus in €
         */
        maxComfortCreditPlusFictionalAmount: number;
        /**
         * Laufzeit KomfortKredit Plus in Monaten - default: 120
         */
        duration?: number;
    }): number {
        duration = !CalculationHelperService.isNullOrNaN(duration) ? (duration as number) : COMFORTCREDIT_PLUS_ASSUMEDDURATION;
        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: maxComfortCreditPlusFictionalAmount, rate: fictionalRate })) {
            return 0;
        }

        const divisor1 = 1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
        if (divisor1 === 0) {
            throw new DivisionByZeroError();
        }

        const divisor2 = 1 - Math.pow(1 / divisor1, duration);
        return maxComfortCreditPlusFictionalAmount * divisor2 / this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate });
    }

    /**
     * Berechnet den maximal möglichen Kreditbetrag
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.maxComfortCredit Maximaler KomforKredit in €
     * @param {number} params.maxComfortCreditPlus Maximaler KomforKredit Plus in €
     * @returns {number} Maximal möglicher Kreditbetrag in €
     */
    public maxComfortCreditTotalAmount({ maxComfortCredit, maxComfortCreditPlus }: {
        /**
         * Maximaler KomforKredit in €
         */
        maxComfortCredit: number;
        /**
         * Maximaler KomforKredit Plus in €
         */
        maxComfortCreditPlus: number;
    }): number {
        return Math.max(0, Math.floor((maxComfortCredit + maxComfortCreditPlus) / 1000) * 1000);
    }

    /**
     * Berechnet Legalisierungsgebühr des KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {ILegalisationFeeBase[]} params.legalisationFeeBases Matrix zur Berechnung der Legalisierungsgebühr
     * @param {number} params.comfortCredit KomfortKredit in €
     * @returns {number | undefined} Legalisierungsgebühr des KomfortKredit in €
     * @throws {ArgumentError} Der Parameter 'legalisationFeeBases' ist nicht valide.
     */
    public comfortCreditLegalisationFee({ legalisationFeeBases, comfortCredit }: {
        /**
         * Matrix zur Berechnung der Legalisierungsgebühr
         */
        legalisationFeeBases: ILegalisationFeeBase[];
        /**
         * KomfortKredit in €
         */
        comfortCredit: number;
    }): number | undefined {
        return this.internalCalculationService.getLegalisationFee({ legalisationFeeBases, creditAmount: comfortCredit });
    }

    /**
     * Ermittelt Splitting durch LTV
     *
     * @param {object} params Parameterobjekt
     * @param {ISplittingRule[]} params.splittingRules Regeln zum Splitting
     * @param {number} params.debitorsCount Anzahl der Kreditnehmer
     * @param {number} params.ltv LTV
     * @param {number} params.netIncome Monatliches Gesamteinkommen aller Kreditnehmer in €
     * @param {number |undefined} params.morixRating Morix-Rating des Objekts mit dem höchsten Marktwert
     * @returns {boolean} Wird Kredit gesplittet
     * @throws {ArgumentError} Der Parameter 'splittingRules' ist nicht valide.
     */
    public useLtvSplitting({ splittingRules, debitorsCount, ltv, netIncome, morixRating }: {
        /**
         * Regeln zum Splitting
         */
        splittingRules: ISplittingRule[];
        /**
         * Anzahl der Kreditnehmer
         */
        debitorsCount: number;
        /**
         * LTV
         */
        ltv: number;
        /**
         * Monatliches Gesamteinkommen aller Kreditnehmer in €
         */
        netIncome: number;
        /**
         * Morix-Rating des Objekts mit dem höchsten Marktwert
         */
        morixRating?: number;
    }): boolean {
        this.checkSplittingRules(splittingRules);
        return splittingRules.some(it => (CalculationHelperService.isNullOrNaN(it.debitorCount) ||
            (it.debitorCount !== 1 && debitorsCount >= (it.debitorCount as number)) ||
            (it.debitorCount === 1 && debitorsCount === it.debitorCount)) &&
            (CalculationHelperService.isNullOrNaN(it.maxTotalMonthlyIncomeJh) || (it.maxTotalMonthlyIncomeJh as number) > netIncome) &&
            (CalculationHelperService.isNullOrNaN(it.ltv) || (it.ltv as number) < ltv) &&
            (CalculationHelperService.isNullOrNaN(it.morixRating) || (this.version >= 3 && !CalculationHelperService.isNullOrNaN(morixRating) && (it.morixRating as number) >= (morixRating as number))));
    }

    /**
     * Ermittelt LTV-Faktor zur Berechnung des KomfortKredit
     *
     * @param {object} params Parameterobjekt
     * @param {ISplittingRule[]} params.splittingRules Regeln zum Splitting
     * @param {number} params.debitorsCount Anzahl der Kreditnehmer
     * @param {number} params.netIncome Monatliches Gesamteinkommen aller Kreditnehmer in €
     * @param {number | undefined} params.morixRating Morix-Rating des Objekts mit dem höchsten Marktwert
     * @returns {number | undefined} LTV-Faktor
     * @throws {ArgumentError} Der Parameter 'splittingRules' ist nicht valide.
     */
    public ltvFactor({ splittingRules, debitorsCount, netIncome, morixRating }: {
        /**
         * Regeln zum Splitting
         */
        splittingRules: ISplittingRule[];
        /**
         * Anzahl der Kreditnehmer
         */
        debitorsCount: number;
        /**
         * Monatliches Gesamteinkommen aller Kreditnehmer in €
         */
        netIncome: number;
        /**
         * Morix-Rating des Objekts mit dem höchsten Marktwert
         */
        morixRating?: number;
    }): number | undefined {
        this.checkSplittingRules(splittingRules);
        let acceptedRule = splittingRules.reduce((pv: ISplittingRule | undefined, cv) => {
            if (
                // hier keine Morix-Regeln
                CalculationHelperService.isNullOrNaN(cv.morixRating) &&
                // nur welche mit Wert bei maxLtv
                !CalculationHelperService.isNullOrNaN(cv.ltv) &&
                // nur welche, die Anzahl der Kreditnehmer entspricht
                // Anzahl der Kreditnehmer irrelevant
                (CalculationHelperService.isNullOrNaN(cv.debitorCount) ||
                    // Anzahl der Kreditnehmer 1
                    (cv.debitorCount === 1 && cv.debitorCount === debitorsCount) ||
                    // Anzahl der Kreditnehmer > 1
                    ((cv.debitorCount as number) > 1 && (cv.debitorCount as number) <= debitorsCount)) &&
                // entweder maximales monatliches Einkommen irrelevant oder größer als das maximale monatliche Einkommen aller Kreditnehmer
                (CalculationHelperService.isNullOrNaN(cv.maxTotalMonthlyIncomeJh) || (cv.maxTotalMonthlyIncomeJh as number) > netIncome) &&
                // Vergleich mit vorheriger Regel
                (
                    // es gab keine vorherige Regel --> erster Durchlauf
                    pv === undefined ||
                    // bei vorheriger Regel war maximales monatliches Einkommen irrelevant
                    CalculationHelperService.isNullOrNaN(pv.maxTotalMonthlyIncomeJh) ||
                    // bei vorheriger Regel war maximales monatliches Einkommen größer, aber aktuelles ist nicht irrelevant
                    (!CalculationHelperService.isNullOrNaN(cv.maxTotalMonthlyIncomeJh) && (pv.maxTotalMonthlyIncomeJh as number) > (cv.maxTotalMonthlyIncomeJh as number))
                )
            ) {
                return cv;
            }
            return pv;
        }, undefined);

        switch (this.version) {
            case 0:
            case 1:
            case 2:
                break;
            case 3:
            case 4:
            case 5:
            case 6:
            default:
                if (!CalculationHelperService.isNullOrNaN(morixRating) && splittingRules.some(it => !CalculationHelperService.isNullOrNaN(it.morixRating) && (morixRating as number) <= (it.morixRating as number))) {
                    // eslint-disable-next-line complexity
                    acceptedRule = splittingRules.reduce((pv: ISplittingRule | undefined, cv) => {
                        if (
                            // hier keine Morix-Regeln
                            !CalculationHelperService.isNullOrNaN(cv.morixRating) &&
                            // nur welche mit Wert bei maxLtv
                            !CalculationHelperService.isNullOrNaN(cv.ltv) &&
                            // nur welche, die Anzahl der Kreditnehmer entspricht
                            // Anzahl der Kreditnehmer irrelevant
                            (CalculationHelperService.isNullOrNaN(cv.debitorCount) ||
                                // Anzahl der Kreditnehmer 1
                                (cv.debitorCount === 1 && cv.debitorCount === debitorsCount) ||
                                // Anzahl der Kreditnehmer > 1
                                ((cv.debitorCount as number) > 1 && (cv.debitorCount as number) <= debitorsCount)) &&
                            // entweder maximales monatliches Einkommen irrelevant oder größer als das maximale monatliche Einkommen aller Kreditnehmer
                            (CalculationHelperService.isNullOrNaN(cv.maxTotalMonthlyIncomeJh) || (cv.maxTotalMonthlyIncomeJh as number) > netIncome) &&
                            // MORIX berücksichtigen
                            (cv.morixRating as number) >= (morixRating as number) &&
                            // Vergleich mit vorheriger Regel
                            (
                                // es gab keine vorherige Regel --> erster Durchlauf
                                pv === undefined ||
                                // bei vorheriger Regel war maximales monatliches Einkommen irrelevant
                                CalculationHelperService.isNullOrNaN(pv.maxTotalMonthlyIncomeJh) ||
                                // bei vorheriger Regel war maximales monatliches Einkommen größer, aber aktuelles ist nicht irrelevant
                                (!CalculationHelperService.isNullOrNaN(cv.maxTotalMonthlyIncomeJh) && (pv.maxTotalMonthlyIncomeJh as number) > (cv.maxTotalMonthlyIncomeJh as number)) ||
                                // bei vorheriger Regel war Morix-Rating größer als in dieser
                                (pv.morixRating as number) > (cv.morixRating as number)
                            )
                        ) {
                            return cv;
                        }
                        return pv;
                    }, undefined);
                }
                break;
        }

        return acceptedRule !== undefined ? acceptedRule.ltv : undefined;
    }

    /**
     * Berechnet den zugewiesenen DSTI-Faktor
     *
     * @param {object} params Parameterobjekt
     * @param {IRiskAssessmentRule[]} params.riskAssessmentRules Regeln zur Riskobewertung
     * @param {number} params.debitorsCount Anzahl der Kreditnehmer
     * @param {number} params.netIncome Monatliches Gesamteinkommen aller Kreditnehmer in €
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @returns {number} DSTI-Faktor
     * @throws {ArgumentError} Der Parameter 'riskAssessmentRules' ist nicht valide.
     */
    public assignedDsti({ riskAssessmentRules, debitorsCount, netIncome, assumedDuration }: {
        /**
         * Regeln zur Riskobewertung
         */
        riskAssessmentRules: IRiskAssessmentRule[];
        /**
         * Anzahl der Kreditnehmer
         */
        debitorsCount: number;
        /**
         * Monatliches Gesamteinkommen aller Kreditnehmer in €
         */
        netIncome: number;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
    }): number | undefined {
        this.checkRiskAssessmentRules(riskAssessmentRules);
        const rules = riskAssessmentRules.filter(it =>
            CalculationHelperService.isNullOrNaN(it.ltv) &&
            !CalculationHelperService.isNullOrNaN(it.dsti) &&
            (CalculationHelperService.isNullOrNaN(it.debitorCount) || (debitorsCount === 1 && it.debitorCount === 1) || (it.debitorCount === 2 && debitorsCount >= it.debitorCount)) &&
            (CalculationHelperService.isNullOrNaN(it.maxTotalMonthlyIncomeJh) || netIncome < (it.maxTotalMonthlyIncomeJh as number)) &&
            (CalculationHelperService.isNullOrNaN(it.loanDuration) || (!CalculationHelperService.isNullOrNaN(assumedDuration) && (it.loanDuration as number) < (assumedDuration as number))));
        if (debitorsCount > 0 && rules.length > 0) {
            rules.sort((a, b) => {
                if (!CalculationHelperService.isNullOrNaN(a.maxTotalMonthlyIncomeJh) && !CalculationHelperService.isNullOrNaN(b.maxTotalMonthlyIncomeJh)) {
                    return (a.maxTotalMonthlyIncomeJh as number) - (b.maxTotalMonthlyIncomeJh as number);
                }
                else if (CalculationHelperService.isNullOrNaN(a.maxTotalMonthlyIncomeJh)) {
                    return 1;
                }
                else {
                    return -1;
                }
            });

            return rules[0].dsti;
        }
        else {
            return undefined;
        }
    }

    /**
     * Liefert den fiktiven Zinssatz für den KomfortKredit anhand der Zinsbindung und der angenommenen Laufzeit zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @param {InterestMethod | undefined} params.interestMethod Zinsbindung
     * @param {number | undefined} params.assumedDuration Angenommene Laufzeit in Monaten
     * @param {number | undefined} params.requestedDebitRate Angefragter Zinssatz in %
     * @returns {number | undefined} Fiktiver Zinsatz für den KomfortKredit in %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public comfortCreditFictionalRate({ configuration, interestMethod, assumedDuration, requestedDebitRate }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
        /**
         * Zinsbindung
         */
        interestMethod?: InterestMethod;
        /**
         * Angenommene Laufzeit in Monaten
         */
        assumedDuration?: number;
        /**
         * Angefragter Zinssatz in %
         */
        requestedDebitRate?: number;
    }): number | undefined {
        this.checkConfigurationForComfortCreditFictionalRate(configuration);
        const configEntry = configuration.mainCreditFictionalRates.find(it => it.interestMethod === interestMethod);

        if (!CalculationHelperService.isNullOrNaN(requestedDebitRate) && interestMethod !== undefined && (interestMethod as unknown) !== null && !CalculationHelperService.isNullOrNaN(assumedDuration) && configuration.fictionalRateTypeAssignments.some(it => it.creditType === CreditType.ComfortCredit && it.interestMethod === interestMethod && it.assumedDuration === assumedDuration && it.fictionalRateSourceType === FictionalRateSourceType.RequestedDebitRate)) {
            return requestedDebitRate as number;
        }
        return configEntry?.fictionalRate;
    }

    /**
     * Liefert den fiktiven Zinssatz für den KomfortKredit Plus anhand der Zinsbindung und der angenommenen Laufzeit zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @param {boolean} params.splitting Wird Kredit gesplittet
     * @param {InterestMethod | undefined} params.interestMethod Zinsbindung
     * @param {number | undefined} params.duration Laufzeit in Monaten - default: 120
     * @param {number | undefined} params.requestedDebitRate Angefragter Zinssatz in %
     * @returns {number} Fiktiver Zinssatz für den KomfortKredit Plus %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public comfortCreditPlusFictionalRate({ configuration, splitting, interestMethod, duration, requestedDebitRate }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
        /**
         * Wird Kredit gesplittet
         */
        splitting: boolean;
        /**
         * Zinsbindung
         */
        interestMethod?: InterestMethod;
        /**
         * Laufzeit in Monaten - default: 120
         */
        duration?: number;
        /**
         * Angefragter Zinssatz in %
         */
        requestedDebitRate?: number;
    }): number {
        this.checkConfigurationForComfortCreditPlusFictionalRate(configuration);
        if (!splitting) {
            return 0;
        }
        const fictionalRate = configuration.comfortCreditPlusFictionalRate;
        duration = !CalculationHelperService.isNullOrNaN(duration) ? (duration as number) : COMFORTCREDIT_PLUS_ASSUMEDDURATION;
        if (!CalculationHelperService.isNullOrNaN(requestedDebitRate) && interestMethod !== undefined && (interestMethod as unknown) !== null && configuration.fictionalRateTypeAssignments.some(it => it.creditType === CreditType.ComfortCreditPlus && it.interestMethod === interestMethod && it.assumedDuration === duration && it.fictionalRateSourceType === FictionalRateSourceType.RequestedDebitRate)) {
            return requestedDebitRate as number;
        }
        return fictionalRate;
    }

    /**
     * Liefert den fiktiven Zinssatz für Wohnbaukonto zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @returns {number} Fiktiver Zinssatz für Wohnbaukonto in %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public constructionCreditFictionalRate({ configuration }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
    }): number {
        this.checkConfigurationForConstructionCreditFictionalRate(configuration);
        return configuration.constructionCreditFictionalRate;
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten beim Wohnbaukonto
     *
     * @param {IFinancingConfiguration | undefined | null} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfigurationForConstructionCreditFictionalRate(configuration?: IFinancingConfiguration | null): void {
        if (configuration === undefined || configuration === null || isNaN(configuration.constructionCreditFictionalRate) || (configuration.constructionCreditFictionalRate as unknown) === null) {
            throw new ArgumentError(configurationInvalid);
        }
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten beim KomfortKredit Plus
     *
     * @param {IFinancingConfiguration | undefined | null} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfigurationForComfortCreditPlusFictionalRate(configuration?: IFinancingConfiguration | null): void {
        if (configuration === undefined ||
            configuration === null ||
            isNaN(configuration.comfortCreditPlusFictionalRate) ||
            (configuration.comfortCreditPlusFictionalRate as unknown) === null ||
            !Array.isArray(configuration.fictionalRateTypeAssignments)) {
            throw new ArgumentError(configurationInvalid);
        }
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten beim KomfortKredit
     *
     * @param {IFinancingConfiguration | undefined | null} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfigurationForComfortCreditFictionalRate(configuration?: IFinancingConfiguration | null): void {
        if (configuration === undefined ||
            configuration === null ||
            !Array.isArray(configuration.mainCreditFictionalRates) ||
            configuration.mainCreditFictionalRates.length === 0 ||
            !Array.isArray(configuration.fictionalRateTypeAssignments)) {
            throw new ArgumentError(configurationInvalid);
        }
    }

    /**
     * Überprüft die Regeln zur Risikoabschätzung
     *
     * @param {IRiskAssessmentRule[] | undefined | null} riskAssessmentRules Regeln zur Risikoabschätzung
     * @throws {ArgumentError} Der Parameter 'riskAssessmentRules' ist nicht valide.
     */
    private checkRiskAssessmentRules(riskAssessmentRules?: IRiskAssessmentRule[] | null): void {
        if (!Array.isArray(riskAssessmentRules) || riskAssessmentRules.length === 0) {
            throw new ArgumentError('Der Parameter \'riskAssessmentRules\' ist nicht valide.');
        }
    }

    /**
     * Überprüft die Regeln zum Splitting
     *
     * @param {ISplittingRule[] | undefined | null} splittingRules Regeln zum Splitting
     * @throws {ArgumentError} Der Parameter 'splittingRules' ist nicht valide.
     */
    private checkSplittingRules(splittingRules?: ISplittingRule[] | null): void {
        if (!Array.isArray(splittingRules) || splittingRules.length === 0) {
            throw new ArgumentError('Der Parameter \'splittingRules\' ist nicht valide.');
        }
    }
}
