export enum DepositType {

    /**
     * Ohne sofortige Hinterlegung
     */
    WithoutImmediateDeposit = 0,

    /**
     * Mit sofortiger Hinterlegung
     */
    WithImmediateDeposit = 1,
}
