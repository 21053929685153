import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { of, tap } from 'rxjs';

import { MasterdataService, SystemConfigurationService } from './services';
import { MasterdataState, SystemConfigurationState } from './states';

export * from './enums';
export * from './interfaces';
export * from './services';
export * from './states';

/**
 * Modul für Stammdatenmodul
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([MasterdataState, SystemConfigurationState]),
    ],
    providers: [
        MasterdataService,
        SystemConfigurationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (masterdataService: MasterdataService, systemConfigurationService: SystemConfigurationService) => (() =>
                of(void 0).pipe(
                    tap(() => {
                        masterdataService.initialize();
                        systemConfigurationService.initialize();
                    }),
                )),
            deps: [MasterdataService, SystemConfigurationService],
            multi: true,
        },
    ],
})
export class MasterdataDataModule {

}
