<div class="ucba-expansion-panel-body">
    <div>
        <div
            class="ucba-expansion-panel-header ucba-expansion-panel-padding"
            [ngClass]="{
                'ucba-expansion-panel-after': arrowPosition() === 'after',
                'ucba-expansion-panel-before': arrowPosition() === 'before'
            }"
            (click)="toggleExpanded()">
            @if (!hideToggle() && arrowPosition() === 'before') { @if (expanded()) {
            <svg height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" />
            </svg>
            } @else {
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
            </svg>
            } }
            <ng-content select="[ucba-expansion-panel-header]"></ng-content>
            @if (!hideToggle() && arrowPosition() === 'after') { @if (expanded()) {
            <svg height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" />
            </svg>
    
            } @else {
            <svg height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
            </svg>
            } }
        </div>
        <ng-content select="[ucba-expansion-panel-subheader]"></ng-content>
    </div>
    @if (expanded()) {
    <div class="ucba-expansion-panel-content ucba-expansion-panel-padding" @expand>
        <ng-content select="[ucba-expansion-panel-content]"></ng-content>
    </div>
    }
</div>
