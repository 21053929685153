/* eslint-disable class-methods-use-this */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { FinancingPremissions, Language } from '@ntag-ef/finprocess-enums';
import { TransferContractDialogComponent } from 'app/modules/administration/api';
import { Role } from 'auth';
import { Observable, OperatorFunction, Subject, Subscription, combineLatest, filter, map, takeUntil, tap } from 'rxjs';
import { FinancingStatus, FinancingSubStatus } from 'shared/data';
import { HelperService, UUID } from 'shared/util';

import { DashboardState, DashboardTab, DashboardType, FinancingsService, IDashboardTabOptions, IFinancingsStateModel } from './../../../../data';
import { IDashboardStateParentDefinition } from './../../../../data/states/state.definition';

/**
 * Komponente zum Anzeigen einer Finanzierungsliste
 */
@Component({
    selector: 'finprocess-financing-list',
    templateUrl: './financing-list.component.html',
    styleUrls: ['./financing-list.component.scss'],
})
export class FinancingListComponent implements OnInit, OnDestroy {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly Role = Role;

    /**
     * Gibt an, ob die Komponente sich im Ladezustand befindet
     */
    @Input()
    public loading = true;

    /**
     * Identifier der Tab
     */
    @Input()
    public tabId!: string;

    @Input()
    public dashboardType: DashboardType = DashboardType.Expert;

    /**
     * Einstellungen des Tab
     */
    public tabSettings$!: Observable<IDashboardTabOptions>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DashboardTab = DashboardTab;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingSubStatus = FinancingSubStatus;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingStatus = FinancingStatus;

    /**
     * Zusdtand der Finanzierungen
     */
    @Select((it: IDashboardStateParentDefinition) => it.financings)
    public readonly financingState$!: Observable<IFinancingsStateModel>;

    /**
     * Benötigte Rollen zum Lesen von Finanzierungen
     */
    public readonly readRoleObject = { needOnlyOne: true, roles: Role.FinancingMapsReader | Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader };

    /**
     * Auswahlliste Einträge pro Seite
     */
    private readonly defaultPageSizes = [20, 50, 100];

    /**
     * Finanzierungsgenehmigungen
     */
    public financingPremissions = FinancingPremissions;

    private onDestroy$ = new Subject<void>()

    private subscriptionChangePage: Subscription | undefined;

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {FinancingsService} financingsService FinancingsService-Injektor
     * @param {Router} router Router-Injektor
     * @param {MatDialog} dialog Angular Dialog-Injektor
     */
    public constructor(
        private store: Store,
        private financingsService: FinancingsService,
        private router: Router,
        private dialog: MatDialog,
    ) {

    }
    /**
     * onDestroy
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.financingsService.loadFinancings(this.dashboardType, this.tabId)
            .pipe(
                tap(() => {this.loading = true}),
                takeUntil(this.onDestroy$),
            ).subscribe(() => {
                this.loading = false;
            });

        this.tabSettings$ = combineLatest([
            this.store.select(DashboardState.currentDashboard).pipe(filter(currentDashboardType => currentDashboardType !== undefined) as OperatorFunction<DashboardType | undefined, DashboardType>),
            this.store.select(DashboardState.tabSettings),
            this.store.select(DashboardState.currentTab).pipe(filter(currentTab => currentTab !== undefined) as OperatorFunction<string | undefined, string>),
        ]).pipe(
            takeUntil(this.onDestroy$),
            tap(([currentDashboardType]) => {this.dashboardType = currentDashboardType}),
            map(([, settingsFn]) => settingsFn(this.dashboardType)),
            filter(settings => settings !== undefined) as OperatorFunction<IDashboardTabOptions | undefined, IDashboardTabOptions>,
        )
    }

    /**
     * Liefert die Optionen für Einträge pro Seite
     *
     * @param {number} maxCount Maximale Anzahl an Einträgen
     * @returns {number[]} Optionen für Einträge pro Seite
     */
    public getPageSizeOptions(maxCount: number): number[] {
        const result = [];
        for (const pageSize of this.defaultPageSizes) {
            if (pageSize <= maxCount) {
                result.push(pageSize)
            }
        }

        if (maxCount > Math.max(...this.defaultPageSizes)) {
            result.push(maxCount);
        }

        return result;
    }

    /**
     * Wechselt die Seite oder die Einträge pro Seite
     *
     * @param {PageEvent} pageEvent Event
     * @param {number} currentPageSize Page Größe vor der Änderung
     */
    public changePageOrPageSize(pageEvent: PageEvent, currentPageSize: number): void {
        if (pageEvent.previousPageIndex !== pageEvent.pageIndex && currentPageSize === pageEvent.pageSize) {
            this.subscriptionChangePage?.unsubscribe();

            this.subscriptionChangePage = this.financingsService.changePage(this.dashboardType, this.tabId, pageEvent.pageIndex + 1, () => {
                this.loading = true;
            }).subscribe(() => {
                this.loading = false;
            });
        }
        else if (pageEvent.previousPageIndex === pageEvent.pageIndex && currentPageSize !== pageEvent.pageSize) {
            this.financingsService.changePageSize(this.dashboardType, this.tabId, pageEvent.pageSize, () => {
                this.loading = true;
            }).subscribe(() => {
                this.loading = false;
            });
        } else {
            this.financingsService.changePageAndSize(this.dashboardType, this.tabId, pageEvent.pageIndex + 1, pageEvent.pageSize, () => {
                this.loading = true;
            }).subscribe(() => {
                this.loading = false;
            });
        }
    }

    /**
     * Öffnet eine Kreditanfrage
     *
     * @param {UUID} id Eindeutiger Schlüssel der Anfrage
     */
    public async open(id: UUID): Promise<void> {
        await this.router.navigate(['financing', id, 'borrower-check-customer-data', id]);
    }

    /**
     * Öffnet den Lastenausgleich für diese Kreditanfrage
     *
     * @param {UUID} financingId Eindeutiger Schlüssel der Anfrage
     */
    public balance(financingId: UUID): void {
        const dialogRef = this.dialog.open(TransferContractDialogComponent, {
            data: financingId,
        });

        dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
            if (result === undefined || !result) {
                return;
            }

            this.loading = true;

            this.financingsService.loadFinancings(this.dashboardType, this.tabId).subscribe(() => {
                this.loading = false;
            });
        });
    }

    /**
     * Überprüft ob eine englische Beratung gewünscht ist
     *
     * @param {Language} language Sprache
     * @returns {boolean} Ob Englisch gewünscht ist
     */
    public isEnglish(language: Language): boolean {
        return HelperService.hasBit(language, Language.English);
    }

    /**
     * Überprüft ob es um Employees skills handelt
     *
     * @param {FinancingPremissions} skills FinancingPremissions
     * @returns {boolean} Ob Employees fall ist
     */
    public isEmployeesSkill(skills?: FinancingPremissions): boolean {
        return HelperService.hasBit(skills, FinancingPremissions.EmployeesSkill);
    }
}
