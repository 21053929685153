/**
 * @copyright NT.AG 2021
 */

/**
 * Status für die Verwendung von SmartDoc
 */
export enum SmartDocJobStatus {
    /**
     * Dokumentenupload offen
     */
    Open = 10,

    /**
     * Dateien werden an SmartDoc übermittelt
     */
    Uploading = 15,

    /**
     * Dateien befinden sich im Splitting (Thumbnails werden generiert)
     */
    Splitting = 20,

    /**
     * Gesplittete Dateien werden aberufen
     */
    RequestingThumbnails = 25,

    /**
     * Splitting-Vorgang ist abgeschlossen, Thumbnails wurden erstellt und sind abrufbar
     */
    SplittingFinished = 30,

    /**
     * Sortierung wird gespeichert und übermittelt
     */
    StoringSortResults = 35,

    /**
     * Neu "angeordnete" Dateien werden erstellt
     */
    Processing = 40,

    /**
     * Neu "angeordnete" werden abgerufen/verarbeitet und gespeichert
     */
    RequestingResultFiles = 45,

    /**
     * Erstellung der neu "angeordneten" Dateien wurde abgeschlossen
     */
    ProcessingFinished = 50,

    /**
     * Zeigt an das es einen Fehler im Job bei der Kommunikation mit SmartDoc gab
     */
    SmartDocError = 999,
}
