import { ChangeDetectorRef, Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { EnumTranslationService } from '../../providers/enum-translation/enum-translation.service';
import { Helper } from '../../utils/helper';
import { UnionValueSingleType } from '../../utils/union-value-type';

/**
 * Pipe zum Übersetzen von Enum-Werten
 */
@Injectable()
@Pipe({
    name: 'finprocessEnumTranslation',
    pure: false,
})
export class EnumTranslationPipe extends TranslatePipe implements PipeTransform, OnDestroy {
    /**
     * Konstruktor
     *
     * @param {EnumTranslationService} translate EnumTranslationService-Injektor
     * @param {ChangeDetectorRef} ref ChangeDetectorRef-Injektor
     */
    public constructor(translate: EnumTranslationService, ref: ChangeDetectorRef) {
        super(translate, ref);
    }

    /**
     * Übersetzt übergebenen Enum-Wert
     *
     * @param {number | string} value Wert des Enums als Zeichenkette oder Zahl
     * @param {string | null} enumType Typ des Enums als Zeichenkette
     * @param {string} translatePraefix Präfix für Übersetzungsschlüssel
     * @param {any[]} args Zusätzliche Argumente
     * @returns {any} Übersetzter Enum-Wert
     */
    public transform(value: number | string, enumType?: string | null, translatePraefix?: string, ...args: unknown[]): unknown {
        if (enumType !== undefined && enumType !== null) {
            return super.transform(Helper.getKeyByEnum({ value, type: enumType, translatePraefix }) as string, ...args);
        }
        else {
            return super.transform(value.toString(), ...args);
        }
    }

    /**
     * Aktualisiert einen übersetzten Wert
     *
     * @param {UnionValueSingleType} value Parameter zum Ermitteln der/des Übersetzungsschlüssel(s)
     * @param {Record<string,any>} interpolateParams Werte, die in die Übersetzung interpoliert werden sollen
     * @param {any} translations Übersetzungen
     */
    public updateValue(value: UnionValueSingleType, interpolateParams?: Record<string, unknown>, translations?: unknown): void {
        super.updateValue(Helper.getKeyByEnum(value) as string, interpolateParams, translations);
    }
}
