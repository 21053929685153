<div mat-dialog-title class="container cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h2 class="headline">{{ data.title }}</h2>
    <div class="useractions">
        <button mat-icon-button (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography container">
    <div class="calculations fix" *ngFor="let list of data.lists; let i = index">
        <div class="header" class="mb-2">
            <b style="font-size: 18px;">{{ list.type | finprocessEnumTranslation:'DocumentType' }}</b>
        </div>
        <div class="files">
            <finprocess-file-upload [maxFileCount]="list.maxFileCount" [files]="files[i]">
            </finprocess-file-upload>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="addFiles()" [disabled]="invalid">
        {{'button.add' | translate}}
    </button>
    <button mat-raised-button (click)="cancel()">
        {{'button.cancel' | translate}}
    </button>
</mat-dialog-actions>