import { Component, ViewEncapsulation } from '@angular/core';

/**
 * A list element component
 */
@Component({
    selector: 'ucba-list-element',
    templateUrl: './list-element.component.html',
    styleUrls: ['./list-element.component.scss'],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
})
export class UcbaListElementComponent {}
