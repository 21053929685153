import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationsModule } from '@ntag-ef/notifications';

import { BranchService, ControlTableService, CountryService, DebitRatesService, FinancingConfigurationService, LiabilityConfigurationService, LoadBalancingService, RiskParametrizationService, SmartDocConfigurationService, UserAdministrationService } from './services';
import { BranchState, DebitRatesState, FinancingConfigurationState, LoadBalancingState, RiskParametrizationState, SmartDocConfigurationState, UserAdministrationState } from './states';

/**
 * Modul für Datenverwaltung und Datenaustausch bzgl. Authentifizierung und Authorisierung
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            BranchState,
            DebitRatesState,
            LoadBalancingState,
            UserAdministrationState,
            RiskParametrizationState,
            FinancingConfigurationState,
            SmartDocConfigurationState,
        ]),
        TranslateModule.forChild(),
        NotificationsModule.forChild(),
    ],
    providers: [
        BranchService,
        DebitRatesService,
        LoadBalancingService,
        UserAdministrationService,
        RiskParametrizationService,
        FinancingConfigurationService,
        CountryService,
        SmartDocConfigurationService,
        ControlTableService,
        LiabilityConfigurationService,
    ],
})
export class AdministrationDataModule {
}
