import { WorkflowType } from '../../enums';
import { Gender, LegalType } from '../../enums/customer-profile';
import { IMidtermModel } from '../../interfaces';

export const CREATE_CUSTOMER: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'createCustomer',

    firstName: { type: WorkflowType.String },

    lastName: { type: WorkflowType.String },

    customerShortName: { type: WorkflowType.String },

    // atecoCode: { type: WorkflowType.String },

    birthPlace: { type: WorkflowType.String },

    birthcountry: { type: WorkflowType.String },

    birthday:{ type: WorkflowType.Date},

    // birthProvince: { type: WorkflowType.String },

    // businessName: { type: WorkflowType.String },

    // businessSegment: { type: WorkflowType.String },

    // cciaaCode: { type: WorkflowType.String },

    // cciaaProvince: { type: WorkflowType.String },

    email: { type: WorkflowType.String },

    // enrollmentDate: { type: WorkflowType.String },

    taxResidence: { type: WorkflowType.String },

    gender:{ type: WorkflowType.Enum, enumObject: Gender, enumLabel: 'Gender' },

    // profession: { type: WorkflowType.String },

    legalType: { type: WorkflowType.Enum, enumObject: LegalType, enumLabel: 'LegalType' },

    mainAddressCity: { type: WorkflowType.String },

    mainAddressCountry: { type: WorkflowType.String },

    // mainAddressHamlet: { type: WorkflowType.String },

    // mainAddressProvince: { type: WorkflowType.String },

    mainAddressStreet: { type: WorkflowType.String },

    mainAddressZipCode: { type: WorkflowType.String },

    countryForReporting: { type: WorkflowType.String },

    // saeCode: { type: WorkflowType.String },

    // phoneNumber: { type: WorkflowType.String },

    // mobileNumber: { type: WorkflowType.String },

    // tinNumber: { type: WorkflowType.String },

    // vatIdentificationNumber: { type: WorkflowType.String },

    // fiscalResidenceList:{ type: WorkflowType.Array, child: WorkflowType.String },

    nationalityList:{ type: WorkflowType.Array, child: WorkflowType.String },
}
