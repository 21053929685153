export enum RealEstateClassType {
    /**
     * Eigentumswohnung
     */
    Flat,

    /**
     * EFH/ZFH
     */
    SingleOrSemiDetachedHouse,

    /**
     * Bauland mit Bauwidmung
     */
    BuildingLandWithBuildingDesignation,

    /**
     * Kleingarten (auf Eigengrund)
     */
    Allotment,

    /**
     * Reihenwohnhaus
     */
    RowHouse,

    /**
     * Gewerbeimmobilie
     */
    CommercialRealEstate,

    /**
     * sonst. Objekt
     */
    Other,
}
