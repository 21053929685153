import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_NATIONALITY_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerNationalityModel',

    nationalityBiCodeComingFromMigration: { type: WorkflowType.String },

    nationalityIsoCode: { type: WorkflowType.String },

    nationalityIsoCode2: { type: WorkflowType.String },

    nationalityIsoCode3: { type: WorkflowType.String },
}
