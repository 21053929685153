import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { FinprocessDocumentType } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { SystemdocumentsService } from 'app/modules/administration/data/services/systemdocuments/systemdocuments.service';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { FinancingStatus, FinancingSubStatus, HelperService, UUID } from 'app/modules/shared';
import { Observable, Subject, forkJoin, map, of, take } from 'rxjs';
import { catchError, filter, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { DocumentService, FictionalRateUpdated, FinancingService, IDeleteJointHeadingFileRequest, IEditLiabilities, IFile, IFinancingStateParentDefinition, IJointHeading, IJointHeadingFileUploadRequest, ILiabilityDataByHousehold, ILiabilityDetail, IUploadContext, JointHeadingFileDelete, LiabilitiesService, LiabilityState } from '../../../../data';
import { AddLiabilityDialogComponent } from '../add-liability-dialog/add-liability-dialog.component';
import { AssignLiabilityDialogComponent } from '../assign-liability-dialog/assign-liability-dialog.component';
import { CreateJointheadingDialogComponent } from '../create-jointheading-dialog/create-jointheading-dialog.component';

/**
 * Verbindlichkeiten
 */
@Component({
    selector: 'finprocess-liabilities',
    templateUrl: './liabilities.component.html',
    styleUrls: ['./liabilities.component.scss'],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
        },
    ],
})
export class LiabilitiesComponent implements IEditLiabilities, OnInit, OnDestroy {

    /**
     * Liabilities, nach Haushalt sortiert zur Anzeige
     */
    @Select(LiabilityState.jointHeadingsByHousehold)
    public liabilityData$!: Observable<ILiabilityDataByHousehold>;

    /**
     * Nicht zugeordnete Verbindlichkeiten
     */
    @Select(LiabilityState.unsortedLiabilities)
    public unsortedLiabilities$!: Observable<ILiabilityDetail[]>;

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * Does every debitor has an ndg.
     * Required to call agp.
     */
    public hasEveryDebitorCustomerNumber: boolean | undefined;

    /**
     * FinancingStatus
     */
    public financingStatus?: FinancingStatus;

    /**
     * FinancingSubStatus
     */
    public financingSubStatus?: FinancingSubStatus;

    /**
     * Expansion Panel Liabilities
     */
    public liabilityExpanded: Record<string, boolean> = {};
    /**
     * Expansion Panel Debitoren
     */
    public debitorExpand: Record<string, boolean> = {};
    /**
     * Expansion Panel Dokumente
     */
    public documentsExpand: Record<string, boolean> = {};

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Status = FinancingStatus;

    /**
     * Enum für Templatenutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SubStatus = FinancingSubStatus;

    private editingReadonly = true;

    /**
     * AGP/KSV Documents Context
     */
    public jointHeadingContexts!: IUploadContext[];

    /**
     * FinancingMapID
     */
    public financingMapID: string | undefined;

    /**
     * HTML FinprocessDocumentType
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinprocessDocumentType = FinprocessDocumentType;

    /**
     * HTML DocumentType
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DocumentType = DocumentType;



    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     *
     * @param {WaiterService} waiterService waiterService
     * @param {MatDialog} dialog dialog
     * @param {Store} store store 
     * @param {LiabilitiesService} liabilityService liabilityService
     * @param {ChangeDetectorRef} changeDetection changeDetection
     * @param {NotificationService} notificationService notificationService
     * @param {TranslateService} translate translation service
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {SystemdocumentsService} systemDocumentService SystemDocumentService
     * @param {DocumentService} documentService documentService
     */
    public constructor(
        private waiterService: WaiterService,
        public dialog: MatDialog,
        private store: Store,
        private liabilityService: LiabilitiesService,
        private changeDetection: ChangeDetectorRef,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private financingService: FinancingService,
        private activatedRoute: ActivatedRoute,
        private systemDocumentService: SystemdocumentsService,
        private documentService: DocumentService,
    ) {
    }

    /**
     * Initalisieren
     */
    public ngOnInit(): void {
        this.financingStatus = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer?.status);
        this.financingSubStatus = (this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer?.subStatus));

        this.editingReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$.pipe(
            tap(isDisabled => {
                this.editingReadonly = isDisabled;
            }),
        );
    
        this.getLiabilities();

        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as string),
            filter(id => id !== undefined),
            mergeMap(id => {
                const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
                this.financingMapID = financing?.id;
                if (!financing) {
                    return this.financingService.loadFinancing(id);
                }

                return of(financing);
            }),
        ).subscribe(financing => {
            this.hasEveryDebitorCustomerNumber = financing?.households.every(household => household?.debitors.every(debitor => !!OverwriteHelperService.getMergedOverwriteValue(debitor, 'customerNumber', debitor?.customerNumber, true)));
        });
    }

    /**
     * Beendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * get Liability Data
     */
    public getLiabilities() {
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);

        if (!financingContainerID) {
            return;
        }

        this.waiterService.show();
    
        this.liabilityService.getLiabilities(financingContainerID).pipe(takeUntil(this.onDestroy$)).subscribe({
            next: () => {
                this.changeDetection.detectChanges();
                this.waiterService.hide();
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.getLiabilitiesError'));
            },
        });
    }


    /**
     * set Liability active/-non active
     *
     * @param {ILiabilityDetail} liability Liability
     * @param {IJointHeading} jointHeading JointHeading
     */
    public setLiabilityActive(liability: ILiabilityDetail, jointHeading: IJointHeading) {
        if (!this.editingReadonly) {
            const active = !liability.active;
            const isFictionalRateAmountUndefined = liability.fictionalRateAmount === undefined;

            const handleRequest = () => this.waiterService.show().pipe(mergeMap(() => this.liabilityService.setLiabilityActive(liability.id, active))).subscribe({
                next: () => {
                    liability.active = active;
                    this.changeDetection.detectChanges();
                    this.waiterService.hide();
                    if (isFictionalRateAmountUndefined) {
                        this.getLiabilities();
                    }
                    this.notificationService.toast(this.translate.instant('financing.features.financing-processing.liabilities.liabilityActiveChanged'));
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.liabilityActiveError'));
                },
            });

            if (!liability.active && isFictionalRateAmountUndefined) {
                const dialogRef = this.dialog.open(AddLiabilityDialogComponent, {
                    data: { editLiabilityData: liability, editJointHeadingData: jointHeading, liabilityRequest: 'fictionalRate' },
                    minWidth: '40vw',
                });

                dialogRef.afterClosed().pipe(take(1)).subscribe((result?: ILiabilityDetail) => {
                    if (!!result) {
                        this.store.dispatch(new FictionalRateUpdated({ liabilityId: result.id, fictionalRate: result.fictionalRateAmount }));

                        if (result.fictionalRateAmount !== undefined) {
                            handleRequest();
                        }
                    }
                });
            } else {
                handleRequest();
            }
        }
    }

    /**
     * Open Add Liablity Dialog
     */
    public openAddLiabilityDialog() {
        const dialogRef = this.dialog.open(AddLiabilityDialogComponent, {
            data: { liabilityRequest: 'add' },
            width: '40%',
        });

        //reload liabilities after new liability was successfully added
        dialogRef.afterClosed().subscribe((liability?: ILiabilityDetail) => {
            if (liability) {
                this.getLiabilities();
            }
        });
    }

    /**
     * Open Assign Liability Dialog
     *
     * @param {UUID} id liability id
     */
    public assignLiabilityDialog(id: UUID) {
        const dialogRef = this.dialog.open(AssignLiabilityDialogComponent, {
            data: { liabilityID: id },
        });

        //reload liabilities after new liability was successfully assigned
        dialogRef.componentInstance.liabilityAssigned.subscribe(() => {
            this.getLiabilities();
        });
    }

    /**
     * open editLiabilityDialog
     * 
     * @param {ILiabilityDetail} liabilityData liability data
     * @param {IJointHeading} jointHeading jointHeading household debitor
     */
    public editLiabilityDialog(liabilityData: ILiabilityDetail, jointHeading: IJointHeading) {
        const dialogRef = this.dialog.open(AddLiabilityDialogComponent, {
            data: { editLiabilityData: liabilityData, editJointHeadingData: jointHeading, liabilityRequest: 'edit' },
            minWidth: '40vw',
        });

        //reload liabilities after new liability was successfully edited
        dialogRef.afterClosed().subscribe((liability?: ILiabilityDetail) => {
            if (liability) {
                this.getLiabilities();
            }
        });
    }

    /**
     * call AGP Data
     */
    public callExternalSystemData() {
        this.waiterService.show();
        const financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);

        if (financingContainerID) {
            this.liabilityService.getLiabilitiesFromExternalSystem(financingContainerID).pipe(take(1)).subscribe({
                complete: () => {
                    this.waiterService.hide();
                    this.getLiabilities();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            })
        }
    }

    /**
     * Zeigt das dialog wann es unsortedLiabilities gibt
     *
     * @returns {boolean | Observable<boolean>} true / false
     */
    @HostListener('window:beforeunload')
    public canDeactivate(): boolean | Observable<boolean> {
        return this.unsortedLiabilities$.pipe(take(1), map(unsortedLiabilities => unsortedLiabilities.length > 0));
    }

    /**
     * upload AGP/KSV Documents
     * 
     * @param {EventTarget | null } target target
     * @param {string} jointHeadingID jointHeadingID
     * @param {DocumentType} type DocumentType
     */
    public uploadJointHeadingFiles(target: EventTarget | null, jointHeadingID: string, type: DocumentType) {
        const tmp = target as HTMLInputElement;

        if (!!tmp.files) {
            const validFiles: File[] = [];
            for (let i = 0; i < tmp.files.length; i++) {
                const file = tmp.files.item(i);

                if (!!file && (file.size === 0 || file.type !== 'application/pdf')) {
                    this.notificationService.toast(this.translate.instant('general.errorUploadPdfFile'));
                    tmp.value = '';
                    this.waiterService.hide();
                    return;
                }

                if (!!file) {
                    validFiles.push(file);
                }
            }

            if (validFiles.length > 0) {
                const fileNames = validFiles.map(file => file.name).join(', ');
                this.notificationService.confirmOkCancel(
                    this.translate.instant('financing.features.financing-processing.liabilities.uploadFile', {
                        type: type === DocumentType.LiabilitiesInternal ? 'AGP' : 'KSV',
                    }),
                    this.translate.instant('financing.features.financing-processing.liabilities.confirmDialogContentUpload', {
                        name: fileNames,
                    }),
                ).pipe(
                    mergeMap(result => {
                        if (result !== 'submit') {
                            return of(void 0);
                        } else {
                            this.waiterService.show();

                            if (!!jointHeadingID && !!this.financingMapID) {
                                const uploadObservables = validFiles.map(file => {
                                    const uploadedData: IJointHeadingFileUploadRequest = {
                                        type: type,
                                        file: file,
                                        mapId: this.financingMapID ?? '',
                                        jointHeadingId: jointHeadingID,
                                    };
                                    return this.documentService.uploadJointHeadingFile(uploadedData).pipe(
                                        catchError(() => {
                                            this.notificationService.alert(
                                                this.translate.instant('general.error'),
                                                this.translate.instant('financing.features.financing-processing.liabilities.uploadJointHeadingFileError'),
                                            );
                                            return of(void 0);
                                        }),
                                    );
                                });

                                return forkJoin(uploadObservables).pipe(
                                    finalize(() => this.waiterService.hide()),
                                );
                            } else {
                                this.waiterService.hide();
                                return of(void 0);
                            }
                        }
                    }),
                ).subscribe();
            }
        }
    }

    /**
     * Open/Download Liability Document KSV/AGP
     *
     * @param {IFile} file File
     * @param {string} action open or download
     */
    public openDownloadDocument(file: IFile | undefined, action: 'download' | 'open'): void {
        if (!!file) {
            this.documentService.loadFile(file.id).subscribe({
                next: async fileContent => {
                    if (fileContent === undefined) {
                        return;
                    }

                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                    if (!blob) {
                        return;
                    }

                    if (action === 'download') {
                        await HelperService.downloadFileFromBlob(blob, file.name);
                    } else if (action === 'open') {
                        await HelperService.openFileFromBlob(blob);
                    }
                },
            });
        }
    }

    /**
     * Delete JointHeading Document KSV/AGP
     *
     * @param {string} jointHeadingID jointheadingID
     * @param {IFile} file file
     */
    public deleteDocument(jointHeadingID: string, file: IFile) {

        this.notificationService.confirmOkCancel(
            this.translate.instant('financing.features.financing-processing.liabilities.deleteJHFile'),
            this.translate.instant('financing.features.financing-processing.liabilities.confirmDialogContentDelete', {
                name: file.name,
            }),
        ).pipe(
            mergeMap(result => {
                if (result !== 'submit') {
                    return of(void 0);
                }
                else {
                    this.waiterService.show();

                    const deleteData: IDeleteJointHeadingFileRequest = {
                        id: file.id,
                        jointHeadingId: jointHeadingID,
                    }
                    this.documentService.deleteJointHeadingFile(deleteData).pipe().subscribe({
                        next: () => {
                            this.store.dispatch(new JointHeadingFileDelete({ fileId: file.id, jointHeadingID: jointHeadingID }));
                        },
                        error: () => {
                            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.deleteJHFileError'));
                        },
                    });
                    this.waiterService.hide();
                    return of(void 0);
                }
            }),
        ).subscribe();
    }

    /**
     * check if specific document type exists
     * 
     * @param {IJointHeading} jointHeading jointHeading
     * @param {DocumentType} documentType documentType
     * @returns {boolean} true / false
     */
    public documentTypeExists(jointHeading: IJointHeading, documentType: DocumentType): boolean {
        return jointHeading.documents?.some(doc => doc.type === documentType) ?? false;
    }

    /**
     * Request JointHeading AGP
     */
    public requestJointHeadingAGP() {
        const dialogRef = this.dialog.open(CreateJointheadingDialogComponent, {
        });
        dialogRef.componentInstance.jointHeadingCreated.pipe(
            take(1),
            mergeMap(newJHID => this.liabilityService.requestJointHeadingAGP(newJHID).pipe(
                take(1),
                catchError(() => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.requestJHError'));
                    return of(void 0);
                }),
            )),
        ).subscribe({
            complete: () => {
                this.getLiabilities();
            },
        });
    }
}
