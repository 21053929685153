import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { WaiterModule } from '@ntag-ef/waiter';
import { SharedUiModule } from 'app/modules/shared';
import { FlexLayoutModule } from 'ngx-flexible-layout'

import { AdministrationDataModule } from './../../data/administration-data.module';
import { TransferContractDialogComponent } from './components/transfer-contract-dialog/transfer-contract-dialog.component';

/**
 * Modul mit Dialog für internen Ausgleich
 */
@NgModule({
    declarations: [
        TransferContractDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatRadioModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        SharedUiModule,
        WaiterModule.forChild(),
        TranslateModule.forChild(),
        AdministrationDataModule,
        DragDropModule,
    ],
    exports: [
        TransferContractDialogComponent,
    ],
})
export class LoadBalancingInternalModule {
}
