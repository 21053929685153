<div mat-dialog-title class="container cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h2 class="headline">{{'financing.features.financing-processing.cancel-contract.headerCancellation' |
        translate}}
    </h2>
    <div class="useractions">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography container">
    <div class="reason fix">
        <finprocess-sub-header [label]="'financing.features.financing-processing.cancel-contract.reason' | translate">
        </finprocess-sub-header>
        <mat-form-field appearance="fill">
            <textarea matInput
                [placeholder]="'financing.features.financing-processing.cancel-contract.reasonPlaceholder' | translate"
                [(ngModel)]="cancellationReason" rows="8">
          </textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

    <button mat-raised-button color="primary"
        [disabled]="cancellationReason === undefined || cancellationReason.length < 10" (click)="cancelContract()">
        <mat-icon>block</mat-icon>
        {{'button.reverse' | translate}}
    </button>
    <button mat-raised-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
        {{'button.discard' | translate}}
    </button>
</mat-dialog-actions>