import { AAGUID } from '../../types/aaguid';

/**
 * Klasse für Aktion
 */
export class SmartDocDocumentsLoadedReset {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocDocumentsLoadedReset';
    /**
     * Constructor
     *
     * @param {AAGUID} payload Payload
     */
    public constructor(public payload: AAGUID) { }
}
