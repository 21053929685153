import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import {
    AssetState,
    ClearCollateral,
    FinancingMode, FinancingService
    , IFinancingStateParentDefinition, IStepperStep,
} from 'app/modules/financing/data';
import { IProductPackageStatusRequest } from 'app/modules/financing/data/interfaces/productpackagestatusrequest.interface';
import { Environment, ProductPackageStatus, UUID } from 'app/modules/shared';
import { environment } from 'environment';
import { ReplaySubject, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, forkJoin, map, mergeMap, of, switchMap, take, takeUntil, tap } from 'rxjs';


/**
 * ProductPackageWorkflow Stepper
 */
@Component({
    selector: 'finprocess-product-package-workflow',
    templateUrl: './product-package-workflow.component.html',
    styleUrls: ['./product-package-workflow.component.scss'],
})
export class ProductPackageWorkflowComponent implements OnInit, OnDestroy {

    public riskDecisionStep: IStepperStep = {
        route: 'risk-decision',
        label: 'Risikoentscheidung',
    };

    public approvalStep: IStepperStep = {
        route: 'approval',
        label: 'Genehmigung',
    };

    public financingDetailsStep: IStepperStep = {
        route: 'financingdetails',
        label: 'Finanzierungsdetails',
    };

    public collateralStep: IStepperStep = {
        route: 'collateral-details',
        label: 'Sicherheitendetails',
    };

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    public loading = false;

    private productPackageId$ = new ReplaySubject<UUID>(1);

    /**
     * Financing ID
     */
    public financingRFPID?: string;

    /**
     * Productpackage ID
     */
    public productPackageID?: string;

    /**
     * FinancingMode
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public FinancingMode = FinancingMode;

    /**
     * ProductPackageStatus
     */
    public productpackageStatus?: ProductPackageStatus;

    /**
     * Standard Konstruktor
     * 
     * @param { Router } router router Injector
     * @param { ActivatedRoute }activatedRoute ActivatedRoute Injector
     * @param {NotificationService} notificationService notification service
     * @param {TranslateService} translate translate service
     * @param {FinancingService} financingService financingService
     * @param {Store} store store
     */
    public constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private financingService: FinancingService,
        private store: Store,
    ) { }


    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.activatedRoute.paramMap.pipe(
            map(params => {
                if (params) {
                    const riskfinancingplanid = params.get('riskfinancingplanid') as UUID;
                    const productPackageID = params.get('productPackageID') as UUID;
                    this.financingRFPID = riskfinancingplanid;
                    return { riskfinancingplanid, productPackageID };
                } else {
                    return null;
                }
            }),
            filter((params): params is { productPackageID: string, riskfinancingplanid: string } => !!params?.productPackageID && !!params?.riskfinancingplanid),
            distinctUntilChanged((previous, current) => previous?.productPackageID === current?.productPackageID && previous?.riskfinancingplanid === current?.riskfinancingplanid),
            tap(params => {
                this.loading = true;
                this.productPackageID = params.productPackageID;
                this.productPackageId$.next(params.productPackageID);
            }),
            mergeMap(params => forkJoin([
                this.financingService.getProductPackage(params.productPackageID),
                this.financingService.loadFinancing(params.riskfinancingplanid),
            ])),
            takeUntil(this.onDestroy$),
        ).subscribe({
            next: () => { this.loading = false; },
            error: () => { this.loading = false; },
        });

        combineLatest([
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.productPackages),
            this.productPackageId$,
        ]).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(([pp, productPackageId]) => {
            const productPackage = pp?.assignProductPackages.find(prodPackage => prodPackage.id === productPackageId);
            this.productpackageStatus = productPackage?.status;
            
            if (!!this.productpackageStatus) {
                this.switchStatus(this.productpackageStatus);
            }
        });

        //Validate Collaterals
        combineLatest([
            this.store.select(AssetState.validateCollateralAccounts),
            this.store.select(AssetState.validateCollateralInsurance),
            this.store.select(AssetState.validateCollateralRealEstates),
        ]).pipe(
            debounceTime(500),
            switchMap(([accounts, insurances, realEstates]) => {
                const allCollaterals = [...accounts, ...insurances, ...realEstates];
                const isInvalid = allCollaterals.some(collateral => !collateral.valid);
                return of(isInvalid);
            }),
            distinctUntilChanged(),
            takeUntil(this.onDestroy$),
        ).subscribe(isInvalid => {
            if (isInvalid) {
                this.updateCollateralStatus(true);
            } else {
                this.updateCollateralStatus(false);
            }
        });
    }

    /**
     * switch Status
     * 
     * @param {ProductPackageStatus} productPackageStatus ProductPackageStatus
     */
    public switchStatus(productPackageStatus: ProductPackageStatus) {
        switch (productPackageStatus) {
            case ProductPackageStatus.CollateralSuccessful:
                this.collateralStep.status = 'success';
                this.riskDecisionStep.disabled = false;
                break;
            case ProductPackageStatus.DecisionFailed:
                this.riskDecisionStep.status = 'error';
                this.approvalStep.disabled = true;
                this.financingDetailsStep.disabled = true;
                this.collateralStep.disabled = false;
                break;
            case ProductPackageStatus.DecisionSuccesful:
            case ProductPackageStatus.ApprovalAwaiting:
                this.riskDecisionStep.status = 'success';
                this.collateralStep.status = 'success';
                this.approvalStep.status = 'pending';
                this.approvalStep.disabled = false;
                this.financingDetailsStep.disabled = environment.environment < Environment.Integration;
                this.collateralStep.disabled = false;
                break;
            case ProductPackageStatus.ApprovalRejected:
                this.riskDecisionStep.status = 'success';
                this.collateralStep.status = 'success';
                this.approvalStep.status = 'warn';
                this.financingDetailsStep.disabled = environment.environment < Environment.Integration;
                this.collateralStep.disabled = false;
                break;
            case ProductPackageStatus.ApprovalDenied:
                this.riskDecisionStep.status = 'success';
                this.collateralStep.status = 'success';
                this.approvalStep.status = 'error';
                this.financingDetailsStep.disabled = environment.environment < Environment.Integration;
                this.collateralStep.disabled = false;
                break;
            case ProductPackageStatus.ApprovalGranted:
                this.riskDecisionStep.status = 'success';
                this.approvalStep.status = 'success';
                this.collateralStep.status = 'success';
                this.approvalStep.disabled = false;
                this.financingDetailsStep.status = 'pending';
                this.financingDetailsStep.disabled = false;
                this.collateralStep.disabled = false;
                break;
            case ProductPackageStatus.Editing:
            default:
                this.collateralStep.status = 'pending';
                this.approvalStep.disabled = true;
                this.financingDetailsStep.disabled = true;
                this.collateralStep.disabled = false;
                this.riskDecisionStep.disabled = true;
                break;
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.store.dispatch(new ClearCollateral());
    }


    /**
     * ausgewälten Step geändert
     *
     * @param {string} route route
     */
    public async navigate(route: string): Promise<void> {
        await this.router.navigate([route], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan }, queryParamsHandling: 'merge' });// preserves queryParams for next navigation
    }

    /**
     * routing back to product packages site
     */
    public backToProductPackages() {
        this.router.navigate(['../../product-package'], { relativeTo: this.activatedRoute, queryParams: { mode: FinancingMode.RiskFinancingPlan } }).catch(error => {
            this.notificationService.alert(
                this.translate.instant('financing.features.financing-processing.productPackages.loadProductError'), error);
        });
    }

    /**
     * Update Collateral Status
     * 
     * @param {boolean} validationFailed validationFailed 
     */
    private updateCollateralStatus(validationFailed: boolean): void {
        if (!!this.financingRFPID && !!this.productPackageID) {
            if (validationFailed && this.productpackageStatus !== ProductPackageStatus.Editing) {
                this.setCollateralStatus('pending', 'Collateral Real Estate Details Validation Failed', ProductPackageStatus.Editing);
            } else if (!validationFailed && this.productpackageStatus !== ProductPackageStatus.CollateralSuccessful) {
                this.setCollateralStatus('success', 'Collateral Real Estate Details Validation Successful', ProductPackageStatus.CollateralSuccessful);
            }
        }
    }

    /**
     * Set Collateral Status
     * 
     * @param {string} stepStatus Status
     * @param {string} reason Reason
     * @param {ProductPackageStatus} status ProductPackageStatus
     */
    private setCollateralStatus(stepStatus: 'success' | 'warn' | 'error' | 'pending', reason: string, status: ProductPackageStatus): void {
        this.riskDecisionStep.disabled = stepStatus === 'pending';
        this.collateralStep.status = stepStatus;

        if (!!this.financingRFPID && !!this.productPackageID) {
            const collateralValidation: IProductPackageStatusRequest = {
                financingPlanID: this.financingRFPID,
                productPackageID: this.productPackageID,
                reason,
                status,
            };

            this.financingService.setProductPackageStatus(collateralValidation).pipe(take(1)).subscribe();
        }
    }
}
