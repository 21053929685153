import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginStateType } from '../../../../data/enums';

import { AuthenticationService } from './../../../../data/services';

/**
 * Interceptor, der das Authentifizerungstoken als Header bei Http-Requests setzt
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     */
    public constructor(private authenticationService: AuthenticationService) { }

    /**
     * Setzt in einem Request das Authentifizierungstoken
     *
     * @param {HttpRequest} request Request
     * @param {HttpHandler} next Handler
     * @returns {Observable<HttpEvent>} Observable mit Request
     */
    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ((this.authenticationService.loginState === LoginStateType.LoggedIn || this.authenticationService.loginState === LoginStateType.Locked) && this.authenticationService.token !== undefined) {
            const authReq = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${this.authenticationService.token}`),
            })

            return next.handle(authReq);
        }
        return next.handle(request);
    }
}
