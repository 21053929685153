import { EnumSettings } from './../enum.settings';

/**
 * Quelle des fikitiven Zinssatzes
 */
export enum FictionalRateSourceType {
    /**
     * Aus Konfiguration
     */
    Config = 0,

    /**
     * Aus angefragtem Zinssatz
     */
    RequestedDebitRate = 1,
}

export namespace FictionalRateSourceType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {FictionalRateSourceType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: FictionalRateSourceType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'FictionalRateSourceType', FictionalRateSourceType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('FictionalRateSourceType', plocale);
}
