import { AAGUID } from '../../types/aaguid';

/**
 * MethodNotAllowed Action
 */
export class MethodNotAllowed {
    /**
     * key for Action
     */
    public static readonly type = 'smartdoc.methodNotAllowed';
    /**
     * Constructor
     *
     * @param {AAGUID} payload AAGUID
     */
    public constructor(public payload: AAGUID) {}
}
