<div class="container" fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
        <h1 class="color-primary">{{ 'navigation.administration.debit-rates' | translate }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button mat-mini-fab color="primary" (click)="toggleLock()" *finprocessAuthorization="Role.ConditionsAdministrator"
                matTooltip="{{ (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate }}">
                <mat-icon *ngIf="locked">lock</mat-icon>
                <mat-icon *ngIf="!locked">lock_open</mat-icon>
            </button>
            <button mat-mini-fab *finprocessAuthorization="Role.ConditionsAdministrator" color="primary" (click)="save()" [disabled]="form.pristine || form.invalid || locked" matTooltip="{{ 'button.save' | translate }}">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>


    <div fxLayout="column" class="p-3" [formGroup]="form" class="content p-3">

        <!-- Basiswerte -->
        <div class="debit-rate-divider p-1 my-2">{{ 'administration.features.debitRates.baseValues' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span fxFlex="20"></span>
            <i fxFlex="19">{{ 'administration.features.debitRates.rating4' | translate }}</i>
            <i fxFlex="19">{{ 'administration.features.debitRates.bestDebitRate4' | translate }}</i>
            <i fxFlex="19">{{ 'administration.features.debitRates.worstDebitRate3' | translate }}</i>
            <i fxFlex="19">{{ 'administration.features.debitRates.worstDebitRate5' | translate }}</i>
        </div>

        <div *ngFor="let debitRate of debitRates" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="debitRate">
            <span fxFlex="20">
                EVA 1 Basis {{ debitRate.get('interestMethod')?.value | finprocessEnumTranslation:'InterestMethod' }}
            </span>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="rating4" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="bestDebitRate4" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="worstDebitRate3" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="worstDebitRate5" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Marge -->
        <div class="debit-rate-divider p-1 my-2">{{ 'administration.features.debitRates.margeTitle' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.debitRates.margeText' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="marge" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- Volumen -->
        <div class="debit-rate-divider p-1 my-2">{{ 'administration.features.debitRates.volumeTitle' | translate }}</div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span fxFlex="20">{{ 'administration.features.debitRates.volumeText' | translate }}</span>
            <mat-form-field appearance="fill" fxFlex="19">
                <input formControlName="volumen" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
        </div>

        <!-- LTV + Bonität -->
        <div class="debit-rate-divider p-1 my-2">LTV + Bonität</div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span fxFlex="20"></span>
            <i fxFlex="26">{{ 'administration.features.debitRates.w3' | translate }}</i>
            <i fxFlex="26">{{ 'administration.features.debitRates.w4' | translate }}</i>
            <i fxFlex="26">{{ 'administration.features.debitRates.w5' | translate }}</i>
        </div>

        <div *ngFor="let adaption of debitRateAdaptions; let i = index" fxLayout="row" fxLayoutAlign="space-between center" [formGroup]="adaption">
            <span fxFlex="20">
                {{ getAdaptionLabel(adaption.get('value')?.value, adaption.get('type')?.value, i === 0 || i === debitRateAdaptions.length - 1 ) }}
            </span>
            <mat-form-field appearance="fill" fxFlex="26">
                <input formControlName="w3" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="26">
                <input formControlName="w4" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="26">
                <input formControlName="w5" currencyMask [options]="factorOptions" matInput>
            </mat-form-field>

        </div>
    </div>

</div>
