import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IFinancingConfiguration } from '../../interfaces';
import { FinancingConfigurationChanged, FinancingConfigurationLoaded } from '../../states';

/**
 * Service für fiktive Zinssaetze und Finanzierungskonfiguration
 */
@Injectable()
export class FinancingConfigurationService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Lädt die Finanzierungskonfiguration
     *
     * @returns {IFinancingConfiguration | undefined} Finanzierungskonfiguration
     */
    public get(): Observable<IFinancingConfiguration | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFinancingConfiguration>(`${this.config.getEnvironment().apiUrl}/FinancingConfiguration`).pipe(
                    mergeMap((response: IFinancingConfiguration) => this.store.dispatch(new FinancingConfigurationLoaded(response)).pipe(
                        map(() => response),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Aktualisiert die Finanzierungskonfiguration
     *
     * @param {IFinancingConfiguration} financingConfiguration Finanzierungskonfiguration
     * @returns {Observable} Observable Antwort ohne Inhalt
     */
    public update(financingConfiguration: IFinancingConfiguration): Observable<IFinancingConfiguration | undefined> {
        return this.authorizationService.checkPermissions$(Role.ConditionsAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IFinancingConfiguration>(`${this.config.getEnvironment().apiUrl}/FinancingConfiguration`, financingConfiguration).pipe(
                    mergeMap((result: IFinancingConfiguration) => this.store.dispatch(new FinancingConfigurationChanged(result)).pipe(map(() => result))),
                ),
                of(undefined),
            )),
        );
    }
}
