export enum PropertyType {
    /**
     * Wohnimmobilie
     */
    ResidentialProperty = 1,

    /**
     * Gewerbeimmobilie
     */
    CommercialProperty = 2,

    /**
     * Sonstige Immobilie
     */
    Other = 3,
}
