/**
 * ActionId Typen
 */
export enum Gender {
    /**
     * Male
     */
    Male,

    /**
     * Female
     */
    Female,

}
