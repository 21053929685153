
import { IEnumParameter } from '../interfaces';

/**
 * Typ für mögliche Wertetypen beim Übersetzen
 */
export type UnionValueType = string | string[] | IEnumParameter | IEnumParameter[] | Array<string | IEnumParameter>;

/**
 * Typ für mögliche Wertetypen beim Übersetzen eines einzelnen Wertes
 */
export type UnionValueSingleType = string | IEnumParameter;
