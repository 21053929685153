import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, Optional, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';

import { IExistingRentalIncome, IFutureRentalIncome } from '../..';
import { ExistingRentalIncomeAdded, ExistingRentalIncomeDeleted, ExistingRentalIncomeUpdated, ExistingRentalIncomesLoaded, FutureRentalIncomeAdded, FutureRentalIncomeDeleted, FutureRentalIncomeUpdated, FutureRentalIncomesLoaded } from '../../states/debitor/debitor.actions';

/**
 * Service for the existing and future rental income controllers
 */
@Injectable()
export class RentalIncomeService {

    /**
     * Service constructor
     * 
     * @param {Store} store Store
     * @param {HttpClient} httpClient HttpClient
     * @param {ConfigService} config ConfigService
     * @param {AuthorizationService} authorizationService AuthorizationService
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    
    /**
     * Loads a list of existing rental incomes for a debitor
     *
     * @param {UUID} debitorId ID of the debitor
     * @returns {Observable<IExistingRentalIncome[] | undefined>} List of existing rental incomes
     */
    public getExistingRentalIncomes(debitorId: UUID): Observable<IExistingRentalIncome[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IExistingRentalIncome[]>(`${this.config.getEnvironment().apiUrl}/existingrentalincome/getall`, { params: { id: debitorId }}).pipe(
                    mergeMap((existingRentalIncomes: IExistingRentalIncome[]) => this.store.dispatch(new ExistingRentalIncomesLoaded({ debitorId, existingRentalIncomes })).pipe(
                        map(() => existingRentalIncomes),
                    )), 
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Adds an existing rental income
     *
     * @param {IExistingRentalIncome} existingRentalIncome Existing rental income
     * @returns {Observable<IExistingRentalIncome | undefined>} The added existing rental income
     */
    public addExistingRentalIncome(existingRentalIncome: Optional<IExistingRentalIncome, 'id'>): Observable<IExistingRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IExistingRentalIncome>(`${this.config.getEnvironment().apiUrl}/existingrentalincome/add`, existingRentalIncome).pipe(
                    mergeMap((newExistingRentalIncome: IExistingRentalIncome) => this.store.dispatch(new ExistingRentalIncomeAdded(newExistingRentalIncome)).pipe(
                        map(() => newExistingRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Updates an existing rental income
     *
     * @param {IExistingRentalIncome} existingRentalIncome Existing rental income
     * @returns {Observable<IExistingRentalIncome | undefined>} The updated existing rental income
     */
    public updateExistingRentalIncome(existingRentalIncome: IExistingRentalIncome): Observable<IExistingRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.patch<IExistingRentalIncome>(`${this.config.getEnvironment().apiUrl}/existingrentalincome/update`, existingRentalIncome).pipe(
                    mergeMap((updatedExistingRentalIncome: IExistingRentalIncome) => this.store.dispatch(new ExistingRentalIncomeUpdated(updatedExistingRentalIncome)).pipe(
                        map(() => updatedExistingRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Deletes an existing rental income
     *
     * @param {UUID} debitorId ID of the debitor
     * @param {UUID} existingRentalIncomeId ID of the existing rental income
     * @returns {Observable<void | undefined>} Empty server response
     */
    public deleteExistingRentalIncome(debitorId: UUID, existingRentalIncomeId: UUID): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete<void>(`${this.config.getEnvironment().apiUrl}/existingrentalincome/delete`, { params: { id: existingRentalIncomeId }}).pipe(
                    mergeMap(() => this.store.dispatch(new ExistingRentalIncomeDeleted({ debitorId, existingRentalIncomeId }))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Loads a list of future rental incomes for a debitor
     *
     * @param {UUID} debitorId ID of the debitor
     * @returns {Observable<IFutureRentalIncome[] | undefined>} List of future rental incomes
     */
    public getFutureRentalIncomes(debitorId: UUID): Observable<IFutureRentalIncome[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFutureRentalIncome[]>(`${this.config.getEnvironment().apiUrl}/futurerentalincome/getall`, { params: { id: debitorId }}).pipe(
                    mergeMap((futureRentalIncomes: IFutureRentalIncome[]) => this.store.dispatch(new FutureRentalIncomesLoaded({ debitorId, futureRentalIncomes })).pipe(
                        map(() => futureRentalIncomes),
                    )), 
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Adds a future rental income
     *
     * @param {IFutureRentalIncome} futureRentalIncome Future rental income
     * @returns {Observable<IFutureRentalIncome | undefined>} The added future rental income
     */
    public addFutureRentalIncome(futureRentalIncome: Optional<IFutureRentalIncome, 'id'>): Observable<IFutureRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IFutureRentalIncome>(`${this.config.getEnvironment().apiUrl}/futurerentalincome/add`, futureRentalIncome).pipe(
                    mergeMap((newFutureRentalIncome: IFutureRentalIncome) => this.store.dispatch(new FutureRentalIncomeAdded(newFutureRentalIncome)).pipe(
                        map(() => newFutureRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Updates a future rental income
     *
     * @param {IFutureRentalIncome} futureRentalIncome Future rental income
     * @returns {Observable<IFutureRentalIncome | undefined>} The updated future rental income
     */
    public updateFutureRentalIncome(futureRentalIncome: IFutureRentalIncome): Observable<IFutureRentalIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.patch<IFutureRentalIncome>(`${this.config.getEnvironment().apiUrl}/futurerentalincome/update`, futureRentalIncome).pipe(
                    mergeMap((updatedFutureRentalIncome: IFutureRentalIncome) => this.store.dispatch(new FutureRentalIncomeUpdated(updatedFutureRentalIncome)).pipe(
                        map(() => updatedFutureRentalIncome),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Deletes a future rental income
     *
     * @param {UUID} debitorId ID of the debitor
     * @param {UUID} futureRentalIncomeId ID of the future rental income
     * @returns {Observable<void | undefined>} Empty server response
     */
    public deleteFutureRentalIncome(debitorId: UUID, futureRentalIncomeId: UUID): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete<void>(`${this.config.getEnvironment().apiUrl}/futurerentalincome/delete`, { params: { id: futureRentalIncomeId }}).pipe(
                    mergeMap(() => this.store.dispatch(new FutureRentalIncomeDeleted({ debitorId, futureRentalIncomeId: futureRentalIncomeId }))),
                ),
                of(undefined),
            )),
        );
    }
}
