<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection()" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id()">
            @if (!!label() && !hideLabel()) {
            <span>{{ label() }}</span>
            } @else if (!label() && !hideLabel()) {
            <ng-content select="[label]"></ng-content>
            } @if (isRequired()) {
            <span class="required-indicator"> *</span>
            }
        </label>
        @if (overwrite() && currentValue() !== originalValue()) {
        <mat-icon
            class="ml-1"
            [matTooltip]="
                'financing.features.financing-processing.originalValue'
                    | translate
                        : {
                              value:
                                  originalValue() !== undefined && originalValue() !== ''
                                      ? originalValue()
                                      : ('financing.features.financing-processing.unset' | translate)
                          }
            "
            >info</mat-icon
        >
        }
    </div>
    <div class="value-container" fxFlex="50" fxFlex.lt-sm="100">
        <mat-form-field
            class="field-color"
            appearance="fill"
            fxFlex="100"
            [subscriptSizing]="subscriptSizing()"
            [class.mat-form-field-transparent-background]="transparent()">
            <input
                matInput
                #input="ngModel"
                [placeholder]="placeholder()"
                [ngModel]="currentValue()"
                [disabled]="(readonly() | async) ?? false"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="save($event, input)" />
            <mat-error *ngIf="input.errors">{{ input.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
    </div>
    @if (!hideHorizontalRule()) {
    <hr class="custom-hr" />
    }
</div>
