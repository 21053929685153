import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'auth';
import { EMPTY, Observable, OperatorFunction, iif, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { SortOrder } from 'shared';
import { ConfigService, HelperService, IDashboardConfig } from 'shared/util';

import { DashboardFilter, DashboardFinancingsSortValues, DashboardTab, DashboardType, SalesPartnerCenterFilter } from '../../enums';
import { IDashboardFinancing, IDashboardFinancingRequest, IDashboardFinancingResponse, ITemporaryDashboardFinancing } from '../../interfaces';
import { DASHBOARD_CONFIG } from '../../tokens';

import { ClientFilterSorting, DashboardState, DashboardTypeChanged, FinancingsLoaded, FinancingsPageLoaded, FinancingsState, IDashboardStateData, IDashboardStateParentDefinition, IDashboardTabOptions, PageChanged, PageSizeChanged, SalesPartnerCenterFilterChanged, SearchChanged, SortingChanged, StatusFilterChanged } from './../../states';
import { DashboardSorting, DashboardStatusMap } from './../../types';

/**
 * Service zum Laden und Interagieren mit Finanzierungsanfragen
 */
@Injectable()
export class FinancingsService {
    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {IDashboardConfig} dashboardConfig DASHBOARD_CONFIG-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(private store: Store, private httpClient: HttpClient, private config: ConfigService, @Inject(DASHBOARD_CONFIG) private dashboardConfig: IDashboardConfig, private authorizationService: AuthorizationService) { }

    /**
     * Führt das Sortieren der Dashboardliste aus
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {DashboardSorting} sorting neue Sortierung
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public sort(dashboardType: DashboardType, tabId: string, sorting: DashboardSorting, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new SortingChanged({
            dashboardType,
            tabId: tabId,
            sorting,
        })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Führt das Filtern der Dashboardliste aus
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {DashboardFilter} filterDash neuer Filter
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public filterStatus(dashboardType: DashboardType, tabId: string, filterDash: DashboardFilter, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new StatusFilterChanged({
            dashboardType,
            tabId: tabId,
            filter: filterDash,
        })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Führt das Filtern der Dashboardliste aus
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {SalesPartnerCenterFilter} filterCenter neuer Filter
     * @param {string[]} salesPartnerCenterIds salesPartnerCenterIds
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public filterSalesPartnerCenter(dashboardType: DashboardType, tabId: string, filterCenter: SalesPartnerCenterFilter, salesPartnerCenterIds: string[], preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new SalesPartnerCenterFilterChanged({ dashboardType, tabId, filter: filterCenter, salesPartnerCenterIds })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Führt die Volltextsuche der Dashboardliste aus
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {string} search neue VollTextsuche
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public search(dashboardType: DashboardType, tabId: string, search: string, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new SearchChanged({
            dashboardType,
            tabId: tabId,
            search,
        })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Wechselt die Seite auf der Dashboardliste
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {number} page neue Seite
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public changePage(dashboardType: DashboardType, tabId: string, page: number, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new PageChanged({
            dashboardType,
            tabId,
            page,
        })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall, true)),
        );
    }

    /**
     * Wechselt die Einträge pro Seite auf der Dashboardliste
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {number} pageSize neue Einträge pro Seite
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public changePageSize(dashboardType: DashboardType, tabId: string, pageSize: number, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch(new PageSizeChanged({
            dashboardType,
            tabId: tabId,
            pageSize,
        })).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Wechselt die Seite und die Einträge pro Seite auf der Dashboardliste
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {number} page Seitenzahl
     * @param {number} pageSize Seitengröße
     * @param {() => void | undefined} preLoadingCall  Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @returns {Observable<void>} Observable, der emitted, wenn ausgeführt
     */
    public changePageAndSize(dashboardType: DashboardType, tabId: string, page: number, pageSize: number, preLoadingCall?: () => void): Observable<void> {
        return this.store.dispatch([
            new PageSizeChanged({ dashboardType, tabId: tabId, pageSize }),
            new PageChanged({ dashboardType, tabId, page }),
        ]).pipe(
            mergeMap(() => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Ändert das DashboardType
     * 
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {() => void} preLoadingCall preLoadingCall
     * @returns {Observable} Request als Observable
     */
    public changeDashboardType(dashboardType: DashboardType, preLoadingCall?: () => void) {
        return this.store.dispatch(new DashboardTypeChanged(dashboardType)).pipe(
            mergeMap(() => this.store.selectOnce(DashboardState.currentTab)),
            filter(tabId => tabId !== undefined) as OperatorFunction<string | undefined, string>,
            mergeMap(tabId => this.processListInteraction(dashboardType, tabId, preLoadingCall)),
        );
    }

    /**
     * Fordert einen neuen Fall für Experten an
     *
     * @returns {Observable<IDashboardFinancing | undefined>} Request als Observable
     */
    public requestNewExpertFinancing(): Observable<IDashboardFinancing | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put<IDashboardFinancing | null>(`${this.config.getEnvironment().apiUrl}/Dashboard/UserAsEditor`, null).pipe(
                    map(response => (response === null ? undefined : response)),
                ),
                EMPTY,
            )),
        );
    }

    /**
     * Fordert einen neuen Fall für Referenten an
     *
     * @returns {Observable<IDashboardFinancing | undefined>} Request als Observable
     */
    public requestNewReferentFinancing(): Observable<IDashboardFinancing | undefined> {
        return this.authorizationService.checkPermissions$(Role.Referent).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put<IDashboardFinancing | null>(`${this.config.getEnvironment().apiUrl}/Dashboard/UserAsRefereeEditor`, null).pipe(
                    map(response => (response === null ? undefined : response)),
                ),
                EMPTY,
            )),
        );
    }



    /**
     * Liefert die Abfrage der Dashboard-Daten aus dem Backend als Observable
     *
     * @param {DashboardType} dashboardType Dashboardtyp
     * @param {string} tabId Tab
     * @param {IDashboardFinancingRequest | undefined} request Request
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @param {boolean} keepData Sollen bereits vorhandene Daten erhalten bleiben
     * @returns {Observable<void>} Abfrage der Dashboard-Daten aus dem Backend als Observable
     */
    public loadFinancings(dashboardType: DashboardType, tabId: string, request?: IDashboardFinancingRequest, preLoadingCall?: () => void, keepData = false): Observable<void> {
        return of(void 0).pipe(
            tap(() => {
                if (preLoadingCall !== undefined) {
                    preLoadingCall();
                }
            }),
            mergeMap<unknown, Observable<IDashboardFinancingRequest>>(() =>
                iif(
                    () => request !== undefined,
                    of(request as IDashboardFinancingRequest),
                    this.getCurrentSettings(dashboardType, tabId),
                )),
            mergeMap(mergedRequest => iif(
                () => (tabId === DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnAllTab) || (tabId !== DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnUserTab),
                this.getToLoadStatusMap(mergedRequest.statusFilter).pipe(map(toLoad => ({
                    allData: mergedRequest.allData,
                    sortings: {},
                    statusFilter: toLoad,
                } as IDashboardFinancingRequest))),
                of(mergedRequest),
            )),
            mergeMap(
                mergedRequest => this.getDashboardFinancingsRequest(mergedRequest).pipe(
                    mergeMap(result => iif(
                        () => (tabId === DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnAllTab) || (tabId !== DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnUserTab),
                        of(void 0).pipe(mergeMap(() => this.store.dispatch(new FinancingsLoaded({ statusMap: mergedRequest.statusFilter, financings: result.dashboardFinancingViewModelList })))),
                        of(void 0).pipe(mergeMap(() => this.store.dispatch(new FinancingsPageLoaded({ page: mergedRequest.currentPage as number, maxResultsCount: result.maxResultsCount, financings: result.dashboardFinancingViewModelList, keepData })))),
                    )),
                ),
            ),
        );
    }

    /**
     * Führt den Workflow zum Anwenden von Filtern, Suche, Sortierung oder Pagination aus aus
     *
     * @param {DashboardType} dashboardType Dashboardtyp (Experte oder Referent)
     * @param {string} tabId Tab
     * @param {() => void | undefined} preLoadingCall Funktion, die vor dem Request gegen das Backend ausgeführt werden soll
     * @param {boolean} keepData Gibt an, ob bereits geladene Daten erhalten bleiben sollen bei serverseitiger Ladelogik
     * @returns {Observable<void>} Observable, der emitted, wenn Prozess abgeschlossen
     */
    private processListInteraction(dashboardType: DashboardType, tabId: string, preLoadingCall?: () => void, keepData = false): Observable<void> {
        return this.getCurrentSettings(dashboardType, tabId).pipe(
            mergeMap(
                request =>
                    iif(() => (tabId === DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnAllTab) || (tabId !== DashboardTab.All && this.dashboardConfig.useClientFilterSortingOnUserTab),
                        // TODO prüfen ob die clientseitige Filterung nach den ganzen umbauten noch funktioniert
                        this.store.selectOnce((it: IDashboardStateParentDefinition) => (it.dashboard.dashboards[dashboardType].tabState.find(tab => tab.tabId === tabId) as IDashboardTabOptions)).pipe(
                            map(dashboardTabOptions => (new ClientFilterSorting({
                                tabId,
                                options: {
                                    tabId,
                                    isTemporaryUser: dashboardTabOptions.isTemporaryUser,
                                    currentPage: request.currentPage ?? 1,
                                    currentPageSize: request.entityCountPerPage,
                                    currentFilter: dashboardTabOptions.currentFilter,
                                    currentSorting: request.sortings,
                                    currentSearch: request.textSearch,
                                },
                            }))),
                            mergeMap(action => iif(
                                () => HelperService.isObjectEmpty(request.statusFilter),
                                of(void 0).pipe(mergeMap(() => this.store.dispatch(action))),
                                this.loadFinancings(dashboardType, tabId, request, preLoadingCall, keepData).pipe(mergeMap(
                                    () => this.store.dispatch(action),
                                )),
                            )),
                        ),
                        this.loadFinancings(dashboardType, tabId, request, preLoadingCall, keepData),
                    ),
            ),
        );
    }

    /**
     * Liefert die noch zu ladende Statuskombinationen als Observable
     *
     * @param {DashboardStatusMap} loadingStatusMap Benötigte Statuskombinationen
     * @returns {Observable<DashboardStatusMap>} Zu ladende Statuskombinationen als Observable
     */
    private getToLoadStatusMap(loadingStatusMap: DashboardStatusMap): Observable<DashboardStatusMap> {
        return this.store.selectOnce(FinancingsState.getUnloadedStatesByStateMap).pipe(
            map(filterFn => filterFn(loadingStatusMap)),
        );
    }

    /**
     * Liefert den Dashboard-HttpRequest als Observable
     *
     * @param {IDashboardFinancingRequest} request Request-Daten
     * @returns {Observable<IDashboardFinancingResponse>} Dashboard-HttpRequest als Observable
     */
    private getDashboardFinancingsRequest(request: IDashboardFinancingRequest): Observable<IDashboardFinancingResponse> {
        const url = (!!request.onlyForUserWithId) ?
            `${this.config.getEnvironment().apiUrl}/Dashboard/TemporaryFinancings` :
            `${this.config.getEnvironment().apiUrl}/Dashboard/Financings`

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsReader | Role.FinancingMapsGlobalReader, false).pipe(
            switchMap(authorized =>
                iif(() => authorized,
                    this.httpClient.get<IDashboardFinancingResponse | ITemporaryDashboardFinancing[]>(url, {
                        params: HelperService.buildHttpParams(request),
                    }).pipe(map(response => {
                        if (!!request.onlyForUserWithId && !!(response as ITemporaryDashboardFinancing[])[0]?.extendedDashboardFinancings) {
                            return (response as ITemporaryDashboardFinancing[])[0]?.extendedDashboardFinancings;
                        }

                        return response as IDashboardFinancingResponse;
                    })),
                    of({
                        dashboardFinancingViewModelList: [],
                        maxResultsCount: 0,
                    }),
                )),
        );
    }

    /**
     * Liefert die aktuellen Einstellungen des Tabs als Observable
     *
     * @param {DashboardType} dashboardType Dashboardtyp
     * @param {string} tabId Tab
     * @returns {Observable<IDashboardFinancingRequest>} Einstellungen des Tabs als Observable
     */
    private getCurrentSettings(dashboardType: DashboardType, tabId: string): Observable<IDashboardFinancingRequest> {
        return this.store.selectOnce((it: IDashboardStateParentDefinition) => it.dashboard.dashboards[dashboardType]).pipe(
            map<IDashboardStateData, IDashboardFinancingRequest>(it => {
                if (it.tabState.some(tab => tab.tabId === tabId)) {
                    const tabData = it.tabState.find(tab => tab.tabId === tabId) as IDashboardTabOptions;

                    return {
                        onlyForUserWithId: tabData.isTemporaryUser ? tabData.tabId : undefined,
                        currentPage: tabData.currentPage,
                        entityCountPerPage: tabData.currentPageSize,
                        allData: tabId === DashboardTab.All,
                        textSearch: tabData.currentSearch,
                        sortings: tabData.currentSorting,
                        statusFilter: DashboardState.getStatusMapByFilter(tabData.currentFilter, dashboardType),
                        salesPartnerCenterFilter: it.salesPartnerCenterFilter,
                        filterRegionsIds: it.salesPartnerCenterIds.length === 0 ? undefined : it.salesPartnerCenterIds,
                        userPurpose: this.dashboardTypeToRole(dashboardType),
                    }
                }
                return {
                    currentPage: 1,
                    entityCountPerPage: 20,
                    allData: tabId === DashboardTab.All,
                    sortings: {
                        [DashboardFinancingsSortValues.SubmissionDate]: SortOrder.Ascending,
                    },
                    statusFilter: DashboardState.getStatusMapByFilter(DashboardFilter.None, dashboardType),
                    salesPartnerCenterFilter: it.salesPartnerCenterFilter,
                    filterRegionsIds: it.salesPartnerCenterIds.length === 0 ? undefined : it.salesPartnerCenterIds,
                    userPurpose: this.dashboardTypeToRole(dashboardType),
                };
            }),
        );
    }

    /**
     * Gibt die passende Rolle zu einem Dashboard Typ zurück
     * 
     * @param {DashboardType} dashboardType Dashboard
     * @returns {Role} Rolle
     */
    private dashboardTypeToRole(dashboardType: DashboardType): Role {
        switch (dashboardType) {
            case DashboardType.Approver:
                return Role.Approver;
            case DashboardType.Expert:
                return Role.Expert;
            case DashboardType.Referent:
                return Role.Referent;
            default:
                throw new Error(`Unknown dashboardType: ${dashboardType}`);
        }
    }
}
