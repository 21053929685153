import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentType } from '@ntag-ef/finprocess-enums/core';
import { IDropAreaViewModel, IDropFolderViewModel } from 'app/modules/smartdoc/data';
import { sort } from 'fast-sort';
import { HelperService } from 'shared/util';

import { ISmartDocDialogData } from './../../../../../data/interfaces/smartdoc-dialog-data';

export interface ISelectDocument {
    value: number;
    displayName: string;
}
/**
 * Komponente für ein SmartDocFolder oder Area zu editieren
 */
@Component({
    selector: 'finprocess-smartdoc-edit-dialog',
    templateUrl: './smartdoc-edit-dialog.component.html',
    styleUrls: ['./smartdoc-edit-dialog.component.scss'],
})
export class SmartdocEditDialogComponent implements OnInit {

    public documentType: ISelectDocument[] = [];

    @Output()
    public folderChanged = new EventEmitter<Partial<IDropFolderViewModel>>();

    @Output()
    public dropAreaChanged = new EventEmitter<Partial<IDropAreaViewModel>>();

    public form: FormGroup | undefined;

    public name: string | undefined;

    private dropArea: Partial<IDropAreaViewModel> | undefined;

    private dropFolder: Partial<IDropFolderViewModel> | undefined;

    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef MatDialogRef
     * @param {SmartdocEditDialogComponent} data übergebene Daten
     * @param {FormBuilder} fb FormBuilder-Injektor
     */
    public constructor(
        private dialogRef: MatDialogRef<SmartdocEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISmartDocDialogData,
        private fb: FormBuilder,

    ) {}

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        //wenn es um Area form handelt
        if (!!this.data.dropArea) {
            this.dropArea = this.data.dropArea;

            //dokument typ, der schon in den folder vorhanden ist, sollte entfernt werden
            const excludeDocumentTyp = this.data.dropFolder?.dropAreas?.map(areas => areas.documentType);

            let docArray = HelperService.getEnumArray(DocumentType) as number[];

            if (!!excludeDocumentTyp) {
                docArray = docArray.filter(nr => excludeDocumentTyp.every(exNr => exNr !== nr))
            }

            this.documentType = sort((docArray as number[]).map(docTyp => ({
                value: docTyp,
                displayName: DocumentType.translate(docTyp as DocumentType) ?? '',
            }))).asc(docTyp => docTyp.displayName);

            this.form = this.fb.group({
                dropAreaName: [this.data.dropArea?.name, [Validators.required, Validators.min(3), Validators.max(50)]],
                exportName: [this.data.dropArea?.shortName],
                document: [this.data.dropArea?.documentType as unknown as number, Validators.required],
                hasQuickAccess: [this.data.dropArea?.hasQuickAccess === undefined ? false : this.data.dropArea?.hasQuickAccess],
            })
        }
        else {
            this.dropFolder = this.data.dropFolder
            this.form = this.fb.group({
                dropFolderName: [this.data.dropFolder?.name, Validators.required],
            })
        }
    }
    /**
     * Schließt den Dialog ohne Aktion
     */
    public cancel(): void {
        this.dialogRef.close();
    }

    /**
     * Daten Senden
     */
    public save(): void {
        if (!!this.form && this.form.valid) {

            if (!!this.dropArea) {
                const editedDropArea: Partial<IDropAreaViewModel> = {
                    ...this.dropArea,
                    name: this.form.get('dropAreaName')?.value,
                    shortName: this.form.get('exportName')?.value,
                    documentType: this.form.get('document')?.value,
                    hasQuickAccess: this.form.get('hasQuickAccess')?.value,
                }
                this.dropAreaChanged.emit(editedDropArea);
            }
            else {
                const editedDropFolder: Partial<IDropFolderViewModel> = {
                    ...this.dropFolder,
                    name: this.form.get('dropFolderName')?.value,
                }
                this.folderChanged.emit(editedDropFolder);
            }
        }
    }
}
