<h1 mat-dialog-title class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{'financing.features.financing-processing.financing-details.generateSampleCalculation' |
    translate}}</h1>
<div mat-dialog-content>
    <div *ngIf="!!productPackage" class="mt-2" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="column"
            *ngFor="let products of productPackage.assignProductPackages[0].assignProducts; let i = index;">
            <div class="mr-3 ml-3">
                <div *ngIf="showProductSection(products)" fxLayout="column" fxLayoutAlign="center">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 300px;">
                                    <h4><b>{{products.position}}. {{products.productCreditType |
                                            finprocessEnumTranslation:"ProductPackageType"}}</b></h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="products.variableInterest > 0">
                                <td>
                                    <div class="mr-4" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button class="mr-2" color="primary" style="margin-left: 20%;"
                                                [value]="InterestMethod.VariableThreeMo"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterest'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="products.variableInterestFix5Year > 0">
                                <td>
                                    <div class="mr-2" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button color="primary" style="margin-left: 5%;"
                                                [value]="InterestMethod.FixFiveYears"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterestFix5Year'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="products.variableInterestFix10Year > 0">
                                <td>
                                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button color="primary"
                                                [value]="InterestMethod.FixTenYears"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterestFix10Year'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="products.variableInterestFix15Year > 0">
                                <td>
                                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button color="primary"
                                                [value]="InterestMethod.FixFifteenYears"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterestFix15Year'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="products.variableInterestFix20Year > 0">
                                <td>
                                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button color="primary"
                                                [value]="InterestMethod.FixTwentyYears"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterestFix20Year'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="products.variableInterestFix25Year > 0">
                                <td>
                                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                                        <mat-radio-group [(ngModel)]="products.sampleCalculationSelectedOption"
                                            [name]="'productRadio' + i">
                                            <mat-radio-button color="primary"
                                                [value]="InterestMethod.FixTwentyfiveYears"></mat-radio-button>
                                            <div>
                                                {{'financing.features.financing-processing.financing-details.variableInterestFix25Year'
                                                | translate}}</div>
                                        </mat-radio-group>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!showProductSection(products) && products.productCreditType !== ProductPackageType.Guarantee" class="rb-info">
                   {{'financing.features.financing-processing.financing-details.generateSCwarning' | translate}}
                </div>
            </div>

        </div>
    </div>
    <div class="mt-5 mb-3" fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button color="primary" [disabled]="isGenerateRBDisabled()"
            (click)="generateSampleCalculation()">{{'financing.features.financing-processing.financing-details.generateSampleCalculation'
            | translate}}</button>
    </div>
</div>