import { IMasterdataStateModel } from './masterdata/masterdata.state';
import { ISystemConfigurationStateModel } from './system-configuration/system-configuration.state';

/**
 * Interface für globalen Zustandsspeicher
 */
export interface IMasterdataParentDefinition {
    /**
     * Zustand für Stammdaten
     */
    masterdata: IMasterdataStateModel;

    /**
     * Zustand für Systemkonfiguration
     */
    systemConfiguration: ISystemConfigurationStateModel;

}
