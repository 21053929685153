<div [ngClass]="class" class="w-100" [fxLayout]="flexDirection()" fxLayoutAlign="start center">
    <div class="label" fxFlex="50" fxFlex.lt-sm="100" fxLayoutAlign="start center">
        <label [for]="id()">
            @if (!!label() && !hideLabel()) {
            <span>{{ label() }}</span>
            } @else if (!label() && !hideLabel()) {
            <ng-content select="[label]"></ng-content>
            } @if (isRequired() && !hideLabel()) {
            <span class="required-indicator"> *</span>
            }
        </label>
        @if (overwrite() && ((!otherIsSelected() && currentValue() !== originalValue()) || (otherIsSelected() && selectedOtherValue() !== originalValue()))) {
        <mat-icon
            class="ml-1"
            [matTooltip]="'financing.features.financing-processing.originalValue' | translate : { value: (translatedOriginalValueKey() | translate) }">
            info</mat-icon
        >
        }
    </div>
    <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="column" class="w-100">
        <div class="value-container" [fxFlex]="otherIsSelected() && showOther() ? 50 : 100">
            <mat-form-field
                class="field-color"
                appearance="fill"
                fxFlex="100"
                [subscriptSizing]="subscriptSizing()"
                [class.mat-form-field-transparent-background]="transparent()">
                <mat-select [ngModel]="selectedValue()" #select="ngModel" [disabled]="(readonly() | async) ?? false" (ngModelChange)="saveSelectValue($event, select);">
                    <mat-option *ngFor="let item of internalItems()" [value]="item.value">
                        {{ item.displayName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="errorsInternal">{{ errorsInternal | finProcessFormError }}</mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="forceShowOtherField || otherIsSelected() && showOther()" class="value-container" fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field
                class="field-color"
                appearance="fill"
                fxFlex="100"
                [subscriptSizing]="subscriptSizing()"
                [class.mat-form-field-transparent-background]="transparent()">
                <input matInput [ngModel]="selectedOtherValue()" [disabled]="(readonly() | async) ?? false" (ngModelChange)="save($event)" />
                <mat-error *ngIf="errorsInternal">{{ errorsInternal | finProcessFormError }}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <hr *ngIf="!hideHorizontalRule" class="custom-hr" />
</div>
