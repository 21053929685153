import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DebitorCalculationService } from '@ntag-ef/finprocess-calculations';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { DebitorState, DocumentService, EntityClassType, FinancingService, FinancingState, IDebitor, IDocument, IExistingRentalIncome, IFile, IFinancing, IFinancingStateParentDefinition, IFutureRentalIncome, IHousehold, IUploadContext, IUploadList, OverwriteValueClassType, RentalIncomeService, ValueStorageType } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { SystemConfigurationState, SystemConfigurationType } from 'app/modules/masterdata/data';
import { FinancingStatus, HelperService, UUID } from 'app/modules/shared';
import { SharedService } from 'app/modules/shared/services/shared.service';
import { sort } from 'fast-sort';
import { BehaviorSubject, Observable, Subject, combineLatest, concatMap, distinctUntilChanged, filter, forkJoin, from, map, mergeMap, of, switchMap, take, takeUntil, tap } from 'rxjs';

import { CalcAverageIncomeDialogComponent } from '../calc-average-income-dialog/calc-average-income-dialog.component';
import { CalculationExamplesDialogComponent } from '../calculation-examples-dialog/calculation-examples-dialog.component';
import { RentalIncomeDialogComponent } from '../rental-income-dialog/rental-income-dialog.component';

export interface IDebitorItem {
    debitor: IDebitor;
    housholdPos: number;
    isSelected: boolean;
    firstNameOverwrite: string,
    lastNameOverwrite: string,
    householdId: string,
    sustainableFiles: IFile[];
}
interface IHouseholdVisbilityMap {
    debitors: IDebitorVisibilityMap[];
}

interface IDebitorVisibilityMap {
    notesIncome: boolean;
    otherIncomeContainsAlimony: boolean;
    notesOtherIncome: boolean;
}

/**
 * Borrower Check Ausgaben
 */
@Component({
    selector: 'finprocess-borrower-check-income',
    templateUrl: './borrower-check-income.component.html',
    styleUrls: ['./borrower-check-income.component.scss'],
})
export class BorrowerCheckIncomeComponent implements OnInit, OnDestroy {

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Rental Income Readonly 
     */
    public sustainableRentalIncomeReadonly$ = of(true);

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    /**
     * Wirtschaftlich führender Kreditnehmer
     */
    @Select(FinancingState.economicLeader)
    public economicLeader$!: Observable<IDebitor | undefined>


    public sustainableFiles: IFile[] | undefined;
    /**
     * Haushalte
     */
    public households: IHousehold[] | undefined;

    /**
     * Kreditnehmer
     *
     * @returns {Observable<IDebitor[]>} Observable Kreditnehmer sortiert
     */
    public debitors$!: Observable<IDebitorItem[]>;

    public oldSortedDebtorItems: IDebitorItem[] | undefined;

    public selectedIndex = 0;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * ausgewählter Kreditnehmer
     */
    public selectedDebitor = new BehaviorSubject<IDebitorItem | undefined>(undefined);

    /**
     * VisibilityMap für die Anzeige der felder
     */
    public visibilityMap?: IHouseholdVisbilityMap;

    /**
     * Haushalt selektiert
     */
    public overviewSelected?: boolean = true;

    private calculationService = new DebitorCalculationService();

    public entityTypeDebtor = EntityClassType.Debitor;

    /**
     * check financing status to enable/disbale sustainable buttons
     */
    public statusEnable = new BehaviorSubject<boolean>(false);

    public fileUploadInProgress = new BehaviorSubject<boolean>(false);

    private financingId: string | undefined;

    /**
     * Monatliches nachhaltiges Nettoeinkommen
     */
    public monthlyNetIncome: Observable<() => number> | undefined;

    /**
     * Gesamtbetrag nachhaltiges Einkommen
     */
    public totalSustainable: Observable<() => number> | undefined;

    /**
     * Nachhaltige Gesamtmieteinnahmen
     */
    public sustainableTotalRentalIncome: Observable<() => number> | undefined;

    /**
     * Transferkosten gemaess KIM-V
     */
    public transferChargesKimV$: Observable<() => number> | undefined;

    /**
     * Liste der bestehenden Mietteinnahmen
     */
    public existingRentalIncomes$?: Observable<IExistingRentalIncome[]>;

    /**
     * Liste der zukünftigen Mietteinnahmen
     */
    public futureRentalIncomes$?: Observable<IFutureRentalIncome[]>;

    /**
     * Anzahl der Kinder mit Überschreibungen
     */
    public numberOfChildrenWithOverwrites$?: Observable<number>;

    public calculatorUrl: string | undefined;

    public debitorMultiUploadContext!: IUploadContext[];

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Ladezustand der Mieteinnahmen
     */
    public loadingRentalIncomes = false;

    /**
     * Konstruktor
     *
     * @param {Store} store store
     * @param {WaiterService} waiterService waiter service
     * @param {NotificationService} notification notification
     * @param {TranslateService} translate translate
     * @param {DocumentService} documentService documentService
     * @param {ChangeDetectorRef} changeDetection changeDetection
     * @param {ActivatedRoute} activatedRoute activatedRoute
     * @param {FinancingService} financingService financingService
     * @param {MatDialog} dialog dialog
     * @param {SharedService} sharedService SharedService
     * @param {RentalIncomeService} rentalIncomeService rentalIncomeService
     */
    public constructor(private store: Store, private waiterService: WaiterService, private notification: NotificationService,
        private translate: TranslateService, private documentService: DocumentService, private changeDetection: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute, private financingService: FinancingService, private dialog: MatDialog,
        private sharedService: SharedService, private rentalIncomeService: RentalIncomeService,
    ) {
    }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
        this.activatedRoute.params.pipe(
            takeUntil(this.onDestroy$),
            map(params => params['financingContainerId'] as UUID),
            distinctUntilChanged(),
            filter(id => id !== undefined),
            mergeMap(id => {
                const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
                const finprocessContainer = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer);

                const financingRequest = (!!financing && financing.id === id) ? of(financing) : this.financingService.loadFinancing(id);
                const containerRequest = (!!finprocessContainer && finprocessContainer.id === id) ? of(finprocessContainer) : this.financingService.loadFinancingContainer(id);

                return forkJoin([financingRequest, containerRequest]);
            }),
            tap(([financing, finProcessContainer]) => {
                this.financingId = financing?.id;
                this.statusEnable.next(finProcessContainer?.status !== FinancingStatus.Open);
            }),
        ).subscribe();

        this.debitors$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            tap(it => { this.households = it?.households }),
            switchMap(financing => this.economicLeader$.pipe(map(leader => ({ financing, leader })))),
            map(resposne => {
                const temp: IDebitorItem[] = [];
                if (resposne.financing === undefined) {
                    return temp;
                }

                const oldSelectedDebtor = this.oldSortedDebtorItems !== undefined ? this.oldSortedDebtorItems[this.selectedIndex] : undefined

                for (const h of resposne.financing.households) {
                    for (const d of h.debitors) {
                        temp.push({
                            debitor: d,
                            isSelected: false,
                            housholdPos: h.position,
                            householdId: h.id,
                            firstNameOverwrite: OverwriteHelperService.getMergedOverwriteValue(d, 'firstName', d.firstName),
                            lastNameOverwrite: OverwriteHelperService.getMergedOverwriteValue(d, 'lastName', d.lastName),
                            sustainableFiles: d.documents.filter(doc => doc.type === DocumentType.SustainableIncome).map(doc => doc?.files)?.reduce((acc, files) => acc?.concat(files), []),
                        } as IDebitorItem)
                    }
                }


                const sorted = sort(temp).desc(b => b.debitor.id === resposne?.leader?.id && this.calculationService.netIncomeTotal({ netIncome: b.debitor.netIncome, fourteenSalariesPerYear: b.debitor.fourteenSalariesPerYear, otherIncome: b.debitor.otherIncome }));

                if (!!oldSelectedDebtor) {
                    this.selectedIndex = sorted.findIndex(x => x.debitor.id === oldSelectedDebtor?.debitor.id);
                    this.selectedDebitor.next(sorted[this.selectedIndex])
                } else {
                    this.selectedDebitor.next(sorted[0])
                }
                this.oldSortedDebtorItems = sorted;
                return sorted;
            }),
        );

        this.totalSustainable = combineLatest([this.selectedDebitor, this.store.select(FinancingState.calculateSustainableTotalIncome)])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([selectedDebitor, calculationFn]) => {
                    if (!selectedDebitor) {
                        return () => 0;
                    }

                    return () => calculationFn(selectedDebitor.householdId, selectedDebitor.debitor.id);
                }),
            );

        this.monthlyNetIncome = combineLatest([this.selectedDebitor, this.store.select(FinancingState.calculateSustainableMonthlyNetIncome)])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([selectedDebitor, calculationFn]) => {
                    if (!selectedDebitor) {
                        return () => 0;
                    }

                    return () => calculationFn(selectedDebitor.householdId, selectedDebitor.debitor.id);
                }),
            );

        this.sustainableTotalRentalIncome = combineLatest([this.selectedDebitor, this.store.select(FinancingState.calculateSustainableTotalRentalIncome)])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([selectedDebitor, calculationFn]) => {
                    if (!selectedDebitor) {
                        return () => 0;
                    }

                    return () => calculationFn(selectedDebitor.householdId, selectedDebitor.debitor.id);
                }),
            );
        
        this.transferChargesKimV$ = combineLatest([this.selectedDebitor, this.store.select(FinancingState.additionalTransferChargesByDebitor)]).pipe(
            takeUntil(this.onDestroy$),
            map(([selectedDebitor, calculationFn]) => {
                if (!selectedDebitor) {
                    return () => 0;
                }
                return () => calculationFn(selectedDebitor.debitor.id);
            }),
        );

        this.numberOfChildrenWithOverwrites$ = this.selectedDebitor.pipe(
            takeUntil(this.onDestroy$),
            filter((selectedDebitor): selectedDebitor is IDebitorItem => !!selectedDebitor),
            map(selectedDebitor => OverwriteHelperService.getMergedOverwriteValue(selectedDebitor.debitor, 'childrenCount', selectedDebitor.debitor.childrenCount)),
        );

        this.store.select(SystemConfigurationState.getSystemConfiguration).pipe(takeUntil(this.onDestroy$)).subscribe(config => {
            this.calculatorUrl = config(SystemConfigurationType.BruttoNettoCalculatorUrl) as string;
        });

        this.calculatorUrl = this.store.selectSnapshot(SystemConfigurationState.getSystemConfiguration)(SystemConfigurationType.BruttoNettoCalculatorUrl) as string;

        this.existingRentalIncomes$ = combineLatest([
            this.selectedDebitor,
            this.store.select(DebitorState.existingRentalIncomesByDebitor),
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([selectedDebitor, existingRentalIncomesFn]) => {
                if (!selectedDebitor) {
                    return [];
                }

                const existingRentalIncomes = existingRentalIncomesFn(selectedDebitor.debitor.id);

                //calculate 70% of netRentPerMonth to show in HTML
                return existingRentalIncomes.map(income => ({
                    ...income,
                    netRentPerMonth70Percent: income.netRentPerMonth * 0.7,
                }));
            }),
        );

        this.futureRentalIncomes$ = combineLatest([
            this.selectedDebitor,
            this.store.select(DebitorState.futureRentalIncomesByDebitor),
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([selectedDebitor, futureRentalIncomesFn]) => {
                if (!selectedDebitor) {
                    return [];
                }

                return futureRentalIncomesFn(selectedDebitor.debitor.id);
            }),
        );
        
        const relevantDocumentTypes = [
            DocumentType.SustainableIncome,
            DocumentType.BalanceSheet,
            DocumentType.AccountingOnCashBasis,
            DocumentType.IncomeTaxReturn,
            DocumentType.Lease,
            DocumentType.SalaryStatement,
            DocumentType.AlimonyIncome,
            DocumentType.IncomeTaxAssessment,
            DocumentType.PensionNotice,
            DocumentType.AlimonyToBePaid,
            DocumentType.DivorceSettlementAgreement,
            DocumentType.JudicialDivorceDecree,
            DocumentType.EmploymentContract,
            DocumentType.InsuranceApplications,
            DocumentType.ChildcareAllowanceNotice,
            DocumentType.RegionalHealthInsuranceStatement,
            DocumentType.ParentalLeaveNotice,
        ];

        this.documents$ = this.selectedDebitor.pipe(
            takeUntil(this.onDestroy$),
            map(selectedDebitor =>
                (documents: IDocument[]) => documents.filter(document =>
                    document.parentId === selectedDebitor?.debitor.id &&
                    relevantDocumentTypes.includes(document.type),
                ),
            ),
        );

        this.updateRentalIncomes();
        this.loadRentalIncomes();
    }

    /**
     * Debtor geändert
     *
     * @param {IDebitorItem} debitor IDebitorItem
     * @param {number} index index
     */
    public changeSelectedDebtor(debitor: IDebitorItem, index: number): void {
        this.selectedIndex = index;
        this.selectedDebitor.next(debitor);
    }

    /**
     * Hochrechnung auf 12 Monate
     *
     * @param {IDebitor} debtor Debtor
     * @param {string} householdId householdId
     * @returns {() => number} Summe
     */
    public calculateMonthlyNetIncome(debtor: IDebitor, householdId: string): Observable<() => number> {
        return this.store.selectOnce(FinancingState.calculateSustainableMonthlyNetIncome).pipe(
            map(calculationFunction => {
                if (!householdId) {
                    return () => 0;
                }
                return () => calculationFunction(householdId, debtor.id);
            }),
        );
    }

    /**
     * Summe nachhaltige monatlichen Einnahme
     *
     * @param {IDebitor} debtor Debtor
     * @param {string} householdId householdId
     * @returns {() => number} Summe
     */
    public calculateTotalSustainable(debtor: IDebitor, householdId: string): Observable<() => number> {
        return this.store.selectOnce(FinancingState.calculateSustainableTotalIncome).pipe(
            map(calculationFunction => {
                if (!householdId) {
                    return () => 0;
                }
                return () => calculationFunction(householdId, debtor.id);
            }),
        );
    }

    /**
     * Berechnung der gesamten Mieteinnahmen
     *
     * @param {IDebitor} debtor Debtor
     * @param {string} householdId householdId
     * @returns {() => number} Summe
     */
    public calculateSustainableTotalRentalIncome(debtor: IDebitor, householdId: string): Observable<() => number> {
        return this.store.selectOnce(FinancingState.calculateSustainableTotalRentalIncome).pipe(
            map(calculationFunction => {
                if (!householdId) {
                    return () => 0;
                }
                return () => calculationFunction(householdId, debtor.id);
            }),
        );
    }

    /**
     * Öffnet den Multiupload
     *
     * @param {string} debtorId debtorId
     */
    public uploadMultiFiles(debtorId: string): void {
        const uploadContext: IUploadContext = {
            title: 'Nachhaltiges Einkommen',
            buttonLabel: '',
            parentId: debtorId,
            lists: [
                { list: [], type: DocumentType.SustainableIncome },
            ],
        };

        const dialogRef = this.dialog.open(CalculationExamplesDialogComponent, {
            minWidth: '70%',
            data: uploadContext,
        });

        dialogRef.afterClosed().pipe(
            take(1),
            tap(() => this.waiterService.show()),
            map((newFiles: IUploadList[]) => {
                if (!!newFiles && newFiles.length > 0) {
                    const requests: Observable<IDocument | undefined>[] = [];

                    newFiles[0].list.forEach(uploadDocument => {
                        requests.push(this.documentService.uploadDebitorFile({
                            debitorId: debtorId,
                            file: uploadDocument.file,
                            mapId: this.financingId as string,
                            type: DocumentType.SustainableIncome,
                        }),
                        )
                    });
                    return requests;
                } else {
                    return undefined
                }

            }),
            filter((requests): requests is Observable<IDocument | undefined>[] => Array.isArray(requests)),
            mergeMap(requests =>
                // Using `from` to emit each request observable one by one and `concatMap` to process them sequentially
                from(requests).pipe(
                    concatMap(request => request),
                ),
            ),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.notification.toast('Belege für geprüftes Einkommen wurden erfolgreich hochgeladen.');
                this.fileUploadInProgress.next(false);
                this.changeDetection.detectChanges();
            },
            error: () => {
                this.waiterService.hide(),
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                this.fileUploadInProgress.next(false);
            },
            complete: () => this.waiterService.hide(),
        });
    }

    /**
     * Öffnet Datei zur Anzeige
     *
     * @param {File} file Datei
     */
    public openFile(file: IFile): void {
        this.waiterService.show();
        this.sharedService.loadFile(file.id)
            .subscribe((fileBase64: string | undefined) => {
                this.sharedService.openFile({
                    id: file.id,
                    mimeType: file.mimeType,
                    base64: fileBase64,
                    name: file.name,
                    extension: file.extension,
                } as IFile);
                this.waiterService.hide();
            },
            );
    }

    /**
     * Lädt Nachhaltiges Einkommen beleg
     *
     * @param {IFile} file IFile
     */
    public sustainableDocumentDownload(file: IFile): void {
        this.waiterService.show();
        if (!!file) {
            this.documentService.loadFile(file?.id).pipe(
                take(1),
            ).subscribe({
                next: async fileContent => {
                    const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                    if (!blob) {
                        return;
                    }

                    await HelperService.downloadFileFromBlob(blob, file.name);
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide(),
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            })
        }
    }

    /**
     * Löscht einen Nachhaltiges Beleg
     *
     * @param {string} debtorId Debtor
     * @param {IFile} file IFile
     */
    public sustainableDocumentDelete(debtorId: string, file: IFile): void {

        if (!!file && !!this.financingId) {
            this.notification.confirmYesNo(
                this.translate.instant('financing.features.financing-processing.debitor.deleteLasting'),
                this.translate.instant('financing.features.financing-processing.debitor.deleteLastingMsg'),
            ).pipe(
                tap(() => { this.waiterService.show(); this.fileUploadInProgress.next(false); }),
                mergeMap(result => {
                    if (result === 'submit') {
                        return this.documentService.deleteDebtorFile({ fileId: file?.id, debtorId: debtorId, mapId: this.financingId as string, documentType: DocumentType.SustainableIncome })
                    }
                    return of(void 0);

                }),
            ).subscribe({
                next: res => {
                    if (!!res) {
                        this.notification.toast(this.translate.instant('financing.features.financing-processing.debitor.lastingDeleted'))
                    }
                    this.waiterService.hide();
                    this.fileUploadInProgress.next(false);
                    this.changeDetection.detectChanges();
                },
                error: () => {
                    this.waiterService.hide();
                    this.fileUploadInProgress.next(false);
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
            });

        }
    }

    /**
     * open Durchschnittsberechnung Dialog
     *
     * @param {UUID} debitorID debitorID
     */
    public openDSBDialog(debitorID: UUID) {
        const dialogRef = this.dialog.open(CalcAverageIncomeDialogComponent, {
            data: debitorID,
        });

        //update sustainableNetIncome
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (!!result && result.netIncome !== undefined) {
                this.financingService.saveInternalField<IDebitor>({
                    financingMapId: this.financingId,
                    entityId: debitorID,
                    fieldName: 'sustainableNetIncome',
                    entityClassType: EntityClassType.Debitor,
                    value: result.netIncome,
                }).pipe(take(1)).subscribe();
            }
        });
    }

    /**
     * Öffnet den Brutto-Netto-Rechner
     */
    public openCalculatorUrl() {
        if (!!this.calculatorUrl) {
            window.open(this.calculatorUrl, '_blank');
        }
    }
    
    /**
     * Öffnet den Mieteinnahmen Dialog
     * 
     * @param { 'existing' | 'future' } type Typ
     * @param {UUID} assignedDebitorId ID des Kreditnehmers
     * @param {IExistingRentalIncome | IFutureRentalIncome} rentalIncome Mieteinnahmen, wenn vorhanden
     */
    public openRentalIncomeDialog(type: 'existing' | 'future', assignedDebitorId: UUID, rentalIncome?: IExistingRentalIncome | IFutureRentalIncome): void {
        const dialogRef = this.dialog.open(RentalIncomeDialogComponent, { minWidth: '40vw', data: { type, rentalIncome: { ...rentalIncome, assignedDebitorId}} });

        dialogRef.afterClosed().subscribe({
            next: (result?: IExistingRentalIncome | IFutureRentalIncome) => {
                if (result) {
                    let request: Observable<unknown> | null = null;
                    if (type === 'existing') {
                        if (rentalIncome !== undefined) {
                            request = this.rentalIncomeService.updateExistingRentalIncome(result as IExistingRentalIncome);
                        } else {
                            request = this.rentalIncomeService.addExistingRentalIncome({...result as IExistingRentalIncome, id: undefined});
                        }
                    } else {
                        if (rentalIncome !== undefined) {
                            request = this.rentalIncomeService.updateFutureRentalIncome(result as IFutureRentalIncome);
                        } else {
                            request = this.rentalIncomeService.addFutureRentalIncome({...result as IFutureRentalIncome, id: undefined});
                        }
                    }

                    this.waiterService.show();
                    request.subscribe({
                        next: () => {
                            this.waiterService.hide();
                        },
                        error: () => {
                            this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                            this.waiterService.hide();
                        },
                        complete: () => {
                            this.waiterService.hide();
                        },
                    })
                }
            },
        })
    }

    /**
     * Löscht eine Mieteinnahme
     *
     * @param { 'existing' | 'future' } type Typ
     * @param {IExistingRentalIncome | IFutureRentalIncome} rentalIncome Mieteinnahmen
     */
    public deleteRentalIncome(type: 'existing' | 'future', rentalIncome: IExistingRentalIncome | IFutureRentalIncome): void {
        const confirm = this.notification.confirmYesNo(this.translate.instant('general.warning'), this.translate.instant('financing.features.financing-processing.rental-income.confirmDelete'));

        confirm.subscribe({
            next: result => {
                if (result === 'submit') {
                    this.waiterService.show();
                    let request: Observable<void | undefined> | null = null;

                    if (type === 'existing') {
                        request = this.rentalIncomeService.deleteExistingRentalIncome(rentalIncome.assignedDebitorId, rentalIncome.id);
                    } else {
                        request = this.rentalIncomeService.deleteFutureRentalIncome(rentalIncome.assignedDebitorId, rentalIncome.id);
                    }

                    request.subscribe({
                        next: () => {
                            this.waiterService.hide();
                        },
                        error: () => {
                            this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                            this.waiterService.hide();
                        },
                        complete: () => {
                            this.waiterService.hide();
                        },
                    })
                }
            },
        });
    }

    /**
     * Angular-Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Aktualisiert den Eingabewert für die Mieteinnahmen
     */
    private updateRentalIncomes(): void {
        combineLatest([
            this.existingRentalIncomes$ ?? of([]),
            this.selectedDebitor.pipe(filter((debitor): debitor is IDebitorItem => debitor !== undefined)),
        ]).pipe(takeUntil(this.onDestroy$)).subscribe({
            next: ([existingRentalIncomes, selectedDebitor]) => {
                const rentalIncome = existingRentalIncomes.reduce<number>((totalRentalIncome, currentRentalIncome) => totalRentalIncome + currentRentalIncome.netRentPerMonth70Percent, 0);

                if (existingRentalIncomes.length > 0 && rentalIncome !== selectedDebitor.debitor.sustainableExistingRentalIncome) {
                    this.financingService.saveInternalField({
                        entityClassType: EntityClassType.Debitor,
                        fieldName: 'sustainableExistingRentalIncome',
                        entityId: selectedDebitor.debitor.id,
                        valueStorageType: ValueStorageType.Decimal,
                        financingMapId: this.financingId,
                        value: rentalIncome,
                    }).subscribe();
                }
            },
        });

        combineLatest([
            this.futureRentalIncomes$ ?? of([]),
            this.selectedDebitor.pipe(filter((debitor): debitor is IDebitorItem => debitor !== undefined)),
        ]).pipe(takeUntil(this.onDestroy$)).subscribe({
            next: ([futureRentalIncomes, selectedDebitor]) => {
                const rentalIncome = futureRentalIncomes.reduce<number>((totalRentalIncome, currentRentalIncome) => totalRentalIncome + currentRentalIncome.netRent70PercentPerMonth, 0);

                if (futureRentalIncomes.length > 0 && rentalIncome !== selectedDebitor.debitor.sustainableRentalIncome) {
                    this.financingService.saveInternalField({
                        entityClassType: EntityClassType.Debitor,
                        fieldName: 'sustainableRentalIncome',
                        entityId: selectedDebitor.debitor.id,
                        valueStorageType: ValueStorageType.Decimal,
                        financingMapId: this.financingId,
                        value: rentalIncome,
                    }).subscribe();
                }
            },
        });
    }

    /**
     * Lädt die Mieteinnahmen
     */
    private loadRentalIncomes(): void {
        this.selectedDebitor.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged((previous, current) => previous?.debitor.id === current?.debitor.id),
            filter((debitor): debitor is IDebitorItem => debitor !== undefined),
        ).subscribe(debitor => {
            const requests = [
                this.rentalIncomeService.getExistingRentalIncomes(debitor.debitor.id),
                this.rentalIncomeService.getFutureRentalIncomes(debitor.debitor.id),
            ];

            this.loadingRentalIncomes = true;
            forkJoin(requests).pipe(take(1)).subscribe({
                error: () => {
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                },
                complete: () => {
                    this.loadingRentalIncomes = false;
                },
            });
        });
    }
}
