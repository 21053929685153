import { EnumSettings } from './../enum.settings';

/**
 * Original an
 */
export enum OriginalToType {
    /**
     * Begünstigten
     */
    Beneficiary = 0,

    /**
     * Filiale
     */
    Branch = 1,

    /**
     * Sonstige
     */
    Other = 2,
}

export namespace OriginalToType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {OriginalToType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: OriginalToType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'OriginalToType', OriginalToType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('OriginalToType', plocale);
}
