import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { FinancingPremissions, Language } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { IUpdateUserRequest } from 'app/modules/administration/data/interfaces';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IMasterdataParentDefinition, ISalesPartnerCenter } from 'app/modules/masterdata/data';
import { inPlaceSort } from 'fast-sort';
import { Observable, Subject, combineLatest, map, mergeMap, of, startWith, take, takeUntil } from 'rxjs';

import { IUser } from './../../../../../auth/data/interfaces/user.interface';
import { UserAdministrationService } from './../../../../data/services/user-administration/user-administration.service';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';

/**
 * Componente um Salespartner region zu manipulieren
 */
@Component({
    selector: 'finprocess-salespartner-region',
    templateUrl: './salespartner-region.component.html',
    styleUrls: ['./salespartner-region.component.scss'],
})
export class SalespartnerRegionComponent implements OnInit, OnDestroy {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    public test = '';
    public myControl = new FormControl('');

    @Select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters)
    public salesPartnerCenters$!: Observable<ISalesPartnerCenter[]>;

    @Select((it: IAdministrationStateParentDefinition) => it.userAdministration.users)
    public users$!: Observable<IUser[]>;
    
    public filteredOptions$!: Observable<IUser[]>;

    private onDestroy$ = new Subject<void>();

    /**
     * Nutzer die dem ausgewählten Salespartnercenter zugeordnet sind
     */
    public assignedUsers: IUser[] = [];

    /**
     * Nutzer die dem ausgewählten Salespartnercenter NICHT zugeordnet sind
     */
    private unassignedUsers: IUser[] = [];

    public unassignedUsersForAutocomplete: IUser[] = [];

    /**
     * Nutzer die über das Suchfeld ausgewählt wurden
     */
    public selectedUsers: IUser[];

    public allUsers: IUser[] = [];
    public allSalesPartnerCenter: ISalesPartnerCenter[] = [];

    /**
     * Das ausgewählte Salespartnercenter (oder "Keine" ausgewählt)
     */
    public selectedSalesPartnerCenter: ISalesPartnerCenter | undefined;

    /**
     * Hat der Nutzer ausreichende Rechte die Seite zu bearbeiten
     */
    public userCanEdit = false;

    /**
     * Standard Konstruktor
     *
     * @param {UserAdministrationService} userAdministration UserAdministrationService injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} transalteService TranslateService injector
     * @param {WaiterService} waiterService WaiterService injector
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private userAdministration: UserAdministrationService,
        private notification: NotificationService,
        private transalteService: TranslateService,
        private waiterService: WaiterService,
        private authorizationService: AuthorizationService,

    ) {
        this.selectedUsers = [];
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {

        this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => value ?? ''),
            map(name => (name ? this.filter(name) : this.unassignedUsers.slice())),
        ).subscribe(res => {
            this.unassignedUsersForAutocomplete = res;
        })

        this.authorizationService.hasRole$(Role.OrganisationalUnitAdministrator).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(hasRole => {
            this.userCanEdit = hasRole;
            if (hasRole) {
                this.myControl.enable();
            } else {
                this.myControl.disable();
            }
        });

        this.userAdministration.getList()
            .pipe(take(1))
            .subscribe();

        combineLatest([this.salesPartnerCenters$, this.users$]).pipe(
            takeUntil(this.onDestroy$),
            map(([salesCenters, users]) => {
                this.allUsers = users;
                this.allSalesPartnerCenter = salesCenters;

                if (this.selectedSalesPartnerCenter) {
                    this.selectedSalesPartnerChanged(this.selectedSalesPartnerCenter)
                }
            }),
        ).subscribe();
    }

    /**
     * Funktion für das Suchfeld (mat-autocomplete)
     * Leert den vom nutzer eingegeben Text nach der Auswahl
     *
     * @returns {string} leer string, um input feld zu leeren
     */
    // eslint-disable-next-line class-methods-use-this
    public displayFn(): string {
        return '';
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Wird aufgerufen wenn ein Salespartnercenter ausgewählt wird
     *
     * @param {ISalesPartnerCenter} salesCenter ISalesPartnerCenter
     */
    public selectedSalesPartnerChanged(salesCenter?: ISalesPartnerCenter): void {

        if (!!salesCenter) {
            this.selectedSalesPartnerCenter = salesCenter;

            this.assignedUsers = this.sortAssignedUsersAscByFirstName(this.allUsers.filter(user => user.salesPartnerCenterId?.includes(salesCenter.id)));

            this.unassignedUsers = this.allUsers.filter(user => !user.salesPartnerCenterId?.includes(salesCenter.id));
            this.unassignedUsersForAutocomplete = this.unassignedUsers;

        } else {
            this.assignedUsers = [];
            this.unassignedUsers = [];
            this.selectedSalesPartnerCenter = undefined;
        }

        this.selectedUsers = [];
    }

    /**
     * Wird aufgerufen wenn der Nutzer die über die Textsuche ausgewählten Nutzer zum Salespartnercenter hinzufügt
     *
     */
    public addSalespartnerCenterToUsers(): void {
        const editedUser = this.selectedUsers.map(user => {
            const salesCenters = user.salesPartnerCenterId?.slice();

            if (!!this.selectedSalesPartnerCenter) {
                salesCenters?.push(this.selectedSalesPartnerCenter.id);
            }
            const newUser: IUpdateUserRequest = {
                ...user,
                languages: user.languages ?? Language.None,
                financingPermissions: user.financingPermissions ?? FinancingPremissions.None,
                userPurpose: (user?.roles ?? 0) & (Role.Expert | Role.Referent),
                salesPartnerCenterId: salesCenters,
            };

            return newUser;
        });

        this.notification.confirmOkCancel(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersConfirmTitle'), this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersConfirmText', { users: this.selectedUsers.map(user => (`${user.firstName} ${user.lastName}, ${user.email}`)).join('<br>'), region: this.selectedSalesPartnerCenter?.name })).pipe(
            mergeMap(result => {
                if (result === 'submit') {
                    this.waiterService.show();
                    return this.userAdministration.updateAll(editedUser);
                }
                return of()
            }),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.notification.toast(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersToast'));
                this.selectedUsers = [];

            },
            error: (error: HttpErrorResponse) => {
                this.waiterService.hide();
                if (error.status === HttpStatusCode.NotModified) {
                    this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText1'));
                }
                else if (error.status === HttpStatusCode.Conflict) {

                    if (error.error === null) {
                        this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText4'));
                    }
                    else {
                        this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText5', { user: error.error }));
                    }
                }
                else {
                    this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.addSalespartnerCenterToUsersErrorText2'));
                }
            },
        });
    }

    /**
     * Löscht einen Center von User
     *
     * @param {IUser} user User
     */
    public deleteSalesCenterFromUser(user: IUser): void {

        this.notification.confirmOkCancel(
            this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserConfirmTitle'),
            this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserConfirmText', { region: this.selectedSalesPartnerCenter?.name }),
        ).pipe(
            mergeMap(result => {
                if (result === 'submit') {
                    this.waiterService.show();
                    if (!!this.selectedSalesPartnerCenter?.id) {
                        return this.userAdministration.delteServiceCenterFromUser(user, this.selectedSalesPartnerCenter?.id);
                    }
                }
                return of();
            }),
        ).subscribe({
            next: () => {
                this.waiterService.hide();
                this.notification.toast(this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserToast'));
            },
            error: (error: HttpErrorResponse) => {
                this.waiterService.hide();
                if (error.status === HttpStatusCode.MethodNotAllowed) {
                    this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorText1'))
                }
                else if (error.status === HttpStatusCode.NotAcceptable) {
                    this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorText3'))
                }
                else {
                    this.notification.alert(this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorTitle'), this.transalteService.instant('administration.features.salespartnerRegion.deleteSalesCenterFromUserErrorText2'))
                }
            },
        })
    }

    /**
     * Hinzufügt einen User in der SelectedUsers Liste und entfernt von der unassignedUsers liste
     *
     * @param {IUser} user IUser
     */
    public addToSelectedUsers(user: IUser): void {
        this.selectedUsers.push(user);
        this.unassignedUsers = this.unassignedUsers.filter(unassignedUser => unassignedUser !== user);
        this.unassignedUsersForAutocomplete = this.unassignedUsers;
    }

    /**
     * Hinzufügt einen User in der der unassignedUsers liste und entfernt von SelectedUsers Liste
     *
     * @param {IUser} user User
     */
    public removeFromSelectedUsers(user: IUser): void {
        this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser !== user);
        this.unassignedUsers.push(user);
        inPlaceSort(this.unassignedUsers).asc(x => x.firstName);
        this.unassignedUsersForAutocomplete = this.unassignedUsers;
    }


    /**
     * Sortieren der Mitarbeiter von der liste Zugeordnete Mitarbeiter beim Vornamen
     *
     * @param {IUser[]} assignedUsers IUser[]
     * @returns {IUser[]} sortUsersAsc
     */
    public sortAssignedUsersAscByFirstName(assignedUsers: IUser[]): IUser[] {
        return inPlaceSort(assignedUsers).asc(x => x.firstName)
    }

    /**
     * Filter für mat-autocomplete
     *
     * @param {string} name Name oder nachname
     * @returns { IUser[]} filtrierte liste
     */
    private filter(name: string): IUser[] {
        const searchTerm = name.toLowerCase();

        const filterd = this.unassignedUsers.filter(user => searchTerm.split(' ').map(term => term.toLowerCase()).every(term => (user.firstName.toLowerCase().includes(term) ||
            user.lastName.toLowerCase().includes(term) ||
            user.email.toLowerCase().includes(term))));

        return filterd;
    }
}
