<h1 mat-dialog-title *ngIf="isProductPackage === true" class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{'financing.features.financing-processing.productPackages.texts.addProductPackageDialog' | translate}}</h1>
<h1 mat-dialog-title *ngIf="isProductPackage === false" class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{'financing.features.financing-processing.productPackages.texts.addProductLineDialog' | translate}}</h1>
<div mat-dialog-content>
    <h4 *ngIf="isProductPackage === true">
        {{'financing.features.financing-processing.productPackages.texts.addProductPackageSubHeader' | translate}}
    </h4>

    <div class="mb-3">
        <small class="info-text">
            {{'financing.features.financing-processing.productPackages.texts.info' | translate}}
        </small>
    </div>
    <mat-form-field>
        <mat-label>{{'financing.features.financing-processing.productPackages.texts.creditLine' |
            translate}}</mat-label>
        <mat-select [(ngModel)]="selectedValue" matNativeControl required>
            <mat-option *ngFor="let type of productPackageType" [value]="type" [disabled]="isOptionDisabled(type)">
                {{type | finprocessEnumTranslation:'ProductPackageType'}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button mat-dialog-close [disabled]="selectedValue === undefined" color="primary"
        (click)="confirmSelection()">{{'financing.features.financing-processing.productPackages.texts.confirm' |
        translate}}</button>
    <button mat-raised-button mat-dialog-close>{{'financing.features.financing-processing.productPackages.texts.cancel'
        |
        translate}}</button>
</div>