import { Component, EventEmitter , OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { UUID } from 'app/modules/shared';

import { ICreateJointHeading, IDebitor, IFinancingStateParentDefinition, IJointHeading, LiabilitiesService } from '../../../../data/';

/**
 * Create new Joint Heading
 */
@Component({
    selector: 'finprocess-create-jointheading-dialog',
    templateUrl: './create-jointheading-dialog.component.html',
    styleUrls: ['./create-jointheading-dialog.component.scss'],
})
export class CreateJointheadingDialogComponent implements OnInit {

    public jointHeadingList: IJointHeading[] = [];
    public debitorSelection: UUID[] = [];
    
    /**
     * Debitors to display
     */
    public debitorsList: IDebitor[] = [];
    
    public ndgValue!: string;

    public financingContainerID?: UUID;

    public jointHeadingCreated: EventEmitter<string> = new EventEmitter<string>();
    
    /**
     * Konstruktor
     *
     * @param {LiabilitiesService} liabilityService liability Service
     * @param {Store} store store
     * @param {WaiterService} waiterService waiter service
     * @param {NotificationService} notificationService notification service
     * @param {TranslateService} translate translate service
     */
    public constructor(private liabilityService: LiabilitiesService, private store: Store, private waiterService: WaiterService,
        private notificationService: NotificationService, private translate: TranslateService) {}


    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        this.getAllJointHeadings();
        this.getAllDebitors();
    }

    /**
     * get all Debitors
     */
    public getAllDebitors(): void {
        if (this.financingContainerID) {
            this.liabilityService.getAllDebitors(this.financingContainerID).subscribe(debitors => {
                if (debitors) {
                    this.debitorsList = debitors;
                }
            });
        }
    }
    
    /**
     * add new joint heading
     */
    public addNewJointHeading() {
        if (this.financingContainerID) {
            const newJH: ICreateJointHeading = {
                containerID: this.financingContainerID,
                debitorIds: this.debitorSelection,
                ndg: this.ndgValue,
            };

            this.liabilityService.createNewJointHeading(newJH).subscribe({
                next: result => {
                    if (!!result) {
                        this.jointHeadingCreated.emit(result.id);
                    }
                    this.waiterService.hide();
                    this.notificationService.toast(this.translate.instant('financing.features.financing-processing.jointHeading.addedNewJH'));  
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.jointHeading.createJHError'));
                },
            });
        }
    }

    /**
     * get all Joint Headings
     */
    public getAllJointHeadings() {
        this.jointHeadingList = [];
        this.waiterService.show();
        if (this.financingContainerID) {
            this.liabilityService.getLiabilities(this.financingContainerID).subscribe({
                next: liabilities => {
                    this.jointHeadingList = liabilities?.jointHeadings || [];
                    this.waiterService.hide();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.liabilities.getLiabilitiesError'));
                },
            });
        }
    }

    /**
     * get Debitor Names for Tooltip
     * 
     * @param {IDebitor} debitor debitor names
     * @returns {string} tooltip text
     */
    public getDebitorsTooltip(debitor: IDebitor): string {
        return `${debitor.firstName} ${debitor.lastName}`;
    }
      

}
