import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IVerifiedCustomer } from 'app/modules/financing/data';

import { UcbaHeadingType } from '../../../../../../../../../../libs/components/heading/src/types';

/**
 * Asset Provider
 */
@Component({
    selector: 'finprocess-asset-provider-details-dialog',
    templateUrl: './asset-provider-details-dialog.component.html',
    styleUrls: ['./asset-provider-details-dialog.component.scss'],
})
export class AssetProviderDetailsDialogComponent implements OnInit {

    public headingType: UcbaHeadingType = 'medium';

    public inputFlexDirection: 'row' | 'column' = 'column';

    public assetProviders: IVerifiedCustomer | undefined;

    /**
     * Konstruktor
     * 
     * @param {IVerifiedCustomer[]} data data
     */
    public constructor(@Inject(MAT_DIALOG_DATA) public data: IVerifiedCustomer[]) { }
    
    /**
     * OnInit
     */
    public ngOnInit() {
        if (!!this.data) {
            this.assetProviders = this.data.find(item => item.searchedByNdg === true);
        }
    }
}
