/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Logout } from 'auth/data/states/authentication/authentication.actions';
import { IsoDate } from 'shared/util';

import { IApprovalCompetence, IBankAccount, IBranch, ICountry, ILegalisationFeeBase, ISalesPartnerCenter } from '../../interfaces';

import { ApprovalCompetenceLoaded, BankAccountsLoaded, BranchesLoaded, CountriesLoaded, CountryChanged, LegalisationFeeBasesLoaded, SalesPartnerCentersLoaded } from './masterdata.actions';

/**
 * Zustandsobjekt für Stammdaten
 */
export interface IMasterdataStateModel {
    /**
     * Kontoverbindungen
     */
    bankAccounts: IBankAccount[];
    /**
     * Kontoverbindungen zuletzt geändert am
     */
    bankAccountsLastModified?: IsoDate;
    /**
     * Filialen
     */
    branches: IBranch[];
    /**
     * Filialen zuletzt geändert am
     */
    branchesLastModified?: IsoDate;
    /**
     * Länder
     */
    countries: ICountry[];
    /**
     * Länder zuletzt geändert am
     */
    countriesLastModified?: IsoDate;
    /**
     * Matrix zur Ermittlung der Legalisierungsgebühr
     */
    legalisationFeeBases: ILegalisationFeeBase[];
    /**
     * Matrix zur Ermittlung der Legalisierungsgebühr zuletzt geändert am
     */
    legalisationFeeBasesLastModified?: IsoDate;
    /**
     * Vertriebspartnercenter
     */
    salesPartnerCenters: ISalesPartnerCenter[];
    /**
     * Vertriebspartnercenter zuletzt geändert am
     */
    salesPartnerCentersLastModified?: IsoDate;
    /**
     * Kompetenztabelle
     */
    approvalCompetence: IApprovalCompetence[];
    /**
     * Kompetenztabelle zuletzt geändert am
     */
    approvalCompetenceLastModified?: IsoDate;
}

const defaulData: IMasterdataStateModel = {
    bankAccounts: [],
    branches: [],
    countries: [],
    legalisationFeeBases: [],
    salesPartnerCenters: [],
    approvalCompetence: [],
};

/**
 * Zustand für das Dashboard
 */
@State<IMasterdataStateModel>({
    name: MasterdataState.keyName,
    defaults: defaulData,
})
@Injectable()
export class MasterdataState {
    public static readonly keyName = 'masterdata';

    /**
     * Zustandsänderung beim Zurücksetzen der Stammdaten
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public reset({ setState }: StateContext<IMasterdataStateModel>): void {
        setState(defaulData);
    }

    /**
     * Zustandsänderung beim Laden der Kontoverbindungen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BankAccountsLoaded} action Aktion
     */
    @Action(BankAccountsLoaded)
    public bankAccountsLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: BankAccountsLoaded): void {
        patchState({
            bankAccounts: payload.data,
            bankAccountsLastModified: payload.lastModified,
        });
    }

    /**
     * Zustandsänderung beim Laden der Filialen
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {BranchesLoaded} action Aktion
     */
    @Action(BranchesLoaded)
    public branchesLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: BranchesLoaded): void {
        patchState({
            branches: payload.data,
            branchesLastModified: payload.lastModified,
        });
    }

    /**
     * Zustandsänderung beim Laden der Länder
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {CountriesLoaded} action Aktion
     */
    @Action(CountriesLoaded)
    public countriesLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: CountriesLoaded): void {
        patchState({
            countries: payload.data,
            countriesLastModified: payload.lastModified,
        });
    }

    /**
     * Zustandsänderung beim Editieren eine Land
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {CountryChanged} action Aktion
     */
     @Action(CountryChanged)
    public countryEdited({ getState, patchState}: StateContext<IMasterdataStateModel>, { payload }: CountryChanged ): void {
        const countries = getState().countries.map(country => {
            if (country.code === payload.code) {
                return {
                    ...country,
                    useForBirthCountry: payload.useForBirthCountry,
                    useForCiticenship: payload.useForCiticenship,
                    useForTaxResidence: payload.useForTaxResidence,
                }
            }
            return country;
        });

        patchState({countries})
    }

    /**
     * Zustandsänderung beim Laden der Matrix zur Ermittlung der Legalisierungsgebühr
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {LegalisationFeeBasesLoaded} action Aktion
     */
    @Action(LegalisationFeeBasesLoaded)
     public legalisationFeeBasesLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: LegalisationFeeBasesLoaded): void {
         patchState({
             legalisationFeeBases: payload.data,
             legalisationFeeBasesLastModified: payload.lastModified,
         });
     }

    /**
     * Zustandsänderung beim Laden der Vertriebspartnercenter
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SalesPartnerCentersLoaded} action Aktion
     */
    @Action(SalesPartnerCentersLoaded)
    public salesPartnerCentersLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: SalesPartnerCentersLoaded): void {
        patchState({
            salesPartnerCenters: payload.data,
            salesPartnerCentersLastModified: payload.lastModified,
        });
    }

    /**
     * Zustandsänderung beim Laden der Kompetenztabelle
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SalesPartnerCentersLoaded} action Aktion
     */
    @Action(ApprovalCompetenceLoaded)
    public approvalCompetenceLoaded({ patchState }: StateContext<IMasterdataStateModel>, { payload }: ApprovalCompetenceLoaded): void {
        patchState({
            approvalCompetence: payload.data,
            approvalCompetenceLastModified: payload.lastModified,
        });
    }
}
