import { INote } from '../interfaces';

/**
 * Aktion beim Laden von Notizen
 */
export class NotesLoaded {
    public static readonly type = 'notes.loaded';


    /**
     * Konstruktor
     * 
     * @param {INote[]} payload Notes
     */
    public constructor(public payload: INote[]) {}
}

/**
 * Aktion beim Hinzufügen einer Notiz
 */
export class NoteAdded {
    public static readonly type = 'note.added';

    /**
     * Konstruktor
     * 
     * @param {INote} payload Note
     */
    public constructor(public payload: INote) {}
}   

/**
 * Aktion beim Löschen einer Notiz
 */ 
export class NoteDeleted {
    public static readonly type = 'note.deleted';

    /**
     * Konstruktor
     * 
     * @param {string} payload Id
     */
    public constructor(public payload: string) {}
}

/**
 * Aktion beim Bearbeiten einer Notiz
 */
export class NoteEdited {
    public static readonly type = 'note.edited';

    /**
     * Konstruktor
     * 
     * @param {INote[]} payload Note
     */
    public constructor(public payload: INote[]) {}
}
