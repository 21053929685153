import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { WaiterService } from '@ntag-ef/waiter';
import { iif, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { AuthenticationService, LoginStateType, Logout, UserService } from '../../../../data';
import { ICanComponentDeactivate } from '../../../checks';

/**
 * Komponente mit Sperrseite (Nutzer ist gesperrt)
 */
@Component({
    selector: 'finprocess-locked',
    templateUrl: './locked.component.html',
    styleUrls: ['./locked.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockedComponent implements ICanComponentDeactivate {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {Router} router Router-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        private waiterService: WaiterService,
        private store: Store,
    ) { }

    /**
     * Prüft, ob die Komponente verlassen werden kann
     *
     * @returns {boolean} Kann die Komponente verlassen werden
     */
    public canDeactivate(): boolean {
        return this.authenticationService.loginState !== LoginStateType.Locked;
    }

    /**
     * Überprüft die Freischaltung des Nutzers
     */
    public refresh(): void {
        if (this.authenticationService.isExternalLogin) {
            this.waiterService.show().pipe(
                mergeMap(() => this.authenticationService.loginState$),
                mergeMap(loginState =>
                    iif(
                        () => loginState === LoginStateType.LoggedIn,
                        this.userService.loadUserData().pipe(
                            mergeMap(userData =>
                                iif(
                                    () => userData.confirmed,
                                    of(void 0).pipe(tap(async () => {
                                        this.authenticationService.notifySuccessfullyLoggedIn();
                                        await this.router.navigateByUrl('/');
                                    })),
                                    of(void 0),
                                ),
                            ),
                        ),
                        this.store.dispatch(new Logout()).pipe(
                            tap(() => this.router.navigateByUrl('/')),
                        ),
                    )),
                tap(() => this.waiterService.hide()),
                take(1),
            ).subscribe();

        } else {
            this.waiterService.show().pipe(
                mergeMap(() => this.authenticationService.loginState$),
                mergeMap(loginState =>
                    iif(
                        () => loginState !== LoginStateType.NotLoggedIn,
                        this.userService.loadUserData().pipe(
                            mergeMap(userData =>
                                iif(
                                    () => userData.confirmed,
                                    of(void 0).pipe(tap(async () => {
                                        this.authenticationService.notifySuccessfullyLoggedIn();
                                        await this.router.navigateByUrl('/');
                                    })),
                                    of(void 0),
                                ),
                            ),
                        ),
                        of(void 0).pipe(tap(async () => {
                            await this.router.navigateByUrl('/auth/login');
                        })),
                    ),
                ),
                tap(() => {
                    this.waiterService.hide();
                }),
                take(1),
            ).subscribe();
        }

    }
}
