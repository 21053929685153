export enum HouseholdCalculationType {
    /**
     * Risikohaushaltsrechnung
     */
    RiskHouseholdCalculation = 0,
    
    /**
     * Kundenhaushaltsrechnung
     */
    CustomerHouseholdCalculation = 1,
    
    /**
     * Pensionshaushaltsrechnung
     */
    PensionHouseholdCalculation = 2
}
