import { Directive } from '@angular/core';

// TODO SPe: Implement

/**
 * Direktive zu gesteuerten Anzeige bei benötigter Verantwortlichkeit (Organisationseinheit)
 */
@Directive({
    selector: '[finprocessOrganisationalUnitResponsibility]',
})
export class OrganisationalUnitResponsibilityDirective {
}
