import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, IUser, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IUpdateUserRequest } from '../../interfaces';
import { UserAdministrationLoaded, UserAdministrationUserConfirmed, UserEdited, UsersEdited } from '../../states';

/**
 * Service für Nutzeradministration
 */
@Injectable()
export class UserAdministrationService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Lädt eine Liste der Nutzer
     *
     * @returns {IUser[] | undefined} Liste der Nutzer
     */
    public getList(): Observable<IUser[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IUser[]>(`${this.config.getEnvironment().apiUrl}/UserAdministration/GetList`).pipe(
                    mergeMap((users: IUser[]) => this.store.dispatch(new UserAdministrationLoaded(users)).pipe(
                        map(() => users),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Setzt einen Nutzer auf bestätigt
     *
     * @param {string} userId ID des Nutzers
     * @param {string} salesPartnerCenterId (optional) ID des Vertriebspartnercenters
     * @returns {Observable<boolean | undefined>} Observable
     */
    public confirmUser(userId: string, salesPartnerCenterId?: string): Observable<boolean | undefined> {
        let params = new HttpParams();

        params = params.append('id', userId);

        if (!!salesPartnerCenterId) {
            params = params.append('salesPartnerCenterId', salesPartnerCenterId);
        }

        return this.authorizationService.checkPermissions$(Role.OrganisationalUnitAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/UserAdministration`, {}, {
                    params,
                }).pipe(mergeMap(() => this.store.dispatch(new UserAdministrationUserConfirmed(userId)).pipe(
                    map(() => true),
                ))),
                of(undefined),
            )),
        )
    }

    /**
     * Update User daten
     *
     * @param {IUpdateUserRequest} user user
     * @returns {Observable<boolean | undefined>} Observable
     */
    public update(user: IUpdateUserRequest): Observable<boolean | undefined> {
        return this.authorizationService.checkPermissions$(Role.OrganisationalUnitAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IUser>(`${this.config.getEnvironment().apiUrl}/UserAdministration/Update`, user, {
                }).pipe(
                    mergeMap(response => this.store.dispatch(new UserEdited(response))),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Update User daten
     *
     * @param {IUpdateUserRequest} users user
     * @returns {Observable<boolean | undefined>} Observable
     */
    public updateAll(users: IUpdateUserRequest[]): Observable<boolean | undefined> {
        return this.authorizationService.checkPermissions$(Role.OrganisationalUnitAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/UserAdministration/UpdateAll`, users, {
                }).pipe(
                    mergeMap(() => this.store.dispatch(new UsersEdited(users.map(usr => ({...usr, phoneNumber: usr.phoneNumber ?? undefined}))))),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Löscht ServiceCenter von User
     *
     * @param {IUser} user user
     * @param {string} serviceCenterId serviceCenterId
     * @returns {Observable<boolean | undefined>} Observable
     */
    public delteServiceCenterFromUser(user: IUser, serviceCenterId: string): Observable<boolean | undefined> {
        return this.authorizationService.checkPermissions$(Role.OrganisationalUnitAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/UserAdministration/DeleteSalesPartnerCenterFromUser`, user, {params: {salesPartnerCenterId: serviceCenterId}}).pipe(

                    mergeMap(() => this.store.dispatch(new UserEdited({...user, salesPartnerCenterId: user.salesPartnerCenterId?.filter(id => id !== serviceCenterId)}))),
                ),
                of(undefined),
            )),
        )
    }
}

