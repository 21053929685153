/* eslint-disable class-methods-use-this */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IUploadFireResult } from 'app/modules/financing/features/financing-processing/components/risk-decision/risk-decision.component';
import { AuthorizationService, Role } from 'auth';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigService, UUID } from 'shared/util';

import { IDebitorFileUploadRequest, IDeleteDebtorFile, IDeleteEsisFileRequest, IDeleteFileRequest, IDeleteHouseholdFileRequest, IDeleteJointHeadingFileRequest, IDeleteSampleCalculationRequest, IDocument, IDownloadFilesRequest, IEsisForProducts, IFile, IFileUploadRequest, IHouseholdFileUploadRequest, IJointHeadingFileUploadRequest, IProductFileUploadRequest } from '../../interfaces';
import { DebtorDocumentUploaded, DeleteDebtorFile, DocumentsLoaded, FinancingDocumentUploaded, HouseholdDocumentUploaded, JointHeadingFileUpdate } from '../../states';

/**
 * Service zum Bearbeiten einer Finanzierung
 */
@Injectable()
export class DocumentService {

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }


    /**
     * Lädt die Dokumente einer Finanzierung
     *
     * @param {UUID} id Identifier der Finanzierung
     * @returns {IDocument[] | undefined} Finanzierung, falls berechtigt
     */
    public loadDocuments(id: UUID): Observable<IDocument[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IDocument[]>(`${this.config.getEnvironment().apiUrl}/document`, {
                    params: {
                        id,
                    },
                }).pipe(
                    mergeMap(documents => this.store.dispatch(new DocumentsLoaded(documents)).pipe(
                        map(() => documents),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt eine Datei
     *
     * @param  {UUID} id ID der Datei
     * @returns {Observable} Datei
     */
    public loadFile(id: UUID): Observable<string | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/document/getfile`, {
                    params: {
                        id,
                    },
                    responseType: 'text',
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Gibt die in der Datenbank hinterlegten File Daten ohne Content und ohne Thumbnail
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable
     */
    public getFileMetadata(id: UUID): Observable<IFile | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IFile>(`${this.config.getEnvironment().apiUrl}/Document/getfilemetadata`, {
                        params: { fileId: id },
                        responseType: 'json',
                    });
                }

                return of(undefined);
            }),
        );
    }

    /**
     * Lädt die gegebenen Dateien als zip Datei herunter
     *
     * @param  {IDownloadFilesRequest} request Download Request
     * @returns {Observable} Zip mit den angeforderten Dateien
     */
    public downloadFiles(request: IDownloadFilesRequest): Observable<ArrayBuffer | null | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.put(`${this.config.getEnvironment().apiUrl}/document/downloadfilesbyidsandmarkasread`, request, {
                    responseType: 'arraybuffer',
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt ein {Rechenbeispiel/ESIS/SampleCalculationVariable/SampleCalculation/HouseholdBalanceSignature/SelfDisclosure} hoch
     *
     * @param  {IFileUploadRequest} request Upload Request
     * @returns {Observable} Antwort vom Server
     */
    public uploadFinancingMapFile(request: IFileUploadRequest): Observable<IDocument | undefined> {
        const formData = this.buildFormData(request);

        return this.authorizationService.checkPermissions$(Role.Referent | Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/document/uploadfinancingmapfile`, formData, {
                    headers: this.getFileUploadHeaders(),
                    params: {
                        type: request.type,
                    },
                }).pipe(
                    mergeMap(doc => this.store.dispatch(new FinancingDocumentUploaded(doc)).pipe(
                        map(() => doc),
                    )),
                ),
                of(undefined),
            )),
        );
    }


    /**
     * add new product package
     *
     * @param {IUploadFireResult} data data
     * @returns {Observable} observable
     */
    public addProductPackageFireDocument(data: IUploadFireResult): Observable<IDocument | undefined> {
        const formData = this.buildFormData(data);

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/Document/uploadproductpackagefile`, formData,
                        {
                            headers: this.getFileUploadHeaders(),
                            params: {
                                type: data.type,
                            },
                        },
                    ),
                    of(undefined)),
            ),
        );
    }

    /**
     * Lädt ein {HouseholdBalanceSignature} hoch
     *
     * @param  {IFileUploadRequest} request Upload Request
     * @returns {Observable} Antwort vom Server
     */
    public uploadHouseholdFile(request: IHouseholdFileUploadRequest): Observable<IDocument | undefined> {
        const formData = this.buildFormData(request);

        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/document/uploadhouseholdfile`, formData, {
                    headers: this.getFileUploadHeaders(),
                    params: {
                        type: request.type,
                    },
                }).pipe(
                    mergeMap(doc => this.store.dispatch(new HouseholdDocumentUploaded(doc)).pipe(
                        map(() => doc),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt ein {SelfDisclosure} hoch
     *
     * @param  {IFileUploadRequest} request Upload Request
     * @returns {Observable} Antwort vom Server
     */
    public uploadDebitorFile(request: IDebitorFileUploadRequest): Observable<IDocument | undefined> {
        const formData = this.buildFormData(request);

        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/document/uploaddebitorfile`, formData, {
                    headers: this.getFileUploadHeaders(),
                    params: {
                        type: request.type,
                    },
                }).pipe(
                    mergeMap(doc => this.store.dispatch(new DebtorDocumentUploaded(doc)).pipe(
                        map(() => doc),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt ein Dokument für ein Produkt hoch
     * 
     * @param {IProductFileUploadRequest} request Upload Request
     * @returns {Observable<IDocument | undefined>} Antwort vom Server mit dem Dokument
     */
    public uploadProductFile(request: IProductFileUploadRequest): Observable<IDocument | undefined> {
        const formData = this.buildFormData(request);

        return this.authorizationService.checkPermissions$(Role.Expert | Role.Referent, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/document/uploadproductfile`, formData, {
                    headers: this.getFileUploadHeaders(),
                    params: {
                        type: request.type,
                    },
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht ein Rechenbeispiel
     *
     * @param  {IFileUploadRequest} request Lösch-Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteSampleCalculation(request: IDeleteSampleCalculationRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/document/deletesamplecalculationfile`, request),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht eine Haushaltsdatei
     *
     * @param {IDeleteHouseholdFileRequest} request Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteHouseholdFile(request: IDeleteHouseholdFileRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/document/deletespecifichouseholdfile`, request),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht einen Debtor Datei
     *
     * @param {IDeleteDebtorFile} request Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteDebtorFile(request: IDeleteDebtorFile): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/document/deletedebitorfile`, request).pipe(
                    mergeMap(() => this.store.dispatch(new DeleteDebtorFile({ debtorId: request.debtorId, fileId: request.fileId }))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht eine Selbstauskunft
     *
     * @param {IDeleteFileRequest} request Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteSelfDisclosure(request: IDeleteFileRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/document/deleteselfdisclosurefile`, request),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht ein ESIS
     *
     * @param {IDeleteEsisFileRequest} request Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteEsis(request: IDeleteEsisFileRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert | Role.Referent, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.delete(`${this.config.getEnvironment().apiUrl}/document/deleteCustomerFinalisationFile`, {
                    body: request,
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt den Bearbeitungsverlauf als PDF herunter
     *
     * @param  {string} id ID der Finanzierung
     * @returns {Observable} Antwort vom Server
     */
    public getEditHistoryExport(id: string): Observable<ArrayBuffer | null | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, true).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/document/getedithistoryexport`, {
                    params: {
                        id,
                    },
                    responseType: 'arraybuffer',
                }),
                of(undefined),
            )),
        );
    }

    /**
     * upload JointHeading File
     *
     * @param {IFileUploadRequest} request request
     * @returns {Observable} observable
     */
    public uploadJointHeadingFile(request: IJointHeadingFileUploadRequest): Observable<IDocument | undefined> {
        const formData = this.buildFormData(request);

        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/document/uploadjointheadingfile`, formData, {
                    headers: this.getFileUploadHeaders(),
                    params: {
                        type: request.type,
                    },
                }).pipe(
                    mergeMap(result => this.store.dispatch(new JointHeadingFileUpdate(result, request.jointHeadingId)).pipe(map(() => result))),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Löscht einen JointHeading Datei
     *
     * @param {IDeleteDebtorFile} request Request
     * @returns {Observable} Antwort vom Server
     */
    public deleteJointHeadingFile(request: IDeleteJointHeadingFileRequest): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/document/deletejointheadingfile`, request),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt die ESIS und Kreditverträge einer Finanzierung für die ESIS Seite
     * 
     * @param {UUID} financingContainerId ID des Finprocess Containers
     * @returns {Observable<IEsisForProducts | undefined>} Sammelobjekt mit allen Produkten und deren Dokumenten
     */
    public getExistingEsisDocumentsForProducts(financingContainerId: UUID): Observable<IEsisForProducts | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, true).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IEsisForProducts>(`${this.config.getEnvironment().apiUrl}/document/getexistingesisdocumentsforproducts`, {
                    params: {
                        containerId: financingContainerId,
                    },
                }),
                of(undefined),
            )),
        )
    }
    /**
     * Gibt HttpHeader für File Uploads zurück
     *
     * @returns {HttpHeaders} HttpHeaders
     */
    private getFileUploadHeaders(): HttpHeaders {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');
        return headers;
    }


    /**
     * Baut FormData aus einem Request
     *
     * @param {object} request Request
     * @returns {FormData} FormData
     */
    private buildFormData<T extends { [key: string]: string | number | File | undefined }>(request: T): FormData {
        const formData = new FormData();

        for (const key of Object.keys(request)) {
            const value = request[key];

            if (value !== undefined) {
                if (value instanceof File) {
                    formData.append(key, value, value.name);
                } else {
                    formData.append(key, typeof value === 'number' ? value.toString() : value);
                }
            }
        }

        return formData;
    }
}
