import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, computed, input } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EnumTranslationPipe, RealEstateType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { ICadastralCommunity } from 'app/modules/administration/data/interfaces';
import { AssetService, AssetState, EntityClassType, FinancingService, IAutocomleteData, IRealEstateDetailDialogData, ISaveFiledData, SourceCollateral, ValueStorageType } from 'app/modules/financing/data';
import { IAssetRealEstate } from 'app/modules/financing/data/interfaces';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, ReplaySubject, Subject, debounceTime, distinctUntilChanged, filter, forkJoin, map, switchMap, takeUntil } from 'rxjs';

import { RealEstateDetailComponent } from '..';
import { AppraisalType, PropertyType, TypeOfShare } from '../../../colt/enums';
import { ControlTableSearchService } from '../../../colt/services';
import { AssetCommonComponent } from '../asset-common/asset-common';


/**
 * Asset Real Estate
 */
@Component({
    selector: 'finprocess-asset-real-estate',
    templateUrl: './asset-real-estate.component.html',
    styleUrls: ['./asset-real-estate.component.scss'],
})
export class AssetRealEstateComponent extends AssetCommonComponent implements OnInit, OnDestroy {

    public assetRealEstate = input.required<IAssetRealEstate>();

    public isActive = computed(() => this.assetRealEstate().isActive ?? false);

    public objectTypeSelectItems: ISelectItem<number>[] = [];

    public typeOfShareSelectItems: ISelectItem<number>[] = [];

    public propertyTypeSelectItems: ISelectItem<number | null>[] = [];

    public valuationTypeSelectItems: ISelectItem<number>[] = [];

    public assetExpand = false;

    public search = new ReplaySubject<string>(1);

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Validators für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /**
     * Type of Share für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public TypeOfShare = TypeOfShare;

    /**
     * SourceCollateral für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral;

    /**
     * AutoComplete Data
     */
    public autocomleteData$!: Observable<IAutocomleteData<ICadastralCommunity>[] | undefined>

    /**
     * Destroy-Subject
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Requirements failed Map
     */
    public validRealEstatesMap: { [id: string]: boolean } = {};

    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     * @param {MatDialog} dialog MatDialog-Injektor
     * @param {ControlTableSearchService} controlTableSearchService ControlTableService-Injektor
     * @param {NotificationService} notificationService NotificationService
     * @param {TranslateService} translate TranslateService
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            enumTranslate: EnumTranslationPipe,
            assetService: AssetService,
            store: Store,
        public financingService: FinancingService,
        private dialog: MatDialog,
        private controlTableSearchService: ControlTableSearchService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        this.objectTypeSelectItems = HelperService.getSortedSelectItems(RealEstateType, value => this.enumTranslate.transform(value, 'RealEstateType') as string, [], true);

        this.typeOfShareSelectItems = HelperService.getSortedSelectItems(TypeOfShare, value => this.enumTranslate.transform(value, 'TypeOfShare') as string, [TypeOfShare.NewProperty], true);

        this.propertyTypeSelectItems = HelperService.getSortedSelectItems(PropertyType, value => this.enumTranslate.transform(value, 'PropertyType') as string, [PropertyType.Property], true);
        this.propertyTypeSelectItems.push({ displayName: 'Keine Auswahl', value: null });

        this.valuationTypeSelectItems = HelperService.getSortedSelectItems(AppraisalType, value => this.enumTranslate.transform(value, 'AppraisalType') as string, [], true);

        this.autocomleteData$ = this.search.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(),
            debounceTime(300),
            filter(value => !!value && typeof value === 'string' && value.trim() !== '' && value.length >= 3),
            switchMap(searchString => this.controlTableSearchService.searchCastralCommunityTableData(searchString).pipe(
                map(data => data?.map(item => ({ value: item, displayValue: `${item.landRegisterNumber}, ${item.nameOfLandRegister}, ${item.districtCourt}` }))),
            )),
        )
        this.validateRealEstates();
    }

    /**
     * Validate Real Estates
     */
    public validateRealEstates() {
        this.store.select(AssetState.validateAssetRealEstates)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(invalidRealEstates => {
                this.validRealEstatesMap = invalidRealEstates.reduce((acc, realEstates) => {
                    acc[realEstates.id] = realEstates.valid;

                    if (!realEstates.valid && realEstates.isActive) {
                        this.setAssetActive(realEstates.id, false).subscribe(() => {
                            this.notificationService.toast(this.translate.instant('asset.assetCommon.assetDeactivated'));
                        });
                    }

                    return acc;
                }, {} as { [id: string]: boolean });
            });
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * onSearchChanged
     * 
     * @param {string} searchString searchString
     */
    public onSearchChanged(searchString: string) {
        this.search.next(searchString);
    }

    /**
     * openRealestateDetail
     * 
     * @param { MouseEvent } event MouseEvent
     * @param { string } realestateId realestateId 
     */
    public openRealestateDetail(event: MouseEvent, realestateId: string) {
        event.stopImmediatePropagation();
        const dialogData: IRealEstateDetailDialogData = { realestateId: realestateId, isNotAssetData: false, onlyOne: true };
        //da weiter zentriert wurde, height findet sich auch in global styles und muss dort angepasst werden
        this.dialog.open(RealEstateDetailComponent, { minWidth: '60vw', minHeight: '75vh', data: dialogData });
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {any} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: any) {
        return array.find(e => e.value === id);
    }

    /**
     *  Save the overwrite
     *
     * @param {ICadastralCommunity} value The cadastral community value
     * @param {ISaveFiledData} data  The save field data
     */
    public saveLandRegisterNumber(value?: unknown, data?: ISaveFiledData) {
        if (value === undefined || data?.entityId === undefined) {
            return
        }
        forkJoin([
            this.financingService.saveInternalField({
                fieldName: 'cadastralNumber',
                valueStorageType: ValueStorageType.String,
                value: (value as ICadastralCommunity).landRegisterNumber,
                entityClassType: EntityClassType.AssetRealEstate,
                entityId: data.entityId,
            }),
            this.financingService.saveInternalField({
                fieldName: 'cadastralName',
                valueStorageType: ValueStorageType.String,
                value: (value as ICadastralCommunity).nameOfLandRegister,
                entityClassType: EntityClassType.AssetRealEstate,
                entityId: data.entityId,
            }),
            this.financingService.saveInternalField({
                fieldName: 'districtCourt',
                valueStorageType: ValueStorageType.String,
                value: (value as ICadastralCommunity).districtCourt,
                entityClassType: EntityClassType.AssetRealEstate,
                entityId: data.entityId,
            }),
        ]).subscribe();
    }

    /**
     * displayLandRegisterNumber
     * 
     * @param {ICadastralCommunity} value The cadastral community value
     * @returns {string | undefined} landRegisterNumber
     */
    public displayLandRegisterNumber(value?: IAutocomleteData<unknown>) {
        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            return (value as unknown as ICadastralCommunity)?.landRegisterNumber ?? '';
        }

        if (value !== null && typeof value === 'string') {
            return value;

        }

        return value?.toString() ?? '';
    }
}
