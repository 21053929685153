<ng-template #decimalInputTemplate let-required="required" let-allowNegativeValues="allowNegativeValues" let-label="label" let-fieldName="fieldName" let-editable="editable" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-decimal-input
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetInsurance"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="editable ? fieldReadonly$ : alwaysReadonly$"
            [entity]="assetInsurance"
            [transparent]="true"
            subscriptSizing="fixed"
            [allowNegativeValues]="allowNegativeValues ?? false"
            [validators]="required ? Validators.required : null">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-decimal-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? (value | number: '1.2-2' : locale) : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #textboxInputTemplate let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-textbox-input
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetInsurance"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetInsurance"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ ('asset.assetInsurance.'+fieldName) | translate }}</span>
        </finprocess-textbox-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ ('asset.assetInsurance.'+fieldName) | translate }}</span>
            <span value>{{ (value !== null && value !== undefined && value !== '') ? value : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #datepickerInputTemplate let-required="required" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-datepicker-input
            class="date-input"
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetInsurance"
            [entity]="assetInsurance"
            [readonly]="fieldReadonly$"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [transparent]="true"
            [validators]="Validators.required"
            subscriptSizing="fixed">
            <span label class="input-label">{{ ('asset.assetInsurance.'+fieldName) | translate }}</span>
        </finprocess-datepicker-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ ('asset.assetInsurance.'+fieldName) | translate }}</span>
            <span value>{{ value ? (value | date : 'dd.MM.yyyy') : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ucba-heading *ngIf="!!assetInsurance" [expandable]="true" [ngClass]="{ 'requirements-failed': validInsuranceMap[assetInsurance.id] === false }">
    <div ucba-heading-title>
        <b>{{ 'asset.assetInsurance.header' | translate }}</b>
    </div>
    <mat-checkbox
        ucba-heading-actions
        #checkboxRef
        class="heading-checkbox"
        color="primary"
        [checked]="assetInsurance.isActive"
        (click)="onCheckboxChange(assetInsurance.id, !assetInsurance.isActive)"
        [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true"
        [disabled]="!validInsuranceMap[assetInsurance.id]">
    </mat-checkbox>
    <div ucba-heading-body-visible>
        <ucba-tile-layout>
            <!-- Versicherungsart  enum -->
            <ng-template ucba-tile>
                @if((fieldReadonly$ | async) === false) {
                    <finprocess-select-input
                        [items]="mortgageTypeSelectItems"
                        [overwrite]="false"
                        [entityClassType]="entityClassType.AssetInsurance"
                        [hideHorizontalRule]="true"
                        fieldName="insuranceType"
                        [flexDirection]="inputFlexDirection"
                        [readonly]="fieldReadonly$"
                        [entity]="assetInsurance"
                        [showTooltip]="true"
                        [transparent]="true"
                        subscriptSizing="fixed"
                        [validators]="Validators.required">
                        <span label class="input-label">{{ 'asset.assetInsurance.insuranceType' | translate }}</span>
                    </finprocess-select-input>
                } @else {
                    <finprocess-label-value>
                        <span label>{{ 'asset.assetInsurance.insuranceType' | translate }}</span>
                        <span value>{{ (assetInsurance.insuranceType !== null && assetInsurance.insuranceType !== undefined) ? findArrayElementWithId(mortgageTypeSelectItems, assetInsurance.insuranceType)?.displayName : '-' }}</span>
                    </finprocess-label-value>
                }
            </ng-template>
    
            <!-- Versicherungssumme -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetInsurance.insuranceSum', fieldName: 'insuranceSum', editable: true, required: true, value: assetInsurance.insuranceSum }"></ng-container>
            </ng-template>
    
            <!-- Ablebenssumme -->
            <ng-template *ngIf="assetInsurance.insuranceType !== CollateralType.BA130 && assetInsurance.insuranceType !== CollateralType.BA135" ucba-tile>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetInsurance.deathSum', fieldName: 'deathSum', editable: true, required: true, value: assetInsurance.deathSum }"></ng-container>
            </ng-template>
    
            <!-- Versicherungsgesellschaft -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { fieldName: 'insuranceCompanyName', value: assetInsurance.insuranceCompanyName }"></ng-container>
            </ng-template>
    
            <!-- materieller Wert gesamt -->
            <ng-template ucba-tile>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetInsurance.materialValueTotal', fieldName: 'materialValueTotal', editable: false, value: assetInsurance.materialValueTotal }"></ng-container>
            </ng-template>
        </ucba-tile-layout>
    </div>
    <div ucba-heading-body>
        <!-- Restliche Daten -->
        <ucba-heading [type]="'medium'">
            <div ucba-heading-title>{{ 'asset.assetCommon.otherInfos' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <!-- Währung Rückkaufwert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { fieldName: 'redemptionValueCurrency', value: assetInsurance.redemptionValueCurrency }"></ng-container>
                    </ng-template>
    
                    <!-- Rückkaufswert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetInsurance.currentCollateralValue', fieldName: 'currentCollateralValue', editable: true, value: assetInsurance.currentCollateralValue, required: true }"></ng-container>
                    </ng-template>
    
                    <!-- Rückkaufwert per -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="datepickerInputTemplate; context: { fieldName: 'currentCollateralValuePer', value: assetInsurance.currentCollateralValuePer, required: true }"></ng-container>
                    </ng-template>
    
                    <!-- Polizzen-/Antragsnr -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="textboxInputTemplate; context: { fieldName: 'applicationNo', value: assetInsurance.applicationNo }"></ng-container>
                    </ng-template>
    
                    <!-- bereits gen. mat. Wert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.materialValueAlreadyInUse', fieldName: 'materialValueAlreadyInUse', editable: true, allowNegativeValues: true, value: assetInsurance.materialValueAlreadyInUse }"></ng-container>
                    </ng-template>
    
                    <!-- freier materieller Wert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.freeMaterialValue', fieldName: 'freeMaterialValue', editable: true, value: assetInsurance.freeMaterialValue }"></ng-container>
                    </ng-template>
    
                    <!-- Beginn der Versicherung -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="datepickerInputTemplate; context: { fieldName: 'insuranceStart', value: assetInsurance.insuranceStart, required: true }"></ng-container>
                    </ng-template>
    
                    <!-- Ende der Versicherung -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="datepickerInputTemplate; context: { fieldName: 'insuranceEnd', value: assetInsurance.insuranceEnd, required: true }"></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>

        <finprocess-asset-provider
            [assetProviders]="assetInsurance.assetProviders"
            [assetId]="assetInsurance.id"
            [entityClassType]="entityClassType.AssetInsurance">
        </finprocess-asset-provider>
        <finprocess-asset-agp [assetsAgps]="assetInsurance.assetsAgp"></finprocess-asset-agp>
    </div>
</ucba-heading>
