/**
 * Status eines Finanzplans
 */
export enum FinancingPlanStatus {
    /**
     * In Bearbeitung
     */
    Editing = 100,

    /**
     * Scoring Error
     */
    ScoringError = 150,
        
    /**
     * Scoring fehlgeschlagen
     */
    ScoringFailed = 200,

    /**
     * Scoring erfolgreich
     */
    ScoringSuccesful = 300,
}
