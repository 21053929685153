import { WorkflowType } from '../../enums';
import { OptionalBoolenType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const DURATIONS_FINANCING_REQUEST: IMidtermModel = {

    midtermName: 'durationsFinancingRequest',

    /**
     * Laufzeit Konsum
     * verwende derzeit max. 120 Monate statt max. 84 Monate bei neuer Finanzierung pacht: Kleingarten, Miete/Renovierung, Zwifi ohne Sonderfinanzierungsart wenn LTV-rel. "nein", Konsum
     * konsum_lange_laufzeit
     */
    konsumLangeLaufzeit: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * Laufzeit Konsum
     * wenn KONSUM_LANGE_LAUFZEIT "ja", dann ggfs. diese Frage beantworten
     * konsum_lange_laufzeit_ausbildungsgrad
     */
    konsumLangeLaufzeitAusbildungsgrad: { type: WorkflowType.Enum, enumObject: OptionalBoolenType, enumLabel: 'OptionalBoolenType' },

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) B&W Mortgage Linie 1
     * glfz_abw_bw1
     */
    glfzAbwBw1: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) B&W Mortgage Linie 2
     * glfz_abw_bw2
     */
    glfzAbwBw2: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Kleingarten Pacht
     * glfz_abw_kg
     */
    glfzAbwKg: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Miete, Renovierung
     * glfz_abw_ren
     */
    glfzAbwRen: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Genossenschaft
     * glfz_abw_gen
     */
    glfzAbwGen: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Ergänzungsdarlehen
     * glfz_abw_erg
     */
    glfzAbwErg: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Nachfinanzierungsergänzungsdarlehen
     * glfz_abw_nf
     */
    glfzAbwNf: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Zwischenfinanzierung mit fremder Anschlussfinanzierung (B&W-Konto)
     * glfz_abw_zf_maf
     */
    glfzAbwZfMaf: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Zwischenfinanzierung ohne Anschlussfinanzierung (B&W-Konto)
     * glfz_abw_zf_oaf
     */
    glfzAbwZfOaf: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Zwischenfinanzierung LS-Verkauf gem. KIMV-Novelle
     * glfz_abw_zf_lsverkauf
     */
    glfzAbwZfLsVerkauf: { type: WorkflowType.Number},

    /**
     * abweichende Gesamtlaufzeit (ohne ASD-Periode) Konsum
     * glfz_abw
     */
    glfzAbw: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum B&W Mortgage Linie 1
     * mort_laufzeit_tilgungsfrei
     */
    mortLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum B&W Mortgage Linie 2
     * mort_two_laufzeit_tilgungsfrei
     */
    mortTwoLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum Kleingarten Pacht
     * nonmort_kg_laufzeit_tilgungsfrei
     */
    nonmortKgLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier miete: Zeitraum, Renovierung
     * nonmort_mr_laufzeit_tilgungsfrei
     */
    nonmortMrLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum Genossenschaft
     * nonmort_gen_laufzeit_tilgungsfrei
     */
    nonmortGenLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum Ergänzungsdarlehen
     * spill_over_ergaenzung_laufzeit_tilgungsfrei
     */
    spillOverErgaenzungLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum Nachfinanzierungsergänzungsdarlehen
     * spill_over_nachfinanzierung_laufzeit_tilgungsfrei
     */
    spillOverNachfinanzierungLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * tilgungsfreier Zeitraum Konsum
     * konsum_laufzeit_tilgungsfrei
     */
    konsumLaufzeitTilgungsfrei: { type: WorkflowType.Number},

    /**
     * ASD-Periode B&W Mortgage Linie 1
     * mort_laufzeit_asd
     */
    mortLaufzeitAsd: { type: WorkflowType.Number},

    /**
     * ASD-Periode B&W Mortgage Linie 2
     * mort_two_laufzeit_asd
     */
    mortTwoLaufzeitAsd: { type: WorkflowType.Number},

    /**
     * ASD-Periode Kleingarten Pacht
     * nonmort_kg_laufzeit_asd
     */
    nonmortKgLaufzeitAsd: { type: WorkflowType.Number},

    /**
     * ASD-Periode Ergänzungsdarlehen
     * spill_over_ergaenzung_laufzeit_asd
     */
    spillOverErgaenzungLaufzeitAsd: { type: WorkflowType.Number},

    /**
     * ASD-Periode Nachfinanzierungsergänzungsdarlehen
     * spill_over_nachfinanzierung_laufzeit_asd
     */
    spillOverNachfinanzierungLaufzeitAsd: { type: WorkflowType.Number},

    /**
     * vereinbarte Laufzeit fremde Anschlussfinanzierung
     * foerderung_laufzeit_wish_son
     */
    foerderungLaufzeitWishSon: { type: WorkflowType.Number},

    /**
     * vereinbarte Laufzeit Sonderfinanzierungsarten
     * eigenmittel_laufzeit_wish_son
     */
    eigenmittelLaufzeitWishSon: { type: WorkflowType.Number},
}
