import { Component } from '@angular/core';
import { Role } from 'app/modules/auth/data';

/**
 * Komponente für den Administrationsbereich mit Navigation
 */
@Component({
    selector: 'finprocess-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    public isExpanded = false;

    /**
     * ControlTable Authorization
     */
    public controlTableAuthorization = { 
        roles: Role.ControlTableDefaultAdministrator | Role.ControlTableUserCompetenceAdministrator,
        needOnlyOne: true,
    }
}
