import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums';
import { FinancingPremissions } from '@ntag-ef/finprocess-enums/finprocess';
import { UserService } from 'app/modules/auth/data';
import { IBranch } from 'app/modules/masterdata/data';
import { sort } from 'fast-sort';

import { HelperService } from './../../../../../shared/util/services/helper/helper.service';
import { IEditBranchDialogResult } from './../../../../data/interfaces';

export interface ISelectedItem {
    value: number;
    displayName: string;
}

/**
 * Dialog zum Bearbeiten und Erstellen von Filialen
 */
@Component({
    selector: 'finprocess-edit-branch-dialog',
    templateUrl: './edit-branch-dialog.component.html',
    styleUrls: ['./edit-branch-dialog.component.scss'],
})
export class EditBranchDialogComponent implements OnInit {

    public form: FormGroup;

    public organisationalResponsibilityType: ISelectedItem[] = [];

    private filteredEnumOrganisationalUnitResponsibility = OrganisationalUnitResponsibilityType;
    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef MatDialogRef
     * @param {IBranch} branch (optional) übergebene Filiale
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {UserService} userService UserService-Injektor
     */
    public constructor(
        private dialogRef: MatDialogRef<EditBranchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public branch: IBranch | undefined,
        private fb: FormBuilder,
        private userService: UserService,
    ) {
        const userFinancingPermissions = this.userService.user?.financingPermissions;
        const branchPermissions = EditBranchDialogComponent.getBranchPermissions(this.branch?.responsibility)
        const disable = !!this.branch && !!userFinancingPermissions && !HelperService.hasBit(userFinancingPermissions, branchPermissions );

        this.form = this.fb.group({
            costCentre: [branch?.costCentre],
            branchCode: [branch?.branchCode],
            name: [branch?.name, Validators.required],
            responsibility: [branch?.responsibility, Validators.required],
            status: [!branch?.disabled],
        });

        if (disable) {
            this.form.get('costCentre')?.disable();
            this.form.get('branchCode')?.disable();
            this.form.get('name')?.disable();
            this.form.get('responsibility')?.disable();
        }

        this.filterEnumOrganisationalUnitResponsibility(this.userService.user?.financingPermissions)
    }


    /**
     * Umwandelt Brnach Responsibility um FinancingPremissions
     *
     * @param {OrganisationalUnitResponsibilityType} organisationalUnitResponsibility organisationalUnitResponsibility
     * @returns {FinancingPremissions} FinancingPremissions
     */
    private static getBranchPermissions(organisationalUnitResponsibility?: OrganisationalUnitResponsibilityType): FinancingPremissions {
        if (!!organisationalUnitResponsibility) {

            if (organisationalUnitResponsibility === OrganisationalUnitResponsibilityType.PrivateCustomers) {
                return FinancingPremissions.PrivateCustomerSkill
            }

            if (organisationalUnitResponsibility === OrganisationalUnitResponsibilityType.SelfEmployed) {
                return FinancingPremissions.SmallBusinessSkill
            }
        }

        return FinancingPremissions.None
    }

    /**
     *  Filtriert OrganisationalUnitResponsibility nach User FinancingPremissions
     *  Entfernt Enumwerte (OrganisationalUnitResponsibility), den den User nicht hat
     *
     * @param {FinancingPremissions} userFinancingPremissions User FinancingPremissions
     */
    private filterEnumOrganisationalUnitResponsibility(userFinancingPremissions?: FinancingPremissions ): void {
        if (!!userFinancingPremissions) {

            if (!HelperService.hasBit(userFinancingPremissions, FinancingPremissions.PrivateCustomerSkill)) {
                this.filteredEnumOrganisationalUnitResponsibility = HelperService.removeValuesFromEnum(OrganisationalUnitResponsibilityType, [OrganisationalUnitResponsibilityType.PrivateCustomers]);
            }

            if (!HelperService.hasBit(userFinancingPremissions, FinancingPremissions.SmallBusinessSkill)) {
                this.filteredEnumOrganisationalUnitResponsibility = HelperService.removeValuesFromEnum(OrganisationalUnitResponsibilityType, [OrganisationalUnitResponsibilityType.SelfEmployed]);
            }
        }
    }
    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.organisationalResponsibilityType = sort((HelperService.getEnumArray(this.filteredEnumOrganisationalUnitResponsibility) as number[]).map(premission => ({
            value: premission,
            displayName: OrganisationalUnitResponsibilityType.translate(premission as OrganisationalUnitResponsibilityType) ?? '',
        })).filter(x => x.value !== 0)).asc(premission => premission.displayName);
    }
    /**
     * Schließt den Dialog ohne Aktion
     */
    public cancel(): void {
        this.dialogRef.close();
    }

    /**
     * Schließt dem Dialog mit Speichern
     */
    public save(): void {
        const result: IEditBranchDialogResult = {};

        if (this.branch === undefined) {
            result.addRequest = {
                active: this.form.get('status')?.value ?? false,
                name: this.form.get('name')?.value ?? '',
                responsibility: this.form.get('responsibility')?.value ?? '',
                costCentre: this.form.get('costCentre')?.value,
                branchCode: this.form.get('branchCode')?.value,
            };
        } else {
            const costCentre = this.form.get('costCentre')?.value;
            const branchCode = this.form.get('branchCode')?.value;
            const name = this.form.get('name')?.value;
            const status = this.form.get('status')?.value;
            const responsibility = this.form.get('responsibility')?.value;

            if (status !== undefined && !status !== this.branch.disabled) {
                result.statusRequest = {
                    id: this.branch.id,
                    active: this.form.get('status')?.value ?? false,
                };
            }

            if (!!name && !!responsibility &&
                (
                    name !== this.branch.name ||
                    responsibility !== this.branch.responsibility ||
                    costCentre !== this.branch.costCentre ||
                    branchCode !== this.branch.branchCode
                )
            ) {
                result.editRequest = {
                    id: this.branch.id,
                    name,
                    responsibility,
                    costCentre,
                    branchCode,
                };
            }

        }

        this.dialogRef.close(result);
    }
}
