<span fxLayoutAlign="start start" fxLayout="column" class="asset-container">
  <div fxLayout="row" class="ml-2 mb-2" fxLayoutAlign="start end" >
    <finprocess-sub-header [label]="'Sicherheitengeber'"></finprocess-sub-header>
    <button [disabled]="(fieldReadonly$ | async)" class="ml-2 add-btn" mat-mini-fab (click)="addAssetProvider()" matTooltip="Sicherheitengeber hinzufügen">
      <mat-icon style="font-size: 20px; color: grey">group_add</mat-icon>
    </button>
  </div>
  
  <div class="ml-2" *ngIf="!assetProviders || assetProviders.length === 0 ">{{'asset.assetProvider.noAssetProviders' | translate}}</div>

  <span *ngFor="let provider of assetProviders; let i = index;" class="mt-2" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="start center" style="margin-left: 20px; width: 100%">

    <ng-container *ngIf="i !== 0">
      <mat-divider class="hr1 mb-2"></mat-divider>
    </ng-container>

    <!-- Sicherheitengeber -->
    <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType"
      fieldName="ndg" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="provider">
      <span label class="input-label">{{'asset.assetProvider.ndg' | translate}}</span>
    </finprocess-textbox-input>

    <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType"
      fieldName="lastName" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="provider">
      <span label class="input-label">{{'asset.assetProvider.lastName' | translate}}</span>
    </finprocess-textbox-input>

    <finprocess-textbox-input [overwrite]="false" [entityClassType]="entityClassType"
      fieldName="firstName" [flexDirection]="inputFlexDirection" [hideHorizontalRule]="true"
      [readonly]="alwaysReadonly$"
      [entity]="provider">
      <span label class="input-label">{{'asset.assetProvider.firstName' | translate}}</span>
    </finprocess-textbox-input>

    <button [disabled]="(fieldReadonly$ | async)" (click)="deleteAssetProvider(provider.id)" class="delete-btn" mat-icon-button style="margin-left: 15px;">
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</span>