import { EnumSettings } from './../enum.settings';

/**
 * Objektart
 */
export enum ObjectPurposeType {
    /**
     * Zu finanzierendes Objekt
     */
    Finance = 0,

    /**
     * In Besitz befindliches Objekt
     */
    Owned = 1,

    /**
     * Zu verkaufendes Objekt
     */
    ForSale = 2,
}

export namespace ObjectPurposeType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {ObjectPurposeType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: ObjectPurposeType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'ObjectPurposeType', ObjectPurposeType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('ObjectPurposeType', plocale);
}
