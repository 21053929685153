import { EnumSettings } from './../enum.settings';

/**
 * Bereitschaft Konto zur BA zu verlegen
 */
export enum BankAustriaStatus {
    /**
     * Ja
     */
    Interested = 0,

    /**
     * Nein
     */
    NotInterested = 1,
}

export namespace BankAustriaStatus {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {BankAustriaStatus | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: BankAustriaStatus | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'BankAustriaStatus', BankAustriaStatus, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('BankAustriaStatus', plocale);
}
