import { IUser } from 'app/modules/auth/data';
import { IsoDate } from 'shared/util';


/**
 * Aktion bei erfolgreichem Laden der bestehenden Nutzerdaten
 */
export class LoadBalancingDataLoaded {
    public static readonly type = 'loadBalancing.usersLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Bestehende Nutzerdaten
     * @param {IUser[]} payload.users Liste bestehender Nutzerdaten
     * @param {IsoDate} payload.lastModified Letzte Änderung der bestehenden Nutzerdaten
     */
    public constructor(public payload: {
        users: IUser[];
        lastModified?: IsoDate;
    }) { }
}
