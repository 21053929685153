/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { sort } from 'fast-sort';

/**
 * Mit Sarah besprechen, ob dieser import IDocument zu einem Shared macht
 */
import { IDocument } from '../../../../../modules/financing/data/interfaces/index';
import {
    LoadedJobStatus,
    MapRevoked,
    MapTemporaryRevokedAccepted,
    SmartDocClosed,
    SmartDocDocumentsLoaded,
    SmartDocDocumentsLoadedReset,
    SmartDocDocumentsUploaded,
    SmartDocJobThumbnailContentLoaded,
    SmartDocJobThumbnailsLoaded,
    SmartDocOpened,
    SmartDocSortingStarted,
    SmartDocSplittingStarted,
    SmartDocStatusLoaded,
    SmartDocStoreRequestCompleted,
    SmartDocThumbnailSortResultStored,
    SmartDocThumbnailsLoadedReset,
    SmartDocThumbnailsSortResultReset,
    SmartDocUploadedFileIdsLoaded,
    SmartDocUploadedFileIdsReset,
} from '../../actions/index';
import { LoadedDropFolders } from '../../actions/smartdoc/loaded-drop-folders.action';
import { SmartDocJobStatusChanged } from '../../actions/smartdoc/smartdoc-jobstatus-changed-action';
import { ISmartDocJobThumbnailViewModel, ISmartDocStatusViewModel } from '../../interfaces/smartdoc';
import { IDropFolderViewModel } from '../../interfaces/smartdoc/drop-folder-view.model';
import { AAGUID } from '../../types/aaguid';

// import { BaseState } from './base.state';

/**
 * Interface für Anwendungszustand im Bereich SmartDoc
 */
export interface ISmartDocStateModel /* extends BaseState */ {
    /**
     * Eindeutiger Schlüssel zum aktuell geöffneten Fall
     */
    openedId?: AAGUID;

    /**
     * Aktueller Status des Prozesses
     */
    status?: ISmartDocStatusViewModel;

    /**
     * Dokumente, die bearbeiten werden
     */
    documents: IDocument[];

    /**
     * Sind Dokumente geladen worden
     */
    documentsLoaded: boolean;

    /**
     * Ids von Dateien, die bereits an SmartDoc gesendet wurden
     */
    smartDocUploadedIds: AAGUID[];

    /**
     * Sind Ids von Dateien, die bereits an SmartDoc gesendet wurden, geladen
     */
    smartDocUploadedIdsLoaded: boolean;

    /**
     * Vorschaubilder, die einer Dokumentenablage zugeordnet werden
     */
    smartDocThumbnails: ISmartDocJobThumbnailViewModel[];

    /**
     * beinhaltet die Contents der einzelnen Vorschaubilder
     */
    smartDocThumbnailContents: Array<{
        /**
         * Eindeutiger Schlüssel des Thumbnails
         */
        id: AAGUID;
        /**
         * Content des Thumbnails
         */
        content: string;
    }>;

    /**
     * Sind Vorschaubilder, die einer Dokumentenablage zugeordnet werden, geladen
     */
    smartDocThumbnailsLoaded: boolean;

    /**
     * Handelt sich um einen SmartDoc-Fall
     */
    isSmartDocMap: boolean;

    /**
     * Gibt an, wie viele Requests gerade ausgeführt werden
     */
    requestCounter: number;

    /**
     * Lesemodus
     */
    isReadonly: boolean;

    /**
     * Fetched SmartDoc-DropFolders
     */
    dropFolders: IDropFolderViewModel[];
}

const defaultData: ISmartDocStateModel = {
    // documents: [],
    // documentsLoaded: false,
    // smartDocUploadedIds: [],
    // smartDocUploadedIdsLoaded: false,
    // smartDocThumbnails: [],
    // smartDocThumbnailContents: [],
    // smartDocThumbnailsLoaded: false,
    // isSmartDocMap: false,
    // requestCounter: 0,
    // isReadonly: false,
    // dropFolders: [],
} as ISmartDocStateModel;

/**
 * Klasse zur Logikimplementierung des Anwendungszustands
 */
@State<ISmartDocStateModel>({
    name: SmartDocState.typeName,
    defaults: defaultData,
})
@Injectable()
export class SmartDocState {
    public static readonly typeName: string = 'smartdoc';

    /**
     * Logik zum Öffnen eines SmartDoc-Falls
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocOpened)
    public opened({ getState, setState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocOpened): void {
        const state = getState();
        setState({
            ...state,
            openedId: payload.id,
            isSmartDocMap: payload.isSmartDocMap,
            isReadonly: payload.isReadonly,
            documents: payload.documents !== undefined ? this.sortDocuments(payload.documents) : state.documents,
            documentsLoaded: payload.documents !== undefined && payload.documents.length > 0,
        })
    }

    /**
     * Logik zum Laden des JobStatus
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(LoadedJobStatus)
    public loadedJobStatus({ getState, patchState, dispatch }: StateContext<ISmartDocStateModel>, { payload }: LoadedJobStatus): void {
        const state = getState();
        // eslint-disable-next-line keyword-spacing
        if(state.openedId === payload.id) {
            patchState({ status: payload });
            if (!state.isSmartDocMap) {
                dispatch(new SmartDocStatusLoaded({id: payload.id, status: payload}));
            }
        }
    }

    /**
     * Logik nach dem Starten eines SmartDoc-Schritts
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocSplittingStarted)
    @Action(SmartDocSortingStarted)
    public jobStatusChanged({ getState, setState, dispatch }: StateContext<ISmartDocStateModel>, { payload }: SmartDocSplittingStarted | SmartDocSortingStarted): void {
        const state = getState();
        if (state.openedId === payload.id) {
            setState({
                ...state,
                status: {
                    ...state.status as ISmartDocStatusViewModel,
                    jobStatus: payload.jobStatus,
                },
            });
            if (!state.isSmartDocMap) {
                dispatch(new SmartDocStatusLoaded({id: payload.id, status: {
                    ...state.status as ISmartDocStatusViewModel,
                    jobStatus: payload.jobStatus,
                }}));
            }
        }
    }

    /**
     * Logik zum Laden der Dokumente
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocDocumentsLoaded)
    public documentsLoaded({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocDocumentsLoaded): void {
        const state = getState();
        if (state.openedId === payload.id) {
            patchState({
                documents: this.sortDocuments(payload.documents),
                documentsLoaded: true,
            });
        }
    }

    /**
     * Logik zum Laden der bereits an SmartDoc gesendeten File-Ids
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocUploadedFileIdsLoaded)
    public smartDocUploadedFileIdsLoaded({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocUploadedFileIdsLoaded): void {
        const state = getState();
        if (state.openedId === payload.id) {
            patchState({
                smartDocUploadedIds: [...payload.ids], //nur kopie des Arrays speichern
                smartDocUploadedIdsLoaded: true,
            });
        }
    }

    /**
     * Logik zum Hochladen der Dokumente
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocDocumentsUploaded)
    public documentsUploaded({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocDocumentsUploaded): void {
        const state = getState();
        if (state.openedId === payload.id) {
            patchState({
                documents: this.sortDocuments([...state.documents, ...payload.documents]),
                documentsLoaded: true,
            });
        }
    }

    /**
     * Logik zum Resetten der geladenen Dokumente innerhalb von SmartDoc
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocDocumentsLoadedReset)
    public smartDocDocumentsLoadedReset({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocDocumentsLoadedReset): void {
        const state = getState();
        if (state.openedId === payload) {
            patchState({
                documents: [],
                documentsLoaded: false,
            });
        }
    }

    /**
     * Logik zum Resetten der Ids der bereits zu SmartDoc gesendeten Dateien
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocUploadedFileIdsReset)
    public smartDocUploadedFileIdsReset({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocUploadedFileIdsReset): void {
        const state = getState();
        if (state.openedId === payload) {
            patchState({
                smartDocUploadedIds: [],
                smartDocUploadedIdsLoaded: false,
            });
        }
    }

    /**
     * Logik zum Resetten der geladenen Thumbnails innerhalb von SmartDoc
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocThumbnailsLoadedReset)
    public smartDocThumbnailsLoadedReset({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocThumbnailsLoadedReset): void {
        const state = getState();
        if (state.openedId === payload) {
            patchState({
                smartDocThumbnails: [],
                smartDocThumbnailsLoaded: false,
                requestCounter: 0,
            });
        }
    }

    /**
     * Logik zum Laden der Vorschaubilder
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocJobThumbnailsLoaded)
    public smartDocJobThumbnailsLoaded({ getState, setState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocJobThumbnailsLoaded): void {
        const state = getState();
        if (state.openedId === payload.id) {
            let newThumbnails = [];
            if (state.smartDocThumbnails) {
                newThumbnails = [
                    ...state.smartDocThumbnails.filter(
                        aThumbnail => !payload.thumbnails.some(
                            thumb => thumb.documentId === aThumbnail.documentId),
                    ), ...payload.thumbnails];
            } else {
                newThumbnails = [...payload.thumbnails];
            }
            setState({
                ...state,
                smartDocThumbnails: this.sortThumbnails(newThumbnails),
                smartDocThumbnailsLoaded: true,
                requestCounter: 0,
            });
        }
    }

    /**
     * Logik zum Laden eines Vorschaubildes in Originalgröße
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocJobThumbnailContentLoaded)
    public smartDocJobThumbnailContentLoaded({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocJobThumbnailContentLoaded): void {
        const state = getState();
        if (state.openedId === payload.id) {
            const newContents = state.smartDocThumbnailContents.filter(it => it.id !== payload.thumbnailId);
            let content = state.smartDocThumbnailContents.find(it => it.id === payload.thumbnailId);
            if (content !== undefined) {
                content = {
                    ...content,
                    content: payload.content,
                };
            }
            else {
                content = {
                    id: payload.thumbnailId,
                    content: payload.content,
                };
            }
            newContents.push(content);
            patchState({
                smartDocThumbnailContents: newContents,
            });
        }
    }

    /**
     * Logik zum Speichern der Zuordnung eines Vorschaubilds zu einer Dokumentenablage
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocThumbnailSortResultStored)
    public smartDocThumbnailSortResultStored({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocThumbnailSortResultStored): void {
        const state = getState();
        if (
            payload.results.length === 0 ||
            payload.results.some(
                result => result.dropAreaId !== undefined && result.sortWeight === undefined,
            )
        ) {
            return;
        }
        if (state.openedId === payload.id) {
            const oldThumbnails = state.smartDocThumbnails.filter(aThumbnail => payload.results.some(result => result.id === aThumbnail.id));
            if (oldThumbnails.length !== payload.results.length) {
                return;
            }
            // alle Thumbnails, die die Änderung nicht betreffen
            let newThumbnails = state.smartDocThumbnails.filter(
                aThumbnail => !payload.results.some(
                    result => result.id === aThumbnail.id,
                ) && payload.results.every(
                    result =>
                        // Unterschiedliche DropArea Ziel
                        aThumbnail.dropAreaId !== result.dropAreaId ||
                        // Droparea Ziel Papierkorb oder Nicht zugeordnet
                        (aThumbnail.dropAreaId === undefined && (
                            // Ziel Papierkorb, also nicht Zugeordneten berücksichtigen
                            (aThumbnail.sortWeight === undefined && result.sortWeight !== undefined) ||
                            // Ziel Nicht zugeordnet, also Papierkorb berücksichtigen
                            (aThumbnail.sortWeight !== undefined && result.sortWeight === undefined)
                        )),
                ) && oldThumbnails.every(
                    result =>
                        // unterschiedliche Droparea Start
                        aThumbnail.dropAreaId !== result.dropAreaId ||
                        // Droparea Start Papierkorb oder Nicht zugeordnet
                        (aThumbnail.dropAreaId === undefined && (
                            // Start Papierkorb, also nicht Zugeordneten berücksichtigen
                            (aThumbnail.sortWeight === undefined && result.sortWeight !== undefined) ||
                            // Start Nicht zugeordnet, also Papierkorb berücksichtigen
                            (aThumbnail.sortWeight !== undefined && result.sortWeight === undefined)
                        )),
                ),
            );

            const oldAreasResorted: Array<string | undefined> = [];
            let oldDropAreasThumbnails: ISmartDocJobThumbnailViewModel[] = [];
            for (const oldThumbnail of oldThumbnails) {
                if (payload.results.some(result => result.dropAreaId === oldThumbnail.dropAreaId && (
                    (result.sortWeight === undefined && oldThumbnail.sortWeight === undefined) ||
                    (result.sortWeight !== undefined && oldThumbnail.sortWeight !== undefined)
                )) || oldAreasResorted.some(it =>
                    (oldThumbnail.dropAreaId !== undefined && oldThumbnail.dropAreaId === it) ||
                        (oldThumbnail.dropAreaId === undefined &&
                            (
                                (oldThumbnail.sortWeight !== undefined && it === 'trash') ||
                                (oldThumbnail.sortWeight === undefined && it === undefined)
                            )
                        ),
                )) {
                    break;
                }

                let oldDropAreaThumbnails = state.smartDocThumbnails.filter(thumbnail => !payload.results.some(result => result.id === thumbnail.id) && thumbnail.dropAreaId === oldThumbnail.dropAreaId && (
                    (thumbnail.sortWeight === undefined && oldThumbnail.sortWeight === undefined) ||
                    (thumbnail.sortWeight !== undefined && oldThumbnail.sortWeight !== undefined)
                ));
                if (oldThumbnail.dropAreaId !== undefined || oldThumbnail.sortWeight !== undefined) {
                    oldDropAreaThumbnails = oldDropAreaThumbnails.map((thumbnail: ISmartDocJobThumbnailViewModel, index: number) => ({
                        ...thumbnail,
                        sortWeight: index,
                    }));
                }

                oldDropAreasThumbnails = oldDropAreasThumbnails.concat(oldDropAreaThumbnails);

                if (oldThumbnail.dropAreaId !== undefined) {
                    oldAreasResorted.push(oldThumbnail.dropAreaId);
                }
                else {
                    oldAreasResorted.push(oldThumbnail.sortWeight !== undefined ? 'trash' : undefined);
                }
            }

            let newDropAreaThumbnails = state.smartDocThumbnails.filter(thumbnail => !payload.results.some(result => result.id === thumbnail.id) &&
                // Gleiche Ziel-DropArea
                payload.results.every(result => thumbnail.dropAreaId === result.dropAreaId) &&
                (
                    // Ziel Nicht zugeordnet
                    (thumbnail.sortWeight === undefined && payload.results.every(result => result.sortWeight === undefined)) ||
                    // Ziel Papierkorb
                    (thumbnail.sortWeight !== undefined && payload.results.every(result => result.sortWeight !== undefined))
                ),
            );
            if (payload.results.every(result => result.dropAreaId === undefined && result.sortWeight === undefined)) {
                newDropAreaThumbnails = [...newDropAreaThumbnails, ...oldThumbnails.map(thumbnail => ({...thumbnail,
                    dropAreaId: undefined,
                    sortWeight: undefined,
                }))];
            } else if (payload.results.every(result => result.sortWeight !== undefined)) {
                sort(payload.results).asc(result => result.sortWeight);
                newDropAreaThumbnails.splice((payload.results[0].sortWeight as number), 0, ...payload.results.map(result => ({
                    ...(oldThumbnails.find(thumbnail => thumbnail.id === result.id) as ISmartDocJobThumbnailViewModel),
                    dropAreaId: result.dropAreaId,
                })));
                newDropAreaThumbnails = newDropAreaThumbnails.map((thumbnail: ISmartDocJobThumbnailViewModel, index: number) => ({
                    ...thumbnail,
                    sortWeight: index,
                }));
            }
            else {
                return;
            }
            newThumbnails = [...newThumbnails, ...oldDropAreasThumbnails, ...newDropAreaThumbnails];
            patchState({
                requestCounter: state.requestCounter + 1,
                smartDocThumbnails: this.sortThumbnails(newThumbnails),
            });
        }
    }

    /**
     * setzt die Zuordnungen zu den Dokumentenablagen zurück
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(SmartDocThumbnailsSortResultReset)
    public smartDocThumbnailsSortResultReset({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: SmartDocThumbnailsSortResultReset): void {
        const state = getState();
        if (state.openedId === payload) {
            const newThumbnails = state.smartDocThumbnails.map(it => ({
                ...it,
                dropAreaId: undefined,
                sortWeight: undefined,
            } as ISmartDocJobThumbnailViewModel));
            patchState({
                smartDocThumbnails: this.sortThumbnails(newThumbnails),
            });
        }
    }

    /**
     * setzt den Requestcounter um 1 zurück
     *
     * @param {StateContext} ctx aktueller State-Kontext
     */
    @Action(SmartDocStoreRequestCompleted)
    public smartDocStoreRequestCompleted({ getState, patchState }: StateContext<ISmartDocStateModel>): void {
        const state = getState();
        patchState({
            requestCounter: Math.max(0, state.requestCounter - 1),
        });
    }

    /**
     * schaltet die Mappe auf readonly, wenn diese nicht mehr dem aktuellen Nutzer gehört
     *
     * @param {StateContext} ctx aktueller State-Kontext
     * @param {SmartDocOpened} action Aktion
     */
    @Action(MapRevoked)
    @Action(MapTemporaryRevokedAccepted)
    public mapRevoked({ getState, patchState }: StateContext<ISmartDocStateModel>, { payload }: MapRevoked | MapTemporaryRevokedAccepted): void {
        const state = getState();
        if (state.openedId === payload) {
            patchState({
                isReadonly: true,
                requestCounter: 0,
            });
        }
    }

    /**
     * Logik zum Schließen des SmartDoc-Bereichs
     *
     * @param {StateContext} ctx aktueller State-Kontext
     */
    @Action(SmartDocClosed)
    public smartDocClosed({ setState }: StateContext<ISmartDocStateModel>): void {
        setState({} as ISmartDocStateModel);
    }

    /**
     * Aktualisiert den SmartDocState nach dem fetchen der DropFolders
     *
     * @param {StateContext} StateContext aktueller State-Kontext
     * @param {LoadedDropFolders} LoadedDropFolders action
     */
    @Action(LoadedDropFolders)
    public loadedDropFolders ({ patchState }: StateContext<ISmartDocStateModel>, { payload }: LoadedDropFolders): void {
        patchState({
            dropFolders: payload,
        });
    }

    /**
     * Aktualisiert den jobStatus im State
     *
     * @param {StateContext} StateContext aktueller State-Kontext
     * @param {SmartDocJobStatusChanged} SmartDocJobStatusChanged action
     */
    @Action(SmartDocJobStatusChanged)
    public smartDocJobStatusChanged (
        { getState, patchState }: StateContext<ISmartDocStateModel>,
        { payload }: SmartDocJobStatusChanged): void {
        const state = getState();
        if (state.openedId === payload.id) {
            patchState({
                ...state,
                status: {
                    ...state.status as ISmartDocStatusViewModel,
                    jobStatus: payload.jobStatus,
                },
            });
        }
    }
    /**
     * sortiert die Dokumente
     *
     * @param {IDocument[]} documents Dokumente
     * @returns {IDocument[]} Sortierte Dokumente
     */
    private sortDocuments(documents: IDocument[]): IDocument[] {
        return sort(documents).by([
            { desc: u => u.created },
            { asc: u => u.name?.toLowerCase() },
        ]);
    }

    /**
     * sortiert Vorschaubilder
     *
     * @param {ISmartDocJobThumbnailViewModel[]} thumbnails Thumbnails
     * @returns {ISmartDocJobThumbnailViewModel[]} Sortierte Thumbnails
     */
    private sortThumbnails(thumbnails: ISmartDocJobThumbnailViewModel[]): ISmartDocJobThumbnailViewModel[] {
        const unsorted = thumbnails.filter(it => it.sortWeight === undefined);
        const sorted = thumbnails.filter(it => it.sortWeight !== undefined);
        sort(unsorted).asc([
            it => it.documentId,
            it => it.pageInOriginalFile,
        ]);
        sort(sorted).asc([
            it => it.dropAreaId,
            it => it.sortWeight,
        ]);
        return unsorted.concat(sorted);
    }
}
