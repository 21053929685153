import { EnumSettings } from './../enum.settings';

/**
 * Anpassungen der Rate
 */
export enum DebitRateAdaptionParameter {
    /**
     * LTV Rating 4- (BA-Durchschnitt)
     */
    LTV4 = 0,

    /**
     * LTV Rating 3 (Bestandskunde)
     */
    LTV3 = 1,

    /**
     * LTV Rating 5- (Neukunde)
     */
    LTV5 = 2,

    /**
     * Volumen-Aufschlag
     */
    Volume = 3,

    /**
     * Aufschlag auf EVA1
     */
    Marge = 4,
}

export namespace DebitRateAdaptionParameter {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {DebitRateAdaptionParameter | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: DebitRateAdaptionParameter | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'DebitRateAdaptionParameter', DebitRateAdaptionParameter, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('DebitRateAdaptionParameter', plocale);
}
