import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LoginStateType } from '../../../../data/enums';
import { ICanComponentDeactivate } from '../../interfaces';

import { AuthenticationService } from './../../../../data/';

/**
 * Guard für Sperrseite
 */
@Injectable()
export class LockedGuard {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {Router} router Router-Injektor
     */
    public constructor(private authenticationService: AuthenticationService, private router: Router) { }

    /**
     * Stellt sicher, dass die Route nur mit aktiver Authentifizierung erreicht werden kann
     *
     * @returns {Promise<boolean>} Route kann aktiviert werden
     */
    public canActivate(): Promise<boolean> {
        return this.checkLocked();
    }

    /**
     * Stellt sicher, dass die Kindrouten nur mit aktiver Authentifizierung erreicht werden können
     *
     * @returns {Promise<boolean>} Kindrouten können aktiviert werden
     */
    public canActivateChild(): Promise<boolean> {
        return this.checkLocked();
    }

    /**
     * Stellt sicher, dass bestimmte Routen nur unter Bedingungen verlassen werden können
     *
     * @param {any} component Komponente
     * @returns {boolean} Route kann verlassen werden
     */
    // eslint-disable-next-line class-methods-use-this
    public canDeactivate(component: ICanComponentDeactivate): boolean {
        return component.canDeactivate !== undefined ? component.canDeactivate() : true;
    }

    /**
     * Prüft, ob eine gültige Authentifizierung vorhanden ist
     *
     * @returns {Promise<boolean>} Aktive Authentifzierung vorhanden
     */
    private async checkLocked(): Promise<boolean> {
        const isExternalLogin = this.authenticationService.isExternalLogin;

        switch (this.authenticationService.loginState) {
            case LoginStateType.LoggedIn:
                await this.router.navigateByUrl('/');
                return false;
            case LoginStateType.Locked:
                return true;
            case LoginStateType.NotLoggedIn:
            default:
                if (isExternalLogin) {
                    await this.router.navigateByUrl('/');
                } else {
                    await this.router.navigateByUrl('/auth/login');
                }

                return false;
        }
    }

}
