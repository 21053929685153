import { LiabilityType } from '@ntag-ef/finprocess-enums';

import { ArgumentError, DivisionByZeroError } from '../errors';
import { IFinancingConfiguration } from '../interfaces';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService, InternalCalculationService } from './core';

/**
 * Berechnungsmethoden zu Bestehenden Verpflichtungen
 */
export class LiabilityCalculationService extends BaseCalculationService {

    /**
     * Berechnungsservice InternalCalculationService
     */
    private pInternalCalculationService?: InternalCalculationService;

    /**
     * Berechnungsservice InternalCalculationService
     *
     * @returns {InternalCalculationService} InternalCalculationService
     */
    private get internalCalculationService() {
        if (this.pInternalCalculationService === undefined) {
            this.pInternalCalculationService = new InternalCalculationService(this.version);
        }
        return this.pInternalCalculationService;
    }

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Liefert Dzt. Aushaftung gem. Nachweis, wenn nicht abgedeckt und grundbücherlich besichert
     * wenn abgedeckt true, dann 0
     * wenn grundbücherlich besichert nicht true, dann 0
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.currentAmount Dzt. Aushaftung gem. Nachweis in €
     * @param {boolean | undefined} params.covered Wird abgedeckt
     * @param {boolean | undefined} params.securedByLandRegister Grundbücherlich besichert
     * @returns {number} Dzt. Aushaftung gem. Nachweis €
     */
    public currentAmountSecuredByLandRegisterNotCovered({ currentAmount, covered, securedByLandRegister }: {
        /**
         * Dzt. Aushaftung gem. Nachweis in €
         */
        currentAmount?: number;
        /**
         * Wird abgedeckt
         */
        covered?: boolean;
        /**
         * Grundbücherlich besichert
         */
        securedByLandRegister?: boolean;
    }): number {
        return covered !== true && securedByLandRegister === true ? (!CalculationHelperService.isNullOrNaN(currentAmount) ? (currentAmount as number) : 0) : 0;
    }

    /**
     * Liefert Monatliche Rate, wenn nicht abgdeckt
     * wenn abgdeckt true, dann 0
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.monthlyRate Monatliche Rate in €
     * @param {boolean | undefined} params.covered Wird abgedeckt
     * @returns {number} Monatliche Rate in €
     */
    public monthlyRateNotCovered({ monthlyRate, covered }: {
        /**
         * Monatliche Rate in €
         */
        monthlyRate?: number;
        /**
         * Wird abgedeckt
         */
        covered?: boolean;
    }): number {
        return covered !== true ? (!CalculationHelperService.isNullOrNaN(monthlyRate) ? (monthlyRate as number) : 0) : 0;
    }

    /**
     * Liefert Dzt. Aushaftung gem. Nachweis, wenn abgedeckt
     * wenn nicht abgedeckt, dann 0
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.currentAmount Dzt. Aushaftung gem. Nachweis in €
     * @param {boolean | undefined} params.covered Wird abgedeckt
     * @returns {number} Dzt. Aushaftung gem. Nachweis in €
     */
    public currentAmountCovered({ currentAmount, covered }: {
        /**
         * Dzt. Aushaftung gem. Nachweis in €
         */
        currentAmount?: number;
        /**
         * Wird abgedeckt
         */
        covered?: boolean;
    }): number {
        return covered === true ? (!CalculationHelperService.isNullOrNaN(currentAmount) ? (currentAmount as number) : 0) : 0;
    }

    /**
     * Berechnet die fiktive Rate einer bestehenden Verpflichtung
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.monthlyRate Monatliche Rate in €
     * @param {number} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number | undefined} params.initialAmount Ursprünglicher Kreditbetrag in €
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Fiktive Rate in €
     */
    public fictionalAmount({ fictionalRate, monthlyRate, loanPeriodInMonths, initialAmount, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Monatliche Rate in €
         */
        monthlyRate: number;
        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths: number;
        /**
         * Ursprünglicher Kreditbetrag in €
         */
        initialAmount?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        if (fictionalRate === 0 || loanPeriodInMonths === 0) {
            throw new DivisionByZeroError();
        }

        if (this.internalCalculationService.isCreditAmountOrRateIllegal({ amount: !CalculationHelperService.isNullOrNaN(initialAmount) ? (initialAmount as number) : (monthlyRate * loanPeriodInMonths), rate: fictionalRate }) || !CalculationHelperService.isGreaterThan(loanPeriodInMonths)) {
            return 0;
        }

        const amount = !CalculationHelperService.isNullOrNaN(initialAmount) ? (initialAmount as number) : (monthlyRate * loanPeriodInMonths);
        return amount * this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }) / (1 - (1 / Math.pow(1 + this.internalCalculationService.interestRateMonthCorrected({ rate: fictionalRate }), loanPeriodInMonths))) + bankAccountFee;
    }

    /**
     * Gibt die fiktive Rate zurück, wenn nicht abgedeckt
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.fictionalRate Fiktive Rate in %
     * @param {number} params.monthlyRate Monatliche Rate in €
     * @param {boolean | undefined} params.covered Wird abgedeckt
     * @param {number} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number | undefined} params.initialAmount Ursprünglicher Kreditbetrag in €
     * @param {number} params.bankAccountFee Kontogebühren pro Monat in €
     * @throws {DivisionByZeroError}
     * @returns {number} Fiktive Rate in €
     */
    public fictionalAmountNotCoveredLiabilities({ fictionalRate, monthlyRate, loanPeriodInMonths, covered, initialAmount, bankAccountFee }: {
        /**
         * Fiktive Rate in %
         */
        fictionalRate: number;
        /**
         * Monatliche Rate in €
         */
        monthlyRate: number;
        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths: number;
        /**
         * Wird abgedeckt
         */
        covered?: boolean;
        /**
         * Ursprünglicher Kreditbetrag in €
         */
        initialAmount?: number;
        /**
         * Kontogebühren pro Monat in €
         */
        bankAccountFee: number;
    }): number {
        return covered !== true ? this.fictionalAmount({ fictionalRate, monthlyRate, loanPeriodInMonths, initialAmount, bankAccountFee }) : 0;
    }

    /**
     * Liefert den fiktiven Zinssatz anhand der Konfiguration zurück
     *
     * @param {object} params Parameterobjekt
     * @param {IFinancingConfiguration} params.configuration Konfigurationsobjekt für die Finanzierung
     * @returns {number} Fiktiver Zinssatz in %
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    public fictionalRate({ configuration }: {
        /**
         * Konfigurationsobjekt für die Finanzierung
         */
        configuration: IFinancingConfiguration;
    }): number {
        this.checkConfiguration(configuration);
        return configuration.liabilityFictionalRate;
    }

    /**
     * Berechnet aus einem jährlichen Zinssatz den monatlichen Zinssatz
     *
     * @param {number} fictionalRate fiktiver Zinssatz
     * @returns {number} monatlichen Zinssatz
     */
    public calculateMonthlyRate({fictionalRate}: { fictionalRate?:number }) {

        if (!CalculationHelperService.isNullOrNaN(fictionalRate)) {
            return fictionalRate * (365 / 360) / 12;
        }
        else {
            return 0;
        }
    }

    /**
     * Berechnet die fiktive monatliche Rate in Euro entsprechend der neuen Berechnungen der Bank
     * die für die Verbindlichkeiten eingeführt wurden.
     * 
     * Diese Funktion unterscheidet nach Verbindlichkeitsarten, für die direkte Berechnung es calculateFictionalRateInternal.
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.liabilityType Fiktive Rate in %
     * @param {number} params.currentAmount Derzeitige Aushaftung in €
     * @param {number} params.initialAmount Ursprünglicher Kreditbetrag in €
     * @param {number} params.creditLimit Rahmenhöhe
     * @param {number} params.fictionalRate Fiktiver Zinssatz (jährlich)
     * @param {number} params.remainingTimeInMonths Restlaufzeit in Monaten
     * @param {number} params.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number} params.loanValue Belehnwert (nur für Fremdwähungskredite)
     * @returns {number} Fiktive Rate in €
     */
    // eslint-disable-next-line complexity
    public calculateFictionalRate({ liabilityType, currentAmount, initialAmount, creditLimit, fictionalRate, remainingTimeInMonths, loanPeriodInMonths, loanValue}: {
        /**
         * Art der Verbindlichkeit
         */
        liabilityType?: LiabilityType | null;

        /**
         * Derzeitige Aushaftung in €
         */
        currentAmount?: number | null;

        /**
         * Ursprünglicher Kreditbetrag in €
         */
        initialAmount?: number | null;

        /**
         * Rahmenhöhe
         */
        creditLimit?: number | null;

        /**
         * Fiktiver Zinssatz (jährlich)
         */
        fictionalRate?: number | null;

        /**
         * Restlaufzeit in Monaten
         */
        remainingTimeInMonths?: number | null;

        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths?: number | null;

        /**
         * Belehnwert (nur für Fremdwähungskredite)
         */
        loanValue?: number | null;
    }): number {
        if (CalculationHelperService.isNullOrNaN(liabilityType)) {
            return 0;
        }

        switch (liabilityType) {
            case LiabilityType.Credit:
            case LiabilityType.ComfortCredit:
            case LiabilityType.OneTimeCashLoan:
            case LiabilityType.KfzLeasing:
            case LiabilityType.GuaranteeStandAlone:
            case LiabilityType.GuaranteeConstruction:
            case LiabilityType.ConstructionFollowUpFinancing:
            case LiabilityType.ConstructionFollowUpFinancingBuildingLoan:
            case LiabilityType.ConstructionInterimFinancing:
            case LiabilityType.ConstructionPrefinancingInvestmentFlatHigh:
            case LiabilityType.ConstructionPrefinancingInvestmentFlatLow:
                return this.calculateFictionalRateInternal({ currentAmount, initialAmount, fictionalRate, remainingTimeInMonths, loanPeriodInMonths, fallbackToInitialAmount: true });
            case LiabilityType.CreditCard:
            case LiabilityType.Overdraft:
                if (!CalculationHelperService.isNullOrNaN(creditLimit)) {
                    const amount = Math.max(!CalculationHelperService.isNullOrNaN(creditLimit) ? creditLimit : 0, !CalculationHelperService.isNullOrNaN(currentAmount) ? currentAmount : 0);
                    return this.calculateFictionalRateInternal({ currentAmount: amount, remainingTimeInMonths: 120, fictionalRate, fallbackToInitialAmount: false });
                }

                return 0;
            case LiabilityType.DevelopmentLoan:
                return this.calculateFictionalRateInternal({
                    currentAmount: (CalculationHelperService.isNullOrNaN(currentAmount) ? 0 : currentAmount) - (CalculationHelperService.isNullOrNaN(loanValue) ? 0 : loanValue),
                    remainingTimeInMonths: remainingTimeInMonths,
                    fictionalRate: fictionalRate,
                    fallbackToInitialAmount: false,
                });
            case LiabilityType.SubsidizedLoan:
                return this.calculateFictionalRateInternal({ currentAmount, initialAmount, fictionalRate, remainingTimeInMonths, loanPeriodInMonths, fallbackToInitialAmount: false });
            case LiabilityType.CompanyCredit:
            default:
                return 0;
        }
    }

    /**
     * Führt die Berechnung der fiktiven Rate aus
     * 
     * @param {object} param Parameterobjekt
     * @param {number} param.currentAmount Derzeitige Aushaftung in €
     * @param {number} param.initialAmount Ursprünglicher Kreditbetrag in €
     * @param {number} param.loanPeriodInMonths Gesamtlaufzeit in Monaten
     * @param {number} param.remainingTimeInMonths Restlaufzeit in Monaten
     * @param {number} param.fictionalRate Fiktiver Zinssatz (jährlich)
     * @param {boolean} param.fallbackToInitialAmount Soll auf den ursprünglichen Kreditbetrag zurückgegriffen werden, wenn der aktuelle Betrag nicht vorhanden ist
     * @returns {number} Fiktive Rate in €
     */
    public calculateFictionalRateInternal({ currentAmount, initialAmount, loanPeriodInMonths, remainingTimeInMonths, fictionalRate, fallbackToInitialAmount }: {

        /**
         * Derzeitige Aushaftung in €
         */
        currentAmount?: number | null;

        /**
         * Ursprünglicher Kreditbetrag in €
         */
        initialAmount?: number | null;

        /**
         * Fiktiver Zinssatz (jährlich)
         */
        fictionalRate?: number | null;

        /**
         * Restlaufzeit in Monaten
         */
        remainingTimeInMonths?: number | null;

        /**
         * Gesamtlaufzeit in Monaten
         */
        loanPeriodInMonths?: number | null;

        /**
         * Soll auf den ursprünglichen Kreditbetrag zurückgegriffen werden, wenn der aktuelle Betrag nicht vorhanden ist
         */
        fallbackToInitialAmount?: boolean;
    }): number {
        if (CalculationHelperService.isNullOrNaN(fictionalRate)) {
            return 0;
        }

        const monthlyFictionalRate = this.calculateMonthlyRate({ fictionalRate: fictionalRate / 100 });

        if (!CalculationHelperService.isNullOrNaN(currentAmount) && !CalculationHelperService.isNullOrNaN(remainingTimeInMonths)) {
            return this.internalRateCalculation({ amount: currentAmount, fictionalRate: monthlyFictionalRate, months: remainingTimeInMonths});
        }

        if (!CalculationHelperService.isNullOrNaN(initialAmount) && !CalculationHelperService.isNullOrNaN(loanPeriodInMonths) && fallbackToInitialAmount) {
            return this.internalRateCalculation({ amount: initialAmount, fictionalRate: monthlyFictionalRate, months: loanPeriodInMonths});
        }

        return 0;
    }

    /**
     * Interne Berechnung der Rate aus UserStory 11223
     * 
     * @param {object} param Parameterobjekt
     * @param {number} param.amount Betrag in €
     * @param {number} param.fictionalRate Fiktive Rate (monatlich)
     * @param {number} param.months Laufzeit in Monaten
     * @returns {number} Berechnete Rate in €
     */
    private internalRateCalculation({ amount, fictionalRate, months }: {

        /**
         * Betrag in €
         */
        amount: number;

        /**
         * Fiktive Rate (monatlich)
         */
        fictionalRate: number;

        /**
         * Laufzeit in Monaten
         */
        months: number;
    }) {
        if (amount === 0 || months === 0) {
            return 0;
        }

        if (fictionalRate === 0) {
            return amount / months;
        }

        return amount * (fictionalRate / (1 - Math.pow(1+ fictionalRate, -1 * months)));
    }

    /**
     * Überprüft die Konfiguration für fiktive Raten bei bestehenden Verpflichtungen
     *
     * @param {IFinancingConfiguration | null | undefined} configuration Konfigurationsobjekt für die Finanzierung
     * @throws {ArgumentError} Der Parameter 'configuration' ist nicht valide.
     */
    private checkConfiguration(configuration?: IFinancingConfiguration | null): void {
        if (configuration === undefined ||
            configuration === null ||
            isNaN(configuration.liabilityFictionalRate) ||
            (configuration.liabilityFictionalRate as unknown) === null) {
            throw new ArgumentError('Der Parameter \'configuration\' ist nicht valide.');
        }
    }
}
