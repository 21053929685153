<mat-dialog-content>
  <div *ngIf="!showContryForm && (!searchForm && !isAssetCall)" fxLayout="row" class="cursor-grab" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div fxFlex class="header bold p-2 my-2">
      {{'financing.features.financing-processing.verify-customer.searchResultFor' | translate:{
      lastName: (debtorHeaderDataWithOverwrite?.lastName),
      firstName: (debtorHeaderDataWithOverwrite?.firstName),
      date: (debtorHeaderDataWithOverwrite?.birthday | date: 'dd.MM.yyyy'),
      birthPlace: (debtorHeaderDataWithOverwrite?.birthPlace),
      birthcountry: (debtorHeaderDataWithOverwrite?.birthcountry),
      strasse: (debtorHeaderDataWithOverwrite?.street),
      houseNr: (debtorHeaderDataWithOverwrite?.streetNumber),
      plz: (debtorHeaderDataWithOverwrite?.zip),
      ort: (debtorHeaderDataWithOverwrite?.city),
      citizenship: (debtorHeaderDataWithOverwrite?.citizenship)
      }
      }}
    </div>
    <button color="primary" class="m-2" mat-mini-fab align="end" (click)="toogleCountyInput()"
      matTooltip="{{'financing.features.financing-processing.verify-customer.newDgaToolTip' | translate }}">
      <mat-icon>person_add</mat-icon>
    </button>
  </div>

  <div *ngIf="!!searchForm && !showManualDgaUserForm" style="width: 100%;" fxLayout="column"
    fxLayoutAlign="start center" class="bold pl-2 pt-2 pr-2 " [formGroup]="searchForm">
    <div>
      <p style="margin-right: 30px;">Tragen Sie bitte die Suchdaten ein: </p>
    </div>
    <div class="search-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{'financing.features.financing-processing.verify-customer.firstName' | translate}}</mat-label>
        <input formControlName="firstName" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'financing.features.financing-processing.verify-customer.lastName' | translate}}</mat-label>
        <input formControlName="lastName" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'financing.features.financing-processing.verify-customer.birthDate' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birthDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>{{ searchForm.get('birthDate')?.errors | finProcessFormError }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'financing.features.financing-processing.verify-customer.ndg' | translate}}</mat-label>
        <input finprocessFormatNdg formControlName="ndg" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'financing.features.financing-processing.verify-customer.email' | translate}}</mat-label>
        <input formControlName="email" matInput>
      </mat-form-field>
      <div align="right">
        <button color="primary" class="m-2" mat-mini-fab [disabled]="searchForm.invalid" (click)="searchDga()"
          matTooltip="suchen">
          <mat-icon>search</mat-icon>
        </button>
        <button color="primary" class="m-2" mat-mini-fab (click)="toogleManualDgaUserForm()"
          matTooltip="{{'financing.features.financing-processing.verify-customer.newDgaToolTip' | translate }}">
          <mat-icon>person_add</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!showContryForm && !showManualDgaUserForm" fxLayout="row" class="activity-header bold">
    <span fxFlex="20">{{'financing.features.financing-processing.verify-customer.ndg' | translate }}</span>
    <span fxFlex="25">
      {{'financing.features.financing-processing.verify-customer.lastName' | translate }},
      {{'financing.features.financing-processing.verify-customer.firstName' | translate }}
    </span>
    <span fxFlex="20">{{'financing.features.financing-processing.verify-customer.birthDate' | translate }}</span>
    <span fxFlex="20">{{'financing.features.financing-processing.verify-customer.birthPlace' | translate }}</span>
  </div>

  <mat-accordion *ngIf="!showContryForm && !showManualDgaUserForm" class="example-headers-align"
    [togglePosition]="'before'">
    <span class="mt-5" style="display: block; width: 100%; text-align: center;"
      *ngIf="!verifiedCustomers|| verifiedCustomers?.length === 0">
      {{'financing.features.financing-processing.verify-customer.noResult' | translate }}
    </span>

    <mat-expansion-panel class="mb-3" *ngFor="let customer of verifiedCustomers; let i = index"
      [expanded]="accordionElementIndex === i" (opened)="setStep(i)">
      <mat-expansion-panel-header [ngClass]="{'mark-text bold': customer?.searchedByNdg}">
        <span fxFlex="20">{{customer?.externalUserData?.ndg | formatNdg}}</span>
        <span fxFlex="25">{{customer?.externalUserData?.surname}}, {{customer?.externalUserData?.firstName}}</span>
        <span fxFlex="20">{{customer?.externalUserData?.birthDate | date: 'dd.MM.yyyy'}}</span>
        <span fxFlex="20">{{customer?.externalUserData?.birthPlace}}</span>
        <button *ngIf="!isAssetCall" fxFlex="15" color="primary" mat-raised-button
          (click)="onSelectedData(customer.externalUserData.ndg)">
          {{'financing.features.financing-processing.verify-customer.takeNdg' | translate }}
        </button>
        <button *ngIf="isAssetCall" fxFlex="15" color="primary" mat-raised-button (click)="takeAssetProvider(customer)">
          {{'financing.features.financing-processing.verify-customer.take' | translate }}
        </button>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutGap="16px" *ngIf="!isCompareActive">
        <div class="dga-search-group">
          <span class="label">{{'financing.features.financing-processing.verify-customer.personalData' | translate
            }}</span>
          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.ndg' | translate }} </span>
            <span value>{{ customer?.externalUserData?.ndg}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.ndgStatus' | translate }} </span>
            <span value *ngIf="!!customer.externalUserData.ndgStatus">{{customer.externalUserData.ndgStatus |
              finprocessEnumTranslation:"NdgStatus"}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.ndgType' | translate }} </span>
            <span value>{{customer?.externalUserData?.ndgType}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.title' | translate }} </span>
            <span value>{{customer?.externalUserData?.title}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.firstName' | translate }} </span>
            <span value>{{ customer?.externalUserData?.firstName}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.surname' | translate }} </span>
            <span value>{{ customer?.externalUserData?.surname}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.birthDate' | translate }} </span>
            <span value>{{ customer?.externalUserData?.birthDate | date: 'dd.MM.yyyy'}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.birthPlace' | translate }} </span>
            <span value>{{ customer?.externalUserData?.birthPlace}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.maritalStatus' | translate }} </span>
            <span value>{{customer.externalUserData.maritalStatus |
              finprocessEnumTranslation:"MaritalStatus"}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.gender' | translate }} </span>
            <span value *ngIf="customer.externalUserData.gender !== undefined">{{ customer.externalUserData.gender |
              finprocessEnumTranslation:"Gender"}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.language' | translate }} </span>
            <span value>{{customer?.externalUserData?.language}}&nbsp;</span>
          </finprocess-label-value>

          <!-- Auskommentiert im Rahmen von US 14185 -->
          <!-- <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.nickname' | translate }} </span>
            <span value>{{ customer?.externalUserData?.nickname}}&nbsp;</span>
          </finprocess-label-value> -->

          <!-- Auskommentiert im Rahmen von US 14185 -->
          <!-- <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.familyMembers' | translate }} </span>
            <span value
              *ngIf="!!customer.externalUserData.familyMembers">{{customer.externalUserData.familyMembers}}&nbsp;</span>
          </finprocess-label-value> -->

        </div>

        <div class="dga-search-group">
          <span class="label">{{'financing.features.financing-processing.verify-customer.addressData' | translate
            }}</span>
          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.zipCode' | translate }} </span>
            <span value>{{customer?.externalUserData?.zipCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.mainCityDescription' | translate }} </span>
            <span value>{{customer?.externalUserData?.mainCityDescription}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.street' | translate }} </span>
            <span value>{{customer?.externalUserData?.street}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.streetNumberNew' | translate }}
            </span>
            <span value>{{customer?.externalUserData?.streetNumber}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.stairway' | translate }} </span>
            <span value>{{customer?.externalUserData?.stairway}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.top' | translate }} </span>
            <span value>{{customer?.externalUserData?.top}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.mainCityCode' | translate }} </span>
            <span value>{{customer?.externalUserData?.mainCityCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.mainCountryCode' | translate }}
            </span>
            <span value>{{customer?.externalUserData?.mainCountryCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.mainCountryDescription' | translate
              }} </span>
            <span value>{{customer?.externalUserData?.mainCountryDescription}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.mainProvinceAddressCode' | translate
              }} </span>
            <span value>{{customer?.externalUserData?.mainProvinceAddressCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.province' | translate }} </span>
            <span value>{{customer?.externalUserData?.province}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.provinceCode' | translate }} </span>
            <span value>{{customer?.externalUserData?.provinceCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.resident' | translate }} </span>
            <span value>{{customer?.externalUserData?.resident}}&nbsp;</span>
          </finprocess-label-value>
        </div>

        <div class="dga-search-group">
          <span class="label">{{'financing.features.financing-processing.verify-customer.legalData' | translate
            }}</span>
          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.headingType' | translate }} </span>
            <span value>{{customer?.externalUserData?.headingType}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.jointHeading' | translate }} </span>
            <span value>{{customer?.externalUserData?.jointHeading}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.legalForm' | translate }} </span>
            <span value>{{customer?.externalUserData?.legalForm}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.businessName' | translate }} </span>
            <span value>{{customer?.externalUserData?.businessName}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.bankCode' | translate }} </span>
            <span value>{{customer?.externalUserData?.bankCode}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.buddyBank' | translate }} </span>
            <span value *ngIf="!!customer.externalUserData.buddyBank"> {{customer.externalUserData.buddyBank.toString()
              | finprocessEnumTranslation:"YesNoBool"}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.taxCode' | translate }} </span>
            <span value>{{customer?.externalUserData?.taxCode}}&nbsp;</span>
          </finprocess-label-value>
        </div>

        <div class="dga-search-group">
          <span class="label">{{'financing.features.financing-processing.verify-customer.otherData' | translate
            }}</span>
          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.automaticJoin' | translate }} </span>
            <span value>{{customer?.externalUserData?.automaticJoin}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.dataCompleteness' | translate }}
            </span>
            <span value>{{customer?.externalUserData?.dataCompleteness}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.extinctionReason' | translate }}
            </span>
            <span value>{{customer?.externalUserData?.extinctionReason }}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.leadBranch' | translate }} </span>
            <span value>{{customer?.externalUserData?.leadBranch}}&nbsp;</span>
          </finprocess-label-value>

          <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.verify-customer.manager' | translate }} </span>
            <span value>{{customer?.externalUserData?.manager}}&nbsp;</span>
          </finprocess-label-value>
        </div>
      </div>
      <div *ngIf="!isCompareActive" fxLayout="row" fxLayoutAlign="end start">
        <button *ngIf="!isAssetCall" mat-button color="primary"
          (click)="initCompareCustomerView(customer.externalUserData)">
          <mat-icon>sync_alt</mat-icon>
          {{'financing.features.financing-processing.verify-customer.edit' | translate }}
        </button>
      </div>

      <div *ngIf="isCompareActive" fxLayout="column" fxLayoutAlign="start none">
        <div fxFlex fxLayout="row wrap" *ngIf="!!debtorForm && !!dgaForm">
          <div *ngFor="let fpValues of selectedValuesFromDga; let i = index">
            <!-- wann input das selbe value hat, hide beide inputs -->
            <div class="box temp-border"
              *ngIf="areValuesSame(debtorForm.controls[fpValues.key].value, dgaForm.controls[fpValues.key].value)">
              <ng-container
                *ngTemplateOutlet="compareDebitorData; context: {debtorControl:debtorForm.controls[fpValues.key], dgaControl: dgaForm.controls[fpValues.key], key:fpValues.key }">
              </ng-container>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start">
          <button mat-raised-button class="mr-2" (click)="clearTempData()">
            {{'financing.features.financing-processing.verify-customer.back' | translate }}</button>
          <button mat-raised-button color="primary"
            [disabled]="hasSelectedDgaValue() && selectedValuesFromFp.length === 0" (click)="sendChanges(customer)">
            {{'financing.features.financing-processing.verify-customer.save'| translate }}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>


  <ng-template #compareDebitorData let-debtorControl="debtorControl" let-dgaControl="dgaControl" let-key="key">
    <mat-form-field [ngClass]="isValueFromFP(key, debtorControl.value) ? 'selected' : ''">
      <mat-label> {{'financing.features.financing-processing.compare-customer.' + key | translate }}</mat-label>
      <input matInput [formControl]="debtorControl" readonly>
    </mat-form-field>
    <button mat-icon-button color="primary"
      [disabled]="!areValuesSame(debtorControl.value, dgaControl.value) || notEditable(key)"
      (click)="saveTempSelectedValue(debtorControl, dgaControl, key)">
      <mat-icon>sync</mat-icon>
    </button>
    <mat-form-field [ngClass]="isValueFromDga(key, dgaControl.value) ? 'selected' : ''">
      <mat-label> {{'financing.features.financing-processing.compare-customer-ndg.' + key | translate }}</mat-label>
      <input matInput [formControl]="dgaControl" readonly>
    </mat-form-field>
  </ng-template>

  <ng-container *ngIf="showContryForm && !!countryForm">
    <div [formGroup]="countryForm" fxLayout="column" fxLayoutGap="12px">
      <div class="header p-2 mt-2 mb-4 bold">{{'financing.features.financing-processing.verify-customer.landTitle' |
        translate }}
      </div>
      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field appearance="outline" class="size">
          <mat-label>{{'financing.features.financing-processing.verify-customer.country' | translate }}</mat-label>
          <input matInput [matAutocomplete]="auto" formControlName="countryName">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let country of filteredCountriesNameControl" [value]="country.value">
              {{country.label}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ countryForm.controls.countryName.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="size">
          <mat-label>{{'financing.features.financing-processing.verify-customer.countryForReporting' | translate
            }}</mat-label>
          <input matInput [matAutocomplete]="auto2" formControlName="countryForReporting">
          <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let country of filteredCountriesReportingControl" [value]="country.value">
              {{country.label}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{ countryForm.controls.countryForReporting.errors | finProcessFormError }}</mat-error>
        </mat-form-field>
      </div>
      <ng-container *ngIf="isExtendedCountryForm(countryForm)">
        <div class="header p-2 mt-5 mb-2 bold">{{'financing.features.financing-processing.verify-customer.hintTop' |
          translate }}
        </div>
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-form-field appearance="outline" class="size">
            <mat-label>{{ 'financing.features.financing-processing.verify-customer.stairway' | translate }}</mat-label>
            <input matInput formControlName="stairway">
            <mat-error>{{ countryForm.controls.stairway.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="size">
            <mat-label>{{ 'financing.features.financing-processing.verify-customer.top' | translate }}</mat-label>
            <input matInput formControlName="top">
            <mat-error>{{ countryForm.controls.stairway.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <div fxflex="row">
        <button class="back-btn mr-2" mat-raised-button
          (click)="toogleCountyInput()">{{'financing.features.financing-processing.verify-customer.back' | translate
          }}</button>
        <button color="primary" [disabled]="!countryForm.valid" mat-raised-button id="createDga"
          (click)="createDgaUser()">{{'financing.features.financing-processing.verify-customer.save' | translate
          }}</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!!dgaUserForm && showManualDgaUserForm">
    <div [formGroup]="dgaUserForm" fxLayout="column" >
      <div>
        <div class="header p-2 mt-3 mb-3 bold">{{'financing.features.financing-processing.verify-customer.landTitle' |
          translate }}</div>
        <div [innerHTML]="dgaManualSaveError" style="color: red;" class="mb-2"></div>

        <div fxLayout="row" class="mb-3">
          <mat-form-field appearance="outline" class="mr-2 mb-2 mt-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.country' | translate }}</mat-label>
            <input matInput [matAutocomplete]="auto3" formControlName="country">
            <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn.bind(this)">
              <mat-option *ngFor="let country of filteredCountriesNameControl" [value]="country.value">
                {{country.label}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ dgaUserForm.get('country')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2 mt-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.countryForReporting' |
              translate}}</mat-label>
            <input matInput [matAutocomplete]="auto4" formControlName="countryForReporting">
            <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn.bind(this)">
              <mat-option *ngFor="let country of filteredCountriesReportingControl" [value]="country.value">
                {{country.label}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ dgaUserForm.get('countryForReporting')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="mb-3">
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.firstName' | translate}}</mat-label>
            <input matInput formControlName="firstName">
            <mat-error>{{ dgaUserForm.get('firstName')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.lastName' | translate}}</mat-label>
            <input matInput formControlName="lastName">
            <mat-error>{{ dgaUserForm.get('lastName')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.titleFirstName' |
              translate}}</mat-label>
            <input matInput formControlName="titleFirstName">
            <mat-error>{{ dgaUserForm.get('titleFirstName')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.titleLastName' |
              translate}}</mat-label>
            <input matInput formControlName="titleLastName">
            <mat-error>{{ dgaUserForm.get('titleLastName')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.birthDate' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="birthDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error>{{ dgaUserForm.get('birthDate')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="mb-3">
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.street' | translate}}</mat-label>
            <input matInput formControlName="street">
            <mat-error>{{ dgaUserForm.get('street')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.streetNumberNew' |
              translate}}</mat-label>
            <input matInput formControlName="streetNumber">
            <mat-error>{{ dgaUserForm.get('streetNumber')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.zip' | translate}}</mat-label>
            <input matInput formControlName="zip">
            <mat-error>{{ dgaUserForm.get('zip')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.city' | translate}}</mat-label>
            <input matInput formControlName="city">
            <mat-error>{{ dgaUserForm.get('city')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.stairway' | translate}}</mat-label>
            <input matInput formControlName="stairway">
            <mat-error>{{ dgaUserForm.get('stairway')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mb-2 mr-2">
            <mat-label>{{'financing.features.financing-processing.verify-customer.top' | translate}}</mat-label>
            <input matInput formControlName="top">
            <mat-error>{{ dgaUserForm.get('top')?.errors | finProcessFormError }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxflex="row" align="right">
        <button class="mr-2 back-btn" mat-raised-button (click)="toogleManualDgaUserForm()">
          {{'financing.features.financing-processing.verify-customer.back' | translate}}
        </button>
        <button *ngIf="!isAssetCall" color="primary" [disabled]="!dgaUserForm.valid" mat-raised-button id="createDga"
          (click)="createDgaUser()">{{'financing.features.financing-processing.verify-customer.save' | translate}}
        </button>
        <button *ngIf="isAssetCall" color="primary" [disabled]="!dgaUserForm.valid" mat-raised-button id="createDga"
          (click)="manuelCreateDgaUser()">{{'financing.features.financing-processing.verify-customer.save' | translate}}
        </button>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>