import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { UserState } from 'app/modules/auth/data/states';
import { IMasterdataParentDefinition, ISalesPartnerCenter, OrganisationalUnitResponsibilityType } from 'app/modules/masterdata/data';
import { SortOrder } from 'app/modules/shared';
import { Environment } from 'app/modules/shared/util/enums';
import { SubstitutionService } from 'app/modules/user-area/data';
import { AuthorizationService, Role } from 'auth';
import { environment } from 'environment';
import { Observable, OperatorFunction, Subject, combineLatest, debounceTime, iif, of, timer, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfigService, HelperService } from 'shared/util';

import {
    DashboardFilter,
    DashboardFinancingsSortValues,
    DashboardLeaved,
    DashboardSorting,
    DashboardState,
    DashboardTab,
    DashboardType,
    FinancingsService,
    IDashboardFinancing,
    IDashboardSorting,
    IDashboardStateParentDefinition,
    IDashboardTabOptions,
    SalesPartnerCenterFilter,
    TabChanged,
    TabsSynced,
} from './../../../../data';

interface IVisibleDashboard {
    type: DashboardType;
    colorClass: string;
    label: string;
}

interface ISalesPartnerCenterGroups {
    name: string;
    disabled?: boolean;
    salesPartnerCenters: ISalesPartnerCenter[];
    salesPartnerCenterFilter: SalesPartnerCenterFilter;
}
const STATIONARY_BUSINESS = '231C89DD-2676-4129-B1D2-E99B60AB1BC4';

/**
 * Komponente für das Dashboard
 */
@Component({
    selector: 'finprocess-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy, OnInit {

    /**
     * Art des Dashboards nach Rollen
     */
    @Input()
    public dashboardType = DashboardType.Expert;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DashboardType = DashboardType;

    @Output()
    public dashboardTypeChange = new EventEmitter<DashboardType>();

    //#region Template Konstanten

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly Role = Role;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly DashboardTab = DashboardTab;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public readonly SalesPartnerCenterFilter = SalesPartnerCenterFilter;

    //#endregion

    /**
     * Aktuelle Tabeinstellungen als Observable
     */
    
    public currentTabSettings$!: Observable<IDashboardTabOptions>;

    public temporaryUserTabs$!: Observable<IDashboardTabOptions[]>;

    @Select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters)
    public salesPartnerCenters$!: Observable<ISalesPartnerCenter[]>;

    /**
     * Selektierter Tabindex als Observable
     */
    public selectedTabIndex$!: Observable<number>;

    /** Repräsentiert die ausgewählten zu filternden Regionen */
    public selectedSalesPartnerCenter: Array<string | SalesPartnerCenterFilter> = [];

    /** Zum anzeigen des Regionsfilters */
    public salesPartnerCenterGroups: ISalesPartnerCenterGroups[];

    /** für schnelleren Zugriff */
    private mobileSalesPartnerCentersIds: string[] = [];

    public visibleDashboards: IVisibleDashboard[] = [];

    private onDestroy$ = new Subject<void>();

    /**
     * Ladezustand der Kindkomponenten
     */
    public loading = true;

    /**
     * Aktuelle Filtermöglichkeiten als Observable
     */
    public currentFilterOptions$!: Observable<DashboardFilter[]>;

    public currentSorting: IDashboardSorting[] = [];

    /**
     * Aktuelle Sortiermöglichkeiten
     */
    public sortOptions: Array<IDashboardSorting> = [];

    /**
     * Subject emittet, wenn Regionsfilter sich ändert
     */
    private regionFilterSubject = new Subject<string>();

    /**
     * Subject emittet, wenn Suche sich ändert
     */
    private searchSubject = new Subject<void>();

    /**
     * Überprüft ob das aktuelle System die Liveumgebung ist
     *
     * @returns {boolean} Handelt es sich um eine Testumgebung
     */
    public get isLiveEnvironment(): boolean {
        return environment.environment >= Environment.Demo;
    }

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {FinancingsService} financingsService FinancingsService-Injektor
     * @param {ConfigService} configService ConfigService-Injektor
     * @param {NotificationService} notificationService notificationService-Injektor
     * @param {TranslateService} translateService TranslateService
     * @param {WaiterService} waiterService WaiterService
     * @param {Router} router Router-Injektor
     * @param {SubstitutionService} substitutionService SubstitutionService-Injektor
     * @param {EnumTranslationService} enumTranslationService EnumTranslationService-Injektor
     */
    public constructor(
        private store: Store,
        private authorizationService: AuthorizationService,
        private financingsService: FinancingsService,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private waiterService: WaiterService,
        private router: Router,
        private substitutionService: SubstitutionService,
        private enumTranslationService: EnumTranslationService,
    ) {
        const salesPartnerCenterFilters = HelperService.getEnumArray(SalesPartnerCenterFilter, true) as SalesPartnerCenterFilter[];

        this.salesPartnerCenterGroups = salesPartnerCenterFilters.reduce<ISalesPartnerCenterGroups[]>((groups, spcFilter) => {
            if (spcFilter !== SalesPartnerCenterFilter.None) {
                return [...groups, {
                    name: this.enumTranslationService.instant(`SalesPartnerCenterFilter.${spcFilter}`) as string,
                    salesPartnerCenters: [],
                    salesPartnerCenterFilter: spcFilter,
                }];
            }
            else {
                return groups;
            }
        }, []);
    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        combineLatest([
            this.salesPartnerCenters$,
            this.store.selectOnce((it: IDashboardStateParentDefinition) => it.dashboard.dashboards[this.dashboardType]),
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([salesPartnerCenters, dashboard]) => ({
                    salesPartnerCenters: salesPartnerCenters
                        .filter(center => center.responsibility === OrganisationalUnitResponsibilityType.PrivateCustomers && center.id.toUpperCase() !== STATIONARY_BUSINESS),
                    dashboard,
                })),
            )
            .subscribe(({ salesPartnerCenters, dashboard }) => {
                const salesPartnerCenterGroup = this.salesPartnerCenterGroups.find(group => group.salesPartnerCenterFilter === SalesPartnerCenterFilter.MobilePF);
                if (!!salesPartnerCenterGroup) {
                    salesPartnerCenterGroup.salesPartnerCenters = salesPartnerCenters;
                    this.mobileSalesPartnerCentersIds = salesPartnerCenters.map(({ id }) => id);
                }

                const selectedIds = dashboard.salesPartnerCenterIds;
                const selectedSPC: SalesPartnerCenterFilter[] = [];

                const salesPartnerCenterFilters = HelperService.getEnumArray(SalesPartnerCenterFilter, true) as SalesPartnerCenterFilter[];

                for (const f of salesPartnerCenterFilters) {
                    if (f !== SalesPartnerCenterFilter.None && HelperService.hasBit(dashboard.salesPartnerCenterFilter, f)) {

                        // Bei Mobile nur wenn alle angehakt sind
                        if (f !== SalesPartnerCenterFilter.MobilePF || selectedIds.length === this.mobileSalesPartnerCentersIds.length) {
                            selectedSPC.push(f);
                        }
                    }
                }

                this.selectedSalesPartnerCenter = [...selectedIds, ...selectedSPC];
            });
        
        this.currentTabSettings$ = this.store.select(DashboardState.tabSettings).pipe(
            map(settingsFn => settingsFn(this.dashboardType)),
            filter(settings => settings !== undefined) as OperatorFunction<IDashboardTabOptions | undefined, IDashboardTabOptions>,
        );

        this.temporaryUserTabs$ = this.store.select(DashboardState.temporaryUserTabs).pipe(
            map(settingsFn => settingsFn(this.dashboardType)),
            distinctUntilChanged((prev, current) => Array.isArray(prev) && Array.isArray(current) && prev.length === current.length && current.every((element, index) => element.tabId === prev[index]?.tabId)),
        );

        this.regionFilterSubject.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(this.configService.getEnvironment().dashboardConfig.searchDelay), // Delay wann der Filter angewendet wird, um nicht bei jedem klick den Filter auszulösen
        ).subscribe(tabId => {
            this.prepareDataCallService(tabId);
        });

        this.selectedTabIndex$ = this.authorizationService.hasRole$(Role.FinancingMapsGlobalReader).pipe(
            mergeMap(hasRight => this.store.select((it: IDashboardStateParentDefinition) => it.dashboard.dashboards[this.dashboardType]).pipe(
                map(dashboardState => {
                    let index = 0;
                    for (const tab of dashboardState.tabState) {
                        if (!hasRight && tab.tabId === DashboardTab.All) {
                            continue;
                        }

                        if (dashboardState.currentTab === tab.tabId) {
                            break;
                        }

                        index++;
                    }

                    return index;
                }),
            )),
        );

        this.currentFilterOptions$ = combineLatest([
            this.store.select((it: IDashboardStateParentDefinition) => it.dashboard.dashboards[this.dashboardType].currentTab),
            this.store.select((it: IDashboardStateParentDefinition) => it.dashboard.currentDashboard),
        ]).pipe(
            mergeMap(([currentTab, currentDashboard]) => combineLatest([
                this.authorizationService.hasRole$(Role.FilterAutomaticallyRejected).pipe(take(1)),
                this.authorizationService.hasRole$(Role.FilterAccounting).pipe(take(1)),
            ]).pipe(
                map(([hasFilterAutomaticallyRejected, hasFilterAccounting]) => {
                    let result = [
                        DashboardFilter.None,
                        DashboardFilter.Accounting,
                        DashboardFilter.StatusCanceled,
                        DashboardFilter.ReadyForReferee,
                    ];

                    if (currentDashboard === DashboardType.Expert || currentDashboard === DashboardType.Approver) {
                        result = result.concat([
                            DashboardFilter.StatusOpen,
                            DashboardFilter.Editing,
                            DashboardFilter.StatusSampleCalculationRejected,
                            DashboardFilter.StatusSampleCalculationWaitingForAcception,
                            DashboardFilter.StatusSampleCalculationAccepted,
                            DashboardFilter.StatusCompleted,
                            DashboardFilter.StatusRejected,
                            DashboardFilter.StatusRejectedByResponsibility,
                            DashboardFilter.StatusAutomaticallyRejected,
                            DashboardFilter.Finalize,
                            DashboardFilter.StatusEsisWaitingForAcception,
                        ]);
                    } else if (currentDashboard === DashboardType.Referent) {
                        result = result.concat([
                            DashboardFilter.Finalize,
                            DashboardFilter.StatusEsisWaitingForAcception,
                            DashboardFilter.StatusEsisRejected,
                            DashboardFilter.StatusCompleted,
                        ]);
                    }

                    if (currentTab !== DashboardTab.All) {
                        result = result.filter(it => ![
                            DashboardFilter.StatusOpen,
                            DashboardFilter.StatusAutomaticallyRejected,
                            DashboardFilter.Accounting,
                            DashboardFilter.ReadyForReferee,
                        ].some(exclude => it === exclude))
                    }
                    else {
                        result = result.filter(it =>
                            (hasFilterAutomaticallyRejected || it !== DashboardFilter.StatusAutomaticallyRejected) &&
                            (hasFilterAccounting || it !== DashboardFilter.Accounting));
                    }

                    return result;
                }),
            )),
        );

        combineLatest([
            this.store.select((it: IDashboardStateParentDefinition) => it.dashboard.currentDashboard).pipe(
                filter((currentDashboard): currentDashboard is DashboardType => currentDashboard !== undefined),
            ),
            this.store.select(DashboardState.currentSorting),
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(([currentDashboard, currentSorting]) => currentSorting(currentDashboard)),
        ).subscribe(sorting => { this.currentSorting = sorting;});

        const currentUser = this.store.selectSnapshot(UserState.user);

        if (this.authorizationService.hasRole(Role.Expert)) {
            this.visibleDashboards.push({type: DashboardType.Expert, label: this.enumTranslationService.instant(`Role.${Role.Expert}`) as string, colorClass: 'color-primary-background'});
        }
        if (this.authorizationService.hasRole(Role.Referent)) {
            this.visibleDashboards.push({type: DashboardType.Referent, label: this.enumTranslationService.instant(`Role.${Role.Referent}`) as string, colorClass: 'color-referent-background'});
        }
        if (this.authorizationService.hasRole(Role.Approver)) {
            this.visibleDashboards.push({type: DashboardType.Approver, label: this.enumTranslationService.instant(`Role.${Role.Approver}`) as string, colorClass: 'color-approver-background'});
        }

        if (this.authorizationService.hasRole(Role.FinancingMapsTemporaryEditor)) {
            zip([this.store.select(DashboardState.temporaryUserTabs), this.substitutionService.getAssignedAsTemporaryEditorList()]).pipe(take(1))
                .subscribe(([temporaryTabsFn, temporaryUsers]) => {
                    if (temporaryUsers === undefined) {
                        return;
                    }
    
                    const temporaryTabs = temporaryTabsFn(this.dashboardType);
    
                    const filteredUsers = temporaryUsers.filter(temporaryUser => !temporaryUser.differentTemporaryUser || temporaryUser.differentTemporaryUser.id === currentUser?.id);
    
                    const removedUsers = temporaryTabs.filter(tab => filteredUsers.every(temporaryUser => tab.tabId !== temporaryUser.currentUser.id));
    
                    if (removedUsers.length > 0) {
                        this.notificationService.dialog(
                            this.translateService.instant('dashboard.features.dashboard.endSelectedSubstitution'),
                            this.translateService.instant(`${removedUsers.map(user => user.name).join('<br>')}<br>Sie werden nun an Ihr Dashboard weiter geleitet`),
                            [
                                { label: this.translateService.instant('OK'), role: 'end' },
    
                            ],
                            {
                                disableClose: true,
                                autoFocus: true,
                            },
                        )
                    }
    
                    this.store.dispatch(new TabsSynced(filteredUsers.map(temporaryUser => temporaryUser.currentUser)));
                },
                );
            this.substitutionService.getAssignedAsTemporaryEditorForOwnFinancings()
                .pipe(
                    mergeMap(editors => {
                        if (Array.isArray(editors) && editors.length > 0) {
                            return this.notificationService.dialog(
                                this.translateService.instant('dashboard.features.dashboard.titleWelcomeBack'),
                                this.translateService.instant('dashboard.features.dashboard.substitutionName', { i: editors[0].differentTemporaryUser?.firstName, j: editors[0].differentTemporaryUser?.lastName }),
                                [
                                    { label: this.translateService.instant('dashboard.features.dashboard.labelEnd'), role: 'end' },
    
                                ],
                                {
                                    disableClose: true,
                                    autoFocus: true,
                                },
                            )
    
                        }
    
                        return of(void 0);
                    }),
                    mergeMap(dialogResult => {
                        if (!!currentUser?.id && dialogResult === 'end') {
                            return this.substitutionService.removeTemporaryEditor(currentUser.id).pipe(
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                mergeMap(result => this.notificationService.toast(this.translateService.instant('dashboard.features.dashboard.substitutionToast'))),
                            );
                        }
                        return of(void 0);
                    }),
                )
                .subscribe();
        }

        this.sortOptions = [
            { value: DashboardFinancingsSortValues.SubmissionDate, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.SubmissionDate, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.FinancingStatus, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.FinancingStatus, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.SystemNumber, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.SystemNumber, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.FirstDebitorFirstname, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.FirstDebitorFirstname, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.FirstDebitorLastname, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.FirstDebitorLastname, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.SecondDebitorFirstname, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.SecondDebitorFirstname, order: SortOrder.Descending },
            { value: DashboardFinancingsSortValues.SecondDebitorLastname, order: SortOrder.Ascending },
            { value: DashboardFinancingsSortValues.SecondDebitorLastname, order: SortOrder.Descending },
        ];
    }

    /**
     * Angular-Hook beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.store.dispatch(new DashboardLeaved());
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Tab wird selektiert
     *
     * @param {MatTabChangeEvent} event Event, wenn sich ausgewählter Tab ändert
     */
    public tabChanged(event: MatTabChangeEvent): void {
        this.store.dispatch(new TabChanged({dashboardType: this.dashboardType, tabId: event.tab.textLabel}));
    }

    /**
     * Eventhandler wenn zwischen verschiedenen Dashboards gewechselt wird. Lädt die Finanzierungsliste neu
     * 
     * @param {MatButtonToggleChange} event Toggle Button Event
     */
    public dashboardChanged(event: MatButtonToggleChange): void {
        this.dashboardType = event.value;
        this.dashboardTypeChange.emit(event.value);
        this.financingsService.changeDashboardType(event.value, () => {this.loading = true}).subscribe({
            next: () => {this.loading = false;},
        });
    }

    /**
     * Fordert einen neuen Fall zur Bearbeitung an und öffnet diesen
     */
    public assignMe(): void {
        const request = this.dashboardType === DashboardType.Expert ? (() => this.financingsService.requestNewExpertFinancing()) : (() => this.financingsService.requestNewReferentFinancing());
        
        this.waiterService.show().pipe(mergeMap(() =>
            request().pipe(
                mergeMap(financing => iif(
                    () => financing !== undefined,
                    this.notificationService.confirm(
                        this.translateService.instant('dashboard.features.dashboard.requestAssignedTitle'),
                        this.translateService.instant('dashboard.features.dashboard.requestAssignedText', { systemNumber: (financing as IDashboardFinancing).systemNumber }),
                        this.translateService.instant('dashboard.features.dashboard.requestAssignedOpen'),
                        this.translateService.instant('dashboard.features.dashboard.requestAssignedNotOpen'),
                    ).pipe(
                        mergeMap(role => iif(
                            () => role === 'submit',
                            of(void 0).pipe(tap(
                                async () => {
                                    await this.router.navigateByUrl(`/financing/${(financing as IDashboardFinancing).id}/borrower-check-customer-data/${(financing as IDashboardFinancing).id}`);
                                },
                            )),
                            this.currentTabSettings$.pipe(
                                take(1),
                                mergeMap(currentTabSettings => this.financingsService.loadFinancings(this.dashboardType, currentTabSettings.tabId, undefined, () => {
                                    this.loading = true;
                                }).pipe(tap(
                                    () => {
                                        this.loading = false
                                    },
                                ))),
                            ),

                        )),
                    ),
                    of(void 0),
                )),
            ),
        )).subscribe({
            next: () => { this.waiterService.hide(); },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translateService.instant('dashboard.features.dashboard.noRequestAssignedTitle'), this.translateService.instant('dashboard.features.dashboard.noRequestAssignedText'));
            },
        });

    }

    /**
     * Aus Dashboard vertretung enden
     *
     * @param {string} id vetretenden id
     */
    public removeSubstitution(id: string): void {
        this.notificationService.confirmYesNo(this.translateService.instant('general.warning'), this.translateService.instant('dashboard.features.dashboard.shouldSubstituteEnd'))
            .pipe(
                tap(() => this.waiterService.show()),
                mergeMap(result => {
                    if (result === 'submit') {
                        return this.substitutionService.removeTemporaryEditor(id);
                    }

                    return of(void 0);
                }),
            ).subscribe({
                next: () => this.waiterService.hide(),
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translateService.instant('general.error'), this.translateService.instant('dashboard.features.dashboard.endSubstitutionError'));
                },
            })
    }

    /**
     * Führt den Filter aus
     *
     * @param {string} tabId Tab
     * @param {MatSelectChange} matSelectChange Selekt-Event
     */
    public filterStatus(tabId: string, matSelectChange: MatSelectChange): void {
        this.financingsService.filterStatus(this.dashboardType, tabId, matSelectChange.value as DashboardFilter, () => {
            this.loading = true;
        }).subscribe(() => {
            this.loading = false;
        });
    }

    /**
     * Führt die Sortierung aus
     * 
     * @param {string } tabId Tab
     * @param {IDashboardSorting[]} currentSorting Aktuelle Sortierung
     * @param {IDashboardSorting[]} newSorting Neuer Wert für die Sortierung
     */
    public updateSorting(tabId: string, currentSorting: IDashboardSorting[], newSorting: IDashboardSorting[]): void {
        const sorting: DashboardSorting = {};
        
        for (const selectedSorting of newSorting) {
            // Wenn die Sortierung bereits vorhanden ist und die Reihenfolge geändert wurde, wird die Sortierung nicht übernommen
            if (newSorting.some(sortValue => sortValue.value === selectedSorting.value && sortValue.order !== selectedSorting.order)
                && currentSorting.some(sortValue => sortValue.value === selectedSorting.value && sortValue.order === selectedSorting.order)) {
                continue;
            }
            sorting[selectedSorting.value] = selectedSorting.order;
        }

        this.financingsService.sort(this.dashboardType, tabId, sorting, () => {
            this.loading = true;
        }).subscribe(() => {
            this.loading = false;
        });
    }

    /**
     * Führt den Filter aus
     *
     * @param {string} tabId Tab
     * @param {MatOption} matOption matOption
     */
    public selectOption(tabId: string, matOption: MatOption): void {
        this.updateUI(matOption);
        this.regionFilterSubject.next(tabId);
    }

    /**
     * Vorbereitet Daten, rüft service an
     *
     * @param {string} tabId TabId
     */
    private prepareDataCallService(tabId: string): void {
        const salesPartnerFilters: SalesPartnerCenterFilter[] = [];
        const mobileSalesPartnersIds: string[] = [];

        this.selectedSalesPartnerCenter.forEach(val => {
            if (typeof val === 'string') {
                mobileSalesPartnersIds.push(val);
            }
            else {
                salesPartnerFilters.push(val);
            }
        });

        //if it has min one mobileSalesPartnersIds, include SalesPartnerCenterFilter.MobilePF in array and send to server
        if (mobileSalesPartnersIds.length > 0 && !salesPartnerFilters.includes(SalesPartnerCenterFilter.MobilePF)) {
            salesPartnerFilters.push(SalesPartnerCenterFilter.MobilePF)
        }

        const salesPartnerFilterFlags = HelperService.convertArrayToFlag(salesPartnerFilters) ?? SalesPartnerCenterFilter.None;

        this.financingsService.filterSalesPartnerCenter(
            this.dashboardType, 
            tabId,
            salesPartnerFilterFlags,
            mobileSalesPartnersIds,
            () => {
                this.loading = true;
            },
        )
            .subscribe(() => {
                this.loading = false;
            });
    }

    /**
     * selektiert oder deselektiert eine Alles-Auswahl im Regionsfilter
     *
     * @param {MatOption} matOption matOption
     */
    private updateUI(matOption: MatOption): void {
        // wenn auf UI, die Option Mobile Regionen "Alles auswählen" selektiert ist, müssen alle mobileSalesPartnerCenters auch selektiert werden
        if (matOption.selected && matOption.value === SalesPartnerCenterFilter.MobilePF) {
            const selected = this.selectedSalesPartnerCenter.concat(this.mobileSalesPartnerCentersIds);
            //clear duplicates
            this.selectedSalesPartnerCenter = [...new Set(selected)]
        }
        // wenn auf UI, die Option Mobile Regionen "Alles auswählen" unselektiert ist, müssen alle mobileSalesPartnerCenters auch unselektiert werden
        else if (!matOption.selected && matOption.value === SalesPartnerCenterFilter.MobilePF) {
            this.selectedSalesPartnerCenter = this.selectedSalesPartnerCenter.filter(id => (typeof id) !== 'string')
        }
        // wenn alle Mobile regionen auf UI ausgewählt sind und einen wird unselected, muss auch group "Alles auswählen" unselektiert werden
        else if (!matOption.selected && this.mobileSalesPartnerCentersIds.includes(matOption.value)) {
            this.selectedSalesPartnerCenter = this.selectedSalesPartnerCenter.filter(id => id !== SalesPartnerCenterFilter.MobilePF)
        }
    }

    /**
     * Führt die Suche nach einem festgelegten Delay aus
     *
     * @param {string} tabId Tab
     * @param {string} search Volltextsuche
     */
    public delaySearch(tabId: string, search: string): void {
        this.searchSubject.next();
        timer(this.configService.getEnvironment().dashboardConfig.searchDelay).pipe(
            takeUntil(this.searchSubject),
            mergeMap(() => this.financingsService.search(this.dashboardType, tabId, search, () => {
                this.loading = true;
            })),
        ).subscribe(() => {
            this.loading = false;
        });
    }

    /**
     * Zur Api Testseite
     *
     * @returns {Promise<void>} Promise
     */
    public async toApiTestPage(): Promise<void> {
        await this.router.navigate(['..', 'workflow', '11111111-1111-1111-1111-111111111111']);
    }

    /**
     * Vergleichsfunktion für Dashboard Sortierungen
     * 
     * @param {IDashboardSorting} a Erste Sortierung
     * @param {IDashboardSorting} b Zweite Sortierung
     * @returns {boolean} Sind die Sortierungen gleich
     */
    public compareSortings(a: IDashboardSorting, b: IDashboardSorting): boolean {
        return a.value === b.value && a.order === b.order;
    }
}
