import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { OrganisationalUnitResponsibilityType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { NtagReleaseNoteService } from '@ntag-ef/release-notes';
import { UserService } from 'app/modules/auth/data';
import { IMasterdataParentDefinition } from 'app/modules/masterdata/data';
import { ReleaseNotesService } from 'app/modules/navigation/data/services';
import { WindowsService } from 'app/modules/shared/services/windows.service';
import { AuthenticationService, IExtendedRoutingData, LoginStateType, Role } from 'auth';
import { environment } from 'environment';
import { APP_INFO } from 'environment/app-info';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';


/**
 * Komponente für die Navigation
 */
@Component({
    selector: 'finprocess-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnDestroy, OnInit {

    /**
     * Schlüssel für die Übersetzung der Standardüberschrift
     */
    public titleKey = 'navigation.app';

    /**
     * Für Template-Verwendung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public LoginStateType = LoginStateType

    /**
     * Für Template-Verwendung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Login-Status als Observable
     *
     * @returns {Observable<LoginStateType>} Login-Status als Observable
     */
    public get loginState$(): Observable<LoginStateType> {
        return this.authenticationService.loginState$;
    }

    public showLoadBalancing = false;

    /**
     * Referenz zur rechten Navigationsleiste
     */
    @ViewChild(MatSidenav)
    public sidenav?: MatSidenav;

    /**
     * Subject beim Entfernen der Komponente
     */
    private onDestroy$ = new Subject<void>();

    public hideNavigation = false;

    /**
     * Username + email in UI
     */
    public saluteUser: string | undefined;

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {Router} router Router-Injektor
     * @param {TranslateService} translateService TranslateService-Injektor
     * @param {UserService} userService userService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {Store} store store-Injektor
     * @param {NtagReleaseNoteService} ntagReleaseNotesService ntagReleaseNoteService-Injektor
     * @param {ReleaseNotesService} releaseNotesService releaseNotesService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef-Injektor
     * @param {DatePipe} date DatePipe-Injektor
     * @param {WindowsService} windowsService windowsService-Injektor
     */
    public constructor(
        private authenticationService: AuthenticationService,
        public router: Router,
        private translateService: TranslateService,
        private userService: UserService,
        private notification: NotificationService,
        private store: Store,
        private ntagReleaseNotesService: NtagReleaseNoteService,
        private releaseNotesService: ReleaseNotesService,
        private cRef: ChangeDetectorRef,
        private date: DatePipe,
        private windowsService: WindowsService,
    ) { }

    /**
     * Angular-Hook Entfernen der Direktive
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Angular-Hook Initialisieren der Direktive
     */
    public ngOnInit(): void {
        combineLatest([this.store.select((it: IMasterdataParentDefinition) => it.masterdata.salesPartnerCenters), this.userService.user$]).subscribe(([salesPartnerCenters, user]) => {
            if (!!user?.salesPartnerCenterId) {
                this.showLoadBalancing = user?.salesPartnerCenterId.map(id => salesPartnerCenters.find(center => center.id === id)).some(center => center?.id !== '231c89dd-2676-4129-b1d2-e99b60ab1bc4' && center?.responsibility === OrganisationalUnitResponsibilityType.PrivateCustomers)
            }
            this.saluteUser = user?.firstName !== undefined ? `Hallo, ${user?.firstName} ${user?.lastName} (${user?.email})` : undefined;
        });

        this.router.events.pipe(
            takeUntil(this.onDestroy$),
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            tap(e => {
                let newKeys = e.urlAfterRedirects.substring(1).split('?')[0].split('/');
                newKeys = newKeys.filter(it => !new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/).test(it));
                newKeys = newKeys.filter(it => !new RegExp(/^\d+$/).test(it));
                // newKeys = newKeys.filter(It => !new RegExp)
                this.titleKey = `navigation.${newKeys.join('.')}`;
                if (typeof (this.translateService.instant(this.titleKey)) === 'object') {
                    this.titleKey = `${this.titleKey}.title`;
                }

                this.cRef.detectChanges();
            }),
        ).subscribe();

        //workaround ActivatedRoute Data gibt ein leeres Objekt zurück
        //https://stackoverflow.com/questions/45737375/angular-activatedroute-data-returns-an-empty-object
        this.router.events.subscribe(data => {
            if (data instanceof RoutesRecognized) {
                this.hideNavigation = (data?.state?.root?.firstChild?.firstChild?.data as IExtendedRoutingData)?.hideNavigation ?? false;
            }
        });
    }

    /**
     * Listener auf Window-Resize
     */
    @HostListener('window:resize')
    public async onResize(): Promise<void> {
        if (this.sidenav !== undefined) {
            await this.sidenav.close();
        }
    }

    /**
     * Loggt den aktuellen Nutzer aus
     */
    public logout(): void {
        this.authenticationService.logout();
    }

    /**
     * Navigiert zum Administrationsbereich
     *
     * @returns {Promise} Router Promise
     */
    public enterAdmin(): Promise<boolean> {
        return this.router.navigate(['/administration']);
    }

    /**
     * Navigiert zum Nutzerbereich
     *
     * @returns {Promise} Router Promise
     */
    public enterUserArea(): Promise<boolean> {
        return this.router.navigate(['/user-area']);
    }

    /**
     * Navigiert zum Lastenausgleich
     *
     * @returns {Promise} Router Promise
     */
    public enterExternalBalancing(): Promise<boolean> {
        return this.router.navigate(['/load-balancing']);
    }

    /**
     * Navigiert zur Startseite
     *
     * @returns {Promise} Router Promise
     */
    public toStart(): Promise<boolean> {
        return this.router.navigate(['/']);
    }

    /**
     * Zeigt die aktuelle Version in einem Dialog an
     */
    public showVersion(): void {
        let version = `<b>${this.translateService.instant('navigation.version')}:</b> ${APP_INFO.version}`;
        const externalSystems = this.store.selectSnapshot((it: IMasterdataParentDefinition) => it.systemConfiguration.externalSystemsAvailability);

        if (environment.showDebugInfo) {
            version += `<br><b>${this.translateService.instant('general.buildDate')}:</b> ${this.date.transform(APP_INFO.buildDate, 'dd.MM.yyyy HH:mm')}`
        }

        version += `<br><br><b>${this.translateService.instant('general.externalSystems')}</b>`
        version += `<br><b>${this.translateService.instant('general.assignAdvisor')}</b>: ${externalSystems.assignAdvisorIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.colt')}</b>: ${externalSystems.coltIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.customerProfile')}</b>: ${externalSystems.customerProfileIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.decision')}</b>: ${externalSystems.decisionIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.pricing')}</b>: ${externalSystems.pricingIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.riskManagement')}</b>: ${externalSystems.riskManagementIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`
        version += `<br><b>${this.translateService.instant('general.scoring')}</b>: ${externalSystems.scoringIsAvailable ? this.translateService.instant('general.yes') : this.translateService.instant('general.no')}`

        this.notification.alert(
            this.translateService.instant('navigation.app'),
            version,
        );
    }
    /**
     * Zeigt die Versionshistorie in einem Dialog an
     */
    public showReleaseNotes(): void {
        this.releaseNotesService.getAllReleaseNotes().subscribe(releaseNotes => {
            if (releaseNotes) {
                this.ntagReleaseNotesService.showReleases(releaseNotes)
            } else {
                throw new Error('no release notes available')
            }
        });
    }

    /**
     * Open Caluclator
     */
    public openCalculator(): void {
        this.windowsService.openWindow('rateCalculationWindow', 'calculator', 'location=yes,height=850,width=700,scrollbars=yes,status=yes')
    }

    /**
     * Open Caluclator
     */
    public openTimeCalculator(): void {
        this.windowsService.openWindow('remainingTimeCalculation', 'calculator/remaining-time-calculation', 'location=yes,height=850,width=600,scrollbars=yes,status=yes')
    }
}
