import { TranslateModuleConfig } from '@ngx-translate/core';

/**
 * Konfigurationsobjekt für Enum-Übersetzungsmodul
 */
export interface IEnumTranslateModuleConfig extends TranslateModuleConfig {
    /**
     * Objekt mit benutzerdefinierten Enums
     */
    customEnums?: Record<string, unknown>;
}
