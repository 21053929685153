import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';

import { ConfigService } from '../config/config.service';

/**
 * Service zum Vorabladen
 */
@Injectable()
export class PreLoadEnumTranslationLoaderService extends TranslateLoader {

    /**
     * Übsetzungscache
     */
    private static translations: { [index: string]: Record<string, unknown> } = {};

    /**
     * Konstruktor
     *
     * @param {HttpClient} http Http-Client
     * @param {ConfigService} configService Konfigurationsservice
     * @param {NGXLogger} logger Logger
     */
    public constructor(private http: HttpClient, private configService: ConfigService, private logger: NGXLogger) {
        super();
    }

    /**
     * Cached alle unterstützen Sprachen
     *
     * @param {string | undefined} path Optionaler Pfad zu den Übersetzungsdateien
     * @returns {Observable<void>} Observable aller unterstützen Sprachen
     */
    public cache(path?: string): Promise<undefined[]> {
        return Promise.all(
            this.configService.getEnvironment().supportedLanguages.map(async language => {
                try {
                    const translation = await this.http.get(`${path ?? './assets/i18n/'}${language}.json`).toPromise();

                    if (PreLoadEnumTranslationLoaderService.translations[language] === undefined) {
                        PreLoadEnumTranslationLoaderService.translations[language] = translation as Record<string, unknown>;
                    }
                    else {
                        PreLoadEnumTranslationLoaderService.translations[language] = Object.assign(
                            PreLoadEnumTranslationLoaderService.translations[language],
                            translation,
                        )
                    }

                    return void 0;
                } catch (error) {
                    if (PreLoadEnumTranslationLoaderService.translations[language] === undefined) {
                        PreLoadEnumTranslationLoaderService.translations[language] = {};
                    }
                    this.logger.fatal(error);
                    return void 0;
                }
            }),
        )
    }

    /**
     * Gibt die angefragte Übersetzung als Observable zurück
     *
     * @param {string} lang Sprache
     * @returns {Record<string, any>} Übersetzung als Observable
     */
    // eslint-disable-next-line class-methods-use-this
    public getTranslation(lang: string): Observable<Record<string, unknown>> {
        if (lang in PreLoadEnumTranslationLoaderService.translations) {
            return of(PreLoadEnumTranslationLoaderService.translations[lang]);
        }
        else if (lang.includes('-') && (lang.substr(0, 2) in PreLoadEnumTranslationLoaderService.translations)) {
            return of(PreLoadEnumTranslationLoaderService.translations[lang.substr(0, 2)]);
        }
        else {
            return of({});
        }
    }
}
