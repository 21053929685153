import { IFinancingConfiguration } from './../../interfaces/financing-configuration.interface';

/**
 * Aktion beim erfolgreichen Laden der Finanzierungskonfiguration
 */
export class FinancingConfigurationLoaded {
    public static readonly type = 'financingConfiguration.loaded';

    /**
     * Konstruktor
     *
     * @param {IFinancingConfiguration} payload Finanzierungskonfiguration
     */
    public constructor(public payload: IFinancingConfiguration) { }
}

/**
 * Aktion beim Ändern der Finanzierungskonfiguration
 */
export class FinancingConfigurationChanged {
    public static readonly type = 'financingConfiguration.changed';

    /**
     * Konstruktor
     *
     * @param {IFinancingConfiguration} payload Finanzierungskonfiguration
     */
    public constructor(public payload: IFinancingConfiguration) { }
}
