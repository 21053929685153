<div class="container" fxFill fxLayout="column" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="header p-3">
        <h1 class="color-primary">{{ 'navigation.administration.systemconfig' | translate }}</h1>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button
                mat-mini-fab
                color="primary"
                (click)="toggleLock()"
                *finprocessAuthorization="Role.RiskParameterizationAdministrator"
                matTooltip="{{
                    (locked ? 'financing.features.financing-processing.unlockEditMode' : 'financing.features.financing-processing.lockEditMode') | translate
                }}">
                <mat-icon *ngIf="locked">lock</mat-icon>
                <mat-icon *ngIf="!locked">lock_open</mat-icon>
            </button>
            <button
                *finprocessAuthorization="Role.RiskParameterizationAdministrator"
                mat-mini-fab
                color="primary"
                (click)="save()"
                [disabled]="form.pristine || locked || hasAnyError(form)"
                matTooltip="{{ 'button.save' | translate }}">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>
    <div class="content p-3">
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.defaultReferenceInterestRate' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="defaultReferenceInterestRate" matInput />
                    <mat-error>{{ form.get('defaultReferenceInterestRate')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.additionComparativeCalculation' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="additionComparativeCalculation" matInput />
                    <mat-error>{{ form.get('additionComparativeCalculation')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.defaultAddition' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="defaultAddition" matInput />
                    <mat-error>{{ form.get('defaultAddition')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.bruttoNettoUrl' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input formControlName="bruttoNettoCalculatorUrl" matInput />
                </mat-form-field>
            </div>
        </div>
        <div class="divider p-1 my-2">{{ 'administration.features.systemconfig.calculator' | translate }}</div>
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.maxDebitRate' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="interestRate" matInput />
                    <mat-error>{{ form.get('interestRate')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.systemconfig.maxAmount' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="amountLimit" matInput />
                    <mat-error>{{ form.get('amountLimit')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="divider p-1 my-2">{{ 'administration.features.guidelines-configuration.guidelines-title' | translate }}</div>
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.guidelines-configuration.numberOfDependentChildren' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="baseChildrenAmount" matInput />
                    <mat-error>{{ form.get('baseChildrenAmount')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.guidelines-configuration.numberOfChildrenReceivingSupportPayments' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="baseChildrenObligationAmount" matInput />
                    <mat-error>{{ form.get('baseChildrenObligationAmount')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.guidelines-configuration.numberOfChildrenProvidingSupportPayments' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="baseChildrenRecipientAmount" matInput />
                    <mat-error>{{ form.get('baseChildrenRecipientAmount')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="divider p-1 my-2">{{ 'administration.features.household-calculation-configuration.household-calculations-title' | translate }}</div>
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.safetyMarginExpenses' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="safetyMarginExpenses" matInput />
                    <mat-error>{{ form.get('safetyMarginExpenses')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.safetyMarginExpensesForPension' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="factorOptionsThree" formControlName="safetyMarginExpensesForPension" matInput />
                    <mat-error>{{ form.get('safetyMarginExpensesForPension')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="divider p-1 my-2">
            {{ 'administration.features.household-calculation-configuration.household-calculation-guidelines-title' | translate }}
        </div>
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <!--Risiko und Kundenhaushaltsrechnung-->
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.operationalCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="operationalCostsHHR" matInput />
                    <mat-error>{{ form.get('operationalCostsHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.operationalCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="operationalCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.phoneCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="phoneCostsHHR" matInput />
                    <mat-error>{{ form.get('phoneCostsHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

               <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.phoneCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="phoneCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.electricityCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="electricityCostsHHR" matInput />
                    <mat-error>{{ form.get('electricityCostsHRR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.electricityCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="electricityCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.carCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="carCostsHHR" matInput />
                    <mat-error>{{ form.get('carCostsHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.carCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="carCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.insuranceCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="insuranceCostsHHR" matInput />
                    <mat-error>{{ form.get('insuranceCostsHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.insuranceCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="insuranceCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.otherCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="otherCostsHHR" matInput />
                    <mat-error>{{ form.get('otherCostsHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.otherCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="otherCostsTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsDebitor' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="livingCostsDebitorHHR" matInput />
                    <mat-error>{{ form.get('livingCostsDebitorHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsDebitorText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="livingCostsDebitorTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsOfDependentChildren' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="livingCostsOfDependentChildrenHHR" matInput />
                    <mat-error>{{ form.get('livingCostsOfDependentChildrenHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsOfDependentChildrenText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="livingCostsOfDependentChildrenTextHHR" matInput></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.additionalExpensesForChildren' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="additionalExpensesForChildrenHHR" matInput />
                    <mat-error>{{ form.get('additionalExpensesForChildrenHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.additionalExpensesForChildrenText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="additionalExpensesForChildrenTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{
                    'administration.features.household-calculation-configuration.expensesForAdditionalResidentialpropertiesForPersonalUse' | translate
                }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="expensesForAdditionalResidentialpropertiesForPersonalUseHHR" matInput />
                    <mat-error>{{ form.get('expensesForAdditionalResidentialpropertiesForPersonalUseHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{
                    'administration.features.household-calculation-configuration.expensesForAdditionalResidentialpropertiesForPersonalUseText' | translate
                }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="expensesForAdditionalResidentialpropertiesForPersonalUseTextHHR" matInput></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="divider p-1 my-2">
            {{ 'administration.features.household-calculation-configuration.householdpension-calculation-guidelines-title' | translate }}
        </div>
        <div fxLayout="column" class="p-3">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.operationalCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="operationalCostsPHHR" matInput />
                    <mat-error>{{ form.get('operationalCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.operationalCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="operationalCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.phoneCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="phoneCostsPHHR" matInput />
                    <mat-error>{{ form.get('phoneCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.phoneCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="phoneCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.electricityCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="electricityCostsPHHR" matInput />
                    <mat-error>{{ form.get('electricityCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.electricityCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="electricityCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.carCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="carCostsPHHR" matInput />
                    <mat-error>{{ form.get('carCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.carCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="carCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.insuranceCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="insuranceCostsPHHR" matInput />
                    <mat-error>{{ form.get('insuranceCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.insuranceCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="insuranceCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.otherCosts' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="otherCostsPHHR" matInput />
                    <mat-error>{{ form.get('otherCostsPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.otherCostsText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="otherCostsTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsDebitor' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="livingCostsDebitorHHR" matInput />
                    <mat-error>{{ form.get('livingCostsDebitorHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsDebitorText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="livingCostsDebitorTextHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsOfDependentChildren' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="livingCostsOfDependentChildrenPHHR" matInput />
                    <mat-error>{{ form.get('livingCostsOfDependentChildrenPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.livingCostsOfDependentChildrenText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="livingCostsOfDependentChildrenTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.additionalExpensesForChildren' | translate }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input currencyMask [options]="currencyOptions" formControlName="additionalExpensesForChildrenPHHR" matInput />
                    <mat-error>{{ form.get('additionalExpensesForChildrenPHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{ 'administration.features.household-calculation-configuration.additionalExpensesForChildrenText' | translate }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="additionalExpensesForChildrenTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <span fxFlex="20">{{
                    'administration.features.household-calculation-configuration.expensesForAdditionalResidentialpropertiesForPersonalUse' | translate
                }}</span>
                <mat-form-field appearance="fill" fxFlex="20">
                    <input
                        currencyMask
                        [options]="currencyOptions"
                        formControlName="expensesForAdditionalResidentialpropertiesForPersonalUsePHHR"
                        matInput />
                    <mat-error>{{ form.get('expensesForAdditionalResidentialpropertiesForPersonalUsePHHR')?.errors | finProcessFormError }}</mat-error>
                </mat-form-field>

                <span fxFlex="20">{{
                    'administration.features.household-calculation-configuration.expensesForAdditionalResidentialpropertiesForPersonalUseText' | translate
                }}</span>
                <mat-form-field appearance="fill">
                    <textarea formControlName="expensesForAdditionalResidentialpropertiesForPersonalUseTextPHHR" matInput></textarea>
                </mat-form-field>
            </div>

            <div class="divider p-1 my-2">{{ 'navigation.administration.user-agreement' | translate }}</div>
            <div class="p-3">
                <mat-form-field appearance="fill" class="user-agreement-area">
                    <textarea formControlName="userAgreement" matInput rows="30" cols="40"> </textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
