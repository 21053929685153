import { AAGUID } from '../../types/aaguid';

export interface ISmartDocUploadedFileIdsLoadedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Ids von Dateien, die bereits an SmartDoc gesendet wurden
     */
    ids: AAGUID[];
}

/**
 * Klasse für Aktion
 */
export class SmartDocUploadedFileIdsLoaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.SmartDocUploadedFileIdsLoaded';
    /**
     * Constructor
     *
     * @param {ISmartDocUploadedFileIdsLoadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocUploadedFileIdsLoadedPayloadType) { }
}
