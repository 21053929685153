export enum TypeOfShare {
    /**
     * ideelles Eigentum
     * wenn EZ-Typ "Superädifikat" nur ideelles Eigentum
     * wenn "Neu zu gründende EZ?" oder "Neu zu gründende BREZ?" -> nur ideelles Eigentum möglich
     */
    IdealProperty,

    /**
     * Wohnungseigentum
     */
    Property,

    /**
     * neu zu begründendes WE
     */
    NewProperty,
}
