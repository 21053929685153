import { AAGUID } from '../../types/aaguid';

/**
 * MapRevoked Action
 */
export class MapRevoked {
    /**
     * key for Action
     */
    public static readonly type = 'smartdoc.mapRevoked';
    /**
     * Constructor fuer MapRevoked
     * 
     * @param {AAGUID} payload AAGUID
     */
    public constructor (public payload: AAGUID) {}
}
