<mat-dialog-content>
    <div fxLayout="column" fxFlexFill>
        <h2 mat-dialog-title class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <span *ngIf="!!branch">{{ 'administration.features.branches.editBranch' | translate }}</span>
            <span *ngIf="!branch">{{ 'administration.features.branches.createBranch' | translate }}</span>
        </h2>
        <div [formGroup]="form" fxLayout="column" fxFlex>
            <mat-form-field>
                <input formControlName="costCentre" placeholder="Kostenstelle" matInput>
            </mat-form-field>
            <mat-form-field>
                <input formControlName="branchCode" placeholder="OE" matInput>
            </mat-form-field>
            <mat-form-field>
                <input formControlName="name" placeholder="Name" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="responsibility"
                    placeholder="{{ 'administration.features.branches.organisationalUnit' | translate }}">
                    <mat-option *ngFor="let orgUnit of organisationalResponsibilityType" [value]="orgUnit.value">
                        {{ orgUnit.displayName }}
                    </mat-option>
                    <mat-option>{{ 'general.noSelection' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox formControlName="status" color="primary">{{ 'checkBox.active' | translate }}</mat-checkbox>
    
        </div>
        <mat-dialog-actions align="center">
            <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid">{{ 'button.save' |
                translate }}</button>
            <button mat-raised-button (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>