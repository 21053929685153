export enum PropertyType {
    /**
     * Liegenschaft(default) (not available if Question "Neu zu gründende BREZ?" is checked)
     */
    Property,

    /**
     * Superädifikat(not available if Question "Neu zu gründende EZ?"
     * or "Neu zu gründende BREZ?"
     * or "Mindereintrag" is checked, not available for BA215)
     */
    Superaddicts,

    /**
     * Baurecht(not available if Question "Neu zu gründende EZ?" or "Mindereintrag" is checked)
     */
    BuildingRights,
}
