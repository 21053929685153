import { WorkflowType } from '../../enums';
import { FormattingType } from '../../enums/fire';
import { IMidtermModel } from '../../interfaces';

export const INTEREST_RATES_FINANCING_REQUEST: IMidtermModel = {

    midtermName: 'interestRatesFinancingRequest',

    /**
     * abweichender Zinssatz B&W Mortgage Linie 1
     * abwzins_bw1
     */
    abwzinsBw1: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz B&W Mortgage Linie 2
     * abwzins_bw2
     */
    abwzinsBw2: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Kleingarten Pacht
     * abwzins_kg
     */
    abwzinsKg: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender miete: Zinssatz, Renovierung
     * abwzins_ren
     */
    abwzinsRen: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Genossenschaft
     * abwzins_gen
     */
    abwzinsGen: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Ergänzungsdarlehen
     * abwzins_erg
     */
    abwzinsErg: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Nachfinanzierungsergänzungsdarlehen
     * abwzins_nf
     */
    abwzinsNf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Zwischenfinanzierung mit fremder Anschlussfinanzierung (B&W-Konto)
     * abwzins_zf_maf
     */
    abwzinsZfMaf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Zwischenfinanzierung ohne fremder Anschlussfinanzierung (B&W-Konto)
     * abwzins_zf_oaf
     */
    abwzinsZfOaf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Zwischenfinanzierung LS-Verkauf gem. KIMV-Novelle
     * abwzins_zf_lsverkauf
     */
    abwzinsZfLsVerkauf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Konsum
     * abwzins_konsum
     */
    abwzinsKonsum: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz fremde Anschlussfinanzierung
     * abwzins_fremde_af
     */
    abwzinsFremdeAf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * abweichender Zinssatz Sonderfinanzierungsarten
     * abwzins_sonderfin
     */
    abwzinsSonderfin: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz B&W Mortgage Linie 1
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins
     */
    kimVRelInterestRateBWMortageLineOne: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz B&W Mortgage Linie 2
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_two
     */
    kimVRelInterestRateBWMortageLineTwo: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Kleingarten Pacht
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_kg
     */
    kimVRelInterestRateAllotment: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Miete, Renovierung
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_miete
     */
    kimVRelInterestRateRentRenovation: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Genossenschaft
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_gen
     */
    kimVRelInterestRateCooperative: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Ergänzungsdarlehen
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_erg
     */
    kimVRelInterestRateSupplementaryLoan: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Nachfinanzierungsergänzungsdarlehen
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_nf
     */
    kimVRelInterestRatePostfinancingLoan: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Zwischenfinanzierung mit fremder Anschlussfinanzierung
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_maf
     */
    kimVRelInterestRateIntermediateFinancingWithExternal: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Zwischenfinanzierung ohne fremder Anschlussfinanzierung
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_oaf
     */
    kimVRelInterestRateIntermediateFinancingWithoutExternal: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Zwischenfinanzierung LS-Verkauf gem. KIMV-Novelle
     * kimv_rel_echtzins_lsverkauf
     */
    kimVRelEchtzinsLsVerkauf: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Konsum
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_konsum
     */
    kimVRelInterestRateConsumption: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

    /**
     * KIM-V relevanter Echtzinssatz Überschreitung Kreditkarte
     * Prozent, 3 Nachkommastellen
     * kimv_rel_echtzins_kk
     */
    kimVRelInterestRateOverageCreditCard: { type: WorkflowType.Number, formattingType: FormattingType.Percentage },

}
