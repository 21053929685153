export enum DashboardFilter {
    None,
    StatusOpen,
    Editing,
    StatusSampleCalculationRejected,
    StatusSampleCalculationWaitingForAcception,
    StatusSampleCalculationAccepted,
    StatusEsisWaitingForAcception,
    StatusEsisRejected,
    StatusCompleted,
    StatusRejected,
    StatusRejectedByResponsibility,
    StatusAutomaticallyRejected,
    StatusCanceled,
    Accounting,
    Finalize,
    ReadyForReferee,
}
