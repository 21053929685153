export enum Euribor {
    /**
     * 1 Monats-EURIBOR
     */
    OneMonthEuribor = 1,

    /**
     * 3 Monats-EURIBOR
     */
    ThreeMonthEuribor = 3,

    /**
     * 6 Monats-EURIBOR
     */
    SixMonthEuribor = 6,

    /**
     * 12 Monats-EURIBOR
     */
    TwelveMonthEuribor = 12,
}
