import { Component, OnDestroy, OutputRefSubscription, contentChildren, effect, model, output } from '@angular/core';

import { UcbaChipComponent } from '../chip/chip.component';

/**
 * A chip list component
 */
@Component({
    selector: 'ucba-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
    standalone: true,
})
export class UcbaChipListComponent implements OnDestroy {
    /**
     * the selected chip index
     */
    public selectedIndex = model<number>(0);

    /**
     * Output the index of the selected chip on change
     */
    public selectionChange = output<number>();

    /**
     * A list of chips through the ng-content
     */
    private chips = contentChildren(UcbaChipComponent);

    /**
     * 
     */
    private subscriptions: OutputRefSubscription[] = [];

    /**
     * Constructor.
     * Subscribe to all chips selected event and make sure only one chip is selected at a time
     */
    public constructor() {
        /**
         * Even the angular developer site says to use effect here
         */
        effect(() => {
            const chips = this.chips();

            if (this.selectedIndex() !== undefined) {
                chips[this.selectedIndex()]?.selected.set(true);
            }
        
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
                this.subscriptions = [];
            }
        
            for (const chip of chips) {
                this.subscriptions.push(chip.selected.subscribe(selected => {
                    if (selected) {
                        const index = chips.indexOf(chip);
                        this.selectionChange.emit(index);
                        this.selectedIndex.set(index);
                        for (const c of chips) {
                            if (c !== chip) {
                                c.selected.set(false);
                            }
                        }
                    }
                }));
            }
        }, { allowSignalWrites: true });
    }

    /**
     * Unsubscribe all subscriptions to the chips
     */
    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}
