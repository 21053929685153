import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMultipleStepsResult } from 'app/modules/financing/data';

/**
 * MultiStepDialogComponent
 */
@Component({
    selector: 'finprocess-multi-step-dialog',
    templateUrl: './multi-step-dialog.component.html',
    styleUrls: ['./multi-step-dialog.component.scss'],
})
export class MultiStepDialogComponent {

    /**
     * Standard Konstruktor
     * 
     * @param {IMultipleStepsResult} data IMultipleStepsResult
     */
    public constructor(
      @Inject(MAT_DIALOG_DATA) public data: IMultipleStepsResult,
    ) { }
}
