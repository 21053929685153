export enum FxIncomeCurrency {

    /** Schweizer Franken */
    CHF,

    /** Pfund Sterling */
    GBP,

    /** YEN */
    JPY,
    
    /**  Russische Föderation-Rubel  */
    RUB,
    
    /**US-Dollar */
    USD,

    /** Sonstige Währung */
    OTH,
}
