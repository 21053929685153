<mat-dialog-content>
    <div [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch">
        <h2 class="cursor-grab" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{user?.firstName}} {{user?.lastName}}</h2>
    
        <mat-form-field>
            <input formControlName="phoneNumber" [placeholder]="'administration.features.users.phoneNumber' | translate"
                matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-select multiple formControlName="language"
                [placeholder]="'administration.features.users.languages' | translate">
                <mat-option *ngFor="let language of languageItems" [value]="language.value">
                    {{ language.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field>
            <mat-select required multiple formControlName="userPurpose"
                [placeholder]="'administration.features.users.userPurpose' | translate">
                <mat-option *ngFor="let purpose of userPurposes" [value]="purpose">
                    {{ purpose | finprocessEnumTranslation: "Role" }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field>
            <mat-select required multiple formControlName="financingPermissions" (valueChange)="setValuesInControl($event)"
                [placeholder]="'administration.features.users.skills' | translate">
                <mat-option *ngFor="let premisson of financingPremisons" [value]="premisson.value">
                    {{ premisson.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field>
            <mat-select required multiple formControlName="salesPartnerCenter"
                [placeholder]="'administration.features.users.salesPartnerCenter' | translate">
                <mat-option *ngFor="let salesCenter of filteredSalesPartnerCenters" [value]="salesCenter.id">
                    {{ salesCenter.name }}, {{ salesCenter.responsibility | finprocessEnumTranslation:
                    "OrganisationalUnitResponsibilityType" }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-checkbox color="primary" formControlName="confirmed">
            {{'administration.features.users.confirmed' | translate}}
        </mat-checkbox>
    
        <mat-dialog-actions fxLayoutAlign="center start" class="action">
            <button mat-raised-button color="primary" (click)="save()" [disabled]="form.pristine || !form.valid">{{
                'button.save' | translate}}</button>
            <button mat-raised-button (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>