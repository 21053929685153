import { Collateralization, ObjectPurposeType } from '@ntag-ef/finprocess-enums';

import { DivisionByZeroError } from '../errors';
import { ILegalisationFeeBase } from '../interfaces';

import { CalculationHelperService } from './calculation-helper.service';
import { BaseCalculationService, InternalCalculationService } from './core';

/**
 * Berechnungsmethoden zur Finanzierung
 */
export class FinancingMapCalculationService extends BaseCalculationService {
    /**
     * Berechnungsservice InternalCalculationService
     */
    private pInternalCalculationService?: InternalCalculationService;

    /**
     * Berechnungsservice InternalCalculationService
     *
     * @returns {InternalCalculationService} InternalCalculationService
     */
    private get internalCalculationService() {
        if (this.pInternalCalculationService === undefined) {
            this.pInternalCalculationService = new InternalCalculationService(this.version);
        }
        return this.pInternalCalculationService;
    }

    /**
     * Konstruktor
     *
     * @param {number} version Zu nutzende Berechnungsversion, default: Aktuelle Version
     * @throws {import('../errors').ArgumentError} Der Parameter 'version' ist nicht valide.
     */
    public constructor(version?: number) {
        super(version);
    }

    /**
     * Berechnet Langfristiger Finanzierungsbedarf Netto
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.sumProjectCosts Summe der Projektkosten in €
     * @param {number} params.sumOwnCapital Summe der Eigenmittel in €
     * @param {number | undefined} params.funding Förderung in €
     * @param {number | undefined} params.otherOwnCapital Sonstige Mittel in €
     * @param {boolean | undefined} params.negativeValuesForbidden Sind negative Ergebnisse verboten --> mindestens 0, default: true
     * @returns {number} Langfristiger Finanzierungsbedarf Netto in €
     */
    public netFinancingRequirement({ sumProjectCosts, sumOwnCapital, funding, otherOwnCapital, negativeValuesForbidden = true }: {
        /**
         * Summe der Projektkosten in €
         */
        sumProjectCosts: number;
        /**
         * Summe der Eigenmittel in €
         */
        sumOwnCapital: number;
        /**
         * Förderung in €
         */
        funding?: number;
        /**
         * Sonstige Mittel in €
         */
        otherOwnCapital?: number;
        /**
         * Sind negative Ergebnisse verboten --> mindestens 0, default: true
         */
        negativeValuesForbidden?: boolean;
    }): number {
        const value = sumProjectCosts -
            sumOwnCapital -
            (!CalculationHelperService.isNullOrNaN(funding) ? (funding as number) : 0) -
            (!CalculationHelperService.isNullOrNaN(otherOwnCapital) ? (otherOwnCapital as number) : 0);
        if (negativeValuesForbidden) {
            return Math.max(0, value);
        }
        else {
            return value;
        }
    }

    /**
     * Berechnet Summe der Eigenmittel
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.cash Bar in €
     * @param {number | undefined} params.salesRevenue Verkaufserlös (bestehende Whg., Haus) in €
     * @param {number | undefined} params.redemptionInsurance Ablösenkapital Versicherung in €
     * @param {number | undefined} params.bausparCreditBalance Bausparguthaben/Restlaufzeit in €
     * @returns {number} Summe der Eigenmittel in €
     */
    public sumOwnCapital({ cash, salesRevenue, redemptionInsurance, bausparCreditBalance }: {
        /**
         * Bar in €
         */
        cash?: number;
        /**
         * Verkaufserlös (bestehende Whg., Haus) in €
         */
        salesRevenue?: number;
        /**
         * Ablösenkapital Versicherung in €
         */
        redemptionInsurance?: number;
        /**
         * Bausparguthaben/Restlaufzeit in €
         */
        bausparCreditBalance?: number;
    }): number {
        return (!CalculationHelperService.isNullOrNaN(cash) ? (cash as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(salesRevenue) ? (salesRevenue as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(redemptionInsurance) ? (redemptionInsurance as number) : 0) +
            (!CalculationHelperService.isNullOrNaN(bausparCreditBalance) ? (bausparCreditBalance as number) : 0);
    }

    /**
     * Berechnet die Summe der Eigenmittel, die nicht vorfinanziert werden und sofort zur Verfügung stehen
     * 
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.cash Bar in €
     * @param {number | undefined} params.salesRevenue Verkaufserlös (bestehende Whg., Haus) in €
     * @param {boolean | undefined} params.prefinancingSales Vorfinanzierung Verkaufserlös
     * @param {number | undefined} params.bausparCreditBalance Bausparguthaben/Restlaufzeit in €
     * @param {boolean | undefined} params.prefinancingBausparCreditBalance Vorfinanzierung Bausparguthaben/Restlaufzeit
     * @param {number | undefined} params.funding Förderung in €
     * @param {boolean | undefined} params.prefinancingFunding Vorfinanzierung Förderung
     * @param {number | undefined} params.redemptionInsurance Ablösenkapital Versicherung in €
     * @param {boolean | undefined} params.prefinancingInsurance Vorfinanzierung Ablösekapital Versicherung
     * @param {number | undefined} params.otherOwnCapital Sonstige Mittel in €
     * @param {boolean | undefined} params.prefinancingOtherOwnCapital Vorfinanzierung Sonstige Mittel
     * @returns {number} Summe der Eigenmittel, die nicht vorfinanziert werden und sofort zur Verfügung stehen in €
     */
    // eslint-disable-next-line complexity
    public sumOwnCapitalImmediatelyAvailable({ cash, salesRevenue, prefinancingSales, bausparCreditBalance, prefinancingBausparCreditBalance, funding, prefinancingFunding, redemptionInsurance, prefinancingInsurance, otherOwnCapital, prefinancingOtherOwnCapital}: {
        /**
         * Bar in €
         */
        cash?: number;
        /**
         * Verkaufserlös (bestehende Whg., Haus) in €
         */
        salesRevenue?: number;
        /**
         * Vorfinanzierung Verkaufserlös
         */
        prefinancingSales?: boolean;
        /**
         * Bausparguthaben/Restlaufzeit in €
         */
        bausparCreditBalance?: number;
        /**
         * Vorfinanzierung Bausparguthaben/Restlaufzeit
         */
        prefinancingBausparCreditBalance?: boolean;
        /**
         * Förderung in €
         */
        funding?: number;
        /**
         * Vorfinanzierung Förderung
         */
        prefinancingFunding?: boolean;
        /**
         * Ablösekapital Versicherung in €
         */
        redemptionInsurance?: number;
        /**
         * Vorfinanzierung Ablösekapital Versicherung
         */
        prefinancingInsurance?: boolean;
        /**
         * Sonstige Mittel in €
         */
        otherOwnCapital?: number;
        /**
         * Vorfinanzierung Sonstige Mittel
         */
        prefinancingOtherOwnCapital?: boolean;
    }): number {
        return (!CalculationHelperService.isNullOrNaN(cash) ? cash : 0) +
            (!CalculationHelperService.isNullOrNaN(salesRevenue) && !(prefinancingSales ?? true) ? salesRevenue : 0) +
            (!CalculationHelperService.isNullOrNaN(bausparCreditBalance) && !(prefinancingBausparCreditBalance ?? true) ? bausparCreditBalance : 0) +
            (!CalculationHelperService.isNullOrNaN(funding) && !(prefinancingFunding ?? true) ? funding : 0) +
            (!CalculationHelperService.isNullOrNaN(redemptionInsurance) && !(prefinancingInsurance ?? true) ? redemptionInsurance : 0) +
            (!CalculationHelperService.isNullOrNaN(otherOwnCapital) && !(prefinancingOtherOwnCapital ?? true) ? otherOwnCapital : 0);
    }

    /**
     * Berechnet den Verkaufserlös
     *
     * @param {object} params Parameterobjekt
     * @param {number | undefined} params.reducedSalesRevenue Reduzierter Verkaufserlös in €
     * @param {Array<object>} params.realEstates Objekte
     * @returns {number | undefined} Verkaufserlös in €
     */
    public salesRevenue({ reducedSalesRevenue, realEstates }: {
        /**
         * Reduzierter Verkaufserlös
         */
        reducedSalesRevenue?: number;

        /**
         * Objekte
         */
        realEstates: Array<{
            /**
             * Marktwert, ohne Aufschläge in €
             */
            marketValue?: number;

            /**
             * Objektart
             */
            objectPurpose: ObjectPurposeType;

            /**
             * Zur Besicherung
             */
            collateralization: Collateralization;
        }>;
    }): number | undefined {
        let calculatedSalesRevenue;

        switch (this.version) {
            case 0:
            case 1:
            case 2:
            case 3:
                return reducedSalesRevenue;
            case 4:
                // eslint-disable-next-line no-case-declarations
                calculatedSalesRevenue = realEstates.filter(it => it.objectPurpose === ObjectPurposeType.ForSale &&
                    it.collateralization === Collateralization.InterimFinancing
                    && !CalculationHelperService.isNullOrNaN(it.marketValue)).reduce((pV, cR) => pV + (cR.marketValue as number), 0);

                return calculatedSalesRevenue;
            case 5:
            case 6:
            default:
                // eslint-disable-next-line no-case-declarations
                calculatedSalesRevenue = realEstates.filter(it => it.objectPurpose === ObjectPurposeType.ForSale &&
                    it.collateralization === Collateralization.InterimFinancing
                    && !CalculationHelperService.isNullOrNaN(it.marketValue)).reduce((pV, cR) => pV + (cR.marketValue as number), 0);

                if (!CalculationHelperService.isNullOrNaN(reducedSalesRevenue) && (reducedSalesRevenue as number) <= calculatedSalesRevenue) {
                    return reducedSalesRevenue as number;
                }

                return calculatedSalesRevenue;
        }

    }

    /**
     * Berechnet Kurzfristiger Finanzierungsbedarf
     *
     * @param {object} params Parameterobjekt
     * @param {boolean | undefined} params.prefinancingSales Vorfinanzierung Verkaufserlös (bestehende Whg., Haus)
     * @param {number | undefined} params.salesRevenue Verkaufserlös (bestehende Whg., Haus) in €
     * @param {boolean | undefined} params.prefinancingInsurance Vorfinanzierung Ablösenkapital Versicherung
     * @param {number | undefined} params.redemptionInsurance Ablösenkapital Versicherung in €
     * @param {boolean | undefined} params.prefinancingBausparCreditBalance Vorfinanzierung Bausparguthaben/Restlaufzeit
     * @param {number | undefined} params.bausparCreditBalance Bausparguthaben/Restlaufzeit in €
     * @param {boolean | undefined} params.prefinancingFunding Vorfinanzierung Förderung
     * @param {number | undefined} params.funding Förderung in €
     * @param {boolean | undefined} params.prefinancingOtherOwnCapital Vorfinanzierung Sonstige Mittel
     * @param {number | undefined} params.otherOwnCapital Sonstige Mittel in €
     * @returns {number} Kurzfristiger Finanzierungsbedarf in €
     */
    public sumPrefinancing({ prefinancingSales, salesRevenue, prefinancingInsurance, redemptionInsurance, prefinancingBausparCreditBalance, bausparCreditBalance, prefinancingFunding, funding, prefinancingOtherOwnCapital, otherOwnCapital }: {
        /**
         * Vorfinanzierung Verkaufserlös (bestehende Whg., Haus)
         */
        prefinancingSales?: boolean;
        /**
         * Verkaufserlös (bestehende Whg., Haus) in €
         */
        salesRevenue?: number;
        /**
         * Vorfinanzierung Ablösenkapital Versicherung
         */
        prefinancingInsurance?: boolean;
        /**
         * Ablösenkapital Versicherung in €
         */
        redemptionInsurance?: number;
        /**
         * Vorfinanzierung Bausparguthaben/Restlaufzeit
         */
        prefinancingBausparCreditBalance?: boolean;
        /**
         * Bausparguthaben/Restlaufzeit in €
         */
        bausparCreditBalance?: number;
        /**
         * Vorfinanzierung Förderung
         */
        prefinancingFunding?: boolean;
        /**
         * Förderung in €
         */
        funding?: number;
        /**
         * Vorfinanzierung Sonstige Mittel
         */
        prefinancingOtherOwnCapital?: boolean;
        /**
         * Sonstige Mittel in €
         */
        otherOwnCapital?: number;
    }): number {
        return (prefinancingSales === true && !CalculationHelperService.isNullOrNaN(salesRevenue) ? (salesRevenue as number) : 0) +
            (prefinancingInsurance === true && !CalculationHelperService.isNullOrNaN(redemptionInsurance) ? (redemptionInsurance as number) : 0) +
            (prefinancingBausparCreditBalance === true && !CalculationHelperService.isNullOrNaN(bausparCreditBalance) ? (bausparCreditBalance as number) : 0) +
            (prefinancingFunding === true && !CalculationHelperService.isNullOrNaN(funding) ? (funding as number) : 0) +
            (prefinancingOtherOwnCapital === true && !CalculationHelperService.isNullOrNaN(otherOwnCapital) ? (otherOwnCapital as number) : 0);
    }

    /**
     * Berechnet Summe der Finanzierungsnebenkosten
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.processingCharges Bearbeitungsspesen in €
     * @param {number} params.estimateCharges Schätzgebühr in €
     * @param {number} params.registrationCharges Eintragungsgebühr in €
     * @param {number} params.legalisationFee Legalisierungsgebühr in €
     * @param {number} params.landRegisterRequest Grundbuchgesuch in €
     * @param {number} params.landRegisterExtract Grundbuchauszug in €
     * @param {number} params.valuationFeeExternal Schätzgebühr extern in €
     * @returns {number} Finanzierungsnebenkosten in €
     */
    public sumFinancingAdditionalCharges({ processingCharges, estimateCharges, registrationCharges, legalisationFee, landRegisterRequest, landRegisterExtract, valuationFeeExternal }: {
        /**
         * Bearbeitungsspesen in €
         */
        processingCharges: number;
        /**
         * Schätzgebühr in €
         */
        estimateCharges: number;

        /**
         * Schätzgebühr extern in  €
         */
        valuationFeeExternal?: number;
        
        /**
         * Eintragungsgebühr in €
         */
        registrationCharges: number;
        /**
         * Legalisierungsgebühr in €
         */
        legalisationFee: number;
        /**
         * Grundbuchgesuch in €
         */
        landRegisterRequest: number;
        /**
         * Grundbuchauszug in €
         */
        landRegisterExtract: number;
    }): number {
        return processingCharges +
            estimateCharges +
            registrationCharges +
            landRegisterRequest +
            landRegisterExtract +
            legalisationFee +
            (valuationFeeExternal ?? 0);
    }

    /**
     * Berechnet Zumutbare Kreditrate
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.freeAccessibleIncome Frei verfügbares Einkommen in €
     * @param {number} params.newMonthlyRates Neue Raten Förderung/Bauspardarlehen in €
     * @param {number} params.monthlyRateNotCoveredLiabilities Bestehen bleibende Kredit-/Leasingraten in €
     * @param {number} params.otherCosts Alimente in €
     * @returns {number} Zumutbare Kreditrate in €
     */
    public acceptableCreditRate({ freeAccessibleIncome, newMonthlyRates, monthlyRateNotCoveredLiabilities, otherCosts }: {
        /**
         * Frei verfügbares Einkommen in €
         */
        freeAccessibleIncome: number;
        /**
         * Neue Raten Förderung/Bauspardarlehen in €
         */
        newMonthlyRates: number;
        /**
         * Bestehen bleibende Kredit-/Leasingraten in €
         */
        monthlyRateNotCoveredLiabilities: number;
        /**
         * Alimente in €
         */
        otherCosts: number;
    }): number {
        return Math.max(0, freeAccessibleIncome - newMonthlyRates - monthlyRateNotCoveredLiabilities - otherCosts);
    }

    /**
     * Berechnet Bearbeitungsspesen
     * Berechnung anhand Langfristiger Finanzierungsbedarf Brutto erfolgt nur, wenn Bearbeitungsspesen in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.processingChargesAmount Bearbeitungsspesen in €
     * @param {number | undefined} params.processingChargesPercent Bearbeitungsspesen in %
     * @returns {number} Bearbeitungsspesen in €
     */
    public processingCharges({ grossFinancingRequirement, processingChargesAmount, processingChargesPercent }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Bearbeitungsspesen in €
         */
        processingChargesAmount?: number;
        /**
         * Bearbeitungsspesen in %
         */
        processingChargesPercent?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(processingChargesAmount) ? (processingChargesAmount as number) : CalculationHelperService.round((!CalculationHelperService.isNullOrNaN(processingChargesPercent) ? (processingChargesPercent as number) : 0) / 100 * grossFinancingRequirement, 2);
    }

    /**
     * Berechnet Eintragungsgebühr
     * Berechnung anhand Langfristiger Finanzierungsbedarf Brutto erfolgt nur, wenn Eintragungsgebühr in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.registrationChargesAmount Eintragungsgebühr in €
     * @param {number | undefined} params.registrationChargesPercent Eintragungsgebühr in %
     * @returns {number} Eintragungsgebühr in €
     */
    public registrationCharges({ grossFinancingRequirement, registrationChargesAmount, registrationChargesPercent }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Eintragungsgebühr in €
         */
        registrationChargesAmount?: number;
        /**
         * Eintragungsgebühr in %
         */
        registrationChargesPercent?: number;
    }): number {
        return !CalculationHelperService.isNullOrNaN(registrationChargesAmount) ? (registrationChargesAmount as number) : CalculationHelperService.round((!CalculationHelperService.isNullOrNaN(registrationChargesPercent) ? (registrationChargesPercent as number) : 0) / 100 * grossFinancingRequirement, 2);
    }

    /**
     * Berechnet Schätzgebühr
     * Berechnung anhand Langfristiger Finanzierungsbedarf Brutto erfolgt nur, wenn Schätzgebühr in € nicht übergeben wurde
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.grossFinancingRequirement Langfristiger Finanzierungsbedarf Brutto in €
     * @param {number | undefined} params.estimateChargesAmount Schätzgebühr in €
     * @param {number | undefined} params.estimateChargesPercent Schätzgebühr in %
     * @param {boolean | undefined} params.minVal Muss Ergebnis mindestens minValEuro sein. default: true
     * @param {number | undefined} params.minValEuro Mindestwert, der nicht unterschritten werden kann. default: 0
     * @returns {number} Schätzgebühr in €
     */
    public estimateCharges({ grossFinancingRequirement, estimateChargesAmount, estimateChargesPercent, minVal = true, minValEuro = 0 }: {
        /**
         * Langfristiger Finanzierungsbedarf Brutto in €
         */
        grossFinancingRequirement: number;
        /**
         * Schätzgebühr in €
         */
        estimateChargesAmount?: number;
        /**
         * Schätzgebühr in %
         */
        estimateChargesPercent?: number;
        /**
         * Muss Ergebnis mindestens minValEuro sein. default: true
         */
        minVal?: boolean;
        /**
         * Mindestwert in Euro. default 0
         */
        minValEuro?: number;
    }): number {
        let result = 0;
        if (!CalculationHelperService.isNullOrNaN(estimateChargesAmount)) {
            result = (estimateChargesAmount as number);
        }
        else if (grossFinancingRequirement === 0) {
            return 0;
        }
        else {
            result = (!CalculationHelperService.isNullOrNaN(estimateChargesPercent) ? (estimateChargesPercent as number) : 0) / 100 * grossFinancingRequirement;
        }

        switch (this.version) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return result < 300 && minVal ? 300 : CalculationHelperService.round(result, 2);
            case 6:
            default: 
                result = CalculationHelperService.round(result, 2);
                return (minVal && !CalculationHelperService.isNullOrNaN(minValEuro) ? Math.max(minValEuro, result) : result);
        }
    }

    /**
     * Berechnet Langfristigen Finanzierungsbedarf Brutto
     *
     * @param {object} params Parameterobjekt
     * @param {number} params.netFinancingRequirementNegativeValuesAllowed Langfristiger Finanzierungsbedarf Netto, nicht gecapped auf 0 in €
     * @param {number} params.legalisationFee Legalisierungsgebühr in €
     * @param {number | undefined} params.estimateChargesAmount Schätzgebühr in €
     * @param {number | undefined} params.estimateChargesPercent Schätzgebühr in %
     * @param {number | undefined} params.processingChargesAmount Bearbeitungsspesen in €
     * @param {number | undefined} params.processingChargesPercent Bearbeitungsspesen in %
     * @param {number | undefined} params.registrationChargesAmount Eintragungsgebühr in €
     * @param {number | undefined} params.registrationChargesPercent Eintragungsgebühr in %
     * @param {number} params.landRegisterRequest Grundbuchgesuch in €
     * @param {number} params.landRegisterExtract Grundbuchauszug in €
     * @param {boolean | undefined} params.checkValuationFee Minimalwert der Schätzgebühr berücksichtigen, default: true
     * @param {number | undefined} params.minValuationFee Minimalwert wenn checkValuationFee gesetzt ist default: 0
     * @throws {DivisionByZeroError}
     * @returns {number} Langfristiger Finanzierungsbedarf Brutto in €
     */
    // eslint-disable-next-line complexity
    public grossFinancingRequirement({ netFinancingRequirementNegativeValuesAllowed, legalisationFee, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract, checkValuationFee = true, minValuationFee = 0 }: {
        /**
         * Langfristiger Finanzierungsbedarf Netto, nicht gecapped auf 0 in €
         */
        netFinancingRequirementNegativeValuesAllowed: number;
        /**
         * Legalisierungsgebühr in €
         */
        legalisationFee: number;
        /**
         * Schätzgebühr in €
         */
        estimateChargesAmount?: number;
        /**
         * Schätzgebühr in %
         */
        estimateChargesPercent?: number;
        /**
         * Bearbeitungsspesen in €
         */
        processingChargesAmount?: number;
        /**
         * Bearbeitungsspesen in %
         */
        processingChargesPercent?: number;
        /**
         * Eintragungsgebühr in €
         */
        registrationChargesAmount?: number;
        /**
         * Eintragungsgebühr in %
         */
        registrationChargesPercent?: number;
        /**
         * Grundbuchgesuch in €
         */
        landRegisterRequest: number;
        /**
         * Grundbuchauszug in €
         */
        landRegisterExtract: number;
        /**
         * Minimalwert der Schätzgebühr berücksichtigen, default: true
         */
        checkValuationFee?: boolean;
        /**
         * Minimalwert der Schätzgebühr, wenn checkValuationFee gesetzt ist, default: 0
         */
        minValuationFee?: number;
    }): number {
        if (netFinancingRequirementNegativeValuesAllowed <= 0) {
            return 0;
        }
        const tempSum = Math.max(0, netFinancingRequirementNegativeValuesAllowed +
            landRegisterRequest +
            landRegisterExtract +
            legalisationFee);

        let result: number;

        if (checkValuationFee) {
            const grossFinancingRequirementNoMinimum = this.grossFinancingRequirement(
                { netFinancingRequirementNegativeValuesAllowed, legalisationFee, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract, checkValuationFee: false },
            );
            const estimateChargesNoMinimum = this.estimateCharges(
                { grossFinancingRequirement: grossFinancingRequirementNoMinimum, estimateChargesAmount, estimateChargesPercent, minVal: false },
            );
            
            const minValuationFeeInternal = this.version >= 6 ? minValuationFee : 300;

            if (grossFinancingRequirementNoMinimum > 0 && estimateChargesNoMinimum < minValuationFeeInternal) {
                estimateChargesAmount = minValuationFeeInternal;
            }
        }

        if (!CalculationHelperService.isNullOrNaN(estimateChargesAmount) && !CalculationHelperService.isNullOrNaN(processingChargesAmount) && !CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementNoPercent(
                tempSum,
                (processingChargesAmount as number),
                (estimateChargesAmount as number),
                (registrationChargesAmount as number),
            );
        }
        else if (!CalculationHelperService.isNullOrNaN(estimateChargesAmount) && CalculationHelperService.isNullOrNaN(processingChargesAmount) && !CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementOnePercent(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(processingChargesPercent) ? (processingChargesPercent as number) : 0),
                (estimateChargesAmount as number),
                (registrationChargesAmount as number),
            );
        }
        else if (!CalculationHelperService.isNullOrNaN(estimateChargesAmount) && !CalculationHelperService.isNullOrNaN(processingChargesAmount) && CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementOnePercent(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(registrationChargesPercent) ? (registrationChargesPercent as number) : 0),
                (processingChargesAmount as number),
                (estimateChargesAmount as number),
            );
        }
        else if (CalculationHelperService.isNullOrNaN(estimateChargesAmount) && !CalculationHelperService.isNullOrNaN(processingChargesAmount) && !CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementOnePercent(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(estimateChargesPercent) ? (estimateChargesPercent as number) : 0),
                (processingChargesAmount as number),
                (registrationChargesAmount as number),
            );
        }
        else if (!CalculationHelperService.isNullOrNaN(estimateChargesAmount) && CalculationHelperService.isNullOrNaN(processingChargesAmount) && CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementTwoPercents(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(processingChargesPercent) ? (processingChargesPercent as number) : 0),
                (!CalculationHelperService.isNullOrNaN(registrationChargesPercent) ? (registrationChargesPercent as number) : 0),
                (estimateChargesAmount as number),
            );
        }
        else if (CalculationHelperService.isNullOrNaN(estimateChargesAmount) && CalculationHelperService.isNullOrNaN(processingChargesAmount) && !CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementTwoPercents(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(processingChargesPercent) ? (processingChargesPercent as number) : 0),
                (!CalculationHelperService.isNullOrNaN(estimateChargesPercent) ? (estimateChargesPercent as number) : 0),
                (registrationChargesAmount as number),
            );
        }
        else if (CalculationHelperService.isNullOrNaN(estimateChargesAmount) && !CalculationHelperService.isNullOrNaN(processingChargesAmount) && CalculationHelperService.isNullOrNaN(registrationChargesAmount)) {
            result = this.grossFinancingRequirementTwoPercents(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(estimateChargesPercent) ? (estimateChargesPercent as number) : 0),
                (!CalculationHelperService.isNullOrNaN(registrationChargesPercent) ? (registrationChargesPercent as number) : 0),
                (processingChargesAmount as number),
            );
        }
        else {
            result = this.grossFinancingRequirementThreePercents(
                tempSum,
                (!CalculationHelperService.isNullOrNaN(processingChargesPercent) ? (processingChargesPercent as number) : 0),
                (!CalculationHelperService.isNullOrNaN(estimateChargesPercent) ? (estimateChargesPercent as number) : 0),
                (!CalculationHelperService.isNullOrNaN(registrationChargesPercent) ? (registrationChargesPercent as number) : 0),
            );
        }

        return Math.ceil(Math.max(0, result) / 1000) * 1000;
    }

    /**
     * Berechnet Legalisierungsgebühr
     *
     * @param {object} params Parameterobjekt
     * @param {ILegalisationFeeBase[]} params.legalisationFeeBases Matrix zur Berechnung der Legalisierungsgebühr
     * @param {number} params.netFinancingRequirementNegativeValuesAllowed Langfristiger Finanzierungsbedarf Netto in €, nicht gecapped auf 0
     * @param {number | undefined} params.estimateChargesAmount Schätzgebühr in €
     * @param {number | undefined} params.estimateChargesPercent Schätzgebühr in %
     * @param {number | undefined} params.processingChargesAmount Bearbeitungsspesen in €
     * @param {number | undefined} params.processingChargesPercent Bearbeitungsspesen in %
     * @param {number | undefined} params.registrationChargesAmount Eintragungsgebühr in €
     * @param {number | undefined} params.registrationChargesPercent Eintragungsgebühr in %
     * @param {number} params.landRegisterRequest Grundbuchgesuch in €
     * @param {number} params.landRegisterExtract Grundbuchauszug in €<s
     * @throws {DivisionByZeroError}
     * @throws {import('../errors').ArgumentError} Der Parameter 'legalisationFeeBases' ist nicht valide.
     * @returns {number} Legalisierungsgebühr in €
     */
    public legalisationFee({ legalisationFeeBases, netFinancingRequirementNegativeValuesAllowed, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract }: {
        /**
         * Matrix zur Berechnung der Legalisierungsgebühr
         */
        legalisationFeeBases: ILegalisationFeeBase[];
        /**
         * Langfristiger Finanzierungsbedarf Netto in €, nicht gecapped auf 0
         */
        netFinancingRequirementNegativeValuesAllowed: number;
        /**
         * Schätzgebühr in €
         */
        estimateChargesAmount?: number;
        /**
         * Schätzgebühr in %
         */
        estimateChargesPercent?: number;
        /**
         * Bearbeitungsspesen in €
         */
        processingChargesAmount?: number;
        /**
         * Bearbeitungsspesen in %
         */
        processingChargesPercent?: number;
        /**
         * Eintragungsgebühr in €
         */
        registrationChargesAmount?: number;
        /**
         * Eintragungsgebühr in %
         */
        registrationChargesPercent?: number;
        /**
         * Grundbuchgesuch in €
         */
        landRegisterRequest: number;
        /**
         * Grundbuchauszug in €
         */
        landRegisterExtract: number;
    }): number {
        // Berechne Langfristigen Finanzierungsbedarf Brutto mit Berücksichtigung der oben berechneten Schätzgebühr
        const financingRequirement = this.grossFinancingRequirement(
            { netFinancingRequirementNegativeValuesAllowed, legalisationFee: 0, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract },
        );

        // Berechne Legalisierungsgebühr anhand des Langfristigen Finanzierungsbedarf Brutto
        let fee = this.internalCalculationService.getLegalisationFee({ legalisationFeeBases, creditAmount: financingRequirement });
        fee = !CalculationHelperService.isNullOrNaN(fee) ? (fee as number) : 0;

        // Berechne Legalisierungsgebühr neu unter Berücksichtigung der berechneten Legalisierungsgebühr und der neu berechneten Schätzgebühr
        let newFee = this.internalCalculationService.getLegalisationFee(
            {
                legalisationFeeBases, creditAmount: this.grossFinancingRequirement(
                    { netFinancingRequirementNegativeValuesAllowed, legalisationFee: fee, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract },
                ),
            },
        );

        while (newFee !== fee) {
            fee = newFee;
            fee = !CalculationHelperService.isNullOrNaN(fee) ? (fee as number) : 0;
            // Berechne Schätzgebühr ohne Minimum mit angegebenen Werten mit neu berechneter Legalisierungsgebühr
            // Berechne Legalisierungsgebühr neu unter Berücksichtigung der berechneten Legalisierungsgebühr und der neu berechneten Schätzgebühr
            newFee = this.internalCalculationService.getLegalisationFee(
                {
                    legalisationFeeBases, creditAmount: this.grossFinancingRequirement(
                        { netFinancingRequirementNegativeValuesAllowed, legalisationFee: fee, estimateChargesAmount, estimateChargesPercent, processingChargesAmount, processingChargesPercent, registrationChargesAmount, registrationChargesPercent, landRegisterRequest, landRegisterExtract },
                    ),
                },
            );
        }

        return newFee;
    }

    /**
     * Berechnet Langfristiger Finanzierungsbedarf Brutto mit Angabe der Finanzierungsnebenkosten ausschließlich in %
     *
     * @param {number} tempSum Zwischensumme in €
     * @param {number} p1 Wert1 in %
     * @param {number} p2 Wert2 in %
     * @param {number} p3 Wert3 in %
     * @throws {DivisionByZeroError}
     * @returns {number} Langfristiger Finanzierungsbedarf Brutto in €
     */
    private grossFinancingRequirementThreePercents(tempSum: number, p1: number, p2: number, p3: number): number {
        const divisor = 1 - p1 / 100 - p2 / 100 - p3 / 100;
        if (divisor === 0) {
            throw new DivisionByZeroError();
        }
        else if (divisor < 0) {
            return 0;
        }
        return tempSum / divisor;
    }

    /**
     * Berechnet Langfristiger Finanzierungsbedarf Brutto mit Angabe von zwei Komponenten der Finanzierungsnebenkosten in %, eine in €
     *
     * @param {number} tempSum Zwischensumme in €
     * @param {number} p1 Wert1 in %
     * @param {number} p2 Wert2 in %
     * @param {number} v3 Wert3 in €
     * @throws {DivisionByZeroError}
     * @returns {number} Langfristiger Finanzierungsbedarf Brutto in €
     */
    private grossFinancingRequirementTwoPercents(tempSum: number, p1: number, p2: number, v3: number): number {
        const divisor = 1 - p1 / 100 - p2 / 100;
        if (divisor === 0) {
            throw new DivisionByZeroError();
        }
        else if (divisor < 0) {
            return 0;
        }
        return (tempSum + v3) / divisor;
    }

    /**
     * Berechnet Langfristiger Finanzierungsbedarf Brutto mit Angabe von einer Komponente der Finanzierungsnebenkosten in %, zwei in €
     *
     * @param {number} tempSum Zwischensumme in €
     * @param {number} p1 Wert1 in %
     * @param {number} v2 Wert2 in €
     * @param {number} v3 Wert3 in €
     * @throws {DivisionByZeroError}
     * @returns {number} Langfristiger Finanzierungsbedarf Brutto in €
     */
    private grossFinancingRequirementOnePercent(tempSum: number, p1: number, v2: number, v3: number): number {
        const divisor = 1 - p1 / 100;
        if (divisor === 0) {
            throw new DivisionByZeroError();
        }
        else if (divisor < 0) {
            return 0;
        }
        return (tempSum + v2 + v3) / divisor;
    }

    /**
     * Berechnet Langfristiger Finanzierungsbedarf Brutto mit Angabe Finanzierungsnebenkosten ausschließlich in €
     *
     * @param {number} tempSum Zwischensumme in €
     * @param {number} v1 Wert1 in €
     * @param {number} v2 Wert2 in €
     * @param {number} v3 Wert3 in €
     * @returns {number} Langfristiger Finanzierungsbedarf Brutto in €
     */
    private grossFinancingRequirementNoPercent(tempSum: number, v1: number, v2: number, v3: number): number {
        return tempSum + v1 + v2 + v3;
    }
}
