export enum FireKzType {
    /**
     * nein
     */
    No,

    /**
     * Vorfälligkeitsfinanz.
     */
    PrepaymentFinance,

    /**
     *  "Krone Sonne Kampagne 2"
     */
    CrownSunCampaignTwo,

    /**
     *  "Seniorenkredit"
     */
    SeniorCredit,
}
