import { EnumSettings } from './../enum.settings';

/**
 * Dokumentenreferenz
 */
export enum DocumentReferenceType {
    /**
     * Finanzierungsmappe
     */
    FinancingMap = 0,

    /**
     * Haushalt
     */
    Household = 1,

    /**
     * Kreditnehmer
     */
    Debitor = 2,

    /**
     * Objekt
     */
    RealEstate = 3,
}

export namespace DocumentReferenceType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {DocumentReferenceType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: DocumentReferenceType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'DebitRateAdaptionParameter', DocumentReferenceType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('DocumentReferenceType', plocale);
}
