import { ISmartDocJobThumbnailViewModel } from '../../interfaces/smartdoc';
import { AAGUID } from '../../types/aaguid';

export interface ISmartDocJobThumbnailsLoadedPayloadType {

    /**
     * Eindeutiger Schlüssel zum Fall
     */
    id: AAGUID;

    /**
     * Vorschaubilder, die geladen wurden
     */
    thumbnails: ISmartDocJobThumbnailViewModel[];
}

/**
 * Klasse für Aktion
 */
export class SmartDocJobThumbnailsLoaded {
    /**
     * Eindeutiger Schlüssel der Aktion
     */
    public static readonly type = 'smartdoc.smartDocJobThumbnailsLoaded';
    /**
     * Constructor
     *
     * @param {ISmartDocJobThumbnailsLoadedPayloadType} payload Payload
     */
    public constructor(public payload: ISmartDocJobThumbnailsLoadedPayloadType) { }
}
