/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Logout } from 'app/modules/auth/data';

import { IDebitRate, IDebitRateAdaption } from './../../interfaces';
import { DebitRatesChanged, DebitRatesLoaded } from './debit-rates.action';

/**
 * Zustandsobjekt für Konditionen
 */
export interface IDebitRatesStateModel {
    /**
     * Sollzinssätze
     */
    debitRates: IDebitRate[];

    /**
     * Regeln zur Risikoeinschätzung
     */
    debitRateAdaptions: IDebitRateAdaption[];

    /**
     * Marge
     */
    marge: number;

    /**
     * Volumen
     */
    volumen: number;
}

/**
 * Zustand für Konditionen
 */
@State<IDebitRatesStateModel>({
    name: DebitRatesState.keyName,
    defaults: {
        debitRates: [],
        debitRateAdaptions: [],
        marge: 0,
        volumen: 0,
    },
})
@Injectable()
export class DebitRatesState {
    public static readonly keyName = 'debitRates';

    /**
     * Zustandsänderung nach dem Laden der Risikoparametrisierung
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {DebitRatesLoaded} action Aktion
     */
    @Action(DebitRatesLoaded)
    public debitRatesLoaded({ patchState }: StateContext<IDebitRatesStateModel>, { payload }: DebitRatesLoaded): void {
        patchState(payload);
    }

    /**
     * Zustandsänderung beim Ändern der Risikoparametrisierung
     *
     * @param {StateContext} context aktueller State Kontext
     * @param {DebitRatesChanged} action Aktion
     */
    @Action(DebitRatesChanged)
    public changed({ patchState }: StateContext<IDebitRatesStateModel>, { payload }: DebitRatesChanged): void {

        patchState(payload);
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IDebitRatesStateModel>): void {
        setState({
            debitRates: [],
            debitRateAdaptions: [],
            marge: 0,
            volumen: 0,
        });
    }
}
