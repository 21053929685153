/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { sort } from 'fast-sort';
import { IApprovalCompetence } from 'masterdata/data/interfaces/approval-competence.interface';
import { IMasterdataStateModel, MasterdataState } from 'masterdata/data/states/masterdata/masterdata.state';
import { IsoDate } from 'shared/util';

import { Logout } from '../authentication/authentication.actions';

import { IUser } from './../../interfaces';
import { UserDataLoaded } from './user.actions';

/**
 * Zustandsobjekt für aktuellen Nutzer
 */
export interface IUserStateModel {
    /**
     * Daten des aktuellen Nutzers
     */
    data?: IUser;

    /**
     * Zeitpunkt, wann Nutzerdaten das letzte Mal geändert wurden
     */
    lastModified?: IsoDate;
}

/**
 * Zustand für aktuellen Nutzer
 */
@State<IUserStateModel>({
    name: UserState.keyName,
    defaults: {},
})
@Injectable()
export class UserState {

    public static readonly keyName = 'user';

    /**
     * Selektor für aktuellen Nutzer
     *
     * @param {IUserStateModel} state Zustandsobjekt für aktuellen Nutzer
     * @returns {IUser} Aktueller Nutzer
     */
    @Selector()
    public static user(state: IUserStateModel): IUser |undefined {
        return state.data;
    }

    /**
     * Liefert die Genehmigungskompetenz eines Nutzers
     * 
     * @param {IUserStateModel} state User State Daten
     * @param {IMasterdataStateModel} masterdata Stammdaten
     * @returns {IApprovalCompetence | undefined} Genehmigungskompetenz
     */
    @Selector([MasterdataState])
    public static approvalCompetence(state: IUserStateModel, masterdata: IMasterdataStateModel): IApprovalCompetence | undefined {
        if (!state.data || !Array.isArray(state.data.salesApprovalLevel) || state.data.salesApprovalLevel.length === 0) {
            return undefined;
        }

        const competencesByLevel = sort(masterdata.approvalCompetence).desc(it => it.minLevel);
        const userCompetences = state.data.salesApprovalLevel.map(userCompetence => competencesByLevel.find(it => it.minLevel === userCompetence));
        return sort(userCompetences).desc(it => it?.upperLimit)[0];
    }

    /**
     * Zustandsänderung nach dem Laden der Nutzerdaten
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {UserDataLoaded} action Aktion
     */
    @Action(UserDataLoaded)
    public userDataLoaded({ patchState }: StateContext<IUserStateModel>, { payload }: UserDataLoaded): void {
        patchState({
            data: payload.data,
            lastModified: payload.lastModified,
        });
    }

    /**
     * Setzt den Zustand zurück
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Logout)
    public resetData({ setState }: StateContext<IUserStateModel>): void {
        setState({});
    }
}
