import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { SystemdocumentsService } from 'app/modules/administration/data/services/systemdocuments/systemdocuments.service';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { IFile } from 'app/modules/financing/data';
import { HelperService } from 'app/modules/shared';
import { EMPTY, Subject, catchError, finalize, takeUntil } from 'rxjs';

interface ISystemDocument {
    name: string;
    type: DocumentType;
    typeName: string;
    files: IFile[];
}
/**
 * Systemdokumente laden, anzeigen und austauschen
 */
@Component({
    selector: 'finprocess-systemdocuments',
    templateUrl: './systemdocuments.component.html',
    styleUrls: ['./systemdocuments.component.scss'],
})
export class SystemdocumentsComponent implements OnInit, OnDestroy {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public DocumentType = DocumentType;

    @Input()
    public layout = 'row wrap';

    /**
     * Documents that will be displayed
     */
    @Input()
    public documents: ISystemDocument[] = [];

    /**
     * empty Documents (nothing uploaded) that will be displayed as placeholder
     */
    public emptyCategoryDocuments: DocumentType[] = [];

    /**
     * List of every DocumentType and Name for Short Application
     */
    public listOfShortApplication = [
        { type: DocumentType.ShortApplicationMobile, name: 'Short Application Mobile' },
        { type: DocumentType.ShortApplicationOwnEmployee, name: 'Short Application Own Employee' },
        { type: DocumentType.ShortApplicationSmallBusiness, name: 'Short Application Small Business' },
        { type: DocumentType.ShortApplicationStationary, name: 'Short Application Stationary' },
    ];

    /**
     * List of every DocumentType and Name for System documents
     */
    public listOfSystemDocuments = [
        { type: DocumentType.BankAccountPrices, name: 'Bank Account Prices' },
        { type: DocumentType.EmployerApproval, name: 'Employer Approval' }];

    /**
     * which option was selected in dropdown
     */
    public selectedDropDownOption!: string;

    /**
     * accepted file formats depending on dropdown value
     */
    public acceptedFormats!: string[];

    private systemDokumentFormats = ['.pdf', '.jpeg', '.jpg', '.png'];
    private shortDokumentFormats = ['.xlsx', '.xlsm'];

    /**
     * Hat der Nutzer Bearbeitungsrechte für die Seite
     */
    public userCanEdit = false;

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$: Subject<void> = new Subject();

    /**
     * Konstruktor
     *
     * @param {SystemdocumentsService} systemDocumentService system documents service
     * @param {TranslateService} translate Translation Service
     * @param {Notification} notification Notification Service
     * @param {WaiterService} waiterService Waiter Service
     * @param {ChangeDetectorRef} changeDetection Change Detection
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private systemDocumentService: SystemdocumentsService,
        private translate: TranslateService,
        private notification: NotificationService,
        private waiterService: WaiterService,
        private changeDetection: ChangeDetectorRef,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Überprüft Berechtigungen des Nutzers um Eingaben zu sperren
     */
    public ngOnInit(): void {
        this.authorizationService.hasRole$(Role.SystemDocumentsAdministrator).pipe(
            takeUntil(this.onDestroy$),
        ).subscribe(hasRole => {
            this.userCanEdit = hasRole;
        });    
    }

    /**
     * Beendet Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();    
    }

    /**
     * öffnet/downloaded ein Dokument
     * 
     * @param {IFile} file Datei
     * @param {string} action Auswahl: Download oder Öffnen
     */
    public onDocumentClick(file: IFile, action: 'download' | 'show' = 'download'): void {
        this.systemDocumentService.getSystemFile(file.id).subscribe({
            next: async fileContent => {
                const blob = HelperService.fileContentToBlob(fileContent, file.mimeType);

                if (!blob) {
                    return;
                }

                if (action === 'download') {
                    await HelperService.downloadFileFromBlob(blob, file.name);
                } else if (action === 'show') {
                    await HelperService.openFileFromBlob(blob); 
                }
            },
        });
    }

    /**
     * File Upload
     *
     * @param {EventTarget} target ausgewählte Datei
     * @param {DocumentType} documentType DokumentType der ausgewählten Datei
     */
    public uploadFile(target: EventTarget | null, documentType: DocumentType): void {
        const tmp = target as HTMLInputElement;

        if (tmp.files && tmp.files.length > 1) {
            this.notification.toast(this.translate.instant('administration.features.control-tables.maxOneFile'), { duration: 3000 });
        }
        const file = tmp.files?.item(0);

        if (!!file) {

            const fileName = file.name.toLocaleLowerCase();

            if (!this.acceptedFormats.some(format => fileName.endsWith(format))) {
                if (this.acceptedFormats.some(format => this.systemDokumentFormats.includes(format)))
                {
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('administration.features.system-documents.wrongFileFormatSystemDocument'));
                    return;   
                }
                else {
                    this.notification.alert(this.translate.instant('general.error'), this.translate.instant('administration.features.system-documents.wrongFileFormatShortApplication'));
                    return;  
                }
            }
            this.waiterService.show();
            this.systemDocumentService.uploadSystemDocument(file, documentType)
                .subscribe({
                    next: () => {
                        this.waiterService.hide();
                        this.handleOption(this.selectedDropDownOption);
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('administration.features.system-documents.uploadError'))
                    },
                });
        }
    }

    /**
     * switch between the selected options in the dropdown menu
     *
     * @param {DocumentType} selectedValue the selected value from dropdown menu
     */
    public handleOption(selectedValue: string): void {
        this.documents = [];
        this.selectedDropDownOption = selectedValue;
        switch (selectedValue) {
            case 'Systemdokumente': 
                this.acceptedFormats = this.systemDokumentFormats;
                this.loadSelectedDocument(this.listOfSystemDocuments.map(({ type }) => type), 'Systemdokumente');
                break;
            case 'Kurzantrag':
                this.acceptedFormats = this.shortDokumentFormats;
                this.loadSelectedDocument(this.listOfShortApplication.map(({ type }) => type), 'Kurzantrag');
                break;
            default:
                break;
        }
    }

    /**
     * load selected document category
     *
     * @param {DocumentType} selectedTypeList selected category values by drop down value
     * @param {string} selectedTypeName string value of DocumentType Name
     */
    public loadSelectedDocument(selectedTypeList: DocumentType[], selectedTypeName: string): void {
        this.waiterService.show();
        let listOfTypes: { type: DocumentType, name: string }[] = [];

        if (selectedTypeName === 'Systemdokumente') {
            listOfTypes = this.listOfSystemDocuments;
        }
        else {
            listOfTypes = this.listOfShortApplication;
        }

        this.systemDocumentService.getSystemDocuments().pipe(
            finalize(() => this.waiterService.hide()),
            catchError(() => {
                this.notification.alert(this.translate.instant('general.error'), this.translate.instant('administration.features.system-documents.uploadError'));
                return EMPTY;
            })).subscribe(documents => {
            const existingDocuments = documents.filter(document => selectedTypeList.includes(document.type)).map(({ files, type }) => ({
                name: files[0]?.name || '',
                type: type,
                typeName: listOfTypes.find(item => item.type === type)?.name || '',
                files: files,
            }));

            // Find empty/not uploaded documents
            const emptyCategoryDocuments = selectedTypeList.filter(value => !existingDocuments.some(doc => doc.type === value)).map(type => ({ name: 'fehlendes Dokument', type, typeName: listOfTypes.find(item => item.type === type)?.name || '', files: [] }));
            //merge both 
            this.documents = [...existingDocuments, ...emptyCategoryDocuments];
            this.changeDetection.detectChanges();
        });
    }
}
