import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

import { CUSTOMER_ADDRESS_IN } from './customer-address-in';

export const CREATE_OR_UPDATE_ADDRESSES: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'createOrUpdateAddresses',

    ndg: { type: WorkflowType.String },

    createOrUpdateModel: { type: WorkflowType.Array, child: CUSTOMER_ADDRESS_IN },
}
