<div fxLayout="column" fxLayoutAlign="center space-between" class="mr-3">

    <mat-divider></mat-divider>
    <div class="financing-scope" fxLayout="row" fxLayoutAlign="center">

        <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.productPackages.texts.creditAmountLongTerm'|
                translate}}</span>
            <span *ngIf="grossFinancingRequirement || grossFinancingRequirement === 0" value>
                {{grossFinancingRequirement | formatCurrency}}
            </span>
            <span *ngIf="grossFinancingRequirement === undefined"
                value><small>{{'financing.features.financing-processing.productPackages.texts.unknown'|
                    translate}}</small></span>
        </finprocess-label-value>

        <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.productPackages.texts.creditAmountShortTerm'|
                translate}}</span>
            <span *ngIf="comfortCreditPlus" value>{{comfortCreditPlus | formatCurrency}}</span>
            <span *ngIf="comfortCreditPlus === undefined || comfortCreditPlus === 0"
                value><small>{{'financing.features.financing-processing.productPackages.texts.unknown'|
                    translate}}</small></span>
        </finprocess-label-value>

        <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.productPackages.texts.bridgingFinancing'|
                translate}}</span>
            <span *ngIf="bridgingFinancing" value>{{bridgingFinancing | formatCurrency}}</span>
            <span *ngIf="bridgingFinancing === undefined || bridgingFinancing === 0"
                value><small>{{'financing.features.financing-processing.productPackages.texts.unknown'|
                    translate}}</small></span>
        </finprocess-label-value>

        <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.productPackages.texts.guarantee'| translate}}</span>
            <span value *ngIf="guaranteeAmount">{{guaranteeAmount | formatCurrency}}</span>
            <span value *ngIf="guaranteeAmount === undefined || guaranteeAmount === 0">
                <small>{{'financing.features.financing-processing.productPackages.texts.unknown'|
                    translate}}
                </small>
            </span>
        </finprocess-label-value>

        <finprocess-label-value>
            <span label>{{'financing.features.financing-processing.productPackages.texts.totalCommitment'| translate}}</span>
            <span value *ngIf="totalCommitment">{{totalCommitment | formatCurrency}}</span>
            <span value *ngIf="totalCommitment === undefined || totalCommitment === 0">
                <small>{{'financing.features.financing-processing.productPackages.texts.unknown'|
                    translate}}
                </small>
            </span>
        </finprocess-label-value>
    </div>
    <mat-divider class="mb-5"></mat-divider>

    <button [disabled]="(fieldReadonly$ | async) ?? true" mat-raised-button color="primary" class="add-product mb-4"
        (click)="openProductPackageDialog(true)" style="width: fit-content;">
        {{'financing.features.financing-processing.productPackages.texts.addProductPackage'
        | translate}}
    </button>

    <mat-expansion-panel [@fadeInOut] class="ml-5 mb-3"
        *ngFor="let productPackage of productPackageForms; let productPackageIndex = index; trackBy: trackByFn">
        <mat-expansion-panel-header>
            <mat-panel-title (keydown.Space)="$event.stopImmediatePropagation();" [formGroup]="productPackage">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="titles mr-5 mt-3 mb-3" style="width: max-content;" fxLayout="column">
                        <div *ngIf="productPackage.errors" fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon class="title-warning mr-2"
                                matTooltip="Eingaben enstprechen nicht dem Finanzierungsrahmen">warning</mat-icon>
                            <b class="title-warning">{{'financing.features.financing-processing.productPackages.texts.productPackage'
                                | translate}} {{productPackageIndex+1}}</b>
                        </div>
                        <div *ngIf="!productPackage.errors">
                            <b>{{'financing.features.financing-processing.productPackages.texts.productPackage' |
                                translate}} {{productPackageIndex+1}}</b>
                        </div>
                        <!-- Product package status-->
                        <div class="product-package-status" fxLayout="row" fxLayoutAlign="center center">
                            <ng-container [ngSwitch]="productPackage.controls.status.value ?? 100">
                                <mat-icon *ngSwitchCase="ProductPackageStatus.Editing" color="primary">edit</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.DecisionFailed"
                                    class="error">local_fire_department</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.DecisionSuccesful"
                                    class="success">local_fire_department</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalAwaiting"
                                    color="primary">hourglass_empty</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalRejected"
                                    class="warn">contact_support</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalDenied"
                                    class="error">error</mat-icon>
                                <mat-icon *ngSwitchCase="ProductPackageStatus.ApprovalGranted"
                                    class="success">check_circle</mat-icon>
                                <mat-icon *ngSwitchDefault>edit</mat-icon>
                            </ng-container>
                            &nbsp;
                            <span class="product-package-status-label color-font">{{ productPackage.controls.status.value ?? 100 |
                                finprocessEnumTranslation:'ProductPackageStatus'}}</span>
                        </div>
                    </div>

                    <!--paket name-->
                    <mat-form-field class="mat-form-field-transparent-background">
                        <mat-label *ngIf="!productPackage.controls.name.value">
                            <small>{{'financing.features.financing-processing.productPackages.texts.packageName' |
                                translate}}</small>
                        </mat-label>
                        <small><input matInput formControlName="name"></small>
                    </mat-form-field>

                </div>
            </mat-panel-title>
            <mat-panel-description>
                <div class="configure-buttons">
                    <button mat-icon-button (click)="deleteProductPackage(productPackage.controls.productPackageID.value)"
                        class="delete-product" [disabled]="(fieldReadonly$ | async) ?? true">
                        <mat-icon matTooltip="Produktpaket löschen" style="margin-bottom: 13px;">delete</mat-icon>
                    </button>
                    <button mat-icon-button (click)="copyProductPackage(productPackage.controls.productPackageID.value)"
                        class="config-product" [disabled]="(fieldReadonly$ | async) ?? true">
                        <mat-icon matTooltip="Produktpaket kopieren" style="margin-bottom: 13px;">file_copy</mat-icon>
                    </button>
                    <button mat-icon-button
                        (click)="openDescriptionDialog(productPackage.getRawFinprocessValue())"
                        class="config-product" [disabled]="(fieldReadonly$ | async) ?? true">
                        <mat-icon matTooltip="Beschreibung hinzufügen"
                            style="margin-bottom: 13px;">description</mat-icon>
                    </button>
                    <button mat-icon-button
                        [disabled]="productPackage.invalid"
                        (click)="openRiskDecision(productPackage.controls.productPackageID.value)" matTooltip="Bitte Kreditbeträge/Garantiebeträge ausfüllen, um fortzufahren!"
                        [matTooltipDisabled]="productPackage.valid">
                        <mat-icon style="margin-bottom: 13px;">arrow_forward</mat-icon>
                    </button>
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-tab-group mat-stretch-tabs="false">
            <!--Tabs mit den Produkten und Inputs-->
            <mat-tab *ngFor="let product of productPackage.controls.assignProducts.controls; let productIndex = index;">
                <ng-template mat-tab-label> {{ product.controls.position.value }}.
                    <span *ngIf="product.controls.productCreditType.value !== null">{{product.controls.productCreditType.value | finprocessEnumTranslation:'ProductPackageType'}}</span>
                    <button (click)="deleteProduct(product.controls.productID.value)" mat-mini-fab class="remove-product ml-2"
                        [disabled]="(fieldReadonly$ | async) ?? true">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                    
                </ng-template>
                <form [formGroup]="product">
                    <div class="mt-4">
                        <div fxLayout="row">
                            <div fxLayout="column" class="mr-4">
                                <h4 class="titles">
                                    <b>
                                        {{'financing.features.financing-processing.productPackages.texts.creditRequest' |
                                        translate}}
                                    </b>
                                </h4>
    
                                <div fxLayout="row">
    
                                    <!--Kreditbetrag-->
                                    <div fxLayout="column">
                                        <mat-form-field appearance="fill" class="mr-3" fxFlex="100"
                                            *ngIf="product.visibilityMap?.creditAmount"
                                            [matTooltip]="'financing.features.financing-processing.productPackages.texts.creditAmount'
                                            | translate" matTooltipPosition="above">
                                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.creditAmount'
                                                | translate}}</mat-label>
                                            <input matInput name="creditAmount" currencyMask [options]="currencyMaskOptions" formControlName="creditAmount">
                                            <mat-hint class="title-warning" *ngIf="!!productPackage.errors?.creditSum && product.controls.productCreditType.value === ProductPackageType.Credit">{{'financing.features.financing-processing.productPackages.texts.financingScopeWarning'
                                                | translate}}</mat-hint>
                                            <mat-hint class="title-warning" *ngIf="!!productPackage.errors?.comfortCreditSum && product.controls.productCreditType.value === ProductPackageType.ComfortCredit">{{'financing.features.financing-processing.productPackages.texts.financingScopeWarning'
                                                | translate}}</mat-hint>
                                            <mat-hint class="title-warning"
                                                *ngIf="!!productPackage.errors?.interimFinancingSum && (product.controls.productCreditType.value === ProductPackageType.BWWithFollowUpFinancing || product.controls.productCreditType.value === ProductPackageType.BWWithoutFollowUpFinancing)">
                                                {{'financing.features.financing-processing.productPackages.texts.financingScopeWarning'
                                                | translate}}</mat-hint>
                                            <mat-error *ngIf="!!product.controls.creditAmount.errors">{{ product.controls.creditAmount.errors | finProcessFormError }}</mat-error>
                                        </mat-form-field>
                                        <div *ngIf="productPackage.errors" style="margin-bottom: 30px;">
                                        </div>
                                    </div>
    
                                    <!--Garantiebetrag-->
                                    <div class="mr-3" *ngIf="product.visibilityMap?.guaranteeAmount" fxLayout="column">
                                        <mat-form-field appearance="fill" fxFlex="100"
                                            [matTooltip]="'financing.features.financing-processing.productPackages.texts.guaranteeAmount'
                                            | translate" matTooltipPosition="above">
                                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.guaranteeAmount'
                                                | translate}}
                                                <mat-icon color="primary" class="info-icon"
                                                    matTooltip="Garantiehöhe ohne gleichzeitig beantragter B&W Finanzierung (z.B. Stand alone Garantie)">info</mat-icon>
                                            </mat-label>
                                            <input matInput currencyMask [options]="currencyMaskOptions" formControlName="guaranteeAmount">
                                            <mat-hint class="title-warning" *ngIf="!!productPackage.errors?.guaranteeSum && product.controls.productCreditType.value === ProductPackageType.Guarantee">{{'financing.features.financing-processing.productPackages.texts.financingScopeWarningGuarantee'
                                                | translate}}</mat-hint>
                                            <mat-error *ngIf="product.controls.guaranteeAmount.errors">{{ product.controls.guaranteeAmount.errors | finProcessFormError }} </mat-error>
                                        </mat-form-field>
                                        <div *ngIf="productPackage.errors" style="margin-bottom: 30px;">
                                        </div>
                                    </div>
    
                                    <!--Hiervon tilgungsfreier Zeitraum-->
                                    <div fxLayout="column">
                                        <mat-form-field appearance="fill" fxFlex="100"
                                            *ngIf="product.visibilityMap?.interestOnlyPeriod"
                                            [matTooltip]="'financing.features.financing-processing.productPackages.texts.interestOnlyPeriod'
                                            | translate" matTooltipPosition="above">
                                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.interestOnlyPeriod'
                                                | translate}}</mat-label>
                                            <input matInput  currencyMask [options]="numberMaskOptions" formControlName="interestOnlyPeriod">
                                            <mat-hint style="margin-bottom: 50px;"
                                                *ngIf="(product.controls.interestOnlyPeriod.value ?? 0) > 408">{{'financing.features.financing-processing.productPackages.texts.interestOnlyPeriodWarning'
                                                | translate}}</mat-hint>
                                            <mat-error *ngIf="!!product.controls.interestOnlyPeriod.errors">{{ product.controls.interestOnlyPeriod.errors | finProcessFormError }}</mat-error>
                                        </mat-form-field>
    
                                        <div *ngIf="(product.controls.interestOnlyPeriod.value ?? 0) > 408" style="margin-bottom: 30px"></div>
                                    </div>
                                </div>
    
                                <!--Gesamtlaufzeit ohne ASD Periode-->
                                <div fxLayout="row">
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100"
                                        *ngIf="product.visibilityMap?.durationWithoutASD"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.durationWithoutASD'
                                            | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.durationWithoutASD'
                                            | translate}} *</mat-label>
                                        <input matInput currencyMask [options]="numberMaskOptions" formControlName="durationWithoutASD">
                                        <mat-hint
                                            *ngIf="(product.controls.durationWithoutASD.value ?? 0) > 408">{{'financing.features.financing-processing.productPackages.texts.durationWarning'
                                            | translate}}</mat-hint>
                                        <mat-error *ngIf="!!product.controls.durationWithoutASD.errors">{{ product.controls.durationWithoutASD.errors | finProcessFormError }}</mat-error>
                                    </mat-form-field>
    
                                    <!--Zusätzliche ASD Periode-->
                                    <mat-form-field appearance="fill" fxFlex="100"
                                        *ngIf="product.visibilityMap?.extraASDPeriod"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.extraASDPeriod'
                                            | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.extraASDPeriod'
                                            | translate}}</mat-label>
                                        <input matInput name="extraASDPeriod" currencyMask [options]="numberMaskOptionsASD" formControlName="extraASDPeriod">
                                    </mat-form-field>
    
                                </div>
    
                                <div fxLayout="row">
    
                                    <!--Sonderfinanzierung-->
                                    <mat-form-field appearance="fill" *ngIf="product.visibilityMap?.specialFinancing"
                                        fxFlex="100"
                                        class="mr-3" [matTooltip]="'financing.features.financing-processing.productPackages.texts.specialFinancing'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.specialFinancing'
                                            | translate}} <mat-icon color="primary" class="info-icon"
                                                matTooltip="Ust-Vorfinanzierungs-Anteil über Grenzwert (dzt. EUR 50.000) sowie Zwischenfinanzierungs-Anteil über belehnbaren Immobilienwert laut Handbuch">info</mat-icon>
                                        </mat-label>
                                        <input matInput currencyMask [options]="currencyMaskOptions" formControlName="specialFinancing">
                                        <mat-hint
                                            *ngIf="(product.controls.specialFinancing.value ?? 0) > (product.controls.guaranteeAmount.value ?? 0)"
                                            class="guarantee-warning">{{'financing.features.financing-processing.productPackages.texts.guaranteeWarning'
                                            | translate}}</mat-hint>
                                    </mat-form-field>
    
                                    <!--Laufzeit für die Sonderfinanzierung-->
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="100"
                                        *ngIf="product.visibilityMap?.specialFinancingDuration"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.durationSpecialFinancing'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.durationSpecialFinancing'
                                            | translate}}</mat-label>
                                        <input matInput name="specialFinancingDuration" currencyMask [options]="numberMaskOptions" formControlName="specialFinancingDuration">
                                    </mat-form-field>
    
                                    <!--Sonderfinanzierung LTV relevant?-->
                                    <div *ngIf="product.visibilityMap?.specialFinancingLTV">
                                        <mat-checkbox class="mr-2" color="primary" formControlName="specialFinancingLTV"
                                            [disabled]="(fieldReadonly$ | async) ?? true"></mat-checkbox>
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.specialFinancingLTV'
                                            | translate}}</mat-label>
                                    </div>
                                </div>
    
                                <!--Summe-->
                                <div fxLayout="row" class="mr-3" fxFlex="50"
                                    *ngIf="product.visibilityMap?.creditSpecialFinancingSum">
                                    <div class="mr-1">
                                        {{'financing.features.financing-processing.productPackages.texts.sum'
                                        | translate}}: </div>
                                    <p>{{(product.controls.creditAmount.value ?? 0) + (product.controls.specialFinancing.value ?? 0) | formatCurrency}}</p>
                                    <!--(Kreditbetrag & Sonderfinanzierung):-->
                                </div>
    
                            </div>
    
                            <!--Garantiesumme-->
                            <div class="mt-5" fxLayout="row" *ngIf="product.visibilityMap?.guaranteeSum">
                                <div fxLayout="column">
                                    <div fxLayout="row">
                                        <mat-label
                                            class="mr-2">{{'financing.features.financing-processing.productPackages.texts.guaranteeSum'
                                            | translate}}: </mat-label>
                                        <p [class.title-warning]="(product.controls.specialFinancing.value ?? 0) > (product.controls.guaranteeAmount.value ?? 0)">
                                            {{(product.controls.guaranteeAmount.value ?? 0) - (product.controls.specialFinancing.value ?? 0) | formatCurrency}}
                                        </p>
                                        <!--(Garantiebetrag - Sonderfinanzierung)-->
                                        <mat-icon class="ml-1" color="primary"
                                            matTooltip="Garantiehöhe in Zusammenhang mit einer B&W Finanzierung (Darlehen inkl. Zwischenfinanzierung)">info</mat-icon>
                                    </div>
    
                                </div>
                            </div>
    
                            <!--Zinssätze-->
                            <div fxLayout="column" *ngIf="product.controls.productCreditType.value !== ProductPackageType.Guarantee">
                                <h4 class="titles">
                                    <b>{{'financing.features.financing-processing.productPackages.texts.interestRates' |
                                        translate}}</b>
                                </h4>
    
                                <div fxLayout="row">
                                    <!--Kim-V relevanter Echtzinssatz-->
                                    <mat-form-field class="mr-3" fxFlex="50" appearance="fill"
                                        *ngIf="product.visibilityMap?.kimVRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.kimV'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.kimV' |
                                            translate}}</mat-label>
                                        <input matInput name="kimVRate" currencyMask [options]="percentageMaskOptions" formControlName="kimVRate">
                                        <mat-error *ngIf="!!product.controls.kimVRate.errors">{{  product.controls.kimVRate.errors | finProcessFormError }}</mat-error>
                                    </mat-form-field>
    
                                    <!--Variabler Echtzinssatz" -> Referenzzinssatz + Aufschlag-->
                                    <mat-form-field appearance="fill" fxFlex="50"
                                        *ngIf="product.visibilityMap?.variableRealInterestRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.variableRealInterestRate'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.variableRealInterestRate'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="variableRealInterestRate">
                                    </mat-form-field>
                                </div>
    
                                <div fxLayout="row">
                                    <!--Fiktiver EUR Zinssatz variabel-->
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="50"
                                        *ngIf="product.visibilityMap?.fictionalEURInterestRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.fictionalEURInterestRate'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.fictionalEURInterestRate'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="fictionalEURInterestRate">
                                    </mat-form-field>
    
                                    <!--Fiktiver Zinssatz fix 25 Jahre-->
                                    <mat-form-field appearance="fill" fxFlex="50"
                                        *ngIf="product.visibilityMap?.fictionalInterestRateFix25Years"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.fictionalInterestRateFix25Years'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.fictionalInterestRateFix25Years'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="fictionalInterestRateFix25Years">
                                    </mat-form-field>
                                </div>
    
                                <div fxLayout="row">
                                    <!--ohne Deckungsregister-->
                                    <div fxLayout="column"
                                        [fxFlex]="product.controls.productCreditType.value === ProductPackageType.ComfortCredit ? '100' : '50'">
                                        <div class="mb-3" *ngIf="product.visibilityMap?.withoutCoverageRegister"
                                            fxFlex="100">
                                            <mat-checkbox class="mr-2" color="primary" formControlName="withoutCoverageRegister">
                                            </mat-checkbox>
                                            <mat-label
                                                [ngClass]="{'disabled-grey': product.controls.subsidizedLoan.value }">{{'financing.features.financing-processing.productPackages.texts.withoutCoverageRegister'
                                                | translate}}</mat-label>
                                        </div>
    
                                        <!--Förderdarlehen-->
                                        <div class="mb-3" *ngIf="product.visibilityMap?.subsidizedLoan" fxFlex="100">
                                            <mat-checkbox class="mr-2" color="primary" formControlName="subsidizedLoan">
                                            </mat-checkbox>
                                            <mat-label
                                                [ngClass]="{'disabled-grey': product.controls.withoutCoverageRegister.value ?? true }">{{'financing.features.financing-processing.productPackages.texts.subsidizedLoan'
                                                | translate}}</mat-label>
                                        </div>
                                    </div>
    
                                    <div>
    
                                        <!--Abweichender fiktiver Zinssatz (ohne Deckungsregister)-->
                                        <!--depending on checkboxes ohne deckungsregister-->
                                        <mat-form-field class="ml-2" appearance="fill" *ngIf="product.visibilityMap?.deviatingFictionalInterestRate"
                                            [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingFictionalInterestRate'
                                        | translate" matTooltipPosition="above">
                                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingFictionalInterestRate'
                                                | translate}}</mat-label>
                                            <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingFictionalInterestRate">
                                        </mat-form-field>
    
                                        <!--Abweichender Zinssatz (Förderdarlehen)-->
                                        <!--depending on checkboxes förderdarlehen-->
                                        <mat-form-field class="ml-2" appearance="fill" *ngIf="product.visibilityMap?.deviatingInterestRateSubsidizedLoan"
                                            [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingInterestRateSubsidizedLoan'
                                        | translate" matTooltipPosition="above">
                                            <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingInterestRateSubsidizedLoan'
                                                | translate}}</mat-label>
                                            <input matInput [options]="percentageMaskOptions" currencyMask formControlName="deviatingInterestRateSubsidizedLoan">
                                        </mat-form-field>
                                    </div>
                                </div>
    
                                <div>
                                    <!--Abweichender Zinssatz (Aufschlag für Vergleichsrechnung)-->
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="50"
                                        *ngIf="product.visibilityMap?.deviatingInterestRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingInterestRate'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingInterestRate'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingInterestRate">
                                    </mat-form-field>
    
                                    <!--Abweichender fiktiver Fixzinssatz über Gesamtlaufzeit-->
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="50"
                                        *ngIf="product.visibilityMap?.deviatingFictionalFixRateOverDuration"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixRateOverDuration'
                                      | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixRateOverDuration'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingFictionalFixRateOverDuration">
                                    </mat-form-field>
    
                                    <!--Vereinbarter Zinssatz für Anschluss-/Sonderfinanzierung-->
                                    <mat-form-field appearance="fill" class="mr-3"
                                        *ngIf="product.visibilityMap?.agreedInterestRateFollowUpOrSpecialFinancing"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.agreedInterestRateFollowUpOrSpecialFinancing'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.agreedInterestRateFollowUpOrSpecialFinancing'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="agreedInterestRateFollowUpOrSpecialFinancing">
                                    </mat-form-field>
    
                                    <!--Abweichender EUR-Zinssatz-->
                                    <mat-form-field appearance="fill" class="mr-3"
                                        *ngIf="product.visibilityMap?.deviatingEURInterestRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingEURInterestRate'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingEURInterestRate'
                                            | translate}}</mat-label>
                                        <input matInput currencyMask [options]="percentageMaskOptions" formControlName="deviatingEURInterestRate">
                                    </mat-form-field>
    
                                    <!--Abweichender fiktiver Fixzinssatz über Gesamtlaufzeit-->
                                    <mat-form-field appearance="fill" class="mr-3" fxFlex="50"
                                        *ngIf="product.visibilityMap?.deviatingFictionalFixInterestRate"
                                        [matTooltip]="'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixInterestRate'
                                        | translate" matTooltipPosition="above">
                                        <mat-label>{{'financing.features.financing-processing.productPackages.texts.deviatingFictionalFixInterestRate'
                                            | translate}}</mat-label>
                                        <input matInput name="deviatingFictionalFixInterestRate" currencyMask [options]="percentageMaskOptions" formControlName="deviatingFictionalFixInterestRate">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </mat-tab>
            <mat-tab disabled="true">
                <ng-template mat-tab-label >
                    <button mat-mini-fab color="primary" class="add-product-button" (click)="$event.stopPropagation()"
                        (click)="openProductPackageDialog(false, productPackage.controls.productPackageID.value)"
                        [disabled]="(fieldReadonly$ | async) ?? true">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-expansion-panel>
</div>