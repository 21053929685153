import { WorkflowType } from '../../enums';
import { IMidtermModel } from '../../interfaces';

export const CUSTOMER_NATURAL_PERSON_MODEL: IMidtermModel = {
    /**
     * Name für Übersetzung
     */
    midtermName: 'customerNaturalPersonModel',

    accademicTitle: { type: WorkflowType.String },

    activityStartingDateFreelance: { type: WorkflowType.String },

    employedSince: { type: WorkflowType.String },

    maritalStatus: { type: WorkflowType.String },

    nobleTitle: { type: WorkflowType.String },

    originalSurnameForMarriedWoman: { type: WorkflowType.String },

    supplementaryName: { type: WorkflowType.String },
}
