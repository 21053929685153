/**
 * Angenommene Laufzeit des KomfortKredit Plus in Monaten
 */
export const COMFORTCREDIT_PLUS_ASSUMEDDURATION = 120;

/**
 * Pensionsalter
 */
export const RETIREMENT_AGE = 65;

/**
 * Minimale Angenommene Laufzeit in Monaten
 */
export const MIN_ASSUMEDDURATION = 120;

/**
 * Maximale Angenommene Laufzeit in Monaten
 */
export const MAX_ASSUMEDURATION = 420;

/**
 * Standard Eintragungsgebühr in %
 */
export const DEFAULT_REGISTRATIONCHARGES_PERCENT = 1.44;

/**
 * Standard Schätzgebühr in %
 */
export const DEFAULT_ESTIMATECHARGES_PERCENT = 0.625;

/**
 * Standard Grunderwerbssteuer in %
 */
export const DEFAULT_REALESTATETAXES_FEE = 3.5;

/**
 * Standard Eintragung Eigentumsrecht in %
 */
export const DEFAULT_LANDREGISTERENTRY_FEE = 1.1;

/**
 * Standard Errichtung Kaufvertrag/Treuhand in %
 */
export const DEFAULT_NOTARYCOSTS_FEE = 3;

/**
 * Maximal Errichtung Kaufvertrag/Treuhand in %
 */
export const MAX_NOTARYCOSTS_FEE = 10;

/**
 * Standard Maklergebühr in %
 */
export const DEFAULT_BROKERAGECOSTS_FEE = 3.6;

/**
 * Maximale Maklergebühr in %
 */
export const MAX_BROKERAGECOSTS_FEE = 3.6;
