/**
 * Datentypen für überschriebene Werte
 */
export enum ValueStorageType {
    String,
    Int,
    Decimal,
    Bool,
    Guid,
    DateTimeOffset,
}
