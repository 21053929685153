export enum TotalObligation {
    /**
     * < 100.000 Euro
     */
    GT100Tsd = 1,

    /**
     * >= 100.000 Euro
     */
    LT100Tsd = 2
}
