export enum ValuationClassType {
    /**
     * LIEBE light
     */
    LiebeLight,

    /**
     * LIEBE light
     */
    LiebePrivat,

    /**
     * BA(IRG)-Tool
     */
    BaIrgTool,

    /**
     * BA-Tool
     */
    BaTool,

    /**
     * Sonstige
     */
    Other,
}
