import { EnumSettings } from './../enum.settings';

/**
 * Wintergarten
 */
export enum WintergardenType {
    /**
     * Keiner
     */
    None = 0,

    /**
     * Beheizbar
     */
    Heated = 1,

    /**
     * Nicht beheizbar
     */
    NotHeated = 2,
}

export namespace WintergardenType {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {WintergardenType | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: WintergardenType | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'WintergardenType', WintergardenType, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('WintergardenType', plocale);
}
