import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, map, mergeMap, of } from 'rxjs';


import { MessagesLoaded } from '../../states';

import { IMessage } from './../../interfaces/messages.interface';

/**
 * Service zum Laden und Senden von Nachrichten
 */
@Injectable()
export class MessagesService {

    public test = 1;
    private currentMessages: IMessage[];

    /**
     * Standardkonstruktor
     *
     * @param {Store} store Store Injector
     * @param {HttpClient} httpClient HttpClient Injector
     * @param {ConfigService} config ConfigService Injector
     * @param {AuthorizationService} authorizationService AuthorizationService Injector
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,

    ) {
        this.currentMessages = [];
    }

    /**
     * Holt alle Nachrichten für eine Finanzierung
     *
     * @param {UUID} id id
     * @returns {Observable<IMessage | undefined>} Request als Observable
     */
    public getMessages(id: UUID): Observable<IMessage[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IMessage[]>(`${this.config.getEnvironment().apiUrl}/Messages`, {
                    params: {
                        id: id,
                    },
                }).pipe(
                    mergeMap(messages => this.store.dispatch(new MessagesLoaded(messages)).pipe(
                        map(() => messages),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Sendet eine Nachricht
     *
     * @param {Partial<IMessage>} msg Nachricht
     * @returns {Observable<void>} Request als Observable
     */
    public sendMessage(msg: Partial<IMessage>): Observable<void> {
        const request = {
            financingMapId: msg.financingMapId,
            text: msg.text,
            to: msg.to,
        }
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/Messages`, request).pipe(
                    mergeMap(() => this.httpClient.get<IMessage[]>(`${this.config.getEnvironment().apiUrl}/Messages?id=${msg.financingMapId}`)),
                    mergeMap(msgResult => this.store.dispatch(new MessagesLoaded(msgResult))),
                    mergeMap(() => of(void 0)),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Fragt alle Nachrichten für eine Finanzierung zum herunterladen an
     *
     * @param {UUID} id id
     * @param {boolean} isInternal interner Chat (oder Kundenchat)
     * @returns {Observable<Blob | undefined>} Request als Observable
     */
    public getMessageExport(id: UUID, isInternal: boolean): Observable<Blob | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/GlobalFunctions/getcommunicationexport`, {
                    params: {
                        id: id,
                        isInternal: isInternal,
                    },
                    responseType: 'blob',
                }),
                of(undefined),
            )),
        )
    }

}
