import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, Subscription, iif, of, timer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ConfigService, HelperService } from 'shared/util';

import { LoginStateType } from '../../enums';
import { UserService } from '../user/user.service';

import { IPGToken, IToken } from './../../interfaces';
import { AuthenticationState, IAuthStateParentDefinition, IAuthenticationStateModel, IUserStateModel, Login, Logout, TokenExpired } from './../../states';

/**
 * Service für Authentifizierung
 */
@Injectable()
export class AuthenticationService {

    private tokenExpireTimerSubscription?: Subscription;

    private loginSuccessful = new Subject<void>();

    /**
     * Gibt einen Observable zurück, der nach einem erfolgreichem Login emitted
     *
     * @returns {Observable<void>} Observable, der nach einem erfolgreichem Login emitted
     */
    public get loginSuccessful$(): Observable<void> {
        return this.loginSuccessful.asObservable();
    }

    /**
     * Gibt als Observable zurück, ob der Nutzer eingeloggt ist als
     *
     * @returns {Observable<LoginStateType>} Ist der Nutzer eingeloggt
     */
    public get loginState$(): Observable<LoginStateType> {
        return this.store.select((it: IAuthStateParentDefinition) => it).pipe(
            map(it => AuthenticationService.checkLoginState(it.authentication, it.user)),
        );
    }

    /**
     * Gibt zurück, ob der Nutzer eingeloggt ist als
     *
     * @returns {LoginStateType} Ist der Nutzer eingeloggt
     */
    public get loginState(): LoginStateType {
        const states = this.store.selectSnapshot((it: IAuthStateParentDefinition) => it);
        return AuthenticationService.checkLoginState(states.authentication, states.user);
    }

    /**
     * Gibt zurück ob es sich im einen externen Login handelt
     *
     * @returns {boolean} Ob es sich um einen externen Login handelt
     */
    public get isExternalLogin(): boolean {
        const states = this.store.selectSnapshot((it: IAuthStateParentDefinition) => it);
        return states.authentication.externalLogin ?? false;
    }

    /**
     * Gibt das Token zurück
     *
     * @returns {string | undefined} Token
     */
    public get token(): string | undefined {
        return this.store.selectSnapshot(AuthenticationState.token)?.token;
    }

    /**
     * Konstruktor
     *
     * @param {UserService} userService UserService-Injektor
     * @param {Store} store Store-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {Router} router Router-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     */
    public constructor(
        private userService: UserService,
        private store: Store,
        private config: ConfigService,
        private router: Router,
        private httpClient: HttpClient,
    ) {
    }

    /**
     * Erstellt ein Token mit einer bestimmten Laufzeit
     *
     * @param {string} username Nutzername
     * @param {string} password Passwort
     * @param {number} lifetime Laufzeit
     * @returns {IToken} Token
     */
    private static createToken(username: string, password: string, lifetime: number): IToken {
        return {
            created: new Date().toISOString(),
            expire: new Date(Date.now() + lifetime).toISOString(),
            token: btoa(`${username}:${password}`),
        }
    }

    /**
     * Prüft den aktuellen Zustand der Authentifizierung
     *
     * @param {IAuthenticationStateModel} authenticationState Aktueller Zustand der Authentifizierung
     * @param {IUserStateModel} userState Daten des aktuellen Nutzers
     * @returns {LoginStateType} Flag, ob Nutzer eingeloggt ist
     */
    private static checkLoginState(authenticationState: IAuthenticationStateModel, userState: IUserStateModel): LoginStateType {
        if (authenticationState.token !== undefined && new Date(authenticationState.token.expire) >= new Date() && HelperService.hasValue(userState.data) && userState.data.confirmed) {
            return LoginStateType.LoggedIn;
        }
        else if (authenticationState.token !== undefined && new Date(authenticationState.token.expire) >= new Date()) {
            return LoginStateType.Locked;
        }
        return LoginStateType.NotLoggedIn;
    }

    /**
     * Initialisiert die Timer für die Tokens
     *
     * @returns {Observable} Abschlussobservable
     */
    public initialize(): Observable<void> {
        return this.store.selectOnce((state: IAuthStateParentDefinition) => state.authentication).pipe(
            tap(authentication => this.setSubscriptions(authentication.token)),
            map(() => void 0),
        );
    }

    /**
     * Kann aufgerufen werden, um nach Entsperrung zu benachrichtigen
     */
    public notifySuccessfullyLoggedIn(): void {
        this.loginSuccessful.next();
    }

    /**
     * Loggt einen Nutzer ein
     *
     * @param {string} email Nutzername
     * @param {string} password Passwort
     * @returns {LoginStateType} War Login erfolgreich
     */
    public login(email: string, password: string): Observable<LoginStateType> {
        return this.httpClient.post(`${this.config.getEnvironment().baseUrl}/Authorization/InternalLogin`, {
            email,
            password,
        }, { responseType: 'text'}).pipe(mergeMap(
            token => {
                const loginStatus = this.getLoginStatusFromToken(token);
                if (loginStatus === LoginStateType.NotLoggedIn) {
                    return of(loginStatus);
                }

                const userData = this.parseTokenData(token);
                const wrappedToken = this.createITokenFromPGToken(token, userData);

                return this.store.dispatch(new Login({ token: wrappedToken, externalLogin: false})).pipe(
                    tap(() => this.setSubscriptions(wrappedToken)),
                    mergeMap(() => this.userService.loadUserData()),
                    map(() => loginStatus),
                    tap(() => this.loginSuccessful.next()),
                )
            },
        ));
    }

    /**
     * Externer Login über SAML2 Provider
     *
     * @param {string} jwt JSON Web Token vom Backend
     * @returns {Observable} Rückgabewert
     */
    public externalLogin(jwt: string): Observable<LoginStateType> {

        const userDataParsed = this.parseTokenData(jwt);

        if (userDataParsed === undefined) {
            return of(LoginStateType.NotLoggedIn);
        }

        const token: IToken = this.createITokenFromPGToken(jwt, userDataParsed);

        this.setSubscriptions(token);
        return this.store.dispatch(new Login({ token, externalLogin: true})).pipe(
            mergeMap(() => iif(
                () => userDataParsed?.confirmed,
                this.userService.loadUserData(),
                of(userDataParsed),
            )),
            map(result => (result?.confirmed ? LoginStateType.LoggedIn : LoginStateType.Locked)),
            tap(() => {
                this.loginSuccessful.next();
            }),
        );
    }

    /**
     * Loggt einen Nutzer aus
     */
    public logout(): void {
        const id = this.userService.user?.id;
        const isExternalLogin = this.isExternalLogin;

        this.store.dispatch(new Logout()).subscribe(async () => {

            if (isExternalLogin) {
                window.open(`${this.config.getEnvironment().baseUrl}/Authorization/LogoutWithExternalAccount?Id=${id}`, '_self')
            } else {
                await this.router.navigateByUrl('/auth/login');
            }
        });
    }

    /**
     * Setzt die Subscriptions für das Ablaufen von Tokens
     *
     * @param {IToken | undefined} token Authentifizierungstoken
     */
    private setSubscriptions(token?: IToken): void {
        if (this.tokenExpireTimerSubscription !== undefined) {
            this.tokenExpireTimerSubscription.unsubscribe();
            this.tokenExpireTimerSubscription = undefined;
        }

        if (token !== undefined && new Date(token.expire) > new Date()) {
            this.tokenExpireTimerSubscription = timer(new Date(token.expire)).pipe(
                mergeMap(() => this.store.dispatch(new TokenExpired())),
            ).subscribe();
        }
    }

    /**
     * Überprüft den Loginstatus eines Tokens
     *
     * @param {string} token Token
     * @returns {LoginStateType} Loginstatus
     */
    private getLoginStatusFromToken(token?: string): LoginStateType {
        if (token === undefined) {
            return LoginStateType.NotLoggedIn;
        }

        const userData = this.parseTokenData(token);

        return userData.confirmed ? LoginStateType.LoggedIn : LoginStateType.Locked;
    }

    /**
     * Erstellt ein IToken für den Authentication State aus einem PG Token
     *
     * @param {string} jwt Vollständiges Token
     * @param {IPGToken} userData Geparster Inhalt des Tokens
     * @returns {IToken} Token für den State
     */
    private createITokenFromPGToken(jwt:string, userData: IPGToken): IToken {
        return {
            token: jwt,
            expire: userData.exp.toISOString(),
            created: new Date().toISOString(),
        };
    }

    /**
     * Parst ein JWT für den externen Login
     *
     * @param {string} token JWT Token in Base64
     * @returns {IPGToken} Geparstes Token
     */
    // eslint-disable-next-line class-methods-use-this
    private parseTokenData(token: string): IPGToken {
        const parsed = HelperService.decodeJWT<Record<string, string | number>>(token);

        const pgToken: Partial<IPGToken> = {};

        for (const key of Object.keys(parsed)) {
            const lowerCaseKey = key.toLowerCase() as keyof IPGToken;

            let value = parsed[key];
            let parsedValue: string | boolean | Date | number = value;

            if (lowerCaseKey === 'confirmed') {
                if (typeof value === 'string') {
                    value = value.toLowerCase();
                }
                parsedValue = value === 'false' ? false : true;
            }

            else if (lowerCaseKey === 'localuser') {
                if (typeof value === 'string') {
                    value = value.toLowerCase();
                }
                parsedValue = value === 'false' ? false : true;
            }

            else if (lowerCaseKey === 'exp') {
                if (typeof value === 'string') {
                    parsedValue = parseInt(value, 10);
                }
                parsedValue = new Date((parsedValue as number) * 1000);
            }

            Object.assign(pgToken, { [lowerCaseKey]: parsedValue});
        }

        return pgToken as IPGToken;
    }
}
