import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { DebitRatesLoaded } from '../../states/debit-rates/debit-rates.action';
import { IDebitRatesStateModel } from '../../states/debit-rates/debit-rates.state';


/**
 * Service für Konditionen
 */
@Injectable()
export class DebitRatesService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} httpClient  HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) {}

    /**
     * Lädt die Konditionen
     *
     * @returns {IDebitRatesStateModel | undefined} Konditionen
     */
    public get(): Observable<IDebitRatesStateModel | undefined> {
        return this.authorizationService.checkPermissions$(Role.AdministrationAreaReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IDebitRatesStateModel>(`${this.config.getEnvironment().apiUrl}/DebitRatesAndAdaptions`).pipe(
                    mergeMap((response: IDebitRatesStateModel) => this.store.dispatch(new DebitRatesLoaded(response)).pipe(
                        map(() => response),
                    )),
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Aktualisiert die Konditionen
     *
     * @param {IDebitRatesStateModel} debitRates Konditionen
     * @returns {Observable} Observable Antwort ohne Inhalt
     */
    public update(debitRates: IDebitRatesStateModel): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.ConditionsAdministrator, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/DebitRatesAndAdaptions`, debitRates).pipe(
                    mergeMap(() => this.store.dispatch(new DebitRatesLoaded(debitRates))),
                ),
                of(undefined),
            )),
        );
    }
}
