<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="end">
        <button [disabled]="editingReadonly$ | async" mat-raised-button class="add-btn mt-4 mb-2 mr-2" color="primary" [matMenuTriggerFor]="menu">
            {{ 'asset.assetContainer.newAsset' | translate }}
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="addNewAsset(entityClassType.AssetRealEstate)">{{ 'asset.assetContainer.mortgage' | translate }}</button>
            <button mat-menu-item (click)="addNewAsset(entityClassType.AssetInsurance)">{{ 'asset.assetContainer.insurance' | translate }}</button>
            <button mat-menu-item (click)="addNewAsset(entityClassType.AssetAccount, true)">{{ 'asset.assetContainer.bonds' | translate }}</button>
            <button mat-menu-item (click)="addNewAsset(entityClassType.AssetAccount)">{{ 'asset.assetContainer.account' | translate }}</button>
        </mat-menu>
    </div>

    @if (assetsEmpty$ | async) {
    <div fxLayout="row" fxLayoutAlign="center center" class="no-assets p-4">
        {{ 'asset.assetContainer.noAssets' | translate }}
    </div>
    } @else {
    <div class="mt-4">
        @defer {
        <ng-container *ngIf="assetRealEstates$ | async as assetRealEstates">
            <div *ngFor="let assetRealEstate of assetRealEstates; trackBy: panelTrackedBy; let last = last" #asset>
                <finprocess-asset-real-estate [assetRealEstate]="assetRealEstate"></finprocess-asset-real-estate>
            </div>
        </ng-container>
        <ng-container *ngIf="assetInsurances$ | async as assetInsurances">
            <div *ngFor="let insurance of assetInsurances; trackBy: panelTrackedBy; let last = last" #asset>
                <finprocess-asset-insurance [assetInsurance]="insurance"></finprocess-asset-insurance>
            </div>
        </ng-container>
        <ng-container *ngIf="assetAccounts$ | async as assetAccounts">
            <div *ngFor="let account of assetAccounts; trackBy: panelTrackedBy; let last = last" #asset>
                <finprocess-asset-account [assetAccount]="account"></finprocess-asset-account>
            </div>
        </ng-container>
        }
    </div>
    }
</div>
