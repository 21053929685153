import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { WaiterModule } from '@ntag-ef/waiter';
import { AuthChecksModule } from 'auth';
import { FlexLayoutModule } from 'ngx-flexible-layout'
import { PipesModule } from 'shared/features/pipes';
import { SharedUiModule } from 'shared/ui';

import { SmartdocDataModule } from '../../data';

import { SmartDocProcessingComponent } from './components/smart-doc-processing/smart-doc-processing.component';
import { SmartdocComponent } from './components/smartdoc/smartdoc.component';
import { SmartdocSelectionComponent } from './components/smartdoc-selection/smartdoc-selection.component';
import { SmartDocWaiterDialogComponent } from './components/smartdoc-waiter-dialog/smartdoc-waiter-dialog.component';
import { ThumbnailDialogComponent } from './components/thumbnail-dialog/thumbnail-dialog.component';
import { SmartdocRoutingModule } from './smartdoc-routing.module';

/**
 * Modul für SmartDoc
 */
@NgModule({
    declarations: [
        SmartdocComponent,
        SmartdocSelectionComponent,
        SmartDocWaiterDialogComponent,
        SmartDocProcessingComponent,
        ThumbnailDialogComponent,
    ],
    imports: [
        TranslateModule.forChild(),
        EnumTranslationModule.forChild(),
        CommonModule,
        SmartdocRoutingModule,
        FlexLayoutModule,
        SmartdocDataModule,
        FormsModule,
        AuthChecksModule,
        SharedUiModule,
        PipesModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        MatCardModule,
        WaiterModule.forChild(),
        NotificationsModule.forChild(),
        DragDropModule,
        MatDialogModule,
    ],
})

export class SmartdocModule { }
