import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { LiabilityConfigurationService } from 'app/modules/administration/data/services/liability-configuration/liability-configuration.service';
import { Role } from 'app/modules/auth/data';
import { HelperService, ILiabilityConfiguration } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { take } from 'rxjs';

/**
 * Liability Componente
 */
@Component({
    selector: 'finprocess-liability-configuration',
    templateUrl: './liability-configuration.component.html',
    styleUrls: ['./liability-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilityConfigurationComponent implements OnInit {

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    public liabilityConfiguration: ILiabilityConfiguration | undefined;

    public liabilityForm: FormGroup | undefined;

    public isLoading = true;
    /**
     * Percentage Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;

    /**
     * Ob das Formular gesperrt ist
     */
    public locked = true;

    /**
     * Standard Konstruktor
     *  
     * @param {FormBuilder} formBuilder FormBuilder Injector
     * @param {string} locale Locale Code
     * @param {LiabilityConfigurationService} liabilityService LiabilityConfigurationService Injector
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef Injector
     */
    public constructor(
    private formBuilder: FormBuilder,
    @Inject(LOCALE_ID) locale: string,
    private liabilityService: LiabilityConfigurationService,
    private waiterService: WaiterService,
    private notification: NotificationService,
    private translate: TranslateService,
    private cRef: ChangeDetectorRef,
    ) 
    {
        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            allowNegative: true,
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });
    }

    /**
     * Angular Lifecycle Hook
     */
    public ngOnInit(): void {
        this.liabilityService.getConfiguration().pipe(
            take(1),
        ).subscribe(configuration => {
            this.initLiabilityForm(configuration);
        });
    }

    /**
     * Init liability form 
     * 
     * @param {ILiabilityConfiguration} liability configuration 
     */
    private initLiabilityForm(liability?: ILiabilityConfiguration): void {
        if (!!liability) {
            this.liabilityForm = this.formBuilder.group({
                id: [liability?.id], 
                comfortCreditFictionalRate: [liability?.comfortCreditFictionalRate, Validators.required],
                constructionFollowUpFinancingBuildingLoanFictionalRate: [liability?.constructionFollowUpFinancingBuildingLoanFictionalRate, Validators.required],
                constructionFollowUpFinancingFictionalRate: [liability?.constructionFollowUpFinancingFictionalRate, Validators.required],
                constructionInterimFinancingFictionalRate: [liability?.constructionInterimFinancingFictionalRate, Validators.required],
                constructionPrefinancingInvestmentFlatHighFictionalRate: [liability?.constructionPrefinancingInvestmentFlatHighFictionalRate, Validators.required],
                constructionPrefinancingInvestmentFlatLowFictionalRate: [liability?.constructionPrefinancingInvestmentFlatLowFictionalRate, Validators.required],
                creditCardFictionalRate: [liability?.creditCardFictionalRate, Validators.required],
                creditFictionalRate: [liability?.creditFictionalRate, Validators.required],
                developmentLoanFictionalRate: [liability?.developmentLoanFictionalRate, Validators.required],
                oneTimeCashLoanFictionalRate: [liability?.oneTimeCashLoanFictionalRate, Validators.required],
                overdraftFictionalRate: [liability?.overdraftFictionalRate, Validators.required],
                subsidizedLoanFictionalRate: [liability?.subsidizedLoanFictionalRate, Validators.required],
                companyCreditFictionalRate: [liability?.companyCreditFictionalRate],
                guaranteeConstructionFictionalRate: [liability?.guaranteeConstructionFictionalRate],
                guaranteeStandAloneFictionalRate: [liability?.guaranteeStandAloneFictionalRate],
                kfzLeasingFictionalRate: [liability?.kfzLeasingFictionalRate],
            });
            this.isLoading = false;
            this.cRef.markForCheck();
            this.cRef.detectChanges();
            this.disableForm();
        }
    }

    /**
     * Schickt Daten an Server
     */
    public onSave(): void {
        this.waiterService.show();
        if (!!this.liabilityForm && this.liabilityForm.valid) {

            this.notification.confirmYesNo(this.translate.instant('administration.features.liabilityConfiguration.confirmationTitle'), this.translate.instant('administration.features.liabilityConfiguration.confirmationMessage')).subscribe(result => {
                
                if (result === 'submit') {
                    
                    const liabilityToSend = this.liabilityForm?.getRawValue() as ILiabilityConfiguration;

                    return this.liabilityService.saveConfiguration(liabilityToSend).subscribe({
                        next: () => {
                            this.waiterService.hide();
                            this.liabilityForm?.markAsPristine();
                            this.cRef.markForCheck();
                            this.cRef.detectChanges();
                            this.disableForm();
                            this.notification.toast(this.translate.instant('administration.features.liabilityConfiguration.toast'));
                        },
                        error: () => {
                            this.waiterService.hide();
                            this.notification.alert(this.translate.instant('general.error'), this.translate.instant('general.unknownError'));
                        },
                    })
                }
                else {
                    this.waiterService.hide();
                    return null;
                }
            });
        }
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.liabilityForm?.enable();
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.liabilityForm?.disable();
    }
}
