import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CollateralType, EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { AssetService, AssetState, FinancingService, IAssetInsurance, SourceCollateral } from 'app/modules/financing/data';
import { HelperService, ISelectItem } from 'app/modules/shared';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 * Asset Insurance
 */
@Component({
    selector: 'finprocess-asset-insurance',
    templateUrl: './asset-insurance.component.html',
    styleUrls: ['./asset-insurance.component.scss'],
})
export class AssetInsuranceComponent extends AssetCommonComponent implements OnInit, OnDestroy {

    @Input()
    public assetInsurance: IAssetInsurance | undefined;
    
    public mortgageTypeSelectItems: ISelectItem<number>[] = [];
    
    public assetExpand = false;

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Validators für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /*
     * CollateralType für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CollateralType = CollateralType;

    /**
     * SourceCollateral für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral;

    /**
     * Date Filter for Datepicker
     * 
     * @param {moment} date Date
     * @returns {boolean} true if date is first of month
     */
    public dateFilter = (date: moment.Moment | null): boolean => date instanceof moment && date.date() === 1;

    /**
     * Requirements failed Map
     */
    public validInsuranceMap: { [id: string]: boolean } = {};

    /**
     * Destroy-Subject
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     * @param {NotificationService} notificationService NotificationService
     * @param {TranslateService} translate TranslateService
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            enumTranslate: EnumTranslationPipe,
            assetService: AssetService,
            store: Store,
            financingService: FinancingService,
            private notificationService: NotificationService,
            private translate: TranslateService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        this.mortgageTypeSelectItems = HelperService.getSortedSelectItems(CollateralType, value => this.enumTranslate.transform(value, 'CollateralType') as string, [CollateralType.BA205, CollateralType.BA210, CollateralType.BA220, CollateralType.BA330, CollateralType.BA415, CollateralType.BA425], true);
    
        //Validate Insurances
        this.store.select(AssetState.validateAssetInsurance).pipe(takeUntil(this.onDestroy$)).subscribe(invalidInsurances => {
            this.validInsuranceMap = invalidInsurances.reduce((acc, insurances) => {
                acc[insurances.id] = insurances.valid;

                if (!insurances.valid && insurances.isActive) {
                    this.setAssetActive(insurances.id, false).subscribe(() => {
                        this.notificationService.toast(this.translate.instant('asset.assetCommon.assetDeactivated'));
                    });
                }

                return acc;
            }, {} as { [id: string]: boolean });
        });
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {any} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: any) {
        return array.find(e => e.value === id);
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
