import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Observable, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { HelperService } from 'shared/util';

import { IUser } from '../../interfaces';

import { LoginStateType, Role } from './../../enums';
import { AuthenticationService } from './../authentication/authentication.service';
import { UserService } from './../user/user.service';

/**
 * Service zur Rollenabfrage
 */
@Injectable()
export class AuthorizationService {

    /**
     * Konstruktor
     *
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {NotificationService} notificationService NotificationService-Injektor
     * @param {TranslateService} translateService TranslateService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     */
    public constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private waiterService: WaiterService) {
    }

    /**
     * Gibt an, ob der aktueller Nutzer eine oder mehrere bestimmte Rollen hat
     * Wenn mehrere Rollen bitcodiert, dann true, wenn der aktuelle Nutzer alle Rollen hat
     *
     * @param {Role} roles Rolle oder Rollen bitcodiert
     * @returns {boolean} Hat der aktueller Nutzer eine oder mehrere bestimmte Rollen
     */
    public hasRole(roles: Role): boolean {
        return this.authenticationService.loginState === LoginStateType.LoggedIn && this.userService.user !== undefined && HelperService.hasBit(this.userService.user.roles, roles);
    }

    /**
     * Liefert Observable, das angibt, ob der aktueller Nutzer eine oder mehrere bestimmte Rollen hat
     * Wenn mehrere Rollen bitcodiert, dann true, wenn der aktuelle Nutzer alle Rollen hat
     *
     * @param {Role} roles Rolle oder Rollen bitcodiert
     * @returns {Observable<boolean>} Hat der aktueller Nutzer eine oder mehrere bestimmte Rollen
     */
    public hasRole$(roles: Role): Observable<boolean> {
        return combineLatest([this.authenticationService.loginState$, this.userService.user$]).pipe(
            map(([loginState, user]) => loginState === LoginStateType.LoggedIn && user !== undefined && HelperService.hasBit(user.roles, roles)),
        );
    }

    /**
     * Gibt an, ob der aktueller Nutzer eine oder eine von mehreren bestimmten Rollen hat
     * Wenn mehrere Rollen bitcodiert, dann true, wenn der aktuelle Nutzer einer der Rollen hat
     *
     * @param {Role} roles Rolle oder Rollen bitcodiert
     * @returns {boolean} Hat der aktueller Nutzer eine oder eine von mehreren bestimmten Rollen
     */
    public hasOneRoleOf(roles: Role): boolean {
        return this.authenticationService.loginState === LoginStateType.LoggedIn && this.userService.user !== undefined && (roles === 0 || HelperService.getBitNumbers(roles).some(it => HelperService.hasBit((this.userService.user as IUser).roles, it)));
    }

    /**
     * Liefert Observable, das angibt, ob der aktueller Nutzer eine oder eine von mehreren bestimmten Rollen hat
     * Wenn mehrere Rollen bitcodiert, dann true, wenn der aktuelle Nutzer einer der Rollen hat
     *
     * @param {Role} roles Rolle oder Rollen bitcodiert
     * @returns {Observable<boolean>} Hat der aktueller Nutzer eine oder eine von mehreren bestimmten Rollen
     */
    public hasOneRoleOf$(roles: Role): Observable<boolean> {
        return combineLatest([this.authenticationService.loginState$, this.userService.user$]).pipe(
            map(([loginState, user]) => loginState === LoginStateType.LoggedIn && user !== undefined && HelperService.getBitNumbers(roles).some(it => HelperService.hasBit((user as IUser).roles, it))),
        );
    }

    /**
     * Prüft auf benötigte Rollen und zeigt ggf. ein Dialog an
     *
     * @param {Role} roles Benötigte Rollen
     * @param {boolean} needAll Gibt an, ob alle übergeben Rolen benötigt werden
     * @param {boolean} showMissingAuthorizationNotification Gibt an, ob ein Dialog auf fehlende Rechte hinweisen soll
     * @returns {Observable<boolean>} Sind benötigte Rollen vorhanden als Observable
     */
    public checkPermissions$(roles: Role, needAll = true, showMissingAuthorizationNotification = true): Observable<boolean> {
        let result = this.hasRole$(roles).pipe(
            take(1),
        );
        if (!needAll) {
            result = this.hasOneRoleOf$(roles).pipe(
                take(1),
            );
        }
        return result.pipe(
            tap(authorized => {
                if (!authorized) {
                    this.waiterService.hide();
                    if (showMissingAuthorizationNotification) {
                        this.notificationService.alert(this.translateService.instant('auth.data.missingPermissionsTitle'), this.translateService.instant('auth.data.missingPermissionsText'));
                    }
                }
            }),
        );
    }

    /**
     * Prüft auf benötigte Rollen und zeigt ggf. ein Dialog an
     *
     * @param {Role} roles Benötigte Rollen
     * @param {boolean} needAll Gibt an, ob alle übergeben Rolen benötigt werden
     * @param {boolean} showMissingAuthorizationNotification Gibt an, ob ein Dialog auf fehlende Rechte hinweisen soll
     * @returns {boolean} Sind benötigte Rollen vorhanden
     */
    public checkPermissions(roles: Role, needAll = true, showMissingAuthorizationNotification = true): boolean {
        const authorized = (needAll && this.hasRole(roles)) || (!needAll && this.hasOneRoleOf(roles));
        if (!authorized) {
            this.waiterService.hide();
            if (showMissingAuthorizationNotification) {
                this.notificationService.alert(this.translateService.instant('auth.data.missingPermissionsTitle'), this.translateService.instant('auth.data.missingPermissionsText'));
            }
        }
        return authorized;
    }
}
