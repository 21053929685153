
<div fxLayout="column" class="card">
    <div fxFlex="20">
        <div fxLayout="row" fxLayoutAlign="end center">
            <mat-icon *ngIf="dropArea?.isDeletable"
                        (click)="onDeleteDropArea()"
                        class="icons"
                        matTooltip="{{'administration.features.smartDocConfiguration.deleteArea' | translate}}">delete
            </mat-icon>
            <mat-icon *ngIf="dropArea?.hasQuickAccess"
                        (click)="onSetFavorite()"
                        class="icons"
                        matTooltip="{{'administration.features.smartDocConfiguration.quickAccessRemove' | translate}}">star
            </mat-icon>
            <mat-icon *ngIf="!dropArea?.hasQuickAccess"
                        (click)="onSetFavorite()"
                        class="icons"
                        matTooltip="{{'administration.features.smartDocConfiguration.quickAccessAdd' | translate}}">star_border
            </mat-icon>
            <mat-icon (click)="onEditDropArea()"
                        class="icons"
                        matTooltip="{{'administration.features.smartDocConfiguration.editArea' | translate}}">edit
            </mat-icon>
        </div>
    </div>
    <div class="body">
        <mat-icon class="icon-description">description</mat-icon>
    </div>
    <div class="footer">
        {{dropArea?.name}}
    </div>
</div>



