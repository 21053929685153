import { Component, Input, OnInit } from '@angular/core';
import { EntityClassType, FinancingService, IBase, IBaseOverwriteValues, OverwriteValueClassType, ValueStorageType } from 'app/modules/financing/data';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Base Input Component
 */
@Component({
    template: '',
})
export class BaseInputComponent<T extends IBase, K extends keyof T & string> implements OnInit {

    /**
     * Angezeigtes Label
     */
    @Input()
    public label!: string;

    /**
     * CSS-Klassen
     */
    @Input()
    public class = 'base-input';

    @Input()
    public hideLabel = false;

    /**
     * Ausrichtung der Komponente
     */
    @Input()
    public flexDirection = 'row wrap';

    /**
     * Ausrichtung der Komponente
     */
    @Input()
    public hideHorizontalRule = false;

    /**
     * Observable für Schreibschutz
     */
    @Input()
    public readonly!: Observable<boolean>;

    /**
     * Zugehörige Entität
     */
    @Input()
    public entity?: T;

    /**
     * Feldname
     */
    @Input()
    public fieldName!: K;

    /**
     * Identifier im DOM
     */
    public id!: string;

    /**
     * Wenn true wird ein Overwrite ausgeführt.
     * Wenn false wird der Wert direkt gespeichert
     */
    @Input()
    public overwrite = true;

    /**
     * Input um welcher Entity handelt sich (für saveInternalFields) Default FinancingMap
     */
    @Input()
    public entityClassType = EntityClassType.FinancingMap;

    /**
     * Liefert den anzuzeigenden Wert
     *
     * @returns {boolean | undefined} Boolean-Wert
     */
    public get currentValue(): T[K] | undefined {
        if (this.entity === undefined) {
            return undefined;
        }

        return this.entity[this.fieldName];
    }

    /**
     * Liefert den ursprünglichen Wert
     *
     * @returns {string | undefined} Wert
     */
    public get originalValue(): T[K] | undefined {
        if (this.entity === undefined) {
            return undefined;
        }

        return this.entity[this.fieldName];
    }


    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(protected financingService: FinancingService) {

    }

    /**
     * Angular-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.id = uuidv4();
    }

    /**
     * Saves the internal value
     * 
     * @param {unknown} value Value of the entity at key fieldName
     */
    public save(value?: T[K]): void {
        if (this.entity === undefined) {
            return;
        }

        const toSaveValue = value !== undefined && value !== this.originalValue ? value : undefined;

        this.financingService.saveInternalField<T>({
            fieldName: this.fieldName,
            entityId: this.entity.id,
            value: toSaveValue,
            entityClassType: this.entityClassType,
        }).subscribe();
    }

}

/**
 * 
 */
@Component({
    template: '',
})
export class OverwriteInputComponent<T extends IBase | IBaseOverwriteValues> extends BaseInputComponent<T, keyof T & string> {

    /**
     * Entitätsklasse zum Overwrite
     */
    @Input()
    public overwriteValueClassType!: OverwriteValueClassType;

    /**
     * Liefert den anzuzeigenden Wert
     *
     * @returns {boolean | undefined} Boolean-Wert
     */
    public get currentValue(): T[keyof T & string] | undefined {
        if (this.entity === undefined) {
            return undefined;
        }

        if ('overwriteValues' in this.entity) {
            return OverwriteHelperService.getMergedOverwriteValue(this.entity, this.fieldName, this.entity[this.fieldName], true);
        }

        return this.entity[this.fieldName];
    }

    protected valueStorageTypeInternal = ValueStorageType.String;

    /**
     * Standard Constructor
     * 
     * @param {ValueStorageType} valueStorageType Value Storage Type
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(valueStorageType: ValueStorageType, financingService: FinancingService) {
        super(financingService);
        this.valueStorageTypeInternal = valueStorageType;
    }

    /**
     * Saves the internal value
     * 
     * @param {unknown} value Value of the entity at key fieldName
     */
    public save(value?: T[keyof T & string]): void {
        if (this.entity === undefined) {
            return;
        }

        const toSaveValue = value !== undefined && value !== this.originalValue ? value : undefined;

        if (this.overwrite) {
            this.financingService.saveOverwriteValue({
                overwriteValueClassType: this.overwriteValueClassType,
                entityForOverwriteId: this.entity.id,
                fieldName: this.fieldName,
                valueStorageType: this.valueStorageTypeInternal,
                value: toSaveValue === undefined || toSaveValue === null ? undefined : toSaveValue.toString(),
            }).subscribe();
        } else {
            this.financingService.saveInternalField<T>({
                fieldName: this.fieldName,
                entityId: this.entity.id,
                value: toSaveValue,
                entityClassType: this.entityClassType,
            }).subscribe();
        }
    }
}

