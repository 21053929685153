import { IsoDate } from 'shared/util';

import { IApprovalCompetence, IBankAccount, IBranch, ICountry, ILegalisationFeeBase, ISalesPartnerCenter } from '../../interfaces';

/**
 * Aktion bei erfolgreichem Laden der Kontoverbindungen
 */
export class BankAccountsLoaded {
    public static readonly type = 'masterdata.bankAccountsLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Kontoverbindungen
     * @param {IBankAccount[]} payload.data Kontoverbindungen
     * @param {IsoDate} payload.lastModified Letzte Änderung der Kontoverbindungen
     */
    public constructor(public payload: {
        data: IBankAccount[];
        lastModified?: IsoDate;
    }) { }
}

/**
 * Aktion bei erfolgreichem Laden der Filialen
 */
export class BranchesLoaded {
    public static readonly type = 'masterdata.branchesLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Filialen
     * @param {IBranch[]} payload.data Filialen
     * @param {IsoDate} payload.lastModified Letzte Änderung der Filialen
     */
    public constructor(public payload: {
        data: IBranch[];
        lastModified?: IsoDate;
    }) { }
}

/**
 * Aktion bei erfolgreichem Laden der Länder
 */
export class CountriesLoaded {
    public static readonly type = 'masterdata.countriesLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Länder
     * @param {ICountry[]} payload.data Länder
     * @param {IsoDate} payload.lastModified Letzte Änderung der Länder
     */
    public constructor(public payload: {
        data: ICountry[];
        lastModified?: IsoDate;
    }) { }
}

/**
 * Aktion beim Ändern eines Landes
 */
export class CountryChanged {
    public static readonly type = 'masterdata.countryChanged';

    /**
     * Konstruktor
     *
     * @param {ICountry} payload Geändertes Land
     */
    public constructor(public payload: ICountry) { }
}

/**
 * Aktion bei erfolgreichem Laden der Matrix zur Ermittlung der Legalisierungsgebühr
 */
export class LegalisationFeeBasesLoaded {
    public static readonly type = 'masterdata.legalisationFeeBasesLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Matrix zur Ermittlung der Legalisierungsgebühr
     * @param {ILegalisationFeeBase[]} payload.data  Matrix zur Ermittlung der Legalisierungsgebühr
     * @param {IsoDate} payload.lastModified Letzte Änderung der Matrix zur Ermittlung der Legalisierungsgebühr
     */
    public constructor(public payload: {
        data: ILegalisationFeeBase[];
        lastModified?: IsoDate;
    }) { }
}

/**
 * Aktion bei erfolgreichem Laden der Vertriebspartnercenter
 */
export class SalesPartnerCentersLoaded {
    public static readonly type = 'masterdata.salesPartnerCentersLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Vertriebspartnercenter
     * @param {ISalesPartnerCenter[]} payload.data Vertriebspartnercenter
     * @param {IsoDate} payload.lastModified Letzte Änderung der Vertriebspartnercenter
     */
    public constructor(public payload: {
        data: ISalesPartnerCenter[];
        lastModified?: IsoDate;
    }) { }
}

/**
 * Aktion bei erfolgreichem Laden der Kompetenztabelle
 */
export class ApprovalCompetenceLoaded {
    public static readonly type = 'masterdata.approvalCompetenceLoaded';

    /**
     * Konstruktor
     *
     * @param {object} payload Kompetenztabelle
     * @param {IApprovalCompetence[]} payload.data Komepetenztabelle
     * @param {IsoDate} payload.lastModified Letzte Änderung der Kompetenztabelle
     */
    public constructor(public payload: {
        data: IApprovalCompetence[];
        lastModified?: IsoDate;
    }) { }
}
