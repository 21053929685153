import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReleaseContainer } from '@ntag-ef/release-notes/lib/interfaces/releaseContainer.interface';
import { AuthenticationService, LoginStateType } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, mergeMap, of, take, throwError } from 'rxjs';

/**
 * Service zum Laden und der Versionhistorie
 */
@Injectable({
    providedIn: 'root',
})
export class ReleaseNotesService {

    protected baseUrl = '/ReleaseNotes';

    /**
     * Standardkonstruktor
     *
     * @param {HttpClient} httpClient HttpClient Injector
     * @param {ConfigService} config ConfigService Injector
     * @param {AuthenticationService} authenticationService AuthenticationService Injector
     */
    public constructor(
        private httpClient: HttpClient,
        private config: ConfigService,
        private authenticationService: AuthenticationService,
    ) { }

    /**
     * Holt alle Release Notes aus dem json im backend
     *
     * @returns {IReleaseContainer} result
     */
    public getAllReleaseNotes(): Observable<IReleaseContainer | undefined> {
        return this.authenticationService.loginState$.pipe(
            take(1),
            mergeMap(loginState => iif(
                () => loginState === LoginStateType.LoggedIn,
                this.httpClient.get<IReleaseContainer>(`${this.config.getEnvironment().apiUrl}${this.baseUrl}/AllReleaseNotes`).pipe(
                    mergeMap(releaseNotes => of(releaseNotes)),
                ),
                of(undefined),
            ),
            ),
        );
    }

    /**
     * schaut anhand welche release notes der user zu letzt gesehen hat
     * wenn nicht aktuell ist, wird die aktuellste zurück gegeben
     *
     * @param {string} releaseVersionNumber releaseVersionNumber
     * @returns {Observable<IReleaseContainer | boolean>} return
     */
    // eslint-disable-next-line class-methods-use-this
    public getUserNewestReleaseNotes(releaseVersionNumber?: string): Observable<IReleaseContainer | boolean | undefined> {
        if (releaseVersionNumber === null) {
            releaseVersionNumber = 'no version'
        }
        return this.authenticationService.loginState$.pipe(
            take(1),
            mergeMap(loginState => iif(
                () => loginState === LoginStateType.LoggedIn,
                this.httpClient.get<IReleaseContainer>(`${this.config.getEnvironment().apiUrl}${this.baseUrl}/CheckLatestReleaseNote`, {
                    params: {
                        releaseVersion: releaseVersionNumber ?? '',
                    },
                }),
                of(undefined),
            ),
            ),
        );
    }

    /**
     * setzt beim user die zu letzte gelesen release note version
     *
     * @param  {string} userId userId
     * @param  {string} releaseVersionNumber releaseVersionNumber
     * @returns {Observable<IReleaseContainer | boolean>} return
     */
    public setUserReadLatestReleaseNote(userId: string, releaseVersionNumber: string): Observable<boolean> {
        return this.authenticationService.loginState$.pipe(
            take(1),
            mergeMap(login => {
                if (login) {
                    if (login === LoginStateType.LoggedIn) {
                        return this.httpClient.put<boolean>(`${this.config.getEnvironment().apiUrl}${this.baseUrl}/UserReadLatestReleaseNote`, null, {
                            params: {
                                userId: userId,
                                releaseVersion: releaseVersionNumber,
                            },
                        });
                    }
                }
                return throwError(() => 'error user not logged IN');
            }),
        )
        /*         return this.put(`${this.baseUrl}/UserReadLatestReleaseNote`, null, {
                    params: {
                        userId: userId,
                        releaseVersion: releaseVersionNumber,
                    },
                }) */
    }

}
