import { IDebitRatesStateModel } from './debit-rates.state';

/**
 * Aktion beim erfolgreichen Laden der Konditionen
 */
export class DebitRatesLoaded {
    public static readonly type = 'debitRates.loaded';

    /**
     * Konstruktor
     *
     * @param {IDebitRatesStateModel} payload Konditionen
     */
    public constructor(public payload: IDebitRatesStateModel) { }
}

/**
 * Aktion beim Ändern der Konditionen
 */
export class DebitRatesChanged {
    public static readonly type = 'debitRates.changed';

    /**
     * Konstruktor
     *
     * @param {IDebitRatesStateModel} payload Konditionen
     */
    public constructor(public payload: IDebitRatesStateModel) { }
}
