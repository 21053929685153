import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule, ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ISelectButtonAction } from '../../data';

/**
 * Button with a dropdown for multiple actions
 */
@Component({
    standalone: true,
    selector: 'finprocess-select-button',
    templateUrl: './select-button.component.html',
    styleUrls: ['./select-button.component.scss'],
    imports: [
        MatIconModule,
        MatButtonModule,
        OverlayModule,
        MatOptionModule,
        CommonModule,
        MatTooltipModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectButtonComponent),
            multi: true,
        },
    ],
})
export class SelectButtonComponent implements OnChanges, ControlValueAccessor {
    
    @ViewChild('selectButtonContainer')
    public selectButtonContainer?: ElementRef<HTMLDivElement>;

    @ViewChild('overlayTemplate')
    public overlayTemplate?: TemplateRef<unknown>;

    @Output()
    public selectedActionChanged: EventEmitter<ISelectButtonAction> = new EventEmitter<ISelectButtonAction>();

    @Input()
    public actions: ISelectButtonAction[] = [];

    @Input()
    public selectedAction?: ISelectButtonAction;

    @Input()
    public color?: ThemePalette;

    /**
     * Disabled status of the component
     */
    @Input()
    public disabled = false;

    /**
     * Status of the overlay, if it is open or closed.
     */
    public isOpen = false;

    /**
     * Reference to the overlay
     */
    private overlayRef?: OverlayRef;

    /**
     * Control value accesor on touched function
     */
    private onTouched?: () => void;

    /**
     * Control value accessor on change function
     */
    private onChange?: (action?: ISelectButtonAction) => void;

    /**
     * Standard constructor
     * 
     * @param {Overlay} overlay Angular CDK OverlayService injector
     * @param {ViewContainerRef} viewContainerRef ViewContainerRef injector
     */
    public constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) { }

    /**
     * Function for control value accesor interface
     * Updates the selected action
     * 
     * @param {ISelectButtonAction} action Action
     */
    public writeValue(action?: ISelectButtonAction): void {
        this.selectedAction = action;
    }

    /**
     * Registers the on change function
     * 
     * @param {(action?: ISelectButtonAction) => void} fn On Change function
     */
    public registerOnChange(fn?: (action?: ISelectButtonAction) => void): void {
        if (!!fn) {
            this.onChange = fn;
        }
    }

    /**
     * Registers the on touched function
     * 
     * @param {() => void} fn On touched function
     */
    public registerOnTouched(fn?: () => void): void {
        if (!!fn) {
            this.onTouched = fn;
        }
    }

    /**
     * Updates the disabled state
     * 
     * @param {boolean} isDisabled Disabled state
     */
    public setDisabledState?(isDisabled?: boolean): void {
        this.disabled = isDisabled ?? false;
    }

    /**
     * Sets the selected action if non are selected or if the array of actions changes
     * 
     * @param {SimpleChanges} changes Angular changes object
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.actions?.firstChange && Array.isArray(changes.actions.currentValue) && changes.actions.currentValue.length > 0 && !this.selectedAction) {
            this.selectedAction = changes.actions.currentValue[0];
        }

        if (changes.actions?.currentValue !== changes.actions?.previousValue) {
            if (Array.isArray(changes.actions.currentValue) && !changes.actions.currentValue.includes(this.selectedAction)) {
                if (changes.actions.currentValue.length > 0) {
                    this.selectedAction = changes.actions.currentValue[0];
                } else {
                    this.selectedAction = undefined;
                }
            }
        }
    }

    /**
     * Toggles the state of the overlay. Opens it if it is closed and closes it if opened.
     */
    public toggleOverlay(): void {
        if (!this.overlayTemplate || !this.selectButtonContainer) {
            return;
        }

        if (this.onTouched) {
            this.onTouched();
        }

        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().flexibleConnectedTo(this.selectButtonContainer).setOrigin(this.selectButtonContainer).withPositions([{originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'}]),
            })
        }

        if (this.overlayRef.hasAttached()) {
            this.closeOverlay();
        } else {
            const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
    
            this.overlayRef.attach(templatePortal);
            this.overlayRef.updateSize({width: this.selectButtonContainer.nativeElement.offsetWidth});
        }

    }

    /**
     * Executes the selected action
     */
    public executeAction(): void {
        if (!this.disabled && this.selectedAction?.action) {
            this.selectedAction.action();
        }
    }

    /**
     * Chooses an action from the list. Sets it as the selected option, emits the action and closes the overlay.
     * 
     * @param {ISelectButtonAction} action Selected action
     */
    public chooseOption(action: ISelectButtonAction) {
        this.selectedAction = action;
        this.selectedActionChanged.emit(action);
        this.closeOverlay();
        
        if (this.onChange) {
            this.onChange(action);
        }
    }

    /**
     * Closes the overlay
     */
    public closeOverlay(): void {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
