/**
 * Status einer Kreditanfrage in FinProcess
 */
export enum FinancingStatus {
    /**
     * Offen
     */
    Open = 10,

    /**
     * Storniert
     */
    Canceled = 20,

    /**
     * Zurückgestellt
     */
    Rejected = 30,

    /**
     * Haushaltsrechnung inkl. Zusatzblätter und Selbstauskunft gesendet
     */
    HouseholdCalculationWaitingForAcception = 32,

    /**
     * Haushaltsrechnung angenommen
     */
    HouseholdCalculationAccepted = 34,

    /**
     * Rechenbeispiel gesendet
     */
    SampleCalculationWaitingForAcception = 40,

    /**
     * Rechenbeispiel angenommen
     */
    SampleCalculationAccepted = 50,

    /**
     * Finalisieren
     */
    Finalize = 60,

    /**
     * ESIS gesendet
     */
    EsisWaitingForAcception = 70,

    /**
     * Abgeschlossen
     */
    Completed = 80,
}
