import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ComparisonType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { Role } from 'app/modules/auth/data';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig } from 'ngx-currency';
import { Observable, mergeMap, tap } from 'rxjs';

import { DebitRatesService } from './../../../../data/services/debit-rates/debit-rates.service';
import { IDebitRatesStateModel } from './../../../../data/states/debit-rates/debit-rates.state';
import { IAdministrationStateParentDefinition } from './../../../../data/states/state.definition';


/**
 * Komponente für die Administration der Konditionen
 */
@Component({
    selector: 'finprocess-debit-rates',
    templateUrl: './debit-rates.component.html',
    styleUrls: ['./debit-rates.component.scss'],
})
export class DebitRatesComponent implements OnInit {

    @Select((it: IAdministrationStateParentDefinition) => it.debitRates)
    public debitRates$!: Observable<IDebitRatesStateModel>;

    /**
     * Enum für Template Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Role = Role;

    /**
     * Formular
     */
    public form: FormGroup;

    /**
     * Ob das Formular gesperrt ist
     */
    public locked = true;

    /**
     * Formatierung für Textfelder
     */
    public factorOptions: NgxCurrencyConfig;

    /**
     * Konditionen Basiswerte für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get debitRates(): FormGroup[] {
        return (this.form.get('debitRates') as FormArray)?.controls as FormGroup[] ?? [];
    }

    /**
     * Konditionen LTV + Bonitaet für Template Nutzung
     *
     * @returns {FormArray} FormArray
     */
    public get debitRateAdaptions(): FormGroup[] {
        return (this.form.get('debitRateAdaptions') as FormArray)?.controls as FormGroup[] ?? [];
    }

    /**
     * Standard Konstruktor
     *
     * @param {DebitRatesService} debitRatesService DebitRatesService-Injektor
     * @param {FormBuilder} fb FormBuilder-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService
     * @param {string} locale Lokalisierung
     */
    public constructor(
        private debitRatesService: DebitRatesService,
        private fb: FormBuilder,
        private waiterService: WaiterService,
        private notification: NotificationService,
        private translate: TranslateService,
        @Inject(LOCALE_ID) locale: string,
    ) {
        this.form = this.fb.group({
            debitRates: this.fb.array([]),
            debitRateAdaptions: this.fb.array([]),
            marge: [null, Validators.required],
            volumen: [null, Validators.required],
        });

        this.disableForm();

        const fixedLocale = locale === 'de-AT' ? 'de' : locale;

        this.factorOptions = HelperService.getStandardPercentageMask(fixedLocale);
    }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.debitRatesService.get().subscribe();

        this.debitRates$.subscribe(debitRates => {
            this.form.get('marge')?.patchValue(debitRates.marge);
            this.form.get('volumen')?.patchValue(debitRates.volumen);

            const debitRatesForm = this.form.get('debitRates') as FormArray;
            const debitRateAdaptionsForm = this.form.get('debitRateAdaptions') as FormArray;
            debitRatesForm.clear();
            debitRateAdaptionsForm.clear();

            for (const debitRate of debitRates.debitRates) {
                const formGroup = this.fb.group({
                    interestMethod: [debitRate.interestMethod],
                    rating4: [debitRate.rating4, Validators.required],
                    bestDebitRate4: [debitRate.bestDebitRate4, Validators.required],
                    worstDebitRate3: [debitRate.worstDebitRate3, Validators.required],
                    worstDebitRate5: [debitRate.worstDebitRate5, Validators.required],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                debitRatesForm.push(formGroup);
            }

            for (const debitRateAdaption of debitRates.debitRateAdaptions) {
                const formGroup = this.fb.group({
                    type: [debitRateAdaption.type],
                    w3: [debitRateAdaption.w3, Validators.required],
                    w4: [debitRateAdaption.w4, Validators.required],
                    w5: [debitRateAdaption.w5, Validators.required],
                    value: [debitRateAdaption.value],
                });

                if (this.locked) {
                    formGroup.disable();
                } else {
                    formGroup.enable();
                }

                debitRateAdaptionsForm.push(formGroup);
            }
        });
    }

    /**
     * Wechselt zwischen gesperrt und nicht gesperrt
     */
    public toggleLock(): void {
        this.locked = !this.locked;

        if (this.locked) {
            this.disableForm();
        } else {
            this.enableForm();
        }
    }

    /**
     * Speichert die Konditionen
     */
    public save(): void {
        const data = this.form.getRawValue() as IDebitRatesStateModel;

        this.waiterService.show().pipe(
            mergeMap(() => this.debitRatesService.update(data)),
            tap(() => this.waiterService.hide()),
        ).subscribe(
            {
                next: () => {
                    this.locked = true;
                    this.disableForm();
                    this.form.markAsPristine();
                },
                error: () => {
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('administration.features.debitRates.error'),
                    );
                },
            },
        );
    }

    /**
     * Gibt das passende Label für eine DebitRateAdaption zurück
     *
     * @param {number} value LTV Wert
     * @param {ComparisonType} comparison Vergleichsart
     * @param {boolean} end Steht die Zeile ganz am Anfang oder ganz am Ende?
     * @returns {string} Label
     */
    // eslint-disable-next-line class-methods-use-this
    public getAdaptionLabel(value?: number, comparison?: ComparisonType, end?: boolean): string {
        let label = '';

        if (value === undefined || comparison === undefined) {
            return label;
        }

        switch (comparison) {
            case ComparisonType.GreaterThan:
                label = end ? `> ${value}` : `${value + 1} - ${value + 10}`;
                break;
            case ComparisonType.GreaterThanEqual:
                label = end ? `>= ${value}` : `${value} - ${value + 10}`;
                break;
            case ComparisonType.LessThan:
                label = end ? `< ${value}` : `${value - 10} - ${value - 1}`;
                break;
            case ComparisonType.LessThanEqual:
                label = end ? `<= ${value}` : `${value - 10} - ${value }`;
                break;
            default:
                label = '';
                break;
        }

        return `LTV ${label}`;
    }

    /**
     * Aktiviert alle FormControls
     */
    private enableForm(): void {
        this.form.enable();
    }

    /**
     * Deaktiviert alle FormControls
     */
    private disableForm(): void {
        this.form.disable();
    }
}
