<div class="container">
    <div class="subheader">{{'administration.features.smartDocConfiguration.folderStart' | translate}}
        <span *ngFor="let historyFolder of trackFoldersNavigation"> > {{historyFolder.name}}</span>
    </div>

    <!-- erste ebene smart DocFolders -->
    <div fxLayout="row wrap" class="cont" fxLayoutAlign="start start" *ngIf="trackFoldersNavigation.length === 0">
        <finprocess-smartdoc-folder class="fix-position" *ngFor="let folder of firstLevelSmartFolders"
            [dropFolder]="folder"
            (openFolder)="openFolder($event)"
            (deleteFolder)="deleteFolder($event)"
            (editFolder)="editFolder($event)"
            >
        </finprocess-smartdoc-folder>
        <div fxLayout="column" fxLayoutAlign="end center" class="card folder-card cursor-pointer" (click)="addFolder()">
            <mat-icon class="icon-transform-3">create_new_folder</mat-icon>
            <p fxFlex="40" class="card-name">{{'administration.features.smartDocConfiguration.createFolder' | translate}}</p>
        </div>
    </div>

    <!-- DocFolders children -->

    <ng-container *ngIf="trackFoldersNavigation.length > 0">
        <h3>{{this.currentFolder?.name}}</h3>
        <div fxLayout="column">
            <div fxLayout="row wrap">
                <div fxLayout="column" fxLayoutAlign="center center" class="card folder-card" (click)="goBack()" >
                    <mat-icon class="icon-transform-3" matTooltip="{{'administration.features.smartDocConfiguration.back' | translate}}">arrow_back</mat-icon>
                </div>
                <finprocess-smartdoc-folder class="fix-position" *ngFor="let childFolder of childFolders"
                    [dropFolder]="childFolder"
                    (openFolder)="openFolder($event)"
                    (deleteFolder)="deleteFolder($event)"
                    (editFolder)="editFolder($event)">
                </finprocess-smartdoc-folder>
                <div fxLayout="column" fxLayoutAlign="end center" class="card folder-card" (click)="addFolder(currentFolder?.id)">
                    <mat-icon class="icon-transform-3">create_new_folder </mat-icon>
                    <p fxFlex="40" class="card-name">{{'administration.features.smartDocConfiguration.createFolder' | translate}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row wrap">
                <div class="subheader">{{'administration.features.smartDocConfiguration.area' | translate}}</div>
                <finprocess-smartdoc-droparea
                    *ngFor="let dropArea of currentFolder?.dropAreas"
                    [dropArea]="dropArea"
                    (setAsFavorite)="setFavorite($event)"
                    (editDropArea)="editDropArea($event)"
                    (deleteDropArea)="deleteDropArea($event)"
                    >
                </finprocess-smartdoc-droparea>
                <div fxLayout="column" fxLayoutAlign="end center" class="card area-card" (click)="addDropArea(currentFolder?.id)">
                    <mat-icon class="icon-transform-3">note_add</mat-icon>
                    <p fxFlex="40" class="card-name">{{'administration.features.smartDocConfiguration.createArea' | translate}}</p>
                </div>
            </div>
        </div>
    </ng-container>

</div>
