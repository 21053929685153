import { EnumSettings } from './../enum.settings';

/**
 * Risikobewertung
 */
export enum Risk {
    /**
     * Ok
     */
    Ok = 0,

    /**
     * Zurückgestellt
     */
    Exit = 1,
}

export namespace Risk {
    /**
     * Funktion zum Übersetzen des Enums
     *
     * @param {Risk | string} value Wert
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string | undefined} Übersetzter Enum-Wert
     */
    export const translate = (value: Risk | string, plocale?: string): string | undefined => EnumSettings.translate(value, 'Risk', Risk, plocale);

    /**
     * Funktion zum Übersetzen des Enum-Labels
     *
     * @param {string} plocale ggf. Lokalisierung, wenn nicht anders als gesetzte Standard-Lokalisierung
     * @returns {string} Übersetztes Enum-Label
     */
    export const translateLabel = (plocale?: string): string | undefined => EnumSettings.translateLabel('Risk', plocale);
}
