import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { MultiStepDialogComponent } from 'app/modules/financing/features/financing-processing/components/multi-step-dialog/multi-step-dialog.component';
import { ICreateNewProductPackage, ICreateNewProductPackageItem, IUpdateProductPackage, IUpdateProductPackageItem } from 'app/modules/financing/features/financing-processing/components/product-package/product-package.component';
import { IFileData } from 'app/modules/financing/features/financing-processing/components/risk-decision/risk-decision.component';
import { FinancingStatus, IEditor } from 'app/modules/shared';
import { AuthorizationService, Role, UserService } from 'auth';
import { Observable, combineLatest, forkJoin, iif, of } from 'rxjs';
import { debounceTime, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { ConfigService, HelperService, UUID } from 'shared/util';

import { OverwriteValueClassType, ValueStorageType } from '../../enums';
import { IAdditionalSheetsActivate, IAdditionalSheetsGenerate, IAdditionalSheetsInformation, IAdditionalSheetsInformationText, IApprovalData, IApprovalProductPackageCompetences, IApproveProductPackageRequest, IDebitor, IDebitorIncomeEntries, IDebitorIncomeEntryData, IDecisionRequestModel, IDocument, IFile, IFinancing, IFinancingPlanStatusEntry, IFinprocessContainer, IFireRiskDecision, IGetSampleCalculationDocuments, IHouseholdCalcData, IHouseholdOverviewDocuments, IMultipleStepsResult, IOverwriteCreateOrUpdateRequest, IOverwriteDelete, IProductPackageAccepted, IProductPackageData, IProductPackageItems, IProductPackageStatusEntry, ISampleCalculationData, ISaveInternalFieldRequest, ISetApproverRequest, ISetStatusRequest, ISignHouseholdDocument, IStatusEntry } from '../../interfaces';
import { IRFPData } from '../../interfaces/riskfinancingplans-data.interface';
import { IUpdateHouseholdCalculationExpenditure } from '../../interfaces/update-household-calc-expenditure.interface';
import { IUpdateHouseholdCalculationIncome } from '../../interfaces/update-household-calc-income.interface';

import { OverwriteHelperService } from './../../../util';
import { AcceptedProductPackageLoaded, AssignedApprover, FinProcessContainerLoaded, FinancingLoaded, FinancingState, IFinancingStateParentDefinition, InternalFieldUpdated, OverwriteCreatedOrUpdated, OverwriteDeleted, ProductPackageStatusLoaded, ProductPackageStatusUpdated, ProductPackages, RiskFinancingPlanStatusLoaded, RiskFinancingPlansLoaded, StatusLoaded, StatusUpdated, StatusUpdatedFinancingContainer } from './../../states';

/**
 * Service zum Bearbeiten einer Finanzierung
 */
@Injectable()
export class FinancingService {

    /**
     * Liefert Observable, ob aktuelle Financierung Documents hat
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Financierung Documents hat
     */
    public smartDocDocumentsExists$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz hat
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz hat
     */
    public editingReadonly$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz für Experte hat
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz hat
     */
    public editingReadonlyExpert$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz für Referenten hat
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz hat
     */
    public editingReadonlyReferent$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz für Approver hat
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz hat
     */
    public editingReadonlyApprover$: Observable<boolean>;

    /**
     * Liefert Observable ob der Nutzer der Bearbeiter der aktuellen Finanzierung ist
     */
    public isEditor$: Observable<boolean>


    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz hat mit Überprüfung des aktuellen Bearbeitungsmodus für alle User
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz bzw. nicht im Bearbeitungsmodus ist
     */
    public editingReadonlyWithEditmode$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz hat mit Überprüfung des aktuellen Bearbeitungsmodus für Experten
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz bzw. nicht im Bearbeitungsmodus ist
     */
    public editingReadonlyWithEditmodeExpert$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz hat mit Überprüfung des aktuellen Bearbeitungsmodus für Referenten
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz bzw. nicht im Bearbeitungsmodus ist
     */
    public editingReadonlyWithEditmodeReferent$: Observable<boolean>;

    /**
     * Liefert Observable, ob aktuelle Anfrage Schreibschutz hat mit Überprüfung des aktuellen Bearbeitungsmodus für Approver
     *
     * @returns {Observable<boolean>} Observable, ob aktuelle Anfrage Schreibschutz bzw. nicht im Bearbeitungsmodus ist
     */
    public editingReadonlyWithEditmodeApprover$: Observable<boolean>;

    /**
     * Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {HttpClient} httpClient HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {UserService} userService UserService-Injektor
     * @param {MatDialog} matDialog MatDialog-Injektor
     */
    public constructor(
        private store: Store,
        private httpClient: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private userService: UserService,
        private matDialog: MatDialog,
    ) {
        this.editingReadonly$ = this.createReadonlyObservable(Role.FinancingMapsEditor);
        this.editingReadonlyExpert$ = this.createReadonlyObservable(Role.Expert, true);
        this.editingReadonlyReferent$ = this.createReadonlyObservable(Role.Referent, true);
        this.editingReadonlyApprover$ = this.createReadonlyObservable(Role.Approver, true);

        this.editingReadonlyWithEditmode$ = this.createEditModeObservable(this.editingReadonly$);
        this.editingReadonlyWithEditmodeExpert$ = this.createEditModeObservable(this.editingReadonlyExpert$);
        this.editingReadonlyWithEditmodeReferent$ = this.createEditModeObservable(this.editingReadonlyReferent$);
        this.editingReadonlyWithEditmodeApprover$ = this.createEditModeObservable(this.editingReadonlyApprover$);

        this.isEditor$ = combineLatest([
            this.userService.user$,
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer),
        ]).pipe(
            map(([user, container]) => {
                if (container === undefined || user === undefined) {
                    return false;
                }

                return container.users?.some(usr => usr.id === user.id) || container.temporaryUser?.id === user.id;
            }),
            shareReplay(1),
        );

        this.smartDocDocumentsExists$ = this.store.select((financingDefinition: IFinancingStateParentDefinition) => financingDefinition.financing.financing?.smartDocStatus).pipe(
            map(status => (!status?.smartDocDocumentsExists ? false : status.smartDocDocumentsExists)),
        )
    }

    /**
     * Lädt die Daten einer Finanzierung
     *
     * @param {UUID} id Identifier der Finanzierung
     * @returns {IFinancing | undefined} Finanzierung, falls berechtigt
     */
    public loadFinancing(id: UUID): Observable<IFinancing | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFinancing>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/Details`, {
                    params: {
                        financingMapId: id,
                    },
                }).pipe(
                    mergeMap(financing => this.store.dispatch(new FinancingLoaded(financing)).pipe(
                        map(() => financing),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * Speichert einen Overwrite oder löscht ihn
     *
     * @param {object} params Parameter
     * @param {OverwriteValueClassType} params.overwriteValueClassType Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
     * @param {UUID} params.entityForOverwriteId Identifier der Entität, bei dem ein Wert überschrieben wird
     * @param {string} params.fieldName Feldname (entsprechend DB-Entität)
     * @param {ValueStorageType} params.valueStorageType Datentyp des zu speichernden Wertes
     * @param {string | undefined | null} params.value Wert
     * @returns {Observable<void>} Observable, wenn gespeichert
     */
    public saveOverwriteValue({ overwriteValueClassType, entityForOverwriteId, fieldName, valueStorageType, value }: {
        /**
         * Zugehörige Entitätsklasse zum Overwrite zur Identifizierung des richtigen Speichers (bessere Performance)
         */
        overwriteValueClassType: OverwriteValueClassType;
        /**
         * Identifier der Entität, bei dem ein Wert überschrieben wird
         */
        entityForOverwriteId: UUID;
        /**
         * Feldname (entsprechend DB-Entität)
         */
        fieldName: string;
        /**
         * Datentyp des zu speichernden Wertes
         */
        valueStorageType: ValueStorageType;
        /**
         * Wert
         */
        value?: string | null;
    }): Observable<void> {
        fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.financing).pipe(
                    mergeMap(financing => {
                        if (financing === undefined) {
                            return of(void 0);
                        }
                        const overwrite = OverwriteHelperService.getOverwriteFromFinancing(financing, { overwriteValueClassType, entityForOverwriteId, fieldName });
                        if (HelperService.isNullOrEmpty(value) && overwrite === undefined) {
                            return of(void 0);
                        }
                        else if (HelperService.isNullOrEmpty(value) && overwrite !== undefined) {
                            const request = {
                                overwriteValueId: overwrite.id,
                                overwriteValueClassType: overwriteValueClassType,
                            } as IOverwriteDelete;
                            return this.httpClient.delete<boolean>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/OverwriteValue`, {
                                params: {
                                    ...request,
                                },
                            }).pipe(
                                mergeMap(done => iif(
                                    () => done,
                                    of(void 0).pipe(
                                        mergeMap(() => this.store.dispatch(new OverwriteDeleted(request)).pipe(mergeMap(() => of(void 0)))),
                                    ),
                                    of(void 0),
                                )),
                            );
                        }
                        else {
                            const request = {
                                fieldName,
                                valueStorageType,
                                value,
                                financingMapId: financing.id,
                                entityForOverwriteId,
                                overwriteValueClassType,
                            } as IOverwriteCreateOrUpdateRequest;
                            return this.httpClient.post<UUID | IMultipleStepsResult>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/OverwriteValue`, request).pipe(
                                mergeMap(response => {
                                    if (typeof response === 'string') {
                                        return this.store.dispatch(new OverwriteCreatedOrUpdated(request, response))
                                    }
                                    else {
                                        this.matDialog.open(MultiStepDialogComponent, { data: response, minWidth: '60vw', minHeight: '70vh'});
                                        return of(void 0);
                                    }
                                }),
                                mergeMap(() => of(void 0)),
                            );
                        }
                    }),
                ),
                of(void 0),
            )),
        );
    }

    /**
     * Speichert einen Wert ohne Overwrite
     *
     * @param {ISaveInternalFieldRequest} request Request
     * @returns {Observable} Server Antwort
     */
    public saveInternalField<T>(request: ISaveInternalFieldRequest<T>): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.store.selectOnce((it: IFinancingStateParentDefinition) => it.financing.financing).pipe(
                    mergeMap(financing => {
                        if (financing === undefined) {
                            return of(void 0);
                        }

                        return this.httpClient.post(`${this.config.getEnvironment().apiUrl}/FinancingDetails/saveinternalfield`, {
                            ...request,
                            value: request.value?.toString(),
                            fieldName: request.fieldName.charAt(0).toUpperCase() + request.fieldName.slice(1),
                            financingMapId: financing.id,
                        }).pipe(
                            mergeMap(() => this.store.dispatch(new InternalFieldUpdated(request))),
                        )
                    }),
                ),

                of(undefined),
            )),
        );
    }
    
    /**
     * Export der Finazierungsmappe
     *
     *  @param  {UUID} id id von der finazinerung
     * @returns {Observable} Zip mit den angeforderten Dateien/Ordnern
     */
    public financingMapExport(id: UUID): Observable<ArrayBuffer | null | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/globalfunctions/getfinancingmapexport`, {
                    params: {
                        id,
                    },
                    responseType: 'arraybuffer',

                }),
                of(undefined),
            )),
        );
    }

    /**
     * ruft den SmartDoc-Export ab
     *
     * @param {UUID} id UUID
     * @returns {Observable<Blob | undefined>} result Blob
     */
    public exportSmartDoc(id: UUID): Observable<Blob | undefined> {
        return this.authorizationService.checkPermissions$(Role.SmartDoc, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/globalfunctions/getsmartdocexport`, {
                    params: {
                        mapContainerId: id,
                    },
                    responseType: 'blob',
                }),
                of(undefined),
            )),
        )
    }
    /**
     * Erstellt ein Ablehnungsschreiben
     *
     * @param {string} id ID der Finanzierungsmappe
     * @param {string} content Inhalt des Schreibens
     * @returns {Observable} ID
     */
    public createRejectionLetter(id: string, content: string): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post(`${this.config.getEnvironment().apiUrl}/FinancingDetails/createrejectionletter`,
                    { financingMapId: id, content },
                    { responseType: 'text' }),
                of(undefined),
            )),
        );
    }

    /**
     * Setzt den Status der Finanzierung
     *
     * @param {ISetStatusRequest} request Request
     * @returns {IStatusEntry} Status Eintrag
     */
    public setStatus(request: ISetStatusRequest): Observable<IStatusEntry | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<IStatusEntry>(`${this.config.getEnvironment().apiUrl}/Status`,
                    request,
                    {
                        responseType: 'json',
                    })
                    .pipe(
                        map(result => {
                            this.store.dispatch(new StatusUpdated(result));
                            this.store.dispatch(new StatusUpdatedFinancingContainer(result));
                            return result;
                        }),
                    ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt die StatusEinträge der Finanzierung
     *
     * @param {string} id ID der Finanzierung
     * @returns {IStatusEntry[]} Status Einträge
     */
    public getStatusEntries(id: string): Observable<IStatusEntry[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IStatusEntry[]>(`${this.config.getEnvironment().apiUrl}/Status/getentries`,
                    {
                        params: {
                            id,
                        },
                        responseType: 'json',
                    })
                    .pipe(
                        mergeMap(result => this.store.dispatch(new StatusLoaded(result))),
                    ),
                of(undefined),
            )),
        );
    }

    /**
     * Lädt die StatusEinträge eines Risikofinanzplans
     *
     * @param {string} id ID des Finanzplans
     * @returns {IStatusEntry[]} Status Einträge
     */
    public getRiskFinancingPlanStatusEntries(id: string): Observable<IFinancingPlanStatusEntry[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFinancingPlanStatusEntry[]>(`${this.config.getEnvironment().apiUrl}/Status/financingplan`,
                    {
                        params: {
                            id,
                        },
                        responseType: 'json',
                    }).pipe(
                    mergeMap(result => this.store.dispatch(new RiskFinancingPlanStatusLoaded({ financingPlanStatusEntry: result, riskFinancingId: id })).pipe(
                        map(() => result),
                    ))),
                of(undefined),
            )),
        );
    }

    /**
     * Lässt eine automatisch zurückgestellte Finanzierung für die Einreichung zu
     *
     * @param {string} id Finanzierungs ID
     * @returns {Observable} Leere Server Antwort
     */
    public overwriteRejection(id: string): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.FilterAutomaticallyRejected, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.post<void>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/OverwriteFinancingMapRejectionFromValidation`,
                    {
                        financingMapId: id,
                    }, undefined),
                of(undefined),
            )),
        );
    }


    /**
     * get all Risk financing plans
     * 
     * @param {UUID} id id
     * @returns {Observable<IRFPData[]>} an observable of an array of IRFPData objects 
     */
    public getAllRiskFinancingPlans(id: UUID): Observable<IRFPData[]> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => (authorized ? this.httpClient.get<IRFPData[]>(`${this.config.getEnvironment().apiUrl}/RiskFinancingPlan/getlist`,
                {
                    params:
                    {
                        financingContainerID: id,
                    },
                })
                .pipe(
                    mergeMap(rfp => this.store.dispatch(new RiskFinancingPlansLoaded(rfp)).pipe(
                        map(() => rfp),
                    ))) : of([])),
            ));
    }


    /**
     * get Risk financing plan
     * 
     * @param {UUID} id id
     * @returns {Observable<IRFPData[]>} an observable of an array of IRFPData objects 
     */
    public getRiskFinancingPlan(id: UUID): Observable<IFinancing | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => (authorized ? this.httpClient.get<IFinancing>(`${this.config.getEnvironment().apiUrl}/RiskFinancingPlan/get`,
                {
                    params:
                    {
                        riskFinancingMapId: id,
                    },
                },
            ) : of(undefined)),
            ));
    }


    /**
     * posting a new risk financing plan
     * 
     * @param {UUID} id id
     * @param {string} name new name of rfp
     * @returns {Observable} observable
     */
    public postNewRiskFinancingPlan(id: UUID, name: string): Observable<string | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, this.httpClient.post<string>(`${this.config.getEnvironment().apiUrl}/RiskFinancingPlan/create`, undefined,
                        {
                            params: {
                                financingMapId: id,
                                description: name,
                            },
                        }),
                    of(undefined),
                )));
    }

    /**
     * Lädt einen Finprocess Container
     * Enthält Bearbeiter und temporären Bearbeiter
     * 
     * @param {UUID} finprocessContainerId Id des Containers
     * @returns {Observable<IFinprocessContainer | undefined>} Der geladene Container
     */
    public loadFinancingContainer(finprocessContainerId: UUID): Observable<IFinprocessContainer | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IFinprocessContainer>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/Container`, {
                    params: {
                        finprocessContainerId,
                    },
                }).pipe(
                    mergeMap(container => this.store.dispatch(new FinProcessContainerLoaded(container)).pipe(
                        map(() => container),
                    )),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * get RiskHousehold Calculations
     * 
     * @param {UUID} financingContainerID id
     * @returns {Observable} returns Observable
     */
    public getRiskHouseHoldCalculations(financingContainerID: UUID): Observable<IHouseholdCalcData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IHouseholdCalcData>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/get`, {
                    params: {
                        riskFinancingMapId: financingContainerID,
                    },
                },
                ),
                of(undefined),
            )),
        )
    }

    /**
     * Load sheets
     * 
     * @param { UUID } containerId Container ID
     * @returns { Observable<IAdditionalSheetsInformation[] | undefined> } Additional Sheets Information 
     */
    public loadAdditionalSheetsInformation(containerId: UUID): Observable<IAdditionalSheetsInformation[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.get<IAdditionalSheetsInformation[]>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/AdditionalSheetsInformation`,
                        {
                            params: {
                                containerId: containerId,
                            },
                        }),
                    of(undefined),
                )));
    }

    /**
     * Zum aktiv setzen für die Möglichkeit der Generierung eins Zusatzblattes 
     * 
     * @param {IAdditionalSheetsActivate} additionalSheetActivate additionalSheetsActivate
     * @returns {Observable<unknown> } Antwort vom Server
     */
    public setAdditionalSheetsActive(additionalSheetActivate: IAdditionalSheetsActivate): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/ActiveAdditionalSheets`, additionalSheetActivate, {}),
                    of(undefined),
                )),
        );
    }

    /**
     * update HouseholdCalculation Data - Expenditures
     *
     * @param {IUpdateHouseholdCalculationExpenditure} updateData update data
     * @returns {Observable} IUpdateHouseholdCalculationExpenditure | undefined
     */
    public updateHouseHoldCalculationExpenditure(updateData: IUpdateHouseholdCalculationExpenditure): Observable<IUpdateHouseholdCalculationExpenditure | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IUpdateHouseholdCalculationExpenditure>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/updateExpenditureInformation`, updateData),
                    of(undefined),
                )));
    }

    /**
     * update HouseholdCalculation Data - Incomes
     *
     * @param {IUpdateHouseholdCalculationIncome} updateData update data
     * @returns {Observable} IUpdateHouseholdCalculationIncome | undefined
     */
    public updateHouseHoldCalculationIncome(updateData: IUpdateHouseholdCalculationIncome): Observable<IUpdateHouseholdCalculationIncome | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IUpdateHouseholdCalculationIncome>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/updateIncomeInformation`, updateData),
                    of(undefined),
                )));
    }

    /**           
     * Zur Angabe des Textes, welcher in dem jeweiligen Zusatzblatt enthalten sein soll
     * 
     * @param {IAdditionalSheetsActivate} aditionalSheetsInformationText aditionalSheetsInformationText
     * @returns {Observable<unknown> } Antwort vom Server
     */
    public updateAdditionalSheetsInformationText(aditionalSheetsInformationText: IAdditionalSheetsInformationText): Observable<void | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<void>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/SetAdditionalSheetsInformationText`, aditionalSheetsInformationText, {}),
                    of(undefined),
                )),
        );
    }

    /**
     * Zum Erzeugen eines Zusatzblatten, welches als Dokument am Debitor gespeichert wird
     * 
     * @param {IAdditionalSheetsGenerate} additionalSheet IAdditionalSheetsGenerate
     * @returns {Observable<IDocument | undefined> } Antwort vom Server
     */
    public generateAdditionalSheets(additionalSheet: IAdditionalSheetsGenerate): Observable<IDocument | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IDocument>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/GenerateAdditionalSheets`, additionalSheet),
                    of(undefined),
                )),
        );
    }

    /**
     * Service für Generierung Haushaltsrechnungen
     * 
     * @param {string} idContainer containerId
     * @param {string} idHousehold householdId
     * @returns {Observable} Antwort vom Server
     */
    public generateHousholdBills(idContainer: string, idHousehold: string): Observable<unknown> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/GenerateHouseholdCalculations`, {
                        containerId: idContainer,
                        householdId: idHousehold,
                    }),
                    of(undefined),
                )),
        );
    }

    /**
     * get all Product Packages
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getAllProductPackages(id: UUID): Observable<IProductPackageData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IProductPackageData>(`${this.config.getEnvironment().apiUrl}/Product/getall`, {
                        params: {
                            financingMapID: id,
                        },
                    }).pipe(
                        tap(data => {
                            if (!!data) {
                                this.store.dispatch(new ProductPackages(data));
                            }
                        }));
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    /**
     * get all Product Packages of all risk financing Plan
     * 
     * @param {IRFPData[]} rfpDatas an array of all IRFPData
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getAllProductPackagesOfAllRFPs(rfpDatas: IRFPData[]): Observable<Array<IProductPackageData & { name: string }>> {
        if (!Array.isArray(rfpDatas) || rfpDatas.length === 0) {
            return of([]);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    const url = `${this.config.getEnvironment().apiUrl}/Product/getall`;
                    const allRequests = rfpDatas.map<Observable<IProductPackageData & { name: string }>>(({ id, description }) =>
                        this.httpClient.get<IProductPackageData>(url, { params: { financingMapID: id, loadSampleCalculations: true } }).pipe(map(data => ({ ...data, name: description }))));
                    return forkJoin(allRequests);
                }
                else {
                    return of([]);
                }
            }), map(datas => datas.reduce<Array<IProductPackageData & { name: string }>>((acc, rfp) => {
                // leere liste wenn keine RB im RFP und wenn keine systemnotes vorhanden sind
                if (
                    (!Array.isArray(rfp.assignProductPackages) || rfp.assignProductPackages.length === 0 ||
                        (!rfp.assignProductPackages.some(app => Array.isArray(app.assignedSampleCalculations) && app.assignedSampleCalculations.length > 0)))
                    && rfp.systemNotes?.length === 0
                ) {
                    return acc;
                }

                // Nur Produktpakete mit RB werden angezeigt
                if (rfp.assignProductPackages && !rfp.assignProductPackages.every(app => Array.isArray(app.assignedSampleCalculations) && app.assignedSampleCalculations.length > 0)) {
                    const tmp: IProductPackageData & { name: string } = {
                        ...rfp,
                        assignProductPackages: rfp.assignProductPackages.filter(app => Array.isArray(app.assignedSampleCalculations) && app.assignedSampleCalculations.length > 0),
                    }
                    return [...acc, tmp];
                }
                return [...acc, rfp];
            }, [])),
        )
    }

    /**
     * Sendet Produktpakete und ändert Finanzierungsstatus
     * 
     * @param {UUID} finProcessContainerId id des Containers
     * @param {UUID[]} productPackageIds zu sendende Produktpakete
     * @returns {Observable} Antwort vom Server
     */
    public sendSampleCalculations(finProcessContainerId: UUID, productPackageIds: UUID[]): Observable<void> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<void>(`${this.config.getEnvironment().apiUrl}/Product/sendproductpackages`, {
                        finProcessContainerId,
                        productPackageIds,
                    }),
                    of(undefined),
                )));
    }

    /**
     * get Product Package
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getProductPackage(id: UUID): Observable<IProductPackageData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IProductPackageData>(`${this.config.getEnvironment().apiUrl}/Product/getDetail`, {
                        params: {
                            productPackageID: id,
                        },
                    }).pipe(
                        tap(data => {
                            if (!!data) {
                                this.store.dispatch(new ProductPackages(data));
                            }
                        }),
                    );
                }
                return of(undefined);
            }),
        );
    }

    /**
     * get Accepted Product Package (zu zeit das ist nur die Bank daten...)
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable of ProductPackageAccepted
     */
    public getAcceptedProductPackage(id: UUID): Observable<IProductPackageAccepted | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IProductPackageAccepted>(`${this.config.getEnvironment().apiUrl}/Product/getacceptedpackage`, {
                        params: {
                            containerId: id,
                        },
                    }).pipe(
                        mergeMap(data => this.store.dispatch(new AcceptedProductPackageLoaded(data)).pipe(map(() => data))),
                    );
                }
                return of(undefined);
            }),
        );
    }
    /**
     * get infos about product package fire document (uploaded)
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getProductPackageFireDocumentInfos(id: UUID): Observable<IFileData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IFileData>(`${this.config.getEnvironment().apiUrl}/Product/getproductdocumentfilename`, {
                        params: {
                            productPackageId: id,
                        },
                    });
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    /**
     * Lädt die StatusEinträge der Produktpakete
     *
     * @param {string } productPackageID product package id
     * @param {string} financingContainerID financingcontainer id
     * @returns {IStatusEntry} Status Einträge
     */
    public getProductPackageStatusEntries(productPackageID: string, financingContainerID: string): Observable<IProductPackageStatusEntry[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IProductPackageStatusEntry[]>(`${this.config.getEnvironment().apiUrl}/Status/productpackage`,
                    {
                        params: {
                            financingContainerId: financingContainerID,
                            productPackageId: productPackageID,
                        },
                    }).pipe(
                    mergeMap((response: IProductPackageStatusEntry[]) => {
                        this.store.dispatch(new ProductPackageStatusLoaded({ statusEntries: response, productPackageId: productPackageID }));
                        return of(response);
                    }),
                ),
                of(undefined),
            )),
        );
    }

    /**
     * add new product package
     *
     * @param {ICreateNewProductPackage} data data
     * @returns {Observable} observable
     */
    public addNewProductPackage(data: ICreateNewProductPackage): Observable<ICreateNewProductPackage | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<ICreateNewProductPackage>(`${this.config.getEnvironment().apiUrl}/Product/add`, data),
                    of(undefined),
                )));
    }

    /**
     * add new product to product package
     *
     * @param {ICreateNewProductPackageItem} data data
     * @returns {Observable} observable
     */
    public addNewProduct(data: ICreateNewProductPackageItem): Observable<IProductPackageData | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IProductPackageData>(`${this.config.getEnvironment().apiUrl}/Product/addproduct`, data),
                    of(undefined),
                )));
    }


    /**
     * delete product package
     *
     * @param {UUID} productPackageID product package id 
     * @returns {Observable} observable
     */
    public deleteProductPackage(productPackageID: UUID): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.delete<UUID>(`${this.config.getEnvironment().apiUrl}/Product/delete`,
                        {
                            params: {
                                productPackageID: productPackageID,
                            },
                        }),
                    of(undefined),
                )));
    }

    /**
     * delete product from product package
     *
     * @param {UUID} productID product id 
     * @returns {Observable} observable
     */
    public deleteProduct(productID: UUID): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.delete<UUID>(`${this.config.getEnvironment().apiUrl}/Product/deleteproduct`,
                        {
                            params: {
                                productID: productID,
                            },
                        }),
                    of(undefined),
                )));
    }

    /**
     * update product package (description)
     * 
     * @param {IUpdateProductPackage} data data
     * @returns {Observable} observable
     */
    public updateProductPackage(data: Partial<IUpdateProductPackage>): Observable<IProductPackageData | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized, 
                    this.httpClient.put<IProductPackageData>(`${this.config.getEnvironment().apiUrl}/Product/update`, data).pipe(
                        mergeMap(allProductPackages => {
                            if (!!allProductPackages) {
                                this.store.dispatch(new ProductPackages(allProductPackages));
                            }
                            return of(allProductPackages);
                        }),
                    ),
                    of(undefined),
                )),
        );
    }
    
    /**
     * update product package item
     * 
     * @param {IUpdateProductPackageItem} data data
     * @returns {Observable} observable
     */
    public updateProductPackageItem(data: Partial<IUpdateProductPackageItem>): Observable<Partial<IProductPackageData> | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.put<Partial<IProductPackageData>>(`${this.config.getEnvironment().apiUrl}/Product/updateproduct`, data),
                    of(undefined),
                )),
            tap(allProductPackages => {
                if (!!allProductPackages) {
                    const ppData: IProductPackageData = allProductPackages as IProductPackageData;
                    this.store.dispatch(new ProductPackages(ppData));
                }
            }),
        )
    }

    /**
     * Updates the name of a product
     * 
     * @param {UUID} productPackageId Id of the product package
     * @param {UUID} productId Id of the product
     * @param {string} productName New name of the product
     * @returns {Observable<IProductPackageItems | undefined>} Updated product entity
     */
    public updateProductName(productPackageId: UUID, productId: UUID, productName: string): Observable<IProductPackageItems | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert | Role.Referent, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.put<IProductPackageItems>(`${this.config.getEnvironment().apiUrl}/Product/updateProductName`, {
                        productPackageId,
                        productId,
                        productName,
                    }),
                    of(undefined),
                )),
        );
    }

    /**
     * copy product package
     * 
     * @param {UUID} productPackageID id
     * @returns {Observable} observable
     */
    public copyProductPackage(productPackageID: UUID): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<UUID>(`${this.config.getEnvironment().apiUrl}/Product/copy`, undefined,
                        {
                            params: {
                                productPackageID: productPackageID,
                            },
                        },
                    ),
                    of(undefined),
                )));
    }

    /**
     * add new sample calculation to product package
     *
     * @param {ICreateNewProductPackageItem} data data
     * @returns {Observable} observable
     */
    public addNewSampleCalculation(data: ISampleCalculationData): Observable<ISampleCalculationData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<ISampleCalculationData>(`${this.config.getEnvironment().apiUrl}/Product/addsamplecalculation`, data),
                    of(undefined),
                )));
    }

    /**
     * add manual fire risk decision data to product package
     *
     * @param {IFireRiskDecision} data data
     * @param {UUID} productPackageID product package id 
     * @returns {Observable} observable
     */
    public addManualFireRiskDecision(data: IFireRiskDecision, productPackageID: UUID): Observable<IFireRiskDecision | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IFireRiskDecision>(`${this.config.getEnvironment().apiUrl}/Decision/PostManualDecision`, data,
                        {
                            params: {
                                productPackageId: productPackageID,
                            },
                        },
                    ),
                    of(undefined),
                )));
    }

    /**
     * get all Sample Calculations of a Product Package
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getAllSampleCalculations(id: UUID): Observable<IGetSampleCalculationDocuments | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IGetSampleCalculationDocuments>(`${this.config.getEnvironment().apiUrl}/Product/getsamplecalculation`, {
                        params: {
                            productPackageID: id,
                        },
                    });
                }

                return of(undefined);
            }),
        );
    }

    /**
     * get Manual Fire Risk Decison Data
     * 
     * @param {UUID} id product package id
     * @returns {Observable<[]>} an observable of an array of IApprovalData objects 
     */
    public getManualFireRiskDecisionData(id: UUID): Observable<IFireRiskDecision | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IFireRiskDecision>(`${this.config.getEnvironment().apiUrl}/Decision/GetExistingDecision`, {
                        params: {
                            productPackageId: id,
                        },
                    });
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    /**
     * Retrieves the the decision request for the given product package id.
     * The request is either prefilled with default data by the backend or returns
     * an already saved request. 
     * 
     * @param {UUID} id product package id
     * @returns {Observable<[]>} an observable of an array of IApprovalData objects 
     */
    public getDecisionRequest(id: UUID): Observable<IDecisionRequestModel | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IDecisionRequestModel>(`${this.config.getEnvironment().apiUrl}/Decision/GetDecisionRequest`, {
                        params: {
                            productPackageId: id,
                        },
                    });
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    /**
     * generiert aus den Metadaten ein Rechenbeispiel und gibt dies zurück
     * 
     * @param {UUID} id id
     * @returns {Observable<[]>} an observable
     */
    public getSampleCalculation(id: UUID): Observable<Blob | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get(`${this.config.getEnvironment().apiUrl}/Product/generatesamplecalculation`, {
                        params: {
                            sampleCalculationID: id,
                        },
                        responseType: 'blob',
                    });
                }

                return of(undefined);
            }),
        );
    }

    /**
     * get all Approval Data
     * 
     * @param {UUID} id product package id
     * @returns {Observable<[]>} an observable of an array of IApprovalData objects 
     */
    public getApproval(id: UUID): Observable<IApprovalData | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IApprovalData>(`${this.config.getEnvironment().apiUrl}/Approval/getexistingapproval`, {
                        params: {
                            productPackageId: id,
                        },
                    });
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    /**
     * delete sample calculation id
     *
     * @param {UUID} id sample calculation id 
     * @returns {Observable} observable
     */
    public deleteSampleCalculation(id: UUID): Observable<UUID | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.delete<UUID>(`${this.config.getEnvironment().apiUrl}/Product/deletesamplecalculation`,
                        {
                            params: {
                                sampleCalculationID: id,
                            },
                        }),
                    of(undefined),
                )));
    }

    /**
     * lädt houshold dokumente
     * 
     * @param {string} householdContainerId ContainerId
     * @returns {Observable} Antwort vom Server
     */
    public getAllHouseholdOVerviewDocuments(householdContainerId: string): Observable<IHouseholdOverviewDocuments[] | undefined> {

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsReader | Role.FinancingMapsGlobalReader, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get<IHouseholdOverviewDocuments[]>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/HouseholdOverviewDocuments`, {
                    params: {
                        containerId: householdContainerId,
                    },
                }),
                of(undefined),
            )),
        );

    }

    /**
     * Markiert das Dokument als unterschreiben
     * 
     * @param {ISignHouseholdDocument} signDocument  signDocument Model
     * @returns { Observable <FinancingStatus | undefined >} status 
     */
    public singDocument(signDocument: ISignHouseholdDocument): Observable<FinancingStatus | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<FinancingStatus>(`${this.config.getEnvironment().apiUrl}/RiskHouseholdCalculation/SignHouseholdDocument`, signDocument)
                        .pipe(
                            map(status => {
                                //wenn alle Dokumenten unterschrieben sind, setzt das Beckend die FM status auf FinancingStatus.HouseholdCalculationAccepted und substatus auf null
                                //erst dann muss die Frontend Status von FM und Container geändert werden
                                if (!!status && status === FinancingStatus.HouseholdCalculationAccepted) {
                                    this.store.dispatch(new StatusUpdated({ id: signDocument.containerId, created: new Date().toISOString(), status: status, subStatus: undefined }));
                                    this.store.dispatch(new StatusUpdatedFinancingContainer({ id: signDocument.containerId, created: new Date().toISOString(), status: status, subStatus: undefined }));
                                }
                                return status;
                            }),
                        ),
                    of(undefined),
                )),
        );
    }

    /**
     * Returns a list of possible approvers for the given product package
     *
     * @param {UUID} productPackageId Product package id
     * @returns {Observable<IEditor[]>} List of possible approvers
     */
    public getApproversList(productPackageId: UUID): Observable<IEditor[]> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.get<IEditor[]>(`${this.config.getEnvironment().apiUrl}/Approval`,
                        {
                            params: {
                                productPackageId,
                            },
                        },
                    ),
                    of([]),
                )));
    }

    /**
     * Sets a user as an approver for a product package and changes the product package status to ApprovalAwaiting
     *
     * @param {ISetApproverRequest} setApproverRequest Request
     * @param {IEditor} approver Chosen approver
     * @returns {Observable<IProductPackageStatusEntry>} Status Entry for the status change
     */
    public setApprover(setApproverRequest: ISetApproverRequest, approver: IEditor): Observable<IProductPackageStatusEntry | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IProductPackageStatusEntry>(`${this.config.getEnvironment().apiUrl}/Approval/SetApprover`, setApproverRequest).pipe(
                        mergeMap(response => this.store.dispatch(new AssignedApprover({ status: response, approver, productPackageId: setApproverRequest.productPackageId })).pipe(map(() => response))),
                    ),
                    of(undefined),
                )));
    }

    /**
     * Sets the product package status to the given status
     *
     * @param {IApproveProductPackageRequest} request Request
     * @returns {Observable<IProductPackageStatusEntry>} Status Entry for the status change
     */
    public approveProductPackage(request: IApproveProductPackageRequest): Observable<IProductPackageStatusEntry | undefined> {
        return this.authorizationService.checkPermissions$(Role.Approver, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IProductPackageStatusEntry>(`${this.config.getEnvironment().apiUrl}/Approval/ApproveProductPackage`, request).pipe(
                        mergeMap(response => this.store.dispatch(new ProductPackageStatusUpdated({ status: response, productPackageId: request.productPackageId })).pipe(map(() => response))),
                    ),
                    of(undefined),
                )));
    }

    /**
     * Sets the product package status to the given status
     *
     * @param {IApproveProductPackageRequest} request Request
     * @returns {Observable<IProductPackageStatusEntry>} Status Entry for the status change
     */
    public approveProductPackageWithBackOffice(request: IApproveProductPackageRequest): Observable<IProductPackageStatusEntry | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IProductPackageStatusEntry>(`${this.config.getEnvironment().apiUrl}/Approval/ApproveProductPackageBackOffice`, request).pipe(
                        mergeMap(response => this.store.dispatch(new ProductPackageStatusUpdated({ status: response, productPackageId: request.productPackageId })).pipe(map(() => response))),
                    ),
                    of(undefined),
                )));
    }

    /**
     * Lädt das vom Backend berechnete Kompetenzlevel für das Produktpaket und den Experten dieses Produktpakets
     * Entweder Eigenkompetenz, Fremdkompetenz oder Marktfolge
     * 
     * @param {UUID} financingContainerId ID des Containers
     * @param {UUID} productPackageId ID des Produktpakets
     * @returns {Observable<IApprovalProductPackageCompetences | undefined>} Obser
     */
    public getApprovalCompetence(financingContainerId: UUID, productPackageId: UUID): Observable<IApprovalProductPackageCompetences | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor | Role.FinancingMapsGlobalReader | Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.get<IApprovalProductPackageCompetences>(`${this.config.getEnvironment().apiUrl}/Approval/approvalcompetences`, {
                        params: {
                            containerId: financingContainerId,
                            productPackageId,
                        },
                    }),
                    of(undefined),
                ),
            ),
        );
    }

    /**
     * get excel export
     *
     * @param {UUID} financingMapID financing map id
     * @param {UUID} productPackageID product package id
     * @returns {Observable} observable blob | undefined
     */
    public getExcelExport(financingMapID: UUID, productPackageID: UUID): Observable<Blob | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader | Role.FinancingMapsGlobalReader | Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.httpClient.get(`${this.config.getEnvironment().apiUrl}/globalfunctions/getonlyexcelexport`, {
                    params: {
                        financingMapId: financingMapID,
                        productPackageId: productPackageID,
                    },
                    responseType: 'blob',
                }),
                of(undefined),
            )),
        )
    }

    /**
     * Erstellt ein Observable um die Bearbeitungsrechte für eine Rolle zu überprüfen
     * 
     * @param {Role} role Rolle
     * @param {boolean} checkRoleInContainer Soll die Rolle nicht nur am Nutzer sondern auch am Container überprüft werden
     * @returns {Observable<boolean>} Observable
     */
    private createReadonlyObservable(role: Role, checkRoleInContainer = false): Observable<boolean> {
        return combineLatest([
            this.authorizationService.hasRole$(role),
            this.store.select(FinancingState.isEditingReadonly),
            this.userService.user$,
        ]).pipe(
            debounceTime(50),
            map(([hasRole, functionCall, user]) => {
                const isReadonly = user !== undefined ? functionCall(user, checkRoleInContainer ? role : undefined) : true;
                return !hasRole || isReadonly;
            }),
        );
    }

    /**
     * Erstellt ein Observable, das Bearbeitungsrechte und die Bearbeitungssperre verbindet
     * 
     * @param {Observable<boolean>} observable Bearbeitungsrechteobservable
     * @returns {Observable<boolean>} Observable
     */
    private createEditModeObservable(observable: Observable<boolean>) {
        return combineLatest([
            observable,
            this.store.select((it: IFinancingStateParentDefinition) => it.financingTabs.editMode),
        ]).pipe(
            map(([editingReadonly, editMode]) => editingReadonly || !editMode),
        );
    }

    /**
     * Selbstauskunft generieren
     *
     * @param {UUID} debitorId debitorID
     * @param {UUID} financingMapId financingMapID
     * @returns {Observable} observable
     */
    public generateSelfDisclosure(debitorId: UUID, financingMapId: UUID): Observable<IFile | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.post<IFile | undefined>(`${this.config.getEnvironment().apiUrl}/Document/CreateSelfDisclosure`, undefined,
                        {
                            params: {
                                debitorId: debitorId,
                                financingMapId: financingMapId,
                            },
                        },
                    ),
                    of(undefined),
                )));
    }

    /**
     * Setzt den aktuellen Nutzer als Referent der Finanzierung
     * 
     * @param {UUID} finprocessContainerId ID des Containers
     * @returns {IEditor | undefined} Aktualisierter Bearbeiter der Finanzierung
     */
    public assignSelfAsReferee(finprocessContainerId: UUID): Observable<IEditor | undefined> {
        return this.authorizationService.checkPermissions$(Role.Referent, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.put<IEditor>(`${this.config.getEnvironment().apiUrl}/FinancingDetails/CurrentUserAsRefereeEditor`, {
                        finprocessContainerId,
                    }),
                    of(undefined),
                )));
    }

    /**
     * Lädt den Hauptkreditnehmer einer Finanzierung (nach Einkommen sortiert)
     * 
     * @param {UUID} containerId ID des Finprocess Containers
     * @returns {IDebitor | undefined} Hauptkreditnehmer
     */
    public getMainDebitor(containerId: UUID): Observable<IDebitor | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized =>
                iif(() => authorized,
                    this.httpClient.get<IDebitor>(`${this.config.getEnvironment().apiUrl}/debitor/maindebitor`, {
                        params: {
                            containerId,
                        },
                    }),
                    of(undefined),
                )));
    }

    /**
     * Save debitor income entries
     *
     * @param {IDebitorIncomeEntryData} data data
     * @returns {Observable} observable
     */
    public postDebitorIncomeEntries(data: IDebitorIncomeEntryData): Observable<IDebitorIncomeEntryData | undefined> {
        return this.authorizationService.checkPermissions$(Role.Expert, false).pipe(
            mergeMap(authorized =>
                iif(
                    () => authorized,
                    this.httpClient.post<IDebitorIncomeEntryData>(`${this.config.getEnvironment().apiUrl}/Debitor/postIncomeEntries`, data),
                    of(undefined),
                )));
    }

    /**
     * get Debitor Income Entries
     * 
     * @param {UUID} debitorID debitorID
     * @param {UUID} containerID containerID
     * @returns {Observable<[]>} an observable of an array of IRFPData objects 
     */
    public getDebitorIncomeEntries(debitorID: UUID, containerID: UUID): Observable<IDebitorIncomeEntries[] | undefined> {
        return this.authorizationService.checkPermissions$(Role.FinancingMapsReader, false).pipe(
            mergeMap(authorized => {
                if (authorized) {
                    return this.httpClient.get<IDebitorIncomeEntries[]>(`${this.config.getEnvironment().apiUrl}/Debitor/getIncomeEntries`, {
                        params: {
                            debitorId: debitorID,
                            containerId: containerID,
                        },
                    },
                    );
                }
                return of(undefined);
            }),
        );
    }

}
