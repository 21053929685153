/* eslint-disable @typescript-eslint/naming-convention */
export enum Product {
    /**
     * @deprecated
     * Konsumfinanzierungen PKK-PrivatKredit var.
     */
    KonsumfinanzierungenPKK_PrivatKreditvar = 10,

    /**
     * Konsumfinanzierungen PKK-PrivatKredit Indikator
     */
    KonsumfinanzierungenPKK_PrivatKreditIndikator = 15,

    /**
     * @deprecated
     * Konsumfinanzierungen PKK-PrivatKredit fix
     */
    KonsumfinanzierungenPKK_PrivatKreditfix = 20,

    /**
     * Konsumfinanzierungen PKK-PrivatKredit fix/follow up Indikator
     */
    KonsumfinanzierungenPKK_PrivatKreditfixfollowupIndikator = 25,

    /**
     * @deprecated
     * Konsumfinanzierungen PKK-BetriebsratsKredit var.
     */
    KonsumfinanzierungenPKK_BetriebsratsKreditvar = 30,

    /**
     * @deprecated
     * Konsumfinanzierungen PKK-BetriebsratsKredit fix
     */
    KonsumfinanzierungenPKK_BetriebsratsKreditfix = 40,

    /**
     * @deprecated
     * Konsumfinanzierungen PKK-GastarbeiterKredit var.
     */
    KonsumfinanzierungenPKK_GastarbeiterKreditvar = 50,

    /**
     * @deprecated
     * Konsumfinanzierungen PKK-StudentenKredit var.
     */
    KonsumfinanzierungenPKK_StudentenKreditvar = 60,

    /**
     * Bau- und Wohnfinanzierungen-FremdwährungsKredit
     */
    BauundWohnfinanzierungen_FremdwährungsKredit = 80,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-FremdwährungsKredit m. Wohnbankvorteil
     */
    BauundWohnfinanzierungen_FremdwährungsKreditmWohnbankvorteil = 90,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit GB var.
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitGBvar = 100,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit WBB Vorteil m. GB var.
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitWBBVorteilmGBvar = 105,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit GB fix
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitGBfix = 110,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit WBB Vorteil m. GB fix
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitWBBVorteilmGBfix = 115,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen ohne GB var.
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenohneGBvar = 120,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit WBB Vorteil o. GB var.
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitWBBVorteiloGBvar = 125,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen ohne GB var.
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenohneGBvar = 127,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen ohne GB fix
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenohneGBfix = 130,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnPrivatDarlehen mit WBB Vorteil o. GB fix
     */
    BauundWohnfinanzierungen_WohnPrivatDarlehenmitWBBVorteiloGBfix = 135,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen ohne GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenohneGBfix = 137,

    /**
     * Bau- und Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil m. GB variabel
     */
    BauundWohnfinanzierungen_EurotopdarlehenmitWBBVorteilmGBvariabel = 140,

    /**
     * Bau- und Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil m. GB fix
     */
    BauundWohnfinanzierungen_EurotopdarlehenmitWBBVorteilmGBfix = 150,

    /**
     * Bau- und Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil o. GB variabel
     */
    BauundWohnfinanzierungen_EurotopdarlehenmitWBBVorteiloGBvariabel = 160,

    /**
     * Bau- und Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil o. GB fix
     */
    BauundWohnfinanzierungen_EurotopdarlehenmitWBBVorteiloGBfix = 170,

    /**
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen endfällig
     */
    BauundWohnfinanzierungen_EuroTopDarlehenendfällig = 180,

    /**
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen mit GB
     */
    BauundWohnfinanzierungen_EuroTopDarlehenmitGB = 190,

    /**
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen mit GB fix
     */
    BauundWohnfinanzierungen_EuroTopDarlehenmitGBfix = 195,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen m. Wohnbankvorteil endf.
     */
    BauundWohnfinanzierungen_EuroTopDarlehenmWohnbankvorteilendf = 200,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen m. Wohnbankvorteil tilg.
     */
    BauundWohnfinanzierungen_EuroTopDarlehenmWohnbankvorteiltilg = 210,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnKredit var.
     */
    BauundWohnfinanzierungen_WohnKreditvar = 220,

    /**
     * Bau- und Wohnfinanzierungen-WohnKredit Indikator
     */
    BauundWohnfinanzierungen_WohnKreditIndikator = 225,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-WohnKredit fix
     */
    BauundWohnfinanzierungen_WohnKreditfix = 230,

    /**
     * Bau- und Wohnfinanzierungen-WohnKredit fix/follow up Indikator
     */
    BauundWohnfinanzierungen_WohnKreditfixfollowupIndikator = 235,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Bau-& Wohnkonto variabel nicht beurk.
     */
    BauundWohnfinanzierungen_BauWohnkontovariabelnichtbeurk = 240,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Bau-& Wohnkonto Indikator nicht beurk.
     */
    BauundWohnfinanzierungen_BauWohnkontoIndikatornichtbeurk = 245,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Bau- & Wohnkonto variabel beurk.
     */
    BauundWohnfinanzierungen_BauWohnkontovariabelbeurk = 260,

    /**
     * Bau- und Wohnfinanzierungen-Bau- & Wohnkonto Indikator beurk.
     */
    BauundWohnfinanzierungen_BauWohnkontoIndikatorbeurk = 265,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Bau- & Wohnkonto in FW nicht beurk.
     */
    BauundWohnfinanzierungen_BauWohnkontoinFWnichtbeurk = 270,

    /**
     * Bau- und Wohnfinanzierungen-Bau- & Wohnkonto in FW beurk. mit GB
     */
    BauundWohnfinanzierungen_BauWohnkontoinFWbeurkmitGB = 280,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit variabel nicht beurk.
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditvariabelnichtbeurk = 290,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit Indikator nicht beurk.
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditIndikatornichtbeurk = 295,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit variabel beurk. mit GB
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditvariabelbeurkmitGB = 310,

    /**
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit Indikator beurk. mit GB
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditIndikatorbeurkmitGB = 312,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit variabel beurk. ohne GB
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditvariabelbeurkohneGB = 315,

    /**
     * Bau- und Wohnfinanzierungen-Revolv. Privat KK-Kredit Indikator beurk. ohne GB
     */
    BauundWohnfinanzierungen_RevolvPrivatKKKreditIndikatorbeurkohneGB = 317,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Interne Überziehung in FW
     */
    BauundWohnfinanzierungen_InterneÜberziehunginFW = 320,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit GB var.
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitGBvar = 330,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitGBfix = 340,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarlehen mit GB
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlehenmitGB = 350,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarlehen mit GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlehenmitGBfix = 360,

    /**
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen ohne GB
     */
    BauundWohnfinanzierungen_EuroTopDarlehenohneGB = 370,

    /**
     * Bau- und Wohnfinanzierungen-EuroTopDarlehen ohne GB fix
     */
    BauundWohnfinanzierungen_EuroTopDarlehenohneGBfix = 380,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarlehen ohne GB
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlehenohneGB = 382,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarlehen ohne GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlehenohneGBfix = 384,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarl.m.WBB m.GB
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlmWBBmGB = 386,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarl.m.WBB m.GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlmWBBmGBfix = 388,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarl.m.WBB o.GB
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlmWBBoGB = 390,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsrats-Euro-TopDarl.m.WBB o.GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsEuroTopDarlmWBBoGBfix = 392,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit WBB Vorteil m. GB var.
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitWBBVorteilmGBvar = 550,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit WBB Vorteil m. GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitWBBVorteilmGBfix = 560,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit WBB Vorteil o. GB var.
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitWBBVorteiloGBvar = 570,

    /**
     * @deprecated
     * Bau- und Wohnfinanzierungen-Betriebsratsdarlehen mit WBB Vorteil o. GB fix
     */
    BauundWohnfinanzierungen_BetriebsratsdarlehenmitWBBVorteiloGBfix = 580,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT PrivatKredit var.
     */
    COLTKonsumfinanzierungen_COLTPrivatKreditvar = 410,

    /**
     * COLT Konsumfinanzierungen-COLT PrivatKredit Indikator
     */
    COLTKonsumfinanzierungen_COLTPrivatKreditIndikator = 415,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT PrivatKredit fix/follow up ZAKL
     */
    COLTKonsumfinanzierungen_COLTPrivatKreditfixfollowupZAKL = 420,

    /**
     * COLT Konsumfinanzierungen-COLT PrivatKredit fix/follow up Indikator
     */
    COLTKonsumfinanzierungen_COLTPrivatKreditfixfollowupIndikator = 425,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT BetriebsratsKredit var.
     */
    COLTKonsumfinanzierungen_COLTBetriebsratsKreditvar = 430,

    /**
     * COLT Konsumfinanzierungen-COLT BetriebsratsKredit Indikator
     */
    COLTKonsumfinanzierungen_COLTBetriebsratsKreditIndikator = 435,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT BetriebsratsKredit fix/follow up ZAKL
     */
    COLTKonsumfinanzierungen_COLTBetriebsratsKreditfixfollowupZAKL = 440,

    /**
     * COLT Konsumfinanzierungen-COLT BetriebsratsKredit fix/follow up Indikator
     */
    COLTKonsumfinanzierungen_COLTBetriebsratsKreditfixfollowupIndikator = 445,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT GastarbeiterKredit var.
     */
    COLTKonsumfinanzierungen_COLTGastarbeiterKreditvar = 450,

    /**
     * COLT Konsumfinanzierungen-COLT GastarbeiterKredit Indikator
     */
    COLTKonsumfinanzierungen_COLTGastarbeiterKreditIndikator = 455,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT GastarbeiterKredit fix/follow up ZAKL
     */
    COLTKonsumfinanzierungen_COLTGastarbeiterKreditfixfollowupZAKL = 460,

    /**
     * COLT Konsumfinanzierungen-COLT GastarbeiterKredit fix/follow up Indikator
     */
    COLTKonsumfinanzierungen_COLTGastarbeiterKreditfixfollowupIndikator = 465,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT StudentenKredit var.
     */
    COLTKonsumfinanzierungen_COLTStudentenKreditvar = 470,

    /**
     * COLT Konsumfinanzierungen-COLT StudentenKredit Indikator
     */
    COLTKonsumfinanzierungen_COLTStudentenKreditIndikator = 475,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT StudentenKredit fix/follow up ZAKL
     */
    COLTKonsumfinanzierungen_COLTStudentenKreditfixfollowupZAKL = 480,

    /**
     * @deprecated
     * COLT Konsumfinanzierungen-COLT StudentenKredit fix/follow up Indikator
     */
    COLTKonsumfinanzierungen_COLTStudentenKreditfixfollowupIndikator = 485,

    /**
     * @deprecated
     * COLT STANDARD Wohnfinanzierungen-COLT WohnKredit var.
     */
    COLTSTANDARDWohnfinanzierungen_COLTWohnKreditvar = 500,

    /**
     * COLT STANDARD Wohnfinanzierungen-COLT WohnKredit Indikator
     */
    COLTSTANDARDWohnfinanzierungen_COLTWohnKreditIndikator = 505,

    /**
     * @deprecated
     * COLT STANDARD Wohnfinanzierungen-COLT WohnKredit fix/follow up ZAKL
     */
    COLTSTANDARDWohnfinanzierungen_COLTWohnKreditfixfollowupZAKL = 510,

    /**
     * COLT STANDARD Wohnfinanzierungen-COLT WohnKredit fix/follow up Indikator
     */
    COLTSTANDARDWohnfinanzierungen_COLTWohnKreditfixfollowupIndikator = 515,

    /**
     * OCC-Konsumfinanzierungen-OCC-PrivatKredit var.
     */
    OCCKonsumfinanzierungen_OCCPrivatKreditvar = 700,

    /**
     * @deprecated
     * OCC-Konsumfinanzierungen-OCC-PrivatKredit Indikator
     */
    OCCKonsumfinanzierungen_OCCPrivatKreditIndikator = 710,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil m. GB variabel
     */
    COLTMORTGAGEWohnfinanzierungen_EurotopdarlehenmitWBBVorteilmGBvariabel = 640,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-EuroTopDarlehen ohne GB
     */
    COLTMORTGAGEWohnfinanzierungen_EuroTopDarlehenohneGB = 645,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil m. GB fix
     */
    COLTMORTGAGEWohnfinanzierungen_EurotopdarlehenmitWBBVorteilmGBfix = 650,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-EuroTopDarlehen ohne GB fix
     */
    COLTMORTGAGEWohnfinanzierungen_EuroTopDarlehenohneGBfix = 655,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil o. GB variabel
     */
    COLTMORTGAGEWohnfinanzierungen_EurotopdarlehenmitWBBVorteiloGBvariabel = 660,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-Eurotopdarlehen mit WBB Vorteil o. GB fix
     */
    COLTMORTGAGEWohnfinanzierungen_EurotopdarlehenmitWBBVorteiloGBfix = 670,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-EuroTopDarlehen mit GB
     */
    COLTMORTGAGEWohnfinanzierungen_EuroTopDarlehenmitGB = 690,

    /**
     * COLT MORTGAGE Wohnfinanzierungen-EuroTopDarlehen mit GB fix
     */
    COLTMORTGAGEWohnfinanzierungen_EuroTopDarlehenmitGBfix = 695,
}
