import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ClassicEditorBuild from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType, MandantType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { DocumentService, FinancingService, IAdditionalSheetsInformation, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { FinancingStatus, FinancingSubStatus, HelperService } from 'app/modules/shared';
import { Observable, Subject, debounceTime, map, mergeMap, of, switchMap, take, takeUntil, tap } from 'rxjs';


interface ICKEditorEvent {
    editor: {
        getData: () => string;
    };
}

/**
 * Additional Sheets
 */
@Component({
    selector: 'finprocess-additional-sheets',
    templateUrl: './additional-sheets.component.html',
    styleUrls: ['./additional-sheets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalSheetsComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<void>();

    public financingContainerId: string | undefined;

    public financingMandantType: MandantType | undefined;

    public mandantType = MandantType;

    public isSheetChangedNewDocumentNotGenerated = false;

    public isStatusNotOpenOrWaiting = false;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * ck editor
     */
    public editor = ClassicEditorBuild;
    public editorData!: string;
    public editorConfig = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
            ],
        },
        readonly: this.fieldReadonly$,
    };

    public noteTextUpdated = new Subject<{containerId: string, sheet: IAdditionalSheetsInformation, type: DocumentType, text: string}>;

    public showNoteEditorPension = false;
    public showNoteEditorAlimony = false;
    public showNoteEditorIncome = false;

    public documentType = DocumentType;
    
    public sheets: IAdditionalSheetsInformation[] = [];

    public isSendingNotice = false;
    
    public isLoading = true;

    /**
     * Konstruktor
     *
     * @param {Store} store store
     * @param {FinancingService} financingService financing service
     * @param {WaiterService} waiter waiter service
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {DomSanitizer} domSanitizer DomSanitizer-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     */
    public constructor(
        private store: Store, 
        private financingService: FinancingService,
        private waiter: WaiterService,
        private cRef: ChangeDetectorRef,
        private documentService: DocumentService,
        private domSanitizer: DomSanitizer,
        private notification: NotificationService,
        private translate: TranslateService,
    ) { }

    /**
     * Initialisierung
     */
    public ngOnInit(): void {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.waiter.show();
        this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer).pipe(
            tap(container => {
                this.financingContainerId = container?.id,
                this.financingMandantType = container?.mandantType
                this.isStatusNotOpenOrWaiting = !(container?.status === FinancingStatus.Open && container?.subStatus === FinancingSubStatus.Editing 
                    || container?.status === FinancingStatus.HouseholdCalculationWaitingForAcception && container?.subStatus === FinancingSubStatus.HouseholdCalculationWaitingForAcception)
            }),
            mergeMap(container => {

                if (!container) {
                    return of(true)
                }

                return this.financingService.loadAdditionalSheetsInformation(container.id).pipe(
                    map(response => {
                        if (!!response) {
                            this.sheets = response;
                        }
                    },
                    ),
                );
            }),
            takeUntil(this.onDestroy$),
        ).subscribe({
            next: notDone => {
                if (!notDone) {
                    this.waiter.hide();
                    this.cRef.markForCheck(),
                    this.cRef.detectChanges();
                }
            },
            error: () => {
                this.waiter.hide(),
                this.notification.alert(
                    this.translate.instant('general.error'), 
                    this.translate.instant('financing.features.financing-processing.additionalSheets.errorLoad'),
                );
                this.isLoading = false,
                this.cRef.markForCheck();
                this.cRef.detectChanges();                     
            },
        });


        this.noteTextUpdated.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(300),
            tap(() => {
                this.isSendingNotice = true, 
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            }),
            switchMap(sheetsInformation => this.financingService.updateAdditionalSheetsInformationText({containerId: sheetsInformation.containerId, householdId: sheetsInformation.sheet.householdId, type: sheetsInformation.type, text: sheetsInformation.text})
                .pipe(
                    take(1),       
                    map(() => this.updateSheet(sheetsInformation.type, sheetsInformation.sheet, MandantType.FinAdvisory, undefined, undefined, undefined, sheetsInformation.text )),
                ),
            )).subscribe({
            next: () => {
                this.isSendingNotice = false,
                this.cRef.markForCheck();
                this.cRef.detectChanges();
            },
            error: () => {
                this.isSendingNotice = false,
                this.notification.alert(
                    this.translate.instant('general.error'), 
                    this.translate.instant('financing.features.financing-processing.additionalSheets.errorNote'),
                );
                this.cRef.markForCheck();
                this.cRef.detectChanges();                     
            },
        })
    }

    /**
     * Angular Lifecycle-Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
        
    /**
     * Einen File öffnen
     * 
     * @param {string} id FileId
     */
    public openFile(id?: string) {
        if (!!id) {
            this.waiter.show();
            this.documentService.loadFile(id)
                .subscribe({
                    next: async fileContent => {
                        const blob = HelperService.fileContentToBlob(fileContent, 'application/pdf');
    
                        if (blob) {
                            await HelperService.openFileFromBlob(blob);
                        }
                    },
                    complete: () => {
                        this.waiter.hide();
                        this.cRef.markForCheck();
                        this.cRef.detectChanges();                     
                    },
                    error: () => {
                        this.waiter.hide();                     
                        this.notification.alert(
                            this.translate.instant('general.error'), 
                            this.translate.instant('financing.features.financing-processing.additionalSheets.downloadDocumentsError'),
                        );
                    },
                }); 
        }
    }

    /**
     *  Einen Document kreiren
     * 
     * @param {string} householdId householdId ID
     */
    public generateDocument(householdId: string): void {
        if (!!this.financingContainerId) {
            this.waiter.show();
            this.financingService.generateAdditionalSheets({containerId: this.financingContainerId, householdId: householdId}) 
                .pipe(
                    take(1),
                    map(document => {
                        if (!!document) {
                            const sheetToUpdate = this.sheets.find(sheet => sheet.householdId === householdId);
                            if (!!sheetToUpdate) {
                                sheetToUpdate.additionalSheetFileId = document.files[0].id;
                                this.isSheetChangedNewDocumentNotGenerated = false;
                                this.cRef.markForCheck();
                                this.cRef.detectChanges(); 
                            }
                        }
                    }),
                ).subscribe({
                    next: () => {
                        this.notification.toast(
                            this.translate.instant('financing.features.financing-processing.additionalSheets.documentCreated'),
                        ),
                        this.waiter.hide()},
                    error: () => {
                        this.waiter.hide(),
                        this.notification.alert(
                            this.translate.instant('general.error'), 
                            this.translate.instant('financing.features.financing-processing.additionalSheets.generateDocumentsError'),
                        );
                    },
                })
        }
    }

    /**
     * change Status of additional sheets
     *
     * @param {DocumentType} documentType DocumentType
     * @param  {boolean} changeStatusTo Status
     * @param {IAdditionalSheetsInformation} sheet Sheet
     */
    public changeStatus(documentType: DocumentType, changeStatusTo: boolean, sheet: IAdditionalSheetsInformation ) {

        if (!!this.financingContainerId) {
            this.waiter.show();
            this.financingService.setAdditionalSheetsActive({active: changeStatusTo, containerId: this.financingContainerId, householdId: sheet.householdId, type: documentType})
                .pipe(
                    take(1),
                )
                .subscribe({
                    next: () => {
                        this.updateSheet(documentType, sheet, MandantType.FinAdvisory, changeStatusTo);
                        this.waiter.hide();
                    },
                    error: () => {
                        this.waiter.hide();
                        this.notification.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.additionalSheets.activeSheetError'));
                    },
                })

        }
    }

    /**
     * Update Sheet data 
     * 
     * @param {DocumentType} documentType Doc Type
     * @param {IAdditionalSheetsInformation} sheet Sheet
     * @param {MandantType} mandantType  mandantType
     * @param {boolean} changeStatusTo  status
     * @param {string} fileIdToUpdate FileId
     * @param {string} created Creted
     * @param {string} textToUpdate  Text for Update
     */
    // eslint-disable-next-line complexity
    public updateSheet(documentType: DocumentType, sheet: IAdditionalSheetsInformation, mandantType: MandantType, changeStatusTo?: boolean, fileIdToUpdate?: string, created?: string, textToUpdate?: string): void {
        
        if (!!fileIdToUpdate && mandantType === MandantType.BAF) {
            sheet.additionalSheetFileId = fileIdToUpdate;
            this.cRef.markForCheck();
            this.cRef.detectChanges();  
            return;
        }

        if (documentType === DocumentType.CreditDurationInRetirementSignature) {
            if (changeStatusTo !== undefined) {
                sheet.creditDurationInRetirementAdditionalSheet = changeStatusTo;

                //hide note editor, if open & sheet not editable
                if (this.showNoteEditorPension && changeStatusTo === false) {
                    this.showHideNoteEditor(DocumentType.CreditDurationInRetirementSignature)
                }
            }
            if (!!fileIdToUpdate && mandantType !== MandantType.BAF) {
                sheet.creditDurationInRetirementAdditionalSheetFileId = fileIdToUpdate;
            } 
            if (!!created) {
                sheet.creditDurationInRetirementAdditionalSheetDocumentCreate = created;
            } 
            if (textToUpdate !== undefined) {
                sheet.creditDurationInRetirementAdditionalSheetText = textToUpdate;
            }
        } 
        else if (documentType === DocumentType.CompositionOfOtherIncomeSignature) {
            if (changeStatusTo !== undefined) {
                sheet.otherIncomeContainsAlimonyAdditionalSheet = changeStatusTo;

                //hide note editor, if open & sheet not editable
                if (this.showNoteEditorAlimony && changeStatusTo === false) {
                    this.showHideNoteEditor(DocumentType.CompositionOfOtherIncomeSignature)
                }
            }
            if (!!fileIdToUpdate && mandantType !== MandantType.BAF) {
                sheet.otherIncomeContainsAlimonyAdditionalSheetFileId = fileIdToUpdate;
            } 
            if (!!created) {
                sheet.otherIncomeContainsAlimonyAdditionalSheetDocumentCreate = created;
            } 
            if (textToUpdate !== undefined) {
                sheet.otherIncomeContainsAlimonyAdditionalSheetText = textToUpdate;
            }
        }
        else if (documentType === DocumentType.CompositionOfIncomeSignature) {
            if (changeStatusTo !== undefined) {
                sheet.compositionOfIncomeAdditionalSheet = changeStatusTo;

                //hide note editor, if open & sheet not editable
                if (this.showNoteEditorPension && changeStatusTo === false) {
                    this.showHideNoteEditor(DocumentType.CompositionOfIncomeSignature)
                }
            }
            if (!!fileIdToUpdate && mandantType !== MandantType.BAF) {
                sheet.compositionOfIncomeAdditionalSheetFileId = fileIdToUpdate;
            } 
            if (!!created) {
                sheet.compositionOfIncomeAdditionalSheetDocumentCreate = created;
            } 
            if (textToUpdate !== undefined) {
                sheet.compositionOfIncomeAdditionalSheetText = textToUpdate;
            }
        }

        this.isSheetChangedNewDocumentNotGenerated = true;

        this.cRef.markForCheck();
        this.cRef.detectChanges();  
    }

    /**
     * On user text note input changed
     *
     * @param {ICKEditorEvent} event event
     * @param {IAdditionalSheetsInformation} sheet IAdditionalSheetsInformation
     * @param {DocumentType} documentType Document type
     */
    public onEditorChangeNote(event: ICKEditorEvent, sheet: IAdditionalSheetsInformation, documentType: DocumentType): void {
        if (!!this.financingContainerId) {
            this.noteTextUpdated.next({containerId: this.financingContainerId, sheet: sheet, type: documentType, text: event.editor.getData()});
        }
    }

    /**
     * show/hide note editor
     *
     * @param {DocumentType} documentType sheet
     */
    public showHideNoteEditor(documentType: DocumentType) {
        if (documentType === DocumentType.CreditDurationInRetirementSignature) {
            this.showNoteEditorPension = !this.showNoteEditorPension;
        }
        if (documentType === DocumentType.CompositionOfOtherIncomeSignature) {
            this.showNoteEditorAlimony = !this.showNoteEditorAlimony;
        }
        if (documentType === DocumentType.CompositionOfIncomeSignature) {
            this.showNoteEditorIncome = !this.showNoteEditorIncome;
        }
        this.cRef.markForCheck();
        this.cRef.detectChanges();  
    }

    /**
     * Sanitize HTML 
     * 
     * @param {string} text input text
     * @returns {string} output text
     */
    public onSanitize(text?: string): string {
        if (!!text) {
            return this.domSanitizer.sanitize(SecurityContext.HTML, text) ?? ''
        }
        return '';
    }

    /**
     * Dokument hochladen
     * 
     * @param { EventTarget | null} target Event Target
     * @param { IAdditionalSheetsInformation } sheet Sheet
     * @param { DocumentType } type DocumentType
     * @param { string} oldFileId Altes File Id
     */
    public uploadFile (target: EventTarget | null, sheet: IAdditionalSheetsInformation, type: DocumentType, oldFileId?: string): void {
        if (this.financingContainerId !== undefined) {
            const tmp = target as HTMLInputElement;

            if (tmp.files && tmp.files.length > 1) {
                this.notification.toast(this.translate.instant('financing.features.financing-processing.additionalSheets.maxOneFile'), { duration: 3000 });
            }
            
            const file = tmp.files?.item(0);

            if (!!file && (file.size === 0 || file.type !== 'application/pdf')) {
                this.notification.toast(
                    this.translate.instant('general.errorUploadPdfFile'),
                )
                tmp.value = '';
                this.waiter.hide();
                return;
            }

            if (!!file) {
                this.notification.confirmOkCancel(
                    this.translate.instant('financing.features.financing-processing.additionalSheets.uploadDocument'),
                    this.translate.instant('financing.features.financing-processing.additionalSheets.confirmDialogContent', {
                        name: file.name,
                    }),
                ).pipe(
                    mergeMap(result => {
                        if (result !== 'submit') {
                            return of(void 0);
                        }

                        return this.waiter.show().pipe(
                            mergeMap(() => {
                                if (this.financingContainerId === undefined) {
                                    return of(void 0);
                                }
                                return this.documentService.uploadHouseholdFile({householdId: sheet.householdId, mapId: this.financingContainerId, file: file, type: type, oldFileId: oldFileId }).pipe(
                                    map(doc => {
                                        if (!!doc) {
                                            this.updateSheet(type, sheet, MandantType.FinAdvisory, undefined, doc.files[0].id)
                                        }
                                    }),
                                )
                            }),
                        )
                    }),
                ).subscribe({
                    next: () => {
                        tmp.value = '';
                        this.waiter.hide();
                        this.notification.toast(
                            this.translate.instant('financing.features.financing-processing.additionalSheets.documentUploaded'),
                        ),
                        this.cRef.markForCheck();
                        this.cRef.detectChanges();
                    },
                    error: (error: HttpErrorResponse) => {
                        this.waiter.hide();

                        if (error.status === HttpStatusCode.MethodNotAllowed) {
                            this.notification.alert(
                                this.translate.instant('general.error'), 
                                this.translate.instant('financing.features.financing-processing.additionalSheets.errorDocumentMethodNotAllowed'),
                            );
                        } 
                        else {
                            this.notification.alert(
                                this.translate.instant('general.error'), 
                                this.translate.instant('financing.features.financing-processing.additionalSheets.errorDocument'),
                            );
                        }
                        tmp.value = '';
                        this.cRef.markForCheck();
                        this.cRef.detectChanges();
                    },
                })
            }
        }
    }
}
